import {
	SimpleTableColumn,
	Spinner,
	TooltipV2,
	Trash,
	useNotifications,
} from '@compstak/ui-kit';
import { CompsetDTO, useRemoveProperty } from 'api';
import styled from 'styled-components';

type Props = {
	propertyId: number;
	rows: CompsetDTO[];
};

export const useDeleteColumn = ({ propertyId, rows }: Props) => {
	const {
		mutateAsync: removeProperties,
		isLoading: isLoadingRemoveProperty,
		variables: removePropertyVariables,
	} = useRemoveProperty({ propertyId });

	const { addNotification } = useNotifications();

	const column: SimpleTableColumn<CompsetDTO> = {
		id: 'delete',
		header: () => {
			if (rows.length === 1) return null;

			return (
				<TooltipV2 content="Delete all comps from the set">
					<TransparentButton
						onClick={async () => {
							await removeProperties({
								id: propertyId,
								ids: rows.map((r) => r.property.id),
							});
							addNotification({
								icon: 'success',
								title: 'Properties were deleted',
							});
						}}
						disabled={isLoadingRemoveProperty}
					>
						{isLoadingRemoveProperty ? (
							<Spinner size="s" />
						) : (
							<StyledIconTrash variant="errorInvert" />
						)}
					</TransparentButton>
				</TooltipV2>
			);
		},
		body: ({ row }) => {
			if (row.property.id === propertyId) return null;

			const isRemovingThisProperty =
				isLoadingRemoveProperty &&
				removePropertyVariables?.ids.includes(row.property.id);

			return (
				<TooltipV2 content="Delete this comp from the set">
					<TransparentButton
						onClick={async (e) => {
							e.stopPropagation();
							await removeProperties({
								id: propertyId,
								ids: [row.property.id],
							});
							addNotification({ icon: 'success', title: 'Items were deleted' });
						}}
						disabled={isLoadingRemoveProperty}
					>
						{isRemovingThisProperty ? (
							<Spinner size="s" />
						) : (
							<StyledIconTrash variant="errorInvert" />
						)}
					</TransparentButton>
				</TooltipV2>
			);
		},
		width: 60,
		hAlign: 'center',
	};

	return column;
};

const StyledIconTrash = styled(Trash)``;

const TransparentButton = styled.button`
	background-color: transparent;
	border: 0;
	&:disabled {
		${StyledIconTrash} {
			color: ${(p) => p.theme.colors.neutral.n100};
		}
	}
`;
