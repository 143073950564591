import { getMapAnalyticsMetricText } from '../getMapAnalyticsMetricText';
import { MapAnalyticsInfoDescription } from '../UI';
import { useSpaceTypeAnalyticsState } from './SpaceTypeAnalyticsProvider';

export const SpaceTypeAnalyticsDescription = () => {
	const [state] = useSpaceTypeAnalyticsState();

	return (
		<MapAnalyticsInfoDescription>
			Pin colors shown indicate prevalent lease type by{' '}
			{getMapAnalyticsMetricText(state.metric)} for a property.
		</MapAnalyticsInfoDescription>
	);
};
