import { SimpleTable } from '@compstak/ui-kit';
import { useMufaFloorPlansQuery } from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { RealPage } from '../RealPage';
import { TabContentTitle } from '../UI';
import { CONTAINER_ID, FloorPlansMenu } from './FloorPlansMenu';
import { TitleContainer } from './UI';
import { getMufaTableColumns } from './getMufaTableColumns';

type Props = {
	isExportPDF?: boolean;
};

export const FloorPlans = ({ isExportPDF }: Props) => {
	const params = routes.propertyById.useParams();
	const { data } = useMufaFloorPlansQuery({ propertyId: params.propertyId });

	const columns = getMufaTableColumns();

	if (data && data.floorPlans.length === 0) return null;

	return (
		<div>
			<FloorPlansContainer>
				<TitleContainer justifyContent="space-between" alignItems="center">
					<TabContentTitle>Floorplan Pricing</TabContentTitle>
					{!isExportPDF && <FloorPlansMenu />}
				</TitleContainer>

				<SimpleTable
					id={CONTAINER_ID}
					rows={data?.floorPlans ?? []}
					columns={columns}
					scrollContainerProps={{
						style: {
							maxHeight: isExportPDF ? 600 : 400,
						},
					}}
				/>
			</FloorPlansContainer>
			<RealPage />
		</div>
	);
};

const FloorPlansContainer = styled.div`
	padding: 1rem 1.5rem;
	background: ${(p) => p.theme.colors.white.white};
	box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.2);
	margin-bottom: 0.75rem;
`;
