import styled, { css } from 'styled-components';

const widthDefault = 160;

const mixin = css`
	color: rgba(0, 0, 0, 0.87);
	font-family: Gotham;
	font-size: 10px;
	font-style: normal;
	font-weight: normal;
`;

const Wrapper = styled.div<{ width?: number }>`
	min-width: ${(props) => props.width || widthDefault}px;
	position: relative;
`;

const Selector = styled.div`
	align-items: center;
	background: transparent;
	border-radius: 3px;
	border: 1px solid #d5d8df;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 4px 13px 7px 4px;
	height: 40px;
	transition: border-color 300ms;

	&:hover {
		border-color: #5e6579;
	}
`;

const SelectorContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const SelectorTitle = styled.span`
	${mixin}
	margin-bottom: 5px;
	text-transform: uppercase;
`;

const SelectorLabel = styled.span`
	${mixin}
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const SelectorIconWrapper = styled.div<{ isOpen: boolean }>`
	transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
`;

const ListWrapper = styled.div`
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0px 4px 12px rgb(50 50 71 / 8%), 0px 16px 24px rgb(50 50 71 / 8%);
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	top: 0;
	min-width: 100%;
	z-index: 100;
`;

const ListItem = styled.div`
	padding: 12px 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: background-color 120ms;

	&:hover {
		background-color: #f1f2f5;
	}
`;

const ListItemText = styled.span<{ active: boolean }>`
	${mixin}
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
	${(props) => props.active && `font-weight: bold`};
`;

export const Styled = {
	Wrapper,
	Selector,
	SelectorContentWrapper,
	SelectorTitle,
	SelectorLabel,
	SelectorIconWrapper,
	ListWrapper,
	ListItem,
	ListItemText,
};
