import { Button, Flex } from '@compstak/ui-kit';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'util/useAppSelector';

export function SubmitManualCompFormButton(
	props: ComponentProps<typeof Button>
) {
	const isUploading = useAppSelector(
		(state) => state.uploads.status === 'PENDING'
	);
	return (
		<Flex justifyContent="center" alignItems="center">
			<ButtonSC
				variant="success"
				{...props}
				disabled={isUploading || props.disabled}
			>
				Submit Comps
			</ButtonSC>
		</Flex>
	);
}

const ButtonSC = styled(Button)`
	width: 360px;
	height: 40px;
	margin: 20px;
`;
