import React from 'react';
import { Chart, DataSet } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import { useDispatch } from 'react-redux';
import { removeDataSet } from 'Pages/Analytics/Builder/actions';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalTitle,
} from 'Components/Modals/common/UI';

type Props = {
	chart: Chart;
	dataSet: DataSet;
};

export const RemoveDataSetModal = ({ chart, dataSet }: Props) => {
	const { closeModal } = useModal();
	const dispatch = useDispatch();

	const remove = () => {
		dispatch(removeDataSet(chart, dataSet.id!));
		closeModal();
	};

	return (
		<ModalCenteredContent>
			<ModalTitle>Are you sure you want to delete this data set?</ModalTitle>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton variant="error" onClick={remove}>
					Delete
				</ModalButton>
			</ModalButtons>
		</ModalCenteredContent>
	);
};
