import React, {
	ReactNode,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';

import { isSet } from 'models/filters/util/getSetFilters';

import '../styles/multiple_select.nomodule.less';
import { FiltersObject } from 'models/filters/types';
import { FilterErrors } from './Filter/Container';
import {
	MultiSelectOptions,
	OptionRenderWrap,
	OptionContent,
	MultiSelectCheckbox,
} from './MultiSelectOptions';
import useOnClickOutside from '../../../util/useOnClickOutside';
import { FilterChips } from './Filter/FilterChips';
import { CheckedState } from '@radix-ui/react-checkbox';
import { AngleArrow, Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';

export type FilterOption = {
	id?: number;
	ids?: number[];
	names?: string[];
	name: string;
	disabledTooltip?: ReactNode;
	inputType?: 'radio' | 'checkbox';
	type?: 'header';
	key?: string;
};

export type MultiselectProps = {
	attribute: keyof FiltersObject;
	disabled?: boolean;
	filter?: number[] | string[] | null;
	usesStringNames?: boolean;
	onChange?: (values: string[], checked: boolean) => void;
	onFilterChange: (change: Partial<FiltersObject>) => void;
	onSelectAll?: (checked: boolean) => void;
	options: FilterOption[];
	setError?: (errors: FilterErrors) => void;
	touch?: () => void;
	renderOption?: (option: FilterOption, searchTerm: string) => ReactNode;
	renderAllOption?: (checked: CheckedState) => ReactNode;
	renderAllPlaceholder?: () => ReactNode;
	renderCheckedChips?: (checkedOptions: FilterOption[]) => ReactNode[];
	hasSearch?: boolean;
	searchInputPlaceholder?: string;
	initialExpanded?: boolean;
	isOptionChecked?: (option: FilterOption) => CheckedState;
	className?: string;
	isLoading?: boolean;
};

const Multiselect = (props: MultiselectProps) => {
	const [expanded, setExpanded] = useState(props.initialExpanded ?? true);

	const allAreSelected: CheckedState = useMemo(() => {
		const optionIds = getIds(props.options, props.usesStringNames);
		const selectedIds = (props.filter ?? []) as (string | number)[];

		return optionIds.every((optionId) => selectedIds.includes(optionId))
			? true
			: selectedIds.some((selectedId) => optionIds.includes(selectedId))
				? 'indeterminate'
				: false;
	}, [props.filter, props.options, props.usesStringNames]);

	const selectAllCheckboxes = () => {
		props.onFilterChange({
			[props.attribute]: props.options
				.filter((opt) => opt.type !== 'header')
				.flatMap((option) => {
					return props.usesStringNames
						? option.names ?? option.name
						: option.ids ?? option.id;
				}),
		});
	};

	const isChecked = useCallback(
		(option: FilterOption) => {
			if (props.isOptionChecked) {
				return props.isOptionChecked(option);
			} else {
				const filter = props.filter;
				if (!filter) return false;

				if (props.usesStringNames) {
					return option.names
						? // @ts-expect-error TS2345: Argument of type 'string' is n...
							option.names.some((name) => filter.includes(name))
						: // @ts-expect-error TS2345: Argument of type 'string' is n...
							filter.includes(option.name);
				} else {
					return option.ids
						? // @ts-expect-error TS2345: Argument of type 'string' is n...
							option.ids.some((id) => filter.includes(id))
						: // @ts-expect-error TS2345: Argument of type 'string' is n...
							filter.includes(option.id);
				}
			}
		},
		[props.filter, props.usesStringNames, props.isOptionChecked]
	);

	const showAllLabel = useMemo(
		() => !isSet(props.filter, props.attribute) || allAreSelected === true,
		[allAreSelected, props.attribute, props.filter]
	);

	const checkedOptions = useMemo(
		() =>
			props.options.filter((opt) => opt.type !== 'header' && isChecked(opt)),
		[isChecked, props.options]
	);

	if (expanded) {
		return (
			<ExpandedComponent
				{...props}
				setExpanded={setExpanded}
				allAreSelected={allAreSelected}
				selectAllCheckboxes={selectAllCheckboxes}
				isChecked={isChecked}
			/>
		);
	}

	const allPlaceholder = props.renderAllPlaceholder
		? [props.renderAllPlaceholder()]
		: ['All'];

	const chips = props.renderCheckedChips
		? props.renderCheckedChips(checkedOptions)
		: checkedOptions.map(({ name }) => name);

	return (
		<div
			className={`ms-parent ${props.className ?? ''} ${props.disabled ? 'disabled' : ''}`}
			onClick={() => !props.disabled && !props.isLoading && setExpanded(true)}
		>
			<FilterChips chips={showAllLabel ? allPlaceholder : chips} />
			{props.isLoading ? (
				<MultiSelectSpinner size="s" />
			) : (
				<MultiSelectAngleArrow rotate={expanded ? 180 : 0} />
			)}
		</div>
	);
};

export default Multiselect;

type MultiselectExpandedProps = MultiselectProps & {
	setExpanded: (expanded: boolean) => void;
	selectAllCheckboxes: () => void;
	allAreSelected: CheckedState;
	isChecked: (option: FilterOption) => CheckedState;
};

const ExpandedComponent = ({
	setExpanded,
	selectAllCheckboxes,
	allAreSelected,
	isChecked,
	...props
}: MultiselectExpandedProps) => {
	const divRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(divRef, () => {
		setExpanded(false);
	});

	const selectAll = (checked: boolean) => {
		props.touch?.();

		if (props.onSelectAll) {
			props.onSelectAll(checked);
		} else if (checked) {
			selectAllCheckboxes();
		} else {
			props.onFilterChange({
				[props.attribute]: null,
			});
		}
	};

	const addOrRemoveItem = (value: string, checked: boolean) => {
		props.touch?.();

		const arrayOfValues = value.split('|');

		try {
			if (props.onChange) {
				props.onChange(arrayOfValues, checked);
			} else {
				const values: (string | number)[] = props.usesStringNames
					? arrayOfValues
					: arrayOfValues.map(parseFloat);

				let nextFilters =
					props.filter?.filter((a) => values.indexOf(a) === -1) ?? [];

				if (checked) {
					nextFilters = nextFilters.concat(values).sort();
				}
				props.onFilterChange({
					[props.attribute]: nextFilters,
				});
			}
		} catch (e) {
			if (e instanceof Error) {
				props.setError?.(e.message);
			}
		}
	};

	const id = `${props.attribute}-multiselect`;
	const idAll = `${id}-all`;

	return (
		<div className="ms-parent expanded" ref={divRef}>
			<div className="ms-drop">
				<ul>
					{props.renderAllOption ? (
						props.renderAllOption(allAreSelected)
					) : (
						<li key={idAll} className="select-all">
							<OptionContent htmlFor={idAll}>
								<MultiSelectCheckbox
									id={idAll}
									checked={allAreSelected}
									onCheckedChange={selectAll}
								/>
								<OptionRenderWrap>All</OptionRenderWrap>
							</OptionContent>
						</li>
					)}
					<MultiSelectOptions
						options={props.options}
						usesStringNames={props.usesStringNames}
						renderOption={props.renderOption}
						addOrRemoveItem={addOrRemoveItem}
						isChecked={isChecked}
						hasSearch={props.hasSearch}
						searchInputPlaceholder={props.searchInputPlaceholder}
					/>
				</ul>
			</div>
		</div>
	);
};

function getIds(
	options: FilterOption[],
	usesStringNames?: boolean
): (string | number)[] {
	return options
		.filter((opt) => opt.type !== 'header')
		.map((opt) => {
			if (usesStringNames) {
				return opt.names ?? opt.name!;
			}
			return opt.ids ?? opt.id!;
		})
		.flat();
}

const MultiSelectAngleArrow = styled(AngleArrow)`
	flex-shrink: 0;
	margin: 0.25rem 0 0 auto;
	path {
		stroke: ${({ theme }) => theme.colors.neutral.n30};
	}
`;

const MultiSelectSpinner = styled(Spinner)`
	flex-shrink: 0;
	margin: 0.25rem 0.25rem 0 auto;
`;
