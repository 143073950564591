import { FilterDropdownContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import React from 'react';
import * as Styled from './styled';

import useOnClickOutside from 'util/useOnClickOutside';
import { CheckboxItem, FilterItemComponenet } from '../FilterItem';

const title = 'space type';

type DropdownData = string[];

type LeaseExpirationDropdownProps = {
	data: DropdownData;
	activeData: DropdownData;
	onChange: React.Dispatch<React.SetStateAction<string[]>>;
};

const LeaseExpirationDropdown = (props: LeaseExpirationDropdownProps) => {
	const ref = React.useRef<null | HTMLDivElement>(null);
	const [isDropdownActive, setDropdownActive] = React.useState<boolean>(false);

	useOnClickOutside(ref, () => setDropdownActive(false));

	const contentName = React.useMemo(
		() =>
			props.data.length === props.activeData.length
				? 'All Selected'
				: props.activeData.join(', '),
		[props.data, props.activeData]
	);

	const toogleDropdown = React.useCallback(
		() => setDropdownActive((dropdownActive) => !dropdownActive),
		[]
	);

	const onSelectAllHandler = React.useCallback(() => {
		const newQuery =
			props.data.length === props.activeData.length ? [] : props.data;
		props.onChange(newQuery);
	}, [props.data, props.activeData]);

	const onClickHandler = React.useCallback(
		(industrie: string) => {
			const newQuery = props.activeData.includes(industrie)
				? props.activeData.filter(
						(activeIndustrie) => activeIndustrie !== industrie
				  )
				: [...props.activeData, industrie];
			props.onChange(newQuery);
		},
		[props.activeData]
	);

	if (!props.data.length) {
		return null;
	}

	return (
		<Styled.FilterContainer data-html2canvas-ignore>
			<FilterItemComponenet
				filterRef={ref}
				filterName={title}
				contentName={contentName}
				width={150}
				active={isDropdownActive}
				onClick={toogleDropdown}
			>
				{isDropdownActive && (
					<FilterDropdownContainer width={150} top={48}>
						{!!props.data.length && (
							<CheckboxItem
								id="lease_expiration_filter_select_all"
								label="Select All"
								fontSize={12}
								height={34}
								checked={props.data.length === props.activeData.length}
								onChange={onSelectAllHandler}
							/>
						)}
						{!!props.data.length &&
							props.data.map((industrie) => (
								<div key={industrie}>
									<CheckboxItem
										id={`lease-expiration-${industrie.toLowerCase()}`}
										label={industrie}
										fontSize={14}
										checked={props.activeData.includes(industrie)}
										onChange={() => onClickHandler(industrie)}
									/>
								</div>
							))}
					</FilterDropdownContainer>
				)}
			</FilterItemComponenet>
		</Styled.FilterContainer>
	);
};

export default LeaseExpirationDropdown;
