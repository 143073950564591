import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import StringsTypeahead from 'Components/Filters/Base/StringsTypeahead';

const attribute = 'loanOriginators' as const;

export const LenderFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<div>
					<StringsTypeahead
						attribute={attribute}
						compType={props.compType}
						filter={props.filter}
						filters={props.filters}
						onFilterChange={props.onFilterChange}
						setError={props.setError}
						touch={props.touch}
						typeaheadAttribute="loanOriginator"
						placeholder="Add a lender"
					/>
					<FilterDescription>Filter by latest lender</FilterDescription>
				</div>
			)}
		</FilterBase>
	);
};
