import styled from 'styled-components';
import IconError from '../../ui/svg_icons/error.svg';

export const NoDataMessage = () => {
	return (
		<Root>
			<StyledIconError width={20} height={20} />
			<Title>No Results Found</Title>
			<Message>
				Try removing some filters or expanding the area of your search
			</Message>
		</Root>
	);
};

const Root = styled.div`
	position: absolute;
	top: 100px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledIconError = styled(IconError)`
	fill: ${(p) => p.theme.colors.neutral.n85};
`;

const Title = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-weight: 300;
	font-size: 30px;
	color: ${(p) => p.theme.colors.neutral.n85};
	margin-bottom: 0.5rem;
`;

const Message = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-weight: 400;
	font-size: 18px;
	max-width: 400px;
	color: ${(p) => p.theme.colors.neutral.n85};
	text-align: center;
`;
