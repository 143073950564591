import {
	UseInfiniteQueryOptions,
	useInfiniteQuery,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { PortfolioPropertiesParams } from 'api/types';
import { PORTFOLIO_PROPERTIES_INCLUDE_FIELDS } from '../constants';
import { PortfolioPropertiesResponse } from './usePortfolioPropertiesQuery';
import { API } from '@compstak/common';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';

const PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE = 50;

export const usePortfolioPropertiesInfiniteQuery = (
	_params: PortfolioPropertiesInfiniteQueryParams,
	options?: UseInfiniteQueryOptions<
		PortfolioPropertiesResponse,
		unknown,
		PortfolioPropertiesResponse
	>
) => {
	const params = {
		includeFields: PORTFOLIO_PROPERTIES_INCLUDE_FIELDS,
		..._params,
	};

	return useInfiniteQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_PROPERTIES_INFINITE, params],
		queryFn: async ({ pageParam = 0, signal }) => {
			const { portfolioId, ..._queryParams } = params;

			const queryParams = {
				..._queryParams,
				offset: pageParam * PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE,
				limit: PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE,
			};

			const response = await API.get<PortfolioPropertiesResponse>(
				`/pa/v2/api/portfolios/${portfolioId}/properties${getQueryStringFromPortfolioFilters(
					queryParams
				)}`,
				{ signal }
			);

			return response.data;
		},
		getNextPageParam: (lastPage, pages) => {
			const hasMore =
				lastPage.length === PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE;
			if (!hasMore) return;
			return pages.length + 1;
		},
		...options,
	});
};

export type PortfolioPropertiesInfiniteQueryParams = Omit<
	PortfolioPropertiesParams,
	'offset' | 'limit'
>;
