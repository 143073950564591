import { AnalyticsChip } from 'Components';
import {
	TenantIndustryLegend,
	useIsTopTenTenantIndustries,
	useTenantIndustryState,
} from 'maps';
import { useFilters } from 'reducers/filtersReducer';
import { MapAnalyticsActions } from '../MapAnalyticsActions';
import { MapAnalyticsCloseButton } from '../MapAnalyticsCloseButton';
import {
	MapAnalyticsInfo,
	MapAnalyticsInfoTitle,
	MapAnalyticsMenuContent,
	MapAnalyticsMenuHeader,
} from '../UI';
import { useFilteredTenantIndustries } from './useFilteredTenantIndustries';
import { TenantIndustryAnalyticsDescription } from './TenantIndustryAnalyticsDescription';

type Props = {
	onClickClose: () => void;
};

export const TenantIndustryAnalyticsReadMenu = ({ onClickClose }: Props) => {
	const [state, setState] = useTenantIndustryState();
	const [filters] = useFilters();

	const tenantIndustries = useFilteredTenantIndustries();

	const isTopTen = useIsTopTenTenantIndustries({ tenantIndustries, filters });

	return (
		<>
			<MapAnalyticsMenuHeader>
				<AnalyticsChip />
				<MapAnalyticsCloseButton
					data-qa-id="analytics-close-btn"
					onClick={onClickClose}
				/>
			</MapAnalyticsMenuHeader>
			<MapAnalyticsInfo>
				<MapAnalyticsInfoTitle>{state.title}</MapAnalyticsInfoTitle>
				<TenantIndustryAnalyticsDescription />
				<MapAnalyticsActions
					onClickSettings={() => {
						setState((s) => ({ ...s, view: 'settings' }));
					}}
				/>
			</MapAnalyticsInfo>
			<MapAnalyticsMenuContent>
				<TenantIndustryLegend
					tenantIndustries={tenantIndustries}
					isTopTen={isTopTen}
				/>
			</MapAnalyticsMenuContent>
		</>
	);
};
