import React from 'react';

import Menu from 'Singletons/Menu';

import styles from '../stacking.less';
import popupStyles from 'Components/Popup/popup.less';

export class SpaceMenu extends React.PureComponent {
	edit = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.openSpaceEditor(this.props.space);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	duplicate = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.duplicateSpace(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
			this.props.space
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	remove = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.unplaceSingleSpace(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
			this.props.space
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	render() {
		let removeOption;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'unplaced' does not exist on type 'Readon... Remove this comment to see the full error message
		if (!this.props.unplaced) {
			removeOption = <li onClick={this.remove}>Remove</li>;
		}

		return (
			<ul className={styles.menuContent}>
				<li onClick={this.edit}>Edit</li>
				<li onClick={this.duplicate}>Duplicate</li>
				{removeOption}
			</ul>
		);
	}
}

Menu.addComponent('stacking-plan-space-menu', SpaceMenu, {
	className: popupStyles.compactWhite,
});

export default 'stacking-plan-space-menu';
