import { useDispatch } from 'react-redux';
import Menu from 'Singletons/Menu';

import * as pageActions from '../../../actions';

import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { CompType } from 'types/comp';
import { getAttributes, getAttributesById } from 'util/comp-format/src/format';
import { useAppSelector } from 'util/useAppSelector';
import { useIsShowTreppUI } from 'utils';
import { useListSearchInput } from '../../../../../Components/ListSearchInput/ListSearchInput';
import {
	MULTIFAMILY_SECTION_KEY,
	TREPP_SECTION_KEY,
} from '../../../../../constants';
import { useIsMultifamily } from '../../../../../hooks';
import { CompFormatAttribute } from '../../../../../util/comp-format/attributes';
import { TableSettingsMenuView } from './TableSettingsMenuView';
import { useFilters } from 'reducers/filtersReducer';

type TableSettingsMenuProps = {
	compType: CompType;
};

export interface SectionedAttr {
	title: string | undefined;
	attributes: CompFormatAttribute[];
}

export const TableSettingsMenu = ({ compType }: TableSettingsMenuProps) => {
	const dispatch = useDispatch();

	const fields = useAppSelector((s) => s.searchReducer.fields);
	const [filters] = useFilters();

	const markets = getFiltersMarkets(filters);

	const { searchTerm, element } = useListSearchInput({
		inputHeight: '2.5rem',
		inputPlaceholder: 'Search for a column',
		inputDataQaId: tableSettingsMenuSearchInputTestId,
	});

	const isMultifamily = useIsMultifamily({
		markets,
	});

	const showTreppUI = useIsShowTreppUI({
		markets,
	});

	const sectionedAttrs = getAttributes(compType)
		.filter((field) => {
			switch (true) {
				case field.name === 'concessionValue' && compType === 'lease':
				case field.hidden:
				// @ts-expect-error TS2339: Property 'inTableView' does no...
				case field.inTableView === false:
				case typeof field.id !== 'number':
				case Number(field.id) < 0:
				case field.section === TREPP_SECTION_KEY && !showTreppUI:
				case field.section === MULTIFAMILY_SECTION_KEY && !isMultifamily:
					return false;
			}
			return true;
		})
		.reduce<SectionedAttr[]>((attrs, attr) => {
			if (attr.section === 'Misc') {
				return attrs;
			}

			let section = attrs.find(
				(localSection) => localSection.title === attr.section
			);

			if (typeof section !== 'object') {
				section = { title: attr.section, attributes: [] };
				attrs.push(section);
			}

			const activeField = fields?.find((field) => field.id === attr.id);

			// @ts-expect-error TS2339: Property 'active' does not exi...
			attr.active = typeof activeField === 'object';

			section.attributes.push(attr);

			return attrs;
		}, []);

	const resetColumns = () => {
		let presetName = 'summary';
		if (compType === 'property') {
			presetName = 'summaryWithLoan';
		}
		dispatch(pageActions.applyColumnsPreset(presetName, compType));
	};

	const addColumn = (id: number | string) => {
		if (!fields) {
			return;
		}

		// @ts-expect-error TS7015: Element implicitly has an 'any...
		const compFormatField = getAttributesById(compType)[id];

		const updatedFields = fields.map((field) => {
			return { compAttributeId: field.id, width: field.width };
		});

		updatedFields.unshift({
			compAttributeId: id,
			width: compFormatField.width,
		});

		// @ts-expect-error TS2345: Argument of type '{ compAttrib...
		dispatch(pageActions.persistColumns(updatedFields, compType));
	};

	const removeColumn = (id: number | string) => {
		if (!fields) {
			return;
		}

		const updatedFields = fields
			.filter((field) => field.id !== id)
			.map((field) => {
				return { compAttributeId: field.id, width: field.width };
			});

		// @ts-expect-error TS2345: Argument of type '{ compAttrib...
		dispatch(pageActions.persistColumns(updatedFields, compType));
	};

	return (
		<TableSettingsMenuView
			compType={compType}
			resetColumns={resetColumns}
			addColumn={addColumn}
			removeColumn={removeColumn}
			sectionedAttrs={sectionedAttrs}
			searchTerm={searchTerm}
			element={element}
		/>
	);
};

export const tableSettingsMenuSearchInputTestId =
	'tableSettingsMenuSearchInputTestId';

Menu.addComponent('table-settings-menu', TableSettingsMenu);
