import { ComponentProps } from 'react';
import LogoRealPage from 'ui/svg_icons/realpage.svg';
import { PoweredByContainer, PoweredByText } from './UI';

export const RealPage = (props: ComponentProps<'div'>) => {
	return (
		<PoweredByContainer
			{...props}
			style={{ justifyContent: 'flex-end', ...props.style }}
		>
			<PoweredByText>Powered by</PoweredByText>
			<LogoRealPage height={10} />
		</PoweredByContainer>
	);
};
