import { LOADING_ROW_TYPE } from './constants';

export const createLoadingRows = (numOfRows: number): any[] => {
	return Array(numOfRows)
		.fill(0)
		.map(() => ({
			id: Math.random().toString(36).substring(2, 8),
			type: LOADING_ROW_TYPE,
		}));
};
