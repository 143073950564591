import { useMemo, useState } from 'react';
import { Portfolio } from 'api';
import constate from 'constate';
import { QUnitType } from 'dayjs';

type Props = {
	portfolio: Portfolio;
};

export type Criterion = 'allLeases' | 'recentlyUpdated' | 'newOnCompstak';

export type CriterionDuration = {
	value: number;
	unit: QUnitType;
};

export type PortfolioLeasesFilters = {
	activeLeasesOnly: boolean;
	filterBy: { criterion: Criterion; duration: CriterionDuration } | null;
};

type UsePortfolioLeasesFilters = {
	filters: PortfolioLeasesFilters;
	setFilters: React.Dispatch<React.SetStateAction<PortfolioLeasesFilters>>;
	portfolio: Portfolio;
};

export const INITIAL_CRITERION_DURATION: CriterionDuration = {
	value: 90,
	unit: 'days',
};

const INITIAL_PORTFOLIO_LEASES_FILTERS: PortfolioLeasesFilters = {
	activeLeasesOnly: true,
	filterBy: {
		criterion: 'allLeases',
		duration: INITIAL_CRITERION_DURATION,
	},
};

export const [PortfolioLeasesFiltersProvider, usePortfolioLeasesFilters] =
	constate(({ portfolio }: Props): UsePortfolioLeasesFilters => {
		const [filters, setFilters] = useState<PortfolioLeasesFilters>(
			INITIAL_PORTFOLIO_LEASES_FILTERS
		);

		return useMemo(
			() => ({ filters, setFilters, portfolio }),
			[filters, setFilters, portfolio]
		);
	});
