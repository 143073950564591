import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { emailConstants } from '@compstak/common';
import { ContactUsParagraph } from 'Components/Modals/UpgradeModal/ContactUsParagraph';
import { AuthStateType } from 'Pages/Login/reducers';
import { requestDemo } from 'actions/user';
import { routes } from 'router';
import { TrialExpiredHeader } from 'Pages/TrialExpired/TrialExpiredHeader';
import styled from 'styled-components';
import { Button } from '@compstak/ui-kit';

type TrialExpiredProps = {
	authState: AuthStateType;
};

export const TrialExpired = ({ authState }: TrialExpiredProps) => {
	const [requestDemoSent, setRequestDemoSent] = useState(false);
	const dispatch = useDispatch();

	const requestDemoHandler = () => {
		setRequestDemoSent(true);
		if (authState.usernameUsedToSignIn) {
			dispatch(
				requestDemo(authState.usernameUsedToSignIn, 'trial expired', null)
			);
		}
	};

	return (
		<>
			<TrialExpiredHeader />
			<Page>
				<Content>
					<h2>Your free trial has ended</h2>
					<p>But your access to lease comps and analytics doesn`t have to!</p>

					<Button
						variant="primary3"
						disabled={requestDemoSent}
						onClick={requestDemoHandler}
					>
						{requestDemoSent
							? 'We will contact you shortly'
							: 'Extend My Access'}
					</Button>

					<Button variant="ghost">
						<a href={routes.upgrade.path}>What do subscribers get?</a>
					</Button>

					<ContactUsParagraph contactEmail={emailConstants.clientSuccess} />
				</Content>
			</Page>
		</>
	);
};

const Page = styled.div`
	display: flex;
	align-items: start;
	justify-content: center;
	height: 100%;
	padding-top: 10em;
	background-color: ${({ theme }) => theme.colors.gray.gray100};
	background-image: url('images/fake-app-bg.png');
	background-repeat: no-repeat;
	background-position: left -64px;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	background-color: ${({ theme }) => theme.colors.white.white};
	padding: 4em 2em;
	border-radius: 5px;

	p {
		color: ${({ theme }) => theme.colors.gray.n70};
	}

	h2 {
		font-weight: 400;
	}

	button {
		margin-top: 2em;
	}
`;
