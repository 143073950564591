import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'occupancy' as const;

export const CurrentOccupancyRateFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Between
					monthly={false}
					thousandSeparator={false}
					strictPercentFromFloat={true}
					{...props}
				/>
			)}
		</FilterBase>
	);
};
