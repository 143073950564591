import { Flex } from '@compstak/ui-kit';
import { LinkishButton } from 'Components';
import styled from 'styled-components';
import { useSearchMapState } from '../SearchMapProvider';

type Props = {
	onClickSettings: () => void;
};

export const MapAnalyticsActions = ({ onClickSettings }: Props) => {
	const [searchMapState, setSearchMapState] = useSearchMapState();

	return (
		<StyledFlex>
			<LinkishButton
				onClick={onClickSettings}
				data-qa-id="analytics-settings-btn"
			>
				Settings
			</LinkishButton>
			{searchMapState.isExporting ? (
				<span>Loading…</span>
			) : (
				<LinkishButton
					onClick={() => {
						setSearchMapState((s) => ({ ...s, isExporting: true }));
					}}
					data-qa-id="analytics-save-image-btn"
				>
					Save Image
				</LinkishButton>
			)}
		</StyledFlex>
	);
};

const StyledFlex = styled(Flex)`
	justify-content: flex-start;
	gap: 20px;
`;
