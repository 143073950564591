/*
 * This file must contain only constants which conflict with the main ui-kit theme
 * Please, before adding new value here: check @compstak/ui-kit/theme
 * Maybe, you can apply some similar color from there or consult with a designer about the solution
 */
import { LayerColor } from '@compstak/maps';
import { defaultTheme } from '@compstak/ui-kit';

export const Buttons = {
	whiteGhost: {
		background: defaultTheme.colors.white.white,
		hover: 'rgba(255, 255, 255, 0.9)',
		active: 'rgba(255, 255, 255, 0.8)',
	},
};

export const WHITE_RGBA_COLOR: LayerColor = [255, 255, 255];

export const MAP_FILTER_RED_COLOR: LayerColor = [255, 100, 100];

export const MAP_FILTER_RED_HALF_OPAQUE_COLOR: LayerColor = [
	MAP_FILTER_RED_COLOR[0],
	MAP_FILTER_RED_COLOR[1],
	MAP_FILTER_RED_COLOR[2],
	50,
];

export const SUBMARKET_FILTER_FILL_COLOR: LayerColor =
	MAP_FILTER_RED_HALF_OPAQUE_COLOR;

export const SUBMARKET_FILTER_LINE_COLOR: LayerColor = MAP_FILTER_RED_COLOR;

export const OPPORTUNITY_ZONES_FILTER_FILL_COLOR: LayerColor =
	MAP_FILTER_RED_HALF_OPAQUE_COLOR;

export const OPPORTUNITY_ZONES_FILTER_LINE_COLOR: LayerColor =
	MAP_FILTER_RED_COLOR;

export const OPPORTUNITY_ZONES_FILTER_HIGHLIGHT_COLOR: LayerColor =
	MAP_FILTER_RED_HALF_OPAQUE_COLOR;

export const TENANT_INDUSTRY_COLORS = {
	Apparel: [49, 19, 255],
	'Automobiles & Components': [65, 0, 39],
	Banks: [30, 46, 0],
	'Capital Goods': [0, 117, 174],
	'Commercial & Professional Service': [0, 110, 255],
	'Consumer Durables': [153, 0, 49],
	Education: [255, 86, 48],
	Energy: [0, 0, 0],
	'Financial Services': [0, 86, 49],
	'Food & Beverage': [3, 69, 153],
	'Health Care Equipment & Services': [170, 71, 53],
	Insurance: [96, 0, 0],
	'Legal Services': [170, 10, 0],
	'Leisure & Restaurants': [0, 255, 255],
	Materials: [100, 51, 51],
	Media: [255, 0, 146],
	'Non-Profit': [45, 166, 65],
	'Pharmaceuticals, Biotechnology & Life Sciences': [0, 135, 147],
	'Public Institutions': [187, 122, 0],
	'Real Estate': [0, 64, 75],
	Retail: [0, 148, 255],
	'Software & Information': [118, 58, 170],
	'Technology Hardware & Equipment': [147, 127, 231],
	'Telecommunication Services': [0, 255, 190],
	'Transportation, Warehousing & Storage': [88, 119, 96],
	Utilities: [8, 3, 94],
	Other: [98, 93, 119],
} as const;

export type TenantIndustryColorKey = keyof typeof TENANT_INDUSTRY_COLORS;

export type TenantIndustryColor =
	(typeof TENANT_INDUSTRY_COLORS)[TenantIndustryColorKey];

export const SURVEY_FOR_CREDITS_COLORS = {
	goToSurveyBanner: '#262931',
} as const;
