import React, { useCallback } from 'react';
import { createFilters } from 'models/filters';
import { Chart, DataSetType } from 'Pages/Analytics/analytics';
import { CreateNewDataSetForm } from './CreateNewDataSetV2';
import { useMarkets } from 'hooks/useMarkets';
import { useDispatch } from 'react-redux';
import { addDataSet } from 'Pages/Analytics/Builder/actions';
import { Market } from '@compstak/common';
import { PropertyTypeId, SpaceTypeId } from 'api';
import { useModal } from 'providers/ModalProvider';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

type Props = {
	chartDraft: Chart;
	onClose: () => void;
};

export const CreateNewDataSetModal = ({ chartDraft, onClose }: Props) => {
	const markets = useMarkets();

	const { closeModal } = useModal();

	const dispatch = useDispatch();

	const handleSubmit = useCallback(
		(
			name: string,
			market: Market,
			spacePropertyType: number[],
			dataSetType: DataSetType,
			series?: AttributeToPlotType
		) => {
			const dataSetFilters = (() => {
				if (dataSetType === DataSetType.MUFA) {
					return createFilters('mufa', {
						market,
					});
				}

				if (dataSetType === DataSetType.SALES) {
					return createFilters('sale', {
						market,
						buildingPropertyTypeId: spacePropertyType as PropertyTypeId[],
					});
				}

				return createFilters('lease', {
					market,
					// The "Other" spacetype has two ids, one for "Other" and one for "Unknown"
					// All other spacetypes will only have one id
					spaceTypeId: spacePropertyType as SpaceTypeId[],
				});
			})();

			dispatch(
				addDataSet(chartDraft, name, dataSetFilters, markets, series, {
					dataSetType,
				})
			);
			closeModal();
			onClose();
		},
		[chartDraft, closeModal, dispatch, markets, onClose]
	);

	return (
		<CreateNewDataSetForm
			chartDraft={chartDraft}
			onSubmit={handleSubmit}
			onBack={closeModal}
		/>
	);
};
