import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FilterCategory } from 'Pages/Search/Sidebar/Components/FilterCategories';
import { SearchWithinViewMapFilter } from './SearchWithinViewMapFilter';
import { useSearch } from 'Pages/Search/searchReducer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { SEARCH_BY_VIEW_ROUTES } from 'router';
import { filtersToQueryString } from 'models/filters/util';
import { DISABLE_FILTERS_RESET } from 'middleware/filtersFromRoutes';
import { RadiusMapFilter } from './RadiusMapFilter';
import { PolygonMapFilter } from './PolygonMapFilter';
import { SubmarketsMapFilter } from './SubmarketsMapFilter';
import { OpportunityZonesMapFilter } from './OpportunityZonesMapFilter';

type MapFiltersProps = {
	params: SearchLayoutRouteParams;
};

export const MapFilters = ({ params }: MapFiltersProps) => {
	const { compType, filters } = useFilterFieldContext();
	const { activeMapControl } = useSearch();
	const navigate = useNavigate();

	// navigate to map view if map filter is on and user is in the list view
	useEffect(() => {
		if (activeMapControl && params.view === 'list') {
			const queryString = filtersToQueryString(filters);
			navigate(
				`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: 'map' })}?${queryString}`,
				{ replace: true, state: { [DISABLE_FILTERS_RESET]: true } }
			);
		}
	}, [activeMapControl, params.view]);

	return (
		<FilterCategory category="Map Filters">
			<SearchWithinViewMapFilter />
			<RadiusMapFilter />
			<PolygonMapFilter />
			<SubmarketsMapFilter />
			<OpportunityZonesMapFilter />
		</FilterCategory>
	);
};
