import {
	SavedSearch,
	SAVED_SEARCH_TYPE_TO_COMP_TYPE,
} from 'reducers/savedSearches';
import { filtersFromServerJSON } from 'models/filters/util';
import {
	useIsSendToAnalyticsBtnAllowed,
	useSendToAnalyticsBtn,
} from 'hooks/chartBuilderHooks';
import { MarketsState } from 'Pages/Login/reducers';

type SavedSearchItemAnalyticButtonProps = {
	savedSearch: SavedSearch;
	markets: MarketsState;
};

export const SavedSearchItemAnalyticButton = ({
	savedSearch,
	markets,
}: SavedSearchItemAnalyticButtonProps) => {
	const searchType = savedSearch.searchType;
	const filters = filtersFromServerJSON(
		searchType,
		markets,
		savedSearch.filters
	);
	const compType = SAVED_SEARCH_TYPE_TO_COMP_TYPE[savedSearch.searchType];

	const isSendToAnalyticsBtnAllowed = useIsSendToAnalyticsBtnAllowed(compType);
	const { href } = useSendToAnalyticsBtn({
		filters,
		compType,
		title: savedSearch.name,
	});

	if (!isSendToAnalyticsBtnAllowed || !savedSearch) {
		return <></>;
	}

	return (
		<div className="edit-saved-search">
			<a data-qa-id="send-to-chart-builder" href={href} className="link edit">
				Send to Chartbuilder
			</a>
		</div>
	);
};
