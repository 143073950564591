import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	gap: 12px;
`;

export const Styled = {
	Container,
};
