import { Chart, DataSet, Project } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import { Dispatch } from 'redux';
import repoService from 'services/analyticsRepo';
import { addFeedback } from 'Singletons/Feedback/actions';
import { AppDispatch, AppGetState } from 'store';
import {
	chartToServerChart,
	deleteChartDraftAndAddDataSets,
	serverChartToChart,
	updateChartDraft,
} from '../Builder/actions';
import { DEFAULT_NEW_CHART_TITLE } from '../Builder/chartBuilderConstants';
import { DRAFTS_PROJECT_NAME } from '../config';

export const START_LOADING_CHART_REPO = 'START_LOADING_CHART_REPO';
export const SET_CHART_REPO = 'SET_CHART_REPO';
export const CHART_REPO_LOAD_ERROR = 'CHART_REPO_LOAD_ERROR';

export const SAVE_CHART = 'SAVE_CHART';
export const CREATE_CHART = 'CREATE_CHART';
export const START_SAVING_CHART = 'START_SAVING_CHART';
export const DELETE_CHART = 'DELETE_CHART';
export const UPDATE_CHART_ERROR = 'UPDATE_CHART_ERROR';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const DELETE_PROJECT = 'DELETE PROJECT';
export const SEND_CHART = 'SEND CHART';
export const SEND_PROJECT = 'SEND PROJECT';

type StartLoadingChartRepoAction = { type: typeof START_LOADING_CHART_REPO };
type SetChartRepoAction = { type: typeof SET_CHART_REPO; payload: Project[] };
type ChartRepoLoadErrorAction = {
	type: typeof CHART_REPO_LOAD_ERROR;
	payload: any;
};
type UpdateProjectAction = { type: typeof UPDATE_PROJECT; payload: Project };
type CreateProjectAction = { type: typeof CREATE_PROJECT; payload: Project };
type SaveChartAction = { type: typeof SAVE_CHART; payload: Chart };
type CreateChartAction = { type: typeof CREATE_CHART; payload: Chart };
type UpdateChartErrorAction = { type: typeof UPDATE_CHART_ERROR; payload: any };
type DeleteProjectAction = { type: typeof DELETE_PROJECT; project: Project };
type DeleteChartAction = { type: typeof DELETE_CHART; chartId: number };
type SendChartAction = { type: typeof SEND_CHART };
type SendProjectAction = { type: typeof SEND_PROJECT };

export const loadProjects =
	(markets: MarketsState) =>
	(dispatch: Dispatch<SetChartRepoAction | ChartRepoLoadErrorAction>) => {
		dispatch({
			// @ts-expect-error ts-migrate(2322) FIXME: Type '"START_LOADING_CHART_REPO"' is not assignabl... Remove this comment to see the full error message
			type: START_LOADING_CHART_REPO,
		});
		repoService.loadAllProjects().then(
			// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Project[... Remove this comment to see the full error message
			(repo: Project[]) => {
				const formattedRepo = repo.map((project: Project) => {
					return {
						...project,
						charts: project.charts.map((chart) =>
							// @ts-expect-error TS2345: Argument of type 'Chart' is no...
							serverChartToChart(markets, chart)
						),
					};
				});
				dispatch({
					type: SET_CHART_REPO,
					payload: formattedRepo,
				});
			},
			(error: any) => {
				dispatch({
					type: CHART_REPO_LOAD_ERROR,
					payload: error,
				});
			}
		);
	};

export type ProjectDTO = Pick<Project, 'id' | 'name' | 'canDelete' | 'charts'>;

export const saveProject =
	(
		project: ProjectDTO,
		markets: MarketsState,
		indexOfChartCurrentlyInChartBuilder: number | null = null
	) =>
	(dispatch: Dispatch<UpdateProjectAction | CreateProjectAction>) => {
		const actionType = project.id ? UPDATE_PROJECT : CREATE_PROJECT;
		const formattedCharts = project.charts.map((chart) =>
			chartToServerChart(chart)
		);
		const updatedProject = {
			...project,
			charts: formattedCharts,
		};
		// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Project'... Remove this comment to see the full error message
		repoService.updateProject(updatedProject).then((savedProject: Project) => {
			const reformattedCharts = savedProject.charts.map((chart) =>
				// @ts-expect-error TS2345: Argument of type 'Chart' is no...
				serverChartToChart(markets, chart)
			);
			dispatch({
				type: actionType,
				payload: {
					...savedProject,
					charts: reformattedCharts,
				},
			});
			if (indexOfChartCurrentlyInChartBuilder !== null) {
				const newChart = reformattedCharts[indexOfChartCurrentlyInChartBuilder];
				const newBuilder = {
					chartType: newChart.chartType,
					dataSets: newChart.dataSets,
					originalChartId: newChart.id,
					timespan: newChart.timespan,
					title: newChart.title,
					trendMonths: newChart.trendMonths,
				};
				// @ts-expect-error ts-migrate(2345) FIXME: Type '(dispatch: any) => void' is missing the foll... Remove this comment to see the full error message
				dispatch(updateChartDraft(newBuilder, markets));
			}
		});
	};

export const saveChart =
	(chart: Chart, markets: MarketsState) =>
	(
		dispatch: Dispatch<
			SaveChartAction | CreateChartAction | UpdateChartErrorAction
		>
	) => {
		const formattedChart = chartToServerChart(chart);
		const actionType = chart.id ? SAVE_CHART : CREATE_CHART;
		repoService.updateChart(formattedChart).then(
			// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Chart'.
			(updatedChart: Chart) => {
				dispatch({
					type: actionType,
					// @ts-expect-error TS2345: Argument of type 'Chart' is no...
					payload: serverChartToChart(markets, updatedChart),
				});
			},
			(error: any) => {
				dispatch({
					type: UPDATE_CHART_ERROR,
					payload: error,
				});
			}
		);
	};

export const saveChartAndUpdateBuilder =
	(chart: Chart, markets: MarketsState) =>
	(
		dispatch: Dispatch<
			SaveChartAction | CreateChartAction | UpdateChartErrorAction
		>
	) => {
		const formattedChart = chartToServerChart(chart);
		const actionType = chart.id ? SAVE_CHART : CREATE_CHART;
		repoService.updateChart(formattedChart).then(
			// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Chart'.
			(updatedChart: Chart) => {
				dispatch({
					type: actionType,
					// @ts-expect-error TS2345: Argument of type 'Chart' is no...
					payload: serverChartToChart(markets, updatedChart),
				});
				updateChartDraft(
					{ ...chart, originalChartId: updatedChart.id },
					markets
				)(dispatch);
			},
			(error: any) => {
				dispatch({
					type: UPDATE_CHART_ERROR,
					payload: error,
				});
			}
		);
	};

export const createNewProjectWithChart =
	(projectName: string, chart: Chart, markets: MarketsState) =>
	(dispatch: Dispatch<CreateProjectAction>) => {
		const newProject = {
			name: projectName,
			charts: [],
			canDelete: true,
		};
		// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Project'... Remove this comment to see the full error message
		repoService.updateProject(newProject).then((savedProject: Project) => {
			dispatch({
				type: CREATE_PROJECT,
				payload: savedProject,
			});
			const chartWithProjectId = {
				...chart,
				projectId: savedProject.id,
			};
			saveChartAndUpdateBuilder(chartWithProjectId, markets)(dispatch);
		});
	};

export const deleteProject =
	(projectId: number) => (dispatch: Dispatch<DeleteProjectAction>) => {
		// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Project'... Remove this comment to see the full error message
		repoService.deleteProject(projectId).then((project: Project) => {
			dispatch({
				type: DELETE_PROJECT,
				project,
			});
		});
	};

export const deleteChart =
	(chartId: number) => (dispatch: Dispatch<DeleteChartAction>) => {
		repoService.deleteChart(chartId).then(() => {
			dispatch({
				type: DELETE_CHART,
				chartId,
			});
		});
	};

export const sendChart =
	(chartId: number, email: string) => (dispatch: Dispatch<SendChartAction>) => {
		repoService.sendChart(chartId, email).then(
			() => {
				// @ts-expect-error ts-migrate(2345) FIXME: Type 'string' is not assignable to type '"SEND CHA... Remove this comment to see the full error message
				dispatch(addFeedback('Chart shared successfully'));
				dispatch({
					type: SEND_CHART,
				});
			},
			() => {
				dispatch(
					// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; payload: { messa... Remove this comment to see the full error message
					addFeedback('Sharing chart unsuccessful', 'error', null, 5000)
				);
			}
		);
	};

export const sendProject =
	(projectId: number, email: string) =>
	(dispatch: Dispatch<SendProjectAction>) => {
		repoService.sendProject(projectId, email).then(
			() => {
				// @ts-expect-error ts-migrate(2345) FIXME: Type 'string' is not assignable to type '"SEND PRO... Remove this comment to see the full error message
				dispatch(addFeedback('Project shared successfully'));
				dispatch({
					type: SEND_PROJECT,
				});
			},
			() => {
				dispatch(
					// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; payload: { messa... Remove this comment to see the full error message
					addFeedback('Sharing project unsuccessful', 'error', null, 5000)
				);
			}
		);
	};

export const saveChartDraftAndNewDataSets =
	(dataSets: DataSet[]) =>
	async (dispatch: AppDispatch, getState: AppGetState) => {
		const draftsProject = getState().analyticsProjects.projects?.find(
			(p) => p.name === DRAFTS_PROJECT_NAME
		);

		const numbeOfChartsInDrafts = draftsProject?.charts.length ?? 0;

		const chartDraft = getState().chartBuilder.chartDraft;

		if (!chartDraft) return;

		const title =
			chartDraft.title === DEFAULT_NEW_CHART_TITLE
				? `Draft ${numbeOfChartsInDrafts + 1}`
				: chartDraft.title;

		const chartToSave = {
			...chartDraft,
			title,
		};
		const updatedDraftsProject = {
			name: DRAFTS_PROJECT_NAME,
			charts: [chartToSave],
			canDelete: true,
		};
		dispatch(saveProject(updatedDraftsProject, getState().markets));
		dispatch(deleteChartDraftAndAddDataSets(dataSets, getState().markets));
	};

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
export const renameChartError = () => (dispatch) => {
	dispatch(
		addFeedback('A chart with this name already exists', 'error', null, 5000)
	);
};

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
export const nonameChartError = () => (dispatch) => {
	dispatch(
		addFeedback('Please provide a name for the chart', 'error', null, 5000)
	);
};

export type ChartRepositoryAction =
	| StartLoadingChartRepoAction
	| SetChartRepoAction
	| ChartRepoLoadErrorAction
	| UpdateProjectAction
	| CreateProjectAction
	| SaveChartAction
	| CreateChartAction
	| UpdateChartErrorAction
	| DeleteProjectAction
	| DeleteChartAction
	| SendChartAction
	| SendProjectAction;

export const analyticsProjectActions = {
	loadProjects,
	saveProject,
	saveChart,
	saveChartAndUpdateBuilder,
	createNewProjectWithChart,
	deleteProject,
	deleteChart,
	sendChart,
	sendProject,
	renameChartError,
	nonameChartError,
};

export type AnalyticsProjectActions = typeof analyticsProjectActions;
