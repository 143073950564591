import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { menuActions } from 'Singletons/Menu/actions';

// TODO: Review where this userActions is used in chartbuilder,
// and replace it with loginActions.
import { loginActions as userActions } from 'Pages/Login/actions';
import { feedbackActions } from 'Singletons/Feedback/actions';

import withChartBuilder from './withChartBuilder';

import InsightsComponent from './Component';

import { DEFAULT_DATASET_COLORS } from './colors';
import { AppState } from 'reducers/root';
import { replace } from 'router';

function mapStoreToProps(store: AppState) {
	return {
		referenceData: store.referenceData,
		user: store.user,
		permissions: store.permissions,
		selection: store.selection,
		colors: DEFAULT_DATASET_COLORS,
		search: store.searchReducer,
		featureFlags: store.featureFlags,
		filters: store.filtersV2.main,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		feedbackActions: bindActionCreators(feedbackActions, dispatch),
		menuActions: bindActionCreators(menuActions, dispatch),
		userActions: bindActionCreators(userActions, dispatch),
		replace: (url: string) => replace(url),
	};
}

export const connector = (Component: React.ComponentType<any | string>) =>
	connect(mapStoreToProps, mapDispatchToProps)(withChartBuilder(Component));

export default connector(InsightsComponent);
