import styled from 'styled-components';

export const UfProgressBar = ({ percent }: { percent: number }) => {
	// https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl
	return (
		<StyledOutline>
			<StyledInside percent={percent} />
		</StyledOutline>
	);
};

const StyledOutline = styled.div`
	height: 20px;
	width: 100%;
	border-radius: 8px;
	/* Using border instead of outline leads to tiny pixel gaps */
	outline: 1px solid ${(props) => props.theme.colors.green.green500};
`;

const StyledInside = styled.div<{ percent: number }>`
	height: 100%;
	width: clamp(0%, ${(props) => props?.percent ?? 0}%, 100%);
	border-radius: inherit;
	background-color: ${(props) => props.theme.colors.green.green500};
	text-align: right;
	transition: width 1s ease-in-out;
`;
