import { Button } from '@compstak/ui-kit';
import { showUploadForm } from '../Upload/actions';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Link, routes } from 'router';
import IconUpload from 'ui/svg_icons/upload2.svg';

type Props = {
	children: ReactNode;
	section: 'lease' | 'sale';
};

export const UploadCompButton = ({ children, section }: Props) => {
	const dispatch = useDispatch();

	return (
		<Link to={routes.uploadSection.toHref({ section })}>
			<Button
				variant="ghost"
				icon={<IconUpload />}
				size="l"
				onClick={() => dispatch(showUploadForm())}
			>
				{children}
			</Button>
		</Link>
	);
};
