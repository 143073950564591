import { Spinner } from '@compstak/ui-kit';
import { usePropertyLeasesSummaryQuery } from 'api/propertyLeases/usePropertyLeasesSummaryQuery';
import {
	SurveyQuestion,
	useAnswerQuestionMutation,
} from 'api/surveyForCredits/surveyQuestionQueries';
import { useState } from 'react';
import { z } from 'zod';
import { SingleQuestionFailed } from '../questionComponents';
import {
	QuestionSidebarBtns,
	RadioBtn,
	RadioBtns,
} from '../questionSidebarComponents';
import { SurveyPropertyAddress } from './components/PropertyAddress';
import { PropertyDetailsKvTable } from './components/PropertyDetailsKvTable';
import { ResponsiveMapSwitcher } from './components/ResponsiveMapSwitcher';
import { QuestionContentContainers1 } from './components/contentContainers';
import { MajorTenantsOfPropertyInQuestion } from './components/majorTenantsKvTable';
import { SurveyInput } from './components/surveyInputs';
import { useExpectedLoadTimePassedForProperty } from './utils/loadTimePassed';
import { useGetQuestionParams } from './utils/useGetQuestionParams';
import { PropertySubtypeName, usePropertyByIdQuery } from 'api';

const OPTIONS: Array<PropertySubtypeName> = [
	'Business Park',
	'Communication/Data Center',
	'Creative',
	'Flex/R&D',
	'Heavy Industrial',
	'Industrial Outdoor Storage',
	'Life Science/Lab',
	'Light Industrial',
	'Manufacturing',
	'Processing',
	'Refrigerated/Cold Storage',
	'Self-Storage',
	'Showroom',
	'Special Industrial',
	'Special Purpose',
	'Warehouse/Distribution',
];

export function PropertySubtypeQuestionSidebar({
	question,
}: {
	question: SurveyQuestion;
}) {
	const { subjectPropertyId: propertyId } = contentValidator.parse(
		question.content
	);
	const [selected, setSelected] = useState('');
	const [noneOfTheAboveActive, setNoneOfTheAboveActive] = useState(false);

	const {
		mutateAsync: answer,
		isLoading: answerPending,
		isError: answerFailed,
	} = useAnswerQuestionMutation();

	const {
		data,
		isLoading: isLoadingProperty,
		isError: propertyFetchFailed,
	} = usePropertyByIdQuery({ id: propertyId });

	const isLoadingLongerThanExpected =
		useExpectedLoadTimePassedForProperty(propertyId) && isLoadingProperty;

	const questionParams = useGetQuestionParams(question);

	const propertyContent = {
		subjectProperty: {
			propertyId,
			address: data?.buildingAddressAndCity ?? data?.buildingAddress,
			city: data?.city,
			latitude: data?.geoPoint?.lat,
			longitude: data?.geoPoint?.lon,
			marketStartingRent: data?.propertyMarketStartingRent,
			yearBuilt: data?.buildingYear,
			yearRenovated: data?.buildingYearRenovated,
			floors: data?.buildingFloorsCount,
			size: data?.buildingSize,
		},
	};

	return (
		<>
			<div>
				<div>
					<b>Question</b>
				</div>
				<div>What is the Subtype for this property?</div>
			</div>
			<RadioBtns>
				{OPTIONS.map((option) => (
					<RadioBtn
						key={option}
						onChange={(val) => {
							setNoneOfTheAboveActive(false);
							setSelected(val);
						}}
						label={option}
					/>
				))}
				<RadioBtn
					key={'Other'}
					onChange={() => {
						setNoneOfTheAboveActive(true);
						setSelected('');
					}}
					label={'None of the above'}
				/>
			</RadioBtns>
			{noneOfTheAboveActive && (
				<SurveyInput
					value={selected}
					onChange={(val) => setSelected(val)}
					placeholder="Type an answer here to receive credits"
					data-qa-id="other-subtype-text-input"
				/>
			)}
			<QuestionSidebarBtns
				onSubmit={() =>
					answer({
						...questionParams,
						content: {
							...propertyContent,
							selectedPropertySubtype: selected.trim(),
						},
					})
				}
				onSkip={() => {
					const shouldAbandon =
						propertyFetchFailed || isLoadingLongerThanExpected;
					answer({
						...questionParams,
						...(shouldAbandon
							? { abandon: true }
							: {
									skip: true,
									content: propertyContent,
								}),
					});
				}}
				submitEnabled={
					!!selected.trim() && !isLoadingProperty && !propertyFetchFailed
				}
				skipDisabled={isLoadingProperty && !isLoadingLongerThanExpected}
				submitting={answerPending}
				submitFailed={answerFailed}
			/>
		</>
	);
}

const contentValidator = z.object({ subjectPropertyId: z.number() });

export function PropertySubtypeQuestionContent({
	question,
}: {
	question: SurveyQuestion;
}) {
	const { subjectPropertyId: propertyId } = contentValidator.parse(
		question.content
	);

	const {
		data: property,
		isLoading: isLoadingProperty,
		isError: propertyFetchFailed,
	} = usePropertyByIdQuery({ id: propertyId });
	const geoPoint = property?.geoPoint;

	const { data: propertySummary, isLoading: isLoadingPropertySummary } =
		usePropertyLeasesSummaryQuery(propertyId);

	const tenantNames = propertySummary?.tenants?.map((t) => t.name)?.join(', ');

	return (
		<>
			{isLoadingProperty || isLoadingPropertySummary ? (
				<Spinner isCentered />
			) : propertyFetchFailed ? (
				<SingleQuestionFailed />
			) : (
				<MainContainer>
					<LeftSideContainer>
						<SurveyPropertyAddress {...{ property }} />
						<PropertyDetailsKvTable {...{ property }} hideFloors />
						{tenantNames && (
							<MajorTenantsOfPropertyInQuestion {...{ tenantNames }} />
						)}
					</LeftSideContainer>

					<RightSideContainer>
						{geoPoint ? (
							<ResponsiveMapSwitcher {...{ geoPoint }} />
						) : (
							<div>No google maps for this property</div>
						)}
					</RightSideContainer>
				</MainContainer>
			)}
		</>
	);
}

const { MainContainer, LeftSideContainer, RightSideContainer } =
	QuestionContentContainers1;
