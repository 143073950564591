import { Spinner } from '@compstak/ui-kit';
import {
	SurveyQuestion,
	useAnswerQuestionMutation,
} from 'api/surveyForCredits/surveyQuestionQueries';
import { useState } from 'react';
import { z } from 'zod';
import { SingleQuestionFailed } from '../questionComponents';
import {
	QuestionSidebarBtns,
	RadioBtn,
	RadioBtns,
} from '../questionSidebarComponents';
import { SurveyPropertyAddress } from './components/PropertyAddress';
import { PropertyDetailsKvTable } from './components/PropertyDetailsKvTable';
import { ResponsiveMapSwitcher } from './components/ResponsiveMapSwitcher';
import { QuestionContentContainers1 } from './components/contentContainers';
import { useExpectedLoadTimePassedForProperty } from './utils/loadTimePassed';
import { useGetQuestionParams } from './utils/useGetQuestionParams';
import { usePropertyByIdQuery } from 'api';

const OPTIONS = ['Class A', 'Class B', 'Class C'] as const;

export function BuildingClassQuestionSidebar({
	question,
}: {
	question: SurveyQuestion;
}) {
	const { subjectPropertyId: propertyId } = contentValidator.parse(
		question.content
	);
	const [selected, setSelected] = useState('');

	const {
		mutateAsync: answer,
		isLoading: answerPending,
		isError: answerFailed,
	} = useAnswerQuestionMutation();

	const {
		data,
		isLoading: isLoadingProperty,
		isError: propertyFetchFailed,
	} = usePropertyByIdQuery({ id: propertyId });

	const isLoadingLongerThanExpected =
		useExpectedLoadTimePassedForProperty(propertyId) && isLoadingProperty;

	const questionParams = useGetQuestionParams(question);

	const propertyContent = {
		subjectProperty: {
			propertyId,
			address: data?.buildingAddressAndCity ?? data?.buildingAddress,
			city: data?.city,
			latitude: data?.geoPoint?.lat,
			longitude: data?.geoPoint?.lon,
			marketStartingRent: data?.propertyMarketStartingRent,
			yearBuilt: data?.buildingYear,
			yearRenovated: data?.buildingYearRenovated,
			floors: data?.buildingFloorsCount,
			size: data?.buildingSize,
		},
	};

	return (
		<>
			<div>
				<div>
					<b>Question</b>
				</div>
				<div>What class is this building?</div>
			</div>
			<RadioBtns>
				{OPTIONS.map((option) => (
					<RadioBtn key={option} onChange={setSelected} label={option} />
				))}
			</RadioBtns>
			<QuestionSidebarBtns
				onSubmit={() =>
					answer({
						...questionParams,
						content: {
							...propertyContent,
							selectedBuildingClass: selected,
						},
					})
				}
				onSkip={() => {
					const shouldAbandon =
						propertyFetchFailed || isLoadingLongerThanExpected;
					answer({
						...questionParams,
						...(shouldAbandon
							? { abandon: true }
							: {
									skip: true,
									content: propertyContent,
								}),
					});
				}}
				submitEnabled={!!selected && !isLoadingProperty && !propertyFetchFailed}
				skipDisabled={isLoadingProperty && !isLoadingLongerThanExpected}
				submitting={answerPending}
				submitFailed={answerFailed}
			/>
		</>
	);
}

const contentValidator = z.object({ subjectPropertyId: z.number() });

export function BuildingClassQuestionContent({
	question,
}: {
	question: SurveyQuestion;
}) {
	const { subjectPropertyId: propertyId } = contentValidator.parse(
		question.content
	);

	const {
		data: property,
		isLoading: isLoadingProperty,
		isError: propertyFetchFailed,
	} = usePropertyByIdQuery({ id: propertyId });
	const geoPoint = property?.geoPoint;

	return (
		<>
			{isLoadingProperty ? (
				<Spinner isCentered />
			) : propertyFetchFailed ? (
				<SingleQuestionFailed />
			) : (
				<MainContainer>
					<LeftSideContainer>
						<SurveyPropertyAddress {...{ property }} />
						<PropertyDetailsKvTable {...{ property }} />
					</LeftSideContainer>

					<RightSideContainer>
						{geoPoint ? (
							<ResponsiveMapSwitcher {...{ geoPoint }} />
						) : (
							<div>No google maps for this property</div>
						)}
					</RightSideContainer>
				</MainContainer>
			)}
		</>
	);
}

const { MainContainer, LeftSideContainer, RightSideContainer } =
	QuestionContentContainers1;
