import {
	AngleArrow,
	DataProviderLogoBase,
	RowFlexContainer,
} from '@compstak/ui-kit';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { BetaChip } from 'Components/UI';
import { useIsExchange } from 'hooks';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export type Category =
	| 'Location'
	| 'Space & Lease'
	| 'Property'
	| 'Tenant'
	| 'Building'
	| 'Tenant & Landlord'
	| 'Comp'
	| 'Transaction'
	| 'Buyer & Seller'
	| 'Loan Info'
	| 'Multifamily'
	| 'Commonly Used Filters'
	| 'Map Filters';

type Props = {
	category: Category;
	children: React.ReactNode;
};
export const FilterCategory = ({ category, children }: Props) => {
	const {
		compType,
		context,
		searchTerm,
		filters,
		categoryVisibility,
		setCategoryVisible,
	} = useFilterFieldContext();
	const ulRef = useRef<HTMLUListElement>(null);

	const storageKey = `${context}_${compType}_${category.replaceAll(' ', '')}`;
	const defaultIsOpen = Boolean(
		JSON.parse(localStorage.getItem(storageKey) ?? 'true')
	);

	const [isOpen, setIsOpen] = useState(defaultIsOpen);

	useEffect(() => {
		const categoryFiltersElements = Array.from(
			ulRef.current?.children ?? []
		) as HTMLElement[];

		setCategoryVisible(
			category,
			categoryFiltersElements.some(
				(li) => li.dataset.filterIsVisible === 'true'
			)
		);
	}, [category, searchTerm, setCategoryVisible]);

	useLayoutEffect(() => {
		if (isOpen) {
			return;
		}

		const categoryFiltersElements = Array.from(
			ulRef.current?.children ?? []
		) as HTMLElement[];

		setIsOpen(
			categoryFiltersElements.some((li) => li.dataset.filterIsSet === 'true')
		);
	}, [filters]);

	const isCategoryVisible =
		!searchTerm || !!categoryVisibility?.[compType]?.[category];

	const isExchange = useIsExchange();

	return (
		<React.Fragment key={storageKey}>
			<StyledDiv
				onClick={() => {
					localStorage.setItem(storageKey, JSON.stringify(!isOpen));
					setIsOpen(!isOpen);
				}}
				isCategoryVisible={isCategoryVisible}
				data-category-is-visible={isCategoryVisible}
			>
				<StyledRowFlexContainer>
					<TitleContainer>
						<span>{category}</span>
						{isExchange && category === 'Loan Info' && (
							<BetaChip>Beta</BetaChip>
						)}
						{category === 'Multifamily' && (
							<DataProviderLogoBase
								logoImageStyles={{ width: 64 }}
								labelStyles={{ fontSize: 9, fontWeight: 'normal' }}
							/>
						)}
					</TitleContainer>
					<StyledAngleArrow rotate={isOpen ? 180 : 0} />
				</StyledRowFlexContainer>
			</StyledDiv>

			<StyledUl isOpen={isOpen} className="search_sidebar_filters" ref={ulRef}>
				{children}
			</StyledUl>
		</React.Fragment>
	);
};

const StyledDiv = styled.div<{ isCategoryVisible: boolean }>`
	padding: 0.5rem 1rem;
	user-select: none;
	font-weight: 600;

	display: ${(p) => (p.isCategoryVisible ? 'block' : 'none')};
`;

const StyledRowFlexContainer = styled(RowFlexContainer)`
	cursor: pointer;
	color: ${(p) => p.theme.colors.blue.blue400};
	gap: 1rem;
`;

const StyledUl = styled.ul<{ isOpen: boolean }>`
	display: ${(p) => (p.isOpen ? 'block' : 'none')};
`;

const StyledAngleArrow = styled(AngleArrow)`
	path {
		stroke: ${(p) => p.theme.colors.blue.blue400};
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;
