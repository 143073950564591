import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

const TOP_TENANTS_DEFAULT_LIMIT = 10;

export const useTopTenantsQuery = (
	params: UseTopTenantsParams,
	options?: UseTopTenantsOptions
) => {
	const { filters, limit = TOP_TENANTS_DEFAULT_LIMIT } = params;
	const filter = filtersToServerJSON(filters);

	return useQuery({
		queryKey: [QUERY_KEYS.TOP_TENANTS, filter, limit],
		queryFn: async () => {
			const response = await API.post<TopTenantsResponse>(
				'/api/comps/actions/topTenants',
				{
					filter,
					limit,
				}
			);

			return response.data;
		},
		...options,
	});
};

type UseTopTenantsOptions = UseQueryOptions<TopTenantsResponse>;

type UseTopTenantsParams = {
	filters: Partial<FiltersObject>;
	limit?: number;
};

export type Tenant = {
	name: string;
	leasedSqFt: number;
	totalLeasesCount: number;
	tenantIndustry?: string;
	globalLeasedSqFt?: number;
	globalTotalLeasesCount?: number;
};

export type TopTenantsResponse = {
	tenants: Tenant[];
};
