import { Spinner, TooltipV2 } from '@compstak/ui-kit';
import { UseQueryResult } from '@tanstack/react-query';
import { CheckExportStatusResponse } from 'api';
import { ComponentProps, useMemo } from 'react';
import styled from 'styled-components';
import IconHelp from 'ui/svg_icons/help_v2.svg';
import { ExportPdfData } from './useExportPdfData';
import { useCheckLeasesExportStatusQuery } from 'api/checkExportStatus/useCheckLeasesExportStatusQuery';
import { useCheckSalesExportStatusQuery } from 'api/checkExportStatus/useCheckSalesExportStatusQuery';

export const ExportPdfLeasesStatus = ({
	data,
	...props
}: Omit<ExportPdfStatusProps, 'exportStatus'>) => {
	const leaseIds = useMemo(() => {
		return [
			...data.leases.map((l) => l.id),
			...data.leasesInCompset.map((l) => l.id),
			...data.submarketRecentLeases.map((l) => l.id),
		];
	}, [data.leases, data.leasesInCompset, data.submarketRecentLeases]);

	const exportStatus = useCheckLeasesExportStatusQuery(
		{
			compIds: leaseIds,
			format: 'pdf',
		},
		{ enabled: leaseIds.length > 0 }
	);

	return <ExportPdfStatus data={data} exportStatus={exportStatus} {...props} />;
};

export const ExportPdfSalesStatus = ({
	data,
	...props
}: Omit<ExportPdfStatusProps, 'exportStatus'>) => {
	const saleIds = useMemo(() => data.sales.map((s) => s.id), [data.sales]);

	const exportStatus = useCheckSalesExportStatusQuery(
		{
			compIds: saleIds,
			format: 'pdf',
		},
		{ enabled: saleIds.length > 0 }
	);

	return <ExportPdfStatus data={data} exportStatus={exportStatus} {...props} />;
};

type ExportPdfStatusProps = ComponentProps<'div'> & {
	data: ExportPdfData;
	exportStatus: UseQueryResult<CheckExportStatusResponse>;
};

const ExportPdfStatus = ({
	data,
	exportStatus,
	...props
}: ExportPdfStatusProps) => {
	const propertyMarketExportStatus = useMemo(() => {
		if (!exportStatus.data) return;
		return exportStatus.data.perMarket?.find(
			(m) => m.marketId === data.property.marketId
		);
	}, [exportStatus.data, data.property.marketId]);

	if (exportStatus.isFetching) {
		return <Spinner size="s" />;
	}

	if (!exportStatus.data) return null;

	const { unlimited, availableWithFinancial, limit } = exportStatus.data;

	let text;

	if (unlimited) {
		text = 'Unlimited Exports Remaining';
	} else if (propertyMarketExportStatus) {
		text = `${propertyMarketExportStatus?.availableWithFinancial}/${propertyMarketExportStatus?.limit} Exports Remaining`;
	} else {
		text = `${availableWithFinancial}/${limit} Exports Remaining`;
	}

	return (
		<Root {...props}>
			<Text>{text}</Text>
			{!unlimited && propertyMarketExportStatus && (
				<StyledTooltip
					content={
						<div>
							<Row style={{ marginBottom: '0.5rem' }}>
								<TooltipTitle>Market</TooltipTitle>
								<TooltipTitle style={{ textAlign: 'right' }}>
									Exports Remaining
								</TooltipTitle>
							</Row>
							<TooltipContent>
								<Row>
									<TooltipText>
										{propertyMarketExportStatus.marketDisplayName}
									</TooltipText>
									<LimitText>
										{propertyMarketExportStatus.availableWithFinancial}/
										{propertyMarketExportStatus.limit}
									</LimitText>
								</Row>
							</TooltipContent>
						</div>
					}
				>
					<span>
						<IconHelp width={16} />
					</span>
				</StyledTooltip>
			)}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const StyledTooltip = styled(TooltipV2)`
	z-index: ${(p) => p.theme.zIndex.overlay + p.theme.zIndex.modal + 1};
	max-width: 320px;
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const Text = styled.div`
	color: ${(p) => p.theme.colors.neutral.n80};
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.01em;
`;

const TooltipTitle = styled.div`
	color: ${(p) => p.theme.colors.white.white};
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.5;
	text-transform: uppercase;
`;

const TooltipText = styled.div`
	color: ${(p) => p.theme.colors.white.white};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 0.75rem;
	font-weight: 300;
	line-height: 1.5;
`;

const LimitText = styled(TooltipText)`
	text-align: right;
`;

const TooltipContent = styled.div`
	max-height: 200px;
	overflow-y: auto;
`;
