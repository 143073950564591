import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

const TOP_BUYERS_DEFAULT_LIMIT = 10;

export const useTopBuyersQuery = (
	params: UseTopBuyersParams,
	options?: UseTopBuyersOptions
) => {
	const { filters, limit = TOP_BUYERS_DEFAULT_LIMIT } = params;
	const filter = filtersToServerJSON(filters);

	return useQuery({
		queryKey: [QUERY_KEYS.TOP_BUYERS, filter, limit],
		queryFn: async () => {
			const response = await API.post<TopBuyersResponse>(
				'/api/salesComps/topBuyers',
				{
					filter,
					limit,
				}
			);

			return response.data;
		},
		...options,
	});
};

type UseTopBuyersOptions = UseQueryOptions<TopBuyersResponse>;

type UseTopBuyersParams = { filters: Partial<FiltersObject>; limit?: number };

export type Buyer = {
	name: string;
	sumOfSalesPrice: number;
	totalSalesCount: number;
	totalPropertiesCount: number;
	averageDealSize: number;
	globalSumOfSalesPrice: number;
	globalTotalSalesCount: number;
	officePropertiesCount?: number;
	industrialPropertiesCount?: number;
	retailPropertiesCount?: number;
	otherPropertiesCount?: number;
};

export type TopBuyersResponse = {
	buyers: Buyer[];
};
