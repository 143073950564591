import {
	colors,
	defaultTheme,
	numberUtils,
	renderTableValue,
	TableColumn,
	typography,
} from '@compstak/ui-kit';
// @ts-expect-error TS2305: Module '"api"' has no exported...
import { LeaseComp } from 'api';

import { TableCellGapper } from '../../Components';

import { nullValueText } from '@compstak/common';
import { Pinpoint } from '@compstak/maps';
import styled from 'styled-components';
import IconArrow from '../../../../ui/svg_icons/arrow_diagonal_right.svg';

export const columns: TableColumn<
	Pick<
		LeaseComp,
		'buildingSize' | 'buildingAddress' | 'city' | 'state' | 'propertyId'
	> & {
		letter: string | undefined;
	}
>[] = [
	{
		header: () => 'Address',
		width: 170,
		body: ({ row }) => {
			return renderTableValue(row.buildingAddress, (v) => (
				<TableCellGapper>
					<PinPointStyled text={row.letter} />
					<AddressLink href={`/property/${row.propertyId}`}>
						{v}
						<IconArrow width={10} height={10} />
					</AddressLink>
				</TableCellGapper>
			));
		},
	},
	{
		header: () => 'City, State',
		width: 120,
		body: ({ row }) => {
			return renderTableValue(row.city + ', ' + row.state);
		},
	},
	{
		header: () => 'Size (SF)',
		width: 105,
		body: ({ row }) => {
			if (row.buildingSize || row.buildingSize === 0)
				return renderTableValue(
					numberUtils.formatNumber(row.buildingSize, 'integer') + ' SF'
				);
			return nullValueText;
		},
	},
];

const AddressLink = styled.a`
	display: flex;
	gap: 4px;
	align-items: center;

	font-family: ${typography.fontFamily.gotham};
	text-decoration: underline;
	color: ${colors.blue.blue500};
`;

const PinPointStyled = styled(Pinpoint)`
	width: 15px;
	height: 25px;

	path {
		fill: ${defaultTheme.colors.blue.blue700};
	}
`;
