import React from 'react';
import styled from 'styled-components';
import { CompstakContactInfo } from '@compstak/common';
import LockIcon from 'ui/svg_icons/lock_with_check.svg';
import HandHoldingLogoIcon from 'ui/svg_icons/hand_holding_logo.svg';
import PersonWithCheckIcon from 'ui/svg_icons/person_with_check.svg';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useTeamInfoRequestMutation } from 'api/team/teamQueries';

type Props = { contactInfo?: CompstakContactInfo | null };

export const TeamInfoRequestModal = ({ contactInfo }: Props) => {
	const { closeModal } = useModal();
	const [stage, setStage] = React.useState<'infoRequest' | 'thankYou'>(
		'infoRequest'
	);

	const { mutate, isLoading } = useTeamInfoRequestMutation({
		onSuccess: () => {
			setStage('thankYou');
		},
	});

	switch (stage) {
		case 'infoRequest':
			return (
				<InfoRequestContainer>
					<ModalTitle>Team Info Request</ModalTitle>
					<h6>
						Establishing a CompStak team enables you and your colleagues to
						share:
					</h6>
					<div className="share-squares">
						<div>
							<LockIcon />
							<div className="title">Comps</div>
							<div className="body">
								Any comp unlocked by a team member is accessible to the entire
								team, giving you access to more data
							</div>
						</div>
						<div>
							<HandHoldingLogoIcon />
							<div className="title">Credits</div>
							<div className="body">
								Share credits among team members, unlock comps even if you don’t
								have credits of your own
							</div>
						</div>
						<div>
							<PersonWithCheckIcon />
							<div className="title">Submissions</div>
							<div className="body">
								Flexibility to coordinate comp submissions through a single
								person so the rest of the team can focus on deals
							</div>
						</div>
					</div>
					<h6>Ready to create your team?</h6>
					<div className="click-below">
						Click below and your CompStak account manager will reach out to set
						up your team
					</div>
					<ModalButtons>
						<ModalButton
							variant="secondary"
							data-qa-id="team-info-cancel-button"
							onClick={closeModal}
						>
							Cancel
						</ModalButton>
						<ModalButton
							variant="primary"
							disabled={!contactInfo || isLoading}
							isLoading={isLoading}
							data-qa-id="team-info-launch-button"
							onClick={() => {
								contactInfo &&
									mutate({
										name: contactInfo.name,
										address: contactInfo.email,
									});
							}}
						>
							Launch a team
						</ModalButton>
					</ModalButtons>
				</InfoRequestContainer>
			);
		case 'thankYou':
			return (
				<ModalCenteredContent>
					<ModalTitle>Thanks!</ModalTitle>
					<ModalParagraph>
						Your account manager will contact you to set up a CompStak team.
					</ModalParagraph>
					<ModalButtons>
						<ModalButton
							variant="primary"
							data-qa-id="team-info-close-button"
							onClick={closeModal}
						>
							Close
						</ModalButton>
					</ModalButtons>
				</ModalCenteredContent>
			);
	}
};

const InfoRequestContainer = styled.div`
	h6 {
		font-weight: 400;
		font-size: 1rem;
	}
	.click-below {
		margin: 0.5rem 0;
	}
	.share-squares {
		height: 245px;
		display: flex;
		justify-content: space-between;
		margin: 1.25rem 0;
		> * {
			padding: 1.5rem;
			background-color: #f9f9f9;
			display: flex;
			flex-direction: column;
			align-items: center;
			svg {
				height: 72px;
			}
			.title {
				font-size: 0.875em;
				text-align: center;
				margin-bottom: 24px;
			}
			.body {
				font-size: 0.75em;
			}
			&:nth-of-type(2) {
				margin: 0 0.5rem;
			}
		}
	}
`;
