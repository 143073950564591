import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';

const attribute = 'loanToValue' as const;

export const LoanToValueFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<div>
					<Between {...props} />
					<FilterDescription>
						Loan-to-Value is generated from latest loan record. Available for
						loans provided by Trepp.
					</FilterDescription>
				</div>
			)}
		</FilterBase>
	);
};
