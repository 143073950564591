import {
	SpaceTypeAnalyticsDescription,
	SpaceTypeAnalyticsLegend,
	useSpaceTypeAnalyticsState,
} from '../SpaceTypeAnalytics';
import { MapExportLegend } from './MapExportLegend';

export const SpaceTypeAnalyticsExportLegend = () => {
	const [state] = useSpaceTypeAnalyticsState();

	return (
		<MapExportLegend
			title={state.title}
			description={<SpaceTypeAnalyticsDescription />}
		>
			<SpaceTypeAnalyticsLegend />
		</MapExportLegend>
	);
};
