import { Map, MapInteractivity, MapStyle } from '@compstak/maps';
import { TenantIndustryLayer, TenantIndustryLegend } from 'maps';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { useFilteredTenantIndustries } from '../../TenantIndustryMap';
import { useDashboardTenantIndustryAnalyticsData } from '../../useDashboardTenantIndustryAnalyticsData';
import { useExportPdfState } from '../ExportPdfProvider';
import { PanelDescription } from '../PreviewComponents/UI';
import { PDFPanel } from '../UI';
import { useTenantIndustryMapDescription } from './useTenantIndustryMapDescription';

export const TenantIndustryMapSection = () => {
	const [filters] = useFilters('exchange-dashboard');

	const { data } = useDashboardTenantIndustryAnalyticsData();

	const tenantIndustries = useFilteredTenantIndustries();

	const { registerImage } = useExportPdfState();

	const description = useTenantIndustryMapDescription();

	return (
		<PDFPanel title="Tenant Industry Map">
			<MapContainer>
				<Map
					initialViewState={{
						latitude: filters.market.lat,
						longitude: filters.market.lon,
						zoom: filters.market.zoom,
					}}
					interactivity={MapInteractivity.STATIC}
					mapStyle={MapStyle.MONOCHROME}
					mapProps={{
						onLoad: (e) => {
							registerImage('tenantIndustryMap', e.target.getContainer());
						},
					}}
				>
					<TenantIndustryLayer data={data} marketName={filters.market.name} />
				</Map>
			</MapContainer>
			<TenantIndustryLegend
				tenantIndustries={tenantIndustries}
				isTopTen={false}
				listCss={{
					display: 'grid',
					gridTemplateColumns: '50% 50%',
					columnGap: '0.5rem',
					padding: '0.5rem',
				}}
			/>
			<PanelDescription style={{ margin: 0 }}>{description}</PanelDescription>
		</PDFPanel>
	);
};

const MapContainer = styled.div`
	height: 400px;
	width: 100%;
	position: relative;
`;
