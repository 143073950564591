import {
	PortfolioFormFields,
	PortfolioFormState,
	usePortfolioForm,
	UsePortfolioFormProps,
} from 'Components/PortfolioForm';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useModal } from 'providers/ModalProvider';

type Props = {
	title: string;
	isLoadingMutation: boolean;
	onSubmit: (values: PortfolioFormState) => void;
} & UsePortfolioFormProps;

export const PortfolioDialog = ({
	title,
	isLoadingMutation,
	onSubmit,
	...formProps
}: Props) => {
	const portfolioForm = usePortfolioForm(formProps);

	const { closeModal } = useModal();

	return (
		<>
			<ModalTitle>{title}</ModalTitle>
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					await onSubmit(portfolioForm.trimmedState);

					closeModal();
				}}
			>
				<PortfolioFormFields {...portfolioForm} />
				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeModal}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={!portfolioForm.isValid || isLoadingMutation}
						isLoading={isLoadingMutation}
						style={{
							transition: 'none',
						}}
					>
						Done
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};
