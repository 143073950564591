import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { noRetryFor404 } from 'api/utils/queryRetriesMisc';
import { mapUrlToGateway } from 'utils/gatewayUtil';

export const useGetAssociatedApns = (
	params: UseGetAssociatedApnsParams,
	options?: UseGetAssociatedApnsOptions
) => {
	const { apn, fips } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.ASSOCIATED_APNS, params],
		queryFn: async () => {
			const response = await API.get<AssociatedApn[]>(
				mapUrlToGateway(`/ppm-property/api/parcel/siblings/${fips}/${apn}`)
			);

			return response.data;
		},
		retry: noRetryFor404,
		...options,
	});
};

export type UseGetAssociatedApnsOptions = UseQueryOptions<AssociatedApn[]>;

type UseGetAssociatedApnsParams = {
	apn: string;
	fips: string;
};

export type AssociatedApn = {
	apn: string;
	blockNumber?: string;
	fips: string;
	id: string;
	lotNumber: string;
	owner: string;
	trueOwner?: string;
};
