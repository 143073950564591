import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'insideviewRevenue' as const;

export const TenantRevenueFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => {
				return (
					<Between
						attribute={attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						touch={props.touch}
						setError={props.setError}
					/>
				);
			}}
		</FilterBase>
	);
};
