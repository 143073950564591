// these are old routes that we redirect from
export const deprecatedRoutes = {
	getGredits: {
		path: '/get-credits',
	},
	settingsCredits: {
		path: '/settings/credits',
	},
	settingsRewards: {
		path: '/settings/rewards',
	},
	compById: {
		path: '/comps/:id',
	},
};
