import { useMemo } from 'react';
import { generatePath, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { encodeQuery } from 'util/encodeQuery';

export const route = <
	Params = void,
	SearchParams = void,
	Data = Record<string, any>,
>({
	path,
	toHref,
	isModal = false,
	isLegacyModal = true,
	data,
}: RouteConfigOptions<Params, SearchParams, Data>) => {
	const defaultToHref = (params: Params, searchParams?: SearchParams) => {
		let generatedPath = generatePath(path, params ?? {});

		if (searchParams && Object.keys(searchParams).length > 0) {
			generatedPath = `${generatedPath}${encodeQuery(searchParams, false)}`;
		}

		return generatedPath;
	};

	return {
		path,
		isModal,
		isLegacyModal,
		data: data ?? {},
		toHref: toHref ?? defaultToHref,
		useParams: () => {
			const params = useParams();
			return useMemo(() => transformParams<Params>(params), [params]);
		},
		useSearchParams: () => {
			const [_searchParams, _setSearchParams] = useSearchParams();

			const searchParams = useMemo(() => {
				return transformParams<SearchParams>(
					Object.fromEntries(_searchParams.entries())
				);
			}, [_searchParams]);

			const setSearchParams = (params: SearchParams) => {
				// @ts-expect-error don't care
				_setSearchParams(new URLSearchParams(params));
			};

			return [searchParams, setSearchParams] as const;
		},
	};
};

// convert numeric params to numbers automatically
const transformParams = <Params>(params: Record<string, any>) => {
	const result = { ...params };

	Object.entries(result).forEach(([key, value]) => {
		if (isNumeric(value ?? '')) {
			result[key] = Number(value);
		} else if (isArray(value)) {
			const array = (JSON.parse(value) as string[]).map((item) =>
				typeof item === 'number'
					? Number(item)
					: typeof item === 'string'
						? decodeURIComponent(item)
						: item
			);
			result[key] = array;
		}
	});

	return result as Params;
};

const isNumeric = (str: string) => {
	if (typeof str != 'string') return false;
	return !isNaN(+str) && !isNaN(parseFloat(str));
};

const isArray = (str: string) => {
	if (typeof str != 'string') return false;
	try {
		return Array.isArray(JSON.parse(str));
	} catch {
		return false;
	}
};

type RouteConfigOptions<
	Params,
	SearchParams = void,
	Data = Record<string, any>,
> = {
	path: string;
	toHref?: (params: Params, searchParams?: SearchParams) => string;
	/** @default false */
	isModal?: boolean;
	/** @default true */
	isLegacyModal?: boolean;
	data?: Data;
};
