import { Spinner } from '@compstak/ui-kit';
import { usePropertyByIdQuery } from 'api';
import { CompPopupHeader } from './CompPopupHeader';
import {
	CompPopupActionLink,
	CompPopupActions,
	CompPopupRoot,
	CompPopupRootLoading,
} from './UI';
import { useFiltersWithAddressString } from './useFiltersWithAddressString';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

/**
 * For all analytics except MarketRent
 */
export const AnalyticsPopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({
		id: propertyId,
	});

	const filtersWithAddressString = useFiltersWithAddressString({
		property,
	});

	const refineLeasesURL = `/search/leases/list?${filtersWithAddressString}`;

	if (!property) {
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);
	}
	//
	else {
		return (
			<CompPopupRoot>
				<CompPopupHeader property={property} closePopup={closePopup} />
				<CompPopupActions>
					<CompPopupActionLink
						to={refineLeasesURL}
						data-qa-id="lease-expiration-popup-refine-link"
						className="single-child"
					>
						Refine Leases ({property.leaseCount})
					</CompPopupActionLink>
					<CompPopupActionLink
						to={`/property/${property.id}`}
						data-qa-id="lease-expiration-popup-property-link"
						className="single-child"
					>
						Detailed property info
					</CompPopupActionLink>
				</CompPopupActions>
			</CompPopupRoot>
		);
	}
};
