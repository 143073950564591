import { usePropertyById } from 'api';
import { useApn } from 'hooks/useApn';
import { routes } from 'router';
import styled from 'styled-components';
import { PropertyComp } from 'types';

export const EditPropertyAddress = () => {
	const params = routes.propertyById.useParams();
	const { data: property } = usePropertyById({ propertyId: params.propertyId });

	const { data: apn } = useApn({ comp: property ?? ({} as PropertyComp) });

	if (!property) return null;

	return (
		<div>
			<Submarket>
				{property.submarket} ({property.marketDisplayName})
			</Submarket>
			<Address>{property.buildingAddress}</Address>
			<Address2Container>
				<Address2>
					{property.city}, {property.state} {property.zip}
				</Address2>
				{property.buildingName && <Address2>{property.buildingName}</Address2>}
			</Address2Container>
			{apn && (
				<ApnContainer>
					<ApnLabel>APN:</ApnLabel>
					<ApnValue>
						{Array.isArray(apn) ? `${apn.length} associated` : apn}
					</ApnValue>
				</ApnContainer>
			)}
		</div>
	);
};

const Submarket = styled.div`
	color: ${(p) => p.theme.colors.neutral.n100};
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.01em;
	text-transform: uppercase;
`;

const Address = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 2rem;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.01em;
	text-transform: capitalize;
`;

const Address2Container = styled.div`
	margin-bottom: 1.25rem;
`;

const Address2 = styled.div`
	color: ${(p) => p.theme.colors.neutral.n600};
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.25;
	letter-spacing: 0.01em;
`;

const ApnContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const ApnLabel = styled.div`
	color: ${(p) => p.theme.colors.neutral.n100};
	font-size: 0.875rem;
	font-weight: 300;
	line-height: 1.25;
	letter-spacing: 0.01em;
`;

const ApnValue = styled.div`
	color: ${(p) => p.theme.colors.neutral.n600};
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.25;
	letter-spacing: 0.01em;
`;
