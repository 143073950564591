import { usePropertyLeasesQuery } from 'api';
import { PropertyStatsSpaceType } from 'api/propertyStats/types';
import { useMemo } from 'react';
import { DEFAULT_PROPERTY_LEASE_SPACE_TYPE } from '../constants';

type Props = {
	propertyId: number;
};

export const useDominantPropertySpaceType = ({ propertyId }: Props) => {
	const { data: leaseData, isFetching } = usePropertyLeasesQuery({
		propertyId,
	});

	return {
		data: useMemo(() => {
			if (!leaseData || leaseData.comps.length === 0) return undefined;

			const leasesBySpaceType = Object.groupBy(
				leaseData.comps,
				(item) => item.spaceType ?? DEFAULT_PROPERTY_LEASE_SPACE_TYPE
			);

			const maxCount = Math.max(
				...Object.values(leasesBySpaceType).map((leases) => leases.length)
			);

			const spaceTypesWithMaxCount = Object.entries(leasesBySpaceType)
				.filter(([, leases]) => leases.length === maxCount)
				.map(([spaceType]) => spaceType as PropertyStatsSpaceType);

			if (spaceTypesWithMaxCount.length === 1) {
				return spaceTypesWithMaxCount[0];
			} else {
				return SPACE_TYPE_RANKING.find((s) =>
					spaceTypesWithMaxCount.includes(s)
				);
			}
		}, [leaseData]),
		isFetching,
	};
};

// ties are resolved in this order
const SPACE_TYPE_RANKING: PropertyStatsSpaceType[] = [
	'Office',
	'Industrial',
	'Flex/R&D',
	'Retail',
	'Other',
	'Land',
];
