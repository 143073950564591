import React, { PropsWithChildren } from 'react';
import { PropertyTypeId } from 'api';

export type FullPageState =
	| {
			type: 'list';
			field: 'tenantIndustry';
			industry: string;
	  }
	| {
			type: 'list';
			field: 'propertyType';
			propertyTypeId: PropertyTypeId;
			year: number;
	  }
	| {
			type: 'list';
			field: 'submarket';
			submarket: string;
			year: number;
	  }
	| {
			type: 'list';
			field: 'tenantName';
			tenantName: string;
	  }
	| {
			type: 'list';
			field: 'landlordName';
			landlordName: string;
	  }
	| {
			type: 'graph';
			field: 'submarket';
	  };

export type ExchangeDashboardContextState = {
	fullPageState?: FullPageState | null;
	setFullPageState?: React.Dispatch<React.SetStateAction<FullPageState | null>>;
	isLeaseExpirationPropertyTypeVisible: boolean;
	setIsLeaseExpirationPropertyTypeVisible: React.Dispatch<
		React.SetStateAction<boolean>
	>;
};

const initialState: ExchangeDashboardContextState = {
	fullPageState: null,
	isLeaseExpirationPropertyTypeVisible: false,
	setIsLeaseExpirationPropertyTypeVisible: () => {},
};

const ExchangeDashboardContext =
	React.createContext<ExchangeDashboardContextState>(initialState);

export const useExchangeDashboardState = (): ExchangeDashboardContextState => {
	const context = React.useContext(ExchangeDashboardContext);
	if (typeof context === 'undefined') {
		throw new Error(
			'useExchangeDashboardState must be used with an ExchangeDashboardProvider'
		);
	}

	return context;
};

export const ExchangeDashboardProvider = ({ children }: PropsWithChildren) => {
	const [fullPageState, setFullPageState] =
		React.useState<FullPageState | null>(null);
	const [
		isLeaseExpirationPropertyTypeVisible,
		setIsLeaseExpirationPropertyTypeVisible,
	] = React.useState<boolean>(false);

	return (
		<ExchangeDashboardContext.Provider
			value={{
				fullPageState,
				setFullPageState,
				isLeaseExpirationPropertyTypeVisible,
				setIsLeaseExpirationPropertyTypeVisible,
			}}
		>
			{children}
		</ExchangeDashboardContext.Provider>
	);
};
