import { API, APIClientNotOkResponseError } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { ServerFilterItem } from 'types/serverFilters';
import { SavedSearchEmailFrequency, SavedSearchType } from './types';
import { SavedSearch } from './useSavedSearchesQuery';
import { QUERY_KEYS } from 'api/constants';

export const useCreateSavedSearchMutation = (
	options?: UseMutationOptions<
		CreateSavedSearchResponse,
		APIClientNotOkResponseError,
		CreateSavedSearchParams
	>
) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (params: CreateSavedSearchParams) => {
			const res = await API.post<CreateSavedSearchResponse>(
				`/api/savedSearches`,
				params
			);
			return res.data;
		},
		...options,
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.SAVED_SEARCHES]);
		},
	});
};

export type CreateSavedSearchParams = {
	name: string;
	searchType: SavedSearchType;
	marketId: number;
	filters: ServerFilterItem[];
	sortAsc: boolean;
	sortProperty: string;
	notifyByEmail: boolean;
	emailFrequency: SavedSearchEmailFrequency;
};

type CreateSavedSearchResponse = SavedSearch[];
