import { useGoogleStaticStreetViewURL } from '@compstak/maps';
import { STREET_VIEW_RADIUS } from 'Pages/PropertyPageV3/constants';
import { GeoPoint } from 'types';

type Props = {
	width: number;
	height: number;
	geoPoint: GeoPoint;
};

export const useStreetViewImageUrl = ({ width, height, geoPoint }: Props) => {
	const { lon, lat } = geoPoint;
	const url = useGoogleStaticStreetViewURL({
		width,
		height,
		lat,
		lon,
		pitch: 30,
		source: 'outdoor',
		radius: STREET_VIEW_RADIUS,
	});

	return url;
};
