import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';

const attribute = 'loanInterestType' as const;

export const LoanInterestTypeFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					attribute={attribute}
					options={[
						{
							title: 'Fixed',
							value: 'Fixed',
						},
						{
							title: 'Floating',
							value: 'Floating',
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
