import { API } from '@compstak/common';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { MUTATION_KEYS } from 'api/constants';

export const useReferColleguesV2Mutation = (
	options?: UseReferColleguesV2MutationOptions
) => {
	return useMutation({
		mutationKey: [MUTATION_KEYS.REFER_COLLEGUES_V2],
		mutationFn: async (params) => {
			const referrals = params.map(
				({ firstName, lastName, email, phone, firm }) => {
					const fields: CollegueReferral = {
						firstName,
						lastName,
						email,
					};
					if (phone) {
						fields.phone = phone;
					}
					if (firm) {
						fields.firm = firm;
					}

					return fields;
				}
			);

			const response = await API.post<void, ReferColleguesV2Paylod>(
				'/api/v2/users/referrals',
				{
					referrals,
				}
			);

			return response.data;
		},
		...options,
	});
};

type ReferColleguesV2Paylod = {
	referrals: CollegueReferral[];
};

type CollegueReferral = {
	firstName: string;
	lastName: string;
	email: string;
	phone?: string;
	firm?: string;
};

type UseReferColleguesV2MutationOptions = UseMutationOptions<
	void,
	unknown,
	CollegueReferral[]
>;
