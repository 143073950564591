import { MAP_CONTROLS } from 'actions/search';
import { MapFilterBase } from './MapFilterBase';
import IconMapViewport from 'ui/svg_icons/map-viewport-icon.svg';

export const SearchWithinViewMapFilter = () => {
	return (
		<MapFilterBase
			Icon={IconMapViewport}
			key={MAP_CONTROLS.SEARCH_WITHIN_VIEW}
			label="Search Within View"
			mapControl={MAP_CONTROLS.SEARCH_WITHIN_VIEW}
		/>
	);
};
