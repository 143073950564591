import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn } from 'api/utils';
import { Feedback } from 'types/marketSummary';
import { mapUrlToGateway } from 'utils/gatewayUtil';

type MarketSummaryFeedbackResponse = { feedbacks: Feedback[] };

type MarketSummaryFeedbackParams = {
	marketId: number;
	userId: number;
} & UseQueryOptions<MarketSummaryFeedbackResponse>;

const fetchMarketSummaryFeedback = createFetchFn<
	MarketSummaryFeedbackParams,
	MarketSummaryFeedbackResponse
>({
	method: 'get',
	getURL: ({ marketId, userId }) =>
		mapUrlToGateway(
			`/user-service/markets/${marketId}/users/${userId}/feedbacks`
		),
});

export const useMarketSummaryFeedbackQuery = ({
	marketId,
	userId,
	...props
}: MarketSummaryFeedbackParams) =>
	useQuery({
		queryKey: [QUERY_KEYS.MARKET_SUMMARY_FEEDBACK, marketId, userId],
		queryFn: fetchMarketSummaryFeedback({ marketId, userId }),
		...props,
	});
