import { TooltipV2 } from '@compstak/ui-kit';
import IconHelp from 'ui/svg_icons/help_v2.svg';

type Props = {
	text: string;
	iconWidth?: number;
};

export const HelpTooltip = ({ text, iconWidth = 12 }: Props) => {
	return (
		<TooltipV2 content={text} delayDuration={500}>
			<div style={{ cursor: 'pointer' }}>
				<IconHelp width={iconWidth} />
			</div>
		</TooltipV2>
	);
};
