import Loader from 'Components/Loader';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import spaceTypeAggregationsService from 'services/spaceTypeAggregations';

export const LeaseAggregationsLoader = Loader(
	spaceTypeAggregationsService,
	'searchResultsAggregations',
	(props: { filters: FiltersObject }) => ({
		compType: 'lease',
		filters: filtersToServerJSON(props.filters),
	}),
	false
);
