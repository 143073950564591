import React, { useEffect } from 'react';
import { SEARCH_BY_VIEW_ROUTES, replace } from 'router';

import savedSearchService from 'services/savedSearches';

import {
	filtersFromServerJSON,
	filtersToQueryString,
} from 'models/filters/util';
import { CompType } from 'types';
import { useSavedSearches } from 'reducers/savedSearches';
import { useDispatch } from 'react-redux';
import { loadSavedSearches } from 'Pages/SavedSearches/actions';
import { useMarkets } from 'hooks/useMarkets';
import { Spinner } from '@compstak/ui-kit';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { RouteComponentProps } from 'router/migration/types';
import { useUserQuery } from 'api';

type SearchRedirectProps = RouteComponentProps<
	{},
	{ savedSearchId?: string }
> & { route: { compType: CompType } };

const DEFAULT_VIEW = 'list';

export const SearchRedirect = ({
	route: { compType },
	location,
}: SearchRedirectProps) => {
	const savedSearchId = location.query.savedSearchId;
	const savedSearches = useSavedSearches().savedSearchList;
	const { data: user } = useUserQuery();
	const markets = useMarkets();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!savedSearches) {
			dispatch(loadSavedSearches(user));
			return;
		}

		const savedSearch = savedSearches?.find(({ id }) => id === savedSearchId);

		if (savedSearch) {
			let filters = filtersFromServerJSON(
				compType,
				markets,
				savedSearch.filters
			);
			filters = mergeFilters(filters, {
				sortDirection: savedSearch.sortAsc ? 'asc' : 'desc',
				sortField: savedSearch.sortProperty,
			});
			const queryString = filtersToQueryString(filters);

			savedSearchService.view({
				id: savedSearch.id,
			});

			replace(
				`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: DEFAULT_VIEW })}?${queryString}`
			);
			return;
		}

		replace(
			`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: DEFAULT_VIEW })}${location.search}`
		);
	}, [
		savedSearchId,
		savedSearches,
		user,
		dispatch,
		location.pathname,
		location.search,
		compType,
		markets,
	]);

	return <Spinner size="xl" isCentered />;
};
