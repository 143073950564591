import { useIsSendToAnalyticsBtnAllowed } from 'hooks/chartBuilderHooks';
import { useAppConfig } from 'Pages/Login/reducers';
import Menu from 'Singletons/Menu';
import styled from 'styled-components';
import { FeatureFlags } from 'api/featureFlags/types';
import { CompType } from '../../types/comp';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type Props = {
	compType: CompType;
	featureFlags: FeatureFlags;
	propertyIds: number[];
	openAddToPortfolio: () => void;
};

const MultiSelectOptionsMenu = (props: Props) => {
	const isSendToAnalyticsBtnAllowed = useIsSendToAnalyticsBtnAllowed();
	const appConfig = useAppConfig();
	const featureFlags = useFeatureFlags();

	const showAddToPortfolioBtn =
		featureFlags['portfolio-analytics'] &&
		appConfig.isEnterprise &&
		(props.compType === 'property' || props.compType === 'lease');

	return (
		<MenuContainer width="250px">
			<ul>
				{/* @ts-expect-error TS2339: Property 'refineUrl' does not ... */}
				<a href={props.refineUrl}>Refine</a>
				{isSendToAnalyticsBtnAllowed && props.compType === 'lease' ? (
					// @ts-expect-error TS2339: Property 'analyticsUrl' does n...
					<a href={props.analyticsUrl}>Send to chartbuilder</a>
				) : null}
				{showAddToPortfolioBtn ? (
					<li onClick={props.openAddToPortfolio}>Add to portfolio</li>
				) : null}
			</ul>
		</MenuContainer>
	);
};

Menu.addComponent('multi-select-options-menu', MultiSelectOptionsMenu);

export default 'multi-select-options-menu';

export const MenuContainer = styled.div<{ width?: string; minHeight?: string }>`
	width: ${(props) => props?.width ?? '188px'};
	min-height: ${(props) => props?.minHeight ?? '41px'};
	margin: -10px;
	background: #303441;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;

	ul {
		position: relative;
		z-index: 2;

		li,
		a,
		span {
			display: flex;
			align-items: center;
			font-size: 13px;
			letter-spacing: 1px;
			line-height: 14px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 300;
			padding-left: 15px;
			height: 41px;
			width: 100%;
			background: transparent;
			text-align: left;
			font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
			&:hover {
				background: #464d5e;
				cursor: pointer;
			}
		}
	}
`;
