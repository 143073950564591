import { Button, useNotifications } from '@compstak/ui-kit';
import { useCompsetData, useResetCompset } from 'api';
import { formatDate } from 'format';
import { routes } from 'router';

export const CompsetModified = () => {
	const params = routes.propertyById.useParams();
	const { data: compset } = useCompsetData({ propertyId: params.propertyId });

	const { mutateAsync: resetCompset, isLoading: isLoadingReset } =
		useResetCompset({
			propertyId: params.propertyId,
		});

	const { addNotification } = useNotifications();

	if (compset?.summary.lastEdited == null) return null;

	return (
		<>
			<div>
				You modified this set on{' '}
				<strong>{formatDate(compset.summary.lastEdited, 'MM/DD/YYYY')}</strong>
			</div>
			<Button
				variant="ghost"
				onClick={async () => {
					await resetCompset({ propertyId: params.propertyId });
					addNotification({ icon: 'success', title: 'Compset was reset' });
				}}
				disabled={isLoadingReset}
			>
				Reset to default
			</Button>
		</>
	);
};
