import React, { useState } from 'react';

import { domainsMatch, isValidEmail } from 'util/validate';

import { Chart } from 'Pages/Analytics/analytics';
import { sendChart } from '../../actions';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalInputMessage,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useDispatch } from 'react-redux';
import { useUser } from 'Pages/Login/reducers';

type Props = {
	chart: Chart;
};

export const ShareChartModal = (props: Props) => {
	const dispatch = useDispatch();
	const { closeModal } = useModal();

	const user = useUser();
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			share();
		}
	};

	const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setErrorMessage('');
	};

	const share = () => {
		if (!isValidEmail(email)) {
			setErrorMessage('Email is not valid');
			return;
		}

		if (!domainsMatch(email, user)) {
			setErrorMessage("Email domain must match your account's email domain");
			return;
		}

		if (isValidEmail(email)) {
			dispatch(sendChart(props.chart.id!, email));
			closeModal();
		}
	};

	return (
		<>
			<ModalTitle>Share Chart</ModalTitle>
			<ModalParagraph>
				Enter your colleague’s email below to send them:{' '}
				<b>{props.chart.title}</b>
			</ModalParagraph>
			<ModalInputLabel isError={!!errorMessage}>
				Recipient’s Email Address
				<input
					type="text"
					value={email}
					placeholder="Email Address"
					onChange={updateEmail}
					onKeyDown={handleKeyDown}
				/>
				<ModalInputMessage>{errorMessage}</ModalInputMessage>
			</ModalInputLabel>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton onClick={share}>Share</ModalButton>
			</ModalButtons>
		</>
	);
};
