import { colors, typography } from '@compstak/ui-kit';
import { formatDate } from 'format';
import styled from 'styled-components';
import { SalesComp } from 'types/comp';

type Props = {
	data: SalesComp;
};

export const SourceInfoFooter = ({ data }: Props) => {
	const { id, dateCreated, dataSource } = data;

	return (
		<Root>
			<div>
				COMP ID: {id} | CREATED: {formatDate(dateCreated, 'MMM DD, YYYY')}
			</div>
			<div>Data Source: {dataSource}</div>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 80px 0 30px;
	height: 64px;
	background-color: #ffffff;
	color: ${colors.neutral.n100};
	font-family: ${typography.fontFamily.gotham};
	font-weight: 500;
	font-size: 10px;
	line-height: 18px;
	text-transform: uppercase;
`;
