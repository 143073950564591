import { TooltipV2 } from '@compstak/ui-kit';
import { Apn } from 'Components/CompPageApn/Apn';
import styled from 'styled-components';
import { MufaPropertyComp, PropertyComp } from 'types';

type Props = {
	property: PropertyComp | MufaPropertyComp;
};

export const PropertyTitle = ({ property }: Props) => {
	const propertyTitle = (
		<>
			{property.buildingName && <strong>{property.buildingName} - </strong>}
			{property.buildingAddressAndCity}, {property.state} {property.zip}
		</>
	);

	return (
		<div>
			<TitleContainer>
				<SubmarketTitle>
					{property?.submarket} ({property?.marketDisplayName})
				</SubmarketTitle>
				<TooltipV2 content={propertyTitle} delayDuration={500}>
					<Title>{propertyTitle}</Title>
				</TooltipV2>
			</TitleContainer>
			<StyledApn comp={property} />
		</div>
	);
};

const Title = styled.h1`
	font-size: 1.5rem;
	font-weight: 300;
	letter-spacing: 0.025em;
	line-height: 1.2;
`;

const SubmarketTitle = styled.div`
	font-size: 0.875rem;
	line-height: 1.5;
	font-weight: 400;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.neutral.n100};
	margin-bottom: 0.25rem;
`;

const TitleContainer = styled.div`
	margin-bottom: 0.2rem;
`;

const StyledApn = styled(Apn)`
	margin-bottom: 0.2rem;

	span {
		font-weight: 400;
	}
`;
