import styled, { CSSObject } from 'styled-components';
import { colors, typography } from '@compstak/ui-kit';

const commonFont: CSSObject = {
	fontFamily: typography.fontFamily.gotham,
	fontWeight: 500,
	fontSize: 12,
	color: colors.gray.gray700,
};

const FIRST_COL_WIDTH = 224;

export const TableContainer = styled.div`
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 20px;
`;

export const Table = styled.table`
	width: 100%;
	margin: 0 auto;
	box-shadow: inset -2px 0 0 0 ${colors.neutral.n20};
`;

export const THead = styled.thead``;

type TRProps = {
	/** Number of years in the range + 1 for CAGR cell */
	contentCellsNumber: number;
	hasBottomShadow?: boolean;
};

export const TR = styled.tr<TRProps>`
	display: grid;
	grid-template-columns: ${({ contentCellsNumber }) => {
		const lastColWidth = contentCellsNumber > 2 ? '90px' : '140px';
		return `${FIRST_COL_WIDTH}px repeat(${
			contentCellsNumber - 1
		}, 1fr) ${lastColWidth}`;
	}};

	box-shadow: ${({ hasBottomShadow }) =>
		hasBottomShadow ? `inset 0 -2px 0 0 ${colors.neutral.n20}` : ''};
`;

export const TRGroupHeader = styled(TR)`
	background-color: ${colors.neutral.n20};
`;

export const TH = styled.th`
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: ${colors.neutral.n40};

	${commonFont}
	text-align: center;
`;

export const TD = styled.td`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 24px;
	padding-bottom: 24px;
	background-color: transparent;

	${commonFont}
	text-align: center;
`;

export const TDGroupHeader = styled.td`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
`;

export const TDGroupHeaderContainer = styled.div`
	position: relative;

	${commonFont}
	color: ${colors.blue.blue500};
	text-align: center;
	text-transform: capitalize;
	font-weight: 400;
`;

export const ProviderLogoContainer = styled.div`
	position: absolute;
	top: 28%;
	left: 70px;
	min-width: 160px;
`;

export const TDFirstColumn = styled.td<{ sideShadowColor: string }>`
	position: relative;
	padding: 14px;

	&::before,
	&::after {
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		width: 4px;
		background-color: ${({ sideShadowColor }) => sideShadowColor};
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}
`;

export const TDLastColumn = styled(TD)<{ sideShadowColor: string }>`
	position: relative;

	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		width: 4px;
		background-color: ${({ sideShadowColor }) => sideShadowColor};
	}
`;

export const DSTitle = styled.h5`
	max-width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	margin-bottom: 4px;
	${commonFont}
`;

export const DSStatsContainer = styled.div`
	display: flex;
	justify-items: flex-start;
	align-items: center;
	gap: 7px;
`;

export const DSStatsItem = styled.span`
	${commonFont}
	font-size: 11px;
	font-weight: 400;
	text-transform: capitalize;

	&:first-child {
		position: relative;
		margin-right: 14px;

		&::after {
			position: absolute;
			content: '';
			right: -12px;
			top: 6px;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: ${colors.gray.gray700};
		}
	}
`;

export const DSStatsDotItem = styled.span`
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: ${colors.gray.gray700};
`;

export const TBody = styled.tbody``;

export const ChartLegendLine = styled.div<{ color: string }>`
	width: 12px;
	height: 3px;
	background-color: ${(p) => p.color};
`;

export const ChartLegendBubble = styled.div<{ color: string }>`
	width: 7px;
	height: 7px;
	border-radius: 100%;
	background-color: ${(p) => p.color};
`;
