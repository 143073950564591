import { useCallback } from 'react';
import { loadUser } from 'Pages/Login/actions';
import { useAppDispatch } from 'util/useAppDispatch';

export function splitCodeIntoFours(code: string) {
	const res: string[] = [];
	for (let i = 0; i < code.length; i += 4) {
		res.push(code.slice(i, i + 4));
	}
	return res;
}

export function useRefreshMfaEnabledInfo() {
	const dispatch = useAppDispatch();
	const refreshIsMfaEnabled = useCallback((): Promise<void> => {
		const refreshUserDataAction = loadUser();
		dispatch(refreshUserDataAction);
		// @ts-expect-error TS2322: Type 'Promise<User>' is not as...
		return refreshUserDataAction.payload.promise;
	}, [dispatch]);
	return { refreshIsMfaEnabled };
}
