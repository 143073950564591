import Between from 'Components/Filters/Base/Between';
import FallbackSwitcher from 'Components/Filters/Base/FallbackSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import React from 'react';

const attribute = 'leaseTerm' as const;

export const LeaseTermFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => {
				return (
					<>
						<Between
							attribute={attribute}
							filter={props.filter}
							onFilterChange={props.onFilterChange}
							touch={props.touch}
							setError={props.setError}
						/>
						<FilterDescription>Years & Months. For Ex: 1y 2m</FilterDescription>
						<FallbackSwitcher
							// @ts-expect-error ts-migrate(2769) FIXME: Property 'options' does not exist on type 'Intrins... Remove this comment to see the full error message
							attribute={attribute}
							filter={props.filter}
							onFilterChange={props.onFilterChange}
							touch={props.touch}
							setError={props.setError}
							options={[
								{
									name: 'Show leases with no lease term',
									value: true,
								},
								{
									name: 'Hide leases with no lease term',
									value: false,
								},
							]}
						/>
					</>
				);
			}}
		</FilterBase>
	);
};
