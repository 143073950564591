import React from 'react';
import styled from 'styled-components';
import button from 'ui/styles/button.less';
import IconPlusLight from '../../../ui/svg_icons/plus.svg';
import { ExchangePanel } from '../../UI';
import TeamIcon from './team-icon.svg';
import { useTeamDashboardQuery } from '../../../api/team/teamQueries';
import { MQB, Spinner } from '@compstak/ui-kit';
import { useModal } from 'providers/ModalProvider';
import { TeamInfoRequestModal } from '../Promotions/TeamInfoRequestModal';
import { TeamInviteModal } from './TeamInviteModal';

export const TeamProfile = () => {
	const { data: team, isFetching: teamDataIsFetching } =
		useTeamDashboardQuery();
	const { openModal } = useModal();
	return (
		<ExchangePanel id="team-profile" title="My Team">
			{teamDataIsFetching && <Spinner isCentered={true} />}
			<TeamProfileContainer>
				{team && team.members && team.members.length > 0 && (
					<TeamInfoContainer>
						<TeamList>
							<ul>
								{team.members.map((member, i) => (
									<li key={i}>
										{member.firstName} {member.lastName}
									</li>
								))}
							</ul>
						</TeamList>

						<button
							className={button.blue}
							data-qa-id="exchange-dashboard-add-member-button"
							onClick={() => openModal({ modalContent: <TeamInviteModal /> })}
						>
							<IconPlusLightStyled />
							Add Member
						</button>
					</TeamInfoContainer>
				)}
				{!team?.members && (
					<TeamActionContainer className="no-team">
						<TeamIcon className="team-icon" />
						Ready to create your team?
						<button
							className={button.blue}
							onClick={() =>
								openModal({ modalContent: <TeamInfoRequestModal /> })
							}
						>
							Launch Your Team
						</button>
					</TeamActionContainer>
				)}
				{team && team.members && team.members.length === 0 && (
					<TeamActionContainer className="no-team">
						<TeamIcon className="team-icon" />
						'Ready to expand your team?
						<button
							className={button.blue}
							onClick={() => openModal({ modalContent: <TeamInviteModal /> })}
						>
							<IconPlusLightStyled />
							Add Member
						</button>
					</TeamActionContainer>
				)}
			</TeamProfileContainer>
		</ExchangePanel>
	);
};

const IconPlusLightStyled = styled(IconPlusLight)`
	path {
		fill: ${({ theme }) => theme.colors.white.white};
	}
`;

const TeamList = styled.div`
	width: 100%;
	height: 296px;
	background: #f9f9f9;
	padding: 0.5rem;
	border-radius: 3px;
	ul {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		overflow: auto;
		height: 100%;
		& li {
			width: 50%;
			font-size: 0.75rem;
			font-weight: 400;
		}
	}
`;

const TeamActionContainer = styled.div`
	width: 300px;
	height: 280px;
	background: #f9f9f9;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 20px 30px;

	@media (min-width: ${MQB.T_834}) {
		padding: 40px 45px 30px;
	}

	.team-icon {
		width: 70px;
		height: 56px;
		margin-bottom: 35px;
	}

	button {
		width: auto;
		margin: 0;
	}

	&.no-team {
		margin: auto;
		button {
			margin-top: 30px;
		}
	}
`;

const TeamProfileContainer = styled.div`
	background-color: white;
	border-radius: 2px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

const TeamInfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	max-width: 500px;

	h3 {
		color: #228fff;
	}

	span {
		color: rgb(33, 36, 44);
		font-size: 12px;
		line-height: 1.5;
	}
`;
