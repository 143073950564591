import { API } from '@compstak/common';
import { useMutation } from '@tanstack/react-query';

type RequestSalesDemoApiParams = {
	uuid?: string;
	usernameOrEmail: string;
};

const fetchRequestSalesDemo = async ({
	uuid,
	usernameOrEmail,
}: RequestSalesDemoApiParams) => {
	const response = await API.post<void>('/api/users/requestSalesDemo', {
		uuid,
		usernameOrEmail,
	});
	return response.data;
};

export const useRequestSalesDemoMutation = () => {
	return useMutation({ mutationFn: fetchRequestSalesDemo });
};
