import React from 'react';
import { TableHeaderTitle } from 'Components';
import { TableContainer, TableHeader } from '../UI';
import { PropertyComp } from 'types';
import { EditPdfTable } from '../EditPdfTable';
import { PublicLoansColumn } from './usePublicLoansPdfColumns';

type Props = {
	property: PropertyComp;
	editablePublicLoansRow: Record<string, string | number>[] | undefined;
	setEditablePublicLoansRow: React.Dispatch<
		React.SetStateAction<Record<string, string | number>[] | undefined>
	>;
	editablePublicLoansColumns: PublicLoansColumn[];
	setEditablePublicLoansColumns: React.Dispatch<
		React.SetStateAction<PublicLoansColumn[]>
	>;
};

export const EditablePublicLoans = ({
	editablePublicLoansRow,
	setEditablePublicLoansRow,
	editablePublicLoansColumns,
	setEditablePublicLoansColumns,
}: Props) => {
	return (
		<TableContainer>
			<TableHeader>
				<TableHeaderTitle>Public Loan Data</TableHeaderTitle>
			</TableHeader>
			<EditPdfTable
				columns={editablePublicLoansColumns}
				rows={editablePublicLoansRow ?? []}
				onChange={({ row, column, value }) => {
					setEditablePublicLoansRow((rows) => {
						return (
							rows &&
							rows.map((r) => {
								if (r.id === row.id) {
									return {
										...r,
										[column.id]: value,
									};
								}
								return r;
							})
						);
					});
				}}
				onToggleColumn={({ column }) => {
					setEditablePublicLoansColumns(
						(cols: PublicLoansColumn[]): PublicLoansColumn[] =>
							cols.map((c) => {
								if (c.id === column.id) {
									return {
										...c,
										isVisible: !c.isVisible,
									};
								}
								return c;
							})
					);
				}}
			/>
		</TableContainer>
	);
};
