import { UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { ExchangeDashboardServerFilterItem } from 'api/types';
import {
	createFetchFn,
	createFiltersParamWithMarketId,
	useQueryHook,
} from 'api/utils';
import { FiltersObject } from 'models/filters/types';

import { useMemo } from 'react';

type LargeLandlordsParams = {
	filters: ExchangeDashboardServerFilterItem[];
	query: string;
};

export type LargeLandlordsResponse = {
	largestLandlords: {
		landlord: string;
		sqft: number;
	}[];
	averageSqft: number;
};

export type UseLargestLandlordsOptions =
	UseQueryOptions<LargeLandlordsResponse>;

const fetchLargestLandlords = createFetchFn<
	LargeLandlordsParams,
	LargeLandlordsResponse
>({
	method: 'post',
	getURL: (params) => `/api/dashboard/topLargestLandlords?${params.query}`,
	getBody: (params) => ({ filters: params.filters }),
});

type LargeLandlordsParamsArg = {
	filters: FiltersObject;
	query: string;
};

export const useFetchLargeLandlordsQuery = (
	params: LargeLandlordsParamsArg,
	options?: UseLargestLandlordsOptions
) => {
	const { filters: paramsFilters, ...otherParams } = params;

	const filters = useMemo(() => {
		return createFiltersParamWithMarketId(paramsFilters);
	}, [paramsFilters]);

	return useQueryHook({
		queryKey: [QUERY_KEYS.LARGEST_LANDLORDS, filters, otherParams],
		queryFn: fetchLargestLandlords({
			filters,
			...otherParams,
		}),
		...options,
	});
};
