import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactTimeout from 'react-timeout';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { getAttributesById } from 'util/comp-format/src/format';
import { MULTIFAMILY_SECTION_KEY, TREPP_SECTION_KEY } from '../../../constants';
import { useIsMultifamily } from '../../../hooks';
import { CompType } from '../../../types';
import ArrowBottomRounded from '../../../ui/svg_icons/arrow_bottom_rounded.svg';
import { usePrevious } from '../../../util/hooks';
import { useIsShowTreppUI } from '../../../utils';
import { Graph, TableView } from './Components';
import './Components/expander.nomodule.css';
import { mapDispatchToProps, mapStoreToProps } from './Table';

export type TableComponentProps = {
	compType: CompType;
	filters: FiltersObject;
	insightsAreExpanded?: boolean;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
	toggleInsightsAreExpanded?: () => void;
} & ReactTimeout.ReactTimeoutProps &
	ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

type TableComponentState = {
	graphVisible: boolean;
	graphAboutToAppear: boolean;
	graphAppearing: boolean;
	graphDisappearing: boolean;
	lastChange: Date | null;
};

export const TableComponent = ReactTimeout(function TableSearchView(
	props: TableComponentProps
) {
	const {
		filters,
		insightsAreExpanded,
		requestAnimationFrame: requestAnimationFrameProp,
		setTimeout: setTimeoutProp,
		toggleInsightsAreExpanded,
		fields,
		compType,
		appConfig,
		user,
	} = props;
	const [state, setState] = useState<TableComponentState>({
		graphVisible: !!insightsAreExpanded,
		graphAboutToAppear: false,
		graphAppearing: false,
		graphDisappearing: false,
		lastChange: null,
	});

	const oldGraphAboutToAppear = usePrevious(state.graphAboutToAppear);

	const expanderRef = useRef(null);

	const useMultifamily = useIsMultifamily({
		markets: getFiltersMarkets(filters),
	});

	const shouldShowTreppUI = useIsShowTreppUI({
		markets: getFiltersMarkets(filters),
	});

	useEffect(() => {
		if (!oldGraphAboutToAppear && state.graphAboutToAppear) {
			requestAnimationFrameProp?.(() => {
				setState((prevState) => ({
					...prevState,
					graphAboutToAppear: false,
					graphAppearing: true,
				}));
			});
		}
	}, [
		state.graphAboutToAppear,
		requestAnimationFrameProp,
		oldGraphAboutToAppear,
	]);

	const setAppearing = () => {
		setState((prevState) => ({
			...prevState,
			graphVisible: true,
			graphAboutToAppear: true,
		}));
	};

	const setDisappearing = () => {
		setState((prevState) => ({
			...prevState,
			graphDisappearing: true,
		}));
	};

	const setEntered = () => {
		setTimeoutProp?.(() => {
			setState((prevState) => ({
				...prevState,
				graphVisible: true,
				graphAppearing: false,
				graphDisappearing: false,
				lastChange: new Date(),
			}));
		}, 200);
	};

	const setDisappeared = () => {
		setState((prevState) => ({
			...prevState,
			graphVisible: false,
			graphAppearing: false,
			graphDisappearing: false,
			lastChange: new Date(),
		}));
	};

	const toggle = () => {
		if (state.graphVisible) {
			setDisappearing();
		} else {
			setAppearing();
		}
		toggleInsightsAreExpanded?.();
	};

	const filteredTableFields = () => {
		const tableFields = fields;

		if (compType !== 'property') {
			return tableFields;
		}

		const formatFields = getAttributesById(compType);

		return tableFields?.filter((field) => {
			// @ts-expect-error TS7015: Element implicitly has an 'any...
			const section = formatFields[field.id]?.section;

			if (section === TREPP_SECTION_KEY) {
				return shouldShowTreppUI;
			}
			if (section === MULTIFAMILY_SECTION_KEY) {
				return useMultifamily;
			}
			return true;
		});
	};

	const containerClasses = ['graph-and-table-container'];

	const userHasInsights =
		compType === 'lease' && (appConfig.isEnterprise || user.vipLevel > 0);

	const multipleMarketsSelected = getFiltersMarkets(props.filters).length > 1;

	if (!userHasInsights || multipleMarketsSelected) {
		containerClasses.push('no-graph');
		return (
			<div className={containerClasses.join(' ')}>
				{/* @ts-expect-error TS2739: Type '{ fields: TableField[] |... */}
				<TableView
					{...props}
					fields={filteredTableFields()}
					lastExpectedChange={state.lastChange}
					className="table-container"
				/>
			</div>
		);
	}

	let shouldRenderGraph = state.graphVisible;
	if (state.graphAboutToAppear) {
		containerClasses.push('graph-about-to-appear');
	}
	if (state.graphAppearing) {
		containerClasses.push('graph-appearing');
	}
	if (state.graphDisappearing) {
		containerClasses.push('graph-disappearing');
		shouldRenderGraph = false;
	}
	if (state.graphVisible) {
		containerClasses.push('graph-visible');
	}
	return (
		<div className={containerClasses.join(' ')}>
			<div className="table-graph-collapse-row">
				<h3>Insights</h3>
				<span className="table-graph-insights-toggler" onClick={toggle}>
					{shouldRenderGraph ? 'Collapse' : 'Expand'}
					<StyledArrowBottomRounded
						className="graph-collapse-icon"
						width={12}
						height={6}
					/>
				</span>
			</div>

			<CSSTransition
				nodeRef={expanderRef}
				in={shouldRenderGraph}
				timeout={300}
				classNames="expander"
				onEntered={setEntered}
				onExited={setDisappeared}
			>
				<div className="expander" ref={expanderRef}>
					<ErrorBoundary
						fallback={<h3>There was a problem with showing insights data</h3>}
					>
						<Graph
							key="graph"
							thresholdForPoints={100}
							{...props}
							// @ts-expect-error TS2322: Type '{ market: Market; compTy...
							market={getFiltersMarkets(filters)[0]}
						/>
					</ErrorBoundary>
				</div>
			</CSSTransition>
			{/* @ts-expect-error TS2739: Type '{ fields: TableField[] |... */}
			<TableView
				{...props}
				fields={filteredTableFields()}
				lastExpectedChange={state.lastChange}
				className="table-container"
			/>
		</div>
	);
});

const StyledArrowBottomRounded = styled(ArrowBottomRounded)`
	display: inline-block;
	fill: ${({ theme }) => theme.colors.blue.blue400};
`;
