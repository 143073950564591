import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

export const HideElement = styled.div`
	display: none;
`;

export const Spacer = styled.div`
	margin-bottom: 10px;
`;

export const SpinnerContainer = styled.div`
	height: 320px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
