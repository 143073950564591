import { useQuery } from '@tanstack/react-query';
import { InsightData } from 'Pages/Analytics/analytics';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';
import { ServerFilterItem } from 'types/serverFilters';

type OutlierServerFilterItem = {
	property: 'outlier';
	value: boolean;
};

type MarketIdServerFilterItem = {
	property: 'marketId';
	value: number[];
};

type InsightServerFilterItem =
	| ServerFilterItem
	| OutlierServerFilterItem
	| MarketIdServerFilterItem;

export type InsightsParams = {
	properties: string[];
	fullDataThreshold: number;
	quartileThreshold: number;
	trendMonths: number;
	filter: InsightServerFilterItem[];
};

export const fetchInsights = createFetchFn<InsightsParams, InsightsResponse>({
	method: 'post',
	getURL: () => `/api/comps/insights`,
	getBody: (params) => params,
});

export const useInsightsQuery = ({ params }: { params: InsightsParams }) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_MARKET_TREND, params],
		queryFn: fetchInsights(params),
	});
};

export type InsightsResponse = InsightData;
