import { CheckExportStatusResponse } from 'api';

export const getExportPopupTitle = (
	exportStatus?: CheckExportStatusResponse
) => {
	if (!exportStatus) {
		return 'Export your comps';
	}

	const { unlimited, availableWithFinancial, limit } = exportStatus;
	const available = availableWithFinancial;

	let status: string;
	if (unlimited) {
		status = 'unlimited';
	} else {
		status = available > 0 ? `${available} / ${limit}` : 'no';
	}

	return `${status} exports remaining`;
};
