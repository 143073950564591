import React, { CSSProperties } from 'react';
import { DataProviderLogoBase, defaultTheme } from '@compstak/ui-kit';

export const RealPageLogo = ({
	baseStyles = baseStyle,
}: {
	baseStyles?: CSSProperties;
}) => {
	return (
		<DataProviderLogoBase
			baseStyles={baseStyles}
			logoImageStyles={logoImgStyle}
			labelStyles={labelStyle}
		/>
	);
};

const baseStyle: CSSProperties = {
	position: 'absolute',
	bottom: '13px',
	right: '10px',
};

const logoImgStyle: CSSProperties = {
	position: 'relative',
	bottom: '1px',
};

const labelStyle: CSSProperties = {
	fontSize: '10px',
	letterSpacing: '0.3px',
	color: `${defaultTheme.colors.neutral.n200}`,
};
