import { useQueryClient } from '@tanstack/react-query';
import { exportCustomData } from 'actions/export';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { ExportState } from 'reducers/export';
import { CompType } from 'types/comp';
import { ExportPDFModalToolbar } from './UI';
import styled from 'styled-components';
import { Button } from '@compstak/ui-kit';
import { Checkbox } from 'Components';

interface Props {
	exportData: ExportState['exportData'];
	showLineItemComps: boolean;
	toggleShowLineItemComps: NoArgCallback;
	showIndividualComps: boolean;
	toggleShowIndivudualComps: NoArgCallback;
	compType: CompType;
	onComplete: NoArgCallback;
	title: string;
	isLoading: boolean;
}
export const ExportPDFToolbar = forwardRef<HTMLDivElement, Props>(
	(props, ref) => {
		const queryClient = useQueryClient();
		const dispatch = useDispatch();

		const exportPDF = () => {
			dispatch(
				exportCustomData(
					props.exportData,
					props.showLineItemComps,
					props.showIndividualComps,
					props.compType,
					queryClient
				)
			);
			props.onComplete();
		};

		return (
			<ExportPDFModalToolbar ref={ref}>
				<Title>{props.title}</Title>

				<Controls>
					<ControlsLabel>Include in Report</ControlsLabel>
					<Control onClick={props.toggleShowLineItemComps}>
						<Checkbox
							data-qa-id="export-pdf-toggleShowLineItemComps"
							id="line-item-summary"
							checked={props.showLineItemComps}
						/>
						<span>Cover Page</span>
					</Control>
					<Control onClick={props.toggleShowIndivudualComps}>
						<Checkbox
							data-qa-id="export-pdf-toggleShowIndivudualComps"
							id="individual-comps"
							checked={props.showIndividualComps}
						/>
						<span>Individual Comps</span>
					</Control>
					<Button
						onClick={exportPDF}
						variant="primary"
						isLoading={props.isLoading}
					>
						Export PDF
					</Button>
				</Controls>
			</ExportPDFModalToolbar>
		);
	}
);

const Title = styled.h2`
	font-weight: 400;
	display: inline-block;
	font-size: 1.2rem;
	flex-grow: 1;
`;

const Controls = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const ControlsLabel = styled.label`
	letter-spacing: 1.25px;
	text-transform: uppercase;
	font-weight: 500;
`;

const Control = styled.div`
	cursor: pointer;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

ExportPDFToolbar.displayName = 'ExportPDFToolbar';
