import styled from 'styled-components';
import { TabContentTitle } from '../UI';
import { Flex } from '@compstak/ui-kit';

export const TableTitle = styled(TabContentTitle)`
	margin-bottom: 1rem;
`;

export const TitleContainer = styled(Flex)`
	margin-bottom: 1rem;
`;
