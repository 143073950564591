import { Styles, Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

export type PdfColumn<Row> = {
	id: string;
	header: ReactNode;
	body: (args: { row: Row; rowIndex: number }) => string | number;
	/** @default 1 */
	flex?: number;
	/** @default "left" */
	hAlign?: 'left' | 'center' | 'right';
	/** @default true */
	wrapBodyContentWithText?: boolean;
};

type Props<Row> = {
	rows: Row[];
	columns: PdfColumn<Row>[];
};

export const PdfTable = <Row,>({ rows, columns }: Props<Row>) => {
	const theme = useTheme();

	const style = {
		row: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			width: '100%',
			borderBottom: `1px solid ${theme.colors.neutral.n20}`,
			paddingVertical: 2,
		},
		cell: {
			fontSize: 9,
			padding: 5,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			flexWrap: 'wrap',
		},
	} satisfies Styles;

	return (
		<View
			style={{
				width: '100%',
			}}
		>
			<View
				style={{
					...style.row,
					backgroundColor: theme.colors.neutral.n30,
				}}
				fixed
			>
				{columns.map((column) => {
					const hAlign = column.hAlign ?? 'left';

					return (
						<View
							key={column.id}
							style={{
								...style.cell,
								fontWeight: 'bold',
								flex: column.flex ?? 1,
								justifyContent: FLEX_JUSTIFY_MAP[hAlign],
								textAlign: hAlign,
							}}
						>
							<Text>{column.header}</Text>
						</View>
					);
				})}
			</View>
			{rows.map((row, rowIndex) => {
				return (
					<View key={rowIndex} style={[style.row]} wrap={false}>
						{columns.map((column) => {
							const content = column.body({ row, rowIndex });

							const wrapBodyContentWithText =
								column.wrapBodyContentWithText ?? true;

							const hAlign = column.hAlign ?? 'left';

							return (
								<View
									key={column.id}
									style={[
										style.cell,
										{
											flex: column.flex ?? 1,
											justifyContent: FLEX_JUSTIFY_MAP[hAlign],
											textAlign: hAlign,
										},
									]}
								>
									{wrapBodyContentWithText ? <Text>{content}</Text> : content}
								</View>
							);
						})}
					</View>
				);
			})}
		</View>
	);
};

const FLEX_JUSTIFY_MAP = {
	left: 'flex-start',
	center: 'center',
	right: 'flex-end',
} as const;
