import React, { useState } from 'react';
import { FiltersObject, FiltersTypesKeys } from 'models/filters/types';
import { CompType } from 'types';
import { FilterErrors } from './Filter/Container';

type Props = {
	attribute: FiltersTypesKeys;
	compType: CompType;
	filter?: string | null;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	touch: NoArgCallback;
	setError: (errors: FilterErrors) => void;
	placeholder?: string;
};

export const StringFilter = ({
	placeholder,
	attribute,
	filter,
	touch,
	setError,
	onFilterChange,
}: Props) => {
	const [value, setValue] = useState(filter ?? '');
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		touch();

		setValue(event.target.value.trim());
		try {
			onFilterChange({ [attribute]: event.target.value.trim() || null });
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message);
			}
		}
	};

	return (
		<div>
			<input
				type="text"
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				data-qa-id="string-filter-input"
			/>
		</div>
	);
};
