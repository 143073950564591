import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { PropertyLease } from 'api';
import { useMemo } from 'react';
import { LeaseComp } from 'types';
import { PdfPage } from '../../../../pdf/PdfPage';
import { PdfTable } from '../PdfTable';
import { pdfStyle } from '../style';
import {
	EditableLeaseComp,
	LeaseStatus,
	PdfColumn,
	PdfPageProps,
} from '../types';

type Props = PdfPageProps & {
	leases: PropertyLease[];
	editableLeasesRowsBySpaceType: Record<string, EditableLeaseComp[]>;
	editableLeasesColumnsBySpaceType: Record<
		string,
		PdfColumn<EditableLeaseComp, LeaseComp>[]
	>;
	leaseStatus: LeaseStatus;
};

export const LeasesPage = (props: Props) => {
	const {
		leases,
		editableLeasesRowsBySpaceType,
		editableLeasesColumnsBySpaceType,
		leaseStatus,
	} = props;

	const numberOfLeases = useMemo(() => {
		return Object.values(editableLeasesRowsBySpaceType).reduce(
			(sum, editableLeases) => sum + editableLeases.length,
			0
		);
	}, [editableLeasesRowsBySpaceType]);

	if (numberOfLeases === 0) return null;

	return (
		<PdfPage
			logo={props.page.logo}
			preparedFor={props.page.preparedFor}
			title={props.page.titles.leases}
			user={props.user}
		>
			<Text style={pdfStyle.pageTitle}>
				Property Lease Transactions ({leases.length})
			</Text>
			{Object.entries(editableLeasesRowsBySpaceType).map(
				([spaceType, leasesBySpaceType]) => {
					return (
						<View
							key={spaceType}
							wrap={leasesBySpaceType.length > PAGE_MAX_ROWS}
						>
							<Text style={style.tableTitle}>
								{spaceType}
								{leaseStatus === LeaseStatus.ACTIVE ? ', Active Only ' : ' '}(
								{leasesBySpaceType.length})
							</Text>
							<PdfTable
								rows={leasesBySpaceType}
								columns={editableLeasesColumnsBySpaceType[spaceType].filter(
									(c) => c.isVisible
								)}
							/>
						</View>
					);
				}
			)}
		</PdfPage>
	);
};

const style = StyleSheet.create({
	tableTitle: {
		fontSize: 11,
		marginTop: 6,
		marginBottom: 6,
	},
});

const PAGE_MAX_ROWS = 8;
