import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { PortfolioV2, PortfolioFilterQuery } from '../../types';
import { composeEnabled } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';

export const usePortfolioByIdQueryV2 = (
	params: PortfolioByIdParams,
	options?: UseQueryOptions<PortfolioByIdV2Response>
) => {
	const queryParams = getQueryStringFromPortfolioFilters(params.filters);

	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID_V2, params.id, queryParams],
		queryFn: async () => {
			const response = await API.get<PortfolioByIdV2Response>(
				`/pa/v2/api/v2/portfolios/${params.id}${queryParams}`
			);
			return response.data;
		},
		...options,
		enabled: composeEnabled(params.id != null, options?.enabled),
	});
};

export type PortfolioByIdParams = {
	id: number | undefined;
	filters?: PortfolioFilterQuery;
};

type PortfolioByIdV2Response = PortfolioV2;
