import {
	AnalyticsErrorMessageContainer,
	AnalyticsErrorMessageDescription,
	AnalyticsErrorMessageTitle,
} from './UI';

type Props = {
	title?: string;
	description?: string;
};

export const MapAnalyticsNoDataMessage = ({
	title = 'No Data Available',
	description = 'Try adjusting the filters in the sidebar to include more lease comps.',
}: Props) => {
	return (
		<AnalyticsErrorMessageContainer>
			<AnalyticsErrorMessageTitle>{title}</AnalyticsErrorMessageTitle>
			<AnalyticsErrorMessageDescription>
				{description}
			</AnalyticsErrorMessageDescription>
		</AnalyticsErrorMessageContainer>
	);
};
