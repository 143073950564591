import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import Multiselect from 'Components/Filters/Base/Multiselect';
import { useAppSelector } from 'util/useAppSelector';

const attribute = 'assetClassMarket' as const;

export const AssetClassFilter = () => {
	const mufaReferenceData = useAppSelector((store) => store.mufaReferenceData);

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Multiselect
					options={mufaReferenceData?.assetClassMarkets ?? []}
					usesStringNames={true}
					{...props}
				/>
			)}
		</FilterBase>
	);
};
