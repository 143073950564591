import { UseQueryOptions } from '@tanstack/react-query';
import {
	Feature,
	FeatureCollection,
	MultiPolygon,
	Polygon,
} from '@turf/helpers';
import { QUERY_KEYS } from '../constants';
import { createFetchFn, useQueryHook } from '../utils';

const fetchAllMarketsPolygons = createFetchFn<void, MarketsPolygonsResponse>({
	method: 'get',
	getURL: () => `/api/markets/all/polygons/simplified`,
});

export const useAllMarketsPolygons = (props?: UseAllMarketsPolygonsProps) =>
	useQueryHook({
		queryKey: [QUERY_KEYS.ALL_MARKETS_POLYGONS],
		queryFn: fetchAllMarketsPolygons(),
		...props,
	});

type UseAllMarketsPolygonsProps = UseQueryOptions<MarketsPolygonsResponse>;

type MarketPolygonProperties = {
	id: number;
	displayName: string;
};

export type MarketPolygonFeature = Feature<
	Polygon | MultiPolygon,
	MarketPolygonProperties
>;

export type MarketsPolygonsResponse = FeatureCollection<
	MarketPolygonFeature['geometry'],
	MarketPolygonFeature['properties']
>;
