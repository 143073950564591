import React from 'react';
import { VictoryScatter, VictoryLabel } from 'victory';
import sortBy from 'lodash/sortBy';
import { abbreviateNumber } from './util';
import dashboardStyles from './dashboard.less';
import { PDFPanel } from './UI';
import { useExchangeDashboardLargeTenants } from 'ExchangeDashboard/hooks/largestTenants/useExchangeDashboardLargeTenants';
import { AverageIndicator } from 'ExchangeDashboard/LargestTenantsChart';

export default () => {
	const { data: largesTenantssData } = useExchangeDashboardLargeTenants();

	if (largesTenantssData?.largestTenants.length === 0) {
		return <div>No Data To Display. Update Filters and Try Again.</div>;
	}

	const largestTenantsPlot =
		largesTenantssData?.largestTenants.map((tenant) => {
			const formattedTenant =
				tenant.tenant.length > 30
					? `${tenant.tenant.slice(0, 30)}…`
					: tenant.tenant;
			return {
				...tenant,
				label: `${abbreviateNumber(tenant.sqft)} sqft\n${formattedTenant}`,
				x: `${formattedTenant}\n${abbreviateNumber(tenant.sqft)} sqft`,
				y: 1,
			};
		}) ?? [];

	const data = sortBy(largestTenantsPlot, 'sqft')
		.reverse()
		.filter((d, i) => i < 6);
	return (
		<PDFPanel
			id="largest-tenants"
			title="Largest Tenants"
			cta={
				<AverageIndicator>
					{`${abbreviateNumber(
						largesTenantssData?.averageSqft
					)} sqft, Average Tenant Size`}
				</AverageIndicator>
			}
		>
			<div className={dashboardStyles.largestTenants}>
				{data.length === 0 && (
					<div>No Data To Display. Update Filters and Try Again.</div>
				)}
				{data.length > 0 && (
					<VictoryScatter
						height={150}
						width={800}
						style={{
							data: { cursor: 'pointer', fill: '#228fff' },
							labels: { fontSize: 10 },
						}}
						size={data.length}
						maxBubbleSize={20}
						minBubbleSize={2}
						bubbleProperty="sqft"
						labelComponent={<VictoryLabel dy={60} />}
						data={data}
					/>
				)}
			</div>
		</PDFPanel>
	);
};
