import {
	IconPromotions,
	ProfileMenu,
	ProfileMenuLink,
} from 'Components/ProfileMenu/UI';
import { useUserCanSeePromotions } from 'api/promotions/promotionQueries';
import { SEARCH_BY_VIEW_ROUTES, routes } from 'router/routes';
import { CompType } from 'types';
import IconMyCompsBuilding from '../../../ui/svg_icons/mycomps_building.svg';
import IconMyCompsSavedSearch from '../../../ui/svg_icons/mycomps_savedsearch.svg';

type MyCompstakProps = {
	closeMenu: () => void;
	compType: CompType;
};

export const MyCompstakMenu = ({ closeMenu, compType }: MyCompstakProps) => {
	const canSeePromos = useUserCanSeePromotions();

	return (
		<ProfileMenu>
			<ProfileMenuLink
				to={`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: 'list' })}?owns=own&isMyComps=true`}
				onClick={closeMenu}
			>
				My Comps
				<IconMyCompsBuilding width="20px" />
			</ProfileMenuLink>
			<ProfileMenuLink to={routes.savedSearches.toHref()} onClick={closeMenu}>
				Saved Searches
				<IconMyCompsSavedSearch width="20px" height="17px" />
			</ProfileMenuLink>
			{canSeePromos && (
				<ProfileMenuLink
					to={routes.promotions.toHref(undefined, {})}
					onClick={closeMenu}
				>
					Promotions
					<IconPromotions width="20px" />
				</ProfileMenuLink>
			)}
		</ProfileMenu>
	);
};
