import { PropertyLease } from 'api';
import styled from 'styled-components';
import { LeaseComp } from 'types';
import { EditPdfTable } from '../EditPdfTable';
import { ExportPdfLeasesStatus } from '../ExportPdfStatus';
import { PropertyEditPage } from '../PropertyEditPage';
import { EditPageSectionTitle } from '../UI';
import { EditableLeaseComp, LeaseStatus, PdfColumn } from '../types';
import { ExportPdfData } from '../useExportPdfData';

type Props = {
	leases: PropertyLease[];
	setEditableLeasesRowsBySpaceType: React.Dispatch<
		React.SetStateAction<Record<string, EditableLeaseComp[]>>
	>;
	setEditableLeasesColumnsBySpaceType: React.Dispatch<
		React.SetStateAction<
			Record<string, PdfColumn<EditableLeaseComp, LeaseComp>[]>
		>
	>;
	editableLeasesRowsBySpaceType: Record<string, EditableLeaseComp[]>;
	editableLeasesColumnsBySpaceType: Record<
		string,
		PdfColumn<EditableLeaseComp, LeaseComp>[]
	>;
	leaseStatus: LeaseStatus;
	data: ExportPdfData;
	leasesSelectionBySpaceType: Record<string, number[]>;
	setLeasesSelectionBySpaceType: React.Dispatch<
		React.SetStateAction<Record<string, number[]>>
	>;
};

export const EditLeasesPage = ({
	leases,
	editableLeasesRowsBySpaceType,
	editableLeasesColumnsBySpaceType,
	setEditableLeasesRowsBySpaceType,
	setEditableLeasesColumnsBySpaceType,
	leaseStatus,
	data,
	leasesSelectionBySpaceType,
	setLeasesSelectionBySpaceType,
}: Props) => {
	return (
		<PropertyEditPage pageKey="leases">
			<LeasesContainer>
				<EditPageSectionTitle>
					<div>Property Lease Transactions ({leases.length})</div>
					<ExportPdfLeasesStatus data={data} />
				</EditPageSectionTitle>
				{Object.entries(editableLeasesRowsBySpaceType).map(
					([spaceType, leasesBySpaceType]) => {
						const selection = leasesSelectionBySpaceType[spaceType];

						const areAllSelected = leasesBySpaceType.every((l) =>
							selection.includes(l.id)
						);

						return (
							<TableContainer key={spaceType}>
								<TableTitle>
									{spaceType}
									{leaseStatus === LeaseStatus.ACTIVE ? ', Active Only ' : ' '}(
									{leasesBySpaceType.length})
								</TableTitle>
								<EditPdfTable
									rows={leasesBySpaceType}
									columns={editableLeasesColumnsBySpaceType[spaceType]}
									onChange={({ row, column, value }) => {
										setEditableLeasesRowsBySpaceType((state) => ({
											...state,
											[spaceType]: state[spaceType].map((l) => {
												if (l.id === row.id) {
													return {
														...l,
														[column.id]: value,
													};
												}
												return l;
											}),
										}));
									}}
									onToggleColumn={({ column }) => {
										setEditableLeasesColumnsBySpaceType((state) => ({
											...state,
											[spaceType]: state[spaceType].map((c) => {
												if (c.id === column.id) {
													return {
														...c,
														isVisible: !c.isVisible,
													};
												}
												return c;
											}),
										}));
									}}
									selection={{
										areAllSelected,
										areSomeSelected: leasesBySpaceType.some((l) =>
											selection.includes(l.id)
										),
										getIsRowSelected: (row) => selection.includes(row.id),
										toggleAllSelected: () => {
											setLeasesSelectionBySpaceType((s) => ({
												...s,
												[spaceType]: areAllSelected
													? []
													: leasesBySpaceType.map((l) => l.id),
											}));
										},
										toggleRow: (row) => {
											const isSelected = selection.includes(row.id);

											setLeasesSelectionBySpaceType((s) => ({
												...s,
												[spaceType]: isSelected
													? s[spaceType].filter((id) => id !== row.id)
													: [...s[spaceType], row.id],
											}));
										},
									}}
								/>
							</TableContainer>
						);
					}
				)}
			</LeasesContainer>
		</PropertyEditPage>
	);
};

const LeasesContainer = styled.div`
	margin-top: 1.5rem;
`;

const TableContainer = styled.div`
	margin-bottom: 1rem;
`;

const TableTitle = styled.div`
	font-size: 0.875rem;
	font-weight: 300;
	line-height: 1;
	margin-bottom: 0.5rem;
`;
