import styled from 'styled-components';

// TODO: These components should be reused between Dashboard & Comp export PDF
// and the related global less files could then deleted
export const ExportPdfContainer = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1300px;
	margin: auto;
	background: #efefef;
	position: relative;
`;

export const ExportPdfToolbarContainer = styled.div`
	width: 100%;
	height: 80px;
	position: fixed;
	top: 60px;
	left: 0;
	z-index: ${(p) => p.theme.zIndex.modal};
`;

export const ExportPdfToolbar = styled.div`
	display: flex;
	align-items: center;
	height: 80px;
	background-color: ${(p) => p.theme.colors.white.white};
	text-align: left;
	border-bottom: 1px solid rgba(31, 34, 43, 0.2);
	padding: 0 1.5rem;
`;

export const ExportPdfToolbarControls = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	min-width: 900px;
	padding: 0 30px;
	box-sizing: border-box;
	align-items: center;
	padding-right: 90px;
`;

export const ExportPdfPage = styled.div`
	margin-bottom: 30px;
	background: #fff;
	width: 100%;
	max-width: 1100px;
	border: 1px solid fade(#50576d, 20%);
	margin: 30px auto;
`;
