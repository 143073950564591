import React, { memo, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import {
	ErrorUI,
	HelpCircle,
	LinesChart,
	numberUtils,
	Pending,
	PeriodSelector,
	RowFlexContainer,
	SaveDataMenu,
	TickFormatter,
	Tooltip,
	WidgetContainer,
	HeightContainer,
	getDownloadFileName,
	Period,
	WidgetTitle,
	Select,
	createTypedLineChartLegend,
} from '@compstak/ui-kit';
import { SubmarketMarketTrendDTO } from 'api';
import { lines, emptyLines } from './auxiliaryData';
import {
	years,
	calcXTicks,
	calcYTicks,
	calcDomainFromTicks,
	getXTickDateFormatter,
} from '../../../../Pages/PropertyPageV2_1/utils';
import {
	getAdaptData,
	getFilteredLines,
} from '../../../../Pages/PropertyPageV2_1/sections/utils';
import {
	MufaChartsNames,
	FiltersNames,
	ChartTypes,
	MetricListItem,
	Metric,
} from 'types/propertyTrendCharts';
import { MufaSubmarketChartProps } from 'Components/MufaSubmarket/MufaSubmarketSection';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';

const adaptData = getAdaptData();

const LineChartLegend = createTypedLineChartLegend<SubmarketMarketTrendDTO>();

type Props = MufaSubmarketChartProps &
	Pick<ReturnType<typeof useMufaTrendsData>['data'], 'submarketMarketTrend'>;

export const SubmarketMarketTrend = memo<Props>(
	({
		address,
		filters,
		handleFilterChange,
		submarketMarketTrend,
		showAnimation = true,
		showSaveDataMenu = true,
		shouldShowTooltip = true,
	}) => {
		const chartFilters = filters[MufaChartsNames.SubmarketMarketTrend];
		const { isFetching, isError, error, data, isSuccess } =
			submarketMarketTrend;

		const chartData = useMemo(() => adaptData(data), [data]);
		const chartId = `${widgetTitle}${address}`;

		const filteredLines = useMemo(
			() => getFilteredLines(chartData, lines, emptyLines),
			[chartData]
		);

		const xTicks = useMemo(
			() => calcXTicks(chartData, chartFilters[FiltersNames.PERIOD]),
			[chartData, chartFilters]
		);

		const yTicks = useMemo(
			() => calcYTicks(ChartTypes['LINE_CHART'], chartData),
			[chartData]
		);

		const yAxisAndTooltipLabel = useMemo(
			() =>
				METRIC_OPTIONS.find(
					({ value }) => value === chartFilters[FiltersNames.METRIC]
				)?.title,
			[chartFilters]
		);

		const handleMetricChange = useCallback(
			// @ts-expect-error TS7006: Parameter 'args' implicitly ha...
			(args) => {
				handleFilterChange(
					MufaChartsNames.SubmarketMarketTrend,
					FiltersNames.METRIC,
					args.selectedItem.value
				);
			},
			[handleFilterChange]
		);

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) =>
				handleFilterChange(
					MufaChartsNames.SubmarketMarketTrend,
					FiltersNames.PERIOD,
					selectedPeriod
				),
			[handleFilterChange]
		);

		return (
			<Root>
				<RowFlexContainer style={rowFlexContainerStyle}>
					<RowFlexContainer>
						<WidgetTitle>{widgetTitle}</WidgetTitle>
						{shouldShowTooltip && (
							<Tooltip
								tooltipComponent={
									<>{metricToTooltipText[chartFilters[FiltersNames.METRIC]]}</>
								}
								placement="bottom-end"
								tooltipRootStyle={tooltipRootStyle}
							>
								<HelpCircle />
							</Tooltip>
						)}
						<Select<MetricListItem>
							items={METRIC_OPTIONS}
							value={chartFilters[FiltersNames.METRIC]}
							onChange={handleMetricChange}
							data-qa-id="select"
						/>
						<PeriodSelector
							period={chartFilters[FiltersNames.PERIOD]}
							onChange={handlePeriodChange}
							years={years}
							data-qa-id="period-selector"
						/>
					</RowFlexContainer>
					{showSaveDataMenu && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, widgetTitle)}
							elementId={chartId}
							dataToCopy={chartData}
							headersMapping={headersMapping}
						/>
					)}
				</RowFlexContainer>
				<HeightContainer margin={HEIGHT_CONTAINER_MARGIN}>
					{isFetching && <Pending times={4} />}
					{isSuccess && (
						<LinesChart
							lines={filteredLines}
							data={chartData}
							yTickFormatter={yTickFormatter}
							xTickFormatter={getXTickDateFormatter(
								chartFilters[FiltersNames.PERIOD]
							)}
							xInterval={0}
							xTicks={xTicks}
							yTicks={yTicks}
							yDomain={calcDomainFromTicks(yTicks)}
							id={chartId}
							yAxisLabel={yAxisAndTooltipLabel}
							tooltipLabel={yAxisAndTooltipLabel}
							tooltipFormatter={tooltipFormatter}
							// @ts-expect-error TS2322: Type 'SuggestedSpan | undefine...
							hasDataForSomePeriod={data?.suggestedSpan}
							isAnimationActive={showAnimation}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<LineChartLegend lines={filteredLines} data={data} />
			</Root>
		);
	}
);

SubmarketMarketTrend.displayName = 'SubmarketMarketTrend';

const widgetTitle = 'Market Trends';

const Root = styled(WidgetContainer)`
	margin: 0;
	width: auto;
`;

const rowFlexContainerStyle = {
	marginBottom: '18px',
};

const HEIGHT_CONTAINER_MARGIN = { bottom: 24 };

export const tooltipRootStyle = {
	margin: '0 16px 0 0',
};

const yTickFormatter: TickFormatter = (value) => {
	return numberUtils.formatCurrency(value, 'currencyTwoDecimals');
};

const tooltipFormatter: TickFormatter = (value) =>
	numberUtils.formatCurrency(value, 'currencyTwoDecimals');

type MarketTrendMetric = Extract<
	Metric,
	'effectiveRentPerSqFt' | 'askingRentPerSqFt'
>;

const metricToTooltipText: Record<MarketTrendMetric, string> = {
	effectiveRentPerSqFt:
		'The average monthly asking rental rate for an apartment, less concessions.',
	askingRentPerSqFt: `The property owner’s average monthly rent, without taking into 
		account concessions or discounts. Rental rates reflect a property’s new lease rent 
		structure at the point of time of the survey, rather than the property’s actual revenues.`,
};

const headersMapping = {
	property: 'Property',
	compSet: 'Comp. Set',
	submarket: 'Submarket',
	name: 'Date',
};

const METRIC_OPTIONS: Array<MetricListItem> = [
	{ title: 'Effective Rent $/SF', value: 'effectiveRentPerSqFt' },
	{ title: 'Asking Rent $/SF', value: 'askingRentPerSqFt' },
];
