import { SelectListItem } from '@compstak/ui-kit';
import {
	YPropValue,
	yPropValuesCommercial,
	yPropValuesMufa,
	yPropValuesSales,
} from '../../../../chartBuilderConstants';
import { DataSetGroupTitles } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import { Market } from '@compstak/common';

const mapMarketToSelectOptions = (market: Market) => {
	return {
		title: market.displayName,
		value: market.name,
		id: market.id,
	};
};

export const mapMarketsToSelectOptionsAndSort = (markets: Market[]) => {
	return markets
		.map(mapMarketToSelectOptions)
		.sort((a, b) => a.title.localeCompare(b.title));
};

export type MarketItem = Required<Pick<SelectListItem, 'title' | 'value'>> & {
	id: number;
};

export const getMarketOptions = (markets: MarketsState) => {
	return markets.list.map(mapMarketToSelectOptions);
};

export const yPropValueToPlotOption = ({ value, label }: YPropValue) => ({
	value: value.y,
	title: label,
});

export const attributeToPlotOptions = {
	commercial: yPropValuesCommercial.map(yPropValueToPlotOption),
	mufa: yPropValuesMufa.map(yPropValueToPlotOption),
	sales: yPropValuesSales.map(yPropValueToPlotOption),
};

export const allAttributeValues = Object.values(attributeToPlotOptions).flat();

export const attributeToPlotValuesToTitles = allAttributeValues.reduce(
	(obj, item) => {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		obj[item.value] = item.title;
		return obj;
	},
	{}
);
export const getAttrOptionsForCreateDSForm = (
	hasMufaAccess: boolean,
	isSalesCompsAnalytics2: boolean
) => {
	const attributeToPlotItems: SelectListItem[] = [
		{
			type: 'header',
			value: DataSetGroupTitles.LEASES,
		},
		...attributeToPlotOptions.commercial,
	];

	if (isSalesCompsAnalytics2) {
		attributeToPlotItems.push(
			{
				type: 'header',
				value: DataSetGroupTitles.SALES,
			},
			...attributeToPlotOptions.sales
		);
	}

	if (hasMufaAccess) {
		attributeToPlotItems.push(
			{
				type: 'header',
				value: DataSetGroupTitles.MUFA,
			},
			...attributeToPlotOptions.mufa
		);
	}

	return attributeToPlotItems;
};
