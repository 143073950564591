import styled from 'styled-components';
import MultiselectOriginal, { MultiselectProps } from '../Multiselect';

export const MapFilterMultiSelect = (props: MultiselectProps) => {
	return <MultiSelect initialExpanded={false} {...props} />;
};

const MultiSelect = styled(MultiselectOriginal)`
	padding: 0.75rem 0.5rem;
`;
