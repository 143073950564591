import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { useColumnsContext } from './Columns';
import { HeaderCell } from './HeaderCell';
import { useLayoutContext } from './LayoutProvider';

export const Header = () => {
	const { columns } = useColumnsContext();
	const { setHeader, headerListRef, bodyWidthWithOffset, headerHeight } =
		useLayoutContext();

	return (
		<VariableSizeList
			width={bodyWidthWithOffset}
			height={headerHeight}
			layout="horizontal"
			itemCount={columns.length}
			itemSize={(index) => columns[index].width}
			itemKey={(index) => columns[index].id}
			style={{
				overflow: 'hidden',
			}}
			ref={(list) => {
				headerListRef.current = list;
				const container = (list as any)?._outerRef;
				setHeader(container);
			}}
			overscanCount={5}
		>
			{cellRenderer}
		</VariableSizeList>
	);
};

const cellRenderer = ({ index, style }: ListChildComponentProps) => (
	<HeaderCell style={style} columnIndex={index} />
);
