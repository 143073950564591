import constate from 'constate';
import { useState, useEffect } from 'react';
import { routes } from 'router';
import { useCompstakEstimatesData } from './hooks/useCompstakEstimatesData';
import { useCompsetData, usePropertyLeasesSummaryQuery } from 'api';
import { useIsExchange } from 'hooks';
import { useShouldShowMarketStats } from './useShouldShowMarketStats';

export const [PropertyPageDataProvider, usePropertyPageDataContext] = constate(
	() => {
		const [isMarketStatsCollapsed, setIsMarketStatsCollapsed] = useState(false);

		const params = routes.propertyById.useParams();

		const isExchange = useIsExchange();

		useEffect(() => {
			setIsMarketStatsCollapsed(false);
		}, [params.propertyId]);

		const compstakEstimates = useCompstakEstimatesData();

		const { data: summary, isLoading: isSummaryLoading } =
			usePropertyLeasesSummaryQuery(params.propertyId);

		const { data: compset, isLoading: isCompsetLoading } = useCompsetData({
			propertyId: params.propertyId,
		});

		const { data: isMarketStatsShown, isLoading: isLoadingShowMarketStats } =
			useShouldShowMarketStats();

		return {
			isMarketStatsCollapsed,
			setIsMarketStatsCollapsed,
			compstakEstimates,
			summary,
			compset,
			isLeaseSnapshotShown:
				summary && compset && (summary.activeLeaseCount || isExchange),
			isMarketStatsShown,
			isLoadingShowMarketStats,
			isLoadingPropertyPageData: isSummaryLoading || isCompsetLoading,
		};
	}
);
