import { LoginLayoutWrapper } from 'Pages/Login/Components/LoginLayoutComponents';
import { TermsAndConditions } from 'Pages/Login/Components/TermsAndConditions';
import { useUser } from 'Pages/Login/reducers';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect } from 'react';
import { useInitUserflow } from 'services/impl/UserFlow';
import { initializeFlags } from 'util/comp-format/attributes';
import { LayoutWithHeader } from './LayoutWithHeader';
import { getUrls } from 'Pages/Login/util';
import { PageSpinner } from 'Layouts/styles/PageSpinner';
import { User } from '@compstak/common';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { useAppSelector } from 'util/useAppSelector';

export const AuthenticatedLayout = () => {
	const authState = useAppSelector((store) => store.authState);
	const user = useUser();
	const flags = useFeatureFlags();
	const navigate = useNavigate();

	useEffect(() => {
		initializeFlags(flags);
	}, [flags]);

	// ensure that non-authorized users get redirected to login page
	useEffect(() => {
		if (authState.checked && !user) {
			navigate(routes.login.path);
		}
	}, [authState.checked, user, navigate]);

	const isAuthenticated =
		user &&
		!user.termsNeeded &&
		!user.privacyPolicyNeeded &&
		authState.succeeded;

	useInitUserflow({ user, isAuthenticated });

	const isRedirectingToAnotherApp = redirectToOtherAppIfNeeded({
		user,
		isAuthenticated,
	});

	// show spinner while non-authorized user gets redirected to home page or authorized user - to another app
	if (!user || isRedirectingToAnotherApp) {
		return <PageSpinner />;
	}

	if (user.termsNeeded || user.privacyPolicyNeeded) {
		return (
			<LoginLayoutWrapper transitionKey={'terms-and-conditions'}>
				<TermsAndConditions />
			</LoginLayoutWrapper>
		);
	}

	return <LayoutWithHeader />;
};

// redirects authorized users to another app if users are enterprise/exchange only
const redirectToOtherAppIfNeeded = ({
	user,
	isAuthenticated,
}: {
	user: User;
	isAuthenticated: boolean;
}) => {
	if (!isAuthenticated || user.userType === 'admin') {
		return false;
	}

	const appConfig = window.appConfig;

	if (user.userType === 'exchange' && !appConfig.isExchange) {
		window.location.href = getUrls().exchange;
		return true;
	}

	if (user.userType !== 'exchange' && appConfig.isExchange) {
		window.location.href = getUrls().enterprise;
		return true;
	}

	return false;
};
