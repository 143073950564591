import styled from 'styled-components';
import { DashboardEditPage } from './DashboardEditPage';
import { useDashboardExportContext } from './DashboardExportProvider';
import { EDIT_COMPONENT_MAP } from './constants';
import { useHalfPanelGroups } from './useHalfPanelGroups';

export const DynamicEditPages = () => {
	const { selectedComponents } = useDashboardExportContext();

	const halfPanelGroups = useHalfPanelGroups({ selectedComponents });

	return (
		<>
			{halfPanelGroups.map((group) => {
				return (
					<DashboardEditPage key={group.join(',')} logoDisplayValueOnly>
						<Row>
							{group.map((section) => {
								const Component = EDIT_COMPONENT_MAP[section];
								return <Component key={section} />;
							})}
						</Row>
					</DashboardEditPage>
				);
			})}
		</>
	);
};

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
`;
