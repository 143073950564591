import React, { memo, useCallback, useMemo, useState } from 'react';
import {
	defaultTheme,
	ErrorUI,
	getDownloadFileName,
	HeightContainer,
	HelpCircle,
	LineDescription,
	LinesChart,
	numberUtils,
	Pending,
	Period,
	PeriodSelector,
	RowFlexContainer,
	SaveDataMenu,
	Select,
	TickFormatter,
	Tooltip,
	WidgetContainer,
	createTypedLineChartLegend,
} from '@compstak/ui-kit';

import {
	RowFlexContainerStyled,
	tooltipRootStyle,
	WidgetTitleStyled,
} from '../../../../Pages/PropertyPageV2_1/sections/Submarket/blocks/UI';
import {
	calcXTicks,
	calcYTicks,
	calcDomainFromTicks,
	getXTickDateFormatter,
	yearsWithForecast,
} from '../../../../Pages/PropertyPageV2_1/utils';
import {
	getAdaptData,
	getFilteredLines,
} from '../../../../Pages/PropertyPageV2_1/sections/utils';
import { OccupancyDTO, OccupancyMetrics } from 'api';
import { ChartTypes } from '../../../../Pages/PropertyPageV2_1/type';
import { addEmptyLines } from '@compstak/common';
import { FiltersNames, MufaChartsNames } from 'types/propertyTrendCharts';
import { MufaSubmarketChartProps } from 'Components/MufaSubmarket/MufaSubmarketSection';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';

const adaptData = getAdaptData();
const LineChartLegend = createTypedLineChartLegend<OccupancyDTO>();

const HEADERS_MAPPING = {
	name: 'Date',
	property: 'Property',
	submarket: 'Submarket',
};

type Props = MufaSubmarketChartProps &
	Pick<ReturnType<typeof useMufaTrendsData>['data'], 'submarketOccupancy'>;

export const SubmarketOccupancy = memo<Props>(
	({
		address,
		filters,
		handleFilterChange,
		submarketOccupancy,
		showAnimation = true,
		showSaveDataMenu = true,
		shouldShowTooltip = true,
	}) => {
		const metric =
			filters[MufaChartsNames.SubmarketOccupancy][FiltersNames.METRIC];
		const period =
			filters[MufaChartsNames.SubmarketOccupancy][FiltersNames.PERIOD];
		const [ref, setRef] = useState<HTMLElement | null>(null);

		const { data, isSuccess, isLoading, isError, error } = submarketOccupancy;

		const chartData = useMemo(() => adaptData(data), [data]);

		const filteredLines = useMemo(
			() => getFilteredLines(chartData, lines, emptyLines),
			[chartData]
		);

		const widgetTitle = 'Occupancy';
		const chartId = `${widgetTitle}${address}`;

		const xTicks = useMemo(
			() => calcXTicks(chartData, period),
			[chartData, period]
		);

		const yTicks = useMemo(
			() =>
				calcYTicks(
					ChartTypes['LINE_CHART'],
					chartData,
					metricToScaleFactor[metric]
				),
			[chartData, metric]
		);

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) =>
				handleFilterChange(
					MufaChartsNames.SubmarketOccupancy,
					FiltersNames.PERIOD,
					selectedPeriod
				),
			[handleFilterChange]
		);

		const handleMetricChange = useCallback(
			// @ts-expect-error TS7006: Parameter 'args' implicitly ha...
			(args) => {
				handleFilterChange(
					MufaChartsNames.SubmarketOccupancy,
					FiltersNames.METRIC,
					args.selectedItem.value
				);
			},
			[handleFilterChange]
		);

		return (
			<WidgetContainer marginBottom={0}>
				<RowFlexContainerStyled>
					<RowFlexContainer>
						<WidgetTitleStyled ref={setRef}>{widgetTitle}</WidgetTitleStyled>
						{shouldShowTooltip && (
							<Tooltip
								tooltipComponent={<>{metricToTooltipText[metric]}</>}
								placement="bottom-end"
								tooltipRootStyle={tooltipRootStyle}
								refToObservePositionChange={ref}
							>
								<HelpCircle />
							</Tooltip>
						)}
						<Select<OccupancyMetricItem>
							items={occupancyMetricItems}
							value={metric}
							onChange={handleMetricChange}
							data-qa-id="select"
						/>
						<PeriodSelector
							period={period}
							onChange={handlePeriodChange}
							years={yearsWithForecast}
							data-qa-id="period-selector"
						/>
					</RowFlexContainer>
					{showSaveDataMenu && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, widgetTitle)}
							elementId={chartId}
							dataToCopy={chartData}
							headersMapping={HEADERS_MAPPING}
						/>
					)}
				</RowFlexContainerStyled>
				<HeightContainer margin={HEIGHT_CONTAINER_MARGIN}>
					{isLoading && <Pending times={4} />}
					{isSuccess && (
						<LinesChart
							lines={filteredLines}
							data={chartData}
							yTickFormatter={getYTickFormatter(metric)}
							xTickFormatter={getXTickDateFormatter(period)}
							tooltipFormatter={tooltipFormatter(metric)}
							xTicks={xTicks}
							yTicks={yTicks}
							yDomain={calcDomainFromTicks(yTicks)}
							xInterval={0}
							yAxisLabel={getLabel(metric)}
							id={chartId}
							// @ts-expect-error TS2322: Type 'SuggestedSpan | undefine...
							hasDataForSomePeriod={data?.suggestedSpan}
							isWithForecastArea
							isAnimationActive={showAnimation}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<LineChartLegend lines={filteredLines} data={data} />
			</WidgetContainer>
		);
	}
);

SubmarketOccupancy.displayName = 'SubmarketOccupancy';

const metricToTooltipText = {
	[OccupancyMetrics.OCCUPANCY]:
		'Percentage of units of a given floorplan physically occupied at a point-in-time.',
	[OccupancyMetrics.NET_OCCUPIED_UNITS]:
		'The change in physically occupied units (existing units multiplied by the occupancy rate) from one period to another.',
};

const HEIGHT_CONTAINER_MARGIN = { bottom: 24 };

const lines: Array<LineDescription> = [
	{
		value: 'property',
		title: 'Property',
		color: `${defaultTheme.colors.green.green500}`,
		type: 'monotone',
	},
	{
		value: 'submarket',
		title: 'Submarket',
		color: `${defaultTheme.colors.blue.blue700}`,
		type: 'monotone',
	},
];

const emptyLines = addEmptyLines(lines);

export type OccupancyMetricItem = {
	value: OccupancyMetrics;
	title: string;
};

const occupancyMetricItems: Array<OccupancyMetricItem> = [
	{ title: 'Occupancy', value: OccupancyMetrics.OCCUPANCY },
	{ title: 'Net Occupied Units', value: OccupancyMetrics.NET_OCCUPIED_UNITS },
];

const getLabel = (metric: OccupancyMetrics) => {
	if (metric === OccupancyMetrics.OCCUPANCY) {
		return 'Occupancy %';
	}
	if (metric === OccupancyMetrics.NET_OCCUPIED_UNITS) {
		return 'Net Occupied Units';
	}
	return 'wrong metric';
};

const metricToScaleFactor = {
	[OccupancyMetrics.OCCUPANCY]: {
		scaleFactorMin: 0.015,
		scaleFactorMax: 0,
	},
	[OccupancyMetrics.NET_OCCUPIED_UNITS]: {
		scaleFactorMin: 0.15,
		scaleFactorMax: 0.15,
	},
};

const tooltipFormatter =
	(metric: OccupancyMetrics): TickFormatter =>
	(value) => {
		if (metric === OccupancyMetrics.OCCUPANCY) {
			return numberUtils.formatPercent(value, 'percentTwoDecimals');
		} else if (metric === OccupancyMetrics.NET_OCCUPIED_UNITS) {
			return numberUtils.formatNumber(value, 'integer');
		}
		return 'wrong metric';
	};

const getYTickFormatter =
	(metric: OccupancyMetrics): TickFormatter =>
	(value) => {
		if (metric === OccupancyMetrics.OCCUPANCY) {
			return numberUtils.formatPercent(value, 'percentInteger');
		} else if (metric === OccupancyMetrics.NET_OCCUPIED_UNITS) {
			return numberUtils.formatNumber(value, 'integer');
		}
		return 'wrong metric';
	};
