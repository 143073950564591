import React from 'react';
import { Link } from 'router';
import { CompPopupActionButton } from './UI';
import { PropertyComp } from 'types/comp';

type Props = {
	propertyId: PropertyComp['id'];
	children: React.ReactNode;
	className?: string;
};

export const PropertyPageLink = ({
	propertyId,
	children,
	className,
}: Props) => {
	return (
		<Link to={`/property/${propertyId}`}>
			<CompPopupActionButton
				data-qa-id="detailed-property-info-btn"
				className={className}
			>
				{children}
			</CompPopupActionButton>
		</Link>
	);
};
