import { TooltipV2 } from '@compstak/ui-kit';
import { CSSProperties, MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { LongPressEventType, useLongPress } from 'use-long-press';
import { useColumnReorderContext } from './ColumnReorder';
import { useColumnResizeContext } from './ColumnResize';
import { useColumnsContext } from './Columns';
import { useOptionsContext } from './OptionsProvider';
import {
	SortTriangle,
	TextOverflow,
	averagesCellCss,
	baseCellCss,
	cellBorders,
} from './UI';
import { HEADER_INNER_CELL_HEIGHT } from './constants';

type Props = {
	style: CSSProperties;
	columnIndex: number;
	isDragPreview?: boolean;
};

export const HeaderCell = ({ style, columnIndex }: Props) => {
	const { startResize, isResizing, activeColumnIndex } =
		useColumnResizeContext();
	const { isDragging, draggedColumnId, insertIndex, startReorder } =
		useColumnReorderContext();
	const { sortName, sortDirection, onSort, showAverages } = useOptionsContext();
	const { columns } = useColumnsContext();

	const column = columns[columnIndex];

	const isThisColumnDragging = isDragging && draggedColumnId === column.id;
	const isThisColumnResizing = isResizing && activeColumnIndex === columnIndex;
	const isSorted = column.sortName === sortName;

	const startSort = () => {
		if (!onSort || !column.isSortable) return;

		const initialSort = column.initialSortDir ?? 'asc';
		const afterSort = initialSort === 'asc' ? 'desc' : 'asc';

		if (isSorted) {
			onSort(sortName, sortDirection === initialSort ? afterSort : initialSort);
		} else {
			onSort(column.sortName, initialSort);
		}
	};

	const bindLongPress = useLongPress(
		(e) => {
			startReorder((e as MouseEvent).clientX, column.id, columnIndex);
		},
		{
			onCancel: startSort,
			threshold: 100,
			detect: LongPressEventType.Mouse,
		}
	);

	if (isThisColumnDragging) {
		return (
			<HeaderCellEmptyShell style={style}>
				<ColumnIndexFinder data-colindex={columnIndex} />
			</HeaderCellEmptyShell>
		);
	}

	const isActive = isSorted || isThisColumnResizing || isThisColumnDragging;

	let sortIcon: ReactNode = null;

	if (isSorted) {
		if (sortDirection === 'asc') {
			sortIcon = <SortTriangle style={{ marginLeft: 'auto' }} />;
		} else {
			sortIcon = (
				<SortTriangle
					style={{ marginLeft: 'auto', transform: 'rotate(180deg)' }}
				/>
			);
		}
	}

	return (
		<HeaderCellRoot
			className="header-cell"
			data-colid={column.id}
			data-colindex={columnIndex}
			style={style}
			{...bindLongPress()}
		>
			<TopCell $isActive={isActive}>
				<TooltipV2 content={column.tooltip}>
					<TextOverflow>{column.label}</TextOverflow>
				</TooltipV2>
				{sortIcon}
				{!isDragging && (
					<ResizeHandle
						onMouseDown={(e) => {
							e.stopPropagation();
							startResize(e, columnIndex);
						}}
					/>
				)}
			</TopCell>
			{showAverages && <BottomCell>{column.renderAverage?.()}</BottomCell>}
			{isDragging && insertIndex === columnIndex && <InsertLine />}
			{isDragging && <ColumnIndexFinder data-colindex={columnIndex} />}
		</HeaderCellRoot>
	);
};

const activeCss = css`
	background-color: ${(p) => p.theme.colors.white.white};
	color: ${(p) => p.theme.colors.blue.blue600};
`;

const TopCell = styled.div<{ $isActive: boolean }>`
	${baseCellCss}
	${cellBorders}
	background-color: ${(p) => p.theme.colors.neutral.n20};
	height: ${HEADER_INNER_CELL_HEIGHT}px;
	font-weight: 500;
	position: relative;
	color: ${(p) => p.theme.colors.neutral.n80};
	border-top: 1px solid ${(p) => p.theme.colors.neutral.n40};
	${(p) => (p.$isActive ? activeCss : undefined)};
`;

const HeaderCellRoot = styled.div`
	user-select: none;
	cursor: pointer;
	&:hover,
	&:active {
		${TopCell} {
			${activeCss}
		}
	}
`;

const BottomCell = styled.div`
	${baseCellCss}
	${averagesCellCss}
  height: ${HEADER_INNER_CELL_HEIGHT}px;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.neutral.n90};
	font-weight: 600;
`;

const ResizeHandle = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 6px;
	height: 100%;
	z-index: 3;
	cursor: col-resize;
	&:hover {
		background-color: ${(p) => p.theme.colors.blue.blue500};
	}
`;

const InsertLine = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: ${(p) => p.theme.colors.blue.blue500};
	z-index: 2;
`;

const ColumnIndexFinder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
`;

const HeaderCellEmptyShell = styled.div`
	${cellBorders}
`;
