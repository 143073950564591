import { StatBox, StatsContainer } from 'ExchangeDashboard/UI';
import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { PanelDescription } from '../PreviewComponents/UI';
import { PDFPanel } from '../UI';

export const AveragesSection = () => {
	const { averages, timeline } = useExchangeDashboardAverages();

	return (
		<PDFPanel id="averages" title="Averages">
			<StatsContainer>
				<StatBox
					property="Starting Rent (PSF)"
					value={averages?.startingRent?.value ?? 'N/A'}
				/>
				<StatBox
					property="Net Effective Rent"
					value={averages?.effectiveRent?.value ?? 'N/A'}
				/>
				<StatBox property="TI Value" value={averages?.ti?.value ?? 'N/A'} />
				<StatBox
					property="Free Rent"
					value={averages?.freeMonths?.value ?? 'N/A'}
				/>
				<StatBox property="Term" value={averages?.leaseTerm?.value ?? 'N/A'} />
			</StatsContainer>
			<PanelDescription>
				Averages encompass leases executed in the last{' '}
				<b>&nbsp;{timeline} months.</b>
			</PanelDescription>
		</PDFPanel>
	);
};
