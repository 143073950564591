import { useScrollbarWidth } from '@compstak/ui-kit';
import {
	createContext,
	MutableRefObject,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { VariableSizeGrid, VariableSizeList } from 'react-window';
import { useRect } from '../../utils';
import { useColumnsContext } from './Columns';
import {
	ACTION_COLUMN_BREAKPOINT,
	ACTION_COLUMN_WIDTH_LARGE,
	ACTION_COLUMN_WIDTH_SMALL,
	DEFAULT_SCROLLBAR_WIDTH,
	HEADER_INNER_CELL_HEIGHT,
	LOAD_MORE_THRESHOLD,
	ROW_HEIGHT,
} from './constants';
import { useOptionsContext } from './OptionsProvider';

type Props = {
	children: ReactNode;
};

/** Table measurements and scrolling live here */
export const LayoutProvider = ({ children }: Props) => {
	const { rows, onLoadMore, isLoading, showAverages } = useOptionsContext();
	const { columns } = useColumnsContext();

	const [root, setRoot] = useState<HTMLElement | null>(null);
	const [body, setBody] = useState<HTMLElement | null>(null);
	const [header, setHeader] = useState<HTMLElement | null>(null);
	const [hScroll, setHScroll] = useState<HTMLElement | null>(null);
	const [vScroll, setVScroll] = useState<HTMLElement | null>(null);
	const actionColumnRef = useRef<HTMLElement | null>(null);

	const rootRect = useRect(root);

	const tableWidth = rootRect?.width ?? 0;
	const tableHeight = rootRect?.height ?? 0;

	const bodyGridRef = useRef<VariableSizeGrid>(null);
	const headerListRef = useRef<VariableSizeList>(null);

	const realScrollbarWidth = useScrollbarWidth();

	const isForcingShowScrollbar = realScrollbarWidth === 0;

	const scrollbarWidth = isForcingShowScrollbar
		? DEFAULT_SCROLLBAR_WIDTH
		: realScrollbarWidth;

	const bodyContentWidth = useMemo(() => {
		return columns.reduce((sum, c) => sum + c.width, 0);
	}, [columns]);
	const bodyContentHeight = rows.length * ROW_HEIGHT;

	const isCompact = useMediaQuery({ maxWidth: ACTION_COLUMN_BREAKPOINT });

	const actionColumnWidth = isCompact
		? ACTION_COLUMN_WIDTH_SMALL
		: ACTION_COLUMN_WIDTH_LARGE;

	const headerHeight = showAverages
		? HEADER_INNER_CELL_HEIGHT * 2
		: HEADER_INNER_CELL_HEIGHT;

	const bodyHeight = tableHeight - headerHeight;
	const bodyWidth = tableWidth - actionColumnWidth;

	const bodyHasHOverflow = bodyContentWidth > bodyWidth;
	const bodyHasVOverflow = bodyContentHeight > bodyHeight;

	const bodyHeightWithOffset =
		bodyHeight - (bodyHasHOverflow ? scrollbarWidth : 0);
	const bodyWidthWithOffset =
		bodyWidth - (bodyHasVOverflow ? scrollbarWidth : 0);

	// handles wheel/touchpad scrolling over the entire width/height of the table
	useEffect(() => {
		if (!root) return;

		const onWheel = (e: WheelEvent) => {
			e.preventDefault();
			const deltaXAbs = Math.abs(e.deltaX);
			const deltaYAbs = Math.abs(e.deltaY);

			if (deltaXAbs > deltaYAbs) {
				if (hScroll) hScroll.scrollLeft += e.deltaX;
			} else if (deltaYAbs > deltaXAbs) {
				if (vScroll) vScroll.scrollTop += e.deltaY;
			}
		};
		root.addEventListener('wheel', onWheel, { passive: false });
		return () => {
			root.removeEventListener('wheel', onWheel);
		};
	}, [root, hScroll, vScroll]);

	const isVScrollNearBottom = useCallback(() => {
		return (
			vScroll &&
			vScroll.scrollHeight - vScroll.scrollTop - vScroll.clientHeight <
				LOAD_MORE_THRESHOLD
		);
	}, [vScroll]);

	const onVScroll = (e: React.UIEvent) => {
		if (!body || !actionColumnRef.current) return;

		const scrollEl = e.currentTarget;
		const scrollTop = scrollEl.scrollTop;

		body.scrollTop = scrollTop;
		actionColumnRef.current.scrollTop = scrollTop;

		if (isVScrollNearBottom()) {
			onLoadMore?.();
		}
	};

	useEffect(() => {
		if (!isLoading) {
			if (isVScrollNearBottom()) {
				onLoadMore?.();
			}
		}
	}, [isLoading, isVScrollNearBottom, onLoadMore]);

	const onHScroll = (e: React.UIEvent) => {
		if (!body || !header || !hScroll) return;

		let scrollLeft = e.currentTarget.scrollLeft;
		const maxBodyScrollLeft = body.scrollWidth - body.clientWidth;
		const maxHeaderScrollLeft = header.scrollWidth - header.clientWidth;
		const minMax = Math.min(maxBodyScrollLeft, maxHeaderScrollLeft);

		if (scrollLeft > minMax) {
			scrollLeft = minMax;
			hScroll.scrollLeft = minMax;
		}

		body.scrollLeft = scrollLeft;
		header.scrollLeft = scrollLeft;
	};

	// reset body and header virtuals if columns are updated
	useEffect(() => {
		const bodyGrid = bodyGridRef.current;
		const headerList = headerListRef.current;

		if (!bodyGrid || !headerList) return;

		bodyGrid.resetAfterColumnIndex(0);
		headerList.resetAfterIndex(0);
	}, [columns]);

	return (
		<LayoutContext.Provider
			value={{
				root,
				tableWidth,
				tableHeight,
				setRoot,
				body,
				setBody,
				header,
				setHeader,
				actionColumnRef,
				hScroll,
				setHScroll,
				vScroll,
				setVScroll,
				onVScroll,
				onHScroll,
				bodyHeightWithOffset,
				bodyWidthWithOffset,
				scrollbarWidth,
				bodyHasHOverflow,
				bodyHasVOverflow,
				bodyContentHeight,
				bodyContentWidth,
				bodyGridRef,
				headerListRef,
				actionColumnWidth,
				isCompact,
				headerHeight,
				rootRect,
				isForcingShowScrollbar,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};

const LayoutContext = createContext({} as LayoutContextValue);

export const useLayoutContext = () => useContext(LayoutContext);

type LayoutContextValue = {
	root: HTMLElement | null;
	tableWidth: number;
	tableHeight: number;
	setRoot: SetElementFn;
	body: HTMLElement | null;
	setBody: SetElementFn;
	header: HTMLElement | null;
	setHeader: SetElementFn;
	actionColumnRef: MutableRefObject<HTMLElement | null>;
	hScroll: HTMLElement | null;
	setHScroll: SetElementFn;
	vScroll: HTMLElement | null;
	setVScroll: SetElementFn;
	onVScroll: OnScrollFn;
	onHScroll: OnScrollFn;
	bodyHeightWithOffset: number;
	bodyWidthWithOffset: number;
	scrollbarWidth: number;
	bodyHasHOverflow: boolean;
	bodyHasVOverflow: boolean;
	bodyContentWidth: number;
	bodyContentHeight: number;
	bodyGridRef: MutableRefObject<VariableSizeGrid | null>;
	headerListRef: MutableRefObject<VariableSizeList | null>;
	actionColumnWidth: number;
	isCompact: boolean;
	headerHeight: number;
	rootRect: DOMRect | null;
	isForcingShowScrollbar: boolean;
};

type OnScrollFn = (e: React.UIEvent) => void;

type SetElementFn = (el: HTMLElement | null) => void;
