/**
 * This is used for modals or select/multiselect dropdowns to detect when user clickes outside of it, so we can close it.
 * This example is taken from the web site: https://usehooks.com/useOnClickOutside/
 */

import { RefObject, useEffect } from 'react';

export default function useOnClickOutside<T extends HTMLElement>(
	ref: RefObject<T>,
	handler: (e: MouseEvent) => void
) {
	useEffect(
		() => {
			const listener = (event: MouseEvent) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
					return;
				}
				handler(event);
			};
			setTimeout(() => {
				document.addEventListener('click', listener);
			}, 0);
			return () => {
				document.removeEventListener('click', listener);
			};
		},
		// Add ref and handler to effect dependencies
		// It's worth noting that because passed in handler is a new ...
		// ... function on every render that will cause this effect ...
		// ... callback/cleanup to run every render. It's not a big deal ...
		// ... but to optimize you can wrap handler in useCallback before ...
		// ... passing it into this hook.
		[ref, handler]
	);
}
