import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { modalActions } from './actions';

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
const mapDispatchToProps = (dispatch) => ({
	modalActions: bindActionCreators(modalActions, dispatch),
});

// @ts-expect-error TS7006: Parameter 'Component' implicit...
export default (Component) => connect(null, mapDispatchToProps)(Component);
