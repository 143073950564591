import React, { ReactNode } from 'react';
import {
	ModalButton,
	ModalButtons,
	ModalScrollableInnerContent,
	ModalParagraph,
	ModalTitle,
} from '../common/UI';
import { useModal } from 'providers/ModalProvider';

export type OurCalculationsModalContext =
	| 'charts'
	| 'marketRent'
	| 'marketSummary';

type OurCalculationsModalProps = {
	context: OurCalculationsModalContext;
};

export const OurCalculationsModal = ({
	context,
}: OurCalculationsModalProps) => {
	const { closeModal } = useModal();

	return (
		<div>
			<ModalTitle>Our calculations</ModalTitle>
			<ModalParagraph>
				Here's how we define and calculate our metrics.
				<br />
				<a
					className="underline"
					data-qa-id="adjusted-rent-calculations-link"
					href="https://support.compstak.com/hc/en-us/sections/26328732794509"
					target="_blank"
					rel="noreferrer"
				>
					Read our white paper
				</a>{' '}
				about all of our definitions.
			</ModalParagraph>
			<ModalScrollableInnerContent>
				{CONTENT_BY_CONTEXT[context]}
			</ModalScrollableInnerContent>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Go Back
				</ModalButton>
			</ModalButtons>
		</div>
	);
};

const CONTENT_BY_CONTEXT: Record<OurCalculationsModalContext, ReactNode> = {
	charts: (
		<div>
			<ModalParagraph>
				<b>Starting Rent</b>
				<div>
					Moving average Starting Rent, grossed up for Office; net for
					Industrial; unadjusted for all other space types
				</div>
			</ModalParagraph>
			<ModalParagraph>
				<b>Effective Rent</b>
				<div>
					Moving average Effective Rent, grossed up for Office; net for
					Industrial; unadjusted for all other space types
				</div>
			</ModalParagraph>
			<ModalParagraph>
				<b>Free Rent</b>
				<div>Moving average Free Rent</div>
			</ModalParagraph>
			<ModalParagraph>
				<b>Tenant Improvement</b>
				<div>Moving average Tenant Improvement</div>
			</ModalParagraph>
			<ModalParagraph>
				<b>Lease Term</b>
				<div>Moving average Lease Term</div>
			</ModalParagraph>
		</div>
	),
	marketRent: (
		<div>
			<ModalParagraph>
				<b>Starting Rent</b>
				<div>Starting Rent grossed up for Office; net for Industrial</div>
			</ModalParagraph>
			<ModalParagraph>
				<b>Effective Rent</b>
				<div>Effective Rent grossed up for Office; net for Industrial</div>
			</ModalParagraph>
		</div>
	),
	marketSummary: (
		<div>
			<ModalParagraph>
				<b>Average Starting Rent</b>
				<div>
					Weighted average of starting rent by transaction size (starting rent *
					size) / size
				</div>
			</ModalParagraph>

			<ModalParagraph>
				<b>Average Effective Rent</b>
				<div>
					Weighted average of effective rent by transaction size (effective rent
					* size) / size
				</div>
			</ModalParagraph>

			<ModalParagraph>
				<b>Average Tenant Improvement Allowance</b>
				<div>Average TIA, excluding nulls and 0s</div>
			</ModalParagraph>

			<ModalParagraph>
				<b>Average Free Months</b>
				<div>
					Average free months' share of lease term, aka free rent ratio of term
					(free months / lease term)
				</div>
			</ModalParagraph>

			<ModalParagraph>
				<b>Average Lease Term</b>
				<div>Average lease term in months</div>
			</ModalParagraph>

			<ModalParagraph>
				<b>Leasing Activity by Market</b>
				<div>Sum of transaction size (rolling 4 quarter average)</div>
			</ModalParagraph>

			<ModalParagraph>
				<b>Leasing Activity by Submarket, Industry, or Building Class</b>
				<div>
					Ratio of segment's total by entire market (rolling 4 quarter average)
				</div>
			</ModalParagraph>

			<ModalParagraph>
				Ex) If the Financial Services Industry made up 10,000 sqft of a market
				and all comps in the market totaled 100,000 sqft, the ratio would be
				10%.
			</ModalParagraph>
		</div>
	),
};

OurCalculationsModal.displayName = 'MarketSummaryMethodologyModal';
