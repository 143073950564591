import React from 'react';

import styles from './drawer.less';

type State = any;

export default class FloorSelector extends React.PureComponent<{}, State> {
	constructor(props: {}) {
		super(props);

		const floorTypes = [
			'mezzanine',
			'subbasement',
			'basement',
			'lower level',
			'lower ground',
			'concourse',
		];
		let isOtherFloorType = false;
		let radioFloorType = '';

		let labelOrFloor = '';
		let labelText;

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.floor !== null && 'label' in this.props.floor) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			labelOrFloor = this.props.floor.label;
			labelText = 'Label';
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		} else if ('floor' in this.props.space) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
			labelOrFloor = this.props.space.floor;
			labelText = 'Floor';
		} else {
			labelText = 'Floor';
		}

		if (floorTypes.includes(labelOrFloor.toLowerCase())) {
			isOtherFloorType = true;
			radioFloorType = labelOrFloor.toLowerCase();
		}

		this.state = {
			label: labelOrFloor,
			isOtherFloorType,
			radioFloorType,
			labelText,
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleCheckboxChange = (event) => {
		this.setState({
			isOtherFloorType: event.target.checked,
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleRadioChange = (event) => {
		this.setState({
			radioFloorType: event.target.value,
			label: event.target.value,
		});
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'updateFloorLabel' does not exist on type... Remove this comment to see the full error message
		this.props.updateFloorLabel(event.target.value);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	sendParentFloorNumber = (event) => {
		this.setState({
			label: event.target.value,
		});

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'updateFloorLabel' does not exist on type... Remove this comment to see the full error message
		this.props.updateFloorLabel(event.target.value);
	};

	render() {
		let disabledStyle;
		let radioButtons;
		if (this.state.isOtherFloorType === true) {
			disabledStyle = {
				backgroundColor: '#d3d3d3',
			};

			radioButtons = (
				<div>
					<div>
						<input
							name="otherFloorTypeRadio"
							type="radio"
							checked={this.state.radioFloorType.toLowerCase() === 'mezzanine'}
							value="Mezzanine"
							id="mezzanine"
							onChange={this.handleRadioChange}
							className="radio"
						/>
						<label htmlFor="mezzanine">Mezzanine</label>
					</div>

					<div>
						<input
							name="otherFloorTypeRadio"
							type="radio"
							checked={
								this.state.radioFloorType.toLowerCase() === 'subbasement'
							}
							value="Subbasement"
							id="subbasement"
							onChange={this.handleRadioChange}
							className="radio"
						/>
						<label htmlFor="subbasement">Subbasement</label>
					</div>

					<div>
						<input
							name="otherFloorTypeRadio"
							type="radio"
							checked={this.state.radioFloorType.toLowerCase() === 'basement'}
							value="Basement"
							id="basement"
							onChange={this.handleRadioChange}
							className="radio"
						/>
						<label htmlFor="basement">Basement</label>
					</div>

					<div>
						<input
							name="otherFloorTypeRadio"
							type="radio"
							checked={
								this.state.radioFloorType.toLowerCase() === 'lower level'
							}
							value="Lower Level"
							id="lowerlevel"
							onChange={this.handleRadioChange}
							className="radio"
						/>
						<label htmlFor="lowerlevel">Lower Level</label>
					</div>

					<div>
						<input
							name="otherFloorTypeRadio"
							type="radio"
							checked={
								this.state.radioFloorType.toLowerCase() === 'lower ground'
							}
							value="Lower Ground"
							id="lowerground"
							onChange={this.handleRadioChange}
							className="radio"
						/>
						<label htmlFor="lowerground">Lower Ground</label>
					</div>

					<div>
						<input
							name="otherFloorTypeRadio"
							type="radio"
							checked={this.state.radioFloorType.toLowerCase() === 'concourse'}
							value="Concourse"
							id="concourse"
							onChange={this.handleRadioChange}
							className="radio"
						/>
						<label htmlFor="concourse">Concourse</label>
					</div>
				</div>
			);
		}

		let message;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'labelAlreadyExists' does not exist on ty... Remove this comment to see the full error message
		if (this.props.labelAlreadyExists) {
			message = 'Floor labels must be unique.';
		}

		return (
			<div>
				<label>
					{this.state.labelText}
					<input
						onChange={this.sendParentFloorNumber}
						type="text"
						name="label"
						style={disabledStyle}
						disabled={this.state.isOtherFloorType}
						value={this.state.label}
					/>
				</label>
				<div className={styles.messageText}>{message}</div>

				<label htmlFor="floorTypeCheckbox">
					<input
						name="isOtherFloorType"
						id="floorTypeCheckbox"
						type="checkbox"
						checked={this.state.isOtherFloorType}
						onChange={this.handleCheckboxChange}
						className="checkbox"
					/>
					<span />
					<span className={styles.otherFloorType}>Other Floor Type</span>
				</label>

				{radioButtons}
			</div>
		);
	}
}
