import { FiltersObject } from 'models/filters/types';
import { init, reset, showUpgradeModal, swapCache } from 'actions/filters';
import { Action } from 'redux';
import { AppState } from 'reducers/root';
import { CompType } from 'types';
import { BOOTSTRAP, BootstrapAction } from 'Pages/Login/actions';
import { getPromiseActionType } from 'types/redux-promise-middleware';
import { setMarkets } from 'actions/user';
import actionWrapper from 'util/actionWrapper';
import { Market } from '@compstak/common';
import { MarketsState, PermissionsState } from 'Pages/Login/reducers';
import { isMarketAccessible } from 'util/marketAccessUtils';
import { setFilters } from 'models/filters/util/setFilters';
import {
	filtersFromQueryParams,
	getParamsFromQueryString,
} from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { createFilters } from 'models/filters';
import { isEmpty } from 'lodash';
import { getSetFilters } from 'models/filters/util/getSetFilters';
import { LOCATION_CHANGE } from 'redux-first-history';
import { Middleware } from 'redux';

export const DISABLE_FILTERS_RESET = 'DISABLE_FILTERS_RESET';

export type LocationChangeRouterAction = Action<typeof LOCATION_CHANGE> & {
	payload: {
		location: {
			pathname: string;
			query: Record<string, string>;
			state?: Record<string, unknown>;
			search: string;
		};
	};
};

type ProcessedAction = BootstrapAction | LocationChangeRouterAction;

let prevCompType: CompType | null = null;

/**
 * Sets filters on BootstrapAction | LocationChangeRouterAction depending on:
 * - URL params
 * - user preferences (affects filters market/markets only)
 * - permissions (affects filters market/markets only)
 * Side effects on BootstrapAction | LocationChangeRouterAction:
 * - filters are reset if queryParams are not empty (@see DISABLE_FILTERS_RESET can disable this behaviour)
 * - swap filter cache if compType has changed
 * - automatically changes user market preferences if needed
 * - shows upgrade modal if any markets are not allowed
 */
export default function filtersFromRoutesMiddleware(): Middleware<
	{},
	AppState
> {
	return (store) => (next) => (action: ProcessedAction) => {
		const pathname = getPathname(action);
		const isFullfilledBootstrapAction =
			action.type === getPromiseActionType(BOOTSTRAP, 'FULFILLED');
		if (
			(isLocationChangeRouterAction(action) &&
				(pathname.startsWith('/search') || pathname.startsWith('/home'))) ||
			isFullfilledBootstrapAction
		) {
			const reduxStore = store.getState();
			const markets = isFullfilledBootstrapAction
				? action.payload.markets
				: reduxStore.markets;
			const user = isFullfilledBootstrapAction
				? action.payload.user
				: reduxStore.user!;
			const permissions = isFullfilledBootstrapAction
				? action.payload.permissions
				: reduxStore.permissions;

			if (!markets || !markets.list?.length || !user || isEmpty(permissions)) {
				return next(action);
			}

			const queryParams = getQueryParams(action);

			const dispatch = store.dispatch;
			const {
				init: initAction,
				setMarkets: setMarketsAction,
				reset: resetAction,
				swapCache: swapCacheAction,
				showUpgradeModal: showUpgradeModalAction,
			} = actionWrapper(
				{ init, setMarkets, reset, showUpgradeModal, swapCache },
				dispatch
			);

			const compType = getCompType(action);

			const filters = filtersFromQueryParams({
				compType,
				markets,
				queryParams,
				extraOptions: {
					ignoreErrors: true,
					warnOnErrors: true,
				},
			});

			const multiMarketMode = true;
			const userMarket = markets[user.preferences.currentMarketId];
			const userMarkets = user.preferences.currentMarketIds.length
				? user.preferences.currentMarketIds.map((marketId) => markets[marketId])
				: [userMarket];

			const mainFilters = getMarketFilters({
				filters,
				multiMarketMode,
				// @ts-expect-error TS2345: Argument of type '{ filters: F...
				userMarket,
				userMarkets,
			});

			const {
				accesibleFilters: accessibleMainFilters,
				nonAccessibleMarkets: nonAccessibleMainMarkets,
			} = getAccessibleFilters({
				multiMarketMode,
				compType,
				permissions,
				filters: mainFilters,
				marketsState: markets,
			});

			const isCurrentSetMainFiltersEmpty = isEmpty(
				getSetFilters(reduxStore.filtersV2.cache[compType] ?? {})
			);

			const isCurrentSetExchangeFiltersEmpty = isEmpty(
				getSetFilters(reduxStore.filtersV2['exchange-dashboard'] ?? {})
			);

			if (isCurrentSetMainFiltersEmpty || isCurrentSetExchangeFiltersEmpty) {
				const exchangeFilters = !isCurrentSetExchangeFiltersEmpty
					? reduxStore.filtersV2['exchange-dashboard']
					: getMarketFilters({
							filters: createFilters('lease'),
							multiMarketMode: false,
							userMarket,
						});
				const {
					accesibleFilters: accessibleExchangeFilters,
					nonAccessibleMarkets: nonAccessibleExchangeMarkets,
				} = getAccessibleFilters({
					multiMarketMode,
					compType,
					permissions,
					filters: exchangeFilters,
					marketsState: markets,
				});

				initAction({
					main: accessibleMainFilters,
					'exchange-dashboard': accessibleExchangeFilters,
				});

				if (nonAccessibleExchangeMarkets.length) {
					showUpgradeModalAction(
						'exchange-dashboard',
						nonAccessibleExchangeMarkets
					);
				}
			} else if (
				!isEmpty(queryParams) &&
				isLocationChangeRouterAction(action) &&
				!action.payload.location.state?.[DISABLE_FILTERS_RESET]
			) {
				resetAction('main', accessibleMainFilters);
			} else if (prevCompType !== compType) {
				swapCacheAction(compType);
			} else {
				return next(action);
			}

			const accessibleFiltersMarkets = getFiltersMarkets(accessibleMainFilters);
			// if markets in preferences are not identical - then rewrite the preferences
			if (multiMarketMode) {
				if (
					userMarkets.length !== accessibleFiltersMarkets.length ||
					!userMarkets.every((market) =>
						accessibleFiltersMarkets.includes(market)
					)
				) {
					setMarketsAction(user, accessibleFiltersMarkets, true);
				}
			} else if (userMarket !== accessibleMainFilters.market) {
				setMarketsAction(user, accessibleFiltersMarkets, false);
			}

			if (nonAccessibleMainMarkets.length) {
				showUpgradeModalAction('main', nonAccessibleMainMarkets);
			}

			prevCompType = compType;
		}

		return next(action);
	};
}

const getPathname = (action?: ProcessedAction) => {
	return action && isLocationChangeRouterAction(action)
		? action.payload.location.pathname
		: window.location.pathname;
};

export const getCompType = (action?: ProcessedAction) => {
	const pathname = getPathname(action);

	let compType: CompType = 'lease';

	if (pathname.startsWith('/search/sales')) {
		compType = 'sale';
	} else if (pathname.startsWith('/search/properties')) {
		compType = 'property';
	}

	return compType;
};

const getQueryParams = (action: ProcessedAction) => {
	return isLocationChangeRouterAction(action)
		? getParamsFromQueryString(action.payload.location.search)
		: getParamsFromQueryString(window.location.search);
};

const isLocationChangeRouterAction = (
	action: ProcessedAction
): action is LocationChangeRouterAction => {
	return action.type === LOCATION_CHANGE;
};

type getFiltersArg = { filters: FiltersObject } & (
	| { multiMarketMode: false; userMarket: Market }
	| { multiMarketMode: true; userMarkets: Market[] }
);

const getMarketFilters = (args: getFiltersArg) => {
	const multiMarketMode = args.multiMarketMode;
	let marketFilters = args.filters;

	if (multiMarketMode) {
		const prevSingleMarket = marketFilters.market;
		const userMarkets = args.userMarkets;
		// @ts-expect-error TS2345: Argument of type 'null' is not...
		marketFilters = setFilters(marketFilters, 'market', null);

		const markets = marketFilters.markets
			? getFiltersMarkets(marketFilters)
			: userMarkets;

		// If multi markets existed in query params or preferences then apply them when either:
		// - single-market is absent
		// - single-market is present in the multi market selection made before
		// otherwise:
		// - apply single-market selection
		const filtersMarkets = prevSingleMarket
			? markets.includes(prevSingleMarket)
				? markets
				: [prevSingleMarket]
			: markets;
		marketFilters = setFilters(marketFilters, 'markets', filtersMarkets);
	} else {
		const userMarket = args.userMarket;
		// @ts-expect-error TS2345: Argument of type 'null' is not...
		marketFilters = setFilters(marketFilters, 'markets', null);
		if (!marketFilters.market) {
			marketFilters = setFilters(marketFilters, 'market', userMarket);
		}
	}

	return marketFilters;
};

const findMarketWithAccess = (
	compType: CompType,
	permissions: PermissionsState,
	markets: MarketsState
) => {
	for (const marketId in permissions) {
		if (isMarketAccessible(marketId, compType, permissions)) {
			return markets[marketId];
		}
	}
};

type GetAccessibleFiltersArgs = {
	marketsState: MarketsState;
	multiMarketMode: boolean;
	compType: CompType;
	permissions: PermissionsState;
	filters: FiltersObject;
};

const getAccessibleFilters = ({
	marketsState,
	multiMarketMode,
	compType,
	permissions,
	filters,
}: GetAccessibleFiltersArgs): {
	accesibleFilters: FiltersObject;
	nonAccessibleMarkets: Market[];
} => {
	const fallbackNYMarket = marketsState[1];

	if (multiMarketMode) {
		const markets = getFiltersMarkets(filters);
		const nonAccessibleMarkets: Record<number, Market> = {};
		for (const market of markets) {
			if (!isMarketAccessible(market.id, compType, permissions)) {
				nonAccessibleMarkets[market.id] = market;
			}
		}

		if (!Object.keys(nonAccessibleMarkets).length) {
			return { accesibleFilters: filters, nonAccessibleMarkets: [] };
		}

		const accessibleMarkets = markets.filter(
			(market) => !nonAccessibleMarkets[market.id]
		);

		if (accessibleMarkets.length > 0) {
			return {
				accesibleFilters: setFilters(filters, 'markets', accessibleMarkets),
				nonAccessibleMarkets: Object.values(nonAccessibleMarkets),
			};
		} else {
			const marketWithAccess = findMarketWithAccess(
				compType,
				permissions,
				marketsState
			);
			return {
				accesibleFilters: setFilters(
					filters,
					'markets',
					marketWithAccess ? [marketWithAccess] : [fallbackNYMarket]
				),
				nonAccessibleMarkets: Object.values(nonAccessibleMarkets),
			};
		}
	} else {
		const market = filters.market;
		if (isMarketAccessible(market.id, compType, permissions)) {
			return { accesibleFilters: filters, nonAccessibleMarkets: [] };
		}

		const marketWithAccess = findMarketWithAccess(
			compType,
			permissions,
			marketsState
		);
		return {
			accesibleFilters: setFilters(
				filters,
				'market',
				marketWithAccess ?? fallbackNYMarket
			),
			nonAccessibleMarkets: [market],
		};
	}
};
