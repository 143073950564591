import React from 'react';

import CompPartial from './CompPartial';
import UploadIcon from '../../../../ui/svg_icons/upload.svg';
import LockIcon from '../../../../ui/svg_icons/lock_outlined.svg';

import styles from '../styles/details.less';
import button from 'ui/styles/button.less';
import barStyles from '../styles/suggestionBar.less';
import updateStyles from '../styles/suggestAnUpdate.less';
import { CompType } from 'types';

// @ts-expect-error TS7031: Binding element 'length' impli...
function SaleVerifyBar({ length, pageActions }) {
	const className = length > 0 ? null : barStyles.opaque;
	return (
		<div className={`${barStyles.suggestionBar} ${className}`}>
			<span>Update missing information for this transaction and </span>
			<span className={barStyles.underlined}>earn 30 credits</span>
			<span
				onClick={pageActions.showVerifyComp}
				style={{ background: 'white', color: '#50b87f' }}
				className={`${button.button}`}
			>
				<UploadIcon />
				Suggest an Update
			</span>
		</div>
	);
}

function SaleVerify({
	// @ts-expect-error TS7031: Binding element 'compSuggestio...
	compSuggestion,
	// @ts-expect-error TS7031: Binding element 'handleCompSug...
	handleCompSuggestionInput,
	// @ts-expect-error TS7031: Binding element 'pageActions' ...
	pageActions,
	// @ts-expect-error TS7031: Binding element 'sendCompSugge...
	sendCompSuggestion,
}) {
	const content = (
		<div>
			Our real-estate analysts will review the update and <br />
			add it to our database
		</div>
	);

	return (
		<div className={updateStyles.suggestAnUpdate}>
			<table className={updateStyles.leftSide}>
				<tbody>
					<tr>
						<td>
							<UploadIcon />
						</td>
						<td>
							<h3>Update missing info or report a new transaction!</h3>
						</td>
					</tr>
					<tr>
						<td />
						<td>{content}</td>
					</tr>
					<tr>
						<td>
							<LockIcon />
						</td>
						<td>
							<h3>Anonymous</h3>
						</td>
					</tr>
					<tr>
						<td />
						<td>
							<div>
								Comp updates are anonymous and secure. <br />
								We respect your privacy.
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div className={updateStyles.rightSide}>
				<textarea
					value={compSuggestion}
					className={updateStyles.leaseSuggestion}
					onChange={handleCompSuggestionInput}
					placeholder="Type or copy & paste your update here"
				/>
				<div className={updateStyles.buttonContainer}>
					<span onClick={pageActions.hideVerifyComp} className={button.gray}>
						Cancel
					</span>
					<span onClick={sendCompSuggestion} className={button.green}>
						Send Update
					</span>
				</div>
			</div>
		</div>
	);
}

type CompVerifyState = any;

type CompVerifyProps = {
	compType: CompType;
};

export default class CompVerify extends React.Component<
	CompVerifyProps,
	CompVerifyState
> {
	constructor(props: CompVerifyProps) {
		super(props);

		this.state = {
			compSuggestion: '',
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleCompSuggestionInput(event) {
		this.setState({
			compSuggestion: event.target.value,
		});
	}

	sendCompSuggestion() {
		if (this.state.compSuggestion.trim() === '') {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'feedbackActions' does not exist on type ... Remove this comment to see the full error message
			this.props.feedbackActions.addFeedback('Please enter a reason first');
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			const id = this.props.comp.id;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.pageActions.sendCompSuggestion(
				id,
				this.state.compSuggestion,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
				this.props.comp.market,
				this.props.compType
			);
		}
	}

	verifyButton() {
		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'compVerificationSent' does not exist on ... Remove this comment to see the full error message
			!this.props.compVerificationSent &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.userType === 'exchange' &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.comp.own === true
		) {
			return (
				<div
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
					onClick={this.props.pageActions.showVerifyComp}
					className={`${button.button} ${styles.buttonTiny} ${styles.buttonVerify}`}
				>
					Suggest an Update
				</div>
			);
		} else if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'compVerificationSent' does not exist on ... Remove this comment to see the full error message
			!this.props.compVerificationSent &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.userType !== 'exchange'
		) {
			return (
				<div
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
					onClick={this.props.pageActions.showVerifyComp}
					className={`${button.button} ${styles.buttonTiny} ${styles.buttonVerify}`}
				>
					Suggest an Update
				</div>
			);
		} else {
			return false;
		}
	}

	verifyText() {
		const { compType } = this.props;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'appConfig' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.appConfig.isExchange) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			if (this.props.comp.versions === 1) {
				return (
					<p>
						CompStak user has verified this{' '}
						<span className={styles.hideAtSmall}>{compType}</span> data
					</p>
				);
			} else {
				return (
					<p>
						CompStak users have verified this{' '}
						<span className={styles.hideAtSmall}>{compType}</span> data
					</p>
				);
			}
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		} else if (this.props.comp.versions === 1) {
			return <p>This {compType} data was received from one source</p>;
		} else {
			return (
				<p>
					This {compType} data was received from{' '}
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
					<span className={styles.versions}> {this.props.comp.versions} </span>{' '}
					different sources
				</p>
			);
		}
	}

	formSendText() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'appConfig' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.appConfig.isExchange) {
			return 'Send Correction';
		} else {
			return 'Send Update';
		}
	}

	formPlaceholder() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'appConfig' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.appConfig.isExchange) {
			return 'Please include what information you believe is incorrect...';
		} else {
			return 'Please include what information you would like to update...';
		}
	}

	renderCompPartial() {
		const verifyButton = this.verifyButton();
		// @ts-expect-error ts-migrate(2769) FIXME: Property 'verifyButton' does not exist on type 'In... Remove this comment to see the full error message
		return <CompPartial {...this.props} verifyButton={verifyButton} />;
	}

	renderCompVerify() {
		const verifyButton = this.verifyButton();
		const verifyText = this.verifyText();

		return (
			<div className={this.className()}>
				<div className={styles.leftSide}>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
					<span className={styles.number}>{this.props.comp.versions}</span>
					{verifyText}
				</div>
				<div className={styles.rightSide}>{verifyButton}</div>
			</div>
		);
	}

	className() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		return this.props.comp.partial
			? styles.partialMessage
			: styles.verification;
	}

	render() {
		const formSendText = this.formSendText();
		const formPlaceholder = this.formPlaceholder();

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'verifyComp' does not exist on type 'Read... Remove this comment to see the full error message
		if (this.props.verifyComp) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'isSale' does not exist on type 'Readonly... Remove this comment to see the full error message
			if (this.props.isSale) {
				return (
					// @ts-expect-error ts-migrate(2741) FIXME: Property 'pageActions' is missing in type '{ child... Remove this comment to see the full error message
					<SaleVerify
						compSuggestion={this.state.compSuggestion}
						handleCompSuggestionInput={this.handleCompSuggestionInput.bind(
							this
						)}
						sendCompSuggestion={this.sendCompSuggestion.bind(this)}
						{...this.props}
					/>
				);
			}
			return (
				<div className={this.className() + ' ' + styles.expanded}>
					<div className={styles.leftSide}>
						<h3>What is missing or incorrect in this comp?</h3>
					</div>
					<div className={styles.rightSide}>
						<textarea
							value={this.state.compSuggestion}
							onChange={this.handleCompSuggestionInput.bind(this)}
							className={styles.leaseSuggestion}
							placeholder={formPlaceholder}
						/>
						<div className={styles.buttonContainer}>
							<span
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'pageActions' does not exist on type 'Rea... Remove this comment to see the full error message
								onClick={this.props.pageActions.hideVerifyComp}
								className={button.gray}
							>
								Cancel
							</span>
							<span
								onClick={this.sendCompSuggestion.bind(this)}
								className={button.blue}
							>
								{formSendText}
							</span>
						</div>
					</div>
				</div>
			);
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'isSale' does not exist on type 'Readonly... Remove this comment to see the full error message
			if (this.props.isSale) {
				// @ts-expect-error ts-migrate(2739) FIXME: Type '{ children?: ReactNode; }' is missing the fo... Remove this comment to see the full error message
				return <SaleVerifyBar {...this.props} />;
			}
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			const { comp } = this.props;
			return comp.partial && comp.dataSource !== 'Public Record'
				? this.renderCompPartial()
				: this.renderCompVerify();
		}
	}
}
