import {
	useCopyPortfolioMutation,
	useCreatePortfolioMutation,
	useUpdatePortfolioMutation,
} from 'api';

export const usePortfolioActions = () => {
	const { mutateAsync: createPortfolio, isLoading: isLoadingCreate } =
		useCreatePortfolioMutation();
	const { mutateAsync: updatePortfolio, isLoading: isLoadingUpdate } =
		useUpdatePortfolioMutation();
	const { mutateAsync: copyPortfolio, isLoading: isLoadingCopy } =
		useCopyPortfolioMutation();

	return {
		copyPortfolio,
		updatePortfolio,
		createPortfolio,
		isLoading: isLoadingCreate || isLoadingUpdate || isLoadingCopy,
	};
};
