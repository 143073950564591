import React from 'react';
import { styled } from 'styled-components';
import { Button } from '@compstak/ui-kit';

type SidebarHeaderProps = {
	filterCount: number;
	onNewSearchClick: () => void;
};

export const SidebarHeader = ({
	filterCount,
	onNewSearchClick,
}: SidebarHeaderProps) => {
	return (
		<SidebarHeaderContainer>
			<SidebarHeaderTitle>
				Filters
				{filterCount > 0 && <FilterCounter>{filterCount}</FilterCounter>}
			</SidebarHeaderTitle>
			<NewSearchButton onClick={onNewSearchClick} variant="primary" v2={true}>
				New Search
			</NewSearchButton>
		</SidebarHeaderContainer>
	);
};

const SidebarHeaderContainer = styled.div`
	background: ${(p) => p.theme.colors.gray.gray700};
	position: sticky;
	top: 0;
	z-index: ${(p) => p.theme.zIndex.menu};
	padding: 0.5rem 1.25rem 0 1.25rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 3.5rem;
	margin: 0;
`;

const SidebarHeaderTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	flex: 1 0 0;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 2rem;
`;

const FilterCounter = styled.div`
	border-radius: 6.25rem;
	background: ${(p) => p.theme.colors.gray.gray200};
	color: ${(p) => p.theme.colors.gray.gray700};
	display: flex;
	min-width: 1.5rem;
	padding: 0 0.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	text-align: center;
	font-size: ${(p) => p.theme.typography.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
`;

const NewSearchButton = styled(Button)`
	font-size: 0.75rem;
	padding: 0.25rem 0.5rem;
	&:hover {
		color: ${(p) => p.theme.colors.white.white};
	}
`;
