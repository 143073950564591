import { FiltersObject } from 'models/filters/types';
import chartBuilderService from 'services/chartBuilder';
import marketsService from 'services/markets';
import * as config from '../config';
import { addFeedback } from 'Singletons/Feedback/actions';
import { Market } from '@compstak/common';
import {
	Chart,
	ChartFromServer,
	DataSet,
	DataSetFromServer,
	DataSetType,
} from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import { MAX_DATASETS_ALLOWED, DEFAULT_CHART } from './chartBuilderConstants';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import {
	filtersFromServerJSON,
	filtersToServerJSON,
} from 'models/filters/util';
import { Submarket } from 'api/submarkets/useSubmarketsByMarket';
import { AppDispatch, AppGetState } from 'store';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

export const CHART_DRAFT_LOADED = 'CHART_DRAFT_LOADED';
export const CHART_DRAFT_LOADING = 'CHART_DRAFT_LOADING';
export const CHART_DRAFT_UPDATED = 'CHART_DRAFT_UPDATED';
export const CHART_DRAFT_DELETED = 'CHART_DRAFT_DELETED';
export const EXPAND_DATA_SET = 'EXPAND_DATA_SET';
export const CHART_BUILDER_UPDATED = 'CHART_BUILDER_UPDATED';
export const PROJECT_UPDATED = 'PROJECT_UPDATED';
export const RESET_CHART_BUILDER = 'RESET_CHART_BUILDER';
export const CHART_DRAFT_ERROR = 'CHART_DRAFT_ERROR' as const;

export function serverChartToChart(
	markets: MarketsState,
	chart: ChartFromServer | null
): Chart {
	const completeChart: ChartFromServer = chart
		? {
				...DEFAULT_CHART,
				...chart,
			}
		: { ...DEFAULT_CHART };

	const dataSets: DataSet[] = completeChart.dataSets.map(
		(dataset: DataSetFromServer) => ({
			...dataset,
			filters: ((): FiltersObject => {
				const conversionOptions = {
					ignoreErrors: true,
					warnOnErrors: true,
				};
				if (dataset.type === DataSetType.MUFA) {
					dataset.filters = dataset.filters?.filter(
						// TODO: Why are these filters received from BE?!
						(f) => !['spaceTypeId', 'hidden'].includes(f.property)
					);
					return filtersFromServerJSON(
						'mufa',
						markets,
						dataset.filters,
						conversionOptions
					);
				} else if (dataset.type === DataSetType.SALES) {
					return filtersFromServerJSON(
						'sale',
						markets,
						dataset.filters,
						conversionOptions
					);
				}

				return filtersFromServerJSON(
					'lease',
					markets,
					dataset.filters,
					conversionOptions
				);
			})(),
		})
	);

	return {
		...completeChart,
		dataSets,
	};
}

export function chartToServerChart(chart: Chart): ChartFromServer {
	return {
		...chart,
		dataSets: chart.dataSets.map((dataset) => ({
			...dataset,
			filters: filtersToServerJSON(dataset.filters),
			type: dataset.type ?? DataSetType.COMMERCIAL,
		})),
	};
}

type ChartDraftLoadingAction = { type: typeof CHART_DRAFT_LOADING };
type ChartDraftLoadedAction = {
	type: typeof CHART_DRAFT_LOADED;
	payload: Chart;
};

type ChartDraftErrorAction = ReturnType<typeof chartDraftErrorAction>;

export const loadChartDraft =
	(markets: MarketsState) => (dispatch: AppDispatch) => {
		dispatch({
			type: CHART_DRAFT_LOADING,
		});
		chartBuilderService
			.load()
			.then((draft) => {
				const chartData = serverChartToChart(markets, draft);
				// Remove execution date and expiration date filters from chartBuilder AP-17069
				chartData.dataSets = chartData.dataSets.map((ds) => {
					if (ds.type !== DataSetType.COMMERCIAL) return ds;
					return {
						...ds,
						filters: {
							...ds.filters,
							executionDate: null,
							expirationDate: null,
						},
					};
				});
				dispatch({
					type: CHART_DRAFT_LOADED,
					payload: chartData,
				});
			})
			.catch((err) => {
				dispatch(
					chartDraftErrorAction({
						statusCode: err.status,
						message: JSON.parse(err.response),
					})
				);
			});
	};

const chartDraftErrorAction = (data: {
	statusCode: number;
	message: { error: string };
}) => {
	return {
		type: CHART_DRAFT_ERROR,
		payload: { statusCode: data.statusCode, message: data.message.error },
	};
};

type ChartDraftUpdatedAction = {
	type: typeof CHART_DRAFT_UPDATED;
	payload: Chart;
};
export const updateChartDraft =
	(chartDraft: Chart, markets: MarketsState, onSuccess?: () => void) =>
	(dispatch: AppDispatch) => {
		chartBuilderService.updateDraft(chartToServerChart(chartDraft)).then(
			(data) => {
				if (onSuccess) {
					onSuccess();
				}
				return dispatch({
					type: CHART_DRAFT_UPDATED,
					payload: serverChartToChart(markets, data),
				});
			},
			() => {
				dispatch(addFeedback('Chart update unsuccessful', 'error', null, 5000));
			}
		);
	};

type DeleteChartDraftAction = {
	type: typeof CHART_DRAFT_DELETED;
	chartId: number;
};
export const deleteChartDraft = () => (dispatch: AppDispatch) => {
	chartBuilderService.deleteDraft().then(() => {
		dispatch({
			type: CHART_DRAFT_DELETED,
		});
	});
};

function tooManyDataSetsWarningFeedbackAction() {
	return addFeedback(
		`Chart cannot contain more than ${MAX_DATASETS_ALLOWED} data sets`,
		'error',
		null,
		5000
	);
}

export const addDataSet =
	(
		existingDraft: null | Chart,
		name: string,
		filters: Partial<FiltersObject>,
		markets: MarketsState,
		series: AttributeToPlotType = 'startingRent',
		extraOptions?: { dataSetType?: DataSetType }
	) =>
	(dispatch: AppDispatch) => {
		const newDataSet: DataSet = {
			name,
			series,
			filters: filters as FiltersObject,
			isVisible: true,
			type: extraOptions?.dataSetType ?? DataSetType.COMMERCIAL,
		};
		dispatch(addDataSets(existingDraft, [newDataSet], markets));
	};

export const addDataSets =
	(
		existingDraft: null | Chart,
		newDataSets: DataSet[],
		markets: MarketsState
	) =>
	(dispatch: AppDispatch) => {
		if (!Array.isArray(newDataSets)) {
			console.error('newDataSets must be an array!');
			return;
		}
		if (newDataSets.length === 0) {
			console.error('newDataSets must have a length greater than 0!');
			return;
		}
		const draft = existingDraft || DEFAULT_CHART;
		if (draft.dataSets.length + newDataSets.length > MAX_DATASETS_ALLOWED) {
			dispatch(tooManyDataSetsWarningFeedbackAction());
			return;
		}
		const newDraft = {
			...draft,
			dataSets: [...draft.dataSets, ...newDataSets],
		};
		dispatch(updateChartDraft(newDraft, markets));
	};

export const updateDataSet =
	(
		chart: Chart,
		newDataSet: DataSet,
		markets: MarketsState,
		onSuccess?: () => void
	) =>
	(dispatch: AppDispatch) => {
		const newChartState = {
			...chart,
			dataSets: chart.dataSets.map((d) =>
				d.id === newDataSet.id ? newDataSet : d
			),
		};
		dispatch(updateChartDraft(newChartState, markets, onSuccess));
	};

export const removeDataSet =
	(chart: Chart, dataSetId: number) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		if (chart.dataSets.length === 1) {
			dispatch(deleteChartDraft());
		} else {
			const markets = getState().markets;

			chartBuilderService.deleteDataSet(dataSetId).then((updatedDraft) => {
				dispatch({
					type: CHART_DRAFT_UPDATED,
					payload: serverChartToChart(markets, updatedDraft),
				});
			});
		}
	};

type ExpandDataSetAction = {
	type: typeof EXPAND_DATA_SET;
	dataSetIndex: number | null;
};

export const expandDataSet =
	(dataSetIndex: number | null) => (dispatch: AppDispatch) => {
		dispatch({
			type: EXPAND_DATA_SET,
			dataSetIndex,
		});
	};

export const deleteChartDraftAndAddDataSets =
	(dataSets: DataSet[], markets: MarketsState) => (dispatch: AppDispatch) => {
		if (dataSets.length > MAX_DATASETS_ALLOWED) {
			dispatch(tooManyDataSetsWarningFeedbackAction());
		}
		const chartDraft = {
			...DEFAULT_CHART,
			dataSets,
		};
		dispatch(updateChartDraft(chartDraft, markets));
	};

export const updateDataSetFilters =
	(
		dataSetId: number | undefined,
		updatedFilter: Partial<FiltersObject>,
		draft: Chart,
		markets: MarketsState,
		dataSetChanges?: Partial<DataSet>
	) =>
	(dispatch: AppDispatch) => {
		if (!dataSetId) {
			return;
		}
		const updatedDraft = {
			...draft,
			dataSets: draft.dataSets.map((dataSet) => {
				if (dataSet.id === dataSetId) {
					return {
						...dataSet,
						filters: mergeFilters(dataSet.filters, updatedFilter),
						...dataSetChanges,
					};
				} else {
					return dataSet;
				}
			}),
		};
		dispatch(updateChartDraft(updatedDraft, markets));
	};

type ResetChartBuilderAction = {
	type: typeof RESET_CHART_BUILDER;
	chartDraft: Chart;
};
export const resetChartBuilder =
	(chart: Chart, markets: MarketsState) => (dispatch: AppDispatch) => {
		chartBuilderService
			.load()
			.then((draft) => {
				if (draft) {
					return chartBuilderService.deleteDraft();
				}
			})
			.then(() => {
				// TODO: change backend to have separate routes for creating and updating
				const newDraft = { ...chart, originalChartId: chart.id, id: null };
				const formattedNewDraft = chartToServerChart(newDraft);
				chartBuilderService.updateDraft(formattedNewDraft).then((draft) => {
					const formattedDraft = serverChartToChart(markets, draft);
					dispatch({
						type: RESET_CHART_BUILDER,
						chartDraft: formattedDraft,
					});
				});
			});
	};

const loadDefaultChart = (
	chart: ChartFromServer,
	dispatch: AppDispatch,
	markets: MarketsState
) => {
	chartBuilderService.updateDraft(chart).then((draft) => {
		dispatch({
			type: CHART_DRAFT_UPDATED,
			payload: serverChartToChart(markets, draft),
		});
	});
};

export const createFirstDefaultChart =
	(markets: MarketsState) => (dispatch: AppDispatch) => {
		const chartData = {
			timespan: 60,
			trendMonths: 3,
			title: 'Starting Rents, NYC vs. SF vs. LA vs. Chicago CBD, Office',
			chartType: 'line',
			dataSets: [
				config.dataSetNYC,
				config.dataSetSF,
				config.dataSetLA,
				config.dataSetCHI,
			],
		} as ChartFromServer;
		loadDefaultChart(chartData, dispatch, markets);
	};

export const createSecondDefaultChart =
	(
		currentMarket: Market,
		markets: MarketsState,
		marketSubmarkets: Submarket[]
	) =>
	(dispatch: AppDispatch) => {
		marketsService.getTopSubmarkets(currentMarket.id).then((submarkets) => {
			const dataSets: DataSetFromServer[] = submarkets.map((submarketName) => {
				const submarketId = marketSubmarkets.find(
					({ name }) => name === submarketName
				)?.id;

				return {
					name: submarketName,
					filters: [
						{ property: 'marketName', value: currentMarket.name },
						{ property: 'spaceTypeId', value: [1] },
						{
							property: 'submarketId',
							value: submarketId ? [submarketId] : [],
						},
						{ property: 'hidden', value: false },
					],
					series: 'startingRent',
					isVisible: true,
					type: DataSetType.COMMERCIAL,
				};
			});
			const chartData: ChartFromServer = {
				timespan: 60,
				trendMonths: 3,
				title: `Starting Rents, ${currentMarket.displayName} Submarkets, Office`,
				chartType: 'line',
				dataSets,
			};
			loadDefaultChart(chartData, dispatch, markets);
		});
	};

export const createThirdDefaultChart =
	(currentMarket: Market, markets: MarketsState) => (dispatch: AppDispatch) => {
		const chartData = {
			timespan: 60,
			trendMonths: 3,
			title: `Lease Terms By Building Class in ${currentMarket.displayName}`,
			chartType: 'line',
			dataSets: config.buildingClassDataSets(currentMarket.name),
		} as ChartFromServer;
		loadDefaultChart(chartData, dispatch, markets);
	};

export type ChartBuilderAction =
	| ChartDraftLoadingAction
	| ChartDraftLoadedAction
	| ChartDraftUpdatedAction
	| DeleteChartDraftAction
	| ExpandDataSetAction
	| ResetChartBuilderAction
	| ChartDraftErrorAction;

export const chartBuilderActions = {
	loadChartDraft,
	updateChartDraft,
	deleteChartDraft,
	addDataSet,
	addDataSets,
	updateDataSet,
	removeDataSet,
	expandDataSet,
	deleteChartDraftAndAddDataSets,
	updateDataSetFilters,
	resetChartBuilder,
	createFirstDefaultChart,
	createSecondDefaultChart,
	createThirdDefaultChart,
};

export type ChartBuilderActions = typeof chartBuilderActions;
