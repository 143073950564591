import styled from 'styled-components';
import { TableOverlay } from '../UI';
import { useColumnResizeContext } from './ColumnResizeProvider';

export const ColumnResizeOverlay = () => {
	const { isResizing, overlayHeight, overlayLeft } = useColumnResizeContext();

	if (!isResizing) return null;

	return (
		<TableOverlay>
			<GuideLine
				style={{
					height: overlayHeight,
					left: overlayLeft,
				}}
			/>
		</TableOverlay>
	);
};

const GuideLine = styled.div`
	position: absolute;
	top: 0;
	width: 1.5px;
	background-color: ${(p) => p.theme.colors.blue.blue500};
	z-index: 2;
`;
