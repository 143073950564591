import LandingPageTokenTransfer from 'Layouts/LandingPageTokenTransfer';
import ResetPassword from 'Pages/ResetPassword';
import Upgrade from 'Pages/Upgrade';
import { Location } from 'history';
import { useIsExchange } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { ModalPageContainer } from './ModalPageContainer';
import { isModalPath } from './isModalPath';
import { routeWrapper } from './migration/routeWrapper';
import { ROUTE_COMPONENTS } from './routeComponents';
import { deprecatedRoutes } from './routes/deprecatedRoutes';
import { RouteKey, routes } from './routes/routes';
import { useLinkInterceptor } from './useLinkInterceptor';
import { Redirect } from './Redirect';
import { CreateAccountPage } from 'Pages/CreateAccount/CreateAccountPage';
import { BootstrapLayout } from 'Layouts/BootstrapLayout/BootstrapLayout';
import { AuthenticatedLayout } from 'Layouts/AuthenticatedLayout/AuthenticatedLayout';
import { UnauthenticatedLayout } from 'Layouts/UnauthenticatedLayout/UnauthenticatedLayout';
import { AlwaysAvailableLayout } from 'Layouts/AlwaysAvailableLayout/AlwaysAvailableLayout';
import { LoginLayout } from 'Pages/Login/Login';

const AuthenticatedRoute = routeWrapper(AuthenticatedLayout);
const UnauthenticatedRoute = routeWrapper(UnauthenticatedLayout);
const AlwaysAvailableRoute = routeWrapper(AlwaysAvailableLayout);
const LoginLayoutRoute = routeWrapper(LoginLayout);
const ResetPasswordRoute = routeWrapper(ResetPassword);
const CreateAccountRoute = routeWrapper(CreateAccountPage);
const LandingPageTokenRoute = routeWrapper(LandingPageTokenTransfer);
const UpgradeRoute = routeWrapper(Upgrade);

export const RoutesComponent = () => {
	useLinkInterceptor();
	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;
	const location = useLocation();
	const prevLocation = useRef<Location>(location);

	const isModal = useMemo(() => {
		return isModalPath(location.pathname);
	}, [location.pathname]);

	const bgLocation = useMemo(() => {
		let _bgLocation: Location | undefined = location;

		if (isModal) {
			const isPrevModal = isModalPath(prevLocation.current.pathname);

			_bgLocation = isPrevModal ? undefined : prevLocation.current;
		}

		return _bgLocation;
	}, [location, isModal]);

	useEffect(() => {
		if (isModal) return;

		prevLocation.current = location;
	}, [isModal, location]);

	return (
		<BootstrapLayout>
			{bgLocation && (
				<Routes location={bgLocation}>
					<Route element={<UnauthenticatedRoute />}>
						<Route path={routes.login.path} element={<LoginLayoutRoute />} />
						<Route
							path={routes.forgotPassword.path}
							element={<LoginLayoutRoute />}
						/>
						<Route
							path={routes.resetPassword.path}
							element={<ResetPasswordRoute />}
						/>
						<Route
							path={routes.createAccount.path}
							element={<CreateAccountRoute />}
						/>
					</Route>

					<Route element={<AuthenticatedRoute />}>{renderRoutes()}</Route>

					<Route element={<AlwaysAvailableRoute />}>
						{isEnterprise && (
							<Route path={routes.upgrade.path} element={<UpgradeRoute />} />
						)}
						<Route
							path={routes.landingpagetoken.path}
							element={<LandingPageTokenRoute />}
						/>
					</Route>

					<Route
						path={deprecatedRoutes.compById.path}
						element={<Redirect to={routes.leaseById.path} />}
					/>
					{isExchange && (
						<>
							<Route
								path={deprecatedRoutes.getGredits.path}
								element={<Navigate to={routes.credits.path} />}
							/>
							<Route
								path={deprecatedRoutes.settingsCredits.path}
								element={<Navigate to={routes.credits.path} />}
							/>
							<Route
								path={deprecatedRoutes.settingsRewards.path}
								element={<Navigate to={routes.rewards.path} />}
							/>
						</>
					)}
					<Route path="*" element={<Navigate to={routes.home.path} />} />
				</Routes>
			)}
			{isModal && (
				<Routes>
					<Route element={<AuthenticatedRoute />}>{renderRoutes(true)}</Route>
				</Routes>
			)}
		</BootstrapLayout>
	);
};

const renderRoutes = (isModal = false) => {
	let pairs = Object.entries(ROUTE_COMPONENTS);
	if (isModal) {
		pairs = pairs.filter(([key]) => {
			const route = routes[key as RouteKey];
			return route.isModal;
		});
	}

	return pairs.map(([key, Component]) => {
		const route = routes[key as RouteKey];
		const data = route.data;

		let toRender = <Component route={data} />;
		if (isModal && route.isLegacyModal) {
			toRender = <ModalPageContainer>{toRender}</ModalPageContainer>;
		}

		return (
			<Route
				{...data}
				key={key}
				path={routes[key as RouteKey].path}
				element={toRender}
			/>
		);
	});
};
