import { requestDemo } from 'actions/user';
import { useUser } from 'Pages/Login/reducers';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CompType } from 'types';
import pluralizeCompType from 'util/pluralizeCompType';
import {
	ModalButton,
	ModalButtons,
	ModalScrollableInnerContent,
	ModalTitle,
} from '../common/UI';
import { useMarkets } from 'hooks/useMarkets';

type MultiMarketUpgradeModalProps = {
	compType: CompType;
	selectedMarkets: number[];
	onClose: NoArgCallback;
};

export const MultiMarketUpgradeModal = memo(
	(props: MultiMarketUpgradeModalProps) => {
		const [requestSent, setRequestSent] = useState(false);

		const user = useUser();
		const markets = useMarkets();

		const dispatch = useDispatch();

		const requestDemoHandler = () => {
			dispatch(requestDemo(user.username, 'change market', null));
			setRequestSent(true);
		};

		return (
			<div>
				<ModalTitle>Upgrade Your Subscription</ModalTitle>
				<MultiMarketUpgradeModalContainer>
					<div>
						You are unable to view {pluralizeCompType(props.compType, false)}{' '}
						for the following markets:
					</div>
					<ModalScrollableInnerContent>
						<ul className="market-list">
							{props.selectedMarkets.map((id) => (
								<li key={id}>{markets[id].displayName}</li>
							))}
						</ul>
					</ModalScrollableInnerContent>
					<div>
						To get access to these markets, reach out to our team to upgrade
						your subscription.
					</div>
				</MultiMarketUpgradeModalContainer>
				<ModalButtons>
					<ModalButton variant="secondary" onClick={props.onClose}>
						Go back
					</ModalButton>
					{requestSent ? (
						<ConfirmationText>
							Thanks! We'll contact you shortly
						</ConfirmationText>
					) : (
						<ModalButton variant="primary" onClick={requestDemoHandler}>
							Request Upgrade
						</ModalButton>
					)}
				</ModalButtons>
			</div>
		);
	}
);

MultiMarketUpgradeModal.displayName = 'MultiMarketUpgradeModal';

const MultiMarketUpgradeModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	.market-list {
		list-style-type: disc;
		list-style-position: inside;
	}
`;

const ConfirmationText = styled.span`
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-transform: uppercase;
`;
