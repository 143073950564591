import { usePortfolioByIdQuery } from 'api';
import { MarketRentAnalyticsProvider } from 'maps';
import { MarketTrendComponent as MarketTrend } from 'PortfolioAnalytics/MarketTrend';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import LeaseExpiration from '../LeaseExpiration';
import { MarketRent } from '../MarketRent';
import { MarketRentProvider } from '../MarketRent/MarketRentProvider';
import { MarketsChartsContainer } from '../styles/PortfolioUI';

type Props = {
	portfolioId: number;
};

export const MarketsTab = ({ portfolioId }: Props) => {
	const { filters } = usePortfolioFilters();
	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	if (!portfolio) return null;

	return (
		<MarketRentAnalyticsProvider>
			<MarketRentProvider portfolio={portfolio}>
				<MarketsChartsContainer>
					<MarketRent portfolioId={portfolioId} portfolio={portfolio} />
					<MarketTrend portfolio={portfolio} filters={filters} />
					<LeaseExpiration portfolio={portfolio} filters={filters} />
				</MarketsChartsContainer>
			</MarketRentProvider>
		</MarketRentAnalyticsProvider>
	);
};
