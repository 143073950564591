import { MapAnalyticsMetric } from 'maps';
import { useState } from 'react';
import { createSimpleProvider } from 'utils';

export const {
	Provider: SpaceTypeAnalyticsProvider,
	useProviderContext: useSpaceTypeAnalyticsState,
} = createSimpleProvider({
	useValue: () => useState(initialState),
});

export type SpaceTypeAnalyticsView = 'read' | 'settings';

export type SpaceTypeAnalyticsState = {
	view: SpaceTypeAnalyticsView;
	metric: MapAnalyticsMetric;
	title: string;
};

const initialState: SpaceTypeAnalyticsState = {
	view: 'read',
	metric: 'sqft',
	title: 'Space Type',
};
