import { useDeletePortfolioMutation } from 'api';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';

type Props = {
	portfolioId: number;
};

export const DeletePortfolioDialog = ({ portfolioId }: Props) => {
	const { mutateAsync: deletePortfolio, isLoading } =
		useDeletePortfolioMutation();

	const { closeModal } = useModal();

	return (
		<>
			<ModalCenteredContent>
				<ModalTitle>Delete Portfolio</ModalTitle>
				<Body>
					Are you sure you want to delete the portfolio? All portfolio details
					will be permanently removed.
				</Body>
				<ModalButtons>
					<ModalButton variant="secondary" type="button" onClick={closeModal}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="error"
						disabled={isLoading}
						isLoading={isLoading}
						style={{
							transition: 'none',
						}}
						onClick={async () => {
							await deletePortfolio({ id: portfolioId });

							closeModal();
						}}
					>
						Delete Portfolio
					</ModalButton>
				</ModalButtons>
			</ModalCenteredContent>
		</>
	);
};

const Body = styled.div`
	font-size: 1rem;
	padding-bottom: 1rem;
`;
