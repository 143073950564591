import styled from 'styled-components';

export const highlightText = (text: string, highlight: string) => {
	if (!highlight) return text;

	const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
	return parts.map((part, index) =>
		part.toLowerCase() === highlight.toLowerCase() ? (
			<Strong key={index}>{part}</Strong>
		) : (
			part
		)
	);
};

export const Strong = styled.strong`
	font-weight: 800;
`;
