import { SearchTableExportButton } from 'Components';
import { SearchTableUnlockButton } from 'Components/SearchTable/UnlockButton';
import { LeaseComp, SalesComp } from 'types';

type Props = {
	type: 'lease' | 'sale';
	row: LeaseComp | SalesComp;
};

export const ExportOrUnlockButton = ({ type, row }: Props) => {
	if (row.own) {
		return <SearchTableExportButton compType={type} row={row} />;
	} else {
		return <SearchTableUnlockButton type={type} row={row} />;
	}
};
