import React from 'react';
import ReactTimeout, { ReactTimeoutProps } from 'react-timeout';

import styles from 'Components/Header/header.less';

type Props = {
	value: number;
	label: string;
	showZeroValue?: boolean;
} & ReactTimeoutProps;

type State = {
	valueChange: 0 | string;
	previousValue: number | null;
};

export default ReactTimeout(
	class AnimatingNumber extends React.PureComponent<Props, State> {
		state: State = {
			valueChange: 0,
			previousValue: null,
		};

		componentDidUpdate(prevProps: Props) {
			if (this.props.value !== prevProps.value) {
				const valueChangeNum = this.props.value - prevProps.value;
				const valueChange = (valueChangeNum > 0 ? '+' : '') + valueChangeNum;

				this.setState({
					valueChange,
					previousValue: prevProps.value,
				});

				this.props.setTimeout?.(() => {
					this.setState({
						valueChange: 0,
					});
				}, 2000);

				this.props.setTimeout?.(() => {
					this.setState({
						previousValue: null,
					});
				}, 1000);
			}
		}

		render() {
			let className = styles.animatingNumber;

			if (
				this.props.value <= 0 &&
				!this.state.valueChange &&
				!this.props.showZeroValue
			) {
				className += ' ' + styles.zeroValue;
			}

			if (this.state.valueChange) {
				className += ' ' + styles.numberChangeAnimating;
			}

			const valueToDisplay =
				this.state.previousValue === null
					? this.props.value
					: this.state.previousValue;

			return (
				<span className={className}>
					<span className={styles.numberChangeAnimation}>
						<div className={styles.notificationCircle} />
						<div className={styles.numberChangeValue}>
							{this.state.valueChange}
						</div>
					</span>
					<span className={styles.currentValue}>
						<span className={styles.circle}>{valueToDisplay}</span>{' '}
						{this.props.label}
					</span>
				</span>
			);
		}
	}
);
