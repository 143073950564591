import { SaveChartModal } from './Save/SaveChartModal';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useDispatch } from 'react-redux';
import { deleteChartDraft } from '../../actions';
import { useModal } from 'providers/ModalProvider';
import { Chart } from 'Pages/Analytics/analytics';

type Props = {
	chartDraft: Chart;
};

export const ClearAnalyticsModal = ({ chartDraft }: Props) => {
	const dispatch = useDispatch();

	const { openModal, closeModal } = useModal();

	const onContinue = () => {
		dispatch(deleteChartDraft());
		closeModal();
	};

	const onSaveAndContinue = () => {
		closeModal();

		const onSave = () => dispatch(deleteChartDraft());
		openModal({
			modalContent: <SaveChartModal onSave={onSave} chartDraft={chartDraft} />,
		});
	};

	return (
		<>
			<ModalTitle>Create New Chart</ModalTitle>
			<ModalParagraph>
				By creating a new chart, you will lose all progess on your current
				chart. Would you like to save your changes or continue with a new, blank
				chart?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={onContinue}>
					Continue
				</ModalButton>
				<ModalButton onClick={onSaveAndContinue}>Save and Continue</ModalButton>
			</ModalButtons>
		</>
	);
};
