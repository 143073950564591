import { useEffect, useRef } from 'react';
import { LeaseExpirationLayer } from './LeaseExpirationLayer';
import { useLeaseExpirationState } from './LeaseExpirationProvider';
import { useLeaseExpirationData } from './useLeaseExpirationData';
import { incrementExpireInMonths, getLeaseExpirationPeriod } from './utils';
import { useFilters } from 'reducers/filtersReducer';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';

const ID = 'LeaseExpirationAnalyticsLayer';

export const LeaseExpirationAnalytics = () => {
	const [state, setState] = useLeaseExpirationState();
	const [, setFilters] = useFilters();

	const { data } = useLeaseExpirationData();

	const playIntervalRef = useRef<NodeJS.Timer | null>(null);

	useEffect(() => {
		if (state.isPlaying) {
			playIntervalRef.current = setInterval(() => {
				setState((s) => ({
					...s,
					expireInMonths: incrementExpireInMonths(s.expireInMonths),
				}));
			}, 2000);
		}
	}, [state.isPlaying, setState]);

	useEffect(() => {
		if (!state.isPlaying && playIntervalRef.current) {
			clearInterval(playIntervalRef.current);
		}
	}, [state.isPlaying]);

	useEffect(() => {
		setFilters({
			expirationDate: {
				allowFallback: false,
				...getLeaseExpirationPeriod(state.expireInMonths),
			},
		});
	}, [state.expireInMonths, setFilters]);

	useEffect(() => {
		return () => {
			if (playIntervalRef.current) {
				clearInterval(playIntervalRef.current);
			}
		};
	}, []);

	return (
		<LeaseExpirationLayer data={data} id={ID} order={MAP_LAYERS_ORDER[ID]} />
	);
};
