import { PropertyLease } from 'api';
import { LeaseComp } from 'types';
import { getIsLeaseCompActive } from 'utils/getIsLeaseCompActive';
import { LeaseStatus } from './types';

export const getLeasesByStatus = (
	leaseStatus: LeaseStatus,
	leases: PropertyLease[] | LeaseComp[]
) => {
	return leaseStatus === LeaseStatus.ALL
		? leases
		: leases.filter((l) => getIsLeaseCompActive(l));
};
