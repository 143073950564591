import { API } from '@compstak/common';
import { SearchPayload, SEARCH_INFINITE_PAGE_SIZE } from 'api';

type Args<ResponseType extends { totalCount: number }> = {
	url: string;
	getResponseSize: (res: ResponseType) => number;
};

export type InfiniteSearchResponse<
	ResponseType extends { totalCount: number },
> = ResponseType & {
	pageParam: number;
	hasMore: boolean;
	nextPageSize: number;
};

export const createInfiniteSearchFetchFn =
	<ResponseType extends { totalCount: number }>({
		url,
		getResponseSize,
	}: Args<ResponseType>) =>
	async ({
		pageParam,
		filter,
		sort,
		order,
		properties,
		signal,
	}: SearchInfiniteParams & {
		pageParam: number;
		signal?: AbortSignal;
	}): Promise<InfiniteSearchResponse<ResponseType>> => {
		const res = await API.post<ResponseType>(
			url,
			{
				offset: pageParam * SEARCH_INFINITE_PAGE_SIZE,
				limit: SEARCH_INFINITE_PAGE_SIZE,
				filter,
				sort,
				order,
				properties,
			},
			{ signal }
		);

		const lastReqCount = getResponseSize(res.data);

		const totalCountSoFar =
			pageParam * SEARCH_INFINITE_PAGE_SIZE + lastReqCount;

		const hasMore = res.data.totalCount > totalCountSoFar;

		const nextPageSize = Math.min(
			res.data.totalCount - totalCountSoFar,
			SEARCH_INFINITE_PAGE_SIZE
		);

		return { ...res.data, pageParam, hasMore, nextPageSize };
	};

export type SearchInfiniteParams = Omit<SearchPayload, 'offset' | 'limit'>;
