import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';

const attribute = 'buildingSize' as const;

type Props = {
	inputId?: string;
};

export const BuildingSizeFilter = ({ inputId }: Props) => {
	return (
		<FilterBase attribute={attribute} inputId={inputId}>
			{(props) => {
				return (
					<Between
						attribute={attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						setError={props.setError}
						touch={props.touch}
					/>
				);
			}}
		</FilterBase>
	);
};
