import React from 'react';
import { ExchangePanel, LoadingChart } from './UI';
import { MovingAverageSelector } from 'Components/Graphs/Selectors';
import { selectorStyles } from 'util/reactSelectStyles';
import { Waypoint } from 'react-waypoint';
import { StartingRentChart } from './StartingRentChart';
import styled from 'styled-components';
import { useExchangeDashboardStartingRent } from './hooks/startingRent/useExchangeDashboardStartingRent';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';

export const StartingRent = () => {
	const [view, setView] = React.useState<'visible' | 'hidden'>('hidden');
	const { fullPageState } = useExchangeDashboardState();
	const {
		startingRentData,
		isFetching,
		monthly,
		movingAverage,
		setMovingAverage,
	} = useExchangeDashboardStartingRent({
		enabled: view === 'visible' && fullPageState === null,
	});

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				id="starting-rent"
				title="Starting Rent Trend"
				cta={
					<SelectorContainer>
						<div className="side-label">Mov. Average</div>
						<MovingAverageSelector
							onChange={setMovingAverage}
							value={movingAverage}
							data-qa-id="dashboard-starting-rent-moving-average-selector"
							styles={{ ...selectorStyles }}
						/>
					</SelectorContainer>
				}
				tooltip="Chart reflects moving average starting rent for lease transactions in selected market and submarket(s)."
			>
				{isFetching && <LoadingChart />}
				{!isFetching &&
					(startingRentData && startingRentData.length > 0 ? (
						<StartingRentChart
							startingRentData={startingRentData}
							monthly={monthly}
						/>
					) : (
						<div>No Data To Display. Update Filters and Try Again.</div>
					))}
			</ExchangePanel>
		</Waypoint>
	);
};

const SelectorContainer = styled.div`
	width: 130px;
	.side-label {
		color: rgba(0, 0, 0, 0.6);
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 500;
	}
`;
