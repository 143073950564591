import {
	MapAnalyticsFormSection,
	MapAnalyticsMetric,
	MapAnalyticsMetricRadioGroup,
	MapAnalyticsSectionTitle,
	useTenantIndustryState,
} from 'maps';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ArrowLeftIcon from 'ui/svg_icons/arrow_left_2.svg';
import { focusFilterSection } from '../../actions';
import { MapAnalyticsSettingsCloseButton } from '../MapAnalyticsSettingsCloseButton';
import {
	FilterFocusButton,
	MapAnalyticsForm,
	MapAnalyticsTitleInput,
} from '../UI';

export const TenantIndustryAnalyticsSettingsMenu = () => {
	const [state, setState] = useTenantIndustryState();
	const dispatch = useDispatch();
	const onChangeMetric = (metric: MapAnalyticsMetric) => {
		setState((s) => ({ ...s, metric }));
	};

	const onClickClose = () => {
		setState((s) => ({ ...s, view: 'read' }));
	};

	const onClickFocusFilter = () => {
		dispatch(focusFilterSection('tenantIndustry'));
	};

	return (
		<>
			<MapAnalyticsForm>
				<MapAnalyticsFormSection>
					<MapAnalyticsSectionTitle>Title</MapAnalyticsSectionTitle>
					<MapAnalyticsTitleInput
						value={state.title}
						onChange={(e) => {
							setState((s) => ({ ...s, title: e.target.value }));
						}}
					/>
				</MapAnalyticsFormSection>
				<MapAnalyticsMetricRadioGroup
					id="tenantIndustry"
					metric={state.metric}
					onChangeMetric={onChangeMetric}
				/>
				<StyledFilterFocusButton
					type="button"
					data-qa-id="focus-tenant-industry-filter-btn"
					onClick={onClickFocusFilter}
				>
					<ArrowLeftIcon /> Filter Tenant Industry
				</StyledFilterFocusButton>
			</MapAnalyticsForm>
			<MapAnalyticsSettingsCloseButton
				data-qa-id="tenant-industry-settings-close-btn"
				onClick={onClickClose}
			/>
		</>
	);
};

const StyledFilterFocusButton = styled(FilterFocusButton)`
	margin: 10px 0;
	display: flex;
	align-items: center;
	gap: 10px;
`;
