import { PropertyComp } from 'types';
import { BuildingStats } from './BuildingStats';
import { CompPopupTitle } from './CompPopupTitle';
import { PopupCloseButton } from './PopupCloseButton';
import { CompPopupSubtitle, PopupHeader } from './UI';
import { getIsCompPopupTitleBuildingName } from './utils';

type Props = {
	property: PropertyComp;
	closePopup: () => void;
};

export const CompPopupHeader = ({ property, closePopup }: Props) => {
	const shouldShowSubtitle = getIsCompPopupTitleBuildingName(property);

	return (
		<PopupHeader>
			<div>
				<CompPopupTitle property={property} />
				{shouldShowSubtitle && (
					<CompPopupSubtitle>{property.buildingAddress}</CompPopupSubtitle>
				)}
				<BuildingStats property={property} />
			</div>
			<PopupCloseButton onClick={closePopup} />
		</PopupHeader>
	);
};
