import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { useUnlockSalesMutation } from 'api';
import styled from 'styled-components';
import { SalesComp } from 'types';
import IconCheckMark from '../../../../ui/svg_icons/tick_small.svg';

type Props = {
	data: SalesComp;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
	className?: string;
};

export const UnlockButton = ({ data, hasEnoughCredits, className }: Props) => {
	const { mutate: unlockSales, isLoading } = useUnlockSalesMutation();

	const handleClick = async () => {
		if (!isLoading) {
			unlockSales({ ids: [data.id] });
		}
	};

	return (
		<Button
			type="button"
			onClick={handleClick}
			disabled={hasEnoughCredits === UNLOCK_BUTTON_STATE.NOT_ENOUGH_CREDITS}
			className={className}
		>
			{isLoading ? (
				<IconCheckMarkStyled width={14} height={14} />
			) : (
				<UnlockButtonContainer>
					<LockIconStyled width={14} height={14} />
					<StyledSpan>Unlock</StyledSpan> |{' '}
					<span>
						{hasEnoughCredits === UNLOCK_BUTTON_STATE.FREE_COMP
							? 'FREE'
							: data.cost}
					</span>
				</UnlockButtonContainer>
			)}
		</Button>
	);
};

const UnlockButtonContainer = styled.div`
	display: flex;
	gap: 7px;
`;

const StyledSpan = styled.span`
	@media (max-width: 1110px) {
		display: none;
	}
`;

const IconCheckMarkStyled = styled(IconCheckMark)`
	fill: ${({ theme }) => theme.colors.white.white};

	-webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

	@-webkit-keyframes slide-top {
		0% {
			-webkit-transform: translateY(25px);
			transform: translateY(25px);
		}
		100% {
			-webkit-transform: transl(0);
			transform: translateY(0);
		}
	}
	@keyframes slide-top {
		0% {
			-webkit-transform: translateY(25px);
			transform: translateY(25px);
		}
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}
`;

const Button = styled.button`
	display: flex;
	gap: 8px;

	background-color: #50b87f;
	color: ${({ theme }) => theme.colors.white.white};
	border: unset;
	padding: 7px 46px;
	text-transform: uppercase;
	letter-spacing: 0.05em;

	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-size: ${({ theme }) => theme.typography.fontSize};
	font-weight: 325px;

	overflow: hidden;

	&:hover {
		background-color: ${({ theme }) => theme.colors.green.green700};
	}

	&:disabled {
		background-color: ${({ theme }) => theme.colors.neutral.n50};
		cursor: not-allowed;
	}
`;

export enum UNLOCK_BUTTON_STATE {
	ENOUGH_CREDITS = 'enoughCredits',
	NOT_ENOUGH_CREDITS = 'notEnoughCredits',
	FREE_COMP = 'freeComp',
}
