import { MAP_CONTROLS } from 'actions/search';
import { MapFilterBase } from './MapFilterBase';
import IconPolygonMapFilter from 'ui/svg_icons/map-polygon-icon.svg';

export const PolygonMapFilter = () => {
	return (
		<MapFilterBase
			Icon={IconPolygonMapFilter}
			key={MAP_CONTROLS.POLYGON}
			label="Polygon Filter"
			mapControl={MAP_CONTROLS.POLYGON}
		/>
	);
};
