import { useUser } from 'Pages/Login/reducers';
import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

const DEBOUNCE_DEFAULT_TIMEOUT = 300;

export function useLocalStorage<T>(
	key: string,
	initialValue: T,
	shapeChecker: (value: unknown) => T,
	onInit?: (value: T) => void
): [T, (value: T) => void] {
	const user = useUser();
	const storageKey = `${key}-${user.id}`;

	const [value, setValue] = useState<T>(() => {
		const getInitialValue = (): T => {
			try {
				const item = window.localStorage.getItem(storageKey);
				if (!item) return initialValue;

				const json = JSON.parse(item);
				return shapeChecker(json);
			} catch {
				return initialValue;
			}
		};
		const initValue = getInitialValue();
		onInit?.(initValue);
		return initValue;
	});

	const [debouncedValue] = useDebounce(value, DEBOUNCE_DEFAULT_TIMEOUT);

	useEffect(() => {
		try {
			window.localStorage.setItem(storageKey, JSON.stringify(debouncedValue));
		} catch {
			console.error('Failed to save to local storage');
		}
	}, [debouncedValue, storageKey]);

	return [value, setValue];
}
