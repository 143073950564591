import React from 'react';
import IconCircleTick from '../../../../../ui/svg_icons/circle_tick.svg';

import styled from 'styled-components';
import { ProjectDTO } from '../../actions';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

type Props = {
	project: ProjectDTO;
	onClose: () => void;
};

export const NewProjectSuccessModal = ({ project, onClose }: Props) => {
	return (
		<ModalCenteredContent>
			<ModalTitle>
				<IconCircleTickStyled width="35px" height="35px" />
			</ModalTitle>
			<ModalParagraph>
				You’ve created a new project named:
				<div>
					<b>{project.name}</b>
				</div>
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="primary" onClick={onClose}>
					OKAY
				</ModalButton>
			</ModalButtons>
		</ModalCenteredContent>
	);
};

const IconCircleTickStyled = styled(IconCircleTick)`
	display: initial;

	path {
		fill: ${({ theme }) => theme.colors.green.green500};
	}
`;
