import React from 'react';
import { GraphOrWarningOrSpaceTypeChooser } from './GraphComponents';

type OwnState = any;

type State = OwnState & typeof Graph.defaultProps;

export class Graph extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		const originalPrefs = this.props.user.preferences.insights;

		const userPrefs = {
			type: originalPrefs.type || 'line',
			yAxis: originalPrefs.yAxis || 'startingRent',
			timespan: originalPrefs.timespan || 120,
			trendMonths: originalPrefs.trendMonths || 3,
		};

		this.state = {
			shapes: [
				{
					color: '#0f86ff',
					x: 'executionDate',
					y: userPrefs.yAxis,
					z: 'transactionSize',
					source: 'lease',
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
					filters: this.props.filters,
					trendMonths: userPrefs.trendMonths,
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'thresholdForPoints' does not exist on ty... Remove this comment to see the full error message
					points: this.props.thresholdForPoints,
					summaries: [180, 120, 60, 36, 12],
				},
			],
			exportRequest: false,
			copyRequest: false,
			warningsAccepted: {},
			userPreferences: userPrefs,
		};
	}

	static defaultProps = {
		thresholdForPoints: 50,
	};

	componentDidUpdate(prevProps: {}) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type '{}'.
		if (prevProps.filters !== this.props.filters) {
			const newShape = {
				...this.state.shapes[0],
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
				filters: this.props.filters,
			};
			this.setState({
				shapes: [newShape],
			});
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'thresholdForPoints' does not exist on ty... Remove this comment to see the full error message
		if (prevProps.thresholdForPoints !== this.props.thresholdForPoints) {
			const newShape = {
				...this.state.shapes[0],
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'thresholdForPoints' does not exist on ty... Remove this comment to see the full error message
				points: this.props.thresholdForPoints,
			};
			this.setState({
				shapes: [newShape],
			});
		}
	}

	// @ts-expect-error TS7006: Parameter 'newValues' implicit...
	updateUser(newValues) {
		const newUserPrefs = {
			...this.state.userPreferences,
			...newValues,
		};
		const newPrefs = {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			...this.props.user.preferences,
			insights: newUserPrefs,
		};
		this.setState({
			userPreferences: newUserPrefs,
		});
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'userActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.userActions.savePreferences(this.props.user, newPrefs);
	}

	// @ts-expect-error TS7006: Parameter 'newValue' implicitl...
	changeTimespan = (newValue) => {
		this.updateUser({ timespan: newValue });
	};

	// @ts-expect-error TS7006: Parameter 'type' implicitly ha...
	changeType = (type) => {
		this.updateUser({ type });
	};

	// @ts-expect-error TS7006: Parameter 'newValue' implicitl...
	changeYProperty = (newValue) => {
		this.updateUser({ yAxis: newValue });
		this.setState({
			shapes: [
				{
					...this.state.shapes[0],
					y: newValue,
				},
			],
		});
	};

	// @ts-expect-error TS7006: Parameter 'newValue' implicitl...
	changeTrendMonths = (newValue) => {
		this.updateUser({ trendMonths: newValue });
		this.setState({
			shapes: [
				{
					...this.state.shapes[0],
					trendMonths: newValue,
				},
			],
		});
	};

	// @ts-expect-error TS7006: Parameter 'options' implicitly...
	export = (options) => {
		this.setState({
			exportRequest: options,
		});
	};

	copyForExcel = () => {
		this.setState({
			copyRequest: new Date(),
		});
	};

	// @ts-expect-error TS7006: Parameter 'type' implicitly ha...
	acceptWarning = (type) => {
		this.setState({
			warningsAccepted: {
				...this.state.warningsAccepted,
				[type]: true,
			},
		});
	};

	render() {
		return (
			// @ts-expect-error ts-migrate(2786) FIXME: Type 'Element[]' is missing the following properti... Remove this comment to see the full error message
			<GraphOrWarningOrSpaceTypeChooser
				{...this.props}
				{...this.state}
				changeTimespan={this.changeTimespan}
				changeType={this.changeType}
				changeYProperty={this.changeYProperty}
				changeTrendMonths={this.changeTrendMonths}
				acceptWarning={this.acceptWarning}
				export={this.export}
				copyForExcel={this.copyForExcel}
			/>
		);
	}
}
