import { PropertyTypeName } from 'api';
import {
	useLeaseExpirationByPropertyType,
	UseLeaseExpirationByPropertyTypeOptions,
} from 'api/leaseExpirationByPropertyType/useLeaseExpirationByPropertyType';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

type LeaseExpirationByPropertyTypeData = Record<
	PropertyTypeName,
	{
		[yearKey: string]: number;
	}
>;

export const useExchangeDashboardLeaseExpirationByPropertyType = (
	options?: UseLeaseExpirationByPropertyTypeOptions
) => {
	const [filters] = useFilters('exchange-dashboard');
	const { data: responseData, isFetching } = useLeaseExpirationByPropertyType(
		{ filters },
		options
	);
	const data = useMemo(() => {
		return responseData?.reduce<LeaseExpirationByPropertyTypeData>(
			(acc, { year, buildingPropertyType, leaseCount }) => {
				const currentYear = new Date().getFullYear();
				const yearKey =
					year >= currentYear + 10 ? `${currentYear + 10}+` : year;
				return {
					...acc,
					[buildingPropertyType]: {
						...acc[buildingPropertyType],
						[yearKey]:
							(acc[buildingPropertyType]
								? acc[buildingPropertyType][yearKey] ?? 0
								: 0) + leaseCount,
					},
				};
			},
			{} as LeaseExpirationByPropertyTypeData
		);
	}, [responseData]);

	return {
		data,
		isFetching,
		type: 'leaseExpirationPropertyType' as const,
	};
};
