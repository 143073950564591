import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { ActionCell } from './ActionCell';
import { ROW_HEIGHT } from './constants';
import { useLayoutContext } from './LayoutProvider';
import { useOptionsContext } from './OptionsProvider';

export const ActionColumn = () => {
	const { rows, getRowId } = useOptionsContext();
	const { actionColumnRef, bodyHeightWithOffset, actionColumnWidth } =
		useLayoutContext();

	return (
		<div style={{ flexShrink: 0 }}>
			<FixedSizeList
				width={actionColumnWidth}
				height={bodyHeightWithOffset}
				itemCount={rows.length}
				itemSize={ROW_HEIGHT}
				style={{
					overflow: 'hidden',
				}}
				itemKey={(rowIndex) => getRowId(rows[rowIndex])}
				ref={(list) => {
					const el = (list as any)?._outerRef;
					actionColumnRef.current = el;
				}}
			>
				{cellRenderer}
			</FixedSizeList>
		</div>
	);
};

const cellRenderer = ({ index, style }: ListChildComponentProps) => {
	return <ActionCell rowIndex={index} style={style} />;
};
