import { PortfolioItem } from 'types';

export const PORTFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_AND_ADDITIONAL_COLUMN: (keyof PortfolioItem)[] =
	[
		'marketDisplayName',
		'submarket',
		'buildingName',
		'buildingLoadFactor',
		'buildingGroundLvlDoorsCount',
		'buildingLoadingDocksCount',
		'buildingRail',
		'buildingPropertySubtype',
		'buildingSize',
		'buildingFloorsCount',
		'buildingYearRenovated',
		'buildingParkingRatio',
		'buildingParkingType',
		'propertyMarketStartingRent',
		'propertyMarketEffectiveRent',
		'amps',
		'elevators',
		'floorAreaRatio',
		'lotSize',
		'numberOfUnits',
		'parkingNotes',
		'percentImproved',
		'propertyAverageTransactionSize',
		'volts',
		'zoning',
		'buildingCeilingHeight',
		'apn',
		'suite',
	];

export const PORTFOLIO_PROPERTY_FIELDS_UNIQUE_VALUES: (keyof PortfolioItem)[] =
	['marketDisplayName', 'submarket', 'county'];

export const PORTFOLIO_PROPERTY_FIELDS_DEFAULT_COLUMN: (keyof PortfolioItem)[] =
	[
		'buildingClass',
		'buildingPropertyType',
		'buildingYear',
		'city',
		'state',
		'zip',
		'buildingAddress',
	];

// Used to know for which property fields to show an additional column
// in the Multi-Property Items Table
export const PORTFOLIO_PROPERTY_FIELDS_ADDITIONAL_COLUMN = [
	...PORTFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_AND_ADDITIONAL_COLUMN,
	...PORTFOLIO_PROPERTY_FIELDS_UNIQUE_VALUES,
];

export const PORTOFOLIO_PROPERTY_FIELDS_DEFINED_VALUES = [
	...PORTFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_AND_ADDITIONAL_COLUMN,
	...PORTFOLIO_PROPERTY_FIELDS_DEFAULT_COLUMN,
];

export const PORTFOLIO_PROPERTY_ALL_FIELDS = [
	...PORTFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_AND_ADDITIONAL_COLUMN,
	...PORTFOLIO_PROPERTY_FIELDS_UNIQUE_VALUES,
	...PORTFOLIO_PROPERTY_FIELDS_DEFAULT_COLUMN,
];

// faster to check presence of field in object than array
export const PORTOFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_OBJECT =
	PORTOFOLIO_PROPERTY_FIELDS_DEFINED_VALUES.reduce<
		Record<keyof PortfolioItem, number>
	>(
		(p, c) => {
			p[c] = 1;
			return p;
		},
		{} as Record<keyof PortfolioItem, number>
	);

export const PORTFOLIO_PROPERTY_ALL_FIELDS_OBJECT =
	PORTFOLIO_PROPERTY_ALL_FIELDS.reduce<Record<keyof PortfolioItem, number>>(
		(p, c) => {
			p[c] = 1;
			return p;
		},
		{} as Record<keyof PortfolioItem, number>
	);

export const TABLE_MAX_HEIGHT = 300;
