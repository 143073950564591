import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { createGlobalStyle } from 'styled-components';
import { useColumnsContext } from '../Columns';
import { useLayoutContext } from '../LayoutProvider';
import { useAutoScroll } from '../useAutoScroll';
import { ColumnReorderContextValue, DragState, StartReorderFn } from './types';

type Props = {
	children: ReactNode;
};

export const ColumnReorderProvider = ({ children }: Props) => {
	const [dragState, setDragState] = useState(initialState);
	const { onColumnReorder } = useColumnsContext();
	const {
		rootRect,
		header,
		actionColumnWidth,
		tableHeight,
		bodyHasHOverflow,
		scrollbarWidth,
	} = useLayoutContext();

	const isDragging = dragState.draggedColumnId !== -1;

	const [startAutoScroll, stopAutoScroll] = useAutoScroll({
		leftX: dragState.relativeDraggableCurrentX,
		rightX:
			dragState.relativeDraggableCurrentX + (dragState.activeRect?.width ?? 0),
		scrollSpeed: 15,
	});

	const startReorder: StartReorderFn = (clientX, columnId, columnIndex) => {
		if (isDragging) return;

		startAutoScroll();

		const activeRect = getRectByIndex(columnIndex);
		if (!activeRect) return;

		const startX = clientX;

		const rootOffset = rootRect?.left ?? 0;

		// draggable position is normalized - relative to parent offset
		const draggableStartX = activeRect.left - rootOffset - actionColumnWidth;

		setDragState({
			startX,
			currentX: startX,
			deltaX: startX,
			relativeDraggableStartX: draggableStartX,
			relativeDraggableCurrentX: draggableStartX,
			activeRect,
			draggedColumnId: columnId,
			columnIndex,
			absoluteDraggableStartX: activeRect.left,
			absoluteDraggableCurrentX: activeRect.left,
			insertIndex: columnIndex,
		});
	};

	const onDragMove = useCallback(
		(e: MouseEvent) => {
			if (!isDragging || !header) return;

			const activeRect = dragState.activeRect;

			if (!activeRect) return;

			// calculate new drag position
			const deltaX = dragState.currentX - dragState.startX;
			const minDraggableCurrentX = 0;
			const maxDraggableCurrentX = header.clientWidth - activeRect.width;
			let newDraggableCurrentX = dragState.relativeDraggableStartX + deltaX;

			// clamp the position between min/max
			newDraggableCurrentX = Math.min(
				Math.max(minDraggableCurrentX, newDraggableCurrentX),
				maxDraggableCurrentX
			);

			const x = dragState.currentX;
			const y = activeRect.top;

			const insertIndex =
				findColumnIndexAtCoordinates(x, y) ?? dragState.insertIndex;

			setDragState({
				...dragState,
				currentX: e.clientX,
				deltaX: e.clientX - dragState.currentX,
				relativeDraggableCurrentX: newDraggableCurrentX,
				absoluteDraggableCurrentX: dragState.absoluteDraggableStartX + deltaX,
				insertIndex,
			});
		},
		[dragState, header, isDragging]
	);

	const endDrag = useCallback(() => {
		if (!isDragging) return;

		stopAutoScroll();

		onColumnReorder(dragState.columnIndex, dragState.insertIndex);

		setDragState(initialState);
	}, [
		isDragging,
		stopAutoScroll,
		dragState.columnIndex,
		dragState.insertIndex,
		onColumnReorder,
	]);

	useEffect(() => {
		window.addEventListener('mousemove', onDragMove);
		window.addEventListener('mouseup', endDrag);
		return () => {
			window.removeEventListener('mousemove', onDragMove);
			window.removeEventListener('mouseup', endDrag);
		};
	}, [onDragMove, endDrag]);

	return (
		<ColumnReorderContext.Provider
			value={{
				...dragState,
				startReorder,
				isDragging,
				overlayLeft: dragState.relativeDraggableCurrentX,
				overlayWidth: dragState.activeRect?.width ?? 0,
				overlayHeight: tableHeight - (bodyHasHOverflow ? scrollbarWidth : 0),
			}}
		>
			{children}
			<GlobalStyle isDragging={isDragging} />
		</ColumnReorderContext.Provider>
	);
};

const initialState: DragState = {
	startX: 0,
	currentX: 0,
	deltaX: 0,
	relativeDraggableStartX: 0,
	relativeDraggableCurrentX: 0,
	draggedColumnId: -1,
	columnIndex: -1,
	activeRect: null,
	absoluteDraggableStartX: 0,
	absoluteDraggableCurrentX: 0,
	insertIndex: -1,
};

const ColumnReorderContext = createContext({} as ColumnReorderContextValue);

export const useColumnReorderContext = () => useContext(ColumnReorderContext);

const getRectByIndex = (index: number) => {
	const el = document.querySelector(`.header-cell[data-colindex="${index}"]`);
	if (!el) return;
	return el.getBoundingClientRect();
};

const findColumnIndexAtCoordinates = (x: number, y: number) => {
	const el = document.elementFromPoint(x, y);
	if (!el || !(el instanceof HTMLElement)) return;
	const index = el.dataset.colindex;
	if (index == null) return;
	const numIndex = Number(index);
	if (Number.isNaN(numIndex)) return;
	return numIndex;
};

const GlobalStyle = createGlobalStyle<{ isDragging: boolean }>((p) => ({
	body: {
		userSelect: p.isDragging ? 'none' : undefined,
		cursor: p.isDragging ? 'grabbing' : undefined,
	},
}));
