import styled from 'styled-components';
import { PropertyComp } from 'types';
import { getIsCompPopupTitleBuildingName } from './utils';

type Props = {
	property: PropertyComp;
};

export const CompPopupTitle = ({ property }: Props) => {
	let title = property.buildingAddress;

	if (getIsCompPopupTitleBuildingName(property)) {
		title = property.buildingName ?? '';
	}

	return <Title>{title}</Title>;
};

const Title = styled.h5`
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	line-height: 1.6;
	word-break: break-word;
`;
