import { useMarketsHaveFeature, usePropertyLoan } from 'api';
import { useState } from 'react';
import { PropertyComp } from 'types';
import { usePublicLoansPdfColumns } from './usePublicLoansPdfColumns';

type Props = {
	property: PropertyComp;
};

export const useEditablePublicLoans = ({ property }: Props) => {
	const { data: hasSalesAccess } = useMarketsHaveFeature({
		marketIds: property ? [property.marketId] : [],
		feature: 'salesAccess',
		isEnabled: !!property,
	});

	const { data: loans } = usePropertyLoan({
		propertyId: property.id,
		isEnabled: hasSalesAccess,
	});

	const columns = usePublicLoansPdfColumns();

	const [editablePublicLoansRow, setEditablePublicLoansRow] = useState(
		loans?.publicLoans?.map((row, rowIndex) => {
			const editableRow: Record<string, string | number> = {
				id: row.loanId,
			};
			columns.forEach((c) => {
				editableRow[c.id] = c.getValue({ row, rowIndex });
			});

			return editableRow;
		})
	);

	const [editablePublicLoansColumns, setEditablePublicLoansColumns] =
		useState(columns);

	return {
		editablePublicLoansRow,
		setEditablePublicLoansRow,
		publicLoansColumn: columns,
		editablePublicLoansColumns,
		setEditablePublicLoansColumns,
	};
};
