import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PortfolioItem, SalesComp } from '../../types';
import { QUERY_KEYS } from '../constants';
import { composeEnabled } from '../utils';
import { API } from '@compstak/common';

export const usePropertySalesQuery = (
	params: PropertySalesParams,
	options?: UseQueryOptions<PropertySalesResponse>
) => {
	const propertyId = params.propertyId;

	return useQuery({
		queryKey: createPropertySalesQueryKey(params),
		queryFn: async () => {
			const response = await API.get<PropertySalesResponse>(
				`/properties/${propertyId}/sales`
			);
			return response.data;
		},
		...options,
		enabled: composeEnabled(propertyId != null, options?.enabled),
	});
};

export const createPropertySalesQueryKey = (params: PropertySalesParams) => [
	QUERY_KEYS.PROPERTY_SALES,
	params,
];

export type PropertySalesParams = {
	propertyId: number;
};

export type PropertySalesResponse = {
	comps: PropertySale[];
};

export type PropertySale = Pick<
	SalesComp,
	| 'id'
	| 'saleDate'
	| 'isPortfolio'
	| 'portfolio'
	| 'totalSalePrice'
	| 'dataSource'
	| 'salePricePsf'
	| 'buyer'
	| 'buyerRepCompanies'
	| 'seller'
	| 'sellerRepCompanies'
	| 'capRate'
	| 'interestPercentage'
	| 'documentNumber'
	| 'own'
	| 'cost'
	| 'market'
> &
	Pick<PortfolioItem, 'percentOccupied'>;
