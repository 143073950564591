import { Button, Excel } from "@compstak/ui-kit";
import styled from "styled-components";

type Props = {
    isMonthly: boolean;
    setIsMonthly: (arg: boolean) => void;
    isLoadingExportExcel: boolean;
    onClickExportExcel: () => void;
}

export const AnnualMonthlySelector = ({ isMonthly, setIsMonthly, isLoadingExportExcel, onClickExportExcel }: Props) => {
    return (<Container>
        <p>Show values monthly or annually</p>
        <AnnualMonthlySelectorButtons>
            <AnnualMonthlySelectorButton
                variant={isMonthly ? 'secondary' : 'ghost'}
                onClick={() => setIsMonthly(true)}
            >
                Monthly
            </AnnualMonthlySelectorButton>
            <AnnualMonthlySelectorButton
                variant={!isMonthly ? 'secondary' : 'ghost'}
                onClick={() => setIsMonthly(false)}
            >
                Annually
            </AnnualMonthlySelectorButton>
        </AnnualMonthlySelectorButtons>
        <Button
            icon={<Excel isInheritedColor />}
            isLoading={isLoadingExportExcel}
            onClick={() => onClickExportExcel()}
        >
            EXPORT
        </Button>
    </Container>);
};

const Container = styled.div`
	display: flex;
	gap: 0.5rem;
	flex-direction: column;

	p {
		font-weight: 400;
		opacity: 0.6;
		font-size: 0.6875rem;
		letter-spacing: 0.025em;
		line-height: 1.333em;
		text-transform: none;
		margin: 0;
		text-align: center;
	}
`;

const AnnualMonthlySelectorButtons = styled.div`
	display: flex;
	padding: 0.25rem;
	border-radius: 3px;
	background-color: ${({ theme }) => theme.colors.neutral.n800};
`;

const AnnualMonthlySelectorButton = styled(Button)`
    flex: 1;
	font-size: 0.75rem;
	height: 1.5rem;
	color: ${({ theme }) => theme.colors.white.white};
`;
