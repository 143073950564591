import { Dash, KeyValueTable } from '@compstak/ui-kit';
import { KvTableHeader } from 'Components/KvTable/kvTableHeader';
import { KvTableStylingWrapper } from 'Components/KvTable/kvTableWrapper';
import { formatInteger } from 'format';
import styled from 'styled-components';
import { PropertyComp } from 'types';

export function PropertyDetailsKvTable({
	property,
	hideFloors,
}: {
	property: PropertyComp;
	hideFloors?: boolean;
}) {
	return (
		<KvTableContainer>
			<KvTableHeader>Property Details</KvTableHeader>
			<KeyValueTable
				columns={1}
				data={[
					['Property Type', property.buildingPropertyType],
					[
						'Building Size',
						property.buildingSize
							? formatInteger(property.buildingSize, { shorten: false })
							: null,
					],
					[
						'Avg. Lease Size',
						property.propertyAverageTransactionSize
							? formatInteger(property.propertyAverageTransactionSize, {
									shorten: false,
								})
							: null,
					],
					...(hideFloors ? [] : [['Floors', property.buildingFloorsCount]]),
					['Year Built', property.buildingYear],
					['Year Renovated', property.buildingYearRenovated],
					['Lot Size (Acres)', property.lotSize],
				].map(([key, value]) => [key, value ?? <Dash />])}
			/>
		</KvTableContainer>
	);
}

const KvTableContainer = styled(KvTableStylingWrapper)`
	max-width: 320px;
	min-width: 240px;
	margin-top: 48px;
`;
KvTableContainer.displayName = 'KvTableContainer';
