import { PropertyComp } from 'types';
import { useSubmarketRecentLeasesPdfColumns } from './useSubmarketRecentLeasesPdfColumns';
import { useState } from 'react';
import { PropertyLease } from 'api';
import { useSelectionState } from 'utils';

type Props = {
	property: PropertyComp;
	comps: PropertyLease[];
};

export const useEditableSubmarketRecentLeases = ({
	property,
	comps,
}: Props) => {
	const columns = useSubmarketRecentLeasesPdfColumns(property);

	const [
		editableSubmarketRecenetLeasesRow,
		setEditableSubmarketRecenetLeasesRow,
	] = useState(
		comps.map((row, rowIndex) => {
			const editableRow: { id: number } & Record<string, string | number> = {
				id: row.id,
			};
			columns.forEach((c) => {
				editableRow[c.id] = c.getValue({ row, rowIndex });
			});

			return editableRow;
		})
	);

	const [
		editableSubmarketRecenetLeasesColumns,
		setEditableSubmarketRecenetLeasesColumns,
	] = useState(columns);

	const recentLeasesSelection = useSelectionState(
		editableSubmarketRecenetLeasesRow,
		() => editableSubmarketRecenetLeasesRow.map((r) => r.id)
	);

	return {
		editableSubmarketRecenetLeasesColumns,
		setEditableSubmarketRecenetLeasesColumns,
		editableSubmarketRecenetLeasesRow,
		setEditableSubmarketRecenetLeasesRow,
		recentLeasesSelection,
	};
};
