import { Flex, Spinner } from '@compstak/ui-kit';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import button from 'ui/styles/button.less';
import styles from '../stylesheets/login.less';

// https://stackoverflow.com/questions/45665188/typescript-types-for-react-checkbox-events-and-handlers
export type CheckboxChangeEv = React.ChangeEvent<HTMLInputElement>;

export function LoginFormWrapper({ children }: { children: ReactNode }) {
	return (
		<div
			className={`${styles.login_form_content} ${styles.login_screen_content}`}
			key="login-form-passwd"
		>
			{children}
		</div>
	);
}

export function ForgotPasswdSection({
	rememberMe,
	updateRememberMe,
}: {
	rememberMe: boolean;
	updateRememberMe: (event: CheckboxChangeEv) => void;
}) {
	return (
		<Flex
			justifyContent="space-between"
			alignItems="baseline"
			className={`${styles.content_below_inputs} ${styles.delayedItem}`}
		>
			<input
				data-qa-id="remember-me-checkbox"
				type="checkbox"
				id="remember_me"
				checked={rememberMe}
				className="checkbox checkbox_minimal"
				onChange={updateRememberMe}
			/>
			<RememberMeLabel htmlFor="remember_me">Keep me logged in</RememberMeLabel>

			<a href="/forgotPassword" data-qa-id="forgot-passwd-link">
				Forgot password?
			</a>
		</Flex>
	);
}

export function LoginBtn({
	checking,
	onClick,
	text,
}: {
	checking: boolean;
	onClick: NoArgCallback;
	text?: string;
}) {
	return checking ? (
		<button className={button.disabled} disabled>
			<Spinner size="s" />
		</button>
	) : (
		<button
			className={button.button}
			onClick={onClick}
			data-qa-id="login-btn"
			type="button"
		>
			{text || 'Login'}
		</button>
	);
}

const RememberMeLabel = styled.label`
	color: ${({ theme }) => theme.colors.neutral.n90};
	&::before {
		margin-bottom: 3px !important;
	}
`;
