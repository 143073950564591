import styled from 'styled-components';
import { MarketRentAnalyticsLegendVariant } from './types';

export const MarketRentAnalyticsLegendContainer = styled.div<{
	variant: MarketRentAnalyticsLegendVariant;
}>`
	position: absolute;
	min-width: ${(p) =>
		p.variant === MarketRentAnalyticsLegendVariant.MEDIUM
			? '170px'
			: undefined};
	min-height: ${(p) =>
		p.variant === MarketRentAnalyticsLegendVariant.MEDIUM
			? '164px'
			: undefined};
	bottom: 12px;
	right: 12px;
	background-color: #fff;
	padding: 8px;
	border-radius: 4px;
	z-index: 2;
`;
