import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import {
	Transition,
	TransitionGroup,
	TransitionStatus,
} from 'react-transition-group';
import { SearchPageView } from 'router';
import styled from 'styled-components';
import { CompType } from 'types';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { useAppSelector } from 'util/useAppSelector';
import { usePrevious } from '../../util/hooks';
import { StatsItem } from './StatsItem';
import { MQB } from '@compstak/ui-kit';

type StatsProps = {
	compType: CompType;
	view?: SearchPageView;
};

const initStatKeys = ['properties', 'leases', 'sqft'] as const;
const propertyStatKeys = ['properties', 'leases', 'sales'] as const;

type StatKeys = typeof initStatKeys | typeof propertyStatKeys;

export const Stats = ({ compType, view }: StatsProps) => {
	const aggregations = useAppSelector(
		(state) => state.searchReducer.aggregations
	);
	const [activeIndex, setActiveIndex] = useState(0);
	const [randomId, setRandomId] = useState(new Date().valueOf());
	const [statKeys, setStatKeys] = useState<StatKeys>(initStatKeys);

	const prevAggregations = usePrevious(aggregations) || {};

	useEffect(() => {
		setStatKeys(compType === 'property' ? propertyStatKeys : initStatKeys);
	}, [compType]);

	useEffect(() => {
		if (!isEqual(aggregations, prevAggregations)) {
			setRandomId(new Date().valueOf());
		}
	}, [aggregations]);

	const switchActiveStat = () => {
		setActiveIndex(
			(prevActiveIndex) => (prevActiveIndex + 1) % statKeys.length
		);
	};

	const getIsActiveStat = (key: StatKeys[number]) => {
		return key === statKeys[activeIndex];
	};

	return (
		<TransitionGroup>
			<Transition key={randomId} timeout={300}>
				{(transitionStatus) => (
					<StatsContainer
						isMapView={view === 'map'}
						onClick={switchActiveStat}
						className={'search-stats'}
						transitionStatus={transitionStatus}
					>
						<StatsItem
							isActive={getIsActiveStat('properties')}
							label="Prop."
							transitionStatus={transitionStatus}
						>
							{abbreviateNumber(aggregations.properties)}
						</StatsItem>
						<StatsItem
							isActive={getIsActiveStat('leases')}
							label={compType === 'sale' ? 'Sales' : 'Leases'}
							transitionStatus={transitionStatus}
						>
							{abbreviateNumber(aggregations.leases)}
						</StatsItem>
						{compType === 'property' ? (
							<StatsItem
								isActive={getIsActiveStat('sales')}
								label="Sales"
								transitionStatus={transitionStatus}
							>
								{abbreviateNumber(aggregations.sales)}
							</StatsItem>
						) : (
							<StatsItem
								isActive={getIsActiveStat('sqft')}
								label="SF"
								transitionStatus={transitionStatus}
							>
								{abbreviateNumber(aggregations.transactionSize)}
							</StatsItem>
						)}
					</StatsContainer>
				)}
			</Transition>
		</TransitionGroup>
	);
};

const StatsContainer = styled.div<{
	isMapView?: boolean;
	transitionStatus?: TransitionStatus;
}>`
	background-color: ${({ isMapView, theme }) =>
		isMapView ? `${theme.colors.white.white}` : `${theme.colors.gray.gray200}`};
	${({ isMapView, theme }) =>
		isMapView && `box-shadow: 0 2px ${theme.colors.gray.gray400}`};
	border-radius: 3px;
	padding: 13px 4px;
	height: 48px;
	width: 55px;
	box-sizing: border-box;
	transition: background-color 0.3s ease-in-out;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};

	@media (min-width: ${MQB.T_834}) {
		width: auto;
	}

	${({ transitionStatus }) =>
		(transitionStatus === 'exited' || transitionStatus === 'exiting') &&
		'display: none'};

	${({ transitionStatus, theme }) =>
		transitionStatus === 'entering' &&
		`background-color: ${theme.colors.blue.blue400}`};
`;

export default Stats;
