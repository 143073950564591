import { AverageIndicator } from 'ExchangeDashboard/LargestTenantsChart';
import { useExchangeDashboardLargeTenants } from 'ExchangeDashboard/hooks/largestTenants/useExchangeDashboardLargeTenants';
import { formatInteger, formatNumber } from 'format';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { VictoryLabel, VictoryScatter } from 'victory';
import { useExportPdfState } from '../ExportPdfProvider';
import { PDFPanel } from '../UI';

export const LargestTenantsSection = () => {
	const { data: largestTenantssData } = useExchangeDashboardLargeTenants();
	const { registerImage } = useExportPdfState();

	const data = useMemo(() => {
		if (!largestTenantssData) return [];

		const chartData = largestTenantssData.largestTenants
			.slice(0, 6)
			.map((tenant) => {
				const formattedTenant =
					tenant.tenant.length > 30
						? `${tenant.tenant.slice(0, 30)}…`
						: tenant.tenant;
				return {
					...tenant,
					label: `${formatInteger(tenant.sqft)} sqft\n${formattedTenant}`,
					x: `${formattedTenant}\n${formatInteger(tenant.sqft)} sqft`,
					y: 1,
				};
			});

		return sortBy(chartData, 'sqft').reverse();
	}, [largestTenantssData]);

	const theme = useTheme();

	const refCallback = useCallback(
		(el: HTMLElement | null) => {
			registerImage('largestTenants', el);
		},
		[registerImage]
	);

	if (!largestTenantssData) return null;

	return (
		<PDFPanel
			id="largest-tenants"
			title="Largest Tenants"
			cta={
				<AverageIndicator>
					{`${formatNumber(
						largestTenantssData?.averageSqft
					)} sqft, Average Tenant Size`}
				</AverageIndicator>
			}
		>
			<div ref={refCallback}>
				<VictoryScatter
					height={150}
					width={800}
					style={{
						data: { cursor: 'pointer', fill: theme.colors.blue.blue400 },
						labels: { fontSize: 10 },
					}}
					size={data.length}
					maxBubbleSize={20}
					minBubbleSize={2}
					bubbleProperty="sqft"
					labelComponent={<VictoryLabel dy={60} />}
					data={data}
				/>
			</div>
		</PDFPanel>
	);
};
