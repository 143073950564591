import React, { useMemo } from 'react';

import '../../styles/marketSelector.nomodule.less';
import formConstants from 'util/formConstants';
import { Market } from '@compstak/common';
import styled from 'styled-components';
import {
	MultiMarketSelectSidebarStyled as MultiMarketSelectSidebarStyledOrigin,
	Button,
	useSimpleForm,
} from '@compstak/ui-kit';
import { StateMultiMarketSelect } from './StateMultiMarketSelect';
import { getSearchTermsForMarkets } from 'util/getSearchTerms';
import { MarketsState } from 'Pages/Login/reducers';

type MarketListProps = {
	searchElement: React.ReactNode;
	searchTerm: string;
	marketSelectorExpanded: boolean;
	selectedMarkets: Market[];
	changeSelectedMarkets: (markets: Market[]) => void;
	onClearAll: NoArgCallback;
	onCancel: NoArgCallback;
	onApply: NoArgCallback;
	isSubmitDisabled: boolean;
	initialExpandedState: Record<string, boolean>;
	markets: MarketsState;
};

export const MarketListV2 = ({
	searchElement,
	searchTerm,
	marketSelectorExpanded,
	changeSelectedMarkets,
	selectedMarkets,
	onClearAll,
	onCancel,
	onApply,
	isSubmitDisabled,
	initialExpandedState,
	markets,
}: MarketListProps) => {
	const {
		state: expandedState,
		change,
		reset,
	} = useSimpleForm<Record<string, boolean>>(initialExpandedState);

	const { searchTermsToMarkets, marketsGroupedByState } = useMemo(() => {
		const searchTermsToMarkets = getSearchTermsForMarkets(markets.list);

		const marketsGroupedByState = formConstants.usStates.reduce<
			Record<string, Market[]>
		>((acc, state) => {
			const stateName = state.name.toLowerCase();
			const stateMarketsOptions = markets.list.filter((market) =>
				market.states.some(
					(marketState) => marketState.name.toLowerCase() === stateName
				)
			);

			if (stateMarketsOptions.length) {
				acc[stateName] = stateMarketsOptions;
			}

			return acc;
		}, {});

		return {
			searchTermsToMarkets,
			marketsGroupedByState,
		};
	}, [markets.list]);

	const selectedMarketsGroupedByState = useMemo(() => {
		const selectedStateToMarkets: Record<string, Market[]> = {};
		for (const selectedMarket of selectedMarkets) {
			for (const marketState of selectedMarket.states) {
				const stateName = marketState.name.toLowerCase();
				if (selectedStateToMarkets[stateName]) {
					selectedStateToMarkets[stateName].push(selectedMarket);
				} else {
					selectedStateToMarkets[stateName] = [selectedMarket];
				}
			}
		}
		return selectedStateToMarkets;
	}, [selectedMarkets]);

	const matchingSearchTermsMarkets = useMemo(() => {
		const foundMarkets = searchTerm
			? Object.keys(searchTermsToMarkets).filter((stateName) =>
					stateName.includes(searchTerm)
				)
			: [];
		return foundMarkets.map(
			(matchingSearchTerm) => searchTermsToMarkets[matchingSearchTerm]
		);
	}, [searchTerm, searchTermsToMarkets]);

	const marketOptions = useMemo(() => {
		const getSearchResults = (): [string, Market[]][] =>
			matchingSearchTermsMarkets.map((market) => {
				return [market.states[0].name.toLowerCase(), [market]];
			});
		return searchTerm
			? getSearchResults()
			: Object.entries(marketsGroupedByState);
	}, [marketsGroupedByState, matchingSearchTermsMarkets, searchTerm]);

	if (!marketSelectorExpanded) return null;

	return (
		<>
			<div className="market-list-wrap multi-market">
				<SelectAllButtonGroupContainer>
					<Button
						variant="ghost"
						onClick={() => {
							changeSelectedMarkets(markets.list);
						}}
					>
						Select All Markets
					</Button>
					<Button
						variant="ghost"
						onClick={() => {
							onClearAll();
							reset();
						}}
					>
						Clear
					</Button>
				</SelectAllButtonGroupContainer>
				{searchElement}
				<MultiMarketSelectSidebarStyled hasSearchTerm={!!searchTerm}>
					{marketOptions.map(([stateName, stateMarkets]) => {
						return (
							<StateMultiMarketSelect
								key={searchTerm ? stateName + stateMarkets[0].id : stateName}
								stateName={stateName}
								stateMarkets={stateMarkets}
								searchTerm={searchTerm}
								isOpen={expandedState[stateName]}
								value={selectedMarketsGroupedByState[stateName] ?? []}
								selectedMarkets={selectedMarkets}
								changeSelectedMarkets={changeSelectedMarkets}
								changeExpandedState={change}
							/>
						);
					})}
				</MultiMarketSelectSidebarStyled>
			</div>
			<ActionFooter>
				<span
					data-tooltipdelay="0"
					data-tooltip={
						isSubmitDisabled ? 'Select a market to continue' : undefined
					}
				>
					<Button
						dataTestId="multi-market-list--apply-button"
						variant="primary"
						onClick={onApply}
						disabled={isSubmitDisabled}
						// "pointer-events: none" necessary for tooltip wrapper to show on disabled button
						style={isSubmitDisabled ? { pointerEvents: 'none' } : undefined}
					>
						Apply Market Changes
					</Button>
				</span>
				<Button variant="secondary2" onClick={onCancel}>
					Go Back
				</Button>
			</ActionFooter>
		</>
	);
};

const MultiMarketSelectSidebarStyled = styled(
	MultiMarketSelectSidebarStyledOrigin
)`
	height: calc(100% - 74px);
	overflow-y: scroll;
`;

const ActionFooter = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.colors.neutral.n400};
	border: 2px solid ${({ theme }) => theme.colors.neutral.n600};
	position: absolute;
	left: 0;
	bottom: 0;

	padding: 8px 16px 8px 8px;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;

	button {
		font-size: 12px;
		&[disabled] {
			opacity: 1;
		}
	}

	> span,
	button {
		width: 100%;
	}
`;

const SelectAllButtonGroupContainer = styled.div`
	padding: 0 8px 8px;
	display: flex;
	justify-content: space-between;

	button {
		font-size: 12px;
	}
`;
