import { useMapRef } from '@compstak/maps';
import {
	featureCollection,
	FeatureCollection,
	lineString,
} from '@turf/helpers';
import turfLength from '@turf/length';
import { Point, RadiusFilter } from 'models/filters/types';
import React from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { RadiusFeature } from './Components/RadiusFilter/type';
import { createRadiusFeatureFromRadiusFilter } from './Components/RadiusFilter/createRadiusFeatureFromRadiusFilter';
import { createRadiusFilterFromRadiusFeature } from './Components/RadiusFilter/createRadiusFilterFromRadiusFeature';

type Props = {
	isRadiusFilterActive: boolean;
};

/**
 * Returns a GeoJSON Polygon FeatureCollection to be consumed by the deck.gl layers,
 * and for the pin selection.
 * It's derived from redux radius filter (object we send to BE) which is treated
 * as a single source of truth.
 * If redux filter is not present, return the default polygon.
 */
export const useRadiusPolygonFromFilter = ({ isRadiusFilterActive }: Props) => {
	const [filters, setFilters] = useFilters();
	const shouldInit = React.useRef(true);
	const { map } = useMapRef();

	const radiusPolygon = React.useMemo(() => {
		if (!map || !isRadiusFilterActive) {
			shouldInit.current = true;
			return null;
		}
		if (!filters.radius) {
			const { lng, lat } = map.getCenter();
			const center: Point = { lng, lat };
			const mapBounds = map.getBounds();
			const [swLon, swLat, neLon, neLat] = mapBounds
				.toArray()
				.flatMap((v) => v);
			const diagonal = lineString([
				[swLon, swLat],
				[neLon, neLat],
			]);
			const distance = turfLength(diagonal, { units: 'kilometers' }) / 8;
			const radiusFilter: RadiusFilter = {
				center,
				distance,
			};
			return createRadiusFeatureCollectionFromRadiusFilter(radiusFilter);
		} else {
			return createRadiusFeatureCollectionFromRadiusFilter(filters.radius);
		}
	}, [filters.radius, isRadiusFilterActive, map]);

	// handle ONLY the initial request
	React.useEffect(() => {
		if (radiusPolygon && isRadiusFilterActive && shouldInit.current) {
			shouldInit.current = false;
			setFilters({
				radius: createRadiusFilterFromRadiusFeatureCollection(radiusPolygon),
			});
		}
	}, [radiusPolygon, isRadiusFilterActive, setFilters]);

	return radiusPolygon;
};

const createRadiusFeatureCollectionFromRadiusFilter = (
	radiusFilter: RadiusFilter
): FeatureCollection<
	RadiusFeature['geometry'],
	RadiusFeature['properties']
> => {
	const radiusFeature = createRadiusFeatureFromRadiusFilter(radiusFilter);
	return featureCollection([radiusFeature]);
};

const createRadiusFilterFromRadiusFeatureCollection = (
	polygon: FeatureCollection<
		RadiusFeature['geometry'],
		RadiusFeature['properties']
	>
): RadiusFilter => {
	const radiusFeature = polygon.features[0];
	return createRadiusFilterFromRadiusFeature(radiusFeature);
};
