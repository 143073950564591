import React, { MouseEvent } from 'react';

import SuggestionItem, { SuggestionItemProps } from './SuggestionItem';

import styles from './suggestions.less';
import { Market } from '@compstak/common';
import { Suggestion } from 'api/suggestions/suggestions';
import { SuggestionsSkeleton } from './SuggestionsSkeleton';
import { isTopResultSuggestion } from './helpers';
import { openMarketSelector } from 'actions/home';
import { useDispatch } from 'react-redux';
import { SuggestionSection, SuggestionsContainer } from '../SearchBar';
import { SuggestionErrorIcon } from './SuggestionItem.styles';

const NoExactMatch = () => {
	const dispatch = useDispatch();

	const openMarketSelectorHandler = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		dispatch(openMarketSelector());
	};

	return (
		<div className={styles.typeaheadEmpty}>
			<SuggestionErrorIcon /> No exact match in selected markets
			<a href="#" onClick={openMarketSelectorHandler}>
				Change Market
			</a>
		</div>
	);
};

type SectionProps = {
	suggestion: Suggestion;
	startingIndex: number;
	selectedIndex: number;
} & Pick<
	SuggestionItemProps,
	'search' | 'onConfirmSelection' | 'onAddPortfolio'
>;

function Section(props: SectionProps) {
	return (
		<SuggestionSection>
			<h4>{props.suggestion.title}</h4>
			{props.suggestion.suggestions.map((suggestionItem, i) => (
				<SuggestionItem
					key={i}
					index={props.startingIndex + i}
					suggestion={props.suggestion}
					suggestionItem={suggestionItem}
					search={props.search.toLowerCase()}
					isSelected={props.selectedIndex === props.startingIndex + i}
					onConfirmSelection={props.onConfirmSelection}
					onAddPortfolio={props.onAddPortfolio}
				/>
			))}
		</SuggestionSection>
	);
}

type SuggestionsProps = {
	suggestions?: Suggestion[];
	search: string;
	market?: Market;
	selectedIndex: number;
	onConfirmSelection: (newIndex: number) => void;
	onAddPortfolio: () => void;
	loading?: boolean;
};

export default function Suggestions(props: SuggestionsProps) {
	const hasTopResult = isTopResultSuggestion(props.suggestions?.[0]);

	if (props.loading || !props.suggestions) {
		return (
			<SuggestionsSkeleton
				suggestions={props.suggestions}
				hasTopResult={hasTopResult}
			/>
		);
	}

	let currentIndex = 0;
	return (
		<SuggestionsContainer tabIndex={0}>
			{!hasTopResult && <NoExactMatch />}
			{props.suggestions.map((suggestion) => {
				const startingIndex = currentIndex;
				currentIndex += suggestion.suggestions.length;
				return (
					<Section
						key={suggestion.title}
						suggestion={suggestion}
						startingIndex={startingIndex}
						search={props.search}
						selectedIndex={props.selectedIndex}
						onConfirmSelection={props.onConfirmSelection}
						onAddPortfolio={props.onAddPortfolio}
					/>
				);
			})}
		</SuggestionsContainer>
	);
}
