import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
	PortfolioDropdownMenuArrow,
	PortfolioDropdownMenuContent,
	PortfolioDropdownMenuRadioItem,
	StyledIconTick,
} from '../UI';

type Props = {
	value: string;
	onValueChange: (value: string) => void;
	options: Option[];
};

type Option = {
	label: string;
	value: string;
};

export const SortFieldMenu = ({ value, onValueChange, options }: Props) => {
	return (
		<DropdownMenu.Portal>
			<PortfolioDropdownMenuContent>
				<PortfolioDropdownMenuArrow />
				<DropdownMenu.RadioGroup value={value} onValueChange={onValueChange}>
					{options.map((option) => {
						const isSelected = option.value === value;

						return (
							<PortfolioDropdownMenuRadioItem
								key={option.value}
								value={option.value}
							>
								<div>{option.label}</div>
								{isSelected && (
									<DropdownMenu.DropdownMenuItemIndicator>
										<StyledIconTick />
									</DropdownMenu.DropdownMenuItemIndicator>
								)}
							</PortfolioDropdownMenuRadioItem>
						);
					})}
				</DropdownMenu.RadioGroup>
			</PortfolioDropdownMenuContent>
		</DropdownMenu.Portal>
	);
};
