import styled, { CSSObject } from 'styled-components';
import { typography, Eye, EyeOff, AngleArrow, Button } from '@compstak/ui-kit';
import ThreeDot from 'ui/svg_icons/three-dot.svg';

const commonFont: CSSObject = {
	fontFamily: `${typography.fontFamily.gotham}`,
	fontWeight: '300',
	lineHeight: '1.3',
	color: '#ffffff',
	letterSpacing: '0.01rem',
};

export const DatasetContainer = styled.div<{
	bgColor: string;
	expanded: boolean;
}>`
	position: relative;
	background-color: ${({ theme }) => theme.colors.gray.gray700};
	box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.neutral.n80};
	padding-bottom: ${({ expanded }) => (expanded ? '16px' : '0')};

	&::before {
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		width: 6px;
		background-color: ${({ bgColor }) => bgColor};
	}
`;

export const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

type BgColorExpanded = {
	bgColor: string;
	expanded: boolean;
};

export const Counter = styled.span<BgColorExpanded>`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 22px;
	min-height: 17px;
	margin-right: 10px;
	padding: 2px 5px;
	border-radius: 3px;
	background-color: ${({ bgColor, expanded }) =>
		expanded ? '#ffffff' : bgColor};

	${commonFont}
	color: ${({ bgColor, expanded }: BgColorExpanded) =>
		expanded ? bgColor : '#ffffff'};
	font-size: 13px;
`;

export const TileClickContainer = styled.div<BgColorExpanded>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 8px 20px;
	background-color: ${({ bgColor, expanded }) => expanded && bgColor};

	&:hover {
		cursor: pointer;
		background-color: ${({ bgColor }) => bgColor};

		${Counter} {
			background-color: #ffffff;
			color: ${({ bgColor }) => bgColor};
		}
	}
`;

export const ArrowDownExpand = styled.span<BgColorExpanded>`
	position: absolute;
	display: ${({ expanded }) => (expanded ? 'block' : 'none')};
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 100%);
	width: 14px;
	border-top: 8px solid ${({ bgColor }) => bgColor};
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	max-width: 160px;
	margin-right: 10px;
`;

export const Title = styled.h4`
	${commonFont}
	font-size: 14px;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const RightGroupWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DatasetSummary = styled.p`
	max-width: 250px;
	margin: 0;
	padding: 0;

	${commonFont}
	font-size: 11px;
`;

export const FormWrapper = styled.div<{
	expanded: boolean;
}>`
	display: ${({ expanded }) => (expanded ? 'block' : 'none')};
	padding: 20px 20px 0 20px;
`;

export const EyeStyled = styled(Eye)`
	color: #ffffff;
	margin-right: 10px;

	svg {
		width: 18px;
		height: 18px;
	}
`;

export const EyeOffStyled = styled(EyeOff)`
	color: #ffffff;
	margin-right: 10px;

	svg {
		width: 18px;
		height: 18px;
	}
`;

export const StyledThreeDot = styled(ThreeDot)`
	cursor: pointer;
	width: 16px;
	height: 14px;

	use {
		fill: #ffffff;
	}
`;

export const MoreFiltersButton = styled(Button)`
	margin: 0 auto;
	display: flex;
	gap: 4px;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
	font-size: 0.75rem;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.blue.blue400};

	&:hover {
		color: ${({ theme }) => theme.colors.white.white};
		path {
			stroke: ${({ theme }) => theme.colors.white.white};
		}
	}
`;

export const StyledAngleArrow = styled(AngleArrow)`
	path {
		stroke: ${(p) => p.theme.colors.blue.blue400};
	}
`;
