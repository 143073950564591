import { useGetAssociatedApns } from 'api/ppm-property/associatedApns/useGetAssociatedApns';
import { Comp } from 'types';
import { isSaleComp } from 'utils/compHelpers';

type Props = {
	comp: Comp;
};

export const useApn = ({ comp }: Props) => {
	const { apn = '', fips = '', numberOfParcels = 1 } = getParcelData(comp);
	const enabled = !!fips && !!apn;

	const {
		data: associatedApns,
		isFetching,
		isLoading,
	} = useGetAssociatedApns(
		{ apn, fips },
		{ enabled: enabled && numberOfParcels > 1 }
	);

	return {
		data: !enabled
			? undefined
			: associatedApns && associatedApns.length > 0
				? associatedApns
				: apn,
		isFetching,
		isLoading,
		isEnabled: enabled,
	};
};

const getParcelData = (comp: Comp) => {
	if (isSaleComp(comp)) {
		const portfolio = comp.portfolio[0];
		return {
			apn: portfolio.apn,
			fips: portfolio.fips,
			numberOfParcels: portfolio.numberOfParcels,
		};
	}

	return {
		apn: comp.apn,
		fips: comp.fips,
		numberOfParcels: comp.numberOfParcels,
	};
};
