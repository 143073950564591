import { createContext, ReactNode, useContext } from 'react';
import { SearchTableProps } from './types';
import { isLoadingRow } from './utils';

type Props<Row> = SearchTableProps<Row> & {
	children: ReactNode;
};

export const OptionsProvider = <Row,>({ children, ...props }: Props<Row>) => {
	const getRowId = (row: Row) => {
		if (isLoadingRow(row)) {
			return row.id;
		}
		return props.getRowId(row);
	};

	const showAverages = !!props.averages;

	return (
		<OptionsContext.Provider value={{ ...props, getRowId, showAverages }}>
			{children}
		</OptionsContext.Provider>
	);
};

const OptionsContext = createContext({} as OptionsContextValue);

export const useOptionsContext = () => useContext(OptionsContext);

type OptionsContextValue = SearchTableProps<any> & {
	showAverages: boolean;
};
