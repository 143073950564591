import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { StatBox, StatsContainer } from 'ExchangeDashboard/UI';
import React from 'react';
import { PDFPanel, PanelDescription } from './UI';

export const AveragesPanel = () => {
	const { averages, timeline } = useExchangeDashboardAverages();

	return (
		<PDFPanel id="averages" title="Averages">
			<div>
				<StatsContainer>
					<StatBox
						property="Starting Rent (PSF)"
						value={averages?.startingRent?.value ?? 'N/A'}
					/>
					<StatBox
						property="Net Effective Rent"
						value={averages?.effectiveRent?.value ?? 'N/A'}
					/>
					<StatBox property="TI Value" value={averages?.ti?.value ?? 'N/A'} />
					<StatBox
						property="Free Rent"
						value={averages?.freeMonths?.value ?? 'N/A'}
					/>
					<StatBox
						property="Term"
						value={averages?.leaseTerm?.value ?? 'N/A'}
					/>
				</StatsContainer>
			</div>
			<PanelDescription>
				Averages encompass leases executed in the last{' '}
				<b>&nbsp;{timeline} months.</b>
			</PanelDescription>
		</PDFPanel>
	);
};
