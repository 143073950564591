import { API } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS, useUserQuery } from 'api';

type TeamUser = {
	creditPoints: number;
	email: string;
	firstName: string;
	id: number;
	isTeamAdmin: boolean;
	lastName: string;
	market: string;
	points: number;
	userName: string;
};

type TeamInfoMember = {
	userId: number;
	firstName: string;
	lastName: string;
};

type TeamInvite = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
};

type TeamInfo = {
	teamId: number;
	name: string;
	members: TeamInfoMember[];
};

export type Team = {
	id: number;
	invites: TeamInvite[];
	name: string;
	points: number;
	users: TeamUser[];
};

export function useTeamQuery(teamId: number | undefined) {
	const result = useQuery({
		queryKey: [QUERY_KEYS.TEAM_INFO, teamId],
		queryFn: async () => {
			const { data } = await API.get<Team>(`/api/teams/${teamId}`);
			return data;
		},
		enabled: !!teamId,
	});
	return result;
}

export function useTeamDashboardQuery() {
	const result = useQuery({
		queryKey: [QUERY_KEYS.TEAM_INFO_DASHBOARD],
		queryFn: async () => {
			const { data } = await API.get<TeamInfo>('/api/dashboard/teamInfo');
			return data;
		},
	});
	return result;
}

export function useTeamAssignCreditsMutation(teamId: number | undefined) {
	const queryClient = useQueryClient();
	const { data: user } = useUserQuery();
	return useMutation({
		mutationFn: async (team: Team) => {
			await API.put(`/api/teams/${team.id}`, team);
			const teamCurrentUser = team.users.find(
				(teamUser) => teamUser.id === user.id
			);
			if (teamCurrentUser?.points !== user.points) {
				queryClient.invalidateQueries([QUERY_KEYS.USER]);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.TEAM_INFO, teamId]);
		},
	});
}

export const useTeamInfoRequestMutation = (
	options?: UseTeamInfoRequestMutationOptions
) => {
	return useMutation({
		mutationFn: async (params) => {
			const response = await API.post<void, UseTeamInfoRequestMutationParams>(
				'/api/dashboard/teamInfoRequest',
				params
			);
			return response.data;
		},
		...options,
	});
};

type UseTeamInfoRequestMutationParams = { name: string; address: string };

type UseTeamInfoRequestMutationOptions = UseMutationOptions<
	void,
	unknown,
	UseTeamInfoRequestMutationParams
>;
