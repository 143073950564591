import {
	MARKER_COLOR_MAP,
	PINPOINT_CIRCLE_COLOR_MAP,
	PINPOINT_TEXT_SIZE,
} from '@compstak/maps';
import { Circle, Path, Svg, Text } from '@react-pdf/renderer';

type Props = {
	text?: string;
	showCircle?: boolean;
	pinColor?: keyof typeof MARKER_COLOR_MAP;
};

export const PdfPinpoint = ({ text, showCircle, pinColor = 'blue' }: Props) => {
	return (
		<Svg
			width={18}
			height={28}
			viewBox="0 0 18 28"
			fill="none"
			style={{
				position: 'relative',
				width: 12,
			}}
		>
			<Path
				fillRule="evenodd"
				d="M5.05751 1.09553C0.697819 3.06521 -1.03631 7.57173 0.616415 12.6369C1.86556 16.4644 5.82511 24.1582 7.66816 26.3384L9.07266 28L10.7455 25.5419C15.0696 19.1884 18.4289 10.8001 17.8562 7.78684C17.0537 3.55877 12.9674 0 8.91501 0C8.12677 0 6.39092 0.49299 5.05751 1.09553Z"
				fill={MARKER_COLOR_MAP[pinColor]}
			/>
			{showCircle && (
				<Circle
					cx="8.95998"
					cy="8.95998"
					r="3.36"
					fill={PINPOINT_CIRCLE_COLOR_MAP[pinColor]}
				/>
			)}
			{text && (
				<Text
					x="50%"
					y={PINPOINT_TEXT_SIZE + 1}
					fill="#fff"
					textAnchor="middle"
					style={{
						fontSize: 12,
					}}
				>
					{text}
				</Text>
			)}
		</Svg>
	);
};
