import styled from 'styled-components';
import { useColumnsContext } from '../Columns';
import { HEADER_INNER_CELL_HEIGHT } from '../constants';
import { useLayoutContext } from '../LayoutProvider';
import { TableOverlay, TextOverflow } from '../UI';
import { useColumnReorderContext } from './ColumnReorderProvider';

export const ColumnReorderOverlay = () => {
	const { columns } = useColumnsContext();
	const { headerHeight } = useLayoutContext();
	const { isDragging, overlayLeft, overlayWidth, overlayHeight, columnIndex } =
		useColumnReorderContext();

	if (!isDragging) return null;

	const column = columns[columnIndex];

	return (
		<>
			<PositionContainer
				style={{
					top: 0,
					left: overlayLeft,
					width: overlayWidth,
					height: overlayHeight,
				}}
			>
				<ColumnLabel>
					<TextOverflow>{column.label}</TextOverflow>
				</ColumnLabel>
				<PreviewColumn />
			</PositionContainer>
			<TableOverlay style={{ top: headerHeight }} />
		</>
	);
};

const PositionContainer = styled.div`
	position: absolute;
	// need this for the elementFromPoint to work
	pointer-events: none;
	display: grid;
	grid-template-rows: ${HEADER_INNER_CELL_HEIGHT}px auto;
	border: 1px solid ${(p) => p.theme.colors.neutral.n40};
`;

const PreviewColumn = styled.div`
	background-color: rgba(47, 145, 250, 0.1);
	box-sizing: border-box;
	pointer-events: auto;
`;

const ColumnLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: ${(p) => p.theme.colors.white.white};
	color: ${(p) => p.theme.colors.blue.blue600};
	font-weight: 500;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n40};
	font-size: 12px;
	padding: 0 0.625rem;
	font-family: system-ui;
	text-align: center;
	box-sizing: border-box;
	overflow: hidden;
`;
