import { Feature, MultiPolygon, Polygon, Properties } from '@turf/helpers';
import { MultiPolygonFilter } from 'models/filters/types';
import { createFilterFromPolygon } from '../PolygonFilter/createFilterFromPolygon';

export const createMultiPolygonFilterFromFeature = (
	feature: Feature<Polygon | MultiPolygon, Properties>
): MultiPolygonFilter => {
	if (feature.geometry.type === 'Polygon') {
		const polygon = feature as Feature<Polygon, Properties>;
		return [createFilterFromPolygon(polygon)];
	}

	const multiPolygon = feature as Feature<MultiPolygon, Properties>;
	return multiPolygon.geometry.coordinates[0].map((coordinates) =>
		coordinates.map(([lng, lat]) => ({
			lat,
			lng,
		}))
	);
};
