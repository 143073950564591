import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { ReactNode } from 'react';
import { PropertyTypeMultiselect } from './PropertyTypeMultiselect';
import { getLabelForFilterAttribute } from 'models/filters/util/filterHelpers';

const attribute = 'buildingPropertyTypeId' as const;

type PropertyTypeFilterProps = {
	children?: ReactNode;
	defaultSelected?: boolean;
	inputId?: string;
};

export const PropertyTypeFilter = ({
	children,
	defaultSelected,
	inputId,
}: PropertyTypeFilterProps) => {
	const { compType, searchTerm } = useFilterFieldContext();

	const hasChildrenAndInSearchMode = !!children && !!searchTerm;

	return (
		<FilterBase
			attribute={attribute}
			labelSynonyms={
				children
					? [
							getLabelForFilterAttribute({
								attribute: 'buildingPropertySubtype',
								compType,
							}),
						]
					: undefined
			}
			defaultSelected={defaultSelected || hasChildrenAndInSearchMode}
			inputId={inputId}
		>
			{(props) => (
				<>
					<PropertyTypeMultiselect {...props} />
					{children}
				</>
			)}
		</FilterBase>
	);
};
