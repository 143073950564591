import { MarketRentAnalyticsPeriod, useMarketRentAnalyticsState } from 'maps';
import { MapAnalyticsInfoDescription } from '../UI';
import { useSpaceTypeLabel } from './useSpaceTypeLabel';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';

export const MarketRentAnalyticsDescription = () => {
	const [state] = useMarketRentAnalyticsState();

	const spaceType = useSpaceTypeLabel();

	return (
		<MapAnalyticsInfoDescription>
			Map reflects estimated{' '}
			{state.period === MarketRentAnalyticsPeriod.MONTH ? 'monthly' : 'annual'}{' '}
			for {spaceType} lease transactions.
			<br />
			<CalculationOverviewLink context="marketRent" />
		</MapAnalyticsInfoDescription>
	);
};
