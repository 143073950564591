import { CompsetType, useCompsetType } from 'api/utils';
import { useMufaRemovePropertyMutation } from './useMufaRemovePropertyMutation';
import { useRemovePropertyMutation } from './useRemovePropertyMutation';

type Props = {
	propertyId: number;
};

export const useRemoveProperty = ({ propertyId }: Props) => {
	const { data: compsetType } = useCompsetType({ propertyId });

	const mutation = useRemovePropertyMutation();

	const mufaMutation = useMufaRemovePropertyMutation();

	return compsetType === CompsetType.COMMERCIAL
		? mutation
		: (mufaMutation as unknown as typeof mutation);
};
