import { checkComps } from 'actions/export';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ExportCheckStatus } from 'reducers/export';
import Menu from 'Singletons/Menu';
import { Comp, CompType } from 'types';
import spinner from 'ui/styles/spinner.less';
import CrossIcon from '../../../ui/svg_icons/cross.svg';
import {
	DEFAULT_PER_MARKET_POLICY,
	useExportMarketTable,
} from './ExportMarketTable';
import styles from './exportMenu.less';

import { ChevronLeft } from '@compstak/ui-kit';
import { useAppSelector } from 'util/useAppSelector';
import { MenuComponentNames } from '../../../Singletons/Menu/types';
import { EXPORT_COMPS_LIMIT } from './const';
import { ExportExcelFinalDialog } from './ExportExcelFinalDialog';
import { InitialExportDialog } from './InitialExportDialog';
import {
	BackIconStyled,
	ExcelHeaderWrap,
	ExcelIconStyled,
	ExportPopupContainer,
	Header,
	HeaderCloseWrap,
	IconContainerStyled,
	MarginBottom,
	SubHeader,
} from './UI';

export type UIExportType = 'xls' | 'pdf' | undefined;

export type ExportComponentCustomProps = Partial<{
	exportType: UIExportType;
	singleComp: boolean;
}>;

type ExportComponentProps = {
	closeMenu: (componentName?: MenuComponentNames) => void;
	comps: Comp[];
	compType: CompType;
} & ExportComponentCustomProps;

const MENU_COMPONENT_NAME = 'export-menu';

export const ExportMenu = ({
	comps,
	singleComp,
	compType,
	exportType: initialExportType,
	closeMenu,
}: ExportComponentProps) => {
	const dispatch = useDispatch();
	const status = useAppSelector((state) => state.export.status);
	const [exportType, setExportType] = useState<UIExportType>(initialExportType);
	const [withFinancial, setWithFinancial] = useState(true);

	const hasInitialStep = !initialExportType;
	const isInitialStep = !exportType;

	const compIds = useMemo(() => {
		return comps.map((comp) => comp.id);
	}, [comps]);

	useEffect(() => {
		dispatch(checkComps(compIds, compType));
	}, []);

	if (!status) {
		return (
			<div className={styles.popup}>
				<div className={spinner.small} />
			</div>
		);
	}

	const isPerMarketPolicy = 'perMarket' in status;
	const availableAmount = status.unlimited
		? 0
		: withFinancial
			? status.availableWithFinancial
			: status.availableWithoutFinancial;

	const isBodyRendered = Boolean(status.unlimited || availableAmount);
	const isLimitMessageShownAtHeader =
		!singleComp &&
		!isPerMarketPolicy &&
		isBodyRendered &&
		isInitialStep &&
		comps.length > EXPORT_COMPS_LIMIT;

	const renderHeader = () => {
		if (exportType === 'xls') {
			return (
				<ExcelHeaderWrap>
					{hasInitialStep && (
						<BackIconStyled onClick={() => setExportType(undefined)}>
							<ChevronLeft />
						</BackIconStyled>
					)}

					<IconContainerStyled>
						<ExcelIconStyled width={14} height={16} />
						<span className="title">Excel Export</span>
					</IconContainerStyled>
				</ExcelHeaderWrap>
			);
		} else {
			if (status.unlimited) {
				return 'Unlimited Exports Remaining';
			}

			if (!availableAmount) {
				return 'No Exports Remaining';
			}

			if (!singleComp && isPerMarketPolicy) {
				return <div>Export your comps</div>;
			}

			return `${availableAmount}/${status.limit} Exports Remaining`;
		}
	};

	return (
		<ExportPopupContainer
			className={styles.popup}
			isBodyRendered={isBodyRendered}
		>
			<MarginBottom>
				<Header>
					<span>{renderHeader()}</span>
					<HeaderCloseWrap onClick={() => closeMenu(MENU_COMPONENT_NAME)}>
						<CrossIcon />
					</HeaderCloseWrap>
				</Header>
				{isLimitMessageShownAtHeader && (
					<SubHeader>
						{`Exports are limited to ${EXPORT_COMPS_LIMIT} comps per file. Selecting one of the
							export options will export the first ${EXPORT_COMPS_LIMIT} comps in your
							selection.`}
					</SubHeader>
				)}
			</MarginBottom>
			{isBodyRendered && (
				<ExportMenuBody
					status={status}
					comps={comps}
					exportType={exportType}
					compType={compType}
					withFinancial={withFinancial}
					setWithFinancial={setWithFinancial}
					singleComp={singleComp}
					setExportType={setExportType}
				/>
			)}
		</ExportPopupContainer>
	);
};

interface ExportMenuBodyProps {
	status: ExportCheckStatus;
	comps: Comp[];
	exportType: UIExportType;
	compType: CompType;
	withFinancial: boolean;
	setWithFinancial: (value: boolean) => void;
	singleComp?: boolean;
	setExportType: (value: UIExportType) => void;
}

const ExportMenuBody = ({
	status,
	comps,
	exportType,
	compType,
	withFinancial,
	setWithFinancial,
	singleComp,
	setExportType,
}: ExportMenuBodyProps) => {
	// type-safety: the hook can't be conditional, so we need to pass all the params explicitly,
	// but we do not render the table yet in case of "unlimited" access
	const exportMarketTableParams = status.unlimited
		? {
				perMarketPolicy: DEFAULT_PER_MARKET_POLICY,
				withFinancial,
				comps,
				nonFinancialAvailableAmount: 0,
			}
		: {
				perMarketPolicy: status.perMarket || DEFAULT_PER_MARKET_POLICY,
				withFinancial,
				nonFinancialAvailableAmount: status.availableWithoutFinancial || 0,
				comps,
			};

	const exportMarketTableProps = useExportMarketTable(exportMarketTableParams);
	const { selectedMarketsCount, selectedMarkets } = exportMarketTableProps;

	const { limitedCompsIds: compsIds, unlimitedCompsIds } = getExportedCompIds(
		comps,
		selectedMarkets,
		selectedMarketsCount,
		exportMarketTableParams.perMarketPolicy.length
	);

	return exportType === 'xls' ? (
		<ExportExcelFinalDialog
			compIds={compsIds}
			compType={compType}
			withFinancial={withFinancial}
		/>
	) : (
		<InitialExportDialog
			compIds={compsIds}
			compType={compType}
			withFinancial={withFinancial}
			setWithFinancial={(event) => {
				setWithFinancial(event.target.value === 'full');
			}}
			status={status}
			singleComp={singleComp}
			onExcelButtonClick={() => {
				setExportType('xls');
			}}
			exportMarketTableProps={exportMarketTableProps}
			unlimitedCompsIds={unlimitedCompsIds}
		/>
	);
};

export function getExportedCompIds(
	comps: Comp[],
	selectedMarkets: Record<string, boolean>,
	selectedMarketsCount: number,
	policyCount: number
) {
	const isSomeSelectedButNotAll =
		selectedMarketsCount > 0 && selectedMarketsCount < policyCount;

	let compsToExport = comps;

	if (isSomeSelectedButNotAll) {
		compsToExport = compsToExport.filter(
			(comp) => selectedMarkets[comp.marketId]
		);
	}

	const unlimitedCompsIds = compsToExport.map((comp) => comp.id);

	const compsIdsToExport =
		unlimitedCompsIds.length > EXPORT_COMPS_LIMIT
			? unlimitedCompsIds.slice(0, EXPORT_COMPS_LIMIT)
			: unlimitedCompsIds;

	return { unlimitedCompsIds, limitedCompsIds: compsIdsToExport };
}

Menu.addComponent(MENU_COMPONENT_NAME, ExportMenu);
