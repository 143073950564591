import { SPACE_TYPE_ID_TO_NAME, SpaceTypeName } from 'api';
import { useMemo, useState } from 'react';
import { LeaseComp } from 'types';
import { useExportPdfContext } from '../ExportPdfProvider';
import { DEFAULT_NUM_OF_LEASES_SELECTED } from '../constants';
import { getLeasesByStatus } from '../getLeasesByStatus';
import { EditableLeaseComp, PdfColumn } from '../types';
import { createEditableRows } from '../useEditableRows';
import { ExportPdfData } from '../useExportPdfData';
import { useLeasesPdfColumns } from '../useLeasesPdfColumns';

type Props = {
	data: ExportPdfData;
};

export const useEditableLeasesState = ({ data }: Props) => {
	const leasesColumns = useLeasesPdfColumns({ property: data.property });

	const { leaseOptionsState } = useExportPdfContext();
	const { leaseStatus, leaseSpaceTypes } = leaseOptionsState;

	const leasesAfterStatus = useMemo(() => {
		return getLeasesByStatus(leaseStatus, data.leases);
	}, [data.leases, leaseStatus]);

	const leasesBySpaceType = useMemo(() => {
		return Object.groupBy(
			leasesAfterStatus.filter(
				(l) => leaseSpaceTypes[l.spaceType ?? SPACE_TYPE_ID_TO_NAME['13']]
			),
			(l) => l.spaceType ?? SPACE_TYPE_ID_TO_NAME['13']
		);
	}, [leasesAfterStatus, leaseSpaceTypes]);

	const [
		editableLeasesColumnsBySpaceType,
		setEditableLeasesColumnsBySpaceType,
	] = useState(() => {
		const bySpaceType: Record<
			string,
			PdfColumn<EditableLeaseComp, LeaseComp>[]
		> = {};

		Object.keys(leasesBySpaceType).forEach((key) => {
			bySpaceType[key] = leasesColumns;
		});

		return bySpaceType;
	});

	const [editableLeasesRowsBySpaceType, setEditableLeasesRowsBySpaceType] =
		useState(() => {
			const bySpaceType: Record<string, EditableLeaseComp[]> = {};

			Object.keys(leasesBySpaceType).forEach((spaceType) => {
				bySpaceType[spaceType] = createEditableRows({
					rows: (leasesBySpaceType as Record<SpaceTypeName, LeaseComp[]>)[
						spaceType as SpaceTypeName
					],
					columns: editableLeasesColumnsBySpaceType[spaceType],
					getId: (l) => l.id,
				});
			});

			return bySpaceType;
		});

	const [leasesSelectionBySpaceType, setLeasesSelectionBySpaceType] = useState(
		() => {
			const tenMostRecentLeases = leasesAfterStatus.slice(
				0,
				DEFAULT_NUM_OF_LEASES_SELECTED
			);
			const bySpaceType: Record<string, number[]> = {};
			Object.keys(leasesBySpaceType).forEach((spaceType) => {
				const mostRecentLeases = tenMostRecentLeases.filter(
					(l) =>
						l.spaceType === spaceType ||
						(l.spaceType === undefined && spaceType === 'Other')
				);

				bySpaceType[spaceType] = mostRecentLeases.map((l) => l.id);
			});
			return bySpaceType;
		}
	);

	const selectedLeaseIds = useMemo(() => {
		const ids: number[] = [];
		Object.values(leasesSelectionBySpaceType).forEach((idsBySpaceType) => {
			ids.push(...idsBySpaceType);
		});
		return ids;
	}, [leasesSelectionBySpaceType]);

	const selectedLeasesBySpaceType = useMemo(() => {
		const leases: Record<string, EditableLeaseComp[]> = {};
		Object.entries(leasesSelectionBySpaceType).forEach(([spaceType, ids]) => {
			if (ids.length === 0) return;

			leases[spaceType] = ids.map(
				(id) =>
					editableLeasesRowsBySpaceType[spaceType].find((l) => l.id === id)!
			);
		});
		return leases;
	}, [editableLeasesRowsBySpaceType, leasesSelectionBySpaceType]);

	return {
		editableLeasesColumnsBySpaceType,
		setEditableLeasesColumnsBySpaceType,
		editableLeasesRowsBySpaceType,
		setEditableLeasesRowsBySpaceType,
		leasesSelectionBySpaceType,
		setLeasesSelectionBySpaceType,
		selectedLeaseIds,
		selectedLeasesBySpaceType,
	};
};
