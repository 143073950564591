import { User } from '@compstak/common';
import { AssociatedApn } from 'api/ppm-property/associatedApns/useGetAssociatedApns';
import { ReactNode } from 'react';
import { PropertyComp } from 'types';
import { SectionKey } from './ExportPdfProvider';

export type PdfColumn<EditableRow, Row> = {
	id: string;
	header: string;
	getValue: (args: { row: Row; rowIndex: number }) => string | number;
	/** @default 1 */
	flex?: number;
	/** @default "left" */
	hAlign?: 'left' | 'center' | 'right';
	/** @default true */
	wrapBodyContentWithText?: boolean;
	/** @default true */
	isVisible: boolean;
	pdfBody?: (args: { row: EditableRow; rowIndex: number }) => ReactNode;
	body?: (args: {
		row: EditableRow;
		rowIndex: number;
		defaultContent: ReactNode;
	}) => ReactNode;
};

export type EditableLeaseComp = {
	id: number;
	propertyId?: number;
	executionQuarter: string;
};

export enum ExportPdfStep {
	ONE = 0,
	TWO = 1,
}

export type ExportPdfPageState = {
	titles: Record<SectionKey, string>;
	preparedFor: string;
	preparedForLogo: string | null;
	preparedBy: string;
	notes: string;
	logo: string | null;
	labelsVisible: ExportPdfLabelsVisibleState;
};

export type ExportPdfLabelsVisibleState = {
	createdOn: boolean;
	preparedFor: boolean;
	preparedBy: boolean;
	notes: boolean;
};

export type PdfPageProps = {
	property: PropertyComp;
	page: ExportPdfPageState;
	apn: AssociatedApn[] | string | undefined;
	user: User;
};

export type EditableCompsetRow = {
	id: number;
	buildingAddressAndCity: string;
	submarket: string;
	distance: string;
	propertyMarketStartingRent: string;
	buildingYear: string;
	buildingYearRenovated: string;
	buildingFloorsCount: string;
	buildingSize: string;
	askingRentPerSqFt: string;
	effectiveRentPerSqFt: string;
	numberOfUnits: string;
};

export enum LeaseStatus {
	ALL = 'ALL',
	ACTIVE = 'ACTIVE',
}
