import { Spinner } from '@compstak/ui-kit';
import { useGetUserSubmissions } from 'api/submissions/submissionQueries';
import styled from 'styled-components';
import './styles/credits.nomodule.less';
import SubmissionDetails from './submissionDetails';

export function CreditsLedger() {
	const { data: userSubmissions, isLoading, isError } = useGetUserSubmissions();

	if (isLoading) {
		return (
			<LedgerBodyWrapper>
				<WithMargin>
					<Spinner size="l" isCentered={true} />
				</WithMargin>
			</LedgerBodyWrapper>
		);
	}

	if (isError || !userSubmissions) {
		return (
			<LedgerBodyWrapper>
				<WithMargin>Failed to get user submissions</WithMargin>
			</LedgerBodyWrapper>
		);
	}

	return (
		<LedgerBodyWrapper>
			{userSubmissions.length === 0
				? EmptyList()
				: userSubmissions.map((userSubmission) => (
						<SubmissionDetails
							key={userSubmission.submission.id}
							{...userSubmission}
						/>
				  ))}
		</LedgerBodyWrapper>
	);
}

const LedgerBodyWrapper = ({ children }: { children: React.ReactNode }) => (
	<div className={'credits-ledger-page'}>
		<SubmissionsHeader />
		<div className="credits-table-body">{children}</div>
	</div>
);

function EmptyList() {
	return (
		<div className="empty-list">
			<h3>We don&rsquo;t have any submissions from you yet</h3>
			<a
				href="/upload"
				className="button_button button_large button_green"
				data-qa-id="earn-credits-link"
			>
				Earn Credits
			</a>
		</div>
	);
}

function SubmissionsHeader() {
	return (
		<header className="credits-table-row">
			<div className="credits-cell col-4-12">File Name</div>
			<div className="credits-cell col-2-12">Date Submitted</div>
			<div className="credits-cell col-2-12">Status</div>
			<div className="credits-cell col-1-12 align-right"># Of Comps</div>
			<div className="credits-cell col-1-12 align-right">Credits</div>
			<div className="credits-cell col-2-12 align-right">Details</div>
		</header>
	);
}

const WithMargin = styled.div`
	margin: 40px;
`;
