import {
	DefaultDialogCloseButton,
	DefaultDialogCloseIcon,
	DefaultDialogContent,
	DefaultDialogOverlay,
	Dialog,
	DialogContent,
} from '@compstak/ui-kit';
import { DialogContentProps } from '@radix-ui/react-dialog';
import { onInteractOutsideModal } from 'Components/Modals/common/UI';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import 'ui/Components/Export/page.nomodule.css';
import { useDashboardExportContext } from './DashboardExportProvider';
import { ExportPdfProvider } from './ExportPdfProvider';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepTwoV2 } from './StepTwoV2';

export const dashboardModal = 'exchange-dashboard-export';

export type UserEdits = {
	preparedFor: string;
	notes: string;
	createdOn: string;
	clientLogo?: string;
	companyLogo?: string;
};

type Props = {
	closeDialog: () => void;
};

export const DashboardExportDialog = ({ closeDialog }: Props) => {
	const { step, setStep } = useDashboardExportContext();
	const flags = useFeatureFlags();

	const onInteractOutside = useCallback<
		NonNullable<DialogContentProps['onInteractOutside']>
	>(
		(event) => {
			onInteractOutsideModal(event, closeDialog);
		},
		[closeDialog]
	);

	let content: ReactNode = null;

	if (step === 'stepOne') {
		content = (
			<DefaultDialogOverlay>
				<DefaultDialogCloseButton>
					<DefaultDialogCloseIcon />
				</DefaultDialogCloseButton>
				<DefaultDialogContent onInteractOutside={onInteractOutside}>
					<StepOne
						onSubmit={() => setStep('stepTwo')}
						closeDialog={closeDialog}
					/>
				</DefaultDialogContent>
			</DefaultDialogOverlay>
		);
	} else if (step === 'stepTwo') {
		content = (
			<StepTwoDialogOverlay>
				<DialogContent onInteractOutside={onInteractOutside}>
					{flags.DashboardExportPdfRefactor ? (
						<ExportPdfProvider>
							<StepTwoV2
								onStepBack={() => setStep('stepOne')}
								closeDialog={closeDialog}
							/>
						</ExportPdfProvider>
					) : (
						<StepTwo
							onStepBack={() => setStep('stepOne')}
							closeDialog={closeDialog}
						/>
					)}
				</DialogContent>
			</StepTwoDialogOverlay>
		);
	}

	return <Dialog open>{content}</Dialog>;
};

const StepTwoDialogOverlay = styled(DefaultDialogOverlay)`
	overflow-y: auto;
`;
