import { API } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
	UseMutationOptions,
} from '@tanstack/react-query';
import { Portfolio } from '../../types';
import { trackSegmentAddPropertyToPortfolio } from 'middleware/tracking/segment';
import { invalidatePortfolioQueries } from 'PortfolioAnalytics/invalidatePortfolioQueries';

const updatePortfolio = async (params: UpdatePortfolioParams) => {
	const res = await API.put<UpdatePortfolioResponse>(
		`/pa/v2/api/portfolios/${params.id}`,
		params.body
	);
	return res.data;
};

export const useUpdatePortfolioMutation = (
	options?: UseMutationOptions<
		Portfolio,
		Error,
		UpdatePortfolioParams,
		QueryClient
	>
) => {
	const queryClient = useQueryClient();
	const { onSuccess: onSuccessFromOptions, ...restOptions } = options || {};
	return useMutation({
		mutationFn: (params: UpdatePortfolioParams) => updatePortfolio(params),
		onSuccess: (data, variables) => {
			const { id: portfolioId, newPropertyIds: propertyIds } = variables.body;
			propertyIds &&
				trackSegmentAddPropertyToPortfolio(portfolioId, propertyIds);

			invalidatePortfolioQueries(queryClient);

			onSuccessFromOptions?.(data, variables, queryClient);
		},
		...restOptions,
	});
};

export type UpdatePortfolioParams = {
	id: number;
	body: {
		id: number;
		name: string;
		description: string | undefined;
		newPropertyIds?: number[];
		removedPropertyIds?: number[];
	};
};

type UpdatePortfolioResponse = Portfolio;
