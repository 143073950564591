import { PropertyLease } from 'api';
import { useMemo } from 'react';
import { LeaseComp } from 'types';

type Props<T extends PropertyLease | LeaseComp> = {
	leases: T[];
};

export const usePropertyLeasesBySpaceType = <
	T extends PropertyLease | LeaseComp,
>({
	leases,
}: Props<T>) => {
	const leasesBySpaceType = useMemo(
		() => Object.groupBy(leases ?? [], (item) => item.spaceType ?? 'Other'), // There a few comps with no spaceType - undefined
		[leases]
	);

	return leasesBySpaceType;
};
