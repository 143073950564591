import { API } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio } from '../../types';
import { trackSegmentCreatePortfolio } from 'middleware/tracking/segment';

const createPortfolio = async (params: CreatePortfolioParams) => {
	const res = await API.post<Portfolio>('/pa/v2/api/portfolios', params);
	return res.data;
};

export const useCreatePortfolioMutation = (
	options?: UseMutationOptions<Portfolio, Error, CreatePortfolioParams>
) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: CreatePortfolioParams) => createPortfolio(params),
		onSuccess: (newPortfolio, params) => {
			const existingPortfolios = queryClient.getQueryData<Portfolio[]>([
				QUERY_KEYS.ALL_PORTFOLIOS,
			]);
			trackSegmentCreatePortfolio({
				existingPortfolios: existingPortfolios || [],
				newPortfolio,
				newPortfolioPropertyIds: params.propertyIds,
			});
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
		...options,
	});
};

export type CreatePortfolioParams = {
	name: string;
	description?: string;
	propertyIds?: number[];
};
