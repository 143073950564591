import { Image } from '@react-pdf/renderer';
import { PdfSectionBox, PdfSectionDescriptionText } from '../PdfUI';
import { PdfSectionProps } from '../constants';

export const MarketRentMapPdfSection = ({
	marketRentHeatMap: { title, description, url },
}: PdfSectionProps) => {
	return (
		<PdfSectionBox title={title}>
			{url && <Image src={url} style={{ marginBottom: 12 }} />}
			<PdfSectionDescriptionText>{description}</PdfSectionDescriptionText>
		</PdfSectionBox>
	);
};
