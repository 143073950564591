import {
	FloorplansUnitTypeCellRenderer,
	NumberCellRenderer,
	PercentCellRenderer,
	PriceCellRenderer,
	SaveDataMenu,
	getRendererValue,
} from '@compstak/ui-kit';
import { MufaFloorPlan, useMufaFloorPlansQuery } from 'api';

import React from 'react';
import { useParams } from 'react-router-dom';

const mapToCSV = (data?: Array<MufaFloorPlan>) => {
	return Array.isArray(data)
		? data.map((i) => {
				const unitTypeProps = {
					row: {
						original: i,
					},
				};
				return {
					'Unit Type': getRendererValue(
						<FloorplansUnitTypeCellRenderer {...unitTypeProps} />
					),
					'Unit Size (SF)': getRendererValue(
						<NumberCellRenderer value={i.unitSqFt} />
					),
					'Total Units': getRendererValue(
						<NumberCellRenderer value={i.unitCount} />
					),
					'Asking Rent $/Unit': getRendererValue(
						<PriceCellRenderer value={i.askingRentPerUnit} />
					),
					'Asking Rent $/SF': getRendererValue(
						<PriceCellRenderer
							value={i.askingRentPerSqFt}
							format={'currencyTwoDecimals'}
						/>
					),
					'Effective Rent $/Unit': getRendererValue(
						<PriceCellRenderer value={i.effectiveRentPerUnit} />
					),
					'Effective Rent $/SF': getRendererValue(
						<PriceCellRenderer
							value={i.effectiveRentPerSqFt}
							format={'currencyTwoDecimals'}
						/>
					),
					'Concessions ($)': getRendererValue(
						<PriceCellRenderer value={i.concessionValue} />
					),
					'Concessions (%)': getRendererValue(
						<PercentCellRenderer
							value={i.concessionsPercentage}
							format={'percentTwoDecimals'}
						/>
					),
				};
			})
		: [];
};

export const FloorPlansMenu = () => {
	const params = useParams();
	const { data } = useMufaFloorPlansQuery({
		propertyId: Number(params.propertyId),
	});

	return (
		<SaveDataMenu
			elementId={CONTAINER_ID}
			// @ts-expect-error legacy
			dataToCopy={mapToCSV(data?.floorPlans)}
			widgetTitle="Title your Image"
			downloadFileName="Floorpricing table"
		/>
	);
};

export const CONTAINER_ID = 'floorplan-pricing-table';

FloorPlansMenu.displayName = 'FloorPlansMenu';
