import { useUnlockConditions } from 'api';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { LeaseComp, SalesComp } from 'types';
import IconLock from '../../../ui/svg_icons/lock.svg';
import { UnlockActionButton } from '../UI';
import { showGetCreditsFeedback } from './showGetCreditsFeedback';
import { useIsRowUnlocking } from './useIsRowUnlocking';
import { useUnlockComp } from './useUnlockComp';

type Props = {
	type: 'lease' | 'sale';
	row: LeaseComp | SalesComp;
};

export const SearchTableUnlockCellButton = ({ type, row }: Props) => {
	const { mutate, isLoading } = useUnlockComp(type);
	const { totalCost, hasEnoughPoints, canUnlockForFree } = useUnlockConditions({
		comps: [row],
	});
	const isRowUnlocking = useIsRowUnlocking({ id: row.id, type });
	const dispatch = useDispatch();

	const tooltipText = canUnlockForFree
		? 'Unlock for free'
		: !hasEnoughPoints
			? 'Get more credits to unlock'
			: `Unlock for ${totalCost} Credits`;

	return (
		<StyledActionButton
			isDisabled={isLoading || !hasEnoughPoints || isRowUnlocking}
			onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
				e.preventDefault();
				e.stopPropagation();
				if (!hasEnoughPoints) {
					return dispatch(showGetCreditsFeedback(row.id));
				}
				if (isLoading || isRowUnlocking) return;
				// @ts-expect-error react query tripping
				mutate({ ids: [row.id] });
			}}
			data-tooltip={tooltipText}
		>
			<StyledIconLock />
			<Divider>|</Divider>
			<CostText>{totalCost}</CostText>
		</StyledActionButton>
	);
};

const StyledIconLock = styled(IconLock)`
	fill: ${(p) => p.theme.colors.neutral.n70};
`;

const Divider = styled.span`
	color: transparent;
`;

const CostText = styled.span`
	color: transparent;
`;

const contentCss = css`
	${StyledIconLock} {
		fill: ${(p) => p.theme.colors.white.white};
	}
	${Divider} {
		color: ${(p) => p.theme.colors.white.white};
	}
	${CostText} {
		color: ${(p) => p.theme.colors.white.white};
	}
`;

const StyledActionButton = styled(UnlockActionButton)`
	background-color: transparent;
	transition: unset;
	&&:hover {
		background-color: ${(p) =>
			p.isDisabled ? p.theme.colors.neutral.n40 : p.theme.palette.success.main};
	}
	&:hover {
		${contentCss}
	}
	${(p) =>
		p.isDisabled &&
		css`
			background-color: ${p.theme.colors.neutral.n40};
			${contentCss}
		`}
`;
