import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { goBack, push } from 'router';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Container, { KeyboardButtons } from './Container';

import actionWrapper from 'util/actionWrapper';
import { modalActions, ModalActions } from './actions';

import { AppState } from 'reducers/root';
import styles from './modal.less';

const componentMap = {};

interface ModalProps {
	component: string;
	data: { modalContainerStyle?: CSSProperties };
	modalActions: ModalActions;
	route: string;
	goBack: NoArgCallback;
	redirect: (arg: string) => void;
}
interface ModalState {
	scrollPos: number;
}

class Modal extends React.Component<ModalProps, ModalState> {
	state = {
		scrollPos: 0,
	};

	static addComponent = ({
		id,
		Component,
		className,
		route,
	}: {
		id: string;
		Component: React.ComponentType<any | string>;
		className?: string;
		route?: string;
	}) => {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		componentMap[id] = {
			Component,
			route,
			className,
		};
	};

	handleModalScroll = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalScroller' does not exist on type 'M... Remove this comment to see the full error message
		const scrollPos = this.modalScroller.scrollTop;
		this.setState({
			scrollPos: scrollPos,
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	closeModal = (event) => {
		if (!event) {
			this.props.modalActions.popModal(this.props.component);
			return;
		}
		if (event.code === KeyboardButtons.ESCAPE) {
			this.props.modalActions.hideModal(this.props.component);
			return;
		}
		if (
			event.currentTarget.dataset.closeButton &&
			!event.isDefaultPrevented()
		) {
			this.props.modalActions.popModal(this.props.component);
			if (this.props.route) {
				this.props.goBack();
			}
		}
		if (
			event.target.dataset &&
			event.target.dataset.closeOnDirectClick &&
			!event.isDefaultPrevented()
		) {
			this.props.modalActions.popModal(this.props.component);
			if (this.props.route) {
				this.props.goBack();
			}
		}
	};

	renderModal() {
		return (
			// @ts-expect-error TS2739: Type '{ children: Element; com...
			<Container
				close={this.closeModal}
				style={{ width: '640px', ...this.props.data?.modalContainerStyle }}
				containerClass={styles.nonRoute}
				{...this.props}
			>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'Component' does not exist on type 'Reado... Remove this comment to see the full error message */}
				<this.props.Component
					{...this.props}
					{...(this.props.data as any)}
					closeModal={this.closeModal}
					scrollPos={this.state.scrollPos}
				/>
			</Container>
		);
	}

	render() {
		if (!this.props.component) {
			return <span data-mr-modal />;
		}

		return (
			<TransitionGroup>
				<CSSTransition
					key={this.props.component}
					classNames="modal-transition"
					timeout={300}
				>
					{this.renderModal()}
				</CSSTransition>
			</TransitionGroup>
		);
	}
}

function mapStoreToProps(store: AppState) {
	const props = store.modal as any;

	if (props.component) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (!componentMap[props.component]) {
			throw new Error('Tried to show an unknown modal: ' + props.component);
		}
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		props.Component = componentMap[props.component].Component;
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		props.routeCallback = componentMap[props.component].routeCallback;
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		props.className = componentMap[props.component].className || '';
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		props.route = componentMap[props.component].route;
		props.headerNotificationShowing = store.headerNotification;
	}
	return props;
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return {
		redirect: push,
		goBack,
		...actionWrapper(
			{
				modalActions,
			},
			dispatch
		),
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(Modal);
