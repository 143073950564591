import { API } from '@compstak/common';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { Feature, MultiPolygon, Polygon } from '@turf/helpers';
import { QUERY_KEYS } from 'api/constants';
import { composeEnabled } from 'api/utils';

const fetchSubmarketPolygons = async ({
	id,
}: FetchSubmarketPolygonsApiParams): Promise<SubmarketPolygonsResponse> => {
	const response = await API.get<SubmarketPolygonsResponse>(
		`/api/markets/${id}/submarketPolygons`
	);

	return response.data;
};

export const useSubmarketPolygons = (
	params: FetchSubmarketPolygonsApiParams,
	options?: UseSubmarketPolygonsOptions
): UseSubmarketPolygonsResponse => {
	return useQuery({
		queryKey: [QUERY_KEYS.SUBMARKET_POLYGONS, params],
		queryFn: () => fetchSubmarketPolygons(params),
		enabled: composeEnabled(params.id != null, options?.enabled),
		...options,
	});
};

type UseSubmarketPolygonsOptions = UseQueryOptions<SubmarketPolygonsResponse>;

type SubmarketProperties = {
	geonameid: string;
	id: number;
	name: string;
};

export type SubmarketPolygon = Feature<
	Polygon | MultiPolygon,
	SubmarketProperties
>;

export type SubmarketPolygonsResponse = {
	type: 'FeatureCollection';
	features: SubmarketPolygon[];
};

type FetchSubmarketPolygonsApiParams = { id: number | string | undefined };

type UseSubmarketPolygonsResponse = UseQueryResult<SubmarketPolygonsResponse>;
