import { MapAnalyticsNoDataMessage, useMarketRentAnalyticsState } from 'maps';
import {
	MapAnalyticsMenuContainer,
	MapAnalyticsMenuPositionContainer,
} from '../UI';
import { MarketRentAnalyticsReadMenu } from './MarketRentAnalyticsReadMenu';
import { MarketRentAnalyticsSettingsMenu } from './MarketRentAnalyticsSettingsMenu';
import { useMarketRentAnalyticsData } from './useMarketRentAnalyticsData';

type Props = {
	onClose: () => void;
};

export const MarketRentAnalyticsMenu = ({ onClose }: Props) => {
	const [state] = useMarketRentAnalyticsState();

	const { data, isError } = useMarketRentAnalyticsData();

	const hasNoData = !data && isError;

	return (
		<MapAnalyticsMenuPositionContainer>
			{hasNoData && <MapAnalyticsNoDataMessage />}
			<MapAnalyticsMenuContainer>
				{state.menuView === 'read' && (
					<MarketRentAnalyticsReadMenu onClose={onClose} />
				)}
				{state.menuView === 'settings' && <MarketRentAnalyticsSettingsMenu />}
			</MapAnalyticsMenuContainer>
		</MapAnalyticsMenuPositionContainer>
	);
};
