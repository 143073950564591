import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { mapAnalyticsMarketIds } from 'Pages/Analytics/config';
import { usePermissions } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';
import { CompType } from 'types';

type Props = {
	compType: CompType;
};

export const useShowMapAnalytics = ({ compType }: Props) => {
	const permissions = usePermissions();
	const [filters] = useFilters();

	const filtersMarkets = getFiltersMarkets(filters);
	const market = filtersMarkets[0];

	return (
		compType === 'lease' &&
		filtersMarkets.length === 1 &&
		!!mapAnalyticsMarketIds[market.id] &&
		!!permissions?.[market.id]?.analytics
	);
};
