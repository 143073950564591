import { TextInput } from '@compstak/ui-kit';
import styled from 'styled-components';

export const SurveyInput = styled(TextInput)`
	margin-top: 12px;
	min-height: 40px;
	height: 40px;
	padding-right: 2px; // To not cut off placeholder text on tablets.
`;

export const SurveyNumInput = styled(SurveyInput)``;
// @ts-expect-error TODO: Add type field to SurveyInput props type in ui-kit.
SurveyNumInput.defaultProps = { type: 'number' };
