import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
	CollapsibleTable,
	SimpleTableColumn,
	SimpleTableBodyRow,
	SimpleTableHeaderCellRoot,
	SimpleTableBodyCellRoot,
	Spinner,
	Button,
} from '@compstak/ui-kit';
import { useState } from 'react';

export type LeagueTableProps<T> = {
	columns: SimpleTableColumn<T>[];
	data: T[];
	id: string;
	isFetching: boolean;
};

const DEFAULT_NUMBER_OF_ROWS = 10;

export const LeagueTable = <T,>({
	columns,
	data,
	id,
	isFetching,
}: LeagueTableProps<T>) => {
	const [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<TableWrap>
			<LeagueTableStyles />
			{isFetching ? (
				<Spinner size="xl" isCentered />
			) : (
				<>
					<BorderWrap>
						<CollapsibleTable
							id={id}
							rows={data}
							bodyRowHeight={48}
							columns={columns}
							renderViewMoreComponent={() => null}
							collapsedNumberOfRows={
								isCollapsed ? DEFAULT_NUMBER_OF_ROWS : data.length
							}
						/>
					</BorderWrap>
					{data.length > DEFAULT_NUMBER_OF_ROWS && (
						<ViewMoreButton
							onClick={() => setIsCollapsed((prevState) => !prevState)}
							variant="ghost"
						>
							{isCollapsed ? 'Show more' : 'Show less'}
						</ViewMoreButton>
					)}
				</>
			)}
		</TableWrap>
	);
};

export const BorderWrap = styled.div`
	border: ${({ theme }) => `1px solid ${theme.colors.gray.gray300}`};
	border-radius: 3px;
	overflow: hidden;
`;

export const TableWrap = styled.div`
	${SimpleTableBodyRow}:nth-child(even) ${SimpleTableBodyCellRoot} {
		background-color: ${({ theme }) => theme.colors.neutral.n10};
	}
	${SimpleTableBodyRow}:last-child ${SimpleTableBodyCellRoot} {
		border-bottom: none;
	}
	${SimpleTableHeaderCellRoot}, ${SimpleTableBodyCellRoot} {
		padding: 0.75rem 0.5rem;
		font-size: 0.875rem;
	}
`;

const LeagueTableStyles = createGlobalStyle`
	${SimpleTableHeaderCellRoot}, ${SimpleTableBodyCellRoot} {
		justify-content: flex-end;
		&:nth-child(1) {
			justify-content: center;
		}
		&:nth-child(2) {
			justify-content: flex-start;
		}
		&:last-child {
			padding-right: 1em;
		}
	}
`;

const ViewMoreButton = styled(Button)`
	float: right;
	text-decoration: underline;
	margin: 8px 0;
	text-transform: none;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;
