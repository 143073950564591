import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
	label: ReactNode;
	isActive: boolean;
	children: ReactNode;
};

export const TabRange = ({ label, isActive, children }: Props) => {
	return (
		<div>
			<Range isActive={isActive}>
				<Label isActive={isActive}>{label}</Label>
			</Range>
			<TabsContainer>{children}</TabsContainer>
		</div>
	);
};

const BORDER_WIDTH = 1;

const Range = styled.div<{ isActive: boolean }>`
	position: relative;
	height: 14px;
	width: 100%;
	border: ${BORDER_WIDTH}px solid
		${(p) =>
			p.isActive ? p.theme.colors.blue.blue400 : p.theme.colors.neutral.n50};
	border-bottom: 0;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	margin-bottom: 0.5rem;
`;

const Label = styled.div<{ isActive: boolean }>`
	font-size: 0.75rem;
	text-transform: uppercase;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, calc(-50% - ${BORDER_WIDTH}px));
	background-color: ${(p) => p.theme.colors.white.white};
	color: ${(p) =>
		p.isActive ? p.theme.colors.blue.blue400 : p.theme.colors.neutral.n70};
	font-weight: 300;
	line-height: 1.25;
	letter-spacing: 0.01em;
	padding: 0 0.625rem;
`;

const TabsContainer = styled.div`
	display: flex;
`;
