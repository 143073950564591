import { SaveDataMenu } from '@compstak/ui-kit';
import { useCompsetExcelRows } from 'Components';
import { usePropertyByIdQuery } from 'api';
import { routes } from 'router';

export const CompsetMenu = () => {
	const params = routes.propertyById.useParams();

	const { data: property } = usePropertyByIdQuery({ id: params.propertyId });

	const excelRows = useCompsetExcelRows({ propertyId: params.propertyId });

	return (
		<SaveDataMenu
			elementId={CONTAINER_ID}
			downloadFileName={`${property?.buildingAddress} Competitive Set`}
			widgetTitle="Title your Image"
			// @ts-expect-error legacy
			dataToCopy={excelRows}
			ignoreElements={(el) => {
				if (el.classList.contains('mapboxgl-control-container')) return true;
				return false;
			}}
		/>
	);
};

export const CONTAINER_ID = 'compset-container';
