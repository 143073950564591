import { CirclesLayer, PinsLayer } from '@compstak/maps';
import { point } from '@turf/helpers';
import {
	useAllMapSearchLeasesByPropertyId,
	useServerFiltersForSearchMap,
} from 'api';
import { AnalyticsPopup } from 'maps';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';
import { SpaceTypeAnalyticsTooltip } from './SpaceTypeAnalyticsTooltip';
import { useSpaceTypeAnalyticsData } from './useSpaceTypeAnalyticsData';

const ID = 'SpaceTypeAnalyticsLayer';

export const SpaceTypeAnalytics = () => {
	const [filters] = useFilters();
	const { data } = useSpaceTypeAnalyticsData();

	const points = useMemo(() => {
		if (!data) return [];
		return data.map((dataPoint) => {
			const { longitude, latitude } = dataPoint;
			return point([longitude, latitude], dataPoint);
		});
	}, [data]);

	const filterParam = useServerFiltersForSearchMap({ filters });

	const allLeasesByPropertyId = useAllMapSearchLeasesByPropertyId({
		filters: filterParam,
	});

	return (
		<>
			<CirclesLayer
				id={ID + 'spaceType'}
				data={points}
				maxZoom={14.5}
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				getFillColor={(f) => SPACE_TYPE_COLOR_MAP[f.properties.spaceType]}
				getRadius={24}
				getLineWidth={4}
				lineWidthMinPixels={0}
				lineWidthMaxPixels={1}
				lineWidthUnits="meters"
				getLineColor={[255, 255, 255]}
				radiusMinPixels={1.2}
				radiusUnits="meters"
				billboard
				getTooltipContent={(f) => {
					const lease = allLeasesByPropertyId[f.properties.propertyId]?.[0];

					if (!lease) return null;

					return <SpaceTypeAnalyticsTooltip feature={f} lease={lease} />;
				}}
				getPopupContent={(f, closePopup) => (
					<AnalyticsPopup
						propertyId={f.properties.propertyId}
						closePopup={closePopup}
					/>
				)}
				order={MAP_LAYERS_ORDER[ID]}
			/>
			<PinsLayer
				id={ID + 'pins'}
				data={points}
				minZoom={14.5}
				popupAnchor={'bottom'}
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				getIconColor={(f) => SPACE_TYPE_PIN_COLOR_MAP[f.properties.spaceType]}
				getTooltipContent={(f) => {
					const lease = allLeasesByPropertyId[f.properties.propertyId]?.[0];

					if (!lease) return null;

					return <SpaceTypeAnalyticsTooltip feature={f} lease={lease} />;
				}}
				getPopupContent={(f, closePopup) => (
					<AnalyticsPopup
						propertyId={f.properties.propertyId}
						closePopup={closePopup}
					/>
				)}
				order={MAP_LAYERS_ORDER[ID]}
			/>
		</>
	);
};

const SPACE_TYPE_PIN_COLOR_MAP = {
	Office: 'blue',
	Industrial: 'gray',
	Retail: 'green',
} as const;

export const SPACE_TYPE_COLOR_MAP = {
	Office: [67, 143, 247, 255],
	Industrial: [69, 90, 99, 255],
	Retail: [16, 216, 106, 255],
} as const;
