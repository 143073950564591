import { ticks as d3Ticks } from 'd3-array';

export const getTicks = (start: number, stop: number, maxCount: number) => {
	if (maxCount < 3) {
		throw new Error('getTicks - maxCount cannot be less than 3');
	}

	let currentCount = maxCount - 2;

	let ticks = d3Ticks(start, stop, currentCount);

	// the count we pass to d3Ticks is an approximation
	// so we need to try calling it until we get the desired max number of ticks
	while (ticks.length > maxCount - 2) {
		ticks = d3Ticks(start, stop, --currentCount);
	}

	const step = ticks.length > 1 ? ticks[1] - ticks[0] : ticks[0];

	const firstTick = ticks[0] - step;
	const lastTick = ticks[ticks.length - 1] + step;

	// don't go below zero with ticks if the min value doesn't
	if (start >= 0 && firstTick < 0) {
		return [...ticks, lastTick];
	}

	// we add the first and last tick ourselves because d3Ticks doesn't include
	// the start and stop values in the tick range
	return [firstTick, ...ticks, lastTick];
};
