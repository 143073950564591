import React from 'react';

import { Project } from 'Pages/Analytics/analytics';
import { useDispatch } from 'react-redux';
import { deleteProject } from '../../actions';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

type DeleteProjectModalProps = {
	project: Project;
};

export const DeleteProjectModal = ({ project }: DeleteProjectModalProps) => {
	const dispatch = useDispatch();
	const { closeModal } = useModal();

	const onDelete = () => {
		dispatch(deleteProject(project.id!));
		closeModal();
	};

	return (
		<>
			<ModalTitle>Delete Project</ModalTitle>
			<ModalParagraph>
				You’re trying to delete the project: <b>{project.name}</b>. This action
				cannot be undone, are you sure you want to proceed?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton variant="error" onClick={onDelete}>
					Delete
				</ModalButton>
			</ModalButtons>
		</>
	);
};
