import { SortDirection } from 'api';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useFilters } from 'reducers/filtersReducer';

/** Returns props to connect SearchTable to redux sort state */
export const useSearchTableSort = () => {
	const [filters, setFilters] = useFilters();

	return {
		sortName: filters.sortField,
		sortDirection: filters.sortDirection,
		onSort: (sortName: string, sortDirection: SortDirection) => {
			setFilters(
				mergeFilters(filters, {
					// @ts-expect-error filters
					sortField: sortName,
					sortDirection,
				})
			);
		},
	};
};
