import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { StringFilter } from 'Components/Filters/Base/StringFilter';
import React from 'react';

const attribute = 'insideviewTickers' as const;

export const TenantTickerFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => <StringFilter {...props} />}
		</FilterBase>
	);
};
