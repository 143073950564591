import React from 'react';
import styled from 'styled-components';
import { CompstakContactInfo } from '@compstak/common';
import { ExchangePanel } from '../UI';
import austinHall from './images/austin_hall.jpg';
import brianCaldwell from './images/brian_caldwell.jpg';
import devinShieldsMas from './images/devin_shields-mas.jpg';
import gabeGrandcolas from './images/gabe_grandcolas.jpg';
import jackWagner from './images/jack_wagner.jpg';
import justinTang from './images/justin_tang.jpg';
import koreyBaker from './images/korey_baker.jpg';
import lucyBurt from './images/lucy_burt.jpg';
import rickSuchan from './images/rick_suchan.jpg';
import sangwookHa from './images/sangwook_ha.jpg';
import shiraMizel from './images/shira_mizel.jpg';
import taylorLockwood from './images/taylor_lockwood.jpg';
import EmailText from '../../ui/svg_icons/email_text.svg';

type Props = { contactInfo: CompstakContactInfo | null };

const contactMap = {
	'Austin Hall': austinHall,
	'Brian Caldwell': brianCaldwell,
	'Devin Shields-Mas': devinShieldsMas,
	'Gabe Grandcolas': gabeGrandcolas,
	'Jack Wagner': jackWagner,
	'Justin Tang': justinTang,
	'Korey Baker': koreyBaker,
	'Lucy Burt': lucyBurt,
	'Rick Suchan': rickSuchan,
	'Sangwook Ha': sangwookHa,
	'Shira Mizel': shiraMizel,
	'Taylor Lockwood': taylorLockwood,
};

export const CompstakContact = ({ contactInfo }: Props) => {
	const contactPhoto = contactInfo
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		? contactMap[contactInfo.name] ?? austinHall
		: austinHall;
	return (
		<ExchangePanel id="compstak-contact" title="Compstak Contact">
			<ContactContent>
				<div className="info">
					<Row>
						<Avatar>
							<img src={contactPhoto} />
						</Avatar>
						<div className="name">{contactInfo?.name ?? 'Austin Hall'}</div>
					</Row>
					<Row>
						<StyledEmailIcon />
						<a
							data-qa-id="account-contact-email-link"
							href={`mailto:${
								contactInfo?.email ?? 'austinhall@compstak.com'
							}?subject=CompStak service request`}
						>
							{contactInfo?.email ?? 'austinhall@compstak.com'}
						</a>
					</Row>
					<div className="thank-you">
						Thank you for being a CompStak member! I am here to help you make
						the most of your CompStak experience. Please contact me if I can be
						of any assistance.
					</div>
				</div>
			</ContactContent>
		</ExchangePanel>
	);
};

const StyledEmailIcon = styled(EmailText)`
	fill: rgb(75, 164, 255);
	width: 20px;
	height: 28px;
	margin-right: 27px;
	margin-left: 11px;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 1rem;

	& .cs-override-icon {
		display: flex;
		width: 42px;
		height: 42px;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
	}
`;

const ContactContent = styled.div`
	height: 300px;
	display: flex;
	justify-content: space-between;
	background-color: #ffffff;
	border-radius: 2px;
	padding: 1rem;

	.info {
		flex: 1;
		.name {
			font-family: Gotham;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 30px;

			letter-spacing: 0.5px;
			text-transform: capitalize;

			color: #000000;
		}
		a {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 30px;

			letter-spacing: 0.5px;
			text-decoration-line: underline;
			text-transform: lowercase;

			color: #4290f7;
		}
		.thank-you {
			margin-top: 20px;
			margin-left: 58px;
		}
	}
`;

const Avatar = styled.div`
	align-items: center;
	background-color: #464b5e;
	border-radius: 50%;
	// border: 1px solid rgb(179, 179, 179);
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 11px;
	letter-spacing: 0.1rem;
	color: rgba(255, 255, 255, 0.6);
	display: flex;
	height: 42px;
	justify-content: center;
	margin-right: 1rem;
	width: 42px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
`;
