import { MultiselectListItem } from '@compstak/ui-kit';
import { SPACE_TYPE_ID_TO_NAME, SpaceTypeId } from 'api';

export const SPACE_TYPE_SELECT_ITEMS: MultiselectListItem[] = [
	{
		title: 'All',
		value: 'all',
	},
	...Object.typedKeys(SPACE_TYPE_ID_TO_NAME)
		.filter((id) => Number(id) !== -1)
		.map((id) => ({
			title: SPACE_TYPE_ID_TO_NAME[id],
			value: `${id}`,
		})),
];

const getItemById = (id: SpaceTypeId) => {
	return SPACE_TYPE_SELECT_ITEMS.find((item) => item.value === String(id));
};

export const getSpaceTypeItemsFromIds = (ids: SpaceTypeId[]) => {
	const items: MultiselectListItem[] = [];
	for (const id of ids) {
		let item: MultiselectListItem | undefined;
		if (id === 13 || id === -1) {
			item = getItemById(13);
		} else {
			item = getItemById(id);
		}
		if (item) {
			items.push(item);
		}
	}
	const itemsWithoutDuplicates = Array.from(new Set(items));
	return itemsWithoutDuplicates;
};

export const getSpaceTypeIdsFromItems = (items: MultiselectListItem[]) => {
	const ids: SpaceTypeId[] = [];
	for (const item of items) {
		if (item.value === '13') {
			ids.push(13, -1);
		} else {
			ids.push(Number(item.value) as SpaceTypeId);
		}
	}
	return ids;
};
