import { useSpaceTypeReferencesByName } from 'api';
import { useFilters } from 'reducers/filtersReducer';

export const useSpaceTypeLabel = () => {
	const [filters] = useFilters();

	const spaceTypeReferences = useSpaceTypeReferencesByName(['Industrial']);

	let spaceType = 'Office';

	if (!filters.spaceTypeId) {
		return spaceType;
	}

	if (
		spaceTypeReferences.length > 0 &&
		filters.spaceTypeId.includes(spaceTypeReferences[0].id)
	) {
		spaceType = 'Industrial';
	}

	return spaceType;
};
