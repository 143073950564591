import { Checkbox, CheckboxProps } from 'Components';
import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { useExchangeDashboardLargeLandlords } from 'ExchangeDashboard/hooks/largestLandlords/useExchangeDashboardLargeLandlords';
import { useExchangeDashboardLargeTenants } from 'ExchangeDashboard/hooks/largestTenants/useExchangeDashboardLargeTenants';
import { useExchangeDashboardLeaseExpirationByPropertyType } from 'ExchangeDashboard/hooks/leaseExpirationByPropertyType/useExchangeDashboardLeaseExpirationByPropertyType';
import { useExchangeDashboardLeaseExpirationBySubmarket } from 'ExchangeDashboard/hooks/leaseExpirationBySubmarket/useExchangeDashboardLeaseExpirationBySubmarket';
import { useExchangeDashboardLeasesActivity } from 'ExchangeDashboard/hooks/leasesActivity/useExchangeDashboardLeasesActivity';
import { useExchangeDashboardSalesActivity } from 'ExchangeDashboard/hooks/salesActivity/useExchangeDashboardSalesActivity';
import { useExchangeDashboardStartingRent } from 'ExchangeDashboard/hooks/startingRent/useExchangeDashboardStartingRent';
import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import { useId, useMemo } from 'react';
import styled from 'styled-components';
import button from 'ui/styles/button.less';
import { useDashboardExportContext } from './DashboardExportProvider';
import { filterLargeSalesAndRecentTransactionsIfNeeded } from './StepTwo';
import { ComponentInfo, componentTypes, componentsByType } from './UI';
import { useAdaptedTenantIndustryExportData } from './useAdaptedTenantIndustryExportData';

type Props = {
	onSubmit: () => void;
	closeDialog: () => void;
};

export const StepOne = (props: Props) => {
	const { selectedComponents, setSelectedComponents, salesAccess } =
		useDashboardExportContext();

	const averagesData = useExchangeDashboardAverages({
		enabled: selectedComponents.includes('averages'),
	});

	const startingRentData = useExchangeDashboardStartingRent({
		enabled: selectedComponents.includes('startingRent'),
	});

	const largeSales = useExchangeDashboardSalesActivity({
		activeTab: 'totalSalePrice',
		enabled: selectedComponents.includes('largeSales'),
	});

	const recentTransactions = useExchangeDashboardSalesActivity({
		activeTab: 'saleDate',
		enabled: selectedComponents.includes('recentTransactions'),
	});

	const recentlySignedLeases = useExchangeDashboardLeasesActivity({
		activeTab: 'executionDate',
		enabled: selectedComponents.includes('recentlySignedLeases'),
	});

	const largestRecentLeases = useExchangeDashboardLeasesActivity({
		activeTab: 'transactionSize',
		enabled: selectedComponents.includes('largestRecentLeases'),
	});

	const leaseExpirationPropertyTypeData =
		useExchangeDashboardLeaseExpirationByPropertyType({
			enabled: selectedComponents.includes('leaseExpirationPropertyType'),
		});

	const leaseExpirationSubmarketData =
		useExchangeDashboardLeaseExpirationBySubmarket({
			enabled: selectedComponents.includes('leaseExpirationSubmarket'),
		});

	const tenantIndustryDistributionData =
		useExchangeDashboardTenantIndustryDistribution({
			enabled: selectedComponents.includes('tenantIndustryDistribution'),
		});

	const largestLandlordsData = useExchangeDashboardLargeLandlords({
		enabled: selectedComponents.includes('largestLandlords'),
	});

	const largestTenantsData = useExchangeDashboardLargeTenants({
		enabled: selectedComponents.includes('largestTenants'),
	});

	const tenantIndustryState = useAdaptedTenantIndustryExportData({
		enabled: selectedComponents.includes('tenantIndustryMap'),
	});

	const stepStatus = [
		averagesData,
		leaseExpirationPropertyTypeData,
		leaseExpirationSubmarketData,
		largestTenantsData,
		largestLandlordsData,
		startingRentData,
		largeSales,
		recentTransactions,
		recentlySignedLeases,
		largestRecentLeases,
		tenantIndustryDistributionData,
		tenantIndustryState,
	]
		.filter((d) => selectedComponents.includes(d.type))
		.every((d) => !d.isFetching)
		? 'complete'
		: 'incomplete';

	const handleComponentSelection = (componentInfo: ComponentInfo) => {
		setSelectedComponents((prevComponents) =>
			prevComponents.includes(componentInfo.component)
				? prevComponents.filter((c) => c !== componentInfo.component)
				: [...prevComponents, componentInfo.component]
		);
	};

	const componentTypesFiltered = useMemo(
		() =>
			componentTypes.filter((sc) =>
				filterLargeSalesAndRecentTransactionsIfNeeded(sc, salesAccess)
			),
		[salesAccess]
	);

	const handleSelectAll = () => {
		const allSelected =
			selectedComponents.length === componentTypesFiltered.length;

		setSelectedComponents(() => (allSelected ? [] : componentTypesFiltered));
	};

	const selectAllId = useId();

	return (
		<Container>
			<Title>Export Manager</Title>
			<SelectAllContainer>
				<Checkbox
					id={selectAllId}
					checked={selectedComponents.length === componentTypesFiltered.length}
					onClick={handleSelectAll}
				/>
				<label htmlFor={selectAllId}>Select All</label>
			</SelectAllContainer>
			<SectionsContainer>
				<div>
					<SectionTitle>Lease Insights</SectionTitle>
					<ul>
						{componentsByType.leaseInsights.map((c) => (
							<ListItem
								{...c}
								key={c.component}
								checked={selectedComponents.includes(c.component)}
								onClick={() => handleComponentSelection(c)}
							/>
						))}
					</ul>
				</div>
				<div>
					<SectionTitle>Lease Activity</SectionTitle>
					<ul>
						{componentsByType.leaseActivity.map((c) => (
							<ListItem
								{...c}
								checked={selectedComponents.includes(c.component)}
								key={c.component}
								onClick={() => handleComponentSelection(c)}
							/>
						))}
					</ul>
				</div>
				{salesAccess && (
					<div>
						<SectionTitle>Sales Activity</SectionTitle>
						<UL>
							{componentsByType.salesActivity.map((c) => (
								<ListItem
									{...c}
									key={c.component}
									checked={selectedComponents.includes(c.component)}
									onClick={() => handleComponentSelection(c)}
								/>
							))}
						</UL>
					</div>
				)}
			</SectionsContainer>
			<ButtonContainer>
				<button
					className={button.white}
					onClick={props.closeDialog}
					data-qa-id="cancel"
				>
					Cancel
				</button>
				<button
					className={button.blue}
					disabled={!selectedComponents.length || stepStatus === 'incomplete'}
					onClick={props.onSubmit}
					data-qa-id="customize"
				>
					Customize
				</button>
			</ButtonContainer>
		</Container>
	);
};

const ListItem = ({
	displayName,
	checked,
	...props
}: ComponentInfo & CheckboxProps) => {
	const id = useId();

	return (
		<LI>
			<Checkbox id={id} checked={checked} {...props} />
			<CheckboxLabel htmlFor={id} checked={!!checked}>
				{displayName}
			</CheckboxLabel>
		</LI>
	);
};

const UL = styled.ul`
	padding: 0;
	margin: 0;
`;

const LI = styled.li`
	padding: 0.5rem 0;
	margin: 0;
	min-width: 250px;
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const CheckboxLabel = styled.label<{ checked: boolean }>`
	font-weight: ${(props) => (props.checked ? '600' : '300')};
`;

const Container = styled.div`
	background-color: white;
	width: 100%;
	padding: 1rem;
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

const SelectAllContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	margin-bottom: 0.5rem;
`;

const SectionsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	${(p) => p.theme.breakpoints.up('D_1280')} {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const SectionTitle = styled.div`
	font-size: 1rem;
	font-weight: 500;
	text-align: left;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 4rem;
`;
