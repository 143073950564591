import Details from 'Components/Lease/Details';
import React from 'react';
import { useGetPreferredPreviewComp } from 'services/userflowOnboardingServices';
import { Spinner, CompNotFound } from '@compstak/ui-kit';
import { routes, useNavigate } from 'router';

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export default function LeaseDetails(props) {
	return <Details {...props} />;
}

export function LeaseDetailsExample() {
	const { data: compId, isLoading, error } = useGetPreferredPreviewComp();
	const navigate = useNavigate();

	if (isLoading) {
		return <Spinner size="l" />;
	}
	if (error) {
		return (
			<CompNotFound
				onNewSearch={() => {
					navigate(routes.searchLeasesHome.toHref(), { replace: true });
				}}
			/>
		);
	}

	navigate(
		{
			pathname: routes.leaseById.toHref({ id: Number(compId) }),
		},
		{ replace: true }
	);
	return null;
}
