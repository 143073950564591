import { useMemo } from 'react';
import { FiltersObject } from '../../../models/filters/types';
import { filtersToQueryString } from '../../../models/filters/util';

interface Props {
	filterName: keyof FiltersObject;
	baseLinkUrl: string;
	filters: Partial<FiltersObject>;
	rows: { name: string }[];
}

export const useViewCompLink = ({
	filterName,
	filters,
	baseLinkUrl,
	rows,
}: Props) => {
	return useMemo(() => {
		const targetFilters: Partial<FiltersObject> = {
			[filterName]: rows.map((row) => row.name),
			...filters,
		};
		return `${baseLinkUrl}?${filtersToQueryString(targetFilters)}`;
	}, [baseLinkUrl, filterName, filters, rows]);
};
