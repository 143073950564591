import { findDOMNode } from 'react-dom';
import { DropTarget } from 'react-dnd';

import findLastIndex from 'lodash/findLastIndex';

import { PLACED, UNPLACED } from '../DragTypes';

// @ts-expect-error TS7006: Parameter 'xOrY' implicitly ha...
function generateHoverCallback(xOrY, rightOrBottom, leftOrTop) {
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	return function (props, monitor, component) {
		if (!monitor.canDrop()) {
			return;
		}

		const dragSpaceId = monitor.getItem().space.id;

		const dragIndex = props.stackingPlan.spaces.findIndex(
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			(s) => s.id === dragSpaceId
		);
		let hoverIndex;
		if (props.space) {
			hoverIndex = props.stackingPlan.spaces.findIndex(
				// @ts-expect-error TS7006: Parameter 's' implicitly has a...
				(s) => s.id === props.space.id
			);
		} else {
			hoverIndex = findLastIndex(
				props.stackingPlan.spaces,
				// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
				(s) => s.floor === props.spaces[0].floor
			);
		}

		const dragSpace = props.stackingPlan.spaces[dragIndex];
		const hoverSpace = props.stackingPlan.spaces[hoverIndex];

		if (dragSpace.placed !== hoverSpace.placed) {
			//props.repositionDraggedSpace(dragSpace, hoverSpace);
			//return;
		}

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// this means it's a combined space block
		if (!props.space) {
			props.repositionDraggedSpace(dragSpace, hoverSpace);
			return;
		}

		// Determine rectangle on screen
		// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddle =
			(hoverBoundingRect[rightOrBottom] - hoverBoundingRect[leftOrTop]) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClient = clientOffset[xOrY] - hoverBoundingRect[leftOrTop];

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClient < hoverMiddle) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClient > hoverMiddle) {
			return;
		}
		// Time to actually perform the action
		props.repositionDraggedSpace(dragSpace, hoverSpace);
	};
}

const verticalSortCardTarget = {
	hover: generateHoverCallback('y', 'bottom', 'top'),
};

const horizontalSortCardTarget = {
	hover: generateHoverCallback('x', 'right', 'left'),
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	canDrop: function (props, monitor) {
		const space = monitor.getItem().space;
		return space.placed || !space.floor;
	},
};

// @ts-expect-error TS7006: Parameter 'connect' implicitly...
const collect = (connect) => ({
	connectDropTarget: connect.dropTarget(),
});

export const VerticalSortDropTarget = DropTarget(
	[UNPLACED],
	verticalSortCardTarget,
	collect
);
export const HorizontalSortDropTarget = DropTarget(
	[PLACED, UNPLACED],
	horizontalSortCardTarget,
	collect
);
