import { memo, useMemo, useCallback, useState } from 'react';
import {
	Period,
	WidgetContainer,
	defaultTheme,
	Tooltip,
	RowFlexContainer,
	SaveDataMenu,
	HeightContainer,
	PeriodSelector,
	VerticalBarChart,
	getDownloadFileName,
	TickFormatter,
	numberUtils,
	Pending,
	ErrorUI,
	WidgetTitle,
} from '@compstak/ui-kit';
import {
	calcXTicks,
	getXTickDateFormatter,
	years,
} from '../../../../Pages/PropertyPageV2_1/utils';
import { adaptData } from './adaptData';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import HelpCircle from 'ui/svg_icons/help.svg';
import TickIcon from 'ui/svg_icons/tick_small.svg';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { MufaSubmarketChartProps } from 'Components/MufaSubmarket/MufaSubmarketSection';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';
import { FiltersNames, MufaChartsNames } from 'types/propertyTrendCharts';

type Props = MufaSubmarketChartProps &
	Pick<ReturnType<typeof useMufaTrendsData>['data'], 'expensesPerUnit'>;

export const SubmarketExpensesPerUnit = memo<Props>(
	({ address, expensesPerUnit, filters, handleFilterChange }) => {
		const period =
			filters[MufaChartsNames.SubmarketExpensesPerUnit][FiltersNames.PERIOD];

		const { data, isLoading, isSuccess, isError, error } = expensesPerUnit;

		const [checkedExpenses, setCheckedExpenses] = useState({
			[ExpensesPerUnit.TAXES_INSURANCE]: true,
			[ExpensesPerUnit.MARKETING]: true,
			[ExpensesPerUnit.ADMIN]: true,
			[ExpensesPerUnit.OPERATING_MAINTENANCE]: true,
			[ExpensesPerUnit.UTILITIES]: true,
			[ExpensesPerUnit.PAYROL_RELATED]: true,
		});

		const [barsToShow, setBarsToShow] = useState(barsData);

		const chartId = `${chartTitle}${address}`;

		const chartData = useMemo(() => adaptData(data), [data]);

		const xTicks = useMemo(
			() => calcXTicks(chartData, period),
			[chartData, period]
		);

		const dataPeriod = useMemo(() => {
			return `${dayjs(period.start).format('MM/YYYY')} - ${dayjs(
				period.end
			).format('MM/YYYY')}`;
		}, [period]);

		const dataCount = useMemo(() => {
			let count = 0;
			data?.histogram?.forEach((dataRecord) => {
				if (Object.keys(dataRecord).length <= 1) return;
				Object.keys(dataRecord).forEach((key) => {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					if (key !== 'date' && checkedExpenses[key]) count++;
				});
			});
			return count;
		}, [checkedExpenses, data]);

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) =>
				handleFilterChange(
					MufaChartsNames.SubmarketExpensesPerUnit,
					FiltersNames.PERIOD,
					selectedPeriod
				),
			[handleFilterChange]
		);

		// @ts-expect-error TS7006: Parameter 'key' implicitly has...
		const handleCheckedExpensesChange = (key) => {
			const updatedObject = {
				...checkedExpenses,
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				[key]: !checkedExpenses[key],
			};
			setCheckedExpenses(updatedObject);
		};

		useMemo(() => {
			const filteredBars = barsData.filter((bar) => {
				return checkedExpenses[`${bar.value}`];
			});
			setBarsToShow(filteredBars);
		}, [checkedExpenses]);

		return (
			<StyledWidgetContainer>
				<HeaderContainer>
					<RowFlexContainer>
						<WidgetTitle>{chartTitle}</WidgetTitle>
						<Tooltip
							tooltipComponent={tooltipContent}
							placement="bottom-end"
							tooltipRootStyle={tooltipRootStyle}
						>
							<StyledHelpCircleIcon />
						</Tooltip>
						<PeriodSelector
							period={period}
							onChange={handlePeriodChange}
							years={years}
							data-qa-id="expenses-per-unit-period-selector"
						/>
					</RowFlexContainer>
					{isSuccess && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, chartTitle)}
							elementId={chartId}
							dataToCopy={chartData}
						/>
					)}
				</HeaderContainer>
				{!dataCount && chartData.length ? (
					<NoDataMessage>
						There is no data for the selected expenses in this submarket
					</NoDataMessage>
				) : null}
				<HeightContainer>
					{isLoading && <Pending times={4} />}
					{isSuccess && (
						<VerticalBarChart
							bars={barsToShow}
							data={dataCount ? chartData : []}
							yAxisLabel={yAxisLabel}
							yTickFormatter={yTickFormatter}
							xTickFormatter={getXTickDateFormatter(period)}
							xInterval={0}
							xTicks={xTicks}
							chartId={chartId}
							isStacked
							yTooltipPosition={-15}
							tooltipFormatter={tooltipFormatter}
							showTotalValue={true}
							dataPeriod={dataPeriod}
							dataCount={dataCount}
							// @ts-expect-error legacy
							hasDataForSomePeriod={data?.suggestedSpan}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				{chartData.length ? (
					<Legend>
						{barsData.map(({ title, value, color }) => {
							return (
								<ExpenseCheckbox key={`${value}Checkbox`}>
									<Checkbox
										type="checkbox"
										checked={checkedExpenses[value]}
										onChange={() => handleCheckedExpensesChange(value)}
										color={color}
									/>
									{checkedExpenses[value] && <StyledTickIcon />}
									<CheckboxLabel>{title}</CheckboxLabel>
								</ExpenseCheckbox>
							);
						})}
					</Legend>
				) : null}
			</StyledWidgetContainer>
		);
	}
);

const chartTitle = 'Expenses Per Unit';
const yAxisLabel = 'Expenses Per Unit';
SubmarketExpensesPerUnit.displayName = 'SubmarketExpensesPerUnit';

export const StyledWidgetContainer = styled(WidgetContainer)`
	margin: 0;
`;
export const HeaderContainer = styled(RowFlexContainer)`
	margin-bottom: 16px;
`;

export const Title = styled(chartTitle)`
	margin-right: 4px;
`;

const yTickFormatter: TickFormatter = (value) => {
	return numberUtils.formatCurrency(value, 'currencyOneDecimal', 'financial');
};

const tooltipRootStyle = {
	marginRight: '16px',
};

const tooltipContent = (
	<>
		Expense metrics are sourced from individual leases executed via RealPage,
		Inc. software products in&nbsp;a&nbsp;specified time period.
		Lease-transaction data is&nbsp;aggregated by&nbsp;market and submarket.
	</>
);

export enum ExpensesPerUnit {
	ADMIN = 'adminExpensePerUnit',
	MARKETING = 'marketingExpensePerUnit',
	OPERATING_MAINTENANCE = 'operatingMaintenanceExpensePerUnit',
	PAYROL_RELATED = 'payrollRelatedExpensePerUnit',
	UTILITIES = 'utilitiesPerUnit',
	TAXES_INSURANCE = 'taxesInsuranceExpensePerUnit',
}

export const barsData = [
	{
		color: defaultTheme.colors.blue.blue700,
		value: ExpensesPerUnit.TAXES_INSURANCE,
		title: 'Taxes/Insurance',
		isIconHidden: false,
		type: 'monotone',
	},
	{
		color: defaultTheme.colors.green.green500,
		value: ExpensesPerUnit.MARKETING,
		title: 'Marketing',
		isIconHidden: false,
		type: 'monotone',
	},
	{
		color: defaultTheme.colors.purple.purple700,
		value: ExpensesPerUnit.ADMIN,
		title: 'Admin',
		isIconHidden: false,
		type: 'monotone',
	},
	{
		color: defaultTheme.colors.gray.n4,
		value: ExpensesPerUnit.OPERATING_MAINTENANCE,
		title: 'Op./Maintenance',
		isIconHidden: false,
		type: 'monotone',
	},
	{
		color: defaultTheme.colors.purple.purple5,
		value: ExpensesPerUnit.UTILITIES,
		title: 'Utilities',
		isIconHidden: false,
		type: 'monotone',
	},
	{
		color: defaultTheme.colors.blue.blue6,
		title: 'Payroll',
		value: ExpensesPerUnit.PAYROL_RELATED,
		isIconHidden: false,
		type: 'monotone',
	},
];

const StyledHelpCircleIcon = styled(HelpCircle)`
	width: 20px;
	height: 20px;
	fill: ${({ theme }) => theme.colors.gray.gray700};
	position: relative;
	cursor: pointer;
`;

const tooltipFormatter: TickFormatter = (value) =>
	value === ''
		? '$--'
		: numberUtils.formatCurrency(value, 'currencyTwoDecimals');

const Legend = styled.div`
	margin: 20px auto 10px auto;
	display: grid;
	grid-template-columns: auto auto auto;
	width: 300px;
`;

const ExpenseCheckbox = styled.div`
	position: relative;
`;

const Checkbox = styled.input<{ color: string }>`
	appearance: none;
	-webkit-appearance: none;
	width: 18px;
	height: 18px;
	background-color: ${(prop) => prop.color};
	border-radius: 3px;
	outline: none;
	cursor: pointer;
`;

const CheckboxLabel = styled.label`
	margin-left: 5px;
	margin-bottom: 5px;
	font-size: 11px;
	line-height: 18px;
`;

const StyledTickIcon = styled(TickIcon)`
	width: 12px;
	height: 18px;
	fill: ${({ theme }) => theme.colors.white.white};
	position: absolute;
	top: 3px;
	left: 3px;
	pointer-events: none;
`;

const NoDataMessage = styled.div`
	width: 420px;
	padding: 20px;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 3px;
	background-color: ${({ theme }) => theme.colors.gray.gray200};
	z-index: 2;
	text-align: center;
	font-size: 17px;
	font-weight: 400;
	line-height: 30px;
	color: ${({ theme }) => theme.colors.gray.gray600};
`;
