import { MapStyle } from '@compstak/maps';
import { useState } from 'react';
import { createSimpleProvider } from '../../../utils';

export const {
	Provider: SearchMapProvider,
	useProviderContext: useSearchMapState,
} = createSimpleProvider({
	useValue: () => {
		return useState(initialState);
	},
});

const initialState: MapState = {
	isAnalyticsMenuOpen: false,
	isAnalyticsDetailMenuOpen: false,
	mapStyle: MapStyle.DEFAULT,
	isExporting: false,
};

export type MapState = {
	mapStyle: MapStyle;
	isAnalyticsMenuOpen: boolean;
	isAnalyticsDetailMenuOpen: boolean;
	isExporting: boolean;
};
