import { AppState } from 'reducers/root';
import { ExchangePanel, StatBox, StatsContainer } from './UI';
// @ts-expect-error TS2459: Module '"Pages/Login/reducers"...
import { UserState } from 'Pages/Login/reducers';
import { useSelector } from 'react-redux';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import factory from 'services/factory';
import React from 'react';
import styled from 'styled-components';
import { TeamInfoRequestModal } from './Membership/Promotions/TeamInfoRequestModal';
import { Waypoint } from 'react-waypoint';
import { useModal } from 'providers/ModalProvider';
import { Button } from '@compstak/ui-kit';

type Props = {};

type Stats = {
	compsSubmitted: number;
	compsViewed: number;
	compsUnlocked: number;
	creditEarned: number;
	creditAvailable: number;
};

export const TeamStatsPanel: React.FC<Props> = (props) => {
	const selector = useSelector<
		AppState,
		{
			user: UserState;
		}
	>((state) => ({
		user: state.user,
	}));
	const { openModal } = useModal();

	const [statsType, setStatsType] = React.useState<'user' | 'team'>(
		selector.user?.team === null ? 'user' : 'team'
	);
	const [userStats, setUserStats] = React.useState<Stats | null>(null);
	const [teamStats, setTeamStats] = React.useState<Stats | null>(null);
	const [view, setView] = React.useState<'visible' | 'hidden'>('hidden');

	React.useEffect(() => {
		if (view === 'visible') {
			const teamId = selector.user?.team?.id;
			if (teamId) {
				factory.get<Stats>(`/api/dashboard/teamStats`).then((response) => {
					setTeamStats(response);
				});
			}
		}
	}, [view, selector.user?.team?.id]);

	React.useEffect(() => {
		if (view === 'visible') {
			factory.get<Stats>(`/api/dashboard/userStats`).then((response) => {
				setUserStats(response);
			});
		}
	}, [view, selector.user?.id]);

	const stats = statsType === 'team' ? teamStats : userStats;

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				id="statistics"
				title="Activity and Credits"
				cta={
					<Tabs>
						<Tab
							active={statsType === 'user'}
							data-qa-id="user-stats-tab"
							onClick={() => setStatsType('user')}
						>
							{selector?.user?.firstName ?? 'You'}
						</Tab>
						<Tab
							active={statsType === 'team'}
							data-qa-id="team-stats-tab"
							onClick={() => setStatsType('team')}
						>
							Team
						</Tab>
					</Tabs>
				}
			>
				<TeamStatsContainer>
					{statsType === 'team' && selector.user?.team == null && (
						<div className="empty-message">
							Increase your access to comps and credits by creating a team.
							<BlueButton
								onClick={() =>
									openModal({ modalContent: <TeamInfoRequestModal /> })
								}
							>
								Launch Your Team
							</BlueButton>
						</div>
					)}
					{((statsType === 'team' && selector.user?.team != null) ||
						statsType === 'user') && (
						<>
							<StatBox
								property="Comps Submitted"
								value={abbreviateNumber(stats?.compsSubmitted) ?? 'N/A'}
							/>
							<StatBox
								property="Comps Viewed"
								value={abbreviateNumber(stats?.compsViewed) ?? 'N/A'}
							/>
							<StatBox
								property="Comps Unlocked"
								value={abbreviateNumber(stats?.compsUnlocked) ?? 'N/A'}
							/>
							<StatBox
								property="Credits Earned"
								value={abbreviateNumber(stats?.creditEarned) ?? 'N/A'}
							/>
							<StatBox
								property="Credits Available"
								value={abbreviateNumber(stats?.creditAvailable) ?? 'N/A'}
							/>
						</>
					)}
				</TeamStatsContainer>
				{/* @ts-expect-error TS2339: Property 'children' does not e... */}
				{props.children}
			</ExchangePanel>
		</Waypoint>
	);
};

const Tab = styled.div<{ active: boolean }>`
	background-color: ${(props) => (props.active ? '#228fff' : 'transparent')};
	border-radius: 30px;
	border: 1px solid #4a78f6;
	padding: 6px 12px;
	color: ${(props) => (props.active ? '#fff' : '#303441')};
	font-family:
		Gotham,
		Roboto Mono;
	font-size: 0.625rem;
	font-weight: 400;
	justify-content: center;
	text-align: center;
	width: auto;

	&:hover {
		background: #eaf2fd;
		color: #2d77e6;
	}

	&:active {
		background-color: #228fff;
		color: #fff;
	}
`;

const Tabs = styled.div`
	background: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	min-width: 120px;
	max-width: 280px;
	margin: 0 0 1rem;
	overflow: hidden;
`;

const TeamStatsContainer = styled(StatsContainer)`
	.empty-message {
		width: 530px;
		height: 145px;
		background: #f9f9f9;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	}
`;

const BlueButton = styled(Button)`
	width: min-content;
	background: #228fff;
	border-radius: 3px;
	height: 35px;
	font-size: 11px;
	letter-spacing: 0.15em;
	margin: 20px auto;
`;
