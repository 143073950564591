import { SurveyQuestion } from 'api/surveyForCredits/surveyQuestionQueries';
import { useRef } from 'react';

export function useGetQuestionParams(question: SurveyQuestion) {
	const questionedAtRef = useRef(new Date());

	// This data is needed by the answer question api endpoint,
	// and it's only related to the survey question itself.
	return {
		questionId: question.id,
		questionedAt: questionedAtRef.current,
		questionType: question.type,
	};
}
