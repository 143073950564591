import React from 'react';

import Typeahead from 'Components/Typeahead';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { SuggestionField } from 'api/suggestions/suggestions';
import { CompType } from 'types';
import { FiltersObject, FiltersTypesKeys } from 'models/filters/types';
import { FilterErrors } from './Filter/Container';

type StringTypeaheadProps = {
	attribute: FiltersTypesKeys;
	compType: CompType;
	filter?: string | null;
	filters: FiltersObject;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	touch: NoArgCallback;
	setError: (errors: FilterErrors) => void;
	typeaheadAttribute?: SuggestionField;
	placeholder?: string;
};

export const StringTypeahead = ({
	attribute,
	compType,
	filter,
	filters,
	onFilterChange,
	placeholder,
	touch,
	typeaheadAttribute,
	setError,
}: StringTypeaheadProps) => {
	const typeaheadAttr = (typeaheadAttribute ?? attribute) as SuggestionField;

	const setFilter = (value: string) => {
		touch();
		try {
			onFilterChange({
				[attribute]: value,
			});
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message);
			}
		}
	};

	return (
		<div>
			<Typeahead
				compType={compType}
				onSelection={setFilter}
				keepValueOfSelection={true}
				defaultValue={filter}
				typeaheadAttribute={typeaheadAttr}
				markets={getFiltersMarkets(filters)}
				placeholder={placeholder}
				filters={filters}
			/>
		</div>
	);
};
