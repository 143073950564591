import { Button, ButtonProps, Download, TooltipV2 } from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { forwardRef, useState } from 'react';
import { CompExportPopup } from './CompExportPopup';
import pluralizeCompType from 'util/pluralizeCompType';
import { CompType, PropertyComp } from 'types';
import { formatInteger } from 'format';
import { useMediaQuery } from 'react-responsive';
import { ACTION_COLUMN_BREAKPOINT } from 'Components/SearchTable/constants';
import { PropertyLease, PropertySale } from 'api';

type ButtonType = 'row' | 'table' | 'standalone' | 'wide-row';

type Props<T extends CompType> = {
	compIds: number[];
	getComps?: () => (T extends 'lease'
		? PropertyLease
		: T extends 'sale'
			? PropertySale
			: PropertyComp)[];
	compType: T;
	buttonType: ButtonType;
} & ButtonProps;

export const ExportCompsButton = <T extends CompType>({
	compType,
	compIds,
	getComps,
	buttonType: _buttonType,
	...props
}: Props<T>) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const isCompact = useMediaQuery({ maxWidth: ACTION_COLUMN_BREAKPOINT });

	const buttonType =
		isCompact && _buttonType === 'wide-row' ? 'row' : _buttonType;

	const tooltipText =
		buttonType === 'table'
			? compIds.length === 0
				? `Select ${pluralizeCompType(compType, false)} to export`
				: `Export multiple ${pluralizeCompType(compType, false)}`
			: 'Export comp';

	const buttonText =
		buttonType === 'table'
			? `EXPORT (${formatInteger(compIds.length)})`
			: buttonType === 'standalone' || buttonType === 'wide-row'
				? 'EXPORT'
				: undefined;

	return (
		<Popover.Root open={isPopupOpen} onOpenChange={setIsPopupOpen}>
			<TooltipV2 content={tooltipText}>
				<Popover.Trigger asChild>
					<ExportButton
						disabled={buttonType === 'table' ? compIds.length === 0 : undefined}
						onClick={() => setIsPopupOpen(true)}
						size={buttonType === 'row' || buttonType === 'wide-row' ? 'm' : 'l'}
						style={{ ...(_buttonType === 'wide-row' && { width: '100%' }) }}
						{...props}
					>
						{buttonText}
					</ExportButton>
				</Popover.Trigger>
			</TooltipV2>

			{isPopupOpen && (
				<CompExportPopup
					compIds={compIds}
					getComps={getComps}
					type={compType}
					closePopup={() => setIsPopupOpen(false)}
					side={
						buttonType === 'row' || buttonType === 'wide-row'
							? 'right'
							: 'bottom'
					}
				/>
			)}
		</Popover.Root>
	);
};

const ExportButton = forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, ...props }, ref) => {
		return (
			<Button variant="primary" data-qa-id="export-btn" {...props} ref={ref}>
				<Download isInheritedColor /> {children}
			</Button>
		);
	}
);

ExportButton.displayName = 'ExportButton';
