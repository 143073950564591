import { memo, CSSProperties } from 'react';
import { VariableSizeGrid } from 'react-window';
import { colors, typography } from '@compstak/ui-kit';
import { nullValueText } from '@compstak/common';
import { PortfolioItem } from 'types/comp';
import formatNumber from 'util/comp-format/src/util/number';

type Props = {
	data: PortfolioItem;
};

export const KeyDetails = memo<Props>(({ data }) => {
	return (
		<VariableSizeGrid
			columnCount={3}
			rowCount={4}
			height={(EVEN_ROW_HEIGHT + ODD_ROW_HEIGHT) * 2}
			width={FIRST_COL_WIDTH + SECOND_COL_WIDTH + THIRD_COL_WIDTH}
			columnWidth={(idx) => {
				if (idx === 0) return FIRST_COL_WIDTH;
				if (idx === 1) return SECOND_COL_WIDTH;
				return THIRD_COL_WIDTH;
			}}
			rowHeight={(idx) => {
				if (idx % 2 === 0) return EVEN_ROW_HEIGHT;
				return ODD_ROW_HEIGHT;
			}}
			itemData={data}
		>
			{ItemRenderer}
		</VariableSizeGrid>
	);
});

KeyDetails.displayName = 'KeyDetails';

const FIRST_COL_WIDTH = 78;
const SECOND_COL_WIDTH = 162;
const THIRD_COL_WIDTH = 146;
const EVEN_ROW_HEIGHT = 22;
const ODD_ROW_HEIGHT = 38;

type ItemRendererProps = {
	data: PortfolioItem;
	columnIndex: number;
	rowIndex: number;
	style: CSSProperties;
};

const ItemRenderer = memo<ItemRendererProps>(
	({ columnIndex, rowIndex, data, style }) => {
		const isEvenRow = rowIndex % 2 === 0;
		const hasBuildingYearRenovatedData = data.buildingYearRenovated;

		let cellContent;
		if (isEvenRow) {
			cellContent =
				rowIndex === 2 && columnIndex === 2 && !hasBuildingYearRenovatedData
					? ''
					: propertyValueToLabel[
							rowColNumberToPropertyValue[
								String(rowIndex) + String(columnIndex)
							]
					  ];
		} else if (
			rowIndex === 3 &&
			columnIndex === 2 &&
			!hasBuildingYearRenovatedData
		)
			cellContent = '';
		else {
			const key =
				rowColNumberToPropertyValue[String(rowIndex - 1) + String(columnIndex)];

			if (key === 'buildingSize') {
				cellContent = data[key] ? formatNumber(data[key]) : nullValueText;
			} else {
				cellContent = data[key] ?? nullValueText;
			}
		}

		const customStyle = {
			...style,
			fontFamily: typography.fontFamily.gotham,
			fontSize: isEvenRow ? 14 : 18,
			fontWeight: isEvenRow ? 300 : 400,
			lineHeight: isEvenRow ? '17.5px' : '22.5px',
			color: isEvenRow ? colors.neutral.n100 : colors.gray.gray700,
		};
		// @ts-expect-error TS2322: Type 'string | number | boolea...
		return <div style={customStyle}>{cellContent}</div>;
	}
);

ItemRenderer.displayName = 'ItemRenderer';

const propertyValueToLabel: {
	[Key in keyof Partial<PortfolioItem>]: string;
} = {
	buildingClass: 'Class',
	buildingSize: 'Building Size (SF)',
	buildingYear: 'Year Built',
	buildingFloorsCount: 'Floors',
	buildingPropertyType: 'Property Type',
	buildingYearRenovated: 'Year Renovated',
};

const rowColNumberToPropertyValue: {
	[x: string]: keyof typeof propertyValueToLabel;
} = {
	'00': 'buildingClass',
	'01': 'buildingSize',
	'02': 'buildingYear',
	'20': 'buildingFloorsCount',
	'21': 'buildingPropertyType',
	'22': 'buildingYearRenovated',
};
