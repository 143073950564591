import {
	MARKER_COLOR_MAP_RGBA,
	MVTLayer,
	PINPOINT_CIRCLE_ZOOM_BREAKPOINT,
	useMapRef,
	useViewState,
	WHITE_RGBA,
} from '@compstak/maps';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { MAP_CONTROLS } from 'actions/search';
import { useIsMultifamily } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { focusProperty } from 'Pages/Search/Map/actions';
import { MultiSelectState } from 'Pages/Search/MultiSelectProvider';
import { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActiveMapControl } from 'types';
import { CompType } from 'types/comp';
import pluralizeCompType from 'util/pluralizeCompType';
import { usePolygonFromFilter } from '../../usePolygonFromFilter';
import { useRadiusPolygonFromFilter } from '../../useRadiusPolygonFromFilter';
import { PROXIMITY_THRESHOLD_DISTANCE_IN_METERS } from './constants';
import { AggTileFeature, HitTileFeature, TileFeature } from './types';
import {
	buildRTree,
	hitTileFeatureToPinFeature,
	isAggOrHitTileFeature,
	isHitTileFeature,
	isPointInsideRTree,
} from './util';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { CountyPolygon } from 'api/ppm-property/countyPolygons/useCountyPolygonByFIPSQuery';
import { APNPolygon } from 'api/ppm-property/apnPolygons/useAPNPolygonQuery';
import { SearchPageView } from 'router';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';
import { OpportunityZoneFeature } from 'api/opportunityZones/useOpportunityZonesByMarketQuery';
import { getAccessToken, refreshAccessToken } from 'auth/auth';

type PinsMVTLayerProps = {
	compType: CompType;
	filters: FiltersObject;
	multiSelect: MultiSelectState;
	activeMapControl: ActiveMapControl;
	selectedSubmarketFeatures: SubmarketPolygon[];
	selectedOpportunityZoneFeatures: OpportunityZoneFeature[];
	countyFeatures: CountyPolygon[];
	apnFeatures: APNPolygon[];
	view?: SearchPageView;
};

const ID = 'PinsMVTLayer';

export const PinsMVTLayer = ({
	compType,
	filters: originalFilters,
	multiSelect,
	activeMapControl,
	selectedSubmarketFeatures,
	selectedOpportunityZoneFeatures,
	countyFeatures,
	apnFeatures,
	view,
}: PinsMVTLayerProps) => {
	const { map } = useMapRef();
	const dispatch = useDispatch();
	const polygon = usePolygonFromFilter({ activeMapControl }).features[0];
	const radius = useRadiusPolygonFromFilter({
		isRadiusFilterActive: activeMapControl === MAP_CONTROLS.RADIUS,
	})?.features[0];
	const [viewState] = useViewState();

	const isRetrying = useRef(false);
	const [accessToken, setAccessToken] = useState(getAccessToken());

	const submarketsRTree = useMemo(() => {
		return buildRTree(selectedSubmarketFeatures);
	}, [selectedSubmarketFeatures]);

	const opportunityZonesRTree = useMemo(() => {
		return buildRTree(selectedOpportunityZoneFeatures);
	}, [selectedOpportunityZoneFeatures]);

	const countyFeaturesRTree = useMemo(() => {
		return buildRTree(countyFeatures);
	}, [countyFeatures]);

	const apnFeaturesRTree = useMemo(() => {
		return buildRTree(apnFeatures);
	}, [apnFeatures]);

	const {
		isMultiSelectOn,
		isPinSelected,
		isPinInProximity,
		selectedPropertyIds,
	} = multiSelect;

	const isMufa =
		useIsMultifamily({ markets: getFiltersMarkets(originalFilters) }) &&
		compType === 'property';

	const featureIsSelected = (tileFeature: AggTileFeature | HitTileFeature) => {
		const pointCoordinatesInWGS84 = tileFeature.properties.coordinatesInWGS84;

		if (countyFeatures.length > 0) {
			const isInsideCounties = isPointInsideRTree({
				point: pointCoordinatesInWGS84,
				rTree: countyFeaturesRTree,
				polygons: countyFeatures,
			});
			if (!isInsideCounties) {
				// Allow other geo filters to filter out results
				return false;
			}
		}

		if (apnFeatures.length > 0) {
			const isInsideAPN = isPointInsideRTree({
				point: pointCoordinatesInWGS84,
				rTree: apnFeaturesRTree,
				polygons: apnFeatures,
			});
			if (!isInsideAPN) {
				// Allow other geo filters to filter out results
				return false;
			}
		}

		if (activeMapControl === MAP_CONTROLS.POLYGON && polygon) {
			return booleanPointInPolygon(pointCoordinatesInWGS84, polygon);
		}
		if (activeMapControl === MAP_CONTROLS.RADIUS && radius) {
			return booleanPointInPolygon(pointCoordinatesInWGS84, radius);
		}
		if (
			activeMapControl === MAP_CONTROLS.SUBMARKETS &&
			selectedSubmarketFeatures.length
		) {
			return isPointInsideRTree({
				point: pointCoordinatesInWGS84,
				rTree: submarketsRTree,
				polygons: selectedSubmarketFeatures,
			});
		}
		if (
			activeMapControl === MAP_CONTROLS.OPPORTUNITY_ZONES &&
			selectedOpportunityZoneFeatures.length
		) {
			return isPointInsideRTree({
				point: pointCoordinatesInWGS84,
				rTree: opportunityZonesRTree,
				polygons: selectedOpportunityZoneFeatures,
			});
		}
		if (isMultiSelectOn) {
			if (isHitTileFeature(tileFeature)) {
				return isPinSelected(tileFeature.properties.propertyId);
			}
			return isPinInProximity(
				pointCoordinatesInWGS84,
				PROXIMITY_THRESHOLD_DISTANCE_IN_METERS
			);
		}

		return true;
	};

	const filters = useMemo(() => {
		const serverFilters = mergeFilters(originalFilters, {
			polygon: null,
			radius: null,
			submarkets: null,
			opportunityZoneId: null,
			fips: null,
			apn: null,
		});
		return serverFilters;
	}, [originalFilters]);

	const updateTriggersDependencies = [
		polygon?.geometry.coordinates,
		radius?.geometry.coordinates,
		selectedSubmarketFeatures.length,
		selectedOpportunityZoneFeatures.length,
		countyFeatures.length,
		apnFeatures.length,
		isMultiSelectOn,
		selectedPropertyIds,
	];

	const tileDataUpdateTriggers = [
		view !== 'list' ? filters : false,
		accessToken,
	];

	const pointType =
		(viewState.zoom ?? 0) < PINPOINT_CIRCLE_ZOOM_BREAKPOINT ? 'circle' : 'icon';

	return (
		<MVTLayer<AggTileFeature | HitTileFeature>
			id={ID}
			binary={false}
			data={`/api/${isMufa ? 'mufa/' : ''}${pluralizeCompType(
				compType,
				false
			)}/mvt/{z}/{x}/{y}?${
				(viewState.zoom ?? 0) < PINPOINT_CIRCLE_ZOOM_BREAKPOINT
					? 'size=0'
					: 'grid_precision=0'
			}`}
			loadOptions={{
				fetch: {
					method: 'POST',
					body: JSON.stringify({
						filter: filtersToServerJSON(filters, compType === 'property'),
					}),
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			}}
			pointType={pointType}
			getTooltipContent={(f) => {
				return isHitTileFeature(f)
					? f.properties.buildingAddressAndCity
					: undefined;
			}}
			getLineColor={WHITE_RGBA}
			// @ts-expect-error MVTLayerProps is not generic thus we need to force the exact type to substitute basic Feature type
			getFillColor={(f: AggTileFeature | HitTileFeature) => {
				return featureIsSelected(f)
					? MARKER_COLOR_MAP_RGBA.blue
					: MARKER_COLOR_MAP_RGBA.gray;
			}}
			getIcon={(f) => {
				return featureIsSelected(f) ? 'blue' : 'gray';
			}}
			updateTriggers={{
				getFillColor: updateTriggersDependencies,
				getIcon: updateTriggersDependencies,
				getTileData: tileDataUpdateTriggers,
			}}
			onTileLoad={(tile) => {
				const deleteTileFeature = (tileFeature: TileFeature) => {
					const tileData = tile.data as TileFeature[];
					tileData.splice(tileData.findIndex((f) => f === tileFeature));
				};

				//@ts-expect-error dataInWGS84 is not declared as a property of a tile
				const tileDataInWGS84 = tile.dataInWGS84 as TileFeature[];
				for (const tileFeature of tileDataInWGS84) {
					if (isAggOrHitTileFeature(tileFeature)) {
						// assign coordinates in World Geodetic Coordinate System for each tile features
						tileFeature.properties.coordinatesInWGS84 =
							tileFeature.geometry.coordinates;
						if (compType === 'property' && isHitTileFeature(tileFeature)) {
							tileFeature.properties.propertyId = tileFeature.properties.id;
						}
					} else {
						// deleting any feature which is not `aggs` or `hits`
						deleteTileFeature(tileFeature);
					}
				}
			}}
			onClick={(info) => {
				if (!map) return;
				const feature = info.object;
				if (feature && isHitTileFeature(feature)) {
					if (multiSelect.isMultiSelectOn) {
						multiSelect.togglePin(hitTileFeatureToPinFeature(feature));
						return;
					}

					dispatch(
						focusProperty(feature.properties.propertyId, {
							coordinates: feature.geometry.coordinates,
							zoom: viewState.zoom,
						})
					);

					map.flyTo({
						center: [
							feature.geometry.coordinates[0],
							feature.geometry.coordinates[1],
						],
						easing: (t) => t,
					});
				}
			}}
			onTileError={async (err) => {
				if (isRetrying.current) return;
				// we assume that an error was caused by stale access token because
				// @loaders.gl/core/src/lib/utils/response-utils.ts#getResponseError absorbs the whole response and returns a simple string
				console.error(
					'Failed to load a tile. We assume it happened due to a stale token, refreshing it.',
					err
				);
				isRetrying.current = true;
				const accessToken = await refreshAccessToken();
				accessToken && setAccessToken(accessToken);
				isRetrying.current = false;
			}}
			pickable={pointType === 'icon'}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
