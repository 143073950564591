import { PINPOINT_CIRCLE_ZOOM_BREAKPOINT, useViewState } from '@compstak/maps';
import { useMultiSelect } from 'Pages/Search/MultiSelectProvider';
import styled from 'styled-components';
import { Button } from './UI';

export const MultiSelectButton = () => {
	const { isAnyPinSelected, isMultiSelectOn, setIsMultiSelectOn } =
		useMultiSelect();
	const [viewState] = useViewState();
	const isPinsSelectable = viewState.zoom >= PINPOINT_CIRCLE_ZOOM_BREAKPOINT;
	const isDisabled = !isAnyPinSelected && !isMultiSelectOn && !isPinsSelectable;

	return (
		<MultiSelectButtonUI
			data-tooltip={
				!isPinsSelectable
					? 'Zoom into the map to select multiple properties'
					: undefined
			}
			variant={isMultiSelectOn ? 'primary' : 'secondary'}
			onClick={() => {
				!isDisabled && setIsMultiSelectOn(!isMultiSelectOn);
			}}
			disabled={isDisabled}
		>
			Multi-select
			{isMultiSelectOn && (
				<span>
					: <b>ON</b>
				</span>
			)}
		</MultiSelectButtonUI>
	);
};

export const MultiSelectButtonUI = styled(Button)`
	color: ${({ theme }) => theme.colors.white.white};
	min-width: 80px;

	b {
		font-weight: 700;
	}
`;
