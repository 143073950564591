import React from 'react';

import { DropTarget } from 'react-dnd';
// @ts-expect-error TS7016: Could not find a declaration f...
import withScrolling from 'frontend-collective-react-dnd-scrollzone';
// @ts-expect-error TS7016: Could not find a declaration f...
import { ScrollContainer } from 'scrollmonitor-react';

import { PLACED, UNPLACED } from './DragTypes';

import Floor from './Floor';
import SpacesContainer from './Spaces/Container';

import { getActualLabel } from './util';

import styles from './stacking.less';

import number from 'ui/util/number';

const FloorRowWrapper = withScrolling(
	ScrollContainer(
		class ScrollWithScroll extends React.Component {
			watchers = [];

			// @ts-expect-error TS7006: Parameter 'el' implicitly has ...
			setWatcher = (el, watcher, props) => {
				// @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
				this.watchers[props.index] = watcher;
			};

			// @ts-expect-error TS7006: Parameter 'newProps' implicitl...
			componentWillReceiveProps(newProps) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'dragFloor' does not exist on type 'Reado... Remove this comment to see the full error message
				if (newProps.dragFloor && newProps.dragFloor !== this.props.dragFloor) {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'floors' does not exist on type 'Readonly... Remove this comment to see the full error message
					const dragIndex = this.props.floors.findIndex(
						// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
						(f) => getActualLabel(f.label) === newProps.dragFloor
					);

					// @ts-expect-error ts-migrate(2339) FIXME: Property 'isFullyInViewport' does not exist on typ... Remove this comment to see the full error message
					if (!this.watchers[dragIndex].isFullyInViewport) {
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'watchItem' does not exist on type 'never... Remove this comment to see the full error message
						this.watchers[dragIndex].watchItem.scrollIntoView();
					}
				}
			}

			// @ts-expect-error TS7006: Parameter 'watcher' implicitly...
			handleStateChange = (watcher, props) => {
				if (this.watchers[props.index] !== watcher) {
					// @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
					this.watchers[props.index] = watcher;
				}
			};

			render() {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				const placedSpaces = this.props.stackingPlan.spaces.filter(
					// @ts-expect-error TS7006: Parameter 's' implicitly has a...
					(s) => s.placed && s.size
				);

				// For calculating overflow.
				// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
				const floorSpaceOccupied = placedSpaces.reduce((acc, s) => {
					acc[getActualLabel(s.floor)] =
						(acc[getActualLabel(s.floor)] || 0) + s.size;
					return acc;
				}, {});

				return (
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
					<div className={this.props.className} style={this.props.style}>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'floors' does not exist on type 'Readonly... Remove this comment to see the full error message */}
						{this.props.floors.map((floor, i) => (
							<Floor
								key={i}
								index={i}
								floor={floor}
								spaceOccupied={
									floorSpaceOccupied[getActualLabel(floor.label)] || 0
								}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
								stackingPlan={this.props.stackingPlan}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'buildingWidth' does not exist on type 'R... Remove this comment to see the full error message
								buildingWidth={this.props.buildingWidth}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
								menuActions={this.props.menuActions}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
								stackingPlanActions={this.props.stackingPlanActions}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'repositionDraggedSpace' does not exist o... Remove this comment to see the full error message
								repositionDraggedSpace={this.props.repositionDraggedSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'changeDraggedSpaceFloor' does not exist ... Remove this comment to see the full error message
								changeDraggedSpaceFloor={this.props.changeDraggedSpaceFloor}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollContainer' does not exist on type ... Remove this comment to see the full error message
								scrollContainer={this.props.scrollContainer}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'originalStackingPlan' does not exist on ... Remove this comment to see the full error message
								originalStackingPlan={this.props.originalStackingPlan}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaceBeingDragged' does not exist on typ... Remove this comment to see the full error message
								spaceBeingDragged={this.props.spaceBeingDragged}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'endDraggingSpace' does not exist on type... Remove this comment to see the full error message
								endDraggingSpace={this.props.endDraggingSpace}
								innerRef={this.setWatcher}
								stateChange={this.handleStateChange}
							/>
						))}

						<SpacesContainer
							// @ts-expect-error ts-migrate(2322) FIXME: Property 'placedSpaces' does not exist on type 'In... Remove this comment to see the full error message
							placedSpaces={placedSpaces}
							floorSpaceOccupied={floorSpaceOccupied}
							{...this.props}
						/>
					</div>
				);
			}
		}
	)
);

const types = [PLACED, UNPLACED];
const spec = {
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	hover: function (props, monitor, component) {
		let dragFloor = null;
		const space = monitor.getItem().space;

		if (!space.placed && space.floor) {
			dragFloor = getActualLabel(space.floor);
		} else {
			return;
		}

		if (component.state.dragSpace === space) {
			return;
		}

		props.changeDraggedSpaceFloor(space, space.floor);

		component.setState({
			dragFloor,
			dragSpace: space,
		});
	},
};

// @ts-expect-error TS7006: Parameter 'connect' implicitly...
function collect(connect, monitor) {
	return {
		isOver: monitor.isOver(),
		didDrop: monitor.didDrop(),
		connectDropTarget: connect.dropTarget(),
	};
}

export default DropTarget(
	types,
	spec,
	collect
)(
	class ExpandedFloors extends React.PureComponent {
		state = {
			dragFloor: null,
			dragSpace: null,
		};

		// @ts-expect-error TS7006: Parameter 'newProps' implicitl...
		componentWillReceiveProps(newProps) {
			if (!newProps.isOver && this.state.dragFloor) {
				this.setState({
					dragFloor: null,
					dragSpace: null,
				});

				if (!newProps.didDrop && newProps.spaceBeingDragged) {
					newProps.unplaceDraggingSpace(newProps.spaceBeingDragged);
				}
			}
		}

		render() {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			const floors = this.props.stackingPlan.floors.slice().reverse();
			let buildingWidth;
			if (floors.length !== 0) {
				buildingWidth = Math.max.apply(
					Math,
					// @ts-expect-error TS7006: Parameter 'floor' implicitly h...
					floors.map(function (floor) {
						return floor.size;
					})
				);
			} else {
				buildingWidth = 0;
			}
			const widthFormatted = number(buildingWidth);
			const marginOverride = { padding: '0', margin: '0', flexShrink: '0' };
			const hideElements = { visibility: 'hidden', height: '0px' };

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
			return this.props.connectDropTarget(
				<div className={styles.stackingPlanView}>
					<FloorRowWrapper
						className={styles.floorRowWrapper}
						{...this.props}
						floors={floors}
						buildingWidth={buildingWidth}
					/>

					<div style={marginOverride} className={styles.floorRow}>
						{/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"hidden" ... Remove this comment to see the full error message */}
						<div style={hideElements} className={styles.yAxis}>
							<div className={styles.floorNumber} />
							<div className={styles.floorDescription} />
						</div>
						<div className={styles.floorCell}>
							<div className={styles.xAxis}>{widthFormatted} SQ FT</div>
						</div>
						{/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ visibility: string; height: string; }' is ... Remove this comment to see the full error message */}
						<div style={hideElements} className={styles.menuContainer}>
							<div className={styles.threeDotMenu} />
						</div>
					</div>
				</div>
			);
		}
	}
);
