import React, { RefCallback, useMemo, useState } from 'react';
import { PortfolioV2, useInsightsQuery } from 'api';
import { FiltersState } from '../PortfolioFiltersProvider';
import { TrendFilters } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';
import { PortfolioTrendChartComponent as Chart } from './Chart/PortfolioTrendChartComponent';
import { PortfolioTrendFiltersComponent as Filters } from './Filters/PortfolioTrendFilterComponent';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import { HelpIconContainer } from 'PortfolioAnalytics/UI';
import {
	ChartContainer,
	Heading,
	TabCardItem,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { MQB, Spinner } from '@compstak/ui-kit';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { Styled } from './styled';
import { styled } from 'styled-components';
import { Spacer } from 'PortfolioAnalytics/UI';
import {
	getTrendlineData,
	getDateForTimeSpan,
	getScatterData,
	getTrendQueryParams,
} from './util';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';

type MarketTrendComponentProps = {
	portfolio: PortfolioV2;
	filters: FiltersState;
	onChartRef: RefCallback<HTMLElement>;
};

export const PortfolioTrend = ({
	portfolio,
	filters,
	onChartRef,
}: MarketTrendComponentProps) => {
	const [trendFilters, setTrendFilters] = useState<TrendFilters>({
		metric: 'effectiveRent',
		timeSpan: 3,
		movAverage: 3,
	});

	const portfolioQueryParams = useMemo(() => {
		return getTrendQueryParams({
			filters,
			portfolio,
			trendFilters: trendFilters,
		}).portfolioQueryParams;
	}, [filters, portfolio, trendFilters]);

	const {
		data: portfolioTrendData,
		isFetching,
		isError: isPortfolioTrendError,
	} = useInsightsQuery({
		params: portfolioQueryParams,
	});

	const isBubbleChart = !!portfolioTrendData?.points;

	const chartData = useMemo(() => {
		if (
			!portfolioTrendData?.trendLine?.length ||
			(portfolioTrendData.points && !portfolioTrendData.points.length)
		) {
			return {
				portfolio: [],
				points: [],
				market: [],
			};
		}

		const timeSpanDate = getDateForTimeSpan(trendFilters.timeSpan);

		return {
			portfolio: getTrendlineData(portfolioTrendData.trendLine, timeSpanDate),
			points: getScatterData(
				portfolioTrendData.points || [],
				trendFilters.metric
			),
		};
	}, [portfolioTrendData, trendFilters.timeSpan, trendFilters.metric]);

	const noData = !chartData.portfolio.length && !chartData.points.length;

	const title = 'Portfolio Trend';
	const downloadBannerId = title.replaceAll(' ', '');

	return (
		<TabCardItem>
			<ChartTopRow>
				<HeadingContainer>
					<Heading>{title}</Heading>
					<HelpIconContainer>
						<PortfolioHelpIcon
							height={16}
							width={16}
							tooltip={MARKET_TREND_TOOLTIP}
						/>
					</HelpIconContainer>
				</HeadingContainer>
				<Filters
					filters={trendFilters}
					setMarketTrendFilters={setTrendFilters}
					isLoading={isFetching}
					isBubbleChart={isBubbleChart}
				/>
			</ChartTopRow>
			<Styled.Error show={isPortfolioTrendError}>
				<div>
					<span>Error!</span>
					<div>An error occurred while loading the Market trends </div>
				</div>
			</Styled.Error>
			{isFetching ? (
				<PortfolioChartContainer>
					<Spinner size="l" />
				</PortfolioChartContainer>
			) : (
				<>
					{noData ? (
						<PortfolioChartContainer>
							<NoDataTab minHeight={`48px`} message="" />
						</PortfolioChartContainer>
					) : (
						<Chart
							data={chartData}
							noData={noData}
							metricFilter={trendFilters.metric}
							timeSpan={trendFilters.timeSpan}
							isBubbleChart={isBubbleChart}
							onChartRef={onChartRef}
						/>
					)}
				</>
			)}
			<DownloadBranding bannerId={downloadBannerId} />
		</TabCardItem>
	);
};

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	column-gap: 1rem;

	@media (min-width: ${MQB.D_1536}) {
		width: 100px;
		margin-right: 2rem;
	}

	@media (min-width: ${MQB.D_1920}) {
		width: 100%;
		column-gap: 1rem;
	}
`;

const ChartTopRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

const PortfolioChartContainer = styled(ChartContainer)`
	padding: 2.5rem 0 0;
`;

const CalculationOverview = styled(CalculationOverviewLink)`
	color: ${({ theme }) => theme.colors.blue.blue500};
	font-size: 0.75rem;
	font-weight: 350;
	line-height: 1.125rem;
	letter-spacing: 0.3px;
	padding: 0.25rem 0;
	text-transform: uppercase;
	&:hover {
		color: ${({ theme }) => theme.colors.blue.blue800};
	}
`;

const MARKET_TREND_TOOLTIP = (
	<>
		<Spacer>
			The portfolio trend chart plots the moving averages of several portfolio
			lease metrics over time.
		</Spacer>
		<Spacer>
			You can use the Metric selector to switch between Net Effective Rent,
			Starting Rent, Lease Term, Free Rent and Tenant Improvement Allowance.
		</Spacer>
		<Spacer>
			You can also select a timespan between one year and 15 years, and adjust
			the moving average period.
		</Spacer>
		<Spacer>
			<CalculationOverview context="charts" />
		</Spacer>
	</>
);
