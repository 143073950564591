import dayjs from 'dayjs';

const FIRST_AVAILABLE = 2005;
const FORECAST_AMOUNT = 2;

const current = dayjs().year();
export const currentDate = dayjs().day(1).format('YYYY-MM-DD');

export const years: string[] = Array(current - FIRST_AVAILABLE + 1)
	.fill(FIRST_AVAILABLE)
	.map((item, i) => String(item + i));

const getYearsWithForecast = () => {
	const result = [...years];
	const lastYear = years[years.length - 1];
	for (let i = 0; i < FORECAST_AMOUNT; i++) {
		result.push(`${+lastYear + (i + 1)}`);
	}
	return result;
};

export const yearsWithForecast = getYearsWithForecast();

export const getYears = (yearsInFuture: number) => [
	...years,
	...getFutureYears(yearsInFuture),
];

const getFutureYears = (n: number) =>
	[...Array(n)].map((_, i) => String(current + i + 1));
