import dayjs from 'dayjs';

import { useSearchSalesQuery } from 'api';
import {
	ActivityType,
	SelectedSavedSearch,
} from 'ExchangeDashboard/SalesActivityTable';
import { filtersToServerJSON } from 'models/filters/util';
import { useAppConfig } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { deleteCompTypeSpecificFilters } from 'models/filters/util/deleteFilter';

export const useExchangeDashboardSalesActivity = ({
	activeTab,
	enabled,
	selectedSavedSearch,
}: {
	activeTab: ActivityType;
	selectedSavedSearch?: SelectedSavedSearch;
	enabled?: boolean;
}) => {
	const appConfig = useAppConfig();

	const viewsState = appConfig.isExchange
		? 'viewsInLast6MonthsByExchangeUsers'
		: 'viewsInLast6MonthsByEnterpriseUsers';

	let [filters] = useFilters('exchange-dashboard');

	filters = deleteCompTypeSpecificFilters(filters, 'sale').filters;

	if (activeTab === 'totalSalePrice') {
		const oneYearAgo = dayjs().subtract(12, 'month').startOf('month').toDate();
		const todaysDate = new Date();

		filters = mergeFilters(filters, {
			saleDate: {
				min: oneYearAgo,
				max: todaysDate,
			},
		});
	}

	const sortKey = activeTab === 'viewsInLast6Months' ? viewsState : activeTab;

	const salesCompView = useSearchSalesQuery({
		params: {
			filter: [
				...filtersToServerJSON(filters),
				...(selectedSavedSearch?.value.filters ?? []),
			],
			limit: 50,
			offset: 0,
			order: 'desc',
			properties: [
				'portfolio.buildingAddress',
				'portfolio.city',
				'portfolio.state',
				'portfolio.zip',
				'portfolio.submarket',
				'portfolio.propertyId',
				'portfolio.buildingYear',
				'portfolio.buildingSize',
				'portfolio.buildingPropertyType',
				'portfolio.buildingClass',
				'saleDate',
				'totalSalePrice',
				'transactionSize',
				'salePricePsf',
				'lastAttributeUpdate',
				'market',
				'cost',
			],
			sort: sortKey,
		},
		enabled,
	});

	return {
		...salesCompView,
		type:
			activeTab === 'totalSalePrice'
				? ('largeSales' as const)
				: ('recentTransactions' as const),
	};
};
