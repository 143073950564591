import { ExportButton } from 'Components';
import { UnlockButton } from 'Components/UnlockButton/UnlockButton';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import {
	useIsSendToAnalyticsBtnAllowed,
	useSendToAnalyticsBtn,
} from 'hooks/chartBuilderHooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMediaQuery } from 'react-responsive';
import { FiltersType, useFilters } from 'reducers/filtersReducer';
import { AppState } from 'reducers/root';
import styled from 'styled-components';
import { CompType } from 'types';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { useAppSelector } from 'util/useAppSelector';
import buttonStyles from '../../../ui/styles/button.less';
import MonitoringIcon from '../../../ui/svg_icons/monitoring.svg';
import { GoToCompTypeSection } from './GoToCompTypeSection';
import { SaveSearchButton } from './SaveSearchButton';
import { MQB } from '@compstak/ui-kit';

type SearchToolbarButtonsProps = {
	compType: CompType;
	params: SearchLayoutRouteParams;
	filterContext?: FiltersType;
};

export const SearchToolbarButtons = ({
	compType,
	params,
	filterContext,
}: SearchToolbarButtonsProps) => {
	const flags = useFeatureFlags();
	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1920 });

	const isSendToAnalyticsBtnAllowed = useIsSendToAnalyticsBtnAllowed(compType);
	const [filters] = useFilters(filterContext);
	const selection = useAppSelector((state) => state.selection.list);

	const allPropertiesCount = useAppSelector(
		(store: AppState) => store.searchReducer.totalResults ?? 0
	);
	const { isEnabled, href, tooltipText } = useSendToAnalyticsBtn({
		filters,
		compType,
	});

	if (params.view !== 'list') {
		return null;
	}

	const relevantPropertiesCount =
		selection.length > 0
			? abbreviateNumber(selection.length)
			: getFiltersMarkets(filters).length > 1
				? undefined
				: abbreviateNumber(allPropertiesCount);

	return (
		<Root>
			<LeftButtons>
				{compType !== 'property' && (
					<UnlockButton
						compType={compType}
						markets={getFiltersMarkets(filters)}
					/>
				)}
				<ExportButton
					compType={compType}
					inactiveClassName="button_dark_blue button_export"
					className="button_export"
				/>
				{compType === 'property' && <GoToCompTypeSection />}
				{flags.SavedSearchImprovements && <SaveSearchButton />}
			</LeftButtons>
			{isSendToAnalyticsBtnAllowed && (
				<>
					<VerticalSeparator />
					<a
						href={href}
						data-qa-id="send-to-chartbuilder"
						data-tooltipdelay="0"
						data-tooltip={tooltipText}
						data-tooltip-position={isEnabled ? 'above' : 'below'}
						className={
							`${buttonStyles.button} button_export` +
							(isEnabled ? '' : ' button_disabled')
						}
					>
						<MonitoringIcon />
						{isLargeScreen && 'SEND TO CHARTBUILDER'}
						{relevantPropertiesCount
							? `\u00A0(${relevantPropertiesCount})`
							: ''}
					</a>
				</>
			)}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	.button_button.button_export,
	.cs-override-button.button_export {
		height: 48px;
		text-transform: none;
		padding: 0 0.5rem;
	}
`;

const LeftButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const VerticalSeparator = styled.span`
	width: 1px;
	height: 32px;
	background-color: ${({ theme }) => theme.colors.neutral.n50};
	margin: 0 1rem;
`;
