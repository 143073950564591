import { deselectAll } from 'actions/selection';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'router/migration';
import { routes } from 'router/routes/routes';
import IconCircleTick from '../../ui/svg_icons/circle_tick.svg';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalTitle,
} from 'Components/Modals/common/UI';

type Props = {
	portfolioId: number;
	closeDialog: () => void;
};

export const SuccessView = ({ portfolioId, closeDialog }: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<ModalCenteredContent>
			<IconCircleTick />
			<ModalTitle>Success</ModalTitle>
			<p>You've saved properties to your portfolio</p>
			<ModalButtons data-qa-id="success-portfolio-modal-btn">
				<ModalButton
					variant="secondary"
					onClick={closeDialog}
					data-qa-id="success-portfolio-modal-close-btn"
				>
					Close
				</ModalButton>
				<ModalButton
					variant="primary"
					onClick={() => {
						navigate(
							routes.portfolioByIdView.toHref({
								portfolioId,
								viewType: 'dashboard',
							})
						);
						dispatch(deselectAll());
					}}
					data-qa-id="success-portfolio-modal-go-to-btn"
				>
					Go to portfolio page
				</ModalButton>
			</ModalButtons>
		</ModalCenteredContent>
	);
};
