import { PortfolioPropertyTypeByMarket } from 'api';
import { isPropertyTypeAllowed } from './isPropertyTypeAllowed';

/**
 * Unique property types which can be used for market rent analytics
 */
export const getValidPropertyTypes = (
	propertyTypesByMarkets: PortfolioPropertyTypeByMarket[],
	selectedMarketId: number | null
) => {
	if (propertyTypesByMarkets.length === 0 || selectedMarketId == null)
		return [];

	const propertyTypes: PortfolioPropertyTypeByMarket[] = [];

	for (const propertyTypeByMarket of propertyTypesByMarkets) {
		const isAlreadyAdded = propertyTypes.find(
			(pm) =>
				pm.buildingPropertyTypeId ===
				propertyTypeByMarket.buildingPropertyTypeId
		);
		if (isAlreadyAdded) continue;

		const isAllowed = isPropertyTypeAllowed(
			propertyTypeByMarket.buildingPropertyTypeId
		);
		const isForSelectedMarket =
			propertyTypeByMarket.marketId === selectedMarketId;

		if (isAllowed && isForSelectedMarket) {
			propertyTypes.push(propertyTypeByMarket);
		}
	}
	return propertyTypes;
};
