import React from 'react';
import Menu from 'Singletons/Menu';

import styles from 'Pages/Analytics/Builder/Components/styles/menu.less';
import popupStyles from 'Components/Popup/popup.less';
import { useDispatch } from 'react-redux';
import { hideMenu } from 'Singletons/Menu/actions';

export type ProjectSortBy = 'dateModified' | 'name' | 'numberOfCharts';

export type ProjectSortMenuProps = {
	setSortBy: (sortBy: ProjectSortBy) => void;
	sortBy: ProjectSortBy;
};

export const ProjectSortMenu = (props: ProjectSortMenuProps) => {
	const dispatch = useDispatch();

	const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value as ProjectSortBy;
		props.setSortBy(value);
		dispatch(hideMenu('project-sort'));
	};

	return (
		<form className={styles.sortMenu}>
			<input
				type="radio"
				id="projectRepoRadioDateModified"
				value="dateModified"
				className="radio"
				checked={props.sortBy === 'dateModified'}
				onChange={handleSelect}
			/>
			<label htmlFor="projectRepoRadioDateModified">Date Modified</label>
			<input
				type="radio"
				id="projectRepoRadioName"
				className="radio"
				value="name"
				checked={props.sortBy === 'name'}
				onChange={handleSelect}
			/>
			<label htmlFor="projectRepoRadioName">Name</label>
			<input
				type="radio"
				id="projectRepoRadioNumberOfCharts"
				className="radio"
				value="numberOfCharts"
				checked={props.sortBy === 'numberOfCharts'}
				onChange={handleSelect}
			/>
			<label htmlFor="projectRepoRadioNumberOfCharts">Number of Charts</label>
		</form>
	);
};

Menu.addComponent('project-sort', ProjectSortMenu, {
	className: popupStyles.compactWhite,
});

export default 'project-sort';
