import { ChartFromServer, DataSetType } from 'Pages/Analytics/analytics';
import factory from './factory';

const serviceController = factory.create<void, ChartFromServer>({
	timeout: 10 * 60 * 1000,
	createUrl: () => {
		return '/api/chartBuilder';
	},
});

const exportable = serviceController.exportable({
	async updateDraft(chart: ChartFromServer) {
		// Remove execution date and expiration date filters from chartBuilder AP-17069
		chart.dataSets = chart.dataSets.map((ds) => {
			if (ds.type !== DataSetType.COMMERCIAL) return ds;
			return {
				...ds,
				filters: ds.filters.filter(
					(filter) =>
						filter.property !== 'executionDate' &&
						filter.property !== 'expirationDate'
				),
			};
		});
		const data = await factory.post<ChartFromServer, ChartFromServer>(
			'/api/chartBuilder',
			chart
		);
		exportable.add(undefined, data);
		return data;
	},

	async deleteDraft() {
		await factory.del('/api/chartBuilder');
		serviceController.service.clear(undefined);
	},

	async deleteDataSet(dataSetId: number) {
		let chart = await exportable.load();
		chart = {
			...chart,
			dataSets: chart.dataSets.filter(({ id }) => id !== dataSetId),
		};

		await factory.del<void, boolean>(`/api/chartBuilder/dataSet/${dataSetId}`);
		exportable.add(undefined, chart);
		return chart;
	},
});

export default exportable;
