import styled from 'styled-components';

export const LinkButtonHoverOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	&:hover {
		opacity: 1;
		background-color: rgba(77, 82, 97, 0.9);
	}
`;
