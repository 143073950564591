import { FormSelect } from '@compstak/ui-kit';
import { useAllPortfoliosQuery } from 'api';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDataSet } from '../../Builder/actions';
import { useChartBuilder } from '../../Builder/reducer';
import { useMarkets } from 'hooks/useMarkets';
import {
	ModalButton,
	ModalButtons,
	ModalInputMessage,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { Market } from '@compstak/common';
import { ChartBuilderMarketFormSelect } from './ChartBuilderMarketFormSelect';
import { useModal } from 'providers/ModalProvider';

type FromPortfolioModalProps = {
	onClose: () => void;
};

export const FromPortfolioModal = ({ onClose }: FromPortfolioModalProps) => {
	const [selectedPortfolioId, setSelectedPortfolioId] = useState<
		number | undefined
	>();
	const [_selectedPortfolioMarket, setSelectedPortfolioMarket] = useState<
		Market | undefined
	>();

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();
	const markets = useMarkets();

	const dispatch = useDispatch();
	const chartBuilder = useChartBuilder();
	const { closeModal } = useModal();

	const selectedPortfolio = useMemo(() => {
		return portfolios?.find((p) => p.id === selectedPortfolioId);
	}, [portfolios, selectedPortfolioId]);

	const selectedPortfolioMarket = useMemo(() => {
		if (!selectedPortfolio) {
			return;
		}
		return (
			_selectedPortfolioMarket ??
			markets[selectedPortfolio.markets[0]?.marketId]
		);
	}, [_selectedPortfolioMarket, selectedPortfolio]);

	const onClickDone = () => {
		if (!selectedPortfolio || !selectedPortfolioMarket) return;

		dispatch(
			addDataSet(
				chartBuilder.chartDraft,
				selectedPortfolio.name,
				{
					portfolioId: selectedPortfolioId,
					market: selectedPortfolioMarket,
				},
				markets
			)
		);
		closeModal();
		onClose();
	};

	const options = useMemo(() => {
		if (!portfolios) return [];
		return portfolios.map((p) => ({ title: p.name, value: p.id }));
	}, [portfolios]);

	const isEmptyPortfolio =
		selectedPortfolioId &&
		(!selectedPortfolio?.markets.some((market) => market.propertyCount > 0) ||
			selectedPortfolio?.markets.length === 0);

	return (
		<div>
			<ModalTitle>Portfolios</ModalTitle>
			<FormSelect
				label="Select A Portfolio"
				isSearchable
				value={selectedPortfolioId}
				items={options}
				noResultMessage="There is no data..."
				isLoading={isFetchingPortfolios}
				onChange={({ selectedItem }) => {
					if (!selectedItem) {
						return;
					}
					setSelectedPortfolioId(Number(selectedItem.value));
					setSelectedPortfolioMarket(undefined);
				}}
			/>
			{isEmptyPortfolio && (
				<ModalInputMessage isError>
					Chart Builder unavailable - This portfolio hasn't been set up yet.
					Chart builder will be available once properties are added to the
					portfolio.
				</ModalInputMessage>
			)}

			<br />
			{!!selectedPortfolio && selectedPortfolio.markets.length > 1 && (
				<ChartBuilderMarketFormSelect
					items={selectedPortfolio.markets.map((market) => ({
						value: market.marketId,
						title: markets[market.marketId].displayName,
					}))}
					value={selectedPortfolioMarket?.id}
					onChange={({ selectedItem }) =>
						selectedItem &&
						setSelectedPortfolioMarket(markets[selectedItem.value])
					}
				/>
			)}
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					type="submit"
					variant="primary"
					onClick={onClickDone}
					disabled={
						selectedPortfolio == null || selectedPortfolioMarket == null
					}
				>
					Done
				</ModalButton>
			</ModalButtons>
		</div>
	);
};
