import { PrivateLoansTable } from 'Pages/PropertyPageV3/LoansTab/PrivateLoansTable';
import { CherreLoan } from 'api';
import React from 'react';
import { PropertyComp } from 'types';
import { Container, EditPageSectionTitle } from '../UI';
import { PdfColumn } from '../types';
import { EditablePublicLoans } from './EditablePublicLoans';
import { PublicLoansColumn } from './usePublicLoansPdfColumns';

type Props = {
	property: PropertyComp;
	editablePublicLoansRow: Record<string, string | number>[] | undefined;
	setEditablePublicLoansRow: React.Dispatch<
		React.SetStateAction<Record<string, string | number>[] | undefined>
	>;
	editablePublicLoansColumns: PdfColumn<
		Record<string, string | number>,
		CherreLoan
	>[];
	setEditablePublicLoansColumns: React.Dispatch<
		React.SetStateAction<PublicLoansColumn[]>
	>;
};
export const EditLoansPage = ({
	property,
	editablePublicLoansRow,
	setEditablePublicLoansRow,
	editablePublicLoansColumns,
	setEditablePublicLoansColumns,
}: Props) => {
	return (
		<Container>
			<EditPageSectionTitle>Property Loan Data</EditPageSectionTitle>

			<PrivateLoansTable />

			{editablePublicLoansRow && editablePublicLoansRow?.length > 0 && (
				<EditablePublicLoans
					property={property}
					editablePublicLoansRow={editablePublicLoansRow}
					setEditablePublicLoansRow={setEditablePublicLoansRow}
					editablePublicLoansColumns={editablePublicLoansColumns}
					setEditablePublicLoansColumns={setEditablePublicLoansColumns}
				/>
			)}
		</Container>
	);
};
