import { Market } from '@compstak/common';
import dayjs from 'dayjs';
import { Shape, InsightDataPoint } from 'Pages/Analytics/analytics';
import { attributesHashFromShape } from 'Pages/Analytics/Builder/chartBuilderHelpers';
// @ts-expect-error TS7016: Could not find a declaration f...
import tinycolor from 'tinycolor';
import {
	getAxisId,
	isInsightDataShownAsBubble,
	isInsightDataShownAsLine,
	NO_NAME,
	transformValueForInput,
} from '../util';
import { SalesAttributeToPlotValues } from 'Pages/Analytics/Builder/chartBuilderConstants';

export default function generateLineOrPointSeries(
	series: Highcharts.SeriesOptionsType[],
	shape: Shape,
	// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
	data,
	market: Market,
	hasNewDesign: boolean
) {
	const color = tinycolor(shape.color);
	const yToUse = shape.y === 'ti' ? 'workValue' : shape.y;
	const canBeMonthly =
		// @ts-expect-error TS7015: Element implicitly has an 'any...
		attributesHashFromShape(shape)[yToUse]?.canBeMonthly ?? false;
	const isMonthly = canBeMonthly && market.monthly;

	if (isInsightDataShownAsLine(data)) {
		// @ts-expect-error TS7006: Parameter 'point' implicitly h...
		const trendData = data.trendLine.map((point) => [
			dayjs(point.date).valueOf(),
			transformValueForInput(isMonthly, shape.y, point.value),
		]);

		const marker: Highcharts.PointMarkerOptionsObject = {
			enabled: false,
		};

		if (hasNewDesign) {
			marker.states = {
				hover: {
					enabled: false,
				},
			};
		}

		series.push({
			type: 'line',
			data: trendData,
			marker,
			states: {
				hover: {
					lineWidthPlus: 0,
				},
			},
			name: shape.name || NO_NAME,
			color: color.toRgbString(),
			animation: false,
			// @ts-expect-error TS2339: Property 'dashStyle' does not ...
			dashStyle: shape.dashStyle,
			yAxis: getAxisId(shape.y),
			xAxis: getAxisId(shape.x),
		});
	}

	if (isInsightDataShownAsBubble(data)) {
		const pointData = data.points
			// @ts-expect-error TS7006: Parameter 'point' implicitly h...
			.filter((point) => point[yToUse] !== undefined && point[shape.z])
			.map((point: InsightDataPoint) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				let date = dayjs(point[shape.x]);

				if (shape.y === SalesAttributeToPlotValues.CAP_RATE) {
					date = dayjs(point.saleDate);
				}

				if (hasNewDesign) {
					date = date.startOf('month');
				}

				return [
					date.valueOf(),
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					transformValueForInput(isMonthly, shape.y, point[yToUse]),
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					point[shape.z],
				];
			});

		let bubbleSeriesOptions: Highcharts.SeriesBubbleOptions = {
			type: 'bubble',
			color: color.setAlpha(0.4).toRgbString(),
			data: pointData,
			name: shape.name || NO_NAME,
			maxSize: 30,
			minSize: 2,
			zMin: 0,
			zMax: 500000,
			animation: false,
			yAxis: getAxisId(shape.y),
			xAxis: shape.x,
		};

		if (hasNewDesign) {
			bubbleSeriesOptions = {
				...bubbleSeriesOptions,
				zIndex: 1,
				// this gives us bigger bubbles
				zMin: undefined,
				zMax: undefined,
			};
		}

		series.push(bubbleSeriesOptions);
	}

	if (data.quartiles) {
		// @ts-expect-error TS7006: Parameter 'point' implicitly h...
		const quartiles = data.quartiles.map((point) => [
			dayjs(point.date).valueOf(),
			...point.values,
		]);
		series.push({
			type: 'line',
			data: quartiles,
			color: color.setAlpha(0.4).toRgbString(),
			animation: false,
			name: NO_NAME,
		});
	}

	return series;
}
