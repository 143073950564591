import {
	SuggestionItemV2,
	PlaceSuggestionItem,
	Suggestion,
	SuggestionItem,
	PropertyTypeOrSubtypeSuggestionItem,
	SpaceTypeOrPropertySubtypeSuggestionItem,
	SpaceTypeOrSubtypeSuggestionItem,
} from 'api/suggestions/suggestions';

export const getSuggestionItemAtIndex = (
	suggestions: Suggestion[] | undefined,
	index: number
) => {
	return (
		getSuggestionAndItemViaItemIndex(suggestions, index)?.suggestionItem ??
		undefined
	);
};

export const getSuggestionItemTitle = (
	suggestionItem: SuggestionItem | undefined
) => {
	if (!suggestionItem) {
		return '';
	}

	if (isPlaceSuggestionItem(suggestionItem)) {
		return suggestionItem.description;
	}

	if (isSuggestionItemV2(suggestionItem)) {
		return suggestionItem.title;
	}

	return '';
};

export const getSuggestionAtIndex = (
	suggestions: Suggestion[] | undefined,
	index: number
) => {
	if (
		index === -1 &&
		suggestions &&
		suggestions.length !== 0 &&
		!isTopResultSuggestion(suggestions[0])
	) {
		return undefined;
	}

	return getSuggestionAndItemViaItemIndex(suggestions, index)?.suggestion;
};

const getSuggestionAndItemViaItemIndex = (
	suggestions: Suggestion[] | undefined,
	index: number
) => {
	if (!suggestions) {
		return undefined;
	}

	let currentCount = 0;
	let currentFieldIndex = 0;

	while (suggestions[currentFieldIndex]) {
		if (
			suggestions[currentFieldIndex].suggestions.length + currentCount <=
			index
		) {
			currentCount += suggestions[currentFieldIndex].suggestions.length;
			currentFieldIndex++;
		} else {
			const targetIndex = index - currentCount;
			return {
				suggestion: suggestions[currentFieldIndex],
				suggestionItem: suggestions[currentFieldIndex].suggestions[targetIndex],
			};
		}
	}
};

export const isSuggestionItemV2 = (
	suggestionItem: SuggestionItem
): suggestionItem is SuggestionItemV2 => {
	return (
		!!suggestionItem &&
		'marketId' in suggestionItem &&
		'title' in suggestionItem
	);
};

export const isGlobalSuggestionItem = (
	suggestion: Suggestion,
	suggestionItem: SuggestionItem
): suggestionItem is SuggestionItemV2 => {
	return isGlobalSuggestion(suggestion) && isSuggestionItemV2(suggestionItem);
};

export const isPlaceSuggestionItem = (
	suggestionItem: SuggestionItem
): suggestionItem is PlaceSuggestionItem => {
	return !!suggestionItem && 'lat' in suggestionItem && 'lon' in suggestionItem;
};

export const isSpaceTypeSuggestionItem = (
	suggestionItem: SuggestionItem
): suggestionItem is Omit<
	SpaceTypeOrPropertySubtypeSuggestionItem,
	'spaceTypeId'
> &
	Required<Pick<SpaceTypeOrPropertySubtypeSuggestionItem, 'spaceTypeId'>> => {
	return (
		'spaceTypeId' in suggestionItem &&
		typeof suggestionItem.spaceTypeId === 'number'
	);
};

export const isSpaceSubtypeSuggestionItem = (
	suggestionItem: SuggestionItem
): suggestionItem is Omit<SpaceTypeOrSubtypeSuggestionItem, 'spaceSubtypeId'> &
	Required<Pick<SpaceTypeOrSubtypeSuggestionItem, 'spaceSubtypeId'>> => {
	return (
		'spaceSubtypeId' in suggestionItem &&
		typeof suggestionItem.spaceSubtypeId === 'number'
	);
};

export const isPropertyTypeSuggestionItem = (
	suggestionItem: SuggestionItem
): suggestionItem is Omit<
	PropertyTypeOrSubtypeSuggestionItem,
	'propertyTypeId'
> &
	Required<Pick<PropertyTypeOrSubtypeSuggestionItem, 'propertyTypeId'>> => {
	return (
		'propertyTypeId' in suggestionItem &&
		typeof suggestionItem.propertyTypeId === 'number'
	);
};

export const isPropertySubtypeSuggestionItem = (
	suggestionItem: SuggestionItem
): suggestionItem is Omit<
	PropertyTypeOrSubtypeSuggestionItem,
	'propertySubtypeId'
> &
	Required<Pick<PropertyTypeOrSubtypeSuggestionItem, 'propertySubtypeId'>> => {
	return (
		'propertySubtypeId' in suggestionItem &&
		typeof suggestionItem.propertySubtypeId === 'number'
	);
};

export const isTopResultSuggestion = (
	suggestion?: Suggestion
): suggestion is Suggestion => {
	return suggestion?.title.toLowerCase() === 'Top Result'.toLowerCase();
};

export const isGlobalAddressSuggestion = (
	suggestion?: Suggestion
): suggestion is Suggestion => {
	return (
		suggestion?.title.toLowerCase() ===
		'Addresses in other markets'.toLowerCase()
	);
};

export const isGlobalBuildingSuggestion = (
	suggestion?: Suggestion
): suggestion is Suggestion => {
	return (
		suggestion?.title.toLowerCase() ===
		'Buildings in other markets'.toLowerCase()
	);
};

export const isGlobalSuggestion = (
	suggestion?: Suggestion
): suggestion is Suggestion => {
	return (
		isGlobalAddressSuggestion(suggestion) ||
		isGlobalBuildingSuggestion(suggestion)
	);
};

export const SUGGESTIONS_WITHOUT_MARKET_CHIP: Suggestion['field'][] = [
	'buyer',
	'seller',
	'tenantName',
	'landlordName',
	'tenantRealtyCompanies',
	'landlordRealtyCompanies',
	'propertyTypeOrSubtype',
	'spaceTypeOrPropertySubtype',
	'spaceTypeOrSpaceSubtype',
];
