import { User } from '@compstak/common';
import { Image, Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import compstakLogo from 'images/compstak-logo.png';
import { useTheme } from 'styled-components';

type Props = {
	preparedFor: string;
	user: User;
};

export const PageFooter = ({ preparedFor, user }: Props) => {
	const theme = useTheme();

	return (
		<View
			style={[
				style.footer,
				{
					borderTop: `1px solid ${theme.colors.gray.gray700}`,
				},
			]}
			fixed
		>
			<Text style={style.footerLabel}>Data provided by</Text>
			<View style={style.logoContainer}>
				<Image style={style.logo} src={compstakLogo} />
				<Text style={style.logoText}>COMPSTAK</Text>
			</View>
			<Text style={style.footerLabel}>
				This report was prepared
				{preparedFor ? ` for ${preparedFor}` : ''} by {user.firstName}{' '}
				{user.lastName} at {user.companyName} and is governed by the{' '}
				<Link
					src="http://www.compstak.com/legal"
					style={{
						color: theme.colors.gray.gray700,
					}}
				>
					CompStak Terms of Use
				</Link>
				. It may not be copied or disseminated to others without written
				permission from CompStak. All information is not guaranteed and should
				be verified by personal inspection by and/or with the appropriate
				professional(s).
			</Text>
		</View>
	);
};

const style = StyleSheet.create({
	footer: {
		position: 'absolute',
		bottom: 10,
		margin: '0 16px',
		width: '100%',
		paddingTop: 4,
	},
	footerLabel: {
		fontSize: 10,
	},
	logoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 4,
	},
	logo: {
		width: 30,
		height: 30,
	},
	logoText: {
		fontSize: 10,
		textTransform: 'uppercase',
	},
});
