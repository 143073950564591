import styled from 'styled-components';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { Spinner, MQB } from '@compstak/ui-kit';
import { PortfolioStats } from 'PortfolioAnalytics/hooks/usePortfolioStats';

type Props = {
	stats: Partial<PortfolioStats>;
	isFetchingStats: boolean;
};

export const PortfolioStatsV2 = ({ stats, isFetchingStats }: Props) => {
	const { propertyCount, activeLeaseCount, totalSqFt } = stats;

	return (
		<StatsContainer>
			<StatsItem>
				<Label>Prop.</Label>
				<Value>
					{isFetchingStats ? (
						<Spinner size="s" />
					) : (
						abbreviateNumber(propertyCount)
					)}
				</Value>
			</StatsItem>
			<StatsItem>
				<Label>Leases</Label>
				<Value>
					{isFetchingStats ? (
						<Spinner size="s" />
					) : (
						abbreviateNumber(activeLeaseCount)
					)}
				</Value>
			</StatsItem>
			<StatsItem>
				<Label>Sqft</Label>
				<Value>
					{isFetchingStats ? <Spinner size="s" /> : abbreviateNumber(totalSqFt)}
				</Value>
			</StatsItem>
		</StatsContainer>
	);
};

const StatsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 10rem;
	height: 3rem;
	border-radius: 3px;
	padding: 4px 4px;
	box-sizing: border-box;
	background: ${({ theme }) => `${theme.colors.gray.gray200}`};
`;

const StatsItem = styled.div`
	min-width: 3.125rem;
	display: inline-block;
	vertical-align: top;
	border-right: 1px solid ${({ theme }) => `${theme.colors.gray.gray110}`};
	text-align: center;

	&:last-child {
		border-right: none;
	}
`;

const Label = styled.div`
	color: ${({ theme }) => `${theme.colors.blue.blue400}`};
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1rem;
	text-transform: uppercase;
`;

const Value = styled.div`
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 0.875rem;
	letter-spacing: 0.2px;
	color: ${({ theme }) => `${theme.colors.gray.gray700}`};
	text-transform: uppercase;
	@media screen and (max-width: ${MQB.D_1280}) {
		font-size: 0.75rem;
		font-weight: 300;
	}
`;
