import styled from 'styled-components';
import { ModalParagraph } from 'Components/Modals/common/UI';

export const MobileIncompatibilityModal = () => (
	<IncompatibilityModalContainer>
		<ModalParagraph>
			CompStak is not optimized for use with a mobile device.
		</ModalParagraph>
		<ModalParagraph>
			To get the most out of CompStak, please access it from a desktop computer.
		</ModalParagraph>
	</IncompatibilityModalContainer>
);

const IncompatibilityModalContainer = styled.div`
	padding-top: 1rem;
`;
