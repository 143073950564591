import styled from 'styled-components';
import IconCross from '../../ui/svg_icons/cross.svg';
import { useHeaderLinks } from './useHeaderLinks';

type Props = {
	closeMenu: () => void;
};

export const MobileMenu = ({ closeMenu }: Props) => {
	return (
		<Container>
			{useHeaderLinks()}
			<CloseButton onClick={closeMenu}>
				<StyledIconCross />
			</CloseButton>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	z-index: ${(p) => p.theme.zIndex.overlay};
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${(p) => p.theme.colors.neutral.n800};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
`;

const CloseButton = styled.button`
	background-color: transparent;
	border: 0;
	padding: 0.5rem;
`;

const StyledIconCross = styled(IconCross)`
	fill: ${(p) => p.theme.colors.white.white};
`;
