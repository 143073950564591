import {
	MapAnalyticsFormSection,
	MapAnalyticsMetric,
	MapAnalyticsMetricRadioGroup,
	MapAnalyticsSectionTitle,
} from 'maps';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ArrowLeftIcon from 'ui/svg_icons/arrow_left_2.svg';
import { focusFilterSection } from '../../actions';
import { MapAnalyticsSettingsCloseButton } from '../MapAnalyticsSettingsCloseButton';
import {
	FilterFocusButton,
	MapAnalyticsForm,
	MapAnalyticsTitleInput,
} from '../UI';
import { useSpaceTypeAnalyticsState } from './SpaceTypeAnalyticsProvider';

export const SpaceTypeAnalyticsSettingsMenu = () => {
	const [state, setState] = useSpaceTypeAnalyticsState();
	const dispatch = useDispatch();

	const onChangeMetric = (metric: MapAnalyticsMetric) => {
		setState((s) => ({ ...s, metric }));
	};

	const onClickClose = () => {
		setState((s) => ({ ...s, view: 'read' }));
	};

	const onClickFocusFilter = () => {
		dispatch(focusFilterSection('spaceTypeId'));
	};

	return (
		<>
			<MapAnalyticsForm>
				<MapAnalyticsFormSection>
					<MapAnalyticsSectionTitle>Title</MapAnalyticsSectionTitle>
					<MapAnalyticsTitleInput
						value={state.title}
						onChange={(e) => {
							setState((s) => ({ ...s, title: e.target.value }));
						}}
					/>
				</MapAnalyticsFormSection>
				<MapAnalyticsMetricRadioGroup
					id="spaceTypeMetric"
					metric={state.metric}
					onChangeMetric={onChangeMetric}
				/>
				<StyledFilterFocusButton
					type="button"
					data-qa-id="focus-space-type-filter-btn"
					onClick={onClickFocusFilter}
				>
					<ArrowLeftIcon /> Filter Space Type
				</StyledFilterFocusButton>
			</MapAnalyticsForm>
			<MapAnalyticsSettingsCloseButton
				data-qa-id="space-type-settings-close-btn"
				onClick={onClickClose}
			/>
		</>
	);
};

const StyledFilterFocusButton = styled(FilterFocusButton)`
	margin: 10px 0;
	display: flex;
	align-items: center;
	gap: 10px;
`;
