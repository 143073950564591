import React, { memo } from 'react';
import {
	MultiMarketSelectedContainerStyled,
	BaseMultiSelect,
	CheckboxEnabled,
	CheckboxDisabled,
	CheckboxIntermediate,
	capitalizeFirstLetter,
} from '@compstak/ui-kit';
import { Market } from '@compstak/common';

interface StateMultiMarketSelectProps {
	stateName: string;
	searchTerm: string;
	stateMarkets: Market[];
	isOpen: boolean;
	value: Market[];
	selectedMarkets: Market[];
	changeSelectedMarkets: (markets: Market[]) => void;
	changeExpandedState: (
		field: string,
		value: (state: Record<string, boolean>) => boolean
	) => void;
}

export const StateMultiMarketSelect = memo(
	({
		stateName,
		searchTerm,
		stateMarkets,
		isOpen = false,
		value,
		selectedMarkets,
		changeExpandedState,
		changeSelectedMarkets,
	}: StateMultiMarketSelectProps) => {
		const localState = value.map(multiselectItemMapper);

		const handleDisableAll = () => {
			changeSelectedMarkets(
				selectedMarkets.filter(
					({ id }) =>
						!stateMarkets.some(({ id: stateMarketId }) => id === stateMarketId)
				)
			);
		};

		const handleEnableAll = () => {
			changeSelectedMarkets([...selectedMarkets, ...stateMarkets]);
		};

		const handleItemChange = ({
			selectedItem,
		}: {
			selectedItem: MultiMarketItem;
		}) => {
			if (!selectedItem) {
				return;
			}

			const selectedMarket = stateMarkets.find(
				({ id }) => id === selectedItem.id
			);

			if (!selectedMarket) {
				return;
			}

			if (selectedMarkets.some(({ id }) => id === selectedMarket.id)) {
				changeSelectedMarkets(
					selectedMarkets.filter(({ id }) => id !== selectedMarket.id)
				);
			} else {
				changeSelectedMarkets([...selectedMarkets, selectedMarket]);
			}
		};

		const selectProps = {
			key: stateName,
			items: stateMarkets.map(multiselectItemMapper),
			value: localState,
			selectedItemsContainerRenderer: () => {
				return (
					<MultiMarketSelectedContainerStyled>
						<span onClick={(e) => e.stopPropagation()}>
							{(() => {
								if (localState.length === stateMarkets.length) {
									return <CheckboxEnabled onClick={handleDisableAll} />;
								} else if (localState.length === 0) {
									return <CheckboxDisabled onClick={handleEnableAll} />;
								}
								return <CheckboxIntermediate onClick={handleDisableAll} />;
							})()}
						</span>
						<span style={{ marginLeft: 12 }}>
							{capitalizeStateName(stateName)}
						</span>
					</MultiMarketSelectedContainerStyled>
				);
			},
			onItemSelectChange: handleItemChange,
			disableMenuFocusByOpen: true,
		};

		if (stateMarkets.length === 0) return null;

		return (
			// @ts-expect-error TS2322: Type '{ isOpen: boolean; onOpe...
			<BaseMultiSelect
				{...selectProps}
				isOpen={searchTerm ? true : isOpen}
				onOpenButtonClick={() => {
					changeExpandedState(
						stateName,
						(prevExpandedState) => !prevExpandedState[stateName]
					);
				}}
			/>
		);
	}
);

StateMultiMarketSelect.displayName = 'StateMultiMarketSelect';

const capitalizeStateName = (name: string) => {
	return name
		.split(' ')
		.map((word) =>
			word.toLowerCase() === 'of' ? word : capitalizeFirstLetter(word)
		)
		.join(' ');
};

type MultiMarketItem = ReturnType<typeof multiselectItemMapper>;

const multiselectItemMapper = (market: Market) => ({
	states: market.states,
	title: market.displayName,
	value: market.name,
	id: market.id,
});
