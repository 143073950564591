import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from './getFiltersMarkets';
import { Market } from '@compstak/common';

export const shouldUseMonthlyMetrics = (filters: FiltersObject) => {
	return shouldUseMonthlyMetricsByMarkets(getFiltersMarkets(filters));
};

export const shouldUseMonthlyMetricsByMarkets = (markets: Market[]) => {
	return markets.every((market) => market.monthly);
};
