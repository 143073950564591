import { Button, Pending } from '@compstak/ui-kit';
import { routes } from 'router';
import styled from 'styled-components';
import IconChevronDown from 'ui/svg_icons/chevrons-down.svg';
import IconChevronUp from 'ui/svg_icons/chevrons-up.svg';
import { BlueBoxLegend } from '../UI';
import { useDominantPropertySpaceType } from './useDominantPropertySpaceType';

type Props = {
	isMarketStatsCollapsed: boolean;
	setIsMarketStatsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MarketStatsBoxHeader = ({
	setIsMarketStatsCollapsed,
	isMarketStatsCollapsed,
}: Props) => {
	const params = routes.propertyById.useParams();
	const { data: dominantSpaceType, isFetching: isFetchingSpaceType } =
		useDominantPropertySpaceType({
			propertyId: params.propertyId,
		});

	return (
		<>
			<BlueBoxLegend>
				<TitleContainer>
					<div>Market Stats</div>
					<div>
						{isFetchingSpaceType ? (
							<TitleContainer>
								- <StyledPending margin="0" />
							</TitleContainer>
						) : (
							dominantSpaceType && <> - {dominantSpaceType}</>
						)}
					</div>
				</TitleContainer>
			</BlueBoxLegend>
			<BlueBoxLegend rightSide>
				<StyledButton
					onClick={() => setIsMarketStatsCollapsed((s) => !s)}
					variant="ghost"
					icon={
						isMarketStatsCollapsed ? <IconChevronDown /> : <IconChevronUp />
					}
				>
					{isMarketStatsCollapsed ? 'Expand' : 'Collapse'}
				</StyledButton>
			</BlueBoxLegend>
		</>
	);
};

const StyledButton = styled(Button)`
	text-transform: none;
	padding: 0;
	font-size: 0.75rem;
	gap: 0.5rem;
	&:not(:disabled):hover {
		background-color: transparent;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const StyledPending = styled(Pending)`
	width: 100px;
	height: 1rem;
	display: inline;
`;
