import { ReactNode } from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

type StatsItemProps = {
	children: ReactNode;
	isActive?: boolean;
	label: string;
	transitionStatus?: TransitionStatus;
};

export const StatsItem = ({
	children,
	isActive,
	label,
	transitionStatus,
}: StatsItemProps) => {
	return (
		<Container isActive={isActive}>
			<Label transitionStatus={transitionStatus}>{label}</Label>
			<Value transitionStatus={transitionStatus}>{children}</Value>
		</Container>
	);
};

const Container = styled.div<{
	isActive?: boolean;
}>`
	width: 60px;
	display: inline-block;
	vertical-align: top;
	border-right: 1px solid ${({ theme }) => `${theme.colors.gray.gray300}`};
	transition: border 0.3s ease-in-out;
	text-align: center;

	&:last-child {
		border-right: none;
	}

	@media (max-width: 1024px) {
		display: ${({ isActive }) => (isActive ? 'inline-block' : 'none')};
		cursor: pointer;
		border: none;
	}

	@media (max-width: 1440px) {
		width: 50px;
	}
`;

const Label = styled.div<{ transitionStatus?: TransitionStatus }>`
	transition: color 0.3s ease-in-out;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 10px;
	letter-spacing: 1px;
	line-height: 10px;
	color: ${({ theme, transitionStatus }) =>
		transitionStatus === 'entering'
			? `${theme.colors.white.white}`
			: `${theme.colors.blue.blue500}`};
`;

const Value = styled.div<{ transitionStatus?: TransitionStatus }>`
	transition: color 0.3s ease-in-out;
	font-weight: 400;
	font-size: 14px;
	padding-top: 2px;
	line-height: 14px;
	color: ${({ theme, transitionStatus }) =>
		transitionStatus === 'entering'
			? `${theme.colors.white.white}`
			: `${theme.colors.gray.gray500}`};
	letter-spacing: 0.2px;
`;
