import { Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';

export const Loading = () => {
	return (
		<Root>
			<Spinner size="xl" />
		</Root>
	);
};

const Root = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
