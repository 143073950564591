import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PropertyTypeName } from 'api/referenceData/useReferenceDataQuery';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

export const useLeaseExpirationByPropertyType = (
	params: UseLeaseExpirationByPropertyTypeParams,
	options?: UseLeaseExpirationByPropertyTypeOptions
) => {
	const { filters } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.LEASE_EXPIRATION_BY_PROPERTY_TYPE, params],
		queryFn: async () => {
			const response = await API.post<LeaseExpirationByPropertyTypeResponse>(
				'/api/dashboard/leaseExpirationByPropertyType',
				{
					filters: [
						{ property: 'marketId', value: filters.market.id },
						...filtersToServerJSON(filters).filter(
							(f) => !f.property.includes('marketName')
						),
					],
				}
			);

			return response.data;
		},
		...options,
	});
};

export type UseLeaseExpirationByPropertyTypeOptions =
	UseQueryOptions<LeaseExpirationByPropertyTypeResponse>;

type UseLeaseExpirationByPropertyTypeParams = {
	filters: FiltersObject;
};

type LeaseExpirationByPropertyTypeResponseItem = {
	buildingPropertyType: PropertyTypeName;
	leaseCount: number;
	sqft: number;
	year: number;
};

type LeaseExpirationByPropertyTypeResponse =
	LeaseExpirationByPropertyTypeResponseItem[];
