import {
	SimpleTableColumn,
	MQB,
	Flex,
	TooltipV2,
	defaultTheme,
	Spinner,
} from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { AnyComp, LeaseComp } from 'types';
import { useRenderCompField } from 'hooks/useFormatCompField';
import { getIsMonthlyMarket } from 'utils';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';
import { COMP_FIELD_TOOLTIPS } from 'constants/tooltips';
import { Checkbox as CheckboxOriginal, StopPropagation } from 'Components';
import { CheckedState } from '@radix-ui/react-checkbox';
import { usePortfolioLeases } from './PortfolioLeasesProvider';
import { ExportCompsButton } from 'Components/Export';
import styled from 'styled-components';
import { ROW_HEIGHT } from 'PortfolioAnalytics/constants';

export const PORTFOLIO_LEASES_COLUMNS: (SimpleTableColumn<LeaseComp> & {
	tabletWidth?: number;
})[] = [
	{
		id: 'executionQuarter',
		header: 'Trans. Qtr.',
		width: 80,
		flex: 1,
	},
	{
		id: 'tenantName',
		header: 'Tenant',
		width: 180,
		tabletWidth: 100,
		flex: 1,
	},
	{
		id: 'spaceType',
		header: 'Space Type',
		width: 80,
		flex: 1,
	},
	{
		id: 'buildingAddress',
		header: 'Address',
		width: 160,
		tabletWidth: 100,
		flex: 1,
	},
	{
		id: 'city',
		header: 'City',
		width: 140,
		tabletWidth: 100,
		flex: 1,
	},
	{
		id: 'market',
		header: 'Market',
		width: 120,
		tabletWidth: 80,
		flex: 1,
	},
	{
		id: 'submarket',
		header: 'Submarket',
		width: 140,
		tabletWidth: 90,
		flex: 1,
	},
	{
		id: 'transactionSize',
		header: 'Transaction SqFt',
		width: 110,
		flex: 1,
		hAlign: 'right',
	},
	{
		id: 'startingRent',
		header: () => (
			<Flex gap="0.25rem">
				<div>Starting Rent</div>
				<TooltipV2 content={COMP_FIELD_TOOLTIPS.startingRent}>
					<span>
						<IconQuestionMark width="1rem" />
					</span>
				</TooltipV2>
			</Flex>
		),
		width: 110,
		flex: 1,
		hAlign: 'right',
	},
	{
		id: 'freeMonths',
		header: 'Free Rent (mo.)',
		width: 110,
		hAlign: 'right',
	},
	{
		id: 'workValue',
		header: 'TI Value / Work Value',
		width: 140,
		hAlign: 'right',
	},
	{
		id: 'leaseTerm',
		header: 'Lease Term',
		width: 90,
		hAlign: 'right',
	},
	{
		id: 'expirationDate',
		header: 'Expiration Date',
		width: 120,
		hAlign: 'right',
	},
];

export const usePortfolioLeasesColumns = () => {
	const { isFetchingAll, selectionState } = usePortfolioLeases();

	const isTablet = useMediaQuery({ maxWidth: MQB.T_834 });

	const renderCompField = useRenderCompField();

	const renderPortfolioLeaseCell = ({
		row,
		name,
	}: {
		row: LeaseComp;
		name: keyof LeaseComp;
	}) => {
		return renderCompField({
			name,
			row: row as AnyComp,
			isMonthly: getIsMonthlyMarket(row.marketId),
		});
	};

	const {
		getAreAllSelected,
		areSomeSelected,
		getIsRowSelected,
		toggleAllSelected,
		toggleRow,
	} = selectionState;

	const selectAllCheckboxState: CheckedState = getAreAllSelected()
		? true
		: areSomeSelected
			? 'indeterminate'
			: false;

	const columns: SimpleTableColumn<LeaseComp>[] = [
		{
			id: 'selection',
			header: ({ column }) => {
				if (isFetchingAll) {
					return <Spinner size="s" isCentered />;
				}

				return (
					<Checkbox
						width={column.width}
						height={ROW_HEIGHT}
						id="selectAll"
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={selectAllCheckboxState}
						onCheckedChange={toggleAllSelected}
						bgColorUnchecked={defaultTheme.colors.white.white}
					/>
				);
			},
			body: ({ row, column }) => {
				return (
					<StopPropagation handler="onClick">
						<Checkbox
							width={column.width}
							height={ROW_HEIGHT}
							name={`selectRow-${row.id}`}
							data-qa-id={`select-row-checkbox-${row.id}`}
							checked={getIsRowSelected(row.id)}
							onCheckedChange={() => toggleRow(row.id)}
							onClick={(e) => e.stopPropagation()}
						/>
					</StopPropagation>
				);
			},
			width: 50,
			hAlign: 'center',
		},
		{
			id: 'actions',
			header: () => '',
			body: ({ row }) => (
				<StopPropagation handler="onClick">
					<ExportCompsButton
						compType="lease"
						compIds={[row.id]}
						buttonType="row"
					/>
				</StopPropagation>
			),
			width: 75,
		},
		...PORTFOLIO_LEASES_COLUMNS.map((col) => {
			const column: SimpleTableColumn<LeaseComp> = {
				...col,
				body: ({ row }) =>
					renderPortfolioLeaseCell({ row, name: col.id as keyof LeaseComp }),
				width: isTablet ? col.tabletWidth ?? col.width : col.width,
			};

			return column;
		}),
	];

	return columns;
};

const Checkbox = styled(CheckboxOriginal)<{ width: number; height: number }>`
	width: ${({ width }) => `calc(${width}px - 12px)`};
	height: ${({ height }) => height}px;
	justify-content: center;
`;
