import { UseQueryOptions } from '@tanstack/react-query';
import {
	SearchLeasesResponse,
	SearchPropertiesResponse,
	SearchSalesResponse,
	composeEnabled,
	useSearchPropertiesQuery,
	useSearchSalesQuery,
} from 'api';
import { useSearchLeasesQuery } from 'api/searchLeases/useSearchLeasesQuery';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { CompType } from 'types';

type UseSearchSingleCompParams = {
	compType: CompType;
	filters: Partial<FiltersObject>;
};

export const useSearchSingleComp = (
	{ compType, filters }: UseSearchSingleCompParams,
	options?:
		| UseQueryOptions<SearchLeasesResponse>
		| UseQueryOptions<SearchSalesResponse>
		| UseQueryOptions<SearchPropertiesResponse>
) => {
	const { data: leaseData, ...restLeaseQueryResult } = useSearchLeasesQuery(
		{
			filter: filtersToServerJSON(filters),
			limit: 1,
			offset: 0,
			sort: 'executionQuarter',
			order: 'desc',
		},
		{
			...(options as UseQueryOptions<SearchLeasesResponse>),
			enabled: composeEnabled(compType === 'lease', options?.enabled),
		}
	);

	const { data: saleData, ...restSaleQueryResult } = useSearchSalesQuery({
		params: {
			filter: filtersToServerJSON(filters),
			limit: 1,
			offset: 0,
			sort: 'dateCreated',
			order: 'asc',
		},
		...(options as UseQueryOptions<SearchSalesResponse>),
		enabled: composeEnabled(compType === 'sale', options?.enabled),
	});

	const { data: propertyData, ...restPropertyQueryResult } =
		useSearchPropertiesQuery(
			{
				filter: filtersToServerJSON(filters),
				limit: 1,
				offset: 0,
				sort: 'buildingSize',
				order: 'desc',
			},
			{
				...(options as UseQueryOptions<SearchPropertiesResponse>),
				enabled: composeEnabled(compType === 'property', options?.enabled),
			}
		);

	const data =
		leaseData?.comps[0] ?? saleData?.comps[0] ?? propertyData?.properties[0];
	const isFetching =
		restLeaseQueryResult.isFetching ||
		restSaleQueryResult.isFetching ||
		restPropertyQueryResult.isFetching;

	return { data, isFetching };
};
