import React, { useEffect } from 'react';
import {
	Row,
	HeaderRow,
	CellLeft,
	Cell,
	SelectedCell,
	Body,
	TextOverflow,
	SummaryRow,
	SummaryIconWrap,
	Wrap,
} from './UI';
import {
	CheckboxEnabled,
	CheckboxDisabled,
	CheckboxIntermediate,
	CheckboxBlocked,
} from '@compstak/ui-kit';
import ExceedLimitIcon from '../../../../ui/svg_icons/exceedLimit.svg';
import { UserExportPerMarketPolicy } from '../../../../reducers/export';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import Help from '../../../../ui/svg_icons/help.svg';
import { Comp } from '../../../../types';
import { useMarkets } from 'hooks/useMarkets';

export type ExportMarketTableOutputValue = Record<string, boolean>;

interface UseExportMarketTableProps {
	perMarketPolicy?: UserExportPerMarketPolicy[];
	withFinancial: boolean;
	nonFinancialAvailableAmount: number;
	comps: Comp[];
}

export const DEFAULT_PER_MARKET_POLICY: UserExportPerMarketPolicy[] = [];

export const useExportMarketTable = ({
	perMarketPolicy = DEFAULT_PER_MARKET_POLICY,
	withFinancial,
	nonFinancialAvailableAmount,
	comps,
}: UseExportMarketTableProps) => {
	const perMarketSelectedAmountMap = React.useMemo(() => {
		return comps.reduce<Record<number, number>>((acc, comp) => {
			if (!acc[comp.marketId]) acc[comp.marketId] = 0;
			acc[comp.marketId]++;
			return acc;
		}, {});
	}, [comps]);

	const [perAllowedMarketPolicyMap, totalCost, totalFinancialAvailable] =
		React.useMemo(() => {
			return perMarketPolicy.reduce<[Record<string, boolean>, number, number]>(
				(acc, policy) => {
					const map = acc[0];

					const isFinancialAllowed = withFinancial && policy.canExport;
					const isNonFinancialAllowed =
						!withFinancial &&
						perMarketSelectedAmountMap[policy.marketId] <=
							nonFinancialAvailableAmount;

					if (isFinancialAllowed || isNonFinancialAllowed)
						map[policy.marketId] = true;

					acc[1] += policy.cost;
					acc[2] += policy.availableWithFinancial;
					return acc;
				},
				[{}, 0, 0]
			);
		}, [
			nonFinancialAvailableAmount,
			perMarketPolicy,
			perMarketSelectedAmountMap,
			withFinancial,
		]);

	const freeExports = comps.length - totalCost;

	const [selectedMarkets, setSelectedMarkets] =
		React.useState<ExportMarketTableOutputValue>(perAllowedMarketPolicyMap);

	const perAllowedMarketSelectedAmountMap = React.useMemo(() => {
		const result: Record<number, number> = {};
		for (const marketId in perMarketSelectedAmountMap) {
			if (perAllowedMarketPolicyMap[marketId]) {
				result[marketId] = perMarketSelectedAmountMap[marketId];
			}
		}
		return result;
	}, [perAllowedMarketPolicyMap, perMarketSelectedAmountMap]);

	const totalSelectedExportAmount = Object.entries(
		perAllowedMarketSelectedAmountMap
	).reduce((acc, [marketId, amount]) => {
		if (selectedMarkets[marketId]) acc += amount;
		return acc;
	}, 0);

	useEffect(() => {
		const result = { ...perAllowedMarketPolicyMap };
		for (const marketId in selectedMarkets) {
			// explicit check on "false", since if market exist and has "undefined" value it means it's blocked
			// in terms of perAllowedMarketPolicyMap
			if (result[marketId] && selectedMarkets[marketId] === false) {
				result[marketId] = false;
			}
		}
		setSelectedMarkets(result);

		// deps: we do not want to be updated on selectedMarket changes, it will obviously lead to an infinite loop
	}, [perAllowedMarketPolicyMap]);

	const allowedMarketPolicyCount = Object.keys(
		perAllowedMarketPolicyMap
	).length;

	const handleChange = (newValue: ExportMarketTableOutputValue) => {
		setSelectedMarkets(newValue);
	};

	const disableAll = () => {
		const result = {};
		for (const id in selectedMarkets) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			result[id] = false;
		}
		handleChange(result);
	};

	const enableAll = () => {
		handleChange(perAllowedMarketPolicyMap);
	};

	// @ts-expect-error TS7006: Parameter 'marketId' implicitl...
	const disable = (marketId) => {
		handleChange({ ...selectedMarkets, [marketId]: false });
	};

	const enable = (marketId: number) => {
		handleChange({
			...selectedMarkets,
			[marketId]: true,
		});
	};

	const selectedMarketsCount = React.useMemo(
		() => getSelectedMarketCount(selectedMarkets),
		[selectedMarkets]
	);

	const isNoneSelected = selectedMarketsCount === 0;
	const isAllSelected = selectedMarketsCount === allowedMarketPolicyCount;
	const isSomeSelected =
		selectedMarketsCount > 0 && selectedMarketsCount < allowedMarketPolicyCount;

	const onMarketHeaderClick = isNoneSelected ? enableAll : disableAll;

	const renderHeaderCheckBox = () => {
		if (allowedMarketPolicyCount === 0) return <CheckboxBlocked />;
		if (isNoneSelected) return <CheckboxDisabled />;
		if (isAllSelected) return <CheckboxEnabled />;
		if (isSomeSelected)
			return <CheckboxIntermediate data-qa-id={'checkboxIntermediateTestId'} />;
	};

	const onMarketClick = (marketId: number, isExceedLimit: boolean) => {
		return isExceedLimit
			? undefined
			: selectedMarkets[marketId]
				? () => disable(marketId)
				: () => enable(marketId);
	};

	return {
		onMarketClick,
		selectedMarkets,
		onMarketHeaderClick,
		checkboxElement: renderHeaderCheckBox(),
		freeExports,
		totalSelectedExportAmount,
		totalFinancialAvailable,
		perMarketSelectedAmountMap,
		selectedMarketsCount,
		allowedMarketPolicyCount,
	};
};

export type UseExportMarketTableReturnType = ReturnType<
	typeof useExportMarketTable
>;

type ExportMarketTableViewProps = Omit<UseExportMarketTableProps, 'comps'> &
	UseExportMarketTableReturnType;

export const ExportMarketTableView = ({
	perMarketPolicy = DEFAULT_PER_MARKET_POLICY,
	onMarketClick,
	selectedMarkets,
	onMarketHeaderClick,
	checkboxElement,
	freeExports,
	totalSelectedExportAmount,
	perMarketSelectedAmountMap,
	withFinancial,
	nonFinancialAvailableAmount,
}: ExportMarketTableViewProps) => {
	const markets = useMarkets();

	return (
		<Wrap data-qa-id={exportMarketTableTestId}>
			<HeaderRow withFinancial={withFinancial} data-qa-id={headerRowTestId}>
				<CellLeft
					onClick={onMarketHeaderClick}
					data-qa-id={headerCheckboxTestId}
				>
					{checkboxElement}
				</CellLeft>
				<CellLeft onClick={onMarketHeaderClick} data-qa-id={headerMarketTestId}>
					Market
				</CellLeft>
				<Cell>Selected</Cell>
				{withFinancial && <Cell>Available</Cell>}
			</HeaderRow>
			<Body data-qa-id={tableBodyTestId}>
				{perMarketPolicy.map(
					({ marketId, availableWithFinancial, limit, canExport }) => {
						const selectedValue = perMarketSelectedAmountMap[marketId];

						const isExceedNonFinancialLimit =
							selectedValue > nonFinancialAvailableAmount;
						const isExceedFinancialLimit = !canExport;
						const isExceedLimit = withFinancial
							? isExceedFinancialLimit
							: isExceedNonFinancialLimit;

						const cellLeftProps = {
							isBlocked: isExceedLimit,
							onClick: onMarketClick(marketId, isExceedLimit),
						};

						return (
							<Row
								key={marketId}
								isExceedLimit={isExceedLimit}
								withFinancial={withFinancial}
								data-qa-id={getRowTestId(marketId)}
							>
								<CellLeft {...cellLeftProps}>
									{isExceedLimit ? (
										<CheckboxBlocked />
									) : selectedMarkets[marketId] ? (
										<CheckboxEnabled />
									) : (
										<CheckboxDisabled />
									)}
								</CellLeft>
								<CellLeft {...cellLeftProps}>
									<TextOverflow>{markets[marketId].displayName}</TextOverflow>
								</CellLeft>
								<SelectedCell>
									{isExceedLimit && (
										<ExceedLimitIcon
											data-tooltip={getExceedIconMessage(
												withFinancial,
												availableWithFinancial,
												nonFinancialAvailableAmount
											)}
											data-qa-id={exportLimitIconTestId}
										/>
									)}
									{selectedValue}
								</SelectedCell>
								{withFinancial && (
									<Cell>
										{availableWithFinancial} / {limit}
									</Cell>
								)}
							</Row>
						);
					}
				)}
			</Body>
			<SummaryRow withFinancial={withFinancial} data-qa-id={summaryRowTestId}>
				<CellLeft>
					<span>Total Export</span>
					{freeExports > 0 && (
						<SummaryIconWrap
							data-tooltip={getSummaryRowTooltip(freeExports)}
							data-qa-id={summaryRowHelpIconTestId}
						>
							<Help width="10px" height="10px" />
						</SummaryIconWrap>
					)}
				</CellLeft>
				<Cell>{totalSelectedExportAmount}</Cell>
			</SummaryRow>
		</Wrap>
	);
};

export const exportMarketTableTestId = 'exportMarketTableTestId';
export const headerRowTestId = 'headerRowTestId';
export const headerMarketTestId = 'headerMarketTestId';
export const headerCheckboxTestId = 'headerCheckboxTestId';
export const tableBodyTestId = 'tableBodyTestId';
export const summaryRowTestId = 'summaryRowTestId';
export const summaryRowHelpIconTestId = 'summaryRowHelpIconTestId';
export const exportLimitIconTestId = 'exportLimitIconTestId';

export const getSelectedMarketCount = (
	selectedMarkets: ExportMarketTableOutputValue
) => {
	let count = 0;
	for (const id in selectedMarkets) {
		if (selectedMarkets[id]) {
			count++;
		}
	}
	return count;
};

export const getRowTestId = (marketId: number): string => {
	return `row-${marketId}-testid`;
};

export const getSummaryRowTooltip = (freeExports: number) => {
	return `You have selected ${freeExports} comps that ${
		freeExports === 1 ? 'was' : 'were'
	} previously exported. These comps will not count against your available exports.`;
};

export const exceedIconMessageMap = {
	financial: {
		doNotHaveEnough:
			'You do not have enough exports in this market available to download your current selection. Refine your filters to export in this market.',
		hitLimit: 'You have hit the export limit for this market',
	},
	nonFinancial: {
		doNotHaveEnough:
			'You do not have enough exports available to download your current selection. Refine your filters to export in this market.',
		hitLimit: 'You have hit the export limit',
	},
};

const getExceedIconMessage = (
	withFinancial: boolean,
	availableWithFinancial: number,
	nonFinancialAvailableAmount: number
) => {
	const financialMessage =
		availableWithFinancial > 0
			? exceedIconMessageMap.financial.doNotHaveEnough
			: exceedIconMessageMap.financial.hitLimit;

	const nonFinancialMessage =
		nonFinancialAvailableAmount > 0
			? exceedIconMessageMap.nonFinancial.doNotHaveEnough
			: exceedIconMessageMap.nonFinancial.hitLimit;

	return withFinancial ? financialMessage : nonFinancialMessage;
};
