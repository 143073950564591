import { useLayoutEffect } from 'react';
import { useNavigate } from 'router';

export function useShouldRedirect(
	shouldRedirect: boolean,
	redirectAddress: string | undefined
): void {
	const navigate = useNavigate();

	useLayoutEffect(() => {
		if (shouldRedirect && redirectAddress) {
			navigate(redirectAddress);
		}
	}, [shouldRedirect, redirectAddress, navigate]);
}
