import { CreatePortfolioParams, useCreatePortfolioMutation } from 'api';
import { useNavigate } from 'router';
import { routes } from 'router/routes/routes';
import { PortfolioDialog } from '../Components';

export const NewPortfolioDialog = () => {
	const { mutateAsync: createPortfolio, isLoading: isLoadingCreatePortfolio } =
		useCreatePortfolioMutation();

	const navigate = useNavigate();

	return (
		<PortfolioDialog
			title="New Portfolio"
			isLoadingMutation={isLoadingCreatePortfolio}
			onSubmit={async (values) => {
				const params: CreatePortfolioParams = {
					name: values.name,
				};
				if (values.description.length > 0) {
					params.description = values.description;
				}
				const newPortfolio = await createPortfolio(params);

				navigate(
					routes.portfolioByIdView.toHref({
						portfolioId: newPortfolio.id,
						viewType: 'dashboard',
					})
				);
			}}
		/>
	);
};
