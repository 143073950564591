import React from 'react';

import Menu from 'Singletons/Menu';

import styles from '../styles/details.less';

type CompPartialMenuState = any;

class CompPartialMenu extends React.Component<{}, CompPartialMenuState> {
	constructor(props: {}) {
		super(props);
		this.state = {
			style: {
				display: '',
			},
		};
	}

	hidePartialCompMessage() {
		// Hacky way to hide popup imediatly because we are going to another page.
		this.setState({
			style: { display: 'none' },
		});
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	}

	render() {
		return (
			<div style={this.state.style} className={styles.leasePartialMenuContent}>
				<p>
					This comp is missing some key details but is visible because you
					submitted it.
				</p>
				<p>
					Read about how we credit submitted comps{' '}
					<a
						onClick={this.hidePartialCompMessage.bind(this)}
						href="/upload?section=about"
					>
						here
					</a>
					.
				</p>
			</div>
		);
	}
}

export default class CompPartial extends React.Component {
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	showPartialCompMessage = (event) => {
		event.preventDefault();
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.showMenu(
			'lease-partial-menu',
			event.target,
			'below',
			this.props
		);
	};

	render() {
		return (
			<div className={styles.partialMessage}>
				<div className={styles.leftSide}>
					<span className={styles.warningIcon}>!</span>
					You submitted this comp but we still need some more details.{' '}
					<a href="#" onClick={this.showPartialCompMessage}>
						Learn More
					</a>
				</div>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'verifyButton' does not exist on type 'Re... Remove this comment to see the full error message */}
				<div className={styles.rightSide}>{this.props.verifyButton}</div>
			</div>
		);
	}
}

Menu.addComponent('lease-partial-menu', CompPartialMenu);
