import { RentPeriod } from 'Pages/PropertyPageV2_1/api';
import { DistributionDTO } from 'api';
import { VerticalBarChartProps } from '@compstak/ui-kit';

export const adaptData = (
	incomingData: DistributionDTO | void,
	rentPeriod: RentPeriod | undefined
): VerticalBarChartProps['data'] => {
	if (
		!incomingData ||
		!incomingData.summaries.length ||
		!incomingData.summaries[0].histogram.length
	) {
		return [];
	}

	return incomingData.summaries[0].histogram.map(
		({ value, transactionSizeSum }) => {
			const adjustedValue =
				rentPeriod === RentPeriod.MONTHLY ? value / 12 : value;
			return { name: adjustedValue, transactionSizeSum };
		}
	);
};
