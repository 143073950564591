import { nullValueText } from '@compstak/common';
import { requestDemo } from 'actions/user';
import { ExportButton, MultiPropertyItemsTooltip } from 'Components';
import ValueOrLockIcon from 'Components/AttributeValues/ValueOrLock/Icon';
import UnlockOrExportButton from 'Components/UnlockOrExportButton';
import {
	filtersFromServerJSON,
	filtersToServerJSON,
} from 'models/filters/util';
import { deleteCompTypeSpecificFilters } from 'models/filters/util/deleteFilter';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { usePermissions, useUser } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { useFilters } from 'reducers/filtersReducer';
import { SavedSearch, useSavedSearches } from 'reducers/savedSearches';
import { useUnlocked } from 'reducers/unlock';
import styled from 'styled-components';
import { SalesComp } from 'types/comp';
import { allAttributes, formatComp } from 'util/comp-format/src/format';
import ArrowBottom from '../ui/svg_icons/arrow_bottom.svg';
import ArrowTop from '../ui/svg_icons/arrow_top.svg';
import ErrorIcon from '../ui/svg_icons/error.svg';
import {
	ButtonWrap,
	ExchangePanel,
	Table,
	TableData,
	TableHead,
	TableRow,
	TableTab,
	TableTabs,
	TableTop,
	ViewButton,
} from './UI';

import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { useExchangeDashboardSalesActivity } from './hooks/salesActivity/useExchangeDashboardSalesActivity';
import { Link } from 'router';
import { useModal } from 'providers/ModalProvider';
import { getLabelForFilterAttribute } from 'models/filters/util/filterHelpers';
import { ThanksForDemoRequestModal } from 'Components/Modals/ThanksForDemoRequestModal/ThanksForDemoRequestModal';

export type ActivityType =
	| 'saleDate'
	| 'lastAttributeUpdate'
	| 'dateCreated'
	| 'totalSalePrice'
	| 'viewsInLast6Months';

type TabOption = { label: string; type: ActivityType; tooltip: string };
const currentApp = window?.appConfig?.isExchange ? 'Exchange' : 'Enterprise';

const salesTabOptions: TabOption[] = [
	{
		label: 'Recent Sales Transactions',
		type: 'saleDate',
		tooltip:
			'This tab contains recently closed transactions based on execution date and your selected filters.  It is displayed in reverse chronological order.',
	},
	{
		label: 'Recently Updated',
		type: 'lastAttributeUpdate',
		tooltip:
			'This tab contains existing transactions that have been recently updated.  It is based on your selected filters and is displayed in reverse chronological order.',
	},
	{
		label: 'New On Compstak',
		type: 'dateCreated',
		tooltip: `This tab contains newly added transactions based on your selected filters.  It may include historical transactions that are newly available on CompStak ${currentApp} and is displayed in reverse chronological order.`,
	},
	{
		label: 'Largest Recent Sales',
		type: 'totalSalePrice',
		tooltip:
			'This tab contains the largest deals, sorted by total sales price, which have closed in the past 12 months.  It is based on your selected filters.',
	},
	{
		label: 'Most Viewed',
		type: 'viewsInLast6Months',
		tooltip: `This tab contains the most viewed transactions on CompStak ${currentApp} over the last six months and based on your selected filters.`,
	},
];

export type SelectedSavedSearch = {
	label: string;
	value: SavedSearch;
} | null;

export const SalesActivityTable = () => {
	const [activeTab, setActiveTab] = React.useState<ActivityType>('saleDate');
	const [filters] = useFilters('exchange-dashboard');
	const [selectedSalesIds, setSelectedSalesIds] = React.useState<number[]>([]);
	const [limit, setLimit] = React.useState<10 | 50>(10);

	const [selectedSavedSearch, setSelectedSavedSearch] =
		React.useState<SelectedSavedSearch>(null);

	const savedSearches = useSavedSearches();

	const unlocked = useUnlocked();
	const permissions = usePermissions();

	const salesAccess = permissions?.[filters.market.id]?.salesAccess;

	const irrelevantFilterLabels = React.useMemo(() => {
		const deletedFilters = filtersToServerJSON(
			deleteCompTypeSpecificFilters({ ...filters }, 'sale').deletedFilters
		);
		const irrelevantFilterAttributes = deletedFilters
			.map((deletedFilter) => deletedFilter.property)
			.filter((deletedFilterProperty) => {
				return !allAttributes.some((attr) => {
					const name = attr.backendSortName ?? attr.name;
					return name === deletedFilterProperty && attr.saleAttribute;
				});
			});

		// 'lease', because all irrelevant filter attributes should be lease specific
		const labelsForIrrelevantFilters = irrelevantFilterAttributes.map(
			(attribute) =>
				getLabelForFilterAttribute({ attribute, compType: 'lease' })
		);

		return labelsForIrrelevantFilters;
	}, [filters]);

	const { fullPageState } = useExchangeDashboardState();
	const { data, isFetching } = useExchangeDashboardSalesActivity({
		activeTab,
		selectedSavedSearch,
		enabled: fullPageState === null,
	});

	const salesComps = data?.comps;

	const filteredAndMappedSalesComps = React.useMemo(() => {
		return salesComps
			?.slice(0, limit)
			.filter((s) => unlocked.unlockedComps[s.id] ?? s)
			.map((comp, index) => (
				<SalesTableRow
					key={comp.id}
					index={index}
					comp={comp}
					setSelectedSalesIds={setSelectedSalesIds}
					selectedSalesIds={selectedSalesIds}
					displayExportButton={true}
				/>
			));
	}, [
		salesComps,
		unlocked.unlockedComps,
		limit,
		setSelectedSalesIds,
		selectedSalesIds,
	]);

	const emptyArray = Array.from(Array(limit).keys());

	const markets = useMarkets();
	const savedSearchOptions =
		savedSearches.savedSearchList
			?.filter((savedSearch) => {
				if (savedSearch.searchType === 'sales') {
					const savedSearchFilters = filtersFromServerJSON(
						'sale',
						markets,
						savedSearch.filters
					);
					const savedSearchMarkets = getFiltersMarkets(savedSearchFilters);

					return (
						savedSearchMarkets.length === 1 &&
						savedSearchMarkets[0]?.id === filters.market.id
					);
				}
				return false;
			})
			.map((savedSearch) => ({
				label: savedSearch.name,
				value: savedSearch,
			})) ?? [];

	const allChecked = React.useMemo(
		() =>
			Boolean(
				salesComps
					?.slice(0, limit)
					?.every(({ id }) => selectedSalesIds.includes(id))
			),
		[salesComps, limit, selectedSalesIds]
	);
	const sectionRef = React.useRef<HTMLDivElement | null>(null);
	const isMultiSelectActive =
		selectedSalesIds.length > 0 && selectedSalesIds.length < limit;

	useEffect(() => {
		if (selectedSavedSearch) {
			setSelectedSavedSearch(null);
		}
	}, [filters.market]);

	return (
		<ExchangePanel id="sales-activity-table" ref={sectionRef}>
			<AccessContainer hasAccess={salesAccess ?? false}>
				{irrelevantFilterLabels.length > 0 && (
					<InfoBox>
						The following filters do not apply to sales transactions and have
						not been used for sales activity results:{' '}
						{irrelevantFilterLabels.join(', ')}.
					</InfoBox>
				)}
				<TableTabs>
					{salesTabOptions.map((option) => (
						<TableTab
							active={option.type === activeTab}
							key={option.type}
							onClick={() => {
								setActiveTab(option.type);
								setSelectedSalesIds([]);
							}}
							data-tooltip-delay={500}
							data-tooltip={option.tooltip}
							data-qa-id={`sales-activity-${option.type}-tab`}
						>
							<span>{option.label}</span>
						</TableTab>
					))}
				</TableTabs>
				<TableTop>
					<ExportButton
						variant="default"
						compType="sale"
						selection={selectedSalesIds
							.map((id) => salesComps?.find((comp) => comp.id === id) ?? null)
							.filter(Boolean)}
					/>

					<SelectContainer>
						<Select
							data-qa-id="sales-saved-search-select"
							onChange={(e) =>
								e?.value === null
									? setSelectedSavedSearch(null)
									: // @ts-expect-error TS2345: Argument of type 'ValueType<{ ...
										setSelectedSavedSearch(e)
							}
							width="300px"
							placeholder="View by Saved Search"
							value={selectedSavedSearch}
							options={savedSearchOptions}
							isSearchable={false}
							isClearable={true}
						/>
					</SelectContainer>
				</TableTop>
				<Table>
					<colgroup>
						<col style={tableWidth} />
					</colgroup>
					<SalesTableHeader
						selectAllIds={() =>
							allChecked
								? setSelectedSalesIds([])
								: setSelectedSalesIds(
										salesComps!.slice(0, limit)?.map((s) => s.id)
									)
						}
						checked={allChecked}
						isMultiSelectActive={isMultiSelectActive}
					/>
					<tbody>
						{isFetching &&
							!filteredAndMappedSalesComps &&
							emptyArray.map((row, index) => (
								<SalesTableRow
									key={row}
									index={index}
									setSelectedSalesIds={setSelectedSalesIds}
									selectedSalesIds={selectedSalesIds}
									displayExportButton={true}
								/>
							))}
						{salesComps && filteredAndMappedSalesComps}
					</tbody>
				</Table>

				<ButtonWrap>
					{salesAccess &&
						limit === 10 &&
						salesComps &&
						salesComps.length > 10 && (
							<ViewButton
								onClick={() => setLimit(50)}
								data-qa-id="sales-view-more-button"
							>
								View Top 50 <ArrowBottom width={8} />
							</ViewButton>
						)}
					{salesAccess && limit === 50 && (
						<ViewButton
							onClick={() => {
								sectionRef.current?.scrollIntoView({
									block: 'start',
								});
								setLimit(10);
							}}
							data-qa-id="sales-view-less-button"
						>
							View Less <ArrowTop width={8} />
						</ViewButton>
					)}
				</ButtonWrap>
			</AccessContainer>
			{!salesAccess && <NoAccessModal />}
		</ExchangePanel>
	);
};

const AccessContainer = styled.div<{ hasAccess: boolean }>`
	filter: blur(${(props) => (props.hasAccess ? '0' : '4px')});
	& > * {
		pointer-events: ${(props) => (props.hasAccess ? 'auto' : 'none')};
	}
`;

const NoAccessModal = () => {
	const dispatch = useDispatch();
	const user = useUser();
	const { openModal } = useModal();

	const handleAccessRequest = () => {
		dispatch(requestDemo(user.email, 'sales', null));
		openModal({ modalContent: <ThanksForDemoRequestModal /> });
	};
	return (
		<NoAccessModalContainer>
			<h1>Make More Informed Decisions With CompStak Sales Comps.</h1>
			<p>
				Our comprehensive solution combines the breadth of public record
				transactions with difficult-to-source details that only CompStak can
				provide.
				<br />
				<br />
				Optimize your strategies, identify new opportunities and close better
				deals.
			</p>
			{user?.email && (
				<button onClick={handleAccessRequest}>Access Sales Comps Today</button>
			)}
		</NoAccessModalContainer>
	);
};

const tableWidth = { width: '100px' };

const NoAccessModalContainer = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.white.white};
	display: flex;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	flex-direction: column;
	font-family: 'Gotham', sans-serif;
	color: ${(props) => props.theme.colors.gray.gray500};

	width: 796px;
	position: absolute;
	top: 0px;
	left: 50%;
	transform: translate(-50%);
	padding: 33px 55px 39px 55px;

	h1 {
		font-size: 28px;
		font-style: normal;
		font-weight: 300;
		line-height: 40px;
		margin: 0 auto;
		text-align: center;
		width: 75%;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.5px;
		margin: 20px 0;
		align-self: start;
	}

	button {
		align-items: center;
		background-color: ${(props) => props.theme.colors.blue.blue400};
		border-radius: 3px;
		border: none;
		color: ${(props) => props.theme.colors.white.white};
		display: flex;
		flex-direction: row;
		font-size: 10px;
		height: 30px;
		justify-content: center;
		line-height: 14px;
		padding: 4px 12px;
		width: 190px;
		text-transform: uppercase;
	}
`;

const SelectContainer = styled.div`
	position: relative;
	width: 300px;
`;

const InfoBox = ({ children }: { children: React.ReactNode }) => (
	<InfoBoxContainer>
		<InfoBoxIconContainer>
			<ErrorIcon />
		</InfoBoxIconContainer>
		<InfoBoxText>{children}</InfoBoxText>
	</InfoBoxContainer>
);

const InfoBoxContainer = styled.div`
	// TODO: add color to ui-kit
	background-color: #2b2d39;
	border-radius: 3px;
	color: ${(props) => props.theme.colors.white.white};
	margin-bottom: 16px;
	padding: 8px;
`;

const InfoBoxText = styled.p`
	display: inline;
	font-family: Gotham;
	font-size: 12px;
	font-style: normal;
	font-weight: normal;
	line-height: 20px;
	margin-left: 28px;
`;

const InfoBoxIconContainer = styled.div`
	position: absolute;
	width: 24px;
	height: 24px;
	background: rgba(249, 249, 249, 0.1);
	border-radius: 40px;

	& svg {
		color: ${(props) => props.theme.colors.white.white};
		height: 17.14px !important;
		width: 17.14px !important;
		margin: 3.42px;
	}
`;

const SalesTableHeader = React.memo(
	({
		selectAllIds,
		checked,
		isMultiSelectActive,
	}: {
		selectAllIds: () => void;
		checked: boolean;
		isMultiSelectActive: boolean;
	}) => {
		return (
			<thead>
				<tr>
					<TableHead>
						<input
							type="checkbox"
							id="sales-activity-select-all-comps"
							className={`checkbox ${
								isMultiSelectActive ? 'tableview-indeterminate-checkbox' : ''
							}`}
							onChange={() => selectAllIds()}
							checked={checked}
						/>
						<label
							htmlFor="sales-activity-select-all-comps"
							className="checkbox"
						/>
					</TableHead>
					<TableHead>Address</TableHead>
					<TableHead>City</TableHead>
					<TableHead>Submarket</TableHead>
					<TableHead>Sale Date</TableHead>
					<TableHead>Total Sale Price</TableHead>
					<TableHead>Transaction Sqft</TableHead>
					<TableHead>Sale Price (PSF)</TableHead>
				</tr>
			</thead>
		);
	}
);

SalesTableHeader.displayName = 'SalesTableHeader';

const SalesTableRow: React.FC<{
	index: number;
	comp?: SalesComp;
	displayExportButton: boolean;
	setSelectedSalesIds: React.Dispatch<React.SetStateAction<number[]>>;
	selectedSalesIds: number[];
}> = React.memo((props) => {
	const comp = props.comp
		? formatComp(props.comp as unknown as SalesComp, 'sale')
		: undefined;

	const compDetailsPath = {
		pathname: `/comps/sales/${comp?.id}`,
		state: { fromMarketDashboard: true },
	};

	return (
		<TableRow>
			{props.displayExportButton && (
				<TableData className="td-container">
					<ExportContainer
						comp={comp}
						setSelectedSalesIds={props.setSelectedSalesIds}
						selectedSalesIds={props.selectedSalesIds}
					/>
				</TableData>
			)}
			<TableData>
				<Link
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp?.portfolio?.length > 1 ? (
						<MultiPropertyItemsTooltip saleComp={comp}>
							<PortfolioSale>
								{comp?.portfolio?.length} Properties
							</PortfolioSale>
						</MultiPropertyItemsTooltip>
					) : (
						comp?.portfolio?.[0]?.buildingAddress ?? nullValueText
					)}
				</Link>
			</TableData>
			<TableData data-qa-id="sales-portfolio-city-data">
				<Link
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp?.portfolio?.[0].city ?? nullValueText}
				</Link>
			</TableData>
			<TableData>
				<Link
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp?.portfolio?.[0].submarket ?? nullValueText}
				</Link>
			</TableData>
			<TableData>
				<Link
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp?.saleDate ?? nullValueText}
				</Link>
			</TableData>
			<TableData>
				<Link
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp?.totalSalePrice ?? nullValueText}
				</Link>
			</TableData>
			<TableData>
				<Link
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp?.transactionSize ?? nullValueText}
				</Link>
			</TableData>
			<TableData>
				<StyledLink
					className="td-container"
					to={compDetailsPath.pathname}
					state={compDetailsPath.state}
				>
					{comp && (
						<ValueOrLockIcon
							comp={comp}
							compType="sale"
							// @ts-expect-error TS2739: Type '{ name: string; }' is mi...
							field={{ name: 'salePricePsf' }}
						/>
					)}
				</StyledLink>
			</TableData>
		</TableRow>
	);
});

SalesTableRow.displayName = 'SalesTableRow';

const ExportContainer = (props: {
	comp?: SalesComp;
	setSelectedSalesIds: React.Dispatch<React.SetStateAction<number[]>>;
	selectedSalesIds: number[];
}) => {
	if (props.comp) {
		return (
			<Wrap>
				<InputContainer>
					<input
						type="checkbox"
						className="checkbox"
						id={'comp-' + props.comp.id}
						data-comp-id={props.comp.id}
						onChange={() =>
							props.setSelectedSalesIds((prevSalesIds) => {
								// @ts-expect-error TS18048: 'props.comp' is possibly 'unde...
								if (prevSalesIds.includes(props.comp.id)) {
									// @ts-expect-error TS18048: 'props.comp' is possibly 'unde...
									return prevSalesIds.filter((id) => id !== props.comp.id);
								} else {
									// @ts-expect-error TS18048: 'props.comp' is possibly 'unde...
									return prevSalesIds.concat([props.comp.id]);
								}
							})
						}
						checked={props.selectedSalesIds.includes(props.comp.id)}
					/>
					<label htmlFor={'comp-' + props.comp.id} className="checkbox" />
				</InputContainer>
				<UnlockOrExportButton
					comp={props.comp}
					exportText=""
					compType="sale"
					width={90}
					height={40}
					configuration={'table'}
				/>
			</Wrap>
		);
	} else {
		const loadingCheckboxId = 'loading-sale-checkbox';

		return (
			<div>
				<input
					id={loadingCheckboxId}
					data-qa-id={loadingCheckboxId}
					type="checkbox"
					className="checkbox"
					checked={false}
					onChange={() => {}}
				/>
				<label className="checkbox" htmlFor={loadingCheckboxId} />
				<div className="button_button button_disabled loading" />
			</div>
		);
	}
};

const Wrap = styled.div`
	align-items: center;
	display: flex;
`;

const InputContainer = styled.div`
	width: 26px;
`;

const PortfolioSale = styled.span`
	color: ${(props) => props.theme.colors.blue.blue400};
	font-weight: bold;
`;

const StyledLink = styled(Link)`
	svg {
		margin-left: 10px;
	}
`;
