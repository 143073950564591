import { UnlockSalesResponse } from 'api';
import saleService from 'services/sale';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';

export const UNLOCK_SALES = 'UNLOCK_SALES' as const;
export const SHARE_A_COMP = 'SHARE_A_COMP';

export function unlockSales(
	ids: number[],
	options?: {
		center?: boolean;
		onSuccess?: (data: UnlockSalesResponse['comps']) => void;
	}
) {
	return {
		type: UNLOCK_SALES,
		meta: {
			updateUser: true,
			ids,
			feedback: {
				className: function () {
					return options?.center ? 'center' : '';
				},
				rejected: () => {
					return 'Error unlocking comps.';
				},
			},
		},
		payload: {
			ids,
			promise: saleService.unlock(ids).then((data) => {
				if (options?.onSuccess) {
					options.onSuccess(data);
				}
				return data;
			}),
		},
	};
}

export function shareAComp(saleId: number, email: string) {
	return {
		type: SHARE_A_COMP,
		meta: {
			feedback: {
				pending: 'Sharing this sale comp with ' + email + '...',
				fulfilled: () => {
					return 'Lease comp successfully shared with ' + email + '!';
				},
				// @ts-expect-error TS7006: Parameter 'rejection' implicit...
				rejected: (rejection) => {
					console.error('Share a comp email error:', rejection.error);
					return 'An error occurred sharing this sale comp with ' + email + '!';
				},
			},
		},
		payload: {
			id: saleId,
			email: email,
			promise: saleService.share(saleId, email),
		},
	};
}

export type SaleAction =
	| ReduxPromiseAction<ReturnType<typeof unlockSales>>
	| ReduxPromiseAction<ReturnType<typeof shareAComp>>;
