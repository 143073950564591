import { Map, MapInteractivity, PinsLayer } from '@compstak/maps';
import { point } from '@turf/helpers';
import React from 'react';
import { TransitScreenProps } from './TransitScreen';
import { MapWrapper } from './UI';

type Props = {
	subjectProperty: TransitScreenProps['propertyData'];
};

export const TransitScreenMap = ({ subjectProperty }: Props) => {
	const points = React.useMemo(() => {
		if (!subjectProperty?.geoPoint) return [];
		const { lon, lat } = subjectProperty.geoPoint;
		return [point([lon, lat], subjectProperty)];
	}, [subjectProperty]);

	return (
		<MapWrapper>
			<Map
				initialViewState={{
					latitude: subjectProperty?.geoPoint?.lat,
					longitude: subjectProperty?.geoPoint?.lon,
					zoom: 14.5,
				}}
				interactivity={MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM}
			>
				<PinsLayer
					data={points}
					getTooltipContent={(f) =>
						f.properties.buildingAddress ??
						f.properties.buildingAddressAndCity ??
						''
					}
					getIconColor={() => 'gray'}
					getSideText={(f) =>
						f.properties.buildingAddress ??
						f.properties.buildingAddressAndCity ??
						''
					}
				/>
			</Map>
		</MapWrapper>
	);
};
