import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

export const useLeaseExpirationBySubmarket = (
	params: UseLeaseExpirationBySubmarketParams,
	options?: UseLeaseExpirationBySubmarketOptions
) => {
	const { filters } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.LEASE_EXPIRATION_BY_SUBMARKET, params],
		queryFn: async () => {
			const response = await API.post<LeaseExpirationBySubmarketResponse>(
				'/api/dashboard/leaseExpirationBySubmarket',
				{
					filters: [
						{ property: 'marketId', value: filters.market.id },
						...filtersToServerJSON(filters).filter(
							(f) => !f.property.includes('marketName')
						),
					],
				}
			);

			return response.data;
		},
		...options,
	});
};

export type UseLeaseExpirationBySubmarketOptions =
	UseQueryOptions<LeaseExpirationBySubmarketResponse>;

type UseLeaseExpirationBySubmarketParams = {
	filters: FiltersObject;
};

type LeaseExpirationBySubmarketResponseItem = {
	submarket: string;
	leaseCount: number;
	sqft: number;
	year: number;
};

type LeaseExpirationBySubmarketResponse =
	LeaseExpirationBySubmarketResponseItem[];
