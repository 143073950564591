import { useMutation } from '@tanstack/react-query';
import { API } from '@compstak/common';
import { FromUnixEpochMS } from 'types';

export type FetchParams = { userId: number; time: FromUnixEpochMS };

const fetchSetModalTimestamp = async ({ userId, time }: FetchParams) => {
	const response = await API.put<void>(
		`/api/users/${userId}/preferences/hideMuFaChartbuilderMessageWindowAt?time=${time}`
	);
	return response.data;
};

const fetchSetBannerTimestamp = async ({ userId, time }: FetchParams) => {
	const response = await API.put<void>(
		`/api/users/${userId}/preferences/hideMuFaChartbuilderMessageBannerAt?time=${time}`
	);
	return response.data;
};

const fetchFilterLossDontShowTimestamp = async ({
	userId,
	time,
}: FetchParams) => {
	const response = await API.put<void>(
		`/api/users/${userId}/preferences/hideMufaChartbuilderFilterLossMessageAt?time=${time}`
	);
	return response.data;
};

export const useSetModalCloseTimestamp = (updateUser: NoArgCallback) =>
	useMutation(fetchSetModalTimestamp, {
		onSuccess: updateUser,
	});

export const useSetBannerCloseTimestamp = (updateUser: NoArgCallback) =>
	useMutation(fetchSetBannerTimestamp, {
		onSuccess: updateUser,
	});

export const useFilterLossDontShowTimestamp = (updateUser: NoArgCallback) =>
	useMutation(fetchFilterLossDontShowTimestamp, {
		onSuccess: updateUser,
	});
