import { Text, View } from '@react-pdf/renderer';
import { MufaKeyMetricsResponse, MufaUnitMixSummaryResponse } from 'api';
import { PdfPage } from '../../../../pdf/PdfPage';
import { pdfStyle } from '../style';
import { PdfPageProps } from '../types';
import { FloorPlansTablePdf } from './FloorPlansTablePdf';
import { KeyMetricsPdf } from './KeyMetricsPdf';
import { SummaryPdf } from './SummaryPdf';

type Props = PdfPageProps & {
	summary: MufaUnitMixSummaryResponse | undefined;
	keyMetrics: MufaKeyMetricsResponse | undefined;
	floorPlans: Record<string, string | number>[] | undefined;
};

export const MultifamilyPage = (props: Props) => {
	const { summary, keyMetrics, floorPlans } = props;

	return (
		<PdfPage
			logo={props.page.logo}
			preparedFor={props.page.preparedFor}
			title={props.page.titles.multifamily}
			user={props.user}
		>
			<Text style={pdfStyle.pageTitle}>Multifamily Overview</Text>

			{summary && <SummaryPdf summary={summary} />}

			{keyMetrics && Object.keys(keyMetrics).length > 0 && (
				<KeyMetricsPdf keyMetrics={keyMetrics} />
			)}

			{floorPlans && floorPlans.length > 0 && (
				<>
					<View break />
					<FloorPlansTablePdf floorPlans={floorPlans} />
				</>
			)}
		</PdfPage>
	);
};
