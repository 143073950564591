import React from 'react';

import CookiesPolicyNotification from 'Components/HeaderNotification/CookiesPolicy';
import { APP_WRAPPER_ID } from 'ui/UI';
import { Outlet } from 'react-router';
import { useAppConfig } from 'Pages/Login/reducers';
import { useAppSelector } from 'util/useAppSelector';

export const LayoutWithoutHeader = () => {
	const appConfig = useAppConfig();
	const headerNotification = useAppSelector(
		(store) => store.headerNotification
	);
	const appClassName = appConfig.isEnterprise ? 'enterprise' : 'exchange';

	return (
		<div id={APP_WRAPPER_ID} className={`${appClassName} application_wrapper`}>
			{' '}
			<CookiesPolicyNotification />
			<div
				className={
					'application_content no-header ' +
					(headerNotification ? 'with-header-notification' : '')
				}
			>
				<Outlet />
			</div>
		</div>
	);
};
