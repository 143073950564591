import React, { useMemo, useState, MouseEvent } from 'react';

import styles from '../../repository.less';

import ThreeDot from '../../../../../ui/svg_icons/three-dot.svg';

import ProjectMenu, { ProjectMenuProps } from './ProjectMenu';
import SortMenu, {
	ProjectSortBy,
	ProjectSortMenuProps,
} from './ProjectSortMenu';

import { NewProjectModal } from '../Modals/NewProjectModal';

import dayjs from 'dayjs';
import { DATE_FORMATS } from 'constants/dateFormats';
import { Project } from 'Pages/Analytics/analytics';
import { showMenu } from 'Singletons/Menu/actions';
import SidebarNavigationButtons from '../../../Components/Buttons/SidebarNavigationButtons';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useModal } from 'providers/ModalProvider';
import { routes } from 'router';

type ChartProjectsSidebarProps = {
	projects: Project[];
	currentProject?: Project;
};

export const ChartProjectsSidebar = ({
	projects,
	currentProject,
}: ChartProjectsSidebarProps) => {
	const [sortBy, setSortBy] = useState<ProjectSortBy>(DEFAULT_PROJECT_SORT);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { openModal } = useModal();

	const goToChartbuilder = () => {
		navigate(routes.analytics.path);
	};

	const addProject = () => {
		openModal({ modalContent: <NewProjectModal /> });
	};

	const showSortMenu = (event: MouseEvent<HTMLSpanElement>) => {
		dispatch(
			showMenu<ProjectSortMenuProps>(
				SortMenu,
				event.currentTarget,
				'below-onleft',
				{
					sortBy,
					setSortBy,
				}
			)
		);
	};

	const { sortByCallback, sortByText } = PROJECT_SORT_METHODS[sortBy];

	return (
		<div className={styles.chartSidebar}>
			<div className={styles.buttonContainer}>
				<span className={styles.sortButton}>
					<span className={styles.sortBy}>Sort by {sortByText}</span>
					<span className={styles.downContainer} onClick={showSortMenu}>
						<div className={styles.down} />
					</span>
				</span>
				<span className={styles.plus} onClick={addProject}>
					<div>+</div>
				</span>
			</div>
			<ProjectItems
				projects={projects}
				currentProject={currentProject}
				sortByCallback={sortByCallback}
			/>
			<SidebarNavigationButtons onClickChartbuilder={goToChartbuilder} />
		</div>
	);
};

type ProjectItemsProps = {
	projects: Project[];
	currentProject?: Project;
	sortByCallback: ProjectSortFunc;
};

const ProjectItems = ({
	projects,
	currentProject,
	sortByCallback,
}: ProjectItemsProps) => {
	const sortedProjects = useMemo(() => {
		return projects.sort(sortByCallback);
	}, [projects, sortByCallback]);

	return (
		<div className={styles.projectList}>
			{sortedProjects.map((project) => {
				return (
					<ProjectListItem
						project={project}
						selected={
							currentProject?.id ? currentProject.id === project.id : false
						}
						key={project.id}
					/>
				);
			})}
		</div>
	);
};

type ProjectSortFunc = (project1: Project, project2: Project) => number;

type ProjectListItemProps = {
	project: Project;
	selected: boolean;
};

const ProjectListItem = ({ project, selected }: ProjectListItemProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const goToProject = (event: MouseEvent<HTMLDivElement>) => {
		if (!event.isDefaultPrevented() && project.id) {
			navigate(routes.analyticsProjectById.toHref({ projectId: project.id }));
		}
	};

	const showSidebarMenu = (event: MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		dispatch(
			showMenu<ProjectMenuProps>(ProjectMenu, event.currentTarget, 'onright', {
				project,
			})
		);
	};

	const selectedClass = selected ? styles.selected : '';
	const threeDotsEl = project.canDelete ? (
		<span onClick={showSidebarMenu} className={styles.threeDots}>
			<ThreeDot width={4} height={16} />
		</span>
	) : null;

	return (
		<div
			className={`${styles.projectListItem} ${selectedClass}`}
			onClick={goToProject}
		>
			<span className={styles.projectInfo}>
				<div className={styles.infoTop}>
					<span className={styles.projectName}>{project.name}</span>
					<span className={styles.chartCount}>
						({project.charts.length} Charts)
					</span>
				</div>
				<div className={styles.projectDateModified}>
					Date Modified:{' '}
					{dayjs(project.lastUpdated).format(DATE_FORMATS['MMM D YYYY'])}
				</div>
			</span>
			{threeDotsEl}
		</div>
	);
};

const sortByDates: ProjectSortFunc = (project1: Project, project2: Project) => {
	return (
		new Date(project2.lastUpdated).getTime() -
		new Date(project1.lastUpdated).getTime()
	);
};

const sortByName: ProjectSortFunc = (project1, project2) => {
	return project1.name.localeCompare(project2.name);
};

const sortByNumberOfCharts: ProjectSortFunc = (project1, project2) => {
	return project2.charts.length - project1.charts.length;
};

export const PROJECT_SORT_METHODS: Record<
	ProjectSortBy,
	{ sortByCallback: ProjectSortFunc; sortByText: string }
> = {
	name: {
		sortByCallback: sortByName,
		sortByText: 'Name',
	},
	numberOfCharts: {
		sortByCallback: sortByNumberOfCharts,
		sortByText: 'No. Of Charts',
	},
	dateModified: {
		sortByCallback: sortByDates,
		sortByText: 'Date Modified',
	},
};

export const DEFAULT_PROJECT_SORT: ProjectSortBy = 'dateModified';
