import {
	getLeaseExpirationPeriodLabel,
	LeaseExpirationDescription,
	useLeaseExpirationState,
} from '../LeaseExpirationAnalytics';
import { MapExportLegend } from './MapExportLegend';

export const LeaseExpirationExportLegend = () => {
	const [state] = useLeaseExpirationState();

	return (
		<MapExportLegend
			title={state.title}
			description={<LeaseExpirationDescription />}
		>
			{getLeaseExpirationPeriodLabel(state.expireInMonths)}
		</MapExportLegend>
	);
};
