import { API } from '@compstak/common';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { z } from 'zod';

const validator = z.object({
	activeLeaseCount: z.number().optional(),
	activeLeaseTotalSqFt: z.number().optional().nullable(),
	avgCurrentRent: z.number().optional().nullable(),
	avgLeaseTerm: z.number().optional().nullable(),
	avgStartingRent: z.number().optional().nullable(),
	avgSqFt: z.number().optional().nullable(),
	tenants: z
		.array(
			z.object({
				name: z.string(),
				activeLeaseTotalSqFt: z.number().optional().nullable(),
			})
		)
		.optional(),
	spaceTypeRents: z
		.array(
			z.object({
				spaceType: z.string(),
				weightedAvgCurrentRent: z.number().optional().nullable(),
			})
		)
		.optional(),
});

export function usePropertyLeasesSummaryQuery(
	propertyId: number,
	enabled = true
) {
	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_LEASES_SUMMARY, propertyId],
		queryFn: async () => {
			const { data } = await API.get(
				`/properties/${propertyId}/leases/summary/total`
			);
			return validator.parse(data);
		},
		refetchOnWindowFocus: false,
		enabled,
	});
}

export type PropertyLeaseSummary = z.infer<typeof validator>;
export type MajorTenant = z.infer<typeof validator>['tenants'];
export type SpaceTypeRents = z.infer<typeof validator>['spaceTypeRents'];
