import { Image } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { PdfSectionBox, PdfSectionDescriptionText } from '../PdfUI';
import { TenantIndustryPdfLegend } from '../TenantIndustryPdfLegend';
import { PdfSectionProps } from '../constants';
import { useLegendData } from './useLegendData';

export const TenantIndustryDistributionPdfSection = ({
	tenantIndustryDistribution: { url, data },
}: PdfSectionProps) => {
	const legendData = useLegendData({ data });

	// adapting to the TenantIndustry type
	const mappedLegendData = useMemo(() => {
		return legendData.map((ti) => ({
			...ti,
			name: ti.industry,
		}));
	}, [legendData]);

	return (
		<PdfSectionBox title="Tenant Industry Distribution">
			{url && <Image src={url} style={{ marginBottom: 6 }} />}
			<TenantIndustryPdfLegend tenantIndustries={mappedLegendData} />
			<PdfSectionDescriptionText>
				Top ten industries based on total square footage in selected market and
				submarket(s).
			</PdfSectionDescriptionText>
		</PdfSectionBox>
	);
};
