import { ComponentProps } from 'react';
import styled from 'styled-components';

export const IconButton = styled.button`
	background-color: transparent;
	border: 0;
	padding: 0;
`;

export const EditPageSectionTitle = styled.div`
	font-size: 1.25rem;
	font-weight: 300;
	line-height: 1;
	border-bottom: 1px solid #000;
	padding-bottom: 0.5rem;
	margin-bottom: 1.25rem;
	display: flex;
	justify-content: space-between;
`;

export const TableContainer = styled.div`
	margin-bottom: 0.5rem;
`;

export const TableHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n50};
	padding: 0.5rem 0;
`;

export const Container = styled.div`
	margin-top: 1.5rem;
`;

export const FormControl = styled.div`
	display: flex;
	align-items: baseline;
	gap: 0.5rem;
`;

export const FormControlLabel = styled.label<{ boldLabel?: boolean }>`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.875rem;
	font-weight: ${({ boldLabel }) => (boldLabel ? '500' : '300')};
	line-height: 1;
	letter-spacing: 0.01em;
	cursor: pointer;
`;

export const Subtext = ({ children, ...props }: ComponentProps<'ul'>) => {
	return (
		<SubtextList {...props}>
			<SubtextItem>{children}</SubtextItem>
		</SubtextList>
	);
};

const SubtextList = styled.ul`
	list-style-type: disc;
	padding-left: 1rem;
	padding-top: 0.5rem;
`;

const SubtextItem = styled.li`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.75rem;
	font-weight: 300;
	line-height: normal;
	letter-spacing: 0.01em;
`;
