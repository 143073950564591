import { useAnalyticsMarketRent } from '@compstak/fe-react-api-layer';
import { defaultContext } from '@tanstack/react-query';
import {
	DEFAULT_SPACE_TYPE_ID,
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsViewMode,
	PROPERTY_TYPE_MAP,
	useMarketRentAnalyticsState,
} from '../../../../maps';
import { useMarketRentState } from '../../MarketRentProvider';

export const usePortfolioMarketRentData = () => {
	const [marketRentState] = useMarketRentState();
	const [state] = useMarketRentAnalyticsState();

	const { selectedMarketId, selectedPropertyTypeId } = marketRentState;

	return useAnalyticsMarketRent(
		{
			metric: state.rentType,
			spaceType:
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				PROPERTY_TYPE_MAP[selectedPropertyTypeId ?? DEFAULT_SPACE_TYPE_ID],
			monthly: state.period === MarketRentAnalyticsPeriod.MONTH,
			submarket: state.viewMode === MarketRentAnalyticsViewMode.SUBMARKET,
			body: {
				filters: [{ property: 'marketId', value: selectedMarketId }],
			},
		},
		{
			enabled: selectedMarketId != null && selectedPropertyTypeId != null,
			context: defaultContext,
		}
	);
};
