import { usePropertyCompsetQuery } from 'api';
import { useMemo } from 'react';
import { Link } from 'router';
import { PropertyComp } from 'types';
import { createSearchLeasesUrl } from '../../../utils';
import {
	CompPopupActionButton,
	PopupActionButton,
	StyledArrowRightIcon,
} from './UI';
import { useMarketByNameOrId } from 'Pages/Login/reducers';
import { Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import IconRelatedComps from '../../../ui/svg_icons/related_comps.svg';

type Props = {
	property: PropertyComp;
	closePopup: () => void;
};

export const GenerateCompSetButton = ({ property, closePopup }: Props) => {
	const { data, isFetching } = usePropertyCompsetQuery({ id: property.id });
	const addresses = data?.propertyCompSet.map(
		(comp) => comp.property.buildingAddressAndCity
	);

	const market = useMarketByNameOrId(property.marketId);

	const url = useMemo(() => {
		return createSearchLeasesUrl({
			market,
			address: addresses,
		});
	}, [market, addresses]);

	const { portfolioRevampFF } = useFeatureFlags();

	return portfolioRevampFF ? (
		<PopupActionButton
			href={url}
			onClick={closePopup}
			icon={
				isFetching ? (
					<SpinnerContainer>
						<Spinner isCentered size="s" />
					</SpinnerContainer>
				) : (
					<IconRelatedComps />
				)
			}
			variant="secondary"
		>
			Generate related comps
			<StyledArrowRightIcon />
		</PopupActionButton>
	) : (
		<Link to={url} onClick={closePopup}>
			<CompPopupActionButton
				data-qa-id="generate-related-comps-btn"
				className="blue single-child"
				disabled={isFetching}
			>
				{isFetching && (
					<SpinnerContainer>
						<Spinner size="s" />
					</SpinnerContainer>
				)}
				Generate Related Comps
			</CompPopupActionButton>
		</Link>
	);
};

const SpinnerContainer = styled.span`
	margin-right: 10px;
`;
