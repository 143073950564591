import LogoPicker from 'Pages/Export/Components/LogoPicker';
import { useReferenceData, useUser } from 'Pages/Login/reducers';
import React from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import Editable from 'ui/Components/Editable';

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export const DashboardPreviewHeader = (props) => {
	const currentUser = useUser();
	const [filters] = useFilters('exchange-dashboard');
	const referenceData = useReferenceData();
	const { userEdits, setUserEdits } = props;
	const renderPropertyTypesCopy = () => {
		const allSelected =
			filters.buildingPropertyTypeId == null ||
			filters.buildingPropertyTypeId.length ===
				referenceData.filterablePropertyTypes.length;

		return allSelected
			? 'All'
			: filters.buildingPropertyTypeId
					?.map((id) => {
						if (id) {
							const propertyType = referenceData?.propertyTypes.find(
								(type) => type.id === id
							);
							return propertyType?.name;
						}
						return null;
					})
					.filter(Boolean)
					.join(', ') ?? 'All';
	};
	return (
		<>
			<Header>
				<span>CompStak Export</span>
				<LogoPicker
					// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the full error message
					attribute="companyLogo"
					placeholder="+ Add your logo"
					logo={userEdits.companyLogo}
					// @ts-expect-error TS7006: Parameter '_' implicitly has a...
					setLogo={(_, logo) =>
						// @ts-expect-error TS7006: Parameter 'oldEdits' implicitl...
						setUserEdits((oldEdits) => ({
							...oldEdits,
							companyLogo: logo,
						}))
					}
				/>
			</Header>
			<FieldsContainer>
				<FieldsList>
					<FieldItem>
						<span>Market</span>
						<Data>{filters.market.displayName}</Data>
					</FieldItem>
					<FieldItem>
						<span>Submarket</span>

						<Data>
							{props.submarkets?.length === (filters.submarkets?.length ?? 0)
								? 'All'
								: filters.submarkets?.map(({ name }) => name).join(', ') ??
								  'All'}
						</Data>
					</FieldItem>
					<FieldItem>
						<span>Property Types*</span>
						<Data>{renderPropertyTypesCopy()}</Data>
					</FieldItem>
					<DisclaimerText>*Except where noted</DisclaimerText>
				</FieldsList>
				<FieldsList>
					<FieldItem>
						<span>Created On</span>
						<Data>{userEdits.createdOn}</Data>
					</FieldItem>
					<FieldItem>
						<span>Prepared For</span>
						<Data>
							<span className="hideable">
								{userEdits.clientLogo && (
									<React.Fragment>
										<LogoPicker
											// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the fFieldsThingl error message
											attribute="clientLogo"
											placeholder="+ Add your client's logo"
											logo={userEdits.clientLogo}
											// @ts-expect-error TS7006: Parameter '_' implicitly has a...
											setLogo={(_, logo) =>
												// @ts-expect-error TS7006: Parameter 'oldEdits' implicitl...
												setUserEdits((oldEdits) => ({
													...oldEdits,
													clientLogo: logo,
												}))
											}
										/>
									</React.Fragment>
								)}

								<StyledEditable
									className="inline"
									multiline={true}
									name="preparedFor"
									// @ts-expect-error TS7006: Parameter '_' implicitly has a...
									updateValue={(_, value) => {
										const preparedFor = value;
										// @ts-expect-error TS7006: Parameter 'oldEdits' implicitl...
										setUserEdits((oldEdits) => ({
											...oldEdits,
											preparedFor,
										}));
									}}
									placeholder="e.g. client's name"
									useEmptyClass={true}
								>
									{userEdits.preparedFor}
								</StyledEditable>

								{!userEdits.clientLogo && (
									<LogoPicker
										// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the full error message
										attribute="clientLogo"
										placeholder="+ Add your client's logo"
										logo={userEdits.clientLogo}
										// @ts-expect-error TS7006: Parameter '_' implicitly has a...
										setLogo={(_, logo) =>
											// @ts-expect-error TS7006: Parameter 'oldEdits' implicitl...
											setUserEdits((oldEdits) => ({
												...oldEdits,
												clientLogo: logo,
											}))
										}
										className="dick"
									/>
								)}
							</span>
						</Data>
					</FieldItem>
					<FieldItem>
						<span>Prepared By</span>
						<Data>
							<div>
								{currentUser?.firstName ? `${currentUser.firstName} ` : ''}
								{currentUser?.lastName}
							</div>
							<div>{currentUser?.email}</div>
						</Data>
					</FieldItem>
					<FieldItem>
						<span>Notes</span>
						<Data>
							<span className="hideable">
								<StyledEditable
									className="inline"
									multiline={true}
									name="notes"
									// @ts-expect-error TS7006: Parameter '_' implicitly has a...
									updateValue={(_, value) => {
										// @ts-expect-error TS7006: Parameter 'oldEdits' implicitl...
										setUserEdits((oldEdits) => ({
											...oldEdits,
											notes: value,
										}));
									}}
									placeholder="Enter your comments..."
									useEmptyClass={true}
								>
									{userEdits.notes}
								</StyledEditable>
							</span>
						</Data>
					</FieldItem>
				</FieldsList>
			</FieldsContainer>
		</>
	);
};

const Header = styled.div`
	border-bottom: 1px solid #000;
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	margin: 0 1rem;
`;

const FieldsContainer = styled.div`
	border-bottom: 1px solid black;
	display: flex;
	gap: 1rem;
	margin: 0 1rem;
`;

const FieldsList = styled.ul`
	flex: 1;
`;

const FieldItem = styled.li`
	border-bottom: 1px solid #d5d8df;
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;

const StyledEditable = styled(Editable)`
	width: 100%;
	margin: 0;
	text-align: left;
`;

const Data = styled.div`
	color: #50576d;
	font-size: 12px;
	line-height: 20px;
	padding: 4px 0;
	width: 50%;
	text-align: left;
`;

const DisclaimerText = styled.div`
	font-size: 11px;
	text-align: left;
`;
