import React from 'react';
import { Market } from '@compstak/common';
import { PermissionsState } from 'Pages/Login/reducers';
import { Transition } from './MarketList';
import ArrowBottom from 'ui/svg_icons/arrow_bottom.svg';
import { MarketListItems } from './MarketListItems';

type MarketListFullViewProps = {
	changeMarket: (market: Market) => void;
	isEnterprise: boolean;
	expandedState?: string;
	permissions: PermissionsState;
	marketsGroupedByState: Record<string, Market[]>;
	toggleStateMarkets: (state: string) => void;
	type: 'sales' | 'lease';
};

export const MarketListFullView = ({
	changeMarket,
	isEnterprise,
	expandedState,
	permissions,
	marketsGroupedByState,
	toggleStateMarkets,
	type,
}: MarketListFullViewProps) => {
	return (
		<>
			{Object.entries(marketsGroupedByState).map(([state, markets]) => {
				const isExpanded = expandedState === state;

				return (
					<Transition key={state}>
						<div className="states-and-markets">
							<div
								onClick={() => toggleStateMarkets(state)}
								className={`states ${isExpanded ? 'rotate-arrow' : ''}`}
							>
								<div>{state}</div>
								<ArrowBottom height="9px" />
							</div>
							<ul className={isExpanded ? 'markets-visible' : 'markets-hidden'}>
								<MarketListItems
									isEnterprise={isEnterprise}
									markets={markets}
									changeMarket={changeMarket}
									permissions={permissions}
									type={type}
								/>
							</ul>
						</div>
					</Transition>
				);
			})}
		</>
	);
};
