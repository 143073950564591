import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { CheckExportStatusResponse } from 'api';
import { useModal } from 'providers/ModalProvider';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ExportPdfData } from './useExportPdfData';

type Props = {
	numberOfSelectedLeases: number;
	exportStatusLeases: CheckExportStatusResponse | undefined;
	numberOfSelectedSales: number;
	exportStatusSales: CheckExportStatusResponse | undefined;
	exportStatusProperties: CheckExportStatusResponse | undefined;
	data: ExportPdfData;
};

export const ExportLimitExceededDialog = ({
	numberOfSelectedLeases,
	exportStatusLeases,
	numberOfSelectedSales,
	exportStatusSales,
	exportStatusProperties,
	data,
}: Props) => {
	const { closeModal } = useModal();

	const finalExportStatusLeases = useExportStatus(
		exportStatusLeases,
		data.property.marketId
	);

	const finalExportStatusSales = useExportStatus(
		exportStatusSales,
		data.property.marketId
	);

	const finalExportStatusProperties = useExportStatus(
		exportStatusProperties,
		data.property.marketId
	);

	return (
		<>
			<ModalTitle>Export Limit Exceeded</ModalTitle>
			<ModalParagraph>
				You have exceeded your export limit. Please modify your selection for
				Lease Comps or Sale Comps.
				{finalExportStatusLeases && (
					<div>
						<div>Leases:</div>
						<List>
							<li>
								Remaining Exports for Leases within the market:{' '}
								{`${finalExportStatusLeases?.availableWithFinancial}/${finalExportStatusLeases?.limit}`}
							</li>
							<li>Lease Comps selected for Export: {numberOfSelectedLeases}</li>
						</List>
					</div>
				)}
				{finalExportStatusSales && (
					<div>
						<div>Sales:</div>
						<List>
							<li>
								Remaining Exports for Sales within the market:{' '}
								{`${finalExportStatusSales?.availableWithFinancial}/${finalExportStatusSales?.limit}`}
							</li>
							<li>Sales Comps selected for Export: {numberOfSelectedSales}</li>
						</List>
					</div>
				)}
				<div>
					<div>Properties:</div>
					<List>
						<li>
							Remaining Exports for Properties within the market:{' '}
							{`${finalExportStatusProperties?.availableWithFinancial}/${finalExportStatusProperties?.limit}`}
						</li>
					</List>
				</div>
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="primary" onClick={closeModal}>
					Modify Selection
				</ModalButton>
			</ModalButtons>
		</>
	);
};

const useExportStatus = (
	exportStatus: CheckExportStatusResponse | undefined,
	marketId: number
) => {
	const propertyMarketExportStatusLeases = useMemo(() => {
		return exportStatus?.perMarket?.find((m) => m.marketId === marketId);
	}, [exportStatus, marketId]);

	return propertyMarketExportStatusLeases ?? exportStatus;
};

const List = styled.ul`
	list-style-type: disc;
	padding-left: 1.5rem;
`;
