import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { StringTypeahead } from 'Components/Filters/Base/StringTypeahead';
import { useState } from 'react';
import styled from 'styled-components';

const BROKERAGE_ATTRIBUTES = {
	landlordRealtyCompanies: 'Landlord Rep',
	tenantRealtyCompanies: 'Tenant Rep',
} as const;

export const BrokerageFilter = () => {
	const { filters } = useFilterFieldContext();
	const [attribute, setAttribute] = useState(
		() =>
			Object.typedKeys(BROKERAGE_ATTRIBUTES).find(
				(brokerageAttr) => filters[brokerageAttr]
			) ?? 'landlordRealtyCompanies'
	);

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Container>
					<StringTypeahead
						attribute={attribute}
						compType={props.compType}
						filter={props.filter}
						filters={props.filters}
						onFilterChange={props.onFilterChange}
						setError={props.setError}
						touch={props.touch}
					/>
					<div>
						{Object.typedKeys(BROKERAGE_ATTRIBUTES).map((attr, index) => {
							const attributeIds =
								Object.typedKeys(BROKERAGE_ATTRIBUTES).join();
							const id = 'filter-selector-' + attributeIds + index;
							const attrTitle = BROKERAGE_ATTRIBUTES[attr];

							return (
								<div key={attr}>
									<input
										type="radio"
										className="radio"
										name={id}
										id={id}
										data-qa-id={id}
										value={attr}
										checked={attr === attribute}
										onChange={(e) => {
											const newAttribute = e.target
												.value as keyof typeof BROKERAGE_ATTRIBUTES;
											setAttribute(newAttribute);
											props.onFilterChange({
												[attribute]: null,
												[newAttribute]: props.filters[attribute],
											});
											props.touch();
										}}
									/>
									<label htmlFor={id}>{attrTitle}</label>
								</div>
							);
						})}
					</div>
				</Container>
			)}
		</FilterBase>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
