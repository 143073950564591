import { Button, TooltipV2 } from '@compstak/ui-kit';
import { useUpdatePortfolioMutation } from 'api';
import { formatInteger } from 'format';
import { SelectionState } from 'utils';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';

type Props = {
	selectionState: SelectionState;
};

export const RemovePropertiesButton = ({ selectionState }: Props) => {
	const { portfolio } = usePortfolioFilters();
	const { selection, resetSelection } = selectionState;
	const { mutate: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation({
			onSuccess: () => {
				resetSelection();
			},
		});

	return (
		<TooltipV2
			content={
				selection.length === 0 ? 'Select properties to remove' : undefined
			}
		>
			<Button
				size="l"
				isLoading={isLoadingUpdatePortfolio}
				disabled={selection.length === 0 || isLoadingUpdatePortfolio}
				onClick={() => {
					updatePortfolio({
						id: portfolio.id,
						body: {
							id: portfolio.id,
							name: portfolio.name,
							description: portfolio.description,
							removedPropertyIds: selection,
						},
					});
				}}
			>
				&mdash; Remove from portfolio ({formatInteger(selection.length)})
			</Button>
		</TooltipV2>
	);
};
