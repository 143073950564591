import React from 'react';
import Menu from 'Singletons/Menu';

import styles from '../styles/menu.less';
import popupStyles from 'Components/Popup/popup.less';
import { Chart, DataSet } from 'Pages/Analytics/analytics';
import styled, { css } from 'styled-components';
import { MAX_DATASETS_ALLOWED } from '../../chartBuilderConstants';
import { useDispatch } from 'react-redux';
import { addDataSet } from '../../actions';
import { hideMenu } from 'Singletons/Menu/actions';
import { useModal } from 'providers/ModalProvider';
import { RemoveDataSetModal } from '../Modals/DataSets/RemoveDataSetModal';
import { RenameDataSetModal } from '../Modals/DataSets/RenameDataSetModal';
import { useMarkets } from 'hooks/useMarkets';

export type DatasetMenuProps = {
	chartDraft: Chart;
	dataSet: DataSet;
	dataSets: DataSet[];
};

const DatasetMenu = (props: DatasetMenuProps) => {
	const markets = useMarkets();
	const { openModal } = useModal();

	const dispatch = useDispatch();

	const duplicate = () => {
		const newName = `${props.dataSet.name} copy`;
		dispatch(
			addDataSet(
				props.chartDraft,
				newName,
				props.dataSet.filters,
				markets,
				props.dataSet.series,
				{
					dataSetType: props.dataSet.type,
				}
			)
		);
		dispatch(hideMenu('dataset-menu'));
	};

	const rename = () => {
		dispatch(hideMenu('dataset-menu'));
		openModal({
			modalContent: (
				<RenameDataSetModal chart={props.chartDraft} dataSet={props.dataSet} />
			),
		});
	};

	const remove = () => {
		dispatch(hideMenu('dataset-menu'));
		openModal({
			modalContent: (
				<RemoveDataSetModal chart={props.chartDraft} dataSet={props.dataSet} />
			),
		});
	};

	const tooltipMessage = `Limit: ${MAX_DATASETS_ALLOWED} data sets`;
	const hasReachedMaximumDataSets =
		props.dataSets.length === MAX_DATASETS_ALLOWED;

	return (
		<ul className={styles.menuContent}>
			<LiStyled
				data-qa-id="duplicate-dataset"
				onClick={duplicate}
				hasReachedMaximumDataSets={hasReachedMaximumDataSets}
				data-tooltip={hasReachedMaximumDataSets ? tooltipMessage : ''}
			>
				Duplicate
			</LiStyled>
			<li data-qa-id="rename-dataset" onClick={rename}>
				Rename
			</li>
			<li data-qa-id="remove-dataset" onClick={remove}>
				Delete
			</li>
		</ul>
	);
};

Menu.addComponent('dataset-menu', DatasetMenu, {
	className: popupStyles.compactWhite,
});

const LiStyled = styled.li<{ hasReachedMaximumDataSets: boolean }>`
	${({ hasReachedMaximumDataSets, theme }) =>
		hasReachedMaximumDataSets &&
		css`
			background-color: ${theme.colors.gray.gray100};
			color: ${theme.colors.gray.gray90};
			-webkit-user-select: none; /* Safari */
			-ms-user-select: none; /* IE 10 and IE 11 */
			user-select: none; /* Standard syntax */

			&&:hover {
				cursor: not-allowed;
				background-color: ${theme.colors.gray.gray100};
			}

			&:active {
				pointer-events: none;
			}
		`}
`;
