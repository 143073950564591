'use strict';

const errorMessages = {
	noUsernameOrPassword:
		'Please provide a login or email address and a password.',
	noUsername: 'Please provide a login or email address.',
	noPassword: 'Please provide a password.',
	passwordMismatch: 'Passwords don’t match.',
};

export default errorMessages;
