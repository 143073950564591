import { getPinLetter, MapImage } from '@compstak/maps';
import {
	capitalizeFirstLetter,
	defaultTheme,
	typography,
	Table,
} from '@compstak/ui-kit';
import { point } from '@turf/helpers';
import { useMemo } from 'react';
import styled from 'styled-components';
import { SalesComp } from 'types';
import pluralizeCompType from 'util/pluralizeCompType';
import { columns } from './columns';

type PortfolioPropertyRailProps = {
	data: SalesComp;
};

const LETTER_PIN_LIMIT = 26;
const MAXIMUM_PINS_ON_MAP = 50;

export const PortfolioPropertyRail = ({ data }: PortfolioPropertyRailProps) => {
	const propertyNumbers = data.portfolio.length;

	const propertyPoints = useMemo(() => {
		return data.portfolio.slice(0, MAXIMUM_PINS_ON_MAP).map((p) => {
			const { lat, lon } = p.geoPoint;
			return point([lon, lat], p);
		});
	}, [data.portfolio]);

	const propertyRows = useMemo(
		() =>
			[...data.portfolio]
				.sort((a, b) => b.buildingSize - a.buildingSize)
				.map((portfolio, index) => ({
					propertyId: portfolio.propertyId,
					buildingAddress: portfolio.buildingAddress,
					city: portfolio.city,
					state: portfolio.state,
					buildingSize: portfolio.buildingSize,
					letter: index < LETTER_PIN_LIMIT ? getPinLetter(index) : undefined,
				})),
		[data.portfolio]
	);

	return (
		<PortfolioPropertyRailContainer>
			<PropertyCountHeader>
				{propertyNumbers}{' '}
				{propertyNumbers > 1
					? pluralizeCompType('property', true)
					: capitalizeFirstLetter('property')}
			</PropertyCountHeader>
			<MapImage
				width={450}
				height={390}
				position={{
					type: 'bounds',
					points: propertyPoints,
					padding: '10',
				}}
				pinPoints={propertyPoints}
				getPinLabel={(_, i) =>
					i < LETTER_PIN_LIMIT ? getPinLetter(i).toLowerCase() : undefined
				}
				getPinColor={() => defaultTheme.colors.blue.blue700}
			/>
			<TableContainer>
				<Table columns={columns} rows={propertyRows} id={LeftColumnTableId} />
			</TableContainer>
		</PortfolioPropertyRailContainer>
	);
};

const LeftColumnTableId = 'LeftColumnTableId';

const PropertyCountHeader = styled.h1`
	font-family: ${typography.fontFamily.gotham};
	font-size: 32px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 30px;
`;

const TableContainer = styled.div`
	margin-top: 20px;
`;

const PortfolioPropertyRailContainer = styled.div`
	min-height: 720px;
`;
