import React, {
	ChangeEventHandler,
	PropsWithChildren,
	useEffect,
	useRef,
} from 'react';

import ErrorIcon from '../../../../ui/svg_icons/error.svg';
import { useSearch } from 'Pages/Search/searchReducer';
import { focusFilterSection } from 'Pages/Search/Map/actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FiltersTypesKeys } from 'models/filters/types';

export type FilterErrors =
	| {
			[error: string]: string | null;
	  }
	| string
	| null;

type Props = PropsWithChildren<{
	id: FiltersTypesKeys;
	errors?: FilterErrors;
	toggleFilter: ChangeEventHandler<HTMLInputElement>;
	selected?: boolean;
	label: string;
	isHidden?: boolean;
	filterIsSet: boolean;
	_inputId?: string;
}>;

const FilterContainer = ({
	children,
	errors,
	id,
	isHidden,
	label,
	selected,
	toggleFilter,
	filterIsSet,
	_inputId,
}: Props) => {
	const { focusFilterSectionId } = useSearch();
	const dispatch = useDispatch();

	const sectionRef = useRef<HTMLLIElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (
			focusFilterSectionId === id &&
			sectionRef?.current != null &&
			inputRef?.current != null
		) {
			sectionRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});

			if (!selected) {
				inputRef.current.click();
			}
		}
		return () => {
			if (focusFilterSectionId != null) {
				setTimeout(() => dispatch(focusFilterSection(null)), 1000);
			}
		};
	}, [dispatch, focusFilterSectionId, inputRef, id, selected, sectionRef]);

	const errorFeedback = () => {
		if (!errors) {
			return [];
		}

		const errorsObj =
			typeof errors === 'string' ? { [errors]: errors } : errors;

		return Object.keys(errorsObj)
			.filter((key) => errorsObj[key])
			.map((key) => (
				<div className="filter_error_message" key={key}>
					<div className="error_circle">
						<ErrorIcon />
					</div>
					{errorsObj[key]!.charAt(0).toUpperCase() + errorsObj[key]!.substr(1)}
				</div>
			));
	};

	const classNames = [
		'search_filter',
		selected ? 'filter_selected' : '',
		sectionRef?.current != null &&
		inputRef?.current != null &&
		focusFilterSectionId === id
			? 'glow'
			: '',
	].join(' ');

	const filterId = `filter-${id}`;
	const inputId = _inputId ? `${_inputId}-${filterId}` : filterId;

	return (
		<FilterContainerListItem
			className={classNames}
			data-filter-container-for={id}
			ref={sectionRef}
			isHidden={isHidden}
			data-filter-is-visible={!isHidden}
			data-filter-is-set={filterIsSet}
		>
			<div className="filter_header">
				<input
					type="checkbox"
					className="checkbox"
					checked={selected}
					onChange={toggleFilter}
					data-qa-id={inputId}
					id={inputId}
					ref={inputRef}
				/>
				<label htmlFor={inputId} className="filter_title_text">
					<span>{label}</span>
				</label>
			</div>
			{selected ? (
				<div className="search_filter_controls">
					<div className="filter_inner_content">{children}</div>
					{errorFeedback()}
				</div>
			) : (
				<span />
			)}
		</FilterContainerListItem>
	);
};

export default FilterContainer;

const FilterContainerListItem = styled.li<{ isHidden?: boolean }>`
	${({ isHidden }) => isHidden && 'display: none;'}
`;
