import React from 'react';

import Add from '../../../../../ui/svg_icons/add.svg';

import withModal from 'Singletons/Modal/withModal';
import withBuilder from 'Pages/Analytics/Builder/withChartBuilder';

import styles from '../../repository.less';
import { Chart } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import { ClearAnalyticsModal } from 'Pages/Analytics/Builder/Components/Modals/ClearAnalyticsModal';

type Props = {
	chartDraft: Chart;
};

const EmptyFolder: React.FC<Props> = (props) => {
	const { openModal } = useModal();
	const showClearModal = () => {
		if (props.chartDraft && props.chartDraft.dataSets.length) {
			setTimeout(() =>
				openModal({
					modalContent: <ClearAnalyticsModal chartDraft={props.chartDraft} />,
				})
			);
		}
	};

	return (
		<a
			href="/analytics"
			onClick={showClearModal}
			className={styles.emptyFolder}
		>
			<Add width={125} height={125} />
			<div className={styles.text}>Click to create a new chart</div>
		</a>
	);
};

export default withBuilder(withModal(EmptyFolder));
