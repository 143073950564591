export enum PropertySections {
	OVERVIEW = 'overview',
	UNIT_MIX = 'unit-mix',
	SUBMARKET = 'submarket',
	MUFA_SUBMARKET = 'mufaSubmarket',
	COMPS = 'comps',
	COMP_SET = 'comp-set',
	TRANSIT_SCREEN = 'transit-screen',
	STACKING_PLAN = 'stacking-plan',
}

export type DisplaySectionsMap = Record<PropertySections, boolean>;

export enum ChartTypes {
	LINE_CHART = 'lineChart',
	BAR_CHART = 'barChart',
}

export type SuggestedSpan = {
	from: string;
	to: string;
};
