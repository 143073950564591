import { Spinner } from '@compstak/ui-kit';
import { useUserW9Query } from 'api';
import { useBalanceAndRedemptions, useVendorsQuery } from './RewardQueries';
import { RewardsInfo } from './RewardsInfo';
import { ErrorMsg, SpinnerWrapper } from './styles';

export function RewardsPage() {
	const {
		data: vendors,
		isLoading: areVendorsLoading,
		error: vendorsError,
	} = useVendorsQuery();
	const {
		balanceData,
		balanceLoading,
		balanceFetchError,
		redemptions,
		redemptionsLoading,
		redemptionsFetchError,
	} = useBalanceAndRedemptions();
	const {
		data: userW9,
		isLoading: isUserW9Loading,
		isError: isUserW9Error,
	} = useUserW9Query();

	if (
		vendorsError ||
		redemptionsFetchError ||
		balanceFetchError ||
		isUserW9Error
	) {
		return <ErrorMsg>Error fetching rewards data.</ErrorMsg>;
	}

	if (
		areVendorsLoading ||
		balanceLoading ||
		redemptionsLoading ||
		!vendors ||
		!balanceData ||
		!redemptions ||
		userW9 == null ||
		isUserW9Loading
	) {
		return (
			<SpinnerWrapper justifyContent="center">
				<Spinner />
			</SpinnerWrapper>
		);
	}

	return (
		<RewardsInfo
			vendors={vendors}
			balanceInDollars={balanceData.remainingCents / 100}
			redemptionsInfo={redemptions}
			userW9={userW9}
		/>
	);
}
