// MuFa is not a space type on the Backend, it's a DataSet type, but for users
// we show them MuFa in the UI as that was the decision from the product team.
import { SpaceTypeSelectListItem } from '@compstak/ui-kit';
import { PROPERTY_TYPE_ID_TO_NAME, SPACE_TYPE_ID_TO_NAME } from 'api';

export const MUFA_OPTION: SpaceTypeSelectListItem = {
	value: 'multifamily',
	title: PROPERTY_TYPE_ID_TO_NAME[2],
	inputType: 'radio',
	parentItem: 'multifamily',
	isWithProviderLogo: true,
};

export const SPACE_TYPE_COMMERCIAL_ITEMS: SpaceTypeSelectListItem[] = [
	{
		value: 'all',
		title: 'All',
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
	{
		value: 'office',
		title: SPACE_TYPE_ID_TO_NAME[1],
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
	{
		value: 'retail',
		title: SPACE_TYPE_ID_TO_NAME[2],
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
	{
		value: 'industrial',
		title: SPACE_TYPE_ID_TO_NAME[6],
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
	{
		value: 'flex/r&d',
		title: SPACE_TYPE_ID_TO_NAME[7],
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
	{
		value: 'land',
		title: SPACE_TYPE_ID_TO_NAME[8],
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
	{
		value: 'other',
		title: SPACE_TYPE_ID_TO_NAME[13],
		inputType: 'checkbox',
		parentItem: 'commercial',
	},
];

export const SPACE_TYPE_MUFA_ITEMS: SpaceTypeSelectListItem[] = [
	{
		value: 'commercial',
		title: 'Commercial',
		inputType: 'radio',
		parentItem: 'commercial',
	},
	...SPACE_TYPE_COMMERCIAL_ITEMS,
	MUFA_OPTION,
];
