import { UnlockLeasesResponse } from 'api';
import leaseService from 'services/lease';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';

export const UNLOCK_LEASES = 'UNLOCK_LEASES' as const;
export const SHARE_A_COMP = 'SHARE_A_COMP';

export function unlockLeases(
	ids: number[],
	options?: {
		center?: boolean;
		onSuccess?: (data: UnlockLeasesResponse['comps']) => void;
	}
) {
	return {
		type: UNLOCK_LEASES,
		meta: {
			updateUser: true,
			ids,
			feedback: {
				className: function () {
					return options?.center ? 'center' : '';
				},
				rejected: () => {
					return 'Error unlocking comps.';
				},
			},
		},
		payload: {
			ids,
			promise: leaseService.unlock(ids).then((data) => {
				if (options?.onSuccess) {
					options.onSuccess(data);
				}
				return data;
			}),
		},
	};
}

export function shareAComp(leaseId: number, email: string) {
	return {
		type: SHARE_A_COMP,
		meta: {
			feedback: {
				pending: 'Sharing this lease comp with ' + email + '...',
				fulfilled: () => {
					return 'Lease comp successfully shared with ' + email + '!';
				},
				// @ts-expect-error TS7006: Parameter 'rejection' implicit...
				rejected: (rejection) => {
					console.error('Share a comp email error:', rejection.error);
					return (
						'An error occurred sharing this lease comp with ' + email + '!'
					);
				},
			},
		},
		payload: {
			id: leaseId,
			email: email,
			promise: leaseService.share(leaseId, email),
		},
	};
}

export type LeaseAction =
	| ReduxPromiseAction<ReturnType<typeof unlockLeases>>
	| ReduxPromiseAction<ReturnType<typeof shareAComp>>;
