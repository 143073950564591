import { useViewState } from '@compstak/maps';
import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import {
	MarketRentAnalyticsLayers,
	MarketRentAnalyticsTooltip,
	useMarketRentAdjustedPercentiles,
	useMarketRentAnalyticsState,
} from 'maps';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useFilters } from 'reducers/filtersReducer';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';
import { MarketRentAnalyticsPopup } from './MarketRentAnalyticsPopup';
import { useMarketRentAnalyticsData } from './useMarketRentAnalyticsData';

const ID = 'MarketRentAnalyticsLayers';

export const MarketRentAnalytics = () => {
	const [filters] = useFilters();
	const [marketRentState] = useMarketRentAnalyticsState();
	const { data: marketRentData } = useMarketRentAnalyticsData();

	const adjustedPercentiles = useMarketRentAdjustedPercentiles({
		percentiles: marketRentData?.percentiles ?? [],
	});

	const market = getFiltersMarkets(filters)[0];

	const { data: submarketData } = useSubmarketPolygons(market);

	const [viewState] = useViewState();

	return (
		<MarketRentAnalyticsLayers
			id={ID}
			data={marketRentData}
			adjustedPercentiles={adjustedPercentiles}
			submarketData={submarketData}
			polygonsLayerProps={{
				getTooltipContent: (f) => (
					<MarketRentAnalyticsTooltip
						title={f.properties.submarket}
						marketRent={f.properties.marketRent}
						period={marketRentState.period}
					/>
				),
				getPopupContent: (f, closePopup) => (
					<MarketRentAnalyticsPopup
						submarketName={f.properties.submarket}
						marketRent={f.properties.marketRent}
						period={marketRentState.period}
						propertyCount={f.properties.propertyCount}
						closePopup={closePopup}
					/>
				),
				getStaticTooltipContent:
					viewState.zoom > 12 ? (f) => f.properties.submarket : undefined,
			}}
			hexagonLayerProps={{
				getTooltipContent: (f) => (
					<MarketRentAnalyticsTooltip
						marketRent={f.properties.marketRent}
						period={marketRentState.period}
					/>
				),
				getPopupContent: (f, closePopup) => (
					<MarketRentAnalyticsPopup
						hexagonId={f.properties.h3}
						marketRent={f.properties.marketRent}
						period={marketRentState.period}
						propertyCount={f.properties.propertyCount}
						closePopup={closePopup}
					/>
				),
			}}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
