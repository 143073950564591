import { PropertyComp } from 'types';
import { BuildingStats } from './BuildingStats';
import { CompPopupTitle } from './CompPopupTitle';
import { PopupCloseButton } from './PopupCloseButton';
import { CompPopupSubtitle, PopupHeader } from './UI';
import { getIsCompPopupTitleBuildingName } from './utils';
type Props = {
	property: PropertyComp;
	closePopup: () => void;
};

export const PropertyPopupHeader = ({ property, closePopup }: Props) => {
	const shouldShowSubtitle = getIsCompPopupTitleBuildingName(property);

	return (
		<PopupHeader>
			<div>
				<CompPopupTitle property={property} />
				{shouldShowSubtitle && (
					<CompPopupSubtitle isDarkGrey>
						{property.buildingAddress}
					</CompPopupSubtitle>
				)}
				<CompPopupSubtitle isDarkGrey>
					{property.city}, {property.state} {property.zip}
				</CompPopupSubtitle>
				<BuildingStats property={property} />
			</div>
			<PopupCloseButton onClick={closePopup} />
		</PopupHeader>
	);
};
