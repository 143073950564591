import { useCallback, useEffect, useRef, useState } from 'react';
import { useLayoutContext } from './LayoutProvider';

type Props = {
	leftX: number;
	rightX: number;
	scrollSpeed: number;
};

export const useAutoScroll = ({ leftX, rightX, scrollSpeed }: Props) => {
	const { header, hScroll } = useLayoutContext();

	const frameIdRef = useRef<number | undefined>();
	const [isActive, setIsActive] = useState(false);

	const start = useCallback(() => {
		setIsActive(true);
	}, []);

	const stop = useCallback(() => {
		setIsActive(false);
	}, []);

	const autoScroll = useCallback(() => {
		if (!header || !hScroll) return;

		const scrollContainerWidth = header.clientWidth;
		const maxScrollX = header.scrollWidth - header.clientWidth;

		let scrollX = header.scrollLeft;

		const ratio = hScroll.clientWidth / header.clientWidth;

		const speed = ratio * scrollSpeed;

		const dragWidth = rightX - leftX;

		// too big - we cannot scroll in both directions
		if (dragWidth >= scrollContainerWidth - 2 * SCROLL_BUFFER) {
			return;
		}

		if (rightX > scrollContainerWidth - SCROLL_BUFFER) {
			scrollX = Math.min(scrollX + speed, maxScrollX);
		} else if (leftX <= SCROLL_BUFFER) {
			scrollX = Math.max(scrollX - speed, 0);
		}

		hScroll.scrollTo(scrollX, 0);
	}, [header, hScroll, leftX, rightX, scrollSpeed]);

	useEffect(() => {
		if (!isActive) return;

		const scheduleAutoScroll = () => {
			frameIdRef.current = requestAnimationFrame(() => {
				autoScroll();
				frameIdRef.current = requestAnimationFrame(scheduleAutoScroll);
			});
		};
		scheduleAutoScroll();

		return () => {
			if (frameIdRef.current) {
				cancelAnimationFrame(frameIdRef.current);
				frameIdRef.current = undefined;
			}
		};
	}, [isActive, autoScroll]);

	return [start, stop] as const;
};

const SCROLL_BUFFER = 50;
