import { SelectListItem } from '@compstak/ui-kit';
import { useCountySuggestionsQuery } from 'api/suggestions/counties/useCountySuggestionsQuery';
import { useCountyPolygonByFIPSQuery } from 'api/ppm-property/countyPolygons/useCountyPolygonByFIPSQuery';
import { FilterSelect } from 'Components/Filters/Base/Filter/FilterSelect';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMarkets } from 'hooks/useMarkets';
import { useEffect, useMemo, useState } from 'react';
import { CompType } from 'types';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FILTERS_DEBOUNCE_TIMEOUT } from 'util/formConstants';

type CountyFilterProps = {
	compType: CompType;
	filters: FiltersObject;
	onFilterChange: (change: Partial<FiltersObject>) => void;
	touch: () => void;
};

export const CountyFilter = ({
	compType,
	filters,
	onFilterChange,
	touch,
}: CountyFilterProps) => {
	const fips = filters.fips?.[0] ?? '';

	const [query, setQuery] = useState('');

	const { data, isFetching: isCountiesFetching } = useCountySuggestionsQuery(
		{
			compType,
			marketId: getFiltersMarkets(filters).map(({ id }) => id),
			query,
			filters,
		},
		{
			keepPreviousData: true,
		}
	);

	const { isFetching: isCountyPolygonFetching } = useCountyPolygonByFIPSQuery(
		{
			fips,
		},
		{
			enabled: !!fips,
		}
	);

	const isFetching = isCountiesFetching || isCountyPolygonFetching;

	const markets = useMarkets();

	const items = useMemo(() => {
		if (!data?.marketCounties.length) {
			return [];
		}

		const marketToCounties = data.marketCounties.reduce<
			Record<string, SelectListItem[]>
		>((acc, county) => {
			const countySelectItem = {
				title: county.name,
				value: county.fips,
				key: `${county.fips}:${county.marketId}`,
			};
			acc[county.marketId]?.push(countySelectItem) ??
				(acc[county.marketId] = [countySelectItem]);

			return acc;
		}, {});

		const selectItems: SelectListItem[] = Object.keys(marketToCounties).flatMap(
			(marketId) => {
				return [
					{
						type: 'header',
						value: markets[marketId].displayName,
					},
					...marketToCounties[marketId],
				];
			}
		);

		return selectItems;
	}, [data, markets]);

	useEffect(() => {
		if (!fips || !data?.marketCounties.length) {
			return;
		}

		const isCountiesIncludeFips = data.marketCounties.some(
			({ fips: countyFips }) => countyFips === fips
		);

		!isCountiesIncludeFips && onFilterChange({ fips: null, apn: null });
	}, [data?.marketCounties, onFilterChange, fips]);

	const [onSearch] = useDebouncedCallback((val: string | null) => {
		touch();
		setQuery(val ?? '');
	}, FILTERS_DEBOUNCE_TIMEOUT);

	return (
		<FilterSelect
			items={items}
			placeholder="Add County"
			isSearchable={true}
			isLoading={isFetching}
			value={fips}
			onSearch={onSearch}
			onChange={(item) => {
				touch();
				const fips = String(item.selectedItem?.value);
				fips && onFilterChange({ fips: [fips], apn: null });
			}}
			noResultMessage="No matching result found"
		/>
	);
};
