import { useExcelData } from 'utils';
import { useCompsetExcelColumns } from './useCompsetExcelColumns';
import { useCompsetTableRows } from './useCompsetTableRows';

type Props = {
	propertyId: number;
};

export const useCompsetExcelRows = ({ propertyId }: Props) => {
	const { data: rows } = useCompsetTableRows({ propertyId });
	const columns = useCompsetExcelColumns({ propertyId });

	return useExcelData({
		rows,
		columns,
	});
};
