import { SPACE_TYPE_NAME_TO_ID, usePropertyMarket } from 'api';
import { useMemo } from 'react';
import {
	CheckedLeaseSpaceType,
	CheckedLeaseStatus,
} from './usePropertyLeasesOptionsState';
import { createSearchLeasesUrl } from 'utils';

type Props = {
	propertyId: number;
	checkedSpaceType: CheckedLeaseSpaceType;
	checkedStatus: CheckedLeaseStatus;
	addresses: string[];
};
export const usePropertyLeasesUrl = ({
	propertyId,
	checkedSpaceType,
	checkedStatus,
	addresses,
}: Props) => {
	const { data: market } = usePropertyMarket({ propertyId });

	const leasesUrl = useMemo(
		() =>
			createSearchLeasesUrl({
				market,
				address: addresses,
				spaceTypeId:
					checkedSpaceType === 'All Leases'
						? null
						: // There are leases that have space type undefined (unknown, SpaceTypeId: -1) and then those values must be applied in Other
							checkedSpaceType === 'Other'
							? [-1, SPACE_TYPE_NAME_TO_ID[checkedSpaceType]]
							: [SPACE_TYPE_NAME_TO_ID[checkedSpaceType]],
				expirationDate:
					checkedStatus === 'Only Active Leases'
						? {
								min: new Date(),
								max: null,
							}
						: null,
			}),
		[checkedSpaceType, checkedStatus, market]
	);

	return leasesUrl;
};
