import {
	Modal,
	ModalCloseButton,
	ModalCloseIcon,
	ModalContent,
	ModalProps,
} from 'Components/Modals/common/UI';
import { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

export const ExportPDFPage = styled.div`
	background-color: ${({ theme }) => theme.colors.white.white};
	padding: 2rem;
	margin-bottom: 2rem;
	min-height: 1000px;
	display: flex;
	flex-direction: column;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	page-break-before: always;

	/* Header */
	header {
		font-size: 14px;
		padding-bottom: 8px;
		margin-bottom: 8px;
		border-bottom: 1px solid #000;
	}

	header .left {
		width: 50%;
		display: inline-block;
		vertical-align: bottom;
	}

	header .right {
		width: 50%;
		display: inline-block;
		text-align: right;
		vertical-align: bottom;
	}

	/* Footer */
	footer {
		position: unset;
		margin-top: auto;
		font-size: 9px;
		padding-top: 8px;
		border-top: 1px solid #000;
	}

	footer .text {
		display: inline-block;
		vertical-align: middle;
		line-height: 22px;
	}

	footer .icon_icon {
		color: #000;
		margin-left: -10px;
		vertical-align: middle;
	}

	footer .footer-content {
		float: left;
	}

	footer .id-link {
		float: right;
	}

	footer .disclaimer-text {
		margin-top: 4px;
		font-size: 9px;
	}
`;

export const ExportPDFModal = (props: ModalProps) => {
	return (
		<ExportPDFModalUI
			contentProps={{
				view: 'wide',
				onInteractOutside: (ev) => ev.preventDefault(),
			}}
			{...props}
		/>
	);
};

const ExportPDFModalUI = styled(Modal)`
	${ModalContent} {
		background-color: unset;
		padding: 0;
	}
	${ModalCloseButton} {
		display: none;
	}
`;

export const ExportPDFModalToolbar = forwardRef<
	HTMLDivElement,
	{ children: ReactNode }
>(({ children }, ref) => {
	return (
		<ExportPDFModalToolbarUI ref={ref}>
			{children}
			<ModalCloseButton>
				<ModalCloseIcon />
			</ModalCloseButton>
		</ExportPDFModalToolbarUI>
	);
});

ExportPDFModalToolbar.displayName = 'ExportPDFModalToolbar';

const ExportPDFModalToolbarUI = styled.div`
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: ${(p) => p.theme.colors.white.white};
	padding: 1rem 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;
	width: 100%;
	${ModalCloseButton} {
		display: unset;
		position: static;
		margin-left: auto;
	}
`;
