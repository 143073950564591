import { Feature, Polygon, Properties } from '@turf/helpers';
import { PolygonFilter } from 'models/filters/types';

export const createFilterFromPolygon = (
	polygon: Feature<Polygon, Properties>
): PolygonFilter => {
	return polygon.geometry.coordinates[0].map(([lng, lat]) => ({
		lat,
		lng,
	}));
};
