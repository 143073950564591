import { useState } from 'react';
import { useEditableRows } from '../useEditableRows';
import { ExportPdfData } from '../useExportPdfData';
import { useCompsetPdfColumns } from './useCompsetPdfColumns';

type Props = {
	data: ExportPdfData;
};

export const useEditableCompsetState = ({ data }: Props) => {
	const compsetColumns = useCompsetPdfColumns({
		property: data.property,
		compsetType: data.compsetType,
	});

	const [editableCompsetColumns, setEditableCompsetColumns] =
		useState(compsetColumns);

	const [editableCompsetRows, setEditableCompsetRows] = useEditableRows({
		rows: data.compsetRows,
		columns: editableCompsetColumns,
		getId: (row) => row.property.id,
	});

	return {
		editableCompsetColumns,
		setEditableCompsetColumns,
		editableCompsetRows,
		setEditableCompsetRows,
	};
};
