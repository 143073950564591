import { SimpleTable } from '@compstak/ui-kit';
import { routes, useNavigate } from 'router';
import { useCompsetTableColumns } from './useCompsetTableColumns';
import { useCompsetTableRows } from './useCompsetTableRows';

type Props = {
	propertyId: number;
	deleteColumn?: boolean;
};

export const CompsetTable = ({ propertyId, deleteColumn = false }: Props) => {
	const { data: rows, isFetching: isFetchingRows } = useCompsetTableRows({
		propertyId,
	});
	const navigate = useNavigate();
	const columns = useCompsetTableColumns({
		propertyId,
		subjectProperty: rows[0]?.property,
		addDeleteColumn: deleteColumn,
		rows,
	});

	// TODO: Add isLoading to table
	if (isFetchingRows) return null;

	return (
		<SimpleTable
			id="property-compset-table"
			columns={columns}
			rows={rows}
			isBodyHoverable
			getBodyCellProps={({ row }) => ({
				onClick: () => {
					navigate(routes.propertyById.toHref({ propertyId: row.property.id }));
				},
			})}
			scrollContainerProps={{
				style: {
					maxHeight: 400,
				},
			}}
		/>
	);
};
