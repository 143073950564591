import { CirclesLayer, LayerColor, linearScale } from '@compstak/maps';
import { point } from '@turf/helpers';
import {
	LeaseExpirationResponse,
	useAllMapSearchLeasesByPropertyId,
	useServerFiltersForSearchMap,
} from 'api';
import { maxBy, minBy } from 'lodash';
import { AnalyticsPopup, CIRCLE_MARKET_MULTIPLIER_MAP } from 'maps';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useLeaseExpirationState } from './LeaseExpirationProvider';
import { LeaseExpirationTooltip } from './LeaseExpirationTooltip';

type Props = {
	data?: LeaseExpirationResponse;
	id?: string;
	order?: number;
};

const metricPropertyMap = {
	sqft: 'transactionSize',
	leaseCount: 'leaseCount',
};

export const LeaseExpirationLayer = ({ data, id, order }: Props) => {
	const [state] = useLeaseExpirationState();
	const [filters] = useFilters();

	const market = getFiltersMarkets(filters)[0];

	// @ts-expect-error TS7053: Element implicitly has an 'any...
	const multiplier = CIRCLE_MARKET_MULTIPLIER_MAP[market.name] ?? 30;

	const points = useMemo(() => {
		if (!data) return [];
		return data.map((p) => point([p.longitude, p.latitude], p));
	}, [data]);

	const key = metricPropertyMap[state.metric];

	const pointCountDomain = useMemo(() => {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		const min = minBy(points, (p) => p.properties[key]);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		const max = maxBy(points, (p) => p.properties[key]);
		if (!min || !max) {
			return { min: 0, max: 0 };
		}
		return {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			min: min.properties[key],
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			max: max.properties[key],
		};
	}, [points, key]);

	const filterParam = useServerFiltersForSearchMap({ filters });

	const allLeasesByPropertyId = useAllMapSearchLeasesByPropertyId({
		filters: filterParam,
	});

	return (
		<CirclesLayer
			id={id}
			data={points}
			visible
			getFillColor={LEASE_EXPIRATION_ANALYTIC_CIRCLE_FILL_COLOR}
			getRadius={(f) => {
				let area = MAX_AREA;

				if (points.length > 1) {
					area = linearScale({
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						value: f.properties[key],
						domainMin: pointCountDomain.min,
						domainMax: pointCountDomain.max,
						rangeMin: MIN_AREA * multiplier,
						rangeMax: MAX_AREA * multiplier,
					});
				}
				return Math.sqrt(area / Math.PI);
			}}
			radiusMinPixels={1.5}
			radiusMaxPixels={50}
			radiusUnits="meters"
			highlightColor={LEASE_EXPIRATION_ANALYTIC_HIGHLIGHT_COLOR}
			getLineColor={LEASE_EXPIRATION_ANALYTIC_CIRCLE_LINE_COLOR}
			lineWidthMinPixels={1}
			lineWidthMaxPixels={1}
			getLineWidth={1}
			getTooltipContent={(f) => {
				const lease = allLeasesByPropertyId[f.properties.propertyId]?.[0];

				if (!lease) return null;

				return (
					<LeaseExpirationTooltip
						feature={f}
						metric={state.metric}
						lease={lease}
					/>
				);
			}}
			getPopupContent={(f, closePopup) => (
				<AnalyticsPopup
					propertyId={f.properties.propertyId}
					closePopup={closePopup}
				/>
			)}
			popupAnchor="bottom"
			opacity={1}
			billboard
			filled
			order={order}
		/>
	);
};

const LEASE_EXPIRATION_ANALYTIC_CIRCLE_FILL_COLOR: LayerColor = [
	0, 148, 112, 220,
];

const LEASE_EXPIRATION_ANALYTIC_CIRCLE_LINE_COLOR: LayerColor = [
	255, 255, 255, 220,
];

const LEASE_EXPIRATION_ANALYTIC_HIGHLIGHT_COLOR = [67, 143, 247];

const MIN_AREA = 1000;
const MAX_AREA = 50000;
