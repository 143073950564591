import { API } from '@compstak/common';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { getPath } from 'util/getPath';
import { PropertyStatsParams, PropertyStatsResponse } from './types';

export const usePropertyMarketStatsQuery = (
	params: PropertyStatsParams,
	options?: UseQueryOptions<PropertyStatsResponse>
) => {
	const { propertyId, metric, ...searchParams } = params;

	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_MARKET_STATS, params],
		queryFn: async () => {
			const res = await API.get<PropertyStatsResponse>(
				getPath(
					`/properties/${propertyId}/market/stats/${metric}`,
					searchParams
				)
			);
			return res.data;
		},
		...options,
	});
};
