import { useMarketByNameOrId } from 'Pages/Login/reducers';
import { useSaleCompQuery } from 'api';

type Props = {
	id: number;
};

export const useSaleMarket = ({ id }: Props) => {
	const { data: saleData } = useSaleCompQuery({ id });

	const market = useMarketByNameOrId(saleData?.marketId);

	return market;
};
