import { useSplitCompset } from 'api';
import { useMemo } from 'react';

type Props = {
	propertyId: number;
};

export const useCompsetTableRows = ({ propertyId }: Props) => {
	const { data: splitCompset, ...rest } = useSplitCompset({
		propertyId,
	});

	return {
		data: useMemo(() => {
			if (!splitCompset) return [];

			return [
				{
					property: splitCompset.subjectProperty,
					custom: false,
				},
				...splitCompset.compset,
			];
		}, [splitCompset]),
		...rest,
	};
};
