import { TooltipV2 } from '@compstak/ui-kit';
import { PropertySale } from 'api';
import { useState } from 'react';
import styled from 'styled-components';
import { SalesComp } from '../../types';
import { TableField } from '../../types/table';
import { TABLE_MAX_HEIGHT } from './config';
import { MultiPropertyItemsTable } from './MultiPropertyItemsTable';

type Props = {
	saleComp: SalesComp | PropertySale;
	field?: TableField;
	children: JSX.Element;
};

export const MultiPropertyItemsTooltip = ({
	saleComp,
	field,
	children,
}: Props) => {
	const [key, setKey] = useState(true);

	const closeTooltip = () => {
		setKey(!key);
	};

	return (
		<TooltipV2
			// a way to close the tooltip
			// without it being controlled with the open prop
			key={String(key)}
			content={
				<Root>
					<Header>
						<Title>Multi-Property Items</Title>
						<div>
							The following properties were sold, together, within this
							multi-property sale.
						</div>
					</Header>
					<TableContainer>
						<MultiPropertyItemsTable
							saleComp={saleComp}
							field={field}
							closeTooltip={closeTooltip}
						/>
					</TableContainer>
				</Root>
			}
			contentCSS={() => ({
				maxWidth: 'unset',
				padding: 0,
				boxShadow: `0px 3px 6px rgba(0, 0, 0, 0.3)`,
				borderRadius: 4,
				overflow: 'hidden',
				// because of old property page ( it has 10000 :angry: )
				zIndex: 10001,
			})}
			arrowCSS={(p) => ({
				fill: p.theme.colors.gray.gray700,
			})}
		>
			{children}
		</TooltipV2>
	);
};

const Root = styled.div`
	min-width: 700px;
	max-width: 1100px;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 16px;
	background-color: ${(p) => p.theme.colors.gray.gray700};
	// TODO: add color to ui-kit theme
	color: #9097ad;
	font-size: 11px;
`;

const Title = styled.h4`
	// TODO: add color to ui-kit theme
	color: #daaaff;
	font-size: 11px;
`;

const TableContainer = styled.div`
	overflow-y: auto;
	max-height: ${TABLE_MAX_HEIGHT}px;
`;
