import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { useReferenceDataQuery } from 'api';
import React from 'react';

const attribute = 'insideviewOwnershipId' as const;

export const TenantOwnershipFilter = ({
	children,
}: {
	children?: React.ReactNode;
}) => {
	const { data: referenceData } = useReferenceDataQuery();

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<>
					<ChoiceSwitcher
						options={referenceData.insideviewOwnership.map((data) => ({
							title: data.name,
							value: data.id,
						}))}
						{...props}
					/>
					{children}
				</>
			)}
		</FilterBase>
	);
};
