import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

type Props = {
	isSaving: boolean;
	onSave: () => void;
	onSaveAs: () => void;
};

export const SaveChartOverwriteModal = ({
	isSaving,
	onSave,
	onSaveAs,
}: Props) => {
	return (
		<>
			<ModalTitle>Save Chart</ModalTitle>
			<ModalParagraph>
				You’re trying to save changes to a chart which already exists in the
				current project. Would you like to overwrite the existing chart or save
				a new, separate version?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="primary2" onClick={onSave} isLoading={isSaving}>
					Save
				</ModalButton>
				<ModalButton variant="primary" onClick={onSaveAs}>
					Save As
				</ModalButton>
			</ModalButtons>
		</>
	);
};
