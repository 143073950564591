import { Coordinate, PinsLayer } from '@compstak/maps';
import { point } from '@turf/helpers';
import { getCompPopup } from 'maps/components/Popups/CompPopup';
import { removeProperty } from 'Pages/Search/Map/actions';
import { PropertyPopup } from 'Pages/Search/Map/searchMapReducer';
import { useDispatch } from 'react-redux';
import { CompType, PropertyComp } from 'types';
import { MAP_LAYERS_ORDER } from '..';

const ID = 'PropertyPopupLayer';

type PropertyPopupLayerProps = {
	compType: CompType;
	property: PropertyComp;
	propertyPopup: PropertyPopup;
};

export const PropertyPopupLayer = ({
	compType,
	property,
	propertyPopup,
}: PropertyPopupLayerProps) => {
	const dispatch = useDispatch();

	const pinsData = [point(propertyPopup.coordinates, property)];

	return (
		<PinsLayer
			id={ID}
			data={pinsData}
			getIconColor={() => 'blue'}
			popupAnchor={'bottom'}
			popupState={{
				feature: pinsData[0],
				pointerCoordinates: pinsData[0].geometry.coordinates as Coordinate,
			}}
			getPopupContent={getCompPopup(compType, () => {
				dispatch(removeProperty());
			})}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
