import { useCallback, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import styled from 'styled-components';
import { useRect } from '../../../utils';
import { PropertyWithSummary } from '../../hooks/usePropertiesWithSummaries';
import { PROPERTY_LIST_ITEM_HEIGHT } from './constants';
import { PropertyListItem } from './PropertyListItem';

type Props = {
	propertiesWithSummaries: PropertyWithSummary[];
};

export const PropertyList = ({ propertiesWithSummaries }: Props) => {
	const [root, setRoot] = useState<HTMLElement | null>(null);
	const rect = useRect(root);

	const renderRow = useCallback(
		({ style, index }: ListChildComponentProps) => {
			const property = propertiesWithSummaries[index];
			return (
				<div style={style}>
					<PropertyListItem property={property} />
				</div>
			);
		},
		[propertiesWithSummaries]
	);

	const width = rect?.width ?? 0;
	const height = rect?.height ?? 0;

	return (
		<Root ref={setRoot}>
			{height > 0 && (
				<FixedSizeList
					width={width}
					height={height}
					itemCount={propertiesWithSummaries.length}
					itemSize={PROPERTY_LIST_ITEM_HEIGHT}
					itemKey={(index) => propertiesWithSummaries[index].id}
					overscanCount={5}
				>
					{renderRow}
				</FixedSizeList>
			)}
		</Root>
	);
};

const Root = styled.div`
	width: 100%;
	height: 100%;
`;
