import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import React from 'react';

const attribute = 'effectiveRent' as const;

export const EffectiveRentFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => {
				return (
					<>
						<Between
							attribute={attribute}
							filter={props.filter}
							onFilterChange={props.onFilterChange}
							monthly={shouldUseMonthlyMetrics(props.filters)}
							touch={props.touch}
							setError={props.setError}
						/>
						<FilterDescription>
							Starting rent, with Escalations, minus TI and Free Rent, divided
							by Lease Term.
						</FilterDescription>
					</>
				);
			}}
		</FilterBase>
	);
};
