import { Text, View } from '@react-pdf/renderer';
import { PropertySale } from 'api';
import { useMemo } from 'react';
import { PdfTable } from '../PdfTable';
import { pdfStyle } from '../style';
import { PdfColumn } from '../types';
import { EditablePropertySale } from './useSalesPdfColumns';

type Props = {
	editableSales: EditablePropertySale[];
	editableSalesColumns: PdfColumn<EditablePropertySale, PropertySale>[];
};

export const SalesPage = (props: Props) => {
	const { editableSales, editableSalesColumns } = props;

	const visibleColumns = useMemo(() => {
		return editableSalesColumns.filter((c) => c.isVisible);
	}, [editableSalesColumns]);

	if (editableSales.length === 0) return null;

	return (
		<View style={{ marginBottom: 20 }}>
			<Text style={pdfStyle.pageTitle}>
				Property Sales History ({editableSales.length})
			</Text>
			<PdfTable rows={editableSales} columns={visibleColumns} />
		</View>
	);
};
