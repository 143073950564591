import { FiltersObject } from 'models/filters/types';
import React, { useCallback } from 'react';
import { FilterErrors } from './Filter/Container';
import { FiltersTypesKeys } from 'models/filters/types';
import { isEqual } from 'lodash';

type Props<T extends FiltersTypesKeys = FiltersTypesKeys> = {
	touch: () => void;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setError: (errors: FilterErrors) => void;
	attribute: T;
	filter: FiltersObject[T];
	options: Option<T>[];
};

type Option<T extends FiltersTypesKeys = FiltersTypesKeys> = {
	title: string;
	value: FiltersObject[T];
};

export const ChoiceSwitcher = <T extends FiltersTypesKeys = FiltersTypesKeys>(
	props: Props<T>
) => {
	const {
		touch,
		onFilterChange,
		setError,
		attribute,
		filter,
		options = [],
	} = props;

	const onClick = useCallback(
		(option: Option) => {
			touch();
			try {
				onFilterChange({
					[attribute]: option.value,
				});
			} catch (e) {
				if (e instanceof Error) {
					setError(e.message);
				}
			}
		},
		[attribute, touch, onFilterChange, setError]
	);

	return (
		<div>
			{options.map((option) => {
				const id = `${attribute}-${option.value}`;

				return (
					<div key={id}>
						<input
							type="radio"
							className="radio"
							id={id}
							checked={isEqual(filter, option.value)}
							onChange={() => onClick(option)}
						/>
						<label htmlFor={id}>{option.title}</label>
					</div>
				);
			})}
		</div>
	);
};
