import React from 'react';

import { Colors } from '..';

import { Styled } from './styled';

type LegendProps = {
	marketName: string;
};

export const MarketTrendLegendComponent = ({ marketName }: LegendProps) => (
	<Styled.Container>
		<Styled.ItemsContainer>
			<Styled.Item color={Colors.PORTFOLIO}>
				<div />
				<span>{`Portfolio - ${marketName}`}</span>
			</Styled.Item>
			<Styled.Item color={Colors.MARKET}>
				<div />
				<span>{`Market - ${marketName}`}</span>
			</Styled.Item>
		</Styled.ItemsContainer>
	</Styled.Container>
);
