import { SimpleTable } from '@compstak/ui-kit';
import { useSaleMarket } from 'Pages/SalesDetailsV2/useSaleMarket';
import { useSaleCompQuery } from 'api';
import { useIsExchange } from 'hooks';
import { useMemo } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { useCompAddressesSearchUrl } from '../../utils';
import { useColumnsPortfolio } from './columnsPortfolio';
import {
	useBodyCellProps,
	useCurrentlyActiveLeases,
	useLeaseSnapshotData,
	useLeasesActiveAtTimeOfSale,
} from './hooks';

export const LeaseSnapshotPortfolio = () => {
	const params = routes.saleById.useParams();

	const { data: sale } = useSaleCompQuery({ id: params.id });

	const { data: leaseSnapshotData } = useLeaseSnapshotData({
		saleId: params.id,
		sort: 'transactionSize',
	});

	const { data: leasesActiveAtTimeOfSale, isFetching: isFetchingActive } =
		useLeasesActiveAtTimeOfSale({
			saleId: params.id,
			sort: 'transactionSize',
		});

	const top10CurrentlyActiveLeases = useCurrentlyActiveLeases(
		leaseSnapshotData?.comps ?? []
	).slice(0, 10);

	const market = useSaleMarket({ id: params.id });

	const leasesSearchUrl = useCompAddressesSearchUrl({
		compType: 'lease',
		addresses: sale
			? sale.portfolio.map((p) => {
					return p.buildingAddressAndCity;
				})
			: [],
		market,
	});

	const seeAllLeasesLink = useMemo(() => {
		if (!leaseSnapshotData?.comps?.length) return null;
		return (
			<SeeAllLink to={leasesSearchUrl} data-qa-id="SeeAllSalesLink">
				view all leases
			</SeeAllLink>
		);
	}, [leaseSnapshotData, leasesSearchUrl]);

	const bodyCellProps = useBodyCellProps();
	const isExchange = useIsExchange();

	const unlockedInTitle = isExchange ? 'Unlocked' : '';

	const columns = useColumnsPortfolio();

	const commonProps = {
		isLoading: isFetchingActive,
		columns,
		scrollContainerProps: {
			style: {
				maxHeight: 400,
			},
		},
		isBodyHoverable: true,
		getBodyCellProps: bodyCellProps,
	};

	return (
		<div>
			<TabContentContainer>
				<TabContentHeader>
					<TabContentTitle>
						Top 10 {unlockedInTitle} Leases at Time of Sale
					</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="activeLeasesAtTimeOfSaleTable"
					rows={leasesActiveAtTimeOfSale.slice(0, 10)}
					{...commonProps}
				/>
			</TabContentContainer>
			<StyledTabContentContainer>
				<TabContentHeader>
					<TabContentTitle>Top 10 Currently Active Leases</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="currentlyActiveLeasesTable"
					rows={top10CurrentlyActiveLeases}
					{...commonProps}
				/>
			</StyledTabContentContainer>
			{isExchange && (
				<StyledTabContentContainer>
					<TabContentHeader>
						<TabContentTitle>All Leases</TabContentTitle>
						{seeAllLeasesLink}
					</TabContentHeader>
					<SimpleTable
						id="allLeasesTable"
						rows={leaseSnapshotData?.comps ?? []}
						{...commonProps}
					/>
				</StyledTabContentContainer>
			)}
		</div>
	);
};

const StyledTabContentContainer = styled(TabContentContainer)`
	margin-top: 8px;
	margin-bottom: 32px;
`;
