import { SubmissionFileDetails } from 'Pages/Settings/Components/Credits/submissionTypes';
import { VersionsWithPromotionsParams } from './types';
import { useLeaseVersionsWithPromotionsQuery } from './useLeaseVersionsWithPromotionsQuery';
import { useSaleVersionsWithPromotionsQuery } from './useSaleVersionsWithPromotionsQuery';

type Props = VersionsWithPromotionsParams & {
	type?: SubmissionFileDetails['dataType'];
};

export const useVersionsWithPromotions = ({ versionIds, type }: Props) => {
	const leaseResponse = useLeaseVersionsWithPromotionsQuery(
		{
			versionIds,
		},
		{
			enabled: type === 'lease',
		}
	);

	const saleResponse = useSaleVersionsWithPromotionsQuery(
		{
			versionIds,
		},
		{
			enabled: type === 'sale',
		}
	);

	return type === 'lease' ? leaseResponse : saleResponse;
};
