import { SearchPropertiesAverages } from 'api';
import { formatInteger, formatMoney, formatRent } from 'format';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import { ReactNode, useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

export const usePropertiesAveragesFormatting = (
	averages: SearchPropertiesAverages | undefined
): Record<string, () => ReactNode> => {
	const [filters] = useFilters();
	const isMonthly = useMemo(() => shouldUseMonthlyMetrics(filters), [filters]);

	if (!averages || Object.keys(averages).length === 0) return {};
	return {
		buildingFloorsCount: () => Number(averages.buildingFloorsCount).toFixed(),
		buildingSize: () => `${formatInteger(averages.buildingSize)} SF`,
		lastSalePrice: () => formatMoney(averages.lastSalePrice),
		propertyAverageTransactionSize: () =>
			`${formatInteger(averages.propertyAverageTransactionSize)} SF`,
		propertyMarketStartingRent: () =>
			formatRent(averages.propertyMarketStartingRent, isMonthly),
		propertySqFtExpiringInTwelveMonths: () =>
			`${formatInteger(averages.propertySqFtExpiringInTwelveMonths)} SF`,
	};
};
