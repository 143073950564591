import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import React from 'react';

const attribute = 'hidden' as const;

export const HiddenFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					attribute={attribute}
					options={[
						{
							title: 'Only Hidden',
							value: 'hidden',
						},
						{
							title: 'Only not hidden',
							value: null,
						},
						{
							title: 'Both',
							value: 'both',
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
