import React from 'react';
import { CompstakContactInfo } from '@compstak/common';
import { SalesActivityTable } from './SalesActivityTable';
import { ExchangeSection } from './UI';
import { Section } from './types';

type Props = {
	setSectionsInView: React.Dispatch<React.SetStateAction<Section['type'][]>>;
	contactInfo?: CompstakContactInfo;
};

export const SalesActivity: React.FC<Props> = (props) => {
	return (
		<ExchangeSection
			id="sales-activity"
			title="Sales Activity"
			setSectionsInView={props.setSectionsInView}
		>
			<SalesActivityTable />
		</ExchangeSection>
	);
};
