import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';

export const useSubmarketsByMarket = (
	params: UseSubmarketsByMarketParams,
	options?: UseSubmarketsByMarketOptions
) => {
	const { id: marketId } = params;

	return useQuery({
		queryKey: [QUERY_KEYS.SUBMARKETS, params],
		queryFn: () => fetchSubmarketByMarket(marketId),
		...options,
	});
};

export const fetchSubmarketByMarket = async (marketId: number) => {
	const response = await API.get<Submarket[]>(
		`/api/markets/${marketId}/submarkets`
	);

	return response.data;
};

export type UseSubmarketsByMarketOptions = UseQueryOptions<Submarket[]>;

export type UseSubmarketsByMarketParams = {
	id: number;
};

export type Submarket = {
	dateCreated: string;
	id: number;
	lastUpdated: string;
	marketId: number;
	name: string;
};
