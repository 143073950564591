import dayjs from 'dayjs';

export const createDefaultPeriodRange = (initDate?: string) => ({
	start: dayjs(initDate)
		.subtract(5, 'year')
		.month(0)
		.date(1)
		.format('YYYY-MM-DD'),
	end: dayjs(initDate).date(1).format('YYYY-MM-DD'),
});
