import { Image, Text, View } from '@react-pdf/renderer';
import { PdfPage } from 'pdf/PdfPage';
import { PropertyAddress } from '../OverviewPage/PropertyAddress';
import { pdfStyle } from '../style';
import { PdfPageProps } from '../types';

type Props = PdfPageProps & { imageUrl: string };

export const MarketStatsPage = ({
	page,
	user,
	apn,
	property,
	imageUrl,
}: Props) => {
	return (
		<PdfPage
			logo={page.logo}
			preparedFor={page.preparedFor}
			title={page.titles.overview}
			user={user}
		>
			<View fixed>
				<PropertyAddress property={property} apn={apn} />
			</View>
			<View style={pdfStyle.pageTitle}>
				<Text>Market Stats</Text>
			</View>
			<Image src={imageUrl} style={pdfStyle.charts} />
		</PdfPage>
	);
};
