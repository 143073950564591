import { createSearchParams } from 'react-router-dom';

export const getPath = (
	basePath: string,
	searchParams?: Record<string, SearchParamValue | SearchParamValue[]>
) => {
	let path = basePath;

	if (searchParams) {
		// @ts-expect-error complains because we have numbers as values
		// but numbers are easily converted to strings
		path += `?${createSearchParams(searchParams).toString()}`;
	}

	return path;
};

type SearchParamValue = string | number;
