const sharedAppConfig = {
	mapbox: {
		styles: {
			default: 'vadimich/cjc81i4wq6jxb2rmxqmou2qsg',
			monochrome: 'vadimich/ck1ay92gg01nh1cqv49w2gt47',
			satellite: 'mapbox/satellite-streets-v9',
		},
		style: 'vadimich/cjc81i4wq6jxb2rmxqmou2qsg',
		accessToken: window._env_.REACT_APP_MAPBOX_TOKEN,
	},
	google: {
		apiKey: window._env_.REACT_APP_GOOGLE_API_KEY,
	},
	segmentKey: window._env_.REACT_APP_SEGMENT_KEY,
	bing: {
		accessToken: window._env_.REACT_APP_BING_TOKEN,
	},
	churnZero: {
		// @ts-expect-error not defined
		accessToken: window._env_.REACT_APP_CHURNZERO_TOKEN,
	},
};

const url = new URL(window.location.href);
const isLocal = window._env_.REACT_APP_DEPLOYMENT_ENV === 'local';
let isEnterprise = false;
if (isLocal) {
	isEnterprise = url.port === '5450';
} else {
	isEnterprise = url.origin.indexOf('enterprise') !== -1;
}
if (isEnterprise) {
	window.appConfig = {
		...sharedAppConfig,
		isEnterprise: true,
		isExchange: false,
	};
} else {
	window.appConfig = {
		...sharedAppConfig,
		isEnterprise: false,
		isExchange: true,
		giftbitVendorIds:
			typeof window._env_.REACT_APP_GIFTBIT_VENDOR_IDS === 'string'
				? JSON.parse(window._env_.REACT_APP_GIFTBIT_VENDOR_IDS)
				: window._env_.REACT_APP_GIFTBIT_VENDOR_IDS,
	};
}
