import {
	renderTableValue,
	SimpleTableColumn,
	TooltipV2,
	zIndex,
} from '@compstak/ui-kit';
import { MarketsState } from 'Pages/Login/reducers';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import { formatDate } from 'format';
import { useMarkets } from 'hooks/useMarkets';
import { ServerFilterItem } from 'types/serverFilters';
import { EditOptionsDropdown } from './Components/EditOptionsDropdown';

export const useSavedSearchesColumns = () => {
	const markets = useMarkets();
	const columns: SimpleTableColumn<SavedSearch>[] = [
		{
			id: 'name',
			header: () => 'Name',
			body: ({ row }) => renderTableValue(row.name),
			width: 460,
			flex: 1,
		},
		{
			id: 'lastRan',
			header: () => 'Last Updated',
			body: ({ row }) => renderTableValue(row.lastRan, formatDate),
			width: 120,
			flex: 1,
		},
		{
			id: 'previousDateSeen',
			header: () => 'Last Viewed',
			body: ({ row }) => renderTableValue(row.previousDateSeen, formatDate),
			width: 120,
			flex: 1,
		},
		{
			id: 'filters',
			header: () => 'Filters Applied',
			body: ({ row }) => {
				const tooltip = row.filters.map((filter) =>
					getTooltip(filter, markets)
				);
				return (
					<TooltipV2
						content={tooltip}
						contentCSS={() => ({
							zIndex: zIndex.overlay + zIndex.modal,
						})}
					>
						<span> {row.filters.length} Filters Set</span>
					</TooltipV2>
				);
			},
			width: 120,
			flex: 1,
		},
		{
			id: 'edit',
			header: () => 'Edit',
			body: ({ row }) => <EditOptionsDropdown savedSearch={row} />,
			width: 48,
		},
	];

	return columns;
};

const getTooltip = (filter: ServerFilterItem, markets: MarketsState) => {
	const { property, value } = filter;
	switch (property) {
		case 'marketName':
			return `${markets[value].displayName} 
			(${markets[value].states.map((state) => state.code).join(', ')})`;
		case 'marketNames':
			return `${value.length} Markets Selected`;
		default:
			return '';
		// TODO: add other filters
	}
};
