import { nullValueText } from '@compstak/common';
import { MultiPropertyItemsTooltip } from 'Components';
import EffectiveRent from 'Components/AttributeValues/EffectiveRent';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { isEqual } from 'lodash';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import React from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { LeaseComp, SalesComp } from 'types/comp';
import { TableField } from 'types/table';
import getFieldValue from 'util/getFieldValue';
import AttributeValue from '../AttributeValue';
import MissingValue from '../MissingValue';
import StartingRent from '../StartingRent';
import styles from './valueorlock.less';

type SharedProps = {
	field: TableField;
	isMonthlyMarket?: boolean;
};

type Props =
	| ({ compType: 'lease'; comp: LeaseComp } & SharedProps)
	| ({ compType: 'sale'; comp: SalesComp } & SharedProps);

const ValueOrLockIcon = React.memo<Props>(
	(props) => {
		const { comp } = props;

		const [filters] = useFilters();
		const isMonthly =
			props.isMonthlyMarket === undefined
				? shouldUseMonthlyMetrics(filters)
				: props.isMonthlyMarket;

		let value = getFieldValue(comp, props.compType, props.field.name);

		if (props.field.name === 'totalSalePrice' && value) {
			const ONE_MILLION = 1000000;
			// @ts-expect-error TS2339: Property 'replace' does not ex...
			const numericValue = parseInt(value.replace(/\$|,/g, ''));
			if (numericValue > ONE_MILLION) {
				value = `${(numericValue / ONE_MILLION).toFixed(1)}M`;
			}
		}

		switch (true) {
			case props.field.showAlertIfNotPresent &&
				(value === undefined || value === Infinity):
				return (
					<MissingValue tooltip={true} displayName={props.field.displayName} />
				);

			case value !== null && props.field.name === 'startingRent':
				return (
					<StartingRent
						isMonthly={isMonthly}
						tooltip={true}
						comp={comp}
						abbreviateText={true}
					/>
				);

			case value !== null &&
				typeof value !== 'undefined' &&
				props.field.name === 'effectiveRent':
				return (
					<EffectiveRent
						isMonthly={isMonthly}
						// @ts-expect-error TS2322: Type 'string | number | null |...
						value={value}
						abbreviateText={true}
					/>
				);

			case value === undefined:
			case value === Infinity: // this is here for the hack that makes it flash. See the search reducer.
				return <span className={styles.emptyValue}>{nullValueText}</span>;

			case value === null:
				return (
					<LockIconStyled
						className="center-y"
						width={14}
						height={17}
						hasDarkColor
					/>
				);

			case props.field.name === 'isPortfolio':
				// @ts-expect-error TS2367: This comparison appears to be ...
				if (value === true) {
					// @ts-expect-error TS2339: Property 'portfolio' does not ...
					const properties = comp.portfolio.length;
					return (
						<MultiPropertyItemsTooltip saleComp={comp as SalesComp}>
							<span className={styles.portfolio}>
								Yes ({properties} Propert{properties === 1 ? 'y' : 'ies'})
							</span>
						</MultiPropertyItemsTooltip>
					);
				// @ts-expect-error TS2367: This comparison appears to be ...
				} else if (value === false) {
					value = 'No';
				}
		}
		if (typeof value === 'function') {
			// @ts-expect-error TS2349: This expression is not callabl...
			return <span>{value(props)}</span>;
		}
		return (
			<span>
				{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
				<AttributeValue value={value} />
			</span>
		);
	},
	(prevProps, nextProps) => isEqual(prevProps, nextProps)
);

ValueOrLockIcon.displayName = 'ValueOrLockIcon';

export default ValueOrLockIcon;
