import React from 'react';

import AnimatingNumber from './AnimatingNumber';
import { useMediaQuery } from 'react-responsive';
import { MQB } from '@compstak/ui-kit';

// @ts-expect-error TS7031: Binding element 'user' implici...
export default function PointsCounter({ user }) {
	const isMobile = useMediaQuery({ maxWidth: MQB.T_834 });
	return (
		<AnimatingNumber
			value={user.pointsAvailable}
			label={isMobile ? '' : 'Credits'}
			showZeroValue={true}
		/>
	);
}
