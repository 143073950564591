import * as Tabs from '@radix-ui/react-tabs';
import { usePortfolioByIdQuery } from 'api';
import styled from 'styled-components';
import { PortfolioOptionsButtonV2 } from '../../PortfolioOptions/PortfolioOptionsButtonV2';
import { PortfolioFiltersV2 } from './Filters/PortfolioFiltersV2';
import IconExportBlue from 'ui/svg_icons/export_blue.svg';
import IconShareBlue from 'ui/svg_icons/share_icon_blue.svg';
import IconPlusVeryLight from 'ui/svg_icons/plus_very_light.svg';
import { PortfolioStatsV2 } from '../../Components/PortfolioStatsV2';
import { exportPortfolio } from 'PortfolioAnalytics/Export/exportPortfolio';
import { Button, MQB } from '@compstak/ui-kit';
import { routes, useNavigate } from 'router';
import { PortfolioTabEnum } from '../Dashboard';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';
import { DataRequestDialog } from 'PortfolioAnalytics/Components/DataRequestDialog';
import { ShareWithColleagueModal } from 'Components/Modals/ShareWithColleagueModal/ShareWithColleagueModal';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { usePortfolioStats } from 'PortfolioAnalytics/hooks/usePortfolioStats';
import { useSharePortfolioMutation } from 'api/portfolio/sharedPortfolio/useSharePortfolioMutation';
import { AddNewPropertyButton } from './UI';

type Props = {
	portfolioId: number;
	dashboardElement: HTMLElement | null;
};

export const DashboardHeaderV2 = ({ portfolioId, dashboardElement }: Props) => {
	const { portfolioRevampPropertiesTabFF } = useFeatureFlags();
	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery({ id: portfolioId });
	const { filters, isPortfolioEmpty } = usePortfolioFilters();
	const portfolioStats = usePortfolioStats(portfolio, filters);

	const { openModal, closeModal } = useModal();
	const navigate = useNavigate();
	const { mutate: sharePortfolio } = useSharePortfolioMutation();

	if (!portfolio) return null;

	const hasDescription =
		typeof portfolio.description === 'string' &&
		portfolio.description.length > 0;

	return (
		<>
			<Header>
				<Row>
					<LeftControls>
						<TitleContainer>
							<TitleRow>
								<Title>{portfolio.name}</Title>
								<PortfolioOptionsButtonV2 portfolio={portfolio} />
							</TitleRow>
							{hasDescription && (
								<Description>{portfolio.description}</Description>
							)}
						</TitleContainer>
						<PortfolioFiltersV2 portfolioId={portfolioId} />
					</LeftControls>
					<RightControls>
						<ButtonsRow>
							<IconButton
								variant="ghost"
								icon={<IconShareBlue />}
								onClick={() => {
									openModal({
										modalContent: (
											<ShareWithColleagueModal
												itemName={portfolio.name}
												modalTitle="Share Portfolio"
												buttonText="Send portfolio"
												action={(emails, note) =>
													sharePortfolio({
														portfolioId,
														recipientEmails: emails,
														note,
													})
												}
												isWithNote
											/>
										),
									});
								}}
							></IconButton>
							<IconButton
								variant="ghost"
								icon={<IconExportBlue />}
								onClick={() => {
									exportPortfolio(portfolio, dashboardElement);
								}}
							></IconButton>
							<AddNewPropertyButton
								variant="primary"
								icon={<IconPlusVeryLight />}
								onClick={() => {
									navigate(routes.searchPropertiesHome.toHref());
								}}
							>
								Add New Property
							</AddNewPropertyButton>
						</ButtonsRow>
						<SendDataRequest
							variant="ghost"
							onClick={() => {
								openModal({
									modalContent: <DataRequestDialog closeDialog={closeModal} />,
								});
							}}
						>
							Send a data request to us
						</SendDataRequest>
					</RightControls>
				</Row>
				<Row>
					<TabList>
						<Tab value={PortfolioTabEnum.OVERVIEW} disabled={isPortfolioEmpty}>
							Overview
						</Tab>
						<Tab value={PortfolioTabEnum.MARKETS} disabled={isPortfolioEmpty}>
							Markets
						</Tab>
						{/* // TODO This should be under separate feature flag */}
						{/* <Tab value={PortfolioTabEnum.BENCHMARKING}>Benchmarking</Tab> */}
						<Tab
							value={PortfolioTabEnum.LEASE_ACTIVITY}
							disabled={isPortfolioEmpty}
						>
							Lease Activity
						</Tab>
						{portfolioRevampPropertiesTabFF && (
							<Tab
								value={PortfolioTabEnum.PROPERTIES}
								disabled={isPortfolioEmpty}
							>
								Properties
							</Tab>
						)}
					</TabList>
					<PortfolioStatsV2
						stats={portfolioStats}
						isFetchingStats={isFetchingPortfolio}
					/>
				</Row>
			</Header>
		</>
	);
};

const Header = styled.div`
	padding: 1rem 1.125rem 0 1.5rem;
	background-color: ${(p) => p.theme.colors.white.white};
	font-size: 1rem;

	@media (max-width: ${MQB.D_1280}) {
		font-size: 0.875rem;
	}
`;

const Row = styled.div`
	font-style: normal;
	display: grid;
	gap: 3rem;
	grid-template-columns: minmax(50px, 1fr) auto;
	@media (max-width: ${MQB.D_1280}) {
		gap: 2.5rem;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.5rem;
	max-width: 550px;
	@media (min-width: ${MQB.D_1536}) {
		max-width: 650px;
	}
`;

const TitleRow = styled.div`
	display: flex;
	line-height: 1.75rem;
	gap: 0.5rem;
`;

const Title = styled.div`
	font-feature-settings:
		'liga' off,
		'clig' off;
	font-style: normal;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 1.3em;
	color: ${(p) => p.theme.colors.gray.gray500};
	overflow: hidden;
	text-align: left;
`;

const Description = styled.p`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	color: ${(p) => p.theme.colors.gray.n100};
	-webkit-line-clamp: 5;
	font-size: 0.875em;
	font-style: normal;
	font-weight: 325;
	line-height: 1.25em;
	word-break: break-word;
	overflow-y: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
	margin-bottom: 0;
`;

const LeftControls = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
`;

const RightControls = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
`;

const ButtonsRow = styled.div`
	display: flex;
	flex-direction: row;
	row-gap: 0.75rem;
	margin-bottom: 0.5rem;
	column-gap: 1rem;

	@media (max-width: ${MQB.D_1280}) {
		column-gap: 0.5rem;
	}
`;

const IconButton = styled(Button)`
	min-width: 0;
	padding: 0.5em;

	svg {
		@media (max-width: ${MQB.D_1280}) {
			width: 26px;
			height: 26px;
		}
	}
`;

const SendDataRequest = styled(Button)`
	font-size: 0.875em;
`;

const TabList = styled(Tabs.List)`
	display: flex;
	gap: 1em;
	margin-top: 1.5em;
	font-size: 0.75em;
`;

const Tab = styled(Tabs.Trigger)`
	all: unset;
	line-height: 1rem;
	letter-spacing: 0.3px;
	font-weight: 325;
	padding: 0.75rem 0.5rem;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	box-sizing: border-box;
	text-transform: uppercase;
	position: relative;
	color: ${(p) => p.theme.colors.gray.n100};
	text-align: center;

	text-transform: uppercase;
	&:hover {
		background-color: ${(p) =>
			p.disabled ? 'initial' : p.theme.colors.neutral.n20};
	}
	&[data-state='active'] {
		font-weight: 400;
		color: ${(p) => p.theme.colors.blue.blue400};
		border-right: 1px solid ${(p) => p.theme.colors.gray.gray300};
		border-left: 1px solid ${(p) => p.theme.colors.gray.gray300};
		border-top: 3px solid ${(p) => p.theme.colors.blue.blue400};
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}

	button {
		pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
	}
`;
