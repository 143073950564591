import { DATE_FORMATS } from 'constants/dateFormats';
import dayjs from 'dayjs';
import {
	TrendData,
	TrendFilters,
	TrendMetricFilter,
	Bubbles,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { InsightDataPoint } from 'Pages/Analytics/analytics';
import { FiltersState } from '../PortfolioFiltersProvider';
import { InsightsParams, Portfolio, PortfolioV2, isPortfolioV2 } from 'api';
import { FULL_DATA_THRESHOLD } from 'Components/Graphs/constants';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioFilters';

type PortfolioMarketTrendsFetchProps = {
	portfolio: Portfolio | PortfolioV2;
	trendFilters: TrendFilters;
	filters: FiltersState;
};

export const getDateForTimeSpan = (years: number) =>
	dayjs().subtract(years, 'year').format(DATE_FORMATS['YYYY-MM-DD']);

export const getTrendQueryParams = ({
	portfolio,
	trendFilters,
	filters,
}: PortfolioMarketTrendsFetchProps): {
	portfolioQueryParams: InsightsParams;
	marketQueryParams: InsightsParams;
} => {
	const baseBody: Omit<InsightsParams, 'filter'> = {
		properties: [trendFilters.metric, 'executionDate', 'transactionSize', 'id'],
		fullDataThreshold: FULL_DATA_THRESHOLD,
		quartileThreshold: 10e9,
		trendMonths: trendFilters.movAverage,
	};

	const portfolioFilter = {
		property: 'portfolioId' as const,
		value: isPortfolioV2(portfolio) ? portfolio.portfolio.id : portfolio.id,
	};
	const marketIdFilter = trendFilters.marketId
		? [
				{
					property: 'marketId' as const,
					value: [trendFilters.marketId],
				},
			]
		: [];

	const portfolioQueryFilter: InsightsParams['filter'] = [
		...filtersToServerJSON(
			getFiltersFromPortfolioFilters({ ...filters, portfolio })
		),
		{ property: 'outlier', value: false },
		portfolioFilter,
	];

	const marketQueryFilter: InsightsParams['filter'] = [
		...filtersToServerJSON(
			getFiltersFromPortfolioFilters({ ...filters, excludeSubmarkets: true })
		),
		{ property: 'outlier', value: false },
		...marketIdFilter,
	];

	return {
		portfolioQueryParams: {
			...baseBody,
			filter: portfolioQueryFilter,
		},
		marketQueryParams: {
			...baseBody,
			filter: marketQueryFilter,
		},
	};
};

export const formatDate = (
	date: Date,
	shouldFormatDateAsYear: boolean,
	shortenYear?: boolean
) =>
	dayjs(date).format(
		shouldFormatDateAsYear ? (shortenYear ? "'YY" : 'YYYY') : "MMM 'YY"
	);

export const getTrendlineData = (arr: TrendData[], timeSpanDate: string) =>
	arr
		.filter(({ date }) => date >= timeSpanDate)
		.map(({ date, value }) => ({ x: new Date(date), y: value }));

export const getScatterData = (
	arr: InsightDataPoint[],
	metricFilter: TrendMetricFilter
) => {
	const metricToUse = metricFilter === 'ti' ? 'workValue' : metricFilter;
	const bubbles: Bubbles[] = [];
	arr.map((point) => {
		if (!point[metricToUse]) return;
		const newPoint = {
			x: new Date(point.executionDate),
			y: point[metricToUse] || 0,
			z: point.transactionSize,
		};
		bubbles.push(newPoint);
	});
	return bubbles;
};
