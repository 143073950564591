import abbreviateNumber from 'ui/util/abbreviateNumber';

type FormatMoneyOptionsProps = {
	/**
	 * USD or GBP currency
	 * @default USD
	 */
	currency?: 'USD' | 'GBP';
	/**
	 * should value be abbreviated
	 * @default false
	 */
	abbreviate?: boolean;
	/**
	 * number of decimal places
	 * @default 2
	 */
	decimals?: number;
};

/**
 * Format Money
 * @param {number} value number value that needs to be formated
 * @param {Object} options options:
 * 	 - decimals	  = 2     - number of decimal places
 * 	 - currency   = USD   - USD or GBP currency
 * 	 - abbreviate = false - should value be abbreviated
 * @returns if value is invalid, empty string would be returned
 */
export const formatMoney = (
	value: number,
	options?: FormatMoneyOptionsProps
) => {
	if (typeof value !== 'number' || isNaN(value)) return '';

	const decimals = options?.decimals ?? 2;
	const currency = options?.currency ?? 'USD';
	const abbreviate = options?.abbreviate;

	if (abbreviate) {
		return moneySymbolMap[currency] + abbreviateNumber(value);
	}

	return new Intl.NumberFormat(localeMap[currency], {
		maximumFractionDigits: decimals,
		minimumFractionDigits: decimals,
		style: 'currency',
		currency,
	}).format(value);
};

const localeMap = {
	USD: 'en-US',
	GBP: 'en-GB',
};
const moneySymbolMap = {
	USD: '$',
	GBP: '£',
};
