import React from 'react';
import { EditPageSectionTitle, TableContainer } from '../UI';
import { EditPdfTable } from '../EditPdfTable';
import { useEditableSubmarketRecentLeases } from './useEditableSubmarketRecentLeases';
import { TableTitle } from 'Components';
import { SelectionState } from 'utils';
import { ExportPdfLeasesStatus } from '../ExportPdfStatus';
import { ExportPdfData } from '../useExportPdfData';

type Props = ReturnType<typeof useEditableSubmarketRecentLeases> & {
	submarket: string;
	recentLeasesSelection: SelectionState;
	data: ExportPdfData;
};

export const EditableSubmarketRecentLeasesTable = ({
	editableSubmarketRecenetLeasesRow,
	editableSubmarketRecenetLeasesColumns,
	setEditableSubmarketRecenetLeasesRow,
	setEditableSubmarketRecenetLeasesColumns,
	recentLeasesSelection,
	data,
	submarket,
}: Props) => {
	const topNumber = editableSubmarketRecenetLeasesRow.length;
	if (!topNumber) return <></>;
	return (
		<TableContainer>
			<EditPageSectionTitle>
				<div>Submarket Recent Leases</div>
				<ExportPdfLeasesStatus data={data} />
			</EditPageSectionTitle>
			<TableTitle>
				{topNumber === 1
					? 'Most recent lease '
					: `Top ${topNumber} most recent leases `}
				in {submarket}
			</TableTitle>
			<EditPdfTable
				columns={editableSubmarketRecenetLeasesColumns}
				rows={editableSubmarketRecenetLeasesRow ?? []}
				onChange={({ row, column, value }) => {
					setEditableSubmarketRecenetLeasesRow((rows) => {
						return (
							rows &&
							rows.map((r) => {
								if (r.id === row.id) {
									return {
										...r,
										[column.id]: value,
									};
								}
								return r;
							})
						);
					});
				}}
				onToggleColumn={({ column }) => {
					setEditableSubmarketRecenetLeasesColumns((cols) =>
						cols.map((c) => {
							if (c.id === column.id) {
								return {
									...c,
									isVisible: !c.isVisible,
								};
							}
							return c;
						})
					);
				}}
				selection={{
					...recentLeasesSelection,
					areAllSelected: recentLeasesSelection.getAreAllSelected(),
					getIsRowSelected: (row) =>
						recentLeasesSelection.getIsRowSelected(row.id),
					toggleRow: (row) => {
						recentLeasesSelection.toggleRow(row.id);
					},
				}}
			/>
		</TableContainer>
	);
};
