import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { DashboardCardLoadingContainer } from 'PortfolioAnalytics/Dashboard/UI';
import { LeaseExpirationsBySpaceTypeChart } from './LeaseExpirationsBySpaceTypeChart';
import { BuildingClassChart } from './BuildingClassChart';
import { PropertyTypeChart } from '../Charts/PropertyTypeChart';
import { TenantExposureChart } from '../Charts/TenantExposureChart';
import { TenantIndustryExposureChart } from '../Charts/TenantIndustryExposureChart';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { ChartsContainer, Row } from '../styles/PortfolioUI';

type PortfolioTabChartsProps = {
	portfolioId: number;
};

const PortfolioTabCharts = ({ portfolioId }: PortfolioTabChartsProps) => {
	const { filters } = usePortfolioFilters();

	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery({
			id: portfolioId,
			filters,
		});

	if (portfolio == null || isFetchingPortfolio) {
		return (
			<DashboardCardLoadingContainer>
				<Spinner />
			</DashboardCardLoadingContainer>
		);
	}
	return (
		<ChartsContainer>
			<Row>
				<TenantExposureChart portfolio={portfolio} />
				<TenantIndustryExposureChart portfolio={portfolio} />
			</Row>
			<Row>
				<LeaseExpirationsBySpaceTypeChart portfolio={portfolio} />
			</Row>
			<Row>
				<PropertyTypeChart portfolio={portfolio} />
				<BuildingClassChart portfolio={portfolio} />
			</Row>
		</ChartsContainer>
	);
};

export default PortfolioTabCharts;
