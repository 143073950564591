// @ts-expect-error TS7006: Parameter 'state' implicitly h...
const optionBackgroundColor = (state) => {
	if (state.isFocused) return 'rgba(0, 126, 255, 0.08)';
	else if (state.isSelected) return 'rgba(0, 126, 255, 0.04)';
	else return '#fff';
};

export const selectorStyles = {
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	control: (oldStyles, state) => ({
		...oldStyles,
		height: '100%',
		borderColor: '#CCCCCC',
		borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : 4,
		borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : 4,
		boxShadow: 'none',
		'&:hover': {
			borderColor: '#CCCCCC',
		},
	}),
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	valueContainer: (oldStyles) => ({
		...oldStyles,
		height: '100%',
	}),
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	dropdownIndicator: (oldStyles) => ({
		...oldStyles,
		cursor: 'pointer',
		paddingLeft: 0,
		paddingRight: 4,
	}),
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	clearIndicator: (oldStyles) => ({
		...oldStyles,
		cursor: 'pointer',
	}),
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	option: (oldStyles, state) => {
		return {
			...oldStyles,
			cursor: 'pointer',
			color: '#333',
			backgroundColor: optionBackgroundColor(state),
			'&:hover': {
				backgroundColor: 'rgba(0, 126, 255, 0.08)',
			},
		};
	},
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	menu: (oldStyles) => ({
		...oldStyles,
		marginTop: 0,
		borderRadius: 0,
		boxShadow: 'none',
		border: '1px solid #CCCCCC',
		borderTop: 'none',
	}),
	// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
	menuList: (oldStyles) => ({
		...oldStyles,
		paddingTop: 0,
		paddingBottom: 0,
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
};
