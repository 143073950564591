import { useSearchPageContext } from 'Layouts/SearchProvider';
import styled from 'styled-components';
import IconChevronUp from 'ui/svg_icons/chevron_up.svg';
import IconCross from 'ui/svg_icons/cross.svg';
import IconLayers from 'ui/svg_icons/layers.svg';
import { useSearchMapState } from '../SearchMapProvider';

export const MapAnalyticsButton = () => {
	const { searchState, setSearchState } = useSearchPageContext();
	const [searchMapState, setSearchMapState] = useSearchMapState();

	let text = 'map analytics';
	let Icon = IconLayers;

	if (searchState.mapAnalyticsType) {
		text = 'change map';
		Icon = IconChevronUp;
	}
	if (searchMapState.isAnalyticsMenuOpen) {
		text = 'exit analytics';
		Icon = IconCross;
	}

	const onClick = () => {
		if (searchMapState.isAnalyticsMenuOpen) {
			setSearchMapState((s) => ({
				...s,
				isAnalyticsMenuOpen: false,
				isAnalyticsDetailMenuOpen: false,
			}));
			setSearchState((s) => ({
				...s,
				mapAnalyticsType: null,
			}));
		} else {
			setSearchMapState((s) => ({
				...s,
				isAnalyticsMenuOpen: true,
				isAnalyticsDetailMenuOpen: false,
			}));
		}
	};

	return (
		<Container>
			<StyledAnalyticsButton data-qa-id="map-analytics-btn" onClick={onClick}>
				<Icon fill="#fff" />
				{text}
			</StyledAnalyticsButton>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Button = styled.button`
	height: 40px;
	border: none;
	border-radius: 3px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-transform: uppercase;
	font-size: 10px;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
`;

const StyledAnalyticsButton = styled(Button)`
	background: #009470;
	color: ${(p) => p.theme.colors.white.white};
	padding: 0 15px;
	min-width: 137px;
	svg {
		margin-right: 0.75rem;
	}
`;
