import Between from 'Components/Filters/Base/Between';
import FallbackSwitcher from 'Components/Filters/Base/FallbackSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import TimespanButton from 'Components/Filters/Base/TimespanButton';
import React from 'react';

const attribute = 'expirationDate' as const;

type Props = {
	children?: React.ReactNode;
};
export const ExpirationDateFilter = ({ children }: Props) => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<>
					<Between
						attribute={props.attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						touch={props.touch}
						setError={props.setError}
					/>
					<div className="presets">
						<TimespanButton max={0} unit="day" {...props}>
							Show Expired
						</TimespanButton>
						<TimespanButton min={0} unit="day" roundTo="day" {...props}>
							Show Active
						</TimespanButton>
					</div>
					<FallbackSwitcher
						// @ts-expect-error ts-migrate(2769) FIXME: Property 'options' does not exist on type 'Intrins... Remove this comment to see the full error message
						attribute={props.attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						touch={props.touch}
						setError={props.setError}
						options={[
							{
								name: 'Show leases with no expiration date',
								value: true,
							},
							{
								name: 'Hide leases with no expiration date',
								value: false,
							},
						]}
					/>

					{children}
				</>
			)}
		</FilterBase>
	);
};
