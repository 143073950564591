import { Button, downloadFile } from '@compstak/ui-kit';
import { PDFDownloadLinkProps, pdf } from '@react-pdf/renderer';
import { useState } from 'react';
import { useExportPdfState } from './ExportPdfProvider';

type Props = {
	pdfDocument: PDFDownloadLinkProps['document'];
};

export const ExportPdfButton = ({ pdfDocument }: Props) => {
	const [isLoadingExportPdf, setIsLoadingExportPdf] = useState(false);
	const { exportPdfState, areImagesReady } = useExportPdfState();

	return (
		<Button
			variant="primary"
			onClick={async () => {
				setIsLoadingExportPdf(true);
				const pdfInstance = pdf();
				pdfInstance.updateContainer(pdfDocument);
				const blob = await pdfInstance.toBlob();
				const url = URL.createObjectURL(blob);
				downloadFile(url, `${exportPdfState.title}.pdf`);
				setIsLoadingExportPdf(false);
			}}
			isLoading={!areImagesReady || isLoadingExportPdf}
			disabled={!areImagesReady || isLoadingExportPdf}
		>
			Create Report
		</Button>
	);
};
