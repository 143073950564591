import React from 'react';

import { getActualLabel, getFloorsSizeChangeShouldAffect } from '../util';
import { Container, Content, Header, Buttons } from './Components';
import FloorSelector from './FloorSelector';

import styles from './drawer.less';

type State = any;

export default class EditFloorDrawer extends React.PureComponent<{}, State> {
	constructor(props: {}) {
		super(props);

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		const floors = this.props.stackingPlan.floors.map((floor) => {
			return getActualLabel(floor.label);
		});

		this.state = {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			label: this.props.floor.label,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			size: this.props.floor.size,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			position: this.props.stackingPlan.position,
			floors: floors,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			initialLabel: this.props.floor.label,
			labelAlreadyExists: false,
		};
	}

	cancel = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.floor.initialIndex !== undefined) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.undo();
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'closeDrawer' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.closeDrawer();
	};

	save = () => {
		if (!this.state.labelAlreadyExists) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			const index = this.getFloorIndex(this.props.floor);
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.updateFloor(
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				this.props.stackingPlan,
				index,
				this.state.size,
				this.state.label,
				this.state.position
			);
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'closeDrawer' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.closeDrawer();
		}
	};

	// @ts-expect-error TS7006: Parameter 'floor' implicitly h...
	getFloorIndex(floor) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		const floors = this.props.stackingPlan.floors;
		return floors.indexOf(floor) !== -1
			? floors.indexOf(floor)
			: floor.initialIndex;
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	update = (event) => {
		let { value, name } = event.target;

		if (name === 'position') {
			value = value.toLowerCase();
		}

		let setValue = true;

		if (name === 'size' && value) {
			if (parseInt(value)) {
				if (parseInt(value) > 0) {
					value = parseInt(value);
				} else {
					setValue = false;
				}
			} else {
				value = '';
			}
		}
		if (setValue) {
			this.setState({
				[name]: value,
			});
		}
	};

	// @ts-expect-error TS7006: Parameter 'label' implicitly h...
	updateFloorLabel = (label) => {
		const newLabel = getActualLabel(label);
		const initialLabel = getActualLabel(this.state.initialLabel);
		let labelAlreadyExists = false;

		if (newLabel !== initialLabel) {
			if (this.state.floors.includes(newLabel)) {
				labelAlreadyExists = true;
			}
		}

		this.setState({
			label: label,
			labelAlreadyExists: labelAlreadyExists,
		});
	};

	createMessage() {
		let message;

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (!this.state.size || this.state.size === this.props.floor.size) {
			return '';
		}

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		const { floors } = this.props.stackingPlan;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const floorIndex = this.getFloorIndex(this.props.floor);

		const [lowest, highest] = getFloorsSizeChangeShouldAffect(
			floorIndex,
			this.state.size,
			floors
		);

		if (lowest === highest) {
			message = 'This will apply only to this floor';
		}

		if (lowest < floorIndex) {
			if (lowest === 0) {
				message = 'Note: this will also apply to all floors below this one';
			} else {
				message =
					'Note: this will also apply to floors down to ' +
					floors[lowest].label;
			}
		} else if (highest > floorIndex) {
			if (highest === floors.length - 1) {
				message = 'Note: this will also apply to all floors above this one';
			} else {
				message =
					'Note: this will also apply to floors up to ' + floors[highest].label;
			}
		}

		return message;
	}

	render() {
		const message = this.createMessage();

		return (
			<Container>
				<Header onCancel={this.cancel}>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
					Edit Floor {this.props.floor.label}
				</Header>
				{/* @ts-expect-error ts-migrate(2769) FIXME: Property 'onCancel' does not exist on type 'Intrin... Remove this comment to see the full error message */}
				<Content onCancel={this.cancel} onSave={this.save}>
					<label className={styles.squareFeetLabel}>
						Floor Size
						<input
							onChange={this.update}
							type="text"
							name="size"
							value={this.state.size}
						/>
					</label>
					<div className={styles.messageText}>{message}</div>

					<label>
						Align Floor
						<select
							onChange={this.update}
							defaultValue={this.state.position}
							name="position"
						>
							<option value="left">Left</option>
							<option value="right">Right</option>
							<option value="center">Center</option>
						</select>
					</label>

					<FloorSelector
						{...this.props}
						// @ts-expect-error ts-migrate(2322) FIXME: Property 'labelAlreadyExists' does not exist on ty... Remove this comment to see the full error message
						labelAlreadyExists={this.state.labelAlreadyExists}
						updateFloorLabel={this.updateFloorLabel}
					/>

					<Buttons
						disabled={this.state.labelAlreadyExists ? 'disabled' : null}
						onCancel={this.cancel}
						onSave={this.save}
					/>
				</Content>
			</Container>
		);
	}
}
