import { createButton, defaultTheme } from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { Checkbox } from 'Components';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useLayoutContext } from './LayoutProvider';
import { useOptionsContext } from './OptionsProvider';
import { TableSettingsMenu } from './TableSettingsMenu';
import { CheckboxCell, averagesCellCss, baseCellCss } from './UI';
import { CHECKBOX_CELL_SIZE, HEADER_INNER_CELL_HEIGHT } from './constants';

export const ActionHeader = () => {
	const {
		showAverages,
		areAllSelected,
		areSomeSelected,
		toggleAllRowsSelection,
	} = useOptionsContext();
	const { actionColumnWidth, isCompact } = useLayoutContext();

	const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

	return (
		<Root
			style={{
				width: actionColumnWidth,
			}}
		>
			<TopCell>
				<CheckboxCell>
					<Checkbox
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={
							areAllSelected ? true : areSomeSelected ? 'indeterminate' : false
						}
						onCheckedChange={toggleAllRowsSelection}
						bgColorUnchecked={defaultTheme.colors.white.white}
					/>
				</CheckboxCell>
				<Popover.Root
					open={isSettingsMenuOpen}
					onOpenChange={setIsSettingsMenuOpen}
				>
					<Popover.Trigger asChild>
						<CustomizeButton>
							{isSettingsMenuOpen
								? 'Close'
								: isCompact
									? 'Customize'
									: 'Customize columns'}
						</CustomizeButton>
					</Popover.Trigger>
					<TableSettingsMenu />
				</Popover.Root>
			</TopCell>
			{showAverages && (
				<BottomCell>
					<div
						style={{ width: CHECKBOX_CELL_SIZE, backgroundColor: '#dfe1e7' }}
					/>
					<AveragesCell>AVERAGES</AveragesCell>
				</BottomCell>
			)}
		</Root>
	);
};

const Root = styled.div`
	flex-shrink: 0;
	border-right: 1px solid #ddd;
	background-color: #f0f1f4;
`;

const TopCell = styled.div`
	display: flex;
	align-items: center;
	height: ${HEADER_INNER_CELL_HEIGHT}px;
	padding: 6px;
	padding-left: 0;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
`;

const CustomizeButton = createButton(
	() => css`
		background-color: ${(p) => p.theme.colors.white.white};
		color: ${(p) => p.theme.colors.gray.gray700};
		font-weight: 500;
		font-size: 11px;
		height: 20px;
		width: 100%;
		&:hover {
			background-color: ${(p) => p.theme.colors.neutral.n40};
		}
	`
);

const BottomCell = styled.div`
	display: flex;
	height: ${HEADER_INNER_CELL_HEIGHT}px;
`;

const AveragesCell = styled.div`
	${baseCellCss}
	${averagesCellCss}
  flex: 1;
	justify-content: center;
`;
