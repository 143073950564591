import React, { useMemo } from 'react';
import { ContentWrap, GridWrap, MethodologySection } from './styles';
import { LargestTenantsTable } from './Tables/LargestTenantsTable';
import { LargestLandlordTable } from './Tables/LargestLandlordTable';
import { omit } from '../../utils';
import { ActiveBuyersTable } from './Tables/ActiveBuyersTable';
import { ActiveSellersTable } from './Tables/ActiveSellersTable';
import { ActiveTenantsTable } from './Tables/ActiveTenantsTable';
import { ActiveLandlordTable } from './Tables/ActiveLandlordTable';
import { CommonFilters, PremierListTableProps } from './PremierList';
import { usePermissions } from '../Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import { PremierListSpaceType } from 'api/premierList/cleanSectors';

interface PremierListContentProps {
	selectedMarket: string;
	selectedSpaceTypeName: PremierListSpaceType;
}

export const PremierListContent = ({
	selectedMarket,
	selectedSpaceTypeName,
}: PremierListContentProps) => {
	const permissions = usePermissions();
	const markets = useMarkets();

	const commonFilters: CommonFilters = useMemo(() => {
		return {
			markets: [markets[selectedMarket]],
			hidden: null,
			partial: 'false',
		};
	}, [markets, selectedMarket]);

	const premierListTableProps: PremierListTableProps = useMemo(
		() => ({
			commonFilters,
			selectedSpaceTypeName,
		}),
		[commonFilters, selectedSpaceTypeName]
	);

	const hasSalesAccess = permissions[markets[selectedMarket].id].salesAccess;
	const hasLeasesAccess = permissions[markets[selectedMarket].id].leaseAccess;

	return (
		<ContentWrap>
			<GridWrap>
				{hasLeasesAccess && <LargestTenantsTable {...premierListTableProps} />}
				<LargestLandlordTable
					{...premierListTableProps}
					commonFilters={omit(
						premierListTableProps.commonFilters,
						'hidden',
						'partial'
					)}
				/>
				{hasSalesAccess && <ActiveBuyersTable {...premierListTableProps} />}
				{hasSalesAccess && <ActiveSellersTable {...premierListTableProps} />}
				{hasLeasesAccess && <ActiveTenantsTable {...premierListTableProps} />}
				{hasLeasesAccess && <ActiveLandlordTable {...premierListTableProps} />}
			</GridWrap>
			<MethodologySection>
				<title>Our Methodology</title>
				<p>
					The <strong>Top of the Stak</strong> relies on CompStak's
					comprehensive database, presenting a snapshot of market dynamics.
					These rankings are based only on CompStak data and may not encompass
					the entire spectrum of market activity. <br />
					<br />
					All tables are updated as CompStak ingests new data. "Activity" tables
					include leases within the past 365 days. "Largest" tables are based on
					active, unexpired leases and sales transactions. In instances of joint
					ventures, each participating entity is assigned the full value of the
					deal, acknowledging their collective market contributions.
				</p>
			</MethodologySection>
		</ContentWrap>
	);
};
