import { API } from '@compstak/common';
import {
	BingMapsConfigProvider,
	GoogleMapConfigProvider,
	MapboxConfigProvider,
} from '@compstak/maps';
import { NotificationProvider, defaultTheme } from '@compstak/ui-kit';
import isPropValid from '@emotion/is-prop-valid';
import { QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'Components/ErrorBoundary';
import {
	SearchPropertiesNavHeaderProvider,
	SearchSalesNavHeaderProvider,
} from 'Components/PrevNextHeader';
import { LogoutEffects } from 'LogoutEffects';
import { PropertyPageTabProvider } from 'Pages/PropertyPageV3/Tabs/TabProvider';
import Singletons from 'Singletons';
import { QUERY_CLIENT } from 'api/queryClient';
import { getAccessToken } from 'auth/auth';
import { enableMapSet } from 'immer';
import { ModalProvider } from 'providers/ModalProvider';
import { ToastProvider } from 'providers/ToastProvider';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { ExchangeDashboardProvider } from 'reducers/exchangeDashboard';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { HistoryProvider } from 'router/HistoryProvider';
import { StyleSheetManager, ThemeProvider, WebTarget } from 'styled-components';
import 'stylesheets/base.nomodule.less';
import 'ui/styles/fonts.less';
import { RoutesComponent } from './router/RoutesComponent';
import store, { history } from './store';

API.init({ getAccessToken });

enableMapSet();

const AppEntry = () => {
	const appConfig = store.getState().appConfig;

	return (
		<ErrorBoundary>
			<Provider store={store}>
				<Router history={history}>
					<StyleSheetManager shouldForwardProp={shouldForwardProp}>
						<ThemeProvider theme={defaultTheme}>
							<NotificationProvider>
								<ExchangeDashboardProvider>
									<QueryClientProvider client={QUERY_CLIENT}>
										<ToastProvider>
											<ModalProvider>
												<LogoutEffects />
												<GoogleMapConfigProvider
													googleApiKey={appConfig.google.apiKey}
												>
													<MapboxConfigProvider
														accessToken={appConfig.mapbox.accessToken}
													>
														<BingMapsConfigProvider
															bingMapsKey={appConfig.bing.accessToken}
														>
															<HistoryProvider>
																<PropertyPageTabProvider>
																	<SearchSalesNavHeaderProvider>
																		<SearchPropertiesNavHeaderProvider>
																			<RoutesComponent />
																		</SearchPropertiesNavHeaderProvider>
																	</SearchSalesNavHeaderProvider>
																</PropertyPageTabProvider>
															</HistoryProvider>
														</BingMapsConfigProvider>
													</MapboxConfigProvider>
												</GoogleMapConfigProvider>
												<Singletons />
											</ModalProvider>
										</ToastProvider>
									</QueryClientProvider>
								</ExchangeDashboardProvider>
							</NotificationProvider>
						</ThemeProvider>
					</StyleSheetManager>
				</Router>
			</Provider>
		</ErrorBoundary>
	);
};

export default DragDropContext(HTML5Backend)(AppEntry);

function shouldForwardProp(propName: string, target: WebTarget) {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
}
