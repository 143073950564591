import { Select, SelectListItem, SelectContainer } from '@compstak/ui-kit';
import { isEqual } from 'lodash';
import { CriterionDuration } from '../PortfolioLeasesFiltersProvider';
import styled from 'styled-components';

export type CriterionDurationSelectProps = {
	onChange: (value: CriterionDuration) => void;
	value: CriterionDuration;
};

type CriterionDurationSelectItem = SelectListItem & {
	durationValue: CriterionDuration;
};

const OPTIONS: CriterionDurationSelectItem[] = [
	{
		title: '7 days',
		value: '7 days',
		durationValue: { value: 7, unit: 'days' },
	},
	{
		title: '30 days',
		value: '30 days',
		durationValue: { value: 30, unit: 'days' },
	},
	{
		title: '90 days',
		value: '90 days',
		durationValue: { value: 90, unit: 'days' },
	},
	{
		title: '6 months',
		value: '6 months',
		durationValue: { value: 6, unit: 'months' },
	},
	{
		title: '12 months',
		value: '12 months',
		durationValue: { value: 12, unit: 'months' },
	},
];

export const CriterionDurationSelect = ({
	value,
	onChange,
}: CriterionDurationSelectProps) => {
	return (
		<SelectWrap>
			<Select
				items={OPTIONS}
				value={
					OPTIONS.find(({ durationValue }) => isEqual(durationValue, value))
						?.value
				}
				onChange={({ selectedItem }) => {
					if (!selectedItem) {
						return;
					}
					const { durationValue } = selectedItem;
					onChange(durationValue);
				}}
			/>
		</SelectWrap>
	);
};

const SelectWrap = styled.div`
	${SelectContainer} {
		margin-right: 0;
	}
`;
