import { Image } from '@react-pdf/renderer';
import { LargeTenantsResponse } from 'api/largestTenants/useFetchLargestTenantsQuery';
import { formatNumber } from 'format';
import { PdfSectionBox } from '../PdfUI';

type Props = {
	url: string | null;
	largestTenantsData: LargeTenantsResponse | undefined;
};

export const LargestTenantsPdfSection = ({
	url,
	largestTenantsData,
}: Props) => {
	if (!largestTenantsData) return null;

	return (
		<PdfSectionBox
			title="Largest Tenants"
			topRightInfo={`${formatNumber(
				largestTenantsData.averageSqft
			)} sqft, Average Tenant Size`}
		>
			{url && <Image src={url} />}
		</PdfSectionBox>
	);
};
