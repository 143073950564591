import React, { memo } from 'react';
import styled from 'styled-components';
import { Flex, AlertCircle } from '@compstak/ui-kit';
import { useHasErrored } from 'hooks/useHasErrored';

type Props = {
	isSuccess: boolean;
	isError: boolean;
};

export const DataSetErrorIndicator = memo(({ isSuccess, isError }: Props) => {
	const datasetHasErrors = useHasErrored({ isSuccess, isError });

	if (!datasetHasErrors) {
		return null;
	}

	return (
		<Flex alignItems="center">
			<StyledErrorIcon
				data-tooltip={
					'This dataset was unable to establish a connection.' +
					'The line will not be shown.' +
					'Please remove it and try again'
				}
				data-tooltip-position="onright"
				data-tooltipdelay="0"
			/>
		</Flex>
	);
});

DataSetErrorIndicator.displayName = 'DataSetErrorIndicator';

const StyledErrorIcon = styled(AlertCircle)`
	svg {
		color: white;
		width: 19px;
	}
`;
