import { useSpaceTypeAggregations } from 'api/spaceTypeAggregations/useSpaceTypeAggregations';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import Multiselect, { FilterOption } from 'Components/Filters/Base/Multiselect';
import { useReferenceData } from 'Pages/Login/reducers';
import { ReactNode, useMemo } from 'react';
import { alphabeticalOrder } from '../../../../utils';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import {
	getCountForAggregation,
	renderAggregationOption,
} from './aggregationFilterHelper';
import { FilterDescription } from '../../Base/Filter/FilterDescription';
import { getLabelForFilterAttribute } from 'models/filters/util/filterHelpers';

const attribute = 'buildingPropertySubtype' as const;

type PropertySubtypeFilterProps = {
	children?: ReactNode;
	inputId?: string;
};

export const PropertySubtypeFilter = ({
	children,
	inputId,
}: PropertySubtypeFilterProps) => {
	const { compType, context, filters } = useFilterFieldContext();
	const referenceData = useReferenceData();

	const { data: aggregationsData } = useSpaceTypeAggregations({
		compType,
		filters,
	});
	const aggregation = aggregationsData?.buildingPropertySubtype;

	const options: FilterOption[] = useMemo(() => {
		if (!referenceData?.filterablePropertySubtypes) {
			return [];
		}

		const filterablePropertySubtypes =
			referenceData.filterablePropertySubtypes.filter(
				(option) =>
					getCountForAggregation({
						aggregation,
						option,
						useStringNames: true,
					}) > 0
			);

		return alphabeticalOrder({
			list: filterablePropertySubtypes,
			sortByProperty: 'name',
		});
	}, [aggregation, referenceData?.filterablePropertySubtypes]);

	return (
		<FilterBase
			inputId={inputId}
			attribute={attribute}
			labelSynonyms={[
				getLabelForFilterAttribute({
					attribute: 'buildingPropertyTypeId',
					compType,
				}),
			]}
		>
			{(props) => (
				<>
					<FilterDescription>
						Property subtype typically represents the majority use of a
						property; applying this filter may yield leases that differ from the
						predominant use.
					</FilterDescription>
					<Multiselect
						attribute={attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						options={options}
						renderOption={(option, searchTerm) =>
							renderAggregationOption({
								aggregation,
								option,
								useStringNames: true,
								searchTerm,
								shouldRenderAggregationCount: context !== 'analytics',
							})
						}
						setError={props.setError}
						touch={props.touch}
						usesStringNames={true}
						hasSearch
						searchInputPlaceholder="Search for a property subtype"
						initialExpanded={!props.nestedFilterIsSet}
					/>
					{children}
				</>
			)}
		</FilterBase>
	);
};
