import React, { memo } from 'react';
import { emailConstants, phoneNumberConstants } from '@compstak/common';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useModal } from 'providers/ModalProvider';

type ThanksForDemoRequestModalProps = {
	onClose?: () => void;
};

export const ThanksForDemoRequestModal = memo(
	({ onClose: _onClose }: ThanksForDemoRequestModalProps) => {
		const { closeModal } = useModal();

		const onClose = () => {
			closeModal();
			_onClose?.();
		};

		return (
			<div data-qa-id="thanks-for-demo-request-modal">
				<ModalTitle>Thanks for your interest!</ModalTitle>
				<ModalParagraph>
					A member of our team will contact you to schedule a demo.
				</ModalParagraph>
				<ModalParagraph>
					Have questions? Reach out to us at{' '}
					<a
						data-qa-id="thanks-for-demo-request-modal-email"
						href={`mailto:${emailConstants.help}`}
					>
						{emailConstants.help}
					</a>{' '}
					or call us at{' '}
					<a
						data-qa-id="thanks-for-demo-request-modal-phone"
						href={`tel:${phoneNumberConstants.phone2.dashed}`}
					>
						{phoneNumberConstants.phone2.dashed}
					</a>
					.
				</ModalParagraph>
				<ModalButtons>
					<ModalButton variant="primary" onClick={onClose}>
						Close
					</ModalButton>
				</ModalButtons>
			</div>
		);
	}
);

ThanksForDemoRequestModal.displayName = 'ThanksForDemoRequestModal';
