import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'isPortfolio' as const;

export const PortfolioFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					options={[
						{
							title: 'Only show multi-property sales',
							value: 'true',
						},
						{
							title: 'Exclude multi-property sales',
							value: 'false',
						},
					]}
					{...props}
				/>
			)}
		</FilterBase>
	);
};
