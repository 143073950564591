import {
	DefaultDialogCloseButton,
	DefaultDialogCloseIcon,
	Flex,
} from '@compstak/ui-kit';
import {
	ExportPdfContainer,
	ExportPdfPage,
	ExportPdfToolbar,
	ExportPdfToolbarContainer,
} from 'Components';
import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { useExchangeDashboardLargeLandlords } from 'ExchangeDashboard/hooks/largestLandlords/useExchangeDashboardLargeLandlords';
import { useExchangeDashboardLargeTenants } from 'ExchangeDashboard/hooks/largestTenants/useExchangeDashboardLargeTenants';
import { useExchangeDashboardLeaseExpirationByPropertyType } from 'ExchangeDashboard/hooks/leaseExpirationByPropertyType/useExchangeDashboardLeaseExpirationByPropertyType';
import { useExchangeDashboardLeaseExpirationBySubmarket } from 'ExchangeDashboard/hooks/leaseExpirationBySubmarket/useExchangeDashboardLeaseExpirationBySubmarket';
import { useExchangeDashboardLeasesActivity } from 'ExchangeDashboard/hooks/leasesActivity/useExchangeDashboardLeasesActivity';
import { useExchangeDashboardSalesActivity } from 'ExchangeDashboard/hooks/salesActivity/useExchangeDashboardSalesActivity';
import { useExchangeDashboardStartingRent } from 'ExchangeDashboard/hooks/startingRent/useExchangeDashboardStartingRent';
import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import { useReferenceData, useUser } from 'Pages/Login/reducers';
import { useSearch } from 'Pages/Search/searchReducer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import { removeFeedback } from 'Singletons/Feedback/actions';
import { hideModal } from 'Singletons/Modal/actions';
import styled from 'styled-components';
import { SalesComp } from 'types';
import button from 'ui/styles/button.less';
import { formatComp } from 'util/comp-format/src/format';
import { dashboardModal } from './DashboardExportModal';
import { DashboardPreviewHeader } from './DashboardPreviewHeader';
import { AveragesPanel } from './PreviewComponents/AveragesPanel';
import LargestLandlords from './PreviewComponents/LargestLandlords';
import LargestTenants from './PreviewComponents/LargestTenants';
import {
	componentMap,
	ComponentPreviewType,
	ComponentWrap,
	Page,
	Panel,
	PDFFooter,
} from './UI';
import { useAdaptedMarketRentExportData } from './useAdaptedMarketRentExportData';
import { useAdaptedTenantIndustryExportData } from './useAdaptedTenantIndustryExportData';
import { useDashboardExportContext } from './DashboardExportProvider';

const groupSize = 2;

const TABLE_ORDER = [
	'recentlySignedLeases',
	'largestRecentLeases',
	'recentTransactions',
	'largeSales',
] as const;

type Props = {
	onStepBack: () => void;
	closeDialog: () => void;
};

export const StepTwo = (props: Props) => {
	const { selectedComponents, userEdits, setUserEdits, salesAccess } =
		useDashboardExportContext();
	const [filters] = useFilters('exchange-dashboard');
	const currentUser = useUser();
	const dispatch = useDispatch();
	const referenceData = useReferenceData();
	const { marketIdToSubmarkets } = useSearch();
	const submarkets = marketIdToSubmarkets?.[filters.market.id] ?? null;

	const marketRentState = useAdaptedMarketRentExportData();
	const { data: tenantIndustryState } = useAdaptedTenantIndustryExportData({
		enabled: selectedComponents.includes('tenantIndustryMap'),
	});

	const { averages: _averages, timeline } = useExchangeDashboardAverages({
		enabled: selectedComponents.includes('averages'),
	});
	const averages = { ..._averages, timeline };

	const {
		startingRentData: _startingRentData,
		monthly,
		movingAverage,
	} = useExchangeDashboardStartingRent({
		enabled: selectedComponents.includes('startingRent'),
	});
	const startingRentData = {
		data: _startingRentData,
		monthly,
		movingAverage,
	};

	const leaseExpirationPropertyTypeData =
		useExchangeDashboardLeaseExpirationByPropertyType({
			enabled: selectedComponents.includes('leaseExpirationPropertyType'),
		});

	const leaseExpirationSubmarketData =
		useExchangeDashboardLeaseExpirationBySubmarket({
			enabled: selectedComponents.includes('leaseExpirationSubmarket'),
		});

	const tableComponents = TABLE_ORDER.filter((tableComponent) =>
		selectedComponents.includes(tableComponent)
	).filter((sc) => {
		return filterLargeSalesAndRecentTransactionsIfNeeded(sc, salesAccess);
	});

	const { data: largeSales } = useExchangeDashboardSalesActivity({
		activeTab: 'totalSalePrice',
		enabled: selectedComponents.includes('largeSales'),
	});

	const { data: recentTransactions } = useExchangeDashboardSalesActivity({
		activeTab: 'saleDate',
		enabled: selectedComponents.includes('recentTransactions'),
	});

	const { data: recentlySignedLeases } = useExchangeDashboardLeasesActivity({
		activeTab: 'executionDate',
		enabled: selectedComponents.includes('recentlySignedLeases'),
	});

	const { data: largestRecentLeases } = useExchangeDashboardLeasesActivity({
		activeTab: 'transactionSize',
		enabled: selectedComponents.includes('largestRecentLeases'),
	});

	const topIndustriesWithCounts =
		useExchangeDashboardTenantIndustryDistribution({
			enabled: selectedComponents.includes('tenantIndustryDistribution'),
		});

	const { data: largestLandlordsData } = useExchangeDashboardLargeLandlords({
		enabled: selectedComponents.includes('largestLandlords'),
	});

	const { data: largestTenantsData } = useExchangeDashboardLargeTenants({
		enabled: selectedComponents.includes('largestLandlords'),
	});

	const tableData = {
		recentTransactions:
			recentTransactions?.comps.map((s) =>
				formatComp(s as unknown as SalesComp, 'sale')
			) ?? [],
		largeSales:
			largeSales?.comps.map((s) =>
				formatComp(s as unknown as SalesComp, 'sale')
			) ?? [],
		recentlySignedLeases:
			recentlySignedLeases?.comps.map((l) =>
				formatComp(l as unknown as SalesComp, 'lease')
			) ?? [],
		largestRecentLeases:
			largestRecentLeases?.comps.map((l) =>
				formatComp(l as unknown as SalesComp, 'lease')
			) ?? [],
	};

	const { setStep } = useDashboardExportContext();

	const handleStepSubmission = () => {
		const date = userEdits.createdOn;
		const marketName = filters.market.displayName ?? '';
		const title = `CompStak Report - ${marketName} ${date}`;
		dispatch({
			type: 'EXPORT_MARKET_DASHBOARD',
			meta: {
				feedback: {
					className: function () {
						return 'center';
					},
					pending: 'Exporting Comps...',
					fulfilled: {
						duration: 0,
						message: `Export of ${title} complete.`,
						buttons: [
							{
								text: 'Make Changes',
								action: () => {
									setStep('stepOne');
								},
							},
							{
								text: 'Dismiss',
								// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
								action: (alertId) => removeFeedback(alertId),
							},
						],
					},
				},
			},
			payload: {
				promise: new Promise((resolve) => {
					const form = document.createElement('form');
					form.method = 'POST';
					form.action = '/api/pdf-gen/exchange-dashboard/';
					const input = document.createElement('input');
					input.type = 'hidden';
					input.name = 'jsonData';
					input.value = JSON.stringify({
						data: {
							...tableData,
							userEdits,
							tenantIndustryState,
							marketRentState,
							submarkets,
							referenceData,
							filters,
							currentUser,
							averages,
							startingRentData,
							leaseExpirationPropertyTypeData,
							leaseExpirationSubmarketData,
							topIndustriesWithCounts,
							largestLandlordsData,
							largestTenantsData,
						},
						selectedComponents,
						directDownload: true,
					});

					form.appendChild(input);
					document.body.appendChild(form);
					form.submit();

					setTimeout(() => {
						dispatch(hideModal(dashboardModal));
						document.body.removeChild(form);
						resolve(null);
					}, 1000);
				}),
			},
		});
	};

	const HALF_PANELS = [
		'marketRentHeatMap',
		'startingRent',
		'leaseExpirationPropertyType',
		'leaseExpirationSubmarket',
		'tenantIndustryMap',
		'tenantIndustryDistribution',
	] as const;

	const selectedHalfPanels = HALF_PANELS.filter((halfPanel) =>
		selectedComponents.includes(halfPanel)
	);

	return (
		<Container>
			<ExportPdfToolbarContainer>
				<ExportPdfToolbar>
					<button
						className={button.liteBlue}
						onClick={props.onStepBack}
						data-qa-id="back"
					>
						Back
					</button>
					<Flex style={{ marginLeft: 'auto' }}>
						<button
							className={button.gray}
							onClick={props.closeDialog}
							data-qa-id="cancel"
						>
							Cancel
						</button>
						<button
							className={button.blue}
							onClick={handleStepSubmission}
							data-qa-id="create"
						>
							Create Report
						</button>
						<DefaultDialogCloseButton
							style={{ position: 'unset' }}
							onClick={props.closeDialog}
						>
							<DefaultDialogCloseIcon />
						</DefaultDialogCloseButton>
					</Flex>
				</ExportPdfToolbar>
			</ExportPdfToolbarContainer>
			<Title>
				<BlueText>Step 2:</BlueText> Update Report Header
			</Title>
			<ExportPdfContainer>
				<ExportPdfPage>
					<Page className="page">
						<DashboardPreviewHeader
							submarkets={submarkets}
							userEdits={userEdits}
							setUserEdits={setUserEdits}
						/>
						{selectedComponents.includes('averages') && (
							<ComponentWrap>
								<AveragesPanel />
							</ComponentWrap>
						)}
						<PDFFooter />
					</Page>
				</ExportPdfPage>
				<div className="components">
					{selectedHalfPanels
						.map((selectedComponent) => {
							const Component = componentMap[selectedComponent];
							return (
								<Panel key={selectedComponent}>
									<Component />
								</Panel>
							);
						})
						.reduce((r, element, index) => {
							// Setting the max panels per page
							// @ts-expect-error TS2345: Argument of type 'never[]' is ...
							index % groupSize === 0 && r.push([]);
							// @ts-expect-error TS2339: Property 'push' does not exist...
							r[r.length - 1].push(element);
							return r;
						}, [])
						.map((rowContent, i) => (
							<ExportPdfPage key={i}>
								<Page className="page">
									<ComponentWrap>{rowContent}</ComponentWrap>
									<PDFFooter />
								</Page>
							</ExportPdfPage>
						))}
					{(selectedComponents.includes('largestLandlords') ||
						selectedComponents.includes('largestTenants')) && (
						<ExportPdfPage>
							<Page className="page">
								{selectedComponents.includes('largestLandlords') && (
									<ComponentWrap>
										<LargestLandlords />
									</ComponentWrap>
								)}
								{selectedComponents.includes('largestTenants') && (
									<ComponentWrap>
										<LargestTenants />
									</ComponentWrap>
								)}
								<PDFFooter />
							</Page>
						</ExportPdfPage>
					)}

					{tableComponents.map((tableComponent) => {
						const Component = componentMap[tableComponent];
						return (
							<ExportPdfPage key={tableComponent}>
								<Page className="page">
									<ComponentWrap>
										<Component data={tableData} />
									</ComponentWrap>
									<PDFFooter />
								</Page>
							</ExportPdfPage>
						);
					})}
				</div>
			</ExportPdfContainer>
		</Container>
	);
};

const Container = styled.div`
	background-color: #efefef;
	padding: 50px;
	width: 1100px;
	margin: 0 auto;
	position: relative;
	top: 50px;
	.react-autosuggest__suggestion {
		border-bottom: 1px solid #000;
		padding: 4px 0;
		&:last-child {
			border-bottom: none;
		}
	}
`;

const Title = styled.h1`
	font-size: 24px;
	text-align: left;
`;

const BlueText = styled.span`
	color: #228fff;
`;

export const filterLargeSalesAndRecentTransactionsIfNeeded = (
	item: ComponentPreviewType,
	hasSalesAccess?: boolean
) => {
	if (hasSalesAccess) return true;
	if (item === 'largeSales') return false;
	if (item === 'recentTransactions') return false;
	return true;
};
