import { Button as OriginalButton } from '@compstak/ui-kit';
import { ComponentProps } from 'react';
import styled from 'styled-components';

const Button = styled(OriginalButton)`
	font-size: 11px;
	text-transform: none;
    margin: 2px 7px 0 0;
    padding: 7px 10px 7px 10px;
    min-width: auto;

    @media @screen1024 {
        margin: 0 2px 0 0;
    }
`;

export const FilterFieldButton = (props: ComponentProps<typeof OriginalButton>) => (
	<Button
		variant="secondary"
        {...props}
	>
		{props.children}
	</Button>
);
