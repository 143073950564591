import { ExpensesPerUnitDTO } from 'api';
import { ExpensesPerUnit } from './SubmarketExpensesPerUnit';

export const adaptData = (data: ExpensesPerUnitDTO | undefined) => {
	if (
		!data?.histogram?.length ||
		!data?.histogram?.some((obj) => Object.keys(obj).length > 1)
	)
		return [];

	const expensesPerUnitDefaultObj = {
		[ExpensesPerUnit.TAXES_INSURANCE]: '',
		[ExpensesPerUnit.MARKETING]: '',
		[ExpensesPerUnit.ADMIN]: '',
		[ExpensesPerUnit.OPERATING_MAINTENANCE]: '',
		[ExpensesPerUnit.UTILITIES]: '',
		[ExpensesPerUnit.PAYROL_RELATED]: '',
	};

	return data.histogram.map(({ date, ...rest }) =>
		Object.assign({}, expensesPerUnitDefaultObj, { name: date, ...rest })
	);
};
