import { Market } from '@compstak/common';
import { closeMarketSelector } from 'actions/home';
import { deselectMapControl } from 'actions/search';
import { setMarkets } from 'actions/user';
import { FiltersObject } from 'models/filters/types';
import { useUser } from 'Pages/Login/reducers';
import { useDispatch } from 'react-redux';
import { FiltersType, useFilters } from 'reducers/filtersReducer';

type UseChangeMarketsProps = {
	/**
	 * @default true
	 */
	multiMarketMode?: boolean;
	/**
	 * @default 'main'
	 */
	filterContext?: FiltersType;
};

export const useChangeMarkets = (props?: UseChangeMarketsProps) => {
	const { multiMarketMode = true, filterContext = 'main' } = props ?? {};
	const [, setFilters, resetFilters] = useFilters(filterContext);

	const dispatch = useDispatch();
	const user = useUser()!;

	return (markets: Market[], needToReset = true) => {
		dispatch(closeMarketSelector());
		dispatch(deselectMapControl());
		const filters: Partial<FiltersObject> = {
			...(multiMarketMode ? { markets } : { market: markets[0] }),
			submarkets: null,
		};
		needToReset ? resetFilters(filters) : setFilters(filters);
		dispatch(setMarkets(user, markets, multiMarketMode));
	};
};
