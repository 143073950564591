import { Spinner } from '@compstak/ui-kit';
import { usePropertyByIdQuery } from 'api';
import { AddToPortfolioButton } from './AddToPortfolioButton';
import { GenerateCompSetButton } from './GenerateCompSetButton';
import { PropertyDetails } from './PropertyDetails';
import { PropertyPageLink } from './PropertyPageLink';
import { PropertyPopupHeader } from './PropertyPopupHeader';
import {
	CompPopupActions,
	CompPopupActionsV2,
	CompPopupRoot,
	CompPopupRootLoading,
} from './UI';
import { useShouldShowPortfolioButton } from './useShouldShowPortfolioButton';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PropertyPageLinkV2 } from './PropertyPageLinkV2';
import { NearbyCompsButton } from './NearbyCompsButton';
import { MapPopupAddToPortfolioButton } from './MapPopupAddToPortfolioButton';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

export const PropertyPopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	const showPortfolioButton = useShouldShowPortfolioButton();

	const { portfolioRevampFF } = useFeatureFlags();

	if (!property) {
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);
	}

	return (
		<CompPopupRoot>
			<PropertyPopupHeader property={property} closePopup={closePopup} />
			<PropertyDetails property={property} />
			{portfolioRevampFF ? (
				<CompPopupActionsV2>
					<PropertyPageLinkV2 propertyId={property.id}>
						Property Info & Comp Set
					</PropertyPageLinkV2>

					<NearbyCompsButton
						closePopup={closePopup}
						compType="property"
						property={property}
						data-qa-id="nearby-comps-btn"
					>
						Nearby Properties
					</NearbyCompsButton>
					{showPortfolioButton && (
						<MapPopupAddToPortfolioButton
							variant="secondary"
							propertyIds={[property.id]}
						/>
					)}
				</CompPopupActionsV2>
			) : (
				<CompPopupActions>
					<PropertyPageLink propertyId={property.id} className="single-child">
						View Property Report
					</PropertyPageLink>
					{showPortfolioButton && <AddToPortfolioButton property={property} />}
					<GenerateCompSetButton property={property} closePopup={closePopup} />
				</CompPopupActions>
			)}
		</CompPopupRoot>
	);
};
