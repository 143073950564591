import { Spinner } from '@compstak/ui-kit';
import { useEffect, useState } from 'react';
import { PromotionsRouteSearchParams, routes } from 'router';
import {
	useGetPublicPromotions,
	useUserCanSeePromotions,
} from '../../api/promotions/promotionQueries';
import { useShouldRedirect } from '../../hooks/useShouldRedirect';
import { Promotion } from './promotionTypes';
import { PromoSimpleWarning } from './promotionUtilComponents';
import { promotionListItemId } from './promotionsList';
import { PromotionsListAndFocusedPromo } from './promotionsListAndFocusedPromo';

export function PromotionsTabContentLoader() {
	const { data: promotions, isLoading, error } = useGetPublicPromotions();
	const canSeePromos = useUserCanSeePromotions();
	useShouldRedirect(!canSeePromos, '/');

	if (!canSeePromos) {
		return <PromoSimpleWarning>Redirecting...</PromoSimpleWarning>;
	}

	if (error) {
		return (
			<PromoSimpleWarning>
				<p>Error loading promotions.</p>
				<p>Please refresh this page or check back in a few minutes.</p>
			</PromoSimpleWarning>
		);
	}

	if (isLoading || !promotions) {
		return <Spinner isCentered />;
	}

	return <PromotionsTabContent {...{ promotions }} />;
}

function PromotionsTabContent({ promotions }: { promotions: Promotion[] }) {
	const [searchParams, setSearchParams] = routes.promotions.useSearchParams();

	const [selectedPromoId, setSelectedPromoId] = useState<number | null>(
		getFocusedPromoIdFromUrlSearchParams(promotions, searchParams)
	);

	useEffect(
		() => {
			const id = getFocusedPromoIdFromUrlSearchParams(promotions, searchParams);
			if (!id) return;
			const el = document.getElementById(promotionListItemId(id));
			if (!el) return;
			el.scrollIntoView();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<PromotionsListAndFocusedPromo
			{...{ promotions, selectedPromoId }}
			onSelectPromo={(id) => {
				setSelectedPromoId(id);
				if (id == null) return;
				setSearchParams({
					'promo-id': id,
				});
			}}
		/>
	);
}

function getFocusedPromoIdFromUrlSearchParams(
	promotions: Promotion[],
	searchParams: PromotionsRouteSearchParams
): number | null {
	const id = searchParams['promo-id'];
	if (id != null && promotions.some((promo) => promo.id === id)) {
		return id;
	}
	return promotions?.[0]?.id ?? null;
}
