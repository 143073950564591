import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api';

export const invalidatePortfolioQueries = (queryClient: QueryClient) => {
	queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
	queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_PROPERTIES]);
	queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_PROPERTIES_SUMMARY]);
	queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_LEASES]);
	queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_BY_ID]);
	queryClient.invalidateQueries([QUERY_KEYS.SEARCH_LEASES]);
	queryClient.invalidateQueries([QUERY_KEYS.SEARCH_LEASES_INFINITE]);
	queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_PROPERTIES_INFINITE]);
	queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_MARKET_TREND]);
};
