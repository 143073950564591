import { useMarkets } from 'hooks/useMarkets';
import { useMemo } from 'react';
import styled from 'styled-components';
import { CompType } from 'types';
import { SuggestionSection, SuggestionsContainer } from '../SearchBar';
import { RecommendedSearchLayout } from './RecommendedSearchLayout';
import { useRecommendedSearches } from './useRecommendedSearches';

type RecommendedSearchesProps = {
	compType: CompType;
};

export const RecommendedSearchesLayout = ({
	compType,
}: RecommendedSearchesProps) => {
	const markets = useMarkets().list;
	const recommendedSearches = useRecommendedSearches()[compType];

	const recommendedSearchesWithMarketsFilter = useMemo(() => {
		return recommendedSearches.map((rs) => ({
			...rs,
			filters: { ...rs.filters, markets },
		}));
	}, [recommendedSearches, markets]);

	return (
		<SuggestionsContainer tabIndex={0}>
			<SuggestionSection>
				<h4>CompStak’s Recommended Searches</h4>
				<Container>
					{recommendedSearchesWithMarketsFilter.map((rs, index) => {
						return (
							<RecommendedSearchLayout
								key={index}
								compType={compType}
								recommendedSearch={rs}
							/>
						);
					})}
				</Container>
			</SuggestionSection>
		</SuggestionsContainer>
	);
};

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	padding: 1rem;
	background-color: ${({ theme }) => theme.colors.white.white};
	${(p) => p.theme.breakpoints.up('D_1280')} {
		grid-template-columns: repeat(2, 1fr);
	}
	${(p) => p.theme.breakpoints.up('D_1920')} {
		grid-template-columns: repeat(4, 1fr);
	}
`;
