import { useMemo, useRef } from 'react';
import { StartingRentChart } from '../../StartingRentChart';
import {
	MovingAverage,
	useExchangeDashboardStartingRent,
} from 'ExchangeDashboard/hooks/startingRent/useExchangeDashboardStartingRent';
import { PDFPanel } from '../UI';
import { PanelDescription } from '../PreviewComponents/UI';
import { useExportPdfState } from '../ExportPdfProvider';

export const StartingRentSection = () => {
	const { startingRentData, monthly, movingAverage } =
		useExchangeDashboardStartingRent();

	const ref = useRef<HTMLDivElement | null>(null);

	const { registerImage } = useExportPdfState();

	const animateConfig = useMemo(
		() => ({
			duration: 100,
			onEnd: () => {
				if (ref.current) {
					registerImage('startingRent', ref.current);
				}
			},
		}),
		[ref, registerImage]
	);

	return (
		<PDFPanel id="starting-rent" title="Starting Rent">
			{startingRentData && startingRentData.length > 0 ? (
				<>
					<div ref={ref}>
						<StartingRentChart
							startingRentData={startingRentData}
							monthly={monthly}
							isHoverDisabled={true}
							animate={animateConfig}
						/>
					</div>
					<PanelDescription>
						{getStartingRentDesc(movingAverage)}
					</PanelDescription>
				</>
			) : (
				<div>No Data To Display. Update Filters and Try Again.</div>
			)}
		</PDFPanel>
	);
};

StartingRentSection.displayName = 'StartingRent';

export const getStartingRentDesc = (movingAverage: MovingAverage) => {
	return `Charts reflect starting rent trend for lease transactions in selected markets and submarket(s). The data reflects a ${movingAverage} months moving average.`;
};
