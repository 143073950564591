import { usePropertyById } from 'api';
import { EditPage, EditPageProps } from 'pdf/EditPage';
import { ReactNode } from 'react';
import { routes } from 'router/routes';
import { SectionKey } from './ExportPdfProvider';
import { useExportPdfStateContext } from './ExportPdfStateProvider';

type Props = {
	pageKey: SectionKey;
	children: ReactNode;
} & Partial<EditPageProps>;

export const PropertyEditPage = ({ pageKey, ...props }: Props) => {
	const params = routes.propertyById.useParams();
	const { data: property } = usePropertyById({ propertyId: params.propertyId });

	const { page, setPage } = useExportPdfStateContext();

	if (!property) return null;

	const title = page.titles[pageKey];

	return (
		<EditPage
			title={title}
			onTitleChange={(t) => {
				setPage((s) => ({ ...s, titles: { ...s.titles, [pageKey]: t } }));
			}}
			onLogoChange={(logo) => setPage((s) => ({ ...s, logo }))}
			logo={page.logo}
			logoDisplayValueOnly={pageKey !== 'cover'}
			preparedFor={page.preparedFor}
			{...props}
		/>
	);
};
