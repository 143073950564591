import { css } from 'styled-components';

export const DarkScrollBarCSS = css`
	::-webkit-scrollbar {
		background-color: transparent;
		background-clip: content-box;
		cursor: pointer;
	}
	::-webkit-scrollbar-button {
		background-color: transparent;
	}
	::-webkit-scrollbar-corner {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		border: solid transparent;
		border-width: 1px 1px 1px 2px;
		border-width: 0 3px;
		background-color: ${({ theme }) => theme.colors.gray.gray500};
		background-clip: content-box;
		cursor: pointer;

		&:hover {
			background-color: ${({ theme }) => theme.colors.gray.gray90};
		}
	}
	::-webkit-scrollbar-track {
		border-width: 1px 1px 1px 2px;
		border-width: 0 3px;
		background-color: ${({ theme }) => theme.colors.neutral.n600};
		background-clip: content-box;
	}
	::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;
