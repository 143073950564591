import { FormSelect } from '@compstak/ui-kit';
import { useFilters } from 'reducers/filtersReducer';
import { useState } from 'react';
import { SPACE_TYPE_NAME_TO_ID, SpaceTypeId } from '../api';
import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

type Props = {
	onClose: NoArgCallback;
	onSubmit?: NoArgCallback;
};

export const SpaceTypeModal = ({ onClose, onSubmit }: Props) => {
	const [, setFilter] = useFilters('exchange-dashboard');
	const [selectedSpaceType, setSelectedSpaceType] = useState<SpaceTypeId>();

	const handleClick = () => {
		if (!selectedSpaceType) {
			return;
		}
		const spaceTypeId: SpaceTypeId[] =
			selectedSpaceType === SPACE_TYPE_NAME_TO_ID.Other
				? [SPACE_TYPE_NAME_TO_ID.Other, SPACE_TYPE_NAME_TO_ID.Unknown]
				: [selectedSpaceType];
		setFilter({ spaceTypeId });
		onSubmit?.();
		onClose();
	};

	return (
		<Modal onClose={onClose}>
			<ModalTitle>Select a Space Type</ModalTitle>
			<ModalParagraph>
				The Market Dashboard is best viewed using one Space Type at a time
			</ModalParagraph>
			<FormSelect
				items={Object.entries(SPACE_TYPE_NAME_TO_ID)
					.filter(([, id]) => {
						return id !== SPACE_TYPE_NAME_TO_ID.Unknown;
					})
					.map(([name, id]) => ({
						title: name,
						value: id,
					}))}
				value={selectedSpaceType}
				onChange={(e) => {
					setSelectedSpaceType(e.selectedItem!.value);
				}}
				placeholder="Select a Space Type"
			/>
			<ModalButtons>
				<ModalButton onClick={handleClick} disabled={!selectedSpaceType}>
					Set Space Type
				</ModalButton>
			</ModalButtons>
		</Modal>
	);
};
