import { AnalyticsChip } from 'Components';
import styled from 'styled-components';
import StopIcon from 'ui/svg_icons/pause_button.svg';
import PlayIcon from 'ui/svg_icons/play_button.svg';
import { MapAnalyticsCloseButton } from '../MapAnalyticsCloseButton';
import { MapAnalyticsMenuHeader } from '../UI';
import { LeaseExpirationInfo } from './LeaseExpirationInfo';
import { useLeaseExpirationState } from './LeaseExpirationProvider';
import { LeaseExpirationSlider } from './LeaseExpirationSlider';
import {
	getLeaseExpirationPeriodLabel,
	incrementExpireInMonths,
} from './utils';

type Props = {
	onClose: () => void;
};

export const LeaseExpirationReadMenu = ({ onClose }: Props) => {
	const [state, setState] = useLeaseExpirationState();

	const onClickPlay = () => {
		setState((s) => ({
			...s,
			isPlaying: true,
			expireInMonths: incrementExpireInMonths(s.expireInMonths),
		}));
	};

	const onClickStop = () => {
		setState((s) => ({ ...s, isPlaying: false }));
	};

	return (
		<LeaseExpirationContainer>
			<MapAnalyticsMenuHeader>
				<AnalyticsChip />
				<MapAnalyticsCloseButton
					data-qa-id="analytics-close-btn"
					onClick={onClose}
				/>
			</MapAnalyticsMenuHeader>
			<LeaseExpirationInfo />
			<Content>
				<TimelineContainer>
					<PlayButtonContainer>
						{state.isPlaying ? (
							<PlayButton
								data-qa-id="lease-expiration-stop-btn"
								onClick={onClickStop}
							>
								<StopIcon width={10} height={10} />
							</PlayButton>
						) : (
							<PlayButton
								data-qa-id="lease-expiration-play-btn"
								onClick={onClickPlay}
							>
								<PlayIcon width={10} height={10} />
							</PlayButton>
						)}
					</PlayButtonContainer>
					<LeaseExpirationSlider
						min={-60}
						max={60}
						step={6}
						marks={true}
						onMouseDown={stop}
						value={state.expireInMonths}
						valueLabelDisplay="on"
						valueLabelFormat={getLeaseExpirationPeriodLabel}
						onChange={(_, value) => {
							setState((s) => ({ ...s, expireInMonths: value as number }));
						}}
					/>
				</TimelineContainer>
			</Content>
		</LeaseExpirationContainer>
	);
};

const Content = styled.div`
	padding: 1rem;
`;

const TimelineContainer = styled.div`
	background-color: #f1f2f5;
	display: flex;
	padding-right: 20px;
	width: 193px;
`;

const PlayButton = styled.button`
	all: unset;
	padding: 6px;
	cursor: pointer;
`;

const PlayButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 46px;
`;

const LeaseExpirationContainer = styled.div`
	overflow: visible;
`;
