import styled, { createGlobalStyle } from 'styled-components';
import { ListSearchInputStyled } from '../../../../../Components/ListSearchInput/ListSearchInput';
import { checkboxStyles } from '../../../../../ui/checkboxStyles';

export const PoweredBy = styled.span`
	display: flex;
	align-items: center;
	font-weight: 300;
	font-size: 10px;
`;

const BaseItem = styled.div`
	height: 32px;
	padding: 0px 16px;
	font-size: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;

	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray800};

	label {
		width: 100%;
	}
	input {
		display: none;
	}

	.checkbox-enabled,
	.checkbox-disabled {
		margin-right: 8px;
	}

	${checkboxStyles}
`;

export const ListItem = styled(BaseItem)`
	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral.n500};
	}
`;

export const ListItemHeader = styled(BaseItem)`
	font-weight: 600;
	justify-content: space-between;
	cursor: default;
`;

export const GlobalStyle = createGlobalStyle`
  .popup_popup {
	  padding: 0px;
  }
  .popup_content {
	  border-radius: 4px;
  }
  ${ListSearchInputStyled} {
	  padding-left: 16px;
	  font-size: 14px;
  }
`;

export const Content = styled.div`
	height: 400px;
	overflow-y: scroll;
`;

export const Header = styled.div`
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.neutral.n0};
	line-height: 24px;

	> span:first-child {
		text-transform: uppercase;
		cursor: default;
	}
`;

export const Wrap = styled.div`
	border-radius: 4px;
	background-color: ${({ theme }) => theme.colors.gray.gray700};
	width: 400px;
`;

export const NoResultsWrap = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 50%;
	transform: translateY(-50%);
`;

export const NoResultsTitle = styled.div`
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 8px;
`;
export const NoResultsDescription = styled.div`
	font-size: 12px;
	text-align: center;
`;

export const Footer = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.neutral.n100};
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 0 16px;
	font-size: 14px;
`;

export const ResetLabel = styled.span`
	text-decoration: underline;
	cursor: pointer;
	font-weight: 300;
`;
