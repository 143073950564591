import { useIsMultifamily } from 'hooks/useIsMultifamily';
import { usePropertyByIdQuery } from '../propertyById';
import {
	PropertyInfoPropertyType,
	usePropertyInfoQuery,
} from '../propertyInfo';

type Props = {
	propertyId: number;
};

type UseIsMufaProperty = {
	data: boolean;
	isFetching: boolean;
	isLoading: boolean;
};

export const useIsMufaProperty = ({ propertyId }: Props): UseIsMufaProperty => {
	const {
		data: propertyInfo,
		isFetching: isFetchingPropertyInfo,
		isLoading: isLoadingPropertyInfo,
	} = usePropertyInfoQuery({ propertyId });

	const {
		data: property,
		isFetching: isFetchingProperty,
		isLoading: isLoadingProperty,
	} = usePropertyByIdQuery({ id: propertyId });

	const isMultifamily = useIsMultifamily({
		markets: property && [property.marketId],
	});

	return {
		data:
			isMultifamily &&
			propertyInfo?.propertyType !== PropertyInfoPropertyType.COMMERCIAL,
		isFetching: isFetchingPropertyInfo || isFetchingProperty,
		isLoading: isLoadingPropertyInfo || isLoadingProperty,
	};
};
