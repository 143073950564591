import { closeMarketSelector } from 'actions/home';
import { collapseSubmarketsList } from 'actions/search';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { CompType } from 'types/comp';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import pluralizeCompType from 'util/pluralizeCompType';
import List from '../../ui/svg_icons/list.svg';
import './leaseResultsButton.nomodule.less';
import { useSearch } from 'Pages/Search/searchReducer';
import { MQB } from '@compstak/ui-kit';
import { SEARCH_BY_VIEW_ROUTES } from 'router';

type ResultsButtonProps = {
	compType: CompType;
};

export const ResultsButton = (props: ResultsButtonProps) => {
	const { totalResults, submarketsListCollapsed } = useSearch();
	const isTablet = useMediaQuery({ maxWidth: MQB.T_834 });

	const dispatch = useDispatch();

	const [shouldUpdateClassname, setShouldUpdateClassname] =
		React.useState<boolean>(false);

	const getButtonContent = () => {
		if (totalResults === null) {
			return (
				<div>
					Loading <div className="icon spinner" />
				</div>
			);
		} else if (totalResults !== 0) {
			return isTablet ? (
				<div>
					<List width="18px" height="14px" />
				</div>
			) : (
				<div>
					Refine {abbreviateNumber(totalResults)}{' '}
					{pluralizeCompType(props.compType, true)}{' '}
					<div className="icon arrow" />
				</div>
			);
		} else {
			return (
				<div>
					No Results Found{' '}
					<div className="icon alert">
						<span>!</span>
					</div>
				</div>
			);
		}
	};

	React.useEffect(() => {
		setShouldUpdateClassname(true);
		const timeout = setTimeout(() => setShouldUpdateClassname(false), 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, [totalResults]);

	const handleClick = () => {
		dispatch(closeMarketSelector());
		if (!submarketsListCollapsed) {
			dispatch(collapseSubmarketsList(true));
		}
	};

	let className = 'button_button lease-results-button button_large button_blue';
	let wrapperClassName =
		'results-button-wrapper' + (isTablet ? ' is-mobile' : '');

	if (totalResults === null) {
		// nothing
	} else if (totalResults === 0) {
		className += ' disabled';
	} else if (totalResults && shouldUpdateClassname) {
		wrapperClassName += ' updated';
		className += ' updated button_blue';
	} else {
		className += ' button_blue';
	}

	return (
		<div className={wrapperClassName}>
			<a
				data-qa-id="comps-results-button"
				onClick={handleClick}
				href={SEARCH_BY_VIEW_ROUTES[props.compType].toHref({ view: 'list' })}
				className={className}
			>
				{getButtonContent()}
			</a>
		</div>
	);
};
