import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export type RadioButtonProps = InputHTMLAttributes<HTMLInputElement>;

export const RadioButton = ({
	children,
	className,
	...props
}: RadioButtonProps) => {
	return (
		<RadioButtonLabel disabled={props.disabled} className={className}>
			<RadioButtonInput type="radio" {...props} />
			{children}
		</RadioButtonLabel>
	);
};

const RadioButtonLabel = styled.label<{ disabled?: boolean }>`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	color: ${({ disabled, theme }) =>
		disabled ? theme.colors.gray.gray100 : theme.colors.gray.gray700};
`;

const RadioButtonInput = styled.input<{ disabled?: boolean }>`
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
