import { composeEnabled } from 'api';
import {
	useCountyPolygonByFIPSQuery,
	UseCountyPolygonByFIPSQueryOptions,
} from 'api/ppm-property/countyPolygons/useCountyPolygonByFIPSQuery';
import { useFilters } from 'reducers/filtersReducer';

export const useCountyFeatures = (
	options?: UseCountyPolygonByFIPSQueryOptions
) => {
	const [filters] = useFilters();
	const apn = filters.apn;
	const fips = filters.fips?.[0] ?? '';

	const { data: countyFeature } = useCountyPolygonByFIPSQuery(
		{ fips },
		{ ...options, enabled: composeEnabled(!!fips && !apn, options?.enabled) }
	);

	return {
		countyFeatures: countyFeature && !apn ? [countyFeature] : [],
	};
};
