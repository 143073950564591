import { useUserQuery } from 'api';
import { useIsExchange } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';

type Props = {
	filters: FiltersObject;
};

export const useHasLeaseInsights = ({ filters }: Props) => {
	const { data: user } = useUserQuery();
	const isEnterprise = !useIsExchange();
	const areMultipleMarketsSelected = getFiltersMarkets(filters).length > 1;

	return !areMultipleMarketsSelected && (isEnterprise || user.vipLevel > 0);
};
