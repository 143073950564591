import { CenterOnPoint, Map, MapInteractivity, MapStyle } from '@compstak/maps';
import { useDashboardTenantIndustryAnalyticsData } from 'ExchangeDashboard/useDashboardTenantIndustryAnalyticsData';
import {
	MapAnalyticsNoDataMessage,
	MapAnalyticsSettingsButton,
	TenantIndustryLayer,
	TenantIndustryLegend,
	useIsTopTenTenantIndustries,
} from 'maps';
import { useState } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { ExchangePanel, MapMessageContainer } from '../UI';
import { TenantIndustryMapSettingsMenu } from './TenantIndustryMapSettingsMenu';
import { useFilteredTenantIndustries } from './useFilteredTenantIndustries';
import { Waypoint } from 'react-waypoint';

export const TenantIndustryMap = () => {
	const [view, setView] = useState<'visible' | 'hidden'>('hidden');
	const [filters] = useFilters('exchange-dashboard');
	const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
	const enabled = view === 'visible';

	const { data } = useDashboardTenantIndustryAnalyticsData({ enabled });
	const tenantIndustries = useFilteredTenantIndustries({ enabled });

	const isTopTen = useIsTopTenTenantIndustries(
		{ filters, tenantIndustries },
		{ enabled }
	);

	const noData = data && data.length === 0;

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				id="tenant-industry"
				title="Tenant Industry"
				tooltip="Top ten industries based on total square footage in selected market and submarket(s). Radius indicates total square feet of prevalent industry type of a property."
			>
				<Container>
					<Map
						interactivity={MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM}
						initialViewState={{
							latitude: filters.market.lat,
							longitude: filters.market.lon,
							zoom: filters.market.zoom,
						}}
						mapStyle={MapStyle.MONOCHROME}
						logoPosition="top-left"
						attributionProps={{
							position: 'top-right',
						}}
					>
						<CenterOnPoint
							latitude={filters.market.lat}
							longitude={filters.market.lon}
							zoom={filters.market.zoom}
						/>
						<TenantIndustryLayer marketName={filters.market.name} data={data} />
						{isSettingsMenuOpen && <TenantIndustryMapSettingsMenu />}
						{!noData && (
							<LegendContainer>
								<TenantIndustryLegend
									tenantIndustries={tenantIndustries}
									isTopTen={isTopTen}
								/>
							</LegendContainer>
						)}
						<MapAnalyticsSettingsButton
							isMenuOpen={isSettingsMenuOpen}
							onClick={() => {
								setIsSettingsMenuOpen(!isSettingsMenuOpen);
							}}
						/>
						{noData && (
							<MapMessageContainer>
								<MapAnalyticsNoDataMessage />
							</MapMessageContainer>
						)}
					</Map>
				</Container>
			</ExchangePanel>
		</Waypoint>
	);
};
const Container = styled.div`
	position: relative;
	max-width: 100%;
	height: 100%;
	height: 500px;
	overflow: hidden;
`;

export const LegendContainer = styled.div`
	position: absolute;
	bottom: 6px;
	right: 8px;
	background-color: rgba(255, 255, 255, 0.9);
	width: 172px;
	max-height: 300px;
	overflow-y: auto;
	padding: 8px;
`;
