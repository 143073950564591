import { CompatibleFilterKey } from 'types/serverFilters';
import { allFiltersStub } from '../stubs/allFiltersStub';
import { FiltersTypesKeys } from '../types';
import { isArrayFilter } from './filterHelpers';
import { COMPATIBLE_FILTERS } from './serverJson';

export const isListFilterKey = (filterKey: string) => {
	const valueType = allFiltersStub[filterKey as FiltersTypesKeys];
	if (valueType) {
		return isArrayFilter(valueType);
	}

	return (
		COMPATIBLE_FILTERS[filterKey as CompatibleFilterKey]?.isListFilterKey ??
		false
	);
};
