import { useReferenceDataQuery } from 'api';
import { useMemo } from 'react';
import { StrFormOption } from './UploadFormCommon';

export const usePropertySubtypeOptions = () => {
	const { data: referenceData } = useReferenceDataQuery();

	return useMemo<StrFormOption[]>(() => {
		return referenceData.propertySubtypes.map((s) => ({
			label: s.name,
			value: s.name,
		}));
	}, [referenceData.propertySubtypes]);
};
