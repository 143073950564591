import React from 'react';
import { pdfStyle } from './style';
import { View, Text } from '@react-pdf/renderer';
import { PdfRealPageLogo } from './PdfRealPageLogo';

export const PdfRealPageLogoView = () => {
	return (
		<View style={pdfStyle.realPageLogoContainer}>
			<Text style={pdfStyle.poweredBy}>Powered by</Text>
			<PdfRealPageLogo />
		</View>
	);
};
