import styled from 'styled-components';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import { BuildingClassFilter } from './BuildingClassFilter';
import { LeaseExpirationFilter } from './LeaseExpirationFilter';
import { PropertyTypeFilter } from './PropertyTypeFilter';
import { SubmarketFilter } from './SubmarketFilter';

type Props = {
	portfolioId: number;
};

export const PortfolioFilters = ({ portfolioId }: Props) => {
	const { resetFilters } = usePortfolioFilters();

	return (
		<Root>
			<FiltersContainer>
				<SubmarketFilter portfolioId={portfolioId} />
				<PropertyTypeFilter />
				<BuildingClassFilter />
				<LeaseExpirationFilter />
			</FiltersContainer>
			<ResetFiltersButton onClick={resetFilters}>
				Reset filters
			</ResetFiltersButton>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

const FiltersContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

const ResetFiltersButton = styled.button`
	all: unset;
	font-size: 0.75rem;
	text-transform: uppercase;
	padding: 0.5rem;
	color: ${(p) => p.theme.colors.blue.blue500};
	border-radius: 0.125rem;
	cursor: pointer;
	height: 44px;
	box-sizing: border-box;
	&:hover {
		background-color: #e8f0fa;
	}
`;
