import {
	calcMonthsQuantityInPeriod,
	calendarUtils,
	Period,
} from '@compstak/ui-kit';

export const getXTickDateFormatter = (period: Period) => {
	const diffInMonths = calcMonthsQuantityInPeriod(period);

	// @ts-expect-error TS7006: Parameter 'value' implicitly h...
	return (value) =>
		diffInMonths && diffInMonths > 24
			? diffInMonths < 180
				? calendarUtils.getDate(value, 'fullYear')
				: calendarUtils.getDate(value, 'shortYear')
			: calendarUtils.getDate(value, 'shortDate1');
};
