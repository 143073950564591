import { Link } from 'router';
import styled from 'styled-components';
import { surveyLetterSpacingCss } from './questionComponents';

export function SurveyDoneUi() {
	return (
		<Page>
			<Container>
				<Title>
					<Emoji>🎉</Emoji>
					<H1>Great Job! You've Maximized Your Earning Potential Today!</H1>
				</Title>
				<Text>
					Congratulations, you’ve completed all of today’s survey questions!
					Remember, every answer brings you closer to unlocking valuable comps
					and insights. We’re excited to welcome you back tomorrow for more
					opportunities to showcase your knowledge and earn credits. Thank you
					for being an integral part of our network and community.
				</Text>
				<LinkContainer>
					<Link to="/home">RETURN TO HOME</Link>
				</LinkContainer>
			</Container>
		</Page>
	);
}

const Page = styled.div`
	font-family: Gotham;
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	justify-content: center;
	${surveyLetterSpacingCss}
`;
Page.displayName = 'Page';

const Container = styled.div`
	max-width: min-content;
	margin-top: 10%;
`;
Container.displayName = 'Container';

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	/* https://stackoverflow.com/questions/68389808/make-one-child-div-expand-the-parents-width-and-second-child-just-fill-the-pare */
	width: max-content;
	max-width: 90vw;
`;
Title.displayName = 'Title';

const Emoji = styled.div`
	font-size: 42px;
`;
Emoji.displayName = 'Emoji';

const H1 = styled.h1`
	color: #000;
	font-size: 24px;
	font-weight: 400;
	text-transform: uppercase;
`;
H1.displayName = 'H1';

const Text = styled.div`
	margin-top: 40px;
	font-size: 16px;
	font-weight: normal;
	color: #000;
`;
Text.displayName = 'Text';

const LinkContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 40px;
	font-size: 16px;
	font-weight: 400;
`;
LinkContainer.displayName = 'LinkContainer';
