import { Spinner } from '@compstak/ui-kit';
import CreatePasswordForm from 'Components/CreatePasswordForm';
import { SetupMultiFactorAuth } from 'Components/MultiFactorAuth/SetupMfAuth';
import { useInviteQuery } from 'api/invites/useInviteQuery';
import { isAPIClientNotOkResponseError } from 'api/utils/queryRetriesMisc';
import { camelCase, mapKeys, mapValues, pickBy } from 'lodash';
import { getParamsFromQueryString } from 'models/filters/util';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { routes } from 'router';
import userService from 'services/user';
import CsLogo from '../../ui/svg_icons/cs_logo.svg';
import '../ResetPassword/resetpassword.nomodule.less';

type State = {
	password: string;
	utmParams: Partial<{
		utmSource: string[];
		utmMedium: string[];
		utmCampaign: string[];
		utmTerm: string[];
	}>;
	is2FARequired: boolean;
	totpCode: string;
	isSubmitting: boolean;
	createAccountFailed: boolean;
};

export const CreateAccountPage = () => {
	const location = useLocation();
	const { uuid } = routes.createAccount.useParams();
	const navigate = useNavigate();

	const [state, setState] = useState<State>(() => {
		const queryParams = mapValues(
			mapKeys(getParamsFromQueryString(location.search), (_, key) =>
				camelCase(key)
			),
			(value) => (Array.isArray(value) ? (value as string[]) : [value])
		);
		const utmParams = pickBy(queryParams, (_, key) => key.includes('utm'));

		return {
			createAccountFailed: false,
			password: '',
			is2FARequired: false,
			totpCode: '',
			isSubmitting: false,
			utmParams,
		};
	});

	const { data: invite, isError } = useInviteQuery(uuid!, { enabled: !!uuid });

	useEffect(() => {
		if (isError) {
			navigate(routes.login.path);
		}
	}, [isError, navigate]);

	if (!invite || !uuid) {
		return <Spinner isCentered size="xl" />;
	}

	const handleSubmit = async (password: string) => {
		setState((prevState) => ({
			...prevState,
			password,
			createAccountFailed: false,
			is2FARequired: false,
			isSubmitting: true,
		}));
		try {
			await userService.activateAccountAndLogin(
				uuid,
				password,
				false,
				state.utmParams
			);
			navigate(routes.home.path);
		} catch (err) {
			if (isAPIClientNotOkResponseError(err) && err.response.status === 401) {
				setState((prevState) => ({ ...prevState, is2FARequired: true }));
			} else {
				setState((prevState) => ({ ...prevState, createAccountFailed: true }));
			}
		} finally {
			setState((prevState) => ({ ...prevState, isSubmitting: false }));
		}
	};

	if (state.is2FARequired) {
		return (
			<SetupMultiFactorAuth
				isSignIn
				credentials={{
					username: invite.email,
					password: state.password,
					totpCode: state.totpCode,
					rememberMe: true,
				}}
				cancel={() => navigate(routes.login.path)}
				setTotpCode={(totpCode) =>
					setState((prevState) => ({ ...prevState, totpCode }))
				}
			/>
		);
	}

	return (
		<section className="reset_password_container">
			<div className="reset_password_header">
				<a
					href="https://compstak.com/"
					target="_blank"
					className="cs_logo"
					title="CompStak"
					rel="noreferrer"
				>
					<CsLogo width={35} />
				</a>
				<h2 className="reset_password_title">Welcome To CompStak</h2>
				<aside className="login_contacts">
					<ul>
						<li>
							<p className="contact_info">
								Need help? <span className="info_divider" /> Call us at{' '}
								<a href="tel:1-646-926-6707">1-646-926-6707</a>
							</p>
						</li>
					</ul>
				</aside>
			</div>
			<div className="reset_password_form">
				<CreatePasswordForm
					isSubmitting={state.isSubmitting}
					headerText="Create Account"
					buttonText="Create Account"
					subhead="Please select a password to activate your CompStak account."
					onSubmit={(password) => handleSubmit(password)}
					requiresTou={true}
					requiresPrivacyPolicy={true}
					errorText={state.createAccountFailed ? ERROR_TEXT : undefined}
				/>
			</div>
		</section>
	);
};

const ERROR_TEXT =
	'An error happened while creating your account. Please try again. If the problem persists, please contact membership@compstak.com for help.';
