import { MarketRentResponse } from '@compstak/fe-react-api-layer';
import {
	HexagonLayer,
	HexagonLayerProps,
	LayerColor,
	PolygonsLayer,
	PolygonsLayerProps,
} from '@compstak/maps';
import { Feature, MultiPolygon, Polygon } from '@turf/helpers';
import { SubmarketPolygonsResponse } from 'api/submarketPolygons/useSubmarketPolygons';
import { useMemo } from 'react';
import {
	MARKET_RENT_ANALYTIC_COLOR_SCALE,
	MARKET_RENT_ANALYTIC_SUBMARKET_LINE_COLOR,
} from './constants';
import { useMarketRentAnalyticsState } from './MarketRentAnalyticsProvider';
import { MarketRentAnalyticsViewMode } from './types';

type Props = {
	data?: MarketRentResponse;
	submarketData?: SubmarketPolygonsResponse;
	adjustedPercentiles: MarketRentResponse['percentiles'];
	polygonsLayerProps?: Partial<PolygonsLayerProps<MarketRentSubmarketPolygon>>;
	hexagonLayerProps?: Partial<HexagonLayerProps<MarketRentHexagonProperties>>;
	id?: string;
	order?: number;
};

export const MarketRentAnalyticsLayers = ({
	data,
	submarketData,
	adjustedPercentiles,
	polygonsLayerProps,
	hexagonLayerProps,
	id,
	order,
}: Props) => {
	const [state] = useMarketRentAnalyticsState();

	const hexagons = useMemo(() => {
		if (!data?.bucketsLevel?.l) return [];

		const bucketsContainer = data.bucketsLevel?.l.filter(
			(bucketsHolder) => bucketsHolder.h3Level === 10
		)[0];

		return bucketsContainer.buckets.map((bucket) => {
			const fillColor = getMarketRentColorFromPercentiles(
				bucket.marketRent,
				adjustedPercentiles
			);
			return {
				hex: bucket.h3,
				properties: { ...bucket, fillColor },
			};
		});
	}, [data?.bucketsLevel?.l, adjustedPercentiles]);

	const submarketPolygons = useMemo(() => {
		const polygons: MarketRentSubmarketPolygon[] = [];

		if (!submarketData?.features || !data?.bucketsLevel?.r) return polygons;

		for (const polygon of submarketData.features) {
			const submarketDataPoint = data.bucketsLevel.r.find(
				(submarket) => submarket.submarket === polygon.properties.name
			);
			if (!submarketDataPoint) continue;

			const fillColor = getMarketRentColorFromPercentiles(
				submarketDataPoint.marketRent,
				adjustedPercentiles
			);

			polygons.push({
				...polygon,
				properties: {
					...submarketDataPoint,
					fillColor,
				},
			});
		}

		return polygons;
	}, [submarketData?.features, data?.bucketsLevel.r, adjustedPercentiles]);

	return (
		<>
			{state.viewMode === MarketRentAnalyticsViewMode.HEXAGON && (
				<HexagonLayer
					id={id + state.viewMode}
					data={hexagons}
					pickable={true}
					getLineWidth={0}
					getFillColor={(f) => f.properties.fillColor}
					opacity={0.7}
					extruded={false}
					coverage={0.95}
					order={order}
					{...hexagonLayerProps}
				/>
			)}
			{state.viewMode === MarketRentAnalyticsViewMode.SUBMARKET && (
				<PolygonsLayer
					id={id + state.viewMode}
					data={submarketPolygons}
					// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
					getFillColor={(f) => f.properties.fillColor}
					getLineColor={MARKET_RENT_ANALYTIC_SUBMARKET_LINE_COLOR}
					getLineWidth={4}
					highlightColor={[255, 100, 100, 50]}
					lineWidthMinPixels={1}
					lineWidthScale={1}
					pickable={true}
					opacity={0.5}
					staticTooltipsMinZoom={12}
					order={order}
					{...polygonsLayerProps}
				/>
			)}
		</>
	);
};

const getMarketRentColorFromPercentiles = (
	marketRent: number,
	percentiles: MarketRentResponse['percentiles']
) => {
	const scaleIndex = percentiles.findIndex((s) => marketRent < s.marketRent);
	return scaleIndex !== undefined
		? MARKET_RENT_ANALYTIC_COLOR_SCALE[scaleIndex - 1 < 0 ? 0 : scaleIndex - 1]
		: MARKET_RENT_ANALYTIC_COLOR_SCALE[percentiles.length - 1];
};

type MarketRentHexagonProperties = {
	fillColor: LayerColor;
	h3: string;
	propertyCount: number;
	marketRent: number;
};

type MarketRentSubmarketPolygonProperties = {
	submarket: string;
	marketRent: number;
	propertyCount: number;
	fillColor: LayerColor;
};

type MarketRentSubmarketPolygon = Feature<
	Polygon | MultiPolygon,
	MarketRentSubmarketPolygonProperties
>;
