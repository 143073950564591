import { zIndex } from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
	SAVED_SEARCH_TYPE_TO_COMP_TYPE,
	SavedSearch,
} from 'api/savedSearches/useSavedSearchesQuery';
import IconThreeDot from 'ui/svg_icons/three-dot.svg';
import styled from 'styled-components';
import { useModal } from 'providers/ModalProvider';
import { DeleteSavedSearchModal } from './DeleteSavedSearchModal';
import {
	useIsSendToAnalyticsBtnAllowed,
	useSendToAnalyticsBtn,
} from 'hooks/chartBuilderHooks';
import { useMarkets } from 'hooks/useMarkets';
import { filtersFromServerJSON } from 'models/filters/util';
import { useNavigate } from 'router';
import { ShareWithColleagueModal } from 'Components/Modals/ShareWithColleagueModal/ShareWithColleagueModal';
import { useShareSavedSearchMutation } from 'api/savedSearches/useShareSavedSearchMutation';
import { useIsExchange } from 'hooks';

type EditOptionsDropdownProps = {
	savedSearch: SavedSearch;
};

export const EditOptionsDropdown = ({
	savedSearch,
}: EditOptionsDropdownProps) => {
	const compType = SAVED_SEARCH_TYPE_TO_COMP_TYPE[savedSearch.searchType];
	const isSendToAnalyticsBtnAllowed = useIsSendToAnalyticsBtnAllowed(compType);

	const markets = useMarkets();

	const filters = filtersFromServerJSON(
		savedSearch.searchType,
		markets,
		savedSearch.filters
	);

	const { href } = useSendToAnalyticsBtn({
		filters,
		compType,
		title: savedSearch.name,
	});

	return (
		<DropdownMenu.Root>
			<DropdownButton>
				<IconThreeDot />
			</DropdownButton>
			<DropdownMenu.Portal>
				<DropdownOptions
					savedSearch={savedSearch}
					sendToChartbuilderUrl={isSendToAnalyticsBtnAllowed ? href : ''}
				/>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

type Props = EditOptionsDropdownProps & {
	sendToChartbuilderUrl?: string;
};

const DropdownOptions = ({ savedSearch, sendToChartbuilderUrl }: Props) => {
	const { openModal } = useModal();

	const navigate = useNavigate();

	const { mutate: shareSavedSearch } = useShareSavedSearchMutation();
	const isExchange = useIsExchange();

	return (
		<DropdownContent align="end" sideOffset={0}>
			<DropdownItem>Edit Name & Notification</DropdownItem>
			<DropdownItem>View & Adjust Filters</DropdownItem>
			<DropdownItem>Duplicate Search</DropdownItem>
			{sendToChartbuilderUrl && (
				<DropdownItem
					onClick={(e) => {
						e.stopPropagation();
						navigate(sendToChartbuilderUrl);
					}}
				>
					Send to Chartbuilder
				</DropdownItem>
			)}
			{!isExchange && (
				<DropdownItem
					onClick={(e) => {
						e.stopPropagation();
						openModal({
							modalContent: (
								<ShareWithColleagueModal
									itemName={savedSearch.name}
									modalTitle="Share Search"
									buttonText="Send Search"
									action={(emails) => {
										shareSavedSearch({
											searchId: savedSearch.id,
											recipientEmails: emails,
										});
									}}
								/>
							),
						});
					}}
				>
					Share Saved Search
				</DropdownItem>
			)}
			<DropdownItem
				onClick={(e) => {
					e.stopPropagation();
					openModal({
						modalContent: <DeleteSavedSearchModal savedSearch={savedSearch} />,
						modalProps: {
							contentProps: { view: 'medium' },
						},
					});
				}}
			>
				Delete Saved Search
			</DropdownItem>
		</DropdownContent>
	);
};

const DropdownButton = styled(DropdownMenu.Trigger)`
	border: none;
	background-color: transparent;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral.n30};
	}
`;

const DropdownContent = styled(DropdownMenu.Content)`
	z-index: ${zIndex.overlay + zIndex.modal};
	background-color: ${({ theme }) => theme.colors.white.white};
	border-radius: 4px;
	box-shadow:
		0 3px 5px rgba(0, 0, 0, 0.2),
		0 0 1px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled(DropdownMenu.Item)`
	padding: 1rem 3rem 1rem 1rem;
	font-size: 0.875rem;
	cursor: pointer;
	&:not(:last-of-type) {
		color: ${({ theme }) => theme.colors.gray.gray700};
		border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.n30};
	}
	&:last-of-type {
		color: ${({ theme }) => theme.colors.red.red500};
	}
	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral.n20};
	}
`;
