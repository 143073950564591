import {
	CommercialChartsNames as ChartsNames,
	FiltersNames,
	FiltersValues,
} from 'types/propertyTrendCharts';
import { useCompSetTrendRequest, useDistributionData } from 'api';
import { CommercialTrendsFiltersState } from '../Components/CommercialSubmarketTrends/useCommercialTrendsState';

// not changed by filters
const COMMERCIAL_CHART_METRIC = 'startingRent';
const RENT_TREND_MONTHS = 6;

export const useCommercialTrendsData = ({
	propertyId,
	filters,
}: {
	propertyId: number;
	filters: CommercialTrendsFiltersState;
}) => {
	const rentTrend = useCompSetTrendRequest({
		id: propertyId,
		metric: COMMERCIAL_CHART_METRIC,
		fromDate:
			filters[ChartsNames.SubmarketRentTrend][FiltersNames.PERIOD].start,
		toDate: filters[ChartsNames.SubmarketRentTrend][FiltersNames.PERIOD].end,
		months: RENT_TREND_MONTHS.toString(),
	});

	const rentDistribution = useDistributionData({
		id: propertyId,
		metric: COMMERCIAL_CHART_METRIC,
		months: filters[ChartsNames.SubmarketRentDistribution][FiltersNames.MONTHS],
		spaceTypes:
			filters[ChartsNames.SubmarketRentDistribution][FiltersNames.SPACE_TYPE],
	});

	return {
		data: { rentTrend, rentDistribution },
		isLoading: rentTrend.isLoading || rentDistribution.isLoading,
		isFetching: rentTrend.isFetching || rentDistribution.isFetching,
	};
};

export type FilterChangeFn = (
	chartName: ChartsNames,
	filterName: FiltersNames,
	filterValue: FiltersValues
) => void;
