const leaseRequiredFields = [
	'Full address',
	'Tenant name',
	'Space type (office, retail, etc.)',
	'Transaction Size (SQFT)',
	'Starting rent ($ PSF)',
	'Execution date / Commencement date',
	'Lease term / Expiration date',
];

const saleRequiredFields = [
	'Full address',
	'Buyer',
	'Seller',
	'Transaction Date',
	'Transaction Size (SQFT)',
	'Price / Price PSF ($PSF)',
];

const leasePremiumFields = [
	'TI value / Work value (PSF) (e.g. $43)',
	'Free rent',
	'Transaction type (e.g. renewal)',
];

const salePremiumFields = ['Cap rate', 'NOI / NOI PSF', 'Opex / Opex PSF'];

export const UPLOAD_PAGE_REQUIRED_FIELDS = {
	leaseRequiredFields,
	saleRequiredFields,
	leasePremiumFields,
	salePremiumFields,
};

export type CompIsPortfolioCompOptionT = 'yes' | 'no' | '';
