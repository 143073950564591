import React from 'react';
import { PropertyTypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeFilter';
import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';

import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { BuildingFloorsCount } from 'Components/Filters/Fields/BuildingFloorsCount/BuildingFloorsCount';
import { YearBuilt } from 'Components/Filters/Fields/YearBuilt/YearBuilt';
import { BuildingNameFilter } from 'Components/Filters/Fields/BuildingNameFilter/BuildingNameFilter';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';

export const BuildingFilterCategoryCommonFilters = () => {
	const { context, compType } = useFilterFieldContext();

	return (
		<>
			<BuildingClassFilter />

			<BuildingNameFilter />
			{context !== 'analytics' || compType === 'lease' ? (
				<PropertyTypeFilter>
					<PropertySubtypeFilter />
				</PropertyTypeFilter>
			) : (
				<PropertySubtypeFilter />
			)}

			<BuildingSizeFilter />
			<BuildingFloorsCount />
			<YearBuilt />
		</>
	);
};
