import React, { FC, memo } from 'react';
import { ModalWithCloseTimestamp } from '../ModalWithCloseTimestamp';
import { useFilterLossDontShowTimestamp } from 'Pages/Analytics/api/user/setDontShowAgainWindows';

type FiltersChangedModalProps = {
	onClose: NoArgCallback;
	updateUser: NoArgCallback;
};

export const FiltersChangedModal: FC<FiltersChangedModalProps> = memo(
	({ onClose, updateUser }) => {
		const onTimestampChange = useFilterLossDontShowTimestamp(updateUser).mutate;

		return (
			<ModalWithCloseTimestamp
				title={title}
				description={description}
				onClose={onClose}
				onTimestampChange={onTimestampChange}
			/>
		);
	}
);

FiltersChangedModal.displayName = 'FiltersChangedModal';

const title = 'New Filter Set';
const description =
	'Some applied filters will be removed as the space type has been updated.';
