import styled from 'styled-components';
import CloseIcon from 'ui/svg_icons/cross.svg';

type Props = {
	onClick?: () => void;
};

export const MapAnalyticsCloseButton = ({ onClick }: Props) => {
	return (
		<Button onClick={onClick} data-qa-id="analytics-close-btn">
			<CloseText>Close</CloseText>
			<CloseIcon width={12} height={12} />
		</Button>
	);
};

const CloseText = styled.span`
	display: none;
	color: #303441;
	font-size: 12px;
`;

const Button = styled.button`
	all: unset;
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 0.2rem;
	cursor: pointer;

	&:hover {
		${CloseText} {
			display: inline;
		}
	}
`;
