import { ProfileMenuHeader } from 'Components/ProfileMenu/ProfileMenuHeader';
import {
	ProfileMenu,
	ProfileMenuAnchor,
	ProfileMenuButton,
	ProfileMenuLink,
} from 'Components/ProfileMenu/UI';
import { logout } from 'auth/auth';
import { routes } from 'router/routes';
import IconAccount from '../../../ui/svg_icons/account.svg';
import IconHelp from '../../../ui/svg_icons/help_v2.svg';
import IconLogout from '../../../ui/svg_icons/logout.svg';

type UserMenuProps = {
	closeMenu: () => void;
};

export const EnterpriseUserMenu = ({ closeMenu }: UserMenuProps) => {
	return (
		<ProfileMenu>
			<ProfileMenuHeader close={closeMenu} />
			<ProfileMenuLink
				to={routes.settingsPage.toHref({ page: 'account' })}
				data-qa-id="account"
				onClick={closeMenu}
			>
				Account
				<IconAccount width="20px" />
			</ProfileMenuLink>
			<ProfileMenuAnchor
				href="https://support.compstak.com/hc/en-us"
				target="_blank"
				rel="noreferrer"
				onClick={closeMenu}
				data-qa-id="help"
			>
				Help
				<IconHelp width="20px" />
			</ProfileMenuAnchor>
			<ProfileMenuButton
				onClick={() => {
					closeMenu();
					logout();
				}}
				data-qa-id="logout"
			>
				Logout
				<IconLogout width="20px" />
			</ProfileMenuButton>
		</ProfileMenu>
	);
};
