import { MQB, TooltipV2 } from '@compstak/ui-kit';
import { CreateSavedSearchModal } from 'Components/CreateSavedSearchModal';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { useModal } from 'providers/ModalProvider';
import { useMediaQuery } from 'react-responsive';
import { useFilters } from 'reducers/filtersReducer';
import { useCompType } from 'router/useCompType';
import IconBookmark from 'ui/svg_icons/bookmark.svg';
import buttonStyles from '../../../ui/styles/button.less';

export const SaveSearchButton = () => {
	const [filters] = useFilters();
	const hasSetFilters = getSetFilterKeysExceptMarkets(filters).length > 0;

	const isDisabled = !hasSetFilters;

	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1920 });

	const compType = useCompType();

	const { openModal } = useModal();

	return (
		<TooltipV2 content="Save Search">
			<button
				className={
					`${buttonStyles.button} button_export` +
					(isDisabled ? ' button_disabled' : '')
				}
				disabled={isDisabled}
				onClick={() => {
					openModal({
						modalContent: <CreateSavedSearchModal />,
					});
				}}
			>
				<IconBookmark />
				{(compType !== 'property' || isLargeScreen) && 'SAVE SEARCH'}
			</button>
		</TooltipV2>
	);
};
