import { API } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { UNLOCK_LEASES } from 'actions/lease';
import { invalidateUserQuery } from 'hooks/userHooks';
import { useDispatch } from 'react-redux';
import { onLeasesUnlock } from 'services/lease';
import { LeaseComp } from 'types';
import { MUTATION_KEYS, QUERY_KEYS } from '../constants';
import { updateCachePropertyComps } from '../utils';

type Props = {
	onSuccess?: OnUnlockLeasesSuccessFn;
};

export type OnUnlockLeasesSuccessFn = (args: {
	data: UnlockLeasesResponse;
	params: UnlockLeasesParams;
	queryClient: QueryClient;
}) => void;

export const useUnlockLeasesMutation = (props?: Props) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutation({
		mutationKey: [MUTATION_KEYS.UNLOCK_LEASES],
		mutationFn: async ({ ids }: UnlockLeasesParams) => {
			const promise = API.put<UnlockLeasesResponse>(
				`/api/comps/actions/unlock`,
				{
					ids,
				}
			);
			// redux legacy
			dispatch({
				type: UNLOCK_LEASES,
				meta: {
					ids,
				},
				payload: {
					ids,
					promise: promise.then((res) => {
						onLeasesUnlock(res.data);
						return res.data.comps;
					}),
				},
			});
			return (await promise).data;
		},
		onSuccess: (data, params) => {
			updateCachePropertyComps('lease', queryClient, data);
			invalidateLeaseQueries(queryClient);
			invalidateUserQuery(queryClient);

			props?.onSuccess?.({ data, params, queryClient });
		},
	});
};

export const invalidateLeaseQueries = (queryClient: QueryClient) => {
	queryClient.invalidateQueries({
		predicate: (query) => {
			const [staticKey] = query.queryKey;
			return [
				QUERY_KEYS.SEARCH_LEASES,
				QUERY_KEYS.SEARCH_LEASES_INFINITE,
				QUERY_KEYS.PROPERTY_LEASES,
				QUERY_KEYS.SUBMARKET_RECENT_LEASES,
			].includes(staticKey as QUERY_KEYS);
		},
	});
};

export type UnlockLeasesParams = {
	ids: number[];
};

export type UnlockLeasesResponse = {
	points: number;
	freeComps: number;
	comps: LeaseComp[];
};
