import {
	useFiltersParamWithMarketId,
	useTenantIndustryAnalyticsQuery,
} from 'api';
import { useTenantIndustryState } from 'maps';

export const useTenantIndustryAnalyticsData = () => {
	const [state] = useTenantIndustryState();

	const filtersParam = useFiltersParamWithMarketId();

	return useTenantIndustryAnalyticsQuery({
		filters: filtersParam,
		maxBySqft: state.metric === 'sqft',
	});
};
