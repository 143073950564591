import {
	TenantIndustry,
	UseTopTenantIndustriesOptions,
	useTopTenantIndustriesQuery,
} from 'api';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';

type Props = {
	tenantIndustries: TenantIndustry[];
	filters: FiltersObject;
};

export const useIsTopTenTenantIndustries = (
	{ tenantIndustries, filters }: Props,
	options?: UseTopTenantIndustriesOptions
) => {
	const { data: topTenantIndustries } = useTopTenantIndustriesQuery(
		{
			filters,
		},
		options
	);

	return useMemo(() => {
		if (!topTenantIndustries) return false;

		return (
			tenantIndustries.length === 10 &&
			tenantIndustries.every(
				(industry) =>
					!!topTenantIndustries.find((i) => i.industry === industry.name)
			)
		);
	}, [topTenantIndustries, tenantIndustries]);
};
