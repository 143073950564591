import { PropertyLease, usePropertyById } from 'api';
import { PdfColumn } from '../types';
import {
	getIsMonthlyMarket,
	getShouldShowLockIcon,
	renderSafeValue,
} from 'utils';
import {
	formatCompQuarter,
	formatObfuscatedSizeRange,
	formatRentWithPostfixAsString,
	formatSqft,
} from 'format';
import { IconLockGrey } from 'ui/UI';
import { useIsExchange } from 'hooks';
import { PropertyComp } from 'types';

export type SubmarketRecentColumn = PdfColumn<
	Record<string, string | number>,
	PropertyLease
>;

export const useSubmarketRecentLeasesPdfColumns = (property: PropertyComp) => {
	const isExchange = useIsExchange();
	const { data: propertyData } = usePropertyById({ propertyId: property.id });

	if (!propertyData?.marketId) return [];

	const isMonthly = getIsMonthlyMarket(propertyData.marketId);

	const columns: SubmarketRecentColumn[] = [
		{
			id: 'executionQuarter',
			header: 'Trans. Qtr.',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.executionQuarter,
					format: formatCompQuarter,
				});
			},
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'buildingAddress',
			header: 'Address',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.buildingAddress,
				});
			},
			isVisible: true,
		},
		{
			id: 'city',
			header: 'City',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.city,
				});
			},
			isVisible: true,
		},
		{
			id: 'zip',
			header: 'Zip Code',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.zip,
				});
			},
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'tenantName',
			header: 'Tenant',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.tenantName,
				});
			},
			isVisible: true,
		},
		{
			id: 'spaceType',
			header: 'Space Type',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.spaceType,
				});
			},
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'transactionSize',
			header: 'Size',
			flex: 1,
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.transactionSize,
					format: (v) =>
						row.own ? formatSqft(v) : formatObfuscatedSizeRange(v),
				});
			},
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'startingRent',
			header: 'Starting Rent ($/SF)',
			flex: 1,
			getValue: ({ row }) => {
				if (getShouldShowLockIcon(row.startingRent, row, isExchange)) {
					return <IconLockGrey />;
				}

				return renderSafeValue({
					value: row.startingRent,
					format: (v) => formatRentWithPostfixAsString(v, isMonthly),
				});
			},
			isVisible: true,
		},
	];

	return columns;
};
