import { MapStyle } from '@compstak/maps';
import { useTopTenantIndustriesQuery } from 'api';
import { AnalyticsChip } from 'Components';
import { MapAnalyticsType, useSearchPageContext } from 'Layouts/SearchProvider';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { focusFilterSection } from '../actions';
import { useSearchMapState } from '../SearchMapProvider';
import {
	MapAnalyticsMenuContainer,
	MapAnalyticsMenuHeader,
	MapAnalyticsMenuPositionContainer,
} from './UI';
import { getLeaseExpirationPeriod } from './LeaseExpirationAnalytics';

export const MapAnalyticsSelectMenu = () => {
	const [filters, , resetFilters] = useFilters();
	const [, setMapState] = useSearchMapState();
	const dispatch = useDispatch();
	const { setSearchState } = useSearchPageContext();

	const { data: topTenantIndustries } = useTopTenantIndustriesQuery({
		filters,
	});

	const onClickListItemButton = (mapAnalyticsType: MapAnalyticsType) => () => {
		setSearchState((s) => ({
			...s,
			mapAnalyticsType,
		}));
		setMapState((s) => ({
			...s,
			isAnalyticsMenuOpen: false,
			isAnalyticsDetailMenuOpen: true,
			mapStyle: MapStyle.MONOCHROME,
		}));
		if (mapAnalyticsType === 'marketRent') {
			resetFilters({
				spaceTypeId: [1],
			});
			dispatch(focusFilterSection('spaceTypeId'));
		}
		if (mapAnalyticsType === 'leaseExpiration') {
			resetFilters({
				expirationDate: {
					allowFallback: false,
					...getLeaseExpirationPeriod(0),
				},
			});
			dispatch(focusFilterSection('expirationDate'));
		}
		if (mapAnalyticsType === 'spaceType') {
			resetFilters({
				spaceTypeId: [1, 2, 6],
			});
			dispatch(focusFilterSection('spaceTypeId'));
		}
		if (mapAnalyticsType === 'tenantIndusty') {
			resetFilters({
				tenantIndustry: topTenantIndustries?.map((t) => t.industry) ?? [],
			});
			dispatch(focusFilterSection('tenantIndustry'));
		}
	};

	return (
		<MapAnalyticsMenuPositionContainer>
			<MapAnalyticsMenuContainer>
				<MapAnalyticsMenuHeader>
					<AnalyticsChip />
				</MapAnalyticsMenuHeader>
				<ul>
					<li>
						<ListItemButton
							data-qa-id="select-market-rent-analytic-btn"
							onClick={onClickListItemButton('marketRent')}
						>
							Market Rent
						</ListItemButton>
					</li>
					<li>
						<ListItemButton
							data-qa-id="select-lease-expiration-analytic-btn"
							onClick={onClickListItemButton('leaseExpiration')}
						>
							Lease Expiration
						</ListItemButton>
					</li>
					<li>
						<ListItemButton
							data-qa-id="select-space-type-analytic-btn"
							onClick={onClickListItemButton('spaceType')}
						>
							Space Type
						</ListItemButton>
					</li>
					<li>
						<ListItemButton
							data-qa-id="select-tenant-industry-analytic-btn"
							onClick={onClickListItemButton('tenantIndusty')}
						>
							Tenant Industry
						</ListItemButton>
					</li>
				</ul>
			</MapAnalyticsMenuContainer>
		</MapAnalyticsMenuPositionContainer>
	);
};

const ListItemButton = styled.button`
	all: unset;
	height: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 20px;
	justify-content: space-between;
	flex-direction: space-between;
	font-size: 14px;
	color: #303441;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 300;
`;
