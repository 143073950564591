import { useState } from 'react';
import { createSimpleProvider } from 'utils';
import { MapAnalyticsMetric } from '../../types';

export const {
	Provider: TenantIndustryAnalyticsProvider,
	useProviderContext: useTenantIndustryState,
} = createSimpleProvider({
	useValue: () => useState(initialState),
});

const initialState: TenantIndustryState = {
	title: 'Tenant Industry',
	view: 'read',
	metric: 'sqft',
};

export type TenantIndustryState = {
	view: TenantIndustryView;
	title: string;
	metric: MapAnalyticsMetric;
};

export type TenantIndustryView = 'read' | 'settings';
