import center from '@turf/center';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { RadiusFilter } from 'models/filters/types';
import { RadiusFeature } from './type';

export const createRadiusFilterFromRadiusFeature = (
	radiusFeature: RadiusFeature
): RadiusFilter => {
	const centerPoint = center(radiusFeature);
	const radius = distance(
		centerPoint,
		point(radiusFeature.geometry.coordinates[0][0])
	);
	const [lng, lat] = centerPoint.geometry.coordinates;
	const buildingAddressAndCity =
		radiusFeature.properties.buildingAddressAndCity;
	return {
		distance: radius,
		center: { lat, lng },
		buildingAddressAndCity,
	};
};
