import { useMutation } from '@tanstack/react-query';
import { API } from '@compstak/common';

type RequestDemoApiParams = {
	uuid?: string;
	usernameOrEmail?: string;
	origin: string;
};

const fetchRequestDemo = async ({
	uuid,
	usernameOrEmail,
	origin,
}: RequestDemoApiParams): Promise<void> => {
	const response = await API.post<void>('/api/users/requestDemo', {
		uuid,
		usernameOrEmail,
		origin,
	});

	return response.data;
};

export const useRequestDemoMutation = () => {
	return useMutation(fetchRequestDemo);
};
