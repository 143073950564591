export function openFilesPicker(onFilesPick: (files: File[]) => void) {
	const inputEl = document.createElement('input');
	inputEl.type = 'file';
	inputEl.multiple = true;

	function cleanUp() {
		inputEl.removeEventListener('change', handleInputChange);
		inputEl.removeEventListener('cancel', cleanUp);
		inputEl.remove();
	}

	function handleInputChange(ev: Event) {
		try {
			if (!(ev.target instanceof HTMLInputElement)) {
				throw new Error('Event target is not an HTMLInputElement');
			}
			const filesList = ev.target.files;
			const files = filesList ? Array.from(filesList) : [];
			onFilesPick(files);
		} catch (error) {
			console.error(error);
			onFilesPick([]);
		} finally {
			cleanUp();
		}
	}

	inputEl.addEventListener('change', handleInputChange);
	inputEl.addEventListener('cancel', cleanUp);

	inputEl.click();
}
