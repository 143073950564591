import React from 'react';

export default class FallbackSwitcher extends React.Component {
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	switchComparison = (event) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'touch' does not exist on type 'Readonly<... Remove this comment to see the full error message
		this.props.touch();
		try {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onFilterChange' does not exist on type '... Remove this comment to see the full error message
			this.props.onFilterChange({
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'attribute' does not exist on type '... Remove this comment to see the full error message
				[this.props.attribute]: {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'filter' does not exist on type '... Remove this comment to see the full error message
					...this.props.filter,
					allowFallback: event.target.value === 'true',
				},
			});
		} catch (e) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Readon... Remove this comment to see the full error message
			this.props.setError(e.message);
		}
	};

	render = () => (
		<div>
			{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message */}
			{this.props.options.map((option, i) => {
				return (
					<div key={i}>
						<input
							type="radio"
							className="radio"
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'attribute' does not exist on type 'Reado... Remove this comment to see the full error message
							name={'fallback-selector' + this.props.attribute}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'attribute' does not exist on type 'Reado... Remove this comment to see the full error message
							id={'fallback-selector-' + this.props.attribute + '-' + i}
							value={option.value || ''}
							checked={
								option.value
									? // @ts-expect-error ts-migrate(2339) FIXME: Property 'filter' does not exist on type 'Readonly... Remove this comment to see the full error message
										this.props.filter?.allowFallback ?? true
									: // @ts-expect-error ts-migrate(2339) FIXME: Property 'filter' does not exist on type 'Readonly... Remove this comment to see the full error message
										!(this.props.filter?.allowFallback ?? true)
							}
							onChange={this.switchComparison}
						/>
						<label
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'attribute' does not exist on type 'Reado... Remove this comment to see the full error message
							htmlFor={'fallback-selector-' + this.props.attribute + '-' + i}
						>
							{option.name}
						</label>
					</div>
				);
			})}
		</div>
	);
}
