import { Market } from '@compstak/common';
import { CompType } from 'types';
import { useMarketsHaveFeature } from 'api';

export const useUnlockButtonIsVisible = (
	compType: CompType,
	markets: Market[]
) => {
	const feature =
		compType === 'sale' ? 'ownsAllSalesComps' : 'ownsAllLeaseComps';

	return !useMarketsHaveFeature({
		feature,
		marketIds: markets.map(({ id }) => id),
	}).data;
};
