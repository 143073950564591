import { numberUtils } from '@compstak/ui-kit';
import * as exportActions from 'actions/export';
import { showExportMenu } from 'actions/export';
import 'Components/Menus/Export';
import styles from 'Components/UnlockOrExportButton/unlockOrExportButton.less';
import { memo, useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import * as modalActions from 'Singletons/Modal/actions';
import { Comp, CompType } from 'types/comp';
import buttonStyles from 'ui/styles/button.less';
import ExportV2Icon from 'ui/svg_icons/export_v2.svg';
import actionWrapper from 'util/actionWrapper';
import pluralizeCompType from 'util/pluralizeCompType';
import { useAppDispatch } from 'util/useAppDispatch';
import { useAppSelector } from 'util/useAppSelector';
import { EXPLICIT_SPACE } from '../../utils';

type Props = {
	className?: string;
	inactiveClassName?: string;
	compType: CompType;
	menuSide?: string;
	variant?: 'light' | 'default';
	selection?: Comp[];
};

const ExportButtonComponent = memo<Props>((props) => {
	const dispatch = useAppDispatch();
	const selection = useAppSelector((s) => props.selection ?? s.selection.list);

	const ref = useRef<HTMLButtonElement>(null);

	const { menuSide = 'below', variant = 'default' } = props;

	const selectedOwnedLeases = useMemo(() => {
		const isFiltering = props.compType !== 'property';

		const selected = isFiltering
			? selection.filter((comp) => 'own' in comp && comp.own)
			: selection;

		return selected;
	}, [props.compType, selection]);

	const handleShowExportMenu = useCallback(() => {
		if (selectedOwnedLeases.length === 0 || !ref.current) {
			return;
		}
		dispatch(
			showExportMenu(selectedOwnedLeases, props.compType, ref.current, menuSide)
		);
	}, [dispatch, menuSide, props.compType, selectedOwnedLeases]);

	const className = useMemo(() => {
		const ownedLength = selectedOwnedLeases.length;
		const final = ownedLength === 0 ? buttonStyles.disabled : buttonStyles.blue;

		return `${final} ${props.className || ''}`;
	}, [props.className, selectedOwnedLeases]);

	const counter = useMemo(() => {
		if (props.compType === 'property') {
			return selection.length;
		} else {
			return selection.reduce((count, comp) => {
				if ('own' in comp && comp.own) {
					return count + 1;
				} else {
					return count;
				}
			}, 0);
		}
	}, [props.compType, selection]);

	return (
		<button
			ref={ref}
			onClick={handleShowExportMenu}
			className={className}
			data-variant={variant}
			data-placement="bottom"
			data-tooltipdelay="0"
			data-tooltip={
				selectedOwnedLeases.length === 0
					? `Select ${pluralizeCompType(props.compType, true)} to export`
					: props.compType === 'property'
						? 'Export multiple properties'
						: 'Export multiple comps'
			}
			data-qa-id={`export-button`}
			style={{ margin: 0 }}
		>
			<ExportV2Icon />
			{props.compType !== 'property' && (
				//temporarily hidden on the Properties page because of many overlapping buttons-> https://compstak.atlassian.net/browse/AP-14020
				<span className={buttonStyles.label}>EXPORT</span>
			)}
			<span className={buttonStyles.number}>
				<span className={styles.exportButtonAmount}>
					{EXPLICIT_SPACE}(
					{numberUtils.formatNumber(
						counter,
						counter < 999 || counter > 99999 ? 'integer' : 'oneDecimal',
						'internationalSystem'
					)}
					)
				</span>
			</span>
		</button>
	);
});
ExportButtonComponent.displayName = 'ExportButtonComponent';

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return actionWrapper(
		{
			exportActions,
			modalActions,
		},
		dispatch
	);
}

export const ExportButton = connect(
	null,
	mapDispatchToProps
)(ExportButtonComponent);
