import {
	G,
	Path,
	Polygon,
	StyleSheet,
	Svg,
	Text,
	View,
} from '@react-pdf/renderer';
import { TreppLoan } from 'api';
import { formatDate, formatMoney, formatPercent } from 'format';
import React from 'react';
import { pdfStyle } from '../style';
import { renderSafeValue } from 'utils';
import { getLoanNOI } from 'util/getLoanNOI';

type Props = {
	loan: TreppLoan;
};

export const PrivateLoansTablePdf = ({ loan }: Props) => {
	return (
		<>
			<View
				style={[
					styles.titleContainer,
					pdfStyle.dFlex,
					styles.itemsCenter,
					styles.justifyBetween,
					pdfStyle.flexRow,
				]}
			>
				<Text style={styles.widgetTitle}>Most Recent CMBS Loan</Text>
				<View
					style={[
						styles.treppLogoContainer,
						pdfStyle.dFlex,
						pdfStyle.flexRow,
						styles.itemsCenter,
					]}
				>
					<Text style={[styles.treppText, styles.colorGray]}>Powered by</Text>
					<TreppLogo />
				</View>
			</View>

			<View style={[styles.row]}>
				<Cell
					label="Lender"
					value={renderSafeValue({ value: loan.loanOriginator })}
				/>
				<Cell
					label="Loan Amount"
					value={renderSafeValue({
						value: loan.loanAmount,
						format: (v) =>
							formatMoney(v, {
								shorten: false,
								decimalPlaces: 0,
							}),
					})}
				/>
				<Cell
					label="Original Loan Term"
					value={renderSafeValue({ value: loan.loanTerm + ' Months' })}
				/>
				<Cell
					label="Interest Type"
					value={renderSafeValue({ value: loan.loanInterestType })}
				/>
			</View>

			<View style={[styles.row]}>
				<Cell
					label="Appraised Value"
					value={renderSafeValue({
						value: loan.loanAppraisedValue,
						format: (v) => formatMoney(v, { shorten: false, decimalPlaces: 0 }),
					})}
				/>
				<Cell
					label="Loan Rate"
					value={renderSafeValue({
						value: loan.currentLoanRate,
						format: (v) => formatPercent(v / 100),
					})}
					asOfDate={`as of ${formatDate(loan.loanUpdatedAt.currentLoanRate, 'MM/DD/YYYY')}`}
				/>
				<Cell
					label="Loan Maturity Date"
					value={renderSafeValue({
						value: loan.loanMaturityDate,
						format: formatDate,
					})}
				/>
				<Cell
					label="Origination Date"
					value={renderSafeValue({
						value: loan.loanOriginationDate,
						format: formatDate,
					})}
				/>
			</View>

			<View style={[styles.row]}>
				<Cell
					label="Net Operating Income"
					value={renderSafeValue({
						value: getLoanNOI(loan),
						format: (v) => formatMoney(v, { shorten: false, decimalPlaces: 0 }),
					})}
					asOfDate={`as of ${formatDate(loan.loanUpdatedAt.loanNOI, 'MM/DD/YYYY')}`}
				/>
				<Cell
					label="Occupancy"
					value={renderSafeValue({
						value: loan.loanOccupancy,
						format: (v) => formatPercent(v / 100, 1),
					})}
					asOfDate={`as of ${formatDate(loan.loanUpdatedAt.loanOccupancy, 'MM/DD/YYYY')}`}
				/>
				<Cell
					label="Loan-to-Value (LTV)"
					value={renderSafeValue({
						value: loan.loanToValue,
						format: (v) => formatPercent(v / 100),
					})}
					asOfDate={`as of ${formatDate(loan.loanUpdatedAt.loanToValue, 'MM/DD/YYYY')}`}
				/>
			</View>
		</>
	);
};

const TreppLogo = () => (
	<Svg width={32} height={32} viewBox="0 0 115 38">
		<G stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<G transform="translate(-358.000000, -100.000000)" fill="#226194">
				<G transform="translate(358.000000, 100.000000)">
					<Polygon points="0 0 0 5.25134459 9.20860336 5.25134459 9.20860336 28.88 14.6778067 28.88 14.6778067 5.25134459 23.6 5.25134459 23.6 0"></Polygon>
					<G transform="translate(20.554839, 5.320000)">
						<Path d="M0.506231919,23.2868091 L0.506231919,2.80125494 L5.15185616,2.80125494 L5.44173091,5.14268082 C5.44173091,5.14268082 6.89301172,2.50905729 10.9579329,2.50905729 C15.0219006,2.50905729 16.4741349,4.55732435 16.4741349,4.55732435 L14.1513228,8.94701729 C14.1513228,8.94701729 12.700042,7.48410671 10.3772299,7.48410671 C8.05441778,7.48410671 5.44173091,9.23921494 5.44173091,11.8737996 L5.44173091,23.2868091 L0.506231919,23.2868091 Z"></Path>
						<Path d="M37.377537,11.5809292 C37.377537,11.5809292 37.377537,2.21618682 26.9248824,2.21618682 C16.1833067,2.21618682 16.4731814,13.0438398 16.4731814,13.0438398 C16.4731814,13.0438398 16.4731814,24.1646515 26.9248824,24.1646515 C34.1831935,24.1646515 36.2151774,20.6534739 36.2151774,20.6534739 L33.022741,17.4335327 C33.022741,17.4335327 30.9907572,19.4827609 27.5055855,19.4827609 C22.2811653,19.4827609 21.1188057,15.093068 21.1188057,15.093068 L37.377537,15.093068 L37.377537,11.5809292 Z M21.2646966,10.8494739 C21.2646966,10.8494739 22.4251491,6.75197859 26.7808986,6.75197859 C32.2971006,6.75197859 32.8768501,10.8494739 32.8768501,10.8494739 L21.2646966,10.8494739 Z"></Path>
						<Path d="M51.8928242,2.50886506 C47.2472,2.50886506 44.9253414,5.43564741 44.9253414,5.43564741 L44.9253414,2.80202388 L40.2797172,2.80202388 L40.1347798,31.9199039 L45.0712323,31.9199039 L45.0712323,21.5315086 C45.0712323,21.5315086 47.5389818,24.1651321 52.1836525,24.1651321 C56.8292768,24.1651321 62.3445253,20.6529933 62.3445253,13.336518 C62.3445253,6.02100388 56.5384485,2.50886506 51.8928242,2.50886506 M51.4570586,19.3361815 C48.0910788,19.3361815 45.3601535,16.5843333 45.3601535,13.1904192 C45.3601535,9.79650506 48.0910788,7.04465682 51.4570586,7.04465682 C54.8249455,7.04465682 57.5539636,9.79650506 57.5539636,13.1904192 C57.5539636,16.5843333 54.8249455,19.3361815 51.4570586,19.3361815"></Path>
						<Path d="M75.4090085,2.50886506 C70.7633842,2.50886506 68.4415257,5.43564741 68.4415257,5.43564741 L68.4415257,2.80202388 L63.7959014,2.80202388 L63.650964,31.9199039 L68.5874166,31.9199039 L68.5874166,21.5315086 C68.5874166,21.5315086 71.0551661,24.1651321 75.6998368,24.1651321 C80.345461,24.1651321 85.8607095,20.6529933 85.8607095,13.336518 C85.8607095,6.02100388 80.0546327,2.50886506 75.4090085,2.50886506 M74.9732428,19.3361815 C71.607263,19.3361815 68.8763378,16.5843333 68.8763378,13.1904192 C68.8763378,9.79650506 71.607263,7.04465682 74.9732428,7.04465682 C78.3411297,7.04465682 81.0701479,9.79650506 81.0701479,13.1904192 C81.0701479,16.5843333 78.3411297,19.3361815 74.9732428,19.3361815"></Path>
						<Path d="M90.0705681,0.0214364706 C87.9861398,0.0214364706 86.2964752,1.72464118 86.2964752,3.82577294 C86.2964752,5.92690471 87.9861398,7.63010941 90.0705681,7.63010941 C92.1559499,7.63010941 93.844661,5.92690471 93.844661,3.82577294 C93.844661,1.72464118 92.1559499,0.0214364706 90.0705681,0.0214364706 M90.0705681,7.04475294 C88.3065277,7.04475294 86.8771782,5.60394941 86.8771782,3.82577294 C86.8771782,2.04759647 88.3065277,0.606792941 90.0705681,0.606792941 C91.8346085,0.606792941 93.263958,2.04759647 93.263958,3.82577294 C93.263958,5.60394941 91.8346085,7.04475294 90.0705681,7.04475294"></Path>
						<Path d="M88.3506764,1.496554 C88.3506764,1.496554 89.6331814,1.48501988 90.0269915,1.48501988 C90.6372541,1.48501988 91.0310642,1.59747753 91.3075895,1.84834459 C91.5307168,2.04730812 91.6546764,2.35103988 91.6546764,2.69514106 C91.6546764,3.28434224 91.2875653,3.67457988 90.8212865,3.833174 L90.8212865,3.85335871 C91.1626521,3.97254459 91.3667087,4.28973282 91.470644,4.75301988 C91.6155814,5.37490106 91.7204703,5.80454694 91.8129632,5.97659753 L91.2217713,5.97659753 C91.1493026,5.85068341 91.0510885,5.467174 90.9271289,4.911614 C90.7945875,4.29646106 90.5581107,4.06481753 90.0393875,4.04463282 L88.921844,4.04463282 L88.921844,5.97659753 L88.3506764,5.97659753 L88.3506764,1.496554 Z M88.921844,3.60825871 L90.0870642,3.60825871 C90.6973269,3.60825871 91.0844622,3.27088576 91.0844622,2.76146224 C91.0844622,2.18571753 90.6706279,1.93485047 90.06704,1.92812224 C90.026038,1.92812224 88.9142158,1.93869518 88.9142158,1.93869518 L88.921844,3.60825871 Z"></Path>
					</G>
				</G>
			</G>
		</G>
	</Svg>
);

const Cell = ({
	label,
	value,
	asOfDate,
}: {
	label: string;
	value: string;
	asOfDate?: string;
}) => (
	<View
		style={[
			styles.column,
			pdfStyle.flexRow,
			styles.justifyBetween,
			styles.itemsCenter,
		]}
	>
		<View style={[styles.labelContainer, pdfStyle.flexCol]}>
			<Text style={styles.colorGray}>{label}</Text>
			{asOfDate && (
				<Text style={[styles.asOfDate, styles.colorGray]}>{asOfDate}</Text>
			)}
		</View>
		<Text style={styles.value}>{value}</Text>
		<View style={styles.borderBottom}></View>
	</View>
);

const styles = StyleSheet.create({
	itemsCenter: {
		alignItems: 'center',
	},
	justifyBetween: { justifyContent: 'space-between' },
	titleContainer: {
		borderBottom: '1px solid #eaeaea',
		marginBottom: '8px',
		marginTop: '16px',
	},
	treppLogoContainer: {
		gap: 4,
		alignItems: 'center',
	},
	widgetTitle: {
		fontSize: '14px',
		lineHeight: '1.5',
		fontStyle: 'normal',
		fontWeight: 500,
	},
	treppText: {
		fontSize: '8px',
	},
	row: {
		flexDirection: 'row',
		paddingVertical: 2,
	},
	column: {
		width: '25%',
		padding: 6,
		position: 'relative',
		fontSize: 10.5,
	},
	borderBottom: {
		position: 'absolute',
		width: '100%',
		height: '1px',
		backgroundColor: '#eaeaea',
		left: '6px',
		bottom: 0,
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	colorGray: { color: '#555' },
	value: {
		color: '#000',
		textAlign: 'right',
		alignSelf: 'center',
		fontFamily: 'Courier-Bold',
		width: '91%',
	},
	asOfDate: {
		fontSize: 8,
		textAlign: 'right',
	},
});
