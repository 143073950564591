import {
	ExportMarketTableView,
	UseExportMarketTableReturnType,
} from './ExportMarketTable';
import button from '../../../ui/styles/button.less';
import styles from './exportMenu.less';
import formatNumber from '../../../util/comp-format/src/util/number';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import Help from '../../../ui/svg_icons/help.svg';
import CopyToClipboardButton from '../../CopyToClipboardButton';
import { addFeedback } from 'Singletons/Feedback/actions';

import { ExcelIconStyled, PDFIconStyled, ExportFormWrap } from './UI';
import { EXPORT_COMPS_LIMIT, exportButtonClass } from './const';
import { useDispatch } from 'react-redux';
import { hideMenu } from '../../../Singletons/Menu/actions';
import { CompType } from '../../../types';
import { ExportCheckStatus } from '../../../reducers/export';
import { ChangeEvent } from 'react';
import { useAppSelector } from 'util/useAppSelector';
import { EXPORT_PDF_ROUTES } from 'router';

const disabledClasses = `${button.disabled} ${button.safariFlexboxBugged} ${styles.action}`;

interface InitialExportDialogProps {
	compType: CompType;
	compIds: number[];
	unlimitedCompsIds: number[];
	withFinancial: boolean;
	setWithFinancial: (value: ChangeEvent<HTMLInputElement>) => void;
	status: ExportCheckStatus;
	singleComp?: boolean;
	onExcelButtonClick: NoArgCallback;
	exportMarketTableProps: UseExportMarketTableReturnType;
}

export const InitialExportDialog = ({
	compType,
	compIds,
	unlimitedCompsIds,
	withFinancial,
	setWithFinancial,
	status,
	singleComp,
	onExcelButtonClick,
	exportMarketTableProps,
}: InitialExportDialogProps) => {
	const {
		totalSelectedExportAmount,
		selectedMarketsCount,
		totalFinancialAvailable,
	} = exportMarketTableProps;

	const user = useAppSelector((state) => state.user!);
	const dispatch = useDispatch();

	let financialOptions = (
		<TwoFinancialOptions
			setWithFinancial={setWithFinancial}
			withFinancial={withFinancial}
			financialExportAmount={'unlimited'}
			nonFinancialExportAmount={'unlimited'}
		/>
	);

	let disabled = false,
		disabledTooltipMessage = '',
		showFinancialTypeOptions = false,
		showMarketTable = false,
		nonFinancialAvailableAmount;

	if (!status.unlimited) {
		const isPerMarketPolicy = !!status.perMarket;

		const financialExportRemainingAmount =
			singleComp || !isPerMarketPolicy
				? status.availableWithFinancial
				: totalFinancialAvailable;

		const isNoneSelected =
			!singleComp && isPerMarketPolicy ? selectedMarketsCount === 0 : false;

		const isSystemLimitExceed =
			!singleComp &&
			(isPerMarketPolicy ? totalSelectedExportAmount : compIds.length) >
				EXPORT_COMPS_LIMIT;

		nonFinancialAvailableAmount = status.availableWithoutFinancial;
		const isNonFinancialLimitExceed =
			!withFinancial &&
			!singleComp &&
			// @ts-expect-error TS18048: 'nonFinancialAvailableAmount' ...
			totalSelectedExportAmount > nonFinancialAvailableAmount;

		const isGlobalPolicyLimitExceed = !isPerMarketPolicy && !status.canExport;

		disabledTooltipMessage = '';
		if (isNoneSelected) {
			disabledTooltipMessage = disabledMessages.noneSelected;
		} else if (isSystemLimitExceed) {
			disabledTooltipMessage = disabledMessages.systemLimitExceed;
		} else if (isNonFinancialLimitExceed) {
			disabledTooltipMessage =
				disabledMessages.getNonFinancialLimitExceedMessage(
					// @ts-expect-error TS2345: Argument of type 'number | und...
					nonFinancialAvailableAmount
				);
		} else if (isGlobalPolicyLimitExceed) {
			disabledTooltipMessage =
				disabledMessages.getGlobalPolicyLimitExceedMessage(
					status.availableWithFinancial
				);
		}

		// in practice, non-financial option is available only for per-market policy
		// and the PO decision is to restrict it explicitly
		showFinancialTypeOptions =
			// @ts-expect-error TS2339: Property 'allowNonFinancialExp...
			user.allowNonFinancialExports &&
			compType === 'lease' &&
			isPerMarketPolicy;

		showMarketTable = !singleComp && isPerMarketPolicy;

		disabled =
			isNoneSelected ||
			isSystemLimitExceed ||
			isNonFinancialLimitExceed ||
			isGlobalPolicyLimitExceed;

		financialOptions = (
			<TwoFinancialOptions
				setWithFinancial={setWithFinancial}
				withFinancial={withFinancial}
				financialExportAmount={financialExportRemainingAmount}
				nonFinancialExportAmount={nonFinancialAvailableAmount}
			/>
		);
	}

	return (
		<ExportFormWrap>
			{showFinancialTypeOptions && financialOptions}
			{showMarketTable && !status.unlimited && (
				<ExportMarketTableView
					perMarketPolicy={status.perMarket}
					withFinancial={withFinancial}
					// @ts-expect-error TS2322: Type 'number | undefined' is n...
					nonFinancialAvailableAmount={nonFinancialAvailableAmount}
					{...exportMarketTableProps}
				/>
			)}
			<div className={styles.actions}>
				<span
					className={disabled ? disabledClasses : exportButtonClass}
					data-tooltip={disabled ? disabledTooltipMessage : null}
					onClick={disabled ? undefined : onExcelButtonClick}
					data-qa-id={excelButtonTestId}
				>
					<ExcelIconStyled width={14} height={16} />
					Excel
				</span>
				{compType !== 'property' && (
					<ExportPDFButton
						disabled={disabled || !withFinancial}
						href={EXPORT_PDF_ROUTES[compType].toHref({ compIds })}
						disabledMessage={
							disabledTooltipMessage ||
							(!withFinancial && disabledMessages.pdfOnlyForFinancial)
						}
					/>
				)}
				{user.userType === 'admin' ? (
					// @ts-expect-error TS2769: No overload matches this call....
					<CopyToClipboardButton
						label="IDs"
						className={exportButtonClass}
						textToCopy={unlimitedCompsIds.join()}
						onSuccess={() => {
							dispatch(addFeedback('Comp IDs have been copied to clipboard'));
							dispatch(hideMenu('export-menu'));
						}}
						onError={() => {
							dispatch(addFeedback('Failed to copy comp IDs to clipboard'));
							dispatch(hideMenu('export-menu'));
						}}
					>
						IDs
					</CopyToClipboardButton>
				) : (
					false
				)}
			</div>
		</ExportFormWrap>
	);
};

export const disabledMessages = {
	pdfOnlyForFinancial: 'PDF export is only available with financial terms',
	noneSelected:
		'No markets available to export. Please refine your filters to export your comps.',
	systemLimitExceed: `Exports are limited to ${formatNumber(
		EXPORT_COMPS_LIMIT
	)} comps per file. Please refine your filters to export your comps.`,
	getNonFinancialLimitExceedMessage: (nonFinancialAvailableAmount: number) =>
		`You only have ${nonFinancialAvailableAmount} exports remaining. Please refine your filters or change your selected markets to export your comps.`,
	getGlobalPolicyLimitExceedMessage: (availableWithFinancial: number) =>
		`You only have ${availableWithFinancial} exports remaining. Please refine your filters or change your selected markets to export your comps.`,
};

export const financialOptionTestId = 'financialOptionTestId';
export const financialOptionRadioTestId = 'financialOptionRadioTestId';
export const financialOptionLabelTestId = 'financialOptionLabelTestId';
export const excelButtonTestId = 'excelButtonTestId';
export const pdfButtonTestId = 'export-pdf-link';

const FinancialOption = ({
	// @ts-expect-error TS7031: Binding element 'onChange' imp...
	onChange,
	// @ts-expect-error TS7031: Binding element 'checked' impl...
	checked,
	// @ts-expect-error TS7031: Binding element 'value' implic...
	value,
	// @ts-expect-error TS7031: Binding element 'label' implic...
	label,
	// @ts-expect-error TS7031: Binding element 'tooltip' impl...
	tooltip,
	// @ts-expect-error TS7031: Binding element 'exportAmount'...
	exportAmount,
}) => {
	const id = `export-with-${value}`;
	return (
		<div
			className={`input-container ${styles.radioButtonContainer}`}
			data-qa-id={financialOptionTestId}
		>
			<span className={styles.labelWrap}>
				<input
					type="radio"
					id={id}
					onChange={onChange}
					checked={checked}
					value={value}
					data-qa-id={financialOptionRadioTestId}
				/>
				<label htmlFor={id} data-qa-id={financialOptionLabelTestId}>
					<span className={styles.exportType}>{label}</span>
				</label>
				<span
					className={styles.tooltip}
					data-tooltip={tooltip}
					data-tooltip-position="above-onright"
				>
					<Help width="10px" height="10px" />
				</span>
			</span>
			<span className={styles.exportsLeft}>
				{exportAmount} exports remaining
			</span>
		</div>
	);
};

export const TwoFinancialOptions = ({
	// @ts-expect-error TS7031: Binding element 'setWithFinanc...
	setWithFinancial,
	// @ts-expect-error TS7031: Binding element 'withFinancial...
	withFinancial,
	// @ts-expect-error TS7031: Binding element 'financialExpo...
	financialExportAmount,
	// @ts-expect-error TS7031: Binding element 'nonFinancialE...
	nonFinancialExportAmount,
}) => {
	return (
		<div>
			<FinancialOption
				onChange={setWithFinancial}
				checked={withFinancial}
				value="full"
				label="With Financial Terms"
				tooltip="The export will include every attribute."
				exportAmount={financialExportAmount}
			/>
			<FinancialOption
				onChange={setWithFinancial}
				checked={!withFinancial}
				value="limited"
				label="Without Financial Terms"
				tooltip="The export will not include financial data such as starting rent, effective rent, or concession information."
				exportAmount={nonFinancialExportAmount}
			/>
		</div>
	);
};

// @ts-expect-error TS7031: Binding element 'compType' imp...
const ExportPDFButton = ({ disabled, disabledMessage, href }) => {
	return (
		<a
			data-qa-id={pdfButtonTestId}
			href={disabled ? undefined : href}
			onClick={(event) => {
				if (disabled) event.preventDefault();
			}}
			data-modal="true"
			className={disabled ? disabledClasses : exportButtonClass}
			data-tooltip={disabled ? disabledMessage : null}
		>
			<PDFIconStyled width={14} height={16} />
			PDF
		</a>
	);
};
