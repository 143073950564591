import dayjs from 'dayjs';
import { SubmarketSupplyDTO } from 'api';

export const adaptData = (data: SubmarketSupplyDTO | undefined) => {
	if (!data?.supplyHistogram?.histogram.length) return [];

	return data.supplyHistogram.histogram.map(
		({ year, quarter, supplyValue }) => {
			let dateFormatted = dayjs().set('year', year).set('month', 0).date(1);

			if (quarter) {
				const monthNumber = 3 * (quarter - 1);
				dateFormatted = dateFormatted.set('month', monthNumber);
			}

			return {
				name: dateFormatted.format('YYYY-MM-DD'),
				supplyValue,
			};
		}
	);
};
