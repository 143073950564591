import { renderRentPeriodPostfix } from 'format';
import { Comp } from 'types';
import LockIcon from '../../../ui/svg_icons/lock.svg';
import AttributeValue from '../AttributeValue';

type ReportedRentWithDateProps = {
	comp: Comp;
	isMonthly: boolean;
};

export const ReportedRentWithDate = ({
	comp,
	isMonthly,
}: ReportedRentWithDateProps) => {
	// @ts-expect-error TS2339: Property 'reportedRentWithDate...
	if (comp.reportedRentWithDate === null) {
		return (
			<span className="locked-data">
				<LockIcon />
			</span>
		);
	}

	const typeOfMarket = isMonthly ? 'a MONTHLY' : 'an ANNUAL';
	const tooltip = `Reported Rent for this lease is set at ${typeOfMarket} amount`;
	// @ts-expect-error TS2339: Property 'reportedRentWithDate...
	const value = comp.reportedRentWithDate;
	const valueOnly = value.split(' ').slice(0, -1).join(' ');

	const annualOrMonthly = renderRentPeriodPostfix(isMonthly, true);
	return (
		<span data-placement="bottom" data-tooltipdelay="0" data-tooltip={tooltip}>
			{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
			<AttributeValue value={valueOnly} />
			{annualOrMonthly}
		</span>
	);
};
