import {
	MufaChartsNames as ChartsNames,
	FiltersNames,
} from 'types/propertyTrendCharts';
import { MufaTrendsFiltersState } from 'Components/MufaSubmarket/useMufaTrendsState';
import {
	useExpensesPerUnitData,
	useIsMufaProperty,
	useLeaseTradeOutData,
	useNOIData,
	useOccupancyData,
	useSubmarketMarketTrendData,
	useSubmarketSupplyData,
} from 'api';
import { useFeatureFlags } from './useFeatureFlags';

//not changed by filters
const NOI_METRIC = 'perUnit';
const EXPENSES_PER_UNIT_PERIOD = 'monthly';

export const useMufaTrendsData = ({
	propertyId,
	filters,
}: {
	propertyId: number;
	filters: MufaTrendsFiltersState;
}) => {
	const flags = useFeatureFlags();

	const { data: isMufa } = useIsMufaProperty({ propertyId });

	const submarketMarketTrend = useSubmarketMarketTrendData(
		{
			id: propertyId,
			metric: filters[ChartsNames.SubmarketMarketTrend][FiltersNames.METRIC],
			fromDate:
				filters[ChartsNames.SubmarketMarketTrend][FiltersNames.PERIOD].start,
			toDate:
				filters[ChartsNames.SubmarketMarketTrend][FiltersNames.PERIOD].end,
		},
		{ enabled: isMufa }
	);

	const submarketLeaseTradeOut = useLeaseTradeOutData(
		{
			id: propertyId,
			fromDate:
				filters[ChartsNames.SubmarketLeaseTradeOut][FiltersNames.PERIOD].start,
			toDate:
				filters[ChartsNames.SubmarketLeaseTradeOut][FiltersNames.PERIOD].end,
		},
		{ enabled: isMufa }
	);

	const submarketOccupancy = useOccupancyData(
		{
			propertyId,
			metric: filters[ChartsNames.SubmarketOccupancy][FiltersNames.METRIC],
			fromDate:
				filters[ChartsNames.SubmarketOccupancy][FiltersNames.PERIOD].start,
			toDate: filters[ChartsNames.SubmarketOccupancy][FiltersNames.PERIOD].end,
		},
		{ enabled: isMufa }
	);

	const submarketSupply = useSubmarketSupplyData(
		{
			id: propertyId,
			metric: filters[ChartsNames.SubmarketSupply][FiltersNames.METRIC],
			fromDate: filters[ChartsNames.SubmarketSupply][FiltersNames.PERIOD].start,
			toDate: filters[ChartsNames.SubmarketSupply][FiltersNames.PERIOD].end,
		},
		{ enabled: isMufa }
	);

	const submarketNOI = useNOIData(
		{
			id: propertyId,
			metric: NOI_METRIC,
			fromDate: filters[ChartsNames.SubmarketNOI][FiltersNames.PERIOD].start,
			toDate: filters[ChartsNames.SubmarketNOI][FiltersNames.PERIOD].end,
		},
		{ enabled: isMufa && flags['expensesNOI'] }
	);

	const expensesPerUnit = useExpensesPerUnitData(
		{
			id: propertyId,
			fromDate:
				filters[ChartsNames.SubmarketExpensesPerUnit][FiltersNames.PERIOD]
					.start,
			toDate:
				filters[ChartsNames.SubmarketExpensesPerUnit][FiltersNames.PERIOD].end,
			period: EXPENSES_PER_UNIT_PERIOD,
		},
		{ enabled: isMufa && flags['expensesNOI'] }
	);

	return {
		data: {
			submarketMarketTrend,
			submarketLeaseTradeOut,
			submarketOccupancy,
			submarketSupply,
			submarketNOI,
			expensesPerUnit,
		},
		isLoading:
			submarketMarketTrend.isLoading ||
			submarketLeaseTradeOut.isLoading ||
			submarketOccupancy.isLoading ||
			submarketSupply.isLoading ||
			submarketNOI.isLoading ||
			expensesPerUnit.isLoading,
		isFetching:
			submarketMarketTrend.isFetching ||
			submarketLeaseTradeOut.isFetching ||
			submarketOccupancy.isFetching ||
			submarketSupply.isFetching ||
			submarketNOI.isFetching ||
			expensesPerUnit.isFetching,
	};
};
