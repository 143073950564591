import { User } from '@compstak/common';
import { trimString } from './trimString';

export function isValidEmail(str: string) {
	// regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
	const re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(str);
}

export const domainsMatch = (email: string, user: User) => {
	const userEmail = user.email;
	const userDomain = userEmail.substring(userEmail.indexOf('@') + 1);
	const targetDomain = email.substring(email.indexOf('@') + 1);

	return (
		userDomain === targetDomain ||
		(targetDomain.toLowerCase() === 'compstak.com' &&
			userDomain.toLowerCase() === 'compstak.com')
	);
};

export const validateEmail = (email: string, user: User) => {
	const trimmedEmail = trimString(email);

	if (!trimmedEmail) {
		return 'Field is empty';
	} else if (!isValidEmail(trimmedEmail)) {
		return 'Email is not valid';
	} else if (user.email.toLowerCase() === trimmedEmail.toLowerCase()) {
		return "You can't use your own email";
	} else if (!domainsMatch(trimmedEmail, user)) {
		const domain = user.email.substring(user.email.indexOf('@') + 1);
		return `Email domain must match your account's email domain (@${domain})`;
	}

	return '';
};

export function isValidPhone(str: string | number) {
	// regex from http://stackoverflow.com/questions/123559/a-comprehensive-regex-for-phone-number-validation
	const re =
		/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
	return re.test(String(str));
}
