import { TooltipV2 } from '@compstak/ui-kit';
import { useSplitCompset } from 'api';
import { routes } from 'router';
import styled from 'styled-components';

export const CompsetLabel = () => {
	const params = routes.propertyById.useParams();
	const { data } = useSplitCompset({ propertyId: params.propertyId });

	return (
		<TooltipV2
			content={
				<List>
					{data?.compset.map((p) => (
						<li key={p.property.id}>{p.property.buildingAddressAndCity}</li>
					))}
				</List>
			}
		>
			<span>Competitive Set</span>
		</TooltipV2>
	);
};

const List = styled.ul`
	list-style-type: disc;
	padding-left: 0.75rem;
`;
