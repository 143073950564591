import { API } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { UNLOCK_SALES } from 'actions/sale';
import { invalidateUserQuery } from 'hooks/userHooks';
import { useDispatch } from 'react-redux';
import { onSalesUnlock } from 'services/sale';
import { SalesComp } from 'types';
import { MUTATION_KEYS, QUERY_KEYS } from '../constants';
import { updateCachePropertyComps } from '../utils';
import { isSaleByIdQuery, updateCacheSaleById } from './updateCacheSaleById';

export const useUnlockSalesMutation = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutation({
		mutationKey: [MUTATION_KEYS.UNLOCK_SALES],
		mutationFn: async ({ ids }: UnlockSalesParams) => {
			const promise = API.put<UnlockSalesResponse>(`/api/salesComps/unlock`, {
				ids,
			});
			// redux legacy
			dispatch({
				type: UNLOCK_SALES,
				meta: {
					ids,
				},
				payload: {
					ids,
					promise: promise.then((res) => {
						onSalesUnlock(res.data);
						return res.data.comps;
					}),
				},
			});

			return (await promise).data;
		},
		onSuccess: (data) => {
			updateCacheSaleById(queryClient, data);
			updateCachePropertyComps('sale', queryClient, data);
			invalidateSalesQueries(queryClient, data.comps);
			invalidateUserQuery(queryClient);
		},
	});
};

export const invalidateSalesQueries = (
	queryClient: QueryClient,
	comps: UnlockSalesResponse['comps']
) => {
	queryClient.invalidateQueries({
		predicate: (query) => {
			const [staticKey] = query.queryKey;
			const isPluralQuery = [
				QUERY_KEYS.SEARCH_SALES,
				QUERY_KEYS.SEARCH_SALES_INFINITE,
				QUERY_KEYS.PROPERTY_SALES,
				QUERY_KEYS.SIMILAR_SALES,
			].includes(staticKey as QUERY_KEYS);

			return isPluralQuery || isSaleByIdQuery(query, comps);
		},
	});
};

export type UnlockSalesParams = {
	ids: number[];
};

export type UnlockSalesResponse = {
	points: number;
	freeComps: number;
	comps: SalesComp[];
};
