import {
	SearchInfiniteParams,
	useSearchMufaPropertiesInfiniteQuery,
	useSearchPropertiesInfiniteQuery,
} from 'api';
import { useIsMultifamily } from 'hooks';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

export const useUnifiedPropertiesInfiniteQuery = (
	params: SearchInfiniteParams
) => {
	const [filters] = useFilters();

	const markets = useMemo(() => getFiltersMarkets(filters), [filters]);

	const isMultifamily = useIsMultifamily({
		markets,
	});

	const csResult = useSearchPropertiesInfiniteQuery(params, !isMultifamily);

	const mufaResult = useSearchMufaPropertiesInfiniteQuery(
		params,
		isMultifamily
	);

	return isMultifamily ? mufaResult : csResult;
};
