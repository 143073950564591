import React from 'react';
import { ErrorIconStyled } from 'Pages/Search/Table/Components/TableView';

import styles from './MissingValue.less';

export default function MissingValueComponent({
	tooltip,
	displayName,
}: {
	tooltip?: boolean;
	displayName?: string;
}) {
	if (tooltip) {
		return (
			<span
				className={styles.missingValue}
				data-tooltip={`No ${displayName}`}
				data-tooltipdelay={100}
			>
				<ErrorIconStyled width={23} height={23} />
			</span>
		);
	} else {
		return (
			<span className={styles.missingValue}>
				<ErrorIconStyled width={23} height={23} /> No {displayName}
			</span>
		);
	}
}
