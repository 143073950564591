import React from 'react';
import { SaveChartModal } from './Modals/Save/SaveChartModal';
import { Chart } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import { Button } from '@compstak/ui-kit';

type Props = {
	chartDraft: Chart;
};

export const SaveButton = ({ chartDraft }: Props) => {
	const { openModal } = useModal();

	const onSave = () => {
		openModal({ modalContent: <SaveChartModal chartDraft={chartDraft} /> });
	};

	return (
		<Button variant="primary" onClick={onSave}>
			Save
		</Button>
	);
};
