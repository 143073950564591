import {
	CreateSessionPayload,
	CreateSessionResponse,
	createSession,
	deleteSession,
	getSession,
} from 'api/sessions/sessions';
import { push, routes } from 'router';
import Cookies from 'cookies-js';
import { getUrls } from 'Pages/Login/util';

const AUTH_SESSION_ID = 'CSUSESSIONID';
const AUTH_ACESS_TOKEN = 'userTokenCFS';
const AUTH_REMEMBER_ME = 'lastUsedUsername';

/** BE sets @see AUTH_SESSION_ID cookie here */
export const login = async (creds: CreateSessionPayload) => {
	const { data } = await createSession(creds);

	redirectToOtherAppIfNeeded({ session: data });

	data.accessToken && setAccessToken(data.accessToken);
	creds.rememberMe
		? setRememberMeUserName(creds.username)
		: removeRememberMeUserName();

	const { default: store } = await import('store');
	const { bootstrap } = await import('Pages/Login/actions');
	store.dispatch(bootstrap());

	return data;
};

export const logout = async () => {
	const { default: store } = await import('store');
	const { logout } = await import('Pages/Login/actions');

	const sessionId = getSessionId();
	if (sessionId) {
		Cookies.expire(AUTH_SESSION_ID, { domain: getAuthSessionIdDomain() });
		try {
			deleteSession(sessionId);
		} catch (e) {
			console.error('Session deletion error', e);
		}
	}
	store.dispatch(logout());
	removeAccessToken();
	push(routes.login.path);
};

export const refreshAccessToken = async () => {
	const sessionId = getSessionId();

	if (!sessionId) {
		return;
	}

	try {
		const {
			data: { accessToken },
		} = await getSession(sessionId);
		if (accessToken) {
			setAccessToken(accessToken);
			return accessToken;
		} else {
			logout();
		}
	} catch (e) {
		logout();
	}
};

const getSessionId = () => {
	return Cookies.get(AUTH_SESSION_ID);
};

export const getAccessToken = () => {
	if (!getSessionId()) {
		return '';
	}
	return localStorage.getItem(AUTH_ACESS_TOKEN) ?? '';
};

export const setAccessToken = (accessToken: string) => {
	if (!getSessionId()) {
		return '';
	}

	localStorage.setItem(AUTH_ACESS_TOKEN, accessToken);
};

const removeAccessToken = () => localStorage.removeItem(AUTH_ACESS_TOKEN);

export const getRememberMeUserName = () => {
	return localStorage.getItem(AUTH_REMEMBER_ME);
};

const setRememberMeUserName = (userName: string) => {
	localStorage.setItem(AUTH_REMEMBER_ME, userName);
};

const removeRememberMeUserName = () => {
	localStorage.removeItem(AUTH_REMEMBER_ME);
};

const getAuthSessionIdDomain = () => {
	const domain = window.location.hostname.split('.').slice(-2).join('.');
	return domain;
};

// allows to set accessToken in another app without extra login
const redirectToOtherAppIfNeeded = ({
	session,
}: {
	session: CreateSessionResponse;
}) => {
	const user = session.user;
	const accessToken = session.accessToken;

	if (user.userType === 'admin') {
		return;
	}

	const appConfig = window.appConfig;

	if (user.userType === 'exchange' && !appConfig.isExchange) {
		const exchangeUrl = getStringWithoutSlashAtTheEnd(getUrls().exchange);
		window.location.href = `${exchangeUrl}${routes.landingpagetoken.toHref(undefined, { accessToken })}`;
		return;
	}

	if (user.userType !== 'exchange' && appConfig.isExchange) {
		const enterpriseUrl = getStringWithoutSlashAtTheEnd(getUrls().enterprise);
		window.location.href = `${enterpriseUrl}${routes.landingpagetoken.toHref(undefined, { accessToken })}`;
		return;
	}
};

const getStringWithoutSlashAtTheEnd = (s: string) => {
	return s.endsWith('/') ? s.substring(0, s.length - 1) : s;
};
