import { TreppLoan } from 'api';

export const getLoanNOI = (loanData: TreppLoan) => {
	const { loanAppraisedValue, loanCapRate } = loanData;

	if (!(loanAppraisedValue && loanCapRate)) return undefined;
	const noiCalculation = loanAppraisedValue * (loanCapRate / 100);
	if (noiCalculation < 0 || Number.isNaN(noiCalculation)) return undefined;
	return noiCalculation;
};
