import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import { SortFieldMenu } from '../../Components';
import { ListSortButton } from '../../UI';
import { SortField } from './types';

type Props = {
	sortField: SortField;
	setSortField: React.Dispatch<React.SetStateAction<SortField>>;
};

export const SortFieldButton = ({ sortField, setSortField }: Props) => {
	const options = Object.keys(SORT_FIELD_MENU_LABELS).map((key) => ({
		value: key as SortField,
		label: SORT_FIELD_MENU_LABELS[key as SortField],
	}));

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
				<StyledListSortButton>
					Sort by {SORT_FIELD_LABELS[sortField]}
				</StyledListSortButton>
			</DropdownMenu.Trigger>
			<SortFieldMenu
				value={sortField}
				onValueChange={(value) => setSortField(value as SortField)}
				options={options}
			/>
		</DropdownMenu.Root>
	);
};

const SORT_FIELD_LABELS: Record<SortField, string> = {
	buildingAddressAndCity: 'Address',
	activeLeaseCount: 'Total Leases',
};

const SORT_FIELD_MENU_LABELS: Record<SortField, string> = {
	buildingAddressAndCity: 'Address',
	activeLeaseCount: 'Total Leases',
};

const StyledListSortButton = styled(ListSortButton)`
	color: ${(p) => p.theme.colors.neutral.n300};
`;
