import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { createGlobalStyle } from 'styled-components';
import { useColumnsContext } from '../Columns';
import { MIN_COLUMN_WIDTH } from '../constants';
import { useLayoutContext } from '../LayoutProvider';
import { useAutoScroll } from '../useAutoScroll';
import {
	ResizeColumnContextValue,
	ResizeColumnState,
	StartResizeFn,
} from './types';

type Props = {
	children: ReactNode;
};

export const ColumnResizeProvider = ({ children }: Props) => {
	const [resizeState, setResizeState] =
		useState<ResizeColumnState>(initialState);

	const isResizing = resizeState.columnIndex !== -1;

	const deltaScrollLeft =
		resizeState.currentScrollLeft - resizeState.startScrollLeft;

	const deltaX = resizeState.currentX - resizeState.startX + deltaScrollLeft;

	const { columns, onColumnResize } = useColumnsContext();
	const {
		body: scrollEl,
		rootRect,
		tableHeight,
		bodyHasHOverflow,
		scrollbarWidth,
		actionColumnWidth,
	} = useLayoutContext();

	const rootOffset = rootRect?.left ?? 0;
	const relativeCurrentX =
		resizeState.currentX - rootOffset - actionColumnWidth;

	const [startAutoScroll, stopAutoScroll] = useAutoScroll({
		leftX: relativeCurrentX,
		rightX: relativeCurrentX,
		scrollSpeed: 10,
	});

	const startResize: StartResizeFn = (e, columnIndex) => {
		if (!onColumnResize || isResizing || !scrollEl) return;

		startAutoScroll();

		const startX = e.clientX;
		const { scrollLeft } = scrollEl;

		setResizeState({
			columnIndex,
			startX,
			currentX: startX,
			startScrollLeft: scrollLeft,
			currentScrollLeft: scrollLeft,
		});
	};

	useEffect(() => {
		const onResize = (e: MouseEvent) => {
			if (!isResizing || !scrollEl) return;

			setResizeState({
				...resizeState,
				currentX: e.clientX,
				currentScrollLeft: scrollEl.scrollLeft,
			});
		};

		const endResize = () => {
			if (!isResizing) return;

			stopAutoScroll();

			const column = columns[resizeState.columnIndex];
			const newWidth = Math.max(MIN_COLUMN_WIDTH, column.width + deltaX);

			onColumnResize?.(resizeState.columnIndex, newWidth);
			setResizeState(initialState);
		};

		window.addEventListener('mousemove', onResize);
		window.addEventListener('mouseup', endResize);

		return () => {
			window.removeEventListener('mousemove', onResize);
			window.removeEventListener('mouseup', endResize);
		};
	}, [
		columns,
		deltaX,
		isResizing,
		onColumnResize,
		resizeState,
		scrollEl,
		stopAutoScroll,
	]);

	return (
		<ColumnResizeContext.Provider
			value={{
				currentX: resizeState.currentX,
				startResize,
				isResizing,
				activeColumnIndex: resizeState.columnIndex,
				overlayLeft: relativeCurrentX,
				overlayHeight: tableHeight - (bodyHasHOverflow ? scrollbarWidth : 0),
			}}
		>
			{children}
			<GlobalStyle isResizing={isResizing} />
		</ColumnResizeContext.Provider>
	);
};

const initialState: ResizeColumnState = {
	columnIndex: -1,
	startX: 0,
	currentX: 0,
	startScrollLeft: 0,
	currentScrollLeft: 0,
};

const ColumnResizeContext = createContext({} as ResizeColumnContextValue);

export const useColumnResizeContext = () => useContext(ColumnResizeContext);

const GlobalStyle = createGlobalStyle<{ isResizing: boolean }>((p) => ({
	body: {
		userSelect: p.isResizing ? 'none' : undefined,
		cursor: p.isResizing ? 'col-resize' : undefined,
	},
}));
