import {
	Combobox,
	ComboboxItem,
	ComboboxLabel,
	ComboboxPopover,
	ComboboxProvider,
} from '@ariakit/react';
import { Spinner, useNotifications } from '@compstak/ui-kit';
import { defaultDropdownContentCss, defaultDropdownItemCss } from 'Components';
import {
	useAddProperty,
	usePropertyByIdQuery,
	usePropertySuggestionsQuery,
	useSplitCompset,
} from 'api';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
	Modal,
} from 'Components/Modals/common/UI';
import { useMemo, useState } from 'react';
import { routes } from 'router';
import styled from 'styled-components';

type Props = {
	closeDialog: () => void;
};

export const AddCompsetPropertyDialog = ({ closeDialog }: Props) => {
	const params = routes.propertyById.useParams();

	const { data: property } = usePropertyByIdQuery({ id: params.propertyId });

	const { data: splitCompset } = useSplitCompset({
		propertyId: params.propertyId,
	});

	const [search, setSearch] = useState('');

	const [selectedPropertyId, setSelectedPropertyId] = useState<number | null>(
		null
	);

	const { data: suggestionsData, isFetching: isFetchingSuggestions } =
		usePropertySuggestionsQuery({
			marketId: property?.marketId,
			text: search,
		});

	const filteredSuggestions = useMemo(() => {
		if (!suggestionsData || !splitCompset) return [];

		return suggestionsData.suggestions.filter(
			(s) => !splitCompset.compset.some((p) => p.property.id === s.propertyId)
		);
	}, [suggestionsData, splitCompset]);

	const { mutateAsync: addProperty, isLoading: isLoadingAddProperty } =
		useAddProperty({ propertyId: params.propertyId });

	const { addNotification } = useNotifications();

	return (
		<Modal onClose={closeDialog}>
			<ModalTitle>Add Property To Set</ModalTitle>
			<ComboboxProvider>
				<Label>Type property’s address</Label>
				<InputContainer>
					<Input
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
							setSelectedPropertyId(null);
						}}
					/>
					{isFetchingSuggestions && (
						<InputSpinnerContainer>
							<Spinner size="m" />
						</InputSpinnerContainer>
					)}
				</InputContainer>
				<Dropdown sameWidth gutter={1}>
					{filteredSuggestions.map((s) => {
						return (
							<DropdownItem
								key={s.propertyId}
								value={String(s.propertyId)}
								onClick={() => {
									setSearch(s.text);
									setSelectedPropertyId(s.propertyId);
								}}
							>
								{s.text}
							</DropdownItem>
						);
					})}
				</Dropdown>
			</ComboboxProvider>
			<ModalButtons>
				<ModalButton
					variant="primary"
					disabled={selectedPropertyId == null || isLoadingAddProperty}
					isLoading={isLoadingAddProperty}
					onClick={async () => {
						await addProperty({
							id: params.propertyId,
							ids: [selectedPropertyId as number],
						});
						addNotification({
							icon: 'success',
							title: 'Property was added',
						});
						closeDialog();
					}}
				>
					Done
				</ModalButton>
			</ModalButtons>
		</Modal>
	);
};

const Label = styled(ComboboxLabel)`
	font-size: 0.875rem;
	line-height: 1.5;
	font-weight: 300;
	color: ${(p) => p.theme.colors.blue.blue400};
	text-align: left;
	width: 100%;
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid ${(p) => p.theme.colors.neutral.n40};
`;

const Input = styled(Combobox)`
	&&& {
		border: 0;
		height: 40px;
		font-size: 0.875rem;
		font-weight: 300;
		color: ${(p) => p.theme.colors.gray.gray700};
		border-radius: 0.25rem;
		margin-bottom: 0;
		width: 100%;
		padding-left: 0.5rem;
	}
`;

const InputSpinnerContainer = styled.div`
	padding-right: 0.5rem;
	display: flex;
	align-items: center;
`;

const Dropdown = styled(ComboboxPopover)`
	${defaultDropdownContentCss}
`;

const DropdownItem = styled(ComboboxItem)`
	${defaultDropdownItemCss};
`;
