import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyle = StyleSheet.create({
	pageTitle: {
		fontSize: 12,
		borderBottom: '1px solid #000',
		paddingBottom: 4,
		marginBottom: 6,
	},
	addressTitleContainer: {
		marginBottom: 20,
	},
	addressTitleSubmarket: {
		fontSize: 8,
		textTransform: 'uppercase',
		marginBottom: 4,
	},
	addressTitleAddress: {
		fontSize: 15,
		fontWeight: 'bold',
	},
	addressTitleCity: {
		fontSize: 10,
		marginTop: 8,
	},
	row: {
		flexDirection: 'row',
	},
	dFlex: {
		display: 'flex',
	},
	flexRow: {
		flexDirection: 'row',
	},
	flexCol: {
		flexDirection: 'column',
	},
	realPageLogoContainer: {
		gap: 4,
		justifyContent: 'flex-end',
		flexDirection: 'row',
	},
	multifamilyRpContainer: {
		marginVertical: 8,
	},
	poweredBy: {
		fontSize: 7.5,
		alignItems: 'center',
		justifyContent: 'center',
	},
	multifamilyTitle: {
		marginBottom: 16,
		fontSize: 12,
	},
	realPageTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	charts: {
		width: '90%',
		height: 'auto',
	},
});
