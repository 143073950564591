import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
// @ts-expect-error TS7016: Could not find a declaration f...
import fixAFloat from 'fix-a-float';
import NumberFormat from 'react-number-format';
import { getDateFormat } from 'util/comp-format/src/format';
import { FiltersObject } from 'models/filters/types';
import { FilterErrors } from './Filter/Container';
import {
	FilterDateInterval,
	FilterNumberInterval,
	FilterStringInterval,
	IntervalFiltersKeys,
} from 'models/filters/types';
import { validateIntervalFilter } from 'models/filters/util/validateFilter';
import {
	isDateIntervalFilterKey,
	isNumberIntervalFilterKey,
} from 'models/filters/util/isIntervalFilterKey';
import { useDebouncedCallback } from 'use-debounce';
import { FILTERS_DEBOUNCE_TIMEOUT } from 'util/formConstants';

type BetweenProps = {
	/** @default true */
	thousandSeparator?: boolean;
	/** @default false */
	monthly?: boolean;
	/** @default false */
	strictPercentFromFloat?: boolean;
	attribute: IntervalFiltersKeys;
	touch: () => void;
	filter:
		| FilterNumberInterval
		| FilterDateInterval
		| FilterStringInterval
		| null
		| undefined;
	onFilterChange: (filters: Partial<FiltersObject>) => void;
	setError: (errors: FilterErrors) => void;
};

const Between = ({
	thousandSeparator = true,
	monthly = false,
	strictPercentFromFloat,
	attribute,
	touch,
	filter,
	onFilterChange,
	setError,
}: BetweenProps) => {
	const [min, setMin] = useState<string | number>('');
	const [max, setMax] = useState<string | number>('');

	useEffect(() => {
		setMin(formatIfNeeded(filter?.min ?? null));
		setMax(formatIfNeeded(filter?.max ?? null));
	}, [filter?.min, filter?.max]);

	// Done in AP-17012
	useEffect(() => {
		if (min) {
			_handleChange('min', min.toString());
		}
		if (max) {
			_handleChange('max', max.toString());
		}
	}, [monthly]);

	const formatIfNeeded = (value: string | number | Date | null) => {
		if (value == null) return '';

		if (typeof value === 'number' && isNumberIntervalFilterKey(attribute)) {
			let numberValue: number = value;
			if (monthly) {
				numberValue = fixAFloat(numberValue / 12);
			}
			if (strictPercentFromFloat) {
				numberValue = fixAFloat(numberValue * 100);
			}
			return numberValue;
		}

		if (isDateIntervalFilterKey(attribute)) {
			return dayjs(value).format(getDateFormat());
		}

		return String(value);
	};

	const changeFilter = (
		targetName: 'min' | 'max',
		value: FilterNumberInterval | FilterDateInterval | FilterStringInterval
	) => {
		try {
			validateIntervalFilter(attribute, value);
			const updatedFilters = {
				[attribute]: {
					...filter,
					...value,
				},
			};

			onFilterChange(updatedFilters);
			setError({ min: null, max: null });
		} catch (e) {
			if (e instanceof Error) {
				setError({ [targetName]: e.message });
			}
		}
	};

	const _handleChange = (targetName: 'min' | 'max', targetValue: string) => {
		touch();

		const value = targetValue.trim();
		const stringifiedValues = {
			min: targetName === 'min' ? value : min.toString().trim(),
			max: targetName === 'max' ? value : max.toString().trim(),
		};

		const formattedValues = {
			min: stringifiedValues.min || null,
			max: stringifiedValues.max || null,
		} as FilterNumberInterval | FilterDateInterval | FilterStringInterval;

		const keys = Object.typedKeys({ min, max });

		if (isNumberIntervalFilterKey(attribute)) {
			keys.forEach((key) => {
				if (!stringifiedValues[key]) return;
				const numberValue = parseFloat(
					stringifiedValues[key].toString().replace(/,/g, '')
				);
				if (monthly) {
					formattedValues[key] = fixAFloat(numberValue * 12);
					return;
				}
				if (strictPercentFromFloat) {
					formattedValues[key] = fixAFloat(numberValue / 100);
					return;
				}
				formattedValues[key] = numberValue;
			});
		} else if (isDateIntervalFilterKey(attribute)) {
			const error: FilterErrors = { min: null, max: null };
			keys.forEach((key) => {
				const asDayjs = dayjs(stringifiedValues[key], getDateFormat());
				const isInvalid =
					!/\d{1,2}\/\d{1,2}\/\d\d\d\d/.test(stringifiedValues[key]) ||
					!asDayjs.isValid();
				if (isInvalid) {
					if (stringifiedValues[key] !== '') {
						error[key] =
							`${key} value must be a date in ${getDateFormat()} form`;
					}
					formattedValues[key] = null;
				} else {
					formattedValues[key] = asDayjs.toDate();
				}
			});
			if (error.min || error.max) {
				setError(error);
				return;
			}
		}

		changeFilter(targetName, formattedValues);
	};

	const [handleChange] = useDebouncedCallback(
		_handleChange,
		FILTERS_DEBOUNCE_TIMEOUT
	);

	const onChange = (targetName: 'min' | 'max', targetValue: string) => {
		if (targetName === 'min') {
			setMin(targetValue);
		} else {
			setMax(targetValue);
		}
		handleChange(targetName, targetValue);
	};

	return (
		<div>
			{isNumberIntervalFilterKey(attribute) ? (
				<>
					<NumberFormat
						thousandSeparator={thousandSeparator}
						className="between_search_filter_min"
						type="text"
						name="min"
						placeholder="From..."
						onValueChange={({ value }) => onChange('min', value)}
						value={min}
						data-qa-id="between-main-filter-number-format-from"
					/>
					<div className="between_to">-</div>
					<NumberFormat
						thousandSeparator={thousandSeparator}
						className="between_search_filter_max"
						type="text"
						name="max"
						placeholder="To..."
						onValueChange={({ value }) => onChange('max', value)}
						value={max}
						data-qa-id="between-main-filter-number-format-to"
					/>
				</>
			) : (
				<>
					<input
						className="between_search_filter_min"
						type="text"
						name="min"
						placeholder="From..."
						onChange={(e) => onChange('min', e.target.value)}
						value={min}
						data-qa-id="between-main-filter-input-from"
					/>
					<div className="between_to">-</div>
					<input
						className="between_search_filter_max"
						type="text"
						name="max"
						placeholder="To..."
						onChange={(e) => onChange('max', e.target.value)}
						value={max}
						data-qa-id="between-main-filter-input-to"
					/>
				</>
			)}
		</div>
	);
};

export default Between;
