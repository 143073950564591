import { UseQueryOptions } from '@tanstack/react-query';
import { PropertyComp } from '../../../types';
import { QUERY_KEYS } from '../../constants';
import { PortfolioPropertiesParams } from '../../types';
import { createFetchFn, useQueryHook } from '../../utils';
import {
	PORTFOLIO_LIMIT,
	PORTFOLIO_PROPERTIES_INCLUDE_FIELDS,
} from '../constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';

export const fetchPortfolioProperties = createFetchFn<
	PortfolioPropertiesParams,
	PortfolioPropertiesResponse
>({
	method: 'get',
	getURL: ({ portfolioId, ...queryArgs }) => {
		return `/pa/v2/api/portfolios/${portfolioId}/properties${getQueryStringFromPortfolioFilters(
			queryArgs
		)}`;
	},
});

export const usePortfolioPropertiesQuery = (
	_params: PortfolioPropertiesParams,
	options?: UseQueryOptions<PortfolioPropertiesResponse>
) => {
	const params = {
		limit: PORTFOLIO_LIMIT,
		includeFields: PORTFOLIO_PROPERTIES_INCLUDE_FIELDS,
		..._params,
	};
	return useQueryHook({
		queryKey: [QUERY_KEYS.PORTFOLIO_PROPERTIES, params],
		queryFn: fetchPortfolioProperties(params),
		...options,
	});
};

export type PortfolioPropertiesResponse = PropertyComp[];
