import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type FilterInformationFieldProps = PropsWithChildren;

export const FilterInformationField = ({
	children,
}: FilterInformationFieldProps) => {
	const { searchTerm } = useFilterFieldContext();

	return (
		<Container isHidden={!!searchTerm}>
			<Content>{children}</Content>
		</Container>
	);
};

const Container = styled.li<{ isHidden?: boolean }>`
	${({ isHidden }) => isHidden && 'display: none;'};
	padding: 5px 20px;
`;

const Content = styled.div`
	padding: 8px;
	font-size: 10px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	background-color: ${({ theme }) => theme.colors.neutral.n600};
`;
