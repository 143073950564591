import { useIsMultifamily } from 'hooks';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useIsShowTreppUI } from 'utils/useIsShowTreppUI';
import {
	BASE_PROPERTY_SECTIONS,
	LEASE_SECTIONS,
	LOAN_SECTION,
	MUFA_SECTION,
	SALE_SECTIONS,
} from '../columnSectionsConfig';
import { useOptionsContext } from '../OptionsProvider';
import { filterColumnPreferences } from '../filterColumnPreferences';
import { TableSection } from '../types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const useColumnSections = (): TableSection[] => {
	const { columnPreferencesType, filterColumnSections } = useOptionsContext();

	const [filters] = useFilters();
	const markets = getFiltersMarkets(filters);

	const showTrepp = useIsShowTreppUI({ markets });
	const isMultifamily = useIsMultifamily({ markets });
	const featureFlags = useFeatureFlags();

	return useMemo(() => {
		let tableSections: TableSection[] = [];
		if (columnPreferencesType === 'lease') {
			tableSections = LEASE_SECTIONS;
		} else if (columnPreferencesType === 'sale') {
			tableSections = SALE_SECTIONS;
		} else if (columnPreferencesType === 'property') {
			const sections = [...BASE_PROPERTY_SECTIONS];

			if (showTrepp) {
				sections.push(LOAN_SECTION);
			}

			if (isMultifamily) {
				sections.push(MUFA_SECTION);
			}

			tableSections = filterColumnSections?.(sections) ?? sections;
		}

		return tableSections.map((tableSection) => ({
			...tableSection,
			ids: filterColumnPreferences(tableSection.ids, featureFlags),
		}));
	}, [
		columnPreferencesType,
		isMultifamily,
		showTrepp,
		filterColumnSections,
		featureFlags,
	]);
};
