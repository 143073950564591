import { Image } from '@react-pdf/renderer';
import { PdfSectionBox, PdfSectionDescriptionText } from '../PdfUI';
import { PdfSectionProps } from '../constants';

export const StartingRentPdfSection = ({
	startingRent: { url, description },
}: PdfSectionProps) => {
	return (
		<PdfSectionBox title="Staring Rent">
			{url && <Image src={url} />}
			<PdfSectionDescriptionText>{description}</PdfSectionDescriptionText>
		</PdfSectionBox>
	);
};
