import { SimpleTableColumn } from '@compstak/ui-kit';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import {
	PropertyLandlord,
	useTopPropertiesLandlordsQuery,
	TopPropertiesLandlordsResponse,
} from '../../../api/topLandlords/useTopPropertiesLandlords';
import { CommonFilters, PremierListTableProps } from '../PremierList';
import { getPremierListTableColumns } from './columns';
import { useViewCompLink } from './util';
import { PROPERTY_TYPE_NAME_TO_ID } from '../../../api';
import { PremierListTableView } from './PremierListTableView';

const DEFAULT_ROWS: TopPropertiesLandlordsResponse['landlords'] = [];
const BASE_LINK_URL = '/search/properties/list';
const FILTER_NAME: keyof FiltersObject = 'landlordNames';

export const LargestLandlordTable = ({
	commonFilters,
	selectedSpaceTypeName,
}: Omit<PremierListTableProps, 'commonFilters'> & {
	commonFilters: Omit<CommonFilters, 'hidden' | 'partial'>;
}) => {
	const filters: Partial<FiltersObject> = useMemo(
		() => ({
			buildingPropertyTypeId: [PROPERTY_TYPE_NAME_TO_ID[selectedSpaceTypeName]],
		}),
		[selectedSpaceTypeName]
	);

	const localFilters = useMemo(
		() => ({ ...filters, ...commonFilters }),
		[commonFilters, filters]
	);

	const { data, isFetching } = useTopPropertiesLandlordsQuery({
		filters: localFilters,
	});
	const rows = data?.landlords ?? DEFAULT_ROWS;

	const columns: SimpleTableColumn<PropertyLandlord>[] = useMemo(
		() =>
			getPremierListTableColumns<PropertyLandlord>({
				filterName: FILTER_NAME,
				baseLinkUrl: BASE_LINK_URL,
				filters: localFilters,
				nameColumn: {
					header: 'Landlord Name',
				},
				volumeColumn: {
					header: 'Owned Space (SQFT K)',
					key: 'sumOfPropertySqFt',
					numberProcess: (value) => value / 1e3,
				},
				countColumn: {
					key: 'totalPropertiesCount',
					header: 'Properties Owned',
				},
			}),
		[localFilters]
	);

	const viewPropertyCompsHref = useViewCompLink({
		filterName: FILTER_NAME,
		baseLinkUrl: BASE_LINK_URL,
		filters: localFilters,
		rows,
	});

	return (
		<PremierListTableView
			data={rows}
			buttonHref={viewPropertyCompsHref}
			buttonText="View Property Comps"
			title="Largest Landlords"
			description={`Landlords that own the most ${selectedSpaceTypeName.toLowerCase()} space in ${commonFilters.markets[0].displayName} based on active unexpired leases within CompStak's available data.`}
			columns={columns}
			id="LargestLandlordTable"
			isFetching={isFetching}
		/>
	);
};
