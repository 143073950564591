import { EditableOverviewFields } from './useEditableOverview';
import {
	KeysToDisplay,
	MufaKeysToDisplay,
	getLabel,
	shouldChangeAlignment,
	getPropertyAttributeSections,
} from 'Pages/PropertyPageV3/MainInfo';
import { HideableLabel } from '../HideableLabel';
import { OverviewKeyValueTableInput, OverviewKeyValueTableLabel } from './UI';
import styled from 'styled-components';
import {
	AttributesTableField,
	AttributesTableHeader,
	AttributesTableSection,
	AttributesTableSectionsContainer,
} from 'Pages/PropertyPageV3/LoansTab/UI';

type EditableKeyInfoItemsProps = {
	editableOverviewFields: EditableOverviewFields;
	setEditableOverviewFields: React.Dispatch<
		React.SetStateAction<EditableOverviewFields>
	>;
	setIsVisible: (key: MufaKeysToDisplay | KeysToDisplay) => () => void;
	getIsVisible: (key: MufaKeysToDisplay | KeysToDisplay) => boolean;
	propertyAttributes: (MufaKeysToDisplay | KeysToDisplay)[];
	isMufa: boolean;
};

export const EditablePropertyAttributes = ({
	editableOverviewFields,
	setEditableOverviewFields,
	setIsVisible,
	getIsVisible,
	propertyAttributes,
	isMufa,
}: EditableKeyInfoItemsProps) => {
	const sections = getPropertyAttributeSections(propertyAttributes);
	return (
		<div style={{ width: '100%' }}>
			<AttributesTableHeader>Property Attributes</AttributesTableHeader>
			<AttributesTableSectionsContainer>
				{sections.map((section, index) => {
					return (
						<AttributesTableSection key={'section' + index}>
							{section.map((attribute) => {
								const isVisible = getIsVisible(attribute);
								const verticalAlignemt = shouldChangeAlignment(
									attribute,
									editableOverviewFields.propertyAttributes[attribute]
								);

								return (
									<AttributesTableField
										key={attribute}
										vertical={verticalAlignemt}
									>
										<HideableLabel
											isVisible={isVisible}
											onToggle={setIsVisible(attribute)}
										>
											<OverviewKeyValueTableLabel>
												{getLabel(isMufa, attribute)}
											</OverviewKeyValueTableLabel>
										</HideableLabel>
										<StyledOverviewKeyValueTableInput
											isVisible={isVisible}
											value={
												editableOverviewFields.propertyAttributes[attribute]
											}
											vertical={verticalAlignemt}
											onChange={(e) => {
												setEditableOverviewFields((s) => ({
													...s,
													propertyAttributes: {
														...s.propertyAttributes,
														[attribute]: e.target.value,
													},
												}));
											}}
										/>
									</AttributesTableField>
								);
							})}
						</AttributesTableSection>
					);
				})}
			</AttributesTableSectionsContainer>
		</div>
	);
};

const StyledOverviewKeyValueTableInput = styled(OverviewKeyValueTableInput)<{
	vertical?: boolean;
}>`
	text-align: ${({ vertical }) => (vertical ? 'left' : 'right')};
`;
