import { MapAnalyticsType } from 'Layouts/SearchProvider';
import { useMarketRentAnalyticsState, useTenantIndustryState } from 'maps';
import { useLeaseExpirationState } from '../LeaseExpirationAnalytics';
import { useSpaceTypeAnalyticsState } from '../SpaceTypeAnalytics';

export const useExportFilename = (mapAnalyticsType: MapAnalyticsType) => {
	const [marketRentState] = useMarketRentAnalyticsState();
	const [tenantIndustryState] = useTenantIndustryState();
	const [leaseExpirationState] = useLeaseExpirationState();
	const [spaceTypeState] = useSpaceTypeAnalyticsState();

	const TITLE_MAP: Record<MapAnalyticsType, string> = {
		marketRent: marketRentState.title,
		tenantIndusty: tenantIndustryState.title,
		leaseExpiration: leaseExpirationState.title,
		spaceType: spaceTypeState.title,
	};

	return TITLE_MAP[mapAnalyticsType];
};
