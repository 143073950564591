import React from 'react';
import styled from 'styled-components';
import CompstakLogo from '../../ui/svg_icons/cs_logo.svg';

type DownloadBrandingProps = {
	bannerId: string;
};

export const DownloadBranding = React.memo(
	({ bannerId = '' }: DownloadBrandingProps) => (
		<div data-compstak-branding-banner={bannerId}>
			<BrandingContainer>
				<div>
					<span>Data provided by</span>
					<CompstakLogo height="28px" width="28px" />
					<BrandingTitle>compstak</BrandingTitle>
				</div>
				<div>
					All information is not guaranteed. Some of the data on this report was
					provided by preparer and not verified by CompStak.
				</div>
			</BrandingContainer>
		</div>
	)
);

DownloadBranding.displayName = 'DownloadBranding';

const BrandingContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 50px;
	padding: 14px 0;
	font-size: 14px;
	border-top: 2px solid black;

	> div {
		display: flex;
		gap: 6px;
		align-items: center;
	}
`;

const BrandingTitle = styled.div`
	text-transform: uppercase;
	font-size: 18px;
`;
