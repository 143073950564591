import dayjs from 'dayjs';
import { LeaseExpirationState } from './LeaseExpirationProvider';

export const incrementExpireInMonths = (
	expireInMonths: LeaseExpirationState['expireInMonths']
) => {
	return expireInMonths === 60 ? -60 : expireInMonths + 6;
};

export const getLeaseExpirationPeriodLabel = (expireInMonths: number) => {
	return `${dayjs()
		.add(expireInMonths, 'months')
		.format('MMM YYYY')} - ${dayjs()
		.add(expireInMonths + 6, 'months')
		.format('MMM YYYY')}`;
};

export const getLeaseExpirationPeriod = (expireInMonths: number) => {
	const min = dayjs().add(expireInMonths, 'month');
	const max = min.add(6, 'month');
	return {
		min: min.toDate(),
		max: max.toDate(),
	};
};
