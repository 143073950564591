import {
	useIsMufaProperty,
	useMufaPropertyByIdQuery,
	usePropertyByIdQuery,
} from 'api';
import { MufaPropertyComp } from 'types';

type Props = {
	propertyId: number;
};

type UsePropertyById = {
	data?: MufaPropertyComp;
	isFetching: boolean;
	isLoading: boolean;
};

/** Returns the basic (commercial) property by id
 * and merges the mufa property data if mufa conditions are satisfied.
 */
export const usePropertyById = ({ propertyId }: Props): UsePropertyById => {
	const {
		data: property,
		isFetching: isFetchingProperty,
		isLoading: isLoadingProperty,
	} = usePropertyByIdQuery({ id: propertyId });

	const { data: isMufa } = useIsMufaProperty({ propertyId });

	const {
		data: mufaProperty,
		isFetching: isFetchingMufaProperty,
		isLoading: isLoadingMufaProperty,
	} = useMufaPropertyByIdQuery(
		{ propertyId },
		{
			enabled: isMufa,
		}
	);

	return {
		data:
			property && (isMufa ? mufaProperty : true)
				? {
						...property,
						...mufaProperty,
					}
				: undefined,
		isFetching: isFetchingProperty || isFetchingMufaProperty,
		isLoading: isLoadingProperty || (isMufa && isLoadingMufaProperty),
	};
};
