import { AngleArrow } from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { defaultDropdownContentCss, defaultDropdownItemCss } from 'Components';
import { showSaveSearchMenu } from 'Pages/SavedSearches/actions';
import { useCompsetAddresses, usePropertyMarket } from 'api';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import { routes, useNavigate } from 'router';
import styled from 'styled-components';
import { useCompsetSendToAnalyticsUrl } from './useCompsetSendToAnalyticsUrl';

export const CompsetActionsButton = () => {
	const dispatch = useDispatch();
	const [filters] = useFilters();
	const params = routes.propertyById.useParams();

	const addresses = useCompsetAddresses({ propertyId: params.propertyId });
	const { data: market } = usePropertyMarket({ propertyId: params.propertyId });

	const { data: sendToAnalyticsUrl } = useCompsetSendToAnalyticsUrl({
		propertyId: params.propertyId,
	});

	const navigate = useNavigate();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<DropdownMenu.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
			<Trigger>
				More Actions
				<AngleArrow rotate={isMenuOpen ? 180 : 0} />
			</Trigger>
			<DropdownMenu.Portal>
				<Content>
					<Item
						onClick={() => {
							if (!sendToAnalyticsUrl) return;
							navigate(sendToAnalyticsUrl, {
								state: { fromPropertyPage: true },
							});
						}}
					>
						Send to Chartbuilder
					</Item>
					<Item
						onClick={(e) => {
							dispatch(
								showSaveSearchMenu(
									mergeFilters(filters, {
										market,
										address: addresses,
									}),
									e.target,
									false,
									'lease'
								)
							);
						}}
					>
						Track this Set
					</Item>
				</Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

const Trigger = styled(DropdownMenu.Trigger)`
	height: 32px;
	padding: 0 0.5rem;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.neutral.n80};
	border: 1px solid ${(p) => p.theme.colors.neutral.n80};
	border-radius: 0.25rem;
	background-color: transparent;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
`;

const Content = styled(DropdownMenu.Content)`
	${defaultDropdownContentCss};
	box-shadow:
		0 10px 15px -3px rgb(0 0 0 / 0.1),
		0 4px 6px -4px rgb(0 0 0 / 0.1);
`;

const Item = styled(DropdownMenu.Item)`
	${defaultDropdownItemCss};
`;
