import { API } from '@compstak/common';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useShareSavedSearchMutation = (
	options?: UseMutationOptions<void, unknown, ShareSavedSearchPayload>
) => {
	return useMutation({
		mutationFn: async (params: ShareSavedSearchPayload) => {
			const response = await API.post<void, ShareSavedSearchPayload>(
				'/api/savedSearches/sendSearch',
				params
			);
			return response.data;
		},
		...options,
	});
};

type ShareSavedSearchPayload = {
	searchId: string;
	recipientEmails: string[];
};
