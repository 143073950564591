import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ExpandedFloors from '../ExpandedFloors';
import Directory from '../Directory';
import Legend from '../Legend';
import SideButtons from './SideButtons';
import UnplacedSpaces from './UnplacedSpaces';
import Drawer from './Drawer';
import BuildingStats from './BuildingStats';
import FloorInput from '../UnknownFloors/FloorInput';
import SFInput from '../UnknownFloors/SFInput';
import Switcher from './Switcher';

import { insertFloorIfNeeded } from '../util';

import styles from '../stacking.less';
import spinner from 'ui/styles/spinner.less';

import * as stackingPlanActions from '../actions';
import * as menuActions from 'Singletons/Menu/actions';
import * as modalActions from 'Singletons/Modal/actions';
import * as feedbackActions from 'Singletons/Feedback/actions';

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function Content(props) {
	const DisplayMode =
		props.routeParams.displayMode === 'directory' ? Directory : ExpandedFloors;
	return (
		<div className={styles.content}>
			<BuildingStats
				// @ts-expect-error ts-migrate(2322) FIXME: Property 'stackingPlan' does not exist on type 'In... Remove this comment to see the full error message
				stackingPlan={props.stackingPlan}
				property={props.property}
				appConfig={props.appConfig}
				routeParams={props.routeParams}
			/>
			<Switcher {...props} />
			<DisplayMode {...props} />
			<Legend />
		</div>
	);
}

type LayoutState = any;

export class Layout extends React.PureComponent<{}, LayoutState> {
	constructor(props: {}) {
		super(props);
		this.state = {
			stackingPlan: null,
			spaceBeingDragged: null,
			spaceWasRepositioned: false,
			floorNumber: false,
			floorPlate: false,
		};
	}

	componentWillMount() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'params' does not exist on type 'Readonly... Remove this comment to see the full error message
		if (this.props.params) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.loadStackingPlan(
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'params' does not exist on type 'Readonly... Remove this comment to see the full error message
				this.props.params.propertyId
			);
		}
	}

	componentWillReceiveProps(newProps: {}) {
		switch (true) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{}... Remove this comment to see the full error message
			case newProps.stackingPlan.isLoading:
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{}... Remove this comment to see the full error message
			case newProps.stackingPlan.isError:
				return;
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{}... Remove this comment to see the full error message
		if (newProps.stackingPlan !== this.props.stackingPlan) {
			let buildingSize = false;
			if (
				parseInt(
					// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
					newProps.stackingPlan.buildingSize /
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{}... Remove this comment to see the full error message
						newProps.stackingPlan.propertyFloors
				)
			) {
				// @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'boolean'.
				buildingSize = parseInt(
					// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
					newProps.stackingPlan.buildingSize /
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{}... Remove this comment to see the full error message
						newProps.stackingPlan.propertyFloors
				);
			}

			this.setState({
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{}... Remove this comment to see the full error message
				stackingPlan: newProps.stackingPlan,
			});

			if (buildingSize !== false) {
				this.setState({
					floorPlate: buildingSize,
				});
			}
		}
	}

	// @ts-expect-error TS7006: Parameter 'spaceBeingDragged' ...
	startDraggingSpace = (spaceBeingDragged) => {
		this.setState({
			spaceBeingDragged,
			stackingPlan: insertFloorIfNeeded(
				this.state.stackingPlan,
				spaceBeingDragged
			),
		});
	};

	// @ts-expect-error TS7006: Parameter 'success' implicitly...
	endDraggingSpace = (success) => {
		const newState = {
			spaceBeingDragged: null,
			spaceWasRepositioned: false,
		};

		if (success) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.saveUpdatedPlan(
				this.state.stackingPlan,
				false,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				this.props.stackingPlan
			);
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type '{ ... Remove this comment to see the full error message
			newState.stackingPlan = this.props.stackingPlan;
		}

		this.setState(newState);
	};

	// @ts-expect-error TS7006: Parameter 'dragItem' implicitl...
	repositionDraggedSpace = (dragItem, hoverItem) => {
		const newSpaces = this.state.stackingPlan.spaces.slice();
		// @ts-expect-error TS7006: Parameter 's' implicitly has a...
		const dragIndex = newSpaces.findIndex((s) => s.id === dragItem.id);
		const hoverIndex = newSpaces.indexOf(hoverItem);

		if (dragIndex === -1 || hoverIndex === -1) {
			return;
		}

		if (dragItem.placed !== hoverItem.placed) {
			dragItem = {
				...dragItem,
				placed: hoverItem.placed,
			};
		}

		if (hoverItem.placed && dragItem.floor !== hoverItem.floor) {
			dragItem = {
				...dragItem,
				floor: hoverItem.floor,
			};
		}
		newSpaces[dragIndex] = hoverItem;
		newSpaces[hoverIndex] = dragItem;

		const stackingPlan = {
			...this.state.stackingPlan,
			spaces: newSpaces,
		};

		this.setState({
			stackingPlan,
			spaceWasRepositioned: true,
		});
	};

	// @ts-expect-error TS7006: Parameter 'dragItem' implicitl...
	changeDraggedSpaceFloor = (dragItem, floor) => {
		const updatedItem = {
			...dragItem,
			placed: true,
			floor,
		};

		const newSpaces = this.state.stackingPlan.spaces.filter(
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			(s) => s.id !== dragItem.id
		);
		newSpaces.push(updatedItem);
		const stackingPlan = {
			...this.state.stackingPlan,
			spaces: newSpaces,
		};

		this.setState({
			stackingPlan,
			spaceWasRepositioned: true,
		});
	};

	// @ts-expect-error TS7006: Parameter 'dragItem' implicitl...
	unplaceDraggingSpace = (dragItem) => {
		const updatedItem = {
			...dragItem,
			placed: false,
		};
		const newSpaces = this.state.stackingPlan.spaces.filter(
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			(s) => s.id !== dragItem.id
		);
		newSpaces.push(updatedItem);

		const stackingPlan = {
			...this.state.stackingPlan,
			spaces: newSpaces,
		};

		this.setState({
			stackingPlan,
			spaceWasRepositioned: true,
		});
	};

	// @ts-expect-error TS7006: Parameter 'floors' implicitly ...
	updateFloorCount = (floors) => {
		this.setState({
			floorNumber: parseInt(floors),
		});
	};

	// @ts-expect-error TS7006: Parameter 'floorPlate' implici...
	updateFloorPlate = (floorPlate) => {
		this.setState({
			floorPlate: parseInt(floorPlate),
		});

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.updateNumberOfFloors(
			this.state.stackingPlan,
			this.state.floorNumber,
			parseInt(floorPlate)
		);
	};

	render() {
		if (!this.state.stackingPlan || this.state.stackingPlan.isLoading) {
			return (
				<div className={styles.layout}>
					<div className={spinner.large} />
				</div>
			);
		}

		if (
			!this.state.stackingPlan.floors.length &&
			!this.state.floorNumber &&
			!this.state.stackingPlan.propertyFloors
		) {
			return (
				<div className={styles.layout}>
					{/* @ts-expect-error ts-migrate(2322) FIXME: Property 'updateFloorCount' does not exist on type... Remove this comment to see the full error message */}
					<FloorInput updateFloorCount={this.updateFloorCount} />
				</div>
			);
		}

		if (!this.state.stackingPlan.floors.length && !this.state.floorPlate) {
			return (
				<div className={styles.layout}>
					{/* @ts-expect-error ts-migrate(2322) FIXME: Property 'updateFloorPlate' does not exist on type... Remove this comment to see the full error message */}
					<SFInput updateFloorPlate={this.updateFloorPlate} />
				</div>
			);
		}

		return (
			<div className={styles.layout}>
				<SideButtons
					stackingPlan={this.state.stackingPlan}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
					stackingPlanActions={this.props.stackingPlanActions}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'property' does not exist on type 'Readon... Remove this comment to see the full error message
					property={this.props.property}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'feedbackActions' does not exist on type ... Remove this comment to see the full error message
					feedbackActions={this.props.feedbackActions}
				/>
				<UnplacedSpaces
					// @ts-expect-error ts-migrate(2322) FIXME: Property 'stackingPlan' does not exist on type 'In... Remove this comment to see the full error message
					stackingPlan={this.state.stackingPlan}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
					stackingPlanActions={this.props.stackingPlanActions}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
					menuActions={this.props.menuActions}
					startDraggingSpace={this.startDraggingSpace}
					endDraggingSpace={this.endDraggingSpace}
					repositionDraggedSpace={this.repositionDraggedSpace}
					spaceBeingDragged={this.state.spaceBeingDragged}
					unplaceDraggingSpace={this.unplaceDraggingSpace}
				/>
				<Content
					{...this.props}
					stackingPlan={this.state.stackingPlan}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
					originalStackingPlan={this.props.stackingPlan}
					startDraggingSpace={this.startDraggingSpace}
					endDraggingSpace={this.endDraggingSpace}
					repositionDraggedSpace={this.repositionDraggedSpace}
					spaceBeingDragged={this.state.spaceBeingDragged}
					changeDraggedSpaceFloor={this.changeDraggedSpaceFloor}
					unplaceDraggingSpace={this.unplaceDraggingSpace}
				/>
				{/* @ts-expect-error ts-migrate(2769) FIXME: Property 'stackingPlan' does not exist on type 'In... Remove this comment to see the full error message */}
				<Drawer {...this.props} stackingPlan={this.state.stackingPlan} />
			</div>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'store' implicitly h...
function mapStoreToProps(store) {
	return {
		stackingPlan: store.stackingPlan.data,
		spaceBeingEdited: store.stackingPlan.spaceBeingEdited,
		floorBeingEdited: store.stackingPlan.floorBeingEdited,
		property: store.stackingPlan.property,
		appConfig: store.appConfig,
	};
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return {
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"UPDATE_STACKING_PLAN"' is not assignable to... Remove this comment to see the full error message
		stackingPlanActions: bindActionCreators(stackingPlanActions, dispatch),
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"MENU_SHOW"' is not assignable to type 'Acti... Remove this comment to see the full error message
		menuActions: bindActionCreators(menuActions, dispatch),
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"MODAL_SHOW"' is not assignable to type 'Act... Remove this comment to see the full error message
		modalActions: bindActionCreators(modalActions, dispatch),
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"ADD_ALERT"' is not assignable to type 'Acti... Remove this comment to see the full error message
		feedbackActions: bindActionCreators(feedbackActions, dispatch),
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(Layout);
