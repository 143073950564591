import { Link } from 'router';
import { routes } from 'router';
import styled from 'styled-components';
import IconPortfolioDashboard from '../styles/svg_icons/icon-portfolio-dashboard.svg';

type Props = {
	portfolioId: number;
	portfolioName: string;
	propertyCount: number;
	leaseCount: number;
};

export const PortfolioStats = ({
	portfolioId,
	portfolioName,
	propertyCount,
	leaseCount,
}: Props) => {
	return (
		<StatsContainer>
			<IconPortfolioDashboard />
			<Stat>
				<PortfolioLabel>PORTFOLIO</PortfolioLabel>
				<PortfolioLink
					to={routes.portfolioByIdView.toHref({
						portfolioId,
						viewType: 'dashboard',
					})}
				>
					{portfolioName}
				</PortfolioLink>
			</Stat>
			<Stat>
				<StatLabel>Properties</StatLabel>
				<StatValue>{propertyCount}</StatValue>
			</Stat>
			<Stat>
				<StatLabel>Leases</StatLabel>
				<StatValue>{leaseCount}</StatValue>
			</Stat>
		</StatsContainer>
	);
};

const StatsContainer = styled.div`
	background-color: ${(p) => p.theme.colors.neutral.n20};
	border-radius: 0.125rem;
	display: flex;
	align-items: center;
	padding: 0.625rem;
	height: 50px;
`;

const Stat = styled.div`
	padding: 0 0.75rem;
	overflow: hidden;
	&:not(:first-of-type) {
		border-left: ${(p) => `1px solid ${p.theme.colors.neutral.n30}`};
	}
`;

const StatLabel = styled.div`
	font-weight: 500;
	font-size: 0.625rem;
	display: flex;
	color: ${(p) => p.theme.colors.blue.blue400};
`;

const PortfolioLabel = styled(StatLabel)`
	color: ${(p) => p.theme.colors.gray.gray500};
`;

const PortfolioLink = styled(Link)`
	display: block;
	overflow: hidden;
	max-width: 100px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StatValue = styled.div`
	font-weight: 500;
	font-size: 0.875rem;
	color: ${(p) => p.theme.colors.gray.gray500};
`;
