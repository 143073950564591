import { Spinner, MQB } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { DashboardCardLoadingContainer } from 'PortfolioAnalytics/Dashboard/UI';
import { LeaseExpirationsBySpaceTypeChart } from './LeaseExpirationsBySpaceTypeChart';
import { BuildingClassChart } from './BuildingClassChart';
import { PropertyTypeChart } from './PropertyTypeChart';
import { TenantExposureChart } from './TenantExposureChart';
import { TenantIndustryExposureChart } from './TenantIndustryExposureChart';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { ChartsContainerV2 } from '../styles/PortfolioUI';
import { useMediaQuery } from 'react-responsive';

type OverviewTabChartsProps = {
	portfolioId: number;
};

export const OverviewTabCharts = ({ portfolioId }: OverviewTabChartsProps) => {
	const { filters } = usePortfolioFilters();

	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery({
			id: portfolioId,
			filters,
		});

	const isNarrowScreen = useMediaQuery({
		maxWidth: MQB.D_1280,
	});

	const minBarChartWidth = isNarrowScreen ? 405 : 480;

	if (portfolio == null || isFetchingPortfolio) {
		return (
			<DashboardCardLoadingContainer>
				<Spinner />
			</DashboardCardLoadingContainer>
		);
	}

	return (
		<ChartsContainerV2>
			<TenantExposureChart
				portfolio={portfolio}
				minBarChartWidth={minBarChartWidth}
			/>
			<TenantIndustryExposureChart portfolio={portfolio} />
			<LeaseExpirationsBySpaceTypeChart
				portfolio={portfolio}
				minBarChartWidth={minBarChartWidth}
			/>
			<PropertyTypeChart portfolio={portfolio} />
			<BuildingClassChart portfolio={portfolio} />
		</ChartsContainerV2>
	);
};
