import { Chart, DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { useCallback, useMemo } from 'react';
import { getSalePropertyTypeItemById } from '../Modals/DataSets/CreateNewDataSetV2/propertyTypeItems';
import { getSpaceTypeItemsFromIds } from '../Modals/DataSets/CreateNewDataSetV2/spaceTypeItemsV2';
import { DataSetFormV2 } from './DataSetForm.v2';
import { UseDataSetFormState } from './useDataSetForm';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

type DataSetFormLegacyAdapterProps = {
	dataSet: DataSet;
	chartDraft: Chart;
	expanded: boolean;
	updateSeries: (series: AttributeToPlotType) => void;
	handleApplyAll: (val: AttributeToPlotType) => void;
};

export const DataSetFormLegacyAdapter = ({
	chartDraft,
	dataSet,
	updateSeries,
	handleApplyAll,
	expanded,
}: DataSetFormLegacyAdapterProps) => {
	const spaceTypeInitOptions = useMemo(() => {
		const spaceTypeFilter = dataSet.filters.spaceTypeId ?? [];
		return getSpaceTypeItemsFromIds(spaceTypeFilter);
	}, [dataSet.filters.spaceTypeId]);

	const propertyTypeInitialValue = useMemo(() => {
		const propertyTypeFilter = dataSet.filters.buildingPropertyTypeId ?? [];
		return propertyTypeFilter.map((id) => getSalePropertyTypeItemById(id)!);
	}, [dataSet.filters.buildingPropertyTypeId]);

	const onAttributeToPlotChange = useCallback(
		// @ts-expect-error TS7006: Parameter 'newSeries' implicit...
		(newSeries) => {
			updateSeries(newSeries);
		},
		[updateSeries]
	);

	const initialState: UseDataSetFormState = useMemo(
		() => ({
			title: '',
			market: dataSet.filters.market,
			spaceTypes: spaceTypeInitOptions,
			propertyTypes: propertyTypeInitialValue,
			attributeToPlot: dataSet.series,
			attributeToPlotItems: [],
			dataSetType: DataSetType.COMMERCIAL,
		}),
		[
			dataSet.filters.market,
			dataSet.series,
			spaceTypeInitOptions,
			propertyTypeInitialValue,
		]
	);

	return (
		<DataSetFormV2
			chartDraft={chartDraft}
			initialState={initialState}
			onAttributeToPlotChange={onAttributeToPlotChange}
			onApplyAll={handleApplyAll}
			dataSet={dataSet}
			expanded={expanded}
		/>
	);
};
