import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { LeaseFilterFields } from 'Pages/Search/Sidebar/Components/LeaseFilterFields';
import { PropertyFilterFields } from 'Pages/Search/Sidebar/Components/PropertyFilterFields';
import { SaleFilterFields } from 'Pages/Search/Sidebar/Components/SaleFilterFields';

export const DataSetMoreFilters = () => {
	const { compType } = useFilterFieldContext();

	if (compType === 'sale') {
		return <SaleFilterFields />;
	}

	if (compType === 'property') {
		return <PropertyFilterFields />;
	}

	return <LeaseFilterFields />;
};
