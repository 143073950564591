import { API } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio } from '../../types';
import { trackSegmentCreatePortfolio } from 'middleware/tracking/segment';
import { CopyPortfolioParams } from '../copyPortfolio';
import { formatDate } from 'format';
import { SharedPortfolio } from './useSharedPortfolioByIdQuery';

export const useCopySharedPortfolioMutation = (
	options?: UseMutationOptions<Portfolio, unknown, SharedPortfolio>
) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: SharedPortfolio) => {
			const currentDate = formatDate(new Date(), 'YYYY_MM_DD_HH-mm');
			const name = `${params.portfolio.name}_${currentDate}`;

			const sharedByUser = `${params.user.firstName} ${params.user.lastName}`;
			const description = `Shared by ${sharedByUser} ${currentDate}\n${params.portfolio.description ?? ''}`;

			const sharedPortfolioParams: CopyPortfolioParams = {
				portfolioId: params.portfolio.id,
				name,
				description,
			};

			const response = await API.post<Portfolio, CopyPortfolioParams>(
				`/pa/v2/api/domain/portfolios/copy`,
				sharedPortfolioParams
			);
			return response.data;
		},
		onSuccess: (newPortfolio) => {
			const portfolios = queryClient.getQueryData<Portfolio[]>([
				QUERY_KEYS.ALL_PORTFOLIOS,
			]);
			trackSegmentCreatePortfolio({
				existingPortfolios: portfolios || [],
				newPortfolio,
				createdFromSharedPortfolio: true,
			});
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
		...options,
	});
};
