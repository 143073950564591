import { Portfolio } from 'api';
import { useEffect } from 'react';
import { getPropertyTypeForSelectionForMarket } from './getPropertyTypeForSelectionForMarket';
import { getValidPropertyTypes } from './getValidPropertyTypes';
import { MarketRentState } from './MarketRentProvider';
import { useValidMarkets } from './useValidMarkets';

/**
 * When the portfolio dashboard filters change -
 * a new portfolio by id is fetched
 * and then we need to update/reset the market rent section state accordingly
 */
export const useResetMarketRentState = (
	marketRentState: MarketRentState,
	setMarketRentState: React.Dispatch<React.SetStateAction<MarketRentState>>,
	portfolio: Portfolio
) => {
	const validMarkets = useValidMarkets({ portfolio });

	const { selectedMarketId, selectedPropertyTypeId } = marketRentState;

	useEffect(() => {
		const isCurrentMarketValid = validMarkets.find(
			(m) => m.marketId === selectedMarketId
		);

		// try to "save" the currently selected market & property type
		if (isCurrentMarketValid && selectedMarketId) {
			const validPropertyTypes = getValidPropertyTypes(
				portfolio.propertyTypesByMarkets,
				selectedMarketId
			);

			const isCurrentPropertyTypeValid = validPropertyTypes.find(
				(pm) => pm.buildingPropertyTypeId === selectedPropertyTypeId
			);

			// if both selected market & property types are valid - no need to reset anything
			if (isCurrentPropertyTypeValid) {
				return;
			}

			// if only selected market is valid - reset the property type, but keep the market
			setMarketRentState((prev) => ({
				...prev,
				selectedPropertyTypeId:
					getPropertyTypeForSelectionForMarket(
						selectedMarketId,
						portfolio.propertyTypesByMarkets
					) ?? null,
			}));
			return;
		}

		if (validMarkets.length === 1) {
			const onlyMarketId = validMarkets[0].marketId;

			const propertyTypeId = getPropertyTypeForSelectionForMarket(
				onlyMarketId,
				portfolio.propertyTypesByMarkets
			);

			setMarketRentState({
				selectedMarketId: onlyMarketId,
				selectedPropertyTypeId: propertyTypeId ?? null,
				propertyPopup: null,
			});
		} else {
			setMarketRentState({
				selectedMarketId: null,
				selectedPropertyTypeId: null,
				propertyPopup: null,
			});
		}
	}, [
		portfolio,
		validMarkets,
		setMarketRentState,
		selectedMarketId,
		selectedPropertyTypeId,
	]);
};
