import { useQueries } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import {
	fetchSubmarketByMarket,
	UseSubmarketsByMarketOptions,
	UseSubmarketsByMarketParams,
} from './useSubmarketsByMarket';

export const useSubmarketsByMarkets = (
	params: UseSubmarketsByMarketParams[],
	options?: UseSubmarketsByMarketOptions
) => {
	const markets = params;

	return useQueries({
		queries: markets.map((market) => {
			const marketId = market?.id;

			return {
				queryKey: [QUERY_KEYS.SUBMARKETS, marketId],
				queryFn: () => fetchSubmarketByMarket(marketId),
				refetchOnWindowFocus: false,
				enabled: !!marketId,
				...options,
			};
		}),
	});
};
