import { nullValueText } from '@compstak/common';
import { isLeaseDateEstimated, LeaseEstimatedFieldName } from 'api';
import {
	formatBool,
	formatBuildingRail,
	formatBuildingCeilingHeight,
	formatCompQuarter,
	formatCsv,
	formatDate,
	formatInteger,
	formatLeaseEscalations,
	formatMoney,
	formatMonths,
	formatPercent,
	formatRentBumpsDollar,
	formatRentBumpsPercent,
	Gapper,
	renderEstimatedPostfix,
} from 'format';
import { AnyComp, CompKeys, LeaseComp, LeaseFloorOccupancy } from 'types';
import { formatLeaseEstimatedField } from './formatLeaseEstimatedField';
import { TableCompKeys, TableCompValues } from 'types/table';
import { formatSortArray } from './formatSortArray';
import { CellWarning } from 'Components/SearchTable/CellWarning';
import { TextOverflow } from 'Components/SearchTable/UI';
import { formatStartingRent } from './formatStartingRent';
import { getIsPortfolioField } from 'Components/MultiPropertyItemsPopup/utils';
import { isLoanField, isLoanPropertyComp, isSaleComp } from 'utils/compHelpers';

type Args<K extends TableCompKeys = TableCompKeys> = {
	name: K;
	row: AnyComp;
	isMonthly?: boolean;
	config?: {
		showWarningIfNoValue?: boolean;
		label: string;
	};
};

/**
 * - extracts field value by name from any comp
 * - compatible with loan & portfolio fields
 * @returns formatted field value sometimes depending on the @argument config - optional @argument isMonthly - optional
 */
export const formatCompField = <K extends TableCompKeys = TableCompKeys>({
	name,
	row,
	isMonthly,
	config,
}: Args<K>) => {
	let value: TableCompValues | any; // <-- `any` to absorb multiple ts-errors in this function (value type guards are needed)

	if (getIsPortfolioField(name) && isSaleComp(row)) {
		value = row.portfolio[0][name];
	} else if (isLoanField(name) && isLoanPropertyComp(row)) {
		value = row.loan[name];
	} else {
		value = row[name as CompKeys];
	}

	if (value == null) {
		if (config && config.showWarningIfNoValue) {
			return (
				<CellWarning>
					<TextOverflow>No {config.label}</TextOverflow>
				</CellWarning>
			);
		}
		return nullValueText;
	}

	switch (name) {
		case 'executionQuarter':
		case 'saleQuarter': {
			return formatCompQuarter(value);
		}
		case 'buildingSize':
		case 'transactionSize': {
			return value === 0
				? nullValueText
				: `${formatInteger(value, { shorten: false })} SF`;
		}
		case 'buildingOfficePortion': {
			return `${formatInteger(value.value, { shorten: false })} SF`;
		}
		case 'totalTransactionSize':
		case 'insideviewEmployees':
		case 'propertyAverageTransactionSize':
		case 'propertySqFtExpiringInTwelveMonths': {
			return formatInteger(value, { shorten: false });
		}
		case 'buildingSellingBasement':
		case 'buildingVentedSpace':
		case 'buildingCornerUnit':
		case 'sublease':
		case 'buildingSprinkler':
		case 'sprinkler':
		case 'includeBusinesses': {
			return formatBool(value);
		}
		case 'rentBumpYears':
		case 'landlordName':
		case 'landlordRealtyBrokers':
		case 'landlordRealtyCompanies':
		case 'tenantRealtyBrokers':
		case 'tenantRealtyCompanies':
		case 'retailAnchor':
		case 'recordedBuyer':
		case 'recordedSeller':
		case 'sellerRepBrokers':
		case 'buyerRepBrokers':
		case 'sellerRepCompanies':
		case 'buyerRepCompanies':
		case 'buyer':
		case 'seller':
		case 'insideviewWebsites':
		case 'insideviewTickers': {
			return formatCsv(value);
		}
		case 'executionDate':
		case 'commencementDate':
		case 'expirationDate': {
			return formatLeaseEstimatedField(name, value, row as LeaseComp);
		}
		case 'leaseTerm': {
			return (
				<Gapper>
					{formatMonths(value)}
					{renderEstimatedPostfix(
						isLeaseDateEstimated(
							row as LeaseComp,
							name as LeaseEstimatedFieldName
						)
					)}
				</Gapper>
			);
		}
		case 'startingRent': {
			return formatStartingRent(row as LeaseComp, value, isMonthly);
		}
		case 'effectiveRent':
		case 'reportedRent':
		case 'currentRent':
		case 'adjStartingRent':
		case 'adjEffectiveRent':
		case 'askingRent':
		case 'workValue':
		case 'operatingExpensesValue':
		case 'grossAnnualAskingRent':
		case 'grossAnnualTakingRent':
		case 'blendedRent':
		case 'officePortionRent':
		case 'totalSalePrice':
		case 'totalNetOperatingIncome':
		case 'netOperatingIncomePsf':
		case 'totalOperatingExpenses':
		case 'totalAskingSalePrice':
		case 'askingSalePricePsf':
		case 'lastSalePrice':
		case 'askingRpsf':
		case 'effectiveRpsf':
		case 'loanAmount':
		case 'insideviewRevenue':
		case 'concessionValue': {
			return formatMoney(value, { shorten: true });
		}
		case 'salePricePsf':
		case 'propertyMarketStartingRent':
		case 'propertyMarketEffectiveRent': {
			return `${formatMoney(value, { shorten: true })} PSF`;
		}
		case 'floorOccupancies': {
			return (value as LeaseFloorOccupancy).formatted;
		}
		case 'rentBumpsDollar': {
			return formatRentBumpsDollar(value, isMonthly);
		}
		case 'rentBumpsPercent': {
			return formatRentBumpsPercent(value);
		}
		case 'leaseEscalations': {
			return formatLeaseEscalations(value, isMonthly);
		}
		case 'freeMonths':
		case 'additionalFreeMonths':
		case 'loanTerm': {
			return formatMonths(value);
		}
		case 'proRataPercent': {
			return formatPercent(value, 1);
		}
		case 'buildingLoadFactor':
		case 'interestPercentage':
		case 'capRate':
		case 'percentOccupied':
		case 'loanToValue':
		case 'currentLoanRate': {
			return formatPercent(value / 100);
		}
		case 'percentImproved':
		case 'occupancy': {
			return formatPercent(value);
		}
		case 'buildingParkingRatio':
		case 'lotSize': {
			return Number(value).toFixed(2);
		}
		case 'geoPoint': {
			return `${value.lat},${value.lon}`;
		}
		case 'lastUpdated':
		case 'dateCreated':
		case 'saleDate':
		case 'recordDate':
		case 'loanOriginationDate': {
			return formatDate(value);
		}
		case 'loanMaturityDate': {
			return formatDate(value, 'MMM YYYY');
		}
		case 'rentReviewDates':
		case 'breakOptionDates': {
			return value
				.toSorted()
				.map((date: string) => formatDate(date))
				.join('; ');
		}
		case 'buildingRail': {
			return formatBuildingRail(value);
		}
		case 'buildingCeilingHeight':
		case 'ceilingHeight': {
			return formatBuildingCeilingHeight(value);
		}
		default:
			return Array.isArray(value) ? formatSortArray(value) : value;
	}
};
