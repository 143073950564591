import { Period } from '@compstak/ui-kit';
import { createDefaultPeriodRange } from 'Pages/PropertyPageV2_1/utils/createDefaultPeriodRange';
import { SpaceTypeName } from 'api';
import { useCallback, useState } from 'react';
import {
	CommercialChartsNames,
	DistributionMonths,
	FiltersNames,
	FiltersValues,
} from 'types/propertyTrendCharts';

export const initFiltersState: CommercialTrendsFiltersState = {
	[CommercialChartsNames.SubmarketRentTrend]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
	},
	[CommercialChartsNames.SubmarketRentDistribution]: {
		[FiltersNames.SPACE_TYPE]: 'Office',
		[FiltersNames.MONTHS]: 36,
	},
};

export type CommercialTrendsFiltersState = {
	[CommercialChartsNames.SubmarketRentTrend]: {
		[FiltersNames.PERIOD]: Period;
	};
	[CommercialChartsNames.SubmarketRentDistribution]: {
		[FiltersNames.SPACE_TYPE]: SpaceTypeName;
		[FiltersNames.MONTHS]: DistributionMonths;
	};
};

export const useCommercialTrendsState = () => {
	const [filters, setFilters] =
		useState<CommercialTrendsFiltersState>(initFiltersState);

	const handleFilterChange: CommercialFilterChangeFn = useCallback(
		(
			chartName: CommercialChartsNames,
			filterName: FiltersNames,
			filterValue: FiltersValues
		) => {
			setFilters((s) => ({
				...s,
				[chartName]: {
					...s[chartName],
					[filterName]: filterValue,
				},
			}));
		},
		[]
	);

	return { filters, handleFilterChange };
};

export type CommercialFilterChangeFn = (
	chartName: CommercialChartsNames,
	filterName: FiltersNames,
	filterValue: FiltersValues
) => void;
