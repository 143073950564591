import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { CompsetDTO, CompsetType } from 'api';
import { useMemo } from 'react';
import { LeaseComp } from 'types';
import { PdfPage } from '../../../../pdf/PdfPage';
import { PdfRealPageLogoView } from '../PdfRealPageLogoView';
import { PdfTable } from '../PdfTable';
import { pdfStyle } from '../style';
import {
	EditableCompsetRow,
	EditableLeaseComp,
	PdfColumn,
	PdfPageProps,
} from '../types';
import { useCompsetMapImageUrl } from './useCompsetMapImageUrl';

type Props = PdfPageProps & {
	rows: CompsetDTO[];
	columns: PdfColumn<EditableCompsetRow, CompsetDTO>[];
	compsetType: CompsetType | undefined;
	editableCompsetRows: EditableCompsetRow[];
	editableLeasesInCompsetColumns: PdfColumn<EditableLeaseComp, LeaseComp>[];
	editableLeasesInCompsetRows: EditableLeaseComp[];
	showLeasesInCompset: boolean;
	getShouldHighlightRow?: (row: number | string) => boolean;
	propertyLeaseCount: number;
};

export const CompsetPage = (props: Props) => {
	const {
		property,
		rows,
		editableCompsetRows,
		columns,
		editableLeasesInCompsetRows,
		editableLeasesInCompsetColumns,
		compsetType,
		showLeasesInCompset,
		propertyLeaseCount,
	} = props;

	const url = useCompsetMapImageUrl({
		width: MAPBOX_IMAGE_SIZE.width,
		height: MAPBOX_IMAGE_SIZE.height,
		properties: rows,
	});

	const visibleColumns = useMemo(
		() => columns.filter((c) => c.isVisible),
		[columns]
	);

	const visibleLeasesInCompsetColumns = useMemo(
		() => editableLeasesInCompsetColumns.filter((c) => c.isVisible),
		[editableLeasesInCompsetColumns]
	);

	return (
		<PdfPage
			logo={props.page.logo}
			preparedFor={props.page.preparedFor}
			title={props.page.titles.compset}
			user={props.user}
		>
			<View style={[pdfStyle.pageTitle, pdfStyle.realPageTitle]}>
				<Text>Competitive Set</Text>
				{compsetType === CompsetType.REALPAGE && (
					<View>
						<PdfRealPageLogoView />
					</View>
				)}
			</View>
			<Image
				src={url.href}
				style={{
					width: '100%',
					height: MAPBOX_IMAGE_SIZE.height,
				}}
			/>

			<PdfTable rows={editableCompsetRows} columns={visibleColumns} />

			{showLeasesInCompset && editableLeasesInCompsetRows.length > 0 && (
				<>
					<View break />
					<View style={[compsetStyle.titleContainer, pdfStyle.pageTitle]}>
						<Text>Leases in Competitive Set</Text>
						{propertyLeaseCount > 0 && (
							<Text style={compsetStyle.titleInfo}>
								Subject property leases are highlighted in grey
							</Text>
						)}
					</View>
					<PdfTable
						rows={editableLeasesInCompsetRows}
						columns={visibleLeasesInCompsetColumns}
						getShouldHighlightRow={(propertyId) =>
							Number(propertyId) === property.id
						}
					/>
				</>
			)}
		</PdfPage>
	);
};

const MAPBOX_IMAGE_SIZE = {
	width: 794,
	height: 150,
};

const compsetStyle = StyleSheet.create({
	titleInfo: {
		fontSize: 10,
		marginLeft: 10,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
});
