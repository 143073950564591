import { LeaseInsights, useHasLeaseInsights } from 'Components/LeaseInsights';
import { FilterChangeEffect } from 'Layouts/SearchLayout';
import 'Layouts/styles/searchLayout.nomodule.less';
import { Table } from 'Pages/Search/Table';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { LeasesSearchTable } from './LeasesSearchTable';
import { useCreateAdditionalFilters } from './utils/useCreateAdditionalFilters';

const TableComponent = () => {
	const [filters, setFilters, resetFilters] = useFilters('exchange-dashboard');

	const additionalFilters = useCreateAdditionalFilters(filters);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const backupFilters = useMemo(() => filters, []);

	useEffect(() => {
		if (!additionalFilters) {
			return;
		}

		setFilters(additionalFilters);

		return () => {
			resetFilters(backupFilters);
		};
	}, [additionalFilters, backupFilters, setFilters, resetFilters]);

	const flags = useFeatureFlags();

	const hasInsights = useHasLeaseInsights({ filters });

	const [areInsightsOpen, setAreInsightsOpen] = useState(false);

	return (
		<Container>
			<FilterChangeEffect compType={'lease'} filters={filters} />
			{flags.DashboardSearchTableRefactor ? (
				<SearchTableLayout
					style={{
						gridTemplateRows: hasInsights
							? areInsightsOpen
								? '35px 270px auto'
								: `35px auto`
							: undefined,
					}}
				>
					{hasInsights && (
						<LeaseInsights
							isOpen={areInsightsOpen}
							toggleIsOpen={() => setAreInsightsOpen((s) => !s)}
							isDashboard
						/>
					)}
					<LeasesSearchTable
						settingsMenuSide={areInsightsOpen ? 'right' : 'bottom'}
					/>
				</SearchTableLayout>
			) : (
				<div className="search_inner_content">
					<div className="search_content_results">
						<TransitionGroup>
							<CSSTransition
								classNames="layout-results-transition"
								timeout={300}
							>
								<Table
									compType="lease"
									filters={filters}
									onFilterChange={setFilters}
								/>
							</CSSTransition>
						</TransitionGroup>
					</div>
				</div>
			)}
		</Container>
	);
};

export default TableComponent;

const Container = styled.div`
	height: calc(100% - 90px);
	position: relative;
	flex: 1;
`;

const SearchTableLayout = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: grid;
	background-color: #fff;
`;
