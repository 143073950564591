import { Pending as PendingOriginal, Table } from '@compstak/ui-kit';
import { useSaleCompQuery, useSimilarSalesQuery } from 'api';
import { useIsExchange } from 'hooks';
import { useMemo } from 'react';
import { routes, useNavigate } from 'router';
import styled from 'styled-components';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { useSaleMarket } from '../../useSaleMarket';
import { useCompAddressesSearchUrl } from '../../utils';
import { columns } from './columns';

export const SimilarSales = () => {
	const params = routes.saleById.useParams();

	const navigate = useNavigate();
	const isExchange = useIsExchange();
	const market = useSaleMarket({ id: params.id });

	const { data: sale } = useSaleCompQuery({ id: params.id });

	const { data: similarSalesData, isFetching } = useSimilarSalesQuery({
		propertyId: sale?.portfolio[0].propertyId,
	});

	const sortedSimilarSales = useMemo(() => {
		if (!similarSalesData) return [];
		return similarSalesData.comps.toSorted((a, b) => {
			if (a.saleDate == null || b.saleDate == null) return 0;
			return b.saleDate.localeCompare(a.saleDate);
		});
	}, [similarSalesData]);

	const addresses = useMemo(
		() => [
			...new Set(
				sortedSimilarSales.map(
					(similarSale) => similarSale.portfolio[0].buildingAddressAndCity
				) ?? []
			),
		],
		[sortedSimilarSales]
	);

	const compsetSalesUrl = useCompAddressesSearchUrl({
		compType: 'sale',
		addresses,
		market,
	});

	return (
		<TabContentContainer>
			<TabContentHeader>
				<TabContentTitle>Similar Sales</TabContentTitle>

				{isFetching ? (
					<Pending margin="0" />
				) : (
					<SeeAllLink
						isDisabled={!similarSalesData}
						to={compsetSalesUrl}
						data-qa-id="SeeAllSalesLink"
					>
						view all sales in this set
					</SeeAllLink>
				)}
			</TabContentHeader>
			<Table
				columns={columns}
				rows={sortedSimilarSales}
				isLoading={isFetching}
				extraData={{ isExchange }}
				bodyCellProps={({ row }) => ({
					onClick: () => {
						navigate(`/comps/sales/${row.id}`, {
							state: {
								fromNewSalePage: true,
							},
						});
					},
				})}
			/>
		</TabContentContainer>
	);
};

const Pending = styled(PendingOriginal)`
	height: 1em;
	width: 12.5rem;
	margin-left: auto;
`;
