import { ErrorMessage } from '@compstak/ui-kit';
import { css } from 'styled-components';

export function SingleQuestionFailed() {
	return (
		<ErrorMessage>
			Something went wrong. You can skip this question.
		</ErrorMessage>
	);
}

export const surveyLetterSpacingCss = css`
	letter-spacing: 0.4px;
	line-height: 22px;
`;
