import { PolygonsLayer, PopupPositioning } from '@compstak/maps';
import { APNPolygon } from 'api/ppm-property/apnPolygons/useAPNPolygonQuery';
import {
	MAP_FILTER_RED_COLOR,
	MAP_FILTER_RED_HALF_OPAQUE_COLOR,
} from 'constants/colors';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';

type APNLayerProps = {
	apnFeatures: APNPolygon[];
};

const ID = 'APNLayer';

export const APNLayer = ({ apnFeatures }: APNLayerProps) => {
	return (
		<PolygonsLayer
			id={ID}
			data={apnFeatures}
			getFillColor={() => MAP_FILTER_RED_HALF_OPAQUE_COLOR}
			getLineColor={MAP_FILTER_RED_COLOR}
			highlightColor={MAP_FILTER_RED_HALF_OPAQUE_COLOR as number[]}
			getTooltipContent={(f) => f.properties.apn}
			tooltipPositioning={PopupPositioning.CENTER_POLYGON}
			getLineWidth={4}
			tooltipAnchor="bottom-left"
			getDashArray={[5, 2]}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
