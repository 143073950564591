import { Button, ErrorMessage } from '@compstak/ui-kit';
import React from 'react';
import styled from 'styled-components';

import Invalid from '../../ui/svg_icons/error.svg';
import Valid from '../../ui/svg_icons/tick_circle.svg';

import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';
import styles from './create-password.less';

const validOrInvalid = (isValid: boolean) => (
	<span className={isValid ? styles.iconValid : styles.iconInvalid}>
		{isValid ? (
			<Valid width={20} height={20} />
		) : (
			<Invalid width={20} height={20} />
		)}
	</span>
);

function ValidatorFeedback({
	// @ts-expect-error TS7031: Binding element 'passwordHasFo...
	passwordHasFocus,
	// @ts-expect-error TS7031: Binding element 'password' imp...
	password,
	// @ts-expect-error TS7031: Binding element 'longEnough' i...
	longEnough,
	// @ts-expect-error TS7031: Binding element 'hasLetter' im...
	hasLetter,
	// @ts-expect-error TS7031: Binding element 'hasNumber' im...
	hasNumber,
}) {
	const className =
		passwordHasFocus && password.length > 0
			? styles.validatorVisible
			: styles.validatorInvisible;
	return (
		<div className={className}>
			<div className={styles.validatorContainer}>
				<h4>PASSWORD RULES</h4>
				<ul>
					<li>
						{validOrInvalid(longEnough)}
						<span className={styles.errorMessage}>5 characters minimum</span>
					</li>

					<li>
						{validOrInvalid(hasLetter)}
						<span className={styles.errorMessage}>
							Contains at least 1 letter
						</span>
					</li>

					<li>
						{validOrInvalid(hasNumber)}
						<span className={styles.errorMessage}>
							Contains at least 1 number
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
}

type CreatePasswordFormProps = {
	isSubmitting: boolean;
	onSubmit: (password: string) => void;
	buttonText: string;
	headerText: string;
	subhead?: string;
	requiresTou?: boolean;
	requiresPrivacyPolicy?: boolean;
	errorText?: string;
};

type CreatePasswordFormState = {
	longEnough: boolean;
	hasNumber: boolean;
	hasLetter: boolean;
	password: string;
	confirmation: string;
	confirmationHasFocus: boolean;
	passwordHasFocus: boolean;
	touAccepted: boolean;
	privacyPolicyAccepted: boolean;
	allIsGood: boolean;
};

export default class CreatePasswordForm extends React.Component<
	CreatePasswordFormProps,
	CreatePasswordFormState
> {
	state = {
		longEnough: true,
		hasNumber: true,
		hasLetter: true,
		password: '',
		confirmation: '',
		confirmationHasFocus: false,
		passwordHasFocus: false,
		touAccepted: false,
		privacyPolicyAccepted: false,
		allIsGood: false,
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleFocusChange = (event) => {
		if (event.target.id === 'password') {
			this.setState({
				passwordHasFocus: event.type === 'focus',
			});
		} else {
			this.setState({
				confirmationHasFocus: event.type === 'focus',
			});
		}
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleConfirmationChange = (event) => {
		this.setState({
			confirmation: event.target.value,
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleNewPasswordChange = (event) => {
		const newPassword = event.target.value;

		const nextState = {
			longEnough: true,
			hasNumber: true,
			hasLetter: true,
			password: newPassword,
			allIsGood: true,
		};

		if (event.target.value.length === 0) {
			this.setState(nextState);
			return;
		}
		if (event.target.value.length < 5) {
			nextState.longEnough = false;
			nextState.allIsGood = false;
		}
		if (/[A-Z]/i.test(newPassword) === false) {
			nextState.hasLetter = false;
			nextState.allIsGood = false;
		}

		if (/[0-9]/.test(newPassword) === false) {
			nextState.hasNumber = false;
			nextState.allIsGood = false;
		}

		this.setState(nextState);
	};

	handleTouAcceptance = () => {
		this.setState({
			touAccepted: !this.state.touAccepted,
		});
	};

	handlePrivacyPolicyAcceptance = () => {
		this.setState({
			privacyPolicyAccepted: !this.state.privacyPolicyAccepted,
		});
	};

	handleSubmit = () => {
		this.props.onSubmit(this.state.password);
	};

	render() {
		const passwordClassName =
			this.state.passwordHasFocus || this.state.password
				? styles.labelCollapsed
				: styles.label;

		const confirmationClassName =
			this.state.confirmationHasFocus || this.state.confirmation
				? styles.labelCollapsed
				: styles.label;

		const shouldShowDontMatch =
			this.state.confirmation &&
			this.state.password !== this.state.confirmation;

		const submitIsEnabled =
			this.state.allIsGood &&
			this.state.password === this.state.confirmation &&
			(!this.props.requiresTou || this.state.touAccepted) &&
			(!this.props.requiresPrivacyPolicy || this.state.privacyPolicyAccepted);

		return (
			<div className={styles.container}>
				<h1>{this.props.headerText}</h1>
				{this.props.subhead ? <p>{this.props.subhead}</p> : <br />}
				<form>
					<div className={styles.inputGroup}>
						<label className={passwordClassName} htmlFor="password">
							<span className={styles.labelText}>Password</span>
						</label>

						<input
							type="password"
							className={styles.input}
							value={this.state.password}
							id="password"
							autoComplete="off"
							onChange={this.handleNewPasswordChange}
							onFocus={this.handleFocusChange}
							onBlur={this.handleFocusChange}
							data-qa-id="password"
						/>

						<ValidatorFeedback {...this.state} />
					</div>

					<div className={styles.inputGroup}>
						<label className={confirmationClassName} htmlFor="confirmPassword">
							<span className={styles.labelText}>Confirm password</span>
						</label>

						<input
							type="password"
							className={styles.input}
							value={this.state.confirmation}
							id="confirmPassword"
							autoComplete="off"
							onChange={this.handleConfirmationChange}
							onFocus={this.handleFocusChange}
							onBlur={this.handleFocusChange}
							data-qa-id="confirm-password"
						/>

						<div
							className={
								shouldShowDontMatch
									? styles.confirmationErrorVisible
									: styles.confirmationErrorInvisible
							}
						>
							Passwords don't match
						</div>
					</div>

					{this.props.requiresTou && (
						<div className={styles.checkboxGroup}>
							<input
								type="checkbox"
								name="terms_of_use"
								id="terms_of_use"
								className="checkbox"
								checked={this.state.touAccepted}
								onClick={this.handleTouAcceptance}
								data-qa-id="terms-of-use-checkbox"
							/>
							<label htmlFor="terms_of_use" />
							<span>
								I agree to the{' '}
								<a
									href="http://www.compstak.com/legal/"
									target="_blank"
									rel="noreferrer"
									data-qa-id="terms-of-use-link"
								>
									terms of use
								</a>
							</span>
						</div>
					)}

					{this.props.requiresPrivacyPolicy && (
						<div className={styles.checkboxGroup}>
							<input
								type="checkbox"
								name="privacy_policy"
								id="privacy_policy"
								className="checkbox"
								checked={this.state.privacyPolicyAccepted}
								onClick={this.handlePrivacyPolicyAcceptance}
								data-qa-id="privacy-policy-checkbox"
							/>
							<label htmlFor="privacy_policy" />
							<span>
								I agree to the{' '}
								<a
									href="https://compstak.com/legal/#PrivacyPolicy"
									target="_blank"
									rel="noreferrer"
									data-qa-id="privacy-policy-link"
								>
									privacy policy
								</a>
							</span>
						</div>
					)}
					<SubmitButton
						variant="primary"
						size="l"
						type="button"
						className={`${button.large} ${button.block}`}
						disabled={!submitIsEnabled || this.props.isSubmitting}
						onClick={submitIsEnabled ? this.handleSubmit : undefined}
						data-qa-id="submit-button"
						isLoading={this.props.isSubmitting}
					>
						{this.props.isSubmitting ? (
							<div className={spinner.spinner} />
						) : (
							this.props.buttonText
						)}
					</SubmitButton>
					{this.props.errorText && (
						<ErrorMessageStyled>{this.props.errorText}</ErrorMessageStyled>
					)}
				</form>
			</div>
		);
	}
}

const SubmitButton = styled(Button)`
	width: 100%;
`;

const ErrorMessageStyled = styled(ErrorMessage)`
	margin-top: 16px;
`;
