import styled from 'styled-components';
import { checkboxStyles } from '../../../../ui/checkboxStyles';

export const Wrap = styled.div`
	min-width: 448px;
`;

const BaseRow = styled.div`
	display: grid;
	height: 40px;
	padding: 8px;
	justify-items: right;
	text-transform: none;
	box-sizing: border-box;
	font-size: 14px;
`;

export const Row = styled(BaseRow)<{
	isExceedLimit?: boolean;
	withFinancial?: boolean;
}>`
	grid-template-columns: 20px minmax(0, 205px) 1fr ${({ withFinancial }) =>
			withFinancial ? ' 1fr' : ''};

	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray700};
	}

	${({ isExceedLimit, theme }) =>
		isExceedLimit
			? `
        color: ${theme.colors.neutral.n70};
    `
			: ''}

	${checkboxStyles}
`;

export const Cell = styled.span`
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: right;
`;

export const CellLeft = styled(Cell)<{ isBlocked?: boolean }>`
	justify-self: left;
	cursor: ${({ isBlocked }) => (isBlocked ? 'not-allowed' : 'pointer')};

	.checkbox-blocked {
		cursor: not-allowed;
	}
`;

export const TextOverflow = styled.span`
	display: inline-block;
	text-wrap: nowrap;
	max-width: 205px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const HeaderRow = styled(Row)`
	background-color: ${({ theme }) => theme.colors.gray.gray700};
	text-transform: uppercase;
	border-bottom: 1px solid white;
	font-weight: 400;
	border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
	border-bottom: none;
`;

export const SelectedCell = styled(Cell)`
	svg {
		width: 14px;
		height: 14px;
		margin-right: 6px;
	}
`;

export const Body = styled.div`
	max-height: 434px;
	overflow-y: auto;
	border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
	background-color: ${({ theme }) => theme.colors.neutral.n500};
`;

export const SummaryIconWrap = styled.span`
	margin-left: 4px;
	fill: ${({ theme }) => theme.colors.neutral.n0};
`;

export const SummaryRow = styled(BaseRow)<{ withFinancial?: boolean }>`
	grid-template-columns: minmax(0, 225px) 1fr ${({ withFinancial }) =>
			withFinancial ? '1fr' : ''};
	font-weight: 700;
	${CellLeft} {
		cursor: default;
	}
`;
