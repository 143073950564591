import { Button as OriginalButton } from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { Link as OriginalLink } from 'router';
import styled from 'styled-components';
import { CompType } from 'types';
import pluralizeCompType from 'util/pluralizeCompType';

type LeagueTablesNavButtonProps = {
	isActive?: boolean;
	compType: CompType;
};

export const LeagueTablesNavButton = ({
	isActive,
	compType,
}: LeagueTablesNavButtonProps) => {
	const href = `/search/${pluralizeCompType(compType, false)}/leagueTables`;
	const isLessThan1300Width = useMediaQuery({ maxWidth: 1300 });

	return (
		<Button variant={isActive ? 'primary' : 'primary3'}>
			<Link to={href} data-qa-id={`${compType}-league-tables-link`}>
				{isLessThan1300Width ? 'LTB' : 'League Tables'}
			</Link>
		</Button>
	);
};

const Button = styled(OriginalButton)`
	height: 48px;
	font-size: 13px;
	min-width: unset;
	padding: 0;
`;

const Link = styled(OriginalLink)`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 4px 12px;
	color: inherit;
	&:hover {
		color: inherit;
	}
`;
