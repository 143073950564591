import React, { useEffect } from 'react';

import Menu from 'Singletons/Menu';
import styled from 'styled-components';
import styles from 'Components/Header/header.less';

import { chartBuilderActions } from '../../../Pages/Analytics/Builder/actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'util/useAppSelector';
import { HeaderLink } from 'Components/AppHeader/UI';
import { routes } from 'router';
import { useMarkets } from 'hooks/useMarkets';

type Props = {
	isAnalyticsActive: boolean;
	isDashboardActive: boolean;
	showItemsInHeader?: boolean;
};

export const AnalyticsMenuItems = ({
	isAnalyticsActive,
	isDashboardActive,
	showItemsInHeader,
}: Props) => {
	const dispatch = useDispatch();

	const chartBuilder = useAppSelector((selector) => selector.chartBuilder);
	const markets = useMarkets();

	useEffect(() => {
		dispatch(chartBuilderActions.loadChartDraft(markets));
	}, []);

	const analyticsLink = showItemsInHeader ? (
		<HeaderLink to={routes.analytics.path} active={isAnalyticsActive}>
			Chartbuilder
		</HeaderLink>
	) : (
		<a href={routes.analytics.path}>Chartbuilder</a>
	);

	const analyticsDashboardLink = showItemsInHeader ? (
		<HeaderLink to={routes.analyticsDashboard.path} active={isDashboardActive}>
			Market Dashboard
		</HeaderLink>
	) : (
		<a href={routes.analyticsDashboard.path}>Market Dashboard</a>
	);

	return (
		<>
			{!chartBuilder.error && !chartBuilder.loading && (
				<li className={isAnalyticsActive ? styles.active : ''}>
					{analyticsLink}
				</li>
			)}
			<li className={isDashboardActive ? styles.active : ''}>
				{analyticsDashboardLink}
			</li>
		</>
	);
};

function AnalyticsMenu(props: Props) {
	return (
		<MenuContainer>
			<ul>
				<AnalyticsMenuItems {...props} />
			</ul>
		</MenuContainer>
	);
}

Menu.addComponent('enterprise-analytics-menu', AnalyticsMenu, {
	offsetTop: 16,
});

export default 'enterprise-analytics-menu';

const MenuContainer = styled.div`
	margin: -10px;
	width: 185px;

	ul {
		border-radius: 3px;
		overflow: hidden;
		position: relative;
		width: 185px;
		z-index: 2;

		li {
			background-color: #313540;
			color: rgba(255, 255, 255, 0.7);
			font-weight: 300;
			font: 14px/24px Gotham;
			height: 40px;
			letter-spacing: 0.02em;
			position: relative;
			text-align: left;
			transition: background 0.3s ease-in-out;

			&:hover {
				background-color: #262931;
				cursor: pointer;

				a {
					background-color: #262931;
				}
			}

			&:not(:first-of-type) {
				border-top: 1px solid rgb(77, 78, 81);
			}

			a {
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				color: #f1f2f5;
				display: block;
				font-size: 12px;
				height: 100%;
				line-height: 40px;
				padding-left: 10px;
				text-transform: uppercase;
				letter-spacing: 0.12em;
				width: 100%;
			}
		}
	}
`;
