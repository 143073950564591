import { UNLOCK_LEASES } from 'actions/lease';
import { getPromiseActionType } from 'types/redux-promise-middleware';
import { formatComp, sectionedMapping } from 'util/comp-format/src/format';
import * as actions from './actions';
import { Action, LeasePage } from './types';

const initialState: LeasePage = {
	searchLoading: false,
	search: undefined,
	leases: {},
	lease: null,
	rawLease: null,
	leaseLoading: false,
	sectionedAttrs: null,
	lowestLoaded: -1,
	lowestLoading: -1,
	index: undefined,
	verifyComp: false,
	compVerificationSent: false,
	did404: false,
	totalLeases: undefined,
};

export default (state = initialState, action: Action) => {
	switch (action.type) {
		case getPromiseActionType(actions.LOAD_LEASE, 'PENDING'):
			return {
				...state,
				search: undefined,
				leaseLoading: true,
				did404: false,
				lease: null,
			};

		case getPromiseActionType(actions.LOAD_LEASE, 'FULFILLED'):
			return {
				...state,
				totalLeases: state.totalLeases,
				lease: formatComp(action.payload, 'lease'),
				rawLease: action.payload,
				sectionedAttrs: sectionedMapping(action.payload, 'lease'),
				compVerificationSent: false,
				leaseLoading: false,
			};

		case getPromiseActionType(actions.LOAD_LEASE, 'REJECTED'):
			return {
				...state,
				did404: true,
				leaseLoading: false,
			};

		case getPromiseActionType(UNLOCK_LEASES, 'PENDING'):
			break;

		case getPromiseActionType(UNLOCK_LEASES, 'FULFILLED'):
			const unlockedIds = action.payload.map((l) => l.id);

			if (!state.lease) {
				return state;
			}

			let newState = state;

			action.payload.forEach((lease) => {
				// @ts-expect-error TS18047: 'state.lease' is possibly 'nul...
				if (lease.id === state.lease.id) {
					newState = {
						...state,
						lease: formatComp(lease, 'lease'),
						rawLease: lease,
						sectionedAttrs: sectionedMapping(lease, 'lease'),
					};
				}
			});

			if (Array.isArray(state.search)) {
				let hasUpdate = false;
				const updated = state.search.map((l) => {
					const indexOfLeaseInPayload = unlockedIds.indexOf(l.id);
					if (indexOfLeaseInPayload !== -1) {
						hasUpdate = true;
						return action.payload[indexOfLeaseInPayload];
					}
					return l;
				});
				if (hasUpdate) {
					newState = {
						...newState,
						search: updated,
					};
				}
			}
			return newState;

		case actions.CLEAR_LEASE_PAGE_DATA:
			return initialState;

		case getPromiseActionType(actions.CREATE_LEASE_PAGE_SEARCH, 'PENDING'):
			return {
				...state,
				searchLoading: true,
			};

		case getPromiseActionType(actions.CREATE_LEASE_PAGE_SEARCH, 'FULFILLED'):
			return {
				...state,
				search: action.payload,
				searchLoading: false,
			};

		case getPromiseActionType(actions.CREATE_LEASE_PAGE_SEARCH, 'REJECTED'):
			return {
				...state,
				searchLoading: false,
			};

		case getPromiseActionType(actions.LEASE_PAGE_LOAD, 'PENDING'):
			return {
				...state,
				lowestLoading: action.meta.lowestLoaded,
				index: action.meta.index,
				compVerificationSent: false,
			};

		case getPromiseActionType(actions.LEASE_PAGE_LOAD, 'FULFILLED'):
			if (state.index !== action.meta.index) {
				break;
			}
			const leases = {};
			// @ts-expect-error TS7006: Parameter 'lease' implicitly h...
			action.payload.forEach((lease, i) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				leases[i + action.meta.lowestLoaded] = lease;
			});

			return {
				...state,
				lowestLoaded: action.meta.lowestLoaded,
				search: action.meta.search,
				leases,
				totalLeases: action.meta.search?.total,
			};

		case actions.SHOW_VERIFY_COMP:
			return {
				...state,
				verifyComp: true,
			};

		case actions.HIDE_VERIFY_COMP:
			return {
				...state,
				verifyComp: false,
			};

		case getPromiseActionType(actions.SEND_USER_CORRECTION, 'FULFILLED'):
			return {
				...state,
				verifyComp: false,
				compVerificationSent: true,
			};
	}
	return state;
};
