import React from 'react';
import { User } from '@compstak/common';
import button from 'ui/styles/button.less';
import settings from '../styles/settings.less';
import { useDispatch } from 'react-redux';
import { savePreferences } from 'actions/user';

type Props = {
	user: User;
};

const EmailSettings: React.FC<Props> = (props) => {
	const [checked, setChecked] = React.useState<boolean>(
		props.user.preferences.emailSubscriptions['SUMMARY']
	);
	const dispatch = useDispatch();

	const handlePreferenceSelect = () => {
		setChecked(!checked);
	};

	const handlePreferencesUpdate = () => {
		const newPreferences = {
			...props.user.preferences,
			emailSubscriptions: {
				...props.user.preferences.emailSubscriptions,
				SUMMARY: checked,
			},
		};

		dispatch(savePreferences(props.user, newPreferences));
	};

	return (
		<section className={`settings_content ${settings.contentGrid}`}>
			<div className={`form ${settings.form}`} id={settings.emailForm}>
				<form>
					<ul className="checkbox_group">
						<li>
							<input
								type="checkbox"
								data-email-type="SUMMARY"
								id="email_checkbox_SUMMARY"
								className="checkbox"
								checked={checked}
								onChange={handlePreferenceSelect}
							/>
							<label htmlFor="email_checkbox_SUMMARY">
								Weekly summary of my activities.
							</label>
						</li>
					</ul>
					<span
						className={`${settings.update} ${button.large} ${button.blue}`}
						onClick={handlePreferencesUpdate}
					>
						Update Email Preferences
					</span>
				</form>
			</div>
			<div className={`explanation ${settings.explanation}`}>
				<h1>Edit Notification Preferences</h1>
				<br />
				<p>
					Uncheck the types of email you do not <br /> want to see
				</p>
			</div>
		</section>
	);
};

export default EmailSettings;
