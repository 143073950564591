import { UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { PortfolioPropertiesSummaryParams } from '../../types';
import { createFetchFn, useQueryHook } from '../../utils';
import { PORTFOLIO_LIMIT } from '../constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';

export const fetchPortfolioPropertiesSummary = createFetchFn<
	PortfolioPropertiesSummaryParams,
	PortfolioPropertiesSummaryResponse
>({
	method: 'get',
	getURL: ({ portfolioId, ...queryArgs }) => {
		return `/pa/v2/api/portfolios/${portfolioId}/propertiesSummary${getQueryStringFromPortfolioFilters(
			queryArgs
		)}`;
	},
});

// TODO: AP-14320 to remove when portfolio revamp is done
export const usePortfolioPropertiesSummaryQuery = (
	_params: PortfolioPropertiesSummaryParams,
	options?: UseQueryOptions<PortfolioPropertiesSummaryResponse>
) => {
	const params = { limit: PORTFOLIO_LIMIT, ..._params };
	return useQueryHook({
		queryKey: [QUERY_KEYS.PORTFOLIO_PROPERTIES_SUMMARY, params],
		queryFn: fetchPortfolioPropertiesSummary(params),
		...options,
	});
};

export type PortfolioPropertiesSummaryResponse = PortfolioPropertySummary[];

export type PortfolioPropertySummary = {
	activeLeaseAmount: number;
	activeLeaseCount: number;
	avgCurrentRent?: number;
	dateCreated: string;
	propertyId: number;
	totalSqFt: number;
};
