import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { formatDate } from 'format';
import { useIsMultifamily } from 'hooks/useIsMultifamily';
import { PropertyFilters } from 'models/filters/property';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { QUERY_KEYS } from '../constants';

export const useNewCompsQuery = (
	params: UseNewCompsParams,
	options?: UseQueryOptions<UseNewCompsResponse>
) => {
	const isMufa = useIsMultifamily({
		markets: getFiltersMarkets(params.filters),
	});

	return useQuery({
		queryKey: [QUERY_KEYS.NEW_COMPS, params],
		queryFn: async () => {
			const response = await API.post<UseNewCompsResponse>(
				`/api${isMufa ? '/mufa' : ''}/properties/actions/newComps`,
				{
					filter: filtersToServerJSON(params.filters, true),
					newFrom: formatDate(params.newFrom, 'YYYY-MM-DD'),
				}
			);
			return response.data;
		},
		...options,
	});
};

type UseNewCompsParams = {
	filters: PropertyFilters;
	newFrom: Date;
};

type UseNewCompsResponse = {
	leases: {
		total: number;
	};
	sales: {
		total: number;
	};
	possiblyHasMore: boolean;
};
