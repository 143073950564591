import { PropertyWithSummary } from 'PortfolioAnalytics/hooks/usePropertiesWithSummaries';
import { Link } from 'router';
import styled from 'styled-components';
import { PROPERTY_LIST_ITEM_HEIGHT } from './constants';

type Props = {
	property: PropertyWithSummary;
};

export const PropertyListItem = ({ property }: Props) => {
	return (
		<Link to={`/property/${property.id}`}>
			<Container>
				<Title>{property.buildingAddressAndCity}</Title>
				<Info>
					{property.activeLeaseCount ?? 0}{' '}
					{property.activeLeaseCount === 1 ? 'Active Lease' : 'Active Leases'}
				</Info>
			</Container>
		</Link>
	);
};

export const Container = styled.div`
	position: relative;
	font-size: 16px;
	height: ${PROPERTY_LIST_ITEM_HEIGHT}px;
	padding: 0.875rem 1.25rem;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n30};
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n30};
	}
`;

export const Title = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1rem;
	font-weight: 300;
	color: ${(p) => p.theme.colors.gray.gray700};
	margin-bottom: 0.25rem;
`;

export const Info = styled.div`
	font-size: 0.75rem;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.neutral.n80};
`;
