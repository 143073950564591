import React, { memo } from 'react';
import { nullValueText } from '@compstak/common';
import { numberUtils, DataProviderLogoBase } from '@compstak/ui-kit';
import { Chart, InsightData } from 'Pages/Analytics/analytics';
import { usePermissions } from 'Pages/Login/reducers';
import dayjs from 'dayjs';

import { getColorForDataSetIndex } from '../colors';
import {
	TableContainer,
	Table,
	THead,
	TR,
	TRGroupHeader,
	TH,
	TD,
	TDGroupHeader,
	TDFirstColumn,
	TDLastColumn,
	TDGroupHeaderContainer,
	ProviderLogoContainer,
	TBody,
	DSStatsContainer,
	DSTitle,
	DSStatsItem,
} from './Components';
import { groupDataSets } from './Sidebar';
import {
	dataSetGroupHeadingMap,
	dataSetTypeToFormattingAttr,
} from '../chartBuilderConstants';
import { DataSetType, InsightDataResponse } from '../../analytics';
import {
	getDatasetStats,
	getStatsCounter,
	findDSIndexInDataArray,
	CounterType,
} from './helpers';
import { AttributeType } from 'util/comp-format/attributes';
import { formatValue } from 'util/comp-format/src/format';
import { CompKeys } from 'types';

type TableV2Props = {
	chartDraft: Chart;
	insightsData: InsightDataResponse[];
};

export const TableV2 = memo<TableV2Props>(({ chartDraft, insightsData }) => {
	const { leaseDataSets, salesDataSets, multifamilyDataSets } = groupDataSets(
		chartDraft.dataSets,
		usePermissions()
	);

	const timespan = chartDraft.timespan;

	const availableDataSetGroups = [
		leaseDataSets,
		salesDataSets,
		multifamilyDataSets,
	].filter(
		(dataSetGroup) => dataSetGroup.filter((dataSet) => dataSet.isVisible).length
	);

	const yearsInRange = createArrOfYearsInRange(timespan);

	const tHeadCells = (
		<>
			<TH />
			{yearsInRange.map((year) => (
				<TH key={year}>{year}</TH>
			))}
			<TH>{`${yearsInRange.length}Y CAGR`}</TH>
		</>
	);

	const tableRows = availableDataSetGroups.map((dsGroup) => {
		const groupHeaderCellsNumber = 1 + yearsInRange.length + 1;

		const groupHeaderRow = (
			<TRGroupHeader contentCellsNumber={yearsInRange.length + 1}>
				{Array.from({ length: groupHeaderCellsNumber }, (_, index) => {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					if (index === timespanToYearAlignIndex[timespan]) {
						return (
							<TDGroupHeader key={index}>
								<TDGroupHeaderContainer>
									{dataSetGroupHeadingMap[dsGroup[0].type || '']}
									{dsGroup[0].type === DataSetType.MUFA && (
										<ProviderLogoContainer>
											<DataProviderLogoBase />
										</ProviderLogoContainer>
									)}
								</TDGroupHeaderContainer>
							</TDGroupHeader>
						);
					}
					return <td key={index} />;
				})}
			</TRGroupHeader>
		);

		const groupDataRows = dsGroup
			.filter((dataSet) => dataSet.isVisible)
			.map((ds) => {
				const indexOfDSInDataArr = findDSIndexInDataArray(
					chartDraft.dataSets,
					ds
				);

				const relevantToDSItemInData = insightsData[indexOfDSInDataArr];
				const { isLoading, isSuccess } = relevantToDSItemInData;

				const dataSetIndex = chartDraft.dataSets.findIndex(
					(dataSet) => dataSet.id === ds.id
				);

				const colorFromDatasetIndex = getColorForDataSetIndex(dataSetIndex);

				const stats = getDatasetStats({
					insightsDataItem: insightsData[indexOfDSInDataArr],
					timespan,
				});

				const renderContentCells = ({
					data,
					numberOfYears,
					attribute,
					dsType,
				}: {
					data: InsightData['trendLine'];
					numberOfYears: number;
					dsType: DataSetType;
					attribute: CompKeys;
				}) => {
					/** Each cell by design shows data of the 1st of Jan */
					const firstOfJanData = data?.filter(({ date }) => {
						return dayjs(date).month() === 0 && dayjs(date).date() === 1;
					});

					return Array.from({ length: numberOfYears }, (_, i) => {
						const value =
							firstOfJanData[firstOfJanData.length - (numberOfYears - i)]
								?.value;

						const cellContent = value
							? formatLabel(
									attribute,
									value,
									dataSetTypeToFormattingAttr[dsType]
							  )
							: nullValueText;

						return <TD key={`${value}${i}`}>{cellContent}</TD>;
					});
				};

				return (
					<TR
						key={ds.id}
						contentCellsNumber={yearsInRange.length + 1}
						hasBottomShadow={true}
					>
						<TDFirstColumn sideShadowColor={colorFromDatasetIndex}>
							<DSTitle>{ds?.name}</DSTitle>
							<DSStatsContainer>
								<DSStatsItem>
									{`${dataSetTypeToStatObject[ds?.type || '']}: `}
									{getStatsCounter({
										counterType: CounterType.COMPS,
										stats,
										dsType: ds.type,
										isLoading,
										isSuccess,
									})}
								</DSStatsItem>
								<DSStatsItem>{`SQFT: ${getStatsCounter({
									counterType: CounterType.SQFT,
									stats,
									dsType: ds.type,
									isLoading,
									isSuccess,
								})}`}</DSStatsItem>
							</DSStatsContainer>
						</TDFirstColumn>
						{renderContentCells({
							data: relevantToDSItemInData.data?.trendLine || [],
							numberOfYears: yearsInRange.length,
							attribute: ds.series as CompKeys,
							dsType: ds.type,
						})}
						<TDLastColumn sideShadowColor={colorFromDatasetIndex}>
							{calcCAGR(relevantToDSItemInData, timespan)}
						</TDLastColumn>
					</TR>
				);
			});

		return [groupHeaderRow, ...groupDataRows];
	});

	return (
		<TableContainer>
			{/* @ts-expect-error TS2769: No overload matches this call.... */}
			<Table numberOfYears={yearsInRange.length}>
				<THead>
					<TR contentCellsNumber={yearsInRange.length + 1}>{tHeadCells}</TR>
				</THead>
				<TBody>{tableRows}</TBody>
			</Table>
		</TableContainer>
	);
});

TableV2.displayName = 'TableV2';

const createArrOfYearsInRange = (timeSpan = 0) => {
	const yearsNumber = timeSpan / 12;
	const firstYearOfRange = new Date().getFullYear() - (yearsNumber - 1);

	return Array.from({ length: yearsNumber }, (_, i) => firstYearOfRange + i);
};

const calcCAGR = (
	dataItem: { data: InsightData },
	timeSpan: number | undefined
) => {
	const summary = dataItem.data?.summaries.find((s) => s.months === timeSpan);

	if (summary && summary.annualizedGrowth !== 0) {
		return numberUtils.formatPercent(
			summary.annualizedGrowth,
			'percentOneDecimal'
		);
	}

	return nullValueText;
};

const dataSetTypeToStatObject = {
	[DataSetType.COMMERCIAL]: 'leases', // Until BE's switch to "commercialLeases"
	[DataSetType.LEASES]: 'leases',
	[DataSetType.SALES]: 'sales',
	[DataSetType.MUFA]: 'properties',
};

const timespanToYearAlignIndex = {
	'12': 1,
	'36': 1,
	'60': 2,
	'120': 4,
	'180': 7,
};

const formatLabel = (
	attribute: CompKeys,
	value: string | number,
	type: AttributeType
) => {
	const label = formatValue(attribute, value, false, type, true);

	return `${label}`
		.replace('.00', '')
		.replace(/(\(Annual\))|(\(Monthly\))/, '')
		.trim();
};
