import React from 'react';

import { getActualLabel } from '../util';
import { Container, Content, Header, Buttons } from './Components';
import FloorSelector from './FloorSelector';

import styles from './drawer.less';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

type OwnState = any;

type State = OwnState & typeof EditSpaceDrawer.defaultProps;

export default class EditSpaceDrawer extends React.PureComponent<{}, State> {
	static defaultProps = {
		space: {},
	};

	constructor(props: {}) {
		super(props);

		this.state = {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
			...this.props.space,
			userSetSpaceSize: false,
		};
	}

	save = () => {
		const space = {
			id: this.state.id,
			size: this.state.size,
			tenant: this.state.tenant,
			occupancy: this.state.occupancy ? this.state.occupancy : 'Tenant',
			expirationDate: this.state.expirationDate,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
			placed: this.props.space.placed,
			floor: this.state.floor,
		};
		if (this.state.id) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.updateSpace(
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				this.props.stackingPlan,
				space
			);
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.addSpace(this.props.stackingPlan, space);
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'closeDrawer' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.closeDrawer();
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	update = (event) => {
		let { value, name } = event.target;

		let setValue = true;

		if (name === 'size' && value) {
			if (parseInt(value)) {
				if (parseInt(value) > 0) {
					value = parseInt(value);
					this.setState({
						userSetSpaceSize: true,
					});
				} else {
					setValue = false;
				}
			} else {
				value = '';
			}
		}

		if (setValue) {
			this.setState({
				[name]: value,
			});
		}

		if (name === 'occupancy' && value.toLowerCase() === 'vacant') {
			this.setState({
				tenant: value,
				occupancy: value,
			});
		} else if (name === 'occupancy' && value.toLowerCase() === 'unrentable') {
			this.setState({
				tenant: value,
				occupancy: value,
				expirationDate: undefined,
			});
		} else if (name === 'occupancy' && value.toLowerCase() === 'tenant') {
			this.setState({
				tenant: '',
			});
		}

		let monthDay;

		if (name === 'quarter') {
			if (Number(value)) {
				if (this.state.expirationDate) {
					if (dayjs(this.state.expirationDate).toISOString()) {
						this.setState({
							expirationDate: dayjs(this.state.expirationDate)
								.quarter(value)
								.toISOString(),
						});
					}
				} else if (Number(this.state.year)) {
					monthDay = this.quarterToMonthDayString(value);

					this.setState({
						expirationDate: this.state.year + monthDay,
					});
				}
			} else {
				this.setState({
					expirationDate: null,
				});
			}
		} else if (name === 'year') {
			if (Number(value)) {
				if (this.state.expirationDate) {
					if (dayjs(this.state.expirationDate).toISOString()) {
						this.setState({
							expirationDate: dayjs(this.state.expirationDate)
								.year(value)
								.toISOString(),
						});
					}
				} else if (Number(this.state.quarter)) {
					monthDay = this.quarterToMonthDayString(this.state.quarter);

					this.setState({
						expirationDate: value + monthDay,
					});
				}
			} else {
				this.setState({
					expirationDate: null,
				});
			}
		}
	};

	// @ts-expect-error TS7006: Parameter 'label' implicitly h...
	updateFloorLabel = (label) => {
		this.setState({
			floor: label,
		});

		if (!this.state.userSetSpaceSize && this.state.id === undefined) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			const spaceOccupied = this.props.stackingPlan.spaces.reduce((acc, s) => {
				if (getActualLabel(s.floor) === getActualLabel(label) && s.placed) {
					return acc + s.size;
				}
				return acc;
			}, 0);

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			const totalSpace = this.props.stackingPlan.floors.find(
				// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
				(f) => getActualLabel(f.label) === getActualLabel(label)
			);

			if (totalSpace && totalSpace.size > spaceOccupied) {
				this.setState({
					size: totalSpace.size - spaceOccupied,
				});
			}
		}
	};

	// @ts-expect-error TS7006: Parameter 'quarter' implicitly...
	quarterToMonthDayString(quarter) {
		if (Number(quarter)) {
			switch (quarter) {
				case '1':
					return '-01-01';
				case '2':
					return '-04-01';
				case '3':
					return '-07-01';
				case '4':
					return '-10-01';
			}
		}
	}

	getQuarter() {
		if (this.state.expirationDate) {
			return dayjs(this.state.expirationDate).quarter();
		}
		return 'Quarter';
	}

	getYear() {
		if (this.state.expirationDate) {
			return dayjs(this.state.expirationDate).year();
		}
		return 'Year';
	}

	shouldDisableInput() {
		if (!this.state.occupancy) {
			return false;
		} else if (this.state.occupancy.toLowerCase() === 'vacant') {
			return true;
		}
		return false;
	}

	renderDateInput() {
		const yearsIntoFuture = 100;
		const currentYear = new Date().getFullYear();
		const years = [];
		for (let i = currentYear; i < currentYear + yearsIntoFuture; i++) {

			years.push(i);
		}

		return (
			<div className={styles.selectContainer}>
				<select
					className={styles.selectInput}
					name="quarter"
					onChange={this.update}
					defaultValue={this.getQuarter()}
				>
					<option value="Quarter">Quarter</option>
					<option value="1">Q1</option>
					<option value="2">Q2</option>
					<option value="3">Q3</option>
					<option value="4">Q4</option>
				</select>
				<select
					className={styles.selectInput}
					name="year"
					onChange={this.update}
					defaultValue={this.getYear()}
				>
					<option value="Year">Year</option>
					{years.map((yearX, i) => (
						<option key={i} value={yearX}>
							{yearX}
						</option>
					))}
				</select>
			</div>
		);
	}

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const label = this.props.space.id ? 'Edit' : 'Add';

		let tenantLabelStyle;
		let tenantLabelText = 'Tenant Name';
		let expirationLabelStyle;

		if (this.state.occupancy) {
			switch (this.state.occupancy.toLowerCase()) {
				case 'unrentable':
					tenantLabelText = 'Unrentable Space';
					expirationLabelStyle = styles.hiddenInput;
					break;
				case 'vacant':
					tenantLabelStyle = styles.hiddenInput;
					break;
				case 'tenant':
					break;
			}
		}

		return (
			<Container>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'closeDrawer' does not exist on type 'Rea... Remove this comment to see the full error message */}
				<Header onCancel={this.props.closeDrawer}>{label} Space</Header>
				{/* @ts-expect-error ts-migrate(2769) FIXME: Property 'onSave' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
				<Content onSave={this.save} onCancel={this.props.closeDrawer}>
					<label>
						Occupant
						<select
							className={styles.selectInput}
							name="occupancy"
							onChange={this.update}
							value={this.state.occupancy}
						>
							<option value="Tenant">Tenant</option>
							<option value="Vacant">Vacant</option>
							<option value="Unrentable">Unrentable</option>
						</select>
					</label>
					<label className={tenantLabelStyle}>
						{tenantLabelText}
						<input
							onChange={this.update}
							type="text"
							name="tenant"
							value={this.state.tenant}
							disabled={this.shouldDisableInput()}
						/>
					</label>

					<FloorSelector
						{...this.props}
						// @ts-expect-error ts-migrate(2322) FIXME: Property 'updateFloorLabel' does not exist on type... Remove this comment to see the full error message
						updateFloorLabel={this.updateFloorLabel}
					/>

					<label className={styles.squareFeetLabel}>
						Space Size
						<input
							onChange={this.update}
							type="text"
							name="size"
							value={this.state.size}
						/>
					</label>
					<label className={expirationLabelStyle}>
						Expiration Date
						{this.renderDateInput()}
					</label>
					{/* @ts-expect-error ts-migrate(2741) FIXME: Property 'disabled' is missing in type '{ onCancel... Remove this comment to see the full error message */}
					<Buttons onCancel={this.props.closeDrawer} onSave={this.save} />
				</Content>
			</Container>
		);
	}
}
