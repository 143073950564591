import { Portfolio } from 'api';
import { PortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import dayjs from 'dayjs';
import { LeaseFilters } from 'models/filters/lease';

export const getFiltersFromPortfolioLeasesFilters = ({
	activeLeasesOnly,
	filterBy,
	portfolio,
}: PortfolioLeasesFilters & { portfolio: Portfolio }) => {
	const filters: Partial<LeaseFilters> = {
		portfolioId: portfolio.id,
		partial: 'false',
		sortField: 'executionQuarter',
		sortDirection: 'desc',
	};
	if (activeLeasesOnly) {
		filters.expirationDate = {
			min: new Date(),
			max: null,
		};
	}
	if (filterBy) {
		const criterion = filterBy.criterion;
		const duration = filterBy.duration.value;
		const unit = filterBy.duration.unit;
		switch (criterion) {
			case 'newOnCompstak': {
				filters.dateCreated = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
			case 'recentlyUpdated': {
				filters.lastAttributeUpdate = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
		}
	}

	return filters;
};
