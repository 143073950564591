import { Spinner } from '@compstak/ui-kit';
import * as Tabs from '@radix-ui/react-tabs';
import { useCompsetData } from 'api';
import { useState } from 'react';
import { routes } from 'router';
import { NestedTab, TabContainer, TabsList } from '../UI';
import { Compset } from './Compset';
import { LeasesInCompset } from './LeasesInCompset';
import { LeasesInCompsetTableDataProvider } from './LeasesInCompsetTableDataProvider';

export const CompsetTab = () => {
	const [tab, setTab] = useState(CompsetTabEnum.COMPSET);
	const params = routes.propertyById.useParams();

	const { isFetching } = useCompsetData({ propertyId: params.propertyId });

	return (
		<TabContainer>
			{isFetching ? (
				<Spinner size="xl" isCentered />
			) : (
				<Tabs.Root
					value={tab}
					onValueChange={(value) => setTab(value as CompsetTabEnum)}
				>
					<TabsList>
						<NestedTab value={CompsetTabEnum.COMPSET}>
							Competitive Set
						</NestedTab>
						<NestedTab value={CompsetTabEnum.LEASES_COMPSET}>
							Leases in Competitive Set
						</NestedTab>
					</TabsList>
					<Tabs.Content value={CompsetTabEnum.COMPSET}>
						<Compset />
					</Tabs.Content>
					<Tabs.Content value={CompsetTabEnum.LEASES_COMPSET}>
						<LeasesInCompsetTableDataProvider propertyId={params.propertyId}>
							<LeasesInCompset />
						</LeasesInCompsetTableDataProvider>
					</Tabs.Content>
				</Tabs.Root>
			)}
		</TabContainer>
	);
};

enum CompsetTabEnum {
	COMPSET = 'COMPSET',
	LEASES_COMPSET = 'LEASES_COMPSET',
}
