import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'totalUnits' as const;

export const TotalUnitsFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Between monthly={false} thousandSeparator={false} {...props} />
			)}
		</FilterBase>
	);
};
