import { LeaseExpirationSubmarket } from 'ExchangeDashboard/LeaseExpirationSubmarket';
import { memo, useCallback, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useExportPdfState } from '../ExportPdfProvider';
import { PDFPanel } from '../UI';

export const LeaseExpirationBySubmarketEdit = memo(() => {
	const ref = useRef(null);
	const { registerImage } = useExportPdfState();

	const [debounced] = useDebouncedCallback(() => {
		if (ref.current) {
			registerImage('leaseExpirationSubmarket', ref.current);
		}
	}, 1000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onChartRender = useCallback(debounced, [ref, registerImage]);
	// onChartRender is memoized and debounced to prevent unnecessary creation of new image data

	return (
		<PDFPanel id="leaseExpirationBySubmarket" title="">
			<div ref={ref}>
				<MemoizedLeaseExpirationBySubmarket onChartRender={onChartRender} />
			</div>
		</PDFPanel>
	);
});

LeaseExpirationBySubmarketEdit.displayName =
	'LeaseExpirationByPropertyTypeEdit';

const MemoizedLeaseExpirationBySubmarket = memo(
	(props: Parameters<typeof LeaseExpirationSubmarket>[0]) => {
		return <LeaseExpirationSubmarket {...props} />;
	}
);

MemoizedLeaseExpirationBySubmarket.displayName =
	'MemoizedLeaseExpirationPropertyType';
