import { ReactNode } from 'react';
import { AnalyticsChip } from 'Components';
import { ExportLegendContainer, ExportLegendMarketName } from './UI';
import {
	MapAnalyticsInfo,
	MapAnalyticsInfoTitle,
	MapAnalyticsMenuContent,
	MapAnalyticsMenuHeader,
} from '../UI';
import { useFilters } from 'reducers/filtersReducer';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';

type MapExportProps = {
	title: string;
	description: JSX.Element;
	children: ReactNode;
};
export const MapExportLegend = ({
	title,
	description,
	children,
}: MapExportProps) => {
	const [filters] = useFilters();
	const market = getFiltersMarkets(filters)[0];

	return (
		<ExportLegendContainer>
			<MapAnalyticsMenuHeader>
				<AnalyticsChip />
			</MapAnalyticsMenuHeader>
			<MapAnalyticsInfo>
				<MapAnalyticsInfoTitle>{title}</MapAnalyticsInfoTitle>
				{description}
				<ExportLegendMarketName>{market.displayName}</ExportLegendMarketName>
			</MapAnalyticsInfo>
			<MapAnalyticsMenuContent>{children}</MapAnalyticsMenuContent>
		</ExportLegendContainer>
	);
};
