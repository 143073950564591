import {
	CompsetResponse,
	MufaCompsetResponse,
	useMufaCompsetQuery,
	usePropertyCompsetQuery,
} from 'api';
import { CompsetType, useCompsetType } from '../utils/useCompsetType';

type Props = {
	propertyId: number | undefined;
};

export const useCompsetData = ({ propertyId }: Props) => {
	const { data: compsetType } = useCompsetType({ propertyId });

	const propertyResponse = usePropertyCompsetQuery(
		{
			id: propertyId,
		},
		{
			enabled: compsetType === CompsetType.COMMERCIAL,
		}
	);

	const mufaPropertyResponse = useMufaCompsetQuery(
		{ propertyId },
		{ enabled: compsetType === CompsetType.REALPAGE }
	);

	if (compsetType === CompsetType.REALPAGE) return mufaPropertyResponse;
	return propertyResponse;
};

// To safely get the compset array from response
// (because compset responses come in different shapes)
export const getCompset = (
	propertyResponse: CompsetResponse | MufaCompsetResponse
) => {
	if (isMufaResponse(propertyResponse)) {
		return propertyResponse.compSet;
	} else {
		return propertyResponse.propertyCompSet;
	}
};

const isMufaResponse = (
	propertyResponse: CompsetResponse | MufaCompsetResponse
): propertyResponse is MufaCompsetResponse => {
	return propertyResponse.hasOwnProperty('compSet');
};
