import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';

const attribute = 'propertyMarketEffectiveRent' as const;

export const PropertyMarketEffectiveRentFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Between monthly={shouldUseMonthlyMetrics(props.filters)} {...props} />
			)}
		</FilterBase>
	);
};
