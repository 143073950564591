import {
	Portfolio,
	UpdatePortfolioParams,
	useUpdatePortfolioMutation,
} from 'api';
import { PortfolioDialog } from '../Components';

type Props = {
	portfolio: Portfolio;
};

export const UpdatePortfolioDialog = ({ portfolio }: Props) => {
	const { mutateAsync: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation();

	return (
		<PortfolioDialog
			title="Name & Description"
			isLoadingMutation={isLoadingUpdatePortfolio}
			onSubmit={async (values) => {
				const body: UpdatePortfolioParams['body'] = {
					id: portfolio.id,
					name: values.name,
					description: undefined,
				};
				if (values.description.length > 0) {
					body.description = values.description;
				}
				await updatePortfolio({ id: portfolio.id, body });
			}}
			initialFormState={{
				name: portfolio.name,
				description: portfolio.description ?? '',
			}}
			editedPortfolio={portfolio}
		/>
	);
};
