import { useMemo } from 'react';
import { getIsLeaseCompActive } from 'utils/getIsLeaseCompActive';
import { LeaseComp } from 'types';
import { PropertyLease } from 'api';

type Props<T extends PropertyLease | LeaseComp> = {
	leases: T[];
};

export const usePropertyLeasesActive = <T extends PropertyLease | LeaseComp>({
	leases,
}: Props<T>) => {
	const leasesActive = useMemo(
		() => leases.filter((lease) => getIsLeaseCompActive(lease)),
		[leases]
	);

	return leasesActive;
};
