import * as Popover from '@radix-ui/react-popover';
import {
	HeaderDropdownButton,
	IconHeaderDropdown,
} from 'Components/AppHeader/UI';
import { useState } from 'react';
import styled from 'styled-components';
import { CompType } from 'types';
import { MyCompstakMenu } from './MyCompstakMenu';

type MyCompstakMenuButtonProps = {
	compType: CompType;
	isActive?: boolean;
};

export const MyCompstakMenuButton = ({
	compType,
	isActive,
}: MyCompstakMenuButtonProps) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
			<Popover.Trigger asChild>
				<HeaderDropdownButton
					onClick={() => setIsMenuOpen(true)}
					active={isActive}
				>
					My Compstak <IconHeaderDropdown width={11} height={8} />
				</HeaderDropdownButton>
			</Popover.Trigger>
			<Popover.Portal>
				<Content>
					<MyCompstakMenu
						closeMenu={() => setIsMenuOpen(false)}
						compType={compType}
					/>
					<Popover.Arrow />
				</Content>
			</Popover.Portal>
		</Popover.Root>
	);
};

const Content = styled(Popover.Content)`
	// TODO: z-index is identical to modal overlay @see packages/apps/src/Components/Modals/common/UI.tsx until the AP-16395 is finished
	z-index: ${({ theme }) => theme.zIndex.overlay + theme.zIndex.modal};
`;
