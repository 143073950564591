import styled from 'styled-components';
import IconArrowBottom from '../../ui/svg_icons/arrow_bottom.svg';
import { useState } from 'react';
import { UserMenu } from './UserMenu';
import { User } from '@compstak/common';
import * as Popover from '@radix-ui/react-popover';
import { MQB } from '@compstak/ui-kit';

export const UserMenuButton = ({ user }: { user: User }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
			<Popover.Trigger asChild>
				<Button onClick={() => setIsMenuOpen(!isMenuOpen)}>
					<Avatar>
						<div>
							{user.firstName[0]}
							{user.lastName[0]}
						</div>
					</Avatar>
					<StyledIconArrowBottom width={11} height={8} />
				</Button>
			</Popover.Trigger>
			<Popover.Portal>
				<Content align="end" alignOffset={10}>
					<UserMenu setIsMenuOpen={setIsMenuOpen} />
					<Popover.Arrow />
				</Content>
			</Popover.Portal>
		</Popover.Root>
	);
};

const Content = styled(Popover.Content)`
	// TODO: z-index is identical to modal overlay @see packages/apps/src/Components/Modals/common/UI.tsx until the AP-16395 is finished
	z-index: ${({ theme }) => theme.zIndex.overlay + theme.zIndex.modal};
`;

const StyledIconArrowBottom = styled(IconArrowBottom)`
	fill: ${(p) => p.theme.colors.neutral.n90};
`;

const Button = styled.button`
	padding: 0 0.625rem;
	align-self: stretch;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n600};
		${StyledIconArrowBottom} {
			fill: ${(p) => p.theme.colors.white.white};
		}
	}
`;

const Avatar = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background-color: ${(p) => p.theme.colors.gray.gray500};
	color: ${(p) => p.theme.colors.white.white};
	font-size: 0.625rem;
	letter-spacing: 0.1em;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: ${MQB.T_834}px) {
		width: 40px;
		height: 40px;
	}
`;
