import { useMapRef } from '@compstak/maps';
import {
	Feature,
	FeatureCollection,
	featureCollection,
	lineString,
	Polygon,
	polygon as turfPolygon,
	Properties,
} from '@turf/helpers';
import lineToPolygon from '@turf/line-to-polygon';
import { MAP_CONTROLS } from 'actions/search';
import React from 'react';
import { useFilters } from 'reducers/filtersReducer';

type Props = {
	activeMapControl: MAP_CONTROLS | null;
};

const initialPolygon: FeatureCollection<Polygon> = {
	type: 'FeatureCollection',
	features: [],
};

/**
 * Returns a GeoJSON Polygon FeatureCollection to be consumed by the deck.gl layers,
 * and for the pin selection.
 * It's derived from redux polygon filter (object we send to BE) which is treated
 * as a single source of truth.
 * If redux filter is not present, return the default polygon.
 */
export const usePolygonFromFilter = ({ activeMapControl }: Props) => {
	const [filters, setFilters] = useFilters();
	const prevActiveMapControlRef = React.useRef<MAP_CONTROLS | null>(null);
	const shouldInit = React.useRef(true);
	const { map } = useMapRef();

	const polygon = React.useMemo(() => {
		if (!map || activeMapControl !== MAP_CONTROLS.POLYGON) {
			prevActiveMapControlRef.current = activeMapControl;
			shouldInit.current = true;
			return initialPolygon;
		}
		if (
			filters.polygon == null ||
			prevActiveMapControlRef.current === MAP_CONTROLS.SEARCH_WITHIN_VIEW
		) {
			prevActiveMapControlRef.current = activeMapControl;
			const { x, y } = map.project(map.getCenter());
			const width = 300;

			const points = [
				[x - width / 2, y + width / 2],
				[x - width / 2, y - width / 2],
				[x + width / 2, y - width / 2],
				[x + width / 2, y + width / 2],
				[x - width / 2, y + width / 2],
			].map(([lon, lat]) => {
				const lngLat = map.unproject([lon, lat]);
				return [lngLat.lng, lngLat.lat];
			});
			const line = lineString(points);
			const _polygon = lineToPolygon(line) as Feature<Polygon, Properties>;
			return featureCollection([_polygon]);
		} else {
			return featureCollection([
				turfPolygon([filters.polygon.map((coord) => [coord.lng, coord.lat])], {
					name: 'poly1',
				}),
			]);
		}
	}, [filters.polygon, map, activeMapControl]);

	// handle ONLY the initial request
	React.useEffect(() => {
		if (
			Array.isArray(polygon?.features) &&
			polygon.features.length > 0 &&
			activeMapControl === MAP_CONTROLS.POLYGON &&
			shouldInit.current
		) {
			shouldInit.current = false;
			setFilters({
				polygon: createPolygonFilterFromPolygon(polygon),
			});
		}
	}, [polygon, activeMapControl, setFilters]);

	return polygon;
};

const createPolygonFilterFromPolygon = (
	polygon: FeatureCollection<Polygon, Properties>
) => {
	return polygon.features.flatMap((f) =>
		f.geometry
			? f.geometry.coordinates[0].map(
					([lng, lat]: number[]): {
						lat: number;
						lng: number;
					} => ({
						lat,
						lng,
					})
			  )
			: []
	);
};
