import { API } from '@compstak/common';
import {
	SubmissionFileDetails,
	SubmissionInfo,
} from '../Pages/Settings/Components/Credits/submissionTypes';

export async function getSubmissions() {
	const res = await API.get<SubmissionInfo[]>('/api/submissions');
	if (res.status >= 400) {
		throw Error('Failed to retrive submissions');
	}
	const { data } = res;
	if (!Array.isArray(data)) {
		throw Error('Submission data is not an array');
	}
	return data;
}

export async function getSubmissionDetailsById(submissionId: number) {
	const res = await API.get<SubmissionFileDetails[]>(
		`/api/submissions/${submissionId}`
	);
	if (res.status >= 400) {
		throw Error('Failed to retrive submission');
	}
	const { data } = res;
	if (!Array.isArray(data)) {
		throw Error('Submission details data is not an array');
	}
	return data;
}
