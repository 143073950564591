import { useFeatureFlags } from 'hooks/useFeatureFlags';
import {
	AddToPortfolioButton,
	CompPopupActions,
	CompPopupActionsV2,
	CompPopupRoot,
	PropertyDetails,
	PropertyPageLink,
	PropertyPopupHeader,
} from 'maps';
import { PropertyPageLinkV2 } from 'maps/components/Popups/PropertyPageLinkV2';
import { PropertyComp } from 'types/comp';
import { RemovePropertyPopupButton } from './RemovePropertyPopupButton';
import { Portfolio } from 'api';

type PortfolioPopupProps = {
	closePopup: () => void;
	isMinified?: boolean;
	property: PropertyComp;
	portfolio: Portfolio;
};

export const PortfolioPopup = ({
	isMinified,
	closePopup,
	property,
	portfolio,
}: PortfolioPopupProps) => {
	const { portfolioRevampFF } = useFeatureFlags();

	return (
		<CompPopupRoot {...(isMinified && { width: 'auto' })}>
			<PropertyPopupHeader property={property} closePopup={closePopup} />
			{!isMinified && <PropertyDetails property={property} />}
			{!portfolioRevampFF && (
				<CompPopupActions>
					<PropertyPageLink propertyId={property.id} className="single-child">
						View Property Report
					</PropertyPageLink>
					<AddToPortfolioButton property={property} />
				</CompPopupActions>
			)}
			{portfolioRevampFF && (
				<CompPopupActionsV2>
					<PropertyPageLinkV2 propertyId={property.id}>
						Detailed Property Info
					</PropertyPageLinkV2>
					<RemovePropertyPopupButton
						portfolio={portfolio}
						property={property}
					/>
				</CompPopupActionsV2>
			)}
		</CompPopupRoot>
	);
};
