import React, { CSSProperties, memo, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {
	ErrorUI,
	HelpCircle,
	VerticalBarChart,
	numberUtils,
	calendarUtils,
	Pending,
	Period,
	PeriodSelector,
	SaveDataMenu,
	TickFormatter,
	Tooltip,
	WidgetContainer,
	RowFlexContainer,
	WidgetTitle,
	HeightContainer,
	getDownloadFileName,
	Select,
	defaultTheme,
	CustomLegend,
} from '@compstak/ui-kit';
import {
	calcXTicks,
	calcYTicks,
	calcDomainFromTicks,
	getXTickDateFormatter,
	years,
} from '../../../../Pages/PropertyPageV2_1/utils';
import { adaptData } from './adaptData';
import {
	ChartTypes,
	FiltersNames,
	Metric,
	MetricListItem,
	MufaChartsNames,
} from 'types/propertyTrendCharts';
import { MufaSubmarketChartProps } from 'Components/MufaSubmarket/MufaSubmarketSection';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';

type Props = MufaSubmarketChartProps &
	Pick<ReturnType<typeof useMufaTrendsData>['data'], 'submarketSupply'>;

export const SubmarketSupply = memo<Props>(
	({
		address,
		filters,
		submarketSupply,
		handleFilterChange,
		showAnimation = true,
		showSaveDataMenu = true,
		shouldShowTooltip = true,
	}) => {
		const period =
			filters[MufaChartsNames.SubmarketSupply][FiltersNames.PERIOD];
		const metric =
			filters[MufaChartsNames.SubmarketSupply][FiltersNames.METRIC];

		const { data, isLoading, isSuccess, isError, error } = submarketSupply;

		const chartId = `${chartTitle}${address}`;

		const chartData = useMemo(() => adaptData(data), [data]);
		const xTicks = useMemo(
			() => calcXTicks(chartData, period),
			[chartData, period]
		);

		const yTicks = useMemo(
			() => calcYTicks(ChartTypes['BAR_CHART'], chartData),
			[chartData]
		);

		const yAxisLabel = useMemo(
			() => selectItems.find(({ value }) => value === metric)?.title,
			[metric]
		);

		const handleMetricChange = useCallback(
			// @ts-expect-error TS7006: Parameter 'args' implicitly ha...
			(args) => {
				handleFilterChange(
					MufaChartsNames.SubmarketSupply,
					FiltersNames.METRIC,
					args.selectedItem.value
				);
			},
			[handleFilterChange]
		);

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) =>
				handleFilterChange(
					MufaChartsNames.SubmarketSupply,
					FiltersNames.PERIOD,
					selectedPeriod
				),
			[handleFilterChange]
		);

		return (
			<WidgetContainer data-qa-id={chartId} marginBottom={0}>
				<InnerContainer>
					<RowFlexContainer>
						<WidgetTitle data-qa-id={widgetTitleTestId}>
							{chartTitle}
						</WidgetTitle>
						{shouldShowTooltip && (
							<Tooltip
								tooltipComponent={<>{metricToTooltipText[metric]}</>}
								placement="bottom-end"
								tooltipRootStyle={tooltipRootStyle}
								tooltipStyle={tooltipStyle}
							>
								<HelpCircle style={iconStyle} />
							</Tooltip>
						)}
						<Select<MetricListItem>
							items={selectItems}
							value={metric}
							onChange={handleMetricChange}
							data-qa-id="select"
						/>
						<PeriodSelector
							period={period}
							onChange={handlePeriodChange}
							years={years}
							data-qa-id="supply-period-selector"
						/>
					</RowFlexContainer>

					{showSaveDataMenu && isSuccess && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, chartTitle)}
							elementId={chartId}
							dataToCopy={chartData}
							headersMapping={headersMapping}
						/>
					)}
				</InnerContainer>

				<HeightContainer margin={HEIGHT_CONTAINER_MARGIN}>
					{isLoading && <Pending times={4} />}

					{isSuccess && (
						<VerticalBarChart
							chartId={chartId}
							data={chartData}
							bars={barsData}
							yAxisLabel={yAxisLabel}
							xTickFormatter={getXTickDateFormatter(period)}
							yTickFormatter={yTickFormatter}
							xInterval={0}
							xTicks={xTicks}
							yTicks={yTicks}
							yDomain={calcDomainFromTicks(yTicks)}
							tooltipFormatter={tooltipFormatter}
							tooltipTitleFormatter={getTooltipTitleFormatter(
								data?.supplyHistogram.type
							)}
							hasDataForSomePeriod={!!data?.suggestedSpan}
							isAnimationActive={showAnimation}
						/>
					)}

					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<CustomLegend legendItems={barsData} />
			</WidgetContainer>
		);
	}
);
SubmarketSupply.displayName = 'SubmarketSupply';
export const widgetTitleTestId = 'widgetTitleTestId';

const chartTitle = 'Supply & Construction';

const selectItems: Array<MetricListItem> = [
	{ title: 'Total Units', value: 'totalUnits' },
	{ title: 'New Supply', value: 'newSupply' },
	{ title: 'Units Under Construction', value: 'unitsUnderConstruction' },
];

const headersMapping = {
	name: 'Date',
	supplyValue: 'Supply Value',
};

export const barsData = [
	{
		color: defaultTheme.colors.blue.blue700,
		value: 'supplyValue',
		title: 'Submarket',
		isIconHidden: false,
		type: 'monotone',
	},
];

const yTickFormatter: TickFormatter = (value) => {
	const roundedValue = Math.round(Number(value) / 100) * 100;

	return roundedValue >= 1000
		? numberUtils.formatNumber(roundedValue, 'oneDecimal', 'financial')
		: numberUtils.formatNumber(roundedValue, 'integer', 'financial');
};

const tooltipFormatter: TickFormatter = (value) =>
	numberUtils.formatNumber(value, 'integer');

const getTooltipTitleFormatter =
	// @ts-expect-error TS7006: Parameter 'value' implicitly h...
	(granularity: TimeGranularity | undefined) => (value) => {
		if (granularity === 'quarterly') {
			const year = dayjs(value).year();
			const month = dayjs(value).month();
			return calendarUtils.getQuarter(year, month, 'shortQuarter1');
		}

		return `EOY ${calendarUtils.getDate(value, 'shortYear')}`;
	};

type SupplyMetric = Extract<
	Metric,
	'totalUnits' | 'newSupply' | 'unitsUnderConstruction'
>;

const metricToTooltipText: Record<SupplyMetric, string> = {
	newSupply: `The number of new units completed in a quarter. An apartment unit is counted 
		as new supply once construction of the unit completes and the unit becomes available 
		for physical occupancy. A property is considered complete when the last unit in the 
		project has completed.`,
	totalUnits: `Existing units are estimated starting with 2010 Census counts of multifamily properties 
		featuring five or more units. Estimates are adjusted historically to reflect the number 
		of conventional apartment units identified by RealPage Analytics, less non-conventional 
		rental units captured by the Census.`,
	unitsUnderConstruction:
		'The number of units under construction at the end of a quarter.',
};

const InnerContainer = styled(RowFlexContainer)`
	margin-bottom: 16px;
`;

const tooltipRootStyle: CSSProperties = {
	margin: '0 16px 0 8px',
};

const tooltipStyle: CSSProperties = {
	top: '-5px',
};

const iconStyle: CSSProperties = {
	transform: 'translateX(-5px)',
};

const HEIGHT_CONTAINER_MARGIN = { bottom: 24 };
