import { TooltipV2 } from '@compstak/ui-kit';
import { usePropertyByIdQuery } from 'api';
import { routes } from 'router';

export const MarketLabel = () => {
	const params = routes.propertyById.useParams();
	const { data: property } = usePropertyByIdQuery({ id: params.propertyId });

	return (
		<TooltipV2 content={property?.marketDisplayName}>
			<span>Market</span>
		</TooltipV2>
	);
};
