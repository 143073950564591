import { TooltipV2 } from '@compstak/ui-kit';
import { useUnlockConditions } from 'api';
import { ReactNode } from 'react';

type Props = {
	children: JSX.Element;
	comps: { cost: number }[];
};

export const UnlockButtonTooltip = ({ comps, children }: Props) => {
	let content: ReactNode = '';

	const { hasEnoughPoints, isUserFetching, totalCost, canUnlockForFree } =
		useUnlockConditions({ comps });

	if (comps.length > 0 && !isUserFetching) {
		if (canUnlockForFree) {
			content = 'Unlock for free.';
		} else if (hasEnoughPoints) {
			content = `Unlock for ${totalCost} credits.`;
		} else {
			content = 'Get more credits to unlock these comps.';
		}
	}

	return <TooltipV2 content={content}>{children}</TooltipV2>;
};
