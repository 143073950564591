import { CenterOnBounds, MAPBOX_MAX_ZOOM } from '@compstak/maps';
import { RadiusFilter } from 'models/filters/types';
import { useMemo } from 'react';
import { createRadiusFeatureFromRadiusFilter } from '../RadiusFilter/createRadiusFeatureFromRadiusFilter';
import { usePrevious } from 'util/hooks';

type CenterOnRadiusFilterEffectProps = {
	radiusFilter: RadiusFilter | null;
};

export const CenterOnRadiusFilterEffect = ({
	radiusFilter,
}: CenterOnRadiusFilterEffectProps) => {
	const prevRadiusFilter = usePrevious(radiusFilter);

	const radiusFeature = useMemo(() => {
		// Do not center on the radius filter geometry when it is initialized for the first time
		// Otherwise there is a radius filter layer rendering delay
		if (!radiusFilter || !prevRadiusFilter) {
			return;
		}

		return createRadiusFeatureFromRadiusFilter(radiusFilter);
	}, [radiusFilter]);

	return (
		<CenterOnBounds
			geoJSON={radiusFeature}
			options={{ maxZoom: MAPBOX_MAX_ZOOM }}
		/>
	);
};
