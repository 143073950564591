import constate from 'constate';
import { LeaseComp } from 'types';
import { useSelectionState } from 'utils/useSelectionState';
import { getFiltersFromPortfolioLeasesFilters } from './utils';
import { useCallback, useEffect, useMemo } from 'react';
import {
	SEARCH_MAX_LIMIT,
	SearchLeasesResponse,
	useSearchFilterParams,
	useSearchLeasesInfiniteQuery,
	useSearchLeasesQuery,
} from 'api';
import { useSearchTableRows } from 'Pages/Search/useSearchTableRows';
import { usePortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioFilters';
import { PORTFOLIO_LEASES_COLUMNS } from './usePortfolioLeasesColumns';

export const [PortfolioLeasesProvider, usePortfolioLeases] = constate(() => {
	const { filters: portfolioFilters } = usePortfolioFilters();
	const { portfolio, filters: portfolioLeasesFilters } =
		usePortfolioLeasesFilters();

	const filters = useMemo(() => {
		return mergeFilters(
			getFiltersFromPortfolioFilters({ ...portfolioFilters, portfolio }),
			getFiltersFromPortfolioLeasesFilters({
				...portfolioLeasesFilters,
				portfolio,
			})
		);
	}, [portfolio, portfolioFilters, portfolioLeasesFilters]);
	const filterParams = useSearchFilterParams({ filters });
	const params = {
		...filterParams,
		properties: PORTFOLIO_LEASES_COLUMNS.map(({ id }) => id as keyof LeaseComp),
	};

	const infiniteLeasesQuery = useSearchLeasesInfiniteQuery(params);
	const noLimitLeasesQuery = useSearchLeasesQuery(
		{ ...params, offset: 0, limit: SEARCH_MAX_LIMIT },
		{ enabled: false }
	);

	const infiniteRows = useSearchTableRows<LeaseComp>({
		data: infiniteLeasesQuery.data,
		getRows: useCallback((page: SearchLeasesResponse) => page.comps, []),
		isFetching: infiniteLeasesQuery.isFetchingNextPage,
	});
	const rows = noLimitLeasesQuery.data?.comps ?? infiniteRows;

	const _selectionState = useSelectionState(rows);
	const selectionState = {
		..._selectionState,
		toggleAllSelected: () => {
			// fetch all leases if we haven't yet and there is more to load
			if (!noLimitLeasesQuery.data && infiniteLeasesQuery.hasNextPage) {
				noLimitLeasesQuery.refetch();
			} else {
				_selectionState.toggleAllSelected();
			}
		},
	};

	useEffect(() => {
		if (noLimitLeasesQuery.data?.comps) {
			selectionState.toggleAllSelected();
		}
	}, [noLimitLeasesQuery.data]);

	useEffect(() => {
		selectionState.resetSelection();
	}, [filters]);

	const isFetching =
		infiniteLeasesQuery.isFetching || noLimitLeasesQuery.isFetching;
	const isError = infiniteLeasesQuery.isError || noLimitLeasesQuery.isError;
	const isFetchingNextPage = noLimitLeasesQuery.data
		? false
		: infiniteLeasesQuery.isFetchingNextPage;
	const hasNextPage = noLimitLeasesQuery.data
		? false
		: infiniteLeasesQuery.hasNextPage;
	const fetchNextPage = infiniteLeasesQuery.fetchNextPage;

	return {
		data: rows,
		isFetchingNextPage,
		isError,
		isFetching,
		isFetchingAll: noLimitLeasesQuery.isFetching,
		hasNextPage,
		fetchNextPage,
		selectionState,
	};
});
