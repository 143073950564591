import { PolygonsLayer, PopupPositioning } from '@compstak/maps';
import { CountyPolygon } from 'api/ppm-property/countyPolygons/useCountyPolygonByFIPSQuery';
import {
	MAP_FILTER_RED_COLOR,
	MAP_FILTER_RED_HALF_OPAQUE_COLOR,
} from 'constants/colors';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';

type CountiesLayerProps = {
	countyFeatures: CountyPolygon[];
};

const ID = 'CountiesLayer';

export const CountiesLayer = ({ countyFeatures }: CountiesLayerProps) => {
	return (
		<PolygonsLayer
			id={ID}
			data={countyFeatures}
			getFillColor={() => MAP_FILTER_RED_HALF_OPAQUE_COLOR}
			getLineColor={MAP_FILTER_RED_COLOR}
			highlightColor={MAP_FILTER_RED_HALF_OPAQUE_COLOR as number[]}
			getTooltipContent={(f) => f.properties.name}
			tooltipPositioning={PopupPositioning.CENTER_POLYGON}
			getLineWidth={4}
			tooltipAnchor="bottom-left"
			getDashArray={[5, 2]}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
