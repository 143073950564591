import { numberUtils, Dash } from '@compstak/ui-kit';
import styled from 'styled-components';
import { FiltersObject } from '../../models/filters/types';
import { filtersToQueryString } from '../../models/filters/util';

export const NumberCell = ({
	value,
	filters,
}: {
	value?: number | string;
	filters?: Partial<FiltersObject>;
}) => {
	if (value) {
		value = numberUtils.formatNumber(value, 'integer');

		return filters ? (
			<a href={`/search/sales/list?${filtersToQueryString(filters)}`}>
				{value}
			</a>
		) : (
			<span>{value}</span>
		);
	}
	return <Dash />;
};

export const RankCell = styled.span`
	font-weight: 500;
`;

export const NameCell = styled.span`
	display: flex;
	align-items: center;
	gap: 1em;

	span {
		font-weight: 400;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;
