import { scaleLinear } from 'd3-scale';
import { CSSProperties } from 'react';
import { useMeasure } from 'react-use';

type Props = {
	minX: number;
	maxX: number;
	minY: number;
	maxY: number;
};

export const useChart = ({ minX, maxX, minY, maxY }: Props) => {
	const [refChart, chartSize] = useMeasure<HTMLDivElement>();

	const xScale = scaleLinear([minX, maxX], [0, 100]);
	const yScale = scaleLinear([minY, maxY], [0, 100]);
	const xInverseScale = xScale.invert;

	const getXTickStyle = (tick: number): CSSProperties => ({
		position: 'absolute',
		top: 0,
		left: `${xScale(tick)}%`,
	});

	const getYTickStyle = (tick: number): CSSProperties => ({
		position: 'absolute',
		left: 0,
		top: `${yScale(tick)}%`,
	});

	const getLinePath = <Item,>({
		data,
		x,
		y,
	}: {
		data: Item[];
		x: (d: Item, i: number) => number;
		y: (d: Item, i: number) => number;
	}) => {
		return `M${data.map((d, i) => `${xScale(x(d, i))},${yScale(y(d, i))}`).join('L')}`;
	};

	const xPxToPercentage = (px: number) =>
		chartSize.width === 0 ? 0 : (px / chartSize.width) * 100;

	return {
		xScale,
		xInverseScale,
		yScale,
		getXTickStyle,
		getYTickStyle,
		getLinePath,
		refChart,
		chartSize,
		xPxToPercentage,
	};
};
