import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { usePermissions, useUser } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import { QUERY_KEYS } from '../constants';
import { SpaceTypeName } from '../referenceData';

export type PremierListSpaceType = Extract<
	SpaceTypeName,
	'Office' | 'Industrial'
>;

export const usePremierListCleanSectorsQuery = (
	options?: UseClearSectorOptions
) => {
	const user = useUser();
	const markets = useMarkets();
	const permissions = usePermissions();

	return useQuery({
		queryKey: [QUERY_KEYS.PREMIER_LIST_CLEAN_SECTORS],
		queryFn: async () => {
			let dataArray = [] as CleanSectorsResponse;

			if (user.userType === 'admin') {
				dataArray = markets.list.map((market) => ({
					marketId: market.id,
					assetTypes: ['Office', 'Industrial'] as PremierListSpaceType[],
				}));
			} else {
				const response = await API.get<CleanSectorsResponse>(
					'/api/markets/cleanSectors'
				);

				dataArray = response.data;
			}

			dataArray = dataArray.filter(({ marketId }) => {
				return (
					permissions[marketId].leaseAccess && permissions[marketId].premierList
				);
			});

			const dataMap = dataArray.reduce<Record<string, PremierListSpaceType[]>>(
				(acc, sector) => {
					acc[markets[sector.marketId].name] = sector.assetTypes;
					return acc;
				},
				{}
			);

			return { array: dataArray, map: dataMap };
		},
		...options,
	});
};

type UseClearSectorOptions = UseQueryOptions<CleanSectorsProcessedResponse>;

export type CleanSector = {
	marketId: number;
	assetTypes: PremierListSpaceType[];
};

export type CleanSectorsResponse = CleanSector[];

export type CleanSectorsProcessedResponse = {
	array: CleanSector[];
	map: Record<string, PremierListSpaceType[]>;
};
