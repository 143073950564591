import { colors } from '@compstak/ui-kit';
import styled from 'styled-components';

export const KvTableStylingWrapper = styled.div`
	position: relative;
	z-index: 0;

	td {
		padding: 8px 0;
		height: auto;
		padding-right: 0 !important;

		div {
			font-size: 12px;
			line-height: 16px;
		}

		div:first-child {
			color: ${colors.neutral.n85};
			text-align: left;
		}

		div:last-child {
			color: ${colors.gray.gray700};
			font-weight: 400;
			text-align: right;
		}
	}
`;
KvTableStylingWrapper.displayName = 'KvTableStylingWrapper';
