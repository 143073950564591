import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { StringTypeahead } from 'Components/Filters/Base/StringTypeahead';
import React from 'react';

const attribute = 'managementCompany' as const;

export const ManagementCompanyFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<StringTypeahead typeaheadAttribute="managementCompany" {...props} />
			)}
		</FilterBase>
	);
};
