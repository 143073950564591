import { bucket } from '../bucket';
import { getAxisId, transformValueForInput } from '../util';

import { Shape } from 'Pages/Analytics/analytics';
import { Market } from '@compstak/common';
import { attributesHashFromShape } from 'Pages/Analytics/Builder/chartBuilderHelpers';

const NO_NAME = '\u00A0'; // non breaking space

export default function generateHistograms(
	// @ts-expect-error TS7006: Parameter 'timespan' implicitl...
	timespan,
	shapes: Shape[],
	// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
	data,
	graphWidth: number,
	minBarSize: number,
	market: Market
) {
	// get histograms
	// @ts-expect-error TS7006: Parameter 'd' implicitly has a...
	let histograms = data.map((d, i) => {
		const shape = shapes[i];
		const canBeMonthly =
			// @ts-expect-error TS7015: Element implicitly has an 'any...
			attributesHashFromShape(shape)[shape.y]?.canBeMonthly ?? false;
		const isMonthly = canBeMonthly && market.monthly;
		// @ts-expect-error TS7006: Parameter 's' implicitly has a...
		const summary = d.data.summaries.find((s) => s.months === timespan);
		let histogram = summary ? summary.histogram : [];
		if (!histogram) {
			console.error('Falsy histogram?!', { data: d, shape });
			histogram = [];
		}
		// @ts-expect-error TS7006: Parameter 'item' implicitly ha...
		histogram = histogram.map((item) => [
			transformValueForInput(isMonthly, shape.y, item[0]),
			item[1],
		]);
		return histogram;
	});

	// trim them of outliers
	// @ts-expect-error TS7006: Parameter 'histogram' implicit...
	histograms = histograms.map((histogram) => {
		if (histogram.length < 15) {
			return histogram;
		}
		// @ts-expect-error TS7006: Parameter 'a' implicitly has a...
		const totalTransactionSize = histogram.reduce((a, row) => a + row[1], 0);
		const trimAmount = totalTransactionSize * 0.025;
		const amountToRetain = totalTransactionSize - trimAmount;

		let amountSoFar = 0;
		// @ts-expect-error TS7006: Parameter 'row' implicitly has...
		histogram = histogram.filter((row) => {
			amountSoFar += row[1];
			if (amountSoFar < trimAmount || amountSoFar > amountToRetain) {
				return false;
			}
			return true;
		});
		return histogram;
	});

	// get high, get low
	const extrema = shapes.reduce((acc, shape) => {
		const axis = getAxisId(shape.y);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (!acc[axis]) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			acc[axis] = {
				low: Infinity,
				high: -Infinity,
			};
		}
		return acc;
	}, {});

	// @ts-expect-error TS7006: Parameter 'histogram' implicit...
	histograms.forEach((histogram, i) => {
		if (histogram.length) {
			const axis = getAxisId(shapes[i].y);
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			const { low, high } = extrema[axis];
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			extrema[axis].low = Math.min(low, histogram[0][0]);
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			extrema[axis].high = Math.max(high, histogram[histogram.length - 1][0]);
		}
	});

	// find buckets
	const bucketCount = graphWidth / histograms.length / minBarSize;
	const buckets = {};
	let highestNumberOfBuckets = 0;
	shapes.forEach((shape) => {
		const axis = getAxisId(shape.y);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		let { low, high } = extrema[axis];
		if (low === Infinity) {
			low = 0;
			high = 100;
		}
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (!buckets[axis]) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			buckets[axis] = bucket(low, high, bucketCount);
			highestNumberOfBuckets = Math.max(
				highestNumberOfBuckets,
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				buckets[axis].buckets.length
			);
		}
	});
	shapes.forEach((shape) => {
		const axis = getAxisId(shape.y);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		while (buckets[axis].buckets.length < highestNumberOfBuckets) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			const last = buckets[axis].buckets[buckets[axis].buckets.length - 1];
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			buckets[axis].buckets.push(last + buckets[axis].interval);
		}
	});

	// @ts-expect-error TS7006: Parameter 'histogram' implicit...
	const series = histograms.map((histogram, i) => {
		const axis = getAxisId(shapes[i].y);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		const seriesBuckets = buckets[axis];
		// @ts-expect-error TS7006: Parameter 'b' implicitly has a...
		const bucketCounts = seriesBuckets.buckets.map((b) => [b, 0]);
		// @ts-expect-error TS7006: Parameter 'histogramEntry' imp...
		histogram.forEach((histogramEntry) => {
			const index = seriesBuckets.buckets.findIndex(
				// @ts-expect-error TS7006: Parameter 'b' implicitly has a...
				(b) => histogramEntry[0] <= b + seriesBuckets.interval
			);
			bucketCounts[index][1] += histogramEntry[1];
		});

		return {
			type: 'column',
			data: bucketCounts,
			name: shapes[i].name || NO_NAME,
			color: shapes[i].color,
			animation: false,
			groupPadding: 0,
			yAxis: 'histogram', //i,
			xAxis: axis,
		};
	});

	return series;
}
