import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';

import {
	Flex,
	Button,
	useBoolean,
	InfoCircle,
	defaultTheme,
	Notification,
	NotificationType,
} from '@compstak/ui-kit';
import { useUser } from 'Pages/Login/reducers';
import { useSetBannerCloseTimestamp } from 'Pages/Analytics/api/user/setDontShowAgainWindows';

interface InfoBannerProps {
	description: string;
	onClose: NoArgCallback;
	showCheckbox?: boolean;
	updateUser?: NoArgCallback;
}

export const InfoBanner: FC<InfoBannerProps> = memo(
	({ description, showCheckbox = true, onClose, updateUser }) => {
		const { id: userId } = useUser();
		const [
			isBannerNotShownAgain,
			,
			,
			toggleIsBannerNotShownAgain,
		] = useBoolean();
		// @ts-expect-error TS2345: Argument of type 'NoArgCallbac...
		const setBannerCloseTimestamp = useSetBannerCloseTimestamp(updateUser);
		const onCloseHandler = useCallback(() => {
			if (isBannerNotShownAgain)
				setBannerCloseTimestamp.mutate({ userId, time: Date.now() });
			onClose();
		}, [isBannerNotShownAgain, userId, onClose, setBannerCloseTimestamp]);

		const NotificationBody = (
			<Flex direction="column">
				<Flex alignItems="flex-start">
					<StyledInfoCircle />
					<Description>{description}</Description>
				</Flex>
				<ControlsWrapper>
					<Flex alignItems="center">
						{showCheckbox && (
							<>
								<input
									type="checkbox"
									className="checkbox"
									id="do-not-show-banner-again"
									name="do-not-show-banner-again"
									checked={isBannerNotShownAgain}
									onClick={toggleIsBannerNotShownAgain}
									data-qa-id="analytics-infobanner-checkbox"
								/>
								<Label htmlFor="do-not-show-banner-again">
									Do Not Show Again
								</Label>
							</>
						)}
					</Flex>
					<StyledButton
						variant="ghost"
						onClick={onCloseHandler}
						data-qa-id="analytics-infobanner-close-button"
					>
						Close
					</StyledButton>
				</ControlsWrapper>
			</Flex>
		);

		return (
			<NotificationWrapper>
				<Notification
					isOpen
					style={notificationStyles}
					type={NotificationType.GRAY}
					notificationCustomBody={NotificationBody}
				/>
			</NotificationWrapper>
		);
	}
);

InfoBanner.displayName = 'InfoBanner';

const NOTIFICATION_WIDTH = 472;
const SIDEBAR_WIDTH = 300;

const notificationStyles = {
	width: NOTIFICATION_WIDTH,
	maxHeight: 'inherit',
	height: 'auto',
	margin: '60px 0 0 0',
	padding: '15px 15px 5px',
	borderRadius: '3px',
};

const NotificationWrapper = styled.div`
	position: absolute;
	z-index: ${defaultTheme.zIndex.notification};
	margin: 0 calc((100vw - ${NOTIFICATION_WIDTH - SIDEBAR_WIDTH}px) / 2);
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-size: 10px;
	line-height: 10px;
`;

const StyledInfoCircle = styled(InfoCircle)`
	svg {
		color: white;
	}
`;

const ControlsWrapper = styled(Flex)`
	width: 100%;
	margin-top: 5px;
`;

const StyledButton = styled(Button)`
	color: white;
`;

const Description = styled.p`
	margin-left: 8px;
`;
