import { useMemo } from 'react';
import { ComponentPreviewType } from './UI';
import { HALF_SECTIONS, HalfSectionKey } from './constants';

const GROUP_SIZE = 2;

type Props = {
	selectedComponents: ComponentPreviewType[];
};

export const useHalfPanelGroups = ({ selectedComponents }: Props) => {
	return useMemo(() => {
		const selectedHalfPanels = HALF_SECTIONS.filter((halfPanel) =>
			selectedComponents.includes(halfPanel)
		);

		const groups: HalfSectionKey[][] = [];

		const len = selectedHalfPanels.length;

		for (let i = 0; i < len; i++) {
			const name = selectedHalfPanels[i];

			if (i % GROUP_SIZE === 0) {
				groups.push([name]);
			} else {
				const lastGroup = groups[groups.length - 1];
				lastGroup.push(name);
			}
		}

		console.assert(
			groups.every((group) => group.length > 0 && group.length <= GROUP_SIZE),
			{
				error: `Expected all groups to have max length ${GROUP_SIZE}`,
				groups,
			}
		);

		return groups;
	}, [selectedComponents]);
};
