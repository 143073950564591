import constate from 'constate';
import { useState } from 'react';
import { ComponentPreviewType } from './UI';
import { usePermissions } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';
import { UserEdits } from './DashboardExportModal';
import { formatDate } from 'format';

const currentDate = formatDate(new Date(), 'MMMM DD, YYYY');

export const [DashboardExportProvider, useDashboardExportContext] = constate(
	() => {
		const [filters] = useFilters('exchange-dashboard');
		const permissions = usePermissions();
		const salesAccess = permissions?.[filters.market.id]?.salesAccess;

		const [step, setStep] = useState<'stepOne' | 'stepTwo'>('stepOne');
		const [selectedComponents, setSelectedComponents] = useState<
			ComponentPreviewType[]
		>([]);

		const [userEdits, setUserEdits] = useState<UserEdits>({
			preparedFor: '',
			notes: '',
			createdOn: currentDate,
		});

		return {
			step,
			setStep,
			selectedComponents,
			setSelectedComponents,
			salesAccess,
			userEdits,
			setUserEdits,
		};
	}
);
