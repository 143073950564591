import {
	PolygonsLayer,
	PopupPositioning,
	TRANSPARENT_RGBA,
} from '@compstak/maps';
import {
	SUBMARKET_FILTER_FILL_COLOR,
	SUBMARKET_FILTER_LINE_COLOR,
} from 'constants/colors';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';

type SubmarketsLayerProps = {
	submarketFeatures: SubmarketPolygon[];
};

const ID = 'SubmarketsLayer';

export const SubmarketsLayer = ({
	submarketFeatures,
}: SubmarketsLayerProps) => {
	const [filters, setFilters] = useFilters();

	const selectedSubmarkets = useMemo(
		() => filters.submarkets ?? [],
		[filters.submarkets]
	);

	return (
		<PolygonsLayer
			id={ID}
			data={submarketFeatures}
			getFillColor={(f: SubmarketPolygon) => {
				const isSelected = selectedSubmarkets.some(
					({ id }) => id === f.properties.id
				);
				return isSelected ? SUBMARKET_FILTER_FILL_COLOR : TRANSPARENT_RGBA;
			}}
			getLineColor={SUBMARKET_FILTER_LINE_COLOR}
			highlightColor={SUBMARKET_FILTER_FILL_COLOR as number[]}
			tooltipPositioning={PopupPositioning.CENTER_POLYGON}
			getDashArray={[5, 2]}
			getTooltipContent={(f) => f.properties.name}
			onClick={({ object }) => {
				if (!object) return;

				const newSelectedSubmarkets = selectedSubmarkets.some(
					({ id }) => id === object.properties.id
				)
					? selectedSubmarkets.filter(({ id }) => {
							return id !== object.properties.id;
					  })
					: [...selectedSubmarkets, object.properties];

				setFilters({
					submarkets: newSelectedSubmarkets,
				});
			}}
			updateTriggers={{
				getFillColor: [selectedSubmarkets],
			}}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
