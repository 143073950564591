import {
	CirclesLayer,
	CirclesLayerProps,
	LayerColor,
	linearScale,
} from '@compstak/maps';
import { point } from '@turf/helpers';
import { TenantIndustryDataPoint, TenantIndustryResponse } from 'api';
import {
	TenantIndustryColor,
	TenantIndustryColorKey,
	TENANT_INDUSTRY_COLORS,
} from 'constants/colors';
import { maxBy, minBy } from 'lodash';
import { CIRCLE_MARKET_MULTIPLIER_MAP, useTenantIndustryState } from 'maps';
import { useMemo } from 'react';
import { TenantIndustryTooltip } from './TenantIndustryTooltip';

type Props = {
	data?: TenantIndustryResponse;
	marketName: string;
	circlesLayerProps?: Partial<CirclesLayerProps<TenantIndustryDataPoint>>;
	id?: string;
	order?: number;
};

export const TenantIndustryLayer = ({
	data,
	marketName,
	circlesLayerProps,
	id,
	order,
}: Props) => {
	const [state] = useTenantIndustryState();

	// @ts-expect-error TS7053: Element implicitly has an 'any...
	const multiplier = CIRCLE_MARKET_MULTIPLIER_MAP[marketName] ?? 30;

	const points = useMemo(() => {
		if (!data) return [];
		return data.map((p) => point([p.longitude, p.latitude], p));
	}, [data]);

	const pointCountDomain = useMemo(() => {
		const min = minBy(points, (p) => p.properties.sqftOrLeaseCount);
		const max = maxBy(points, (p) => p.properties.sqftOrLeaseCount);
		if (!min || !max) {
			return { min: 0, max: 0 };
		}
		return {
			min: min.properties.sqftOrLeaseCount,
			max: max.properties.sqftOrLeaseCount,
		};
	}, [points]);

	return (
		<CirclesLayer
			id={id}
			data={points}
			getFillColor={(f) => {
				const tenantIndustryColor: TenantIndustryColor | undefined =
					TENANT_INDUSTRY_COLORS[
						f.properties.industry as TenantIndustryColorKey
					];
				return [...(tenantIndustryColor ?? TENANT_INDUSTRY_COLORS.Other), 183];
			}}
			getRadius={(f) => {
				let area = MAX_AREA;

				if (points.length > 1) {
					area = linearScale({
						value: f.properties.sqftOrLeaseCount,
						domainMin: pointCountDomain.min,
						domainMax: pointCountDomain.max,
						rangeMin: MIN_AREA * multiplier,
						rangeMax: MAX_AREA * multiplier,
					});
				}
				return Math.sqrt(area / Math.PI);
			}}
			radiusMinPixels={1.5}
			radiusMaxPixels={50}
			radiusUnits="meters"
			getLineColor={TENANT_INDUSTRY_ANALYTIC_LINE_COLOR}
			lineWidthMinPixels={1}
			lineWidthMaxPixels={1}
			getLineWidth={1}
			getTooltipContent={(f) => {
				return <TenantIndustryTooltip feature={f} metric={state.metric} />;
			}}
			popupAnchor="bottom"
			opacity={1}
			billboard
			filled
			order={order}
			{...circlesLayerProps}
		/>
	);
};

const TENANT_INDUSTRY_ANALYTIC_LINE_COLOR: LayerColor = [255, 255, 255];

const MIN_AREA = 1000;
const MAX_AREA = 50000;
