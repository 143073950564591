import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

export const LeaseExpirationSlider = withStyles({
	mark: {
		backgroundColor: '#000000',
		height: 10,
		width: 1,
		marginTop: -4,
	},
	track: {
		backgroundColor: '#000000',
		height: 1,
	},
	rail: {
		backgroundColor: '#000000',
		height: 1,
		opacity: 1,
	},
	thumb: {
		backgroundColor: '#438ff7',
		border: '2px solid white',
		'&:focus,&:hover,&:active': {
			boxShadow: 'none',
		},
	},
	valueLabel: {
		width: 'fit-content',
		padding: '0 6px',
		left: 'calc(-50% - 64.5px)',
		color: '#000000',
		'&:after': {
			content: '""',
			width: 0,
			height: 0,
			display: 'inline-block',
			borderTop: '6px solid #000',
			borderLeft: '6px solid transparent',
			borderRight: '6px solid transparent',
			transform: 'translate(60px, -6px)',
		},
		'& > span': {
			width: 117,
			height: 32,
			display: 'flex',
			textAlign: 'center',
			transform: 'none',
			borderRadius: 3,
			fontSize: 10,
			fontFamily: '"Gotham", "Helvetica", "Arial", sans-serif',
			'& > span': {
				transform: 'none',
			},
		},
	},
})(Slider);
