import constate from 'constate';
import { useRef, useState } from 'react';
import { PropertyPageTab } from '../types';

export const [PropertyPageTabProvider, useTabContext] = constate(() => {
	const [tab, setTab] = useState<PropertyPageTab>(PropertyPageTab.COMPSET);
	const sectionRef = useRef<HTMLDivElement | null>(null);

	const scrollToTabs = () =>
		sectionRef?.current?.scrollIntoView({
			block: 'start',
			behavior: 'smooth',
		});

	return { tab, setTab, sectionRef, scrollToTabs };
});
