import { Select, SelectListItem } from '@compstak/ui-kit';
import { useMarketStatsFilters } from './MarketStatsFiltersProvider';

export const DurationFilter = () => {
	const [filters, setFilters] = useMarketStatsFilters();

	return (
		<Select
			items={OPTIONS}
			value={filters.duration}
			onChange={({ selectedItem }) => {
				if (selectedItem) {
					setFilters((s) => ({ ...s, duration: selectedItem.value }));
				}
			}}
		/>
	);
};

const OPTIONS = [
	{ title: '1 year', value: 12 },
	{ title: '3 years', value: 36 },
	{ title: '5 years', value: 60 },
	{ title: '10 years', value: 120 },
	{ title: '15 years', value: 180 },
] as const satisfies SelectListItem[];

export type DurationMonths = (typeof OPTIONS)[number]['value'];
