import { SimpleTable } from '@compstak/ui-kit';
import { PropertyLease } from 'api';
import { useNavigate } from 'router/migration';
import { routes } from 'router/routes';
import { useLeasesTableColumns } from '../useLeasesTableColumns';
import { useLeasesSelectionContext } from './LeasesSelectionProvider';
import { UseUnlockCompsProps } from 'Components/Unlock/useUnlockComps';
import { usePropertyPageDataContext } from '../PropertyPageDataProvider';

type Props = {
	leases: PropertyLease[];
} & Pick<UseUnlockCompsProps, 'onUnlockLeasesSuccess'>;

export const LeasesTable = ({ leases, onUnlockLeasesSuccess }: Props) => {
	const navigate = useNavigate();
	const selection = useLeasesSelectionContext();
	const columns = useLeasesTableColumns({
		selection,
		onUnlockLeasesSuccess,
	});

	const {
		compstakEstimates,
		isLeaseSnapshotShown,
		isMarketStatsCollapsed,
		isMarketStatsShown,
	} = usePropertyPageDataContext();

	const calculateBodyHeight = () => {
		if (!compstakEstimates.showCompstakEstimates && !isLeaseSnapshotShown)
			return DEFAULT_MAX_BODY_HEIGHT;

		return (
			(compstakEstimates.showCompstakEstimates ? 120 : 0) +
			(isLeaseSnapshotShown ? 980 : 0)
		);
	};

	const bodyHeight =
		!isMarketStatsShown || isMarketStatsCollapsed
			? DEFAULT_MAX_BODY_HEIGHT
			: calculateBodyHeight();

	return (
		<SimpleTable
			id="lease"
			rows={leases}
			columns={columns}
			isBodyHoverable
			scrollContainerProps={{
				style: {
					maxHeight: bodyHeight,
					textAlign: 'right',
				},
			}}
			renderBodyCellContent={({ columnRender }) => {
				return columnRender();
			}}
			getBodyCellProps={({ row }) => ({
				onClick: () => {
					navigate(routes.leaseById.toHref({ id: row.id }), {
						state: {
							fromNewPropertyPage: true,
						},
					});
				},
			})}
		/>
	);
};

const DEFAULT_MAX_BODY_HEIGHT = 586;
