import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';
import { useIsMultifamily } from 'hooks/useIsMultifamily';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { FiltersObject } from 'models/filters/types';
import { CompType } from 'types';
import {
	PropertySubtypeId,
	PropertyTypeId,
	SpaceSubtypeId,
	SpaceTypeId,
} from 'api/referenceData/useReferenceDataQuery';

export const useSpaceTypeAggregations = (
	params: UseSpaceTypeAggregationsParams,
	options?: UseQueryOptions<UseSpaceTypeAggregationsResponse>
) => {
	const isMufa =
		useIsMultifamily({
			markets: getFiltersMarkets(params.filters),
		}) && params.compType === 'property';

	return useQuery({
		queryKey: [QUERY_KEYS.SPACE_TYPES_AGGREGATIONS, params],
		queryFn: async () => {
			const response = await API.post<UseSpaceTypeAggregationsResponse>(
				`/api${isMufa ? '/mufa' : ''}/${
					params.compType === 'property'
						? 'properties'
						: params.compType === 'sale'
							? 'salesComps'
							: 'comps'
				}/search/spaceTypeFilterAggregations`,
				{
					filter: filtersToServerJSON(
						params.filters,
						params.compType === 'property'
					),
				}
			);
			return response.data;
		},
		keepPreviousData: true,
		...options,
	});
};

type UseSpaceTypeAggregationsParams = {
	compType: CompType;
	filters: FiltersObject;
};

type UseSpaceTypeAggregationsResponse = {
	spaceTypeId: { [key in SpaceTypeId]?: number };
	spaceSubtypeId: { [key in SpaceSubtypeId]?: number };
	buildingPropertyTypeId: { [key in PropertyTypeId]?: number };
	buildingPropertySubtype: { [key in PropertySubtypeId]?: number };
};
