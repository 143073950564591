import {
	MapAnalyticsMetricRadioGroup,
	DashboardSettingsForm,
	DashboardSettingsMenuContainer,
	MapAnalyticsMetric,
	useTenantIndustryState,
} from 'maps';

export const TenantIndustryMapSettingsMenu = () => {
	const [state, setState] = useTenantIndustryState();

	const onChangeMetric = (metric: MapAnalyticsMetric) => {
		setState((s) => ({ ...s, metric }));
	};

	return (
		<DashboardSettingsMenuContainer>
			<DashboardSettingsForm>
				<MapAnalyticsMetricRadioGroup
					id="dashboardTenantIndustry"
					metric={state.metric}
					onChangeMetric={onChangeMetric}
				/>
			</DashboardSettingsForm>
		</DashboardSettingsMenuContainer>
	);
};
