import { API } from '@compstak/common';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UnderlyingLeasesInfiniteParams } from './useUnderlyingLeasesInfiniteQuery';
import { UnderlyingLeaseResponse } from './types';

export const fetchUnderlyingLeases =
	(params: UnderlyingLeasesInfiniteParams) =>
	async ({
		signal,
	}: QueryFunctionContext): Promise<UnderlyingLeaseResponse> => {
		const res = await API.post<UnderlyingLeaseResponse>(
			'/api/comps/insights/trendlineUnderlying',
			params,
			{ signal }
		);
		return res.data;
	};
