import { Spinner } from '@compstak/ui-kit';
import { useMarketRentAnalyticsPropertiesQuery } from 'api';
import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import {
	CompPopupActionLink,
	CompPopupActions,
	CompPopupRoot,
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsViewMode,
	PopupCloseButton,
	PopupHeader,
	useMarketRentAnalyticsState,
} from 'maps';
import { createFilters } from 'models/filters';
import { filtersToQueryString } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { setFilters } from 'models/filters/util/setFilters';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import money from 'ui/util/money';

type Props = {
	marketRent: number;
	propertyCount: number;
	submarketName?: string;
	hexagonId?: string;
	closePopup: () => void;
	period: MarketRentAnalyticsPeriod;
};

export const MarketRentAnalyticsPopup = ({
	marketRent,
	propertyCount,
	hexagonId,
	submarketName,
	closePopup,
	period,
}: Props) => {
	const [filters] = useFilters();
	const [marketRentState] = useMarketRentAnalyticsState();

	const market = getFiltersMarkets(filters)[0];

	const { data: addresses, isFetching: isFetchingProperties } =
		useMarketRentAnalyticsPropertiesQuery({
			marketId: market.id,
			hexagonId: hexagonId ?? '',
		});

	const { data: submarketData, isFetching: isFetchingSubmarkets } =
		useSubmarketPolygons(market);

	const submarket = useMemo(() => {
		if (!submarketData?.features) return;
		return submarketData.features.find(
			(s) => s.properties.name === submarketName
		);
	}, [submarketData?.features, submarketName]);

	const leasesPath = useMemo(() => {
		let leaseFilters;

		if (marketRentState.viewMode === MarketRentAnalyticsViewMode.SUBMARKET) {
			if (!submarket) return;
			leaseFilters = setFilters(filters, 'submarkets', [
				{ id: submarket.properties.id, name: submarket.properties.name },
			]);
		} else {
			if (!addresses) return;
			leaseFilters = setFilters(filters, 'address', addresses);
		}

		return `/search/leases/list?${filtersToQueryString(leaseFilters)}`;
	}, [addresses, filters, submarket, marketRentState.viewMode]);

	const propertiesPath = useMemo(() => {
		let propertyFilters = createFilters('property', { market: filters.market });

		if (marketRentState.viewMode === MarketRentAnalyticsViewMode.SUBMARKET) {
			if (!submarket) return;
			propertyFilters = setFilters(propertyFilters, 'submarkets', [
				{ id: submarket.properties.id, name: submarket.properties.name },
			]);
		} else {
			if (!addresses) return;
			propertyFilters = setFilters(propertyFilters, 'address', addresses);
		}

		return `/search/properties/list?${filtersToQueryString(propertyFilters)}`;
	}, [addresses, filters, submarket, marketRentState.viewMode]);

	const isLoading =
		marketRentState.viewMode === MarketRentAnalyticsViewMode.SUBMARKET
			? isFetchingSubmarkets
			: isFetchingProperties;

	const value = moneyFormat(marketRent);
	const postfix =
		period === MarketRentAnalyticsPeriod.MONTH
			? MONTHLY_POSTFIX
			: YEARLY_POSTFIX;
	return (
		<CompPopupRoot>
			<PopupHeader>
				<div>
					<ValueTitle>AVG PPSF</ValueTitle>
					<div>
						<Value>{value}</Value>
						<ValuePostfix>{postfix}</ValuePostfix>
					</div>
				</div>
				<PopupCloseButton onClick={closePopup} />
			</PopupHeader>
			<CompPopupActions>
				{isLoading && (
					<SpinnerContainer>
						<Spinner />
					</SpinnerContainer>
				)}
				{leasesPath && (
					<CompPopupActionLink
						to={leasesPath}
						data-qa-id="market-rent-analytics-popup-refine-link"
						className="single-child"
					>
						Refine Leases ({propertyCount})
					</CompPopupActionLink>
				)}
				{propertiesPath && (
					<CompPopupActionLink
						to={propertiesPath}
						data-qa-id="market-rent-analytics-popup-property-link"
						className="single-child"
					>
						Refine properties {addresses ? `(${addresses.length})` : ''}
					</CompPopupActionLink>
				)}
			</CompPopupActions>
		</CompPopupRoot>
	);
};

export const moneyFormat = (value: number) => money(value.toFixed(2), 'USD');
export const MONTHLY_POSTFIX = '/SF/MO';
export const YEARLY_POSTFIX = '/SF/YR';

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 0.5rem 0;
`;

const ValueTitle = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 1px;
	color: ${({ theme }) => theme.colors.neutral.n50};
`;

const Value = styled.span`
	font-family: 'Roboto Mono', Roboto, 'Helvetica Neue', Helvetica, Arial,
		sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 1px;
	color: ${({ theme }) => theme.colors.neutral.n0};
`;

const ValuePostfix = styled.span`
	font-family: 'Roboto Mono', Roboto, 'Helvetica Neue', Helvetica, Arial,
		sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 1px;
	color: ${({ theme }) => theme.colors.neutral.n0};
`;
