import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'reducers/root';
import { hideMenu } from 'Singletons/Menu/actions';
import { AppDispatch } from 'store';
import { CompType } from '../../../../types/comp';
import { PageActions } from '../../actions';

type TableSettingsButtonProps = {
	actionsColumnWidth: number;
	actionsColumnWidthExpanded: number;
	freeComps: number;
	columnFreeCompSpacer: number;
	additionalStyle?: string;
	compType: CompType;
	pageActions: PageActions;
} & ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

export class TableSettingsButton extends React.Component<TableSettingsButtonProps> {
	static defaultProps = {
		menuOpen: false,
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	showTableSettingsMenu = (event) => {
		this.props.pageActions.showTableSettingsMenu(
			event.currentTarget,
			this.props.compType
		);
	};

	render() {
		const columnWidthSpacer =
			this.props.freeComps > 0 ? this.props.columnFreeCompSpacer : 0;

		const buttonStyle =
			this.props.actionsColumnWidth ===
			this.props.actionsColumnWidthExpanded + columnWidthSpacer
				? { width: 170 + columnWidthSpacer }
				: { width: 90 + columnWidthSpacer };

		if (!this.props.menuOpen) {
			return (
				<div
					style={buttonStyle}
					onClick={this.showTableSettingsMenu}
					className={
						'button_button button_light_gray table-settings safari-flexbox-bugged ' +
						this.props.additionalStyle
					}
				>
					Customize<span className="hide-small">&nbsp;Columns</span>
				</div>
			);
		} else {
			return (
				<div
					style={buttonStyle}
					onClick={this.props.closeMenu}
					className={
						'button_button button_light_gray table-settings safari-flexbox-bugged ' +
						this.props.additionalStyle
					}
				>
					Close
				</div>
			);
		}
	}
}

function mapStoreToProps(store: AppState) {
	// @ts-expect-error what
	return { menuOpen: store.menu.component === 'table-settings-menu' };
}

function mapDispatchToProps(dispatch: AppDispatch) {
	return {
		closeMenu: () => dispatch(hideMenu()),
	};
}

export default connect(
	mapStoreToProps,
	mapDispatchToProps
)(TableSettingsButton);
