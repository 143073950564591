// @ts-expect-error TS2307: Cannot find module 'MapAnalyti...
import { TenantIndustryState } from 'MapAnalytics/mapAnalyticsTypes';
import { useTenantIndustryState } from 'maps';
import { useDashboardTenantIndustryAnalyticsData } from '../useDashboardTenantIndustryAnalyticsData';
import { UseTenantIndustryDashboardQueryOptions } from 'api';

/**
 * Dashboard export uses redux data for generating a PDF,
 * so this is a temprorary adjustment until we eventually refactor
 * PDF generation code
 */
export const useAdaptedTenantIndustryExportData = (
	options?: UseTenantIndustryDashboardQueryOptions
) => {
	const [state] = useTenantIndustryState();

	const { data: _data, ...rest } =
		useDashboardTenantIndustryAnalyticsData(options);

	const data: Partial<TenantIndustryState> = {
		params: {
			metric: state.metric === 'sqft' ? 'squareFeet' : 'leaseCount',
		},
		results: _data,
	};

	return { type: 'tenantIndustryMap' as const, data, ...rest };
};
