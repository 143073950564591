import { marketIdToMarketNameAndRentPeriod } from '@compstak/common';
import { CommonMenu } from '@compstak/ui-kit';
import { RentPeriod } from './api';
import { allMenuItems } from './constants';
import { DisplaySectionsMap, PropertySections } from './type';

export const getRentPeriod = (id?: number | string): RentPeriod => {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	if (id && marketIdToMarketNameAndRentPeriod[id]) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		return marketIdToMarketNameAndRentPeriod[id].rentPeriod;
	}
	return RentPeriod.ANNUAL;
};

export const getMenuItemsFromCertainItems =
	(menuItems: Array<CommonMenu<PropertySections>>) =>
	(
		displaySectionMap: DisplaySectionsMap
	): Array<CommonMenu<PropertySections>> => {
		return menuItems.filter(filterMenuItems(displaySectionMap));
	};

export const getMenuItems = getMenuItemsFromCertainItems(allMenuItems);

export const filterMenuItems =
	(map: DisplaySectionsMap) =>
	// @ts-expect-error TS7031: Binding element 'url' implicit...
	({ url }) => {
		if (url === PropertySections.SUBMARKET) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			return map[url] || map['mufaSubmarket'];
		}
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		return map[url];
	};
