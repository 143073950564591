import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import styled from 'styled-components';
import { APNFilter } from './APNFilter/APNFilter';
import { CountyFilter } from './CountyFilter/CountyFilter';

const FIPS_ATTRIBUTE = 'fips' as const;

export const CountyAPNFilter = () => {
	return (
		<FilterBase attribute={FIPS_ATTRIBUTE}>
			{(props) => (
				<Container>
					<CountyFilter
						compType={props.compType}
						filters={props.filters}
						onFilterChange={props.onFilterChange}
						touch={props.touch}
					/>
					<APNFilter
						filters={props.filters}
						onFilterChange={(changes) => props.onFilterChange(changes, false)}
						setError={props.setError}
						touch={props.touch}
					/>
				</Container>
			)}
		</FilterBase>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
