import { PortfolioItem, SalesComp } from 'types/comp';

export type Item = {
	key: keyof SalesComp | keyof Pick<PortfolioItem, 'percentOccupied'>;
	title: string;
};

export const transactionItems: Item[] = [
	{
		key: 'totalSalePrice',
		title: 'Total Sale Price',
	},
	{
		key: 'salePricePsf',
		title: 'Sale Price (PSF)',
	},
	{
		key: 'capRate',
		title: 'Cap Rate (%)',
	},
	{
		key: 'saleDate',
		title: 'Sale Date',
	},
	{
		key: 'documentNumber',
		title: 'Deed Doc #',
	},
	{
		key: 'percentOccupied',
		title: 'Occupancy Rate',
	},
	{
		key: 'transactionSize',
		title: 'Transaction SQFT',
	},
	{
		key: 'portfolio',
		title: '# Properties in Sale',
	},
];

export const buyerSellerItems: Item[] = [
	{
		key: 'buyer',
		title: 'Buyer',
	},
	{
		key: 'recordedBuyer',
		title: 'Recorded Buyer',
	},
	{
		key: 'buyerRepBrokers',
		title: 'Buyer Rep',
	},
	{
		key: 'buyerRepCompanies',
		title: 'Buyer Rep Companies',
	},
	{
		key: 'seller',
		title: 'Seller',
	},
	{
		key: 'recordedSeller',
		title: 'Recorded Seller',
	},
	{
		key: 'sellerRepBrokers',
		title: 'Seller Rep',
	},
	{
		key: 'sellerRepCompanies',
		title: 'Seller Rep Companies',
	},
];
