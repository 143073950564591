import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { APP_WRAPPER_ID } from 'ui/UI';
import { useExportPdfContext } from '../pdf/ExportPdfProvider';
import { ExportPdfStateProvider } from '../pdf/ExportPdfStateProvider';
import { ExportPdfStep } from '../pdf/types';
import { ExportPdfData } from '../pdf/useExportPdfData';
import { ExportPdfStepOne } from './ExportPdfStepOne';
import { ExportPdfStepTwo } from './ExportPdfStepTwo';

type Props = {
	data: ExportPdfData;
};

export const ExportPdfDialog = ({ data }: Props) => {
	const { step } = useExportPdfContext();

	return (
		<Dialog.Portal container={document.getElementById(APP_WRAPPER_ID)}>
			<Root
				// nav header
				onKeyDown={(e) => {
					if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
						e.stopPropagation();
					}
				}}
			>
				<ExportPdfStateProvider data={data}>
					{step === ExportPdfStep.ONE ? (
						<ExportPdfStepOne data={data} />
					) : (
						<ExportPdfStepTwo data={data} />
					)}
				</ExportPdfStateProvider>
			</Root>
		</Dialog.Portal>
	);
};

const Root = styled.div`
	// defaults
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	* {
		box-sizing: border-box;
	}
`;
