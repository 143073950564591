import constate from 'constate';
import { useSelectionState } from 'utils/useSelectionState';
import { useListViewLeases } from './useListViewLeases';

type Props = {
	portfolioId: number;
};

export const [LeasesListAndMapViewProvider, useLeasesListAndMapViewContext] =
	constate(({ portfolioId }: Props) => {
		const {
			data: leases,
			isLoading,
			isFetching,
		} = useListViewLeases({
			portfolioId,
		});

		const selectionState = useSelectionState(leases);

		return { leases, isLoading, isFetching, ...selectionState };
	});
