import React, { FormEventHandler, memo, useCallback } from 'react';
import { FormSelect, TextInput, FormMultiSelect, Chip } from '@compstak/ui-kit';
import { DataSetType } from '../../../../../analytics';
import {
	useDataSetForm,
	UseDataSetFormProps,
} from '../../../DataSet/useDataSetForm';
import { getAttrOptionsForCreateDSForm } from './utils';
import { SPACE_TYPE_SELECT_ITEMS } from './spaceTypeItemsV2';
import {
	MUFA_PROPERTY_TYPE_ITEMS,
	SALE_PROPERTY_TYPE_ITEMS,
	getPropertyTypeIdsFromItems,
} from './propertyTypeItems';
import { getSpaceTypeIdsFromItems } from './spaceTypeItemsV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Market } from '@compstak/common';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import styled from 'styled-components';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

interface Props extends UseDataSetFormProps {
	onBack: () => void;
	onSubmit: (
		name: string,
		market: Market,
		spaceType: number[],
		dataSetType: DataSetType,
		series?: AttributeToPlotType
	) => void;
}

export const CreateNewDataSetForm = memo(
	({ onBack, onSubmit, ...props }: Props) => {
		const { state, handlers, options, meta } = useDataSetForm({
			...props,
			isMufaSpaceTypesIfAtLeastOneMarketAccess: true,
		});

		const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
			(e) => {
				e.preventDefault();

				const {
					title,
					spaceTypes,
					market,
					attributeToPlot,
					dataSetType,
					propertyTypes,
				} = state;

				onSubmit(
					title,
					market!,
					dataSetType === DataSetType.MUFA
						? [-1]
						: dataSetType === DataSetType.SALES
							? getPropertyTypeIdsFromItems(propertyTypes ?? [])
							: getSpaceTypeIdsFromItems(spaceTypes ?? []),
					dataSetType,
					attributeToPlot
				);
			},
			[onSubmit, state]
		);

		const { salesCompsAnalytics2 } = useFeatureFlags();

		const attributeToPlotOptions = getAttrOptionsForCreateDSForm(
			meta.hasMufaAccess,
			salesCompsAnalytics2
		);

		const attributeAndTypeLabel = salesCompsAnalytics2
			? 'Property / Space Type'
			: 'Space Type';

		const attributeAndTypeTooltip = salesCompsAnalytics2
			? propertySpaceTypeTooltip
			: null;

		return (
			<>
				<ModalTitle>Create New Data Set</ModalTitle>
				<Form onSubmit={handleSubmit}>
					<TextInput
						data-qa-id={'set-dataset-title'}
						label="Title Your Data Set"
						placeholder="Enter name here"
						value={state.title}
						onChange={handlers.onChangeTitle}
						// @ts-expect-error TS2322: Type '(e: any) => void' is not...
						onBlur={handlers.onBlurTitle}
						errorText={options.titleFieldError}
					/>
					<FormSelect
						label="Market"
						placeholder="Search or select"
						items={options.market}
						value={state.market?.name}
						onChange={handlers.onChangeMarket}
						noResultMessage="No data"
						showScrollbarOnHover
						isSearchable
					/>
					<FormSelect
						data-qa-id={'select-attribute-to-plot'}
						label="Attribute to Plot"
						items={
							state.market ? state.attributeToPlotItems : attributeToPlotOptions
						}
						onChange={handlers.onChangeAttributeToPlot}
						value={state.attributeToPlot}
						placeholder="Select from Dropdown"
						showScrollbarOnHover
					/>
					{(state.dataSetType === DataSetType.LEASES ||
						state.dataSetType === DataSetType.COMMERCIAL) && (
						<FormMultiSelect
							data-qa-id={'select-spacetype'}
							label={attributeAndTypeLabel}
							optionsTitle="Space type"
							items={SPACE_TYPE_SELECT_ITEMS}
							onChange={handlers.onChangeSpaceType}
							value={state.spaceTypes ?? []}
							disabled={!state.attributeToPlot}
							tooltipContent={attributeAndTypeTooltip}
						/>
					)}
					{state.dataSetType === DataSetType.SALES && (
						<FormMultiSelect
							data-qa-id={'select-property-type'}
							label={attributeAndTypeLabel}
							optionsTitle="Property type"
							items={SALE_PROPERTY_TYPE_ITEMS}
							onChange={handlers.onChangePropertyType}
							value={state.propertyTypes ?? []}
							tooltipContent={attributeAndTypeTooltip}
						/>
					)}
					{state.dataSetType === DataSetType.MUFA && (
						<FormMultiSelect
							data-qa-id={'select-mufa-type'}
							label={attributeAndTypeLabel}
							items={MUFA_PROPERTY_TYPE_ITEMS}
							onChange={() => {}}
							tooltipContent={attributeAndTypeTooltip}
							placeholder={<Chip>{MUFA_PROPERTY_TYPE_ITEMS[0].title}</Chip>}
							disabled={true}
							value={[]}
						/>
					)}
					<ModalButtons>
						<ModalButton variant="secondary" type="button" onClick={onBack}>
							Back
						</ModalButton>
						<ModalButton
							type="submit"
							disabled={!meta.formComplete || !!options.titleFieldError}
						>
							Create
						</ModalButton>
					</ModalButtons>
				</Form>
			</>
		);
	}
);

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

CreateNewDataSetForm.displayName = 'CreateNewDataSetForm';

const propertySpaceTypeTooltip =
	'Property Types will be available for Sales Comp Data Sets and Space Types will be available for Lease Data Sets.';
