import { PdfTable } from '../PdfTable';
import { getFloorPlansPdfColumns } from './getFloorPlansPdfColumns';
import { Text, View } from '@react-pdf/renderer';
import { pdfStyle } from '../style';
import { PdfRealPageLogoView } from '../PdfRealPageLogoView';

export const FloorPlansTablePdf = ({
	floorPlans,
}: {
	floorPlans: Record<string, string | number>[];
}) => {
	const columns = getFloorPlansPdfColumns();

	return (
		<>
			<Text style={pdfStyle.multifamilyTitle}>Floorplan Pricing</Text>
			<PdfTable columns={columns} rows={floorPlans} />
			<View style={pdfStyle.multifamilyRpContainer}>
				<PdfRealPageLogoView />
			</View>
		</>
	);
};
