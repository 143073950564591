import { getRentPeriodPostfix } from './getRentPeriodPostfix';
import { GrayPostfix } from './UI';

export const renderRentPeriodPostfix = (
	isMonthly?: boolean,
	shorten = true
) => {
	return (
		<GrayPostfix>{getRentPeriodPostfix(isMonthly, { shorten })}</GrayPostfix>
	);
};
