import React, {
	memo,
	useMemo,
	useState,
	useCallback,
	CSSProperties,
} from 'react';
import {
	RowFlexContainer,
	WidgetTitle,
	WidgetContainer,
	PeriodSelector,
	SaveDataMenu,
	Pending,
	LinesChart,
	Tooltip,
	HelpCircle,
	LineDescription,
	Period,
	defaultTheme,
	ErrorUI,
	numberUtils,
	TickFormatter,
	HeightContainer,
	getDownloadFileName,
	createTypedLineChartLegend,
} from '@compstak/ui-kit';
import {
	getAdaptData,
	getFilteredLines,
} from '../../../Pages/PropertyPageV2_1/sections/utils';

import {
	years,
	calcXTicks,
	calcYTicks,
	calcDomainFromTicks,
	getXTickDateFormatter,
} from '../../../Pages/PropertyPageV2_1/utils';
import { addEmptyLines } from '@compstak/common';
import { CompSetTrendDTO, useCompSetTrendRequest } from 'api';
import { ChartTypes } from '../../../Pages/PropertyPageV2_1/type';
import {
	CommercialTrendsFiltersState,
	CommercialFilterChangeFn,
} from '../useCommercialTrendsState';
import {
	CommercialChartsNames,
	CommonChartProps,
	FiltersNames,
} from 'types/propertyTrendCharts';

const adaptData = getAdaptData();

const LineChartLegend = createTypedLineChartLegend<CompSetTrendDTO>();

export type SubmarketRentTrendProps = {
	address: string;
	rentTrend: ReturnType<typeof useCompSetTrendRequest>;
	filters: CommercialTrendsFiltersState;
	handleFilterChange: CommercialFilterChangeFn;
} & CommonChartProps;

const headersMapping = {
	name: 'Date',
	compSetAverage: 'Competitive Set Average',
	submarket: 'Submarket',
};

export const SubmarketRentTrend = memo<SubmarketRentTrendProps>(
	({
		address,
		filters,
		handleFilterChange,
		showSaveDataMenu = true,
		showAnimation = true,
		shouldShowTooltip = true,
		rentTrend,
	}) => {
		const [ref, setRef] = useState<HTMLElement | null>(null);

		const { data, isFetching, isError, error } = rentTrend;
		const widgetTitle = 'Rent Trend';
		const chartId = `${widgetTitle}${address}`;
		const chartData = useMemo(() => adaptData(data), [data]);

		const filteredLines = useMemo(
			() => getFilteredLines(chartData, lines, emptyLines),
			[chartData]
		);

		const xTicks = useMemo(
			() =>
				calcXTicks(
					chartData,
					filters[CommercialChartsNames.SubmarketRentTrend][FiltersNames.PERIOD]
				),
			[chartData, filters]
		);

		const yTicks = useMemo(
			() => calcYTicks(ChartTypes['LINE_CHART'], chartData),
			[chartData]
		);

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) => {
				handleFilterChange(
					CommercialChartsNames.SubmarketRentTrend,
					FiltersNames.PERIOD,
					selectedPeriod
				);
			},
			[handleFilterChange]
		);

		return (
			<WidgetContainer>
				<RowFlexContainer style={rowFlexContainerStyle}>
					<RowFlexContainer>
						<WidgetTitle ref={setRef}>{widgetTitle}</WidgetTitle>
						{shouldShowTooltip && (
							<Tooltip
								tooltipComponent={tooltipComponent}
								placement="bottom"
								tooltipRootStyle={tooltipRootStyle}
								refToObservePositionChange={ref}
							>
								<HelpCircle />
							</Tooltip>
						)}
						<PeriodSelector
							period={
								filters[CommercialChartsNames.SubmarketRentTrend][
									FiltersNames.PERIOD
								]
							}
							onChange={handlePeriodChange}
							years={years}
							data-qa-id="period-selector"
						/>
					</RowFlexContainer>
					{showSaveDataMenu && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, widgetTitle)}
							elementId={chartId}
							dataToCopy={chartData}
							headersMapping={headersMapping}
						/>
					)}
				</RowFlexContainer>
				<HeightContainer margin={HEIGHT_CONTAINER_MARGIN}>
					{isFetching ? (
						<Pending times={4} />
					) : (
						<LinesChart
							lines={filteredLines}
							data={chartData}
							yAxisLabel="Starting Rent"
							tooltipLabel="Starting rent $/SF"
							yTickFormatter={yTickFormatter}
							tooltipFormatter={tooltipFormatter}
							xTickFormatter={getXTickDateFormatter(
								filters[CommercialChartsNames.SubmarketRentTrend][
									FiltersNames.PERIOD
								]
							)}
							xTicks={xTicks}
							yTicks={yTicks}
							yDomain={calcDomainFromTicks(yTicks)}
							id={chartId}
							yTooltipPosition={-15}
							isAnimationActive={showAnimation}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<LineChartLegend lines={filteredLines} data={data} />
			</WidgetContainer>
		);
	}
);

SubmarketRentTrend.displayName = 'SubmarketRentTrend';

const rowFlexContainerStyle = {
	marginBottom: '30px',
};

const HEIGHT_CONTAINER_MARGIN = { bottom: 24 };

const lines: Array<LineDescription> = [
	{
		value: 'compSetAverage',
		title: 'Comp. Set',
		color: `${defaultTheme.colors.purple.purple700}`,
		type: 'monotone',
	},
	{
		value: 'submarket',
		title: 'Submarket',
		color: `${defaultTheme.colors.blue.blue700}`,
		type: 'monotone',
	},
];

const emptyLines = addEmptyLines(lines);

const yTickFormatter: TickFormatter = (value) => {
	return numberUtils.formatCurrency(value, 'currencyInteger');
};

const tooltipFormatter: TickFormatter = (value) =>
	numberUtils.formatCurrency(value, 'currencyTwoDecimals');

const tooltipComponent = (
	<>
		6&nbsp;months moving average of&nbsp;Starting Rent showing Competitive
		set&nbsp;vs. Submarket (Central).
	</>
);

const tooltipRootStyle: CSSProperties = {
	marginRight: '16px',
};
