import { Flex } from '@compstak/ui-kit';
import styled, { css } from 'styled-components';

export const WrapFlex = styled(Flex)<{ marginBottom?: string }>`
	flex-wrap: wrap;
	${(props) =>
		props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ''}
`;

export const INPUT_HEIGHT = 48;

export const inputCss = css`
	height: ${INPUT_HEIGHT}px;
	width: 100%;
	border: ${({ theme }) => `1px solid ${theme.colors.neutral.n50}`};
	background-color: ${({ theme }) => theme.colors.neutral.n0};
	border-radius: 3px;
	padding: 12px 16px;
	margin: 0;
`;
