import React, { useEffect, useState } from 'react';

import { Chart } from 'Pages/Analytics/analytics';
import { useAppSelector } from 'util/useAppSelector';
import { useModal } from 'providers/ModalProvider';
import { useMarkets } from 'hooks/useMarkets';
import { useDispatch } from 'react-redux';
import {
	createNewProjectWithChart,
	saveChart,
} from 'Pages/Analytics/Repository/actions';
import { updateChartDraft } from 'Pages/Analytics/Builder/actions';
import { usePrevious } from 'util/hooks';
import { SaveChartOverwriteModal } from './SaveChartOverwriteModal';
import { SaveChartAsModal } from './SaveChartAsModal';

type Props = {
	chartDraft: Chart;
	onSave?: () => void;
};

export const SaveChartModal = ({ chartDraft, onSave }: Props) => {
	const { lastUpdated, projects } = useAppSelector(
		(store) => store.analyticsProjects
	);
	const markets = useMarkets();

	const [isSaving, setIsSaving] = useState(false);
	const [isSavingExistingChart, setIsSavingExistingChart] = useState(
		!!chartDraft?.originalChartId
	);

	const prevLastUpdated = usePrevious(lastUpdated);

	const dispatch = useDispatch();
	const { closeModal } = useModal();

	useEffect(() => {
		if (prevLastUpdated && prevLastUpdated !== lastUpdated) {
			closeModal();
			onSave?.();
		}
	}, [lastUpdated]);

	const handlePlainSave = () => {
		const project = projects?.find((p) =>
			p.charts.some((chart) => chart.id === chartDraft.originalChartId)
		);

		const newChart = {
			...chartDraft,
			id: chartDraft.originalChartId,
			projectId: project?.id,
			originalChartId: null,
		};
		dispatch(saveChart(newChart, markets));
		setIsSaving(true);
	};

	const handleSaveAs = (draft: Chart, projectName: string) => {
		const project = projects?.find((p) => p.name === projectName);

		dispatch(updateChartDraft(draft, markets));

		const newChart = {
			...draft,
			projectId: project?.id,
			id: null,
			originalChartId: null,
		};

		if (project) {
			dispatch(saveChart(newChart, markets));
		} else {
			dispatch(createNewProjectWithChart(projectName, newChart, markets));
		}
		setIsSaving(true);
	};

	if (isSavingExistingChart) {
		return (
			<SaveChartOverwriteModal
				onSave={handlePlainSave}
				onSaveAs={() => setIsSavingExistingChart(false)}
				isSaving={isSaving}
			/>
		);
	}

	return (
		<SaveChartAsModal
			chartDraft={chartDraft}
			onSave={handleSaveAs}
			isSaving={isSaving}
		/>
	);
};
