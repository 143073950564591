import popupStyles from 'Components/Popup/popup.less';
import { TENANT_INDUSTRY_COLORS } from 'constants/colors';
import { hierarchy, treemap, treemapSquarify } from 'd3-hierarchy';
import { useMemo, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { LeafProps, TreeNode, TreemapProps } from './treemapTypes';

export const Treemap = ({
	margin,
	width,
	height,
	data,
	sortFunction,
	getSize,
	onLeafClick,
}: TreemapProps) => {
	const nodes = useMemo(() => {
		const marginProps = {
			...(typeof margin === 'number'
				? {
						left: margin,
						right: margin,
						top: margin,
						bottom: margin,
					}
				: margin),
		};
		const {
			left: marginLeft = 0,
			top: marginTop = 0,
			right: marginRight = 0,
			bottom: marginBottom = 0,
		} = marginProps;

		const innerWidth = width - marginLeft - marginRight;
		const innerHeight = height - marginBottom - marginTop;

		if (!data) {
			return [];
		}

		const treemapingFunction = treemap()
			.tile(treemapSquarify)
			.size([innerWidth, innerHeight]);
		const structuredInput = hierarchy(data)
			.sum(getSize)
			.sort((a, b) => sortFunction(a.data, b.data));
		return treemapingFunction(structuredInput)
			.descendants()
			.map((n) => {
				// @ts-expect-error issue with d3 types
				return { ...n, ...n.data };
			}) as TreeNode[];
	}, [data, getSize, height, width, margin, sortFunction]);

	const [selectedLeafIndex, setSelectedLeafIndex] = useState<number | null>(
		null
	);

	return (
		<div className="treemap">
			{nodes.map((node, index) => {
				return (
					<TreemapLeaf
						key={index}
						index={index}
						margin={margin}
						node={node}
						selectedLeafIndex={selectedLeafIndex}
						setSelectedLeafIndex={setSelectedLeafIndex}
						backgroundColor={
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							TENANT_INDUSTRY_COLORS?.[node?.data?.title ?? '']
								? // @ts-expect-error TS2538: Type 'undefined' cannot be use...
									`rgb(${TENANT_INDUSTRY_COLORS?.[node?.data?.title]?.join(
										','
									)})`
								: 'rgb(249, 249, 249)'
						}
						onLeafClick={onLeafClick}
					/>
				);
			})}
		</div>
	);
};

const TreemapLeaf = ({
	node,
	onLeafClick,
	onLeafMouseOver,
	onLeafMouseOut,
	margin,
	backgroundColor,
	setSelectedLeafIndex,
	selectedLeafIndex,
	index,
}: LeafProps) => {
	const { data, x0 = 0, x1 = 0, y0 = 0, y1 = 0 } = node;

	// don't display root node
	if (!data?.size) return null;

	const leafStyle = {
		top: y0,
		left: x0,
		width: x1 - x0 - margin,
		height: y1 - y0 - margin,
		margin,
		backgroundColor,
	};

	const Tooltip = () => {
		return (
			<Column>
				<Row>
					<Title>{data.title}</Title>
				</Row>
				<Row>
					<span>Properties</span>
					<span>{abbreviateNumber(data.propertyCount)}</span>
				</Row>
				<Row>
					<span>Leases</span>
					<span>{abbreviateNumber(data.leaseCount)}</span>
				</Row>
				<Row>
					<span>SQFT</span>
					<span>{abbreviateNumber(data.sqft)}</span>
				</Row>
			</Column>
		);
	};
	const blockProps =
		data?.type === 'loading-block'
			? {}
			: {
					onMouseEnter: onLeafMouseOver,
					onMouseLeave: onLeafMouseOut,
					'data-tooltip': renderToStaticMarkup(<Tooltip />),
					'data-tooltip-offset-top': 20,
					'data-tooltip-position': 'center',
					'data-tooltip-class': popupStyles.pointerNone,
					'data-qa-id': `leaf-button-${data.title}`,
					onMouseOver: () => setSelectedLeafIndex(index),
					onMouseOut: () => setSelectedLeafIndex(null),
					onClick: () => onLeafClick && data.title && onLeafClick(data.title),
					'data-hover-state':
						selectedLeafIndex === index
							? 'active'
							: selectedLeafIndex === null
								? 'idle'
								: 'inactive',
				};

	const dataVariant =
		data?.type === 'loading-block'
			? 'loading-block'
			: data.title === 'Leisure & Restaurants'
				? 'dark'
				: 'light';

	return (
		<button
			{...blockProps}
			className="treemap__leaf"
			style={leafStyle}
			data-variant={dataVariant}
			data-qa-id="leaf-button"
		>
			<div className="treemap__leaf__content" data-variant={dataVariant}>
				<div className="leaf-title">{data.title}</div>
				{data.sqft != null && (
					<div className="leaf-size">{abbreviateNumber(data.sqft)} SQFT</div>
				)}
			</div>
		</button>
	);
};

const Title = styled.span`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 127px;
	height: 122px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 8px;
	font-size: 12px;
	line-height: 24px;
`;
