import {
	Chart,
	DataSetType,
	InsightData,
	InsightDataResponse,
} from '../../analytics';
import { usePermissions } from '../../../Login/reducers';
import styles from './styles/analytics.less';
import { isPermittedDataset } from '../chartBuilderHelpers';
import { Graph } from '../../../../Components/Graphs';
import { Legend } from './Legend';
import { TableV2 } from './TableV2';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { Tabs, Tab, TabsList, TabContent } from '@compstak/ui-kit';
import { UnderlyingDataTableTabContent } from './UnderlyingDataTable/UnderlyingDataTableTabContent';
import { useUserQuery } from 'api';

export type ContentProps = {
	chartDraft: Chart;
	indexesToDisplay: number[];
	colors: string[];
	insights: InsightData[];
	insightsData: InsightDataResponse[];
};

export enum TabsEnum {
	UNDERLYING_DATA = 'underlying-data',
	YEARLY_BREAKOUT = 'yearly-breakout',
}

export function ContentComponent(props: ContentProps) {
	const permissions = usePermissions();
	const [activeTab, setActiveTab] = useState<TabsEnum>(
		TabsEnum.UNDERLYING_DATA
	);
	const { data: user } = useUserQuery();

	const areAllDataSetsWithoutTrendLine = props.insights.every(
		(dataSet: InsightData) =>
			!dataSet.trendLine || dataSet.trendLine.length === 0
	);

	const sectionRef = useRef<HTMLDivElement | null>(null);

	const handleClick = () => {
		sectionRef?.current?.scrollIntoView({
			block: 'start',
			behavior: 'smooth',
		});
	};

	if (areAllDataSetsWithoutTrendLine) {
		return (
			<div className={styles.emptyDataSetScreen}>
				<div>
					No results found. Try removing some filters or expanding the area of
					your search
				</div>
			</div>
		);
	}

	if (props.indexesToDisplay.length === 0) {
		return (
			<div className={styles.unhideDataSet}>
				<div>Unhide a data set to see the chart</div>
			</div>
		);
	}

	const legendObjects = props.chartDraft.dataSets
		.map((dataSet, index) => {
			return {
				dataSet,
				color: props.colors[index],
				isBubble: !!props.insights[index].points,
			};
		})
		.filter((ds, idx) => {
			if (!props.indexesToDisplay.includes(idx)) {
				return false;
			}
			return isPermittedDataset(ds.dataSet, permissions);
		});

	return (
		<div className={styles.mainContent}>
			<div className={styles.graph}>
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Graph
					legend={false}
					hasNewDesign={props.chartDraft.chartType === 'line'}
					{...props}
					{...props.chartDraft}
				/>
			</div>
			<Legend legendObjects={legendObjects} />
			{!user.isExchangePlusClient ? (
				<Tabs
					ref={sectionRef}
					value={activeTab}
					onValueChange={(value) => {
						setActiveTab(value as TabsEnum);
					}}
				>
					<TabsList>
						<StyledTab value={TabsEnum.UNDERLYING_DATA} onClick={handleClick}>
							Underlying Comps
						</StyledTab>
						<StyledTab value={TabsEnum.YEARLY_BREAKOUT} onClick={handleClick}>
							Yearly Breakout
						</StyledTab>
					</TabsList>
					<StyledTabContent value={TabsEnum.UNDERLYING_DATA}>
						<UnderlyingDataTableTabContent
							dataSets={props.chartDraft.dataSets.filter(
								(dataSet) => dataSet.type !== DataSetType.SALES
							)}
							chartDraft={props.chartDraft}
							insightsData={props.insightsData}
						/>
					</StyledTabContent>
					<StyledTabContent value={TabsEnum.YEARLY_BREAKOUT}>
						<div>
							<TableV2
								chartDraft={props.chartDraft}
								insightsData={props.insightsData}
							/>
						</div>
					</StyledTabContent>
				</Tabs>
			) : (
				<div>
					<TableV2
						chartDraft={props.chartDraft}
						insightsData={props.insightsData}
					/>
				</div>
			)}
		</div>
	);
}

const StyledTabContent = styled(TabContent)`
	padding: 1.5rem;
`;

const StyledTab = styled(Tab)`
	cursor: pointer;
`;
