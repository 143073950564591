import styled from 'styled-components';
import {
	baseHeaderItemCss,
	CircleText,
	DesktopLinksContainer,
	Header,
	MessageText,
	RightContainer,
	StyledIconLogo,
} from '../../Components/AppHeader/UI';

export const TrialExpiredHeader = () => {
	return (
		<Header>
			<StyledIconLogo />
			<DesktopLinksContainer>
				<Text>Trial Period Complete</Text>
			</DesktopLinksContainer>
			<StyledRightContainer>
				<CircleText isRed>0</CircleText>
				<MessageText>days left</MessageText>
			</StyledRightContainer>
		</Header>
	);
};

const Text = styled.div`
	${baseHeaderItemCss};
`;

const StyledRightContainer = styled(RightContainer)`
	padding-right: 1.25rem;
`;
