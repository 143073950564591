import { Portfolio } from 'api';
import { useMemo } from 'react';
import { useValidMarkets } from '../useValidMarkets';
import { DropdownItem } from './Dropdown';

type Props = {
	portfolio: Portfolio;
};

export const useMarketOptions = ({
	portfolio,
}: Props): DropdownItem<number>[] => {
	const validMarkets = useValidMarkets({ portfolio });

	return useMemo(() => {
		return validMarkets.map((market) => ({
			id: market.marketId,
			value: market.market,
		}));
	}, [validMarkets]);
};
