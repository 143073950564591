import { MapFilterMultiSelect } from 'Components/Filters/Base/Filter/MapFilterMultiSelect';
import { FilterOption } from 'Components/Filters/Base/Multiselect';
import { useSearch } from 'Pages/Search/searchReducer';
import { loadSubmarkets, removeSubmarkets } from 'actions/search';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { useMarkets } from 'hooks/useMarkets';
import { FiltersObject, SubmarketsFilter } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

type SubmarketFilterProps = {
	filters: FiltersObject;
	isActive: boolean;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setIsLoading?: (isLoading: boolean) => void;
};

const attribute = 'submarkets' as const;

export const SubmarketFilter = ({
	filters,
	isActive,
	onFilterChange,
	setIsLoading,
}: SubmarketFilterProps) => {
	const { loadingSubmarkets, marketIdToSubmarkets } = useSearch();
	const markets = useMarkets();

	const dispatch = useDispatch();

	useLayoutEffect(() => {
		if (isActive) {
			dispatch(loadSubmarkets(getFiltersMarkets(filters)));
		} else {
			dispatch(removeSubmarkets());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive, dispatch, filters.market, filters.markets]);

	useLayoutEffect(() => {
		setIsLoading?.(loadingSubmarkets);
	}, [loadingSubmarkets, setIsLoading]);

	const marketNameToSubmarkets = useMemo(() => {
		if (!marketIdToSubmarkets) {
			return undefined;
		}

		const marketToSubmarkets = Object.typedKeys(marketIdToSubmarkets).reduce<
			Record<string, SubmarketPolygon[]>
		>((acc, marketId) => {
			const marketDisplayName = markets[marketId].displayName;
			acc[marketDisplayName] = marketIdToSubmarkets[marketId];
			return acc;
		}, {});

		return Object.keys(marketToSubmarkets)
			.sort()
			.reduce<Record<string, SubmarketPolygon[]>>((obj, sortedDisplayName) => {
				obj[sortedDisplayName] = marketToSubmarkets[sortedDisplayName];
				return obj;
			}, {});
	}, [markets, marketIdToSubmarkets]);

	const options: FilterOption[] = useMemo(() => {
		if (!marketNameToSubmarkets) {
			return [];
		}

		return Object.keys(marketNameToSubmarkets).flatMap((marketName) => {
			const marketHeader: FilterOption = {
				type: 'header',
				name: marketName,
				key: `market_${markets[marketName].id}`,
			};
			const marketSubmarkets: FilterOption[] = marketNameToSubmarkets[
				marketName
			].map((submarket) => {
				return {
					name: submarket.properties.name,
					id: submarket.properties.id,
				};
			});
			return [marketHeader, ...marketSubmarkets];
		});
	}, [marketNameToSubmarkets]);

	return (
		<MapFilterMultiSelect
			attribute={attribute}
			// @ts-expect-error expected wrong type
			filter={filters[attribute]}
			onFilterChange={onFilterChange}
			options={options}
			hasSearch
			searchInputPlaceholder="Search for submarket"
			isOptionChecked={(option) =>
				Boolean(filters.submarkets?.some(({ id }) => id === option.id))
			}
			onChange={(values, checked) => {
				const currentSubmarkets = filters.submarkets;
				const clickedSubmarket = Object.values(marketIdToSubmarkets ?? {})
					.flat()
					.find((selectedSubmarket) =>
						values.includes(String(selectedSubmarket.properties.id))
					)?.properties;

				let newSubmarkets: SubmarketsFilter | null;

				if (!clickedSubmarket) {
					return;
				}

				if (checked) {
					newSubmarkets = currentSubmarkets
						? [...currentSubmarkets, clickedSubmarket]
						: [clickedSubmarket];
				} else {
					newSubmarkets = (currentSubmarkets ?? []).filter(
						(submarket) => !values.includes(String(submarket.id))
					);
					if (newSubmarkets.length === 0) {
						newSubmarkets = null;
					}
				}
				onFilterChange({ submarkets: newSubmarkets });
			}}
			renderAllOption={() => undefined}
			renderAllPlaceholder={() => 'All Submarkets'}
			isLoading={loadingSubmarkets}
		/>
	);
};
