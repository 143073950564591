import { CheckedState } from '@radix-ui/react-checkbox';
import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Tr } from './MarketToCompsExportTable';
import { Checkbox } from 'Components/Checkbox/Checkbox';

type TableHeaderProps = {
	selectableMarketIds: number[];
	selectedMarketIds: number[];
	setSelectedMarketIds: (arg: number[]) => void;
};

export const TableHeader = ({
	selectableMarketIds,
	selectedMarketIds,
	setSelectedMarketIds,
}: TableHeaderProps) => {
	const checked: CheckedState = useMemo(() => {
		if (!selectedMarketIds.length) {
			return false;
		}
		if (selectedMarketIds.length === selectableMarketIds.length) {
			return true;
		}
		return 'indeterminate';
	}, [selectableMarketIds.length, selectedMarketIds.length]);

	const onCheckedChange = useCallback(
		(checked: CheckedState) => {
			const newSelectedMarketIds = checked ? selectableMarketIds : [];

			setSelectedMarketIds(newSelectedMarketIds);
		},
		[selectableMarketIds, setSelectedMarketIds]
	);

	return (
		<Thead>
			<Tr disabled={!selectableMarketIds.length}>
				<Th>
					<Checkbox
						checked={checked}
						disabled={!selectableMarketIds.length}
						onCheckedChange={onCheckedChange}
					/>
				</Th>
				<Th>Market</Th>
				<Th>Selected</Th>
				<Th>Available</Th>
			</Tr>
		</Thead>
	);
};

const Thead = styled.thead`
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.gray.gray700};
`;

const Th = styled.th`
	padding: 0.5rem;
	text-align: left;

	&:has(label) {
		padding: 0;
		label {
			padding: 0.5rem;
		}
	}
`;
