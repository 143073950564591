import {
	useDeleteMarketSummaryFeedbackMutation,
	useMarketSummaryQuery,
	useMarketSummaryFeedbackQuery,
	useUpdateMarketSummaryFeedbackMutation,
} from 'api/marketSummary';

export const useExchangeDashboardMarketSummary = ({
	marketId,
	userId,
}: {
	marketId: number;
	userId: number;
}) => {
	const summaryWithFeedback = useSummaryWithFeedback({ marketId, userId });

	return {
		summaryWithFeedback,
		useDeleteMarketSummaryFeedbackMutation,
		useUpdateMarketSummaryFeedbackMutation,
	};
};

const useSummaryWithFeedback = ({
	marketId,
	userId,
}: {
	marketId: number;
	userId: number;
}) => {
	const summary = useMarketSummaryQuery({ marketId });

	const feedback = useMarketSummaryFeedbackQuery({
		marketId,
		userId,
	});

	return {
		data: {
			...summary.data,
			...feedback.data,
		},
		isFetching: summary.isFetching || feedback.isFetching,
		isLoading: summary.isLoading,
		isError: feedback.isError,
	};
};
