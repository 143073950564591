import { Button, useBoolean, useNotifications } from '@compstak/ui-kit';
import { FC, useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { generateTotpKey, isValidTotpCode } from 'util/mfAuthUtil';
import { useAppSelector } from 'util/useAppSelector';
import {
	BtnsArea,
	CancelBtn,
	MfaPageContainer,
	PrimaryBtn,
	TotpCode32CharDisplay,
	TotpCodeInput,
} from './MfAuthComponents';
import { TOTP_ERROR_MESSAGES } from './MfAuthConstants';
import { useRefreshMfaEnabledInfo } from './utils';
import { HasCancelProp, HasGoToNextStageProp, MFAScreenProps } from './types';
import {
	ErrorMessage,
	InnerSection,
	SectionTitle,
	StyledSection,
} from './styles';
import {
	BUTTON_SIZE,
	PHONE_AUTH_APPS,
	POST_MVP,
	QR_BOX_SIDE_LENGTH,
} from './constants';
import { bootstrap } from 'Pages/Login/actions';
import { useDispatch } from 'react-redux';
import {
	useDisableMFAForCurrentUserMutation,
	useEnableMfaForCurrentUserMutation,
	useLoginCurrentUserWithMfaMutation,
} from 'api/mfa/useMFAMutations';

export const SetupMultiFactorAuth: FC<MFAScreenProps> = ({
	cancel,
	credentials,
	setTotpCode,
	isSignIn,
}) => {
	const [stage, setStage] = useState<number>(0);
	const goToNextStage = () => {
		if (stage >= activeScreenOrder.length) {
			cancel();
			return;
		}
		setStage((prevStage) => prevStage + 1);
	};
	const ActiveScreen = activeScreenOrder[stage] || MfaSetupStageErrorScreen;
	return (
		<MfaPageContainer>
			<ActiveScreen
				isSignIn={isSignIn}
				cancel={cancel}
				credentials={credentials}
				setTotpCode={setTotpCode}
				goToNextStage={goToNextStage}
			/>
		</MfaPageContainer>
	);
};

const activeScreenOrder = [
	...(POST_MVP ? [MfaSetupStageReenterPasswd] : []),
	MfaSetupStageInstallPhoneAppPrompt,
	MfaSetupStageScanQrCode,
	...(POST_MVP ? [MfaSetupStageSaveRecoveryCodes] : []),
	MfaSetupStageSuccessScreen,
];

function MfaSetupStageReenterPasswd({
	goToNextStage,
	cancel,
}: HasGoToNextStageProp & HasCancelProp) {
	return (
		<StyledSection>
			<SectionTitle>Two-Factor Authentication Setup</SectionTitle>

			<p>For security purposes, please re-enter your password</p>

			<InnerSection>
				<input style={{ width: '100%' }} data-qa-id="passwd-input" />
				<a
					href="#"
					onClick={() => {
						alert(
							'TODO: Can\'t go to "forgot password" route when logged in. Check with UX Designers'
						);
					}}
				>
					Forgot Password?
				</a>
			</InnerSection>

			<BtnsArea>
				<CancelBtn cancel={cancel} />
				<PrimaryBtn
					onClick={() => {
						alert('TODO: Check passwd when BE is ready');
						goToNextStage();
					}}
				/>
			</BtnsArea>
		</StyledSection>
	);
}

function MfaSetupStageInstallPhoneAppPrompt({
	goToNextStage,
	cancel,
}: HasCancelProp & HasGoToNextStageProp) {
	return (
		<StyledSection>
			<SectionTitle>Step 1: Download a Mobile Authentication App</SectionTitle>

			<p>
				You’ll need an authenticator app on your phone to complete this process.
				Once 2-factor authentication is enabled, you’ll use this app to log in
				to CompStak. Here are a few apps we support:
			</p>

			<ul>
				<li key="google auth">
					Google Authenticator (
					<LinkToAuthApp
						name="Android"
						link="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
					/>
					{', '}
					<LinkToAuthApp
						name="iOS"
						link="https://apps.apple.com/us/app/google-authenticator/id388497605"
					/>
					)
				</li>
				{PHONE_AUTH_APPS.map(({ name, link }) => (
					<li key={name}>
						<LinkToAuthApp {...{ name, link }} />
					</li>
				))}
			</ul>

			<BtnsArea>
				<CancelBtn cancel={cancel} />
				<PrimaryBtn data-qa-id="go-to-next-stage-btn" onClick={goToNextStage} />
			</BtnsArea>
		</StyledSection>
	);
}

function MfaSetupStageScanQrCode({
	goToNextStage,
	cancel,
	credentials,
	setTotpCode,
	isSignIn,
}: MFAScreenProps & HasGoToNextStageProp) {
	const userEmail = useAppSelector((store) => store.user?.email);
	const { refreshIsMfaEnabled } = useRefreshMfaEnabledInfo();
	const [totpSecret] = useState(generateTotpKey());
	const dispatch = useDispatch();
	const { username, totpCode } = credentials;

	const [isTouched, setIsTouched] = useBoolean(false);

	const totpCodeFormatError =
		isTouched && totpCode.length < 6 ? TOTP_ERROR_MESSAGES.INVALID_CODE : '';

	// https://github.com/google/google-authenticator/wiki/Key-Uri-Format
	// https://stackoverflow.com/questions/34520928/how-to-generate-a-qr-code-for-google-authenticator-that-correctly-shows-issuer-d
	const qrCodeFormattedToken = `otpauth://totp/CompStak:${
		userEmail || username
	}?secret=${totpSecret}&issuer=CompStak`;

	const onSuccess = async () => {
		await refreshIsMfaEnabled();
		goToNextStage();
	};

	const {
		mutate: loginCurrentUserWithMfa,
		isLoading: isLoadingLoginWithMFA,
		isError: isLoginWithMFAError,
	} = useLoginCurrentUserWithMfaMutation({
		onSuccess: async () => {
			await onSuccess();
			dispatch(bootstrap());
		},
	});

	const {
		mutate: enableMfaForCurrentUser,
		isLoading: isLoadingEnablingMFA,
		isError: isEnablingMFAError,
	} = useEnableMfaForCurrentUserMutation({
		onSuccess,
	});

	const isLoading = isLoadingLoginWithMFA || isLoadingEnablingMFA;
	const isError = isLoginWithMFAError || isEnablingMFAError;

	const submit = async () => {
		setIsTouched();
		if (!isValidTotpCode(totpCode)) {
			return;
		}

		if (isSignIn) {
			loginCurrentUserWithMfa({
				...credentials,
				mfaSecret: totpSecret,
			});
		} else {
			enableMfaForCurrentUser({
				totpSecret,
				totpCode,
			});
		}
	};

	useEffect(() => () => setTotpCode(''), []);

	return (
		<StyledSection width="680px">
			<SectionTitle>
				Step 2: Authenticate Your Account Using the App
			</SectionTitle>

			<InnerSection>
				<p>Using the app, scan this QR code:</p>
				<QRCode
					style={{ width: QR_BOX_SIDE_LENGTH, height: QR_BOX_SIDE_LENGTH }}
					value={qrCodeFormattedToken}
				/>
				<InnerSection gap={'8px'}>
					<p>If you can’t scan the code, type in this key instead:</p>
					<TotpCode32CharDisplay code={totpSecret} />
				</InnerSection>
			</InnerSection>

			<InnerSection>
				<p>
					Once you’ve scanned or typed in the code above, enter the 6-digit
					authentication code displayed in the app:
				</p>
				<TotpCodeInput
					digits={totpCode}
					setDigits={setTotpCode}
					style={{ width: QR_BOX_SIDE_LENGTH }}
					onBlur={setIsTouched}
					onEnterKeyDown={submit}
					error={!!totpCodeFormatError || !!isError}
				/>
				<ErrorMessage>
					{totpCodeFormatError ||
						(isError ? TOTP_ERROR_MESSAGES.INVALID_CODE : '')}
				</ErrorMessage>
			</InnerSection>

			<BtnsArea>
				<CancelBtn cancel={cancel} disabled={isLoading} />
				<PrimaryBtn
					disabled={isTouched && !isValidTotpCode(totpCode)}
					isLoading={isLoading}
					onClick={submit}
				/>
			</BtnsArea>
		</StyledSection>
	);
}

function MfaSetupStageSaveRecoveryCodes({
	goToNextStage,
	cancel,
}: HasGoToNextStageProp & HasCancelProp) {
	return (
		<StyledSection>
			<SectionTitle>Step 3: Save Your Recovery Codes</SectionTitle>

			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
				{Array(8)
					.fill(12345678)
					.map((recoveryCode, idx) => (
						<p key={idx + recoveryCode}>{recoveryCode}</p>
					))}
			</div>

			<BtnsArea>
				<CancelBtn cancel={cancel} />
				<PrimaryBtn onClick={goToNextStage} />
			</BtnsArea>
		</StyledSection>
	);
}

function MfaSetupStageSuccessScreen({ cancel }: HasCancelProp) {
	return (
		<StyledSection>
			<SectionTitle>
				Setup Complete: Two-Factor Authentication Enabled
			</SectionTitle>

			<p>
				You can view or adjust your two-factor authentication settings in the
				Password section of your account settings.
			</p>

			<BtnsArea>
				<Button
					data-qa-id="goto-settings-btn"
					size={BUTTON_SIZE}
					onClick={cancel}
				>
					Go to Settings
				</Button>
			</BtnsArea>
		</StyledSection>
	);
}

function MfaSetupStageErrorScreen({ cancel }: HasCancelProp) {
	return (
		<StyledSection>
			<SectionTitle>
				Setup Failed: Two-Factor Authentication Setup Failed
			</SectionTitle>

			<BtnsArea>
				<Button dataTestId="goto-settings-btn" onClick={cancel}>
					Go to Settings
				</Button>
			</BtnsArea>
		</StyledSection>
	);
}

export function DisableMultiFactorAuth({ cancel }: HasCancelProp) {
	const { refreshIsMfaEnabled } = useRefreshMfaEnabledInfo();
	const { addNotification } = useNotifications();
	const { isLoading, mutate: disableMFAForCurrentUser } =
		useDisableMFAForCurrentUserMutation({
			onSuccess: async () => {
				await refreshIsMfaEnabled();
				cancel();
			},
			onError: () => {
				addNotification({
					icon: 'error',
					title: 'MFA is required.',
					description: 'Cannot delete MFA secret.',
				});
				cancel();
			},
		});

	return (
		<MfaPageContainer>
			<StyledSection>
				<SectionTitle>Disable Two-Factor Authentication</SectionTitle>
				<p>Are you sure you want to turn off two-factor authentication?</p>

				<BtnsArea>
					<CancelBtn cancel={cancel} disabled={isLoading} />
					<PrimaryBtn
						text="Disable"
						isLoading={isLoading}
						onClick={disableMFAForCurrentUser}
					/>
				</BtnsArea>
			</StyledSection>
		</MfaPageContainer>
	);
}

function LinkToAuthApp({ link, name }: { link: string; name: string }) {
	return (
		<a
			href={link}
			data-qa-id={name + '-link'}
			target="_blank" // Open links in a new tab
			rel="noreferrer"
		>
			{name}
		</a>
	);
}
