import styled from 'styled-components';
import { Flex, AlertCircle, defaultTheme } from '@compstak/ui-kit';

export const modalStyles = {
	padding: '24px',
	marginTop: '225px',
};

export const closeButtonStyle = {
	display: 'none',
};

export const StyledAlertCircle = styled(AlertCircle)`
	svg {
		color: ${defaultTheme.colors.gray.gray600};
	}
`;

export const Title = styled.p`
	margin: 0 0 0 10px;
	font-size: 20px;
	line-height: 24px;
`;

export const Description = styled.p`
	margin: 16px 0 0 0;
	font-size: 14px;
	line-height: 20px;
`;

export const ControlsWrapper = styled(Flex)`
	margin-top: 16px;
	width: 100%;
`;

export const Label = styled.label`
	margin-bottom: 10px;
	font-size: 10px;
	line-height: 10px;
`;
