import { HOVERED_ROW_BG, LOADING_ROW_TYPE, SELECTED_ROW_BG } from './constants';
import { LoadingRow } from './types';

const isObject = (v: unknown): v is Record<string, unknown> =>
	v != null && typeof v === 'object';

export const isLoadingRow = (row: unknown): row is LoadingRow =>
	isObject(row) && row.type === LOADING_ROW_TYPE;

export const getCellBackgroundColor = (
	isLoading: boolean,
	isHovered: boolean,
	isSelected: boolean
) => {
	if (isLoading) return;
	if (isHovered) return HOVERED_ROW_BG;
	if (isSelected) return SELECTED_ROW_BG;
};
