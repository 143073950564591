import { Image } from '@react-pdf/renderer';
import { LargeLandlordsResponse } from 'api/largeLandlords/useFetchLargeLandlordsQuery';
import { formatNumber } from 'format';
import { PdfSectionBox } from '../PdfUI';

type Props = {
	url: string | null;
	largestLandlordsData: LargeLandlordsResponse | undefined;
};

export const LargestLandlordsPdfSection = ({
	url,
	largestLandlordsData,
}: Props) => {
	if (!largestLandlordsData) return null;

	return (
		<PdfSectionBox
			title="Largest Landlords"
			topRightInfo={`${formatNumber(
				largestLandlordsData.averageSqft
			)} sqft, Average Landlord Size`}
		>
			{url && <Image src={url} />}
		</PdfSectionBox>
	);
};
