import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import Multiselect from 'Components/Filters/Base/Multiselect';
import { useReferenceDataQuery } from 'api';
import React from 'react';

const attribute = 'leaseTypeId' as const;
export const LeaseTypeFilter = () => {
	const { data: referenceData } = useReferenceDataQuery();

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Multiselect
					{...props}
					attribute={attribute}
					onFilterChange={props.onFilterChange}
					options={referenceData.leaseTypes}
				/>
			)}
		</FilterBase>
	);
};
