import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';
import styled from 'styled-components';
import { CompType } from 'types';
import { SearchToolbarListToolbar } from './SearchToolbarListToolbar';
import { SearchToolbarMapToolbar } from './SearchToolbarMapToolbar';

type SearchToolbarProps = {
	compType: CompType;
	filters: FiltersObject;
	params: SearchLayoutRouteParams;
};

export const SearchToolbar = ({
	compType,
	filters,
	params,
}: SearchToolbarProps) => {
	const isListToolbar =
		params.view === 'list' || params.view === 'leagueTables';
	const isMapToolbar = !isListToolbar;
	return (
		<Root>
			{isMapToolbar && (
				<SearchToolbarMapToolbar
					compType={compType}
					filters={filters}
					params={params}
				/>
			)}
			{isListToolbar && (
				<SearchToolbarListToolbar
					compType={compType}
					filters={filters}
					params={params}
				/>
			)}
		</Root>
	);
};

const Root = styled.div`
	height: 90px;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
`;
