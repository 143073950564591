import { PortfolioShort } from 'api';
import { useMemo } from 'react';

export const usePortfoliosWithAggregations = (
	portfolios: PortfolioShort[] | undefined
) => {
	return useMemo(() => {
		if (!portfolios) return [];

		return portfolios.map((p) => {
			const { propertyCount } = p.markets.reduce(
				(agg, m) => {
					agg.propertyCount += m.propertyCount;
					return agg;
				},
				{ propertyCount: 0 }
			);

			return {
				...p,
				propertyCount,
			};
		});
	}, [portfolios]);
};

export type PortfolioShortWithAggregations = ReturnType<
	typeof usePortfoliosWithAggregations
>[number];
