import { useMemo } from 'react';
import { PropertyStatsParams } from './types';
import { usePropertyCompsetStatsQuery } from './usePropertyCompsetStatsQuery';
import { usePropertyLeasesStatsQuery } from './usePropertyLeasesStatsQuery';
import { usePropertyMarketStatsQuery } from './usePropertyMarketStatsQuery';
import { usePropertySubmarketStatsQuery } from './usePropertySubmarketStatsQuery';

type Props = PropertyStatsParams & {
	enabled?: boolean;
};

export const usePropertyStats = ({
	propertyId,
	metric,
	months,
	spaceTypes,
	enabled,
}: Props) => {
	const { data: marketData, isFetching: isFetchingMarket } =
		usePropertyMarketStatsQuery(
			{
				propertyId,
				metric,
				months,
				spaceTypes,
			},
			{ enabled }
		);

	const { data: submarketData, isFetching: isFetchingSubmarket } =
		usePropertySubmarketStatsQuery(
			{
				propertyId,
				metric,
				months,
				spaceTypes,
			},
			{ enabled }
		);

	const { data: compsetData, isFetching: isFetchingCompset } =
		usePropertyCompsetStatsQuery(
			{
				propertyId,
				metric,
				months,
				spaceTypes,
			},
			{ enabled }
		);

	const { data: leasesData, isFetching: isFetchingLeases } =
		usePropertyLeasesStatsQuery(
			{
				propertyId,
				metric,
				months,
				spaceTypes,
			},
			{ enabled }
		);

	return {
		data: useMemo(() => {
			if (!marketData || !submarketData || !compsetData || !leasesData)
				return undefined;
			return {
				market: marketData,
				submarket: submarketData,
				compset: compsetData,
				leases: leasesData,
			};
		}, [marketData, submarketData, compsetData, leasesData]),
		isFetching:
			isFetchingMarket ||
			isFetchingSubmarket ||
			isFetchingCompset ||
			isFetchingLeases,
	};
};
