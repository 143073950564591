import React, { memo } from 'react';
import { BUILDING_CLASS_ID_TO_NAME, BuildingClassId, Portfolio } from 'api';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { formatPercent } from 'format';
import { groupBy, mapValues } from 'lodash';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { ChartBox, ChartType, getTotalValues } from './ChartSelect';
import { PortfolioPieChart } from './PortfolioPieChart';
import { useNavigate } from 'react-router';
import { routes } from 'router';

type BuildingClassChartProps = {
	portfolio: Portfolio;
};

const BUILDING_CLASS_CHART_NAME = 'Building Class';

export const BuildingClassChart = memo(
	({ portfolio }: BuildingClassChartProps) => {
		const groupsByBuildingClassId = groupBy(
			portfolio.buildingClassesByMarkets,
			(p) => p.buildingClassId
		);

		const buildingClassIds = Object.keys(groupsByBuildingClassId).map(
			(buildingClassId) => Number(buildingClassId)
		) as BuildingClassId[];

		const totalValuesPerBuildingClass = mapValues(
			groupsByBuildingClassId,
			getTotalValues
		);
		const totalValuesForPortfolio = getTotalValues(
			Object.values(totalValuesPerBuildingClass)
		);

		const buildingClassBySqft = buildingClassIds.map((buildingClassId) => {
			return getChartPoint(
				buildingClassId,
				totalValuesPerBuildingClass[buildingClassId].totalSqFt,
				totalValuesForPortfolio.totalSqFt
			);
		});

		const buildingClassByLeaseAmount = buildingClassIds.map(
			(buildingClassId) => {
				return getChartPoint(
					buildingClassId,
					totalValuesPerBuildingClass[buildingClassId].activeLeaseAmount,
					totalValuesForPortfolio.activeLeaseAmount
				);
			}
		);

		const buildingClassByLeaseCount = buildingClassIds.map(
			(buildingClassId) => {
				return getChartPoint(
					buildingClassId,
					totalValuesPerBuildingClass[buildingClassId].activeLeaseCount,
					totalValuesForPortfolio.activeLeaseCount
				);
			}
		);

		const getData = (chartType: ChartType) => {
			switch (chartType) {
				case 'sf':
					return buildingClassBySqft;
				case 'leaseAmount':
					return buildingClassByLeaseAmount;
				case 'leaseCount':
					return buildingClassByLeaseCount;
				default:
					return [];
			}
		};

		const navigate = useNavigate();

		return (
			<ChartBox chartName={BUILDING_CLASS_CHART_NAME}>
				{(selectedChart) => {
					const data = getData(selectedChart.type);
					if (data.length === 0 || !data.some((d) => d.y > 0))
						return (
							<ChartContainer>
								<DataNotAvailableMessage
									chartName={`${BUILDING_CLASS_CHART_NAME} by ${selectedChart?.value}`}
								/>
							</ChartContainer>
						);

					return (
						<PortfolioPieChart
							data={data}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (_, clickedProps) => {
											const buildingClassId = clickedProps.datum
												.buildingClassId as BuildingClassId;
											navigate(
												routes.portfolioByIdView.toHref(
													{ portfolioId: portfolio.id, viewType: 'list' },
													{ buildingClassIds: [buildingClassId] }
												)
											);
										},
									},
								},
							]}
						/>
					);
				}}
			</ChartBox>
		);
	}
);

BuildingClassChart.displayName = 'BuildingClassChart';

const getChartPoint = (
	buildingClassId: BuildingClassId,
	value: number,
	totalValue: number
) => {
	return {
		x: BUILDING_CLASS_ID_TO_NAME[buildingClassId],
		y: value,
		label:
			totalValue !== 0
				? formatPercent(value / totalValue, 1)
				: formatPercent(0, 1),
		buildingClassId,
	};
};
