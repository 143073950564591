import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

import { Project } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import { useAppSelector } from 'util/useAppSelector';
import { useDispatch } from 'react-redux';
import { saveProject } from '../../actions';
import { useMarkets } from 'hooks/useMarkets';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalInputMessage,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { trimString } from 'util/trimString';

type RenameProjectModalProps = {
	project: Project;
};

export const RenameProjectModal = ({ project }: RenameProjectModalProps) => {
	const [name, setName] = useState(project.name);
	const [errorMessage, setErrorMessage] = useState('');

	const analyticsProjects = useAppSelector(
		(store) => store.analyticsProjects.projects
	);
	const markets = useMarkets();

	const dispatch = useDispatch();
	const { closeModal } = useModal();

	const updateName = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length <= 75) {
			setName(event.target.value);
			setErrorMessage('');
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onDone();
		}
	};

	const onDone = () => {
		if (name && name !== project.name) {
			const trimmedName = trimString(name);
			if (analyticsProjects?.some((p) => p.name === trimmedName)) {
				setErrorMessage(
					`The project ${trimmedName} already exists. Enter a new name to continue.`
				);
			} else {
				const renamedProject = {
					...project,
					name: trimmedName,
				};
				// @ts-expect-error TS2551: Property 'lastUpdated' does no...
				delete renamedProject.lastUpdated; // Ensure backend handles this correctly

				dispatch(saveProject(renamedProject, markets));
				closeModal();
			}
		}
	};

	const isDoneDisabled = !name || trimString(name) === project.name;

	return (
		<>
			<ModalTitle>Rename Project</ModalTitle>
			<ModalInputLabel isError={!!errorMessage}>
				Project Name
				<input
					type="text"
					value={name}
					onChange={updateName}
					onKeyDown={handleKeyDown}
				/>
				{!!errorMessage && (
					<ModalInputMessage>{errorMessage}</ModalInputMessage>
				)}
			</ModalInputLabel>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					variant="primary"
					disabled={isDoneDisabled}
					onClick={onDone}
				>
					Done
				</ModalButton>
			</ModalButtons>
		</>
	);
};
