import {
	CenterOnBounds,
	ClusterLayer,
	Map,
	MapInteractivity,
	MapStyle,
	MapTooltip,
	PIN_POPUP_OFFSET,
} from '@compstak/maps';
import { featureCollection, point } from '@turf/helpers';
import { Portfolio } from 'api';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { MapAnalyticsSettingsButton } from '../../../maps';
import { useMarketRentState } from '../MarketRentProvider';
import { useMarketRentPropertiesWithSummaries } from '../useMarketRentPropertiesWithSummaries';
import { MarketRentAnalytics } from './MarketRentAnalytics';
import { MarketRentMapOverlay } from './MarketRentMapOverlay';
import { MarketRentMapSettingsMenu } from './MarketRentMapSettingsMenu';
import { MarketRentMapTooltip } from './MarketRentMapTooltip';

type Props = {
	portfolioId: number;
	portfolio: Portfolio;
};

export const MarketRentMap = ({ portfolioId, portfolio }: Props) => {
	const [marketRentState, setMarketRentState] = useMarketRentState();
	const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

	const propertiesWithSummaries = useMarketRentPropertiesWithSummaries({
		portfolioId,
	});

	// merged property & summary data in feature properties
	const propertyPoints = useMemo(() => {
		return propertiesWithSummaries.map((p) => {
			return point([p.geoPoint.lon, p.geoPoint.lat], { ...p });
		});
	}, [propertiesWithSummaries]);

	const featureCollectionPropertyPoints = useMemo(() => {
		return featureCollection(propertyPoints);
	}, [propertyPoints]);

	const hasSelection =
		marketRentState.selectedMarketId != null &&
		marketRentState.selectedPropertyTypeId != null;

	const tooltipFeature = marketRentState.propertyPopup
		? point(
				[
					marketRentState.propertyPopup.geoPoint.lon,
					marketRentState.propertyPopup.geoPoint.lat,
				],
				marketRentState.propertyPopup
		  )
		: undefined;

	return (
		<MapContainer>
			<Map
				interactivity={MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM}
				mapStyle={MapStyle.MONOCHROME}
				logoPosition="top-left"
				attributionProps={{
					position: 'top-right',
				}}
				mapProps={{
					// circles are weird with polygons when increase the pitch
					pitchWithRotate: false,
					touchPitch: false,
				}}
			>
				<CenterOnBounds geoJSON={featureCollectionPropertyPoints} />
				{hasSelection && (
					<>
						<ClusterLayer
							data={propertyPoints}
							pinsLayerProps={{
								onHover: (info) => {
									setMarketRentState((prev) => ({
										...prev,
										propertyPopup: info.object?.properties ?? null,
									}));
								},
							}}
						/>
						<MarketRentAnalytics />
					</>
				)}
				{tooltipFeature && (
					<MapTooltip
						latitude={tooltipFeature.geometry.coordinates[1]}
						longitude={tooltipFeature.geometry.coordinates[0]}
						offset={PIN_POPUP_OFFSET}
					>
						<MarketRentMapTooltip
							propertyWithSummary={tooltipFeature.properties}
						/>
					</MapTooltip>
				)}
			</Map>
			<MapAnalyticsSettingsButton
				isMenuOpen={isSettingsMenuOpen}
				onClick={() => setIsSettingsMenuOpen(!isSettingsMenuOpen)}
				data-html2canvas-ignore
			/>
			{isSettingsMenuOpen && (
				<MarketRentMapSettingsMenu portfolio={portfolio} />
			)}
			{!hasSelection && <MarketRentMapOverlay portfolio={portfolio} />}
		</MapContainer>
	);
};

export const MapContainer = styled.div`
	border-radius: 3px;
	height: 424px;
	position: relative;
`;
