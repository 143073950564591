import { Chip, TooltipV2 } from '@compstak/ui-kit';
import styled from 'styled-components';

export const AnalyticsChip = () => {
	return (
		<TooltipV2 content="Thank you for being an Analytics subscriber">
			<div>
				<StyledChip>Analytics</StyledChip>
			</div>
		</TooltipV2>
	);
};

const StyledChip = styled(Chip)`
	position: relative;
	font-size: 10px;
	text-transform: uppercase;
	background-color: #009470;
	font-weight: 600;
	letter-spacing: 0.1em;
	padding: 0px 0.818em 0px 1em;
	display: block;
`;
