import { ColumnPreference } from '@compstak/common';
import {
	LOAN_SECTION,
	MUFA_SECTION,
	SearchTable,
	SearchTableExportButton,
} from 'Components';
import { ColumnConfig } from 'Components/SearchTable/columnConfigs';
import { TableSection } from 'Components/SearchTable/types';
import { routes } from 'router';
import { usePropertiesListAndMapViewContext } from './PropertiesListAndMapViewsProvider';
import { ExportCompsButton } from 'Components/Export';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const PortfolioTable = () => {
	const { portfolioRevampOverviewTabFF } = useFeatureFlags();

	const {
		getIsRowSelected,
		toggleRow,
		getAreAllSelected,
		toggleAllSelected,
		areSomeSelected,
		properties,
		isLoading,
		isFetching,
	} = usePropertiesListAndMapViewContext();

	const totalCount = properties?.length ?? 0;

	return (
		<SearchTable
			rows={properties ?? []}
			getRowId={(row) => row.id}
			isLoading={isFetching}
			noData={!isLoading && totalCount === 0}
			columnPreferencesType="property"
			getIsRowSelected={(row) => getIsRowSelected(row.id)}
			areAllSelected={getAreAllSelected()}
			areSomeSelected={areSomeSelected}
			toggleRowSelection={(row) => toggleRow(row.id)}
			toggleAllRowsSelection={toggleAllSelected}
			renderActionButton={(row) =>
				portfolioRevampOverviewTabFF ? (
					<ExportCompsButton
						compType="property"
						compIds={[row.id]}
						buttonType="wide-row"
					/>
				) : (
					<SearchTableExportButton compType="property" row={row} />
				)
			}
			getBodyCellProps={(row) => ({
				to: routes.propertyById.toHref({ propertyId: row.id }),
			})}
			filterColumnSections={(sections: TableSection[]) => {
				return sections.filter(
					(section) => !['loanInfo', 'mufa'].includes(section.id)
				);
			}}
			filterTableColumns={(columns: ColumnPreference[]) => {
				return columns.filter(
					(column) =>
						![...MUFA_SECTION.ids, ...LOAN_SECTION.ids].includes(
							column.compAttributeId as keyof ColumnConfig
						)
				);
			}}
		/>
	);
};
