import {
	MapAnalyticsFormSection,
	MapAnalyticsSectionTitle,
	MarketRentAnalyticsSharedFormControls,
	useMarketRentAnalyticsState,
} from 'maps';
import { MapAnalyticsSettingsCloseButton } from '../MapAnalyticsSettingsCloseButton';
import { MapAnalyticsForm, MapAnalyticsTitleInput } from '../UI';

export const MarketRentAnalyticsSettingsMenu = () => {
	const [state, setState] = useMarketRentAnalyticsState();

	return (
		<>
			<MapAnalyticsForm>
				<MapAnalyticsFormSection>
					<MapAnalyticsSectionTitle>Title</MapAnalyticsSectionTitle>
					<MapAnalyticsTitleInput
						value={state.title}
						onChange={(e) => {
							setState((s) => ({ ...s, title: e.target.value }));
						}}
					/>
				</MapAnalyticsFormSection>
				<MarketRentAnalyticsSharedFormControls />
			</MapAnalyticsForm>
			<MapAnalyticsSettingsCloseButton
				data-qa-id="market-rent-settings-close-btn"
				onClick={() => {
					setState((s) => ({ ...s, menuView: 'read' }));
				}}
			/>
		</>
	);
};
