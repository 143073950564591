import { Market } from '@compstak/common';
import { MarketRentAnalyticsRentType } from 'api';
import { MarketRentAnalyticsPeriod, useMarketRentAnalyticsState } from 'maps';

export const useMarketRentDescriptionText = (market: Market) => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();
	const { period, rentType, propertyType } = marketRentAnalyticsState;

	return `Map reflects estimated ${PERIOD_MAP[period]} ${RENT_TYPE_TEXT_MAP[rentType]} rent for ${propertyType} lease transactions in ${market.displayName}.`;
};

const PERIOD_MAP = {
	[MarketRentAnalyticsPeriod.YEAR]: 'annual',
	[MarketRentAnalyticsPeriod.MONTH]: 'monthly',
};

const RENT_TYPE_TEXT_MAP = {
	[MarketRentAnalyticsRentType.STARTING]: 'starting',
	[MarketRentAnalyticsRentType.EFFECTIVE]: 'effective',
};
