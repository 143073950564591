import { setAccessToken } from 'auth/auth';
import { useEffect } from 'react';
import { routes, useNavigate } from 'router';
import { PageSpinner } from './styles/PageSpinner';

const LandingPageTokenTransfer = () => {
	const [{ accessToken }] = routes.landingpagetoken.useSearchParams();
	const navigate = useNavigate();

	if (accessToken) {
		setAccessToken(accessToken);
	}

	useEffect(() => {
		navigate(routes.home.path, { replace: true });
	}, [navigate]);

	return <PageSpinner />;
};

export default LandingPageTokenTransfer;
