import { useUser } from 'Pages/Login/reducers';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { validateEmail as _validateEmail } from 'util/validate';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalParagraph,
	ModalScrollableInnerContent,
	ModalTitle,
} from '../common/UI';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';

import { SendToColleagueInput } from './ShareWithColleagueInput';
import { trimString } from 'util/trimString';

type Props = {
	itemName: string;
	modalTitle: string;
	buttonText: string;
	action: (emails: string[], note?: string) => void;
	isWithNote?: boolean;
};

export const ShareWithColleagueModal = (props: Props) => {
	const [emails, setEmails] = useState([
		{
			text: '',
			error: '',
		},
	]);
	const [note, setNote] = useState<string | undefined>(undefined);
	const [thankYou, setThankYou] = useState(false);
	const user = useUser();

	const { closeModal } = useModal();

	const validateEmail = (email: string) => {
		const error = _validateEmail(email, user);

		if (error) {
			return error;
		}

		const isDuplicate =
			new Set(emails.map(({ text }) => text)).size !== emails.length;

		if (isDuplicate) {
			return 'Duplicate email';
		}

		return '';
	};

	const handleChange = (idx: number, event: ChangeEvent<HTMLInputElement>) => {
		const text = event.target.value;

		const updatedEmails = emails.slice();

		updatedEmails[idx] = {
			text,
			error: '',
		};

		setEmails(updatedEmails);
	};

	const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const validatedEmails = emails.map((email) => ({
			...email,
			error: validateEmail(email.text),
		}));
		const hasError = validatedEmails.some(({ error }) => !!error);

		if (hasError) {
			setEmails(validatedEmails);
			return;
		}

		props.action(
			emails.map(({ text }) => trimString(text)),
			note ? trimString(note) : undefined
		);
		setThankYou(true);
	};

	const addEmail = () => {
		const updatedEmailList = emails.slice();
		updatedEmailList.push({
			text: '',
			error: '',
		});
		setEmails(updatedEmailList);
	};

	const removeEmail = (idx: number) => {
		const updatedEmailList = emails.slice();
		updatedEmailList.splice(idx, 1);
		setEmails(updatedEmailList);
	};

	if (thankYou) {
		const title = `Email${emails.length === 1 ? '' : 's'} Sent`;
		const text =
			emails.length === 1
				? `Email has been sent to ${emails[0].text} successfully`
				: 'Emails have been sent successfully';

		return (
			<>
				<ModalTitle>{title}</ModalTitle>
				<ModalParagraph>{text}</ModalParagraph>
				<ModalButtons>
					<ModalButton
						variant="primary"
						onClick={() => closeModal()}
						dataTestId="send-search-done-button"
					>
						DONE
					</ModalButton>
				</ModalButtons>
			</>
		);
	} else {
		return (
			<>
				<ModalTitle>{props.modalTitle}</ModalTitle>
				<ModalParagraph>
					Enter your colleagues' emails below to send them{' '}
					<b>{props.itemName}</b>
				</ModalParagraph>
				<RecipientsLabel>
					Recipients' Email Addresses:
					<ModalScrollableInnerContent>
						{emails.map((email, idx) => (
							<SendToColleagueInput
								key={idx}
								idx={idx}
								email={email}
								handleChange={(ev) => handleChange(idx, ev)}
								addEmail={addEmail}
								removeEmail={() => removeEmail(idx)}
							/>
						))}
					</ModalScrollableInnerContent>
				</RecipientsLabel>
				{props.isWithNote && (
					<ModalInputLabel>
						Note
						<textarea
							value={note}
							onChange={(e) => {
								setNote(e.target.value);
							}}
						/>
					</ModalInputLabel>
				)}
				<ModalButtons>
					<ModalButton
						variant="primary"
						dataTestId="send-search-submit-button"
						onClick={handleSubmit}
					>
						{props.buttonText}
					</ModalButton>
				</ModalButtons>
			</>
		);
	}
};

const RecipientsLabel = styled.label`
	color: ${({ theme }) => theme.colors.blue.blue400};
	display: block;
`;
