import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import React from 'react';

const attribute = 'owns' as const;

export const MyCompsFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					options={[
						{
							title: 'All Comps',
							value: null,
						},
						{
							title: 'Comps I own',
							value: 'own',
						},
						{
							title: "Comps I don't own",
							value: 'dont-own',
						},
						{
							title: 'Comps owned by my team',
							value: 'team',
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
