import { Portfolio } from 'api';
import React from 'react';
import styled from 'styled-components';
import { DownloadBranding } from '../Singletons/DownloadBranding';
import DownloadButton from '../Singletons/DownloadButton';
import { PortfolioHelpIcon } from '../Singletons/PortfolioHelpIcon';
import {
	MarketsHeading,
	MarketsHeadingWrapper,
	TabCardItem
} from '../styles/PortfolioUI';
import { Spacer } from '../UI';
import { MarketRentMap } from './MarketRentMap';
import { useMarketRentState } from './MarketRentProvider';
import { MarketRentTable } from './MarketRentTable';
import { useSectionTitle } from './useSectionTitle';

type Props = {
	portfolioId: number;
	portfolio: Portfolio;
};

export const MarketRent = ({ portfolioId, portfolio }: Props) => {
	const [marketRentState] = useMarketRentState();
	const { selectedMarketId, selectedPropertyTypeId } = marketRentState;

	const sectionTitle = useSectionTitle();

	const mainRef = React.useRef<null | HTMLDivElement>(null);

	const downloadBannerId = sectionTitle.replaceAll(' ', '');

	const shouldShowTable =
		selectedMarketId != null && selectedPropertyTypeId != null;

	const canExportImage =
		portfolio != null &&
		selectedMarketId != null &&
		selectedPropertyTypeId != null;

	return (
		<TabCardItem ref={mainRef}>
			<section>
				<MarketsHeadingWrapper>
					<MarketsHeading>
						<h4>{sectionTitle}</h4>
						<PortfolioHelpIcon tooltip={MARKET_RENT_TOOLTIP} />
					</MarketsHeading>
					<DownloadButton
						downloadRef={mainRef}
						filename={`${sectionTitle} - ${portfolio.name}`}
						disabled={!canExportImage}
						bannerId={downloadBannerId}
						data-qa-id="download-button-pa-market-rent"
					/>
				</MarketsHeadingWrapper>
				<MarketRentMap portfolioId={portfolioId} portfolio={portfolio} />
				{shouldShowTable && (
					<TableContainer>
						<MarketRentTable portfolioId={portfolioId} />
					</TableContainer>
				)}
			</section>
			<DownloadBranding bannerId={downloadBannerId} />
		</TabCardItem>
	);
};

const TableContainer = styled.div`
	margin-top: 0.5rem;
`;

const MARKET_RENT_TOOLTIP = (
	<>
		<Spacer>
			The Office Rent Heatmap is intended to help you visualize your properties
			and compare them to the market. Changing the filters at the top of the
			page will filter down which properties you see reflected here.
		</Spacer>
		<Spacer>
			The heatmap currently supports visualizing either Office or Industrial
			market rents which can by changed in the map settings gear icon.
		</Spacer>
		<div>
			CompStak’s market rent analysis is currently available for select markets.
		</div>
	</>
);
