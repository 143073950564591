import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';

const attribute = 'interestType' as const;

export const InterestTypeFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					options={[
						{
							title: 'Only partial interest transactions',
							value: 'Partial',
						},
						{
							title: 'Exclude partial interest transactions',
							value: 'Full',
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
