import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import TimespanButton from 'Components/Filters/Base/TimespanButton';
import React from 'react';

const attribute = 'dateCreated' as const;

export const DateCreatedFilter = ({
	children,
}: {
	children?: React.ReactNode;
}) => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<>
					<Between
						attribute={attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						setError={props.setError}
						touch={props.touch}
					/>
					<div className="presets">
						In the past... <br />
						<TimespanButton min={-1} max={0} unit="months" {...props}>
							Month
						</TimespanButton>
						<TimespanButton min={-3} max={0} unit="months" {...props}>
							3 Months
						</TimespanButton>
						<TimespanButton min={-1} max={0} unit="year" {...props}>
							Year
						</TimespanButton>
					</div>
					{children}
				</>
			)}
		</FilterBase>
	);
};
