import React from 'react';
import number from 'ui/util/number';

import styles from '../styles/team-settings.less';
import button from 'ui/styles/button.less';
import settings from '../styles/settings.less';
import spinner from 'ui/styles/spinner.less';
import { User } from '@compstak/common';
import { Team } from '../../../api/team/teamQueries';

class TeamItem extends React.Component {
	increment = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'increment' does not exist on type 'Reado... Remove this comment to see the full error message
		this.props.increment(this.props.member.id, 10);
	};

	decrement = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'increment' does not exist on type 'Reado... Remove this comment to see the full error message
		this.props.increment(this.props.member.id, -10);
	};

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message
		const adminEl = this.props.member.isTeamAdmin ? (
			<span className={styles.admin}>ADMIN</span>
		) : (
			''
		);
		const greenClass =
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.member.points === this.props.originalPoints
				? ''
				: styles.changed;

		return (
			<div className={styles.listItem}>
				<div className={styles.userLeft}>
					<div className={styles.userName}>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message */}
						{this.props.member.firstName} {this.props.member.lastName}
						{adminEl}
					</div>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message */}
					<div className={styles.userEmail}>{this.props.member.email}</div>
				</div>
				<div className={`${styles.userPoints} ${greenClass}`}>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message */}
					{number(this.props.member.points)}
				</div>
				<div className={styles.userControls}>
					<div className={styles.userAdd} onClick={this.increment}>
						+
					</div>
					<div className={styles.userSubtract} onClick={this.decrement}>
						-
					</div>
				</div>
			</div>
		);
	}
}

type TeamSettingsProps = {
	user: User;
	team: Team | null;
	assignCredits: (team: Team) => void;
};

type TeamSettingsState = {
	team: Team;
	edited: boolean;
	error: string;
};

export default class TeamSettings extends React.Component<
	TeamSettingsProps,
	TeamSettingsState
> {
	constructor(props: TeamSettingsProps) {
		super(props);
		this.state = {
			team: { ...props.team!, users: props.team?.users ?? [] },
			edited: false,
			error: '',
		};
	}

	componentWillReceiveProps(props: TeamSettingsProps) {
		if (props.team) {
			this.setState({ team: { ...props.team, users: props.team.users ?? [] } });
		}
	}

	increment = (memberId: number, delta: number) => {
		if (delta < 0 || this.extraPoints() > 0) {
			this.setState({
				edited: true,
				team: {
					...this.state.team,
					users: this.state.team.users.map((member) => {
						if (member.id === memberId) {
							const points = member.points + delta;
							return {
								...member,
								points,
							};
						} else {
							return member;
						}
					}),
				},
			});
		}
	};

	handleSave = (event: React.MouseEvent) => {
		event.preventDefault();
		if (this.extraPoints()) {
			this.setState({ error: 'Your extra points must be 0 to save.' });
		} else {
			this.setState({ error: '' });
			this.props.assignCredits(this.state.team);
		}
	};

	handleCancel = (event: React.MouseEvent) => {
		event.preventDefault();
		this.setState({
			edited: false,
			error: '',
			team: {
				...this.state.team,
				users: this.props.team?.users ?? [],
			},
		});
	};

	extraPoints() {
		return (
			-1 *
			this.state.team.users.reduce((acc, user, idx) => {
				const diff = user.points - this.props.team!.users[idx].points;
				if (diff === 0) {
					return acc;
				} else {
					return acc + diff;
				}
			}, 0)
		);
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	equalizeCredits = (event) => {
		event.preventDefault();
		const pointsTotal = this.state.team.users.reduce(
			(acc, user) => acc + user.points,
			0
		);
		const pointsAvg = Math.floor(pointsTotal / this.state.team.users.length);
		this.setState({
			team: {
				...this.state.team,
				users: this.state.team.users.map((user) => {
					if (user.id === this.props.user.id) {
						return {
							...user,
							points:
								pointsTotal - pointsAvg * (this.state.team.users.length - 1),
						};
					} else {
						return {
							...user,
							points: pointsAvg,
						};
					}
				}),
			},
		});
	};

	render() {
		const team = this.props.team;

		if (!team) {
			return <div className={spinner.large} />;
		}

		let edited = false;

		const teamList = this.state.team.users.map((member, idx) => {
			const originalPoints = team.users[idx].points;
			if (member.points - originalPoints) {
				edited = true;
			}
			return (
				<TeamItem
					// @ts-expect-error ts-migrate(2769) FIXME: Property 'member' does not exist on type 'Intrinsi... Remove this comment to see the full error message
					member={member}
					increment={this.increment}
					key={member.id}
					originalPoints={originalPoints}
				/>
			);
		});

		const redClass = this.extraPoints() > 0 ? 'red' : '';

		const rightEl = edited ? (
			<div>
				<a className={`${button.blue}`} onClick={this.handleSave}>
					Save
				</a>
				<a className={`${button.noOutline}`} onClick={this.handleCancel}>
					Cancel
				</a>
			</div>
		) : (
			<div className="info">
				<p>
					You may add or subtract credits for each team member by adjusting
					other members credits.
				</p>
				<p>
					In order to add credits to a team member you must first subtract
					credits from another team member.
				</p>
				<p>The extra points column must show '0' before saving changes.</p>
			</div>
		);

		return (
			<div className="settings_content">
				<div className={`${settings.form} ${styles.listContainer}`}>
					<div className={styles.inner}>
						<div className={styles.titleInner}>
							<div className={styles.title}>{team.name}</div>
							<div className={styles.summary}>
								{number(team.users.length)} members
							</div>
						</div>
						<div className={styles.header}>
							<a
								className={`${styles.pointsEqualize} ${button.lite}`}
								onClick={this.equalizeCredits}
							>
								Equalize Credits
							</a>
							<div className={styles.pointsTotal}>
								<div>{number(team.points)}</div>
								<div className={styles.pointsTeam}>TOTAL TEAM</div>
							</div>
							<div className={`${styles.pointsExtra} ${redClass}`}>
								<div>{number(this.extraPoints())}</div>
								<div className={styles.extraPointsNumber}>EXTRA</div>
							</div>
						</div>
						<div className={styles.list}>{teamList}</div>
					</div>
				</div>

				<div className={`${settings.explanation}`}>
					<section className={styles.right_panel}>
						<div className="explanation">
							{rightEl}
							<div className="error">{this.state.error}</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
