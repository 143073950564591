import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { useMemo } from 'react';

type Props = {
	filters: Partial<FiltersObject> &
		Pick<FiltersObject, 'sortDirection' | 'sortField'>;
};

export const useSearchFilterParams = ({ filters }: Props) => {
	return useMemo(
		() => ({
			order: filters.sortDirection,
			sort: filters.sortField,
			filter: filtersToServerJSON(filters),
		}),
		[filters]
	);
};
