import { useState } from 'react';

import styles from '../../repository.less';

import EmptyFolder from './EmptyFolder';
import ChartRow from './ChartRow';
import { Project, Chart } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import { useModal } from 'providers/ModalProvider';
import { SaveBeforeOpeningModal } from '../Modals/SaveBeforeOpeningModal';

type Props = {
	chartDraft: Chart;
	chart: Chart;
	redirect: (url: string) => void;
	markets: MarketsState;
	project: Project;
};

const ChartRepository = (props: Props) => {
	const [toggledChartId, setToggledChartId] = useState<null | number>(null);
	const [sortByAttribute, setSortByAttribute] = useState('lastUpdated');
	const [reversed, setReversed] = useState(false);

	const { openModal } = useModal();

	const handleChartToggle = (chartId: number) => {
		setToggledChartId((prevToggledChartId) =>
			prevToggledChartId === chartId ? null : chartId
		);
	};

	const openNewChart = (newChart: Chart) => {
		if (props.chartDraft && props.chartDraft.dataSets.length) {
			openModal({
				modalContent: <SaveBeforeOpeningModal newChart={newChart} />,
			});
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'chartBuilderActions' does not exist on t... Remove this comment to see the full error message
			props.chartBuilderActions.resetChartBuilder(newChart, props.markets);
			props.redirect('/analytics');
		}
	};

	const changeSort = (attribute: string) => (event: any) => {
		event.preventDefault();
		if (sortByAttribute === attribute) {
			setReversed(!reversed);
		} else {
			setSortByAttribute(attribute);
			setReversed(false);
		}
	};

	const sortBy =
		(attribute: string, reversed: boolean) =>
		(chart1: Chart, chart2: Chart) => {
			const reverse = reversed ? -1 : 1;
			if (attribute === 'title') {
				return chart1.title.localeCompare(chart2.title) * reverse;
			} else {
				return (
					// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
					(new Date(chart2[attribute]) - new Date(chart1[attribute])) * reverse
				);
			}
		};

	if (!props.project) {
		return (
			<div className={styles.emptyState}>
				<div className={styles.message}>
					Please select a project from the sidebar to view its contents
				</div>
			</div>
		);
	}

	const fieldNameMap = {
		name: 'title',
		dateCreated: 'dateCreated',
		dateModified: 'lastUpdated',
	};

	const fieldHeaders = ['name', 'dateCreated', 'dateModified'].map(
		(field, idx) => {
			const displayName = field
				.replace(/([A-Z])/g, ' $1')
				.replace(/^./, function (str) {
					return str.toUpperCase();
				});

			// @ts-expect-error TS7053: Element implicitly has an 'any...
			if (fieldNameMap[field] === sortByAttribute) {
				const arrowClass = reversed ? styles.up : styles.down;
				return (
					<span
						className={`${styles[field]} ${styles.sortingField}`}
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						onClick={changeSort(fieldNameMap[field])}
						key={idx}
					>
						<span className={styles.fieldName}>{displayName}</span>
						<span className={`${styles.arrow} ${arrowClass}`} />
					</span>
				);
			} else {
				return (
					<span
						className={styles[field]}
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						onClick={changeSort(fieldNameMap[field])}
						key={idx}
					>
						<span className={styles.fieldName}>{displayName}</span>
						<span className={`${styles.arrow} ${styles.down}`} />
					</span>
				);
			}
		}
	);

	const chartRows = props.project.charts
		.sort(sortBy(sortByAttribute, reversed))
		.map((chart, idx) => (
			<ChartRow
				chart={chart}
				project={props.project}
				colorClass={idx % 2 ? styles.darkRow : ''}
				key={chart.id}
				handleToggle={handleChartToggle}
				toggled={chart.id === toggledChartId}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'referenceData' does not exist on type 'R... Remove this comment to see the full error message
				referenceData={props.referenceData}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'chartBuilderActions' does not exist on t... Remove this comment to see the full error message
				resetChartBuilder={props.chartBuilderActions.resetChartBuilder}
				openNewChart={openNewChart}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
				analyticsProjectActions={props.analyticsProjectActions}
				markets={props.markets}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsLastUpdated' does not exist on ... Remove this comment to see the full error message
				analyticsLastUpdated={props.analyticsLastUpdated}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
				user={props.user}
			/>
		));

	return (
		<div className={styles.table}>
			<div className={styles.header}>
				<span className={styles.title}>Projects {props.project.name}</span>
			</div>
			<div className={styles.fields}>
				<span className={styles.arrowBoxHeader} />
				{fieldHeaders}
			</div>
			{chartRows.length > 0 ? chartRows : <EmptyFolder />}
		</div>
	);
};

export default ChartRepository;
