import {
	SEARCH_MAX_LIMIT,
	SearchLeasesPayload,
	useSaleCompQuery,
	useSearchLeasesQuery,
} from 'api';
import { useMemo } from 'react';
import { ServerFilterItem } from 'types/serverFilters';

type Props = {
	saleId: number;
	sort?: SearchLeasesPayload['sort'];
};

export const useLeaseSnapshotData = ({
	saleId,
	sort = 'executionQuarter',
}: Props) => {
	const { data: sale } = useSaleCompQuery({ id: saleId });

	const filters = useMemo(() => {
		if (!sale) return [];

		const filters: ServerFilterItem[] = [
			{ property: 'hidden', value: false },
			{
				property: 'address',
				value: sale.portfolio.map((p) => p.buildingAddressAndCity),
			},
			{
				property: 'marketName',
				value: sale.market,
			},
		];
		return filters;
	}, [sale]);

	return useSearchLeasesQuery(
		{
			filter: filters,
			limit: SEARCH_MAX_LIMIT,
			offset: 0,
			sort,
			order: 'desc',
		},
		{
			enabled: filters.length > 0,
		}
	);
};
