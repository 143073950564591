import React from 'react';
import { Cell } from 'fixed-data-table-2';
import { FiltersObject } from 'models/filters/types';
import { TableField } from 'types/table';
import styled from 'styled-components';

type HeaderCellFieldProps = {
	children: React.ReactNode;
	field: TableField;
	filters: FiltersObject;
	onFilterChange?: (newFilters: Partial<FiltersObject>) => void;
};

export const HeaderCell = (props: HeaderCellFieldProps) => {
	let className = props.field.backendSortName
		? 'header-cell sortable '
		: 'header-cell ';
	if (props.filters.sortField === props.field.backendSortName) {
		className += props.filters.sortDirection + ' active-sort';
	}

	return (
		<StyledCell
			data-tooltip={props.field.tooltip}
			data-tooltip-delay={500}
			className={className}
		>
			{props.children}
		</StyledCell>
	);
};

const StyledCell = styled(Cell)`
	&& .public_fixedDataTableCell_cellContent {
		margin-left: 0;
	}
`;
