import { Pending, SaveDataMenu, SimpleTable } from '@compstak/ui-kit';
import { useNavigate, routes } from 'router';
import { useSubmarketRecentLeasesTableColumns } from './useSubmarketRecentLeasesTableColumns';
import { useSubmarketRecentLeases } from 'api';
import { useExcelData } from 'utils';
import { useSubmarketRecentLeasesExcelColumns } from './useSubmarketRecentLeasesExcelColumns';
import styled from 'styled-components';
import { SUBMARKET_RECENT_LEASES_LIMIT } from 'constants/limits';

type SubmarketRecentLeasesTableProps = {
	propertyId: number;
	marketId: number;
	submarket: string;
	limit?: number;
};

export const SubmarketRecentLeasesTable = ({
	propertyId,
	marketId,
	submarket,
	limit = SUBMARKET_RECENT_LEASES_LIMIT,
}: SubmarketRecentLeasesTableProps) => {
	const navigate = useNavigate();

	const { data, isLoading } = useSubmarketRecentLeases({
		propertyId,
		limit,
	});

	const title = `Top ${limit} most recent leases in ${submarket}`;
	const columns = useSubmarketRecentLeasesTableColumns(marketId);

	const excelColumns = useSubmarketRecentLeasesExcelColumns(marketId);
	const excelData = useExcelData({
		rows: data?.comps ?? [],
		columns: excelColumns,
	});

	return (
		<div>
			<TitleContainer>
				<TableTitle>{title}</TableTitle>
				<SaveDataMenu
					elementId={TABLE_ID}
					// @ts-expect-error legacy
					dataToCopy={excelData}
					downloadFileName={title}
				/>
			</TitleContainer>
			{isLoading && (
				<PendingContainer>
					<Pending times={4} />
				</PendingContainer>
			)}
			{data && (
				<SimpleTable
					id={TABLE_ID}
					columns={columns}
					rows={data.comps}
					isBodyHoverable
					getBodyCellProps={({ row }) => ({
						onClick: () => {
							navigate(routes.leaseById.toHref({ id: row.id }), {
								state: {
									fromNewPropertyPage: true,
								},
							});
						},
					})}
				/>
			)}
		</div>
	);
};

export const TableTitle = styled.div`
	width: 440px;
	background-color: ${({ theme }) => theme.colors.gray.gray200};
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 24px;
	padding: 4px 40px 4px 4px;
	border-radius: 3px;
`;

const TitleContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const PendingContainer = styled.div`
	height: 300px;
`;

const TABLE_ID = 'submarket-recent-leases-table';
