import { Portfolio } from 'api';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import styled, { css } from 'styled-components';
import { getPropertyTypeForSelectionForMarket } from '../getPropertyTypeForSelectionForMarket';
import { useMarketRentState } from '../MarketRentProvider';
import Dropdown from './Dropdown';
import { useMarketOptions } from './useMarketOptions';
import { usePropertyTypeOptions } from './usePropertyTypeOptions';
import IconMap from 'ui/svg_icons/map_folded.svg';
import { useSectionTitle } from '../useSectionTitle';

type Props = {
	portfolio: Portfolio;
};

export const MarketRentMapOverlay = ({ portfolio }: Props) => {
	const [marketRentState, setMarketRentState] = useMarketRentState();
	const marketOptions = useMarketOptions({ portfolio });
	const propertyTypeOptions = usePropertyTypeOptions({ portfolio });

	const noOptions = marketOptions.length === 0;
	const chartTitle = useSectionTitle();

	return (
		<Container>
			{noOptions ? (
				<DataNotAvailableMessage
					icon={<IconMap />}
					title={'Map not available'}
					chartName={chartTitle}
				/>
			) : (
				<Box>
					<form>
						{marketRentState.selectedMarketId == null && (
							<>
								<Label>Market selection</Label>
								<Dropdown
									items={marketOptions}
									activeItemId={marketRentState.selectedMarketId}
									onSelect={(id) => {
										setMarketRentState((prev) => ({
											...prev,
											selectedMarketId: id,
											selectedPropertyTypeId:
												getPropertyTypeForSelectionForMarket(
													id,
													portfolio?.propertyTypesByMarkets ?? []
												) ?? null,
										}));
									}}
								/>
							</>
						)}
						{marketRentState.selectedMarketId != null && (
							<>
								<Label>Property type selection</Label>
								<Dropdown
									items={propertyTypeOptions}
									activeItemId={marketRentState.selectedPropertyTypeId}
									onSelect={(id) => {
										setMarketRentState((prev) => ({
											...prev,
											selectedPropertyTypeId: id,
										}));
									}}
								/>
							</>
						)}
					</form>
				</Box>
			)}
		</Container>
	);
};

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;

export const Box = styled.div`
	background: rgba(255, 255, 255, 0.8);
	border-radius: 3px;
	min-height: 200px;
	padding: 8px;
	min-width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const fontStyle = css`
	color: rgba(0, 0, 0, 0.87);
	font-family: Gotham;
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
`;

export const Label = styled.label`
	${fontStyle};
	margin-bottom: 16px;
`;

export const MessageText = styled.div`
	${fontStyle};
	color: #50576d;
	text-align: center;
	margin-bottom: 16px;
`;
