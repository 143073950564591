import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';

const attribute = 'dataSource' as const;

export const DataSourceFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					options={[
						{
							title: 'Only CompStak',
							value: 'CompStak',
						},
						{
							title: 'Only Public Record',
							value: 'Public Record',
						},
					]}
					{...props}
				/>
			)}
		</FilterBase>
	);
};
