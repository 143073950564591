import { FiltersObject } from 'models/filters/types';

export const getExpirationaAndExecutionDateFilterWarning = (
	filters: Partial<FiltersObject> | null
) => {
	if (!filters) {
		return undefined;
	}
	const excutionDateFilterInDataSet = filters['executionDate'];
	const expirationDateFilterInDataSet = filters['expirationDate'];
	const incompatibleFilters = excutionDateFilterInDataSet
		? expirationDateFilterInDataSet
			? 'Execution Date and Expiration Date Filters are'
			: 'Execution Date Filter is'
		: expirationDateFilterInDataSet
			? 'Expiration Date Filter is'
			: undefined;

	const IncompatibleFiltersWarningMessage = incompatibleFilters
		? `Note: ${incompatibleFilters} not supported in Chartbuilder and will be removed from the filters applied to the dataset.`
		: undefined;
	return IncompatibleFiltersWarningMessage;
};
