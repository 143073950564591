import { FC, ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Logo from '../../../ui/svg_icons/logo_new.svg';
import { useAppConfig, useUser } from '../reducers';
import styles from '../stylesheets/login.less';

export const LoginLayoutWrapper: FC<{
	children: ReactNode;
	transitionKey: string;
}> = ({ children, transitionKey }) => {
	return (
		<div className={styles.pageLogin + ' ' + styles.mainlogin}>
			<div className={styles.loginForm}>
				<div>
					<span className={styles.csIconContainer}>
						<Logo />
					</span>

					<div className={styles.csIconText}>COMPSTAK</div>
				</div>
				<TransitionGroup>
					<CSSTransition
						key={transitionKey}
						classNames="login-screen"
						timeout={1000}
					>
						{children}
					</CSSTransition>
				</TransitionGroup>
			</div>

			<SignupLink />
		</div>
	);
};

function SignupLink(): JSX.Element | null {
	const user = useUser();
	const appConfig = useAppConfig();
	if (user) {
		return null;
	}

	let link = '/';
	if (appConfig) {
		const enterpriseOrExchange = appConfig.isEnterprise
			? 'enterprise'
			: 'exchange';
		link = `https://compstak.com/${enterpriseOrExchange}/signup`;
	}

	return (
		<div className={styles.bottomText}>
			<span>Don’t have an account?</span>
			<a
				style={{ marginLeft: 8, fontWeight: 400 }}
				data-qa-id="sign-up-link"
				href={link}
				target="_blank"
				rel="noreferrer"
			>
				Sign up
			</a>
		</div>
	);
}
