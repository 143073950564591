import { defaultTheme } from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Checkbox } from 'Components';
import { formatCsv } from 'format';
import { ComponentProps, useMemo } from 'react';
import { FilterOption } from '../types';
import {
	FilterButtonIconArrow,
	FilterButtonIconContainer,
	FilterButtonLabel,
	FilterButtonLabelContainer,
	FilterButtonValue,
	FilterDropdownButton,
	FilterDropdownCheckboxItem,
	FilterDropdownContent,
} from '../UI';

type Props<T extends number | string> = {
	label: string;
	emptyValueLabel: string;
	options: FilterOption<T>[];
	value: T[];
	onChange: (value: T[]) => void;
	buttonProps?: Omit<ComponentProps<'button'>, 'ref'>;
};

export const CheckboxFilter = <T extends number | string>({
	label,
	emptyValueLabel,
	options,
	value,
	onChange,
	buttonProps,
}: Props<T>) => {
	const areAllSelected = useMemo(
		() => options.every((o) => value.includes(o.value)),
		[options, value]
	);

	const onToggleSelectAll = () => {
		if (areAllSelected) {
			onChange([]);
		} else {
			onChange(options.map((o) => o.value));
		}
	};

	const onToggleOption = (option: FilterOption<T>, checked: boolean) => {
		const newValue = option.value;

		if (checked) {
			onChange([...value, newValue]);
		}
		//
		else {
			onChange(value.filter((id) => id !== newValue));
		}
	};

	const displayValue = useMemo(() => {
		return value.length === 0
			? emptyValueLabel
			: formatCsv(
					options.filter((o) => value.includes(o.value)).map((o) => o.label)
				);
	}, [value, options, emptyValueLabel]);

	return (
		<DropdownMenu.Root modal={false}>
			<FilterDropdownButton {...buttonProps}>
				<FilterButtonLabelContainer>
					<FilterButtonLabel>{label}</FilterButtonLabel>
					<FilterButtonValue>{displayValue}</FilterButtonValue>
				</FilterButtonLabelContainer>
				<FilterButtonIconContainer>
					<FilterButtonIconArrow width={10} height={6} />
				</FilterButtonIconContainer>
			</FilterDropdownButton>
			<DropdownMenu.Portal>
				<FilterDropdownContent>
					<FilterDropdownCheckboxItem
						checked={areAllSelected}
						onSelect={(e) => {
							e.preventDefault();
							onToggleSelectAll();
						}}
					>
						<Checkbox
							checked={areAllSelected}
							bgColorUnchecked={defaultTheme.colors.white.white}
						/>{' '}
						Select All
					</FilterDropdownCheckboxItem>
					{options.map((option) => {
						const isChecked = value.includes(option.value);
						return (
							<FilterDropdownCheckboxItem
								key={option.value}
								checked={isChecked}
								onSelect={(e) => {
									e.preventDefault();
								}}
								onCheckedChange={(checked) => {
									onToggleOption(option, checked);
								}}
							>
								<Checkbox
									checked={isChecked}
									bgColorUnchecked={defaultTheme.colors.white.white}
								/>{' '}
								{option.label}
							</FilterDropdownCheckboxItem>
						);
					})}
				</FilterDropdownContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
