import { Button, Flex } from '@compstak/ui-kit';
import styled from 'styled-components';

export const RewardsInfoWrapper = styled(Flex)`
	margin-top: 40px;
`;

export const BalanceHeading = styled.h2`
	margin-bottom: 8px;
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0.3px;

	span {
		color: ${({ theme }) => theme.colors.green.green500};
	}
`;

export const CancelButton = styled(Button)`
	background: ${({ theme }) => theme.colors.neutral.n30};
	color: ${({ theme }) => theme.colors.gray.gray700};
	margin-right: 8px;

	&&&:hover {
		background: ${({ theme }) => theme.colors.neutral.n50};
	}
`;

export const HistoryHeading = styled.h2`
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 8px;
	letter-spacing: 0.3px;
`;

export const HistoryWrapper = styled.div`
	width: 260px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray700};

	p {
		margin: 0;
	}

	div {
		padding: 12px 16px;
	}

	> div {
		&:first-of-type {
			font-weight: 400;
			color: white;
			text-transform: uppercase;
			background: ${({ theme }) => theme.colors.gray.gray700};

			p {
				letter-spacing: 0.3px;
			}
		}

		&:nth-of-type(2) {
			height: 250px;
			padding: 0;
			overflow-y: scroll;

			> div {
				padding: 12px 16px;
				border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.n30};
			}
		}

		&:nth-of-type(3) {
			font-weight: bold;
			color: ${({ theme }) => theme.colors.gray.gray700};
			background: ${({ theme }) => theme.colors.neutral.n30};
		}
	}
`;

export const ContactUsHeading = styled.h2`
	font-size: 24px;
	font-weight: 400;
	margin: 40px 0;
	letter-spacing: 0.3px;
`;

export const IconContainer = styled.div`
	svg {
		width: 64px;
		height: 64px;
	}
`;

export const Suggestions = styled(Flex)`
	gap: 64px;
`;

export const SuggestionBlock = styled(Flex)`
	width: 360px;
	padding: 24px;
	text-align: center;
	background: white;

	h3 {
		margin-top: 16px;
		font-size: 20px;
		font-weight: bold;
	}

	p {
		margin: 8px 0 16px;
	}
`;

export const SpinnerWrapper = styled(Flex)`
	margin-top: 20px;
`;

export const ModalBobyWrapper = styled.div`
	h3 {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 23px;
	}

	> p {
		font-size: 16px;
		margin-bottom: 8px;
		font-weight: bold;

		span {
			color: ${({ theme }) => theme.colors.green.green500};
		}

		&:last-of-type {
			font-weight: 300;
		}
	}

	div {
		flex-wrap: wrap;
		flex: 0 0 21%;

		img {
			width: 100%;
		}
	}
`;

export const InputWrapper = styled.div<{ error: boolean }>`
	margin-bottom: 23px;

	> input {
		width: 100%;
		height: 40px;
		margin: 0;
		padding: 8px 16px;
		border: 1px solid;
		border-radius: 0;

		&::placeholder {
			font-style: italic;
			color: ${({ theme, error }) =>
				error ? theme.colors.red.red500 : theme.colors.neutral.n50} !important;
		}

		border-color: ${({ theme, error }) =>
			error ? theme.colors.red.red500 : theme.colors.neutral.n50} !important;
	}
`;

export const VendorsWrapper = styled(Flex)`
	margin-bottom: 23px;
`;

export const Vendor = styled.div<{ selected: boolean }>`
	border: 2px solid;
	border-color: ${({ theme, selected }) =>
		selected ? theme.colors.blue.blue400 : 'transparent'};
	cursor: pointer;
`;

export const ButtonWrapper = styled(Flex)`
	margin: 14px 0 0;
`;

export const ErrorWrapper = styled(Flex)`
	p {
		color: ${({ theme }) => theme.colors.red.red500};
		margin: 0 5px;
	}

	svg {
		width: 18px;
		color: ${({ theme }) => theme.colors.red.red500};
	}
`;

export const ErrorMsg = styled.p`
	margin: 0;
	color: ${({ theme }) => theme.colors.red.red500};
`;
