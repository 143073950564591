import { Image } from '@react-pdf/renderer';
import { PdfSectionBox } from '../PdfUI';
import { PdfSectionProps } from '../constants';

export function LeaseExpirationByPropertyTypePdf({
	leaseExpirationPropertyType: { url },
}: PdfSectionProps) {
	return (
		<PdfSectionBox title="Lease expiration by property type">
			{url && <Image src={url} style={{ marginBottom: 12 }} />}
		</PdfSectionBox>
	);
}
