import { useQueries } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import {
	fetchOpportunityZonesByMarket,
	UseOpportunityZonesByMarketQueryParams,
	UseOpportunityZonesByMarketQueryOptions,
} from './useOpportunityZonesByMarketQuery';

export const useOpportunityZonesByMarketsQueries = (
	params: UseOpportunityZonesByMarketQueryParams[],
	options?: UseOpportunityZonesByMarketQueryOptions
) => {
	const markets = params;

	return useQueries({
		queries: markets.map((market) => {
			const marketId = market?.id;

			return {
				queryKey: [QUERY_KEYS.OPPORTUNITY_ZONES, marketId],
				queryFn: () => fetchOpportunityZonesByMarket(marketId),
				refetchOnWindowFocus: false,
				enabled: !!marketId,
				...options,
			};
		}),
	});
};
