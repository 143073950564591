import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Feature, MultiPolygon, Polygon } from '@turf/helpers';
import { QUERY_KEYS } from 'api/constants';
import { noRetryFor404 } from 'api/utils/queryRetriesMisc';
import { mapUrlToGateway } from 'utils/gatewayUtil';

export const useAPNPolygonQuery = (
	params: UseAPNPolygonQueryParams,
	options?: UseAPNPolygonQueryOptions
) => {
	const { apn, fips } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.COUNTY_POLYGON_BY_FIPS, params],
		queryFn: async () => {
			const response = await API.get<APNPolygon>(
				mapUrlToGateway(`/ppm-property/api/parcel/stable-id/${fips}/${apn}`)
			);

			return response.data;
		},
		retry: noRetryFor404,
		...options,
	});
};

export type UseAPNPolygonQueryOptions = UseQueryOptions<APNPolygon>;

type UseAPNPolygonQueryParams = {
	apn: string;
	fips: string;
};

type APNProperties = {
	id: string;
	_meta: {
		start: string;
		end: string;
		source: string;
	};
	fips: string;
	apn: string;
	attributes: object;
};

export type APNPolygon = Feature<Polygon | MultiPolygon, APNProperties>;
