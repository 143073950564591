import React from 'react';
import { PdfPage } from '../../../../pdf/PdfPage';
import { PdfPageProps } from '../types';
import { useTrendsToShow } from 'Pages/PropertyPageV3/SubmarketTrendsTab/useTrendsToShow';
import { CommercialSubmarketTrends } from './CommercialSubmarketTrends';
import { MufaSubmarketTrends } from './MufaSubmarketTrends';
import { SubmarketRecentColumn } from './useSubmarketRecentLeasesPdfColumns';
import { SubmarketRecentLeasesPdfTable } from './SubmarketRecentLeasesPdfTable';
import { ImageUrls } from 'Pages/PropertyPageV3/ExportPdf/ExportPdfStepTwo';

type Props = PdfPageProps & {
	trendsToShow: ReturnType<typeof useTrendsToShow>;
	trendChartsURL: ImageUrls;
	columns: SubmarketRecentColumn[];
	editableSubmarketRecentLeasesRow: Record<string, string | number>[];
};

export const SubmarketTrendsPage = (props: Props) => {
	const {
		trendsToShow,
		trendChartsURL,
		columns,
		editableSubmarketRecentLeasesRow,
		...pdfPageProps
	} = props;

	return (
		<PdfPage
			logo={props.page.logo}
			preparedFor={props.page.preparedFor}
			title={props.page.titles.submarketTrends}
			user={props.user}
		>
			{trendsToShow.mufaTrends &&
				trendChartsURL.mufa1 &&
				trendChartsURL.mufa2 && (
					<MufaSubmarketTrends
						{...pdfPageProps}
						trendChartsURL={trendChartsURL}
					/>
				)}

			{trendsToShow.commercialTrends && trendChartsURL.commercial && (
				<CommercialSubmarketTrends
					{...pdfPageProps}
					src={trendChartsURL.commercial}
				/>
			)}

			{editableSubmarketRecentLeasesRow.length > 0 && (
				<SubmarketRecentLeasesPdfTable
					{...pdfPageProps}
					editableSubmarketRecentLeasesRow={editableSubmarketRecentLeasesRow}
					columns={columns}
				/>
			)}
		</PdfPage>
	);
};
