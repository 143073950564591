import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	height: 18px;
	margin-bottom: 8px;
`;

export const ItemsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 24px;
	width: 100%;
`;

export const Spacer = styled.div`
	width: 13.5%;
`;

export const Item = styled.div<{ color: string }>`
	display: flex;

	div {
		width: 18px;
		height: 18px;
		background-color: ${({ color }) => color};
		border-radius: 3px;
		margin-right: 4px;
	}

	span {
		font-family: Gotham;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: #303441;
		margin-top: 1px;
	}
`;
