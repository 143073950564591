import React, { useState, memo, useMemo } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Flex } from '@compstak/ui-kit';
import {
	Chart,
	DataSet,
	DataSetType,
	InsightDataResponse,
} from 'Pages/Analytics/analytics';
import { ModalActions } from 'Singletons/Modal/actions';
import {
	expandDataSet,
	updateDataSet,
	updateDataSetFilters,
} from '../../actions';
import { getColorForDataSetIndex } from '../../colors';
import '../Menus/DatasetMenu';

import {
	ArrowDownExpand,
	Counter,
	DatasetContainer,
	DataSetErrorIndicator,
	DatasetSummary,
	EyeOffStyled,
	EyeStyled,
	FormWrapper,
	MoreFiltersButton,
	RightGroupWrapper,
	StyledAngleArrow,
	StyledThreeDot,
	TileClickContainer,
	Title,
	TitleWrapper,
	TopContainer,
} from './Components';
import { DataSetFormLegacyAdapter } from './DataSetFormLegacyAdapter';

import { attributeToPlotValuesToTitles } from '../Modals/DataSets/CreateNewDataSetV2/utils';
import {
	findDSIndexInDataArray,
	getDatasetStats,
	getStatsCounter,
	CounterType,
	getCounterTooltip,
} from '../helpers';
import { FiltersObject } from 'models/filters/types';
import {
	PROPERTY_TYPE_ID_TO_NAME,
	PropertyTypeId,
	SPACE_TYPE_ID_TO_NAME,
	SPACE_TYPE_NAME_TO_ID,
	SpaceTypeId,
} from 'api/referenceData/useReferenceDataQuery';
import { FilterFieldContextProvider } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { DataSetMoreFilters } from './DataSetFilters';
import { getCompTypeFromDataSetType } from '../../chartBuilderHelpers';
import { useDispatch } from 'react-redux';
import { showMenu } from 'Singletons/Menu/actions';
import { DatasetMenuProps } from '../Menus/DatasetMenu';
import { useMarkets } from 'hooks/useMarkets';
import { useModal } from 'providers/ModalProvider';
import { ApplyAllModal } from '../Modals/ApplyAllModal';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

type DatasetV2Props = {
	chartDraft: Chart;
	dataSet: DataSet;
	dataSets: DataSet[];
	expanded: boolean;
	expandedDataSetIndex: number | null;
	index: number;
	modalActions: ModalActions;
	insightsData: InsightDataResponse[];
};

const DatasetV2Impl = memo<DatasetV2Props>(
	({
		index,
		dataSet,
		dataSets,
		expandedDataSetIndex,
		insightsData,
		chartDraft,
		expanded,
	}) => {
		const [areFiltersVisible, setAreFiltersVisible] = useState(false);

		const { openModal } = useModal();
		const markets = useMarkets();
		const compType = getCompTypeFromDataSetType(dataSet.type);

		const titleRef = React.useRef<HTMLElement>(null);
		const isTitleOverflown = useMemo(() => {
			if (titleRef.current)
				return titleRef.current.scrollWidth > titleRef.current.clientWidth;
		}, [titleRef.current]);

		const colorFromDatasetIndex = useMemo(
			() => getColorForDataSetIndex(index),
			[index]
		);

		const insightsDataItem =
			insightsData[findDSIndexInDataArray(chartDraft.dataSets, dataSet)];

		const stats = getDatasetStats({
			insightsDataItem,
			timespan: chartDraft.timespan,
		});

		const dispatch = useDispatch();

		const marketName = dataSet.filters?.market?.displayName || 'N/A';

		const spaceTypeIds = dataSet.filters?.spaceTypeId
			? dataSet.filters.spaceTypeId
			: null;

		const propertyTypeIds = dataSet.filters?.buildingPropertyTypeId
			? dataSet.filters.buildingPropertyTypeId
			: null;

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		const onExpandDataSet = (event) => {
			if (!event.defaultPrevented) {
				if (index === expandedDataSetIndex) {
					dispatch(expandDataSet(null));
				} else {
					dispatch(expandDataSet(index));
				}

				setAreFiltersVisible(false);
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		const showSidebarMenu = (event) => {
			event.preventDefault();
			dispatch(
				showMenu<DatasetMenuProps>('dataset-menu', event.target, 'onright', {
					chartDraft,
					dataSet,
					dataSets,
				})
			);
		};

		const handleApplyAll = (val: AttributeToPlotType) => {
			openModal({
				modalContent: (
					<ApplyAllModal chartDraft={chartDraft} attributeToPlotType={val} />
				),
			});
		};

		const updateSeries = (newSeries: AttributeToPlotType) => {
			const newDataSet = {
				...dataSet,
				series: newSeries,
			};
			dispatch(updateDataSet(chartDraft, newDataSet, markets));
		};

		const onFilterChange = (newFilters: Partial<FiltersObject>) => {
			dispatch(
				updateDataSetFilters(dataSet.id, newFilters, chartDraft, markets)
			);
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		const toggleDSVisibility = (event) => {
			event.preventDefault();
			const updatedDataSet = {
				...dataSet,
				isVisible: !dataSet.isVisible,
			};
			dispatch(updateDataSet(chartDraft, updatedDataSet, markets));
		};

		const toggleFiltersVisibility = () => setAreFiltersVisible((prev) => !prev);

		const moreFiltersCount = useMemo(() => {
			const dataSetFilters = dataSet.filters;

			const {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				submarkets,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				spaceTypeId,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				buildingPropertyTypeId,
				...dataSetFiltersToCount
			} = dataSetFilters;

			return getSetFilterKeysExceptMarkets(dataSetFiltersToCount).length;
		}, [dataSet.filters]);

		return (
			<FilterFieldContextProvider
				compType={compType}
				filters={dataSet.filters}
				onFilterChange={onFilterChange}
				context="analytics"
			>
				<DatasetContainer bgColor={colorFromDatasetIndex} expanded={expanded}>
					<TileClickContainer
						onClick={onExpandDataSet}
						expanded={expanded}
						bgColor={colorFromDatasetIndex}
					>
						<TopContainer>
							<TitleWrapper>
								<Title
									data-tooltip={isTitleOverflown ? dataSet.name : ''}
									data-tooltip-position="onright"
									data-tooltipdelay="0"
									// @ts-expect-error TS2769: No overload matches this call....
									ref={titleRef}
								>
									{dataSet.name}
								</Title>
								<DataSetErrorIndicator
									isSuccess={insightsDataItem?.isSuccess}
									isError={insightsDataItem?.isError}
								/>
							</TitleWrapper>
							<RightGroupWrapper>
								<Counter
									bgColor={colorFromDatasetIndex}
									expanded={expanded}
									data-tooltip={getCounterTooltip({
										counterType: CounterType.COMPS,
										stats,
										dsType: dataSet.type,
									})}
									data-tooltip-position="above"
									data-tooltipdelay="0"
								>
									{getStatsCounter({
										counterType: CounterType.COMPS,
										stats,
										dsType: dataSet.type,
										isLoading: insightsDataItem?.isLoading,
										isSuccess: insightsDataItem?.isSuccess,
									})}
								</Counter>
								{dataSet.isVisible ? (
									<EyeStyled onClick={toggleDSVisibility} />
								) : (
									<EyeOffStyled onClick={toggleDSVisibility} />
								)}
								<StyledThreeDot
									width={4}
									height={16}
									onClick={showSidebarMenu}
								/>
							</RightGroupWrapper>
						</TopContainer>
						<DatasetSummary>
							{createDataSetSummary({
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								attrToPlot: attributeToPlotValuesToTitles[dataSet.series],
								dataSetType: dataSet.type,
								marketName,
								spaceTypeIds:
									spaceTypeIds?.filter(
										(id) => id !== SPACE_TYPE_NAME_TO_ID.Unknown
									) ?? [],
								propertyTypeIds,
							})}
						</DatasetSummary>
						<ArrowDownExpand
							expanded={expanded}
							bgColor={colorFromDatasetIndex}
						/>
					</TileClickContainer>
					<FormWrapper expanded={expanded}>
						<DataSetFormLegacyAdapter
							dataSet={dataSet}
							chartDraft={chartDraft}
							updateSeries={updateSeries}
							handleApplyAll={handleApplyAll}
							expanded={expanded}
						/>
					</FormWrapper>
					{expanded && areFiltersVisible && <DataSetMoreFilters />}
					{expanded && (
						<MoreFiltersButton
							variant="ghost"
							type="button"
							onClick={toggleFiltersVisibility}
						>
							{areFiltersVisible ? 'Show Less Filters' : 'Show More Filters'}{' '}
							{moreFiltersCount ? `(${moreFiltersCount})` : ''}{' '}
							<StyledAngleArrow rotate={areFiltersVisible ? 180 : 0} />
						</MoreFiltersButton>
					)}
				</DatasetContainer>
			</FilterFieldContextProvider>
		);
	}
);

DatasetV2Impl.displayName = 'DatasetV2Impl';

export const DatasetV2 = withErrorBoundary(DatasetV2Impl, {
	FallbackComponent: () => {
		const fireExtinguisherIcon = '\uD83E\uDDEF';

		return (
			<Flex justifyContent={'center'} style={{ padding: '4px' }}>
				<span>Error in dataset {fireExtinguisherIcon}</span>
			</Flex>
		);
	},
	onError(error) {
		console.error('Error in dataset', error);
	},
});

type CreateDataSetSummaryParams = {
	attrToPlot: string;
	dataSetType: DataSetType | undefined;
	marketName: string;
	spaceTypeIds: SpaceTypeId[] | null;
	propertyTypeIds: PropertyTypeId[] | null;
};

const createDataSetSummary = ({
	attrToPlot,
	dataSetType,
	marketName,
	spaceTypeIds,
	propertyTypeIds,
}: CreateDataSetSummaryParams) => {
	const createSpaceOrPropertyTypesString = (
		ids: PropertyTypeId[] | SpaceTypeId[] | null
	) => {
		if (!ids) return '';

		const mapTypeToClientValue =
			dataSetType === DataSetType.SALES
				? PROPERTY_TYPE_ID_TO_NAME
				: SPACE_TYPE_ID_TO_NAME;

		const allTypesAreSelected =
			Object.keys(mapTypeToClientValue).length === ids.length;

		return allTypesAreSelected
			? ''
			: // @ts-expect-error TS7053: Element implicitly has an 'any...
				ids.map((id) => mapTypeToClientValue[id]).join(', ');
	};

	const dataSetTypeToCounterValue = {
		[DataSetType.COMMERCIAL]: createSpaceOrPropertyTypesString(spaceTypeIds),
		[DataSetType.LEASES]: createSpaceOrPropertyTypesString(spaceTypeIds),
		[DataSetType.SALES]: createSpaceOrPropertyTypesString(propertyTypeIds),
		[DataSetType.MUFA]: PROPERTY_TYPE_ID_TO_NAME[2],
	};

	// @ts-expect-error TS7053: Element implicitly has an 'any...
	const types = dataSetTypeToCounterValue[dataSetType || ''];

	return `${attrToPlot} ${types ? `for ${types}` : ''} in ${marketName}`;
};
