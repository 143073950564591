import { memo } from 'react';
import { Portfolio, PROPERTY_TYPE_ID_TO_NAME, PropertyTypeId } from 'api';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { formatPercent } from 'format';
import { groupBy, mapValues } from 'lodash';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { ChartBox, ChartType, getTotalValues } from './ChartSelect';
import { PortfolioPieChart } from './PortfolioPieChart';
import { useNavigate } from 'react-router';
import { routes } from 'router';

interface PropertyTypeChartProps {
	portfolio: Portfolio;
}

const PROPERTY_TYPE_CHART_NAME = 'Property Type';

export const PropertyTypeChart = memo(
	({ portfolio }: PropertyTypeChartProps) => {
		const groupsByPropertyType = groupBy(
			portfolio.propertyTypesByMarkets,
			(p) => p.buildingPropertyTypeId
		);

		const propertyTypeIds = Object.keys(groupsByPropertyType).map((key) =>
			Number(key)
		) as PropertyTypeId[];

		const totalValuesPerPropertyType = mapValues(
			groupsByPropertyType,
			getTotalValues
		);
		const totalValuesForPortfolio = getTotalValues(
			Object.values(totalValuesPerPropertyType)
		);

		const propertyTypeBySqft = propertyTypeIds.map((propertyTypeId) => {
			return getChartPoint(
				propertyTypeId,
				totalValuesPerPropertyType[propertyTypeId].totalSqFt,
				totalValuesForPortfolio.totalSqFt
			);
		});

		const propertyTypeByLeaseAmount = propertyTypeIds.map((propertyTypeId) => {
			return getChartPoint(
				propertyTypeId,
				totalValuesPerPropertyType[propertyTypeId].activeLeaseAmount,
				totalValuesForPortfolio.activeLeaseAmount
			);
		});

		const propertyTypeByLeaseCount = propertyTypeIds.map((propertyTypeId) => {
			return getChartPoint(
				propertyTypeId,
				totalValuesPerPropertyType[propertyTypeId].activeLeaseCount,
				totalValuesForPortfolio.activeLeaseCount
			);
		});

		const getData = (chartType: ChartType) => {
			switch (chartType) {
				case 'sf':
					return propertyTypeBySqft;
				case 'leaseAmount':
					return propertyTypeByLeaseAmount;
				case 'leaseCount':
					return propertyTypeByLeaseCount;
				default:
					return [];
			}
		};

		const navigate = useNavigate();

		return (
			<ChartBox chartName={PROPERTY_TYPE_CHART_NAME}>
				{(selectedChart) => {
					const data = getData(selectedChart.type);

					if (data.length === 0 || !data.some((d) => d.y > 0)) {
						return (
							<ChartContainer>
								<DataNotAvailableMessage
									chartName={`${PROPERTY_TYPE_CHART_NAME} By ${selectedChart?.value}`}
								/>
							</ChartContainer>
						);
					}

					return (
						<PortfolioPieChart
							data={data}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (_, clickedProps) => {
											const propertyTypeId = clickedProps.datum
												.propertyTypeId as PropertyTypeId;
											navigate(
												routes.portfolioByIdView.toHref(
													{ portfolioId: portfolio.id, viewType: 'list' },
													{ propertyTypeIds: [propertyTypeId] }
												)
											);
										},
									},
								},
							]}
						/>
					);
				}}
			</ChartBox>
		);
	}
);

PropertyTypeChart.displayName = 'PropertyTypeChart';

const getChartPoint = (
	propertyTypeId: PropertyTypeId,
	value: number,
	totalValue: number
) => {
	return {
		x: PROPERTY_TYPE_ID_TO_NAME[propertyTypeId],
		y: value,
		label:
			totalValue !== 0
				? formatPercent(value / totalValue, 1)
				: formatPercent(0, 1),
		propertyTypeId,
	};
};
