import React from 'react';
import styled from 'styled-components';
import { ExchangePanel } from '../UI';
import button from 'ui/styles/button.less';
import ReactPlayer from 'react-player';
import ArrowRight from '../../ui/svg_icons/arrow_right.svg';
import { MQB } from '@compstak/ui-kit';

type VideoProps = {
	title: string;
	description: string;
	zendeskLink: string;
	videoLink: string;
	qaId: string;
};

const videoList: VideoProps[] = [
	{
		title: 'Find a comp',
		description: 'Learn how to use our search engine and filters to find comps',
		zendeskLink:
			'https://help.compstak.com/how-do-i-search-for-lease-or-sales-comps',
		videoLink: 'https://vimeo.com/491257277',
		qaId: 'support-find-comp',
	},
	{
		title: 'Export a comp',
		description: 'Learn how to export comps in your preferred format',
		zendeskLink: 'https://help.compstak.com/how-to-export-comps',
		videoLink: 'https://vimeo.com/490484419',
		qaId: 'support-export-comp',
	},
	{
		title: 'Submit a comp',
		description: 'Learn how to submit comps and earn credits',
		zendeskLink: 'https://help.compstak.com/how-do-i-submit-comps-for-credits',
		videoLink: 'https://vimeo.com/490481420',
		qaId: 'support-submit-comp',
	},
	{
		title: 'Create deal alerts',
		description: 'Learn how to create customized deal email alerts',
		zendeskLink: 'https://help.compstak.com/how-do-i-create-deal-alerts',
		videoLink: 'https://vimeo.com/492510534',
		qaId: 'support-create-deal-alerts',
	},
	{
		title: 'Establish a team',
		description:
			'Learn how to set up a team to share comps and credits with colleagues',
		zendeskLink: 'https://help.compstak.com/can-i-bring-my-entire-team-onboard',
		videoLink: 'https://vimeo.com/492568198',
		qaId: 'support-establish-team',
	},
];

export const SupportGuides = () => {
	return (
		<ExchangePanel id="support-guides" title="Supporting Guides">
			<Container>
				{videoList.map((video) => (
					<VideoElement {...video} key={video.qaId} />
				))}
				<div className="item more-guides">
					<div className="title">For more guides and tips...</div>
					<div>
						Visit our support hub for more guides and FAQs to help you maximize
						the value of your CompStak membership
					</div>
					<a
						className={button.blue}
						href="https://help.compstak.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Support Hub
					</a>
				</div>
			</Container>
		</ExchangePanel>
	);
};

const VideoElement: React.FC<VideoProps> = ({
	title,
	description,
	videoLink,
	zendeskLink,
	qaId,
}) => {
	return (
		<div className="item">
			<PlayerWrapper>
				<ReactPlayer
					controls
					className="react-player"
					height="100%"
					url={videoLink}
					width="100%"
				/>
			</PlayerWrapper>
			<div className="title">{title}</div>
			<div className="description">{description}</div>
			<a
				className="guide-link"
				data-qa-id={`${qaId}-pdf-link`}
				href={zendeskLink}
				target="_blank"
				rel="noreferrer"
			>
				View Guide <StyledArrowRightIcon />
			</a>
		</div>
	);
};

const StyledArrowRightIcon = styled(ArrowRight)`
	fill: rgb(34, 143, 255);
	height: 10px;
	width: 9px;
`;

const Container = styled.div`
	margin: 0 auto;
	padding: 1rem;
	width: 100%;
	background-color: #ffffff;
	display: flex;
	flex-wrap: wrap;
	.item {
		width: 46%;
		margin: 2%;
		padding: 0 2%;

		@media (min-width: ${MQB.T_834}) {
			width: 29.33%;
		}

		a.guide-link {
			align-items: center;
			display: flex;
			font-family: Gotham;
			font-size: 11px;
			font-style: normal;
			font-weight: normal;
			height: 36px;
			justify-content: space-between;
			line-height: 19px;
			padding: 8px;
			text-decoration: none;
			text-transform: uppercase;
			width: 116px;
		}

		&.more-guides {
			padding: 1rem;
			height: min-content;
			display: flex;
			flex-direction: column;
			justify-content: center;

			background: #ffffff;

			border: 1px solid #f1f2f5;
			box-sizing: border-box;
			border-radius: 3px;

			a {
				margin-top: 1rem;
				text-decoration: none;
				width: min-content;
			}
		}

		.title {
			color: rgba(0, 0, 0, 0.87);
			font-size: 14px;
			line-height: 22px;
			font-weight: normal;
			letter-spacing: 0.5px;
			margin: 12px 0 8px;
		}

		.description {
			color: rgba(0, 0, 0, 0.87);
			font-size: 14px;
			line-height: 22px;
		}
	}
`;

const PlayerWrapper = styled.div`
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

	& .react-player {
		position: absolute;
		top: 0;
		left: 0;
	}
`;
