import {
	CenterOnBounds,
	CompsetLayer,
	Map,
	MapInteractivity,
	PinpointMarker,
	SubmarketPolygonLayer,
	areValidCoordinates,
} from '@compstak/maps';
import { featureCollection, point } from '@turf/helpers';
import { useSplitCompset } from 'api';
import { useMemo } from 'react';
import { useNavigate } from 'router';
import { useCompsetSubmarketPolygon } from './useCompsetSubmarketPolygon';

type Props = {
	propertyId: number;
};

export const CompsetMap = ({ propertyId }: Props) => {
	const { data: submarketPolygon } = useCompsetSubmarketPolygon({ propertyId });

	const { data: splitCompset } = useSplitCompset({
		propertyId,
	});

	const navigate = useNavigate();

	const compsetPoints = useMemo(() => {
		if (!splitCompset) return [];
		return splitCompset.compset.map((p, index) => {
			const { lon, lat } = p.property.geoPoint;

			return point([lon, lat], { ...p.property, index });
		});
	}, [splitCompset]);

	const subjectPropertyPoint = useMemo(() => {
		if (!splitCompset) return;
		const { lat, lon } = splitCompset.subjectProperty.geoPoint;

		if (areValidCoordinates([lon, lat])) {
			return point([lon, lat], splitCompset.subjectProperty);
		}
	}, [splitCompset]);

	const allFeatures = useMemo(() => {
		if (!subjectPropertyPoint) return;

		return featureCollection([...compsetPoints, subjectPropertyPoint]);
	}, [subjectPropertyPoint, compsetPoints]);

	return (
		<Map interactivity={MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM}>
			<CenterOnBounds geoJSON={allFeatures} />
			<SubmarketPolygonLayer data={submarketPolygon} />
			<CompsetLayer
				data={compsetPoints}
				getTooltipContent={(f) => f.properties.buildingAddressAndCity}
				onClick={(f) => {
					navigate(`/property/${f.properties.id}`, {
						state: {
							fromNewSalePage: true,
						},
					});
				}}
			/>
			{subjectPropertyPoint && (
				<PinpointMarker
					longitude={subjectPropertyPoint.geometry.coordinates[0]}
					latitude={subjectPropertyPoint.geometry.coordinates[1]}
					getTooltipContent={() =>
						subjectPropertyPoint.properties.buildingAddressAndCity
					}
					pinColor="gray"
					showCircle
				/>
			)}
		</Map>
	);
};
