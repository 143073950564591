import { MapboxImagePinSize, MapImage } from '@compstak/maps';
import { point } from '@turf/helpers';
import styled from 'styled-components';
import { LinkButtonHoverOverlay } from '../../../Components';
import { PropertyComp } from '../../../types';

type Props = {
	property: PropertyComp;
};

export const ViewPropertyPageLink = ({ property }: Props) => {
	const propertyPoint = point([property.geoPoint.lon, property.geoPoint.lat]);

	return (
		<StyledLink
			href={`/property/${property.id}`}
			className="button_button button_large"
			data-qa-id="view-property-page-link"
		>
			<MapContainer>
				<LinkButtonHoverOverlay>View Property Page</LinkButtonHoverOverlay>
				<MapImage
					width={280}
					height={50}
					position={{
						type: 'bounds',
						points: [propertyPoint],
						padding: '20, 0, 0',
					}}
					pinPoints={[propertyPoint]}
					getPinSize={() => MapboxImagePinSize.SMALL}
					showLogo={false}
					showAttribution={false}
				/>
			</MapContainer>
		</StyledLink>
	);
};

const StyledLink = styled.a`
	width: 280px;
	height: 50px;
	margin-left: 16px;
`;

const MapContainer = styled.div`
	width: 280px;
	height: 50px;
	position: relative;
	// TODO: This color is to match the background of the stacking plan header
	// Problem is while the image is loading - user sees the background
	// defined in the class "button_button"
	// todo is for the possibility of introducing loading UI for the MapImage
	background-color: #f0f4f5;
`;
