import { Fragment, useState } from 'react';
import {
	Flex,
	TooltipV2,
	calendarUtils,
	renderTableValue,
} from '@compstak/ui-kit';
import { formatPercent } from 'format';
import { getIsMonthlyMarket, createSearchLeasesUrl } from 'utils';
import { formatSqft } from 'format/formatSqft';
import { useIsExchange } from 'hooks';
import { Link, routes } from 'router';
import styled from 'styled-components';
import IconStackingPlan from 'ui/svg_icons/stacking_plan.svg';
import { useLockedSnapshotData } from './useLockedSnapshotData';
import { NoDataMessage } from '../../../Components/NoDataMessaging/NoDataMessaging';
import { UploadCompButton } from '../UploadCompButton';
import { IconLockGrey } from 'ui/UI';
import { LeaseSnapshotUnlockOverlay } from './LeaseSnapshotUnlockOverlay';
import { useMarketByNameOrId } from 'Pages/Login/reducers';
import dayjs from 'dayjs';
import { useTabContext } from '../Tabs';
import { PropertyPageTab } from '../types';
import { useLeaseContext } from '../LeasesTab/LeaseProvider';
import { AverageInPlaceRents } from './AverageInPlaceRents';
import { BlueBox, BlueBoxLabel, BlueBoxLegend, BlueBoxValue } from '../UI';
import { HelpTooltip } from '../HelpTooltip';
import { usePropertyPageDataContext } from '../PropertyPageDataProvider';

export const PropertyLeaseSnapshot = () => {
	const { summary, compset } = usePropertyPageDataContext();

	const [isHovered, setIsHovered] = useState(false);

	const params = routes.propertyById.useParams();

	const {
		isSnapshotLocked,
		lockedLeases,
		spaceTypeRents,
		isSnapshotBaseLocked,
	} = useLockedSnapshotData();

	const market = useMarketByNameOrId(compset?.subjectProperty.marketId);

	const isExchange = useIsExchange();

	const { setTab, scrollToTabs } = useTabContext();
	const { setCheckedStatus } = useLeaseContext();

	if (!summary || !compset) return null;

	if (summary.activeLeaseCount == null || summary.activeLeaseCount === 0) {
		if (isExchange) return <NoLeaseSnapshotExchange />;

		return null;
	}

	const property = compset.subjectProperty;
	const isMonthlyMarket = getIsMonthlyMarket(property.marketId);

	const renderExpiringLeases = (inYears: 1 | 5) => {
		const leaseCount =
			property[
				inYears === 1
					? 'propertyLeasesExpiringInTwelveMonths'
					: 'propertyLeasesExpiringInFiveYears'
			];
		const leaseSqft =
			property[
				inYears === 1
					? 'propertySqFtExpiringInTwelveMonths'
					: 'propertySqFtExpiringInFiveYears'
			];

		if (leaseCount == null || leaseSqft == null)
			return (
				<>
					0 Leases • 0 SF <AuxText>(0% of total building)</AuxText>
				</>
			);

		if (isSnapshotBaseLocked)
			return <LockedActiveLeases leaseCount={leaseCount} />;

		const leasesExpiringInLink = createSearchLeasesUrl({
			market,
			address: [property.buildingAddressAndCity],
			expirationDate: {
				min: new Date(),
				max: dayjs().add(inYears, 'year').toDate(),
				allowFallback: false,
			},
		});

		const hideSF = getShouldHideActiveLeasesTotalSF(
			leaseSqft,
			property.buildingSize
		);

		return (
			<>
				<TooltipV2 content={LINK_TOOLTIP}>
					<LeasesLink to={leasesExpiringInLink} target="_blank">
						{`${leaseCount} ${leaseCount === 1 ? 'Lease' : 'Leases'}`}
						{!hideSF && ` • ${formatSqft(leaseSqft)} `}
					</LeasesLink>
				</TooltipV2>
				{!hideSF && !!property.buildingSize && (
					<AuxText>
						({formatPercent(leaseSqft / property.buildingSize)} of total
						building)
					</AuxText>
				)}
			</>
		);
	};

	const handleChangeTab = () => {
		setTab(PropertyPageTab.LEASES);
		setCheckedStatus('Only Active Leases');

		scrollToTabs();
	};

	return (
		<BlueBox>
			<BlueBoxLegend>
				<Flex gap="0.5rem">
					Property Lease Snapshot{' '}
					<HelpTooltip text={PROPERTY_LEASE_SNAPSHOT_TOOLTIP} />
				</Flex>
			</BlueBoxLegend>
			<Content
				onMouseOver={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				{isSnapshotLocked && isHovered && (
					<LeaseSnapshotUnlockOverlay
						lockedLeases={lockedLeases}
						propertyId={params.propertyId}
					/>
				)}
				<div>
					<BlueBoxLabel>Active Leases</BlueBoxLabel>
					{isSnapshotBaseLocked ? (
						<LockedActiveLeases leaseCount={summary.activeLeaseCount} />
					) : (
						<TooltipV2 content={LINK_TOOLTIP}>
							<TabSelector onClick={handleChangeTab}>
								{summary.activeLeaseCount}{' '}
								{summary.activeLeaseCount === 1 ? 'Lease' : 'Leases'}{' '}
								{!getShouldHideActiveLeasesTotalSF(
									summary.activeLeaseTotalSqFt,
									property.buildingSize
								) &&
									` • ${renderTableValue(summary.activeLeaseTotalSqFt, formatSqft)}`}
							</TabSelector>
						</TooltipV2>
					)}
				</div>
				<AverageInPlaceRents
					isMonthlyMarket={isMonthlyMarket}
					propertyType={property.buildingPropertyType}
					spaceTypeRents={spaceTypeRents}
				/>
				<div>
					<BlueBoxLabel>
						Average Lease Term{' '}
						<HelpTooltip text="Calculated by taking the lease term of each active lease and dividing by the number of leases." />
					</BlueBoxLabel>
					<BlueBoxValue>
						{renderSnapshotItem(
							summary.avgLeaseTerm,
							(v) => calendarUtils.getHumanizeDuration(v, 'months'),
							isSnapshotBaseLocked
						)}
					</BlueBoxValue>
				</div>
				<div>
					<BlueBoxLabel>
						Leases Expiring in the next 12 Months{' '}
						<HelpTooltip text="The total area of expiring leases compared to the building's total size. Percentages exceeding 100% may be the result of subleased spaced contributing additional SF." />
					</BlueBoxLabel>
					<BlueBoxValue>{renderExpiringLeases(1)}</BlueBoxValue>
				</div>
				<div>
					<BlueBoxLabel>
						Leases Expiring in the next 5 Years{' '}
						<HelpTooltip text="The total area of expiring leases compared to the building's total size. Percentages exceeding 100% may be the result of subleased spaces contributing additional sqft." />
					</BlueBoxLabel>
					<BlueBoxValue>{renderExpiringLeases(5)}</BlueBoxValue>
				</div>
				<div>
					<BlueBoxLabel>
						Major Tenants
						<HelpTooltip text="Top three tenants based on active lease square footage. Note - subleased spaces not included." />
					</BlueBoxLabel>
					<BlueBoxValue>
						{renderTableValue(summary.tenants, (value) => {
							const topTenants = value.slice(0, TOP_TENANTS_NUMBER);
							return topTenants.map((v, index) => (
								<Fragment key={v.name}>
									<TooltipV2 content={LINK_TOOLTIP}>
										<LeasesLink
											to={createSearchLeasesUrl({
												market,
												tenantNames: [v.name],
											})}
											target="_blank"
										>
											{v.name}
										</LeasesLink>
									</TooltipV2>
									{index < topTenants.length - 1 && ', '}
								</Fragment>
							));
						})}
					</BlueBoxValue>
					{isExchange && (
						<StackingPlanLink
							to={routes.stackingPlan.toHref({
								propertyId: params.propertyId,
							})}
						>
							<IconStackingPlan width={16} height={16} />
							See Stacking plan
						</StackingPlanLink>
					)}
				</div>
			</Content>
		</BlueBox>
	);
};

const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const TabSelector = styled(BlueBoxValue)`
	cursor: pointer;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray700};
	line-height: 1;
	width: fit-content;
`;

export const AuxText = styled.span`
	font-weight: 400;
`;

const StackingPlanLink = styled(Link)`
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	gap: 0.25rem;
	text-transform: uppercase;
	font-size: 0.8rem;
`;

const NoDataContent = styled(Content)`
	align-items: center;
	padding: 2.5rem 1rem;
	text-align: center;
`;

const renderSnapshotItem = (
	value: number | null | undefined,
	format?: ((value: number) => React.ReactNode) | undefined,
	isSnapshotLocked?: boolean
) => {
	if (value === null || (value && isSnapshotLocked)) {
		return <IconLockGrey />;
	}
	return renderTableValue(value, format);
};

const NoLeaseSnapshotExchange = () => {
	return (
		<BlueBox>
			<BlueBoxLegend>Property Lease Snapshot</BlueBoxLegend>
			<NoDataContent>
				<NoDataMessage
					message={
						<>
							<div>
								CompStak doesn't have any Active Leases for this property at the
								moment.
							</div>
							<div>
								Be the first to report an Active Lease for this property and
								earn an extra FREE comp!
							</div>
						</>
					}
					action={
						<UploadCompButton section="lease">
							Upload lease comp
						</UploadCompButton>
					}
				/>
			</NoDataContent>
		</BlueBox>
	);
};

const LockedActiveLeases = ({ leaseCount }: { leaseCount: number }) => {
	return (
		<Flex direction="row" justifyContent="start" gap="4px">
			<BlueBoxValue>
				{leaseCount} {leaseCount > 1 ? 'Leases' : 'Lease'} •
			</BlueBoxValue>{' '}
			<IconLockGrey />
		</Flex>
	);
};

const LeasesLink = styled(Link)`
	color: ${(p) => p.theme.colors.gray.gray700};
	text-decoration: underline;
	&:hover {
		cursor: pointer;
		color: ${(p) => p.theme.colors.gray.gray700};
		text-decoration: underline;
	}
`;

export const TOP_TENANTS_NUMBER = 3;
const LINK_TOOLTIP = 'Click to view more details';
const PROPERTY_LEASE_SNAPSHOT_TOOLTIP =
	'Snapshot displays all of the active lease information for the property according to CompStak data.';

export const getShouldHideActiveLeasesTotalSF = (
	expiringSqft: number | undefined | null,
	propertySqft: number | undefined
) => {
	return expiringSqft && propertySqft && propertySqft < expiringSqft;
};
