import styled from 'styled-components';

type DefaultLogoProps = {
	text: string;
};

const MAX_NUMBER_OF_INITIALS = 2;

export const DefaultLogo = ({ text }: DefaultLogoProps) => {
	const textInitials = text
		.split(' ')
		.map((subString) => subString[0])
		.slice(0, MAX_NUMBER_OF_INITIALS)
		.join('');

	return <Logo>{textInitials}</Logo>;
};

const Logo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	background-color: ${({ theme }) => theme.colors.gray.gray200};
	color: ${({ theme }) => theme.colors.gray.gray700};
	text-transform: uppercase;
	font-size: 0.8em;
`;
