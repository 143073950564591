import React from 'react';
import { Market } from '@compstak/common';
import { Transition } from './MarketList';

type MarketListItemProps = {
	hasFullAccess?: boolean;
	market: Market;
	onMarketClick: NoArgCallback;
};

export const MarketListItem = ({
	hasFullAccess,
	market,
	onMarketClick,
}: MarketListItemProps) => {
	return (
		<Transition>
			<li
				onClick={onMarketClick}
				className={`market ${hasFullAccess ? 'full-access' : ''}`}
				data-qa-id={market.id}
			>
				<div className="change-market-link">{market.displayName}</div>
			</li>
		</Transition>
	);
};
