import styled from 'styled-components';

export const NoDataContainer = styled.div`
	background-color: ${(p) => p.theme.colors.gray.gray200};
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const NoDataMessage = styled.div`
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
`;
