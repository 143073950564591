import {
	Flex,
	IconAddDeals,
	IconArrowUp,
	IconComp,
	IconMailBlue,
	IconSearchBlue,
	IconUserBlue,
	IconWebinar,
	Spinner,
	typography,
} from '@compstak/ui-kit';
import { useHasErrored } from 'hooks/useHasErrored';
import { ReactNode, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
	useUserflowCompletedFlows,
	useUserflowOnboardingConfig,
} from 'services/userflowOnboardingServices';
import styled, { css } from 'styled-components';
import IconLogo from 'ui/svg_icons/logo_new.svg';
import userflow from 'userflow.js';
import { ExchangePanel, ExchangeSection } from './UI';
import { UfProgressBar } from './UserflowOnboardingTableProgressBar';

const iconIdToIconMapping = {
	AddDeals: <IconAddDeals />,
	ArrowUp: <IconArrowUp />,
	Comp: <IconComp />,
	Mail: <IconMailBlue />,
	Search: <IconSearchBlue />,
	User: <IconUserBlue />,
	Webinar: <IconWebinar />,
};

type SetSectionsInView = Parameters<
	typeof ExchangeSection
>[0]['setSectionsInView'];

export function UserflowOnboardingTable({
	setSectionsInView,
}: {
	setSectionsInView: SetSectionsInView;
}) {
	return (
		<OnboardingContainer setSectionsInView={setSectionsInView}>
			<ErrorBoundary FallbackComponent={ErrorUi}>
				<UserflowOnboardingTableInner />
			</ErrorBoundary>
		</OnboardingContainer>
	);
}

const REFER_A_COLLEAGUE_FLOW_ID = '445f3e46-33ee-4f4f-b392-562a6d1c296b';

export function useOnboardingSectionPosition():
	| 'start'
	| 'end'
	| 'hidden'
	| 'isLoading' {
	const {
		data: configData,
		isError: configIsError,
		isSuccess: configIsSuccess,
		isLoading: configIsLoading,
		isFetching: configIsFetching,
	} = useUserflowOnboardingConfig();

	const {
		data: completedFlows,
		isError: completedFlowsIsError,
		isSuccess: completedFlowsIsSuccess,
		isLoading: completedFlowsIsLoading,
		isFetching: completedFlowsIsFetching,
	} = useUserflowCompletedFlows();

	const hasErrored = useHasErrored({
		isSuccess: configIsSuccess && completedFlowsIsSuccess,
		isError: configIsError || completedFlowsIsError,
	});
	const incompleteConfigFlows = useMemo(() => {
		if (!configData || !completedFlows) {
			return [];
		}
		try {
			const completedFlowIds = completedFlows.flows.map((fl) => fl.flowId);
			return configData.flows.filter(
				(fl) => !completedFlowIds.includes(fl.flowId)
			);
		} catch (_err) {
			return [];
		}
	}, [configData, completedFlows]);

	if (
		configIsLoading ||
		configIsFetching ||
		completedFlowsIsLoading ||
		completedFlowsIsFetching
	) {
		return 'isLoading';
	}
	if (hasErrored) {
		return 'start';
	}
	if (!configData?.show || !completedFlows) {
		return 'hidden';
	}
	if (
		incompleteConfigFlows.length === 1 &&
		incompleteConfigFlows[0].flowId === REFER_A_COLLEAGUE_FLOW_ID
	) {
		// Most flows completed
		// https://compstak.atlassian.net/browse/AP-9617
		return 'end';
	}
	if (incompleteConfigFlows.length === 0) {
		// All flows completed
		// https://compstak.atlassian.net/browse/AP-9618
		return 'hidden';
	}

	return 'start';
}

function UserflowOnboardingTableInner() {
	const {
		data: config,
		error: configErr,
		isLoading: isLoadingConfig,
	} = useUserflowOnboardingConfig();
	const {
		data: completedFlowsData,
		error: completedFlowsErr,
		isLoading: isLoadingCompletedFlows,
	} = useUserflowCompletedFlows();

	if (isLoadingConfig || isLoadingCompletedFlows) {
		return (
			<Flex justifyContent="center">
				<Spinner />
			</Flex>
		);
	}

	const configFlows = (!!config?.show && config?.flows) || [];
	const completedFlows = completedFlowsData?.flows;

	if (
		configErr ||
		completedFlowsErr ||
		!Array.isArray(configFlows) ||
		!Array.isArray(completedFlows)
	) {
		return <ErrorUi />;
	}

	const pairedConfigFlows = configFlows.map((configFlow) => {
		const correspondingCompletedFlow = completedFlows.find(
			(completedFlow) => completedFlow.flowId === configFlow.flowId
		);
		const isCompleted = Boolean(correspondingCompletedFlow);
		const credits =
			correspondingCompletedFlow?.rewardCredits ?? configFlow.rewardCredits;
		return { ...configFlow, credits, isCompleted };
	});

	const maxCredits = pairedConfigFlows.reduce(
		(sum, nextFlow) => sum + (nextFlow.credits ?? 0),
		0
	);

	const creditsReceived = pairedConfigFlows
		.filter((flow) => flow.isCompleted)
		.reduce((sum, nextFlow) => sum + (nextFlow.credits ?? 0), 0);

	return (
		<StyledContentContainer>
			<Flex
				justifyContent="center"
				gap={'16px'}
				style={{ width: '90%', maxWidth: '640px', margin: '24px auto' }}
			>
				<UfProgressBar percent={(creditsReceived / (maxCredits || 1)) * 100} />
				<CreditsText>
					{creditsReceived} / {maxCredits} credits
				</CreditsText>
				<StyledLogoGreen>
					<IconLogo />
				</StyledLogoGreen>
			</Flex>
			<StyledCTA>
				Earn up to {maxCredits} credits to unlock comps by completing each
				checklist item. You can also get credits by submitting comps.
			</StyledCTA>
			<UfTable>
				{pairedConfigFlows.map((flow) => {
					return (
						<>
							<StyledDivider />
							<UfTableRow key={flow.flowName}>
								<Spacer />
								<StyledTdCheckbox>
									<StyledCheckbox
										type="checkbox"
										checked={flow.isCompleted}
										data-qa-id="onboarding-flow-checkbox"
									/>
								</StyledTdCheckbox>
								<Spacer />
								<StyledTdIcon>{getIcon(flow?.iconName)}</StyledTdIcon>
								<Spacer />
								<StyledTdFlowTitle
									onClick={() => {
										try {
											userflow.start(flow.flowId);
										} catch (err) {
											console.warn(
												'Failed to start a userflow flow',
												{ flowId: flow.flowId },
												err
											);
										}
									}}
									data-qa-id="onboarding-flow-title"
								>
									{flow.flowName ?? 'Title missing'}
								</StyledTdFlowTitle>
								<StyledTdMinutes>{flow.minutes ?? 'N/A'} min</StyledTdMinutes>
								<Spacer />
								<StyledTdCredits>
									{flow.credits ?? 'N/A'} credits
								</StyledTdCredits>
								<Spacer />
							</UfTableRow>
						</>
					);
				})}
				{configFlows.length === 0 && (
					<Flex justifyContent="center" style={{ marginTop: '20px' }}>
						<p>There are no onboarding tasks for you to complete right now</p>
					</Flex>
				)}
				<StyledDivider />
			</UfTable>
		</StyledContentContainer>
	);
}

function OnboardingContainer({
	children,
	setSectionsInView,
}: {
	children?: ReactNode;
	setSectionsInView: SetSectionsInView;
}) {
	return (
		<ExchangeSection
			id="onboarding-section"
			title="Earn credits: onboard"
			setSectionsInView={setSectionsInView}
		>
			<ExchangePanel id="onboarding-panel">{children}</ExchangePanel>
		</ExchangeSection>
	);
}

function ErrorUi() {
	return (
		<Flex justifyContent="center">
			<ErrorMessage>Onboarding section error</ErrorMessage>
		</Flex>
	);
}

const SVG_SIZE = 18;

function getIcon(iconId: string) {
	return (
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		(iconId && iconIdToIconMapping[iconId]) ?? (
			<StyledLogoIconWrapper>
				<IconLogo />
			</StyledLogoIconWrapper>
		)
	);
}

const StyledLogoGreen = styled.div`
	flex-shrink: 0;
	background-color: ${(props) => props.theme.colors.green.green500};
	height: 24px;
	width: 24px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	& svg {
		height: 16px;
		width: 16px;
		polygon {
			fill: ${({ theme }) => theme.colors.white.white};
		}
	}
`;

const StyledLogoIconWrapper = styled.div`
	& svg {
		height: ${SVG_SIZE}px;
		width: ${SVG_SIZE}px;
		polygon {
			fill: black;
		}
	}
`;

const ErrorMessage = styled.h2`
	color: red;
	font-size: 24px;
	font-weight: 400;
`;

const CreditsText = styled.span`
	color: ${(props) => props.theme.colors.green.green500};
	white-space: nowrap;
	font-weight: 500;
	text-transform: uppercase;
`;

const StyledContentContainer = styled.div`
	width: 80%;
	margin: 0 auto;

	font-family: ${typography.fontFamily.gotham};
	line-height: 18px;
	font-size: 12px;
`;

const StyledCTA = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.3px;
	margin-top: 16px;
	margin-bottom: 40px;
`;

const StyledCheckbox = styled.input`
	/* https://stackoverflow.com/questions/29617200/how-to-make-checkboxes-rounded */
	position: relative;
	vertical-align: middle;
	appearance: none;
	outline: none;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.n70};
	cursor: pointer;

	&:checked {
		background-color: ${(props) => props.theme.colors.green.green500};
		border: none;

		&::after {
			/* https://stackoverflow.com/questions/40791217/how-to-center-text-inside-before-pseudo-element */
			content: '✓';
			position: absolute;
			white-space: pre;
			display: inline;
			color: white;
			font-size: 12px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;

const UfTable = styled.tbody`
	display: flex;
	flex-direction: column;
	max-width: 940px;
	margin: 32px auto 0;
`;

// Spacer Width
const sw = (w: number) => `min(${w}px,${w / 8}%)`;

const columns = [
	sw(48), // Spacer
	'32px', // Checkbox
	sw(56), // Spacer
	'32px', // Icon
	sw(32), // Spacer
	'1fr', // Title
	'48px', // Minutes
	sw(56), // Spacer
	'72px', // Credits
	sw(48), // Spacer
];

const UfTableRow = styled.tr`
	display: grid;
	place-items: center baseline;
	grid-template-rows: 1fr;
	grid-template-columns: ${columns.join(' ')};
	margin: 8px;

	& > td {
		height: fit-content;
	}
`;

const leftAlignedStyles = css`
	margin-left: 0;
	margin-right: auto;
`;

const rightAlignedStyles = css`
	margin-left: auto;
	margin-right: 0;
`;

const Spacer = styled.div``;

const StyledTdCheckbox = styled.td`
	${leftAlignedStyles}
`;

const StyledTdIcon = styled.td`
	${leftAlignedStyles}

	& svg {
		height: ${SVG_SIZE}px;
		width: ${SVG_SIZE}px;
		path,
		circle {
			fill: ${(props) => props.theme.colors.blue.blue400};
			color: ${(props) => props.theme.colors.blue.blue400};
		}
	}
`;

const StyledTdFlowTitle = styled.td`
	${leftAlignedStyles}

	color: ${(props) => props.theme.colors.blue.blue400};
	width: 100%;
	height: 18px;
	font-weight: 500;

	&:hover {
		cursor: pointer;
	}
`;

const StyledTdMinutes = styled.td`
	${rightAlignedStyles}

	color: ${(props) => props.theme.colors.neutral.n70};
	white-space: nowrap;
	font-weight: 400px;
`;

const StyledTdCredits = styled.td`
	${rightAlignedStyles}
`;

const StyledDivider = styled.hr`
	width: 100%;
	color: gray;
	opacity: 0.5;
`;
