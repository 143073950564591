import { Image } from '@react-pdf/renderer';
import { PdfSectionBox } from '../PdfUI';
import { PdfSectionProps } from '../constants';

export function LeaseExpirationBySubmarketPdf({
	leaseExpirationSubmarket: { url },
}: PdfSectionProps) {
	return (
		<PdfSectionBox title="Lease expiration by submarket">
			{url && <Image src={url} style={{ marginBottom: 12 }} />}
		</PdfSectionBox>
	);
}
