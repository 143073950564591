import { useCallback, useEffect, useMemo, useState } from 'react';

export type SelectionState<Row extends { id: number } = { id: number }> = {
	selection: number[];
	getIsRowSelected: (id: number) => boolean;
	toggleRow: (id: number) => void;
	getAreAllSelected: () => boolean;
	toggleAllSelected: () => void;
	areSomeSelected: boolean;
	getSelectedRows: () => Row[];
	resetSelection: () => void;
};

export const useSelectionState = <Row extends { id: number }>(
	rows: Row[] | undefined,
	getInitialSelection?: () => number[]
): SelectionState<Row> => {
	const [selectionMap, setSelectionMap] = useState<Record<number, boolean>>(
		() => {
			const initialSelection = getInitialSelection ? getInitialSelection() : [];
			return initialSelection.reduce<Record<number, boolean>>((acc, id) => {
				acc[id] = true;
				return acc;
			}, {});
		}
	);

	const getIsRowSelected = useCallback(
		(id: number) => !!selectionMap[id],
		[selectionMap]
	);

	const toggleRow = useCallback((id: number) => {
		setSelectionMap((prevSelection) => {
			const newSelection = { ...prevSelection };
			if (newSelection[id]) {
				delete newSelection[id];
			} else {
				newSelection[id] = true;
			}
			return newSelection;
		});
	}, []);

	const getAreAllSelected = useCallback(() => {
		if (!rows) return false;
		return rows.every((row) => !!selectionMap[row.id]);
	}, [rows, selectionMap]);

	const toggleAllSelected = useCallback(() => {
		if (!rows) return;

		setSelectionMap(() => {
			const allSelected = getAreAllSelected();
			if (allSelected) {
				return {};
			}
			return rows.reduce(
				(acc, row) => {
					acc[row.id] = true;
					return acc;
				},
				{} as Record<number, true>
			);
		});
	}, [rows, getAreAllSelected]);

	const areSomeSelected = useMemo(
		() => Object.keys(selectionMap).length > 0,
		[selectionMap]
	);

	const rowsMap = useMemo(() => {
		return (
			rows?.reduce<Record<number, Row>>((acc, row) => {
				acc[row.id] = row;
				return acc;
			}, {}) ?? {}
		);
	}, [rows]);

	const getSelectedRows = useCallback(() => {
		return Object.keys(selectionMap)
			.map(Number)
			.map((id) => rowsMap[id])
			.filter((row) => !!row);
	}, [selectionMap, rowsMap]);

	const resetSelection = useCallback(() => {
		setSelectionMap({});
	}, []);

	useEffect(() => {
		setSelectionMap((prevSelection) => {
			return Object.keys(prevSelection)
				.map(Number)
				.filter((id) => rows?.some((row) => row.id === id))
				.reduce(
					(acc, id) => {
						acc[id] = true;
						return acc;
					},
					{} as Record<number, true>
				);
		});
	}, [rows]);

	const selection = useMemo(() => {
		return Object.keys(selectionMap).map(Number);
	}, [selectionMap]);

	return {
		selection,
		getIsRowSelected,
		toggleRow,
		getAreAllSelected,
		toggleAllSelected,
		areSomeSelected,
		getSelectedRows,
		resetSelection,
	};
};
