import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { useMemo } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from '../constants';
import { Dashboard } from './Dashboard';
import { NoPortfolios } from './NoPortfolios';
import { EmptyPortfolio } from './EmptyPortfolio';
import { ListView } from './ListView';
import { ListViewV2 } from './ListView/ListViewV2';
import { MapView } from './MapView';
import { MapViewV2 } from './MapView/MapViewV2';
import { PortfolioFiltersProvider } from './PortfolioFiltersProvider';
import { PortfolioSidebar } from './PortfolioSidebar';
import { useRedirectPortfolio } from './hooks/useRedirectPortfolio';
import { PortfolioLeasesFiltersProvider } from './LeaseActivity/PortfolioLeasesFiltersProvider';
import { PropertiesListAndMapViewsProvider } from './ListView/PropertiesListAndMapViewsProvider';
import { LeasesListAndMapViewProvider } from './ListView/LeasesListAndMapViewProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const PortfolioLayout = () => {
	const { portfolioRevampOverviewTabFF } = useFeatureFlags();
	const ListViewComponent = portfolioRevampOverviewTabFF
		? ListViewV2
		: ListView;

	const MapViewComponent = portfolioRevampOverviewTabFF ? MapViewV2 : MapView;

	const params = routes.portfolioByIdView.useParams();

	const portfolioId = params.portfolioId
		? Number(params.portfolioId)
		: undefined;

	const { data: portfolio, isFetching: isFetchingPortfolioById } =
		usePortfolioByIdQuery({
			id: portfolioId,
		});

	const isPortfolioEmpty = useMemo(() => {
		if (!portfolio) return;
		const totalPropertyCount = portfolio.markets.reduce(
			(sum, m) => sum + m.propertyCount,
			0
		);
		return totalPropertyCount === 0;
	}, [portfolio]);

	const { isFetching: isRedirecting } = useRedirectPortfolio();

	const isFetching = isRedirecting || isFetchingPortfolioById;

	const content = (() => {
		if (isFetching) {
			return (
				<LoadingContainer>
					<Spinner />
				</LoadingContainer>
			);
		}

		if (!portfolio) {
			return <NoPortfolios />;
		}

		if (isPortfolioEmpty && !portfolioRevampOverviewTabFF) {
			return <EmptyPortfolio portfolio={portfolio} />;
		}

		return (
			<PortfolioFiltersProvider key={portfolio.id} portfolio={portfolio}>
				<PortfolioLeasesFiltersProvider
					key={portfolio.id}
					portfolio={portfolio}
				>
					<Content>
						{(params.viewType === 'map' || params.viewType === 'list') && (
							<PropertiesListAndMapViewsProvider portfolioId={portfolio.id}>
								<LeasesListAndMapViewProvider portfolioId={portfolio.id}>
									{params.viewType === 'map' && (
										<MapViewComponent portfolioId={portfolio.id} />
									)}
									{params.viewType === 'list' && (
										<ListViewComponent portfolioId={portfolio.id} />
									)}
								</LeasesListAndMapViewProvider>
							</PropertiesListAndMapViewsProvider>
						)}
						{(!params.viewType || params.viewType === 'dashboard') && (
							<Dashboard portfolioId={portfolio.id} />
						)}
					</Content>
				</PortfolioLeasesFiltersProvider>
			</PortfolioFiltersProvider>
		);
	})();

	return (
		<Root>
			<PortfolioSidebar portfolioId={portfolioId} />
			{content}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	// defaults
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	line-height: 1.2;
	box-sizing: border-box;
`;

const Content = styled.div`
	flex: 1;
	height: 100%;
`;

const LoadingContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
