import { useCompsetAddresses, usePropertyMarket } from 'api';
import { useMemo } from 'react';
import { createSearchLeasesUrl } from 'utils';

type Props = {
	propertyId: number;
};

export const useCompsetLeasesUrl = ({ propertyId }: Props) => {
	const { data: market } = usePropertyMarket({ propertyId });
	const addresses = useCompsetAddresses({ propertyId });

	return {
		data: useMemo(() => {
			return createSearchLeasesUrl({ market, address: addresses });
		}, [market, addresses]),
	};
};
