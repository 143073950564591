import { SimpleTable } from '@compstak/ui-kit';
import { useSaleCompQuery } from 'api';
import { useIsExchange } from 'hooks';
import { useMemo } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { useColumns } from './columns';
import {
	useBodyCellProps,
	useCurrentlyActiveLeases,
	useLeaseSnapshotData,
	useLeasesActiveAtTimeOfSale,
} from './hooks';

export const LeaseSnapshot = () => {
	const params = routes.saleById.useParams();

	const { data: sale } = useSaleCompQuery({ id: params.id });

	const { data: leaseSnapshotData } = useLeaseSnapshotData({
		saleId: params.id,
	});

	const { data: leasesActiveAtTimeOfSale, isFetching: isFetchingActive } =
		useLeasesActiveAtTimeOfSale({
			saleId: params.id,
		});

	const currentlyActiveLeases = useCurrentlyActiveLeases(
		leaseSnapshotData?.comps ?? []
	);

	const seeAllLeasesLink = useMemo(() => {
		if (!sale || !leaseSnapshotData?.comps?.length) return null;
		return (
			<SeeAllLink
				to={`/property/${sale.portfolio[0].propertyId}`}
				state={{ scrollToLeases: true }}
				data-qa-id="SeeAllSalesLink"
			>
				view full lease history
			</SeeAllLink>
		);
	}, [leaseSnapshotData, sale]);

	const bodyCellProps = useBodyCellProps();
	const isExchange = useIsExchange();
	const columns = useColumns();

	const commonTableProps = {
		isLoading: isFetchingActive,
		columns,
		scrollContainerProps: {
			style: {
				maxHeight: 400,
			},
		},
		isBodyHoverable: true,
		getBodyCellProps: bodyCellProps,
	};

	return (
		<div>
			<TabContentContainer>
				<TabContentHeader>
					<TabContentTitle>
						{isExchange ? 'Unlocked' : ''} Active Leases at Time of Sale
					</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="activeLeasesTimeOfSaleTable"
					rows={leasesActiveAtTimeOfSale}
					{...commonTableProps}
				/>
			</TabContentContainer>
			<StyledTabContentContainer>
				<TabContentHeader>
					<TabContentTitle>Currently Active Leases</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="currentlyActiveLeasesTable"
					rows={currentlyActiveLeases}
					{...commonTableProps}
				/>
			</StyledTabContentContainer>
			<StyledTabContentContainer>
				<TabContentHeader>
					<TabContentTitle>All Leases</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="activeLeasesTable"
					rows={leaseSnapshotData?.comps ?? []}
					{...commonTableProps}
				/>
			</StyledTabContentContainer>
		</div>
	);
};

const StyledTabContentContainer = styled(TabContentContainer)`
	margin-top: 8px;
	margin-bottom: 32px;
`;
