import { LinesChartProps, VerticalBarChartProps } from '@compstak/ui-kit';
import { ChartTypes } from '../../type';
import { getMinMaxChartDataValue } from '../getMinMaxChartDataValue';

export type ScaleFactors = {
	scaleFactorMin: number;
	scaleFactorMax: number;
};

export const calcYTicks = (
	chartType: ChartTypes,
	data: LinesChartProps['data'] | VerticalBarChartProps['data'],
	scaleFactors: ScaleFactors = { scaleFactorMax: 0.1, scaleFactorMin: 0.1 }
) => {
	const { min, max } = getMinMaxChartDataValue(data);
	const { scaleFactorMin, scaleFactorMax } = scaleFactors;

	const areValuesPositiveAndNegative = min < 0 && max > 0;

	let firstTick;
	let lastTick;

	const minWithScaleFactor = min - Math.abs(min) * scaleFactorMin;
	const maxWithScaleFactor = max + Math.abs(max) * scaleFactorMax;

	if (chartType === ChartTypes['BAR_CHART'] && !areValuesPositiveAndNegative) {
		firstTick = Math.min(minWithScaleFactor, 0);
		lastTick = Math.max(maxWithScaleFactor, 0);
	} else {
		firstTick = minWithScaleFactor;
		lastTick = maxWithScaleFactor;
	}

	const diff = lastTick - firstTick;
	const step = diff / (TICK_COUNT - 1);

	const ticks = Array.from({ length: TICK_COUNT }, (_tick, i) => {
		return firstTick + step * i;
	});

	if (areValuesPositiveAndNegative) {
		/** When in chart data there are both negative and positive values
      we need 0 tick in the final array */
		const firstPositiveTickIndex = ticks.findIndex((item) => item > 0);
		ticks.splice(firstPositiveTickIndex, 0, 0);
	}

	return ticks;
};

/** Count of visible Y Axis ticks */
const TICK_COUNT = 5;
