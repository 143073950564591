import {
	useIsMufaProperty,
	useMufaPropertyByIdQuery,
	usePropertyByIdQuery,
	useTransitScreenQuery,
} from 'api';
import React, { useMemo } from 'react';
import { routes } from 'router';
import { MufaPropertyComp, PropertyComp } from 'types';
import { WidgetContainer } from '@compstak/ui-kit';
import styled from 'styled-components';
import { TabContainer } from '../UI';
import { TransitScreen } from '../../../Components/TransitScreen';

export const TransitScreenTab = () => {
	const propertyId = String(routes.propertyById.useParams().propertyId);

	const { data: propertyQueryData } = usePropertyByIdQuery({
		id: Number(propertyId),
	});

	const transitScreenQueryResponse = useTransitScreenQuery({ id: propertyId });

	const { data: hasMufaAccessAndDataProvider } = useIsMufaProperty({
		propertyId: Number(propertyId),
	});

	const { data: mufaPropertyQueryData } = useMufaPropertyByIdQuery(
		{ propertyId },
		{ enabled: hasMufaAccessAndDataProvider }
	);

	const overviewSectionData: PropertyComp | MufaPropertyComp | undefined =
		useMemo(() => {
			if (!propertyQueryData) {
				return undefined;
			}

			return {
				...(!!mufaPropertyQueryData && mufaPropertyQueryData),
				...propertyQueryData,
			};
		}, [propertyQueryData, mufaPropertyQueryData]);

	const {
		data: transitScreenData,
		isLoading,
		isError,
	} = transitScreenQueryResponse;

	return (
		<TabContainer>
			<TransitScreenContainer>
				<TransitScreen
					isLoading={isLoading}
					data={transitScreenData}
					propertyData={overviewSectionData}
					isError={isError}
					isPropertyPageV3
				/>
			</TransitScreenContainer>
		</TabContainer>
	);
};

TransitScreenTab.displayName = 'TransitScreenTab';

const TransitScreenContainer = styled.div`
	${WidgetContainer} {
		box-shadow: none;
		padding: 0;
	}
`;
