import { nullValueText } from '@compstak/common';
import { Pinpoint, getPinLetter } from '@compstak/maps';
import { Text, View } from '@react-pdf/renderer';
import { TableCellGapper } from 'Components';
import { CompsetDTO, CompsetType, MufaCompsetDTO } from 'api';
import { formatInteger, formatMoney, formatNumber, formatRent } from 'format';
import { PropertyComp } from 'types';
import {
	getDistanceBetweenProperties,
	getIsMonthlyMarket,
	renderSafeValue,
} from 'utils';
import { PdfPinpoint } from '../PdfPinpoint';
import { EditableCompsetRow, PdfColumn } from '../types';

type Props = {
	property: PropertyComp;
	compsetType: CompsetType | undefined;
};

export const useCompsetPdfColumns = ({ property, compsetType }: Props) => {
	const commercialColumns: PdfColumn<EditableCompsetRow, CompsetDTO>[] = [
		{
			id: 'buildingAddressAndCity',
			header: 'Building Name, Address',
			getValue: ({ row }) =>
				renderSafeValue({ value: row.property.buildingAddressAndCity }),
			flex: 3,
			isVisible: true,
			body: ({ row, rowIndex, defaultContent }) => {
				let pin = (
					<Pinpoint
						style={{ width: PINPOINT_WIDTH }}
						text={getPinLetter(rowIndex - 1)}
					/>
				);

				if (row.id === property.id) {
					pin = (
						<Pinpoint
							style={{ width: PINPOINT_WIDTH }}
							showCircle
							pinColor="gray"
						/>
					);
				}

				return (
					<TableCellGapper
						style={{
							width: '100%',
						}}
					>
						{pin}
						{defaultContent}
					</TableCellGapper>
				);
			},
			pdfBody: ({ row, rowIndex }) => {
				const isSubjectProperty = row.id === property.id;
				return (
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: 5,
							flexWrap: 'wrap',
						}}
					>
						{isSubjectProperty ? (
							<PdfPinpoint pinColor="gray" showCircle />
						) : (
							<PdfPinpoint pinColor="blue" text={getPinLetter(rowIndex - 1)} />
						)}
						<Text>
							{renderSafeValue({ value: row.buildingAddressAndCity })}
						</Text>
					</View>
				);
			},
			wrapBodyContentWithText: false,
		},
		{
			id: 'submarket',
			header: 'Submarket',
			getValue: ({ row }) => renderSafeValue({ value: row.property.submarket }),
			flex: 1.5,
			isVisible: true,
		},
		{
			id: 'distance',
			header: 'Distance From Property',
			getValue: ({ row }) => {
				if (row.property.id === property.id) {
					return nullValueText;
				}
				return renderSafeValue({
					value: row.property.geoPoint,
					format: () => {
						const distance = getDistanceBetweenProperties(
							property,
							row.property
						);
						return (
							formatNumber(distance, { minDecimals: 2, maxDecimals: 2 }) + ' mi'
						);
					},
				});
			},
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'propertyMarketStartingRent',
			header: 'Market Starting Rent $/SF',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.property.propertyMarketStartingRent,
					format: formatMoney,
				}),
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'buildingYear',
			header: 'Year Built',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.property.buildingYear,
				}),
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'buildingYearRenovated',
			header: 'Year Renovated',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.property.buildingYearRenovated,
				}),
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'buildingFloorsCount',
			header: 'Floors',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.property.buildingFloorsCount,
				}),
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'buildingSize',
			header: 'Size (SF)',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.property.buildingSize,
					format: (v) => formatInteger(v, { shorten: false }),
				}),
			hAlign: 'center',
			isVisible: true,
		},
	];

	const mufaColumns: PdfColumn<EditableCompsetRow, MufaCompsetDTO>[] = [
		...commercialColumns.slice(0, 3),
		{
			id: 'askingRentPerSqFt',
			header: 'Asking Rent $/SF',
			getValue: ({ row }) => {
				const isMonthly = getIsMonthlyMarket(row.property.marketId);
				return renderSafeValue({
					value: row.property.askingRentPerSqFt,
					format: (v) => formatRent(v, isMonthly),
				});
			},
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'effectiveRentPerSqFt',
			header: 'Effective Rent $/SF',
			getValue: ({ row }) => {
				const isMonthly = getIsMonthlyMarket(row.property.marketId);
				return renderSafeValue({
					value: row.property.effectiveRentPerSqFt,
					format: (v) => formatRent(v, isMonthly),
				});
			},
			hAlign: 'center',
			isVisible: true,
		},
		{
			id: 'numberOfUnits',
			header: '# of Units',
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.property.numberOfUnits,
					format: formatInteger,
				});
			},
			hAlign: 'center',
			isVisible: true,
		},
		...commercialColumns.slice(4),
	];

	return compsetType === CompsetType.COMMERCIAL
		? commercialColumns
		: mufaColumns;
};

const PINPOINT_WIDTH = 18;
