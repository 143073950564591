import React, { memo, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
	ErrorUI,
	HelpCircle,
	LinesChart,
	numberUtils,
	Pending,
	PeriodSelector,
	RowFlexContainer,
	SaveDataMenu,
	TickFormatter,
	Tooltip,
	WidgetContainer,
	HeightContainer,
	getDownloadFileName,
	Period,
	WidgetTitle,
	createTypedLineChartLegend,
} from '@compstak/ui-kit';
import { emptyLines, lines } from './auxiliaryData';
import {
	years,
	calcXTicks,
	calcYTicks,
	calcDomainFromTicks,
	getXTickDateFormatter,
} from '../../../../Pages/PropertyPageV2_1/utils';
import {
	getAdaptData,
	getFilteredLines,
} from '../../../../Pages/PropertyPageV2_1/sections/utils';
import { LeaseTradeOutDTO } from 'api';
import {
	FiltersNames,
	MufaChartsNames,
	ChartTypes,
} from 'types/propertyTrendCharts';
import { MufaSubmarketChartProps } from 'Components/MufaSubmarket/MufaSubmarketSection';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';

const adaptData = getAdaptData();

const LineChartLegend = createTypedLineChartLegend<LeaseTradeOutDTO>();

const HEADERS_MAPPING = {
	name: 'Date',
	new: 'New',
	renewal: 'Renewal',
	all: 'All',
};

type Props = MufaSubmarketChartProps &
	Pick<ReturnType<typeof useMufaTrendsData>['data'], 'submarketLeaseTradeOut'>;

export const SubmarketLeaseTradeOut = memo<Props>(
	({
		address,
		filters,
		handleFilterChange,
		submarketLeaseTradeOut,
		showAnimation = true,
		showSaveDataMenu = true,
		shouldShowTooltip = true,
	}) => {
		const period =
			filters[MufaChartsNames.SubmarketLeaseTradeOut][FiltersNames.PERIOD];
		const [ref, setRef] = useState<HTMLElement | null>(null);

		const { isLoading, isError, error, data, isSuccess } =
			submarketLeaseTradeOut;

		const chartData = useMemo(() => adaptData(data), [data]);

		const widgetTitle = 'Lease Trade Out';
		const chartId = `${widgetTitle}${address}`;

		const filteredLines = useMemo(
			() => getFilteredLines(chartData, lines, emptyLines),
			[chartData]
		);

		const xTicks = useMemo(
			() => calcXTicks(chartData, period),
			[chartData, period]
		);

		const yTicks = useMemo(
			() => calcYTicks(ChartTypes['LINE_CHART'], chartData),
			[chartData]
		);

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) =>
				handleFilterChange(
					MufaChartsNames.SubmarketLeaseTradeOut,
					FiltersNames.PERIOD,
					selectedPeriod
				),
			[handleFilterChange]
		);

		return (
			<Root>
				<RowFlexContainer style={rowFlexContainerStyle}>
					<RowFlexContainer>
						<WidgetTitle ref={setRef}>{widgetTitle}</WidgetTitle>
						{shouldShowTooltip && (
							<Tooltip
								tooltipComponent={<TooltipText />}
								placement="bottom-end"
								tooltipRootStyle={tooltipRootStyle}
								refToObservePositionChange={ref}
							>
								<HelpCircle />
							</Tooltip>
						)}
						<PeriodSelector
							period={period}
							onChange={handlePeriodChange}
							years={years}
							data-qa-id="period-selector"
						/>
					</RowFlexContainer>
					{showSaveDataMenu && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, widgetTitle)}
							elementId={chartId}
							dataToCopy={chartData}
							headersMapping={HEADERS_MAPPING}
						/>
					)}
				</RowFlexContainer>
				<HeightContainer margin={HEIGHT_CONTAINER_MARGIN}>
					{isLoading && <Pending times={4} />}
					{isSuccess && (
						<LinesChart
							lines={filteredLines}
							data={chartData}
							yTickFormatter={yTickFormatter}
							xTickFormatter={getXTickDateFormatter(period)}
							xInterval={0}
							xTicks={xTicks}
							yTicks={yTicks}
							yDomain={calcDomainFromTicks(yTicks)}
							id={chartId}
							yAxisLabel={widgetTitle}
							tooltipLabel={widgetTitle}
							tooltipFormatter={tooltipFormatter}
							// @ts-expect-error TS2322: Type 'SuggestedSpan | undefine...
							hasDataForSomePeriod={data?.suggestedSpan}
							isAnimationActive={showAnimation}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<LineChartLegend lines={filteredLines} data={data} />
			</Root>
		);
	}
);

SubmarketLeaseTradeOut.displayName = 'SubmarketLeaseTradeOut';

const Root = styled(WidgetContainer)`
	margin: 0;
	width: auto;
`;

const rowFlexContainerStyle = {
	marginBottom: '18px',
};

const HEIGHT_CONTAINER_MARGIN = { bottom: 24 };

export const tooltipRootStyle = {
	margin: '0 16px 0 0',
};

const yTickFormatter: TickFormatter = (value) => {
	return numberUtils.formatPercent(value, 'percentInteger');
};

const tooltipFormatter: TickFormatter = (value) =>
	numberUtils.formatPercent(value, 'percentTwoDecimals');

const TooltipText = () => {
	return (
		<>
			Lease Trade Out is&nbsp;the percentage change between the previous lease
			effective rent to&nbsp;the new lease effective rent in&nbsp;the same unit.
			Units vacant longer than ninety (90) days are excluded from this
			calculation. Combined Lease Trade Out shows the weighted aggregation
			of&nbsp;the new lease and renewal rate changes.
		</>
	);
};
