import { useMemo } from 'react';
import {
	CheckedLeaseSpaceType,
	CheckedLeaseStatus,
} from './usePropertyLeasesOptionsState';
import { LeaseComp } from 'types';
import { PropertyLease } from 'api';

type Props<T extends LeaseComp | PropertyLease> = {
	leases: T[];
	checkedSpaceType: CheckedLeaseSpaceType;
	checkedStatus: CheckedLeaseStatus;
};

export const usePropertyLeasesFiltered = <T extends LeaseComp | PropertyLease>({
	leases,
	checkedSpaceType,
	checkedStatus,
}: Props<T>) => {
	const filteredLeases = useMemo(() => {
		return leases.filter((lease) => {
			if (checkedSpaceType === 'All Leases' && checkedStatus === 'All Leases') {
				return true;
			}

			const isSpaceTypeValid =
				checkedSpaceType === 'All Leases' ||
				lease.spaceType === checkedSpaceType ||
				(lease.spaceType === undefined && checkedSpaceType === 'Other');

			return isSpaceTypeValid;
		});
	}, [checkedSpaceType, checkedStatus, leases]);

	return filteredLeases;
};
