import React from 'react';
import { useMarketsHaveFeature, useUserQuery } from 'api';
import { useIsExchange } from 'hooks';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes, useLocation, useMatch } from 'router';
import { HeaderLink } from './UI';
import { MyCompstakMenuButton } from 'exchange/Components/Header/MyCompstakMenuButton';

export const useMyCompstakLinks = () => {
	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;
	const [filters] = useFilters();
	const location = useLocation();

	const { data: user } = useUserQuery();

	const isSavedSearches = !!useMatch(routes.savedSearches.path);

	const filterMarketIds = useMemo(() => {
		return getFiltersMarkets(filters).map((m) => m.id);
	}, [filters]);

	const compType = location.pathname.match('/sales') ? 'sale' : 'lease';

	const { data: ownsAllComps } = useMarketsHaveFeature({
		feature: compType === 'lease' ? 'ownsAllLeaseComps' : 'ownsAllSalesComps',
		marketIds: filterMarketIds,
	});

	if (isEnterprise) {
		const ownCompsSearch = '?owns=own&isMyComps=true';

		return (
			<>
				{!user.salesTrader && (
					<HeaderLink
						to={routes.savedSearches.path}
						active={isSavedSearches}
						state={{
							modal: true,
						}}
					>
						Saved Searches
					</HeaderLink>
				)}
				{user.salesTrader && !ownsAllComps && (
					<HeaderLink
						to={
							compType === 'lease'
								? routes.searchLeasesByView.toHref({ view: 'list' }) +
									ownCompsSearch
								: routes.searchSalesByView.toHref({ view: 'list' }) +
									ownCompsSearch
						}
					>
						My Comps
					</HeaderLink>
				)}
			</>
		);
	} else {
		return (
			<MyCompstakMenuButton compType={compType} isActive={isSavedSearches} />
		);
	}
};
