import { MarketRentAnalyticsRentType } from 'api';
import { useMarketRentAnalyticsState } from 'maps';
import { useMarketRentState } from './MarketRentProvider';

export const useSectionTitle = () => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();
	const [marketRentState] = useMarketRentState();

	const { selectedPropertyTypeId } = marketRentState;

	const propertyTypeText = selectedPropertyTypeId
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		? PROPERTY_TYPE_TEXT_MAP[selectedPropertyTypeId]
		: '';

	const rentTypeText = RENT_TYPE_TEXT_MAP[marketRentAnalyticsState.rentType];

	return `${propertyTypeText} market ${rentTypeText} heat map`;
};

const PROPERTY_TYPE_TEXT_MAP = {
	1: 'Office',
	7: 'Industrial',
};

const RENT_TYPE_TEXT_MAP = {
	[MarketRentAnalyticsRentType.STARTING]: 'starting rent',
	[MarketRentAnalyticsRentType.EFFECTIVE]: 'effective rent',
};
