import { useMemo } from 'react';

type Props<Row, ExtraData = object> = {
	columns: ExcelColumn<Row, ExtraData>[];
	rows: Row[];
	extraData?: ExtraData;
};

export const useExcelData = <Row, ExtraData>({
	rows,
	columns,
	extraData,
}: Props<Row, ExtraData>) => {
	return useMemo(() => {
		return rows.map((row) => {
			const obj = {};

			for (const c of columns) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				obj[c.header] = c.body({
					row,
					extraData: extraData as ExtraData,
				});
			}

			return obj;
		});
	}, [rows, columns, extraData]);
};

export type ExcelColumn<Row, ExtraData = object> = {
	header: string;
	body: (args: { row: Row; extraData: ExtraData }) => string | number;
};
