import styled from 'styled-components';
import { RecommendedSearch } from './useRecommendedSearches';
import { Button, TooltipV2 } from '@compstak/ui-kit';
import ArrowRightIcon from 'ui/svg_icons/arrow_pointer_right.svg';
import { Link, SEARCH_BY_VIEW_ROUTES } from 'router';
import { CompType } from 'types';
import { filtersToQueryString } from 'models/filters/util';
import { trackSegmentRecommendedSearch } from 'middleware/tracking/segment';
import { MouseEventHandler, useMemo, useState } from 'react';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { isMarketAccessible } from 'util/marketAccessUtils';
import { useMarketsFeaturesQuery } from 'api';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { Modal } from 'Components/Modals/common/UI';

type RecommendedSearchLayoutProps = {
	compType: CompType;
	recommendedSearch: RecommendedSearch;
};

export const RecommendedSearchLayout = ({
	compType,
	recommendedSearch,
}: RecommendedSearchLayoutProps) => {
	const [isMarketsPermissionModalOpen, setIsMarketsPermissionModalOpen] =
		useState(false);

	const { data: permissions } = useMarketsFeaturesQuery();
	const recommendedSearchCompType = recommendedSearch.compType ?? compType;
	const requiredAccessTypeToViewRecommednedSearch =
		recommendedSearch.compType === 'lease' ? 'lease' : 'sale';

	const marketsWithoutAccess = useMemo(() => {
		return getFiltersMarkets(recommendedSearch.filters).filter((market) => {
			return !isMarketAccessible(
				market.id,
				requiredAccessTypeToViewRecommednedSearch,
				permissions
			);
		});
	}, [
		requiredAccessTypeToViewRecommednedSearch,
		permissions,
		recommendedSearch.filters,
	]);

	const onClick: MouseEventHandler<HTMLAnchorElement> = (ev) => {
		if (marketsWithoutAccess.length) {
			ev.preventDefault();
			setIsMarketsPermissionModalOpen(true);
		}

		trackSegmentRecommendedSearch(recommendedSearch);
	};

	const onUpgradeModalClose = () => setIsMarketsPermissionModalOpen(false);

	return (
		<>
			{isMarketsPermissionModalOpen && (
				<Modal onClose={onUpgradeModalClose}>
					<MultiMarketUpgradeModal
						compType={requiredAccessTypeToViewRecommednedSearch}
						selectedMarkets={marketsWithoutAccess.map(({ id }) => id)}
						onClose={onUpgradeModalClose}
					/>
				</Modal>
			)}
			<Container
				to={`${SEARCH_BY_VIEW_ROUTES[recommendedSearchCompType].toHref({ view: 'map' })}?${filtersToQueryString(recommendedSearch.filters)}`}
				onClick={onClick}
			>
				<TooltipV2 content={recommendedSearch.tooltip}>
					<Header>
						<recommendedSearch.icon />
						<Title>{recommendedSearch.title}</Title>
					</Header>
				</TooltipV2>
				<FiltersText>{recommendedSearch.filtersText}</FiltersText>
				<ViewCompsButton variant="ghost2">
					<span>View Comps</span>
					<ArrowRightIcon />
				</ViewCompsButton>
			</Container>
		</>
	);
};

const Container = styled(Link)`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	flex: 1 1 0;
	background-color: ${({ theme }) => theme.colors.neutral.n10};
	border: 1px solid ${({ theme }) => theme.colors.neutral.n50};
	border-radius: 3px;
	min-width: 15rem;
	padding: 1rem;
	color: ${({ theme }) => theme.colors.gray.gray700};
	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.colors.neutral.n30};
		color: ${({ theme }) => theme.colors.gray.gray700};
	}
`;

const Header = styled.div`
	display: inline-flex;
	gap: 0.5rem;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
`;

const Title = styled.span`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const FiltersText = styled.div`
	font-size: 0.875rem;
	height: 3rem;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.5rem;
	max-width: 16rem;
`;

const ViewCompsButton = styled(Button)`
	align-self: flex-end;
	font-weight: 400;
	font-size: 0.75rem;
	text-transform: none;
	border: none;
	margin-top: auto;
	gap: 0.25rem;
`;
