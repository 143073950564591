import { MufaSubmarketPolygon } from 'api';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { TabContentTitle } from 'Components/UI';
import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { PolygonDataSource, Submarket } from './Submarket';

type SubmarketTrendsHeaderProps = {
	children: ReactNode;
	submarketPolygon?: SubmarketPolygon | MufaSubmarketPolygon;
	submarketName: string | undefined;
	polygonDataSource: PolygonDataSource;
};

export const SubmarketTrendsHeader = memo<SubmarketTrendsHeaderProps>(
	({ children, submarketName, submarketPolygon, polygonDataSource }) => {
		return (
			<SectionHeader>
				<TabContentTitle>{children}</TabContentTitle>
				{submarketName && submarketPolygon && (
					<Submarket
						submarket={submarketName}
						submarketPolygon={submarketPolygon}
						polygonDataSource={polygonDataSource}
					/>
				)}
			</SectionHeader>
		);
	}
);

SubmarketTrendsHeader.displayName = 'SubmarketTrendsHeader';

const SectionHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 16px;
`;
