import { SimpleTable } from '@compstak/ui-kit';
import { useMarketsHaveFeature, usePropertyById, usePropertyLoan } from 'api';
import { publicLoanColumns } from './publicLoanColumns';

type Props = {
	propertyId: number;
};

export const PublicLoansTable = ({ propertyId }: Props) => {
	const { data: property } = usePropertyById({ propertyId });

	const { data: hasSalesAccess } = useMarketsHaveFeature({
		marketIds: property ? [property.marketId] : [],
		feature: 'salesAccess',
		isEnabled: !!property,
	});

	const { data: loans } = usePropertyLoan({
		propertyId,
		isEnabled: hasSalesAccess,
	});

	if (!loans?.publicLoans || loans?.publicLoans?.length === 0) return null;

	return (
		<SimpleTable
			id="public-loans-table"
			columns={publicLoanColumns}
			rows={loans?.publicLoans ?? []}
			scrollContainerProps={{
				style: {
					maxHeight: 400,
				},
			}}
		/>
	);
};
