import React, { ChangeEventHandler, useState } from 'react';

import '../styles/marketSelector.nomodule.less';
import { Market } from '@compstak/common';
import { FiltersObject } from 'models/filters/types';
import { MarketList, MarketListProps } from './MarketList/MarketList';
import { useDispatch } from 'react-redux';
import { closeMarketSelector, openMarketSelector } from 'actions/home';
import { useChangeMarkets } from 'hooks/useChangeMarkets';
import { useCheckMarketAccess } from 'util/marketAccessUtils';

//===============================
// DEPRECATED
// Only used in ExchangeDashboard
//===============================

export type MarketSelectorProps = {
	filters: FiltersObject;
} & Omit<MarketListProps, 'market' | 'searchTerm' | 'search' | 'changeMarket'>;

export const MarketSelector = (props: MarketSelectorProps) => {
	const [searchTerm, setSearchTerm] = useState('');
	const dispatch = useDispatch();
	const changeMarkets = useChangeMarkets({
		filterContext: 'exchange-dashboard',
		multiMarketMode: false,
	});
	const { checkMarketAccess } = useCheckMarketAccess();

	const search: ChangeEventHandler<HTMLInputElement> = (event) => {
		setSearchTerm(event.target.value.toLowerCase());
	};

	const toggleMarketList = () => {
		if (props.marketSelectorExpanded) {
			dispatch(closeMarketSelector());
			setSearchTerm('');
		} else {
			dispatch(openMarketSelector());
		}
	};

	const changeMarket = (market: Market) => {
		if (
			props.filters.market.id !== market.id &&
			checkMarketAccess({ marketId: market.id, compType: props.compType })
		) {
			changeMarkets([market]);
		}
	};

	const renderCurrentMarket = () => {
		let className = '';
		if (props.marketSelectorExpanded) {
			className = 'opened ';
		}

		const currentMarket = props.filters.market;

		const stateCode = appendStateCodes(currentMarket.states);

		return (
			<div onClick={toggleMarketList} className={className + 'market-toggle'}>
				{`${currentMarket.displayName} ${stateCode}`}
			</div>
		);
	};

	return (
		<div className="market-selector">
			{renderCurrentMarket()}

			<MarketList
				appConfig={props.appConfig}
				changeMarket={changeMarket}
				compType={props.compType}
				market={props.filters.market}
				marketSelectorExpanded={props.marketSelectorExpanded}
				permissions={props.permissions}
				search={search}
				searchTerm={searchTerm}
			/>
		</div>
	);
};

export function appendStateCodes(states: Market['states']) {
	if (states.length === 0) {
		return '';
	}

	return '(' + states.map((state) => state.code).join(', ') + ')';
}
