import circle from '@turf/circle';
import { RadiusFilter } from 'models/filters/types';
import { RadiusFeature } from './type';

export const createRadiusFeatureFromRadiusFilter = (
	radiusFilter: RadiusFilter
): RadiusFeature => {
	const center = [radiusFilter.center.lng, radiusFilter.center.lat];
	const radius = radiusFilter.distance;
	const buildingAddressAndCity = radiusFilter.buildingAddressAndCity;
	return circle(center, radius, {
		units: 'kilometers',
		properties: {
			isCircle: true,
			center,
			radiusInKm: radius,
			buildingAddressAndCity,
		},
	});
};
