import React from 'react';
import Cookies from 'cookies-js';

import CrossIcon from '../../ui/svg_icons/cross.svg';
import styled from 'styled-components';
import styles from './header-notification.less';

const isServer = typeof window === 'undefined';

type State = any;

export default class CookiesPolicyNotification extends React.Component<
	{},
	State
> {
	constructor(props: {}) {
		super(props);
		this.state = {
			cookiesPolicyAccepted:
				isServer || Cookies.get('cookies_policy_accepted') === 'true',
		};
	}

	componentDidMount() {
		if (isServer) {
			return;
		}
		if (
			!this.state.cookiesPolicyAccepted &&
			window.navigator.language.toLowerCase().indexOf('en-gb') !== -1
		) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onShow' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.onShow && this.props.onShow();
		}
	}

	acceptCookiesPolicy = () => {
		if (!isServer) {
			const expirationDate = new Date();
			expirationDate.setFullYear(expirationDate.getFullYear() + 1);

			const domain = window.location.hostname.endsWith('compstak.com')
				? 'compstak.com'
				: window.location.hostname;

			const opts = {
				expires: expirationDate,
				domain: domain,
			};
			Cookies.set('cookies_policy_accepted', 'true', opts);
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onAccept' does not exist on type 'Readon... Remove this comment to see the full error message
			this.setState({ cookiesPolicyAccepted: true }, this.props.onAccept);
		}

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'close' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.close) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'close' does not exist on type 'Readonly<... Remove this comment to see the full error message
			this.props.close();
		}
	};

	render() {
		if (
			this.state.cookiesPolicyAccepted ||
			(window.navigator.language.toLowerCase().indexOf('en-gb') === -1 &&
				!localStorage.getItem('fake-gb'))
		) {
			return false;
		} else {
			return (
				<div className={styles.headerNotification}>
					<span className={styles.close} onClick={this.acceptCookiesPolicy}>
						<StyledCrossIcon />
					</span>
					<p>
						We use cookies to give you the best experience when using CompStak.
						Read our{' '}
						<a href="http://www.compstak.com/legal#CookiePolicy">
							privacy and cookie policy
						</a>{' '}
						to learn more.
					</p>
				</div>
			);
		}
	}
}

const StyledCrossIcon = styled(CrossIcon)`
	width: 13px;
	height: 13px;
`;
