import React from 'react';
import realPageLogo from 'images/RealPage-logo.png';
import { CompType } from 'types/comp';
import { moveMarketSubmarketCountyAttributes } from 'util/comp-format/src/format';
import { CheckboxEnabled, CheckboxDisabled } from '@compstak/ui-kit';
import { highlightText } from '../../../../../utils/highlightText';
import {
	Wrap,
	PoweredBy,
	ListItem,
	ListItemHeader,
	Header,
	Content,
	GlobalStyle,
	NoResultsWrap,
	NoResultsTitle,
	NoResultsDescription,
	Footer,
	ResetLabel,
} from './UI';
import { SectionedAttr } from './TableSettingsMenu';
import { PresetSelect } from './PresetSelect/PresetSelect';

type TableSettingsMenuViewProps = {
	compType: CompType;
	resetColumns: NoArgCallback;
	addColumn: (id: number | string) => void;
	removeColumn: (id: number | string) => void;
	sectionedAttrs: SectionedAttr[];
	searchTerm: string;
	element: React.ReactNode;
};

export const TableSettingsMenuView = ({
	compType,
	resetColumns,
	addColumn,
	removeColumn,
	sectionedAttrs,
	searchTerm,
	element,
}: TableSettingsMenuViewProps) => {
	const items = sectionedAttrs.reduce<React.ReactNode[]>((list, section) => {
		if (!section.title) return list;

		if (
			(compType === 'lease' && section.title === 'Building') ||
			(compType === 'sale' && section.title === 'Property') ||
			(compType === 'property' && section.title === 'Location')
		) {
			section.attributes = moveMarketSubmarketCountyAttributes(
				section.attributes
			);
		}

		const isMultifamilySection = section.title === 'Multifamily';
		const isTreppSection = section.title === 'Trepp';

		if (!searchTerm) {
			let listItemHeader = (
				<ListItemHeader key={section.title} data-qa-id={headerItemTestId}>
					{isTreppSection ? 'Loan Info' : section.title}
				</ListItemHeader>
			);

			if (isMultifamilySection) {
				listItemHeader = renderListItemHeaderWithLogo({
					title: section.title,
					logo: realPageLogo,
				});
			}

			list.push(listItemHeader);
		}

		let attributeList = section.attributes;

		if (searchTerm) {
			attributeList = attributeList.filter((attribute) => {
				return attribute.displayName?.toLowerCase().includes(searchTerm);
			});
		}

		const attributeElements = attributeList.map((attribute) => {
			// @ts-expect-error Property 'active' does not exist on type (something wrong with dynamic typings)
			const checked = !!attribute.active;

			return (
				<ListItem
					key={attribute.name}
					data-qa-id={`${listItemTestId}-${attribute.name}`}
					onClick={() => {
						if (checked) {
							removeColumn(attribute.id);
						} else {
							addColumn(attribute.id);
						}
					}}
				>
					{checked ? <CheckboxEnabled /> : <CheckboxDisabled />}
					<span>
						{searchTerm && attribute.displayName
							? highlightText(attribute.displayName, searchTerm)
							: attribute.displayName}
					</span>
				</ListItem>
			);
		});
		return list.concat(attributeElements);
	}, []);

	return (
		<Wrap>
			<GlobalStyle />
			<Header>
				<span>Customize Columns</span>
				<PresetSelect compType={compType} />
			</Header>
			{element}
			<Content data-qa-id={contentTestId}>
				{items.length > 0 ? (
					items
				) : (
					<NoResultsWrap data-qa-id={noResultsTestId}>
						<NoResultsTitle>No results found</NoResultsTitle>
						<NoResultsDescription>
							There are no columns that match your search criteria.
						</NoResultsDescription>
					</NoResultsWrap>
				)}
			</Content>
			<Footer>
				<ResetLabel data-qa-id={resetColumnsTestId} onClick={resetColumns}>
					Reset to Default
				</ResetLabel>
			</Footer>
		</Wrap>
	);
};

export const headerItemTestId = 'headerItemTestId';
export const listItemTestId = 'listItemTestId';
export const resetColumnsTestId = 'resetColumnsTestId';
export const contentTestId = 'contentTestId';
export const noResultsTestId = 'noResultsTestId';

function renderListItemHeaderWithLogo({
	title,
	logo,
}: {
	title: string;
	logo: string;
}) {
	return (
		<ListItemHeader key={title} data-qa-id={headerItemTestId}>
			<span>{title}</span>
			<PoweredBy>
				<span>Powered by&nbsp;</span>
				<img src={logo} />
			</PoweredBy>
		</ListItemHeader>
	);
}
