import React from 'react';

import { Chart } from 'Pages/Analytics/analytics';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { deleteChart } from '../../actions';
import { useModal } from 'providers/ModalProvider';
import { useDispatch } from 'react-redux';

type Props = {
	chart: Chart;
};

export const DeleteChartModal = (props: Props) => {
	const { closeModal } = useModal();
	const dispatch = useDispatch();

	const handleDelete = () => {
		if (props.chart.id) {
			dispatch(deleteChart(props.chart.id));
		}
		closeModal();
	};

	return (
		<>
			<ModalTitle>Delete Chart</ModalTitle>
			<ModalParagraph>
				You’re trying to delete the chart: <b>{props.chart.title}</b>. This
				action cannot be undone, are you sure you want to proceed?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton variant="error" onClick={handleDelete}>
					Delete
				</ModalButton>
			</ModalButtons>
		</>
	);
};
