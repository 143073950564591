import { Market } from '@compstak/common';
import { loadSubmarkets, removeSubmarkets } from 'actions/search';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import 'Components/Filters/styles/filters.nomodule.less';
import { SubmarketFilter } from 'Components/Filters/Fields/SubmarketFilter/SubmarketFilter';
import { MarketSelector } from 'Pages/Home/Sidebar/Components/MarketSelector';
import 'Pages/Home/Sidebar/styles/marketSelector.nomodule.less';
import { useAppConfig, usePermissions } from 'Pages/Login/reducers';
import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'router';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { DarkSidebar } from 'ui/UI';
import { useAppSelector } from 'util/useAppSelector';
import { ExchangeDashboardFiltersV2 } from './ExchangeDashboardFiltersV2';
import { SidebarHeader } from '../Pages/Search/Sidebar/Components/SidebarHeader';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { Section } from './types';

type ExchangeDashboardSideBarProps = {
	sections: Section[];
	sideBarState: 'open' | 'closed';
};

export const DashboardSidebarV2 = (props: ExchangeDashboardSideBarProps) => {
	const [filters, setFilters, resetFilters] = useFilters('exchange-dashboard');
	const appConfig = useAppConfig();
	const permissions = usePermissions();
	const marketSelectorExpanded = useAppSelector(
		(s) => s.home.marketSelectorExpanded
	);
	const currentMarket = filters.market as Market;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(removeSubmarkets());
		dispatch(loadSubmarkets(currentMarket));
	}, [currentMarket, dispatch]);

	const setFiltersCount = useMemo(() => {
		return getSetFilterKeysExceptMarkets(filters).length;
	}, [filters]);

	const {
		element: filterSearchInput,
		searchTerm: filterInputSearchTerm,
		reset: resetFilterSearchInput,
	} = useListSearchInput({
		inputDataQaId: 'exchangeDashboardFilterFieldsSearchInputTestId',
		inputPlaceholder: 'Search for filters',
	});

	const onNewSearchClick = useCallback(() => {
		resetFilters();
		resetFilterSearchInput();
	}, [resetFilterSearchInput, resetFilters]);

	return (
		<DarkSidebar sideBarState={props.sideBarState}>
			<Wrap expanded={marketSelectorExpanded}>
				<SidebarHeader
					filterCount={setFiltersCount}
					onNewSearchClick={onNewSearchClick}
				/>
				<div className="market-selector-container">
					<MarketSelector
						appConfig={appConfig}
						compType={'lease'}
						filters={filters}
						permissions={permissions}
						marketSelectorExpanded={marketSelectorExpanded}
					/>
				</div>
				<SubmarketAndFilterSearchContainer>
					<SubmarketFilterContainer>
						<SubmarketFilter
							filters={filters}
							isActive={true}
							onFilterChange={setFilters}
						/>
					</SubmarketFilterContainer>
					{filterSearchInput}
				</SubmarketAndFilterSearchContainer>
				<ExchangeDashboardFiltersV2
					appConfig={appConfig}
					searchTerm={filterInputSearchTerm}
				/>
			</Wrap>
		</DarkSidebar>
	);
};

const SectionButton = styled(Link)`
	background: #222530;
	color: #fff;
	display: block;
	min-height: 50px;
	margin-bottom: 1px;
	padding: 10px 12px;
	cursor: pointer;
	position: relative;
	border: 2px solid transparent;
	transition:
		background 0.3s ease-in-out,
		border 0.3s ease-in-out,
		padding 0.5s ease-in-out;
`;

const Wrap = styled.div<{ expanded: boolean }>`
	color: #fff;
	width: 100%;

	& ${SectionButton}:first-of-type {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		margin-top: 2rem;
	}

	& ${SectionButton}:last-of-type {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	.market-selector-container {
		position: ${({ expanded }) => (expanded ? 'static' : 'sticky')};
		top: 3.5rem;
		z-index: ${({ theme }) => theme.zIndex.menu};
		background: ${({ theme }) => theme.colors.gray.gray700};
		height: 4rem;
	}
	.market-selector {
		margin: 0 20px;
	}

	.home-sidebar-controls {
		padding: 0 20px 10px;
	}
`;

const SubmarketAndFilterSearchContainer = styled.div`
	position: sticky;
	top: 7.5rem;
	z-index: ${({ theme }) => theme.zIndex.menu};
	background: ${({ theme }) => theme.colors.gray.gray700};
`;

const SubmarketFilterContainer = styled.div`
	padding: 0 20px 10px 20px;
`;
