import { ColumnPreference } from '@compstak/common';
import {
	useAllPresetsQuery,
	useApplyPresetMutation,
	useUpdatePreferencesMutation,
	useUserQuery,
} from 'api';
import { createContext, ReactNode, useContext, useState } from 'react';
import { flushSync } from 'react-dom';
import { COLUMN_PREF_MAP, DEFAULT_COLUMN_WIDTH } from '../constants';
import { filterColumnPreferences } from '../filterColumnPreferences';
import { useOptionsContext } from '../OptionsProvider';
import { SearchTableColumn } from '../types';
import { useSearchTableColumns } from './useSearchTableColumns';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type Props = {
	children: ReactNode;
};

/** Have to use React state ( not user.preferences server state ) for column preferences because:
 * - we want the updates in the UI to be immediate
 * - we don't want race conditions (!)
 *
 * So, when there is an update, we update the React state and then fire the
 * mutation request and hope for the best.
 * And we always read from the React state.
 */
export const ColumnsProvider = ({ children }: Props) => {
	const { columnPreferencesType } = useOptionsContext();

	const { data: user } = useUserQuery();

	const featureFlags = useFeatureFlags();

	const userColumnPreferencesKey = COLUMN_PREF_MAP[columnPreferencesType];

	const [columnPreferences, setColumnPreferences] = useState(() => {
		return filterColumnPreferences(
			user.preferences[userColumnPreferencesKey],
			featureFlags
		);
	});

	const { data: presets, isFetching: isFetchingPresets } = useAllPresetsQuery(
		columnPreferencesType
	);

	const { mutate: updatePreferences } = useUpdatePreferencesMutation();

	const { mutateAsync: mutateApplyPreset, isLoading: isLoadingApplyPreset } =
		useApplyPresetMutation();

	const toggleColumn = (id: number) => {
		const isSelected = columnPreferences.find((p) => p.compAttributeId === id);
		let newColumnPreferences: ColumnPreference[] = [];
		if (isSelected) {
			newColumnPreferences = columnPreferences.filter(
				(c) => c.compAttributeId !== id
			);
		} else {
			newColumnPreferences = [
				{ compAttributeId: id, width: DEFAULT_COLUMN_WIDTH },
				...columnPreferences,
			];
		}
		setColumnPreferences(newColumnPreferences);
		updatePreferences({
			[userColumnPreferencesKey]: newColumnPreferences,
		});
	};

	const applyPreset = (presetName: string) => {
		mutateApplyPreset({
			preset: presetName,
			property: userColumnPreferencesKey as any,
		}).then((preferences) => {
			// To avoid the previous preset value showing for a brief moment
			// before switching to the newest value
			flushSync(() => {
				setColumnPreferences(
					filterColumnPreferences(
						preferences[userColumnPreferencesKey],
						featureFlags
					)
				);
			});
		});
	};

	const resetToDefault = () => {
		if (!presets || isFetchingPresets) return;
		const defaultPreset = Object.keys(presets).find((p) =>
			p.startsWith('summary')
		);
		if (defaultPreset) {
			applyPreset(defaultPreset);
		}
	};

	const onColumnResize = (columnIndex: number, newWidth: number) => {
		const newColumnPreferences = [...columnPreferences];
		newColumnPreferences[columnIndex] = {
			...newColumnPreferences[columnIndex],
			width: newWidth,
		};
		setColumnPreferences(newColumnPreferences);
		updatePreferences({
			[userColumnPreferencesKey]: newColumnPreferences,
		});
	};

	const onColumnReorder = (fromIndex: number, toIndex: number) => {
		const newColumnPreferences = move(columnPreferences, fromIndex, toIndex);
		setColumnPreferences(newColumnPreferences);
		updatePreferences({
			[userColumnPreferencesKey]: newColumnPreferences,
		});
	};

	const columns = useSearchTableColumns({
		columnPreferences,
	});

	return (
		<ColumnsContext.Provider
			value={{
				selectedColumnPreferences: columnPreferences,
				applyPreset,
				toggleColumn,
				isLoadingApplyPreset,
				columns,
				onColumnResize,
				onColumnReorder,
				resetToDefault,
			}}
		>
			{children}
		</ColumnsContext.Provider>
	);
};

const move = <E,>(arr: E[], fromIndex: number, toIndex: number) => {
	const copy = [...arr];
	const element = copy[fromIndex];
	copy.splice(fromIndex, 1);
	copy.splice(toIndex, 0, element);
	return copy;
};

const ColumnsContext = createContext({} as ColumnsContextValue);

export const useColumnsContext = () => useContext(ColumnsContext);

type ColumnsContextValue = {
	selectedColumnPreferences: ColumnPreference[];
	applyPreset: (presetName: string) => void;
	toggleColumn: (id: number) => void;
	isLoadingApplyPreset: boolean;
	columns: SearchTableColumn<any>[];
	onColumnResize: (columnIndex: number, newWidth: number) => void;
	onColumnReorder: (fromIndex: number, toIndex: number) => void;
	resetToDefault: () => void;
};
