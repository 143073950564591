import { DataSetType } from 'Pages/Analytics/analytics';
import {
	MufaPropertyAveragesResponse,
	useCompsetIds,
	usePropertyAverages,
	usePropertyById,
	usePropertyMarket,
} from 'api';
import { filterToQueryString } from 'models/filters/util';
import { useMemo } from 'react';
import { routes } from 'router';

type Props = {
	propertyId: number;
};

export const useCompsetSendToAnalyticsUrl = ({ propertyId }: Props) => {
	const { data: compsetIds } = useCompsetIds({ propertyId });
	const { data: property } = usePropertyById({ propertyId });

	const { data: propertyAverages } = usePropertyAverages({
		ids: compsetIds,
		marketIds: property ? [property.marketId] : [],
		enabled: !!property,
	});

	const { data: market } = usePropertyMarket({ propertyId });

	return {
		data: useMemo(() => {
			if (!market || !propertyAverages || !compsetIds) return;

			const hasRpData =
				(propertyAverages as MufaPropertyAveragesResponse).withRpDataCount > 0;

			return routes.analyticsImport.toHref(undefined, {
				series: filterToQueryString(market, 'market') ?? '',
				tab: hasRpData ? 'property' : 'lease',
				'dataset-types': hasRpData ? DataSetType.MUFA : DataSetType.COMMERCIAL,
				propertyId: JSON.stringify(compsetIds),
			});
		}, [propertyAverages, market, compsetIds]),
	};
};
