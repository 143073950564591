import { useUserQuery } from 'api';
import { useRequestDemoMutation } from '../../api/requestDemo/requestDemo';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { Flex, useNotifications } from '@compstak/ui-kit';

export const ExportTrialLimitExceededModal = () => {
	const {
		mutate: requestDemo,
		isLoading: isRequestDemoLoading,
		isSuccess: isRequestDemoSuccess,
	} = useRequestDemoMutation();
	const { addNotification } = useNotifications();
	const { data: user } = useUserQuery();
	const { closeModal } = useModal();

	const onClickRequestDemo = () => {
		if (!user.username) {
			addNotification({
				icon: 'error',
				title: 'Error',
				description:
					'The demo request has been failed. Please, try again later',
			});
			return;
		}
		requestDemo({ origin: 'export', usernameOrEmail: user.username });
	};

	return (
		<div>
			<ModalTitle>Comp Exports Exceeded</ModalTitle>
			<Flex direction="column" justifyContent="center">
				<ModalParagraph>
					During your trial, you have a limited number of exports.
				</ModalParagraph>
			</Flex>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					variant="primary"
					onClick={onClickRequestDemo}
					disabled={!user || isRequestDemoSuccess}
					isLoading={isRequestDemoLoading}
				>
					{isRequestDemoSuccess
						? "Thanks! We'll contact you shortly"
						: 'Get more exports'}
				</ModalButton>
			</ModalButtons>
		</div>
	);
};
