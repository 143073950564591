import { nullValueText } from '@compstak/common';
import { renderTableValue } from '@compstak/ui-kit';
import { useMufaKeyMetricsQuery } from 'api';
import { formatPercent } from 'format';
import { routes } from 'router';
import styled from 'styled-components';
import {
	KeyValueGrid,
	KeyValueItem,
	KeyValueLabel,
	KeyValueValue,
} from '../UI';
import { RealPage } from '../RealPage';
import { TableTitle } from './UI';
import { formatMoneyMufa } from './utils';

export const KeyMetrics = () => {
	const params = routes.propertyById.useParams();
	const { data: keyMetrics } = useMufaKeyMetricsQuery({
		propertyId: params.propertyId,
	});

	if (!keyMetrics || Object.keys(keyMetrics).length === 0) return null;

	return (
		<div>
			<KeyMetricsCard>
				<TableTitle>Key Property Metrics</TableTitle>
				<KeyValueGrid>
					<KeyValueItem>
						<KeyValueLabel>Asking Rent $/Unit</KeyValueLabel>
						<KeyValueValue>
							{formatMoneyMufa(keyMetrics.askingRentPerUnit)}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Effective Rent $/Unit</KeyValueLabel>
						<KeyValueValue>
							{formatMoneyMufa(keyMetrics.effectiveRentPerUnit)}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Concessions ($)</KeyValueLabel>
						<KeyValueValue>
							{renderTableValue(keyMetrics.concessionValue, formatMoneyMufa)}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Units with Concessions</KeyValueLabel>
						<KeyValueValue>
							{renderTableValue(
								keyMetrics.unitsWithConcessionsPercentage,
								(v) => {
									if (v === 0) return nullValueText;
									return formatPercent(v, 0);
								}
							)}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Asking Rent $/SF</KeyValueLabel>
						<KeyValueValue>
							{formatMoneyMufa(keyMetrics.askingRentPerSqFt)}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Effective Rent $/SF</KeyValueLabel>
						<KeyValueValue>
							{formatMoneyMufa(keyMetrics.effectiveRentPerSqFt)}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Concessions (%)</KeyValueLabel>
						<KeyValueValue>
							{renderTableValue(keyMetrics.concessionsPercentage, (v) => {
								if (v === 0) return nullValueText;
								return formatPercent(v, 0);
							})}
						</KeyValueValue>
					</KeyValueItem>
					<KeyValueItem>
						<KeyValueLabel>Occupancy</KeyValueLabel>
						<KeyValueValue>
							{formatPercent(keyMetrics.occupancyPercentage, 1)}
						</KeyValueValue>
					</KeyValueItem>
				</KeyValueGrid>
			</KeyMetricsCard>
			<RealPage />
		</div>
	);
};

const KeyMetricsCard = styled.div`
	padding: 1.5rem 1rem;
	border-radius: 0.25rem;
	background-color: ${(p) => p.theme.colors.neutral.n10};
	box-shadow: 0px 0.942px 1.885px 0px rgba(51, 51, 51, 0.1);
	margin-bottom: 0.75rem;
`;
