import React from 'react';
import { LeaseActivityTable } from './LeaseActivityTable';
import { ExchangeSection } from './UI';
import { Section } from './types';

type Props = {
	setSectionsInView: React.Dispatch<React.SetStateAction<Section['type'][]>>;
};

export const LeaseActivity: React.FC<Props> = (props) => {
	return (
		<ExchangeSection
			id="lease-activity"
			title="Lease Activity"
			setSectionsInView={props.setSectionsInView}
		>
			<LeaseActivityTable />
		</ExchangeSection>
	);
};
