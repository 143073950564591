import { TabContent } from '@compstak/ui-kit';
import { CompsetTab } from '../CompsetTab';
import { LeasesSelectionProvider, LeasesTab } from '../LeasesTab';
import { LoansTab } from '../LoansTab';
import { MultifamilyTab } from '../MultifamilyTab';
import { SalesTab } from '../SalesTab';
import { SalesSelectionProvider } from '../SalesTab/SalesSelectionProvider';
import { StackingPlanTab } from '../StackingPlanTab';
import { SubmarketTrendsTab } from '../SubmarketTrendsTab';
import { TransitScreenTab } from '../TransitScreenTab';
import { PropertyPageTab } from '../types';
import { useIsExchange } from 'hooks';
import { useIsMufaProperty, usePropertySalesQuery } from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const TabsContent = () => {
	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;
	const flags = useFeatureFlags();

	const params = routes.propertyById.useParams();

	const { data: isMufa } = useIsMufaProperty({
		propertyId: params.propertyId,
	});

	const showMufaTab = isEnterprise && isMufa;

	const { data: salesData } = usePropertySalesQuery({
		propertyId: params.propertyId,
	});

	return (
		<TabContentContainer>
			<TabContent value={PropertyPageTab.COMPSET}>
				<CompsetTab />
			</TabContent>
			<TabContent value={PropertyPageTab.SUBMARKET_TRENDS}>
				<SubmarketTrendsTab />
			</TabContent>
			{showMufaTab && (
				<TabContent value={PropertyPageTab.MULTIFAMILY}>
					<MultifamilyTab />
				</TabContent>
			)}
			<TabContent value={PropertyPageTab.LEASES}>
				<LeasesSelectionProvider>
					<LeasesTab />
				</LeasesSelectionProvider>
			</TabContent>
			<TabContent value={PropertyPageTab.SALES}>
				<SalesSelectionProvider propertySales={salesData?.comps}>
					<SalesTab />
				</SalesSelectionProvider>
			</TabContent>
			<TabContent value={PropertyPageTab.STACKING_PLAN}>
				<StackingPlanTab />
			</TabContent>
			{(isEnterprise || flags.ExchangeLoans) && (
				<TabContent value={PropertyPageTab.LOANS}>
					<LoansTab />
				</TabContent>
			)}
			<TabContent value={PropertyPageTab.TRANSIT}>
				<TransitScreenTab />
			</TabContent>
		</TabContentContainer>
	);
};

const TabContentContainer = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.gray.gray300};
`;
