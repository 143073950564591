import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { CheckExportStatusParams, CheckExportStatusResponse } from '../types';
import { createFetchFn } from '../utils';

const checkSalesExportStatus = createFetchFn<
	CheckExportStatusParams,
	CheckExportStatusResponse
>({
	method: 'post',
	getURL: () => `/api/salesComps/checkExportStatus`,
	getBody: ({ compIds, format }) => ({ compIds, format }),
});

export const useCheckSalesExportStatusQuery = (
	params: CheckExportStatusParams,
	options?: UseQueryOptions<CheckExportStatusResponse>
) =>
	useQuery({
		queryKey: [QUERY_KEYS.CHECK_SALES_EXPORT_STATUS, params],
		queryFn: checkSalesExportStatus(params),
		...options,
	});
