import {
	MapAnalyticsMetricRadioGroup,
	MapAnalyticsFormSection,
	MapAnalyticsMetric,
	MapAnalyticsSectionTitle,
} from 'maps';
import { MapAnalyticsSettingsCloseButton } from '../MapAnalyticsSettingsCloseButton';
import { MapAnalyticsForm, MapAnalyticsTitleInput } from '../UI';
import { useLeaseExpirationState } from './LeaseExpirationProvider';

export const LeaseExpirationSettingsMenu = () => {
	const [state, setState] = useLeaseExpirationState();

	const onChangeMetric = (metric: MapAnalyticsMetric) => {
		setState((s) => ({ ...s, metric }));
	};

	const onClickClose = () => {
		setState((s) => ({ ...s, view: 'read' }));
	};

	return (
		<>
			<MapAnalyticsForm>
				<MapAnalyticsFormSection>
					<MapAnalyticsSectionTitle>Title</MapAnalyticsSectionTitle>
					<MapAnalyticsTitleInput
						value={state.title}
						onChange={(e) => {
							setState((s) => ({ ...s, title: e.target.value }));
						}}
					/>
				</MapAnalyticsFormSection>
				<MapAnalyticsMetricRadioGroup
					id="leaseExpiration"
					metric={state.metric}
					onChangeMetric={onChangeMetric}
				/>
			</MapAnalyticsForm>
			<MapAnalyticsSettingsCloseButton
				data-qa-id="lease-expiration-settings-close-btn"
				onClick={onClickClose}
			/>
		</>
	);
};
