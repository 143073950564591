import React, { useCallback } from 'react';
import {
	TrendFilters,
	TrendMetricFilter,
	TrendTimespanFilter,
	TrendMovingAverageFilter,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import Dropdown, {
	DropdownItem,
} from '../../MarketRent/MarketRentMap/Dropdown';
import { Styled } from './styled';
import { useMediaQuery } from 'react-responsive';
import { MQB } from '@compstak/ui-kit';

export const metricDropdownData: Array<DropdownItem<TrendMetricFilter>> = [
	{ id: 'effectiveRent', value: 'Effective Rent' },
	{ id: 'startingRent', value: 'Starting Rent' },
	{ id: 'freeMonths', value: 'Free Rent' },
	{ id: 'ti', value: 'Tenant Improvement' },
	{ id: 'leaseTerm', value: 'Lease Term' },
];
const timeSpanDropdownData: Array<DropdownItem<TrendTimespanFilter>> = [
	{ id: 15, value: '15 years' },
	{ id: 10, value: '10 years' },
	{ id: 5, value: '5 years' },
	{ id: 3, value: '3 years' },
	{ id: 1, value: '1 year' },
];
const movingAverageDropdownData: Array<DropdownItem<TrendMovingAverageFilter>> =
	[
		{ id: 3, value: '3 month' },
		{ id: 6, value: '6 month' },
		{ id: 12, value: '12 month' },
	];

type MarketTrendFiltersProps = {
	filters: TrendFilters;
	setMarketTrendFilters: React.Dispatch<React.SetStateAction<TrendFilters>>;
	isBubbleChart: boolean;
	isLoading?: boolean;
};

export const PortfolioTrendFiltersComponent = ({
	filters,
	setMarketTrendFilters,
	isBubbleChart,
	isLoading,
}: MarketTrendFiltersProps) => {
	const onChangeMetric = useCallback(
		(metric: TrendMetricFilter) =>
			setMarketTrendFilters((state) => ({ ...state, metric })),
		[setMarketTrendFilters]
	);
	const onChangeTimeSpan = useCallback(
		(timeSpan: TrendTimespanFilter) =>
			setMarketTrendFilters((state) => ({ ...state, timeSpan })),
		[setMarketTrendFilters]
	);
	const onChangeMovingAverage = useCallback(
		(movAverage: TrendMovingAverageFilter) =>
			setMarketTrendFilters((state) => ({ ...state, movAverage })),
		[setMarketTrendFilters]
	);
	const isMediumScreen = useMediaQuery({
		minWidth: MQB.D_1536,
		maxWidth: MQB.D_1920,
	});

	const isNarrowScreen = useMediaQuery({
		maxWidth: MQB.D_1280,
	});

	if (isLoading) return null;

	return (
		<Styled.Container data-html2canvas-ignore>
			<Dropdown
				width={isNarrowScreen ? 120 : isMediumScreen ? 150 : 200}
				activeItemId={filters.metric}
				items={metricDropdownData}
				onSelect={onChangeMetric}
				title="METRIC"
			/>

			{!isBubbleChart && (
				<>
					<Dropdown
						width={isNarrowScreen ? 100 : 110}
						title="TIME SPAN"
						items={timeSpanDropdownData}
						onSelect={onChangeTimeSpan}
						activeItemId={filters.timeSpan}
					/>
					<Dropdown
						width={isNarrowScreen ? 100 : 110}
						title="MOV. AVG."
						items={movingAverageDropdownData}
						onSelect={onChangeMovingAverage}
						activeItemId={filters.movAverage}
					/>
				</>
			)}
		</Styled.Container>
	);
};
