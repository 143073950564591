import { colors } from '@compstak/ui-kit';
export type DataSetColor = string;

export const DEFAULT_DATASET_COLORS = Object.values(colors.dataSet);

export function getColorForDataSetIndex(
	index: number,
	customColors?: string[]
) {
	const colorChoices = customColors ?? DEFAULT_DATASET_COLORS;
	return colorChoices[index % colorChoices.length];
}
