import {
	MarketRentAnalyticsDashboardPropertyType,
	MarketRentAnalyticsRentType,
	MarketRentAnalyticsSpaceType,
} from '../../../api';

export enum MarketRentAnalyticsViewMode {
	SUBMARKET = 'submarket',
	HEXAGON = 'hexagon',
}

export enum MarketRentAnalyticsPeriod {
	MONTH = 'month',
	YEAR = 'year',
}

export enum MarketRentAnalyticsLegendVariant {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
}

export type MarketRentAnalyticsState = {
	title: string;
	rentType: MarketRentAnalyticsRentType;
	period: MarketRentAnalyticsPeriod;
	viewMode: MarketRentAnalyticsViewMode;
	spaceType: MarketRentAnalyticsSpaceType;
	propertyType: MarketRentAnalyticsDashboardPropertyType;
	menuView: 'read' | 'settings';
};
