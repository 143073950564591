import { CopyPortfolioParams, Portfolio, useCopyPortfolioMutation } from 'api';
import { routes, useNavigate } from 'router';
import { PortfolioDialog } from '../Components';

type Props = {
	portfolio: Portfolio;
};

export const CopyPortfolioDialog = ({ portfolio }: Props) => {
	const { mutateAsync: copyPortfolio, isLoading } = useCopyPortfolioMutation();
	const navigate = useNavigate();

	return (
		<PortfolioDialog
			title="Duplicate Portfolio"
			isLoadingMutation={isLoading}
			onSubmit={async (values) => {
				const copyPortfolioParams: CopyPortfolioParams = {
					portfolioId: portfolio.id,
					name: values.name,
					description:
						values.description.length > 0 ? values.description : undefined,
				};
				await copyPortfolio(copyPortfolioParams);

				navigate(routes.portfolios.path);
			}}
			initialFormState={{
				name: `${portfolio.name} copy`,
				description: portfolio.description ?? '',
			}}
		/>
	);
};
