import { useState } from 'react';
import { Pending, Button } from '@compstak/ui-kit';
// @ts-expect-error TS2307: Cannot find module '../../../S...
import { UseAllPageData } from '../../../SalesDetailsV2/hooks';
import { SuggestUpdateForm } from 'Components/SuggestUpdate';
import styled from 'styled-components';

type Props = {
	salesCompMainInfoResponse: UseAllPageData['salesCompMainInfoResponse'];
};

export const SourceCountFooter = ({ salesCompMainInfoResponse }: Props) => {
	const [formIsOpen, setFormIsOpen] = useState(false);

	const { data } = salesCompMainInfoResponse;

	if (!data) return <StyledPending />;

	const { versions } = data;

	const sourceCountText =
		versions === 1
			? 'This sale data was received from one source'
			: `This sale data was received from ${versions} different sources`;

	return (
		<StyledSourceCountContainer>
			{formIsOpen ? (
				<FormContainer>
					<SuggestUpdateForm
						formIsOpen={formIsOpen}
						setFormIsOpen={setFormIsOpen}
						selectedComps={[data]}
						compType="sale"
						market={data.market}
					/>
				</FormContainer>
			) : (
				<>
					<SourceCount>{versions}</SourceCount>
					<SourceCountText>{sourceCountText}</SourceCountText>
					<SuggestUpdateButton
						variant={'secondary2'}
						onClick={() => {
							setFormIsOpen(true);
						}}
					>
						Suggest an update
					</SuggestUpdateButton>
				</>
			)}
		</StyledSourceCountContainer>
	);
};

const FormContainer = styled.div`
	width: 100%;
`;

const StyledPending = styled(Pending)`
	height: 64px;
`;

const StyledSourceCountContainer = styled.div`
	display: flex;
	padding: 16px 40px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	align-items: center;
	color: ${({ theme }) => theme.colors.gray.n100};
`;

const SourceCount = styled.div`
	width: 32px;
	height: 32px;
	color: ${({ theme }) => theme.colors.white.white};
	background-color: ${({ theme }) => theme.colors.neutral.n100};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SourceCountText = styled.span`
	margin-left: 8px;
`;

const SuggestUpdateButton = styled(Button)`
	width: 250px;
	margin-left: auto;
	font-size: 11px;
	font-weight: 400;
	&:not(:disabled) {
		&:hover {
			background-color: ${({ theme }) => theme.colors.blue.blue500};
			color: ${({ theme }) => theme.colors.white.white};
		}
	}
`;
