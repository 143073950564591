import { TopTenantIndustry } from 'api';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

type Props = {
	data: TopTenantIndustry[] | undefined;
};

export const useLegendData = ({ data }: Props) => {
	return useMemo(() => {
		if (!data) return [];
		return sortBy(data, 'sqft').reverse();
	}, [data]);
};
