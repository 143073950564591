import { useTenantIndustryState } from 'maps';
import { getMapAnalyticsMetricText } from '../getMapAnalyticsMetricText';
import { MapAnalyticsInfoDescription } from '../UI';

export const TenantIndustryAnalyticsDescription = () => {
	const [state] = useTenantIndustryState();

	return (
		<MapAnalyticsInfoDescription>
			Radius indicates total {getMapAnalyticsMetricText(state.metric)} of
			prevalent industry type of a property.
		</MapAnalyticsInfoDescription>
	);
};
