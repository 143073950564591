import { AppHeader } from 'Components/AppHeader';
import CookiesPolicyNotification from 'Components/HeaderNotification/CookiesPolicy';
import { useAppConfig, useUser } from 'Pages/Login/reducers';
import { Outlet } from 'react-router';
import '../styles/layouts.nomodule.less';
import { useAppSelector } from 'util/useAppSelector';
import { TrialExpiredHeader } from 'Pages/TrialExpired/TrialExpiredHeader';
import { APP_WRAPPER_ID } from 'ui/UI';

export const LayoutWithHeader = () => {
	const appConfig = useAppConfig();
	const authState = useAppSelector((state) => state.authState);
	const user = useUser();

	const appClassName = appConfig.isEnterprise ? 'enterprise' : 'exchange';
	const safariClass = 'safari' in window && window.safari ? 'safari' : '';

	return (
		<div
			id={APP_WRAPPER_ID}
			className={`${appClassName} ${safariClass} application_wrapper`}
		>
			<CookiesPolicyNotification />
			<header className="application_header">
				{authState.trialExpired || !user ? (
					<TrialExpiredHeader />
				) : (
					<AppHeader />
				)}
			</header>
			<div className={'application_content'}>
				<Outlet />
			</div>
		</div>
	);
};
