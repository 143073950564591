import { generateKey } from './randomKeyGenerator';

export function generateTotpKey(): string {
	// https://en.wikipedia.org/wiki/Time-based_one-time_password
	//
	// RFC-4648 is required for google authenticator.
	// https://github.com/google/google-authenticator-android/issues/83
	const RFC_4648_KEYS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
	return generateKey(32, { characters: RFC_4648_KEYS });
}
export function isDigitsOnly(str: string): boolean {
	// https://stackoverflow.com/questions/1779013/check-if-string-contains-only-digits
	return /^\d+$/.test(str);
}

export function isValidTotpCodeInputValue(code: string): boolean {
	if (code === '') return true;
	return isDigitsOnly(code) && code.length <= 6;
}

export function isValidTotpCode(code: string): boolean {
	return isDigitsOnly(code) && code.length === 6;
}
