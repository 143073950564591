import React, { memo, useCallback, useMemo } from 'react';
import { NOIDTO } from 'api';
import { NOI } from './NOI';
import { LinesChartProps, Period } from '@compstak/ui-kit';
import { MufaSubmarketChartProps } from 'Components/MufaSubmarket/MufaSubmarketSection';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';
import { FiltersNames, MufaChartsNames } from 'types/propertyTrendCharts';

type Props = MufaSubmarketChartProps &
	Pick<ReturnType<typeof useMufaTrendsData>['data'], 'submarketNOI'>;

export const NOIContainer = memo<Props>(
	({ address, filters, handleFilterChange, submarketNOI }) => {
		const period = filters[MufaChartsNames.SubmarketNOI][FiltersNames.PERIOD];
		const { data, isSuccess, isLoading, isError, error } = submarketNOI;

		const handlePeriodChange = useCallback<(args: Period) => void>(
			(selectedPeriod) =>
				handleFilterChange(
					MufaChartsNames.SubmarketNOI,
					FiltersNames.PERIOD,
					selectedPeriod
				),
			[handleFilterChange]
		);
		const chartData = useMemo(() => adaptData(data), [data]);

		return (
			<NOI
				data={chartData}
				// @ts-expect-error TS2322: Type 'SuggestedSpan | undefine...
				suggestedSpan={data?.suggestedSpan}
				isSuccess={isSuccess}
				isLoading={isLoading}
				isError={isError}
				error={error}
				period={period}
				onPeriodChange={handlePeriodChange}
				metric={defaultMetric}
				address={address}
			/>
		);
	}
);

NOIContainer.displayName = 'NOIContainer';

export const defaultMetric = 'perUnit';

export const adaptData = (data: NOIDTO | void): LinesChartProps['data'] => {
	if (!data?.trendLine) {
		return [];
	}

	return data?.trendLine.map((item) => ({
		name: item.date,
		NOI: item.value,
	}));
};
