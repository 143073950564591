import {
	Tab as UIKitTab,
	TabContent as UIKitTabContent,
	Tabs as UIKitTabs,
	TabsList as UIKitTabsList,
} from '@compstak/ui-kit';
import { PromotionsTabContentLoader } from 'Pages/Promotions/promotionsTab';
import {
	PromoPageOutline,
	PROMO_TABS_MAX_WIDTH,
} from 'Pages/Promotions/promotionUtilComponents';
import { RewardsPage } from 'Pages/Rewards/RewardsPage';
import { CreditsLedger } from 'Pages/Settings/Components/Credits';
import { Link } from 'router';
import styled, { css } from 'styled-components';
import { useUserCanSeePromotions } from '../../api/promotions/promotionQueries';
import { RouteComponentProps } from 'router/migration/types';

enum TAB {
	PROMOS = 'promotions',
	CREDITS = 'credits',
	REWARDS = 'rewards',
}

const TAB_QUERY_PARAM = 'active-tab';

type Empty = Record<string, never>;
type Props = RouteComponentProps<Empty, { [TAB_QUERY_PARAM]: TAB }>;

export function PromotionsCreditsRewardsPage(props: Props) {
	const activeTab = (() => {
		const pageName = props.location?.pathname?.replace('/', '') as TAB;
		if (pageName && Object.values(TAB).includes(pageName)) {
			return pageName;
		}
		return TAB.PROMOS;
	})();

	const canSeePromos = useUserCanSeePromotions();

	return (
		<PromoPageOutline>
			<Tabs value={activeTab}>
				<TabsList>
					{canSeePromos && <Tab value={TAB.PROMOS}>ACTIVE PROMOTIONS</Tab>}
					<Tab value={TAB.CREDITS}>MY CREDITS</Tab>
					<Tab value={TAB.REWARDS}>MY REWARDS</Tab>
				</TabsList>
				<TabContent value={TAB.PROMOS}>
					<TabTitle>Active Promotions</TabTitle>
					<TabSummary>
						View active promotions where you can get bonuses on your comp
						submissions.
						<Link to="/upload">Upload your files anonymously and securely</Link>
						.
					</TabSummary>
					<PromotionsTabContentLoader />
				</TabContent>
				<TabContent value={TAB.CREDITS}>
					<TabTitle>Credits Earned</TabTitle>
					<TabSummary>
						See a history of the credits you’ve earned based on your comp
						submissions. If you have additional comps you’d like to submit,
						<Link to="/upload">upload your files anonymously and securely</Link>
						.
					</TabSummary>
					<CreditsLedger />
				</TabContent>
				<TabContent value={TAB.REWARDS}>
					<TabTitle>My Rewards</TabTitle>
					<TabSummary>
						Redeem your gift card balance.
						<Link to="/upload">Upload your files anonymously and securely</Link>
						.
					</TabSummary>
					<RewardsPage />
				</TabContent>
			</Tabs>
		</PromoPageOutline>
	);
}

const flexColumnCss = css`
	display: flex;
	flex-direction: column;
`;

const Tabs = styled(UIKitTabs)`
	${flexColumnCss}
	flex-grow:1;
	padding: 16px 80px 0px;
	width: 100%;
	max-width: ${PROMO_TABS_MAX_WIDTH}px;
`;

const TabsList = styled(UIKitTabsList)`
	margin-bottom: 16px;
`;

function Tab({ children, value }: { children: string; value: TAB }) {
	return (
		<TabSC value={value}>
			<Link to={`/${value}`}>{children}</Link>
		</TabSC>
	);
}

const TabSC = styled(UIKitTab)`
	padding: 0;
	&[data-state='active'] {
		background-color: hsl(226, 15%, 96%);
	}

	& a {
		display: block;
		color: inherit;
		transition: none;
		padding: 14px 16px;
	}
`;

const TabContent = styled(UIKitTabContent)`
	margin-top: 1px;
	&[data-state='active'] {
		${flexColumnCss}
		flex-grow:1;
	}
`;

const TabTitle = styled.h2`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-family: Gotham;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
	letter-spacing: 0.3px;
`;

const TabSummary = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-family: Gotham;
	font-size: 16px;
	font-style: normal;
	font-weight: 325;
	line-height: 24px;
	margin: 8px 0 16px;

	> a {
		margin-left: 4px;
	}
`;
