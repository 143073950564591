import { MarketPolygonFeature } from 'api/allMarketsPolygons/useAllMarketsPolygons';
import { useMarkets } from 'hooks/useMarkets';
import { useMarketsBuffer } from 'hooks/useMarketsBuffer';
import { useCallback, useMemo } from 'react';

export const useMarketFeatures = (marketFeatures?: MarketPolygonFeature[]) => {
	const { marketsBuffer, setMarketsBuffer } = useMarketsBuffer();
	const markets = useMarkets();

	const selectedMarketFeatures = useMemo(
		() =>
			(marketFeatures ?? []).filter(({ properties: { id } }) =>
				marketsBuffer.some(({ id: marketBufferId }) => id === marketBufferId)
			),
		[marketsBuffer, marketFeatures]
	);

	const onMarketsChange = useCallback(
		(newMarketFeatures: MarketPolygonFeature[]) =>
			setMarketsBuffer(
				newMarketFeatures.map(({ properties: { id } }) => markets[id])
			),
		[setMarketsBuffer, markets]
	);

	return {
		selectedMarketFeatures,
		onMarketsChange,
	};
};
