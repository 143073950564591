import { SPACE_TYPE_ID_TO_NAME } from 'api';
import { useMemo, useState } from 'react';
import { LeaseComp } from 'types';
import { useSelectionState } from 'utils';
import { useExportPdfContext } from '../ExportPdfProvider';
import { DEFAULT_NUM_OF_LEASES_SELECTED } from '../constants';
import { getLeasesByStatus } from '../getLeasesByStatus';
import { useEditableRows } from '../useEditableRows';
import { ExportPdfData } from '../useExportPdfData';
import { useLeasesPdfColumns } from '../useLeasesPdfColumns';

type Props = {
	data: ExportPdfData;
};

export const useEditableLeasesInCompsetState = ({ data }: Props) => {
	const { leasesInCompsetOptionsState } = useExportPdfContext();
	const { leaseStatus, leaseSpaceTypes } = leasesInCompsetOptionsState;
	const leasesColumns = useLeasesPdfColumns({ property: data.property });

	const [editableLeasesInCompsetColumns, setEditableLeasesInCompsetColumns] =
		useState(leasesColumns);

	const filteredLeases = useMemo(() => {
		return getLeasesByStatus(leaseStatus, data.leasesInCompset).filter(
			(l) => leaseSpaceTypes[l.spaceType ?? SPACE_TYPE_ID_TO_NAME['13']]
		);
	}, [leaseStatus, data.leasesInCompset, leaseSpaceTypes]);

	const [editableLeasesInCompsetRows, setEditableLeasesInCompsetRows] =
		useEditableRows({
			rows: filteredLeases as LeaseComp[],
			columns: editableLeasesInCompsetColumns,
			getId: (row) => row.id,
			getPropertyId: (row) => row.propertyId,
		});

	const leasesInCompsetSelection = useSelectionState(
		editableLeasesInCompsetRows,
		() =>
			editableLeasesInCompsetRows
				.slice(0, DEFAULT_NUM_OF_LEASES_SELECTED)
				.map((r) => r.id)
	);

	return {
		editableLeasesInCompsetColumns,
		setEditableLeasesInCompsetColumns,
		editableLeasesInCompsetRows,
		setEditableLeasesInCompsetRows,
		leasesInCompsetSelection,
	};
};
