import { FiltersObject } from 'models/filters/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CompType } from 'types';
import { createSimpleProvider } from 'utils/createSimpleProvider';
import { FiltersType } from '../../../reducers/filtersReducer';
import { Category } from 'Pages/Search/Sidebar/Components/FilterCategories';
import { useSearch } from 'Pages/Search/searchReducer';
import { usePrevious } from 'util/hooks';

type Props = {
	compType: CompType;
	filters: FiltersObject;
	onFilterChange: (change: Partial<FiltersObject>) => void;
	context?: FiltersType | 'analytics';
};

type State = {
	compType: CompType;
	filters: FiltersObject;
	onFilterChange: (change: Partial<FiltersObject>) => void;
	context: FiltersType | 'analytics';
	searchTerm: string | undefined;
	setSearchTerm: (value: string | undefined) => void;
	resetFiltersTrigger: boolean | null;
	categoryVisibility: CategoryVisibility | null;
	setCategoryVisible: (category: Category, isVisible: boolean) => void;
};

type CategoryVisibility = Record<CompType, Record<Category, boolean>>;

type ResetFiltersTrigger = Record<CompType, boolean | null>;

export const {
	Provider: FilterFieldContextProvider,
	useProviderContext: useFilterFieldContext,
} = createSimpleProvider({
	useValue: ({
		compType,
		filters,
		onFilterChange,
		context = 'main',
	}: Props): State => {
		const prevCompType = usePrevious(compType);
		const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
		const [resetFiltersTrigger, setResetFiltersTrigger] =
			useState<ResetFiltersTrigger>({
				lease: null,
				sale: null,
				property: null,
			});

		const [categoryVisibility, setCategoryVisibility] =
			useState<CategoryVisibility | null>(null);

		const { resetFiltersTrigger: _resetFiltersTrigger } = useSearch();

		useEffect(() => {
			if (prevCompType !== compType) {
				setResetFiltersTrigger({ lease: null, sale: null, property: null });
			} else {
				setResetFiltersTrigger((prevResetFiltersTrigger) => ({
					...prevResetFiltersTrigger,
					[compType]: !prevResetFiltersTrigger[compType],
				}));
			}
		}, [_resetFiltersTrigger]);

		const setCategoryVisible = useCallback(
			(category: Category, isVisible: boolean) => {
				setCategoryVisibility((prev) => {
					if (!prev) {
						return {
							[compType]: { [category]: isVisible },
						} as CategoryVisibility;
					}

					const newVisibility = { ...prev };

					if (!newVisibility[compType]) {
						newVisibility[compType] = {} as Record<Category, boolean>;
					}

					newVisibility[compType][category] = isVisible;

					return newVisibility;
				});
			},
			[compType]
		);

		return useMemo(
			() => ({
				compType,
				filters,
				onFilterChange,
				context,
				searchTerm,
				setSearchTerm,
				resetFiltersTrigger: resetFiltersTrigger[compType],
				categoryVisibility,
				setCategoryVisible,
			}),
			[
				compType,
				filters,
				onFilterChange,
				context,
				searchTerm,
				resetFiltersTrigger,
				categoryVisibility,
				setCategoryVisible,
			]
		);
	},
});
