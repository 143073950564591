export function isManualCompFormValid<
	K extends string,
	T extends Record<K, unknown>,
>({
	submission,
	requiredKeys,
	oneFromListsRequired,
	dateFields,
}: {
	submission: T;
	requiredKeys: ReadonlyArray<K>;
	oneFromListsRequired: ReadonlyArray<ReadonlyArray<K>>;
	dateFields: ReadonlyArray<K>;
}): boolean {
	try {
		if (!submission) {
			return false;
		}
		const fieldIsValid = (key: K) => {
			const val = submission[key] as string;
			if (dateFields.includes(key)) {
				const [m, d, y] = val.split('/');
				return [m, d, y].every((v) => v?.length >= 1);
			}
			return !!val;
		};
		const requiredFieldsFilled = requiredKeys.every(fieldIsValid);
		if (!requiredFieldsFilled) {
			return false;
		}
		const allOneOfListsCovered = oneFromListsRequired.every((list) =>
			list.some(fieldIsValid)
		);
		if (!allOneOfListsCovered) {
			return false;
		}
		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
}

export function currentYear() {
	return new Date().getFullYear();
}

export function getNumsInRange(options: {
	start: number;
	stop: number;
	asc: boolean;
}) {
	const [start, stop] = [options.start, options.stop].sort((a, b) => a - b);
	const asc = !!options.asc;
	const vals = Array.from({ length: stop - start + 1 }, (_, i) => start + i);
	if (!asc) {
		vals.reverse();
	}
	return vals;
}
