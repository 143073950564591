import { Button, defaultTheme } from '@compstak/ui-kit';
import { Document, View } from '@react-pdf/renderer';
import { ModalCloseButton, ModalCloseIcon } from 'Components/Modals/common/UI';
import { useFilteredTenantIndustries } from 'ExchangeDashboard/TenantIndustryMap';
import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { useExchangeDashboardLargeLandlords } from 'ExchangeDashboard/hooks/largestLandlords/useExchangeDashboardLargeLandlords';
import { useExchangeDashboardLargeTenants } from 'ExchangeDashboard/hooks/largestTenants/useExchangeDashboardLargeTenants';
import { useExchangeDashboardLeasesActivity } from 'ExchangeDashboard/hooks/leasesActivity/useExchangeDashboardLeasesActivity';
import { useExchangeDashboardSalesActivity } from 'ExchangeDashboard/hooks/salesActivity/useExchangeDashboardSalesActivity';
import { useExchangeDashboardStartingRent } from 'ExchangeDashboard/hooks/startingRent/useExchangeDashboardStartingRent';
import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import { useMarketsHaveFeature, useUserQuery } from 'api';
import { PdfPage } from 'pdf/PdfPage';
import { PdfTable } from 'pdf/PdfTable';
import { EditContent } from 'pdf/UI';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled, { ThemeProvider } from 'styled-components';
import { formatComp } from 'util/comp-format/src/format';
import { DashboardEditPage } from './DashboardEditPage';
import { useDashboardExportContext } from './DashboardExportProvider';
import { DynamicEditPages } from './DynamicEditPages';
import { DynamicPdfPages } from './DynamicPdfPages';
import { ExportPdfButton } from './ExportPdfButton';
import { useExportPdfState } from './ExportPdfProvider';
import { EditHeaderPage } from './HeaderPage/EditHeaderPage';
import { HeaderPage } from './HeaderPage/HeaderPage';
import { LargestLandlordsPdfSection } from './LargestLandlords/LargestLandlordsPdfSection';
import { LargestLandlordsSection } from './LargestLandlords/LargestLandlordsSection';
import { LargestTenantsPdfSection } from './LargestTenants/LargestTenantsPdfSection';
import { LargestTenantsSection } from './LargestTenants/LargestTenantsSection';
import { useMarketRentSectionText } from './MarketRentSection/useMarketRentSectionText';
import { PdfSectionBox } from './PdfUI';
import { LeasesTable } from './PreviewComponents/LeasesTable';
import { SalesTable } from './PreviewComponents/SalesTable';
import { getStartingRentDesc } from './StartingRentSection';
import { filterLargeSalesAndRecentTransactionsIfNeeded } from './StepTwo';
import { useTenantIndustryMapDescription } from './TenantIndustryMap/useTenantIndustryMapDescription';
import { PDFPanel } from './UI';
import { PdfSectionProps, TABLE_SECTIONS } from './constants';
import { useLeasesPdfColumns } from './useLeasesPdfColumns';
import { useSalesPdfColumns } from './useSalesPdfColumns';

type Props = {
	closeDialog: () => void;
	onStepBack: () => void;
};

export const StepTwoV2 = ({ closeDialog, onStepBack }: Props) => {
	const {
		exportPdfState,
		marketText,
		submarketText,
		propertyTypesText,
		createdOnText,
		imageUrls,
	} = useExportPdfState();
	const { data: user } = useUserQuery();
	const { selectedComponents } = useDashboardExportContext();

	const { title: marketRentTitle, description: marketRentDescription } =
		useMarketRentSectionText();

	const { data: tenantIndustryDistributionData } =
		useExchangeDashboardTenantIndustryDistribution();

	const { movingAverage } = useExchangeDashboardStartingRent();

	const tenantIndustryMapData = useFilteredTenantIndustries();

	const tenantIndustryMapDescription = useTenantIndustryMapDescription();

	const averagesData = useExchangeDashboardAverages();

	const { data: largestLandlordsData } = useExchangeDashboardLargeLandlords();

	const { data: largestTenantsData } = useExchangeDashboardLargeTenants();

	const { data: recentlySignedLeases } = useExchangeDashboardLeasesActivity({
		activeTab: 'executionDate',
		enabled: selectedComponents.includes('recentlySignedLeases'),
	});

	const { data: largestRecentLeases } = useExchangeDashboardLeasesActivity({
		activeTab: 'transactionSize',
		enabled: selectedComponents.includes('largestRecentLeases'),
	});

	const { data: recentSalesTransactions } = useExchangeDashboardSalesActivity({
		activeTab: 'saleDate',
		enabled: selectedComponents.includes('recentTransactions'),
	});

	const { data: largeSales } = useExchangeDashboardSalesActivity({
		activeTab: 'totalSalePrice',
		enabled: selectedComponents.includes('largeSales'),
	});

	const pdfSectionProps: PdfSectionProps = {
		marketRentHeatMap: {
			title: marketRentTitle,
			description: marketRentDescription,
			url: imageUrls.marketRentHeatMap,
		},
		startingRent: {
			url: imageUrls.startingRent,
			description: getStartingRentDesc(movingAverage),
		},
		leaseExpirationPropertyType: {
			url: imageUrls.leaseExpirationPropertyType,
		},
		leaseExpirationSubmarket: {
			url: imageUrls.leaseExpirationSubmarket,
		},
		tenantIndustryDistribution: {
			url: imageUrls.tenantIndustryDistribution,
			data: tenantIndustryDistributionData,
		},
		tenantIndustryMap: {
			url: imageUrls.tenantIndustryMap,
			data: tenantIndustryMapData,
			description: tenantIndustryMapDescription,
		},
	};

	const [filters] = useFilters('exchange-dashboard');

	const { data: hasSalesAccess } = useMarketsHaveFeature({
		feature: 'salesAccess',
		marketIds: [filters.market.id],
	});

	const tableSections = useMemo(() => {
		return TABLE_SECTIONS.filter((key) => {
			return (
				selectedComponents.includes(key) &&
				filterLargeSalesAndRecentTransactionsIfNeeded(key, hasSalesAccess)
			);
		});
	}, [selectedComponents, hasSalesAccess]);

	const leasesPdfColumns = useLeasesPdfColumns();

	const salesPdfColumns = useSalesPdfColumns();

	const formattedRecentlySignedLeases = useMemo(() => {
		return recentlySignedLeases?.comps.map((l) => formatComp(l, 'lease')) ?? [];
	}, [recentlySignedLeases]);

	const formattedLargestRecentLeases = useMemo(() => {
		return largestRecentLeases?.comps.map((l) => formatComp(l, 'lease')) ?? [];
	}, [largestRecentLeases]);

	const formatedRecentSalesTransactions = useMemo(() => {
		return (
			recentSalesTransactions?.comps.map((l) => formatComp(l, 'sale')) ?? []
		);
	}, [recentSalesTransactions]);

	const formatedLargestRecentSales = useMemo(() => {
		return largeSales?.comps.map((l) => formatComp(l, 'sale')) ?? [];
	}, [largeSales]);

	return (
		<div style={{ textAlign: 'left' }}>
			<Toolbar>
				<Button variant="ghost" onClick={onStepBack} data-qa-id="back">
					Back
				</Button>
				<RightContainer>
					<ExportPdfButton
						pdfDocument={
							<ThemeProvider theme={defaultTheme}>
								<Document>
									<HeaderPage
										exportPdfState={exportPdfState}
										user={user}
										marketText={marketText}
										submarketText={submarketText}
										propertyTypesText={propertyTypesText}
										createdOnText={createdOnText}
										showAverages={selectedComponents.includes('averages')}
										averagesData={averagesData}
									/>
									<DynamicPdfPages
										selectedComponents={selectedComponents}
										user={user}
										exportPdfState={exportPdfState}
										pdfSectionProps={pdfSectionProps}
									/>
									{(selectedComponents.includes('largestLandlords') ||
										selectedComponents.includes('largestTenants')) && (
										<PdfPage
											logo={exportPdfState.logo}
											preparedFor={exportPdfState.preparedFor}
											title={exportPdfState.title}
											user={user}
										>
											{selectedComponents.includes('largestLandlords') && (
												<View style={{ marginBottom: 12 }}>
													<LargestLandlordsPdfSection
														url={imageUrls.largestLandlords}
														largestLandlordsData={largestLandlordsData}
													/>
												</View>
											)}
											{selectedComponents.includes('largestTenants') && (
												<LargestTenantsPdfSection
													url={imageUrls.largestTenants}
													largestTenantsData={largestTenantsData}
												/>
											)}
										</PdfPage>
									)}
									{tableSections.map((key) => {
										return (
											<PdfPage
												key={key}
												logo={exportPdfState.logo}
												preparedFor={exportPdfState.preparedFor}
												title={exportPdfState.title}
												user={user}
											>
												{key === 'recentlySignedLeases' && (
													<PdfSectionBox title="Recently Signed Leases">
														<PdfTable
															rows={
																recentlySignedLeases?.comps.slice(0, 10) ?? []
															}
															columns={leasesPdfColumns}
														/>
													</PdfSectionBox>
												)}
												{key === 'largestRecentLeases' && (
													<PdfSectionBox title="Largest Recent Leases">
														<PdfTable
															rows={
																largestRecentLeases?.comps.slice(0, 10) ?? []
															}
															columns={leasesPdfColumns}
														/>
													</PdfSectionBox>
												)}
												{key === 'recentTransactions' && (
													<PdfSectionBox title="Recent Sales Transactions">
														<PdfTable
															rows={
																recentSalesTransactions?.comps.slice(0, 10) ??
																[]
															}
															columns={salesPdfColumns}
														/>
													</PdfSectionBox>
												)}
												{key === 'largeSales' && (
													<PdfSectionBox title="Largest Recent Sales">
														<PdfTable
															rows={largeSales?.comps.slice(0, 10) ?? []}
															columns={salesPdfColumns}
														/>
													</PdfSectionBox>
												)}
											</PdfPage>
										);
									})}
								</Document>
							</ThemeProvider>
						}
					/>
					<ModalCloseButton onClick={closeDialog} style={{ position: 'unset' }}>
						<ModalCloseIcon />
					</ModalCloseButton>
				</RightContainer>
			</Toolbar>
			<EditContent>
				<EditHeaderPage
					marketText={marketText}
					submarketText={submarketText}
					propertyTypesText={propertyTypesText}
					createdOnText={createdOnText}
				/>
				<DynamicEditPages />
				{(selectedComponents.includes('largestLandlords') ||
					selectedComponents.includes('largestTenants')) && (
					<DashboardEditPage>
						{selectedComponents.includes('largestLandlords') && (
							<div style={{ marginBottom: '1rem' }}>
								<LargestLandlordsSection />
							</div>
						)}
						{selectedComponents.includes('largestTenants') && (
							<LargestTenantsSection />
						)}
					</DashboardEditPage>
				)}
				{tableSections.map((key) => {
					return (
						<DashboardEditPage key={key}>
							{key === 'recentlySignedLeases' && (
								<PDFPanel title="Recently Signed Leases">
									<LeasesTable leaseComps={formattedRecentlySignedLeases} />
								</PDFPanel>
							)}
							{key === 'largestRecentLeases' && (
								<PDFPanel title="Largest Recent Leases">
									<LeasesTable leaseComps={formattedLargestRecentLeases} />
								</PDFPanel>
							)}
							{key === 'recentTransactions' && (
								<PDFPanel title="Recent Sales Transactions">
									<SalesTable salesComps={formatedRecentSalesTransactions} />
								</PDFPanel>
							)}
							{key === 'largeSales' && (
								<PDFPanel title="Largest Recent Sales">
									<SalesTable salesComps={formatedLargestRecentSales} />
								</PDFPanel>
							)}
						</DashboardEditPage>
					);
				})}
			</EditContent>
		</div>
	);
};

const Toolbar = styled.div`
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: ${(p) => p.theme.colors.white.white};
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
`;

const RightContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 0.5rem;
	margin-left: auto;
`;
