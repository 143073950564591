import { SpaceTypeName } from 'api';
import React from 'react';
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryLabel,
	VictoryVoronoiContainer,
	VictoryGroup,
} from 'victory';

type BarData = { x: string; y: number; spaceType: string; color: string };
type ChartData = BarData[][];
const barWidthMap = {
	1: 52,
	2: 36,
	3: 26,
	4: 20,
	5: 16,
	6: 8,
	7: 4,
};
const rightPaddingMap = {
	1: 0,
	2: 0,
	3: 10,
	4: 30,
	5: 35,
	6: 35,
	7: 35,
};
const barLabelMap = {
	1: 13,
	2: 12,
	3: 10,
	4: 9,
	5: 9,
	6: 6,
	7: 6,
};
const domain: { x: [number, number] } = { x: [1, 5] };
export type ChartOnClickHandlerProps = {
	expirationYear: number;
	spaceType: SpaceTypeName;
};
type LeaseExpirationChartProps = {
	data: ChartData;
	xAxisLabels: string[];
	onClick: (clickProps: ChartOnClickHandlerProps) => void;
};

const LeaseExpirationChart = ({
	data,
	xAxisLabels,
	onClick,
}: LeaseExpirationChartProps) => {
	return (
		<div>
			<VictoryChart
				domain={domain}
				style={{ parent: { maxWidth: '100%' } }}
				height={320}
				width={780}
				padding={{
					left: 95,
					top: 30,
					bottom: 42,
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					right: data.length ? rightPaddingMap[data.length] : 25,
				}}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						height={350}
						style={{ margin: '0 auto' }}
					/>
				}
			>
				<VictoryAxis
					axisLabelComponent={
						<VictoryLabel
							dy={12}
							style={{ fontSize: 14, fontFamily: 'Gotham, sans-serif' }}
						/>
					}
					tickLabelComponent={
						<VictoryLabel
							style={{
								fontSize: 12,
								fontFamily: 'Gotham, sans-serif',
								opacity: data.length ? 1 : 0.3,
							}}
						/>
					}
					label="Years"
					tickFormat={xAxisLabels}
					style={{
						axis: { stroke: 'none' },
					}}
				/>
				<VictoryAxis
					axisLabelComponent={
						<VictoryLabel
							dy={-54}
							style={{ fontSize: 14, fontFamily: 'Gotham, sans-serif' }}
						/>
					}
					tickLabelComponent={
						<VictoryLabel
							transform={'translate(-15, 0)'}
							style={{
								fontSize: 12,
								fontFamily: 'Gotham, sans-serif',
								opacity: data.length ? 1 : 0.3,
							}}
						/>
					}
					label="% of lease expirations"
					dependentAxis
					tickFormat={(yAxisValue: number) => `${yAxisValue}%`}
					style={{
						axis: { stroke: 'none' },
					}}
				/>
				{/* @ts-expect-error TS7053: Element implicitly has an 'any... */}
				<VictoryGroup offset={barWidthMap[data.length] + 8}>
					{data.map((spaceTypeBarData, i) => (
						<VictoryBar
							name={`pa-v2-lease-expiration-group-bar-chart-${i}`}
							key={i}
							data={spaceTypeBarData}
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							barWidth={barWidthMap[data.length]}
							labels={({ datum: { y } }) => `${y}%`}
							style={{
								data: {
									cursor: 'pointer',
									fill: spaceTypeBarData[0].color,
								},
								labels: {
									fontFamily: 'Gotham, sans-serif',
									// @ts-expect-error TS7053: Element implicitly has an 'any...
									fontSize: barLabelMap[data.length],
								},
							}}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (_, clickProps) => {
											const {
												datum: { xName: expirationYear, spaceType },
											} = clickProps;
											onClick({
												expirationYear,
												spaceType,
											});
										},
									},
								},
							]}
						/>
					))}
				</VictoryGroup>
				{!data.length && (
					<>
						<VictoryLabel
							x={363}
							y={105}
							text="No Data Available"
							style={{
								fontSize: 16,
								fontFamily: 'Gotham, sans-serif',
								fontWeight: 500,
							}}
						/>
						<VictoryLabel
							x={348}
							y={140}
							text="Try adjusting your filters."
							style={{ fontSize: 14, fontFamily: 'Gotham, sans-serif' }}
						/>
					</>
				)}
			</VictoryChart>
		</div>
	);
};

export default LeaseExpirationChart;
