import { MAP_CONTROLS } from 'actions/search';
import { MapFilterBase } from './MapFilterBase';
import IconSubmarketsMapFilter from 'ui/svg_icons/submarkets.svg';
import { SubmarketFilter } from '../SubmarketFilter/SubmarketFilter';

export const SubmarketsMapFilter = () => {
	return (
		<MapFilterBase
			Icon={IconSubmarketsMapFilter}
			key={MAP_CONTROLS.SUBMARKETS}
			label="Submarkets"
			mapControl={MAP_CONTROLS.SUBMARKETS}
		>
			{(props) => (
				<SubmarketFilter
					filters={props.filters}
					isActive={props.isActive}
					onFilterChange={props.onFilterChange}
					setIsLoading={props.setIsLoading}
				/>
			)}
		</MapFilterBase>
	);
};
