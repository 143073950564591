import { API } from '@compstak/common';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useSharePortfolioMutation = (
	options?: UseMutationOptions<void, unknown, SharePortfolioPayload>
) => {
	return useMutation({
		mutationFn: async (params: SharePortfolioPayload) => {
			const response = await API.post<void, SharePortfolioPayload>(
				`/pa/v2/api/portfolios/share`,
				params
			);
			return response.data;
		},
		...options,
	});
};

type SharePortfolioPayload = {
	portfolioId: number;
	note?: string;
	recipientEmails: string[];
};
