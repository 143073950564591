import { nullValueText } from '@compstak/common';
import { TooltipV2, InfoCircle } from '@compstak/ui-kit';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';
import styled from 'styled-components';

type KeyInfoItemProps = {
	title: string;
	value?: any;
	className?: string;
	tooltip?: string;
};
export const KeyInfoItem = ({
	title,
	value,
	className,
	tooltip,
}: KeyInfoItemProps) => {
	return (
		<KeyInfoItemContainer className={className}>
			<Title>
				{title}
				{tooltip && (
					<TooltipV2 content={tooltip} side="bottom">
						<span>
							<IconQuestionMark />
						</span>
					</TooltipV2>
				)}
			</Title>
			<Value>
				{!value || value === 0 || value === '0' ? nullValueText : value}
			</Value>
		</KeyInfoItemContainer>
	);
};

export const KeyInfoItemContainer = styled.div`
	padding: 0;
`;

export const Title = styled.p`
	display: flex;
	gap: 4px;
	align-items: flex-start;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	color: ${({ theme }) => theme.colors.neutral.n100};
	text-transform: capitalize;
	margin-bottom: 5px;
`;

export const Value = styled.span<{ large?: boolean }>`
	font-size: 18px;
	font-weight: 400;
	line-height: 22.5px;
	color: ${({ theme }) => theme.colors.gray.gray700};
`;

export const KeyInfoItems = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	margin-bottom: 24px;
`;

export const StyledInfoCircle = styled(InfoCircle)`
	width: 16px;
	height: 16px;
	fill: ${({ theme }) => theme.colors.neutral.n100};
`;
