import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as menuActions from 'Singletons/Menu/actions';
import * as modalActions from 'Singletons/Modal/actions';

import ChartRepoLayout from './Layout';
import withProjects from '../../withProjects';
import withChartBuilder from '../../../Builder/withChartBuilder';
import { AppState } from 'reducers/root';
import { push } from 'router';

const mapStoreToProps = (store: AppState) => {
	return {
		user: store.user,
		referenceData: store.referenceData,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"MENU_SHOW"' is not assignable to type 'Acti... Remove this comment to see the full error message
		menuActions: bindActionCreators(menuActions, dispatch),
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"MODAL_SHOW"' is not assignable to type 'Act... Remove this comment to see the full error message
		modalActions: bindActionCreators(modalActions, dispatch),
		// @ts-expect-error TS7006: Parameter 'url' implicitly has...
		redirect: (url) => push(url),
	};
};

export default withProjects(
	withChartBuilder(
		connect(mapStoreToProps, mapDispatchToProps)(ChartRepoLayout)
	)
);
