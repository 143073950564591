import { Portfolio } from 'api';
import styled from 'styled-components';
import {
	DashboardSettingsForm,
	DashboardSettingsMenuContainer,
	FormControl,
	MarketRentAnalyticsSharedFormControls,
} from '../../../maps';
import { useMarketRentState } from '../MarketRentProvider';
import Dropdown from './Dropdown';
import { useMarketOptions } from './useMarketOptions';
import { usePropertyTypeOptions } from './usePropertyTypeOptions';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';

type Props = {
	portfolio: Portfolio;
};

export const MarketRentMapSettingsMenu = ({ portfolio }: Props) => {
	const [marketRentState, setMarketRentState] = useMarketRentState();
	const marketOptions = useMarketOptions({ portfolio });
	const propertyTypeOptions = usePropertyTypeOptions({ portfolio });

	return (
		<DashboardSettingsMenuContainer data-html2canvas-ignore>
			<DashboardSettingsForm>
				<DrodownWrapper>
					<Dropdown
						title="MARKETS"
						items={marketOptions}
						activeItemId={marketRentState.selectedMarketId}
						onSelect={(id) => {
							setMarketRentState((prev) => ({ ...prev, selectedMarketId: id }));
						}}
					/>
				</DrodownWrapper>
				<DrodownWrapper>
					<Dropdown
						title="PROPERTY TYPE"
						items={propertyTypeOptions}
						activeItemId={marketRentState.selectedPropertyTypeId}
						onSelect={(id) => {
							setMarketRentState((prev) => ({
								...prev,
								selectedPropertyTypeId: id,
							}));
						}}
					/>
				</DrodownWrapper>
				<MarketRentAnalyticsSharedFormControls />
				<FormControl>
					<CalculationOverviewLink context="marketRent" />
				</FormControl>
			</DashboardSettingsForm>
		</DashboardSettingsMenuContainer>
	);
};

export const DrodownWrapper = styled.div`
	margin-bottom: 5px;
`;
