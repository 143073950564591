import { usePropertyLeasesQuery } from 'api';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { routes } from 'router';

export const useShouldShowMarketStats = () => {
	const params = routes.propertyById.useParams();
	const flags = useFeatureFlags();

	const { data: leaseData, isLoading: isLoadingLeases } =
		usePropertyLeasesQuery({
			propertyId: params.propertyId,
		});

	return {
		data: !leaseData
			? undefined
			: flags.PropertyPagePhase3 && leaseData.comps.length > 0,
		isLoading: isLoadingLeases,
	};
};
