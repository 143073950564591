import { DataSet } from 'Pages/Analytics/analytics';
import styled from 'styled-components';
import { ChartLegendBubble, ChartLegendLine } from './Components';

type LegendObject = {
	dataSet: DataSet;
	color: string;
	isBubble: boolean;
};

type Props = {
	legendObjects: LegendObject[];
};

export const Legend = ({ legendObjects }: Props) => {
	return (
		<Root>
			{legendObjects.map((legendObject) => {
				return (
					<Item key={legendObject.dataSet.id}>
						{legendObject.isBubble ? (
							<ChartLegendBubble color={legendObject.color} />
						) : (
							<ChartLegendLine color={legendObject.color} />
						)}

						<div>{legendObject.dataSet.name}</div>
					</Item>
				);
			})}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 30px 0;
	gap: 20px;
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	font-size: 10px;
	line-height: 10px;
	font-weight: 300;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
`;
