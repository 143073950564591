import styles from 'Pages/StackingPlans/stacking.less';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { ViewPropertyPageLink } from './ViewPropertyPageLink';

export default class BuildingStats extends React.Component {
	constructor(props: any) {
		super(props);
	}

	getFloors() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		return this.props.stackingPlan.floors.length
			? // @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			  this.props.stackingPlan.floors.length
			: '?';
	}

	getBldgName() {
		return (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'property' does not exist on type 'Readon... Remove this comment to see the full error message
			this.props.property.buildingName || this.props.property.buildingAddress
		);
	}

	getBldgSize() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		if (this.props.stackingPlan.floors) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			return this.props.stackingPlan.floors.reduce(
				// @ts-expect-error TS7006: Parameter 'total' implicitly h...
				(total, floor) => total + floor.size,
				0
			);
		}
		return '?';
	}

	getAvailSpace() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		if (this.props.stackingPlan.floors) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			return this.props.stackingPlan.spaces.reduce((total, space) => {
				if (space.placed) {
					return total - space.size;
				}
				return total;
			}, this.getBldgSize());
		}
		return '?';
	}

	getAddress() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'property' does not exist on type 'Readon... Remove this comment to see the full error message
		const address = this.props.property.address;
		return address.city + ', ' + address.state + ' ' + address.zip;
	}

	render() {
		return (
			<div className={styles.header}>
				<div className={styles.addressText}>
					<div className={styles.buildingNumber}>{this.getBldgName()}</div>
					<div className={styles.cityState}>{this.getAddress()}</div>
				</div>
				<div className={styles.stats}>
					<TransitionGroup>
						<CSSTransition classNames="stats-transition" timeout={300}>
							<div className={styles.buildingStats}>
								<ul>
									<li>
										<div className={styles.statsLabel}>Floors</div>
										<div className={styles.statsValue}>
											{abbreviateNumber(this.getFloors())}
										</div>
									</li>
									<li>
										<div className={styles.statsLabel}>Size (Sq Ft)</div>
										<div className={styles.statsValue}>
											{abbreviateNumber(this.getBldgSize())}
										</div>
									</li>
									<li>
										<div className={styles.statsLabel}>Avail. Space</div>
										<div className={styles.statsValue}>
											{abbreviateNumber(this.getAvailSpace())}
										</div>
									</li>
								</ul>
							</div>
						</CSSTransition>
					</TransitionGroup>
				</div>
				{/* @ts-expect-error TS2339: Property 'property' does not e... */}
				<ViewPropertyPageLink property={this.props.property} />
			</div>
		);
	}
}
