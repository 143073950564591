import { typography } from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useUserIsSalesTrader } from 'hooks/userHooks';
import styled from 'styled-components';
import IconAddNotes from 'ui/svg_icons/add-notes.svg';
import IconAnonymous from 'ui/svg_icons/admin-panel-settings.svg';
import IconAsterisk from 'ui/svg_icons/asterisk.svg';
import IconDocumentScanner from 'ui/svg_icons/document-scanner.svg';
import { useAppSelector } from 'util/useAppSelector';
import { CreditAwardCard } from './Components/CreditAwardCard';
import { DragAndDropComp } from './Components/DragAndDropComp';
import { RequiredCompFieldsSection } from './Components/RequiredCompFields';
import { WrapFlex } from './Components/Ui';
import UploadFormsContainer from './Components/UploadFormsContainer';
import { UploadPageRouteProps } from './types';

export function UploadPage({ params }: UploadPageRouteProps) {
	const salesTrader = useUserIsSalesTrader();
	const uploadFormVisible = useAppSelector(
		(store) => store.uploads.uploadFormVisible
	);
	if (uploadFormVisible) {
		return <UploadFormsContainer params={params} />;
	}
	return (
		<Page>
			<GreenHeader>
				EARN CREDITS FOR YOUR {salesTrader ? '' : 'LEASE AND '}SALE COMPS
			</GreenHeader>
			<WrapFlex justifyContent="center" gap="64px" marginBottom="80px">
				<section>
					<CompInfoBadges />
					<CreditAwardCards />
					<CreditAwardNotes />
				</section>
				<DragAndDropComp />
			</WrapFlex>
			<RequiredCompFieldsSection />
			<Footer>
				<div>Still have questions? Contact us:</div>
				<div>Email: credits@compstak.com</div>
				<div>Call: 612-729-9183</div>
			</Footer>
		</Page>
	);
}

function CompInfoBadges() {
	return (
		<WrapFlex justifyContent="space-around" gap="16px" marginBottom="24px">
			<Info1>
				<div>
					<IconAnonymous />
				</div>
				<div>
					<b>ANONYMOUS</b>
					<div>
						Comp uploads are anonymous and secure. We respect your privacy.
					</div>
				</div>
			</Info1>
			<Info1>
				<div>
					<IconDocumentScanner />
				</div>
				<div>
					<b>ANY FILE ACCEPTABLE</b>
					<div>
						Send us files in ANY format (e.g. Excel, JPEG, PNG, PDF, etc).
					</div>
				</div>
			</Info1>
		</WrapFlex>
	);
}

function CreditAwardCards() {
	const { salesCompsCreditImprovements24Q2 } = useFeatureFlags();
	const salesTrader = useUserIsSalesTrader();
	const defaultBonusDescription = useDefaultBonusSectionDescription();
	const defaultBonus = {
		// Bonuses use to be different per card.
		// They changed a lot and they might change in the future too,
		// so I'm not hardcoding them yet in the CreditAwardCard implementation.
		bonusAward: 25,
		description: defaultBonusDescription,
	};
	return (
		<WrapFlex justifyContent="center" gap="24px" marginBottom="16px">
			{(salesCompsCreditImprovements24Q2 || !salesTrader) && (
				<CreditAwardCard
					awardCredits={50}
					requirements={
						<ul>
							<AllRequiredFieldsLi />
							<li>
								has a transaction date <Bi>within the past 30 days</Bi>
							</li>
						</ul>
					}
					bonus={
						salesCompsCreditImprovements24Q2
							? defaultBonus
							: {
									bonusAward: 10,
									description: ['3 lease comp premium fields*'],
							  }
					}
					leaseOnly
				/>
			)}
			<CreditAwardCard
				awardCredits={25}
				requirements={
					<ul>
						<AllRequiredFieldsLi />
						<li>
							has a transaction date <Bi>within</Bi> the past 12 months
						</li>
					</ul>
				}
				bonus={
					salesCompsCreditImprovements24Q2
						? defaultBonus
						: {
								bonusAward: 5,
								description: salesTrader
									? ['2 sale comp premium fields*']
									: [
											'3 lease comp premium fields*, or 2 sale comp premium fields*',
									  ],
						  }
				}
			/>
			<CreditAwardCard
				awardCredits={5}
				requirements={
					<ul>
						<AllRequiredFieldsLi />
						<li>
							has a transaction date <b>older</b> than 12 months
						</li>
					</ul>
				}
				bonus={salesCompsCreditImprovements24Q2 ? defaultBonus : undefined}
			/>
		</WrapFlex>
	);
}

function useDefaultBonusSectionDescription(): string[] {
	const salesTrader = useUserIsSalesTrader();
	return [
		...(!salesTrader ? ['3 lease comp premium fields*'] : []),
		'2 sale comp premium fields*',
	];
}

function CreditAwardNotes() {
	return (
		<ul>
			<LiWithSvg>
				<IconAddNotes /> Receive 1 credit for each comp that is already in our
				database.
			</LiWithSvg>
			<LiWithSvg>
				<IconAddNotes /> Unlock 1 comp for your first submission everyday.
			</LiWithSvg>
			<LiWithSvg>
				<IconAsterisk /> See details of required fields and premium fields
				below.
			</LiWithSvg>
		</ul>
	);
}

function AllRequiredFieldsLi() {
	return <li>contains all required fields*</li>;
}

const Page = styled.div`
	position: absolute;
	padding: 0 80px 80px;
	inset: 0;
	background-color: white;
	font-family: ${typography.fontFamily.gotham};
	overflow-y: auto;
`;
Page.displayName = 'Page';

const GreenHeader = styled.h2`
	color: ${(props) => props.theme.colors.green.green500};
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	width: fit-content;
	margin: 64px auto 48px;
`;
GreenHeader.displayName = 'GreenHeader';

const Info1 = styled.div`
	display: flex;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	width: 320px;
	gap: 16px;
`;

const LiWithSvg = styled.li`
	& > svg {
		margin-bottom: -3px;
		display: inline;
	}
`;

const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	margin-top: 80px;
	color: ${(props) => props.theme.colors.gray.gray500};
`;

const Bi = styled.b`
	/* Bold & Italic */
	font-style: italic;
`;
