import constate from 'constate';
import { useCommercialTrendsState } from 'Components/CommercialSubmarketTrends/useCommercialTrendsState';
import { useCommercialTrendsData } from 'hooks/useCommercialTrendsData';
import { useMufaTrendsState } from 'Components/MufaSubmarket/useMufaTrendsState';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';
import { useTrendsToShow } from './useTrendsToShow';

export const [SubmarketTrendsProvider, useSubmarketTrendsContext] = constate(
	({ propertyId }: { propertyId: number }) => {
		const {
			filters: commercialTrendsFilters,
			handleFilterChange: setCommercialTrendsFilters,
		} = useCommercialTrendsState();

		const {
			filters: mufaTrendsFilters,
			handleFilterChange: setMufaTrendsFilters,
		} = useMufaTrendsState();

		const {
			data: commercialTrendsData,
			isLoading: isCommercialTrendsLoading,
			isFetching: isFetchingCommercialTrends,
		} = useCommercialTrendsData({
			propertyId,
			filters: commercialTrendsFilters,
		});

		const {
			data: mufaTrendsData,
			isLoading: isMufaTrendsLoading,
			isFetching: isFetchingMufaTrendsLoading,
		} = useMufaTrendsData({
			propertyId,
			filters: mufaTrendsFilters,
		});

		const trendsToShow = useTrendsToShow({
			propertyId,
		});

		return {
			commercialTrendsFilters,
			setCommercialTrendsFilters,
			commercialTrendsData,
			mufaTrendsFilters,
			setMufaTrendsFilters,
			mufaTrendsData,
			trendsToShow,
			isTrendsLoading: isCommercialTrendsLoading || isMufaTrendsLoading,
			isTrendsFetching:
				isFetchingCommercialTrends || isFetchingMufaTrendsLoading,
		};
	}
);
