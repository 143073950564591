import { Image } from '@react-pdf/renderer';
import { PdfSectionBox, PdfSectionDescriptionText } from '../PdfUI';
import { TenantIndustryPdfLegend } from '../TenantIndustryPdfLegend';
import { PdfSectionProps } from '../constants';

export const TenantIndustryMapPdfSection = ({
	tenantIndustryMap: { url, data, description },
}: PdfSectionProps) => {
	return (
		<PdfSectionBox title="Tenant Industry Map">
			{url && <Image src={url} style={{ marginBottom: 12 }} />}
			<TenantIndustryPdfLegend tenantIndustries={data} />
			<PdfSectionDescriptionText>{description}</PdfSectionDescriptionText>
		</PdfSectionBox>
	);
};
