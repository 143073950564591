import { PageSpinner } from 'Layouts/styles/PageSpinner';
import { bootstrap } from 'Pages/Login/actions';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'util/useAppSelector';

export const BootstrapLayout = ({ children }: { children: ReactNode }) => {
	const authState = useAppSelector((store) => store.authState);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!authState.checked) {
			dispatch(bootstrap());
		}
	}, [authState.checked, dispatch]);

	if (authState.checking || !authState.checked) {
		return <PageSpinner />;
	}

	return <>{children}</>;
};
