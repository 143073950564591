import { MarketRentResponse } from '@compstak/fe-react-api-layer';
import { useMemo } from 'react';
import { useMarketRentAnalyticsState } from './MarketRentAnalyticsProvider';
import { MarketRentAnalyticsPeriod } from './types';

type Props = {
	percentiles: MarketRentResponse['percentiles'];
};

/**
 * Returns percentiles in yearly or monthly values,
 * depending on the chosen period
 */
export const useMarketRentAdjustedPercentiles = ({ percentiles }: Props) => {
	const [state] = useMarketRentAnalyticsState();

	return useMemo(() => {
		if (state.period === MarketRentAnalyticsPeriod.MONTH) {
			return percentiles.map((percentile) => ({
				...percentile,
				marketRent: percentile.marketRent / 12,
			}));
		}
		return percentiles;
	}, [percentiles, state.period]);
};
