const UPPER_CASE_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const LOWER_CASE_LETTERS = 'abcdefghijklmnopqrstuvwxyz';
const NUMBERS = '0123456789';

const DEFAULT_CHARACTERS = LOWER_CASE_LETTERS + UPPER_CASE_LETTERS + NUMBERS;

export const generateKey = (
	keyLength = 8,
	options?: { characters?: string }
): string => {
	const characters = options?.characters || DEFAULT_CHARACTERS;
	const result = [...Array(keyLength)].reduce(
		(acc: string) =>
			acc + characters.charAt(Math.floor(Math.random() * characters.length)),
		''
	);

	return result;
};
