import React, { memo } from 'react';
import { TooltipV2 } from '@compstak/ui-kit';
import styled from 'styled-components';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';

type LabelProps = {
	name: string;
	tooltip?: string;
	dateUpdatedAt?: string;
	className?: string;
};

export const KeyValueTableLabel = memo<LabelProps>(
	({ name, tooltip, dateUpdatedAt, className }) => {
		if (!tooltip) {
			return (
				<div className={className}>
					<Name>{name}</Name>
					{dateUpdatedAt && <SmallStyled>as of {dateUpdatedAt}</SmallStyled>}
				</div>
			);
		}

		return (
			<StyledDiv className={className}>
				<Name>{name}</Name>
				<TooltipV2 content={tooltip} side="bottom">
					<span data-qa-id={infoIconTestId}>
						<StyledIconQuestionMark />
					</span>
				</TooltipV2>
			</StyledDiv>
		);
	}
);
KeyValueTableLabel.displayName = 'Label';

const StyledDiv = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

const SmallStyled = styled.small`
	display: block;
	color: ${({ theme }) => theme.colors.neutral.n100};
	font-size: 12px;
	line-height: 16px;
`;

const Name = styled.div`
	display: inline-block;
`;

const infoIconTestId = 'key-value-table-label-tooltip-icon';

const StyledIconQuestionMark = styled(IconQuestionMark)`
	&:hover {
		stroke-width: 1.5;
	}
`;
