import { HorizontalScrollbar, VerticalScrollbar } from '@compstak/ui-kit';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { ActionColumn } from './ActionColumn';
import { ActionHeader } from './ActionHeader';
import { Body } from './Body';
import { ColumnReorderOverlay } from './ColumnReorder';
import { ColumnResizeOverlay } from './ColumnResize';
import { Header } from './Header';
import { useLayoutContext } from './LayoutProvider';
import { Loading } from './Loading';
import { NoDataMessage } from './NoDataMessage';
import { useOptionsContext } from './OptionsProvider';
import { HeaderScrollPadding, Root, TableSectionContainer } from './UI';

export const Table = () => {
	const { isLoading, noData } = useOptionsContext();
	const {
		tableWidth,
		tableHeight,
		setRoot,
		setHScroll,
		setVScroll,
		scrollbarWidth,
		bodyHeightWithOffset,
		bodyWidthWithOffset,
		bodyHasVOverflow,
		bodyHasHOverflow,
		onHScroll,
		onVScroll,
		bodyContentHeight,
		bodyContentWidth,
		isForcingShowScrollbar,
	} = useLayoutContext();

	let content: ReactNode;

	if (tableWidth === 0 || tableHeight === 0 || isLoading) {
		content = <Loading />;
	} else if (noData) {
		content = <NoDataMessage />;
	} else {
		content = (
			<>
				<TableSectionContainer>
					<div>
						<ActionHeader />
						<ActionColumn />
					</div>
					<RightContainer>
						<HeaderContainer>
							<Header />
							{bodyHasVOverflow && (
								<HeaderScrollPadding style={{ width: scrollbarWidth }} />
							)}
						</HeaderContainer>
						<BodyContainer>
							<Body />
							{bodyHasVOverflow && (
								<VerticalScrollbar
									height={bodyHeightWithOffset}
									scrollContainerHeight={bodyHeightWithOffset}
									scrollContentHeight={bodyContentHeight}
									onScroll={onVScroll}
									getElement={setVScroll}
									forcedScrollWidth={
										isForcingShowScrollbar ? scrollbarWidth : undefined
									}
								/>
							)}
						</BodyContainer>
						<ColumnResizeOverlay />
						<ColumnReorderOverlay />
					</RightContainer>
				</TableSectionContainer>
				{bodyHasHOverflow && (
					<HorizontalScrollbar
						width={tableWidth}
						scrollContainerWidth={bodyWidthWithOffset}
						scrollContentWidth={bodyContentWidth}
						onScroll={onHScroll}
						getElement={setHScroll}
						forcedScrollWidth={
							isForcingShowScrollbar ? scrollbarWidth : undefined
						}
					/>
				)}
			</>
		);
	}

	return <Root ref={setRoot}>{content}</Root>;
};

const RightContainer = styled.div`
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
`;

const HeaderContainer = styled.div`
	display: flex;
`;

const BodyContainer = styled.div`
	display: flex;
`;
