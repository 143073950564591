import React from 'react';
import Multiselect from 'Components/Filters/Base/Multiselect';

import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { useReferenceDataQuery } from 'api';

const attribute = 'buildingClassId' as const;

type Props = {
	inputId?: string;
};
export const BuildingClassFilter = ({ inputId }: Props) => {
	const { data: referenceData } = useReferenceDataQuery();
	return (
		<FilterBase attribute={attribute} inputId={inputId}>
			{(props) => (
				<Multiselect
					attribute={attribute}
					filter={props.filter}
					onFilterChange={props.onFilterChange}
					setError={props.setError}
					touch={props.touch}
					options={referenceData.buildingClasses ?? []}
				/>
			)}
		</FilterBase>
	);
};
