import PortfolioTabCharts from 'PortfolioAnalytics/Charts/PortfolioTabCharts';
import { OverviewTabCharts } from 'PortfolioAnalytics/Charts/OverviewTabCharts';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type PortfolioTabProps = {
	portfolioId: number;
};

export const PortfolioTab = ({ portfolioId }: PortfolioTabProps) => {
	const { portfolioRevampOverviewTabFF } = useFeatureFlags();
	return portfolioRevampOverviewTabFF ? (
		<OverviewTabCharts portfolioId={portfolioId} />
	) : (
		<PortfolioTabCharts portfolioId={portfolioId} />
	);
};
