import {
	useFetchLargeLandlordsQuery,
	UseLargestLandlordsOptions,
} from 'api/largeLandlords/useFetchLargeLandlordsQuery';

import { useFilters } from 'reducers/filtersReducer';
import { useUser } from '../../../Pages/Login/reducers';
import { isSet } from 'models/filters/util/getSetFilters';

export const useExchangeDashboardLargeLandlords = (
	options?: UseLargestLandlordsOptions
) => {
	const [filters] = useFilters('exchange-dashboard');
	const user = useUser();

	let resultFilters = filters;
	if (user.userType !== 'admin') {
		resultFilters = {
			...resultFilters,
			partial: 'false',
		};
	}

	if (!isSet(resultFilters.expirationDate, 'expirationDate')) {
		resultFilters = {
			...resultFilters,
			expirationDate: {
				min: new Date(),
				max: null,
			},
		};
	}

	const query = 'size_attribute=transactionSize';
	const largeLandlords = useFetchLargeLandlordsQuery(
		{
			query,
			filters: resultFilters,
		},
		options
	);

	return {
		...largeLandlords,
		type: 'largestLandlords' as const,
	};
};
