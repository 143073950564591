import { FeatureFlags } from 'api/featureFlags/types';
import {
	ColumnConfig,
	COLUMN_CONFIG,
	ColumnConfigValues,
} from './columnConfigs';
import { LOAN_SECTION, MUFA_SECTION } from './columnSectionsConfig';

export const filterColumnPreferences = <
	T extends { compAttributeId: number }[] | number[],
>(
	preferences: T,
	featureFlags: FeatureFlags
): T => {
	return preferences.filter((c) => {
		const compAttributeId = typeof c === 'object' ? c.compAttributeId : c;
		const column: ColumnConfigValues | undefined =
			COLUMN_CONFIG[compAttributeId as keyof ColumnConfig];
		if (!column) {
			return false;
		}

		if (column.featureFlag || column.featureFlagOff) {
			return (
				((column.featureFlag && featureFlags[column.featureFlag]) ?? false) ||
				((column.featureFlagOff && !featureFlags[column.featureFlagOff]) ??
					false)
			);
		}

		if (window.appConfig.isExchange && !featureFlags.ExchangeLoans) {
			return !LOAN_SECTION.ids.includes(column.id);
		}

		if (window.appConfig.isExchange) {
			return !MUFA_SECTION.ids.includes(column.id);
		}

		return true;
	}) as T;
};
