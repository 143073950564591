import { nullValueText, User } from '@compstak/common';
import ValueOrLockButton from 'Components/AttributeValues/ValueOrLock/Button';
import React, { ComponentProps } from 'react';
import { Comp, CompType } from 'types';
import { TableField } from 'types/table';
import { isEstimated } from 'util/estimated';
import getFieldValue from 'util/getFieldValue';
import { kebabize } from 'utils';
import { getIsPortfolioField, NAV_KEY } from '../../../../Components';

type LiteCellProps = {
	comp: Comp;
	compType: CompType;
	field: TableField;
	height?: number;
	queryString: string;
	rowIndex?: number;
	selection: Map<number, boolean>;
	user: User;
	value: ReturnType<typeof getFieldValue>;
	width?: number;
	onClick?: ComponentProps<'a'>['onClick'];
};

type LiteCellState = {
	comp: Comp;
	justPurchased: boolean;
};

export class LiteCell extends React.Component<LiteCellProps, LiteCellState> {
	state: LiteCellState = {
		comp: this.props.comp,
		justPurchased: false,
	};

	compIdRef: { value: number | null } = { value: null };

	static getDerivedStateFromProps(props: LiteCellProps, state: LiteCellState) {
		if (props.comp.id !== state.comp.id) {
			return {
				comp: props.comp,
				justPurchased: false,
			};
		} else if (
			'own' in props.comp &&
			props.comp.own &&
			'own' in state.comp &&
			!state.comp.own
		) {
			return {
				comp: props.comp,
				justPurchased: true,
			};
		}
		return null;
	}

	// TODO: if can live withou shouldComponentUpdate, we probably can remove the handler below
	// This horrible hack of a function is here to optimize the speed of the table.
	// The cell doesn't update unless the value that it's displaying changes. But,
	// if the cell gets reused by a different comp that has the same value (or lack
	// of value) for this field, it won't update any of the metadata on the link.
	// So, we fix that onMouseOver.
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	fixLinkData = (event) => {
		const link = event.target;

		const comp = this.props.comp;

		let href = `/comps/${this.props.compType}s/${comp.id}`;
		if (this.props.compType === 'property') {
			href = `/property/${comp.id}`;
		}

		const id = link.getAttribute('data-comp-id');
		if (id !== comp.id.toString()) {
			link.href = href;
			link.setAttribute('data-query-string', this.props.queryString);
			link.setAttribute('data-index', this.props.rowIndex);
			link.setAttribute('data-comp-id', comp.id);
			link.setAttribute('data-cell-for', this.props.rowIndex);
			this.compIdRef.value = comp.id;
		}
	};

	render() {
		const { comp, height, width } = this.props;

		const style = { height, width };

		let value = this.props.value;

		let className =
			'fixedDataTableCellLayout_main public_fixedDataTableCell_main search-table-cell';

		// need this condition because we handle portfolio property
		// values differently elsewhere
		if (!getIsPortfolioField(this.props.field.name)) {
			switch (value) {
				case undefined:
				case Infinity: {
					value = nullValueText;
					className += ' no-data';
					break;
				}
			}
		}

		if (this.props.selection.get(comp.id)) {
			className += ' comp-selected';
		}

		if (this.state.justPurchased) {
			className += ' just-purchased';
		}

		let href = `/comps/${this.props.compType}s/${comp.id}`;
		if (this.props.compType === 'property') {
			href = `/property/${comp.id}`;
		}

		const navKeyDataAttr = {
			[`data-${kebabize(NAV_KEY)}`]: true,
		};

		return (
			// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,
			<a
				href={href}
				data-query-string={this.props.queryString}
				data-index={this.props.rowIndex}
				data-comp-id={comp.id}
				style={style}
				className={className}
				data-cell-for={this.props.field.name + '-' + this.props.rowIndex}
				onMouseOver={this.fixLinkData}
				data-tooltip="Click to view details"
				data-tooltip-class="data-cell-tooltip-zindex-override"
				onClick={this.props.onClick}
				{...navKeyDataAttr}
			>
				<span className="cell-data">
					<ValueOrLockButton
						{...this.props}
						comp={comp}
						compIdRef={this.compIdRef}
					/>
					{isEstimated(comp, this.props.field.name) ? (
						<span style={{ color: '#cbced8' }}> (Est.)</span>
					) : (
						false
					)}
				</span>
			</a>
		);
	}
}

type LoadingCellProps = {
	field: TableField;
	height?: number;
	rowIndex?: number;
};

const LoadingCell = (props: LoadingCellProps) => {
	const loaderStyle = {
		height: props.height,
		width: Math.floor(Math.random() * 30) + 40,
	};
	return (
		<a
			href=""
			onClick={(event) => event.preventDefault()}
			style={loaderStyle}
			className="fixedDataTableCellLayout_main public_fixedDataTableCell_main search-table-cell"
			data-cell-for={props.field.name + '-' + props.rowIndex}
		>
			<span className="cell-data">
				<div className="cell-data-loading" style={loaderStyle} />
			</span>
		</a>
	);
};

export type DataCellProps = {
	compType: CompType;
	field: TableField;
	height?: number;
	isLoadingComps?: boolean;
	loadRow: (rowIndex: number) => Comp;
	queryString: string;
	rowIndex?: number;
	selection: Map<number, boolean>;
	user: User;
	width?: number;
	onClick?: LiteCellProps['onClick'];
};

const DataCell = (props: DataCellProps) => {
	const comp = props.rowIndex != null ? props.loadRow(props.rowIndex) : null;

	if (!comp || props.isLoadingComps) {
		return (
			<LoadingCell
				field={props.field}
				height={props.height}
				rowIndex={props.rowIndex}
			/>
		);
	} else {
		const value = getFieldValue(comp, props.compType, props.field.name);
		return <LiteCell {...props} value={value} comp={comp} />;
	}
};

export default DataCell;
