import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Feature, MultiPolygon, Polygon } from '@turf/helpers';
import { QUERY_KEYS } from 'api/constants';
import { mapUrlToGateway } from 'utils/gatewayUtil';

export const useCountyPolygonByFIPSQuery = (
	params: UseCountyPolygonByFIPSQueryParams,
	options?: UseCountyPolygonByFIPSQueryOptions
) => {
	const { fips } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.COUNTY_POLYGON_BY_FIPS, params],
		queryFn: async () => {
			const response = await API.get<CountyPolygon>(
				mapUrlToGateway(`/ppm-property/api/v1/county/feature/fips/${fips}`)
			);

			return response.data;
		},
		...options,
	});
};

export type UseCountyPolygonByFIPSQueryOptions = UseQueryOptions<CountyPolygon>;

type UseCountyPolygonByFIPSQueryParams = {
	fips: string;
};

type CountyProperties = {
	countyId: number;
	name: string;
	state_code: string;
};

export type CountyPolygon = Feature<Polygon | MultiPolygon, CountyProperties>;
