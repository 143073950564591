import {
	useMarketRentAnalyticsDashboardQuery,
	SPACE_TYPE_NAME_TO_ID,
} from 'api';
import {
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsViewMode,
	useMarketRentAnalyticsState,
} from 'maps';
import { useFilters } from 'reducers/filtersReducer';
import { MarketRentAnalyticsDashboardPropertyType } from 'api';
import { propertyTypeFilterStatus } from 'ExchangeDashboard/utils/propertyTypeFilterStatus';
import { useEffect } from 'react';

export const useDashboardMarketRentData = () => {
	let propertyTypeBasedOnFilters =
		MarketRentAnalyticsDashboardPropertyType.OFFICE;
	const [marketRentAnalyticsState, setMarketRentAnalyticsState] =
		useMarketRentAnalyticsState();
	const { period, viewMode, propertyType, rentType } = marketRentAnalyticsState;
	const [filters] = useFilters('exchange-dashboard');
	const filtersSpaceTypeId = filters.spaceTypeId;
	const filtersPropertyTypeId = filters.buildingPropertyTypeId;

	const {
		moreThanOnePropertyTypeSelected,
		incorrectPropertyTypeSelected,
		propertyTypeSelected,
		industrialPropertyTypeSelected,
	} = propertyTypeFilterStatus(filtersPropertyTypeId);

	// If the user only applied a space type filter and no property type filter, the map's property type depends on the space type selection. See AP-17209
	if (
		propertyTypeSelected &&
		!moreThanOnePropertyTypeSelected &&
		!incorrectPropertyTypeSelected
	) {
		if (industrialPropertyTypeSelected)
			propertyTypeBasedOnFilters =
				MarketRentAnalyticsDashboardPropertyType.INDUSTRIAL;
	} else if (filtersSpaceTypeId) {
		if (filtersSpaceTypeId.includes(SPACE_TYPE_NAME_TO_ID.Office)) {
			propertyTypeBasedOnFilters =
				MarketRentAnalyticsDashboardPropertyType.OFFICE;
		} else if (filtersSpaceTypeId.includes(SPACE_TYPE_NAME_TO_ID.Industrial))
			propertyTypeBasedOnFilters =
				MarketRentAnalyticsDashboardPropertyType.INDUSTRIAL;
	}

	useEffect(() => {
		setMarketRentAnalyticsState((s) => ({
			...s,
			propertyType: propertyTypeBasedOnFilters,
		}));
	}, [propertyTypeBasedOnFilters]);

	return useMarketRentAnalyticsDashboardQuery({
		monthly: period === MarketRentAnalyticsPeriod.MONTH,
		submarket: viewMode === MarketRentAnalyticsViewMode.SUBMARKET,
		propertyType,
		rentType,
		filters,
	});
};
