import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MUTATION_KEYS, QUERY_KEYS } from 'api/constants';
import {
	MarketSummaryFeedbackParams,
	FeedbackRequest,
	Feedback,
} from 'types/marketSummary';
import { mapUrlToGateway } from 'utils/gatewayUtil';

export type SummaryFeedbackUpdate = MarketSummaryFeedbackParams & {
	body: FeedbackRequest;
};

export const useUpdateMarketSummaryFeedbackMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: [MUTATION_KEYS.UPDATE_MARKET_SUMMARY_FEEDBACK],
		mutationFn: async ({
			marketId,
			spaceType,
			userId,
			body,
		}: SummaryFeedbackUpdate) => {
			return API.put(
				mapUrlToGateway(
					`/user-service/markets/${marketId}/users/${userId}/spaceTypes/${spaceType}/feedback`
				),
				body
			);
		},
		onMutate: async ({ marketId, userId, spaceType, body }) => {
			await queryClient.cancelQueries([
				QUERY_KEYS.MARKET_SUMMARY_FEEDBACK,
				marketId,
				userId,
			]);

			queryClient.setQueryData<{ feedbacks: Feedback[] | undefined }>(
				[QUERY_KEYS.MARKET_SUMMARY_FEEDBACK, marketId, userId],
				(oldData) => {
					if (!oldData) return oldData;
					const updatedData = {
						...oldData,
						feedbacks: oldData?.feedbacks?.map((feedback) =>
							feedback.spaceType === spaceType
								? { ...feedback, ...body }
								: feedback
						),
					};
					return updatedData;
				}
			);
		},
		onSuccess: (_, { marketId, userId }) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.MARKET_SUMMARY_FEEDBACK, marketId, userId],
			});
		},
	});
};
