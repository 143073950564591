import { Text, View } from '@react-pdf/renderer';
import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { useTheme } from 'styled-components';
import { PdfSectionDescriptionText, useSharedPdfStyle } from '../PdfUI';

export const AveragesPdfSection = ({
	averagesData: { averages, timeline },
}: {
	averagesData: ReturnType<typeof useExchangeDashboardAverages>;
}) => {
	const style = useSharedPdfStyle();

	return (
		<View style={style.pdfSectionBox}>
			<Text style={[style.pdfSectionTitle, { marginBottom: 9 }]}>Averages</Text>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: 12,
					marginBottom: 12,
				}}
			>
				<Card
					label="Starting Rent (PSF)"
					value={averages?.startingRent?.value ?? 'N/A'}
				/>
				<Card
					label="Net Effective Rent"
					value={averages?.effectiveRent?.value ?? 'N/A'}
				/>
				<Card label="TI Value" value={averages?.ti?.value ?? 'N/A'} />
				<Card label="Free Rent" value={averages?.freeMonths?.value ?? 'N/A'} />
				<Card label="Term" value={averages?.leaseTerm?.value ?? 'N/A'} />
			</View>
			<PdfSectionDescriptionText>
				Averages encompass leases executed in the last{' '}
				<Text style={{ fontWeight: 'bold' }}>{timeline} months.</Text>
			</PdfSectionDescriptionText>
		</View>
	);
};

const Card = ({ label, value }: { label: string; value: string }) => {
	const theme = useTheme();

	return (
		<View
			style={{
				backgroundColor: theme.colors.neutral.n20,
				flex: 1,
				minHeight: 60,
				padding: 11,
			}}
		>
			<Text style={{ fontSize: 24, marginBottom: 9 }}>{value}</Text>
			<Text style={{ fontSize: 9 }}>{label}</Text>
		</View>
	);
};
