import { useAPNPolygonQuery } from 'api/ppm-property/apnPolygons/useAPNPolygonQuery';
import { FilterErrors } from 'Components/Filters/Base/Filter/Container';
import { FilterInput } from 'Components/Filters/Base/Filter/FilterInput';
import { FilterSpinner } from 'Components/Filters/Base/Filter/FilterSpinner';
import {
	APNFilter as APNFilterType,
	FiltersObject,
} from 'models/filters/types';
import { setServerCacheFilter } from 'models/filters/util/serverFiltersCache';
import { createMultiPolygonFilterFromFeature } from 'Pages/Search/Map/Components/MultiPolygonFilter/createMultiPolygonFilterFromFeature';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FILTERS_DEBOUNCE_TIMEOUT } from 'util/formConstants';
import { trimString } from 'util/trimString';

type APNFilterProps = {
	filters: FiltersObject;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setError: (errors: FilterErrors) => void;
	touch: () => void;
};

export const APNFilter = ({
	filters,
	onFilterChange,
	setError,
	touch,
}: APNFilterProps) => {
	const fips = filters.fips?.[0] ?? '';
	const apn = filters.apn?.apn ?? '';

	const [value, setValue] = useState(apn);

	const {
		data: apnPolygon,
		isFetching,
		isError,
	} = useAPNPolygonQuery(
		{ apn: value, fips },
		{
			enabled: !!fips && !!trimString(value),
		}
	);

	useEffect(() => {
		if (isError) {
			setError('APN not found');
			return;
		}

		if (!apnPolygon) {
			return;
		}

		setError(null);

		const apnFilter: APNFilterType = {
			fips,
			apn: value,
			area: createMultiPolygonFilterFromFeature(apnPolygon),
		};

		// AP-14633: storing apn filter in cache as a workaround when filters are reset via URL params
		// Since `area` part of the filter is way too big when it's serialized to URL param
		setServerCacheFilter('apn', apnFilter);

		onFilterChange({ apn: apnFilter });
	}, [apnPolygon, isError]);

	useEffect(() => {
		if (!!trimString(value) && !fips) {
			setError('County must be selected before APN');
		}
	}, [fips, setError, value]);

	useEffect(() => {
		return () => setServerCacheFilter('apn', null);
	}, []);

	const [onChange] = useDebouncedCallback((apnValue: string) => {
		touch();
		if (!trimString(apnValue)) {
			onFilterChange({
				apn: null,
			});
		}

		setError(null);
		setValue(apnValue);
	}, FILTERS_DEBOUNCE_TIMEOUT);

	return (
		<Container>
			<FilterInput
				placeholder="Add APN"
				defaultValue={value}
				onChange={(ev) => onChange(ev.target.value)}
			/>
			{isFetching && <FilterSpinner />}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;
