import { ReactNode } from 'react';
import styled from 'styled-components';
import IconBars from 'ui/svg_icons/bars.svg';

type NoDataTabProps = NoDataMessageProps & {
	minHeight?: string;
};

export const NoDataTab = ({ minHeight, ...props }: NoDataTabProps) => {
	return (
		<Root minHeight={minHeight}>
			<NoDataMessage {...props} />
		</Root>
	);
};

type NoDataMessageProps = {
	message: ReactNode;
	action?: ReactNode;
};

export const NoDataMessage = ({ message, action }: NoDataMessageProps) => {
	return (
		<>
			<IconBars />
			<Title>No data available</Title>
			<Message>{message}</Message>
			{action}
		</>
	);
};

const Root = styled.div<{ minHeight?: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	padding: 5rem 0;
	${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

const Title = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.openSans};
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.25;
`;

const Message = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.openSans};
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
`;
