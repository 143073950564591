import { ExportButton } from '../../Components';
import styled from 'styled-components';
import { useLeasesListAndMapViewContext } from './LeasesListAndMapViewProvider';
import { usePropertiesListAndMapViewContext } from './PropertiesListAndMapViewsProvider';

type Props = {
	type: 'lease' | 'property';
};

// TODO: AP-14320 to remove when portfolio revamp is done
export const ListViewExportButton = ({ type }: Props) => {
	const { getSelectedRows: getSelectedProperties } =
		usePropertiesListAndMapViewContext();
	const { getSelectedRows: getSelectedLeases } =
		useLeasesListAndMapViewContext();

	return (
		<PortfolioExportButton
			className="button_dark_blue"
			selection={
				type === 'property' ? getSelectedProperties() : getSelectedLeases()
			}
			compType={type}
			menuSide="below-onright"
		/>
	);
};

const PortfolioExportButton = styled(ExportButton)`
	height: 42px;
	margin: 0;
`;
