import { emailConstants } from '@compstak/common';
import { TotpCodeInput } from 'Components/MultiFactorAuth/MfAuthComponents';
import { TOTP_ERROR_MESSAGES } from 'Components/MultiFactorAuth/MfAuthConstants';
import styled, { css } from 'styled-components';
import { isValidTotpCode } from 'util/mfAuthUtil';
import { LoginBtn, LoginFormWrapper } from './LoginFormComponents';
import styles from '../stylesheets/login.less';

export function MfaLoginForm({
	checking,
	signin,
	errorMessage,
	setErrorMessage,
	totpCode,
	setTotpCode,
}: {
	checking: boolean;
	signin: NoArgCallback;
	errorMessage?: string | null;
	setErrorMessage: (errorMessage: string) => void;
	totpCode: string;
	setTotpCode: (digits: string) => void;
}) {
	const submit = () => {
		if (!isValidTotpCode(totpCode)) {
			setErrorMessage(TOTP_ERROR_MESSAGES.INVALID_CODE);
			return;
		}
		signin();
	};

	return (
		<LoginFormWrapper>
			{errorMessage && (
				<div className={styles.error_message}>{errorMessage}</div>
			)}

			<div style={{ marginBottom: 16 }}>
				<StyledH2>Two-Factor Authentication</StyledH2>
				<StyledCTA>
					Enter the 6-digit code from your authentication app.{' '}
				</StyledCTA>
			</div>

			<TotpCodeInput
				digits={totpCode}
				setDigits={setTotpCode}
				onEnterKeyDown={submit}
			/>

			<ResetSection>
				<StyledResetText>Can’t receive code? Contact </StyledResetText>
				<a data-qa-id="help-mail-link" href={`mailto:${emailConstants.help}`}>
					{emailConstants.help}
				</a>
			</ResetSection>

			<LoginBtn
				data-qa-id="login-btn"
				text="Verify Code"
				onClick={submit}
				checking={checking}
			/>
		</LoginFormWrapper>
	);
}

const commonHeaderTextStyles = css`
	font-weight: 400;
	line-height: 20px;
	width: fit-content;
	color: ${({ theme }) => theme.colors.neutral.n800};
`;

const StyledH2 = styled.h2`
	${commonHeaderTextStyles}
	font-size: 14px;
`;

const StyledCTA = styled.div`
	// Using <div> instead of <p> because of some tricky parent css.
	${commonHeaderTextStyles}
	font-size: 12px;
`;

const ResetSection = styled.div`
	font-weight: 400;
	font-size: 12px;
	text-align: left;
`;

const StyledResetText = styled.span`
	color: ${({ theme }) => theme.colors.neutral.n90};
`;
