import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import React from 'react';

const attribute = 'partial' as const;

export const PartialFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					attribute={attribute}
					options={[
						{
							title: 'Only Full Comps',
							value: 'false',
						},
						{
							title: 'Only Partial Comps',
							value: 'true',
						},
						{
							title: 'Both',
							value: null,
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
