import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

const TOP_LANDLORDS_DEFAULT_LIMIT = 10;

export const useTopLandlordsQuery = (
	params: UseTopLandlordsParams,
	options?: UseTopLandlordsOptions
) => {
	const { filters, limit = TOP_LANDLORDS_DEFAULT_LIMIT } = params;
	const filter = filtersToServerJSON(filters);

	return useQuery({
		queryKey: [QUERY_KEYS.TOP_LANDLORDS, filter, limit],
		queryFn: async () => {
			const response = await API.post<TopLandlordsResponse>(
				'/api/comps/actions/topLandlords',
				{
					filter,
					limit,
				}
			);

			return response.data;
		},
		...options,
	});
};

type UseTopLandlordsOptions = UseQueryOptions<TopLandlordsResponse>;

type UseTopLandlordsParams = {
	filters: Partial<FiltersObject>;
	limit?: number;
};

export type Landlord = {
	name: string;
	leasedSqFt: number;
	totalLeasesCount: number;
	tenantIndustry?: string;
	globalLeasedSqFt?: number;
	globalTotalLeasesCount?: number;
};

export type TopLandlordsResponse = {
	landlords: Landlord[];
};
