import React, {
	ChangeEventHandler,
	forwardRef,
	KeyboardEventHandler,
} from 'react';

import SearchIcon from 'ui/svg_icons/search.svg';

import styles from './search-input.less';

type SearchInputProps = {
	completion?: string;
	currentIndex: number;
	moveSelection: (index: number) => void;
	onChange: ChangeEventHandler<HTMLInputElement>;
	onConfirmSelection: (index: number) => void;
	placeholder?: string;
	value?: string;
	onClick?: () => void;
};

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
	(
		{
			completion = '',
			currentIndex,
			moveSelection,
			onChange,
			onConfirmSelection,
			placeholder,
			value = '',
			onClick,
		},
		ref
	) => {
		const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
			if (event.key === 'ArrowDown') {
				moveSelection(1);
			}
			if (event.key === 'ArrowUp') {
				moveSelection(-1);
			}
			if (event.key === 'Enter') {
				onConfirmSelection(currentIndex);
			}
		};

		const firstPartOfCompletion = completion.slice(0, value.length);
		if (
			value.length === 0 ||
			firstPartOfCompletion.toLowerCase() !== value.toLowerCase()
		) {
			completion = '';
		} else {
			completion = completion.slice(value.length);
		}

		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<div className={styles.container} onClick={onClick}>
				<SearchIcon className={styles.icon} width={19} height={19} />
				<input
					className={styles.completion}
					type="text"
					value={value + completion}
					readOnly={true}
				/>
				<input
					className={styles.mask}
					type="text"
					value={value}
					readOnly={true}
				/>
				<input
					data-qa-id="search-bar"
					ref={ref}
					className={styles.input}
					type="text"
					data-typeahead-input={true}
					value={value}
					onChange={onChange}
					onKeyDown={handleKeyDown}
					placeholder={placeholder}
				/>
			</div>
		);
	}
);

SearchInput.displayName = 'SearchInput';
