import { CheckboxDisabled, CheckboxEnabled } from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { Fragment, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { checkboxStyles } from 'ui/checkboxStyles';
import { highlightText } from 'utils/highlightText';
import IconCross from '../../../ui/svg_icons/cross.svg';
import IconSearch from '../../../ui/svg_icons/search.svg';
import { useColumnsContext } from '../Columns';
import { useOptionsContext } from '../OptionsProvider';
import {
	COLUMN_CONFIG,
	ColumnConfig,
	ColumnConfigValues,
} from '../columnConfigs';
import { TableSection } from '../types';
import { PresetSelect } from './PresetSelect';
import { useColumnSections } from './useColumnSections';

export const TableSettingsMenu = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { settingsMenuSide } = useOptionsContext();

	const { selectedColumnPreferences, toggleColumn, resetToDefault } =
		useColumnsContext();

	const columnSections = useColumnSections();

	const trimmedSearchTerm = searchTerm.trim().toLowerCase();

	const filteredSections = useMemo(() => {
		const filtered: TableSection[] = [];

		for (const section of columnSections) {
			const ids: (keyof ColumnConfig)[] = [];

			for (const id of section.ids) {
				const config = COLUMN_CONFIG[id];
				if (config.label.toLowerCase().includes(trimmedSearchTerm)) {
					ids.push(id);
				}
			}

			if (ids.length > 0) {
				filtered.push({
					...section,
					ids,
				});
			}
		}

		return filtered;
	}, [trimmedSearchTerm, columnSections]);

	const renderListItem = (config: ColumnConfigValues) => {
		const isSelected = !!selectedColumnPreferences.find(
			(p) => p.compAttributeId === config.id
		);

		return (
			<ListItem
				key={config.id}
				onClick={() => {
					toggleColumn(config.id);
				}}
			>
				{isSelected ? <CheckboxEnabled /> : <CheckboxDisabled />}
				<div>
					{trimmedSearchTerm.length > 0
						? highlightText(config.label, trimmedSearchTerm)
						: config.label}
				</div>
			</ListItem>
		);
	};

	return (
		<Popover.Portal>
			<Popover.Content side={settingsMenuSide} style={{ zIndex: 5 }}>
				<Root>
					<Header>
						<Title>Customize Columns</Title>
						<PresetSelect />
					</Header>
					<InputContainer>
						<StyledIconSearch width={14} height={14} />
						<Input
							type="text"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.currentTarget.value)}
							placeholder="Search for a column"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							ref={inputRef}
						/>
						{searchTerm.length > 0 && (
							<ClearButton
								onClick={() => {
									setSearchTerm('');
									inputRef.current?.focus();
								}}
							>
								<StyledIconCross width={10} height={10} />
							</ClearButton>
						)}
					</InputContainer>
					<List>
						{filteredSections.map((section) => {
							const listItems = section.ids.map((id) => {
								const config = COLUMN_CONFIG[id];
								return renderListItem(config);
							});

							if (trimmedSearchTerm.length > 0) {
								return <Fragment key={section.id}>{listItems}</Fragment>;
							} else {
								return (
									<Fragment key={section.id}>
										<ListItemHeader>
											{section.label}
											{section.renderLogo?.()}
										</ListItemHeader>
										{listItems}
									</Fragment>
								);
							}
						})}
					</List>
					<Footer>
						<ResetButton data-qa-id="reset-btn" onClick={resetToDefault}>
							Reset to Default
						</ResetButton>
					</Footer>
				</Root>
				<Arrow />
			</Popover.Content>
		</Popover.Portal>
	);
};

const Root = styled.div`
	border-radius: 4px;
	background-color: ${(p) => p.theme.colors.gray.gray700};
	width: 400px;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
`;

const Arrow = styled(Popover.Arrow)`
	fill: ${(p) => p.theme.colors.gray.gray700};
`;

const Title = styled.div`
	color: ${(p) => p.theme.colors.neutral.n0};
	text-transform: uppercase;
`;

const Header = styled.div`
	padding: 0.75rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
	background-color: ${({ theme }) => theme.colors.gray.gray800};
`;

const StyledIconSearch = styled(IconSearch)`
	fill: #fff;
`;

const ClearButton = styled.button`
	all: unset;
	cursor: pointer;
	padding: 0.5rem;
`;

const StyledIconCross = styled(IconCross)`
	fill: #fff;
`;

const Input = styled.input`
	&&& {
		all: unset;
		width: 100%;
		height: 40px;
		padding: 0 0.5rem;
		box-sizing: border-box;
		color: #fff;
		background-color: ${(p) => p.theme.colors.gray.gray800};
		&::placeholder {
			font-style: italic;
		}
	}
`;

const List = styled.ul`
	height: 400px;
	overflow-y: scroll;
`;

const BaseItem = styled.li`
	height: 32px;
	padding: 0px 16px;
	font-size: 12px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${(p) => p.theme.colors.gray.gray800};
	color: ${(p) => p.theme.colors.neutral.n0};
`;

const ListItemHeader = styled(BaseItem)`
	font-weight: 600;
	display: flex;
	justify-content: space-between;
`;

const ListItem = styled(BaseItem)`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 400;
	cursor: pointer;
	${checkboxStyles}
	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral.n500};
	}
`;

export const Footer = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.neutral.n100};
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 0 1rem;
	font-size: 14px;
`;

export const ResetButton = styled.button`
	all: unset;
	text-decoration: underline;
	cursor: pointer;
	font-weight: 300;
	color: #fff;
`;
