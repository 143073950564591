import { RowFlexContainer, WidgetTitle } from '@compstak/ui-kit';
import styled from 'styled-components';

export const RowFlexContainerStyled = styled(RowFlexContainer)`
	margin-bottom: 16px;
`;

export const WidgetTitleStyled = styled(WidgetTitle)`
	margin: 0px 4px 2px 0px;
`;

export const tooltipRootStyle = {
	margin: '0 16px 0 0',
};
