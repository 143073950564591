import { EnterpriseUserMenu } from 'enterprise/Components/Header/EnterpriseUserMenu';
import { ExchangeUserMenu } from 'exchange/Components/Header/ExchangeUserMenu';
import { useIsExchange } from 'hooks';

type UserMenuProps = {
	setIsMenuOpen: (isOpen: boolean) => void;
};

export const UserMenu = ({ setIsMenuOpen }: UserMenuProps) => {
	const isExchange = useIsExchange();
	const closeMenu = () => setIsMenuOpen(false);

	return isExchange ? (
		<ExchangeUserMenu closeMenu={closeMenu} />
	) : (
		<EnterpriseUserMenu closeMenu={closeMenu} />
	);
};
