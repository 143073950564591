import { CenterOnBounds } from '@compstak/maps';
import { useAPNPolygonQuery } from 'api/ppm-property/apnPolygons/useAPNPolygonQuery';
import { useCountyPolygonByFIPSQuery } from 'api/ppm-property/countyPolygons/useCountyPolygonByFIPSQuery';
import { FiltersObject } from 'models/filters/types';

const APN_MAX_ZOOM = 18;

export const CenterOnCountyOrApnEffect = ({
	filters,
}: {
	filters: FiltersObject;
}) => {
	const fips = filters.fips?.[0] ?? '';
	const apn = filters.apn?.apn ?? '';

	const { data: countyPolygon } = useCountyPolygonByFIPSQuery(
		{ fips },
		{
			enabled: !!fips && !apn,
		}
	);

	const { data: apnPolygon } = useAPNPolygonQuery(
		{ fips, apn },
		{
			enabled: !!fips && !!apn,
		}
	);

	if (!apn && countyPolygon) {
		return <CenterOnBounds geoJSON={countyPolygon} />;
	}

	if (apnPolygon) {
		return (
			<CenterOnBounds
				geoJSON={apnPolygon}
				options={{ maxZoom: APN_MAX_ZOOM }}
			/>
		);
	}

	return null;
};
