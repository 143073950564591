import { renderTableValue } from '@compstak/ui-kit';
import { PropertySale } from 'api';
import { useNavigate } from 'router';
import styled from 'styled-components';
import { PortfolioItem, SalesComp } from 'types';
import { TableField } from 'types/table';
import { formatValue } from 'util/comp-format/src/format';
import { getIsMonthlyMarket } from '../../utils';
import { PORTFOLIO_PROPERTY_FIELDS_ADDITIONAL_COLUMN } from './config';

type Props = {
	saleComp: SalesComp | PropertySale;
	field?: TableField;
	closeTooltip: () => void;
};

export const MultiPropertyItemsTable = ({
	saleComp,
	field,
	closeTooltip,
}: Props) => {
	const navigate = useNavigate();

	const shouldShowAdditionalColumn =
		field &&
		PORTFOLIO_PROPERTY_FIELDS_ADDITIONAL_COLUMN.includes(
			field.name as keyof PortfolioItem
		);

	return (
		<Table>
			<thead>
				<tr>
					<TableHeaderCell center>Index</TableHeaderCell>
					<TableHeaderCell>Address</TableHeaderCell>
					<TableHeaderCell>City</TableHeaderCell>
					<TableHeaderCell>State</TableHeaderCell>
					<TableHeaderCell>Zip Code</TableHeaderCell>
					<TableHeaderCell>Property Type</TableHeaderCell>
					<TableHeaderCell>Year Built</TableHeaderCell>
					<TableHeaderCell>Building Class</TableHeaderCell>
					{shouldShowAdditionalColumn && (
						<TableHeaderCell>{field.displayName}</TableHeaderCell>
					)}
					<TableHeaderCell />
				</tr>
			</thead>
			<tbody>
				{saleComp.portfolio.map((p, i) => {
					return (
						<TableBodyRow key={i}>
							<TableBodyCell center>{i + 1}</TableBodyCell>
							<TableBodyCell>
								{renderTableValue(p.buildingAddress)}
							</TableBodyCell>
							<TableBodyCell>{renderTableValue(p.city)}</TableBodyCell>
							<TableBodyCell>{renderTableValue(p.state)}</TableBodyCell>
							<TableBodyCell>{renderTableValue(p.zip)}</TableBodyCell>
							<TableBodyCell>
								{renderTableValue(p.buildingPropertyType)}
							</TableBodyCell>
							<TableBodyCell>{renderTableValue(p.buildingYear)}</TableBodyCell>
							<TableBodyCell>{renderTableValue(p.buildingClass)}</TableBodyCell>
							{/* Added this statement (marketId' in saleComp) to prevent entering in this if the type is PropertySale */}
							{'marketId' in saleComp && shouldShowAdditionalColumn && (
								<TableBodyCell>
									{/* @ts-expect-error TS7053: Element implicitly has an 'any... */}
									{renderTableValue(p[field.name], (v) =>
										formatValue(
											field.name,
											v,
											getIsMonthlyMarket(saleComp.marketId),
											'sale'
										)
									)}
								</TableBodyCell>
							)}
							<TableBodyCell>
								<ViewPropertyButton
									onClick={(e) => {
										e.stopPropagation();
										closeTooltip();
										navigate(`/property/${p.propertyId}`);
									}}
									data-qa-id="view-property-btn"
								>
									VIEW PROPERTY
								</ViewPropertyButton>
							</TableBodyCell>
						</TableBodyRow>
					);
				})}
			</tbody>
		</Table>
	);
};

const Table = styled.table`
	background: ${(p) => p.theme.colors.white.white};
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-weight: 400;
	width: 100%;
	box-sizing: border-box;
`;

const TableHeaderCell = styled.th<{ center?: boolean }>`
	background-color: ${(p) => p.theme.colors.gray.gray200};
	color: ${(p) => p.theme.colors.gray.gray500};
	padding: 8px;
	text-align: ${(p) => (p.center ? 'center' : 'left')};
`;

const TableBodyRow = styled.tr`
	border-bottom: 4px solid ${(p) => p.theme.colors.gray.gray200};
`;

const TableBodyCell = styled.td<{ center?: boolean }>`
	padding: 8px;
	// TODO: add color to ui-kit theme
	color: #9097ad;
	text-align: ${(p) => (p.center ? 'center' : 'left')};
`;

const ViewPropertyButton = styled.button`
	all: unset;
	color: #9097ad;
	display: block;
	text-align: right;
	cursor: pointer;
	&:hover {
		color: ${(p) => p.theme.colors.blue.blue400};
	}
`;
