import React from 'react';
import styled from 'styled-components';

interface LeagueTableHeaderProps {
	title: React.ReactNode;
	description?: React.ReactNode;
	rightArea?: React.ReactNode;
}

export const LEAGUE_TABLE_RIGHT_AREA_CLASSNAME = 'league-table-right-area';

export const LeagueTableHeader = ({
	title,
	description,
	rightArea,
}: LeagueTableHeaderProps) => {
	return (
		<HeaderWrap>
			<LeftArea>
				<TableTitle>{title}</TableTitle>
				{description && <TableDescription>{description}</TableDescription>}
			</LeftArea>
			<div className={LEAGUE_TABLE_RIGHT_AREA_CLASSNAME}>{rightArea}</div>
		</HeaderWrap>
	);
};

const LeftArea = styled.div`
	min-width: 0;
`;

export const HeaderWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	gap: 16px;

	${({ theme }) => theme.breakpoints.down('M_414')} {
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
`;

export const TableDescription = styled.h4`
	color: ${({ theme }) => theme.colors.neutral.n100};
	font-family: ${({ theme }) => theme.typography.fontFamily.openSans};
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.3px;

	${({ theme }) => theme.breakpoints.down('M_414')} {
		font-size: 0.75rem;
	}
`;

export const TableTitle = styled.h3`
	margin: 0;
	color: ${({ theme }) => theme.colors.gray.gray700};
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;

	${({ theme }) => theme.breakpoints.down('M_414')} {
		font-size: 1rem;
		line-height: 24px;
	}
`;
