import { Indicator, TrendLineItem } from '../../../api';
import { LeaseTradeOutDTO, OccupancyDTO, SubmarketMarketTrendDTO } from 'api';
import { CompSetTrendDTO } from 'api';
import { ChartData, LineDescription } from '@compstak/ui-kit';
import { LinesChartProps } from '@compstak/ui-kit';
import maxBy from 'lodash/maxBy';
import {
	CHART_DATA_EMPTY_PREFIX,
	CHART_DATA_EMPTY_SEPARATOR,
} from '@compstak/common';

type IncomingData =
	| MarketTrendDTO
	| LeaseTradeOutDTO
	| CompSetTrendDTO
	| SubmarketMarketTrendDTO
	| OccupancyDTO;

export const getAdaptData = () => {
	return (incomingData: IncomingData | undefined): LinesChartProps['data'] => {
		if (!incomingData) return [];
		const filteredData = removeAttributeWithoutData(incomingData);
		const keysOfData = Object.keys(filteredData);
		const baseKey = maxBy<string>(
			keysOfData,
			(i) => filteredData[i].trendLine.length
		);
		if (!baseKey) return [];
		const baseArray = filteredData[baseKey]?.trendLine;

		return baseArray?.map((item: TrendLineItem) => {
			const objectEntries = keysOfData.map((key: string) => {
				// TODO: need to revise this logic, it works accidentally or extremely implicit
				// "same date", most likely, here is about quarter-based values.
				const valueOfItemWithSameDate = findValueOfItemWithSameDate(
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					incomingData[key].trendLine,
					item.date
				);

				return [key, valueOfItemWithSameDate];
			});

			const targetCoordsObj = Object.fromEntries(objectEntries);
			targetCoordsObj.name = item.date;

			return targetCoordsObj;
		});
	};
};

export const removeAttributeWithoutData = (data: IncomingData) => {
	return (
		data &&
		Object.entries(data).reduce<Record<string, Indicator>>(
			(acc, [key, value]) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				if (data[key].trendLine?.length) {
					acc[key] = value;
				}
				return acc;
			},
			{}
		)
	);
};

export const findValueOfItemWithSameDate = (
	arr: Array<TrendLineItem> = [],
	date: string
) => {
	const itemWithSameDate = arr.find((obj) => obj.date === date);
	return itemWithSameDate?.value;
};

export const getFilteredLines = (
	coords: Array<ChartData> | undefined,
	sourceLines: Array<LineDescription>,
	emptyLines: Array<LineDescription> = []
): Array<LineDescription> => {
	if (coords?.length === 0) return sourceLines;

	const keysOfData = coords && Object.keys(coords[0]);
	if (!keysOfData) return sourceLines;

	const linesWithoutNonexistent = sourceLines.filter((line) =>
		keysOfData.includes(line.value)
	);

	addChunkedEmptyLines(linesWithoutNonexistent, emptyLines, keysOfData);

	return linesWithoutNonexistent;
};

// @ts-expect-error TS7006: Parameter 'lines' implicitly h...
export const addChunkedEmptyLines = (lines, emptyLines, keysOfData) => {
	// @ts-expect-error TS7006: Parameter 'key' implicitly has...
	const emptyChunks = keysOfData.filter((key) =>
		key.startsWith(CHART_DATA_EMPTY_PREFIX)
	);
	const uniqChunks = {};
	emptyChunks.forEach(incrementChunkAmountForUniqKey(uniqChunks));

	for (const chunkKey in uniqChunks) {
		// @ts-expect-error TS7006: Parameter 'line' implicitly ha...
		const sourceLineIdx = lines.findIndex((line) => {
			const [, name] = chunkKey.split(CHART_DATA_EMPTY_SEPARATOR);
			return name === line.value;
		});
		// @ts-expect-error TS7006: Parameter 'line' implicitly ha...
		const emptyTemplate = emptyLines.find((line) => line.value === chunkKey);

		if (sourceLineIdx === -1 || emptyTemplate === undefined) continue;

		// @ts-expect-error TS7053: Element implicitly has an 'any...
		for (let i = 0; i < uniqChunks[chunkKey]; i++) {
			const emptyLineToPut = {
				...emptyTemplate,
				value: chunkKey + CHART_DATA_EMPTY_SEPARATOR + i,
			};
			lines.splice(sourceLineIdx + 1 + i, 0, emptyLineToPut);
		}
	}
};

// @ts-expect-error TS7006: Parameter 'uniqChunks' implici...
const incrementChunkAmountForUniqKey = (uniqChunks) => (chunk) => {
	const [prefix, name] = chunk.split(CHART_DATA_EMPTY_SEPARATOR);
	const uniqChunkKey = prefix + CHART_DATA_EMPTY_SEPARATOR + name;
	if (uniqChunks[uniqChunkKey] === undefined) uniqChunks[uniqChunkKey] = 0;
	uniqChunks[uniqChunkKey]++;
};

type Key = 'property' | 'compSetAverage' | 'submarket' | 'market';

type MarketTrendDTO = Record<Key, Indicator>;
