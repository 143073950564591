import React from 'react';
import Menu from 'Singletons/Menu';

import styles from 'Pages/Analytics/Builder/Components/styles/menu.less';
import popupStyles from 'Components/Popup/popup.less';
import { useModal } from 'providers/ModalProvider';
import { Project } from 'Pages/Analytics/analytics';
import { RenameProjectModal } from '../Modals/RenameProjectModal';
import { ShareProjectModal } from '../Modals/ShareProjectModal';
import { DeleteProjectModal } from '../Modals/DeleteProjectModal';
import { useDispatch } from 'react-redux';
import { hideMenu } from 'Singletons/Menu/actions';

export type ProjectMenuProps = {
	project: Project;
};

const PROJECT_MENU = 'project-menu';

export const ProjectMenu = ({ project }: ProjectMenuProps) => {
	const { openModal } = useModal();

	const dispatch = useDispatch();

	const rename = () => {
		dispatch(hideMenu(PROJECT_MENU));
		openModal({ modalContent: <RenameProjectModal project={project} /> });
	};

	const share = () => {
		dispatch(hideMenu(PROJECT_MENU));
		openModal({ modalContent: <ShareProjectModal project={project} /> });
	};

	const remove = () => {
		dispatch(hideMenu(PROJECT_MENU));
		openModal({ modalContent: <DeleteProjectModal project={project} /> });
	};

	return (
		<ul className={styles.menuContent}>
			<li onClick={rename}>Rename</li>
			<li onClick={share}>Share</li>
			<li onClick={remove}>Delete</li>
		</ul>
	);
};

Menu.addComponent(PROJECT_MENU, ProjectMenu, {
	className: popupStyles.compactWhite,
});

export default PROJECT_MENU;
