import styled from 'styled-components';
import { MapAnalyticsMenuContainer } from '../UI';

export const ExportLegendContainer = styled(MapAnalyticsMenuContainer)`
	position: absolute;
	left: 20px;
	bottom: 20px;
	z-index: 3;
`;

export const ExportLegendMarketName = styled.div`
	text-transform: uppercase;
	color: #303441;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
`;
