import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';

const attribute = 'loanSource' as const;

export const LoanSourceFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					attribute={attribute}
					options={[
						{
							title: 'All Sources',
							value: ['Trepp', 'Public'],
						},
						{
							title: 'Only Trepp',
							value: ['Trepp'],
						},
						{
							title: 'Only Public Record',
							value: ['Public'],
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
