import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useAppSelector } from 'util/useAppSelector';

export const useSubmarketFeatures = () => {
	const marketIdToSubmarkets = useAppSelector(
		(s) => s.searchReducer.marketIdToSubmarkets
	);
	const [filters] = useFilters();

	const submarketFeatures = useMemo(() => {
		return marketIdToSubmarkets
			? Object.values(marketIdToSubmarkets).flat()
			: [];
	}, [marketIdToSubmarkets]);

	const selectedSubmarketFeatures = useMemo(() => {
		const selectedSubmarkets = filters.submarkets;

		if (!selectedSubmarkets) {
			return [];
		}

		return submarketFeatures.filter((submarketPolygon) =>
			selectedSubmarkets.some(({ id }) => id === submarketPolygon.properties.id)
		);
	}, [submarketFeatures, filters.submarkets]);

	return {
		submarketFeatures,
		selectedSubmarketFeatures,
	};
};
