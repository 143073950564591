import React from 'react';

import {
	Suggestion,
	SuggestionItem as SuggestionItemDTO,
} from 'api/suggestions/suggestions';
import { SuggestionItemImpl } from './SuggestionItemImpl';
import {
	SuggestionMapMarkerIcon,
	SuggestionSearchIcon,
	SuggestionAssetTypeIcon,
} from './SuggestionItem.styles';

const Property = SuggestionItemImpl(
	SuggestionMapMarkerIcon,
	'View property',
	true
);
const Nearby = SuggestionItemImpl(SuggestionMapMarkerIcon, 'View nearby comps');
const Search = SuggestionItemImpl(SuggestionSearchIcon);
const Submarket = SuggestionItemImpl(SuggestionSearchIcon, 'View submarket');
const AssetType = SuggestionItemImpl(SuggestionAssetTypeIcon);

export type SuggestionItemProps = {
	index: number;
	suggestion: Suggestion;
	isSelected: boolean;
	search: string;
	suggestionItem: SuggestionItemDTO;
	onConfirmSelection: (newIndex: number) => void;
	onAddPortfolio: () => void;
};

const SuggestionItem = (props: SuggestionItemProps) => {
	switch (props.suggestion.field) {
		case 'buildingName':
		case 'buildingAddressAndCity':
			return <Property {...props} />;
		case 'places':
			return <Nearby {...props} />;
		case 'submarket':
			return <Submarket {...props} />;
		case 'propertyTypeOrSubtype':
		case 'spaceTypeOrPropertySubtype':
		case 'spaceTypeOrSpaceSubtype':
			return <AssetType {...props} />;
		default:
			return <Search {...props} />;
	}
};

export default SuggestionItem;
