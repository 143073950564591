import React from 'react';
import { connect } from 'react-redux';

import wrapActions from 'util/actionWrapper';
import * as headerNotificationActions from 'Components/CookiesNotification/actions';
import CookiesPolicyComponent from 'Components/CookiesNotification';

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function CookiesPolicyNotification(props) {
	return (
		<CookiesPolicyComponent
			onShow={props.headerNotificationActions.showHeaderNotification}
			close={props.headerNotificationActions.hideHeaderNotification}
			{...props}
		/>
	);
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return wrapActions(
		{
			headerNotificationActions,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(CookiesPolicyNotification);
