import { SortDirection } from 'api';
import { PropertyWithSummary } from 'PortfolioAnalytics/hooks/usePropertiesWithSummaries';
import { useMemo } from 'react';
import { SortField } from './types';

type Props = {
	propertiesWithSummaries: PropertyWithSummary[];
	sortField: SortField;
	sortDirection: SortDirection;
};

export const useSortedPropertiesWithSummaries = ({
	propertiesWithSummaries,
	sortField,
	sortDirection,
}: Props) => {
	const sortedPortfolios = useMemo(() => {
		const sortedAsc = propertiesWithSummaries.toSorted(SORT_MAP[sortField]);

		return sortDirection === 'desc' ? sortedAsc.reverse() : sortedAsc;
	}, [propertiesWithSummaries, sortField, sortDirection]);

	return sortedPortfolios;
};

const sortByAddress: SortFunction = (p1, p2) => {
	return p1.buildingAddressAndCity.localeCompare(p2.buildingAddressAndCity);
};

const sortByActiveLeases: SortFunction = (p1, p2) =>
	p1.activeLeaseCount - p2.activeLeaseCount;

const SORT_MAP: Record<SortField, SortFunction> = {
	buildingAddressAndCity: sortByAddress,
	activeLeaseCount: sortByActiveLeases,
};

type SortFunction = (
	property1: PropertyWithSummary,
	property2: PropertyWithSummary
) => number;
