import React from 'react';

import Menu from 'Singletons/Menu';

import { getExpirationQuarter, getSpaceClassName } from '../util';
import number from 'ui/util/number';

import styles from '../stacking.less';
import multiSpaceStyles from './multiSpace.less';
import popupStyles from 'Components/Popup/popup.less';

export class SpaceMenu extends React.PureComponent {
	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	edit = (space) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.openSpaceEditor(space);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	remove = (space) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.unplaceSingleSpace(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			space
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	render() {
		return (
			<div className={multiSpaceStyles.container}>
				<table>
					<thead>
						<tr>
							<td />
							<td>Tenant</td>
							<td>Size</td>
							<td>Expiration Date</td>
							<td />
							<td />
						</tr>
					</thead>
					<tbody>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'spaces' does not exist on type 'Readonly... Remove this comment to see the full error message */}
						{this.props.spaces.map((space) => (
							<tr key={space.id}>
								<td>
									<span
										className={
											styles[getSpaceClassName(space)] +
											' ' +
											multiSpaceStyles.yearsIndicator
										}
									/>
								</td>
								<td className={multiSpaceStyles.tenantName}>{space.tenant}</td>
								<td>{number(space.size)} SF</td>
								<td>{getExpirationQuarter(space)}</td>
								<td>
									<a onClick={() => this.edit(space)}>Edit</a>
								</td>
								<td>
									<a onClick={() => this.remove(space)}>Remove</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

Menu.addComponent('stacking-plan-multi-space-menu', SpaceMenu, {
	className: popupStyles.compactWhite,
});

export default 'stacking-plan-multi-space-menu';
