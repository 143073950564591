import { nullValueText } from '@compstak/common';
import { Flex, Spinner } from '@compstak/ui-kit';
import { useGetUserSubmissionDetails } from 'api/submissions/submissionQueries';
import { useVersionsWithPromotions } from 'api/versions/useVersionsWithPromotions';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'router';
import styled from 'styled-components';
import DownArrow from '../../../../ui/svg_icons/arrow_bottom.svg';
import { CategoryPills } from './CategoryPills';
import { COMPS_PER_PAGE, NoCreditsContactLink } from './submissionDetails';
import { SubmissionCategory } from './submissionTypes';

type Props = {
	submissionId: number;
	currentPage: number;
	showMore: () => void;
};

export const SubmissionDetailsVersions = (props: Props) => {
	const flags = useFeatureFlags();

	return flags.TargetedPromotionIterationsAppsPhase2 ? (
		<SubmissionDetailsVersionsV2 {...props} />
	) : (
		<SubmissionDetailsSubRow {...props} />
	);
};

export const SubmissionDetailsVersionsV2 = ({
	submissionId,
	currentPage,
	showMore,
}: Props) => {
	const {
		data: submissionDetails,
		isFetching: isFetchingSubmissionDetails,
		isError: iisErrorSubmissionDetails,
	} = useGetUserSubmissionDetails(submissionId);

	const versionIds = useMemo(() => {
		return submissionDetails?.map((s) => s.id);
	}, [submissionDetails]);

	const {
		data: versionsWithPromotions,
		isFetching: isFetchingVersionsWithPromotions,
		isError: isErrorVersionsWithPromotions,
	} = useVersionsWithPromotions({
		type: submissionDetails?.[0]?.dataType,
		versionIds,
	});

	const detailsWithPromotions = useMemo(() => {
		if (!submissionDetails || !versionsWithPromotions) return [];
		return submissionDetails.map((d) => ({
			...d,
			promotion: versionsWithPromotions.versionsWithPromotions.find(
				(v) => v.versionId === d.id
			)?.promotion,
		}));
	}, [submissionDetails, versionsWithPromotions]);

	const isFetching =
		isFetchingSubmissionDetails || isFetchingVersionsWithPromotions;

	const isError = iisErrorSubmissionDetails || isErrorVersionsWithPromotions;

	if (isFetching || isError) {
		return (
			<Flex
				justifyContent="center"
				className="container"
				style={{ padding: 4 }}
			>
				{isError ? 'Failed to load submission details' : <Spinner />}
			</Flex>
		);
	}

	if (!Array.isArray(submissionDetails) || submissionDetails.length === 0) {
		return (
			<div className="credits-submission-empty">
				<h3>This submission doesn’t contain any relevant Comps</h3>
				<p>Usually this is because:</p>
				<ul>
					<li>
						This submission contains property listings, not lease or sales comps
					</li>
					<li>The addresses in the submission could not be verified</li>
				</ul>
				<NoCreditsContactLink />
			</div>
		);
	}

	return (
		<>
			<div>
				<Header className={'credits-table-row'}>
					<HeaderCell className="credits-cell col-4-12">
						<span className="number">#</span>
						Address
					</HeaderCell>
					<HeaderCell className="credits-cell col-2-12">Marked As</HeaderCell>
					<HeaderCell className="credits-cell col-3-12">
						Part of Promotion
					</HeaderCell>
					<HeaderCell className="credits-cell col-1-12 align-right">
						Earned
					</HeaderCell>
				</Header>
				{detailsWithPromotions
					.slice(0, currentPage * COMPS_PER_PAGE)
					.map((comp, i) => {
						return (
							<VersionRow
								key={comp.id}
								className={
									'credits-table-row ' +
									(isRecent(comp.category) ? 'recent' : '')
								}
							>
								<div className="credits-cell col-4-12">
									<div className="ellipsis">
										<span className="number">{i + 1}.</span>
										{comp.address}
									</div>
								</div>
								<div className="credits-cell col-2-12">
									<CategoryPills category={comp.category} />
								</div>
								<div className="credits-cell col-3-12">
									{comp.promotion ? (
										<PromoLink
											to={routes.promotions.toHref(undefined, {
												'promo-id': comp.promotion.id,
											})}
										>
											{comp.promotion.title}
										</PromoLink>
									) : (
										nullValueText
									)}
								</div>
								<div className="credits-cell col-1-12 align-right">
									{comp.points}
								</div>
							</VersionRow>
						);
					})}
			</div>
			{submissionDetails &&
			currentPage * COMPS_PER_PAGE < submissionDetails.length ? (
				<button
					className="container show-more-btn-row"
					onClick={showMore}
					data-qa-id="credit-ledger-show-more-comps-button"
				>
					<span className="show-more-btn-label">
						Show More <StyledDownArrow />
					</span>
				</button>
			) : (
				false
			)}
		</>
	);
};

const Header = styled.div`
	&&& {
		background-color: ${(p) => p.theme.colors.neutral.n10};
		&:hover {
			color: ${(p) => p.theme.colors.gray.gray700};
		}
	}
`;

const HeaderCell = styled.div`
	&&& {
		text-transform: uppercase;
		font-weight: 500;
		font-size: 0.75rem;
		line-height: 1.5;
	}
`;

const PromoLink = styled(Link)`
	font-size: 0.875rem;
	line-height: 1.5;
	text-decoration: underline;
	color: ${(p) => p.theme.colors.blue.blue400};
	&:hover {
		text-decoration: underline;
	}
`;

const VersionRow = styled.div`
	&&& {
		background-color: ${(p) => p.theme.colors.neutral.n0};
	}
`;

function SubmissionDetailsSubRow({
	submissionId,
	currentPage,
	showMore,
}: {
	submissionId: number;
	currentPage: number;
	showMore: () => void;
}) {
	const {
		data: submissionDetails,
		isLoading,
		isError,
	} = useGetUserSubmissionDetails(submissionId);

	if (isLoading || isError) {
		return (
			<Flex
				justifyContent="center"
				className="container"
				style={{ padding: 4 }}
			>
				{isError ? 'Failed to load submission details' : <Spinner />}
			</Flex>
		);
	}

	if (!Array.isArray(submissionDetails) || submissionDetails.length === 0) {
		return (
			<div className="credits-submission-empty">
				<h3>This submission doesn’t contain any relevant Comps</h3>
				<p>Usually this is because:</p>
				<ul>
					<li>
						This submission contains property listings, not lease or sales comps
					</li>
					<li>The addresses in the submission could not be verified</li>
				</ul>
				<NoCreditsContactLink />
			</div>
		);
	}
	return (
		<>
			<ol>
				{submissionDetails
					.slice(0, currentPage * COMPS_PER_PAGE)
					.map((comp, i) => (
						<li key={comp.id}>
							<div
								className={
									'credits-table-row ' +
									(isRecent(comp.category) ? 'recent' : '')
								}
							>
								<div className="credits-cell col-6-12">
									<div className="ellipsis">
										<span className="number">{i + 1}.</span>
										{comp.address}
									</div>
								</div>
								<div className="credits-cell col-3-12">
									<CategoryPills category={comp.category} />
								</div>
								<div className="credits-cell col-1-12 align-right">
									{comp.points}
								</div>
							</div>
						</li>
					))}
			</ol>
			{submissionDetails &&
			currentPage * COMPS_PER_PAGE < submissionDetails.length ? (
				<button
					className="container show-more-btn-row"
					onClick={showMore}
					data-qa-id="credit-ledger-show-more-comps-button"
				>
					<span className="show-more-btn-label">
						Show More <StyledDownArrow />
					</span>
				</button>
			) : (
				false
			)}
		</>
	);
}

const isRecent = (category: SubmissionCategory) => {
	switch (category) {
		case 'BASIC':
		case 'PREMIUM':
			return true;
		default:
			return false;
	}
};

const StyledDownArrow = styled(DownArrow)`
	width: 12px;
	height: 12px;
	display: inline;
	fill: ${({ theme }) => theme.colors.neutral.n85};
`;
