import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'sublease' as const;

export const SubleaseFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<ChoiceSwitcher
					{...props}
					attribute={attribute}
					options={[
						{
							title: 'Yes',
							value: 'true',
						},
						{
							title: 'No',
							value: 'false',
						},
					]}
				/>
			)}
		</FilterBase>
	);
};
