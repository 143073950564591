import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { CheckExportStatusParams, CheckExportStatusResponse } from '../types';
import { createFetchFn } from '../utils';

const createFetchStatus = createFetchFn<
	CheckExportStatusParams,
	CheckExportStatusResponse
>({
	method: 'post',
	getURL: () => `/api/properties/checkExportStatus`,
	getBody: (params) => params,
});

export const useCheckPropertiesExportStatusQuery = (
	params: CheckExportStatusParams,
	options?: UseQueryOptions<CheckExportStatusResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.CHECK_PROPERTIES_EXPORT_STATUS, params],
		queryFn: createFetchStatus(params),
		...options,
	});
};
