import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { UnlockButton } from './UnlockButton';
import { UnlockButtonTooltip } from './UnlockButtonTooltip';
import { useUnlockButtonLongText } from './useUnlockButtonLongText';
import { useUnlockComps } from './useUnlockComps';

type Props = {
	type: 'lease' | 'sale';
	comps: { id: number; cost: number }[];
};

export const UnlockButtonTable = ({ type, comps }: Props) => {
	const { unlock, disabled, isLoading } = useUnlockComps({ type, comps });

	const text = useUnlockButtonLongText({ comps });

	return (
		<UnlockButtonTooltip comps={comps}>
			<UnlockButton
				disabled={disabled}
				isLoading={isLoading}
				onClick={() => {
					unlock({ ids: comps.map((c) => c.id) });
				}}
			>
				<LockIconStyled /> {text}
			</UnlockButton>
		</UnlockButtonTooltip>
	);
};
