import { Spinner } from '@compstak/ui-kit';
import {
	Portfolio,
	PortfolioMarketsSummaryResponse,
	SPACE_TYPE_NAME_TO_ID,
	usePortfolioMarketsSummaryQuery,
} from 'api';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';
import DownloadButton from 'PortfolioAnalytics/Singletons/DownloadButton';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import {
	ChartContainer,
	MarketsHeading,
	MarketsHeadingWrapper,
	TabCardItem,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { Spacer } from 'PortfolioAnalytics/UI';
import React, { useEffect, useState } from 'react';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { MarketSummaryPortfolioLeaseExpirationsBySpaceTypesByYears } from '../portfolioAnalyticsTypes';
import { FiltersState } from '../PortfolioFiltersProvider';
import Chart, { ChartOnClickHandlerProps } from './Chart';
import Dropdown from './Dropdown';
import Legend from './Legend';
import * as Styled from './styled';
import { useNavigate } from 'react-router';
import { routes } from 'router';

const LEASE_EXPIRATION_CHART_NAME = 'Lease Expiration By Space Type';

export const Colors = {
	Office: PORTFOLIO_CHART_COLORS[0],
	Retail: PORTFOLIO_CHART_COLORS[1],
	Industrial: PORTFOLIO_CHART_COLORS[2],
	'Flex/R&D': PORTFOLIO_CHART_COLORS[3],
	Land: PORTFOLIO_CHART_COLORS[4],
	Other: PORTFOLIO_CHART_COLORS[5],
	Unknown: PORTFOLIO_CHART_COLORS[6],
};

const ALL_SPACE_TYPES = Object.keys(SPACE_TYPE_NAME_TO_ID);

const sortDataHandler = (unsortedData: { [spaceType: string]: unknown }) => {
	const sortedData = {};
	const dataKeys = Object.keys(unsortedData);
	ALL_SPACE_TYPES.filter((spaceType) => dataKeys.includes(spaceType)).forEach(
		(key) => {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			sortedData[key] = unsortedData[key];
		}
	);

	return sortedData;
};

const currentYear = new Date().getFullYear();
const numOfYears = 5;
const validYears = Array.from(Array(numOfYears).keys()).map(
	(n) => `${currentYear + n}`
);

type LeaseExpirationProps = {
	portfolio: Portfolio;
	filters: FiltersState;
};

const LeaseExpiration = ({ portfolio, filters }: LeaseExpirationProps) => {
	const mainRef = React.useRef<null | HTMLDivElement>(null);
	const [spaceTypeFilter, setSpaceTypeFilter] = useState<string[]>([]);

	const { data: marketsSummary, isLoading: isMarketsSummaryLoading } =
		usePortfolioMarketsSummaryQuery({
			...filters,
			marketIds: portfolio.markets.map((m) => m.marketId),
		});

	const navigate = useNavigate();

	const parsedDataBySpaceType = React.useMemo(() => {
		const rawResult = portfolio.leaseExpirationsBySpaceTypesByYears.reduce(
			(acc, { expirationYear, spaceType, expiringLeaseCount }) => {
				const isHavingExpiringLeasesInValidYears =
					validYears.includes(`${expirationYear}`) && expiringLeaseCount > 0;

				if (isHavingExpiringLeasesInValidYears) {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					acc[spaceType] = acc[spaceType]
						? {
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								...acc[spaceType],
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								[expirationYear]: acc[spaceType][expirationYear]
									? {
											// @ts-expect-error TS7053: Element implicitly has an 'any...
											...acc[spaceType][expirationYear],
											expiringLeaseCount:
												// @ts-expect-error TS7053: Element implicitly has an 'any...
												acc[spaceType][expirationYear].expiringLeaseCount +
												expiringLeaseCount,
										}
									: {
											spaceType: spaceType,
											expirationYear,
											expiringLeaseCount,
											color: Colors[spaceType],
										},
							}
						: {
								[expirationYear]: {
									spaceType,
									expirationYear,
									expiringLeaseCount,
									color: Colors[spaceType],
								},
							};
				}
				return acc;
			},
			{}
		);

		return sortDataHandler(rawResult);
	}, [portfolio]);

	const dropdownData = React.useMemo(
		() => Object.keys(parsedDataBySpaceType || {}),
		[parsedDataBySpaceType]
	);

	useEffect(() => {
		setSpaceTypeFilter(dropdownData);
	}, [dropdownData]);

	const chartData = React.useMemo(() => {
		if (!spaceTypeFilter || !parsedDataBySpaceType || !marketsSummary) {
			return [];
		}

		const leaseExpirationsBySpaceTypesByYears =
			getMarketSummaryLeaseExpirationsBySpaceTypesByYears(marketsSummary);
		const leaseExpirationsSpaceTypesKeys = Object.keys(
			leaseExpirationsBySpaceTypesByYears
		);
		const parsedDataBySpaceTypeKeys = Object.keys(parsedDataBySpaceType);

		const existingKeys = parsedDataBySpaceTypeKeys.filter(
			(key) =>
				spaceTypeFilter.includes(key) &&
				leaseExpirationsSpaceTypesKeys.includes(key)
		);

		const getChartDataByKey = (spaceType: string) => {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			const portfolioDataItem = parsedDataBySpaceType[spaceType];

			return Object.keys(portfolioDataItem).map((expirationYear) => {
				const { expiringLeaseCount, color } = portfolioDataItem[expirationYear];

				const yData =
					(expiringLeaseCount /
						leaseExpirationsBySpaceTypesByYears[spaceType][expirationYear]) *
					100;
				return {
					x: expirationYear,
					y: Math.round(yData * 100) / 100,
					color,
					spaceType,
				};
			});
		};

		return existingKeys.map(getChartDataByKey);
	}, [parsedDataBySpaceType, spaceTypeFilter, marketsSummary]);

	const legendData = React.useMemo(() => {
		return ALL_SPACE_TYPES.filter((spaceType) =>
			spaceTypeFilter.includes(spaceType)
		).map((spaceType) => ({
			label: spaceType,
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			color: Colors[spaceType],
		}));
	}, [spaceTypeFilter]);

	const onChartClickHandler = React.useCallback(
		({ expirationYear, spaceType }: ChartOnClickHandlerProps) => {
			navigate(
				routes.portfolioByIdView.toHref(
					{ portfolioId: portfolio.id, viewType: 'list' },
					{
						spaceTypes: [spaceType],
						expirationYears: [expirationYear],
					}
				)
			);
		},
		[navigate, portfolio.id]
	);

	const downloadBannerId = LEASE_EXPIRATION_CHART_NAME.replaceAll(' ', '');

	const noData = chartData.length === 0;

	return (
		<TabCardItem ref={mainRef}>
			<Styled.Container>
				<MarketsHeadingWrapper marginBottom={10}>
					<MarketsHeading>
						<h4>{LEASE_EXPIRATION_CHART_NAME}</h4>
						<PortfolioHelpIcon tooltip={LEASE_EXPIRATION_TOOLTIP} />
					</MarketsHeading>
					<DownloadButton
						downloadRef={mainRef}
						filename={`${LEASE_EXPIRATION_CHART_NAME} - ${portfolio?.name}`}
						disabled={isMarketsSummaryLoading || !chartData.length}
						bannerId={downloadBannerId}
						data-qa-id="download-button-pa-lease-expiration-chart"
					/>
				</MarketsHeadingWrapper>
				<Styled.Spacer />
				{isMarketsSummaryLoading ? (
					<ChartContainer>
						<Spinner size="xl" />
					</ChartContainer>
				) : (
					<>
						<Dropdown
							data={dropdownData}
							activeData={spaceTypeFilter}
							onChange={setSpaceTypeFilter}
							data-qa-id="dropdown-pa-lease-expiration-chart"
						/>
						{noData ? (
							<ChartContainer>
								<DataNotAvailableMessage
									chartName={LEASE_EXPIRATION_CHART_NAME}
								/>
							</ChartContainer>
						) : (
							<>
								<Chart
									data={chartData}
									xAxisLabels={validYears}
									onClick={onChartClickHandler}
									data-qa-id="chart-pa-lease-expiration-chart"
								/>
								<Legend data={legendData} />
							</>
						)}
					</>
				)}
			</Styled.Container>
			<DownloadBranding bannerId={downloadBannerId} />
		</TabCardItem>
	);
};

export default LeaseExpiration;

const LEASE_EXPIRATION_TOOLTIP = (
	<>
		<Spacer>
			The Market Lease Expiration Overview compares how many leases grouped by
			space type are expiring in your portfolio compared to the number of leases
			grouped by space type are expiring in the market over the next 5 years.
		</Spacer>
		<div>
			Changing the filters at the top of the page will filter down which
			properties you see reflected here.
		</div>
	</>
);

const getMarketSummaryLeaseExpirationsBySpaceTypesByYears = (
	marketsSummary: PortfolioMarketsSummaryResponse
): MarketSummaryPortfolioLeaseExpirationsBySpaceTypesByYears =>
	marketsSummary.leaseExpirationsBySpaceTypesByYears.reduce<MarketSummaryPortfolioLeaseExpirationsBySpaceTypesByYears>(
		(acc, { expirationYear, spaceType, expiringLeaseCount }) => {
			if (acc[spaceType]) {
				acc[spaceType][expirationYear] = acc[spaceType][expirationYear]
					? acc[spaceType][expirationYear] + expiringLeaseCount
					: expiringLeaseCount;
			} else {
				acc[spaceType] = {
					[expirationYear]: expiringLeaseCount,
				};
			}
			return acc;
		},
		{}
	);
