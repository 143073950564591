import { TopTenantIndustry } from 'api';
import { TENANT_INDUSTRY_COLORS } from 'constants/colors';
import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import {
	createTopTenantIndustryNode,
	LOADING_TREEMAP,
	TreemapContainer as OriginalTreemapContainer,
} from 'ExchangeDashboard/TenantIndustryDistribution';
import { Treemap } from 'ExchangeDashboard/TenantIndustryDistribution/Treemap';
import { sortBy } from 'lodash';
import useDimensions from 'react-use-dimensions';
import styled from 'styled-components';
import { PanelDescription, PDFPanel } from './UI';

export const TenantIndustryDistribution = () => {
	const { data, isFetching } = useExchangeDashboardTenantIndustryDistribution();
	const [panelRef, dimensions] = useDimensions();

	return (
		<PDFPanel
			ref={panelRef}
			id="tenant-industry-distribution"
			title="Tenant Industry Distribution"
		>
			{isFetching ? (
				<div>
					<TreemapContainer>
						<div className="container">
							<Treemap
								width={(dimensions?.width ?? 42) - 42}
								height={(dimensions?.height ?? 90) - 90}
								margin={2}
								data={{
									children: LOADING_TREEMAP,
								}}
								getSize={(node) => node.data?.size ?? 0}
								sortFunction={(n1, n2) =>
									(n2.data?.size ?? 0) - (n1.data?.size ?? 0)
								}
							/>
						</div>
					</TreemapContainer>
				</div>
			) : data && data.length > 0 ? (
				<div>
					<TreemapContainer>
						<div className="container">
							<Treemap
								width={(dimensions?.width ?? 42) - 42}
								height={400}
								margin={2}
								data={{
									children: data.map(createTopTenantIndustryNode),
								}}
								getSize={(node) => node.data?.size ?? 0}
								sortFunction={(n1, n2) =>
									(n2.data?.size ?? 0) - (n1.data?.size ?? 0)
								}
							/>
						</div>
					</TreemapContainer>
				</div>
			) : (
				<div>No Data To Display. Update Filters and Try Again.</div>
			)}
			{data && data.length > 0 && (
				<>
					<Legend data={data} />
					<PanelDescription>
						Top ten industries based on total square footage in selected market
						and submarket(s).
					</PanelDescription>
				</>
			)}
		</PDFPanel>
	);
};

const TreemapContainer = styled(OriginalTreemapContainer)`
	.container {
		min-height: 435px;
	}
`;

const Legend = (props: { data: TopTenantIndustry[] }) => {
	const { data } = props;
	return (
		<LegendContainer>
			<ul>
				{sortBy(data, 'sqft')
					.reverse()
					.map((ti, idx) => (
						<li key={idx} data-tooltip={ti.industry}>
							<span
								style={{
									backgroundColor: `rgb(${
										// @ts-expect-error TS7053: Element implicitly has an 'any...
										TENANT_INDUSTRY_COLORS[ti.industry]
									})`,
								}}
							/>
							<div className="industry-name">{ti.industry}</div>
						</li>
					))}
			</ul>
		</LegendContainer>
	);
};

const LegendContainer = styled.div`
	width: 100%;
	padding: 8px;
	background: rgba(255, 255, 255, 0.9);
	font-family: Gotham;
	h4 {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.87);
		margin-bottom: 5px;
	}
	ul {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	ul li {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 49%;
		span {
			width: 10px;
			height: 10px;
			border-radius: 10px;
			margin-right: 10px;
		}
		.industry-name {
			font-size: 12px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			flex: 1;
		}
	}
`;
