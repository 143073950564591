import { TooltipV2 } from '@compstak/ui-kit';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ArrowBottom from '../../ui/svg_icons/arrow_bottom.svg';
import SearchIcon from '../../ui/svg_icons/search.svg';

type Props = {
	filterName: string;
	contentName: string;
	width: number;
	active: boolean;
	readOnly?: boolean;
	onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
	tooltip?: string;
	filterRef?: React.RefObject<HTMLDivElement>;
	children?: ReactNode;
};

export const FilterItemComponenet = (props: Props) => (
	<FilterItemContainer ref={props.filterRef}>
		<TooltipV2 content={props.tooltip ?? undefined}>
			<FilterItem
				width={props.width}
				onClick={props.onClick}
				data-qa-id="pa-filter-item"
			>
				<FilterItemNameContainer>
					<FilterItemName active={props.active}>
						{props.filterName}
					</FilterItemName>
					<FilterItemContentName>{props.contentName}</FilterItemContentName>
				</FilterItemNameContainer>
				{!props.readOnly && <FilterArrowBottom active={props.active} />}
			</FilterItem>
		</TooltipV2>
		{props.children}
	</FilterItemContainer>
);

type FilterArrowBottomProps = {
	active: boolean;
	onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
};

export const FilterArrowBottom = (props: FilterArrowBottomProps) => (
	<FilterArrow
		onClick={props.onClick}
		active={props.active}
		data-qa-id="pa-filter-item-arrow"
	>
		<StyledArrowBottomIcon />
	</FilterArrow>
);

type CheckboxItemProps = {
	id: string;
	checked: boolean;
	onChange: (event?: React.FormEvent<HTMLInputElement>) => void;
	fontSize?: number;
	height?: number;
	label: string;
	intermediate?: boolean;
};

export const CheckboxItem = (props: CheckboxItemProps) => (
	<CheckboxItemContainer height={props.height}>
		<input
			id={props.id}
			type="checkbox"
			className="checkbox"
			checked={props.checked}
			onChange={props.onChange}
			data-qa-id="pa-filter-checkbox-item"
		/>
		<label
			className={props.intermediate ? 'checkbox intermediate' : 'checkbox'}
			htmlFor={props.id}
			style={{
				fontSize: props.fontSize ? props.fontSize : 16,
				fontWeight: props.checked ? 500 : 400,
			}}
		>
			{props.label}
		</label>
	</CheckboxItemContainer>
);

type SearchFieldProps = {
	value: string;
	label: string;
	completion: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (e: React.KeyboardEvent) => void;
	searchRef: React.RefObject<HTMLInputElement>;
};

const searchInputStyle = {
	backgroundColor: 'transparent',
	border: 'none',
	width: 'calc(100% - 1em)',
};

export const SearchField = (props: SearchFieldProps) => (
	<SearchItemContainer>
		<SearchItemLabel>{props.label}</SearchItemLabel>
		<SearchItemIcon>
			<SearchIcon width={13} height={13} />
		</SearchItemIcon>
		<div style={{ position: 'relative' }}>
			<SearchItemInput
				type="text"
				value={props.value + props.completion.slice(props.value.length)}
				readOnly={true}
				style={{ color: 'rgba(0, 0, 0, 0.6)', ...searchInputStyle }}
			/>
			<SearchItemInput
				type="text"
				value={props.value}
				readOnly={true}
				style={{ color: '#F9F9F9', ...searchInputStyle }}
			/>
			<SearchItemInput
				type="text"
				placeholder="Search"
				style={{ ...searchInputStyle }}
				value={props.value}
				onChange={props.onChange}
				onKeyDown={props.onKeyDown}
				ref={props.searchRef}
				data-qa-id="pa-filter-search-item"
			/>
		</div>
	</SearchItemContainer>
);

const FilterItemContainer = styled.div`
	position: relative;
`;

const FilterItem = styled.div<{ width: number; onClick?: () => void }>`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	border: 1px solid #d5d8df;
	border-radius: 3px;
	height: 44px;
	padding: 4px 13px 7px 4px;
	transition: border-color 300ms;
	width: ${(props) => `${props.width}px`};

	&:hover {
		border-color: #5e6579;
	}
`;

const FilterItemNameContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
`;

const FilterItemName = styled.div<{ active: boolean }>`
	font-size: 10px;
	text-transform: uppercase;
	color: ${({ active }) => (active ? '#4290F7' : '#000000')};
`;

const FilterItemContentName = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const FilterArrow = styled.div<{ active: boolean }>`
	${({ active }) => active && 'transform: rotate(180deg);'}
	color: #474d5d;
	margin-top: 9px;
	margin-bottom: 5px;
`;

const CheckboxItemContainer = styled.div<{ height?: number }>`
	height: ${({ height }) => (height ? `${height}px` : '46px')};
	display: flex;
	align-content: center;
	align-items: center;
	padding: 0 12px;
	transition: background-color 120ms;

	&:hover {
		background-color: #f1f2f5;
	}

	label:before {
		top: 2px !important;
	}
`;

const SearchItemContainer = styled.div`
	position: relative;
	background-color: #f9f9f9;
	height: 53px;
	margin: 0 12px;
	border-bottom: 1px solid #4290f7;
	border-radius: 3px 3px 0 0;
`;

const SearchItemIcon = styled.div`
	position: absolute;
	top: 31.5px;
	left: 7.5px;
`;

const SearchItemInput = styled.input`
	position: absolute;
	left: 17px;
	top: 8px;
`;

const SearchItemLabel = styled.div`
	position: absolute;
	top: 6px;
	left: 4px;
	text-transform: uppercase;
	font-size: 10px;
	line-height: 14px;
	color: #4290f7;
`;

export const LeaseExpItem = styled.div<{ active: boolean }>`
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	height: 46px;
	padding: 12px 20px;
	cursor: pointer;
	transition: background-color 300ms;
	font-weight: ${({ active }) => (active ? '500' : '400')};

	&:hover {
		background-color: #f1f2f5;
	}

	${({ active }) =>
		active &&
		`
		&:before {
			content: '';
			display: inline-block;
			width: 22px;
			height: 15px;
			color: transparent;
			background: transparent
				url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2017.1.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2010%2010%22%20enable-background%3D%22new%200%200%2010%2010%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Cpolygon%20fill%3D%22%23545A6A%22%20points%3D%223.201%2C8%200%2C4.846%200.742%2C4.115%203.227%2C6.562%209.285%2C1%2010%2C1.757%20%09%22/%3E%0D%0A%3C/g%3E%0D%0A%3C/svg%3E%0D%0A')
				0 1px no-repeat;
			}
	`}
`;

export const MarketsContainer = styled.div`
	overflow: auto;
	max-height: 350px;
	padding-bottom: 13px;

	scrollbar-width: thin;
	scrollbar-color: #a7abb6;

	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #a7abb6;
		border-radius: 10px;
	}
`;

const StyledArrowBottomIcon = styled(ArrowBottom)`
	width: 10px;
	height: 6px;
	fill: ${(props) => props.theme.colors.gray.gray600};
	margin-top: 4.5px;
`;
