import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery, usePortfolioPropertiesQuery } from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { PortfolioStats } from '../Components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioOptionsButton } from '../PortfolioOptions';
import {
	BackToDashboardArrowLeft,
	BackToDashboardLink,
	MapListLoadingContainer,
	MapViewLink,
	Toolbar,
} from '../UI';
import { usePortfolioTotalActiveLeaseCount } from '../hooks/usePortfolioTotalActiveLeaseCount';
import { ListViewExportButton } from './ListViewExportButton';
import { PortfolioTable } from './PortfolioTable';
import { PortfolioTableLeases } from './PortfolioTableLeases';
import { RemovePropertiesButton } from '../Components/RemovePropertiesButton';
import { usePropertiesListAndMapViewContext } from './PropertiesListAndMapViewsProvider';

type Props = {
	portfolioId: number;
};

export const ListView = ({ portfolioId }: Props) => {
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	const isLeasesList =
		!!searchParams.expirationYears ||
		!!searchParams.tenantNames ||
		!!searchParams.tenantIndustry;

	const { filters } = usePortfolioFilters();

	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	const { data: properties } = usePortfolioPropertiesQuery({
		portfolioId,
		...filters,
	});

	const totalActiveLeaseCount = usePortfolioTotalActiveLeaseCount({
		portfolioId,
	});

	const propertiesSelectionState = usePropertiesListAndMapViewContext();

	if (!portfolio) {
		return (
			<MapListLoadingContainer>
				<Spinner />
			</MapListLoadingContainer>
		);
	}

	return (
		<Root>
			<Toolbar>
				<div>
					<BackToDashboardLink
						to={routes.portfolioByIdView.toHref({
							portfolioId: portfolioId,
							viewType: 'dashboard',
						})}
					>
						<BackToDashboardArrowLeft />
						Back to Dashboard
					</BackToDashboardLink>
				</div>
				<BottomContainer>
					<ControlsContainer>
						{!isLeasesList && <PortfolioOptionsButton portfolio={portfolio} />}
						<ListViewExportButton type={isLeasesList ? 'lease' : 'property'} />
						{!isLeasesList && (
							<RemovePropertiesButton
								selectionState={propertiesSelectionState}
							/>
						)}
					</ControlsContainer>
					<ControlsContainer>
						{properties && (
							<PortfolioStats
								portfolioId={portfolioId}
								portfolioName={portfolio.name}
								propertyCount={properties.length}
								leaseCount={totalActiveLeaseCount}
							/>
						)}
						<MapViewLink
							to={routes.portfolioByIdView.toHref({
								portfolioId,
								viewType: 'map',
							})}
						/>
					</ControlsContainer>
				</BottomContainer>
			</Toolbar>
			<>{isLeasesList ? <PortfolioTableLeases /> : <PortfolioTable />}</>
		</Root>
	);
};

const LIST_VIEW_TOOLBAR_HEIGHT = 130;

const Root = styled.div`
	display: grid;
	grid-template-rows: ${LIST_VIEW_TOOLBAR_HEIGHT}px auto;
	box-sizing: border-box;
	height: 100%;
	* {
		box-sizing: border-box;
	}
`;

const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 1rem;
	gap: 0.5rem;
`;

const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;
