import styled from 'styled-components';
import { PropertyComp } from 'types';

export function SurveyPropertyAddress({
	property: {
		buildingName,
		marketDisplayName,
		submarket,
		buildingAddress,
		city,
		state,
		zip,
	},
}: {
	property: PropertyComp;
}) {
	const line2 = (() => {
		const line2Left = city ?? '';
		const line2Right = `${state ?? ''} ${zip ?? ''}`.trim();
		const line2Seperator = line2Left && line2Right ? ', ' : '';
		return `${line2Left}${line2Seperator}${line2Right}`;
	})();
	return (
		<div>
			<MarketLine>
				{marketDisplayName} {submarket && `(${submarket})`}
			</MarketLine>
			<AddressLineMain>{buildingAddress}</AddressLineMain>
			<AddressLines>
				<div>{line2}</div>
				{buildingName && <div>{buildingName}</div>}
			</AddressLines>
		</div>
	);
}

const MarketLine = styled.div`
	color: ${(props) => props.theme.colors.neutral.n100};
	font-size: 14px;
`;

const AddressLineMain = styled.b`
	margin-top: 8px;
	font-size: 20px;
	display: block;
`;

const AddressLines = styled.div`
	margin-top: 12px;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral.n600};
`;
