import {
	ModalInputMessage,
	ModalInputLabel,
} from 'Components/Modals/common/UI';
import { usePortfolioForm } from './usePortfolioForm';
import { Actions } from 'PortfolioAnalytics/PortfolioSidebar/PortfolioModal';
import { Flex } from '@compstak/ui-kit';
import { Checkbox } from 'Components';

import styled from 'styled-components';
import IconQuestionMark from '../../ui/svg_icons/question_mark_outline.svg';

type Props = ReturnType<typeof usePortfolioForm> & { action: Actions };

type Option = {
	id: 'every-time' | 'daily-updates' | 'weekly-updates';
	value: string;
};

const emailOptions: Option[] = [
	{
		id: 'every-time',
		value: 'Every time a new comp is added',
	},
	{ id: 'daily-updates', value: 'Daily updates' },
	{ id: 'weekly-updates', value: 'Weekly updates' },
];

export const PortfolioFormFieldsV2 = ({
	errors,
	setIsTouched,
	setState,
	state,
	isTouched,
	action,
}: Props) => {
	const isErrorName = (action === 'copy' || isTouched) && !!errors.name;
	return (
		<div>
			<ModalInputLabel isError={isErrorName}>
				Name <Asterisk>*</Asterisk>
				<input
					value={state.name}
					type="text"
					onChange={(e) => {
						setIsTouched(true);
						setState({ ...state, name: e.currentTarget.value });
					}}
				/>
				{isErrorName && (
					<ModalInputMessage isError>{errors.name}</ModalInputMessage>
				)}
			</ModalInputLabel>

			<ModalInputLabel isError={isTouched && !!errors.description}>
				Description
				<textarea
					value={state.description}
					onChange={(e) => {
						setIsTouched(true);
						setState({ ...state, description: e.currentTarget.value });
					}}
				/>
				{isTouched && !!errors.description && (
					<ModalInputMessage isError>{errors.description}</ModalInputMessage>
				)}
			</ModalInputLabel>

			<Flex justifyContent="flex-start" gap="0.5rem">
				<strong>Notification Preferences</strong>
				<IconQuestionMark />
			</Flex>

			<StyledTable>
				{emailOptions.map((option) => (
					<tr key={option.id}>
						<td>
							<label htmlFor={option.id}>{option.value}</label>
						</td>
						<td>
							<Checkbox
								id={option.id}
								checked={state.checkboxes?.[option.id]}
								onCheckedChange={(checked) => {
									setState((s) => ({
										...s,
										checkboxes: {
											...(s.checkboxes ?? {}),
											[option.id]: checked,
										},
									}));
								}}
							/>
						</td>
					</tr>
				))}
			</StyledTable>
		</div>
	);
};

const Asterisk = styled.span`
	color: ${({ theme }) => theme.colors.red.red500};
`;

const StyledTable = styled.table`
	width: 100%;
	margin: 1rem 0;

	label {
		cursor: pointer;
		width: 100%;
	}
`;
