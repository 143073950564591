import { ExchangePanel, LoadingBox } from './UI';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { VictoryScatter, VictoryLabel } from 'victory';
import { Waypoint } from 'react-waypoint';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import React from 'react';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { useExchangeDashboardLargeTenants } from './hooks/largestTenants/useExchangeDashboardLargeTenants';

export const LargestTenantsChart = () => {
	const [view, setView] = React.useState<'visible' | 'hidden'>('hidden');

	const { fullPageState, setFullPageState } = useExchangeDashboardState();

	const { data: largestLandlordsData, isFetching } =
		useExchangeDashboardLargeTenants({
			enabled: view === 'visible' && fullPageState === null,
		});

	const largestTenantsPlot = largestLandlordsData?.largestTenants.map(
		(tenant) => {
			const formattedTenant =
				tenant.tenant.length > 16
					? `${tenant.tenant.slice(0, 16)}…`
					: tenant.tenant;
			return {
				...tenant,
				label: `${abbreviateNumber(tenant.sqft)} sqft\n${formattedTenant}`,
				x: `${formattedTenant}\n${abbreviateNumber(tenant.sqft)} sqft`,
				y: 1,
			};
		}
	);

	const data = sortBy(largestTenantsPlot ?? [], 'sqft')
		.reverse()
		.filter((d, i) => i < 6);

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				id="largest-tenants"
				title="Largest Tenants"
				cta={
					!isFetching && (
						<AverageIndicator>
							{`${abbreviateNumber(
								largestLandlordsData?.averageSqft
							)} sqft, Average Tenant Size`}
						</AverageIndicator>
					)
				}
			>
				{isFetching && (
					<Row>
						{Array.from(Array(6).keys()).map((_, index) => (
							<div key={index}>
								<LoadingPlot />
								<LoadingStat />
								<LoadingLandlord />
							</div>
						))}
					</Row>
				)}

				{!isFetching &&
					(data.length === 0 ? (
						<div>No Data To Display. Update Filters and Try Again.</div>
					) : (
						<VictoryScatter
							height={150}
							width={800}
							style={{
								data: { cursor: 'pointer', fill: '#228fff' },
								labels: { fontSize: 10 },
							}}
							size={data.length}
							maxBubbleSize={20}
							minBubbleSize={2}
							bubbleProperty="sqft"
							labelComponent={<VictoryLabel dy={60} />}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (
											e: unknown,
											clickedProps: { datum: { tenant: string } }
										) => {
											if (clickedProps.datum.tenant) {
												if (clickedProps.datum.tenant) {
													setFullPageState &&
														setFullPageState({
															type: 'list',
															field: 'tenantName',
															tenantName: clickedProps.datum.tenant,
														});
												}
											}
										},
									},
								},
							]}
							data={data}
						/>
					))}
			</ExchangePanel>
		</Waypoint>
	);
};

export const AverageIndicator = styled.div`
	background: #f9f9f9;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #d5d8df;
	border-radius: 3px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 22px;
	padding: 0 8px;

	color: ${({ theme }) => theme.colors.gray.gray0};
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`;

const LoadingPlot = styled(LoadingBox)`
	margin: 4px;
	height: 80px;
	width: 80px;
`;

const LoadingStat = styled(LoadingBox)`
	margin: 4px;
	height: 22px;
	width: 50px;
`;

const LoadingLandlord = styled(LoadingBox)`
	margin: 4px;
	height: 22px;
	width: 66px;
`;
