import { Button, ErrorMessage, Flex, MQB } from '@compstak/ui-kit';
import styled from 'styled-components';

export function RadioBtn({
	label,
	onChange,
}: {
	label: string;
	onChange: (val: string) => void;
}) {
	return (
		<RadioInputLabel>
			<RadioInput
				onChange={(ev) => onChange(ev.target.value)}
				type="radio"
				name="question"
				value={label}
				data-qa-id={`question-radio-[${label}]`}
			/>
			{label}
		</RadioInputLabel>
	);
}

export function QuestionSidebarBtns({
	onSubmit,
	onSkip,
	submitEnabled,
	submitting,
	submitFailed,
	skipDisabled,
}: {
	onSubmit: () => void;
	onSkip: () => void;
	submitEnabled: boolean;
	submitting?: boolean;
	submitFailed?: boolean;
	skipDisabled?: boolean;
}) {
	return (
		<>
			<Spacer />
			{submitFailed && (
				<BtnsArea marginBottom="8px">
					<ErrorMessage>Something went wrong.</ErrorMessage>
				</BtnsArea>
			)}
			<BtnsArea>
				<Button variant="ghost" disabled={skipDisabled} onClick={onSkip}>
					SKIP QUESTION
				</Button>
				<Button
					disabled={!submitEnabled}
					onClick={onSubmit}
					isLoading={submitting}
				>
					SUBMIT ANSWER
				</Button>
			</BtnsArea>
		</>
	);
}

const RadioInputLabel = styled.label`
	cursor: pointer;
	margin-top: 4px;
	padding-top: 12px;
	@media (min-width: ${MQB.T_834}) {
		padding-top: 4px;
	}
`;
RadioInputLabel.displayName = 'RadioInputLabel';

export const RadioBtns = styled(Flex)`
	margin-top: 8px;
	flex-direction: column;
	${RadioInputLabel} {
		/* Labels should be as wide as the widest label */
		width: 100%;
	}
	@media (min-width: ${MQB.T_834}) {
		margin-top: 32px;
		overflow: auto;
	}
`;
RadioBtns.displayName = 'RadioBtns';

const RadioInput = styled.input`
	margin-right: 16px;
	margin-bottom: 4px;
`;

const Spacer = styled.div`
	flex-grow: 1;
	min-height: 24px;
`;
Spacer.displayName = 'Spacer';

const BtnsArea = styled(Flex)<{ marginBottom?: string }>`
	width: 100%;
	justify-content: flex-end;
	gap: 8px;
	${(p) => (p.marginBottom ? `margin-bottom: ${p.marginBottom};` : '')}
`;
