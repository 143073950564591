import distance from '@turf/distance';
import { Position } from '@turf/helpers';
import { useCallback, useMemo, useState } from 'react';
import { PinFeature } from 'types';
import { createSimpleProvider } from 'utils/createSimpleProvider';

export type MultiSelectState = ReturnType<typeof useMultiSelect>;

export const {
	Provider: MultiSelectProvider,
	useProviderContext: useMultiSelect,
} = createSimpleProvider({
	useValue: () => {
		const [isMultiSelectOn, setIsMultiSelectOn] = useState(false);
		const [selectedPins, setSelectedPins] = useState<Map<number, PinFeature>>(
			new Map()
		);

		const togglePin = useCallback(
			(pinFeature: PinFeature) => {
				setSelectedPins((s) => {
					const propertyId = pinFeature.properties.id;
					if (s.has(propertyId)) {
						s.delete(propertyId);
					} else {
						s.set(propertyId, pinFeature);
					}
					return new Map(s);
				});
			},
			[setSelectedPins]
		);

		const selectedPropertyIds = useMemo(() => {
			return [...selectedPins.keys()];
		}, [selectedPins]);

		const isAnyPinSelected = useMemo(() => {
			return selectedPropertyIds.length !== 0;
		}, [selectedPropertyIds]);

		const isPinSelected = useCallback(
			(propertyId: number) => {
				return selectedPins.has(propertyId);
			},
			[selectedPins]
		);

		const isPinInProximity = useCallback(
			(coordinates: Position, proximityThresholdDistanceInMeters: number) => {
				for (const selectedPin of selectedPins.values()) {
					const distanceBetweenPins = distance(
						selectedPin.geometry.coordinates,
						coordinates,
						{ units: 'meters' }
					);
					if (distanceBetweenPins < proximityThresholdDistanceInMeters) {
						return true;
					}
				}

				return false;
			},
			[selectedPins]
		);

		const resetMultiSelect = useCallback(() => {
			setIsMultiSelectOn(false);
			setSelectedPins(new Map());
		}, []);

		const contextValue = useMemo(() => {
			return {
				isAnyPinSelected,
				isPinSelected,
				isPinInProximity,
				togglePin,
				selectedPropertyIds,
				isMultiSelectOn,
				setIsMultiSelectOn,
				resetMultiSelect,
			};
		}, [
			isPinSelected,
			isPinInProximity,
			togglePin,
			selectedPropertyIds,
			isMultiSelectOn,
			setIsMultiSelectOn,
			resetMultiSelect,
		]);

		return contextValue;
	},
});
