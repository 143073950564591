import { ComponentProps } from 'react';
import styled from 'styled-components';
import CrossIcon from '../../ui/svg_icons/cross.svg';
import GearIcon from '../../ui/svg_icons/gear.svg';

type Props = {
	isMenuOpen: boolean;
} & Omit<ComponentProps<'button'>, 'ref'>;

export const MapAnalyticsSettingsButton = ({ isMenuOpen, ...props }: Props) => {
	const text = isMenuOpen ? 'CLOSE' : 'SETTINGS';
	const icon = isMenuOpen ? (
		<CrossIcon width={8} height={8} />
	) : (
		<GearIcon width={17} height={17} viewBox="0 0 25 25" />
	);

	return (
		<Button data-qa-id="analytics-settings-btn" {...props}>
			{icon}
			{text}
		</Button>
	);
};

const Button = styled.button`
	position: absolute;
	left: 8px;
	bottom: 8px;
	background: #f9f9f9;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 8px;
	align-items: center;
	min-width: 100px;
	height: 30px;
	padding: 4px 12px 4px 8px;
	border: 1px solid #50576d;
	box-sizing: border-box;
	border-radius: 3px;
	font-family: Gotham;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	text-align: center;
	color: rgba(0, 0, 0, 0.87);
`;
