import { Table } from 'fixed-data-table-2';
import styled, { createGlobalStyle } from 'styled-components';

export const ReorderGlobalStyle = createGlobalStyle<{
	activeReorderColumn: string;
}>`
	div[data-column-key="${({ activeReorderColumn }) =>
		activeReorderColumn}"] .public_fixedDataTableCell_hasReorderHandle::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 34px;
		width: 100%;
		height: 2000px;
		max-height: 100vh;
		background: hsl(226, 15%, 22%);
		opacity: 0.05;
	}

	div[data-column-key="${({ activeReorderColumn }) =>
		activeReorderColumn}"] .fixedDataTableCellLayout_columnReorderContainer {
		cursor: grabbing;
	}
	
`;

export const TableStyled = styled(Table)`
	& .public_fixedDataTable_scrollbarSpacer {
		display: none;
	}
`;
