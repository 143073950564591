import React from 'react';

import Cross from '../../../ui/svg_icons/cross.svg';
import styled from 'styled-components';
import styles from './drawer.less';
import button from 'ui/styles/button.less';

export class Container extends React.PureComponent {
	focusInput = () => {
		if (this.inputFocused === false) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'el' does not exist on type 'Container'.
			this.el.querySelector('input').focus();
			this.inputFocused = true;
		}
	};

	inputFocused = false;

	render() {
		return (
			<div
				className={styles.container}
				onTransitionEnd={this.focusInput}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'el' does not exist on type 'Container'.
				ref={(el) => (this.el = el)}
			>
				{/* @ts-expect-error TS2339: Property 'children' does not e... */}
				{this.props.children}
			</div>
		);
	}
}

export class Content extends React.PureComponent {
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	checkForEnterAndEscape = (event) => {
		if (event.keyCode === 13) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.onSave();
		}

		if (event.keyCode === 27) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onCancel' does not exist on type 'Readon... Remove this comment to see the full error message
			if (!this.props.onCancel || this.props.onCancel() !== false) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'onCancel' does not exist on type 'Readon... Remove this comment to see the full error message
				this.props.onCancel();
			}
		}
	};

	render() {
		return (
			<div className={styles.content} onKeyUp={this.checkForEnterAndEscape}>
				{/* @ts-expect-error TS2339: Property 'children' does not e... */}
				{this.props.children}
			</div>
		);
	}
}

// @ts-expect-error TS7031: Binding element 'children' imp...
export function Header({ children, onCancel }) {
	return (
		<div className={styles.header}>
			<span className={styles.text}>{children}</span>
			<StyledCrossIcon onClick={onCancel} />
		</div>
	);
}

// @ts-expect-error TS7031: Binding element 'disabled' imp...
export function Buttons({ disabled, onCancel, onSave }) {
	const buttonStyle = disabled ? 'disabled' : 'blue';

	return (
		<div className={styles.buttonContainer}>
			<div className={button.button} onClick={onCancel}>
				Cancel
			</div>
			<div className={button[buttonStyle]} onClick={onSave}>
				Save
			</div>
		</div>
	);
}

const StyledCrossIcon = styled(Cross)`
	width: 18px;
	height: 18px;
	fill: ${({ theme }) => theme.colors.white.white};
	position: absolute;
	top: 22px;
	right: 15px;
	cursor: pointer;
`;
