import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

export const getDisabledTooltip = (type: 'email' | 'username') =>
	renderToStaticMarkup(
		<Container>
			Please reach out to <Email>help@compstak.com</Email> if you need to change
			the {type} for your account.
		</Container>
	);

const Container = styled.div`
	width: 100%;
	font-family: Gotham;
	font-style: normal;
	font-weight: 0;
	font-size: 11px;
	letter-spacing: 0.3px;
	color: #ffffff;
	border-radius: 3px;
`;

const Email = styled.span`
	color: #84e1ff;
	text-decoration: underline;
`;
