import { CenterOnPoint } from '@compstak/maps';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useAppSelector } from 'util/useAppSelector';
import { CenterOnCountyOrApnEffect } from './CenterOnCountyOrApnEffect';
import { CenterOnMarketsEffect } from './CenterOnMarketsEffect';
import { CenterOnRadiusFilterEffect } from './CenterOnRadiusFilterEffect';

export const CenterEffects = () => {
	const [filters] = useFilters();
	const propertyPopup = useAppSelector(
		(s) => s.mapSearchResultsV2.propertyPopup
	);

	const markets = useMemo(
		() => getFiltersMarkets(filters),
		[filters.market, filters.markets]
	);

	return (
		<>
			<CenterOnMarketsEffect markets={markets} />
			{propertyPopup && (
				<CenterOnPoint
					longitude={propertyPopup.coordinates[0]}
					latitude={propertyPopup.coordinates[1]}
					zoom={propertyPopup.zoom}
				/>
			)}
			<CenterOnRadiusFilterEffect radiusFilter={filters.radius} />
			<CenterOnCountyOrApnEffect filters={filters} />
		</>
	);
};
