import React from 'react';

import { DropTarget } from 'react-dnd';
import { PLACED } from '../DragTypes';

import PendingSpace from '../Spaces/Unplaced';
import { getTenant } from '../util';

import styles from '../stacking.less';

import Search from '../../../ui/svg_icons/search.svg';

const types = PLACED;
const spec = {
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	drop: function (props) {
		props.stackingPlanActions.saveUpdatedPlan(
			props.stackingPlan,
			false,
			props.originalStackingPlan
		);
	},
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	hover: function (props, monitor) {
		const space = monitor.getItem().space;
		// @ts-expect-error TS7006: Parameter 's' implicitly has a...
		const newSpace = props.stackingPlan.spaces.find((s) => s.id === space.id);
		if (newSpace.placed) {
			props.unplaceDraggingSpace(space);
		}
	},
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	canDrop: function (props, monitor) {
		return monitor.getItem().space.placed;
	},
};

// @ts-expect-error TS7006: Parameter 'connect' implicitly...
function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	};
}

export default DropTarget(
	types,
	spec,
	collect
)(
	class PendingSpaces extends React.PureComponent {
		// @ts-expect-error TS7006: Parameter 'props' implicitly h...
		constructor(props) {
			super(props);
			this.state = {
				searchTerm: '',
				inputLength: 0,
				hasFocus: false,
			};
		}

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		search = (event) => {
			this.setState({
				searchTerm: event.target.value.toLowerCase(),
				inputLength: event.target.value.length,
			});
		};

		onFocus = () => {
			this.setState({
				hasFocus: true,
			});
		};

		onBlur = () => {
			this.setState({
				hasFocus: false,
			});
		};

		render() {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			let unplacedSpaces = this.props.stackingPlan.spaces.filter(
				// @ts-expect-error TS7006: Parameter 's' implicitly has a...
				(s) => !s.placed || !s.size
			);

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'searchTerm' does not exist on type 'Read... Remove this comment to see the full error message
			if (this.state.searchTerm) {
				// @ts-expect-error TS7006: Parameter 'space' implicitly h...
				unplacedSpaces = unplacedSpaces.filter((space) => {
					const searchValue = getTenant(space).toLowerCase();
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'searchTerm' does not exist on type 'Read... Remove this comment to see the full error message
					return searchValue.indexOf(this.state.searchTerm) !== -1;
				});
			}

			let searchMod = {};
			let inputMod = {};
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'inputLength' does not exist on type 'Rea... Remove this comment to see the full error message
			if (this.state.inputLength > 0 || this.state.hasFocus) {
				searchMod = {
					width: '100%',
				};
				inputMod = {
					width: '80%',
					paddingLeft: 0,
					zIndex: 1,
				};
			}

			let className = styles.sidebar;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'isOver' does not exist on type 'Readonly... Remove this comment to see the full error message
			if (this.props.isOver && this.props.canDrop) {
				className += ' ' + styles.dropTarget;
			}

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
			return this.props.connectDropTarget(
				<div className={className}>
					<div className={styles.sidebarHeader}>
						<div className={styles.headerText}>Available Leases</div>
						<div style={searchMod} className={styles.search}>
							<Search />
							<input
								style={inputMod}
								type="text"
								name="search"
								className={styles.searchInput}
								onChange={this.search}
								onBlur={this.onBlur}
								onFocus={this.onFocus}
							/>
						</div>
					</div>
					<div className={styles.pendingSpaces}>
						{/* @ts-expect-error TS7006: Parameter 'space' implicitly h... */}
						{unplacedSpaces.map((space, i) => (
							<PendingSpace
								// @ts-expect-error ts-migrate(2322) FIXME: Property 'space' does not exist on type 'Intrinsic... Remove this comment to see the full error message
								space={space}
								index={i}
								key={space.id}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
								menuActions={this.props.menuActions}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
								stackingPlanActions={this.props.stackingPlanActions}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
								stackingPlan={this.props.stackingPlan}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'repositionDraggedSpace' does not exist o... Remove this comment to see the full error message
								repositionDraggedSpace={this.props.repositionDraggedSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'startDraggingSpace' does not exist on ty... Remove this comment to see the full error message
								startDraggingSpace={this.props.startDraggingSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'endDraggingSpace' does not exist on type... Remove this comment to see the full error message
								endDraggingSpace={this.props.endDraggingSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaceBeingDragged' does not exist on typ... Remove this comment to see the full error message
								spaceBeingDragged={this.props.spaceBeingDragged}
							/>
						))}
					</div>
				</div>
			);
		}
	}
);
