import { Flex } from '@compstak/ui-kit';
import { CheckExportStatusPerMarket } from 'api';
import { Tr, Td } from './MarketToCompsExportTable';
import ExceedLimitIcon from 'ui/svg_icons/exceedLimit.svg';
import { Checkbox } from 'Components/Checkbox/Checkbox';

type TableRowProps = {
	marketToCompIds: Record<number, number[]>;
	marketPolicy: CheckExportStatusPerMarket;
	selectedMarketIds: number[];
	setSelectedMarketIds: (arg: number[]) => void;
};

const LIMIT_MESSAGE =
	'You do not have enough exports in this market available to download your current selection. Refine your filters to export in this market.';

export const TableRow = ({
	marketToCompIds,
	marketPolicy,
	selectedMarketIds,
	setSelectedMarketIds,
}: TableRowProps) => {
	const marketId = marketPolicy.marketId;

	return (
		<Tr disabled={!marketPolicy.canExport}>
			<Td>
				<Checkbox
					checked={selectedMarketIds.some(
						(selectedMarketId) => selectedMarketId === marketId
					)}
					disabled={!marketPolicy.canExport}
					onCheckedChange={(checked) => {
						const newSelectedMarketIds = checked
							? [...selectedMarketIds, marketId]
							: selectedMarketIds.filter(
									(selectedMarketId) => selectedMarketId !== marketId
								);
						setSelectedMarketIds(newSelectedMarketIds);
					}}
				/>
			</Td>
			<Td>{marketPolicy.marketDisplayName}</Td>
			{/* radix-tooltip is rendered on top of radix-popover. It seems to be not working thus the `data-tooltip` usage */}
			<Td>
				<Flex alignItems="center" gap="0.25rem">
					{marketToCompIds[marketId].length}
					{!marketPolicy.canExport && (
						<ExceedLimitIcon data-tooltip={LIMIT_MESSAGE} />
					)}
				</Flex>
			</Td>
			<Td>{`${marketPolicy.availableWithFinancial} / ${marketPolicy.limit}`}</Td>
		</Tr>
	);
};
