import { csLogger } from 'util/log/logger';
import * as actions from './actions';
import type {
	LeaseSubmission,
	SaleSubmission,
	UploadActions,
	UploadState,
} from './types';

const initialState: UploadState = {
	files: [],
	leaseSubmission: {
		tenantName: '',
		buildingAddress: '',
		city: '',
		state: '',
		floorOccupancies: '',
		spaceType: '',
		transactionSize: '',
		transactionType: '',
		startingRent: '',
		askingRent: '',
		leaseTerm: '',
		executionDate: '',
		expirationDate: '',
		comments: '',
		landlordName: '',
		landlordRealtyBrokers: '',
		landlordRealtyCompanies: '',
		tenantRealtyBrokers: '',
		tenantRealtyCompanies: '',
		effectiveRent: '',
		commencementDate: '',
		leaseType: '',
		rentBumpsPercent: '',
		rentBumpsDollar: '',
		leaseEscalations: '',
		freeMonths: '',
		workType: '',
		workValue: '',
		sublease: '',
		sublessorName: '',
		suite: '',
		buildingPropertySubtype: '',
		buildingPropertyType: '',
	},
	saleSubmission: {
		buildingAddress: '',
		city: '',
		state: '',
		buildingPropertyType: '',
		buildingPropertySubtype: '',
		buildingSize: '',
		lotSize: '',
		transactionSize: '',
		totalSalePrice: '',
		salePricePsf: '',
		saleDate: '',
		comments: '',
		recordedBuyer: '',
		recordedSeller: '',
		buyer: '',
		seller: '',
		capRate: '',
		totalOperatingExpenses: '',
		interestPercentage: '',
		interestType: '',
		totalNetOperatingIncome: '',
		netOperatingIncomePsf: '',
		operatingExpensesValue: '',
		isPortfolio: '',
		condo: '',
		capRateNotes: '',
		buyerRepBrokers: '',
		buyerRepCompanies: '',
		sellerRepBrokers: '',
		sellerRepCompanies: '',
		askingSalePricePsf: '',
		totalAskingSalePrice: '',
		floorOccupancies: '',
		suite: '',
		parcelNumber: '',
		financingInfo: '',
		propertyRights: '',
		numberOfBuildings: '',
		additionalAddresses: [1, 2].map((key) => ({
			key,
			buildingAddress: '',
			city: '',
			state: '',
		})),
	},
	status: null,
	compsAwarded: 0,
	uploadFormVisible: false,
	leaseAttributes: null,
};

export default (
	state = initialState,
	action: UploadActions = {} as UploadActions
): UploadState => {
	switch (action.type) {
		case actions.RESET_FILE_UPLOAD: {
			return { ...initialState };
		}

		case actions.SHOW_UPLOAD_FORM: {
			return {
				...state,
				uploadFormVisible: true,
			};
		}

		case actions.HIDE_UPLOAD_FORM: {
			return {
				...state,
				uploadFormVisible: false,
			};
		}

		case actions.UPDATE_SUBMISSION: {
			const { compType } = action.payload;
			const currentSubmission = state[`${compType}Submission`];
			const submission = currentSubmission ? { ...currentSubmission } : null;

			if (!submission) return state;
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			submission[action.payload.property] = action.payload.value;
			if (compType === 'lease') {
				return {
					...state,
					leaseSubmission: submission as LeaseSubmission,
				};
			} else if (compType === 'sale') {
				return {
					...state,
					saleSubmission: submission as SaleSubmission,
				};
			}
			return state;
		}

		case actions.SEND_SUBMISSION_PENDING: {
			return {
				...state,
				status: 'PENDING',
			};
		}

		case actions.SEND_SUBMISSION_REJECTED: {
			return state;
		}

		case actions.SEND_SUBMISSION_FULFILLED: {
			const compsAwarded = action?.payload?.comps?.awarded;
			if (typeof compsAwarded !== 'number') {
				csLogger.warn({
					event: 'SEND_SUBMISSION_FULFILLED: compsAwarded is not a number',
					extraInfo: { action },
				});
				return state;
			}
			return {
				...state,
				status: 'SUCCESS',
				compsAwarded,
			};
		}
	}

	return state;
};
