import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { SpaceTypeName } from 'api/referenceData';
import { createFetchFn } from 'api/utils';
import { mapUrlToGateway } from 'utils/gatewayUtil';

type MarketSummary = {
	spaceType: SpaceTypeName;
	content: string;
	createdAt: string;
};

type MarketSummaryParams = {
	marketId: number;
} & UseQueryOptions<MarketSummaryResponse>;

type MarketSummaryResponse = {
	summaries: MarketSummary[];
};

export const fetchMarketSummary = createFetchFn<
	MarketSummaryParams,
	MarketSummaryResponse
>({
	method: 'get',
	getURL: ({ marketId }) =>
		mapUrlToGateway(`/user-service/markets/${marketId}/summaries`),
});

export const useMarketSummaryQuery = ({
	marketId,
	...props
}: MarketSummaryParams) =>
	useQuery({
		queryKey: [QUERY_KEYS.MARKET_SUMMARY, marketId],
		queryFn: fetchMarketSummary({ marketId }),
		...props,
	});
