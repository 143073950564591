import { useState } from 'react';
import { useSelectionState } from 'utils';
import { DEFAULT_NUM_OF_SALES_SELECTED } from '../constants';
import { useEditableRows } from '../useEditableRows';
import { ExportPdfData } from '../useExportPdfData';
import { useSalesPdfColumns } from './useSalesPdfColumns';

type Props = {
	data: ExportPdfData;
};

export const useEditableSalesState = ({ data }: Props) => {
	const salesColumns = useSalesPdfColumns();

	const [editableSalesColumns, setEditableSalesColumns] =
		useState(salesColumns);

	const [editableSales, setEditableSales] = useEditableRows({
		rows: data.sales,
		columns: editableSalesColumns,
		getId: (row) => row.id,
	});

	const salesSelection = useSelectionState(editableSales, () =>
		editableSales.slice(0, DEFAULT_NUM_OF_SALES_SELECTED).map((r) => r.id)
	);

	return {
		editableSalesColumns,
		setEditableSalesColumns,
		editableSales,
		setEditableSales,
		salesSelection,
	};
};
