import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { formatDate } from 'format';
import { useSharedPdfStyle } from 'pdf/useSharedPdfStyle';
import { PdfPage } from '../../../../pdf/PdfPage';
import { PropertyAddress } from '../PropertyAddress';
import { PdfPageProps } from '../types';
import {
	useMapImageUrl,
	useStreetViewImageUrl,
} from 'Pages/PropertyPageV3/hooks/mapImages';

type Props = PdfPageProps & {
	isStreetViewEnabled: boolean;
};
export const CoverPage = (props: Props) => {
	const { property, page, apn, isStreetViewEnabled } = props;
	const now = new Date();

	const { geoPoint } = property;

	const streetViewImageUrl = useStreetViewImageUrl({
		width: STREETVIEW_SIZE.width,
		height: STREETVIEW_SIZE.height,
		geoPoint,
	});

	const mapImageUrl = useMapImageUrl({
		width: STREETVIEW_SIZE.width,
		height: STREETVIEW_SIZE.height,
		geoPoint,
	});

	const mapUrl = isStreetViewEnabled
		? {
				src: streetViewImageUrl.href,
				style: { height: STREETVIEW_SIZE.height },
			}
		: { src: mapImageUrl.href, style: {} };

	const pdfStyle = useSharedPdfStyle();

	return (
		<PdfPage
			logo={props.page.logo}
			preparedFor={props.page.preparedFor}
			title={props.page.titles.cover}
			user={props.user}
		>
			<View style={style.topRow}>
				<View style={style.topLeft}>
					<Image style={[mapUrl.style, style.img]} src={mapUrl.src} />
					<PropertyAddress property={property} apn={apn} />
				</View>
				<View style={style.topRight}>
					{page.labelsVisible.createdOn && (
						<View style={pdfStyle.infoRow}>
							<Text style={pdfStyle.infoLabel}>Created on</Text>
							<Text style={pdfStyle.infoValue}>
								{formatDate(now, 'MMMM D, YYYY [at] h:mm A')}
							</Text>
						</View>
					)}
					{page.labelsVisible.preparedFor &&
						(page.preparedFor || page.preparedForLogo) && (
							<View style={pdfStyle.infoRow}>
								<Text style={pdfStyle.infoLabel}>Prepared for</Text>
								<View style={style.preparedForContainer}>
									{page.preparedForLogo && (
										<Image
											src={() =>
												fetch(page.preparedForLogo!).then((res) => res.url)
											}
											style={{ width: 35, height: 35 }}
										/>
									)}
									{page.preparedFor && (
										<Text style={pdfStyle.infoValue}>{page.preparedFor}</Text>
									)}
								</View>
							</View>
						)}
					{page.labelsVisible.preparedBy && page.preparedBy && (
						<View style={pdfStyle.infoRow}>
							<Text style={pdfStyle.infoLabel}>Prepared by</Text>
							<Text style={pdfStyle.infoValue}>{page.preparedBy}</Text>
						</View>
					)}
					{page.labelsVisible.notes && page.notes && (
						<View style={pdfStyle.infoRow}>
							<Text style={pdfStyle.infoLabel}>Notes</Text>
							<Text style={pdfStyle.infoValue}>{page.notes}</Text>
						</View>
					)}
				</View>
			</View>
		</PdfPage>
	);
};

const STREETVIEW_SIZE = {
	width: 318,
	height: 180,
};

const style = StyleSheet.create({
	topRow: {
		display: 'flex',
		flexDirection: 'row',
		gap: 24,
	},
	topLeft: {
		width: '40%',
		flexShrink: 0,
	},
	img: {
		width: '100%',
		marginRight: 10,
		marginBottom: 20,
	},
	topRight: {
		flex: 1,
	},
	preparedForContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		gap: 5,
		flex: 1,
	},
});
