import { Link } from 'router';
import styled, { css } from 'styled-components';
import { APP_HEADER_HEIGHT } from '../../constants';
import IconArrowBottom from '../../ui/svg_icons/arrow_bottom.svg';
import IconCompstakLogo from '../../ui/svg_icons/logo_new.svg';

export const Header = styled.header`
	display: flex;
	align-items: center;
	padding-left: 1.25rem;
	height: ${APP_HEADER_HEIGHT}px;
	background-color: ${(p) => p.theme.colors.neutral.n800};
	gap: 0.5rem;
	// defaults
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	* {
		box-sizing: border-box;
	}
`;

export const StyledIconLogo = styled(IconCompstakLogo)`
	polygon {
		fill: unset;
	}
	fill: ${(p) => p.theme.colors.neutral.n200};
	&:hover {
		fill: ${(p) => p.theme.colors.white.white};
	}
`;

export const RightContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.375rem;
	align-self: stretch;
	margin-left: auto;
`;

export const baseHeaderItemCss = css`
	font-size: 0.6875rem;
	font-weight: 400;
	letter-spacing: 0.1em;
	line-height: 2;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.white.white};
	padding: 0 0.625rem;
	border-radius: 0.625rem;
	transition: none;
	white-space: nowrap;
	display: inline-block;
	${({ theme }) => theme.breakpoints.down('D_1280')} {
		font-size: 0.625rem;
		padding: 0 0.375rem;
	}
`;

const headerItemCss = css`
	${baseHeaderItemCss};
	&:hover {
		color: ${(p) => p.theme.colors.blue.blue800};
	}
`;

const headerSecondaryItemCss = css`
	color: ${(p) => p.theme.colors.neutral.n60};
`;

const activeItemCss = css`
	background-color: ${(p) => p.theme.colors.blue.blue500};
	color: ${(p) => p.theme.colors.white.white};
`;

export const HeaderLink = styled(Link)<{ active?: boolean }>`
	${headerItemCss};
	${(p) => p.active && activeItemCss}
`;

export const HeaderButton = styled.button<{ active?: boolean }>`
	${headerItemCss};
	background-color: transparent;
	border: 0;
	${(p) => p.active && activeItemCss}
`;

export const HeaderAnchorLink = styled.a`
	${headerItemCss};
	${headerSecondaryItemCss}
`;

export const HeaderGreenButtonLink = styled(Link)`
	height: 30px;
	padding: 0.25rem 0.75rem;
	background-color: ${(p) => p.theme.colors.green.green500};
	color: ${(p) => p.theme.colors.white.white};
	border-radius: 0.125rem;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 400;
	letter-spacing: 0.1em;
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	&:hover {
		background-color: ${(p) => p.theme.colors.green.green600};
		color: ${(p) => p.theme.colors.white.white};
	}
`;

export const CircleText = styled.div<{ isRed?: boolean }>`
	border-radius: 0.75rem;
	border: 1px solid
		${(p) =>
			p.isRed ? p.theme.colors.red.red500 : p.theme.colors.green.green500};
	font-size: 0.75rem;
	color: ${(p) => p.theme.colors.white.white};
	padding: 0.25rem 0.5rem;
`;

export const MessageText = styled.div`
	${headerSecondaryItemCss};
	font-size: 0.75rem;
`;

export const IconHeaderDropdown = styled(IconArrowBottom)`
	fill: ${(p) => p.theme.colors.white.white};
`;

export const HeaderDropdownButton = styled(HeaderButton)`
	display: flex;
	align-items: center;
	gap: 0.25rem;
	&:hover {
		${IconHeaderDropdown} {
			fill: ${(p) => p.theme.colors.blue.blue800};
		}
	}
`;

export const DesktopLinksContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
	${({ theme }) => theme.breakpoints.down('D_1280')} {
		gap: 0.125rem;
	}
`;

export const AnalyticsContainer = styled.div`
	display: flex;
	align-items: center;

	button {
		display: none;
	}

	li {
		display: flex;
	}

	${({ theme }) => theme.breakpoints.down('D_1280')} {
		button {
			display: flex;
		}

		li {
			display: none;
		}
	}
`;
