import {
	PolygonsLayer,
	PopupPositioning,
	TRANSPARENT_RGBA,
} from '@compstak/maps';
import { MarketPolygonFeature } from 'api/allMarketsPolygons/useAllMarketsPolygons';
import {
	SUBMARKET_FILTER_FILL_COLOR,
	SUBMARKET_FILTER_LINE_COLOR,
} from 'constants/colors';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';

type MarketsLayerProps = {
	marketFeatures: MarketPolygonFeature[];
	selectedMarketFeatures: MarketPolygonFeature[];
	onMarketsChange: (marketFeatures: MarketPolygonFeature[]) => void;
};

const ID = 'MarketsLayer';

export const MarketsLayer = ({
	marketFeatures,
	selectedMarketFeatures,
	onMarketsChange,
}: MarketsLayerProps) => {
	return (
		<PolygonsLayer
			id={ID}
			data={marketFeatures}
			getFillColor={(f: MarketPolygonFeature) => {
				const isSelected = selectedMarketFeatures.some(
					({ properties: { id } }) => id === f.properties.id
				);
				return isSelected ? SUBMARKET_FILTER_FILL_COLOR : TRANSPARENT_RGBA;
			}}
			getLineColor={SUBMARKET_FILTER_LINE_COLOR}
			highlightColor={SUBMARKET_FILTER_FILL_COLOR as number[]}
			tooltipPositioning={PopupPositioning.CENTER_POLYGON}
			getDashArray={[5, 2]}
			getTooltipContent={(f) => f.properties.displayName}
			onClick={({ object }) => {
				if (!object) return;

				const newselectedMarketFeatures = selectedMarketFeatures.some(
					({ properties: { id } }) => id === object.properties.id
				)
					? selectedMarketFeatures.filter(({ properties: { id } }) => {
							return id !== object.properties.id;
					  })
					: [...selectedMarketFeatures, object];

				onMarketsChange(newselectedMarketFeatures);
			}}
			updateTriggers={{
				getFillColor: [selectedMarketFeatures],
			}}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
