import { LineDescription, defaultTheme } from '@compstak/ui-kit';
import { addEmptyLines } from '@compstak/common';

export const lines: Array<LineDescription> = [
	{
		value: 'property',
		title: 'Property',
		color: `${defaultTheme.colors.green.green500}`,
		type: 'monotone',
	},
	{
		value: 'compSet',
		title: 'Comp. Set',
		color: `${defaultTheme.colors.purple.purple700}`,
		type: 'monotone',
	},
	{
		value: 'submarket',
		title: 'Submarket',
		color: `${defaultTheme.colors.blue.blue700}`,
		type: 'monotone',
	},
];

export const emptyLines = addEmptyLines(lines);
