import { setFilters } from 'models/filters/util/setFilters';
import { filtersToQueryString } from 'models/filters/util/urls';
import { useMemo } from 'react';
import { useSingleMarketFilters } from 'reducers/filtersReducer';
import { PropertyComp } from 'types';

type Props = {
	property?: PropertyComp;
};

// TODO: is no longer needed when MM FF is always on (existed for backwards compatibility with SM)
export const useFiltersWithAddressString = ({ property }: Props) => {
	const [singleMarketFilters] = useSingleMarketFilters(property?.marketId);

	return useMemo(() => {
		if (!property?.buildingAddressAndCity) return;
		const filtersWithAddress = setFilters(singleMarketFilters, 'address', [
			property.buildingAddressAndCity,
		]);
		return filtersToQueryString(filtersWithAddress);
	}, [singleMarketFilters, property?.buildingAddressAndCity]);
};
