import { defaultTheme, Pending } from '@compstak/ui-kit';
import React, { memo } from 'react';
import { Suggestion } from 'api/suggestions/suggestions';
import styled from 'styled-components';
import styles from './suggestions.less';
import { SuggestionSection, SuggestionsContainer } from '../SearchBar';

const SKELETON_SUGGESTION_SECTIONS = 8;
const SKELETON_SUGGESTION_SECTIONS_ITEMS = 3;

export const SuggestionsSkeleton = memo<{
	suggestions?: Suggestion[];
	hasTopResult?: boolean;
}>(({ suggestions, hasTopResult }) => {
	return (
		<SuggestionsContainer tabIndex={0}>
			{!hasTopResult && suggestions && (
				<div className={styles.typeaheadEmpty}>
					<Pending margin="0" bgColor={defaultTheme.colors.blue.blue400} />
				</div>
			)}
			{Array.from(
				{ length: suggestions?.length ?? SKELETON_SUGGESTION_SECTIONS },
				(_, index) => (
					<SuggestionSection key={`skeleton_suggestion_section${index}`}>
						<SkeletonContainer height="2rem">
							<Pending margin="0" bgColor={defaultTheme.colors.gray.gray110} />
						</SkeletonContainer>
						{Array.from(
							{
								length:
									suggestions?.[index].suggestions.length ??
									SKELETON_SUGGESTION_SECTIONS_ITEMS,
							},
							(_, index) => (
								<div
									className={styles.suggestion}
									key={`skeleton_suggestion_section_item${index}`}
								>
									<Pending margin="0" />
								</div>
							)
						)}
					</SuggestionSection>
				)
			)}
		</SuggestionsContainer>
	);
});

SuggestionsSkeleton.displayName = 'SuggestionsSkeleton';

const SkeletonContainer = styled.div<{ height: string }>`
	height: ${({ height }) => height};
	width: 100%;
`;
