import React, { FC, memo, useCallback } from 'react';
import { Modal, Flex, Button, useBoolean } from '@compstak/ui-kit';
import { useUser } from 'Pages/Login/reducers';
import {
	closeButtonStyle,
	ControlsWrapper,
	Description,
	Label,
	modalStyles,
	StyledAlertCircle,
	Title,
} from '../modalCommonStyles';
import { FetchParams } from 'Pages/Analytics/api/user/setDontShowAgainWindows';

interface ModalWithCloseTimestampProps {
	title: string;
	description: string;
	onClose: NoArgCallback;
	onTimestampChange: (params: FetchParams) => void;
}

export const ModalWithCloseTimestamp: FC<ModalWithCloseTimestampProps> = memo(
	({ title, description, onClose, onTimestampChange }) => {
		const { id: userId } = useUser();
		const [isModalNotShownAgain, , , toggleIsModalNotShownAgain] = useBoolean();

		const onCloseHandler = useCallback(() => {
			if (isModalNotShownAgain) {
				onTimestampChange({ userId, time: Date.now() });
			}

			onClose();
		}, [isModalNotShownAgain, userId, onClose, onTimestampChange]);

		return (
			// @ts-expect-error TS2741: Property 'onClose' is missing ...
			<Modal
				data-id="real-page-mufa-property"
				modalBodyCSS={modalStyles}
				closeButtonStyle={closeButtonStyle}
			>
				<Flex direction="column" alignItems="flex-start">
					<Flex alignItems="center">
						<StyledAlertCircle />
						<Title>{title}</Title>
					</Flex>
					<Description>{description}</Description>
					<ControlsWrapper justifyContent="space-between">
						<Flex alignItems="center">
							<input
								type="checkbox"
								className="checkbox"
								id="do-not-show-modal-again"
								name="do-not-show-modal-again"
								checked={isModalNotShownAgain}
								onClick={toggleIsModalNotShownAgain}
							/>
							<Label htmlFor="do-not-show-modal-again">Do Not Show Again</Label>
						</Flex>
						<Button onClick={onCloseHandler}>I understand</Button>
					</ControlsWrapper>
				</Flex>
			</Modal>
		);
	}
);

ModalWithCloseTimestamp.displayName = 'ModalWithCloseTimestamp';
