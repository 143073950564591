import styled from 'styled-components';
import { colors } from '@compstak/ui-kit';

type DataNotAvailableMessageProps = {
	icon?: React.ReactElement;
	title?: string;
	message?: string;
	chartName?: string;
};
export const DataNotAvailableMessage = ({
	icon,
	title,
	message,
	chartName,
}: DataNotAvailableMessageProps) => {
	return (
		<MessageContainer>
			{icon && <IconContainer>{icon}</IconContainer>}
			<MessageHeader>{title ?? 'Data not available'}</MessageHeader>
			<Message>
				{message ?? (
					<>
						The
						{chartName ? (
							<ChartNameText> {chartName} </ChartNameText>
						) : (
							' data '
						)}
						is currently not available for your selected market(s).
					</>
				)}
			</Message>
		</MessageContainer>
	);
};

const MessageContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 10px;
	color: ${colors.neutral.n400};
	border: 1px solid ${colors.neutral.n40};
	background-color: ${colors.white.white};
`;

const MessageHeader = styled.div`
	font-size: 16px;
	font-weight: 500;
`;
const Message = styled.div`
	font-size: 12px;
`;

const IconContainer = styled.div`
	svg {
		width: 40px;
		height: 40px;
		path {
			fill: ${colors.gray.n100};
		}
	}
`;

const ChartNameText = styled.span`
	text-transform: capitalize;
`;
