import { AverageIndicator } from 'ExchangeDashboard/LargestLandlordsChart';
import { useExchangeDashboardLargeLandlords } from 'ExchangeDashboard/hooks/largestLandlords/useExchangeDashboardLargeLandlords';
import { formatInteger, formatNumber } from 'format';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { VictoryLabel, VictoryScatter } from 'victory';
import { useExportPdfState } from '../ExportPdfProvider';
import { PDFPanel } from '../UI';

export const LargestLandlordsSection = () => {
	const { data: largestLandlordsData } = useExchangeDashboardLargeLandlords();
	const { registerImage } = useExportPdfState();

	const data = useMemo(() => {
		if (!largestLandlordsData) return [];

		const chartData = largestLandlordsData.largestLandlords
			.slice(0, 6)
			.map((landlord) => {
				const formattedLandlord =
					landlord.landlord.length > 30
						? `${landlord.landlord.slice(0, 30)}…`
						: landlord.landlord;
				return {
					...landlord,
					label: `${formatInteger(landlord.sqft)} SQFT\n ${formattedLandlord}`,
					x: `${formattedLandlord}\n${formatInteger(landlord.sqft)} sqft`,
					y: 1,
				};
			});

		return sortBy(chartData, 'sqft').reverse();
	}, [largestLandlordsData]);

	const theme = useTheme();

	const refCallback = useCallback(
		(el: HTMLElement | null) => {
			registerImage('largestLandlords', el);
		},
		[registerImage]
	);

	if (!largestLandlordsData) return null;

	return (
		<PDFPanel
			title="Largest Landlords"
			cta={
				<AverageIndicator>
					{`${formatNumber(
						largestLandlordsData.averageSqft
					)} sqft, Average Landlord Size`}
				</AverageIndicator>
			}
		>
			<div ref={refCallback}>
				<VictoryScatter
					height={150}
					width={800}
					style={{
						data: { cursor: 'pointer', fill: theme.colors.blue.blue400 },
						labels: { fontSize: 10 },
					}}
					size={data?.length ?? 0}
					maxBubbleSize={30}
					minBubbleSize={5}
					bubbleProperty="sqft"
					labelComponent={<VictoryLabel dy={60} />}
					data={data}
				/>
			</div>
		</PDFPanel>
	);
};
