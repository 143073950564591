import React from 'react';
import { PdfTable } from '../PdfTable';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { PdfColumn } from '../types';
import { CherreLoan, TreppLoan } from 'api';

type Props = {
	editablePublicLoansRow: Record<string, string | number>[];
	visibleColumns: PdfColumn<
		Record<keyof CherreLoan, string | number>,
		CherreLoan
	>[];
	loan: TreppLoan | undefined;
};

export const PublicLoansTablePdf = ({
	visibleColumns,
	editablePublicLoansRow,
	loan,
}: Props) => (
	<>
		<View style={[styles.titleContainer, { marginTop: loan ? '48px' : 0 }]}>
			<Text style={styles.widgetTitle}>Public Loan Data</Text>
		</View>

		<PdfTable rows={editablePublicLoansRow} columns={visibleColumns} />
	</>
);

const styles = StyleSheet.create({
	titleContainer: {
		display: 'flex',
		marginBottom: '8px',
	},
	widgetTitle: {
		fontSize: '16px',
		lineHeight: '1.5',
		fontStyle: 'normal',
		fontWeight: 500,
	},
});
