import { SimpleTable } from '@compstak/ui-kit';
import { useNavigate } from 'router';
import { routes } from 'router/routes/routes';
import { useLeasesInCompsetTableColumns } from './useLeasesInCompsetTableColumns';
import { LeaseComp } from 'types';

type Props = {
	leases: LeaseComp[];
	isLoading: boolean;
	id: string;
};

export const LeasesInCompsetTable = ({ leases, isLoading, id }: Props) => {
	const columns = useLeasesInCompsetTableColumns();

	const navigate = useNavigate();

	return (
		<>
			<SimpleTable
				id={id}
				rows={leases}
				isLoading={isLoading}
				columns={columns}
				scrollContainerProps={{
					style: {
						maxHeight: 400,
					},
				}}
				isBodyHoverable
				getBodyCellProps={({ row }) => ({
					onClick: () => {
						navigate(routes.leaseById.toHref({ id: row.id }), {
							state: {
								fromNewSalePage: true,
							},
						});
					},
				})}
			/>
		</>
	);
};
