import * as Tabs from '@radix-ui/react-tabs';
import { MQB } from 'constants/mediaQueryBreakpoints';
import styled from 'styled-components';
import { MIN_TAB_HEIGHT } from './constants';

export const TabContentHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 0.75rem 0;
`;

export const TabContentTitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const TabContentTitle = styled.h3`
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 1;
`;

export const TabContainer = styled.div`
	padding: 1.75rem 0;
	min-height: ${MIN_TAB_HEIGHT}px;

	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: ${({ theme }) => theme.colors.gray.gray300};
	}
`;

export const TableContainer = styled.div`
	margin-bottom: 0.5rem;
`;

export const TableHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n50};
	padding: 0.5rem 0;
`;

export const PoweredByContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.375rem;
`;

export const PoweredByText = styled.div`
	font-size: 0.625rem;
	color: ${(p) => p.theme.colors.neutral.n200};
`;

export const TableActionsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 1rem 0;
`;

export const TabsList = styled(Tabs.List)`
	display: flex;
	justify-content: center;
`;

export const NestedTab = styled(Tabs.Trigger)`
	height: 36px;
	min-width: 200px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.5rem;
	font-size: 0.875rem;
	line-height: 1;
	font-weight: 300;
	border: 1px solid ${(p) => p.theme.colors.neutral.n50};
	background-color: ${(p) => p.theme.colors.white.white};
	color: ${(p) => p.theme.colors.neutral.n100};
	&:last-of-type {
		border-left-width: 0;
	}
	&[data-state='active'] {
		background-color: ${(p) => p.theme.colors.blue.blue400};
		color: ${(p) => p.theme.colors.white.white};
	}

	&:disabled {
		transition: 0.3s ease-in-out;

		background-color: ${({ theme }) => theme.colors.gray.gray300};
		cursor: not-allowed;
	}
`;

export const TabContentHeaderRightContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-left: auto;
`;

/** NEW KEY VALUE GRID */

export const KeyValueGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-flow: row;
	column-gap: 1.5rem;
	@media (min-width: ${MQB.M_640}px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: ${MQB.D_1024}px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (min-width: ${MQB.D_1360}px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const KeyValueItem = styled.div`
	font-size: 0.875rem;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n50};
`;

export const KeyValueLabel = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.25;
`;

export const KeyValueValue = styled(KeyValueLabel)`
	font-weight: 600;
	text-align: right;
`;

export const KeyValueLabelHelpText = styled.div`
	color: ${(p) => p.theme.colors.neutral.n80};
	font-weight: 400;
	line-height: 1.25;
	font-size: 0.6875rem;
`;

/** NEW KEY VALUE GRID */

/** RIGHT SIDE WIDGETS */

const BOX_BORDER_WIDTH = 1.5;

export const BlueBox = styled.div<{ isCollapsed?: boolean }>((p) => {
	const border = `${BOX_BORDER_WIDTH}px solid ${p.theme.colors.blue.blue400};`;

	return {
		position: 'relative',
		borderRadius: '0.25rem',
		backgroundColor: p.theme.colors.white.white,
		padding: p.isCollapsed ? 0 : '1.25rem 1.5rem',
		border: p.isCollapsed ? undefined : border,
		borderTop: p.isCollapsed ? border : undefined,
	};
});

export const BlueBoxLegend = styled.div<{ rightSide?: boolean }>`
	position: absolute;
	top: 0;
	${({ rightSide }) => (rightSide ? 'right: 1rem' : 'left: 1rem')};
	transform: translateY(calc(-50% - ${BOX_BORDER_WIDTH}px));
	padding: 0.5rem;
	font-size: 1rem;
	line-height: 1;
	font-weight: 400;
	text-transform: uppercase;
	background-color: ${(p) => p.theme.colors.white.white};
`;

export const BlueBoxLine = styled.div`
	border-top: ${BOX_BORDER_WIDTH}px solid ${(p) => p.theme.colors.blue.blue400};
	padding: 1rem 1.5rem;
	position: relative;
	background-color: ${(p) => p.theme.colors.white.white};
`;

export const BlueBoxLabel = styled.div`
	color: ${(p) => p.theme.colors.blue.blue400};
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 0.01em;
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

export const BlueBoxValue = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 2;
`;

/** RIGHT SIDE WIDGETS */
