import { ColumnReorderProvider } from './ColumnReorder';
import { ColumnResizeProvider } from './ColumnResize';
import { ColumnsProvider } from './Columns';
import { HoverProvider } from './HoverProvider';
import { LayoutProvider } from './LayoutProvider';
import { OptionsProvider } from './OptionsProvider';
import { Table } from './Table';
import { SearchTableProps } from './types';

export const SearchTable = <Row,>(props: SearchTableProps<Row>) => {
	return (
		<OptionsProvider {...props}>
			<ColumnsProvider>
				<LayoutProvider>
					<ColumnResizeProvider>
						<ColumnReorderProvider>
							<HoverProvider>
								<Table />
							</HoverProvider>
						</ColumnReorderProvider>
					</ColumnResizeProvider>
				</LayoutProvider>
			</ColumnsProvider>
		</OptionsProvider>
	);
};
