import { useEffect, useState } from 'react';

export function useHasErrored({
	isSuccess,
	isError,
}: {
	isSuccess: boolean;
	isError: boolean;
}) {
	const [hasErroredLast, setHasErroredLast] = useState(false);
	// In ReactQuery isError is set to false every time a refetch is tried.
	// That is why we're using this useEffect, to show the user that the last time
	// that the data has been fetched an error happened.
	useEffect(() => {
		if (isError) {
			setHasErroredLast(true);
			return;
		}
		if (isSuccess) {
			setHasErroredLast(false);
		}
	}, [isSuccess, isError]);
	return hasErroredLast;
}
