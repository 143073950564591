import { useCompsetTableRows } from 'Components';
import {
	PropertyLease,
	PropertySale,
	useCompsetData,
	useCompsetType,
	useIsMufaProperty,
	useLeasesInCompset,
	useMarketsHaveFeature,
	useMufaFloorPlansQuery,
	useMufaKeyMetricsQuery,
	useMufaUnitMixSummaryQuery,
	usePropertyById,
	usePropertyLeasesQuery,
	usePropertyLeasesSummaryQuery,
	usePropertyLoan,
	usePropertySalesQuery,
	useSubmarketRecentLeases,
	useUserQuery,
} from 'api';
import { useIsExchange } from 'hooks';
import { useApn } from 'hooks/useApn';
import { useMemo } from 'react';
import { LeaseComp, PropertyComp } from 'types';
import { useLockedSnapshotData } from '../PropertyLeaseSnapshot/useLockedSnapshotData';

type Props = {
	propertyId: number;
};

export const useExportPdfData = ({ propertyId }: Props) => {
	const { data: property, isLoading: isLoadingProperty } = usePropertyById({
		propertyId,
	});

	const { data: compsetRows, isLoading: isLoadingCompsetRows } =
		useCompsetTableRows({
			propertyId,
		});

	const { data: compsetType } = useCompsetType({ propertyId });

	const { data: isMufa, isLoading: isLoadingMufa } = useIsMufaProperty({
		propertyId,
	});

	const { data: user } = useUserQuery();

	const { data: apn } = useApn({
		comp: property ?? ({} as PropertyComp),
	});

	const { data: leasesInCompset, isLoading: isLoadingLeasesInCompset } =
		useLeasesInCompset({
			propertyId,
		});

	const ownLeasesInCompset = useOwnComps(leasesInCompset?.comps) as LeaseComp[];

	const { data: salesData, isLoading: isLoadingSales } = usePropertySalesQuery({
		propertyId,
	});

	const { data: leasesSummary, isLoading: isLoadingLeasesSummary } =
		usePropertyLeasesSummaryQuery(propertyId);

	const { data: compset, isLoading: isLoadingCompset } = useCompsetData({
		propertyId,
	});

	const ownSales = useOwnComps(salesData?.comps) as PropertySale[];

	const { data: leasesData, isLoading: isLoadingLeases } =
		usePropertyLeasesQuery({
			propertyId,
		});

	const ownLeases = useOwnComps(leasesData?.comps) as PropertyLease[];

	const { data: loans, isLoading: isLoadingLoan } = usePropertyLoan({
		propertyId,
	});

	const { data: summary, isFetching: isFetchingSummary } =
		useMufaUnitMixSummaryQuery({ propertyId });

	const { data: keyMetrics, isFetching: isFetchingStatusKeyMetrics } =
		useMufaKeyMetricsQuery({ propertyId });

	const { data: mufaFloorPlans, isFetching: isFetchingFloorPlans } =
		useMufaFloorPlansQuery({ propertyId });

	const {
		data: submarketRecentLeases,
		isFetching: isFetchingSubmarketRecentLeases,
	} = useSubmarketRecentLeases({ propertyId });

	const ownSubmarketRecentLeases = useOwnComps(
		submarketRecentLeases?.comps
	) as PropertyLease[];

	const { data: hasSalesAccess, isLoading: isLoadingSalesAccess } =
		useMarketsHaveFeature({
			feature: 'salesAccess',
			marketIds: property ? [property.marketId] : [],
			isEnabled: property != null,
		});

	const { isSnapshotBaseLocked, spaceTypeRents: leaseSnapshotRents } =
		useLockedSnapshotData();

	const isLeaseSnapshotPdfDisabled =
		!leasesSummary?.activeLeaseCount || !compset || isSnapshotBaseLocked;

	return {
		data: {
			property,
			compsetType,
			compsetRows,
			isMufa,
			user,
			apn,
			leasesInCompset: ownLeasesInCompset,
			sales: ownSales,
			leases: ownLeases,
			loans,
			summary,
			keyMetrics,
			mufaFloorPlans,
			compset,
			leasesSummary,
			submarketRecentLeases: ownSubmarketRecentLeases,
			hasSalesAccess,
			isLeaseSnapshotPdfDisabled,
			leaseSnapshotRents,
		},
		isLoading:
			isLoadingProperty ||
			isLoadingCompsetRows ||
			compsetType === undefined ||
			isLoadingMufa ||
			isLoadingLeasesInCompset ||
			isLoadingSales ||
			isLoadingLeases ||
			isLoadingLoan ||
			isFetchingSummary ||
			isFetchingStatusKeyMetrics ||
			isFetchingFloorPlans ||
			isLoadingCompset ||
			isLoadingLeasesSummary ||
			isFetchingSubmarketRecentLeases ||
			isLoadingSalesAccess,
	};
};

type DeepNonNullable<T> = {
	[K in keyof T]: NonNullable<T[K]>;
};

export type ExportPdfData = DeepNonNullable<
	ReturnType<typeof useExportPdfData>['data']
>;

const useOwnComps = (comps: { own?: boolean }[] | undefined) => {
	const isExchange = useIsExchange();
	const ownComps = useMemo(() => {
		if (!comps) return [];
		return isExchange ? comps.filter((l) => l.own) : comps;
	}, [comps]);
	return ownComps;
};
