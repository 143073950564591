import { Checkbox } from 'Components';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { CHECKBOX_CELL_SIZE, ROW_HEIGHT } from './constants';
import { useHoverContext } from './HoverProvider';
import { useOptionsContext } from './OptionsProvider';
import { cellBorders } from './UI';
import { getCellBackgroundColor, isLoadingRow } from './utils';

type Props = {
	rowIndex: number;
	style: CSSProperties;
};

export const ActionCell = ({ rowIndex, style }: Props) => {
	const { getIsRowSelected, toggleRowSelection, renderActionButton, rows } =
		useOptionsContext();
	const [hoveredIndex, setHoveredIndex] = useHoverContext();
	const row = rows[rowIndex];

	const isSelected = getIsRowSelected(row, rowIndex);
	const isHovered = hoveredIndex === rowIndex;
	const isLoading = isLoadingRow(row);

	return (
		<Cell
			className="action-cell"
			data-rowindex={rowIndex}
			style={{
				...style,
				height: ROW_HEIGHT,
				paddingLeft: 0,
				backgroundColor: getCellBackgroundColor(
					isLoading,
					isHovered,
					isSelected
				),
			}}
			onMouseEnter={() => {
				setHoveredIndex(rowIndex);
			}}
			onMouseLeave={() => {
				setHoveredIndex(null);
			}}
		>
			<CheckboxCell>
				<Checkbox
					name="selectRow"
					checked={isSelected}
					disabled={isLoading}
					onCheckedChange={() => {
						toggleRowSelection?.(row, rowIndex);
					}}
					data-qa-id="select-row-checkbox"
				/>
			</CheckboxCell>
			<ButtonContainer>
				{isLoading ? <LoadingButton /> : renderActionButton(row)}
			</ButtonContainer>
		</Cell>
	);
};

const Cell = styled.div`
	${cellBorders}
	display: flex;
	background-color: #fff;
`;

const CheckboxCell = styled.div`
	width: ${CHECKBOX_CELL_SIZE}px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ButtonContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	padding-left: 0;
`;

const LoadingButton = styled.div`
	width: 100%;
	height: 30px;
	background-color: ${(p) => p.theme.colors.neutral.n40};
	border-radius: 3px;
`;
