import { Comp } from '../types';
import { useAppSelector } from 'util/useAppSelector';
import { Selection } from '../reducers/selection';
import { SearchState } from '../Pages/Search/searchReducer';

export function getAveragesState(
	searchReducerState: SearchState,
	selection: Selection
) {
	let averages = searchReducerState.averages;
	let averagesNeeded: false | Comp[] = false;

	if (!selection.allSelected && selection.list.length) {
		const selectionAverages = selection.averages;
		if (!selectionAverages) {
			averagesNeeded = selection.list;
		} else {
			averages = selectionAverages;
		}
	}
	return { averages, averagesNeeded };
}

export function useAverages() {
	const searchReducerState = useAppSelector((state) => state.searchReducer);
	const selection = useAppSelector((state) => state.selection);

	return getAveragesState(searchReducerState, selection);
}
