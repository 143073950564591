import {
	TenantIndustryLegend,
	useIsTopTenTenantIndustries,
	useTenantIndustryState,
} from 'maps';
import { useFilters } from 'reducers/filtersReducer';
import {
	TenantIndustryAnalyticsDescription,
	useFilteredTenantIndustries,
} from '../TenantIndustryAnalytics';
import { MapExportLegend } from './MapExportLegend';

export const TenantIndustryAnalyticsExportLegend = () => {
	const [state] = useTenantIndustryState();

	const [filters] = useFilters();

	const tenantIndustries = useFilteredTenantIndustries();

	const isTopTen = useIsTopTenTenantIndustries({ tenantIndustries, filters });

	return (
		<MapExportLegend
			title={state.title}
			description={<TenantIndustryAnalyticsDescription />}
		>
			<TenantIndustryLegend
				tenantIndustries={tenantIndustries}
				isTopTen={isTopTen}
			/>
		</MapExportLegend>
	);
};
