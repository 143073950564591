import { nullValueText } from '@compstak/common';
import styled from 'styled-components';
import { PropertyComp } from 'types/comp';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import formatMoney from 'ui/util/money';
import { formatComp } from 'util/comp-format/src/format';

type Props = {
	property: PropertyComp;
};

export const PropertyDetails = ({ property }: Props) => {
	const sumOfAvailableFields = propertyDetailsFields.reduce((sum, field) => {
		if (property[field] != null) sum++;
		return sum;
	}, 0);

	if (sumOfAvailableFields < 3) return null;

	const { lastSalePrice } = formatComp(property, 'property');

	let truncatedLastSalePrice = lastSalePrice;

	if (typeof truncatedLastSalePrice !== 'undefined') {
		let symbol = lastSalePrice[0];
		if (symbol !== '$' && symbol !== '£') {
			symbol = '$';
		}

		truncatedLastSalePrice = formatMoney(
			// @ts-expect-error TS2345: Argument of type 'number | und...
			property.lastSalePrice,
			symbol,
			'',
			true
		);
	}

	return (
		<PropertyDetailsGrid>
			<PropertyDetailBox>
				<PropertyDetailsBoxHeader>Floors</PropertyDetailsBoxHeader>
				<PropertyDetailsBoxContent>
					{property.buildingFloorsCount ?? nullValueText}
				</PropertyDetailsBoxContent>
			</PropertyDetailBox>
			<PropertyDetailBox>
				<PropertyDetailsBoxHeader>Sq ft exp in 12 mo.</PropertyDetailsBoxHeader>
				<PropertyDetailsBoxContent>
					{property.propertySqFtExpiringInTwelveMonths == null
						? nullValueText
						: abbreviateNumber(property.propertySqFtExpiringInTwelveMonths)}
				</PropertyDetailsBoxContent>
			</PropertyDetailBox>
			<PropertyDetailBox>
				<PropertyDetailsBoxHeader>Avg. lease size</PropertyDetailsBoxHeader>
				<PropertyDetailsBoxContent>
					{property.propertyAverageTransactionSize == null
						? nullValueText
						: abbreviateNumber(property.propertyAverageTransactionSize)}
				</PropertyDetailsBoxContent>
			</PropertyDetailBox>
			<PropertyDetailBox>
				<PropertyDetailsBoxHeader>Last sale price</PropertyDetailsBoxHeader>
				<PropertyDetailsBoxContent>
					{truncatedLastSalePrice ?? nullValueText}
				</PropertyDetailsBoxContent>
			</PropertyDetailBox>
		</PropertyDetailsGrid>
	);
};

const propertyDetailsFields: (keyof PropertyComp)[] = [
	'buildingFloorsCount',
	'propertySqFtExpiringInTwelveMonths',
	'propertyAverageTransactionSize',
	'lastSalePrice',
];

const PropertyDetailsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const PropertyDetailBox = styled.div`
	height: 70px;
	border: 1px solid #222530;
	padding: 10px;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 400;
	line-height: 1.5;
	text-transform: uppercase;
	text-align: left;
`;

const PropertyDetailsBoxHeader = styled.div`
	font-size: 10px;
	color: #ffffff;
	opacity: 0.5;
	margin-bottom: 5px;
	letter-spacing: 0.6px;
	text-transform: uppercase;
`;

const PropertyDetailsBoxContent = styled.div`
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 100;
	font-size: 14px;
	color: white;
	letter-spacing: 0.4px;
`;
