import { MAP_CONTROLS } from 'actions/search';
import { MapFilterBase } from './MapFilterBase';
import IconRadiusMapFilter from 'ui/svg_icons/map-radius-icon.svg';
import { RadiusFilter } from '../RadiusFilter/RadiusFilter';

export const RadiusMapFilter = () => {
	return (
		<MapFilterBase
			Icon={IconRadiusMapFilter}
			key={MAP_CONTROLS.RADIUS}
			label="Radius Filter"
			mapControl={MAP_CONTROLS.RADIUS}
		>
			{(childrenProps) => <RadiusFilter {...childrenProps} />}
		</MapFilterBase>
	);
};
