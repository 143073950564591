import { API, APIClientResponse } from '@compstak/common';
import {
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { useUserQuery } from 'api/user';
import { qcRetryOnlyAuth } from 'api/utils/queryRetries';
import { useIsExchange } from 'hooks';
import { useAddCreditsToUser, useUserId } from 'hooks/userHooks';
import { mapUrlToGateway } from 'utils/gatewayUtil';
import { z } from 'zod';

// https://compstak.atlassian.net/wiki/spaces/DS/pages/2841870405/In-App+Survey+API+Design#Response%3A
const questionDataValidator = z.object({
	id: z.number(),
	type: z.string(),
	content: z.unknown().optional(),
	done: z.boolean().optional(),
});

const answerResponseValidator = z.object({
	rewardCredits: z.number(),
});

const currentBatchQuestionSkippingPromises = [] as Array<
	Promise<APIClientResponse<unknown>>
>;

export type SurveyQuestion = z.infer<typeof questionDataValidator>;

export function useSurveyQuestionsQuery(): UseQueryResult<SurveyQuestion[]> {
	const userId = useUserId();
	const isExchange = useIsExchange();
	return useQuery({
		queryKey: [QUERY_KEYS.SURVEY_QUESTIONS, userId],
		queryFn: async () => {
			// First await all question skipping, to not get skipped questions again.
			await Promise.allSettled(currentBatchQuestionSkippingPromises);
			// Clear the skipped questions array.
			currentBatchQuestionSkippingPromises.length = 0;

			const { data } = await API.post(
				mapUrlToGateway(`/user-service/surveys/users/${userId}/questions/fetch`)
			);

			const validatedData = z
				.object({ questions: z.array(questionDataValidator) })
				.parse(data);
			return validatedData.questions;
		},
		enabled: userId != null && isExchange,
		retry: qcRetryOnlyAuth,
	});
}

function useMarkQuestionAsDone() {
	const qc = useQueryClient();
	const userId = useUserId();
	const { data: questions } = useSurveyQuestionsQuery();
	return (targetQuestionId: number) => {
		if (!questions) {
			return;
		}
		const unansweredQuestions = questions.filter((q) => !q.done);
		if (
			unansweredQuestions.length === 1 &&
			unansweredQuestions[0].id === targetQuestionId
		) {
			// Trigger the fetching of next batch if all questions are done.
			qc.invalidateQueries([QUERY_KEYS.SURVEY_QUESTIONS, userId]);
			return;
		}

		qc.setQueryData<SurveyQuestion[]>(
			[QUERY_KEYS.SURVEY_QUESTIONS, userId],
			(prevQuestions) => {
				if (prevQuestions == null) {
					return prevQuestions;
				}
				return prevQuestions.map((question) => {
					if (question.id === targetQuestionId) {
						return { ...question, done: true };
					}
					return question;
				});
			}
		);
	};
}

export function useAnswerQuestionMutation() {
	const markDone = useMarkQuestionAsDone();
	const addCreditsToUser = useAddCreditsToUser();
	const { data: user } = useUserQuery();
	return useMutation({
		mutationFn: async function answer({
			questionId,
			questionType,
			questionedAt,
			content,
			abandon,
			skip,
		}: {
			questionId: SurveyQuestion['id'];
			questionType: SurveyQuestion['type'];
			questionedAt: Date;
		} & (
			| {
					abandon?: never;
					skip?: true;
					content: unknown;
			  }
			| {
					abandon: true;
					skip?: never;
					content?: never;
			  }
		)) {
			if (!user) {
				throw new Error('User not logged in');
			}
			const apiPromise = API.post(
				mapUrlToGateway(
					`/user-service/surveys/users/${user.id}/questions/${questionId}/answer`
				),
				{
					type: questionType,
					answeredAt: new Date(),
					questionedAt,
					content,
					remainingCredits: user.pointsAvailable,
					// We skip when a user doesn't know the answer.
					// We abandon if the question UI crashed for an unforseen reason,
					// and the user "skipped" the crashed question.
					abandoned: !!abandon,
					skipped: !!skip,
				}
			);
			if (abandon || skip) {
				// If we are skipping the UI shouldn't await the request.
				// In case of skipping the UI shouldn't react to failures.
				// The skips will be handled in the next question batch fetch.
				currentBatchQuestionSkippingPromises.push(apiPromise);
			} else {
				const { data } = await apiPromise;
				const { rewardCredits } = answerResponseValidator.parse(data);
				addCreditsToUser(rewardCredits);
			}
			markDone(questionId);
		},
		retry: qcRetryOnlyAuth,
	});
}

type SurveyBannerState = {
	shown: boolean;
};

export function useSurveyBannerStateQuery(): UseQueryResult<SurveyBannerState> {
	return useQuery({
		queryKey: [QUERY_KEYS.SURVEY_BANNER_STATE],
		queryFn: async () => {
			return {
				shown: true,
			};
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,
	});
}

export function useHideSurveyBannerMutation() {
	const qc = useQueryClient();
	return useMutation({
		mutationFn: async () => {
			qc.setQueryData<SurveyBannerState>(
				[QUERY_KEYS.SURVEY_BANNER_STATE],
				() => ({
					shown: false,
				})
			);
		},
		retry: false,
	});
}
