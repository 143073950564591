import { ButtonProps } from '@compstak/ui-kit';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import styled from 'styled-components';
import { UnlockButton } from './UnlockButton';
import { useUnlockButtonShortText } from './useUnlockButtonShortText';
import { UseUnlockCompsProps, useUnlockComps } from './useUnlockComps';

type Props = {
	comp: { id: number; cost: number };
} & ButtonProps &
	Pick<UseUnlockCompsProps, 'onUnlockLeasesSuccess' | 'type'>;

export const UnlockButtonRow = ({
	type,
	comp,
	onUnlockLeasesSuccess,
	...btnProps
}: Props) => {
	const text = useUnlockButtonShortText(comp);

	const { unlock, disabled, isLoading } = useUnlockComps({
		comps: [comp],
		type,
		onUnlockLeasesSuccess,
	});

	return (
		<StyledUnlockButton
			size="m"
			{...btnProps}
			disabled={disabled}
			isLoading={isLoading}
			onClick={() => {
				unlock({ ids: [comp.id] });
			}}
		>
			<LockIconStyled width={10} /> {text}
		</StyledUnlockButton>
	);
};

const StyledUnlockButton = styled(UnlockButton)`
	min-width: 70px;
	font-size: 12px;
	gap: 0.25rem;
`;
