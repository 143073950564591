import IconDocument from 'ui/svg_icons/document.svg';
import { PopupActionButton, StyledArrowRightIcon } from './UI';

type Props = {
	propertyId: number;
	children: React.ReactNode;
};

export const PropertyPageLinkV2 = ({ propertyId, children }: Props) => {
	return (
		<PopupActionButton href={`/property/${propertyId}`} icon={<IconDocument />}>
			{children}
			<StyledArrowRightIcon />
		</PopupActionButton>
	);
};
