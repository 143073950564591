import { useMemo } from 'react';
import { Portfolio, PortfolioFilterQuery } from 'api';

export type PortfolioStats = {
	propertyCount: number | undefined;
	activeLeaseCount: number | undefined;
	totalSqFt: number | undefined;
	expiringSqFt24Months: number | undefined;
	marketsCount: number | undefined;
};

export const portfolioHasNoLeases = (portfolio: Portfolio) =>
	portfolio.markets.every((market) => market.activeLeaseCount === 0);

export const usePortfolioStats = (
	portfolio: Portfolio | undefined,
	filters: PortfolioFilterQuery
): PortfolioStats => {
	return useMemo(() => {
		if (!portfolio)
			return {
				propertyCount: undefined,
				activeLeaseCount: undefined,
				totalSqFt: undefined,
				expiringSqFt24Months: undefined,
				marketsCount: undefined,
			};
		const noLeases = portfolioHasNoLeases(portfolio);
		let propertyCount = 0;
		let activeLeaseCount = 0;
		let totalSqFt = 0;
		let expiringSqFt24Months = 0;
		let marketsCount = 0;

		portfolio.markets.map((market) => {
			propertyCount +=
				filters.leaseExpirationsMonths !== null
					? market.propertyThatHasActiveLeasesCount
					: market.propertyCount;
			activeLeaseCount += market.activeLeaseCount;
			totalSqFt += market.totalSqFt;
			expiringSqFt24Months += market.expiringSqFt24Months;
			marketsCount++;
		});

		if (noLeases)
			return {
				propertyCount,
				marketsCount,
				activeLeaseCount,
				totalSqFt: undefined,
				expiringSqFt24Months: undefined,
			};

		return {
			propertyCount,
			activeLeaseCount,
			totalSqFt,
			expiringSqFt24Months,
			marketsCount,
		};
	}, [portfolio, filters.leaseExpirationsMonths]);
};
