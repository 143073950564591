import { SaveChartModal } from 'Pages/Analytics/Builder/Components/Modals/Save/SaveChartModal';
import { Chart } from 'Pages/Analytics/analytics';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useNavigate } from 'router';
import { useModal } from 'providers/ModalProvider';
import { useDispatch } from 'react-redux';
import { resetChartBuilder } from 'Pages/Analytics/Builder/actions';
import { useMarkets } from 'hooks/useMarkets';

type Props = {
	newChart: Chart;
};

export const SaveBeforeOpeningModal = ({ newChart }: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const markets = useMarkets();

	const { openModal, closeModal } = useModal();

	const resetAndContinue = () => {
		dispatch(resetChartBuilder(newChart, markets));
		closeModal();
		navigate('/analytics');
	};

	const showSaveModal = () => {
		closeModal();
		openModal({
			modalContent: (
				<SaveChartModal onSave={resetAndContinue} chartDraft={newChart} />
			),
		});
	};

	return (
		<>
			<ModalTitle>Chart in Progress</ModalTitle>
			<ModalParagraph>
				You currently have an Analytics chart in progress.
			</ModalParagraph>
			<ModalParagraph>
				Would you like to save your changes to your current analytics chart?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Return to Projects
				</ModalButton>
				<ModalButton onClick={resetAndContinue} variant="secondary">
					Discard
				</ModalButton>
				<ModalButton onClick={showSaveModal}>Save</ModalButton>
			</ModalButtons>
		</>
	);
};
