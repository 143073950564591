import styled from 'styled-components';
import ExcelIcon from '../../../ui/svg_icons/export-excel.svg';
import PDFIcon from '../../../ui/svg_icons/export_pdf.svg';

export const ExportFormWrap = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ExportPopupContainer = styled.div<{ isBodyRendered: boolean }>`
	max-width: ${({ isBodyRendered }) => (isBodyRendered ? 448 : 240)}px;
	padding: 6px;
	box-sizing: content-box;
`;

export const SubHeader = styled.div`
	font-size: 10px;
	white-space: initial;
	text-transform: none;
`;

export const ExcelHeaderWrap = styled.div`
	display: flex;
	align-items: center;
`;

export const BackIconStyled = styled.span`
	margin-right: 5px;
	svg path.CompstakSvgPath {
		stroke: ${({ theme }) => theme.colors.neutral.n0};
	}
`;

export const ExcelIconStyled = styled(ExcelIcon)`
	fill: ${({ theme }) => theme.colors.neutral.n0};
`;

export const PDFIconStyled = styled(PDFIcon)`
	fill: ${({ theme }) => theme.colors.neutral.n0};
`;

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 32px;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
`;

export const MarginBottom = styled.div`
	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

export const HeaderCloseWrap = styled.span`
	width: 14px;
	height: 14px;
	fill: ${({ theme }) => theme.colors.neutral.n0};
	cursor: pointer;
	margin-left: 8px;
`;

export const IconContainerStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;
