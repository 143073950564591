import React from 'react';
import Select, { StylesConfig, OptionTypeBase } from 'react-select';
import styled from 'styled-components';

import LineIcon from '../../ui/svg_icons/chart_line.svg';
import BarIcon from '../../ui/svg_icons/chart_bar.svg';

import button from 'ui/styles/button.less';
import { ChartType, DataSetType } from 'Pages/Analytics/analytics';
import popup from 'Components/Popup/popup.less';
import { renderToStaticMarkup } from 'react-dom/server';
import {
	yPropValuesCommercial,
	yPropValuesMufa,
	YPropValue,
} from 'Pages/Analytics/Builder/chartBuilderConstants';

interface YSeriesSelectorProps {
	onChange: (valueY: string) => void;
	value: string;
	dataSetType?: DataSetType;
}

export class YSeriesSelector extends React.Component<YSeriesSelectorProps> {
	handleChange = (valueEntry: YPropValue) => {
		this.props.onChange(valueEntry.value.y);
	};
	render() {
		const yPropValues =
			this.props.dataSetType === DataSetType.MUFA
				? yPropValuesMufa
				: yPropValuesCommercial;
		return (
			<Select
				data-qa-id="YSeriesSelector-select"
				{...this.props}
				// @ts-expect-error TS2769: No overload matches this call....
				onChange={this.handleChange}
				value={yPropValues.find(
					(option) => this.props.value === option.value.y
				)}
				options={yPropValues}
				isClearable={false}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'styles' does not exist on type 'Readonly... Remove this comment to see the full error message
				styles={this.props.styles}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'components' does not exist on type 'Read... Remove this comment to see the full error message
				components={this.props.components}
				isSearchable={false}
				classNamePrefix="dropdown-selector"
			/>
		);
	}
}

type MovingAverageSelectorProps = {
	// @ts-expect-error TS7051: Parameter has a name but no ty...
	onChange?: (string) => void;
	value: number | undefined;
	styles: StylesConfig<OptionTypeBase, boolean>;
};

export class MovingAverageSelector extends React.Component<MovingAverageSelectorProps> {
	// @ts-expect-error TS7006: Parameter 'valueEntry' implici...
	handleChange = (valueEntry) => {
		this.props.onChange?.(valueEntry.value);
	};
	render() {
		return (
			<Select
				onChange={this.handleChange}
				value={{
					value: this.props.value,
					label: this.props.value + ' mo moving',
				}}
				options={[
					{ value: 3, label: '3 mo moving' },
					{ value: 6, label: '6 mo moving' },
					{ value: 12, label: '12 mo moving' },
				]}
				styles={this.props.styles}
				isSearchable={false}
				classNamePrefix="dropdown-selector"
				data-qa-id="moving-average-selector"
			/>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export const TimespanSelector = (props) => {
	// @ts-expect-error TS7006: Parameter 'valueEntry' implici...
	const handleChange = (valueEntry) => {
		props.onChange(valueEntry.value);
	};

	return (
		<Select
			onChange={handleChange}
			value={{
				value: props.value,
				label: props.value / 12 + 'Y',
			}}
			options={[
				{ value: 180, label: '15Y' },
				{ value: 120, label: '10Y' },
				{ value: 60, label: '5Y' },
				{ value: 36, label: '3Y' },
				{ value: 12, label: '1Y' },
			]}
			styles={props.styles}
			isSearchable={false}
			classNamePrefix="dropdown-selector"
		/>
	);
};

interface GraphTypeSelectorProps {
	hasMultiFamilyDatasets: boolean;
	value: 'line' | 'histogram';
	onChange: (chartType: ChartType) => void;
}

export class GraphTypeSelector extends React.Component<GraphTypeSelectorProps> {
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleChange = (event) => {
		this.props.onChange(event.currentTarget.dataset.chartType);
	};

	render() {
		const tooltip: string = renderToStaticMarkup(
			<>Histogram not available when Multifamily data is visible</>
		);

		return (
			<div className={button.switch}>
				<ul>
					<li className={this.props.value === 'line' ? button.active : ''}>
						<div
							className={button.button}
							data-chart-type="line"
							onClick={this.handleChange}
						>
							<IconWrapper>
								<LineIcon width={25} height={21} />
							</IconWrapper>
						</div>
					</li>
					<li
						className={this.props.value === 'histogram' ? button.active : ''}
						data-tooltip-position="above-onright"
						data-tooltip-delay="0"
						data-tooltip-class={popup.histogram}
						data-tooltip={this.props.hasMultiFamilyDatasets ? tooltip : ''}
					>
						<div
							className={
								this.props.hasMultiFamilyDatasets
									? button.disabled
									: button.button
							}
							data-chart-type="histogram"
							onClick={
								this.props.hasMultiFamilyDatasets
									? undefined
									: this.handleChange
							}
						>
							<IconWrapper>
								<BarIcon width={23} height={21} />
							</IconWrapper>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

const IconWrapper = styled.span`
	svg path {
		fill: #ffffff;
	}
`;
