import { useState } from 'react';

type ActiveMfaScreen =
	| 'no-mfa-screen'
	| 'mfa-setup-screen'
	| 'mfa-disable-screen';

export function useActiveMfaSetupScreen() {
	const [activeMfaScreen, setActiveMfaSetupScreen] = useState<ActiveMfaScreen>(
		'no-mfa-screen'
	);
	return {
		activeMfaScreen,
		showSetupMfaScreen: () => setActiveMfaSetupScreen('mfa-setup-screen'),
		showDisableMfaScreen: () => setActiveMfaSetupScreen('mfa-disable-screen'),
		hideActiveMfaScreen: () => setActiveMfaSetupScreen('no-mfa-screen'),
	};
}
