import { SimpleTableColumn } from '@compstak/ui-kit';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import {
	Tenant,
	useTopTenantsQuery,
	TopTenantsResponse,
} from '../../../api/topTenants/useTopTenantsQuery';
import { PremierListTableProps } from '../PremierList';
import dayjs from 'dayjs';
import { getPremierListTableColumns } from './columns';
import { useViewCompLink } from './util';
import { SPACE_TYPE_NAME_TO_ID } from '../../../api';
import { PremierListTableView } from './PremierListTableView';

const DEFAULT_ROWS: TopTenantsResponse['tenants'] = [];
const BASE_LINK_URL = '/search/leases/list';
const FILTER_NAME: keyof FiltersObject = 'tenantNames';

export const ActiveTenantsTable = ({
	commonFilters,
	selectedSpaceTypeName,
}: PremierListTableProps) => {
	const filters: Partial<FiltersObject> = useMemo(
		() => ({
			spaceTypeId: [SPACE_TYPE_NAME_TO_ID[selectedSpaceTypeName]],
			executionDate: {
				max: dayjs().toDate(),
				min: dayjs().subtract(1, 'year').toDate(),
			},
		}),
		[selectedSpaceTypeName]
	);

	const localFilters = useMemo(
		() => ({ ...filters, ...commonFilters }),
		[commonFilters, filters]
	);

	const { data, isFetching } = useTopTenantsQuery({ filters: localFilters });
	const rows = data?.tenants ?? DEFAULT_ROWS;

	const columns: SimpleTableColumn<Tenant>[] = useMemo(
		() =>
			getPremierListTableColumns<Tenant>({
				filterName: FILTER_NAME,
				baseLinkUrl: BASE_LINK_URL,
				filters: localFilters,
				nameColumn: {
					header: 'Tenant Name',
				},
				volumeColumn: {
					header: 'Space Leased (SQFT K)',
					key: 'leasedSqFt',
					numberProcess: (value) => value / 1e3,
				},
				countColumn: {
					key: 'totalLeasesCount',
					header: 'Active Leases',
				},
			}),
		[localFilters]
	);

	const viewLeaseCompsHref = useViewCompLink({
		filterName: FILTER_NAME,
		baseLinkUrl: BASE_LINK_URL,
		filters: localFilters,
		rows,
	});

	return (
		<PremierListTableView
			data={rows}
			buttonHref={viewLeaseCompsHref}
			buttonText="View Lease Comps"
			title="Tenant Activity"
			description={`Tenants that leased the most ${selectedSpaceTypeName.toLowerCase()} space in the last 365 days in ${commonFilters.markets[0].displayName} according to CompStak’s available data.`}
			columns={columns}
			id="ActiveTenantTable"
			isFetching={isFetching}
		/>
	);
};
