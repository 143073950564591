export const getRentPeriodPostfix = (
	isMonthly?: boolean,
	options?: { shorten?: boolean; withParens?: boolean }
) => {
	const shorten = options?.shorten ?? true;
	const withParens = options?.withParens ?? true;

	const base = isMonthly
		? shorten
			? 'Mon.'
			: 'Monthly'
		: shorten
			? 'Ann.'
			: 'Annual';

	return withParens ? `(${base})` : base;
};
