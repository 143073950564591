import { Spinner } from '@compstak/ui-kit';
import {
	SurveyQuestion,
	useAnswerQuestionMutation,
} from 'api/surveyForCredits/surveyQuestionQueries';
import { useState } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { SingleQuestionFailed } from '../questionComponents';
import { QuestionSidebarBtns } from '../questionSidebarComponents';
import { SurveyPropertyAddress } from './components/PropertyAddress';
import { PropertyDetailsKvTable } from './components/PropertyDetailsKvTable';
import { ResponsiveMapSwitcher } from './components/ResponsiveMapSwitcher';
import { QuestionContentContainers1 } from './components/contentContainers';
import { SurveyNumInput } from './components/surveyInputs';
import { useExpectedLoadTimePassedForProperty } from './utils/loadTimePassed';
import { useGetQuestionParams } from './utils/useGetQuestionParams';
import { usePropertyByIdQuery } from 'api';

const INT_REQUIRED = 'Please enter an integer value';

export function IndustrialQuestionSidebar({
	question,
}: {
	question: SurveyQuestion;
}) {
	const { subjectPropertyId: propertyId } = contentValidator.parse(
		question.content
	);
	const [clearHeights, setClearHeights] = useState('');
	const [loadingDocks, setLoadingDocks] = useState('');
	const [groundLevelDoors, setGroundLevelDoors] = useState('');

	const {
		mutateAsync: answer,
		isLoading: answerPending,
		isError: answerFailed,
	} = useAnswerQuestionMutation();

	const {
		data,
		isLoading: isLoadingProperty,
		isError: propertyFetchFailed,
	} = usePropertyByIdQuery({ id: propertyId });

	const isLoadingLongerThanExpected =
		useExpectedLoadTimePassedForProperty(propertyId) && isLoadingProperty;

	const questionParams = useGetQuestionParams(question);

	const propertyContent = {
		subjectProperty: {
			propertyId,
			address: data?.buildingAddressAndCity ?? data?.buildingAddress,
			city: data?.city,
			latitude: data?.geoPoint?.lat,
			longitude: data?.geoPoint?.lon,
			marketStartingRent: data?.propertyMarketStartingRent,
			yearBuilt: data?.buildingYear,
			yearRenovated: data?.buildingYearRenovated,
			floors: data?.buildingFloorsCount,
			size: data?.buildingSize,
		},
	};

	const submitEnabled = Boolean(
		!isLoadingProperty &&
			!propertyFetchFailed &&
			(strIsFloatLargerOrEq0(clearHeights) ||
				[loadingDocks, groundLevelDoors].some(strIsIntegerLargerOrEq0))
	);

	return (
		<>
			<b>Question</b>
			<QuestionAndInputDiv>
				<div>What is the Clear Height (ft) of this property?</div>
				<SurveyNumInput
					value={clearHeights}
					onChange={setIfPositiveOr0(setClearHeights)}
					data-qa-id="clear-height-input"
					{...inputProps}
				/>
			</QuestionAndInputDiv>
			<QuestionAndInputDiv>
				<div>How many loading docks are available at this property?</div>
				<SurveyNumInput
					value={loadingDocks}
					onChange={setIfPositiveOr0(setLoadingDocks)}
					data-qa-id="loading-docks-input"
					errorText={
						loadingDocks && !strIsIntegerLargerOrEq0(loadingDocks)
							? INT_REQUIRED
							: undefined
					}
					{...inputProps}
				/>
			</QuestionAndInputDiv>
			<QuestionAndInputDiv>
				<div>How many ground level doors are available at this property?</div>
				<SurveyNumInput
					value={groundLevelDoors}
					onChange={setIfPositiveOr0(setGroundLevelDoors)}
					data-qa-id="ground-level-doors-input"
					errorText={
						groundLevelDoors && !strIsIntegerLargerOrEq0(groundLevelDoors)
							? INT_REQUIRED
							: undefined
					}
					{...inputProps}
				/>
			</QuestionAndInputDiv>
			<QuestionSidebarBtns
				onSubmit={() =>
					answer({
						...questionParams,
						content: {
							...propertyContent,
							submittedCeilingHeight: strToNumberOrNull(clearHeights),
							submittedLoadingDocks: strToNumberOrNull(loadingDocks),
							submittedGroundLevelDoors: strToNumberOrNull(groundLevelDoors),
						},
					})
				}
				onSkip={() => {
					const shouldAbandon =
						propertyFetchFailed || isLoadingLongerThanExpected;
					answer({
						...questionParams,
						...(shouldAbandon
							? { abandon: true }
							: {
									skip: true,
									content: propertyContent,
								}),
					});
				}}
				{...{ submitEnabled }}
				skipDisabled={isLoadingProperty && !isLoadingLongerThanExpected}
				submitting={answerPending}
				submitFailed={answerFailed}
			/>
		</>
	);
}

const contentValidator = z.object({ subjectPropertyId: z.number() });

export function IndustrialQuestionContent({
	question,
}: {
	question: SurveyQuestion;
}) {
	const { subjectPropertyId: propertyId } = contentValidator.parse(
		question.content
	);

	const {
		data: property,
		isLoading: isLoadingProperty,
		isError: propertyFetchFailed,
	} = usePropertyByIdQuery({ id: propertyId });
	const geoPoint = property?.geoPoint;

	return (
		<>
			{isLoadingProperty ? (
				<Spinner isCentered />
			) : propertyFetchFailed ? (
				<SingleQuestionFailed />
			) : (
				<MainContainer>
					<LeftSideContainer>
						<SurveyPropertyAddress {...{ property }} />
						<PropertyDetailsKvTable {...{ property }} hideFloors />
					</LeftSideContainer>

					<RightSideContainer>
						{geoPoint ? (
							<ResponsiveMapSwitcher {...{ geoPoint }} />
						) : (
							<div>No google maps for this property</div>
						)}
					</RightSideContainer>
				</MainContainer>
			)}
		</>
	);
}

function setIfPositiveOr0(setVal: (val: string) => void) {
	return (val: string) => {
		if (Number(val) >= 0) {
			setVal(val);
		}
	};
}

function strIsFloatLargerOrEq0(str: string): boolean {
	if (str.trim() === '') return false;
	return Number(str) >= 0;
}

function strIsIntegerLargerOrEq0(str: string): boolean {
	if (str.trim() === '') return false;
	const num = Number(str);
	return Number.isInteger(num) && num >= 0;
}

function strToNumberOrNull(str: string): number | null {
	if (str.trim() === '') return null;
	const num = Number(str);
	return isNaN(num) ? null : num;
}

const inputProps = { placeholder: 'Type an answer here', min: '0' };

const { MainContainer, LeftSideContainer, RightSideContainer } =
	QuestionContentContainers1;

const QuestionAndInputDiv = styled.div`
	margin-top: 12px;
`;
