import { FloorPlans } from 'Pages/PropertyPageV3/MultifamilyTab/FloorPlans';
import { KeyMetrics } from 'Pages/PropertyPageV3/MultifamilyTab/KeyMetrics';
import { Summary } from 'Pages/PropertyPageV3/MultifamilyTab/Summary';
import styled from 'styled-components';
import { PropertyEditPage } from '../PropertyEditPage';
import { Container, EditPageSectionTitle } from '../UI';

export const EditMultifamilyPage = () => {
	return (
		<PropertyEditPage pageKey="multifamily">
			<Container>
				<EditPageSectionTitle>Multifamily overview</EditPageSectionTitle>
				<Summary isExportPDF />
				<KeyMetrics />
				<FloorPlansContainer>
					<FloorPlans isExportPDF />
				</FloorPlansContainer>
			</Container>
		</PropertyEditPage>
	);
};

const FloorPlansContainer = styled.div`
	margin: 1.5rem 0;
`;
