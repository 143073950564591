import constate from 'constate';
import { useSelectionState } from 'utils/useSelectionState';
import { useListViewProperties } from './useListViewProperties';

type Props = {
	portfolioId: number;
};

export const [
	PropertiesListAndMapViewsProvider,
	usePropertiesListAndMapViewContext,
] = constate(({ portfolioId }: Props) => {
	const {
		data: properties,
		isLoading,
		isFetching,
	} = useListViewProperties({
		portfolioId,
	});

	const selectionState = useSelectionState(properties);

	return {
		properties,
		isLoading,
		isFetching,
		...selectionState,
	};
});
