import { numberUtils, Spinner } from '@compstak/ui-kit';
import {
	DataSet,
	DataSetType,
	DataSetStats,
	InsightDataResponse,
} from 'Pages/Analytics/analytics';

export const getDatasetStats = ({
	insightsDataItem,
	timespan,
}: {
	insightsDataItem: InsightDataResponse;
	timespan: number | undefined;
}) => {
	return insightsDataItem?.data?.summaries?.find(
		(summary) => summary.months === timespan
	);
};

export enum CounterType {
	COMPS = 'comps',
	SQFT = 'sqft',
}

type StatsKeys = keyof DataSetStats;

export const getStatsCounter = ({
	counterType,
	stats,
	dsType,
	isLoading,
	isSuccess,
}: {
	counterType: CounterType;
	stats: DataSetStats | undefined;
	dsType: DataSetType | undefined;
	isLoading: boolean | undefined;
	isSuccess: boolean | undefined;
}) => {
	if (isLoading) return <Spinner size="xs" />;

	if (isSuccess && stats && dsType) {
		const counter = stats[counterTypeToStatsKeys[counterType][dsType]];
		if (typeof counter === 'number') {
			return counter >= 1000
				? numberUtils.formatNumber(counter, 'oneDecimal', 'internationalSystem')
				: numberUtils.formatNumber(counter, 'integer', 'internationalSystem');
		}
	}

	return 0;
};

export const findDSIndexInDataArray = (
	dataSets: DataSet[],
	dataSet: DataSet
) => {
	return dataSets.findIndex((ds) => dataSet.id === ds.id);
};

export const getCounterTooltip = ({
	counterType,
	stats,
	dsType,
}: {
	counterType: CounterType;
	stats: DataSetStats | undefined;
	dsType: DataSetType | undefined;
}) => {
	if (stats === undefined) {
		return TOOLTIP_NO_DATA;
	}

	if (dsType) {
		if (stats[counterTypeToStatsKeys[counterType][dsType]] === 0) {
			return TOOLTIP_NO_DATA_FOR_TIMESPAN;
		}

		return dataSetTypeToCounterTooltip[dsType];
	}

	return '';
};

const counterTypeToStatsKeys: Record<
	CounterType,
	Record<DataSetType, StatsKeys>
> = {
	[CounterType.COMPS]: {
		[DataSetType.COMMERCIAL]: 'leases',
		[DataSetType.LEASES]: 'leases',
		[DataSetType.SALES]: 'sales',
		[DataSetType.MUFA]: 'properties',
	},
	[CounterType.SQFT]: {
		[DataSetType.COMMERCIAL]: 'transactionSize',
		[DataSetType.LEASES]: 'transactionSize',
		[DataSetType.SALES]: 'transactionSize',
		[DataSetType.MUFA]: 'buildingSize',
	},
};

const dataSetTypeToCounterTooltip = {
	[DataSetType.COMMERCIAL]: 'Count of Leases',
	[DataSetType.LEASES]: 'Count of Leases',
	[DataSetType.SALES]: 'Count of Sales',
	[DataSetType.MUFA]: 'Count of Properties',
};

const TOOLTIP_NO_DATA = `There are no&nbsp;values for this attribute in&nbsp;this data set, Please select another attribute or&nbsp;add a&nbsp;new data set.`;

const TOOLTIP_NO_DATA_FOR_TIMESPAN = `There are no&nbsp;values for this timespan in&nbsp;this data set. 
Please adjust the timespan, select another attribute, or&nbsp;add a&nbsp;new data set.`;
