import { useCallback, useEffect } from 'react';
import {
	PrevNextContainer,
	PrevContainer,
	NextContainer,
	CenteredContainer,
	Link,
	UnderlinedLink,
	NumberOfResults,
	StyledIconArrowLeft,
	StyledIconArrowRight,
	StyledSpinner,
} from '../../../../ui/PrevNextHeaderUI';
import { Spinner } from '@compstak/ui-kit';
import { useNavigate } from 'router';
import { LegacyRouter, LegacyRouterLocation } from 'router/migration/types';

type PrevNextLeaseHeaderProps = {
	index: number;
	numberOfComps: number | undefined;
	location: LegacyRouterLocation;
	router: LegacyRouter;
	prevCompId?: number;
	nextCompId?: number;
};

export const PrevNextLeaseHeader = ({
	index,
	numberOfComps,
	location,
	router,
	prevCompId,
	nextCompId,
}: PrevNextLeaseHeaderProps) => {
	const onBackToList = useCallback(() => {
		location.state ? router.goBack() : router.push('/search/leases/list');
	}, [location.state]);

	const queryString = location.state && location.state.queryString;
	const numberOfCompsFromMarketDashboard = location.state?.numberOfComps;

	const totalComps = numberOfComps ?? numberOfCompsFromMarketDashboard;

	const navigate = useNavigate();

	const onPrevComp = useCallback(() => {
		if (prevCompId || location.state.prevCompId) {
			navigate(`/comps/leases/${prevCompId ?? location.state.prevCompId}`, {
				replace: true,
				state: {
					index: index - 1,
					queryString,
					prevCompId: location.state?.leaseCompsIds?.[index - 1],
					nextCompId: router.params.id,
					numberOfComps: numberOfCompsFromMarketDashboard,
					leaseCompsIds: location.state.leaseCompsIds,
				},
			});
		}
	}, [prevCompId, index, queryString, navigate]);

	const onNextComp = useCallback(() => {
		if (nextCompId || location.state.nextCompId) {
			navigate(`/comps/leases/${nextCompId ?? location.state.nextCompId}`, {
				replace: true,
				state: {
					index: index + 1,
					queryString,
					prevCompId: router.params.id,
					nextCompId: location.state?.leaseCompsIds?.[index + 1],
					numberOfComps: numberOfCompsFromMarketDashboard,
					leaseCompsIds: location.state.leaseCompsIds,
				},
			});
		}
	}, [nextCompId, index, queryString, navigate]);

	useEffect(() => {
		const handleKeyNavigation = (ev: KeyboardEvent) => {
			if (ev.key === 'ArrowLeft') onPrevComp();
			if (ev.key === 'ArrowRight') onNextComp();
		};
		window.addEventListener('keydown', handleKeyNavigation);
		return () => {
			window.removeEventListener('keydown', handleKeyNavigation);
		};
	}, [onPrevComp, onNextComp]);

	return (
		location.state && (
			<PrevNextContainer>
				<PrevContainer>
					{index > 0 &&
						(prevCompId || location.state.prevCompId ? (
							<Link onClick={onPrevComp}>
								<StyledIconArrowLeft />
								Previous result
							</Link>
						) : (
							<StyledSpinner size="s" />
						))}
				</PrevContainer>
				<CenteredContainer>
					{!isNaN(index + 1) && (
						<NumberOfResults>
							Viewing lease result {index + 1} of&nbsp;
							{totalComps ?? <Spinner size="s" />}
						</NumberOfResults>
					)}
					<UnderlinedLink onClick={onBackToList}>Back to list</UnderlinedLink>
				</CenteredContainer>
				<NextContainer>
					{totalComps &&
						index !== totalComps - 1 &&
						(nextCompId || location.state.nextCompId ? (
							<Link onClick={onNextComp}>
								Next result
								<StyledIconArrowRight />
							</Link>
						) : (
							<StyledSpinner size="s" />
						))}
				</NextContainer>
			</PrevNextContainer>
		)
	);
};
