import React from 'react';
import { Navigate } from 'react-router-dom';
import { NavigateProps, useParams } from 'react-router';

/**
 * Wraps the <Navigate> component and replaces "/:<paramName>" with "/<paramValue"
 */
export const Redirect = (props: Omit<NavigateProps, 'to'> & { to: string }) => {
	const params = useParams();
	const entries = Object.entries(params);
	let toPath = props.to;

	entries.forEach(([key, value]) => {
		toPath = toPath.replace(`:${key}`, `${value}`);
	});

	return <Navigate {...props} to={toPath} replace />;
};
