import { useUserQuery } from 'api';
import { ExportPdfInput } from 'pdf/ExportPdfInput';
import { InfoLabel, InfoRow, InfoValue } from 'pdf/UI';
import styled from 'styled-components';
import { AveragesSection } from '../Averages/AveragesSection';
import { DashboardEditPage } from '../DashboardEditPage';
import { useDashboardExportContext } from '../DashboardExportProvider';
import { useExportPdfState } from '../ExportPdfProvider';

type Props = {
	marketText: string;
	submarketText: string;
	propertyTypesText: string;
	createdOnText: string;
};

export const EditHeaderPage = ({
	marketText,
	submarketText,
	propertyTypesText,
	createdOnText,
}: Props) => {
	const { data: user } = useUserQuery();
	const { exportPdfState, setExportPdfState } = useExportPdfState();
	const { selectedComponents } = useDashboardExportContext();

	return (
		<DashboardEditPage>
			<Container>
				<div>
					<InfoRow>
						<InfoLabel>Market</InfoLabel>
						<InfoValue>{marketText}</InfoValue>
					</InfoRow>
					<InfoRow>
						<InfoLabel>Submarket</InfoLabel>
						<InfoValue>{submarketText}</InfoValue>
					</InfoRow>
					<InfoRow>
						<InfoLabel>Property Types*</InfoLabel>
						<InfoValue>{propertyTypesText}</InfoValue>
					</InfoRow>
					<NoteText>*Except where noted</NoteText>
				</div>
				<div>
					<InfoRow>
						<InfoLabel>Created On</InfoLabel>
						<InfoValue>{createdOnText}</InfoValue>
					</InfoRow>
					<InfoRow>
						<InfoLabel>Prepared For</InfoLabel>
						<ExportPdfInput
							value={exportPdfState.preparedFor}
							onChange={(e) => {
								setExportPdfState((s) => ({
									...s,
									preparedFor: e.target.value,
								}));
							}}
						/>
					</InfoRow>
					<InfoRow>
						<InfoLabel>Prepared By</InfoLabel>
						<InfoValue>
							<div>
								{user.firstName} {user.lastName}
							</div>
							<div>{user.email}</div>
						</InfoValue>
					</InfoRow>
					<InfoRow>
						<InfoLabel>Notes</InfoLabel>
						<ExportPdfInput
							value={exportPdfState.notes}
							onChange={(e) => {
								setExportPdfState((s) => ({
									...s,
									notes: e.target.value,
								}));
							}}
						/>
					</InfoRow>
				</div>
			</Container>
			{selectedComponents.includes('averages') && <AveragesSection />}
		</DashboardEditPage>
	);
};

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	margin-bottom: 1rem;
`;

const NoteText = styled.div`
	font-size: 0.75rem;
	margin-top: 1rem;
`;
