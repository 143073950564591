import factory from './factory';

import { InsightBodyData } from 'PortfolioAnalytics/portfolioAnalyticsTypes';

const service = factory.create({
	createUrl: () => '/api/comps/insights',
	createData: (data) => data,
	createKey: (identifier) => JSON.stringify(identifier),
	cache: {
		storage: window.sessionStorage,
		ttl: 1000 * 60 * 60, // 1 hour
		namespace: 'insights',
		maxSize: 1024 * 1024 * 6, // 6 MB
	},
});

const exportable = service.exportable({
	portfolioMarketTrend(body: InsightBodyData) {
		return factory.post('/api/comps/insights', body);
	},
});

export default exportable;

export const mufaInsightsService = factory
	.create({
		createUrl: () => '/api/mufa/insights',
		createData: (data) => data,
		createKey: (identifier) => JSON.stringify(identifier),
	})
	.exportable({});

export const salesInsightsService = factory
	.create({
		createUrl: () => '/api/salesComps/insights',
		createData: (data) => data,
		createKey: (identifier) => JSON.stringify(identifier),
	})
	.exportable({});
