import React, { useState } from 'react';
import { Chart, DataSet } from 'Pages/Analytics/analytics';
import { useMarkets } from 'hooks/useMarkets';
import { useDispatch } from 'react-redux';
import { updateDataSet } from 'Pages/Analytics/Builder/actions';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { trimString } from 'util/trimString';

type Props = {
	chart: Chart;
	dataSet: DataSet;
};

export const RenameDataSetModal = ({ chart, dataSet }: Props) => {
	const [title, setTitle] = useState(dataSet.name);

	const markets = useMarkets();
	const { closeModal } = useModal();

	const dispatch = useDispatch();

	const isSubmitDisabled = !trimString(title);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (isSubmitDisabled) {
			event.preventDefault();
			return;
		}
		dispatch(updateDataSet(chart, { ...dataSet, name: title }, markets));
		closeModal();
	};

	return (
		<div>
			<ModalTitle>Rename your data set</ModalTitle>
			<form onSubmit={handleSubmit}>
				<ModalInputLabel>
					Title
					<input
						data-qa-id="dataset-title"
						type="text"
						placeholder="Enter Title"
						onChange={(ev) => setTitle(ev.target.value)}
						value={title}
					/>
				</ModalInputLabel>
				<ModalButtons>
					<ModalButton type="button" variant="secondary" onClick={closeModal}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={isSubmitDisabled}
					>
						Done
					</ModalButton>
				</ModalButtons>
			</form>
		</div>
	);
};
