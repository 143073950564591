import React from 'react';
import ReactDOM from 'react-dom';
// @ts-expect-error TS7016: Could not find a declaration f...
import Clipboard from 'clipboard';

export const copyToClipboardTestId = 'copyToClipboardTestId';

export default class CopyToClipboardButton extends React.Component {
	componentDidMount() {
		const clipboard = new Clipboard(ReactDOM.findDOMNode(this), {
			text: () => {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'textToCopy' does not exist on type 'Read... Remove this comment to see the full error message
				return this.props.textToCopy;
			},
		});

		clipboard.on('success', () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSuccess' does not exist on type 'Reado... Remove this comment to see the full error message
			this.props.onSuccess();
		});

		clipboard.on('error', () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onError' does not exist on type 'Readonl... Remove this comment to see the full error message
			this.props.onError();
		});
	}

	render() {
		return (
			// @ts-expect-error TS2339: Property 'className' does not ...
			<span className={this.props.className} data-qa-id={copyToClipboardTestId}>
				{/* @ts-expect-error TS2339: Property 'children' does not e... */}
				{this.props.children}
			</span>
		);
	}
}
