import { MufaFloorPlan } from 'api';
import { useState } from 'react';
import { getFloorPlansPdfColumns } from './getFloorPlansPdfColumns';

type Props = {
	floorPlans: MufaFloorPlan[] | undefined;
};
export const useEditableMultifamily = ({ floorPlans }: Props) => {
	const columns = getFloorPlansPdfColumns();

	const [editableMultiFamilyRow] = useState(
		floorPlans?.map((row, rowIndex) => {
			const editableRow: Record<string, string | number> = {
				id: row.id,
			};
			columns.forEach((c) => {
				editableRow[c.id] = c.getValue({ row, rowIndex });
			});

			return editableRow;
		})
	);

	return { editableMultiFamilyRow };
};
