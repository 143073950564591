import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { useIsMultifamily } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { filterToServerJSON } from 'models/filters/util';
import { CompType } from 'types';
import { ServerFilterItem } from 'types/serverFilters';
import { encodeQuery } from 'util/encodeQuery';
import { trimString } from 'util/trimString';

const COUNTIES_LIMIT = 4000;

export const useCountySuggestionsQuery = (
	params: UseCountiesQueryParams,
	options?: UseCountiesQueryOptions
) => {
	const {
		compType,
		filters,
		marketId,
		query: _query,
		limit = COUNTIES_LIMIT,
	} = params;
	const query = _query ? trimString(_query) : undefined;
	const hidden = (
		filterToServerJSON(filters?.hidden, 'hidden') as ServerFilterItem<'hidden'>
	).value;
	const partial = (
		filterToServerJSON(
			filters?.partial ?? null,
			'partial'
		) as ServerFilterItem<'partial'>
	).value;
	const queryParams = encodeQuery({
		query,
		limit,
		marketId,
		hidden,
		partial,
	});
	const isMufa = useIsMultifamily({ markets: params.marketId });
	const compTypePrefix =
		compType === 'property'
			? `${isMufa ? 'mufa/' : ''}properties`
			: compType === 'sale'
				? 'salesComps'
				: 'comps';

	return useQuery({
		queryKey: [QUERY_KEYS.COUNTIES, params],
		queryFn: async () => {
			const response = await API.get<CountiesResponse>(
				`/api/${compTypePrefix}/counties${queryParams}`
			);

			return response.data;
		},
		...options,
	});
};

type UseCountiesQueryOptions = UseQueryOptions<CountiesResponse>;

type UseCountiesQueryParams = {
	compType: CompType;
	marketId: number[];
	limit?: number;
	query?: string;
	filters?: FiltersObject;
};

type CountiesResponse = {
	marketCounties: County[];
};

type County = {
	name: string;
	state: string;
	fips: string;
	marketId: number;
};
