import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { CompsetResponse } from 'api/propertyById';
import { createFetchFn } from 'api/utils';

const createFetchProperty = createFetchFn<
	PropertyByMarketAndAddressParams,
	CompsetResponse
>({
	method: 'get',
	getURL: ({ marketId, address }) => `/api/properties/${marketId}/${address}`,
});

export const usePropertyByMarketAndAddressQuery = (
	params: PropertyByMarketAndAddressParams,
	options?: UseQueryOptions<CompsetResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_BY_MARKET_AND_ADDRESS, params],
		queryFn: createFetchProperty(params),
		...options,
	});
};

type PropertyByMarketAndAddressParams = {
	marketId: number;
	address: string;
};
