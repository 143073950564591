import { useMarkets } from 'hooks/useMarkets';

type Params = {
	getComps?: () => { id: number; market?: string; marketId?: number }[];
};

/** @returns a map of markets with associated compIds */
export const useMarketToCompIds = (
	{ getComps }: Params,
	options?: { enabled: boolean }
) => {
	const markets = useMarkets();

	if (!options?.enabled) {
		return {};
	}

	const comps = getComps?.() ?? [];

	const marketToCompIds = comps.reduce<Record<number, number[]>>(
		(acc, { id, market, marketId }) => {
			const compMarket = market ?? marketId;
			const compMarketId = compMarket ? markets[compMarket]?.id : undefined;
			if (compMarketId != null) {
				if (!acc[compMarketId]) {
					acc[compMarketId] = [];
				}
				acc[compMarketId].push(id);
			}
			return acc;
		},
		{}
	);

	return marketToCompIds;
};
