import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

export const useTrendLineDashboard = (
	params: UseTrendLineDashboardParams,
	options?: UseTrendLineDashboardOptions
) => {
	const { filters, trendMonths } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.TREND_LINE_DASHBOARD, params],
		queryFn: async () => {
			const response = await API.post<TrendLineDashboardResponse>(
				'/api/dashboard/trendLine',
				{
					filter: filtersToServerJSON(filters),
					trendMonths,
				}
			);

			return response.data;
		},
		...options,
	});
};

export type UseTrendLineDashboardOptions =
	UseQueryOptions<TrendLineDashboardResponse>;

type UseTrendLineDashboardParams = {
	filters: FiltersObject;
	trendMonths: number;
};

type TrendLineDashboardResponseItem = {
	date: string;
	value: number;
};

type TrendLineDashboardResponse = TrendLineDashboardResponseItem[];
