import { ChartData, isNumber } from '@compstak/ui-kit';

type MinMax = {
	min: number;
	max: number;
};

/** Calculates min and max values from incoming chart data */
export const getMinMaxChartDataValue = (data: ChartData[]): MinMax => {
	if (!data?.length) {
		return {
			min: 0,
			max: 0,
		};
	}

	const allNumberedValues = data?.reduce<number[]>((accumulator, current) => {
		Object.entries(current).forEach(([key, value]) => {
			if (key !== 'name' && isNumber(value)) {
				accumulator.push(Number(value));
			}
		});

		return accumulator;
	}, []);

	return {
		min: Math.min(...allNumberedValues),
		max: Math.max(...allNumberedValues),
	};
};
