import { PropertyTypeId } from 'api';

export const propertyTypeFilterStatus = (
	filtersPropertyTypeId: PropertyTypeId[] | null | undefined
) => {
	const moreThanOnePropertyTypeSelected = !!(
		filtersPropertyTypeId && filtersPropertyTypeId.length > 1
	);
	const incorrectPropertyTypeSelected = !!(
		filtersPropertyTypeId &&
		filtersPropertyTypeId.length === 1 &&
		![1, 7].includes(filtersPropertyTypeId[0])
	);

	const propertyTypeSelected = !!(
		filtersPropertyTypeId && filtersPropertyTypeId.length > 0
	);

	const industrialPropertyTypeSelected =
		filtersPropertyTypeId && filtersPropertyTypeId[0] === 7;

	return {
		moreThanOnePropertyTypeSelected,
		incorrectPropertyTypeSelected,
		propertyTypeSelected,
		industrialPropertyTypeSelected,
	};
};
