import { nullValueText } from '@compstak/common';
import { ExchangePanel } from 'ExchangeDashboard/UI';
import { useReferenceData, useUser } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import { Link } from 'router';
import styled from 'styled-components';
import button from 'ui/styles/button.less';

export const MemberProfile = () => {
	const referenceData = useReferenceData();
	const markets = useMarkets();
	const user = useUser();

	const role = referenceData?.jobTypes?.find(
		(jobType) => jobType.id === user.preferences.jobId
	);
	const specialities = referenceData?.specializations
		?.filter((specialization) =>
			user?.specializationIds?.includes(specialization.id)
		)
		.map((s) => s.name)
		.join(', ');
	const primaryMarket = markets ? markets[user.primaryMarketId] : null;
	const propertyTypePreferences = referenceData?.propertyTypes
		.filter((propertyType) => user?.propertyTypeIds?.includes(propertyType.id))
		.map((pt) => pt.name)
		.join(', ');

	const industryPreferences = referenceData?.tenantIndustries
		.filter((industry) => user?.industryIds?.includes(industry.id))
		.map((i) => i.name)
		.join(', ');

	return (
		<ExchangePanel id="team-profile" title="My Profile">
			<MemberInfoContainer>
				<ul>
					<li>
						<Property>Name</Property>
						<Value>{user.firstName}</Value>
					</li>
					<li>
						<Property>Title</Property>
						<Value>{user.title ?? nullValueText}</Value>
					</li>
					<li>
						<Property>Role</Property>
						<Value>{role?.name ?? nullValueText}</Value>
					</li>
					<li>
						<Property>Speciality</Property>
						<Value>{specialities?.length ? specialities : nullValueText}</Value>
					</li>
					<li>
						<Property>Primary Market</Property>
						<Value>{primaryMarket?.displayName}</Value>
					</li>
					<li>
						<Property>Property Type</Property>
						<Value>
							{propertyTypePreferences?.length
								? propertyTypePreferences
								: nullValueText}
						</Value>
					</li>
					<li>
						<Property>Industry</Property>
						<Value>
							{industryPreferences?.length
								? industryPreferences
								: nullValueText}
						</Value>
					</li>
				</ul>
				<Link
					className={button.blue}
					data-qa-id="exchange-dashboard-view-profile"
					to="/settings/account"
				>
					View Profile
				</Link>
			</MemberInfoContainer>
		</ExchangePanel>
	);
};

const Property = styled.span`
	font-family: Gotham;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	margin-right: 8px;
	width: 30%;
`;

const MemberInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	min-width: 300px;
	max-width: 500px;

	h3 {
		color: #228fff;
	}

	ul {
		width: 100%;
		li {
			display: flex;
			justify-content: space-between;
			span {
				color: rgb(33, 36, 44);
				font-size: 12px;
				line-height: 1.5;
			}
		}
	}
`;

const Value = styled.span`
	color: rgba(0, 0, 0, 0.87);
	font-family: Gotham;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	margin: 0 0 1rem;
	width: 70%;
`;
