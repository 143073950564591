import { Text, View } from '@react-pdf/renderer';
import { TENANT_INDUSTRY_COLORS } from 'constants/colors';

type Props = {
	tenantIndustries: { name: string }[];
};

export const TenantIndustryPdfLegend = ({ tenantIndustries }: Props) => {
	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				marginBottom: 6,
			}}
		>
			{tenantIndustries.map((ti) => {
				return (
					<View
						key={ti.name}
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							gap: 6,
							width: '50%',
						}}
					>
						<View
							style={{
								width: 8,
								height: 8,
								borderRadius: 8,
								flexShrink: 0,
								backgroundColor: `rgb(${
									// @ts-expect-error TS7053: Element implicitly has an 'any...
									TENANT_INDUSTRY_COLORS[ti.name]
								})`,
							}}
						/>
						<Text style={{ fontSize: 11 }}>{ti.name}</Text>
					</View>
				);
			})}
		</View>
	);
};
