import { EditablePolygonLayer } from '@compstak/maps';
import {
	FiltersObject,
	PolygonFilter as PolygonFilterType,
} from 'models/filters/types';
import { useMemo } from 'react';
import { createFilterFromPolygon } from './createFilterFromPolygon';
import { createPolygonFromFilter } from './createPolygonFromFilter';

type PolygonFilterProps = {
	polygonFilter?: PolygonFilterType | null;
	setFilters: (changes: Partial<FiltersObject>) => void;
};

export const PolygonFilter = ({
	polygonFilter,
	setFilters,
}: PolygonFilterProps) => {
	const polygonFeature = useMemo(() => {
		if (!polygonFilter) return;
		return createPolygonFromFilter(polygonFilter);
	}, [polygonFilter]);

	return (
		<EditablePolygonLayer
			initialPolygon={polygonFeature}
			onUpdate={(f) => {
				setFilters({
					polygon: createFilterFromPolygon(f),
				});
			}}
		/>
	);
};
