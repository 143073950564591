import {
	FilterFieldContextProvider,
	useFilterFieldContext,
} from 'Components/Filters/Fields/FilterFieldContextProvider';
import { MarketUpgradeModal } from 'Components/Modals/UpgradeModal/MarketUpgradeModal';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { Modal } from 'Components/Modals/common/UI';
import { LeagueTablesView } from 'Pages/LeagueTables/LeagueTablesView';
import MapComponent from 'Pages/Search/Map';
import { resetSearch } from 'Pages/Search/Map/actions';
import {
	MultiSelectProvider,
	useMultiSelect,
} from 'Pages/Search/MultiSelectProvider';
import { NewSearchTableLayout } from 'Pages/Search/NewSearchTableLayout';
import { SearchToolbar } from 'Pages/Search/SearchToolbar/SearchToolbar';
import { SearchSidebar } from 'Pages/Search/Sidebar/Components/SearchSidebar';
import { useSearch } from 'Pages/Search/searchReducer';
import { closeUpgradeModal } from 'actions/filters';
import { createSearch, setMyComps } from 'actions/search';
import { SearchLayoutCommonProps } from 'exchange/ExchangeHome';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { DISABLE_FILTERS_RESET } from 'middleware/filtersFromRoutes';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { filtersToQueryString } from 'models/filters/util/urls';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useFilters } from 'reducers/filtersReducer';
import { SearchPageParams } from 'router';
import styled from 'styled-components';
import { CompType } from 'types';
import layoutStyles from 'ui/styles/layout.less';
import { useIsMarketsAccessible } from 'util/marketAccessUtils';
import { useAppSelector } from 'util/useAppSelector';
import { Table } from '../Pages/Search/Table';
import { usePrevious } from '../util/hooks';
import { SearchPageProvider, useSearchPageContext } from './SearchProvider';
import './styles/searchLayout.nomodule.less';
import { APP_HEADER_HEIGHT } from 'constants/sizes';

export type SearchLayoutRouteParams = Partial<SearchPageParams>;

type SearchLayoutProps = SearchLayoutCommonProps<SearchLayoutRouteParams>;

const InternalSearchLayout = (props: SearchLayoutProps) => {
	const [sideBarState, setSideBarState] = useState<'open' | 'closed'>('closed');
	const [insightsAreExpanded, setInsightsAreExpanded] = useState(true);

	const showUpgradeModal = useAppSelector((s) => s.filtersV2.showUpgradeModal);
	const { isMyComps } = useSearch();

	const { leagueTables, SearchTableRefactor } = useFeatureFlags();
	const prevIsMyComps = usePrevious(isMyComps);
	const { resetSearchState } = useSearchPageContext();
	const { filters, onFilterChange } = useFilterFieldContext();

	const compTab = getCompTab(window.location.pathname);
	const prevCompTab = usePrevious(compTab);

	const { resetMultiSelect } = useMultiSelect();

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.query.isMyComps !== undefined) {
			dispatch(setMyComps(true));
		}
	}, []);

	useEffect(() => {
		if (isMyComps) {
			dispatch(setMyComps(false));
		} else if (
			props.location.query.isMyComps !== undefined &&
			prevIsMyComps === false
		) {
			dispatch(setMyComps(true));
		}
	}, [dispatch, prevIsMyComps, isMyComps, props.location]);

	const navigate = useNavigate();

	useEffect(() => {
		// sync URL with the filter changes
		navigate(
			{
				pathname: props.location.pathname,
				search: '?' + filtersToQueryString(filters),
			},
			{ replace: true, state: { [DISABLE_FILTERS_RESET]: true } }
		);
	}, [filters, navigate, props.location]);

	// reset multi-select, reset search state, saved search (except first render), selection (except first render) on main tab change
	useEffect(() => {
		if (compTab && compTab !== prevCompTab) {
			// remove current saved search if compType actually switched from non-nullish value (done in AP-10784)
			const resetCurrentSavedSearch = !!prevCompTab;
			const resetSelection = !!prevCompTab;
			dispatch(
				resetSearch({
					resetCurrentSavedSearch,
					resetFilters: false,
					resetSelection,
				})
			);
			resetMultiSelect();
			resetSearchState();
		}
	}, [
		compTab,
		prevCompTab,
		dispatch,
		resetMultiSelect,
		props.route.compType,
		resetSearchState,
	]);

	const contentIfUserHasAccess = () => {
		const { route, params } = props;

		const showTable = route.isSearch && params.view === 'list';

		const showLeagueTable =
			route.isSearch && leagueTables && params.view === 'leagueTables';

		return (
			<Right>
				<FilterChangeEffect compType={props.route.compType} filters={filters} />
				<SearchToolbar
					compType={route.compType}
					params={params}
					filters={filters}
				/>
				<>
					<MapComponent
						compType={route.compType}
						setSideBarState={() =>
							setSideBarState((prev) => (prev === 'open' ? 'closed' : 'open'))
						}
						params={params}
					/>
					{SearchTableRefactor ? (
						<>
							{showTable && (
								<NewSearchTableLayout
									compTab={compTab ?? prevCompTab!}
									insightsAreExpanded={insightsAreExpanded}
									toggleInsightsAreExpanded={() =>
										setInsightsAreExpanded((prev) => !prev)
									}
								/>
							)}
							{showLeagueTable && (
								<LeagueTablesView compType={route.compType} />
							)}
						</>
					) : (
						<TransitionGroup>
							{showLeagueTable && (
								<CSSTransition
									key="leagueTables"
									classNames="layout-results-transition"
									timeout={300}
								>
									<LeagueTablesView compType={route.compType} />
								</CSSTransition>
							)}
							{showTable ? (
								<CSSTransition
									key="table"
									classNames="layout-results-transition"
									timeout={300}
								>
									<Table
										key="table"
										compType={route.compType}
										filters={filters}
										onFilterChange={onFilterChange}
										insightsAreExpanded={insightsAreExpanded}
										toggleInsightsAreExpanded={() =>
											setInsightsAreExpanded((prev) => !prev)
										}
									/>
								</CSSTransition>
							) : (
								<CSSTransition
									key="nothing"
									classNames="layout-results-transition"
									timeout={300}
								>
									<span />
								</CSSTransition>
							)}
						</TransitionGroup>
					)}
				</>
			</Right>
		);
	};

	const hasNoPermissionAtAll = !useIsMarketsAccessible({
		compType: props.route.compType,
		marketIds: getFiltersMarkets(filters).map(({ id }) => id),
	});

	if (!filters) {
		return <div />;
	}

	const onNewSearchClick = () => {
		dispatch(resetSearch({ resetFilters: true }));
		resetMultiSelect();
		resetSearchState();
	};

	const onUpgradeModalClose = () => dispatch(closeUpgradeModal('main'));

	return (
		<section className={layoutStyles.contentWithSidebar} key="search-layout">
			<div className={layoutStyles.sidebar} data-sidebar-state={sideBarState}>
				<SearchSidebar
					onNewSearchClick={onNewSearchClick}
					params={props.params}
				/>
			</div>
			<div className={layoutStyles.content + ' search_content'}>
				{hasNoPermissionAtAll ? (
					<MarketUpgradeModal inline market={getFiltersMarkets(filters)[0]} />
				) : (
					contentIfUserHasAccess()
				)}
			</div>
			{!hasNoPermissionAtAll && showUpgradeModal.main && (
				<Modal onClose={onUpgradeModalClose}>
					<MultiMarketUpgradeModal
						compType={props.route.compType}
						selectedMarkets={showUpgradeModal.main.map(({ id }) => id)}
						onClose={onUpgradeModalClose}
					/>
				</Modal>
			)}
		</section>
	);
};

export const SearchLayout = (props: SearchLayoutProps) => {
	const [filters, setFilters] = useFilters();
	return (
		<MultiSelectProvider>
			<SearchPageProvider>
				<FilterFieldContextProvider
					compType={props.route.compType}
					filters={filters}
					onFilterChange={setFilters}
				>
					<InternalSearchLayout {...props} />
				</FilterFieldContextProvider>
			</SearchPageProvider>
		</MultiSelectProvider>
	);
};

export const FilterChangeEffect = ({
	compType,
	filters,
}: {
	compType: CompType;
	filters: FiltersObject;
}) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(
			createSearch({
				compType,
				filters,
			})
		);
		// do not add `compType` into the effect
		// since it produces unnecessary request on tab change
		// while having a proper request on actual filters change
	}, [filters, dispatch]);

	return null;
};

const getCompTab = (pathname: string) => {
	if (pathname.startsWith('/search/leases') || pathname.startsWith('/home'))
		return 'leases';
	if (pathname.startsWith('/search/sales')) return 'sales';
	if (pathname.startsWith('/search/properties')) return 'properties';
	return null;
};

const Right = styled.div`
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
`;
