import { formatInteger } from 'format';
import { colors } from '@compstak/ui-kit';
import { PORTFOLIO_LIMIT } from 'api/portfolio/constants';

export const LIST_SORT_ROW_HEIGHT = 30;

export const PORTFOLIO_LIMIT_EXCEEDED_MESSAGE = `Exceeded the limit of ${formatInteger(
	PORTFOLIO_LIMIT
)} properties.`;

export const PORTFOLIO_CHART_COLORS = Object.values(colors.dataSet);

export const ROW_HEIGHT = 48;
export const MAX_BODY_HEIGHT = ROW_HEIGHT * 10;
