export const encodeQuery = (
	query: Record<string, any>,
	appendArrayParams = true
) => {
	const queryData = Object.keys(query)
		.filter((key) => {
			const value = query[key];

			return Array.isArray(value)
				? value.length
				: value !== undefined && value !== null;
		})
		.map((key) => {
			const value = query[key];

			if (Array.isArray(value)) {
				if (appendArrayParams) {
					return value
						.map(
							(item) =>
								`${key}=${typeof item === 'number' ? Number(item) : typeof item === 'string' ? encodeURIComponent(item) : item}`
						)
						.join('&');
				}
				return `${key}=${JSON.stringify(value.map((item) => (typeof item === 'number' ? Number(item) : typeof item === 'string' ? encodeURIComponent(item) : item)))}`;
			}

			return `${key}=${typeof value === 'number' ? Number(value) : typeof value === 'string' ? encodeURIComponent(value) : value}`;
		});

	return queryData.length ? `?${queryData.join('&')}` : '';
};
