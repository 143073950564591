import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { useFilterFieldContext } from '../FilterFieldContextProvider';

import { SpaceTypeMultiselect } from './SpaceTypeMultiselect';
import { getLabelForFilterAttribute } from 'models/filters/util/filterHelpers';

const attribute = 'spaceTypeId' as const;

type SpaceTypeFilterProps = {
	children?: React.ReactNode;
	defaultSelected?: boolean;
	inputId?: string;
};

export const SpaceTypeFilter = ({
	children,
	defaultSelected,
	inputId,
}: SpaceTypeFilterProps) => {
	const { compType, searchTerm } = useFilterFieldContext();
	const hasChildrenAndInSearchMode = !!children && !!searchTerm;

	return (
		<FilterBase
			attribute={attribute}
			defaultSelected={defaultSelected || hasChildrenAndInSearchMode}
			labelSynonyms={
				children
					? [
							getLabelForFilterAttribute({
								attribute: 'spaceSubtype',
								compType,
							}),
						]
					: undefined
			}
			inputId={inputId}
		>
			{(props) => (
				<>
					<SpaceTypeMultiselect {...props} />
					{children}
				</>
			)}
		</FilterBase>
	);
};
