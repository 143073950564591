import { useCompsetData } from 'api';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { routes } from 'router';
import { getIsMonthlyMarket } from 'utils';
import { formatRentWithPsfPostfix, getRentPeriodPostfix } from 'format';
import { nullValueText } from '@compstak/common';

const noEstimates = {
	startingRent: nullValueText,
	effectiveRent: nullValueText,
	showCompstakEstimates: false,
};

export const useCompstakEstimatesData = (): Record<CompstakEstimate, string> & {
	showCompstakEstimates: boolean;
} => {
	const params = routes.propertyById.useParams();

	const { data: compset } = useCompsetData({ propertyId: params.propertyId });

	const flags = useFeatureFlags();

	if (!flags.PropertyPageCompstakEstimates || !compset) return noEstimates;

	const { propertyMarketStartingRent, propertyMarketEffectiveRent, marketId } =
		compset.subjectProperty;

	const startingRent = getNonNegative(propertyMarketStartingRent);
	const effectiveRent = getNonNegative(propertyMarketEffectiveRent);

	if (startingRent == null && effectiveRent == null) return noEstimates;

	const isMonthlyMarket = getIsMonthlyMarket(marketId);
	const rentPostfix = getRentPeriodPostfix(isMonthlyMarket);

	const getRent = (rent?: number) => {
		return `${formatRentWithPsfPostfix(isMonthlyMarket, rent)}${rent != null ? ` ${rentPostfix}` : ''}`;
	};

	return {
		startingRent: getRent(startingRent),
		effectiveRent: getRent(effectiveRent),
		showCompstakEstimates: true,
	};
};

// there is a small amount of our values that turn up negative, we don't want them displayed
const getNonNegative = (rent?: number) => {
	return rent && rent < 0 ? undefined : rent;
};

export type CompstakEstimate = 'startingRent' | 'effectiveRent';
