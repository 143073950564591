import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfColumn } from './types';
import { defaultTheme } from '@compstak/ui-kit';

type Props<EditableRow, Row> = {
	rows: EditableRow[];
	columns: PdfColumn<EditableRow, Row>[];
	getShouldHighlightRow?: (id: number | string) => boolean;
};

export const PdfTable = <
	EditableRow extends Record<string, string | number>,
	Row,
>({
	rows,
	columns,
	getShouldHighlightRow,
}: Props<EditableRow, Row>) => {
	return (
		<View style={style.table}>
			<View style={[style.row, style.headerRow]} fixed>
				{columns.map((c, i) => {
					const hAlign = c.hAlign ?? 'left';

					return (
						<View
							key={i}
							style={[
								style.cell,
								style.headerCell,
								{
									flex: c.flex ?? 1,
									justifyContent: FLEX_JUSTIFY_MAP[hAlign],
									textAlign: hAlign,
								},
							]}
						>
							<Text>{c.header}</Text>
						</View>
					);
				})}
			</View>
			{rows.map((row, i) => {
				return (
					<View
						key={i}
						style={[
							style.row,
							{
								backgroundColor: getShouldHighlightRow?.(row.propertyId)
									? defaultTheme.colors.gray.gray200
									: '',
							},
						]}
						wrap={false}
					>
						{columns.map((c, j) => {
							const content = c.pdfBody
								? c.pdfBody({ row, rowIndex: i })
								: row[c.id];

							const wrapBodyContentWithText = c.wrapBodyContentWithText ?? true;

							const hAlign = c.hAlign ?? 'left';

							return (
								<View
									key={j}
									style={[
										style.cell,
										{
											flex: c.flex ?? 1,
											justifyContent: FLEX_JUSTIFY_MAP[hAlign],
											textAlign: hAlign,
										},
									]}
								>
									{wrapBodyContentWithText ? <Text>{content}</Text> : content}
								</View>
							);
						})}
					</View>
				);
			})}
		</View>
	);
};

const style = StyleSheet.create({
	table: {
		width: '100%',
	},
	headerCell: {
		fontWeight: 'bold',
	},
	headerRow: {
		backgroundColor: defaultTheme.colors.neutral.n30,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		borderBottom: `1px solid ${defaultTheme.colors.neutral.n20}`,
		paddingVertical: 2,
	},
	cell: {
		fontSize: 9,
		padding: 5,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		flexWrap: 'wrap',
	},
});

const FLEX_JUSTIFY_MAP = {
	left: 'flex-start',
	center: 'center',
	right: 'flex-end',
} as const;
