export enum SavedSearchEmailFrequency {
	Never = 'Never',
	RealTime = 'RealTime',
	Daily = 'Daily',
	Weekly = 'Weekly',
}

export enum SavedSearchType {
	LEASES = 'leases',
	SALES = 'sales',
	PROPERTIES = 'properties',
}
