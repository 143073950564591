import constate from 'constate';
import { useState } from 'react';
import { DurationMonths } from './DurationFilter';

export const [MarketStatsFiltersProvider, useMarketStatsFilters] = constate(
	() => useState<MarketStatsFilters>({ duration: 36 })
);

export type MarketStatsFilters = {
	duration: DurationMonths;
};
