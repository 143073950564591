import { Button, ButtonProps } from '@compstak/ui-kit';
import styled from 'styled-components';

type Props = ButtonProps;

export const UnlockButton = ({ children, ...props }: Props) => {
	return (
		<StyledButton size="l" variant="success" data-qa-id="unlock-btn" {...props}>
			{children}
		</StyledButton>
	);
};

const StyledButton = styled(Button)`
	gap: 0.25rem;
`;
