import React from 'react';
import styled from 'styled-components';
import { exportImage } from '@compstak/ui-kit';
import ExportIcon from '../../ui/svg_icons/download_icon.svg';

type Props = {
	downloadRef: React.RefObject<HTMLDivElement> | null;
	filename: string;
	disabled?: boolean;
	ignoreClassNames?: string[];
	ignoreElements?: (e: Element) => boolean;
	bannerId: string;
};

const DownloadButton = React.memo(
	({
		downloadRef,
		filename,
		disabled,
		ignoreClassNames = [],
		ignoreElements,
		bannerId,
	}: Props) => {
		const ignoreElementsHandler = React.useCallback(
			(node: Element) => {
				const ignoreClassNamesList = ['mapboxgl-ctrl', ...ignoreClassNames];

				const ignoredClassNames = ignoreClassNamesList.some(
					(className) =>
						typeof node.className === 'string' &&
						node?.className?.includes(className)
				);

				const ignoredElements = ignoreElements ? ignoreElements(node) : false;

				return ignoredClassNames || ignoredElements;
			},
			[ignoreClassNames, ignoreElements]
		);

		const onClickHandler = React.useCallback(() => {
			if (downloadRef?.current && !disabled) {
				return exportImage({
					element: downloadRef.current,
					filename,
					bannerId,
					ignoreElements: ignoreElementsHandler,
				});
			}
		}, [downloadRef, disabled, filename, bannerId, ignoreElementsHandler]);

		if (!SHOW_DOWNLOAD_BUTTON) return null;

		return (
			<DownloadBtn
				disabled={disabled || !downloadRef || false}
				onClick={onClickHandler}
				data-qa-id="download-button-image"
				data-html2canvas-ignore
			>
				<DownloadIcon>
					<StyledExportIcon />
				</DownloadIcon>
				<div>Download</div>
			</DownloadBtn>
		);
	}
);

DownloadButton.displayName = 'DownloadButton';

export default DownloadButton;

//temporarily hidden - product decision - ticket: https://compstak.atlassian.net/browse/AP-14709
const SHOW_DOWNLOAD_BUTTON = false;

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
const COLOR_DISABLED = (props) => props.theme.colors.gray.n4;
// @ts-expect-error TS7006: Parameter 'props' implicitly h...
const COLOR_ENABLED = (props) => props.theme.colors.blue.blue500;
const DownloadBtn = styled.div<{ disabled: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px 8px 8px;
	border-radius: 3px;
	color: ${({ disabled }) => (disabled ? COLOR_DISABLED : COLOR_ENABLED)};
	font-size: 12px;
	line-height: 16px;
	transition: background-color 300ms;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	svg {
		fill: ${({ disabled }) => (disabled ? COLOR_DISABLED : COLOR_ENABLED)};
	}

	${({ disabled }) =>
		!disabled &&
		`
	&:hover {
		background-color: rgba(45, 119, 230, 0.1);
	}
	`}
`;

const DownloadIcon = styled.div`
	margin-right: 7.5px;
	height: 14px;
`;

const StyledExportIcon = styled(ExportIcon)`
	width: 10px;
	height: 14px;
`;
