import { MapAnalyticsNoDataMessage, useTenantIndustryState } from 'maps';
import {
	MapAnalyticsMenuContainer,
	MapAnalyticsMenuPositionContainer
} from '../UI';
import { TenantIndustryAnalyticsReadMenu } from './TenantIndustryAnalyticsReadMenu';
import { TenantIndustryAnalyticsSettingsMenu } from './TenantIndustryAnalyticsSettingsMenu';
import { useTenantIndustryAnalyticsData } from './useTenantIndustryAnalyticsData';

type Props = {
	onClose: () => void;
};

export const TenantIndustryAnalyticsMenu = ({ onClose }: Props) => {
	const [state] = useTenantIndustryState();
	const { data } = useTenantIndustryAnalyticsData();

	const hasNoData = data && data.length === 0;

	return (
		<MapAnalyticsMenuPositionContainer>
			{hasNoData && <MapAnalyticsNoDataMessage />}
			<MapAnalyticsMenuContainer>
				{state.view === 'read' && (
					<TenantIndustryAnalyticsReadMenu onClickClose={onClose} />
				)}
				{state.view === 'settings' && <TenantIndustryAnalyticsSettingsMenu />}
			</MapAnalyticsMenuContainer>
		</MapAnalyticsMenuPositionContainer>
	);
};
