import { User } from '@compstak/common';
import { View } from '@react-pdf/renderer';
import { PdfPage } from 'pdf/PdfPage';
import { ExportPdfState } from './ExportPdfProvider';
import { ComponentPreviewType } from './UI';
import { PDF_COMPONENT_MAP, PdfSectionProps } from './constants';
import { useHalfPanelGroups } from './useHalfPanelGroups';

type Props = {
	user: User;
	selectedComponents: ComponentPreviewType[];
	exportPdfState: ExportPdfState;
	pdfSectionProps: PdfSectionProps;
};

export const DynamicPdfPages = ({
	user,
	selectedComponents,
	exportPdfState,
	pdfSectionProps,
}: Props) => {
	const halfPanelGroups = useHalfPanelGroups({ selectedComponents });

	return (
		<>
			{halfPanelGroups.map((group, i) => {
				return (
					<PdfPage
						key={i}
						logo={exportPdfState.logo}
						preparedFor={exportPdfState.preparedFor}
						title={exportPdfState.title}
						user={user}
					>
						<View style={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
							{group.map((key) => {
								const Component = PDF_COMPONENT_MAP[key];
								return (
									<View key={key} style={{ width: '50%' }}>
										<Component {...pdfSectionProps} />
									</View>
								);
							})}
						</View>
					</PdfPage>
				);
			})}
		</>
	);
};
