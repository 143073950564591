import {
	MapboxImagePinSize,
	getPinLetter,
	useMapboxImageURL,
} from '@compstak/maps';
import { point } from '@turf/helpers';
import { CompsetDTO } from 'api';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

type Props = {
	width: number;
	height: number;
	properties: CompsetDTO[];
};

export const useCompsetMapImageUrl = ({ width, height, properties }: Props) => {
	const points = useMemo(() => {
		return properties.map((p) =>
			point([p.property.geoPoint.lon, p.property.geoPoint.lat], p.property)
		);
	}, [properties]);

	const theme = useTheme();

	return useMapboxImageURL({
		width,
		height,
		position: {
			type: 'bounds',
			points,
		},
		pinPoints: points,
		getPinLabel: (_, index) => {
			if (index === 0) return undefined;
			return getPinLetter(index - 1).toLowerCase();
		},
		getPinSize: () => MapboxImagePinSize.SMALL,
		getPinColor: (_, index) =>
			index === 0 ? theme.colors.neutral.n300 : theme.colors.blue.blue400,
	});
};
