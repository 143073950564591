import React from 'react';

import { HorizontalSortDropTarget } from './DropTargets';
import { getSpaceClassName } from '../util';

import MultiSpaceMenu from '../Menus/MultiSpace';

import styles from '../stacking.less';

const colors = {
	yearsLeft0: 'rgba(252, 52, 51, 0.25)',
	yearsLeft1: 'rgba(255, 175, 51, 0.25)',
	yearsLeft2: 'rgba(243, 248, 51, 0.25)',
	yearsLeft3: 'rgba(51, 205, 82, 0.25)',
	yearsLeft4: 'rgba(51, 227, 174, 0.25)',
	yearsLeftUnknown: 'rgba(0,0,0, 0.15)',
};
const colors2 = {
	yearsLeft0: 'rgba(252, 52, 51, 0.35)',
	yearsLeft1: 'rgba(255, 175, 51, 0.35)',
	yearsLeft2: 'rgba(243, 248, 51, 0.35)',
	yearsLeft3: 'rgba(51, 205, 82, 0.35)',
	yearsLeft4: 'rgba(51, 227, 174, 0.35)',
	yearsLeftUnknown: 'rgba(0,0,0, 0.25)',
};

export default HorizontalSortDropTarget(
	class SpaceOnFloor extends React.PureComponent {
		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		showMenu = (event) => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.menuActions.showMenu(
				MultiSpaceMenu,
				event.currentTarget,
				'above',
				this.props
			);
		};

		render() {
			const inlineStyle = {
				// width: this.props.width + '%',
				position: 'absolute',
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'floorIndex' does not exist on type 'Read... Remove this comment to see the full error message
				top: `${(this.props.floorIndex * 100).toFixed(4)}%`,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'positionLeft' does not exist on type 'Re... Remove this comment to see the full error message
				left: `${this.props.positionLeft.toFixed(4)}%`,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'positionLeft' does not exist on type 'Re... Remove this comment to see the full error message
				right: `${(100 - this.props.positionLeft - this.props.width).toFixed(
					4
				)}%`,
			};

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaces' does not exist on type 'Readonly... Remove this comment to see the full error message
			const totalSize = this.props.spaces.reduce((acc, s) => s.size + acc, 0);

			let spaceSoFar = 0;
			// @ts-expect-error TS7034: Variable 'gradientEntries' imp...
			const gradientEntries = [];

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaces' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.spaces.map((s) => {
				const percentage = (s.size / totalSize) * 100;
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				const color = colors[getSpaceClassName(s)];
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				const color2 = colors2[getSpaceClassName(s)];

				gradientEntries.push(`${color} ${spaceSoFar}%`);
				spaceSoFar += percentage;

				gradientEntries.push(`${color2} ${spaceSoFar}%`);
			});

			// @ts-expect-error TS7005: Variable 'gradientEntries' imp...
			const gradientString = `linear-gradient(90deg, ${gradientEntries.join(
				','
			)}`;

			const className = styles.varWidthWrapper;

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
			const { connectDropTarget } = this.props;

			let additionalClass;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaces' does not exist on type 'Readonly... Remove this comment to see the full error message
			if (this.props.spaces.length === 0) {
				additionalClass = styles.hideOnPrint;
			}

			return connectDropTarget(
				// @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"absolute... Remove this comment to see the full error message
				<div style={inlineStyle} className={className + ' ' + additionalClass}>
					<div
						className={styles.spaceCombined + ' ' + styles.floorCellVarWidth}
						onClick={this.showMenu}
						style={{ background: gradientString }}
					>
						<div className={styles.floorCellTenantName}>
							{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'spaces' does not exist on type 'Readonly... Remove this comment to see the full error message */}
							{this.props.spaces.length} tenant
							{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'spaces' does not exist on type 'Readonly... Remove this comment to see the full error message */}
							{this.props.spaces.length > 1 ? 's' : ''}
						</div>
					</div>
				</div>
			);
		}
	}
);
