import {
	usePropertyInfoQuery,
	useIsMufaProperty,
	PropertyInfoPropertyType,
} from 'api';

export const useTrendsToShow = ({ propertyId }: { propertyId: number }) => {
	const { data: propertyInfo } = usePropertyInfoQuery({ propertyId });

	const { data: isMufaProperty } = useIsMufaProperty({
		propertyId,
	});

	const mufaTrends = isMufaProperty && propertyInfo?.hasRpCompSetData;
	const commercialTrends =
		!mufaTrends ||
		propertyInfo?.propertyType === PropertyInfoPropertyType.MIXED_USE;

	return {
		mufaTrends,
		commercialTrends,
	};
};
