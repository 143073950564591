import { useId, useRef } from 'react';
import styled, { css } from 'styled-components';
import { DialogFormError } from '../UI';
import { usePortfolioForm } from './usePortfolioForm';

type Props = ReturnType<typeof usePortfolioForm>;

export const PortfolioFormFields = ({
	state,
	setState,
	errors,
	isTouched,
	setIsTouched,
	isFormLoading,
}: Props) => {
	const nameId = useId();
	const descriptionId = useId();
	const refName = useRef<HTMLInputElement>(null);
	const refDescription = useRef<HTMLTextAreaElement>(null);

	return (
		<>
			<div style={{ marginBottom: '0.5rem' }}>
				{isTouched && errors.name && (
					<DialogFormError>{errors.name}</DialogFormError>
				)}
				<PortfolioInputContainer
					hasError={isTouched && !!errors.name}
					isFormLoading={isFormLoading}
					onClick={() => {
						refName.current?.focus();
					}}
				>
					<DialogFormLabel htmlFor={nameId} hasValue={!!state.name}>
						Portfolio Name
					</DialogFormLabel>
					<TextInput
						type="text"
						id={nameId}
						value={state.name}
						onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
						onBlur={() => setIsTouched(true)}
						ref={refName}
						disabled={isFormLoading}
						data-qa-id="name"
					/>
				</PortfolioInputContainer>
			</div>
			{isTouched && errors.description && (
				<DialogFormError>{errors.description}</DialogFormError>
			)}
			<PortfolioInputContainer
				hasError={isTouched && !!errors.description}
				isFormLoading={isFormLoading}
				onClick={() => {
					refDescription.current?.focus();
				}}
			>
				<DialogFormLabel id={descriptionId} hasValue={!!state.description}>
					Portfolio Description
				</DialogFormLabel>
				<TextArea
					id={descriptionId}
					value={state.description}
					onChange={(e) =>
						setState((s) => ({ ...s, description: e.target.value }))
					}
					onBlur={() => setIsTouched(true)}
					disabled={isFormLoading}
					ref={refDescription}
					data-qa-id="description"
				/>
			</PortfolioInputContainer>
		</>
	);
};

const portfolioInputCss = css`
	&& {
		background-color: ${(p) => p.theme.colors.white.white};
		border: 0;
		box-sizing: border-box;
		color: ${(p) => p.theme.colors.gray.gray700};
		font-size: 1rem;
		margin: 0;
		padding: 0;
		height: auto;
		font-family: ${(p) => p.theme.typography.fontFamily.gotham};
		&:focus {
			background-color: ${(p) => p.theme.colors.white.white};
		}
	}
`;

const TextInput = styled.input`
	${portfolioInputCss}
`;

const TextArea = styled.textarea`
	${portfolioInputCss};
	min-height: 140px;
`;

const PortfolioInputContainer = styled.div<{
	hasError?: boolean;
	isFormLoading: boolean;
}>`
	width: 100%;
	background-color: ${(p) =>
		p.isFormLoading ? p.theme.colors.neutral.n30 : p.theme.colors.white.white};
	border: 1px solid;
	border-color: ${(p) =>
		p.hasError ? p.theme.colors.red.red500 : p.theme.colors.neutral.n30};
	border-radius: 0.125rem;
	display: block;
	outline: 0;
	padding: 1rem;

	&& input,
	&& textarea {
		background-color: ${({ isFormLoading, theme }) =>
			isFormLoading ? theme.colors.neutral.n30 : 'inherit'};

		cursor: ${({ isFormLoading }) => (isFormLoading ? 'not-allowed' : 'auto')};

		&:focus {
			background-color: ${({ isFormLoading, theme }) =>
				isFormLoading ? theme.colors.neutral.n30 : 'inherit'};
		}
	}
`;

const DialogFormLabel = styled.label<{ hasValue?: boolean }>`
	&& {
		font-size: 0.75rem;
		color: ${(p) => p.theme.colors.blue.blue400};
		font-weight: 400;
		display: block;
		text-align: left;
	}
`;
