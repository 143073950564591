import {
	PolygonsLayer,
	PopupPositioning,
	TRANSPARENT_RGBA,
} from '@compstak/maps';
import {
	OPPORTUNITY_ZONES_FILTER_FILL_COLOR,
	OPPORTUNITY_ZONES_FILTER_LINE_COLOR,
} from 'constants/colors';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';
import { OpportunityZoneFeature } from 'api/opportunityZones/useOpportunityZonesByMarketQuery';

type OpportunityZonesLayerProps = {
	opportunityZoneFeatures: OpportunityZoneFeature[];
};

const ID = 'OpportunityZonesLayer';

export const OpportunityZonesLayer = ({
	opportunityZoneFeatures,
}: OpportunityZonesLayerProps) => {
	const [filters, setFilters] = useFilters();

	const selectedOpportunityZones = useMemo(
		() => filters.opportunityZoneId ?? [],
		[filters.opportunityZoneId]
	);

	return (
		<PolygonsLayer
			id={ID}
			data={opportunityZoneFeatures}
			// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
			getFillColor={(f) => {
				const isSelected = selectedOpportunityZones.includes(
					f.properties.GEOID10
				);
				return isSelected
					? OPPORTUNITY_ZONES_FILTER_FILL_COLOR
					: TRANSPARENT_RGBA;
			}}
			getLineColor={OPPORTUNITY_ZONES_FILTER_LINE_COLOR}
			highlightColor={OPPORTUNITY_ZONES_FILTER_FILL_COLOR as number[]}
			getTooltipContent={(f) =>
				`Opportunity Zone Parcel#: ${f.properties.GEOID10}`
			}
			tooltipPositioning={PopupPositioning.CENTER_POLYGON}
			getLineWidth={4}
			tooltipAnchor="bottom-left"
			onClick={(info) => {
				if (!info.object) return;

				const newSelectedOpportunityZones = selectedOpportunityZones.includes(
					info.object.properties.GEOID10
				)
					? selectedOpportunityZones.filter(
							(id) => id !== info.object?.properties.GEOID10
					  )
					: [...selectedOpportunityZones, info.object.properties.GEOID10];
				setFilters({ opportunityZoneId: newSelectedOpportunityZones });
			}}
			getDashArray={[5, 2]}
			updateTriggers={{
				getFillColor: [selectedOpportunityZones],
			}}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
