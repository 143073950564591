import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import EditFloor from '../Drawer/EditFloor';
import EditSpace from '../Drawer/EditSpace';
import styled from 'styled-components';
import styles from '../stacking.less';

function Nothing() {
	return null;
}

export default class Drawer extends React.PureComponent {
	dismiss = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.closeEditor();
	};

	render() {
		let DrawerContent = Nothing,
			key = 'nothing';
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaceBeingEdited' does not exist on type... Remove this comment to see the full error message
		if (this.props.spaceBeingEdited) {
			// @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof EditSpaceDrawer' provides no match fo... Remove this comment to see the full error message
			DrawerContent = EditSpace;
			key = 'space';
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floorBeingEdited' does not exist on type... Remove this comment to see the full error message
		} else if (this.props.floorBeingEdited) {
			// @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof EditFloorDrawer' provides no match fo... Remove this comment to see the full error message
			DrawerContent = EditFloor;
			key = 'floor';
		}

		let curtain = <Nothing />;
		if (DrawerContent !== Nothing) {
			curtain = <div onClick={this.dismiss} className={styles.drawerCurtain} />;
		}

		return (
			<div className={styles.drawerContainer}>
				<StyledTransitionGroup>
					<CSSTransition
						key={key}
						classNames="stacking-plan-drawer"
						timeout={400}
					>
						<DrawerContent
							// @ts-expect-error ts-migrate(2322) FIXME: Property 'space' does not exist on type 'Intrinsic... Remove this comment to see the full error message
							space={this.props.spaceBeingEdited}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'floorBeingEdited' does not exist on type... Remove this comment to see the full error message
							floor={this.props.floorBeingEdited}
							key={key}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
							closeDrawer={this.props.stackingPlanActions.closeEditor}
							{...this.props}
						/>
					</CSSTransition>
				</StyledTransitionGroup>
				{curtain}
			</div>
		);
	}
}

const StyledTransitionGroup = styled(TransitionGroup)`
	height: 100%;
`;
