import { ComponentProps, ReactNode, useId } from 'react';
import styled from 'styled-components';
import { FormControl, FormControlLabel } from './UI';

type Props = {
	label: ReactNode;
} & ComponentProps<'input'>;

export const RadioButtonControl = ({ label, ...props }: Props) => {
	const id = useId();

	return (
		<StyledFormControl>
			<RadioButton type="radio" id={id} {...props} />
			<FormControlLabel htmlFor={id}>{label}</FormControlLabel>
		</StyledFormControl>
	);
};

const StyledFormControl = styled(FormControl)`
	align-items: center;
`;

const RadioButton = styled.input`
	width: 14px;
	height: 14px;
	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
	}
`;
