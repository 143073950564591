import { Text, View } from '@react-pdf/renderer';
import { AssociatedApn } from 'api/ppm-property/associatedApns/useGetAssociatedApns';
import { useTheme } from 'styled-components';
import { PropertyComp } from 'types';
import { pdfStyle } from '../style';
import { PdfApn } from '../PdfApn';

type Props = {
	property: PropertyComp;
	apn: AssociatedApn[] | string | undefined;
};

export const PropertyAddress = ({ property, apn }: Props) => {
	const theme = useTheme();

	return (
		<View style={pdfStyle.addressTitleContainer}>
			<Text
				style={[
					pdfStyle.addressTitleSubmarket,
					{ color: theme.colors.neutral.n100 },
				]}
			>
				{property.submarket} ({property.marketDisplayName})
			</Text>
			<Text style={pdfStyle.addressTitleAddress}>
				{property.buildingName ? `${property.buildingName} - ` : ''}
				{property.buildingAddressAndCity}, {property.state} {property.zip}
			</Text>
			<PdfApn apn={apn} />
		</View>
	);
};
