import { MarketRentResponse } from '@compstak/fe-react-api-layer';
import styled from 'styled-components';
import { MARKET_RENT_ANALYTIC_COLOR_SCALE } from './constants';
import { useMarketRentAnalyticsState } from './MarketRentAnalyticsProvider';
import {
	MarketRentAnalyticsLegendVariant,
	MarketRentAnalyticsPeriod,
} from './types';

type Props = {
	percentiles: MarketRentResponse['percentiles'];
	variant?: MarketRentAnalyticsLegendVariant;
};

export const MarketRentAnalyticsLegend = ({
	percentiles,
	variant = MarketRentAnalyticsLegendVariant.MEDIUM,
}: Props) => {
	const [state] = useMarketRentAnalyticsState();

	return (
		<>
			<LegendTitle variant={variant}>Scale Legend</LegendTitle>
			<ScaleList>
				{percentiles
					.map((percentile, index, arr) => {
						const lowerRange = percentile.marketRent;
						const formattedLowerRange = `$${Math.round(lowerRange)}`;
						let upperRange: number | null = null;

						if (index + 1 < percentiles.length) {
							upperRange = arr[index + 1].marketRent;
							if (upperRange < lowerRange) upperRange = lowerRange;
						}

						if (upperRange === null) {
							return null;
						}
						const formattedUpperRange = `$${Math.round(upperRange)}`;
						const unit =
							state.period === MarketRentAnalyticsPeriod.MONTH ? 'MO' : 'YR';

						return (
							<ScaleListItem key={index}>
								<ColorIndex index={index} />
								<Range variant={variant}>
									{formattedLowerRange} - {formattedUpperRange}
								</Range>
								<Unit variant={variant}> /SF/{unit}</Unit>
							</ScaleListItem>
						);
					})
					.reverse()}
			</ScaleList>
		</>
	);
};

const TITLE_TEXT_SIZE_MAP = {
	[MarketRentAnalyticsLegendVariant.SMALL]: '12px',
	[MarketRentAnalyticsLegendVariant.MEDIUM]: '14px',
};

const TITLE_MARGIN_MAP = {
	[MarketRentAnalyticsLegendVariant.SMALL]: '0.5rem',
	[MarketRentAnalyticsLegendVariant.MEDIUM]: '1rem',
};

const LegendTitle = styled.div<{ variant: MarketRentAnalyticsLegendVariant }>`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: normal;
	font-size: ${(p) => TITLE_TEXT_SIZE_MAP[p.variant]};
	line-height: 18px;
	letter-spacing: 0.48px;
	color: ${(p) => p.theme.colors.gray.gray700};
	margin-bottom: ${(p) => TITLE_MARGIN_MAP[p.variant]};
`;

const ScaleList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ScaleListItem = styled.li`
	display: flex;
	align-items: center;
	line-height: 17px;
	gap: 10px;
	&:not(:last-of-type) {
		margin-bottom: 0.5rem;
	}
`;

const TEXT_SIZE_MAP = {
	[MarketRentAnalyticsLegendVariant.SMALL]: '10px',
	[MarketRentAnalyticsLegendVariant.MEDIUM]: '12px',
};

const Text = styled.span<{ variant: MarketRentAnalyticsLegendVariant }>`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: normal;
	font-size: ${(p) => TEXT_SIZE_MAP[p.variant]};
	line-height: 14px;
	color: rgba(0, 0, 0, 0.87);
`;

const Range = styled(Text)<{ variant: MarketRentAnalyticsLegendVariant }>`
	font-weight: 500;
	white-space: pre;
`;

const Unit = styled(Text)<{ variant: MarketRentAnalyticsLegendVariant }>`
	white-space: pre;
	color: rgba(0, 0, 0, 0.6);
	margin-left: auto;
`;

const ColorIndex = styled.div<{ index: number }>`
	background-color: rgb(
		${(props) => MARKET_RENT_ANALYTIC_COLOR_SCALE[props.index].join(', ')}
	);
	width: 16px;
	height: 16px;
	border-radius: 2px;
`;
