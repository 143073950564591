import * as RadixCheckbox from '@radix-ui/react-checkbox';
import styled from 'styled-components';
import IconLine from '../../ui/svg_icons/line.svg';
import IconTick from '../../ui/svg_icons/tick_small.svg';

type BgProps = {
	iconSize?: number;
	rootSize?: number;
	bgColorChecked?: string;
	bgColorUnchecked?: string;
	borderColorChecked?: string;
	borderColorUnchecked?: string;
};

type AddDollarSignToKeys<T> = {
	[K in keyof T as `$${string & K}`]: T[K];
};

type TransientBgProps = AddDollarSignToKeys<BgProps>;

export type CheckboxProps = RadixCheckbox.CheckboxProps & BgProps;

const DEFAULT_ICON_SIZE = 10;
const DEFAULT_ROOT_SIZE = 14;

export const Checkbox = ({
	iconSize = DEFAULT_ICON_SIZE,
	rootSize,
	bgColorChecked,
	bgColorUnchecked,
	borderColorChecked,
	borderColorUnchecked,
	className,
	...props
}: CheckboxProps) => {
	return (
		<CheckboxLabel className={className}>
			<CheckboxRoot
				{...{
					...props,
					// starts with $ - to avoid passing props to the dom (avoiding react warning)
					$rootSize: rootSize ?? DEFAULT_ROOT_SIZE,
					$bgColorChecked: bgColorChecked,
					$bgColorUnchecked: bgColorUnchecked ?? 'inherit',
					$borderColorChecked: borderColorChecked,
					$borderColorUnchecked: borderColorUnchecked,
				}}
			>
				<RadixCheckbox.Indicator>
					{props.checked === true && (
						<StyledIconTick width={iconSize} height={iconSize} />
					)}
					{props.checked === 'indeterminate' && (
						<StyledIconLine width={iconSize} height={iconSize} />
					)}
				</RadixCheckbox.Indicator>
			</CheckboxRoot>
			{props.children}
		</CheckboxLabel>
	);
};

const StyledIconTick = styled(IconTick)`
	fill: ${(p) => p.theme.colors.white.white};
`;

const StyledIconLine = styled(IconLine)`
	fill: ${(p) => p.theme.colors.white.white};
`;

export const CheckboxRoot = styled(RadixCheckbox.Root)<
	Omit<TransientBgProps, '$iconSize'>
>`
	all: unset;
	width: ${({ $rootSize }) => $rootSize}px;
	height: ${({ $rootSize }) => $rootSize}px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	justify-content: center;
	background-color: ${(p) =>
		p.checked
			? p.$bgColorChecked || p.theme.colors.blue.blue600
			: p.$bgColorUnchecked};
	border: 1px solid
		${(p) =>
			p.checked
				? p.$borderColorChecked || p.theme.colors.blue.blue600
				: p.$borderColorUnchecked || p.theme.colors.neutral.n50};
	border-radius: 2px;
	&:disabled {
		cursor: not-allowed;
		background-color: ${(p) =>
			p.checked ? p.theme.colors.neutral.n50 : p.$bgColorUnchecked};
		border-color: ${(p) => p.theme.colors.neutral.n50};
	}
	cursor: pointer;
`;

const CheckboxLabel = styled.label`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.gray.gray700};
`;
