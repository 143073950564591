import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SavedSearchIcon from '../../../ui/svg_icons/saved_search.svg';
import { SavedSearchItem } from './SavedSearchItem';

import { loadSavedSearches } from '../actions';
import { AppConfig } from 'types/config';
import { User } from '@compstak/common';
import { SavedSearch, SavedSearchesState } from 'reducers/savedSearches';
import styled from 'styled-components';
import { Button, Spinner } from '@compstak/ui-kit';
import { Link } from 'router';
import { useDispatch } from 'react-redux';
import { resetSearch } from 'Pages/Search/Map/actions';

type SavedSearchesListProps = {
	appConfig: AppConfig;
	savedSearches: SavedSearchesState;
	user: User;
};

export const SavedSearchesList = ({
	appConfig,
	savedSearches,
	user,
}: SavedSearchesListProps) => {
	const dispatch = useDispatch();
	const [editingId, setEditingId] = useState('');
	const [deletingId, setDeletingId] = useState('');

	useEffect(() => {
		dispatch(loadSavedSearches(user));
	}, [dispatch, user]);

	const toggleEditingSavedSearch = useCallback(
		(savedSearch: SavedSearch, isEditing: boolean) => {
			setEditingId(isEditing ? savedSearch.id : '');
		},
		[]
	);

	const toggleDeletingSavedSearch = useCallback(
		(savedSearch: SavedSearch, isDeleting: boolean) => {
			setDeletingId(isDeleting ? savedSearch.id : '');
		},
		[]
	);

	const { leasesSavedSearches, salesSavedSearches, propertiesSavedSearches } =
		useMemo(() => {
			if (!savedSearches.savedSearchList) {
				return {
					leasesSavedSearches: [],
					salesSavedSearches: [],
					propertiesSavedSearches: [],
				};
			}

			return savedSearches.savedSearchList.reduce<{
				leasesSavedSearches: SavedSearch[];
				salesSavedSearches: SavedSearch[];
				propertiesSavedSearches: SavedSearch[];
			}>(
				(acc, savedSearch) => {
					switch (savedSearch.searchType) {
						case 'leases': {
							acc.leasesSavedSearches.push(savedSearch);
							break;
						}
						case 'sales': {
							acc.salesSavedSearches.push(savedSearch);
							break;
						}
						case 'properties': {
							acc.propertiesSavedSearches.push(savedSearch);
							break;
						}
					}
					return acc;
				},
				{
					leasesSavedSearches: [],
					salesSavedSearches: [],
					propertiesSavedSearches: [],
				}
			);
		}, [savedSearches.savedSearchList]);

	const renderSavedSearchItem = (savedSearch: SavedSearch) => (
		<div className="item" key={'savedSearch-' + savedSearch.id}>
			<SavedSearchItem
				appConfig={appConfig}
				editingId={editingId}
				deletingId={deletingId}
				savedSearch={savedSearch}
				toggleEditingSavedSearch={toggleEditingSavedSearch}
				toggleDeletingSavedSearch={toggleDeletingSavedSearch}
			/>
		</div>
	);

	if (!savedSearches.savedSearchList) {
		return <Spinner size="xl" isCentered></Spinner>;
	} else {
		const hasData = savedSearches.savedSearchList.length !== 0;
		return (
			<>
				<SavedSearchesHeader>
					<SavedSearchIcon /> Saved Searches
				</SavedSearchesHeader>
				{!hasData && (
					<NoResultsContainer>
						<ul>
							<li>
								<h1>No Saved Searches Yet</h1>
							</li>
							<li>
								<span>
									Save searches to receive updates and keep track of the comps
									you’re interested in.
								</span>
							</li>
						</ul>
						<ul>
							<li>
								<Button
									dataTestId="no-results-actions-newSearch"
									onClick={() => dispatch(resetSearch({ resetFilters: true }))}
								>
									<NewSearchLink to="/search/leases/home">
										New Search
									</NewSearchLink>
								</Button>
							</li>
						</ul>
					</NoResultsContainer>
				)}
				{hasData && (
					<SavedSearchesGrid>
						{leasesSavedSearches.length > 0 && (
							<div className="saved-search-column">
								<div className="saved-search-column-title">Leases</div>
								{leasesSavedSearches.map((savedSearch) =>
									renderSavedSearchItem(savedSearch)
								)}
							</div>
						)}
						{salesSavedSearches.length > 0 && (
							<div className="saved-search-column">
								<div className="saved-search-column-title">Sales</div>
								{salesSavedSearches.map((savedSearch) =>
									renderSavedSearchItem(savedSearch)
								)}
							</div>
						)}
						{propertiesSavedSearches.length > 0 && (
							<div className="saved-search-column">
								<div className="saved-search-column-title">Properties</div>
								{propertiesSavedSearches.map((savedSearch) =>
									renderSavedSearchItem(savedSearch)
								)}
							</div>
						)}
					</SavedSearchesGrid>
				)}
			</>
		);
	}
};

const SavedSearchesGrid = styled.div`
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 24px;

	.saved-search-column-title {
		color: ${(props) => props.theme.colors.blue.blue400};
		text-transform: uppercase;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 20px;
		line-height: 24px;
		letter-spacing: 0.3px;
	}

	.saved-search-column {
		flex: 1 1 0px;
		min-width: 400px;
		box-sizing: border-box;
		.item {
			padding: 20px 0;
			display: inline-block;
			vertical-align: top;
			width: 100%;
		}
	}
`;

const SavedSearchesHeader = styled.div`
	align-items: center;
	display: flex;
	font-size: 26px;
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: 32px;
	margin-bottom: 40px;
	text-align: left;

	svg {
		margin-right: 1rem;
	}
`;

const NoResultsContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-around;
`;

const NewSearchLink = styled(Link)`
	color: inherit;
	&:hover {
		color: inherit;
	}
`;
