import { defaultTheme } from '@compstak/ui-kit';
import { UNLOCK_BUTTON_STATE } from '../UnlockButton';
import styled from 'styled-components';

type Props = {
	creditCount: number;
	compCost: number;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
};

export const Banner = ({
	color,
	message,
}: {
	color: string;
	message: string;
}) => {
	return (
		<BannerContainer color={color}>
			<span>{message}</span>
			<GetCreditsButton href="/upload" color={color}>
				Get credits
			</GetCreditsButton>
		</BannerContainer>
	);
};

export const ExchangeBanner = ({
	creditCount,
	compCost,
	hasEnoughCredits,
}: Props) => {
	if (hasEnoughCredits === UNLOCK_BUTTON_STATE.NOT_ENOUGH_CREDITS)
		return (
			<Banner
				// TODO add to colors in the ui-kit
				color={'#50B87F'}
				message={'Not enough credits to unlock this comp'}
			/>
		);

	if (
		hasEnoughCredits === UNLOCK_BUTTON_STATE.ENOUGH_CREDITS &&
		creditCount <= compCost * 2
	)
		return (
			<Banner
				color={defaultTheme.colors.blue.blue500}
				message={'Looks like you`re running low on credits'}
			/>
		);

	return null;
};

ExchangeBanner.displayName = 'ExchangeBanner';

const GetCreditsButton = styled.a<{ color: string }>`
	background-color: ${defaultTheme.colors.white.white};
	padding: 8px 88px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 325;
	text-transform: uppercase;
	border-radius: 3px;
	margin-left: 10px;
	color: ${(prop) => prop.color};
	&:hover {
		color: ${(prop) => prop.color};
		background-color: ${defaultTheme.colors.gray.gray200};
	}
`;

const BannerContainer = styled.div<{ color: string }>`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 24px 40px;
	align-items: center;
	background-color: ${(prop) => prop.color};
	color: ${defaultTheme.colors.white.white};
	font-weight: 325;
	font-size: 16px;
	line-height: 19px;
	font-family: ${defaultTheme.typography.fontFamily.gotham};
	white-space: nowrap;
	margin-bottom: 16px;
`;
