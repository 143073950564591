import {
	useFetchLargestTenantsQuery,
	UseLargestTenantsOptions,
} from 'api/largestTenants/useFetchLargestTenantsQuery';
import { useUser } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';

export const useExchangeDashboardLargeTenants = (
	options?: UseLargestTenantsOptions
) => {
	const [filters] = useFilters('exchange-dashboard');
	const user = useUser();

	let resultFilters = filters;
	if (user.userType !== 'admin') {
		resultFilters = {
			...filters,
			partial: 'false',
		};
	}

	const largestTenants = useFetchLargestTenantsQuery(
		{
			filters: resultFilters,
		},
		options
	);

	return {
		...largestTenants,
		type: 'largestTenants' as const,
	};
};
