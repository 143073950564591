import { LeagueTableHeader } from '../../LeagueTables/LeagueTableHeader';
import ViewIcon from './view_icon.svg';
import { LeagueTable, LeagueTableProps } from '../../LeagueTables/LeagueTable';
import { ButtonV2, MQB } from '@compstak/ui-kit';
import FinanceIcon from './finance.svg';
import styled from 'styled-components';

interface PremierListTableViewProps<T> extends LeagueTableProps<T> {
	buttonHref: string;
	buttonText: string;
	title: string;
	description: string;
	isFetching: boolean;
}

export const PremierListTableView = <T,>({
	id,
	isFetching,
	columns,
	data,
	buttonHref,
	buttonText,
	title,
	description,
}: PremierListTableViewProps<T>) => {
	return (
		<div>
			<LeagueTableHeader
				title={title}
				description={description}
				rightArea={
					<ButtonV2 href={buttonHref} variant="ghost2" icon={<ViewIcon />}>
						{buttonText}
					</ButtonV2>
				}
			/>
			{!isFetching && data.length === 0 ? (
				<NoDataComponent />
			) : (
				<LeagueTable
					isFetching={isFetching}
					columns={columns}
					data={data}
					id={id}
				/>
			)}
		</div>
	);
};

const NoDataComponent = () => {
	return (
		<NoDataWrap>
			<FinanceIcon />
			<h3>No data available</h3>
			<p>Update your filters to expand your search.</p>
		</NoDataWrap>
	);
};

const NoDataWrap = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.gray300};
	background-color: white;
	border-radius: 3px;
	height: 555px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: ${MQB.T_834}) {
		height: 240px;
		padding: 24px;
	}

	h3 {
		margin: 0;
		color: ${({ theme }) => theme.colors.gray.gray700};
		font-family: 'OpenSans';
		font-size: 24px;
		font-weight: 600;
		line-height: 32px;
		@media (max-width: ${MQB.T_834}) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	p {
		color: ${({ theme }) => theme.colors.neutral.n100};
		font-family: 'OpenSans';
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		@media (max-width: ${MQB.T_834}) {
			font-size: 14px;
		}
	}
`;
