import { useUserQuery } from 'api';
import dayjs from 'dayjs';
import { useIsExchange } from 'hooks';
import styled from 'styled-components';
import { CircleText, MessageText } from './UI';

export const usePromotionExperimentLinks = () => {
	const { data: user } = useUserQuery();
	const isExchange = useIsExchange();

	if (!isExchange) return null;
	if (user.experimentData == null) return null;

	const now = dayjs();
	// @ts-expect-error experimentData type is wrong
	const expirationTime = dayjs(user.experimentData.expirationTime);
	const daysLeft = expirationTime.diff(now, 'days');

	const daysText = daysLeft === 1 ? 'Day' : 'Days';

	return (
		<Container>
			<StyledMessageText>Promotion Ends In:</StyledMessageText>
			<CircleText>{daysLeft}</CircleText> {daysText}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const StyledMessageText = styled(MessageText)`
	text-transform: uppercase;
`;
