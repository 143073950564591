import { Criterion } from 'PortfolioAnalytics/LeaseActivity/PortfolioLeasesFiltersProvider';
import { CompKeys } from '../types';

export const COMP_FIELD_TOOLTIPS: { [key in CompKeys]?: string } = {
	effectiveRent:
		'(Starting Rent + Escalations - TI (depending on market) - Free Rent) / Lease Term.',
	startingRent:
		'The base rent due before any concessions ($/FT) on the first day of the lease, quoted in annual or monthly terms, depending on the market.',
	currentRent:
		'$/SF paid currently, including free rent, rent schedule, and escalations over the lease term.',
	reportedRentWithDate:
		'Reported Rent is the rent per sq. ft. being paid at the time of the Reported Date.',
	capRate:
		'Cap rate at time of sale as reported by CompStak members unless otherwise specified.',
	buildingClass:
		"CompStak's proprietary building class model assesses properties based on factors like year built, square footage, floors, and other features, scoring them by market for standardized comparison of building quality.",
	reportedRent: 'The rent being paid at the time of the Reported Rent Date',
};

export const LEASE_ACTIVITY_TOOLTIPS: Record<Criterion, string> = {
	allLeases:
		"All leases for the properties in your portfolio. Check 'Active Leases Only' to display only unexpired leases.",
	recentlyUpdated:
		'Updated leases (at least one attribute has been changed) within the selected time frame (based on last attribute update)',
	newOnCompstak:
		'New leases added to CompStak within the selected time frame; includes recently signed and leases that may not be recent but are new to our database (based on Date Created)',
};
