import { ErrorMessage } from '@compstak/ui-kit';
import { useMutation } from '@tanstack/react-query';
import CreatePasswordForm from 'Components/CreatePasswordForm';
import { Link } from 'router';
import userService from 'services/user';
import CsLogo from '../../ui/svg_icons/cs_logo.svg';
import './resetpassword.nomodule.less';

export default function ResetPassword(props: {
	params: { uuid: string } | undefined;
}) {
	const {
		mutateAsync: resetPassword,
		isLoading,
		isError,
		isSuccess,
	} = useMutation({
		mutationFn: async function resetPassword(password: string) {
			const uuid = props.params ? props.params.uuid : 'FAKE';
			return await userService.resetPassword(uuid, password);
		},
		retry: false,
	});

	return (
		<div className="reset_password">
			<section className="reset_password_container">
				<div className="reset_password_header">
					<a
						href="https://compstak.com/"
						target="_blank"
						className="cs_logo"
						title="CompStak"
						rel="noreferrer"
					>
						<CsLogo width={35} height={40} />
					</a>

					<aside className="login_contacts">
						<ul>
							<li>
								<p className="contact_info">
									Need help? <span className="info_divider" /> Call us at{' '}
									<a href="tel:1-646-926-6707">1-646-926-6707</a>
								</p>
							</li>
						</ul>
					</aside>
				</div>

				<div>
					<CreatePasswordForm
						isSubmitting={isLoading}
						onSubmit={resetPassword}
						buttonText="Reset Password"
						headerText="Reset Password"
					/>
					{isSuccess && (
						<div>
							<p>Your password has been reset.</p>
							<p>
								You can now <Link to="login">login in to your account</Link>.
							</p>
						</div>
					)}
					{isError && (
						<ErrorMessage>
							An error happened while resetting your password.
						</ErrorMessage>
					)}
				</div>
			</section>
		</div>
	);
}
