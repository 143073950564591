import { Spinner, TabsList as UIKitTabs } from '@compstak/ui-kit';
import { BetaChip, StyledTab } from 'Components/UI';
import {
	useIsMufaProperty,
	usePropertyLeasesQuery,
	usePropertyLoan,
	usePropertySalesQuery,
} from 'api';
import { useIsExchange } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import { PropertyPageTab } from '../types';
import { useTabContext } from './TabProvider';
import { TabRange } from './TabRange';

export const TabsList = () => {
	const { tab, setTab, sectionRef, scrollToTabs } = useTabContext();
	const flags = useFeatureFlags();

	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;

	const params = routes.propertyById.useParams();

	const { propertyId } = params;

	const { data: leasesData, isFetching: isFetchingLeases } =
		usePropertyLeasesQuery({
			propertyId,
		});

	const { data: salesData, isFetching: isFetchingSales } =
		usePropertySalesQuery({
			propertyId,
		});

	const { data: loans, isFetching: isFetchingLoans } = usePropertyLoan({
		propertyId: params.propertyId,
		isEnabled: isEnterprise || flags.ExchangeLoans,
	});

	const totalLeases = leasesData?.comps.length;
	const totalSales = salesData?.comps.length;
	const totalLoans =
		(loans?.publicLoans?.length ?? 0) + (loans?.treppLoans?.length ?? 0);

	const { data: isMufa, isLoading: isMufaLoading } = useIsMufaProperty({
		propertyId: params.propertyId,
	});

	const showMufaTab = isEnterprise && isMufa;

	// If tab is conditional we must check if the condition is true
	// because tab state is global
	useEffect(() => {
		if (tab === PropertyPageTab.MULTIFAMILY && !isMufa && !isMufaLoading) {
			setTab(PropertyPageTab.COMPSET);
		}
	}, [tab, setTab, isMufa, isMufaLoading]);

	return (
		<StyledTabsList
			ref={sectionRef}
			style={{
				scrollMarginTop: '80px',
			}}
		>
			<TabRange
				label="Market Info"
				isActive={[
					PropertyPageTab.COMPSET,
					PropertyPageTab.SUBMARKET_TRENDS,
				].includes(tab)}
			>
				<StyledTab value={PropertyPageTab.COMPSET} onClick={scrollToTabs}>
					COMPETITIVE SET
				</StyledTab>
				<StyledTab
					value={PropertyPageTab.SUBMARKET_TRENDS}
					onClick={scrollToTabs}
				>
					SUBMARKET TRENDS
				</StyledTab>
			</TabRange>
			<TabRange
				label="Property Info"
				isActive={[
					PropertyPageTab.MULTIFAMILY,
					PropertyPageTab.LEASES,
					PropertyPageTab.SALES,
					PropertyPageTab.STACKING_PLAN,
					PropertyPageTab.LOANS,
					PropertyPageTab.TRANSIT,
				].includes(tab)}
			>
				{showMufaTab && (
					<StyledTab value={PropertyPageTab.MULTIFAMILY} onClick={scrollToTabs}>
						Multifamily
					</StyledTab>
				)}
				<StyledTab value={PropertyPageTab.LEASES} onClick={scrollToTabs}>
					Leases{' '}
					{isFetchingLeases || totalLeases == null ? (
						<Spinner size="s" />
					) : (
						`(${totalLeases})`
					)}
				</StyledTab>
				<StyledTab value={PropertyPageTab.SALES} onClick={scrollToTabs}>
					Sales{' '}
					{isFetchingSales || totalSales == null ? (
						<Spinner size="s" />
					) : (
						`(${totalSales})`
					)}
				</StyledTab>
				{(isEnterprise || flags.ExchangeLoans) && (
					<StyledTab value={PropertyPageTab.LOANS} onClick={scrollToTabs}>
						<div>
							Loans{' '}
							{isFetchingLoans || totalLoans == null ? (
								<Spinner size="s" />
							) : (
								`(${totalLoans})`
							)}
						</div>
						{!isEnterprise && <BetaChip>Beta</BetaChip>}
					</StyledTab>
				)}
				{isExchange && (
					<StyledTab
						value={PropertyPageTab.STACKING_PLAN}
						onClick={scrollToTabs}
					>
						Stacking Plan
					</StyledTab>
				)}
				<StyledTab value={PropertyPageTab.TRANSIT} onClick={scrollToTabs}>
					Transit
				</StyledTab>
			</TabRange>
		</StyledTabsList>
	);
};

const StyledTabsList = styled(UIKitTabs)`
	border-bottom: 0;
`;
