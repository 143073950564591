import simplify from '@turf/simplify';
import { useMarketToOpportunityZones } from 'api/opportunityZones/useMarketToOpportunityZones';
import { UseOpportunityZonesByMarketQueryOptions } from 'api/opportunityZones/useOpportunityZonesByMarketQuery';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';

export const useOpportunityZonesFeatures = (
	filters: FiltersObject,
	options?: UseOpportunityZonesByMarketQueryOptions
) => {
	const { marketIdToOpportunityZones } = useMarketToOpportunityZones(
		getFiltersMarkets(filters),
		options
	);

	const opportunityZoneFeatures = useMemo(
		() =>
			marketIdToOpportunityZones
				? Object.values(marketIdToOpportunityZones).flat()
				: [],
		[marketIdToOpportunityZones]
	);
	const selectedOpportunityZoneFeatures = useMemo(() => {
		const selectedOpportunityZones = filters.opportunityZoneId;

		if (!selectedOpportunityZones) {
			return [];
		}

		const originalSelectedOZFeatures = opportunityZoneFeatures.filter(
			(opportunityZonePolygon) =>
				selectedOpportunityZones.includes(
					opportunityZonePolygon.properties.GEOID10
				)
		);
		const simplifiedSelectedOZFeatures = originalSelectedOZFeatures.map(
			(opportunityZonePolygon) =>
				simplify(opportunityZonePolygon, {
					tolerance: 0.001,
					highQuality: false,
					mutate: false,
				})
		);
		return simplifiedSelectedOZFeatures;
	}, [opportunityZoneFeatures, filters.opportunityZoneId]);

	return {
		selectedOpportunityZoneFeatures,
		opportunityZoneFeatures,
	};
};
