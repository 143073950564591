import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import Multiselect from 'Components/Filters/Base/Multiselect';
import React from 'react';
import { useAppSelector } from 'util/useAppSelector';

const attribute = 'propertyStatus' as const;

export const PropertyStatusFilter = () => {
	const mufaReferenceData = useAppSelector((s) => s.mufaReferenceData!);

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Multiselect
					options={mufaReferenceData.propertyStatuses}
					usesStringNames={true}
					{...props}
				/>
			)}
		</FilterBase>
	);
};
