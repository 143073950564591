import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Error = styled.div<{ show: boolean }>`
	display: ${({ show }) => (show ? 'flex' : 'none')};
	justify-content: center;
	background-color: red;
	border-radius: 3px;
	margin-top: 10px;

	> div {
		color: white;
		font-size: 14px;
		font-weight: 500;
		padding: 5px;
		text-align: center;

		span {
			font-size: 16px;
		}
	}
`;

export const Styled = {
	Container,
	Error,
};
