import { useMemo } from 'react';
import {
	OpportunityZoneFeature,
	UseOpportunityZonesByMarketQueryOptions,
	UseOpportunityZonesByMarketQueryParams,
} from './useOpportunityZonesByMarketQuery';
import { useOpportunityZonesByMarketsQueries } from './useOpportunityZonesByMarketsQueries';

export const useMarketToOpportunityZones = (
	params: UseOpportunityZonesByMarketQueryParams[],
	options?: UseOpportunityZonesByMarketQueryOptions
) => {
	const opportunityZonesQueryResult = useOpportunityZonesByMarketsQueries(
		params,
		options
	);

	const marketIdToOpportunityZones: MarketIdToOpportunityZones | undefined =
		useMemo(() => {
			if (opportunityZonesQueryResult.some(({ data }) => !data)) {
				return undefined;
			}

			return opportunityZonesQueryResult.reduce<MarketIdToOpportunityZones>(
				(acc, item, index) => {
					const marketId = params[index].id;
					acc[marketId] = item.data!.features;

					return acc;
				},
				{}
			);
		}, [opportunityZonesQueryResult]);

	const isFetching = opportunityZonesQueryResult.some(
		({ isFetching }) => isFetching
	);

	return { isFetching, marketIdToOpportunityZones };
};

type MarketIdToOpportunityZones = {
	[marketId: number]: OpportunityZoneFeature[];
};
