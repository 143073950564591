import { BetaChip } from 'Components/UI';
import { useIsExchange } from 'hooks';
import realPageLogo from 'images/RealPage-logo.png';
import styled from 'styled-components';
import { TableSection } from './types';

export const LEASE_SECTIONS: TableSection[] = [
	{
		id: 'lease',
		label: 'Lease',
		ids: [
			0, 3, 4, 1, 13, 34, 24, 71, 16, 81, 80, 42, 22, 21, 20, 23, 14, 143, 26,
			27, 29, 15, 28, 37, 38, 39, 41, 40, 138, 30, 110, 133, 111, 370, 127, 128,
			379, 281,
		],
	},
	{
		id: 'dates',
		label: 'Dates',
		ids: [11, 33, 10, 12, 141, 142, 31, 124, 109],
	},
	{
		id: 'notes',
		label: 'Notes',
		ids: [70, 112],
	},
	{
		id: 'space',
		label: 'Space',
		ids: [2, 378, 17, 18, 56, 57, 58, 59, 60, 61, 62, 6, 7, 8, 9, 195],
	},
	{
		id: 'building',
		label: 'Building',
		ids: [
			43, 32, 44, 73, 74, 350, 222, 75, 46, 45, 49, 72, 50, 51, 47, 48, 52, 55,
			82, 90, 199, 216, 181, 197, 200,
		],
	},
	{
		id: 'landlord',
		label: 'Landlord',
		ids: [63, 85, 84],
	},
	{
		id: 'tenant',
		label: 'Tenant',
		ids: [308, 284, 285, 286, 287, 288, 289, 290, 291, 66, 292, 293],
	},
	{
		id: 'tenantBrokerage',
		label: 'Tenant Brokerage',
		ids: [77, 76],
	},
];

export const SALE_SECTIONS: TableSection[] = [
	{
		id: 'transactionDetails',
		label: 'Transaction Details',
		ids: [
			192, 193, 196, 243, 202, 201, 38, 203, 39, 174, 191, 189, 217, 197, 4, 18,
			62, 82, 370, 205, 247, 195, 218, 206, 209, 224, 223, 175, 194, 190, 204,
		],
	},
	{
		id: 'address',
		label: 'Address',
		ids: [43, 44, 73, 74],
	},
	{
		id: 'property',
		label: 'Property',
		ids: [
			350, 222, 75, 46, 45, 56, 58, 59, 60, 61, 49, 72, 50, 51, 47, 48, 52, 55,
			260, 261, 199, 216, 182, 181, 249, 90, 198, 262, 200, 173,
		],
	},
	{
		id: 'notes',
		label: 'Notes',
		ids: [70],
	},
	{
		id: 'buyerSeller',
		label: 'Buyer / Seller',
		ids: [225, 211, 213, 178, 179, 226, 210, 212],
	},
	{
		id: 'dates',
		label: 'Dates',
		ids: [124],
	},
];

export const BASE_PROPERTY_SECTIONS: TableSection[] = [
	{
		id: 'location',
		label: 'Location',
		ids: [43, 44, 73, 74, 350, 222, 75],
	},
	{
		id: 'property',
		label: 'Property',
		ids: [
			46, 45, 49, 72, 50, 51, 47, 48, 63, 272, 262, 260, 274, 370, 90, 152, 173,
			199, 200, 373, 374, 375, 376, 377,
		],
	},
];

const LoansLabel = () => {
	const isExchange = useIsExchange();

	return isExchange ? (
		<LoansLabelContainer>
			<div>Loan Info</div>
			<BetaChip>Beta</BetaChip>
		</LoansLabelContainer>
	) : (
		<>Loan Info</>
	);
};

const LoansLabelContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const LOAN_SECTION: TableSection = {
	id: 'loanInfo',
	label: <LoansLabel />,
	ids: [352, 353, 356, 357, 358, 362, 364, 366, 368],
};

export const MUFA_SECTION: TableSection = {
	id: 'mufa',
	label: 'Multifamily',
	ids: [342, 343, 344, 345, 346, 347, 348],
	renderLogo: () => (
		<LogoContainer>
			<PoweredBy>Powered by</PoweredBy>
			<img src={realPageLogo} alt="Real Page logo" />
		</LogoContainer>
	),
};

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const PoweredBy = styled.span`
	display: flex;
	align-items: center;
	font-weight: 300;
	font-size: 10px;
`;
