import { Market } from '@compstak/common';
import { FiltersObject } from 'models/filters/types';
import { filtersToQueryString } from 'models/filters/util';
import { useMemo } from 'react';
import { CompType } from 'types';
import pluralizeCompType from 'util/pluralizeCompType';

type Props = {
	compType: CompType;
	market?: Market;
	addresses: string[];
};

export const useCompAddressesSearchUrl = ({
	compType,
	market,
	addresses,
}: Props) => {
	return useMemo(() => {
		if (!market) return '';

		const filters: Partial<FiltersObject> = {
			address: addresses,
			markets: [market],
		};

		return `/search/${pluralizeCompType(
			compType,
			false
		)}/list?${filtersToQueryString(filters)}`;
	}, [addresses, market, compType]);
};
