import { Market } from '@compstak/common';
import { useUnlockButtonIsVisible } from 'hooks/useUnlockButtonIsVisible';
import { memo } from 'react';
import {
	UnlockButtonComponent,
	UnlockButtonComponentProps,
} from './UnlockButtonComponent';

type UnlockButtonProps = UnlockButtonComponentProps & {
	markets: Market[];
};

export const UnlockButton = memo<UnlockButtonProps>(({ compType, markets }) => {
	const isVisible = useUnlockButtonIsVisible(compType, markets);

	if (!isVisible) {
		return null;
	}

	return <UnlockButtonComponent compType={compType} />;
});

UnlockButton.displayName = 'UnlockButton';
