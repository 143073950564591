import styled from 'styled-components';
import { highlightText } from '../../../../utils/highlightText';
import { FilterOption } from 'Components/Filters/Base/Multiselect';

export const getCountForAggregation = ({
	aggregation,
	option,
	useStringNames = false,
}: {
	aggregation?: Partial<Record<string | number, number>>;
	option: FilterOption;
	useStringNames?: boolean;
}) => {
	if (!aggregation) {
		return 0;
	}

	const singleId = useStringNames ? option.name : option.id;
	const multipleId = useStringNames ? option.names : option.ids;

	let resultsNumber = 0;
	if (multipleId) {
		multipleId.forEach((id) => {
			const resultsForId = aggregation[id] ?? 0;
			if (resultsForId) {
				resultsNumber += resultsForId;
			}
		});
	} else if (singleId) {
		resultsNumber = aggregation[singleId] ?? 0;
	}

	return resultsNumber;
};

export const renderAggregationOption = ({
	aggregation,
	option,
	useStringNames = false,
	searchTerm = '',
	shouldRenderAggregationCount = true,
}: {
	aggregation?: Partial<Record<string | number, number>>;
	option: FilterOption;
	useStringNames?: boolean;
	searchTerm?: string;
	shouldRenderAggregationCount?: boolean;
}) => {
	const count = getCountForAggregation({
		aggregation,
		option,
		useStringNames,
	});

	return (
		<Wrap>
			<Text>{highlightText(option.name, searchTerm)} </Text>
			{shouldRenderAggregationCount && (
				<AggregationCount>{count}</AggregationCount>
			)}
		</Wrap>
	);
};

const AggregationCount = styled.span`
	color: gray;
	margin-left: 8px;
`;

const Wrap = styled.span`
	display: flex;
	justify-content: space-between;
`;

const Text = styled.span`
	text-wrap: nowrap;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
