import React from 'react';
import { PDFPanel } from './UI';
import {
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryStack,
	VictoryBar,
	VictoryAxis,
	VictoryLabel,
} from 'victory';
import styled from 'styled-components';
import {
	COLOR_SCALE,
	getYearKeys,
} from 'ExchangeDashboard/LeaseExpirationPropertyType';
import { useReferenceData } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';
import { useExchangeDashboardLeaseExpirationByPropertyType } from 'ExchangeDashboard/hooks/leaseExpirationByPropertyType/useExchangeDashboardLeaseExpirationByPropertyType';
import { LoadingChart } from 'ExchangeDashboard/UI';

export const LeaseExpirationPropertyType = () => {
	const referenceData = useReferenceData();
	const [filters] = useFilters('exchange-dashboard');
	const { data, isFetching } =
		useExchangeDashboardLeaseExpirationByPropertyType();

	const domain: { x: [number, number] } = { x: [0, 5] };
	const isEnoughData =
		Object.values(data ?? []).reduce((acc, compsByType) => {
			return (
				acc +
				Object.values(compsByType).reduce(
					(acc, compCount) => acc + compCount,
					0
				)
			);
		}, 0) >= 10;
	const yearKeys = getYearKeys();

	return (
		<PDFPanel
			id="leaseExpirationPropertyType"
			title="Lease Expiration by Property Type"
		>
			{isFetching ? (
				<LoadingChart />
			) : data && referenceData && isEnoughData ? (
				<ChartContainer>
					<VictoryChart
						horizontal
						maxDomain={{ x: 5 }}
						domain={domain}
						domainPadding={0}
						style={{ parent: { maxWidth: '100%' } }}
						height={320}
						width={520}
						padding={{ left: 75, top: 50, bottom: 0, right: 50 }}
						containerComponent={
							<VictoryVoronoiContainer
								voronoiDimension="x"
								height={350}
								style={{ margin: '0 auto' }}
							/>
						}
					>
						<VictoryAxis
							axisLabelComponent={<VictoryLabel dy={-22} />}
							fixLabelOverlap
							label="Expiring in the market (years)"
							tickLabelComponent={
								<VictoryLabel style={{ fontSize: 12, fontFamily: 'Gotham' }} />
							}
							style={{
								axis: { stroke: 'none' },
								axisLabel: {
									fontSize: 14,
									color: '#000',
									textTransform: 'capitalize',
									fontFamily: 'Gotham',
								},
								grid: { stroke: 'none' },
								ticks: { stroke: '#F0F0F0' },
								tickLabels: {
									fontSize: 12,
									fontFamily: 'Gotham',
									color: '#000',
								},
							}}
						/>
						<VictoryAxis
							axisLabelComponent={
								<VictoryLabel dy={-22} style={{ fontFamily: 'Gotham' }} />
							}
							dependentAxis
							fixLabelOverlap
							label="Number of Leases Expiring"
							tickLabelComponent={
								<VictoryLabel
									dy={-30}
									style={{ fontSize: 12, fontFamily: 'Gotham' }}
								/>
							}
							style={{
								axis: { stroke: 'none', padding: 0, margin: 0 },
								axisLabel: {
									fontSize: 14,
									fontFamily: 'Gotham',
									color: '#000',
									textTransform: 'capitalize',
								},
								grid: { stroke: '#F0F0F0', margin: 0, padding: 0 },
								ticks: { stroke: '#F0F0F0' },
								tickLabels: {
									fontSize: 12,
									fontFamily: 'Gotham',
									color: '#000',
								},
							}}
						/>
						<VictoryStack colorScale={COLOR_SCALE} labels={() => ' '}>
							{referenceData.propertyTypes
								.filter((pt) => pt.id > 0)
								.map((propertyType) => {
									const barData = yearKeys.slice(...domain.x).map(
										(yearKey) => {
											return {
												x: yearKey,
												y: data[propertyType.name]
													? data[propertyType.name]?.[yearKey] ?? 0
													: 0,
												propertyTypeId: propertyType.id,
											};
										}
									);
									return (
										<VictoryBar
											key={propertyType.name}
											data={barData}
											barWidth={36}
											style={{
												data: {
													cursor: 'pointer',
												},
											}}
											labels={() => ' '}
										/>
									);
								})}
						</VictoryStack>
					</VictoryChart>
					<Legend>
						{referenceData.propertyTypes
							.filter((pt) => pt.id > 0)
							.map((propertyType, i) => {
								if (
									!filters.buildingPropertyTypeId?.length ||
									filters?.buildingPropertyTypeId.includes(propertyType.id)
								) {
									return (
										<div key={i} className="property-type">
											<div
												className="color"
												style={{ backgroundColor: COLOR_SCALE[i] }}
											/>
											<div>{propertyType.name}</div>
										</div>
									);
								} else {
									return null;
								}
							})}
					</Legend>
				</ChartContainer>
			) : (
				<div>No Data To Display. Update Filters and Try Again.</div>
			)}
		</PDFPanel>
	);
};

const ChartContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		overflow: visible;
	}
`;

const Legend = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1rem;
	max-width: 500px;

	.property-type {
		font-size: 12px;
		display: flex;
		width: 125px;
		align-items: center;
		margin-bottom: 1rem;

		.color {
			width: 18px;
			height: 18px;
			border-radius: 3px;
			margin-right: 8px;
		}
	}
`;
