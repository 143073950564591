import { InfoLabel } from 'pdf/UI';
import { ReactNode } from 'react';
import styled from 'styled-components';
import IconAdd from 'ui/svg_icons/add.svg';
import IconSubtract from 'ui/svg_icons/subtract.svg';
import { IconButton } from './UI';

type Props = {
	isVisible: boolean;
	onToggle: () => void;
	children: ReactNode;
};

export const HideableLabel = ({ isVisible, onToggle, children }: Props) => {
	return (
		<Root>
			<InfoLabel style={{ opacity: isVisible ? 1 : 0.4 }}>{children}</InfoLabel>
			<IconButton onClick={onToggle}>
				{isVisible ? <StyledIconSubract /> : <StyledIconAdd />}
			</IconButton>
		</Root>
	);
};

const StyledIconSubract = styled(IconSubtract)`
	visibility: hidden;
	path {
		// TODO: new color
		fill: #f16767;
	}
`;

const StyledIconAdd = styled(IconAdd)`
	visibility: hidden;
	path {
		fill: ${(p) => p.theme.colors.blue.blue400};
	}
`;

const Root = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	&:hover {
		${StyledIconSubract}, ${StyledIconAdd} {
			visibility: visible;
		}
	}
`;
