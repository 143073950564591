import { PanelTitle, Row } from 'ExchangeDashboard/UI';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SectionContainerFlex = styled.div`
	background-color: #fff;
	border-radius: 3px;
	border: 1px solid #d5d8df;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	padding: 1rem;
	position: relative;
	margin: 0;
	flex: 1;
	z-index: 1;
	align-self: start;
`;

type SectionProps = {
	id?: string;
	title: string;
	cta?: ReactNode;
	tooltip?: string;
	setSectionsInView?: React.Dispatch<React.SetStateAction<string[]>>;
	children?: ReactNode;
};

export const PDFPanel = React.forwardRef<HTMLDivElement, SectionProps>(
	(props, ref) => {
		return (
			<SectionContainerFlex id={props.id} ref={ref}>
				{props.title != null && (
					<Row>
						<PanelTitle data-tooltip={props.tooltip}>{props.title}</PanelTitle>
						{props.cta}
					</Row>
				)}
				<Relative>{props.children}</Relative>
			</SectionContainerFlex>
		);
	}
);

PDFPanel.displayName = 'PDFPanel';

export const PanelDescription = styled.div`
	align-items: center;
	background-color: #f9f9f9;
	border-radius: 3px;
	display: flex;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin: 1rem 0 0;
	padding: 12px;

	b {
		font-weight: 500;
	}
`;

const Relative = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
`;

export const PDFTable = styled.table`
	width: 100%;
	display: block;
	overflow-x: auto;
	white-space: nowrap;
	td {
		background-color: #fff;
		// border: 1px solid rgb(223, 225, 232);
		color: #464b5e;
		font-weight: 400;
		font-size: 12px;
		font-family: 'OpenSans', Helvetica, Arial, sans-serif;
		padding: 4px 10px;
		min-height: 46px;
	}

	// border: 1px solid rgb(209, 209, 209);
	width: 100%;
	display: block;
	overflow-x: auto;
	white-space: nowrap;

	@media (min-width: 1180px) {
		display: table;
		white-space: normal;
	}
	th {
		background-color: transparent;
		border-bottom: 1px solid rgb(223, 225, 232);
		color: #737b97;
		font-weight: 600;
		font-size: 10px;
		font-family: 'OpenSans', Helvetica, Arial, sans-serif;
		padding: 0 10px;
		height: 34px;
		text-transform: uppercase;
		text-align: left;
	}
	tr td {
		background-color: #fff;
		border-bottom: 1px solid rgb(223, 225, 232);
		color: #464b5e;
		font-weight: 400;
		font-size: 12px;
		font-family: 'OpenSans', Helvetica, Arial, sans-serif;
		padding: 4px 10px;
		height: 36px;
	}

	tr:last-child > td {
		border: 0;
	}
`;
