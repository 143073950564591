import { useAllPortfoliosQuery } from 'api';
import { useMemo } from 'react';

// This should probably be done on BE
export const usePortfoliosSortedByMostRecent = () => {
	const { data: allPortfolios, isFetching } = useAllPortfoliosQuery();

	const sortedPortfolios = useMemo(() => {
		if (!allPortfolios) return [];

		return [...allPortfolios].sort(
			(a, b) =>
				new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf()
		);
	}, [allPortfolios]);

	return {
		sortedPortfolios,
		isFetching,
	};
};
