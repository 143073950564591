import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import TimespanButton from 'Components/Filters/Base/TimespanButton';
import React from 'react';

const attribute = 'executionDate' as const;

type Props = {
	inputId?: string;
	children?: React.ReactNode;
};

export const ExecutionDateFilter = ({ inputId, children }: Props) => {
	return (
		<FilterBase attribute={attribute} inputId={inputId}>
			{(props) => (
				<>
					<Between
						attribute={attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						touch={props.touch}
						setError={props.setError}
					/>
					<div className="presets">
						In the past... <br />
						<TimespanButton
							min={-6}
							max={0}
							unit="months"
							roundTo="day"
							{...props}
						>
							6 months
						</TimespanButton>
						<TimespanButton
							min={-1}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							Year
						</TimespanButton>
						<TimespanButton
							min={-2}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							2 Years
						</TimespanButton>
					</div>

					{children}
				</>
			)}
		</FilterBase>
	);
};
