import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio } from '../../types';
import { trackSegmentCreatePortfolio } from 'middleware/tracking/segment';

const copyPortfolio = async (params: CopyPortfolioParams) => {
	const res = await API.post<CopyPorfolioResponse>(
		`/pa/v2/api/portfolios/copy`,
		params
	);
	return res.data;
};

export const useCopyPortfolioMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: CopyPortfolioParams) => copyPortfolio(params),
		onSuccess: (newPortfolio) => {
			const portfolios = queryClient.getQueryData<Portfolio[]>([
				QUERY_KEYS.ALL_PORTFOLIOS,
			]);
			trackSegmentCreatePortfolio({
				existingPortfolios: portfolios || [],
				newPortfolio,
				duplicatePortfolio: true,
			});
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
	});
};

export type CopyPortfolioParams = {
	portfolioId: number;
	name: string;
	description: string | undefined;
};

export type CopyPorfolioResponse = Portfolio;
