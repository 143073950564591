import { Comp, CompType } from 'types/comp';
import salePointService from 'services/salePoints';
import leasePointService from 'services/leasePoints';
import propertyPointService from 'services/propertyPoints';
import mufaPropertyPointsService from 'services/mufaPropertyPoints';
import leaseAveragesServices from 'services/averages/leaseAverages';
import saleAveragesServices from 'services/averages/saleAverages';
import propertyAveragesServices from 'services/averages/propertyAverages';
import mufaPropertyAveragesServices from 'services/averages/mufaPropertyAverages';
import { formatAverages } from 'services/util/formatAverages';
import { FiltersObject } from 'models/filters/types';
import { PINS_LIMIT } from 'Pages/Search/Map/actions';
import { AppDispatch, AppGetState } from 'store';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { isMultifamily } from 'util/isMultifamily';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';

export const SELECT_COMPS = 'SELECT_COMPS' as const;
export const DESELECT_COMPS = 'DESELECT_COMPS' as const;
export const DESELECT_ALL = 'DESELECT_ALL' as const;
export const SELECT_ALL_COMPS = 'SELECT_ALL_COMPS' as const;
export const GET_AVERAGES = 'GET_AVERAGES' as const;

export const selectAllComps =
	(filters: FiltersObject, compType: CompType) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		const store = getState();

		if (compType === 'property') {
			const permissions = store.permissions;

			const useMultifamily = isMultifamily({
				markets: getFiltersMarkets(filters),
				permissions,
			});
			return dispatch(
				useMultifamily
					? selectMufaPropertiesInternal(filters)
					: selectPropertiesInternal(filters)
			);
		} else if (compType === 'sale') {
			return dispatch(selectSalesInternal(filters));
		}
		return dispatch(selectLeasesInternal(filters));
	};

export function deselectAll() {
	return {
		type: DESELECT_ALL,
	};
}

export function selectComps(comps: Comp[]) {
	return {
		type: SELECT_COMPS,
		payload: comps,
	};
}

export function deselectComps(compIds: number[]) {
	return {
		type: DESELECT_COMPS,
		payload: compIds,
	};
}

export const getAverages =
	(filters: FiltersObject, comps: Comp[], compType: CompType) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		const compIds = comps.map((comp) => comp.id);
		let averageService:
			| typeof saleAveragesServices
			| typeof mufaPropertyAveragesServices
			| typeof propertyAveragesServices
			| typeof leaseAveragesServices;
		switch (compType) {
			case 'sale':
				averageService = saleAveragesServices;
				break;
			case 'property': {
				const store = getState();
				const permissions = store.permissions;

				const useMultifamily = isMultifamily({
					markets: getFiltersMarkets(filters),
					permissions,
				});

				averageService = useMultifamily
					? mufaPropertyAveragesServices
					: propertyAveragesServices;
				break;
			}
			default:
				averageService = leaseAveragesServices;
				break;
		}

		return dispatch(
			getAveragesInternal(
				compIds,
				averageService,
				shouldUseMonthlyMetrics(filters)
			)
		);
	};

const selectLeasesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: leasePointService
				.load({
					filter,
					properties: ['id', 'own', 'cost', 'marketId', 'propertyId'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.comps),
		},
	};
};

const selectSalesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: salePointService
				.load({
					filter,
					properties: ['id', 'own', 'cost', 'marketId', 'portfolio.propertyId'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.comps),
		},
	};
};

const selectPropertiesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: propertyPointService
				.load({
					filter,
					properties: ['id', 'marketId', 'buildingAddressAndCity'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.properties),
		},
	};
};

const selectMufaPropertiesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: mufaPropertyPointsService
				.load({
					filter,
					properties: ['id', 'marketId', 'buildingAddressAndCity'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.properties),
		},
	};
};

const getAveragesInternal = (
	compIds: number[],
	averageService:
		| typeof saleAveragesServices
		| typeof mufaPropertyAveragesServices
		| typeof propertyAveragesServices
		| typeof leaseAveragesServices,
	isMonthlyMetrics: boolean
) => {
	return {
		type: GET_AVERAGES,
		meta: {
			leaseIds: compIds,
		},
		payload: {
			promise: averageService
				.load(compIds)
				// Promise awaited return type is not inferred when it's a union type
				.then(
					(averages: Awaited<ReturnType<(typeof averageService)['load']>>) =>
						formatAverages(isMonthlyMetrics, averages)
				),
		},
	};
};

export type SelectionAction =
	| ReduxPromiseAction<ReturnType<typeof selectLeasesInternal>>
	| ReduxPromiseAction<ReturnType<typeof selectSalesInternal>>
	| ReduxPromiseAction<ReturnType<typeof selectPropertiesInternal>>
	| ReduxPromiseAction<ReturnType<typeof selectMufaPropertiesInternal>>
	| ReduxPromiseAction<ReturnType<typeof getAveragesInternal>>
	| ReturnType<typeof deselectAll>
	| ReturnType<typeof selectComps>
	| ReturnType<typeof deselectComps>;

export const selectionActions = {
	selectAllComps,
	deselectAll,
	selectComps,
	deselectComps,
	getAverages,
};

export type SelectionActions = typeof selectionActions;
