import { useState } from 'react';
import { nullValueText } from '@compstak/common';
import { useIsMufaProperty } from 'api';
import { MufaPropertyComp, PropertyComp } from 'types';
import { keyInfoAttributes, mufaLabels } from 'Pages/PropertyPageV3/MainInfo';
import { getPropertyAttributesKeys } from 'Pages/PropertyPageV3/MainInfo/PropertyAttributes';

type Props = {
	property: MufaPropertyComp;
};

export const useEditableOverview = ({ property }: Props) => {
	const { data: isMufa } = useIsMufaProperty({ propertyId: property.id });
	const propertyAttributes = getPropertyAttributesKeys(
		isMufa,
		property.buildingPropertyType
	);
	const [editableOverviewFields, setEditableOverviewFields] =
		useState<EditableOverviewFields>({
			keyInfoAttributes: {
				...keyInfoAttributes.reduce(
					(acc, key) => ({
						...acc,
						[key]: !property[key]
							? nullValueText
							: // using mufaLabels because it includes commercial labels as well (formatting is the same)
								mufaLabels[key].formatter?.(property[key]) ?? property[key],
					}),
					{} as OverviewAttributes
				),
			},
			propertyAttributes: {
				...propertyAttributes.reduce(
					(acc, key) => ({
						...acc,
						[key]: !property[key]
							? nullValueText
							: mufaLabels[key].formatter?.(property[key]) ?? property[key],
					}),
					{} as OverviewAttributes
				),
			},
			visibleFields: {
				...[...keyInfoAttributes, ...propertyAttributes].reduce(
					(acc, key) => ({
						...acc,
						[key]: true,
					}),
					{} as OverviewVisibleFields
				),
			},
		});

	return {
		editableOverviewFields,
		setEditableOverviewFields,
	};
};

export type EditableOverviewFields = {
	keyInfoAttributes: OverviewAttributes;
	propertyAttributes: OverviewAttributes;
	visibleFields: OverviewVisibleFields;
};

export type OverviewVisibleFields = Record<
	keyof (PropertyComp & MufaPropertyComp),
	boolean
>;
export type OverviewAttributes = Record<
	keyof (PropertyComp & MufaPropertyComp),
	any
>;
