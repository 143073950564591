import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ChartBuilderActions, chartBuilderActions } from './actions';

import spinner from 'ui/styles/spinner.less';
import { AppState } from 'reducers/root';
import { ChartBuilderState } from './reducer';
import { MarketsState } from 'Pages/Login/reducers';
import { FeatureFlags } from 'api/featureFlags/types';
import styled from 'styled-components';

const mapStoreToProps = (store: AppState) => ({
	chartBuilder: store.chartBuilder,
	featureFlags: store.featureFlags,
	permissions: store.permissions,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	chartBuilderActions: bindActionCreators(chartBuilderActions, dispatch),
});

const Spinner = () => <div className={spinner.spinner} />;

type Props = {
	chartBuilder: ChartBuilderState;
	chartBuilderActions: ChartBuilderActions;
	markets: MarketsState;
	featureFlags: FeatureFlags;
};

const loader = (Component: React.ComponentType, LoadingComponent = Spinner) =>
	class ChartBuilderLoader extends React.Component<Props> {
		constructor(props: Props) {
			super(props);
			if (!props.chartBuilder.chartDraft) {
				this.props.chartBuilderActions.loadChartDraft(props.markets);
			}
		}

		render() {
			const { chartBuilder, ...props } = this.props;
			const { chartDraft, expandedDataSetIndex, loading, error } = chartBuilder;

			if (error) {
				return (
					<ErrorContainer>
						<h3>You don't have access to chart builder</h3>
					</ErrorContainer>
				);
			}

			if (loading) {
				// @ts-expect-error ts-migrate(2559) FIXME: Type '{ children?: ReactNode; }' has no properties... Remove this comment to see the full error message
				return <LoadingComponent {...props} />;
			}
			return (
				<Component
					{...props}
					// @ts-expect-error TS2322: Type '{ chartDraft: Chart | nu...
					chartDraft={chartDraft}
					expandedDataSetIndex={expandedDataSetIndex}
				/>
			);
		}
	};

const connector = connect(mapStoreToProps, mapDispatchToProps);

const ErrorContainer = styled.div`
	min-height: calc(100vh - 50px);
	display: flex;
	align-items: center;
	justify-content: center;

	color: ${({ theme }) => theme.colors.gray.gray600};
`;

export default (Component: React.ComponentType) => connector(loader(Component));
