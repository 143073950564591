import { UseQueryOptions } from '@tanstack/react-query';
import { LeaseComp } from '../../../types';
import { QUERY_KEYS } from '../../constants';
import { PortfolioLeasesParams } from '../../types';
import { createFetchFn, useQueryHook } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';

export const fetchPortfolioLeases = createFetchFn<
	PortfolioLeasesParams,
	PortfolioLeasesResponse
>({
	method: 'get',
	getURL: ({ portfolioId, ...queryArgs }) => {
		return `/pa/v2/api/portfolios/${portfolioId}/leases${getQueryStringFromPortfolioFilters(
			queryArgs
		)}`;
	},
});

export const usePortfolioLeasesQuery = (
	params: PortfolioLeasesParams,
	options?: UseQueryOptions<PortfolioLeasesResponse>
) =>
	useQueryHook({
		queryKey: [QUERY_KEYS.PORTFOLIO_LEASES, params],
		queryFn: fetchPortfolioLeases(params),
		...options,
		enabled: params.portfolioId != null && (options?.enabled ?? true),
	});

export type PortfolioLeasesResponse = LeaseComp[];
