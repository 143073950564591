import { PropertyComp } from 'types';
import {
	BuildingClassId,
	BuildingClassName,
	PropertyTypeId,
	PropertyTypeName,
	SpaceTypeName,
} from '../referenceData';
import { FilterNumberInterval } from 'models/filters/types';

type BasePortfolioPropertiesParams = {
	portfolioId: number;
	offset?: number;
	limit?: number;
} & PortfolioPropertiesFilterQuery;

export type PortfolioPropertiesSummaryParams = BasePortfolioPropertiesParams;

export type PortfolioPropertiesParams = BasePortfolioPropertiesParams & {
	includeFields?: (keyof PropertyComp)[];
};

export type PortfolioFilterQuery = {
	marketIds?: number[];
	submarketIds?: number[];
	propertyTypeIds?: PropertyTypeId[];
	buildingClassIds?: BuildingClassId[];
	leaseExpirationsMonths?: PortfolioLeaseExpirationsMonths;
	buildingSize?: FilterNumberInterval | null;
};

export type PortfolioPropertiesFilterQuery = PortfolioFilterQuery & {
	spaceTypes?: SpaceTypeName[];
	tenantIndustry?: string[];
	tenantNames?: string[];
	expirationYears?: number[];
};

export type PortfolioLeasesParams = PortfolioPropertiesParams;

export type PortfolioLeaseExpirationsMonths = 12 | 24 | 36 | 48 | 60 | null;

export type PortfolioMarket = {
	marketId: number;
	market: string;
	submarkets: PortfolioSubmarket[];
	propertyCount: number;
	propertyIds: number[];
	activeLeaseCount: number;
	activeLeaseAmount: number;
	totalSqFt: number;
	expiringSqFt24Months: number;
	avgStartingRent: number;
	avgCurrentRent: number;
	avgNetEffectiveRent: number;
	avgFreeRent: number;
	avgTIImprovement: number;
	avgLeaseTerm: number;
	avgDiscountRentInPercents: number;
	avgTransactionalSqFt: number;
	propertyThatHasActiveLeasesCount: number;
};

export type PortfolioSubmarket = {
	submarketId: number;
	submarket: string;
};

export type Portfolio = {
	id: number;
	name: string;
	description?: string;
	dateCreated: string;
	lastUpdated?: string;
	tenantNames: PortfolioTenant[];
	tenantIndustries: PortfolioTenantIndustry[];
	markets: PortfolioMarket[];
	propertyTypesByMarkets: PortfolioPropertyTypeByMarket[];
	buildingClassesByMarkets: PortfolioBuildingClassByMarket[];
	leaseExpirationsByIndustriesByYears: PortfolioLeaseExpirationsByIndustriesByYears[];
	leaseExpirationsBySpaceTypesByYears: PortfolioLeaseExpirationsBySpaceTypesByYears[];
	portfolioSummary?: PortfolioSummary;
};

export type PortfolioTenant = {
	tenantName: string;
	activeLeaseCount: number;
	activeLeaseAmount: number;
	totalSqFt: number;
	marketId: number;
	propertyIds: PropertyTypeId[];
	avgStartingRent: number;
	avgCurrentRent: number;
	avgNetEffectiveRent: number;
	avgFreeRent: number;
	avgTIImprovement: number;
	avgLeaseTerm: number;
	expiringSqFt24Months: number;
	avgDiscountRentInPercents: number;
	avgTransactionalSqFt: number;
};

export type PortfolioTenantIndustry = {
	tenantIndustry: string;
	activeLeaseCount: number;
	activeLeaseAmount: number;
	totalSqFt: number;
	marketId: number;
	propertyIds: number[];
	avgStartingRent: number;
	avgCurrentRent: number;
	avgNetEffectiveRent: number;
	avgFreeRent: number;
	avgTIImprovement: number;
	avgLeaseTerm: number;
	expiringSqFt24Months: number;
	avgDiscountRentInPercents: number;
	avgTransactionalSqFt: number;
};

type PortfolioSummary = {
	propertiesCount: number;
	activeLeaseCount: number;
	totalSqFt: number;
};

export type PortfolioPropertyTypeByMarket = {
	buildingPropertyTypeId: PropertyTypeId;
	buildingPropertyType: PropertyTypeName;
	marketId: number;
	propertyIds: number[];
	activeLeaseCount: number;
	activeLeaseAmount: number;
	totalSqFt: number;
	avgStartingRent: number;
	avgCurrentRent: number;
	avgNetEffectiveRent: number;
	avgFreeRent: number;
	avgTIImprovement: number;
	avgLeaseTerm: number;
	expiringSqFt24Months: number;
	avgDiscountRentInPercents: number;
	avgTransactionalSqFt: number;
};

type PortfolioBuildingClassByMarket = {
	buildingClassId: BuildingClassId;
	buildingClass: BuildingClassName;
	marketId: number;
	propertyIds: PropertyTypeId[];
	activeLeaseCount: number;
	activeLeaseAmount: number;
	totalSqFt: number;
};

export type PortfolioLeaseExpirationsByIndustriesByYears = {
	tenantIndustry: string;
	expirationYear: number;
	marketId: number;
	propertyIds: PropertyTypeId[];
	expiringLeaseCount: number;
	expiringLeaseAmount: number;
	totalSqFt: number;
};

export type PortfolioLeaseExpirationsBySpaceTypesByYears = {
	spaceType: SpaceTypeName;
	expirationYear: number;
	marketId: number;
	expiringLeaseCount: number;
	expiringLeaseAmount: number;
	totalSqFt: number;
};

type PortfolioV2Market = {
	marketId: number;
	market: string;
	submarkets: PortfolioSubmarket[];
};

type PortfolioV2Metrics = {
	activeLeaseAmount: number;
	activeLeaseCount: number;
	avgCurrentRent?: number;
	avgFreeRent?: number;
	avgLeaseTerm?: number;
	avgNetEffectiveRent?: number;
	avgTIImprovement?: number;
	avgTransactionalSqFt?: number;
	expiringSqFt24Months: number;
	marketCount: number;
	propertyCount: number;
	totalSqFt: number;
};

export type PortfolioV2 = {
	portfolio: {
		id: number;
		userId: number;
		name: string;
		description?: string;
		propertyIds: number[];
	};
	markets: PortfolioV2Market[];
	metrics: PortfolioV2Metrics;
};

export const isPortfolioV2 = (value: unknown): value is PortfolioV2 => {
	if (typeof value === 'object' && value !== null) {
		const hasMetrics =
			'metrics' in value &&
			typeof value.metrics === 'object' &&
			value.metrics !== null;
		const hasMarkets =
			'markets' in value &&
			typeof value.markets === 'object' &&
			value.markets !== null;
		const hasPortfolio =
			'portfolio' in value &&
			typeof value.portfolio === 'object' &&
			value.portfolio !== null &&
			'id' in value.portfolio &&
			typeof value.portfolio.id === 'number';
		return hasMetrics && hasMarkets && hasPortfolio;
	}
	return false;
};
