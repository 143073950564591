import constate from 'constate';
import { Modal, ModalProps } from 'Components/Modals/common/UI';
import React, { useState, ReactNode } from 'react';

type ModalState = {
	modalContent: ReactNode;
	modalProps?: ModalProps;
};

const [_ModalProvider, _useModal] = constate(() => {
	return useState<ModalState[]>([]);
});

const ModalProvider = ({ children }: { children: ReactNode }) => {
	return (
		<_ModalProvider>
			{children}
			<_Modals />
		</_ModalProvider>
	);
};

const useModal = (): {
	openModal: (modal: ModalState) => void;
	closeModal: () => void;
} => {
	const [modals, setModals] = _useModal();

	const openModal = (modal: ModalState) => {
		setModals((prevModals) => [...prevModals, modal]);
	};

	const closeModal = () => {
		setModals((prevModals) => prevModals.slice(0, modals.length - 1));
	};

	return { openModal, closeModal };
};

const _Modals = () => {
	const [modals, setModals] = _useModal();

	const closeModal = (modal: ModalState) => {
		modal.modalProps?.onClose?.();
		setModals(modals.slice(0, modals.length - 1));
	};

	return (
		<>
			{modals.map((modal, index) => (
				<Modal
					key={index}
					{...modal.modalProps}
					onClose={() => closeModal(modal)}
				>
					{modal.modalContent}
				</Modal>
			))}
		</>
	);
};

export { ModalProvider, useModal };
