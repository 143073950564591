import { SimpleTableColumn } from '@compstak/ui-kit';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import {
	useTopSellersQuery,
	Seller,
	TopSellersResponse,
} from '../../../api/topSellers/useTopSellers';
import { PremierListTableProps } from '../PremierList';
import dayjs from 'dayjs';
import { getPremierListTableColumns } from './columns';
import { useViewCompLink } from './util';
import { PROPERTY_TYPE_NAME_TO_ID } from '../../../api';
import { PremierListTableView } from './PremierListTableView';

const DEFAULT_ROWS: TopSellersResponse['sellers'] = [];
const BASE_LINK_URL = '/search/sales/list';
const FILTER_NAME: keyof FiltersObject = 'sellers';

export const ActiveSellersTable = ({
	commonFilters,
	selectedSpaceTypeName,
}: PremierListTableProps) => {
	const filters: Partial<FiltersObject> = useMemo(
		() => ({
			buildingPropertyTypeId: [PROPERTY_TYPE_NAME_TO_ID[selectedSpaceTypeName]],
			saleDate: {
				max: dayjs().toDate(),
				min: dayjs().subtract(1, 'year').toDate(),
			},
		}),
		[selectedSpaceTypeName]
	);

	const localFilters = useMemo(
		() => ({ ...filters, ...commonFilters }),
		[commonFilters, filters]
	);

	const { data, isFetching } = useTopSellersQuery({ filters: localFilters });
	const rows = data?.sellers ?? DEFAULT_ROWS;

	const columns: SimpleTableColumn<Seller>[] = useMemo(
		() =>
			getPremierListTableColumns<Seller>({
				filterName: FILTER_NAME,
				baseLinkUrl: BASE_LINK_URL,
				filters: localFilters,
				nameColumn: {
					header: 'Seller Name',
				},
				volumeColumn: {
					header: 'Volume ($M)',
					prefix: '$',
					key: 'sumOfSalesPrice',
					numberProcess: (value) => value / 1e6,
				},
				countColumn: {
					key: 'totalSalesCount',
					header: 'Sale Transactions',
				},
			}),
		[localFilters]
	);

	const viewSaleCompsHref = useViewCompLink({
		filterName: FILTER_NAME,
		baseLinkUrl: BASE_LINK_URL,
		filters: localFilters,
		rows,
	});

	return (
		<PremierListTableView
			data={rows}
			buttonHref={viewSaleCompsHref}
			buttonText="View Sale Comps"
			title="Seller Activity"
			description={`Companies that sold the most ${selectedSpaceTypeName.toLowerCase()} space in the last 365 days in ${commonFilters.markets[0].displayName} according to CompStak’s available data.`}
			isFetching={isFetching}
			columns={columns}
			id="ActiveSellersTable"
		/>
	);
};
