import { memo } from 'react';
import { Button, Pending } from '@compstak/ui-kit';
import { nullValueText } from '@compstak/common';
import stackingPlanImage from 'ui/Images/plan@2x.png';
import styled from 'styled-components';
import { useNavigate, routes } from 'router';
import { useCompsetTableRows } from 'Components/CompsetTable';
import { TabContentTitle } from 'Components/UI';

export const StackingPlan = memo(() => {
	const navigate = useNavigate();
	const propertyId = routes.propertyById.useParams().propertyId;

	const compSetQueryResponse = useCompsetTableRows({ propertyId });

	if (compSetQueryResponse.isFetching)
		return (
			<StackingPlanWidgetContainer>
				<TabContentTitle>Stacking Plan</TabContentTitle>
				<PendingContainer>
					<Pending times={2} />
				</PendingContainer>
			</StackingPlanWidgetContainer>
		);

	const {
		notExpiredLeaseCount,
		propertyLeasesExpiringInTwelveMonths,
		propertyLeasesExpiringInFiveYears,
		subleaseCount,
	} = compSetQueryResponse.data[0].property;

	const stackingPlanData = {
		notExpiredLeaseCount,
		propertyLeasesExpiringInTwelveMonths,
		propertyLeasesExpiringInFiveYears,
		subleaseCount,
	};

	const hasData = Object.values(stackingPlanData).some((item) => item);

	return (
		<StackingPlanWidgetContainer>
			<TabContentTitle>Stacking Plan</TabContentTitle>
			{hasData ? (
				<div>
					<StackingPlanText>
						Create a stacking plan using CompStak’s custom builder. Add and edit
						information, floors, and building-shapes to generate an exportable
						plan.
					</StackingPlanText>
					<StackingPlanContainer>
						<img src={stackingPlanImage} alt="stacking-plan" />
						<DataContainer>
							{Object.entries(stackingPlanDataLabels).map(
								([key, { label }]) => {
									return (
										<DataItem key={key}>
											<p>{label}</p>
											<hr />
											{/* @ts-expect-error TS7053: Element implicitly has an 'any... */}
											<p>{stackingPlanData[key] ?? nullValueText}</p>
										</DataItem>
									);
								}
							)}
						</DataContainer>
					</StackingPlanContainer>
				</div>
			) : (
				<StackingPlanText>
					Create a stacking plan using Compstak's custom builder. Be the{' '}
					<UnderlinedText>first</UnderlinedText> to contribute an unexpired
					lease to this building's plan!
				</StackingPlanText>
			)}
			<StackingPlanButton
				variant={'secondary'}
				onClick={() => {
					navigate(routes.stackingPlan.toHref({ propertyId }));
				}}
			>
				Build stacking plan
			</StackingPlanButton>
			{hasData && (
				<Note>
					Note: Lease counts above are based on leases from the CompStak
					database.
				</Note>
			)}
		</StackingPlanWidgetContainer>
	);
});

StackingPlan.displayName = 'StackingPlan';

const stackingPlanDataLabels = {
	notExpiredLeaseCount: {
		label: 'Unexpired Leases',
	},
	propertyLeasesExpiringInTwelveMonths: {
		label: 'Leases Expiring in 12 months',
	},
	propertyLeasesExpiringInFiveYears: {
		label: 'Leases Expiring in Five Years',
	},
	subleaseCount: {
		label: 'Subleases',
	},
};

const StackingPlanWidgetContainer = styled.div`
	margin: 24px 0;
`;

const StackingPlanContainer = styled.div`
	display: flex;
	align-items: stretch;
	margin: 16px 0;
	img {
		width: 400px;
		height: 300px;
	}
`;

const StackingPlanButton = styled(Button)`
	font-size: 12px;
	line-height: 20px;
	&:not(:disabled):hover {
		background-color: ${({ theme }) => theme.colors.blue.blue500};
	}
`;

const StackingPlanText = styled.p`
	font-size: 14px;
	line-height: 24px;
	margin: 16px 0;
`;

const UnderlinedText = styled.span`
	text-decoration: underline ${({ theme }) => theme.colors.gray.gray700};
	text-underline-offset: 2px;
`;

const Note = styled.p`
	font-size: 16px;
	line-height: 24px;
	margin: 16px 0 0;
`;

const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	padding: 8px 0 8px 40px;
	margin-left: 40px;
	border-left: 1px solid ${({ theme }) => theme.colors.gray.gray110};
`;

const DataItem = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	p {
		font-size: 16px;
		line-height: 24px;
		white-space: nowrap;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	hr {
		width: 100%;
		border: 1px dashed ${({ theme }) => theme.colors.gray.gray110};
	}
`;

const PendingContainer = styled.div`
	height: 200px;
	margin-top: 16px;
`;
