import { useActiveMfaSetupScreen } from 'Components/MultiFactorAuth/MfAuthHooks';
import {
	DisableMultiFactorAuth,
	SetupMultiFactorAuth,
} from 'Components/MultiFactorAuth/SetupMfAuth';
import { useReferenceData, useUser } from 'Pages/Login/reducers';
import { useState } from 'react';
import layoutStyles from 'ui/styles/layout.less';
import Account from '../../ui/svg_icons/account.svg';
import EmailText from '../../ui/svg_icons/email_text.svg';
import LockLarge from '../../ui/svg_icons/lock_large.svg';
import AccountSettings from './Components/AccountSettings';
import EmailSettings from './Components/EmailSettings';
import NotFound from './Components/NotFound';
import { PasswordSettings } from './Components/PasswordSettings';
import './styles/content.nomodule.less';
import styles from './styles/settings.less';
import { useMarkets } from 'hooks/useMarkets';

const classMap = {
	account: AccountSettings,
	email: EmailSettings,
	password: PasswordSettings,
};

type Props = {
	params: {
		page: string;
	};
};

export const EnterpriseSettings = (props: Props) => {
	const [totpCode, setTotpCode] = useState('');
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	const SubComponent = classMap[props.params.page] || NotFound;
	const shortcutLabelClasses = { [props.params.page]: styles.selected };
	const {
		activeMfaScreen,
		hideActiveMfaScreen,
		showDisableMfaScreen,
		showSetupMfaScreen,
	} = useActiveMfaSetupScreen();

	const markets = useMarkets();
	const user = useUser();

	const referenceData = useReferenceData();

	if (activeMfaScreen === 'mfa-setup-screen') {
		return (
			<SetupMultiFactorAuth
				cancel={hideActiveMfaScreen}
				// @ts-expect-error TS2739: Type '{ totpCode: string; }' i...
				credentials={{ totpCode }}
				setTotpCode={(code: string) => setTotpCode(code)}
			/>
		);
	}
	if (activeMfaScreen === 'mfa-disable-screen') {
		return <DisableMultiFactorAuth cancel={hideActiveMfaScreen} />;
	}

	return (
		<section className={layoutStyles.contentWithSidebar}>
			<div className={layoutStyles.sidebar}>
				<ul className={styles.tabItemView}>
					<li className={shortcutLabelClasses.account || ''}>
						<a href="/settings/account" className={styles.sidebarIcon}>
							<span>
								<Account width={27} height={22} />
							</span>
							<span className={styles.shortcutLabel}>USER ACCOUNT</span>
						</a>
					</li>
					<li className={shortcutLabelClasses.email || ''}>
						<a href="/settings/email" className={styles.sidebarIcon}>
							<span>
								<EmailText width={28} height={28} />
							</span>
							<span className={styles.shortcutLabel}>EMAIL SUBSCRIPTIONS</span>
						</a>
					</li>
					<li className={shortcutLabelClasses.password || ''}>
						<a href="/settings/password" className={styles.sidebarIcon}>
							<span>
								<LockLarge width={27} height={30} />
							</span>
							<span className={styles.shortcutLabel}>PASSWORD</span>
						</a>
					</li>
				</ul>
			</div>
			<div className={`${layoutStyles.content}`}>
				<SubComponent
					referenceData={referenceData}
					user={user}
					markets={markets}
					showSetupMfaScreen={showSetupMfaScreen}
					showDisableMfaScreen={showDisableMfaScreen}
				/>
			</div>
		</section>
	);
};
