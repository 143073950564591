import { Portfolio, PropertyTypeId } from 'api';
import { useMemo } from 'react';
import { getValidPropertyTypes } from '../getValidPropertyTypes';
import { useMarketRentState } from '../MarketRentProvider';
import { DropdownItem } from './Dropdown';

type Props = {
	portfolio: Portfolio;
};

export const usePropertyTypeOptions = ({
	portfolio,
}: Props): DropdownItem<PropertyTypeId>[] => {
	const [marketRentState] = useMarketRentState();

	return useMemo(() => {
		if (marketRentState.selectedMarketId == null) {
			return [];
		}

		const validPropertyTypes = getValidPropertyTypes(
			portfolio.propertyTypesByMarkets,
			marketRentState.selectedMarketId
		);

		return validPropertyTypes.map((pm) => ({
			id: pm.buildingPropertyTypeId,
			value: pm.buildingPropertyType,
		}));
	}, [portfolio, marketRentState.selectedMarketId]);
};
