import Multiselect, {
	FilterOption,
	MultiselectProps,
} from 'Components/Filters/Base/Multiselect';
import React, { useMemo } from 'react';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { useReferenceData } from 'Pages/Login/reducers';
import { useSpaceTypeAggregations } from 'api/spaceTypeAggregations/useSpaceTypeAggregations';
import {
	getCountForAggregation,
	renderAggregationOption,
} from './aggregationFilterHelper';
import { alphabeticalOrder } from 'utils';
import { FilterErrors } from 'Components/Filters/Base/Filter/Container';

const attribute = 'buildingPropertyTypeId' as const;

type Props = {
	nestedFilterIsSet?: boolean;
	setError?: (errors: FilterErrors) => void;
	touch?: NoArgCallback;
	multiSelectProps?: Partial<MultiselectProps>;
};

export const PropertyTypeMultiselect = ({
	multiSelectProps,
	nestedFilterIsSet,
	setError,
	touch,
}: Props) => {
	const { compType, context, filters, onFilterChange } =
		useFilterFieldContext();
	const referenceData = useReferenceData();

	const { data: aggregationsData } = useSpaceTypeAggregations({
		compType,
		filters,
	});
	const aggregation = aggregationsData?.buildingPropertyTypeId;

	const options: FilterOption[] = useMemo(() => {
		if (!referenceData?.filterablePropertyTypes) {
			return [];
		}

		const filterablePropertyTypes =
			context !== 'analytics'
				? referenceData.filterablePropertyTypes.filter(
						(option) =>
							getCountForAggregation({
								aggregation,
								option,
							}) > 0
					)
				: referenceData.filterablePropertyTypes;

		return alphabeticalOrder({
			list: filterablePropertyTypes,
			sortByProperty: 'name',
		});
	}, [aggregation, context, referenceData?.filterablePropertyTypes]);

	return (
		<Multiselect
			attribute={attribute}
			filter={filters[attribute]}
			onFilterChange={onFilterChange}
			options={options}
			renderOption={(option) =>
				renderAggregationOption({
					aggregation,
					option,
					shouldRenderAggregationCount: context !== 'analytics',
				})
			}
			renderAllPlaceholder={() => 'All Property Types'}
			setError={setError}
			touch={touch}
			initialExpanded={!nestedFilterIsSet}
			{...multiSelectProps}
		/>
	);
};
