import React, { useState, KeyboardEvent, ChangeEvent } from 'react';

import { domainsMatch, isValidEmail } from 'util/validate';
import { Project } from 'Pages/Analytics/analytics';
import { useUser } from 'Pages/Login/reducers';
import { useDispatch } from 'react-redux';
import { sendProject } from '../../actions';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalInputMessage,
	ModalInputLabel,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

type ShareProjectModalProps = {
	project: Project;
};

export const ShareProjectModal = ({ project }: ShareProjectModalProps) => {
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const user = useUser();

	const dispatch = useDispatch();
	const { closeModal } = useModal();

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onShare();
		}
	};

	const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setErrorMessage('');
	};

	const onShare = () => {
		if (!isValidEmail(email)) {
			setErrorMessage('Email is not valid');
			return;
		}

		if (!domainsMatch(email, user)) {
			setErrorMessage("Email domain must match your account's email domain");
			return;
		}

		if (project.id) {
			dispatch(sendProject(project.id, email));
		}
		closeModal();
	};

	return (
		<>
			<ModalTitle>Share Project</ModalTitle>
			<ModalParagraph>
				Enter your colleague’s email below to send them: <b>{project.name}</b>
			</ModalParagraph>
			<ModalInputLabel isError={!!errorMessage}>
				Recipient’s Email Address
				<input
					type="text"
					value={email}
					placeholder="Email Address"
					onChange={updateEmail}
					onKeyDown={handleKeyDown}
				/>
				{!!errorMessage && (
					<ModalInputMessage>{errorMessage}</ModalInputMessage>
				)}
			</ModalInputLabel>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton variant="primary" onClick={onShare}>
					Share
				</ModalButton>
			</ModalButtons>
		</>
	);
};
