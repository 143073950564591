import {
	Button,
	TooltipV2,
	defaultTheme,
	downloadFile,
} from '@compstak/ui-kit';
import { PDFDownloadLinkProps, pdf } from '@react-pdf/renderer';
import { addFeedback, removeFeedback } from 'Singletons/Feedback/actions';
import { useCheckLeasesExportStatusQuery } from 'api/checkExportStatus/useCheckLeasesExportStatusQuery';
import { useCheckPropertiesExportStatusQuery } from 'api/checkExportStatus/useCheckPropertiesExportStatusQuery';
import { useCheckSalesExportStatusQuery } from 'api/checkExportStatus/useCheckSalesExportStatusQuery';
import { useExportLeasesMutation } from 'api/export/useExportLeasesMutation';
import { useExportPropertiesMutation } from 'api/export/useExportPropertiesMutation';
import { useExportSalesMutation } from 'api/export/useExportSalesMutation';
import html2canvas from 'html2canvas';
import { uniq } from 'lodash';
import { useModal } from 'providers/ModalProvider';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ImageUrls } from '../ExportPdf/ExportPdfStepTwo';
import { useSubmarketTrendsContext } from '../SubmarketTrendsTab/SubmarketTrendsProvider';
import { ExportLimitExceededDialog } from './ExportLimitExceededDialog';
import { useExportPdfContext } from './ExportPdfProvider';
import { MARKET_STATS_CONTAINER_ID } from './MarketStatsPage/EditMarketStatsPage';
import {
	commercialTrendChartsId,
	mufaTrendChartsId1,
	mufaTrendChartsId2,
} from './SubmarketTrends';
import { getDocumentTitle } from './getDocumentTitle';
import { ExportPdfStep } from './types';
import { ExportPdfData } from './useExportPdfData';
import { useMarketStatsFilters } from '../MarketStats/MarketStatsFiltersProvider';

type Props = {
	data: ExportPdfData;
	document: PDFDownloadLinkProps['document'];
	leasesInCompsetSelection: number[];
	recentLeasesSelection: number[];
	leasesSelection: number[];
	salesSelection: number[];
	onPrepared: (args: { imageUrls: ImageUrls }) => void;
};

export const ExportPdfButton = ({
	data,
	document,
	leasesInCompsetSelection,
	recentLeasesSelection,
	leasesSelection,
	salesSelection,
	onPrepared,
}: Props) => {
	const [prepareStatus, setPrepareStatus] =
		useState<PrepareStatus>('notPrepared');
	const [isLoadingExportPdf, setIsLoadingExportPdf] = useState(false);

	const { sections, setStep } = useExportPdfContext();

	const leaseCompIds = useMemo(() => {
		return uniq([
			...leasesSelection,
			...leasesInCompsetSelection,
			...recentLeasesSelection,
		]);
	}, [leasesSelection, leasesInCompsetSelection, recentLeasesSelection]);

	const hasLeases = leaseCompIds.length > 0;
	const hasSales = salesSelection.length > 0;

	const { data: exportStatusLeases, isFetching: isFetchingExportStatusLeases } =
		useCheckLeasesExportStatusQuery(
			{
				compIds: leaseCompIds,
				format: 'pdf',
			},
			{
				enabled: hasLeases,
			}
		);

	const { data: exportStatusSales, isFetching: isFetchingExportStatusSales } =
		useCheckSalesExportStatusQuery(
			{
				compIds: salesSelection,
				format: 'pdf',
			},
			{
				enabled: hasSales,
			}
		);

	const {
		data: exportStatusProperties,
		isFetching: isFetchingExportStatusProperties,
	} = useCheckPropertiesExportStatusQuery({
		compIds: [data.property.id],
		format: 'pdf',
	});

	const isFetchingExportStatus =
		isFetchingExportStatusLeases ||
		isFetchingExportStatusSales ||
		isFetchingExportStatusProperties;

	const canExport =
		(hasLeases ? exportStatusLeases?.canExport : true) &&
		(hasSales ? exportStatusSales?.canExport : true) &&
		exportStatusProperties?.canExport;

	const exportTooltip = (
		<ul style={{ listStyle: 'inside' }}>
			{hasLeases && exportStatusLeases && (
				<li>
					Leases Selected: {leaseCompIds.length}/
					{exportStatusLeases.unlimited
						? 'Unlimited'
						: exportStatusLeases.availableWithFinancial}
				</li>
			)}
			{hasSales && exportStatusSales && (
				<li>
					Sales Selected: {salesSelection.length}/
					{exportStatusSales.unlimited
						? 'Unlimited'
						: exportStatusSales.availableWithFinancial}
				</li>
			)}
			<li>Subject Property Selected: 1</li>
		</ul>
	);

	//adding +1 for subject property
	const totalSelection = leaseCompIds.length + salesSelection.length + 1;

	const { mutateAsync: exportLeases, isLoading: isLoadingExportLeases } =
		useExportLeasesMutation();

	const { mutateAsync: exportSales, isLoading: isLoadingExportSales } =
		useExportSalesMutation();

	const {
		mutateAsync: exportProperties,
		isLoading: isLoadingExportProperties,
	} = useExportPropertiesMutation();

	const isLoadingExport =
		isLoadingExportLeases || isLoadingExportSales || isLoadingExportProperties;

	const {
		commercialTrendsFilters,
		mufaTrendsFilters,
		trendsToShow,
		isTrendsFetching,
	} = useSubmarketTrendsContext();

	const [marketStatsFilters] = useMarketStatsFilters();

	const shouldPrepare =
		(sections.submarketTrends &&
			(trendsToShow.commercialTrends || trendsToShow.mufaTrends)) ||
		sections.marketStats;

	useEffect(() => {
		setPrepareStatus('notPrepared');
	}, [commercialTrendsFilters, mufaTrendsFilters, marketStatsFilters]);

	const { openModal } = useModal();

	const dispatch = useDispatch();

	const onClick = async () => {
		//  check export limit and show modal if needed
		if (!canExport) {
			openModal({
				modalContent: (
					<ExportLimitExceededDialog
						numberOfSelectedLeases={leaseCompIds.length}
						exportStatusLeases={exportStatusLeases}
						numberOfSelectedSales={salesSelection.length}
						exportStatusSales={exportStatusSales}
						exportStatusProperties={exportStatusProperties}
						data={data}
					/>
				),
			});
		}
		// prepare pdf document if needed
		else if (shouldPrepare && prepareStatus !== 'prepared') {
			setPrepareStatus('isPreparing');

			const promises = [];
			const chartKeys: Array<keyof ImageUrls> = [];

			if (sections.submarketTrends) {
				if (trendsToShow.commercialTrends) {
					promises.push(getChartGeneratedURL(commercialTrendChartsId));
					chartKeys.push('commercial');
				}
				if (trendsToShow.mufaTrends) {
					promises.push(getChartGeneratedURL(mufaTrendChartsId1));
					promises.push(getChartGeneratedURL(mufaTrendChartsId2));
					chartKeys.push('mufa1', 'mufa2');
				}
			}
			if (sections.marketStats) {
				promises.push(getChartGeneratedURL(MARKET_STATS_CONTAINER_ID));
				chartKeys.push('marketStats');
			}
			if (promises.length > 0) {
				const results = await Promise.all(promises);
				const trends: Partial<ImageUrls> = {};
				results.forEach((url, index) => {
					trends[chartKeys[index]] = url ?? '';
				});
				onPrepared({ imageUrls: trends as ImageUrls });
			}
			setPrepareStatus('prepared');
		}
		// finally export
		else {
			await Promise.all([
				hasLeases &&
					exportLeases({
						compIds: leaseCompIds,
						format: 'pdf',
						monthly: true,
						withFinancial: true,
					}),
				hasSales &&
					exportSales({
						compIds: salesSelection,
						format: 'pdf',
						monthly: true,
						withFinancial: true,
					}),
				exportProperties({
					compIds: [data.property.id],
					monthly: true,
					format: 'pdf',
					withFinancial: true,
				}),
			]);

			setIsLoadingExportPdf(true);
			const pdfInstance = pdf();
			pdfInstance.updateContainer(document);
			const blob = await pdfInstance.toBlob();
			const url = URL.createObjectURL(blob);
			const documentTitle = getDocumentTitle(data.property);
			downloadFile(url, `${documentTitle}.pdf`);
			setIsLoadingExportPdf(false);
			dispatch(
				addFeedback(
					`PDF Export of ${documentTitle} - complete.`,
					'',
					`exportPropertyInPdf-${documentTitle}`,
					5000,
					[
						{
							text: 'Make changes',
							action: (alertId: string) => {
								dispatch(removeFeedback(alertId));
								setStep(ExportPdfStep.ONE);
							},
						},
						{
							text: 'Dismiss',
							action: (alertId: string) => removeFeedback(alertId),
						},
					]
				)
			);
		}
	};

	const isLoading =
		(shouldPrepare && (isTrendsFetching || prepareStatus === 'isPreparing')) ||
		isFetchingExportStatus ||
		isLoadingExport ||
		isLoadingExportPdf;

	return (
		<>
			<TooltipV2
				content={exportTooltip}
				side="bottom"
				contentCSS={() => ({
					zIndex: defaultTheme.zIndex.overlay + defaultTheme.zIndex.modal,
				})}
			>
				<StyledButton
					variant="primary"
					isLoading={isLoading}
					disabled={isLoading}
					onClick={onClick}
				>
					{shouldPrepare && prepareStatus === 'notPrepared'
						? 'Prepare for export'
						: 'Export to pdf'}{' '}
					({totalSelection})
				</StyledButton>
			</TooltipV2>
		</>
	);
};

type PrepareStatus = 'notPrepared' | 'isPreparing' | 'prepared';

export const getChartGeneratedURL = async (elementId: string) => {
	const chartElement = document.getElementById(elementId);
	if (!chartElement) {
		throw new Error('Chart element not found');
	}
	const canvas = await html2canvas(chartElement);
	return canvas.toDataURL('image/png');
};

const StyledButton = styled(Button)`
	flex-shrink: 0;
`;
