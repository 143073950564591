import { MouseEvent } from 'react';

import styles from '../stylesheets/login.less';
import button from 'ui/styles/button.less';

import { useMarkets } from 'hooks/useMarkets';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'util/useAppSelector';
import { acceptTerms, setAsCompany } from '../actions';
import { useUserQuery } from 'api';
import { logout } from 'auth/auth';
import userService from 'services/user';

export const TermsAndConditions = () => {
	const authState = useAppSelector((state) => state.authState);
	const markets = useMarkets();
	const { data: user } = useUserQuery();

	const dispatch = useDispatch();

	const toggleAsCompany = (event: MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault();
		dispatch(setAsCompany(!authState.displayGBCompany));
	};

	const declineTerms = async () => {
		await userService.declineTerms(user);
		logout();
	};

	const getGBContent = () => {
		const marketId = user?.primaryMarketId;
		const market = marketId ? markets[marketId] : null;
		if (market?.countryCode !== 'GB') {
			return undefined;
		} else if (authState.displayGBCompany) {
			return (
				<p>
					Please review the{' '}
					<a
						href="http://www.compstak.com/legal?company={{companyName}}&email={{email}}#ModelContract"
						target="_blank"
						rel="noreferrer"
					>
						terms and conditions of our model contract
					</a>
					. By clicking "Accept", you acknowledge that you have read, understood
					and agree to be legally bound by the European Commission Standard
					Contractual Clauses (processors). If you are accepting these clauses
					on behalf of a company or other legal entity, you represent that you
					have the authority to bind such company or legal entity.
					<br />
					<br />
					Note: If you are not signing up on behalf of a company, or a legal
					entity{' '}
					<a href="#" onClick={toggleAsCompany}>
						click here.
					</a>
				</p>
			);
		} else {
			return (
				<p>
					If I am a resident of the European Union, then by clicking "Accept", I
					consent to the transfer of my personal data and the personal data of
					third parties that I provide to you from my country to the United
					States for processing in order that I may receive the services. I
					understand that the European Commission has determined that the US
					does not provide adequate security for the personal data of European
					citizens, and that I have the right to revoke my consent at any time
					upon notice to you and my personal data will be returned.
					<br />
					<br />
					Note: If you are a European Union resident signing up on behalf of a
					company, or a legal entity{' '}
					<a href="#" onClick={toggleAsCompany}>
						click here
					</a>{' '}
					to view and agree the applicable terms of use.
				</p>
			);
		}
	};

	return (
		<div className={`${styles.terms_content} ${styles.login_screen_content}`}>
			<h1 className={styles.login_terms_caption}>Terms of Use</h1>

			<p>
				Before you can proceed to CompStak, you must
				<br />
				accept our Terms of Use
				{' & '}
				Privacy Policy.
				<br />
				You can view them{' '}
				<a
					href="http://www.compstak.com/legal/"
					target="_blank"
					rel="noreferrer"
				>
					here
				</a>
				.
			</p>

			{getGBContent()}

			<div className="terms_table">
				<div className="terms_accept">
					<span
						className={button.button}
						onClick={() => dispatch(acceptTerms(authState.displayGBCompany))}
					>
						ACCEPT
					</span>
				</div>

				<div className="terms_decline">
					<span className={button.gray} onClick={declineTerms}>
						DECLINE
					</span>
				</div>
			</div>
		</div>
	);
};
