import styled from 'styled-components';

export const DashboardCardLoadingContainer = styled.div`
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(p) => p.theme.colors.white.white};
	border-radius: 0.125rem;
	box-sizing: border-box;
`;
