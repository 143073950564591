import React from 'react';
import ReactTimeout from 'react-timeout';

import SpaceOnFloor from './Placed';
import CombinedSpacesOnFloor from './Combined';

import generateSpaceLayout from '../spaceLayoutGenerator';

import styles from '../stacking.less';

export default ReactTimeout(
	class SpaceContainer extends React.Component {
		state = {
			width: null,
			minRatio: 0,
		};

		componentDidMount() {
			window.addEventListener('resize', this.setWidth);
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.setWidth);
		}

		setWidth = () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'container' does not exist on type 'Space... Remove this comment to see the full error message
			const width = this.container.offsetWidth;
			const minRatio = 120 / width;
			this.setState({
				width,
				minRatio,
			});
		};

		// @ts-expect-error TS7006: Parameter 'el' implicitly has ...
		receiveRef = (el) => {
			if (el) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'container' does not exist on type 'Space... Remove this comment to see the full error message
				this.container = el;
				this.setWidth();
			}
		};

		render() {
			// we don't want the spaceDatas until we know the size of the viewport
			const spaceDatas = generateSpaceLayout(
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'placedSpaces' does not exist on type 'Re... Remove this comment to see the full error message
				this.props.placedSpaces,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				this.props.stackingPlan.floors,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'floorSpaceOccupied' does not exist on ty... Remove this comment to see the full error message
				this.props.floorSpaceOccupied,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				this.props.stackingPlan.position,
				this.state.minRatio
			);
			const className = styles.spaceWrapper;
			return (
				<div className={className} ref={this.receiveRef} key="container">
					{spaceDatas.map((obj) => {
						const Type = obj.spaces ? CombinedSpacesOnFloor : SpaceOnFloor;
						return (
							<Type
								key={obj.id || 'new'}
								// @ts-expect-error ts-migrate(2322) FIXME: Property 'index' does not exist on type 'Intrinsic... Remove this comment to see the full error message
								index={obj.index}
								floorIndex={obj.floorIndex}
								left={obj.transfromLeft}
								positionLeft={obj.positionLeft}
								width={obj.width}
								space={obj.space}
								spaces={obj.spaces}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
								menuActions={this.props.menuActions}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
								stackingPlanActions={this.props.stackingPlanActions}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
								stackingPlan={this.props.stackingPlan}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'repositionDraggedSpace' does not exist o... Remove this comment to see the full error message
								repositionDraggedSpace={this.props.repositionDraggedSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'originalStackingPlan' does not exist on ... Remove this comment to see the full error message
								originalStackingPlan={this.props.originalStackingPlan}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'startDraggingSpace' does not exist on ty... Remove this comment to see the full error message
								startDraggingSpace={this.props.startDraggingSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'endDraggingSpace' does not exist on type... Remove this comment to see the full error message
								endDraggingSpace={this.props.endDraggingSpace}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaceBeingDragged' does not exist on typ... Remove this comment to see the full error message
								spaceBeingDragged={this.props.spaceBeingDragged}
							/>
						);
					})}
				</div>
			);
		}
	}
);
