import { LineDescription, defaultTheme } from '@compstak/ui-kit';
import { addEmptyLines } from '@compstak/common';

export const lines: Array<LineDescription> = [
	{
		value: 'new',
		title: 'New',
		color: `${defaultTheme.colors.blue.blue700}`,
		type: 'monotone',
	},
	{
		value: 'renewal',
		title: 'Renewal',
		color: `${defaultTheme.colors.green.green500}`,
		type: 'monotone',
	},
	{
		value: 'all',
		title: 'All',
		color: `${defaultTheme.colors.purple.purple700}`,
		type: 'monotone',
	},
];

export const emptyLines = addEmptyLines(lines);
