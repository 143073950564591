import { useMarketRentDescriptionText } from 'ExchangeDashboard/MarketRentMap';
import { useMarketRentAnalyticsState } from 'maps';
import { useFilters } from 'reducers/filtersReducer';

export const useMarketRentSectionText = () => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();
	const [filters] = useFilters('exchange-dashboard');

	const descriptionText = useMarketRentDescriptionText(filters.market);

	return {
		title: `${marketRentAnalyticsState.propertyType} Market Rent Heat Map`,
		description: `${descriptionText} Data available for office and industrial property
				types in select markets only.`,
	};
};
