import { Table } from '@compstak/ui-kit';
import { memo } from 'react';
import styled from 'styled-components';
import { SalesComp } from 'types/comp';
import { TabContentHeader, TabContentTitle } from '../../Components';
import { columns } from './columns';

type Props = { data: SalesComp };

export const PortfolioTransactionDetails = memo<Props>(({ data }) => {
	return (
		<PortfolioTransactionDetailsContainer>
			<TabContentHeader>
				<TabContentTitle>Transaction Detail Per Property</TabContentTitle>
			</TabContentHeader>
			<Table
				id="portfolioTransactionDetails"
				columns={columns}
				rows={data.portfolio}
				isLoading={false}
				areRowsHoverable={false}
			/>
		</PortfolioTransactionDetailsContainer>
	);
});

PortfolioTransactionDetails.displayName = 'PortfolioTransactionDetails';

const PortfolioTransactionDetailsContainer = styled.div`
	margin-top: 32px;
`;
