import {
	ClusterLayer,
	Map,
	MapInteractivity,
	MapRef,
	MapStyle,
} from '@compstak/maps';
import { point } from '@turf/helpers';
import { usePortfolioPropertiesQuery } from 'api';
import { MapStyleButton } from 'maps';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioPopup } from './PortfolioPopup';
import { PortfolioByIdRouteSearchParams } from 'router';
import { CenterOnMarketsEffect } from 'Pages/Search/Map/Components/MapEffects/CenterOnMarketsEffect';
import { useMarkets } from 'hooks/useMarkets';
import { Flex, Spinner, defaultTheme } from '@compstak/ui-kit';

type PortfolioMapProps = {
	onMapRef?: (mapRef: MapRef) => void;
	isMapView?: boolean;
	onClickPinClusterIds?: (propertyIds: number[]) => void;
	portfolioId: number;
	searchParams?: PortfolioByIdRouteSearchParams;
};

export const PortfolioMap = ({
	onMapRef,
	isMapView,
	onClickPinClusterIds,
	portfolioId,
	searchParams,
}: PortfolioMapProps) => {
	const [mapStyle, setMapStyle] = useState(MapStyle.DEFAULT);
	const { filters, portfolio } = usePortfolioFilters();
	const markets = useMarkets();

	const { data: properties, isFetching: isFetchingPortfoliosProperties } =
		usePortfolioPropertiesQuery({
			portfolioId,
			...filters,
			...searchParams,
		});

	const propertyPoints = useMemo(() => {
		if (!properties) return [];
		return properties.map((p) => {
			const { lon, lat } = p.geoPoint;
			return point([lon, lat], p);
		});
	}, [properties]);

	const portfolioMarkets = useMemo(() => {
		if (!portfolio) {
			return [];
		}

		return portfolio.markets.map(({ marketId }) => markets[marketId]);
	}, [portfolio, markets]);

	return (
		<Map
			onMapRef={onMapRef}
			interactivity={
				isMapView
					? MapInteractivity.INTERACTIVE
					: MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM
			}
			mapStyle={mapStyle}
			logoPosition="top-left"
		>
			{isFetchingPortfoliosProperties && (
				<SpinnerContainer justifyContent="flex-end">
					<Spinner
						thickness="3px"
						color={defaultTheme.colors.gray.gray0}
						size="l"
					/>
				</SpinnerContainer>
			)}
			<CenterOnMarketsEffect markets={portfolioMarkets} />
			<ClusterLayer
				data={propertyPoints}
				circlesLayerProps={{ billboard: true }}
				pinsLayerProps={{
					getTooltipContent: (p) => `${p.properties.buildingAddressAndCity}`,
					getPopupContent(p, closePopup) {
						return (
							<PortfolioPopup
								closePopup={closePopup}
								isMinified={!isMapView}
								property={p.properties}
								portfolio={portfolio}
							/>
						);
					},
				}}
				onClickCluster={(_, leaves) => {
					if (typeof onClickPinClusterIds === 'function') {
						onClickPinClusterIds(leaves.map((pin) => pin.properties?.id));
					}
				}}
			/>
			<MapButtonsContainer>
				<MapStyleButton
					mapStyle={mapStyle}
					onClick={() => {
						setMapStyle(
							mapStyle === MapStyle.DEFAULT
								? MapStyle.SATELLITE
								: MapStyle.DEFAULT
						);
					}}
				/>
			</MapButtonsContainer>
		</Map>
	);
};

export const MapButtonsContainer = styled.div`
	bottom: 0;
	display: flex;
	height: 40px;
	left: 0;
	margin: 0 15px 15px 15px;
	position: absolute;
	max-width: 550px;
`;

const SpinnerContainer = styled(Flex)`
	margin-top: 0.75rem;
	margin-right: 1rem;
`;
