import { nullValueText } from '@compstak/common';
import { renderRentPeriodPostfix } from 'format';
import styled from 'styled-components';
import { Comp } from 'types';
import ErrorIcon from '../../../ui/svg_icons/error.svg';
import LockIcon from '../../../ui/svg_icons/lock.svg';
import AttributeValue from '../AttributeValue';
import styles from './startingRent.less';

type StartingRentComponentProps = {
	abbreviateText?: boolean;
	isMonthly: boolean;
	comp: Comp;
	tooltip?: boolean;
};

const StartingRentComponent = ({
	abbreviateText,
	isMonthly,
	comp,
	tooltip,
}: StartingRentComponentProps) => {
	if (
		// @ts-expect-error TS2339: Property 'presentationRentName...
		comp.presentationRentName &&
		// @ts-expect-error TS2339: Property 'presentationRentName...
		!comp.presentationRentName.match('startingRent')
	) {
		// @ts-expect-error TS2339: Property 'presentationRentName...
		let name = comp.presentationRentName.replace('Rent', '');
		if (name === 'avg') {
			name = 'Average';
		} else {
			name = name[0].toUpperCase() + name.slice(1);
		}
		if (tooltip) {
			return (
				<span
					className={styles.missingValue}
					data-tooltip={`${name} Rent Only`}
					data-tooltipdelay={100}
				>
					<ErrorIcon width={23} height={23} />
				</span>
			);
		} else {
			return (
				<span className={styles.missingValue}>
					<ErrorIcon width={23} height={23} /> {name} Rent Only
				</span>
			);
		}
		// @ts-expect-error TS2339: Property 'startingRent' does n...
	} else if (comp.startingRent === null) {
		return (
			<span className="locked-data">
				<LockIconContainerStyled>
					<LockIcon width={14} height={17} />
				</LockIconContainerStyled>
			</span>
		);
	} else if (
		// @ts-expect-error TS2339: Property 'startingRent' does n...
		comp.startingRent === undefined ||
		// @ts-expect-error TS2339: Property 'startingRent' does n...
		comp.startingRent === Infinity
	) {
		// Infinity is here for the nasty hack that gets the row to flash when you unlock a comp.
		return <span className={styles.missingValue}>{nullValueText}</span>;
	}

	const typeOfMarket = isMonthly ? 'a MONTHLY' : 'an ANNUAL';
	const tooltipText = `Starting Rent for this lease is set at ${typeOfMarket} amount`;

	// @ts-expect-error TS2339: Property 'startingRent' does n...
	const value = comp.startingRent;
	const valueOnly = value.split(' ')[0];

	const annualOrMonthly = renderRentPeriodPostfix(isMonthly, abbreviateText);

	return (
		<span
			data-placement="bottom"
			data-tooltipdelay="0"
			data-tooltip={tooltipText}
		>
			{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
			<AttributeValue value={valueOnly} /> {annualOrMonthly}
		</span>
	);
};

const LockIconContainerStyled = styled.div`
	float: right;
`;

export default StartingRentComponent;
