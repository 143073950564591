import { z } from 'zod';

const quarterValidator = z.object({
	quarter: z.number(),
	year: z.number(),
	earned: z.number(),
	redeemed: z.number(),
	fullCompsSubmitted: z.number(),
	levelThreshold: z.number(),
	nextLevelThreshold: z.number(),
	level: z.number(),
	nextLevel: z.number(),
});

export const rewardHistoryValidator = z.object({
	userId: z.number(),
	currentQuarter: quarterValidator,
	previousQuarter: quarterValidator,
	history: z.array(
		z.object({
			amount: z.number(), // Amount in dollars (not cents).
			timestamp: z.string(),
			year: z.number(),
		})
	),
});

export const vendorsValidator = z.array(
	z.object({
		id: z.number(),
		name: z.string(),
		image_url: z.string(),
	})
);

export type Vendors = z.infer<typeof vendorsValidator>;
export type RewardHistory = z.infer<typeof rewardHistoryValidator>;
