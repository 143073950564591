import { useUserQuery } from 'api';
import FreeCompsCounter from 'Components/Header/FreeComps';
import FreeCompsNotification from 'Components/Header/FreeCompsNotification';
import PointsCounter from 'Components/Header/Points';
import { useIsExchange } from 'hooks';
import { routes, useLocation } from 'router';
import { HeaderGreenButtonLink } from './UI';

export const useCreditsLinks = () => {
	const { data: user } = useUserQuery();
	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;
	const location = useLocation();

	if (
		isEnterprise &&
		(!user.salesTrader ||
			// not sure about the 2 conditions below
			// but they were here before
			!location.pathname.match('/sales') ||
			location.pathname === '/home')
	) {
		return null;
	}

	return (
		<>
			<FreeCompsCounter freeComps={user.freeComps} />
			<FreeCompsNotification freeComps={user.freeComps} />
			<PointsCounter user={user} />
			<HeaderGreenButtonLink to={routes.upload.path}>
				Get Credits
			</HeaderGreenButtonLink>
		</>
	);
};
