import { LeaseInsights, useHasLeaseInsights } from 'Components/LeaseInsights';
import { useEffect, useRef } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { LeasesSearchTable } from './LeasesSearchTable';
import { PropertiesSearchTable } from './PropertiesSearchTable';
import { SalesSearchTable } from './SalesSearchTable';

type Props = {
	compTab: 'leases' | 'sales' | 'properties';
	insightsAreExpanded: boolean;
	toggleInsightsAreExpanded: () => void;
};

export const NewSearchTableLayout = ({
	compTab,
	insightsAreExpanded,
	toggleInsightsAreExpanded,
}: Props) => {
	const [filters] = useFilters();
	const hasInsights = useHasLeaseInsights({ filters });
	// to not unmount the table when compTab is null (when modal routes opened)
	const lastPresentCompTabRef = useRef(compTab);

	useEffect(() => {
		if (compTab != null) lastPresentCompTabRef.current = compTab;
	}, [compTab]);

	const compTabToUse = compTab ?? lastPresentCompTabRef.current;

	return (
		<TableLayout
			style={{
				gridTemplateRows:
					compTabToUse === 'leases' && hasInsights
						? insightsAreExpanded
							? '35px 270px auto'
							: `35px auto`
						: undefined,
			}}
		>
			{compTabToUse === 'leases' && (
				<>
					{hasInsights && (
						<LeaseInsights
							isOpen={insightsAreExpanded}
							toggleIsOpen={toggleInsightsAreExpanded}
						/>
					)}
					<LeasesSearchTable
						settingsMenuSide={
							hasInsights && insightsAreExpanded ? 'right' : 'bottom'
						}
					/>
				</>
			)}
			{compTabToUse === 'sales' && <SalesSearchTable />}
			{compTabToUse === 'properties' && <PropertiesSearchTable />}
		</TableLayout>
	);
};

const TableLayout = styled.div`
	position: absolute;
	top: 90px;
	left: 0;
	width: 100%;
	height: calc(100% - 90px);
	z-index: 1;
	display: grid;
	background-color: #fff;
`;
