export const STATIC_COMPANY_LOGOS: Record<string, string> = {
	'29th Street Capital': 'https://logo.clearbit.com/29thstreetcapital.com',
	'33 Nassau LLC': 'https://logo.clearbit.com/kalmondolgin.com',
	'601W Companies': 'https://logo.clearbit.com/601wcompanies.com',
	'Abacus Capital Group': 'https://logo.clearbit.com/abacuscapitalgroup.com',
	'Abacus Capital Partners': 'https://logo.clearbit.com/abacuscapitalgroup.com',
	'Abu Dhabi Investment Authority': 'https://logo.clearbit.com/adia.ae',
	'Acacia Capital': 'https://logo.clearbit.com/acaciacapital.com',
	'Acacia Capital Corporation': 'https://logo.clearbit.com/acaciacapital.com',
	'Acadia Realty': 'https://logo.clearbit.com/acadiarealty.com',
	'Acadia Realty Trust': 'https://logo.clearbit.com/acadiarealty.com',
	'Accesso Partners': 'https://logo.clearbit.com/accessopartners.com',
	'Accesso Partners LLC': 'https://logo.clearbit.com/accessopartners.com',
	'Adler Development': 'https://logo.clearbit.com/adlerdevelopment.com',
	'Adler Realty Investments': 'https://logo.clearbit.com/adlerrealty.com',
	'AEW Capital Management': 'https://logo.clearbit.com/aew.com',
	'AFL-CIO Building Investment': 'https://logo.clearbit.com/aflcio-itc.com',
	'Agellan Commercial REIT':
		'https://logo.clearbit.com/agellancommercialreit.com',
	'Agree Realty Corporation': 'https://logo.clearbit.com/agreerealty.com',
	AIG: 'https://logo.clearbit.com/aig.com',
	'AION Partners': 'https://logo.clearbit.com/aionpartners.com',
	'AIR Communities': 'https://logo.clearbit.com/aircommunities.com',
	'Alaris Health': 'https://logo.clearbit.com/alarishealth.com',
	'Albany Road Real Estate Partners':
		'https://logo.clearbit.com/albanyroadre.com',
	'Alere Property Group': 'https://logo.clearbit.com/alerellc.com',
	'Alere Property Group LLC': 'https://logo.clearbit.com/alerellc.com',
	'Alexandria Real Estate Equites': 'https://logo.clearbit.com/are.com',
	'Alexandria Real Estate Equities': 'https://logo.clearbit.com/are.com',
	'Alexandria Real Estate Equities, Inc.': 'https://logo.clearbit.com/are.com',
	'Alfred Sanzari Enterprises': 'https://logo.clearbit.com/sanzari.com',
	'Alidade Capital': 'https://logo.clearbit.com/alidadecapital.com',
	'Alliance HP': 'https://logo.clearbit.com/alliancehp.com',
	'Alliance Residential': 'https://logo.clearbit.com/allresco.com',
	'Allianz Real Estate': 'https://logo.clearbit.com/allianz.com',
	'Alloy Properties': 'https://logo.clearbit.com/alloyproperties.com',
	Allstate: 'https://logo.clearbit.com/allstate.com',
	'Alpha Industrial': 'https://logo.clearbit.com/alphaip.com',
	'Alpha Industrial Properties': 'https://logo.clearbit.com/alphaip.com',
	'Altera Fund Advisors': 'https://logo.clearbit.com/alterafundadvisors.com',
	'Alterra Property Group': 'https://logo.clearbit.com/alterraproperty.com',
	Amazon: 'https://logo.clearbit.com/amazon.com',
	'Amazon.com': 'https://logo.clearbit.com/amazon.com',
	'American Assets Trust': 'https://logo.clearbit.com/americanassetstrust.com',
	'American Property Management':
		'https://logo.clearbit.com/americanpropertymgmt.com',
	'American Real Estate Partners':
		'https://logo.clearbit.com/americanrepartners.com',
	'American Realty Advisors': 'https://logo.clearbit.com/americanreal.com',
	'AMLI Residential': 'https://logo.clearbit.com/greekrep.com',
	'Amsprop Estates': 'https://logo.clearbit.com/amsprop.com',
	'Amsprop Limited': 'https://logo.clearbit.com/amsprop.com',
	'AmTrust Realty': 'https://logo.clearbit.com/amtrustre.com',
	'Anchor Health Properties':
		'https://logo.clearbit.com/anchorhealthproperties.com',
	'Anchor Line Partners': 'https://logo.clearbit.com/anchorlinepartners.com',
	'Angelo Gordon': 'https://logo.clearbit.com/angelogordon.com',
	'Apollo Global Management': 'https://logo.clearbit.com/apollo.com',
	Apple: 'https://logo.clearbit.com/apple.com',
	'Apple Hospitality REIT':
		'https://logo.clearbit.com/applehospitalityreit.com',
	'Arcadia Development Co': 'https://logo.clearbit.com/arcadia.com',
	'Arden Group': 'https://logo.clearbit.com/ardengroup.com',
	'Arden Group, Silverstein Properties, Migdal Insurance':
		'https://logo.clearbit.com/ardengroup.com',
	'Arden Realty': 'https://logo.clearbit.com/ardengroup.com',
	'Arden Realty, Inc.': 'https://logo.clearbit.com/ardengroup.com',
	'Ares Management': 'https://logo.clearbit.com/aresmgmt.com',
	'Arris Partners,Seabrook Real Estate Partners':
		'https://logo.clearbit.com/arrispartnersllc.com',
	'Artemis Real Estate Partners': 'https://logo.clearbit.com/artemisrep.com',
	'Artis REIT': 'https://logo.clearbit.com/artisreit.com',
	'Asana Partners': 'https://logo.clearbit.com/asanapartners.com',
	'ASB Capital Management': 'https://logo.clearbit.com/asbcm.com',
	'ASB Real Estate Investments': 'https://logo.clearbit.com/asbcm.com',
	'Ascendas REIT': 'https://logo.clearbit.com/capitaland-ascendasreit.com',
	'Ashcroft Capital': 'https://logo.clearbit.com/ashcroftcapital.com',
	'Ashkenazy Acquisition Corporation':
		'https://logo.clearbit.com/ashkenazyacquisition.com',
	'Ashley Capital': 'https://logo.clearbit.com/ashleycaptl.com',
	'ATCAP Partners': 'https://logo.clearbit.com/atcappartners.com',
	'Atlas Capital Group': 'https://logo.clearbit.com/atlas-cap.com',
	'Atlas Capital Group, LLC': 'https://logo.clearbit.com/atlas-cap.com',
	AustralianSuper: 'https://logo.clearbit.com/australiansuper.com',
	'AvalonBay Communities': 'https://logo.clearbit.com/avaloncommunities.com',
	'Avanath Capital Management': 'https://logo.clearbit.com/avanath.com',
	'Avara Pharmaceutical Services': 'https://logo.clearbit.com/avara.com',
	'Aviva Investors': 'https://logo.clearbit.com/avivainvestors.com',
	AXA: 'https://logo.clearbit.com/axa-im.com',
	'AXA Investment Managers ‚Äì Real Assets':
		'https://logo.clearbit.com/axa-im.com',
	'B Developments': 'https://logo.clearbit.com/bdevelopments.com',
	'B.F. Saul Company': 'https://logo.clearbit.com/bfsaul.com',
	Bailard: 'https://logo.clearbit.com/bailard.com',
	'Balch Enterprises': 'https://logo.clearbit.com/balchenterprises.com',
	'Balch Enterprises, Inc.': 'https://logo.clearbit.com/balchenterprises.com',
	'Bandera Ventures': 'https://logo.clearbit.com/banderaventures.com',
	'Bank of America': 'https://logo.clearbit.com/bankofamerica.com',
	'Banyan Street Capital': 'https://logo.clearbit.com/banyanstreet.com',
	Barings: 'https://logo.clearbit.com/barings.com',
	'Baron Properties': 'https://logo.clearbit.com/baronproperties.com',
	'Barry Swenson Builder': 'https://logo.clearbit.com/swenson.com',
	'Basin Street Properties': 'https://logo.clearbit.com/basin-street.com',
	'Beachwold Residential': 'https://logo.clearbit.com/beachwold.com',
	'Beacon Capital Partners': 'https://logo.clearbit.com/beaconcapital.com',
	'Beacon Partners': 'https://logo.clearbit.com/beacondevelopment.com',
	'BEB Capital': 'https://logo.clearbit.com/bebcapital.com',
	'Becknell Industrial': 'https://logo.clearbit.com/becknellindustrial.com',
	'Bedford Estates (The)': 'https://logo.clearbit.com/bedfordestates.com',
	'Benderson Development Company':
		'https://logo.clearbit.com/bendersondevelopment.com',
	'Bentall Greenoak': 'https://bgo.com/hubfs/Logos/logo-bgo-rgb.svg',
	BentallGreenOak: 'https://logo.clearbit.com/bentallgreenoak.com',
	'Bergman Real Estate Group': 'https://logo.clearbit.com/bergmanrealty.com',
	'Berkeley Investments': 'https://logo.clearbit.com/berkinv.com',
	'Berkeley Partners': 'https://logo.clearbit.com/berkeleypartners.com',
	'BH Properties': 'https://logo.clearbit.com/bhproperties.com',
	'BioMed Realty': 'https://logo.clearbit.com/biomedrealty.com',
	'BioMed Realty Trust': 'https://logo.clearbit.com/biomedrealty.com',
	'Bixby Land Company': 'https://logo.clearbit.com/bixbyland.com',
	'BKM Capital Partners': 'https://logo.clearbit.com/bkmcapitalpartners.com',
	'Black Creek Group':
		'https://logo.clearbit.com/bcgretail.propertycapsule.com',
	'Black Mountain Properties':
		'https://logo.clearbit.com/blackmountainproperties.com',
	'Black Mountain Properties, LLC':
		'https://logo.clearbit.com/blackmountainproperties.com',
	'Blackfin Real Estate Investors': 'https://logo.clearbit.com/blackfinrei.com',
	Blackrock: 'https://logo.clearbit.com/blackrock.com',
	BlackRock: 'https://logo.clearbit.com/blackrock.com',
	Blackstone: 'https://logo.clearbit.com/breit.com',
	'Blackstone Group': 'https://logo.clearbit.com/breit.com',
	'Blackstone Real Estate': 'https://logo.clearbit.com/breit.com',
	'Blackstone Real Estate Income Trust Inc':
		'https://logo.clearbit.com/breit.com',
	'Blaze Capital Partners': 'https://logo.clearbit.com/blazepartners.com',
	'Blue Ridge Associates': 'https://logo.clearbit.com/bri-re.com',
	'Blue Ridge Industrial': 'https://logo.clearbit.com/bri-re.com',
	'Blue Vista Capital Management': 'https://logo.clearbit.com/bluevistallc.com',
	'Blueline Equity Partners':
		'https://logo.clearbit.com/bluelineequitypartners.com',
	'Blueline Equity Partners, LLC':
		'https://logo.clearbit.com/bluelineequitypartners.com',
	'BML Development Corp': 'https://logo.clearbit.com/bellevuedowntown.com',
	'Bohannon Development Company':
		'https://logo.clearbit.com/bohannondevelopment.com',
	'Bohannon Development Corporation':
		'https://logo.clearbit.com/bohannondevelopment.com',
	Bonaventure: 'https://logo.clearbit.com/bonaventure.com',
	'Borelli Investment Company': 'https://logo.clearbit.com/borelli.com',
	'Boston Properties': 'https://logo.clearbit.com/bxp.com',
	'Boston Properties, Inc.': 'https://logo.clearbit.com/bxp.com',
	'Boxer Property': 'https://logo.clearbit.com/boxerproperty.com',
	'Boyd Watterson Asset Management':
		'https://logo.clearbit.com/boydwatterson.com',
	'Boylston Properties': 'https://logo.clearbit.com/boylstonproperties.com',
	'Brady Sullivan Properties': 'https://logo.clearbit.com/bradysullivan.com',
	'Brandywine Realty Trust': 'https://logo.clearbit.com/brandywinerealty.com',
	'Breakthrough Properties': 'https://logo.clearbit.com/btprop.com',
	'Breakthrough Properties (Tishman Speyer & Bellco Capital)':
		'https://logo.clearbit.com/btprop.com',
	'Brennan Investment Group': 'https://logo.clearbit.com/brennanllc.com',
	'Bridge Investment Group': 'https://logo.clearbit.com/bridgeig.com',
	'Bristol Group': 'https://logo.clearbit.com/bristolgroupinc.com',
	'British Land Company': 'https://logo.clearbit.com/britishland.com',
	'British Land Company (The)': 'https://logo.clearbit.com/britishland.com',
	'Broadreach Capital Partners': 'https://logo.clearbit.com/broadreachcp.com',
	'Broadstone Net Lease': 'https://logo.clearbit.com/broadstone.com',
	'Broadway Partners': 'https://logo.clearbit.com/co.uk',
	'Brookfield AM': 'https://logo.clearbit.com/brookfield.com',
	'Brookfield Asset Management, Inc.':
		'https://logo.clearbit.com/brookfield.com',
	'Brookfield Properties': 'https://logo.clearbit.com/brookfieldproperties.com',
	'Brookfield Property Partners': 'https://logo.clearbit.com/brookfield.com',
	'Brookfield Properties Prtnrs': 'https://logo.clearbit.com/brookfield.com',
	'Brookwood Financial Partners':
		'https://logo.clearbit.com/brookwoodfinancial.com',
	'Brookwood Financial Partners LLC':
		'https://logo.clearbit.com/brookwoodfinancial.com',
	'Browning Investments': 'https://logo.clearbit.com/browningrep.com',
	'Buchanan Street Partners': 'https://logo.clearbit.com/buchananstreet.com',
	'Bulfinch/Harrison St': 'https://logo.clearbit.com/bulfinch.com',
	'Buzz Oates': 'https://logo.clearbit.com/buzzoates.com',
	'C-III Capital Partners': 'https://logo.clearbit.com/c3cp.com',
	'CA Ventures': 'https://logo.clearbit.com/ca-ventures.com',
	'Cabot Properties': 'https://logo.clearbit.com/cabotprop.com',
	'Cadogan Estates': 'https://logo.clearbit.com/co.uk',
	'Cadogan Estates Limited': 'https://logo.clearbit.com/co.uk',
	'CAF Capital Partners': 'https://logo.clearbit.com/cafcapital.com',
	'Calida Group': 'https://logo.clearbit.com/calidagroup.com',
	CalPERS: 'https://logo.clearbit.com/ca.gov',
	CALPERS: 'https://logo.clearbit.com/ca.gov',
	CalSTRS: 'https://logo.clearbit.com/calstrs.com',
	'Camden Property Trust': 'https://logo.clearbit.com/camdenliving.com',
	'Cantor Fitzgerald': 'https://logo.clearbit.com/cantor.com',
	'Capital Commercial Investments':
		'https://logo.clearbit.com/capitalcommercial.com',
	'Capital Square': 'https://logo.clearbit.com/capitalsquare.com',
	'CapRidge Partners': 'https://logo.clearbit.com/capridgepartners.com',
	'CareTrust REIT': 'https://logo.clearbit.com/caretrustreit.com',
	'Carmel Partners': 'https://logo.clearbit.com/carmelpartners.com',
	'Carter Multifamily': 'https://logo.clearbit.com/cartermultifamily.com',
	CBL: 'https://logo.clearbit.com/cblproperties.com',
	'CBL & Associates': 'https://logo.clearbit.com/cblproperties.com',
	'CBRE Global Investors': 'https://logo.clearbit.com/cbreim.com',
	'CBRE Global Investors Ltd': 'https://logo.clearbit.com/cbreim.com',
	'CBRE Investment Management': 'https://logo.clearbit.com/cbreim.com',
	'Cedar Realty Trust': 'https://logo.clearbit.com/cedarrealtytrust.com',
	'Cedar Realty Trust, Inc.': 'https://logo.clearbit.com/cedarrealtytrust.com',
	'CenterPoint Properties': 'https://logo.clearbit.com/centerpoint.com',
	'Cerberus Capital Management, Hines': 'https://logo.clearbit.com/hines.com',
	CheckerProp: 'https://logo.clearbit.com/capitalcommercialinvestments.com',
	'CHECKERPROP UTAH 30 NORTH 181 EAST LLC':
		'https://logo.clearbit.com/capitalcommercialinvestments.com',
	'Childress Klein': 'https://logo.clearbit.com/childressklein.com',
	'Church of Jesus Christ of Latter- day Saints':
		'https://logo.clearbit.com/churchofjesuschrist.org',
	'CIM Group': 'https://logo.clearbit.com/cimgroup.com',
	'Ciminelli Real Estate Corporation':
		'https://logo.clearbit.com/ciminelli.com',
	'CIP Real Estate': 'https://logo.clearbit.com/ciprealestate.com',
	'City of Boston': 'https://logo.clearbit.com/boston.gov',
	'City of London': 'https://logo.clearbit.com/gov.uk',
	'City Office REIT': 'https://logo.clearbit.com/cityofficereit.com',
	'Clarion Partners': 'https://logo.clearbit.com/clarionpartners.com',
	'Clear Capital': 'https://logo.clearbit.com/clearcapital.com',
	'Cohen Asset Management': 'https://logo.clearbit.com/cohenasset.com',
	'Cohen Brothers Realty': 'https://logo.clearbit.com/cohenbrothersrealty.com',
	'Cohen Brothers Realty Corporation':
		'https://logo.clearbit.com/cohenbrothersrealty.com',
	'Collett Capital': 'https://logo.clearbit.com/collettcapital.com',
	'Colony Hills Capital': 'https://logo.clearbit.com/colonyhillscapital.com',
	'Combined Properties': 'https://logo.clearbit.com/soe.io',
	'CommonWealth REIT': 'https://logo.clearbit.com/eqcre.com',
	'Community Healthcare Trust': 'https://logo.clearbit.com/chct.reit',
	'Connor Properties':
		'https://logo.clearbit.com/connorrentals2.managebuilding.com',
	'Conor Commercial': 'https://logo.clearbit.com/conor.com',
	'Conor Commercial Real Estate': 'https://logo.clearbit.com/conor.com',
	'Continental Development':
		'https://logo.clearbit.com/continentaldevelopment.com',
	'Continental Development Corp':
		'https://logo.clearbit.com/continentaldevelopment.com',
	'Continental Realty Corporation': 'https://logo.clearbit.com/crcrealty.com',
	'Cooper Street Capital': 'https://logo.clearbit.com/cooperstreetcapital.com',
	'Cooperative Development Institute Inc': 'https://logo.clearbit.com/cdi.coop',
	'Core Investment Management':
		'https://logo.clearbit.com/corerealtyholdings.com',
	'Core Realty Holdings Management':
		'https://logo.clearbit.com/corerealtyholdings.com',
	'Cornerstone Companies': 'https://logo.clearbit.com/cornerstoneondemand.com',
	'Corporate Office Properties Trust': 'https://logo.clearbit.com/copt.com',
	'Corporate Properties Trust I LP c/o Transwestern Investment Group':
		'https://logo.clearbit.com/transwestern.com',
	Cortland: 'https://logo.clearbit.com/cortland.com',
	'Cortland Partners': 'https://logo.clearbit.com/cortland.com',
	'Cottonwood Residential': 'https://logo.clearbit.com/cottonwoodres.com',
	CPPIB: 'https://logo.clearbit.com/cppinvestments.com',
	'Crescent Heights': 'https://logo.clearbit.com/crescentheights.com',
	'Crow Holdings': 'https://logo.clearbit.com/crowholdings.com',
	'Cummings Properties': 'https://logo.clearbit.com/cummings.com',
	'Cushman & Wakefield': 'https://logo.clearbit.com/cushmanwakefield.com',
	'CV PropCo LLC': 'https://logo.clearbit.com/cvproperties.com',
	'CV Properties': 'https://logo.clearbit.com/cvproperties.com',
	'D.R. Stephens & Company': 'https://logo.clearbit.com/drstephens.com',
	'Dalfen Industrial': 'https://logo.clearbit.com/dalfen.com',
	'DCT Industrial': 'https://logo.clearbit.com/prologis.com',
	'Dedeaux Properties': 'https://logo.clearbit.com/dedeauxproperties.com',
	DekaBank: 'https://logo.clearbit.com/dekabank.de',
	'Denholtz Associates': 'https://logo.clearbit.com/denholtz.com',
	'Denholtz Properties': 'https://logo.clearbit.com/denholtz.com',
	'Dermody Properties': 'https://logo.clearbit.com/dermody.com',
	'Derwent London': 'https://logo.clearbit.com/derwentlondon.com',
	'Digital Realty Trust': 'https://logo.clearbit.com/digitalrealty.com',
	'Digital Realty Trust, Inc.': 'https://logo.clearbit.com/digitalrealty.com',
	DivcoWest: 'https://logo.clearbit.com/divcowest.com',
	'Diversified Healthcare Trust': 'https://logo.clearbit.com/dhcreit.com',
	'DLC Management Corp.': 'https://logo.clearbit.com/dlcmgmt.com',
	'DLC Management Corporation': 'https://logo.clearbit.com/dlcmgmt.com',
	'DML Capital': 'https://logo.clearbit.com/dmlcapitalpartners.com',
	'Dogwood Industrial Properties':
		'https://logo.clearbit.com/dogwoodindustrial.com',
	'Dollinger Properties': 'https://logo.clearbit.com/dollingerproperties.com',
	Donohoe: 'https://logo.clearbit.com/donohoe.com',
	'Donohoe Real Estate Services': 'https://logo.clearbit.com/donohoe.com',
	'Douglas Development': 'https://logo.clearbit.com/douglasdevelopment.com',
	'Douglas Emmett': 'https://logo.clearbit.com/douglasemmett.com',
	'Douglas Emmett, Inc.': 'https://logo.clearbit.com/douglasemmett.com',
	DRA: 'https://logo.clearbit.com/draadvisors.com',
	'DRA Advisors': 'https://logo.clearbit.com/draadvisors.com',
	'DRG Distribution Realty Group':
		'https://logo.clearbit.com/distributionrealty.com',
	'DTN Managmenet': 'https://logo.clearbit.com/dtnmgt.com',
	'Duke Realty Corporation': 'https://logo.clearbit.com/prologis.com',
	'Dunhill Partners': 'https://logo.clearbit.com/dunhillpartners.com',
	'Dweck Properties': 'https://logo.clearbit.com/baronproperties.com',
	'Easterly Government Properties':
		'https://logo.clearbit.com/easterlyreit.com',
	'Easterly Government Properties, Inc.':
		'https://logo.clearbit.com/easterlyreit.com',
	'Eastgroup Properties': 'https://logo.clearbit.com/eastgroup.net',
	EDENS: 'https://logo.clearbit.com/edens.com',
	EdgeConneX: 'https://logo.clearbit.com/edgeconnex.com',
	'EdgeConneX Inc.': 'https://logo.clearbit.com/edgeconnex.com',
	'Elion Partners': 'https://logo.clearbit.com/elionpartners.com',
	'Elmtree Funds': 'https://logo.clearbit.com/elmtreefunds.com',
	'ElmTree Funds': 'https://logo.clearbit.com/elmtreefunds.com',
	'ELV Associates': 'https://logo.clearbit.com/elvassociates.com',
	'Empire State Realty Trust':
		'https://logo.clearbit.com/empirestaterealtytrust.com',
	'Endurance Real Estate': 'https://logo.clearbit.com/endurance-re.com',
	'Endurance Real Estate Group': 'https://logo.clearbit.com/endurance-re.com',
	'Entrada Partners': 'https://logo.clearbit.com/entradapartners.com',
	'EPR Properties': 'https://logo.clearbit.com/eprkc.com',
	'EQ Office': 'https://logo.clearbit.com/eqoffice.com',
	'EQT Exeter': 'https://logo.clearbit.com/eqtexeter.com',
	'Equity Commonwealth': 'https://logo.clearbit.com/eqcre.com',
	'Equity Group Investments':
		'https://www.egizell.com/wp-content/themes/egi/dist/images/White_Logo_Solo_small.png',
	'Equity Residential': 'https://logo.clearbit.com/equityapartments.com',
	'Equus Capital Partners': 'https://logo.clearbit.com/equuspartners.com',
	'ERG Enterprises, LLC': 'https://logo.clearbit.com/ergenterprises.net',
	'Essential Properties Realty Trust':
		'https://logo.clearbit.com/essentialproperties.com',
	'Essential Properties Realty Trust, Inc.':
		'https://logo.clearbit.com/essentialproperties.com',
	'Essex Property Trust': 'https://logo.clearbit.com/essex.com',
	'Ethan Conrad Properties': 'https://logo.clearbit.com/ethanconradprop.com',
	'Eurus Capital Partners':
		'https://logo.clearbit.com/euruscapitalpartners.com',
	'EverWest Real Estate Investors': 'https://logo.clearbit.com/everwest.com',
	'EverWest Real Estate Partners': 'https://logo.clearbit.com/everwest.com',
	ExchangeRight: 'https://logo.clearbit.com/exchangeright.com',
	'Exeter Property Group': 'https://logo.clearbit.com/eqtexeter.com',
	'Extra Space Storage': 'https://logo.clearbit.com/extraspace.com',
	'Fanuc Robotics': 'https://logo.clearbit.com/fanucamerica.com',
	'Farallon Capital': 'https://logo.clearbit.com/faralloncapital.com',
	'Farallon Capital Management':
		'https://logo.clearbit.com/faralloncapital.com',
	'Farley White Interests': 'https://logo.clearbit.com/farleywhite.com',
	Faropoint: 'https://logo.clearbit.com/faropoint.com',
	'Federal Business Centers':
		'https://logo.clearbit.com/federalbusinesscenters.com',
	'Federal Realty Investment Trust':
		'https://logo.clearbit.com/federalrealty.com',
	'Feil Organization': 'https://logo.clearbit.com/feil.com',
	'Fidelity Investments': 'https://logo.clearbit.com/fidelity.com',
	'First Industrial': 'https://logo.clearbit.com/firstindustrial.com',
	'First Industrial Realty Trust':
		'https://logo.clearbit.com/firstindustrial.com',
	'First National Realty Partners': 'https://logo.clearbit.com/fnrpusa.com',
	'First Washington Realty': 'https://logo.clearbit.com/firstwash.com',
	'Fisher Brothers': 'https://logo.clearbit.com/fisherbrothers.com',
	'Flynn Holdings': 'https://logo.clearbit.com/flynncompanies.com',
	'Flynn Properties': 'https://logo.clearbit.com/flynncompanies.com',
	'FM Ferrari': 'https://logo.clearbit.com/ferrariinvestments.org',
	'Focus Healthcare Partners':
		'https://static.wixstatic.com/media/cb4977_89d99cc2b03c4957a909efae1ce9a2aa~mv2.png',
	'Forsgate Industrial Partners':
		'https://logo.clearbit.com/forsgateindustrial.com',
	'Fort Capital': 'https://logo.clearbit.com/fortcapitallp.com',
	'Fortis Property Group': 'https://logo.clearbit.com/fortispropertygroup.com',
	'Fortress Investment Group': 'https://logo.clearbit.com/fortress.com',
	'Founders Properties': 'https://logo.clearbit.com/foundersproperties.com',
	'Four Corners Property Trust': 'https://logo.clearbit.com/fcpt.com',
	'Four Peaks Capital': 'https://logo.clearbit.com/fpmpartners.com',
	'Fourth Avenue Capital': 'https://logo.clearbit.com/fourthavecapital.com',
	'Foxrock Properties': 'https://logo.clearbit.com/foxrockproperties.com',
	'FoxRock Properties': 'https://logo.clearbit.com/foxrockproperties.com',
	'Franklin Street Properties': 'https://logo.clearbit.com/fspreit.com',
	'Frazer Capital': 'https://logo.clearbit.com/frazercapital.com',
	'Friedman Properties': 'https://logo.clearbit.com/friedmanproperties.com',
	'Frost National Bank': 'https://logo.clearbit.com/frostbank.com',
	'Fulcrum Asset Advisors': 'https://logo.clearbit.com/fulcrumasset.com',
	'Fulcrum Hospitality LLC': 'https://logo.clearbit.com/fulcrumhospitality.com',
	'Gaedeke Group': 'https://logo.clearbit.com/gaedeke.com',
	'Gaming and Leisure Properties': 'https://logo.clearbit.com/glpropinc.com',
	'GAMING AND LEISURE PROPERTIES, INC.':
		'https://logo.clearbit.com/glpropinc.com',
	'GAW Capital Partners': 'https://logo.clearbit.com/gawcapital.com',
	'GE Capital': 'https://logo.clearbit.com/ge.com',
	'GEM Realty Capital': 'https://logo.clearbit.com/gemrc.com',
	'Gemdale USA Corporation': 'https://logo.clearbit.com/gemdaleusa.com',
	'Gemini Rosemont': 'https://logo.clearbit.com/GeminiRosemont.com',
	Genentech: 'https://logo.clearbit.com/gene.com',
	'General Electric': 'https://logo.clearbit.com/ge.com',
	'George Comfort & Sons': 'https://logo.clearbit.com/georgecomfort.com',
	'Gershman Properties': 'https://logo.clearbit.com/gershmanproperties.com',
	'GFP Real Estate': 'https://logo.clearbit.com/gfpre.com',
	'GI Partners': 'https://logo.clearbit.com/gipartners.com',
	GIC: 'https://logo.clearbit.com/gic.com.sg',
	'GIC Real Estate Inc.': 'https://logo.clearbit.com/gic.com.sg',
	GID: 'https://logo.clearbit.com/gid.com',
	'Giri Hotels Management': 'https://logo.clearbit.com/girihotels.com',
	'GlenStar Properties': 'https://logo.clearbit.com/glenstar.com',
	'GlenStar Properties, Singerman Real Estate, LLC':
		'https://logo.clearbit.com/glenstar.com',
	'GLL Real Estate Partners': 'https://logo.clearbit.com/crunchbase.com',
	'GLL Real Estate Partners GmbH': 'https://logo.clearbit.com/crunchbase.com',
	GLP: 'https://logo.clearbit.com/glp.com',
	'GlP Capital Partners': 'https://logo.clearbit.com/glp.com',
	'GLP Capital Partners': 'https://logo.clearbit.com/glp.com',
	'Goldman Sachs Asset Management': 'https://logo.clearbit.com/gsam.com',
	'Goldrich & Kest': 'https://logo.clearbit.com/goldrichkest.com',
	'Goldrich Kest': 'https://logo.clearbit.com/goldrichkest.com',
	Google: 'https://logo.clearbit.com/google.com',
	'Google Inc.': 'https://logo.clearbit.com/google.com',
	'Google, Inc.': 'https://logo.clearbit.com/google.com',
	'Graham Street Realty': 'https://logo.clearbit.com/grahamstreetrealty.com',
	'Gramercy Property Trust': 'https://logo.clearbit.com/breit.com',
	'Grander Capital Partners':
		'https://logo.clearbit.com/grandercapitalpartners.com',
	'Granite Properties': 'https://logo.clearbit.com/graniteprop.com',
	'Granite REIT': 'https://logo.clearbit.com/granitereit.com',
	'Granite US Acquisitions LLC':
		'https://logo.clearbit.com/granitegroupllc.com',
	'Graymark Capital': 'https://logo.clearbit.com/graymarkcapital.com',
	'Greenlaw Partners': 'https://logo.clearbit.com/greenlawpartners.com',
	'Greenlaw Partners LLC': 'https://logo.clearbit.com/greenlawpartners.com',
	'Griffin Capital EAR II': 'https://logo.clearbit.com/griffincapital.com',
	Grosvenor: 'https://logo.clearbit.com/grosvenor.com',
	'Grover Corlew': 'https://logo.clearbit.com/grovercorlew.com',
	'Guggenheim Partners': 'https://logo.clearbit.com/guggenheimpartners.com',
	'Guggenheim Real Estate': 'https://logo.clearbit.com/guggenheimpartners.com',
	'H.G. Fenton Company': 'https://logo.clearbit.com/hgfenton.com',
	'Hackman Capital Partners': 'https://logo.clearbit.com/hackmancapital.com',
	'Hall Capital': 'https://logo.clearbit.com/hallcapital.com',
	'Hall Capital Partners': 'https://logo.clearbit.com/hallcapital.com',
	'Hamilton Partners': 'https://logo.clearbit.com/hamiltonpartners.com',
	'Hamilton Point Investments': 'https://logo.clearbit.com/hamiltonptinv.com',
	'Hammes Company': 'https://logo.clearbit.com/hammesco.com',
	'Hankey Investment Company': 'https://logo.clearbit.com/hiclp.com',
	'Harbert Management Corporation': 'https://logo.clearbit.com/harbert.net',
	'Harsch Investment Properties':
		'https://logo.clearbit.com/schnitzerproperties.com',
	'Hartz Mountain Industries': 'https://logo.clearbit.com/hartzmountain.com',
	'Hartz Mountain Industries, Inc.':
		'https://logo.clearbit.com/hartzmountain.com',
	'Haverford Properties, Inc.':
		'https://logo.clearbit.com/haverfordproperties.com',
	'Healthcare Realty Trust': 'https://logo.clearbit.com/healthcarerealty.com',
	'Healthpeak Properties': 'https://logo.clearbit.com/healthpeak.com',
	'Healthpeak Properties, Inc.': 'https://logo.clearbit.com/healthpeak.com',
	'Heartland Properties, Inc.':
		'https://logo.clearbit.com/heartlandproperties.com',
	Heitman: 'https://logo.clearbit.com/heitman.com',
	'Heitman Capital': 'https://logo.clearbit.com/heitman.com',
	'Heitman, LLC': 'https://logo.clearbit.com/heitman.com',
	'Heller Industrial Parks': 'https://logo.clearbit.com/hellerpark.com',
	'Heritage Capital Group':
		'https://logo.clearbit.com/heritagecapitalgroup.com',
	'Hermes Investment Management':
		'https://logo.clearbit.com/hermes-investment.com',
	'Hermes Real Estate Investment Management':
		'https://logo.clearbit.com/hermes-investment.com',
	'High Street Logistics Properties':
		'https://logo.clearbit.com/highstreetlp.com',
	'Highwoods Properties': 'https://logo.clearbit.com/highwoods.com',
	Hillwood: 'https://logo.clearbit.com/hillwoodinvestmentproperties.com',
	'Hillwood Investment Properties':
		'https://logo.clearbit.com/hillwoodinvestmentproperties.com',
	Hines: 'https://logo.clearbit.com/hines.com',
	'HN Capital Partners': 'https://logo.clearbit.com/hncapitalpartners.com',
	'Holt Lunsford Commercial': 'https://logo.clearbit.com/holtlunsford.com',
	'Hong Kong Monetary Authority': 'https://logo.clearbit.com/gov.hk',
	'Housing Authority of the City of Los Angeles':
		'https://logo.clearbit.com/hacla.org',
	'Howard de Walden Estates Limited': 'https://logo.clearbit.com/co.uk',
	'Howard Hughes Corporation': 'https://logo.clearbit.com/howardhughes.com',
	'HPI Real Estate Services': 'https://logo.clearbit.com/hpiinvestments.com',
	'HPI Real Estate Services & Investments':
		'https://logo.clearbit.com/hpiinvestments.com',
	'HQ Capital': 'https://logo.clearbit.com/hqcapital.com',
	'HQ Capital Real Estate': 'https://logo.clearbit.com/hqcapital.com',
	'Hudson Pacific Properties':
		'https://logo.clearbit.com/hudsonpacificproperties.com',
	'IDI Logistics': 'https://logo.clearbit.com/idilogistics.com',
	'IDS Real Estate Group': 'https://logo.clearbit.com/idsrealestate.com',
	'Independence Realty Trust': 'https://logo.clearbit.com/irtliving.com',
	'Industrial Logistics Properties Trust':
		'https://logo.clearbit.com/ilptreit.com',
	'Industrial Outdoor Ventures':
		'https://logo.clearbit.com/industrialoutdoor.com',
	'Industrial Property Trust': 'https://logo.clearbit.com/prologis.com',
	'Inland Real Estate Acquisitions, Inc.':
		'https://logo.clearbit.com/inlandgroup.com',
	'Innovative Industrial Properties':
		'https://logo.clearbit.com/innovativeindustrialproperties.com',
	'Innovatus Capital Partners': 'https://logo.clearbit.com/innovatuscp.com',
	'Innovo Property Group (IPG)': 'https://logo.clearbit.com/innovopg.com',
	'Intercontinental Real Estate Corporation':
		'https://logo.clearbit.com/intercontinental.net',
	Invesco: 'https://logo.clearbit.com/invesco.com',
	INVESCO: 'https://logo.clearbit.com/invesco.com',
	Investcorp: 'https://logo.clearbit.com/investcorp.com',
	'Invictus Real Estate Partners LLC':
		'https://logo.clearbit.com/invictus-rep.com',
	'IRA Capital': 'https://logo.clearbit.com/iracapital.com',
	'Iron Mountain': 'https://logo.clearbit.com/ironmountain.com',
	'Iron Point Partners': 'https://logo.clearbit.com/ironpointpartners.com',
	'Ivanho√© Cambridge': 'https://logo.clearbit.com/ivanhoecambridge.com',
	'Ivanhoe Cambridge': 'https://logo.clearbit.com/ivanhoecambridge.com',
	'J.C. BAR Properties, Inc.': 'https://logo.clearbit.com/jcbarprop.com',
	'Jackson Properties':
		'https://logo.clearbit.com/cortlandapartmentrentals.com',
	'Jackson Shaw': 'https://logo.clearbit.com/jacksonshaw.com',
	'Jair Lynch Development Partners': 'https://logo.clearbit.com/jairlynch.com',
	'Jair Lynch Real Estate Partners': 'https://logo.clearbit.com/jairlynch.com',
	'James Campbell Company': 'https://logo.clearbit.com/jamescampbell.com',
	Jamestown: 'https://logo.clearbit.com/jamestownlp.com',
	'Jay Paul Company': 'https://logo.clearbit.com/jaypaul.com',
	'JLL Income Property Trust, Inc': 'https://logo.clearbit.com/jllipt.com',
	'John Hancock Real Estate': 'https://logo.clearbit.com/johnhancock.com',
	'Jonathan Rose Companies': 'https://logo.clearbit.com/rosecompanies.com',
	'Jones Street Investment Partners':
		'https://logo.clearbit.com/jonesstreet.com',
	'JOSS Realty Partners': 'https://logo.clearbit.com/jossrealty.com',
	'JOSS Realty Partners LLC': 'https://logo.clearbit.com/jossrealty.com',
	'JP Morgan Asset Management': 'https://logo.clearbit.com/jpmorgan.com',
	'JV Koch Real Estate Development Investments':
		'https://logo.clearbit.com/kochind.com',
	'JVM Realty Corporation': 'https://logo.clearbit.com/jvmrealty.com',
	'KABR Group': 'https://logo.clearbit.com/kabrgroup.com',
	'Kairos Investment Management': 'https://logo.clearbit.com/kimc.com',
	'Kairos Investment Management Company': 'https://logo.clearbit.com/kimc.com',
	'Kalmon Dolgin Affiliates Inc.': 'https://logo.clearbit.com/kalmondolgin.com',
	'Karlin Real Estate': 'https://logo.clearbit.com/karlinre.com',
	'Kawa Capital Management': 'https://logo.clearbit.com/kawa.com',
	'Kayne Anderson Real Estate': 'https://logo.clearbit.com/kayneanderson.com',
	'KB Home': 'https://logo.clearbit.com/kbhome.com',
	KBS: 'https://logo.clearbit.com/kbscompanies.com',
	'KBS Companies': 'https://logo.clearbit.com/kbscompanies.com',
	'Kennedy Wilson': 'https://logo.clearbit.com/kennedywilson.com',
	'Kennedy Wilson, Inc.': 'https://logo.clearbit.com/kennedywilson.com',
	'Keppel Pacific Oak US REIT': 'https://logo.clearbit.com/koreusreit.com',
	'Keystone Property Group':
		'https://logo.clearbit.com/keystonepropertygroup.com',
	'Khosla Capital':
		'https://khoslacap.com/wp-content/uploads/2022/08/khoslaCapitalLogo.png',
	'Kiewit Corporation': 'https://logo.clearbit.com/kiewit.com',
	'Killam Development': 'https://logo.clearbit.com/killamdevelopment.com',
	'Kilroy Realty': 'https://logo.clearbit.com/kilroyrealty.com',
	'Kimco Realty': 'https://logo.clearbit.com/kimcorealty.com',
	'King Street Properties':
		'https://logo.clearbit.com/kingstreetproperties.com',
	'Kingsbarn Realty Capital': 'https://logo.clearbit.com/kingsbarn.com',
	'Kite Realty Group': 'https://logo.clearbit.com/kiterealty.com',
	'Kite Realty Group Trust': 'https://logo.clearbit.com/kiterealty.com',
	KKR: 'https://logo.clearbit.com/kkr.com',
	'Klein Enterprises': 'https://logo.clearbit.com/kleinenterprises.com',
	'KS Partners': 'https://logo.clearbit.com/kspartnersllc.com',
	'KS Partners LLC': 'https://logo.clearbit.com/kspartnersllc.com',
	'Kushner Companies': 'https://logo.clearbit.com/kushner.com',
	'L&B Realty Advisors': 'https://logo.clearbit.com/lbrealty.com',
	'L&L Holding Company': 'https://logo.clearbit.com/ll-holding.com',
	'Land Securities Group': 'https://logo.clearbit.com/landsec.com',
	'Lane Partners': 'https://logo.clearbit.com/lane-partners.com',
	'Langdon Park Capital': 'https://logo.clearbit.com/langdonparkcapital.com',
	'LaSalle Investment Management': 'https://logo.clearbit.com/lasalle.com',
	'LBA Realty': 'https://logo.clearbit.com/lbarealty.com',
	'Legacy Automotive Capital':
		'https://logo.clearbit.com/legacyautocapital.com',
	'Legacy Partners': 'https://logo.clearbit.com/legacypartners.com',
	'Leggat McCall Properties': 'https://logo.clearbit.com/lmp.com',
	'Liberty Mutual': 'https://logo.clearbit.com/libertymutual.com',
	'Liberty Property Trust': 'https://logo.clearbit.com/prologis.com',
	Lightstone: 'https://logo.clearbit.com/lightstonegroup.com',
	'Lincoln Equities Group': 'https://logo.clearbit.com/lincolnequities.com',
	'Lincoln Property Company': 'https://logo.clearbit.com/lincolnapts.com',
	'Lineage Logistics': 'https://logo.clearbit.com/lineagelogistics.com',
	'Link Industrial Properties': 'https://logo.clearbit.com/linklogistics.com',
	LinkedIn: 'https://logo.clearbit.com/linkedin.com',
	'Lionstone Investments': 'https://logo.clearbit.com/lionstoneinvestments.com',
	'Liquid Capital': 'https://logo.clearbit.com/liquidcapitalcorp.com',
	'London Borough of City of London': 'https://logo.clearbit.com/gov.uk',
	'Lone Star Funds': 'https://logo.clearbit.com/lonestarfunds.com',
	'Long & Gordon Real Estate':
		'https://gordonlong.com/wp-content/uploads/2017/08/gordon.png',
	'Longfellow Real Estate Partners': 'https://logo.clearbit.com/lfrep.com',
	'Longpoint Realty Partners': 'https://logo.clearbit.com/longpoint.com',
	'Lovett Industrial': 'https://logo.clearbit.com/lovettindustrial.com',
	'Lowe Enterprises': 'https://logo.clearbit.com/lowe-re.com',
	'Lund Company': 'https://logo.clearbit.com/lundco.com',
	'M&J Wilkow': 'https://logo.clearbit.com/wilkow.com',
	MAA: 'https://logo.clearbit.com/maac.com',
	'MAA (Mid-America Apartment Communities)':
		'https://logo.clearbit.com/maac.com',
	Macerich: 'https://logo.clearbit.com/macerich.com',
	'Mack Real Estate Group': 'https://logo.clearbit.com/mackregroup.com',
	'Mack-Cali': 'https://logo.clearbit.com/verisresidential.com',
	'Mack-Cali Realty': 'https://logo.clearbit.com/verisresidential.com',
	'Mack-Cali Realty Corporation':
		'https://logo.clearbit.com/verisresidential.com',
	'Macquarie Group': 'https://logo.clearbit.com/macquarie.com',
	'Madison Marquette': 'https://logo.clearbit.com/madisonmarquette.com',
	'Magma Equities': 'https://logo.clearbit.com/magmaequities.com',
	'Majestic Realty': 'https://logo.clearbit.com/majesticrealty.com',
	'Majestic Realty Co.': 'https://logo.clearbit.com/majesticrealty.com',
	'Mapletree Investments': 'https://logo.clearbit.com/mapletree.com.sg',
	'Mapletree US Mgmt': 'https://logo.clearbit.com/mapletree.com.sg',
	'Marc Realty': 'https://logo.clearbit.com/dedeauxproperties.com',
	'Marcus Partners': 'https://logo.clearbit.com/marcuspartners.com',
	'Matan Companies': 'https://logo.clearbit.com/mataninc.com',
	'Matan Realty, LLP': 'https://logo.clearbit.com/mataninc.com',
	'Matrix Development Group': 'https://logo.clearbit.com/matrixcompanies.com',
	'McCarthy Cook & Co.': 'https://logo.clearbit.com/mccarthycook.com',
	'McCraney Property Company': 'https://logo.clearbit.com/mccraneyproperty.com',
	McWhinney: 'https://logo.clearbit.com/mcwhinney.com',
	'MDH Partners': 'https://logo.clearbit.com/mdhpartners.com',
	'Medical Properties Trust':
		'https://logo.clearbit.com/medicalpropertiestrust.com',
	'Menlo Equities': 'https://logo.clearbit.com/menloequities.com',
	'Mericle Commercial Real Estate': 'https://logo.clearbit.com/mericle.com',
	'Mericle Commercial Real Estate Services':
		'https://logo.clearbit.com/mericle.com',
	'Merit Hill Capital': 'https://logo.clearbit.com/merithillcapital.com',
	Meritex: 'https://logo.clearbit.com/livemerit.com',
	'Merrill Gardens': 'https://logo.clearbit.com/merrillgardens.com',
	'Merritt Properties': 'https://logo.clearbit.com/merrittproperties.com',
	MetLife: 'https://logo.clearbit.com/metlife.com',
	'MetLife Real Estate': 'https://logo.clearbit.com/metlife.com',
	'Metropolis Investment Holdings':
		'https://logo.clearbit.com/metropolisinv.com',
	Microsoft: 'https://logo.clearbit.com/microsoft.com',
	'Midtown Equities': 'https://logo.clearbit.com/meqs.com',
	Midwood: 'https://logo.clearbit.com/midwoodid.com',
	'Mirae Asset Global Investment': 'https://logo.clearbit.com/miraeasset.com',
	'Mirae Asset Global Investments': 'https://logo.clearbit.com/miraeasset.com',
	'Mitsui Fudosan Co.': 'https://logo.clearbit.com/co.jp',
	'ML Realty Partners': 'https://logo.clearbit.com/mlrealtypartners.com',
	'MLG Capital': 'https://logo.clearbit.com/mlgcapital.com',
	'MLL Capital': 'https://logo.clearbit.com/mllcap.com',
	'Mohr Capital': 'https://logo.clearbit.com/mohrcap.com',
	'Moinian Group': 'https://logo.clearbit.com/moinian.com',
	'Monday Properties': 'https://logo.clearbit.com/mondayre.com',
	'Montecito Medical': 'https://logo.clearbit.com/montecitomac.com',
	'Montecito Medical Real Estate': 'https://logo.clearbit.com/montecitomac.com',
	'Monument Realty': 'https://logo.clearbit.com/monumentrealty.com',
	'Morgan Stanley':
		'https://media.licdn.com/dms/image/C560BAQFtDpMvYgbIwA/company-logo_200_200/0/1677778128461/morgan_stanley_logo?e=1715212800&v=beta&t=dnwXez7cGVQI9ErUS1J-qCOcXOAbzA9x_5vylVe_0TU',
	'Morningstar Investments': 'https://logo.clearbit.com/morningstar.com',
	'Morningstar Properties': 'https://logo.clearbit.com/morningstar.com',
	Mosaic: 'https://logo.clearbit.com/mymosaicrealty.com',
	'MRP Industrial': 'https://logo.clearbit.com/mrpindustrial.com',
	'MRP Realty': 'https://logo.clearbit.com/mrprealty.com',
	'National Development': 'https://logo.clearbit.com/natdev.com',
	'National Pension Service': 'https://logo.clearbit.com/or.kr',
	'National Pension Service of Korea': 'https://logo.clearbit.com/or.kr',
	'National Storage Affiliates':
		'https://logo.clearbit.com/nationalstorageaffiliates.com',
	'National Storage Affiliates Trust':
		'https://logo.clearbit.com/nationalstorageaffiliates.com',
	'New York Life': 'https://logo.clearbit.com/newyorklifeinvestments.com',
	NewcrestImage: 'https://logo.clearbit.com/newcrestimage.com',
	'Newmark Group, Inc.': 'https://logo.clearbit.com/nmrk.com',
	'Newmark Knight Frank': 'https://logo.clearbit.com/nmrk.com',
	'NFI Industries': 'https://logo.clearbit.com/nfiindustries.com',
	'NGP Management LLC': 'https://logo.clearbit.com/ngpmanagement.com',
	'Nicola Wealth': 'https://logo.clearbit.com/nicolawealth.com',
	'Nitya Capital': 'https://logo.clearbit.com/nityacapital.com',
	'Nome Capital Partners': 'https://logo.clearbit.com/nomecapital.com',
	'Nordblom Company': 'https://logo.clearbit.com/nordblom.com',
	NBIM: 'https://logo.clearbit.com/nbim.no',
	'Norges Bank': 'https://logo.clearbit.com/nbim.no',
	'Norges Bank Investment Management': 'https://logo.clearbit.com/nbim.no',
	'Northern Builders': 'https://logo.clearbit.com/gnbmn.com',
	'NorthPoint Development': 'https://logo.clearbit.com/beyondthecontract.com',
	'Northwood Investors': 'https://logo.clearbit.com/northwoodinvestors.com',
	Nuveen: 'https://logo.clearbit.com/nuveen.com',
	'Nuveen Real Estate': 'https://logo.clearbit.com/nuveen.com',
	"O'Connor Cap Prtnrs": 'https://logo.clearbit.com/oconnorcp.com',
	"O'Connor Capital Partners": 'https://logo.clearbit.com/oconnorcp.com',
	'OA Development': 'https://logo.clearbit.com/oadevelopment.com',
	'Oak Street Real Estate Capital': 'https://logo.clearbit.com/blueowl.com',
	'Oaktree Capital Management': 'https://logo.clearbit.com/oaktreecapital.com',
	'Oaktree Capital Management LLC':
		'https://logo.clearbit.com/oaktreecapital.com',
	'Ocean West': 'https://logo.clearbit.com/oceanwest.com',
	'Ocean West Capital Partners': 'https://logo.clearbit.com/oceanwest.com',
	'Octave Holdings and Investments':
		'https://logo.clearbit.com/octaveinvestments.com',
	'Office Properties Income Trust': 'https://logo.clearbit.com/opireit.com',
	'Ohana Real Estate Investors': 'https://logo.clearbit.com/ohanare.com',
	'Olen Properties': 'https://logo.clearbit.com/olenproperties.com',
	"Ole' Mexican Foods": 'https://logo.clearbit.com/olemex.com',
	'Oliver Street Capital': 'https://logo.clearbit.com/oliverst.com',
	'Olympus Property': 'https://logo.clearbit.com/olympusproperty.com',
	'Omega Healthcare Investors': 'https://logo.clearbit.com/omegahealthcare.com',
	'Omega Healthcare Investors, Inc.':
		'https://logo.clearbit.com/omegahealthcare.com',
	'Omni New York': 'https://logo.clearbit.com/omninewyork.com',
	'Omninet Capital': 'https://logo.clearbit.com/omninetcapital.com',
	'One Liberty Properties':
		'https://logo.clearbit.com/onelibertyproperties.com',
	'Onni Group': 'https://logo.clearbit.com/onni.com',
	'ONNI Group': 'https://logo.clearbit.com/onni.com',
	'Onward Investors': 'https://logo.clearbit.com/onwardinvestors.com',
	'Onyx Equities': 'https://logo.clearbit.com/onyxequities.com',
	'Onyx Equities, LLC': 'https://logo.clearbit.com/onyxequities.com',
	'Opal Holdings': 'https://logo.clearbit.com/opalholdings.com',
	'Orion Real Estate Group': 'https://logo.clearbit.com/orionmiami.com',
	'Orton Development': 'https://logo.clearbit.com/ortondevelopment.com',
	'Outrigger Resorts & Hotels': 'https://logo.clearbit.com/outrigger.com',
	'Oxford Properties': 'https://logo.clearbit.com/oxfordproperties.com',
	'Oxford Properties Group (JV) JP Morgan':
		'https://logo.clearbit.com/oxfordproperties.com',
	'Pacific Coast Capital Partner (PCCP)':
		'https://logo.clearbit.com/pccpllc.com',
	'Pacific Life': 'https://logo.clearbit.com/pacificlife.com',
	Pactrust: 'https://logo.clearbit.com/pactrust.com',
	PacTrust: 'https://logo.clearbit.com/pactrust.com',
	Panattoni: 'https://logo.clearbit.com/panattoni.com',
	'Paradigm Properties': 'https://logo.clearbit.com/paradigmprop.com',
	'Paramount Group': 'https://logo.clearbit.com/paramount-group.com',
	'Parkway Corporation': 'https://logo.clearbit.com/parkwaycorp.com',
	'Partners Group': 'https://logo.clearbit.com/partnersgroup.com',
	PCCP: 'https://logo.clearbit.com/pccpllc.com',
	'Peak Capital Partners': 'https://logo.clearbit.com/peakcapitalpartners.com',
	'Peninsula Land & Capital': 'https://logo.clearbit.com/peninsulaland.com',
	'Penwood Real Estate Investment Management':
		'https://logo.clearbit.com/penwoodre.com',
	Penzance: 'https://logo.clearbit.com/penzance.com',
	'PGIM Real Estate': 'https://logo.clearbit.com/pgim.com',
	'Phase 3 Real Estate Partners': 'https://logo.clearbit.com/p3re.com',
	'Phase 3 Real Estate Partners, Inc.': 'https://logo.clearbit.com/p3re.com',
	'Phoenix Investors': 'https://logo.clearbit.com/phoenixinvestors.com',
	'Piedmont Office Realty Trust': 'https://logo.clearbit.com/piedmontreit.com',
	'Plymouth Industrial REIT': 'https://logo.clearbit.com/plymouthreit.com',
	'PMI Management': 'https://logo.clearbit.com/propertymanagementinc.com',
	'Positive Investments': 'https://logo.clearbit.com/positiveinvestments.com',
	'Prado Group': 'https://logo.clearbit.com/pradogroup.com',
	'Praedium Group': 'https://logo.clearbit.com/praediumgroup.com',
	'Presidio Bay': 'https://logo.clearbit.com/presidiobay.com',
	'Presidio Bay Ventures': 'https://logo.clearbit.com/presidiobay.com',
	'Preylock Holdings': 'https://logo.clearbit.com/preylock.com',
	'Priderock Capital Management':
		'https://logo.clearbit.com/priderockcapitalpartnersllc.com',
	'Prime Healthcare': 'https://logo.clearbit.com/primehealthcare.com',
	'Principal Real Estate Investors':
		'https://logo.clearbit.com/principalglobal.com',
	Prologis: 'https://logo.clearbit.com/prologis.com',
	ProLogis: 'https://logo.clearbit.com/prologis.com',
	'Property Management, Inc.':
		'https://logo.clearbit.com/propertymanagementinc.com',
	'Prospect Ridge Advisors': 'https://logo.clearbit.com/prospectridge.com',
	'PRP Real Estate Investment Management':
		'https://logo.clearbit.com/prprei.com',
	'Prudential Financial': 'https://logo.clearbit.com/prudential.com',
	'PS Business Parks': 'https://logo.clearbit.com/psbusinessparks.com',
	'Public Storage': 'https://logo.clearbit.com/publicstorage.com',
	Publix: 'https://logo.clearbit.com/publix.com',
	'Quadrangle Development':
		'https://logo.clearbit.com/quadrangledevelopment.com',
	'Ralph Dweck Properties': 'https://logo.clearbit.com/baronproperties.com',
	Rappaport: 'https://logo.clearbit.com/rappaportco.com',
	'Real Capital Solutions, Inc.':
		'https://logo.clearbit.com/realcapitalsolutions.com',
	'Realife Real Estate Group': 'https://logo.clearbit.com/realife-group.com',
	Realterm: 'https://logo.clearbit.com/realterm.com',
	'Realty Income Corporation': 'https://logo.clearbit.com/realtyincome.com',
	'Realty Income Corporation 301 Apache Trail':
		'https://logo.clearbit.com/realtyincome.com',
	'Red Mountain Group': 'https://logo.clearbit.com/rmrginc.com',
	'Red Tail Acquisitions': 'https://logo.clearbit.com/rtacq.com',
	'Redfearn Capital': 'https://logo.clearbit.com/redfearncapital.com',
	'Regency Centers': 'https://logo.clearbit.com/regencycenters.com',
	'Regent Properties': 'https://logo.clearbit.com/regentproperties.com',
	'Regents of the University of California':
		'https://logo.clearbit.com/universityofcalifornia.edu',
	Regus: 'https://logo.clearbit.com/regus.com',
	'Related Companies': 'https://logo.clearbit.com/related.com',
	'Remic Properties':
		'https://remicprop.com/wp-content/uploads/2018/01/logo.png',
	'Rexford Industrial': 'https://logo.clearbit.com/rexfordindustrial.com',
	'Rexford Industrial Realty, Inc.':
		'https://logo.clearbit.com/rexfordindustrial.com',
	RFR: 'https://logo.clearbit.com/rfr.com',
	'RFR Realty': 'https://logo.clearbit.com/rfr.com',
	'Rise48 Equity': 'https://logo.clearbit.com/rise48equity.com',
	'Rising Realty Partners': 'https://logo.clearbit.com/risingrp.com',
	'Ritz Banc Group': 'https://logo.clearbit.com/ritzbanc.com',
	'RJ Kelly Company': 'https://logo.clearbit.com/rjk.com',
	'RK Centers': 'https://logo.clearbit.com/rkcenters.com',
	'Rockefeller Group': 'https://logo.clearbit.com/rockefellergroup.com',
	'Rockwood Capital': 'https://logo.clearbit.com/rockwoodcap.com',
	Rosenthal: 'https://logo.clearbit.com/rosenthalproperties.com',
	'Rosenthal Properties': 'https://logo.clearbit.com/rosenthalproperties.com',
	'Rouse (Brookfield)': 'https://logo.clearbit.com/rouseproperties.com',
	'RREAF Holdings': 'https://logo.clearbit.com/rreaf.com',
	RREEF: 'https://logo.clearbit.com/dws.com',
	'RREEF Property Trust (DWS)': 'https://logo.clearbit.com/dws.com',
	'Rubenstein Partners': 'https://logo.clearbit.com/rubensteinpartners.com',
	'Rubicon Point Partners':
		'https://logo.clearbit.com/rubiconpointpartners.com',
	'RXR Realty':
		'https://rxr.com/assets/general/corporate/lab/19905047_1940521422826604_6611460677765138053_n.jpg',
	'Ryan Companies': 'https://logo.clearbit.com/ryancompanies.com',
	'S2 Capital': 'https://logo.clearbit.com/s2cp.com',
	'Sabra Health Care REIT': 'https://logo.clearbit.com/sabrahealth.com',
	'Safari Timberline Hotels, LLC':
		'https://logo.clearbit.com/safarihospitality.com',
	'SAFE HARBOR MARINA': 'https://logo.clearbit.com/shmarinas.com',
	'Safe Harbor Marinas': 'https://logo.clearbit.com/shmarinas.com',
	'Samuels & Associates': 'https://logo.clearbit.com/samuelsre.com',
	'Sand Hill Property Company': 'https://logo.clearbit.com/shpco.com',
	'Sares Regis Group': 'https://logo.clearbit.com/sares-regis.com',
	'Sares-Regis Group': 'https://logo.clearbit.com/sares-regis.com',
	'Sarofim Realty Advisors': 'https://logo.clearbit.com/sarofimrealty.com',
	'ScanlanKemperBard (SKB)': 'https://logo.clearbit.com/skbcos.com',
	'Scannell Properties': 'https://logo.clearbit.com/scannellproperties.com',
	'Schnitzer Properties': 'https://logo.clearbit.com/schnitzerproperties.com',
	'Seagate Properties': 'https://logo.clearbit.com/seagateprop.com',
	'Seagis Property Group': 'https://logo.clearbit.com/seagisproperty.com',
	'Sealy & Company': 'https://logo.clearbit.com/sealynet.com',
	'Security Properties': 'https://logo.clearbit.com/securityproperties.com',
	'SECURITY PROPERTIES': 'https://logo.clearbit.com/securityproperties.com',
	'Seefried Industrial Properties':
		'https://logo.clearbit.com/seefriedproperties.com',
	'Senior Property Housing Trust':
		'https://logo.clearbit.com/seniorhousingnews.com',
	'Seritage Growth Properties': 'https://logo.clearbit.com/seritage.com',
	'Shea Properties': 'https://logo.clearbit.com/sheaproperties.com',
	'Shopcore Properties': 'https://logo.clearbit.com/shopcore.com',
	'ShopCore Properties': 'https://logo.clearbit.com/shopcore.com',
	'Shorenstein Properties': 'https://logo.clearbit.com/shorenstein.com',
	'Sila Realty Trust': 'https://logo.clearbit.com/silarealtytrust.com',
	'Sila Realty Trust, Inc.': 'https://logo.clearbit.com/silarealtytrust.com',
	'Silverstein Properties':
		'https://logo.clearbit.com/silversteinproperties.com',
	'Silverstein Properties Arden Group and Migdal Insurance':
		'https://logo.clearbit.com/silversteinproperties.com',
	'Simon Property Group': 'https://logo.clearbit.com/simon.com',
	'SITE Centers': 'https://logo.clearbit.com/sitecenters.com',
	'SJP Properties': 'https://logo.clearbit.com/sjpproperties.com',
	Skanska: 'https://logo.clearbit.com/skanska.com',
	'Skanska AB': 'https://logo.clearbit.com/skanska.com',
	SKB: 'https://logo.clearbit.com/skbcos.com',
	'SKS Investments': 'https://logo.clearbit.com/skanska.com',
	'SKS Partners': 'https://logo.clearbit.com/skanska.com',
	'SL Green Realty Corp.': 'https://logo.clearbit.com/slgreen.com',
	'Slate Asset Management': 'https://logo.clearbit.com/slateam.com',
	'Sobrato Development Company': 'https://logo.clearbit.com/Sobrato.com',
	'Solomon Organization, The': 'https://logo.clearbit.com/solomonorg.com',
	'Somerset Properties': 'https://logo.clearbit.com/somprop.com',
	'Songbird Estates plc': 'https://logo.clearbit.com/co.uk',
	'Songbird Estates PLC': 'https://logo.clearbit.com/co.uk',
	'South Bay Development Company': 'https://logo.clearbit.com/sbwib.org',
	'Southwood Realty': 'https://logo.clearbit.com/southwoodrealty.com',
	'SOUTHWOOD REALTY': 'https://logo.clearbit.com/southwoodrealty.com',
	'Spear Street Capital': 'https://logo.clearbit.com/spearstreet.com',
	'Spear Street Capital, Sidra Capital':
		'https://logo.clearbit.com/spearstreetcapital.com',
	'Sperry Equities': 'https://logo.clearbit.com/sperryequities.com',
	'Spieker Investments': 'https://logo.clearbit.com/spiekerinv.com',
	'Spirit Realty': 'https://logo.clearbit.com/spiritrealty.com',
	'Square Mile Capital': 'https://logo.clearbit.com/squaremilecapital.com',
	"St. Luke's Health System, Ltd.":
		'https://logo.clearbit.com/stlukesonline.org',
	'STAG Industrial': 'https://logo.clearbit.com/stagindustrial.com',
	'Starlight Investments': 'https://logo.clearbit.com/starlighthomes.com',
	'Starlight Realty': 'https://logo.clearbit.com/starlighthomes.com',
	'Starwood Capital Group': 'https://logo.clearbit.com/starwoodcapital.com',
	'State Farm': 'https://logo.clearbit.com/statefarm.com',
	'State Teachers Retirement System of Ohio':
		'https://logo.clearbit.com/strsoh.org',
	SteelWave: 'https://logo.clearbit.com/steelwavellc.com',
	'Sterling Bay': 'https://logo.clearbit.com/sterlingbay.com',
	'Stillwater Capital': 'https://logo.clearbit.com/stillwatercap.com',
	Stockbridge: 'https://logo.clearbit.com/stockbridge.com',
	'Stockbridge Capital Group': 'https://logo.clearbit.com/stockbridge.com',
	'Stockdale Capital Partners':
		'https://logo.clearbit.com/stockdalecapital.com',
	'Stonelake Capital Partners': 'https://logo.clearbit.com/stonelake.com',
	'Stonemont Financial': 'https://logo.clearbit.com/stonemontfinancial.com',
	'Stonemont Financial Group':
		'https://logo.clearbit.com/stonemontfinancial.com',
	'STORE Capital': 'https://logo.clearbit.com/storecapital.com',
	'Strategic Capital Partners':
		'https://logo.clearbit.com/strategiccapitalpartners.com',
	'Strategic Office Partners': 'https://logo.clearbit.com/strategicoffice.com',
	'Stream Realty Partners': 'https://logo.clearbit.com/streamrealty.com',
	'Sudler Companies': 'https://logo.clearbit.com/sudlerco.com',
	'Summerhill Homes': 'https://logo.clearbit.com/summerhillhomes.com',
	'Summit Associates': 'https://logo.clearbit.com/sairealestate.com',
	SunCap: 'https://logo.clearbit.com/suncappg.com',
	'Suncap Property Group': 'https://logo.clearbit.com/suncappg.com',
	'Sunroad Enterprises': 'https://logo.clearbit.com/sunroadenterprises.com',
	'Swift Real Estate Partners': 'https://logo.clearbit.com/swiftrp.com',
	'TA Associates Realty': 'https://logo.clearbit.com/tarealty.com',
	'TA Realty': 'https://logo.clearbit.com/tarealty.com',
	'Taconic Investment Partners':
		'https://logo.clearbit.com/taconicinvestment.com',
	'Talon Private Capital': 'https://logo.clearbit.com/talonprivate.com',
	Target: 'https://logo.clearbit.com/target.com',
	'Target Corporation': 'https://logo.clearbit.com/target.com',
	'TerraCap Management': 'https://logo.clearbit.com/terracapmanagement.com',
	'Terreno Realty Corporation': 'https://logo.clearbit.com/terreno.com',
	'The Allstate Corporation': 'https://logo.clearbit.com/allstate.com',
	'The Bedford Estates': 'https://logo.clearbit.com/bedfordestates.com',
	'The Berger Company, Inc.': 'https://logo.clearbit.com/thebergerco.com',
	'The Blackstone Group': 'https://logo.clearbit.com/breit.com',
	'The Brodsky Organization': 'https://logo.clearbit.com/brodsky.com',
	'The Bulfinch Companies': 'https://logo.clearbit.com/bulfinch.com',
	'The Bulfinch Companies, Inc.': 'https://logo.clearbit.com/bulfinch.com',
	'The Calida Group': 'https://logo.clearbit.com/calidagroup.com',
	'The Carlyle Group': 'https://logo.clearbit.com/carlyle.com',
	'The Crown Estate': 'https://logo.clearbit.com/co.uk',
	'The Dakota REIT': 'https://logo.clearbit.com/dakotareit.com',
	'The Davis Companies': 'https://logo.clearbit.com/thedaviscompanies.com',
	'The Dilweg Companies': 'https://logo.clearbit.com/dilweg.com',
	'The Durst Organization': 'https://logo.clearbit.com/durst.org',
	'The Easton Group': 'https://logo.clearbit.com/eastongrp.com',
	'The Feil Organization': 'https://logo.clearbit.com/feil.com',
	'The Gutierrez Company': 'https://logo.clearbit.com/gutierrezco.com',
	'The Howard de Walden Estate': 'https://logo.clearbit.com/co.uk',
	'The Howard Hughes Corporation': 'https://logo.clearbit.com/howardhughes.com',
	'The Irvine Company': 'https://logo.clearbit.com/irvinecompany.com',
	'The Keith Corporation': 'https://logo.clearbit.com/thekeithcorp.com',
	'The Koll Company': 'https://logo.clearbit.com/koll.com',
	'The Lightstone Group': 'https://logo.clearbit.com/lightstonegroup.com',
	'The Muller Company': 'https://logo.clearbit.com/themullercompany.com',
	'The Portman Estate': 'https://logo.clearbit.com/co.uk',
	'The Praedium Group': 'https://logo.clearbit.com/praediumgroup.com',
	'The Pruitt Corporation': 'https://logo.clearbit.com/pruitthealth.com',
	'The Ratkovich Company': 'https://logo.clearbit.com/ratkovich.com',
	'The RMR Group': 'https://logo.clearbit.com/rmrgroup.com',
	'The Rockefeller Group': 'https://logo.clearbit.com/rockefellergroup.com',
	'The Rosen Group': 'https://logo.clearbit.com/rosen-group.com',
	'The Simpson Organization': 'https://logo.clearbit.com/tso-europe.de',
	'The Sobrato Organization': 'https://logo.clearbit.com/Sobrato.com',
	'The Sudler Companies': 'https://logo.clearbit.com/sudlerco.com',
	'The Travelers Companies': 'https://logo.clearbit.com/travelers.com',
	'The Treeline Companies': 'https://logo.clearbit.com/treelinecompanies.com',
	'The Trump Organization': 'https://logo.clearbit.com/trump.com',
	'Tides Equities': 'https://logo.clearbit.com/tidesequities.com',
	'Time Equities, Inc.': 'https://logo.clearbit.com/timeequities.com',
	'Tishman Speyer': 'https://logo.clearbit.com/tishmanspeyer.com',
	'Tishman Speyer BlackRock': 'https://logo.clearbit.com/tishmanspeyer.com',
	'Tishman Speyer, Blackrock Realty':
		'https://logo.clearbit.com/tishmanspeyer.com',
	'Toll Brothers': 'https://logo.clearbit.com/tollbrothers.com',
	'Tower Investments': 'https://logo.clearbit.com/towerdev.com',
	'Tower Realty': 'https://logo.clearbit.com/towerdev.com',
	TPG: 'https://logo.clearbit.com/tpg.com',
	'TPG Capital': 'https://logo.clearbit.com/tpg.com',
	'Trammell Crow': 'https://logo.clearbit.com/trammellcrow.com',
	'Trammell Crow Company': 'https://logo.clearbit.com/trammellcrow.com',
	Transwestern: 'https://logo.clearbit.com/transwestern.com',
	'Transwestern Investment Group':
		'https://transwesterninvest.com/img/Transwestern-TIG-Logo-RGB.svg',
	'Transwestern, Mirae Asset': 'https://logo.clearbit.com/transwestern.com',
	Travelers: 'https://logo.clearbit.com/travelers.com',
	'Treeline Companies': 'https://logo.clearbit.com/treelinecompanies.com',
	'TriGate Capital': 'https://logo.clearbit.com/trigatecapital.com',
	'Trinity Capital Advisors':
		'https://logo.clearbit.com/trinitycapitaladvisors.com',
	'Trinity Church Wall Street': 'https://logo.clearbit.com/trinityonline.com',
	'Trinity Property Consultants': 'https://logo.clearbit.com/trinity-pm.com',
	'Trinity Real Estate': 'https://logo.clearbit.com/trinityonline.com',
	'Trion Properties': 'https://logo.clearbit.com/trionproperties.com',
	'Triple Crown Corporation': 'https://logo.clearbit.com/triplecrowncorp.com',
	'Triten Real Estate Partners': 'https://logo.clearbit.com/tritenre.com',
	'TruAmerica Multifamily': 'https://logo.clearbit.com/truamerica.com',
	'Trump Organization': 'https://logo.clearbit.com/trump.com',
	'TS Office Owner 1 - 5 LLC (Starwood Property Trust)':
		'https://logo.clearbit.com/starwoodpropertytrust.com',
	Turnbridge: 'https://logo.clearbit.com/turnbridgeeq.com',
	'Turnbridge Equities': 'https://logo.clearbit.com/turnbridgeeq.com',
	'U.S. Bank': 'https://logo.clearbit.com/usbank.com',
	'Unibail Rodamco Christophe Cuvillier': 'https://logo.clearbit.com/urw.com',
	'Unibail Rodamco - Christophe Cuvillier': 'https://logo.clearbit.com/urw.com',
	'Unico Properties': 'https://logo.clearbit.com/unicoprop.com',
	'United States General Services Administration':
		'https://logo.clearbit.com/gsa.gov',
	'Urban Edge': 'https://logo.clearbit.com/uedge.com',
	'Urban Edge Properties': 'https://logo.clearbit.com/uedge.com',
	'Urban Renaissance Group': 'https://logo.clearbit.com/urbanrengroup.com',
	'USAA Real Estate': 'https://logo.clearbit.com/usrealco.com',
	'USAA Real Estate Company': 'https://logo.clearbit.com/usrealco.com',
	'Utah Retirement System': 'https://logo.clearbit.com/urs.org',
	'Utah Retirement Systems': 'https://logo.clearbit.com/urs.org',
	'Van Vlissingen & Company': 'https://logo.clearbit.com/vvco.com',
	'Van Vlissingen and Co.': 'https://logo.clearbit.com/vvco.com',
	'Vanbarton Group': 'https://logo.clearbit.com/vanbartongroup.com',
	'VanTrust Real Estate': 'https://logo.clearbit.com/vantrustrealestate.com',
	'Venture One Real Estate':
		'https://logo.clearbit.com/ventureonerealestate.com',
	VEREIT: 'https://logo.clearbit.com/vereit.com',
	Veritas: 'https://logo.clearbit.com/veritasinvestments.com',
	'Veritas Investments': 'https://logo.clearbit.com/veritasinvestments.com',
	Verizon: 'https://logo.clearbit.com/verizon.com',
	'Verizon Wireless': 'https://logo.clearbit.com/verizon.com',
	Vestar: 'https://logo.clearbit.com/vestar.com',
	'VICI Properties Inc': 'https://logo.clearbit.com/viciproperties.com',
	'VICI Properties Inc.': 'https://logo.clearbit.com/viciproperties.com',
	Vintage: 'https://logo.clearbit.com/vintagerealestateservices.com',
	'Vintage Properties':
		'https://logo.clearbit.com/vintagerealestateservices.com',
	'Vision Real Estate Partners': 'https://logo.clearbit.com/visionrep.com',
	'Vornado Realty Trust':
		'https://media.licdn.com/dms/image/C4E0BAQEZl95UdsTeaw/company-logo_200_200/0/1630632095610/vornado_realty_trust_logo?e=1715212800&v=beta&t=8yCPW-DXiBcctsUnpjh2zHWMgjtVtZbSA9zUOdZVqp8',
	Walgreens: 'https://logo.clearbit.com/walgreens.com',
	'Walter & Samuels': 'https://logo.clearbit.com/walter-samuels.com',
	'Walter & Samuels, Inc.': 'https://logo.clearbit.com/walter-samuels.com',
	'Walton Development & Management': 'https://logo.clearbit.com/walton.com',
	'Walton Street Capital': 'https://logo.clearbit.com/waltonst.com',
	'Wareham Development': 'https://logo.clearbit.com/warehamdevelopment.com',
	'Washington Capital Management':
		'https://logo.clearbit.com/washingtoncapital.com',
	'Washington Real Estate Investment Trust':
		'https://logo.clearbit.com/elmecommunities.com',
	Waterton: 'https://logo.clearbit.com/waterton.com',
	'Watson Land Company': 'https://logo.clearbit.com/watsonlandcompany.com',
	'Watt Companies': 'https://logo.clearbit.com/wattcompanies.com',
	'Weidner Property Management': 'https://logo.clearbit.com/weidner.com',
	'Wells Fargo': 'https://logo.clearbit.com/wellsfargo.com',
	'Wells Fargo Bank': 'https://logo.clearbit.com/wellsfargo.com',
	Welltower: 'https://logo.clearbit.com/welltower.com',
	'Wentworth Property Company':
		'https://logo.clearbit.com/wentworthpropertyco.com',
	'WePartner Group': 'https://logo.clearbit.com/wepartnergroup.com',
	'Westbrook Partners': 'https://logo.clearbit.com/westbrookpartners.com',
	'Westcore Properties': 'https://logo.clearbit.com/westcore.net',
	'Western Wealth Capital':
		'https://logo.clearbit.com/westernwealthcapital.com',
	Westfield: 'https://logo.clearbit.com/urw.com',
	'Westmount Realty Capital': 'https://logo.clearbit.com/WestmountRC.com',
	'Westmount Realty Capital, LLC': 'https://logo.clearbit.com/WestmountRC.com',
	'Westport Capital Partners': 'https://logo.clearbit.com/westportcp.com',
	'Westport Capital Partners LLC': 'https://logo.clearbit.com/westportcp.com',
	'Westward Properties Llc': 'https://logo.clearbit.com/westwardprop.com',
	'Wheelock Street Capital': 'https://logo.clearbit.com/wheelockst.com',
	'White Eagle Property Group':
		'https://www.whiteeaglegroup.com/wp-content/themes/whiteeagle/images/logo-header.jpg',
	'White Oak Partners': 'https://logo.clearbit.com/whiteoakpartners.com',
	'White Oak Realty Partners': 'https://logo.clearbit.com/whiteoakpartners.com',
	'Whitestone REIT': 'https://logo.clearbit.com/whitestonereit.com',
	'Windy Hill Property Ventures': 'https://logo.clearbit.com/windyhillpv.com',
	'Winstanley Enterprises': 'https://logo.clearbit.com/winstanley.com',
	'Woodside Capital': 'https://logo.clearbit.com/woodsidecp.com',
	'Woodside Capital Partners': 'https://logo.clearbit.com/woodsidecp.com',
	'Workspace Property Trust': 'https://logo.clearbit.com/workspaceproperty.com',
	'WS Development': 'https://logo.clearbit.com/wsdevelopment.com',
	Xebec: 'https://logo.clearbit.com/xebecrealty.com',
	'Yellowstone Properties Llc':
		'https://www.yellowstonepropllc.com/uploads/1/1/0/5/110503491/yellowstone-properties-logos-wide.png',
	'Zar Property NY': 'https://logo.clearbit.com/zarpropertyny.com',
	'Zar Property NY, LLC': 'https://logo.clearbit.com/zarpropertyny.com',
	'Zeller Realty Group': 'https://logo.clearbit.com/zellerrealty.com',
};

// This function was used to extract company name <-> clearbitLogoURL mapping from JSONArray data (can be gathered via "excel to JSON"-like tools)
// Leaving it here just in case if DS team will collect more companies data eventually
// const extractCompaniesLogos = (
// 	data: {
// 		name: string;
// 		display_name: string;
// 		website?: string;
// 	}[]
// ) => {
// 	{
// 		const result = data.reduce<Record<string, string | undefined>>(
// 			(acc, item) => {
// 				const name = item.name;
// 				const displayName = item.display_name;
// 				const website = item.website;

// 				const domain = !!website && /http(?:s?):\/\/.*?([^\.\/]+?\.[^\.]+?)(?:\/|$)/.exec(
// 					website
// 				)?.[1];

// 				if (!domain) {
// 					acc[name] = undefined;
// 					if (!!displayName && name !== displayName) {
// 						acc[displayName] = undefined;
// 					}
// 					return acc;
// 				}

// 				const clearbitLogo = `https://logo.clearbit.com/${domain}`;
// 				acc[name] = clearbitLogo;
// 				if (!!displayName && name !== displayName) {
// 					acc[displayName] = clearbitLogo;
// 				}

// 				return acc;
// 			},
// 			{}
// 		);

// 		return result;
// 	}
// };
