import { PanelTitle, Row } from 'ExchangeDashboard/UI';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { LeaseComp, SalesComp } from 'types';
import Logo from '../../ui/svg_icons/cs_full_logo.svg';
import { AveragesPanel } from './PreviewComponents/AveragesPanel';
import LargestLandlordsChart from './PreviewComponents/LargestLandlords';
import LargestTenantsChart from './PreviewComponents/LargestTenants';
import { LeaseExpirationPropertyType } from './PreviewComponents/LeaseExpirationPropertyType';
import { LeaseExpirationSubmarket } from './PreviewComponents/LeaseExpirationSubmarket';
import { LeasesTable } from './PreviewComponents/LeasesTable';
import { MarketRentMap } from './PreviewComponents/MarketRentMap';
import { SalesTable } from './PreviewComponents/SalesTable';
import { StartingRent } from './PreviewComponents/StartingRent';
import { TenantIndustryDistribution } from './PreviewComponents/TenantIndustryDistribution';
import { TenantIndustryMap } from './PreviewComponents/TenantIndustryMap';

export const componentMap = {
	averages: AveragesPanel,
	marketRentHeatMap: MarketRentMap,
	startingRent: StartingRent,
	leaseExpirationPropertyType: LeaseExpirationPropertyType,
	leaseExpirationSubmarket: LeaseExpirationSubmarket,
	tenantIndustryMap: TenantIndustryMap,
	tenantIndustryDistribution: TenantIndustryDistribution,
	largestLandlords: LargestLandlordsChart,
	largestTenants: LargestTenantsChart,
	recentlySignedLeases: ({
		data,
	}: {
		data: { recentlySignedLeases: LeaseComp[] };
	}) => (
		<PDFPanel title="Recently Signed Leases">
			<LeasesTable leaseComps={data.recentlySignedLeases} />
		</PDFPanel>
	),
	largestRecentLeases: ({
		data,
	}: {
		data: { largestRecentLeases: LeaseComp[] };
	}) => (
		<PDFPanel title="Largest Recent Leases">
			<LeasesTable leaseComps={data.largestRecentLeases} />
		</PDFPanel>
	),
	recentTransactions: ({
		data,
	}: {
		data: { recentTransactions: SalesComp[] };
	}) => (
		<PDFPanel title="Recent Sales Transactions">
			<SalesTable salesComps={data.recentTransactions} />
		</PDFPanel>
	),
	largeSales: ({ data }: { data: { largeSales: SalesComp[] } }) => (
		<PDFPanel title="Largest Recent Sales">
			<SalesTable salesComps={data.largeSales} />
		</PDFPanel>
	),
} as const;

export type ComponentPreviewType = keyof typeof componentMap;
export type ComponentInfo = {
	component: ComponentPreviewType;
	displayName: string;
};

export const componentsByType = {
	leaseInsights: [
		{ component: 'averages', displayName: 'Averages' },
		{ component: 'marketRentHeatMap', displayName: 'Market Rent Heat Map' },
		{ component: 'startingRent', displayName: 'Starting Rent' },
		{
			component: 'leaseExpirationPropertyType',
			displayName: 'Lease Expiration by Property Type',
		},
		{
			component: 'leaseExpirationSubmarket',
			displayName: 'Lease Expiration by Submarket',
		},
		{ component: 'tenantIndustryMap', displayName: 'Tenant Industry Map View' },
		{
			component: 'tenantIndustryDistribution',
			displayName: 'Tenant Industry Distribution',
		},
		{ component: 'largestLandlords', displayName: 'Largest Landlords' },
		{ component: 'largestTenants', displayName: 'Largest Tenants' },
	],
	leaseActivity: [
		{
			component: 'recentlySignedLeases',
			displayName: 'Recently Signed Leases',
		},
		{ component: 'largestRecentLeases', displayName: 'Largest Recent Leases' },
	],
	salesActivity: [
		{
			component: 'recentTransactions',
			displayName: 'Recent Sales Transactions',
		},
		{ component: 'largeSales', displayName: 'Largest Recent Sales' },
	],
} as const;

export const componentTypes = Object.values(componentsByType).flatMap((c) =>
	c.map((a) => a.component)
);

export const SectionContainer = styled.div`
	background-color: #fff;
	border-radius: 3px;
	border: 1px solid #d5d8df;
	padding: 1rem;
	position: relative;
	margin: 0;
	flex: 1;
	z-index: 1;
`;

type SectionProps = {
	id?: string;
	title: string;
	cta?: React.ReactNode;
	tooltip?: string;
	setSectionsInView?: React.Dispatch<React.SetStateAction<string[]>>;
	children?: ReactNode;
};

export const PDFPanel = (props: SectionProps) => {
		return (
		<SectionContainer id={props.id}>
				{props.title != null && (
					<Row>
						<PanelTitle data-tooltip={props.tooltip}>{props.title}</PanelTitle>
						{props.cta}
					</Row>
				)}
				<Relative>{props.children}</Relative>
			</SectionContainer>
		);
};

PDFPanel.displayName = 'PDFPanel';

const Relative = styled.div`
	position: relative;
`;

const disclaimerText =
	'All information is not guaranteed. Some of the data on this report was provided by preparer and not verified by CompStak.';

export const PDFFooter = () => (
	<footer>
		<div className="footer-content">
			<span className="text">Data provided by</span>
			<a href="http://compstak.com/">
				<Logo height="20px" />
			</a>
			<div className="disclaimer-text">{disclaimerText}</div>
		</div>
	</footer>
);

export const Page = styled.div`
	color: #000;
	font-family: 'Gotham', 'Helvetica Neue', 'Helvetica', sans-serif;
	font-weight: 400;
	margin: 5px 0 0;
	min-height: 715px;
	page-break-before: always;
	position: relative;
	text-rendering: optimizeLegibility;

	& footer {
		background: #fff;
		border-top: 1px solid #000;
		bottom: 0px;
		font-size: 9px;
		left: 12px;
		margin: 0 0 1rem;
		padding: 8px 0.5rem 0;
		position: absolute;
		right: 12px;
	}

	& footer .text {
		display: inline-block;
		line-height: 22px;
		vertical-align: middle;
	}

	& footer .icon_icon {
		color: #000;
		margin-left: -10px;
		vertical-align: middle;
	}

	& footer .footer-content {
		float: left;
	}

	& footer .id-link {
		float: right;
	}

	& footer .disclaimer-text {
		margin-top: 4px;
		font-size: 9px;
	}
`;

export const ComponentWrap = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 1rem 1rem 6.7rem;
	width: auto;
`;

export const Panel = styled.div`
	width: calc(50% - 0.5rem);
`;
