import { connect } from 'react-redux';

import actionWrapper from 'util/actionWrapper';
import * as pageActions from './actions';
import * as feedbackActions from 'Singletons/Feedback/actions';
import * as exportActions from 'actions/export';
import * as menuActions from 'Singletons/Menu/actions';
import * as modalActions from 'Singletons/Modal/actions';
import Wrapper from './Components/Wrapper';
import { AppState } from 'reducers/root';
import { goBack } from 'router';

export function mapStoreToProps(store: AppState) {
	const lease = store.leaseDetails.lease;
	let locationState;
	if (store.router.location) {
		locationState = store.router.location.state || {};
	} else {
		locationState = {};
	}

	return {
		appConfig: store.appConfig,
		did404: store.leaseDetails.did404,
		headerNotificationShowing: store.headerNotification,
		index: store.leaseDetails.index,
		lease,
		rawLease: store.leaseDetails.rawLease,
		leaseId: lease ? lease.id : null,
		leases: store.leaseDetails.leases,
		compVerificationSent: store.leaseDetails.compVerificationSent,
		lowestLoaded: store.leaseDetails.lowestLoaded,
		permissions: store.permissions,
		search: store.leaseDetails.search,
		searchLoading: store.leaseDetails.searchLoading,
		sectionedAttrs: store.leaseDetails.sectionedAttrs,
		user: store.user,
		verifyComp: store.leaseDetails.verifyComp,
		totalLeases: store.leaseDetails.totalLeases,
		leaseLoading: store.leaseDetails.leaseLoading,
		locationState,
	};
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
export const mapDispatchToProps = (dispatch) => {
	return {
		goBack,
		...actionWrapper(
			{
				feedbackActions,
				exportActions,
				menuActions,
				modalActions,
				pageActions,
			},
			dispatch
		),
	};
};

export default connect(mapStoreToProps, mapDispatchToProps)(Wrapper);
