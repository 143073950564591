import { radiusExpanderSearch } from 'actions/search';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import { CompType, PropertyComp } from 'types/comp';
import {
	CompPopupActionButton,
	PopupActionButton,
	StyledArrowRightIcon,
} from './UI';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import IconRadius from 'ui/svg_icons/radius.svg';

type Props = {
	compType: CompType;
	property: PropertyComp;
	closePopup: () => void;
	['data-qa-id']: string;
	children: React.ReactNode;
};

export const NearbyCompsButton = ({
	property,
	compType,
	closePopup,
	children,
	...props
}: Props) => {
	const [filters] = useFilters();
	const dispatch = useDispatch();

	const onClickNearbyLeaseComps = async () => {
		dispatch(
			radiusExpanderSearch({
				compType,
				filters,
				lat: property.geoPoint.lat,
				lon: property.geoPoint.lon,
			})
		);
		closePopup();
	};

	const { portfolioRevampFF } = useFeatureFlags();

	return portfolioRevampFF ? (
		<PopupActionButton
			variant="secondary"
			onClick={onClickNearbyLeaseComps}
			icon={<IconRadius />}
		>
			{children}

			<StyledArrowRightIcon />
		</PopupActionButton>
	) : (
		<CompPopupActionButton
			onClick={onClickNearbyLeaseComps}
			data-qa-id={props['data-qa-id']}
		>
			{children}
		</CompPopupActionButton>
	);
};
