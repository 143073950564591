import { QUERY_KEYS } from 'api/constants';
import { useQueryHook } from 'api/utils';
import { getSubmissionDetailsById, getSubmissions } from 'services/submission';

export function useGetUserSubmissions() {
	return useQueryHook({
		queryKey: [QUERY_KEYS.USER_SUBMISSIONS],
		queryFn: async () => await getSubmissions(),
		refetchOnWindowFocus: false,
	});
}

export function useGetUserSubmissionDetails(submissionId: number) {
	return useQueryHook({
		queryKey: [QUERY_KEYS.USER_SUBMISSION_DETAILS, submissionId],
		queryFn: async () => await getSubmissionDetailsById(submissionId),
		refetchOnWindowFocus: false,
	});
}
