import { nullValueText } from '@compstak/common';

type AttributeValueProps = {
	value: string | string[] | number | null | undefined;
	showNullValueText?: boolean;
};

const AttributeValue = ({ value, showNullValueText }: AttributeValueProps) => {
	const noValueText = showNullValueText ? nullValueText : '';

	if (Array.isArray(value)) {
		return value.length ? value.join(' ') : noValueText;
	}

	if (value || value === 0) {
		return value;
	}

	return noValueText;
};

export default AttributeValue;
