import { MQB } from 'constants/mediaQueryBreakpoints';
import styled from 'styled-components';

const MainContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 80px;
	@media (min-width: ${MQB.D_1280}px) {
		flex-direction: row;
	}
`;
MainContainer.displayName = 'MainContentContainer';

const LeftSideContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 0;
`;
LeftSideContainer.displayName = 'LeftSideContainer';

const RightSideContainer = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
`;
RightSideContainer.displayName = 'RightSideContainer';

export const QuestionContentContainers1 = {
	MainContainer,
	LeftSideContainer,
	RightSideContainer,
};
