// Media Query Breakpoints
export const MQB = Object.freeze({
	// The M_ prefix is for < 1000px (mobile), D_ is for >= 1000px (desktop).
	M_640: 640,
	M_800: 800,
	D_1024: 1024,
	D_1280: 1280,
	D_1360: 1360,
	D_1536: 1536,
	D_1920: 1920,
} as const);
