import { KeyInfoItem } from 'Components/KeyInfoItem';
import styled from 'styled-components';
import { MufaPropertyComp, PropertyComp } from 'types';
import { commercialLabels, mufaLabels } from './labels';
import { COMP_FIELD_TOOLTIPS } from 'constants/tooltips';

type KeyInfoItemsProps = {
	property: PropertyComp | MufaPropertyComp;
	isMufa: boolean;
};
export const KeyInfoItems = ({ property, isMufa }: KeyInfoItemsProps) => {
	const labels = isMufa ? mufaLabels : commercialLabels;

	return (
		<KeyInfoContainer>
			{keyInfoAttributes.map((key) => (
				<KeyInfoItem
					key={key}
					tooltip={
						key === 'buildingClass'
							? COMP_FIELD_TOOLTIPS.buildingClass
							: undefined
					}
					title={labels[key].name}
					value={
						!property[key]
							? ''
							: labels[key].formatter?.(property[key]) ?? property[key]
					}
				/>
			))}
		</KeyInfoContainer>
	);
};

export const keyInfoAttributes: KeyInfoAttributes[] = [
	'buildingPropertyType',
	'buildingClass',
	'landlordName',
	'buildingPropertySubtype',
	'buildingSize',
	'buildingYear',
	'buildingYearRenovated',
];

export type KeyInfoAttributes = keyof Pick<
	PropertyComp,
	| 'buildingPropertyType'
	| 'buildingClass'
	| 'landlordName'
	| 'buildingPropertySubtype'
	| 'buildingSize'
	| 'buildingYear'
	| 'buildingYearRenovated'
>;

export const KeyInfoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 16px;
	& > div:nth-child(3) {
		grid-column: span 2;
	}
`;
