import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import Multiselect from 'Components/Filters/Base/Multiselect';
import { useReferenceDataQuery } from 'api';
import React from 'react';

const attribute = 'insideviewStatusId' as const;

export const TenantStatusFilter = () => {
	const { data: referenceData } = useReferenceDataQuery();

	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<Multiselect
					filter={props.filter}
					attribute={attribute}
					onFilterChange={props.onFilterChange}
					setError={props.setError}
					touch={props.touch}
					options={referenceData.insideviewStatus}
				/>
			)}
		</FilterBase>
	);
};
