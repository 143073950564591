import { css } from 'styled-components';
import { APP_HEADER_HEIGHT } from './sizes';

const APP_HEADER_HEIGHT_MOBILE = 50;
const APP_HEADER_MOBILE_BREAKPOINT = 801;

export const BELOW_APP_HEADER_CSS = css`
	inset: 0;
	top: ${APP_HEADER_HEIGHT}px;
	@media (max-width: ${APP_HEADER_MOBILE_BREAKPOINT}px) {
		top: ${APP_HEADER_HEIGHT_MOBILE}px;
	}
`;
