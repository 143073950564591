import { styled, css } from 'styled-components';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
	ModalParagraph,
} from 'Components/Modals/common/UI';
import { useId, useState } from 'react';
import { useUser } from 'Pages/Login/reducers.ts';
import { useDispatch } from 'react-redux';
import { addFeedback } from 'Singletons/Feedback/actions';

type Props = {
	closeDialog: () => void;
};

export const DataRequestDialog = ({ closeDialog }: Props) => {
	const [state, setState] = useState({ title: '', description: '' });
	const { email: senderEmail } = useUser();
	const onSubmit = () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const requestBody = {
			title: state.title,
			description: state.description,
			recipientEmail: 'clientsuccess@compstak.com',
			senderEmail,
		};
		//TODO: send the data request when AP-17686 is done.
		// dispatching a success or failute toast will depend on the response.
		closeDialog();
		dispatch(addFeedback('Data request sent!', 'success', null, 5000));
	};
	const nameId = useId();
	const descriptionId = useId();
	const dispatch = useDispatch();
	return (
		<>
			<ModalTitle>Send a Data Request</ModalTitle>
			<ModalParagraph>
				Submit your data request using the form below. The more details you
				provide, the better we can assist you. Our client Success Team will
				respond in 1-3 business days, though data collection times may very
				based on the complexity of the request.
			</ModalParagraph>
			<Spacer />
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<InputContainer>
					<DialogFormLabel htmlFor={nameId}>Request Title</DialogFormLabel>
					<TextInput
						type="text"
						id={nameId}
						value={state.title}
						onChange={(e) =>
							setState((prevState) => ({ ...prevState, title: e.target.value }))
						}
						data-qa-id="title"
					/>
				</InputContainer>
				<Spacer />
				<InputContainer>
					<DialogFormLabel id={descriptionId}>
						Request Description
					</DialogFormLabel>
					<TextArea
						id={descriptionId}
						placeholder="What data would you like to request?"
						value={state.description}
						onChange={(e) =>
							setState((prevState) => ({
								...prevState,
								description: e.target.value,
							}))
						}
						data-qa-id="description"
					/>
				</InputContainer>
				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeDialog}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={state.description.length === 0}
						style={{
							transition: 'none',
						}}
					>
						Submit
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};

const Spacer = styled.div`
	background-color: ${(p) => p.theme.colors.white.white};
	height: 1rem;
`;

const InputCss = css`
	&& {
		background-color: ${(p) => p.theme.colors.neutral.n30};
		border: 0;
		box-sizing: border-box;
		color: ${(p) => p.theme.colors.gray.gray700};
		font-size: 1rem;
		margin: 0;
		padding: 0;
		height: auto;
	}
`;

const TextInput = styled.input`
	${InputCss}
`;

const TextArea = styled.textarea`
	${InputCss};
	min-height: 140px;
`;

const InputContainer = styled.div`
	width: 100%;
	background-color: ${(p) => p.theme.colors.neutral.n30};
	border: 1px solid;
	border-color: ${(p) => p.theme.colors.neutral.n30};
	border-radius: 0.125rem;
	display: block;
	outline: 0;
	padding: 1rem;
`;

const DialogFormLabel = styled.label`
	font-size: 0.75rem;
	color: ${(p) => p.theme.colors.blue.blue400};
	font-weight: 400;
	display: block;
	text-align: left;
`;
