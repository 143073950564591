import { FormSelect, FormSelectProps } from '@compstak/ui-kit';
import { ModalInputLabel, ModalParagraph } from 'Components/Modals/common/UI';

type ChartBuilderMarketFormSelectProps = FormSelectProps;

export const ChartBuilderMarketFormSelect = (
	props: ChartBuilderMarketFormSelectProps
) => {
	return (
		<>
			<ModalParagraph>
				Chartbuilder currently only supports data from a single market per
				dataset. Please select a market.
			</ModalParagraph>
			<ModalInputLabel>
				Market
				<FormSelect
					data-qa-id="select-market"
					placeholder="Select Market"
					{...props}
				/>
			</ModalInputLabel>
		</>
	);
};
