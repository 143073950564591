import React from 'react';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryStack,
} from 'victory';
import { PDFPanel } from './UI';
import styled from 'styled-components';
import { LoadingChart } from 'ExchangeDashboard/UI';
import {
	COLOR_SCALE,
	getYearKeys,
} from 'ExchangeDashboard/LeaseExpirationSubmarket';
import { useExchangeDashboardLeaseExpirationBySubmarket } from 'ExchangeDashboard/hooks/leaseExpirationBySubmarket/useExchangeDashboardLeaseExpirationBySubmarket';

const MAX_SUBMARKETS_NUMBER = 8;
const DOMAIN: [number, number] = [0, MAX_SUBMARKETS_NUMBER];

export const LeaseExpirationSubmarket = () => {
	const { data, submarkets, isFetching } =
		useExchangeDashboardLeaseExpirationBySubmarket();

	const submarketsToDisplay = submarkets?.slice(...DOMAIN).reverse();

	const yearKeys = getYearKeys();

	const barDataByYearKey = yearKeys.reduce((acc, yearKey) => {
		return {
			...acc,
			[yearKey]: submarketsToDisplay?.map((submarket) => {
				return {
					x: submarket,
					y: data?.[yearKey]?.[submarket] ?? 0,
					yearKey,
					label: '',
				};
			}),
		};
	}, {});

	if (isFetching) {
		return <LoadingChart />;
	}

	return (
		<PDFPanel
			id="leaseExpirationSubmarket"
			title="Lease Expiration by Submarket"
		>
			<ChartContainer>
				<VictoryChart
					horizontal
					maxDomain={{ x: DOMAIN[1] }}
					domain={{ x: DOMAIN }}
					domainPadding={0}
					style={{ parent: { maxWidth: '100%', height: '100%' } }}
					height={540}
					width={520}
					padding={{
						left: 0,
						top: 50,
						bottom: 0,
						right: 50,
					}}
					containerComponent={
						<VictoryVoronoiContainer
							voronoiDimension="x"
							height={580}
							style={{ margin: '0 auto' }}
						/>
					}
				>
					<VictoryAxis
						axisLabelComponent={
							<VictoryLabel
								style={{
									fontSize: 12,
									fontFamily: 'Gotham',
								}}
							/>
						}
						fixLabelOverlap
						tickLabelComponent={
							<VictoryLabel
								textAnchor="start"
								dx={10}
								dy={-28}
								style={{
									fontSize: 12,
									fontFamily: 'Gotham',
									textTransform: 'capitalize',
								}}
							/>
						}
						style={{
							axis: { stroke: 'none' },
							axisLabel: {
								fontSize: 12,
								fontFamily: 'Gotham',
								color: '#000',
								textTransform: 'uppercase',
							},
							grid: { stroke: 'none' },
							ticks: { stroke: '#F0F0F0' },
							tickLabels: {
								fontSize: 12,
								fontFamily: 'Gotham',
								color: '#000',
								textTransform: 'uppercase',
							},
						}}
					/>
					<VictoryAxis
						dependentAxis
						label="Number of Leases Expiring"
						axisLabelComponent={
							<VictoryLabel
								dy={-22}
								style={{ fontFamily: 'Gotham' }}
								renderInPortal={false}
							/>
						}
						tickLabelComponent={
							<VictoryLabel
								dy={-30}
								style={{ fontSize: 14, fontFamily: 'Gotham' }}
							/>
						}
						fixLabelOverlap
						style={{
							axis: { stroke: '#F0F0F0', padding: 0, margin: 0 },
							axisLabel: {
								fontSize: 14,
								fontFamily: 'Gotham',
								color: '#000',
								textTransform: 'capitalize',
							},
							grid: { stroke: '#F0F0F0', margin: 0, padding: 0 },
							ticks: { stroke: '#F0F0F0' },
							tickLabels: {
								fontSize: 12,
								fontFamily: 'Gotham',
								color: '#000',
							},
						}}
					/>
					<VictoryStack
						colorScale={COLOR_SCALE.filter(
							(c, idx) =>
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								barDataByYearKey?.[yearKeys[idx]]?.filter((d) => d.y !== 0)
									.length > 0
						)}
						labels={() => ' '}
					>
						{yearKeys.map((yearKey) => {
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							const barData = barDataByYearKey?.[yearKey]
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								// @ts-expect-error TS7031: Binding element 'label' implic...
								.map(({ label, ...rest }) => rest)
								// @ts-expect-error TS7006: Parameter 'd' implicitly has a...
								.filter((d) => d.y !== 0);

							return (
								<VictoryBar
									key={yearKey}
									data={barData}
									barWidth={36}
									labels={() => ' '}
									style={{
										data: {
											cursor: 'pointer',
										},
									}}
								/>
							);
						})}
					</VictoryStack>
				</VictoryChart>
				<Legend isFullPage={false}>
					{yearKeys.map((yearKey, i) => (
						<div key={i} className="year-key">
							<div
								className="color"
								style={{ backgroundColor: COLOR_SCALE[i] }}
							/>
							<div>{yearKey}</div>
						</div>
					))}
				</Legend>
			</ChartContainer>
		</PDFPanel>
	);
};

const ChartContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
`;

const Legend = styled.div<{ isFullPage: boolean }>`
	max-width: 480px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	margin: ${(props) => (props.isFullPage ? '60px 54px 0 auto' : '25px 0 0')};

	.year-key {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		margin-right: 12px;
		font-size: 12px;

		.color {
			width: 18px;
			height: 18px;
			border-radius: 3px;
			margin-right: 8px;
		}
	}
`;
