import { API } from '@compstak/common';
import {
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api';
import { qcRetryOnlyAuth } from 'api/utils/queryRetries';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useUserId } from 'hooks/userHooks';
import { mapUrlToGateway } from 'utils/gatewayUtil';
import { z } from 'zod';
import {
	RewardHistory,
	rewardHistoryValidator,
	Vendors,
	vendorsValidator,
} from './types';

const NO_USER_ID_FOUND_ERR_MSG = 'No user id found';

export function useVendorsQuery(): UseQueryResult<Vendors> {
	const result = useQuery({
		queryKey: [QUERY_KEYS.VENDORS],
		queryFn: async () => {
			try {
				const { data } = await API.get<Vendors>('/api/getVendors');
				const validatedData = vendorsValidator.parse(data);
				return validatedData;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
	});
	return result;
}

async function getValidatedRewardHistory(): Promise<RewardHistory> {
	const { data } = await API.get<RewardHistory>('/api/v2/giftCard/summary');
	const validatedData = rewardHistoryValidator.parse(data);
	return validatedData;
}

function useRewardHistoryQuery(): UseQueryResult<RewardHistory> {
	const { ffGiftbitViaUserService } = useFeatureFlags();
	const result = useQuery({
		queryKey: [QUERY_KEYS.REWARD_HISTORY],
		queryFn: async () => {
			try {
				return await getValidatedRewardHistory();
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		enabled: !ffGiftbitViaUserService,
	});
	return result;
}

const rewardRedemptionsValidator = z.object({
	redemptions: z.array(
		z.object({
			cents: z.number(),
			redeemedAt: z.string(),
		})
	),
});

export type RewardRedemptionsT = z.infer<typeof rewardRedemptionsValidator>;

async function getRewardRedemptions(
	userId: number | undefined
): Promise<RewardRedemptionsT> {
	if (typeof userId !== 'number') {
		throw Error(NO_USER_ID_FOUND_ERR_MSG);
	}
	const { data } = await API.get(
		mapUrlToGateway(`/user-service/users/${userId}/redemptions`)
	);
	const parsedData = rewardRedemptionsValidator.parse(data);
	return parsedData;
}

export function useGetRewardRedemptionsQuery(): UseQueryResult<RewardRedemptionsT> {
	const userId = useUserId();
	const { ffGiftbitViaUserService } = useFeatureFlags();
	return useQuery({
		queryKey: [QUERY_KEYS.REWARD_REDEMPTIONS, userId],
		queryFn: async () => {
			try {
				const redemptions = await getRewardRedemptions(userId);
				return redemptions;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		enabled: !!ffGiftbitViaUserService,
	});
}

const rewardAmountValidator = z.object({
	currentYearRedeemedCents: z.number(),
	remainingCents: z.number(),
});

type RewardAmountT = z.infer<typeof rewardAmountValidator>;

async function getRewardAmount(
	userId: number | undefined
): Promise<RewardAmountT> {
	if (typeof userId !== 'number') {
		throw Error(NO_USER_ID_FOUND_ERR_MSG);
	}
	const { data } = await API.get(
		mapUrlToGateway(`/user-service/users/${userId}/rewards/amount`)
	);
	const parsedData = rewardAmountValidator.parse(data);
	return parsedData;
}

export function useGetRewardAmountQuery() {
	const userId = useUserId();
	const { ffGiftbitViaUserService } = useFeatureFlags();
	return useQuery({
		queryKey: [QUERY_KEYS.REWARD_AMOUNT, userId],
		queryFn: async () => {
			try {
				const rewardAmount = await getRewardAmount(userId);
				return rewardAmount;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		enabled: !!ffGiftbitViaUserService,
	});
}

export function useBalanceAndRedemptions(): {
	balanceData: RewardAmountT | undefined;
	balanceLoading: boolean;
	balanceFetchError: unknown;
	redemptions: RewardRedemptionsT | undefined;
	redemptionsLoading: boolean;
	redemptionsFetchError: unknown;
} {
	// When backend is fixed we will switch to using user-service endpoints.
	// https://compstak.atlassian.net/browse/AP-14433
	const { ffGiftbitViaUserService } = useFeatureFlags();

	const {
		data: v1Data,
		isLoading: v1IsLoading,
		error: v1Error,
	} = useRewardHistoryQuery();
	const {
		data: balanceData,
		isLoading: balanceLoading,
		error: balanceFetchError,
	} = useGetRewardAmountQuery();
	const {
		data: redemptions,
		isLoading: redemptionsLoading,
		error: redemptionsFetchError,
	} = useGetRewardRedemptionsQuery();

	if (ffGiftbitViaUserService) {
		return {
			balanceData,
			balanceLoading,
			balanceFetchError,
			redemptions,
			redemptionsLoading,
			redemptionsFetchError,
		};
	}

	const v1Aux = {
		balanceLoading: v1IsLoading,
		balanceFetchError: v1Error,
		redemptionsLoading: v1IsLoading,
		redemptionsFetchError: v1Error,
	};

	if (!v1Data) {
		return {
			balanceData: undefined,
			redemptions: undefined,
			...v1Aux,
		};
	}

	const { history, currentQuarter } = v1Data;
	const balanceDataV1 = {
		remainingCents: (currentQuarter.earned - currentQuarter.redeemed) * 100,
		currentYearRedeemedCents: currentQuarter.redeemed * 100,
	};
	const redemptionsDataV1 = {
		redemptions: history.map((historyItem) => ({
			cents: historyItem.amount * 100,
			redeemedAt: historyItem.timestamp,
		})),
	};
	return {
		balanceData: balanceDataV1,
		redemptions: redemptionsDataV1,
		...v1Aux,
	};
}

export function useRefreshRewardsHistory() {
	const userId = useUserId();
	const queryClient = useQueryClient();
	const { ffGiftbitViaUserService } = useFeatureFlags();

	const refreshRewardsHistory = async () => {
		if (!ffGiftbitViaUserService) {
			const rewardData = await getValidatedRewardHistory();
			queryClient.setQueryData([QUERY_KEYS.REWARD_HISTORY], rewardData);
			return;
		}
		const [redemptions, amount] = await Promise.all([
			await getRewardRedemptions(userId),
			await getRewardAmount(userId),
		]);
		queryClient.setQueryData(
			[QUERY_KEYS.REWARD_REDEMPTIONS, userId],
			redemptions
		);
		queryClient.setQueryData([QUERY_KEYS.REWARD_AMOUNT, userId], amount);
	};
	return { refreshRewardsHistory };
}

export function useInvalidateRewardsHistory() {
	// This is only for the legacy rewards history page in /settings.
	const queryClient = useQueryClient();
	const invalidateRewardsHistory = () => {
		queryClient.invalidateQueries([QUERY_KEYS.REWARD_HISTORY]);
	};
	return invalidateRewardsHistory;
}

function legacyRedeemGiftCard({
	email,
	vendorId,
}: {
	email: string;
	vendorId: number;
}) {
	return API.post('/api/v2/giftCard/redeem', {
		email,
		vendorId,
	});
}

function redeemGiftCard({
	email,
	vendorId,
	userId,
}: {
	email: string;
	vendorId: number;
	userId: number;
}) {
	return API.post(
		mapUrlToGateway(`/user-service/users/${userId}/rewards/redeemGiftbit`),
		{
			email,
			vendorId,
		}
	);
}

export function useRedeemRewardMutation({
	onSuccess,
	onError,
}: {
	onSuccess: () => void;
	onError?: () => void;
}) {
	const { refreshRewardsHistory } = useRefreshRewardsHistory();
	const userId = useUserId();
	const { ffGiftbitViaUserService } = useFeatureFlags();

	return useMutation({
		mutationFn: async ({
			email,
			vendorId,
		}: {
			email: string;
			vendorId: number;
		}) => {
			if (typeof userId !== 'number') {
				throw Error(NO_USER_ID_FOUND_ERR_MSG);
			}
			if (ffGiftbitViaUserService) {
				await redeemGiftCard({ email, vendorId, userId });
			} else {
				await legacyRedeemGiftCard({ email, vendorId });
			}
			await refreshRewardsHistory();
		},
		retry: qcRetryOnlyAuth,
		onSuccess,
		onError,
	});
}
