import { useFilteredTenantIndustries } from 'ExchangeDashboard/TenantIndustryMap';
import { useIsTopTenTenantIndustries, useTenantIndustryState } from 'maps';
import { useFilters } from 'reducers/filtersReducer';

export const useTenantIndustryMapDescription = () => {
	const [state] = useTenantIndustryState();
	const tenantIndustries = useFilteredTenantIndustries();
	const [filters] = useFilters('exchange-dashboard');

	const isTopTen = useIsTopTenTenantIndustries({ tenantIndustries, filters });

	return `${isTopTen ? 'Top ten industries' : 'Selected industries'} based on total ${TEXT_MAP[state.metric]} in selected market and submarket(s). Radius indicates total ${TEXT_MAP[state.metric]} of prevalent industry type of a property.`;
};

const TEXT_MAP = {
	sqft: 'square feet',
	leaseCount: 'lease count',
};
