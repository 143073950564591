import { mapValues, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryTooltip,
} from 'victory';
import { ChartBox, TotalValue } from './ChartSelect';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { Portfolio, PortfolioTenant } from 'api';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { groupBy } from 'lodash';
import { formatInteger } from 'format';
import { useNavigate } from 'react-router';
import { routes } from 'router';

type TenantExposureChartProps = {
	portfolio: Portfolio;
	minBarChartWidth?: number;
};

const TOP_COUNT = 10;
const TENANT_INDUSTRY_EXPOSURE_CHART_NAME = `Top ${TOP_COUNT} Tenant Exposure`;

export const TenantExposureChart = ({
	portfolio,
	minBarChartWidth,
}: TenantExposureChartProps) => {
	const navigate = useNavigate();

	const tenantExposureData = useMemo(() => {
		const tenantNameGroups = groupBy(
			portfolio.tenantNames,
			(t) => t.tenantName
		);
		const tenantData = Object.values(
			mapValues(tenantNameGroups, getTotalValues)
		);

		return {
			sf: getChartOptionData(tenantData, 'totalSqFt'),
			leaseCount: getChartOptionData(tenantData, 'activeLeaseCount'),
			leaseAmount: getChartOptionData(tenantData, 'activeLeaseAmount'),
		};
	}, [portfolio]);

	const theme = useTheme();

	return (
		<ChartBox chartName={TENANT_INDUSTRY_EXPOSURE_CHART_NAME}>
			{(selectedChart) => {
				const data = tenantExposureData[selectedChart.type];
				if (data.length === 0) {
					return (
						<ChartContainer>
							<DataNotAvailableMessage
								chartName={`${TENANT_INDUSTRY_EXPOSURE_CHART_NAME} by ${selectedChart.value}`}
							/>
						</ChartContainer>
					);
				}

				return (
					<Centered>
						<VictoryChart
							height={500}
							width={minBarChartWidth ? minBarChartWidth : 480}
							containerComponent={
								<VictoryVoronoiContainer
									responsive={false}
									style={{ margin: '0 auto' }}
								/>
							}
							padding={{ left: 90, top: 50, right: 10, bottom: 50 }}
							style={{
								parent: {
									color: '#A3A3A3',
									fontSize: 10,
									lineHeight: 10,
									maxWidth: '80%',
									margin: '0 auto',
								},
							}}
						>
							<VictoryAxis
								dependentAxis
								fixLabelOverlap
								offsetY={30}
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
								}
								tickFormat={(d: string) => abbreviateNumber(d)}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: {
										fontSize: 20,
										padding: 30,
										color: '#A3A3A3',
										textOverflow: 'ellipsis',
										whiteSpace: 'no-wrap',
										overflow: 'hidden',
									},
									grid: { stroke: '#F0F0F0' },
									ticks: { stroke: '#F0F0F0', size: 3 },
									tickLabels: {
										padding: 0,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryAxis
								fixLabelOverlap
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
								}
								tickFormat={(splitTick: string) => {
									return splitTick.replace(
										new RegExp(`(?![^\\n]{1,20}$)([^\\n]{1,20})\\s`, 'g'),
										'$1\n'
									);
								}}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: { fontSize: 20, padding: 0, color: '#A3A3A3' },
									grid: { stroke: '#F0F0F0', border: '1px solid black' },
									ticks: { stroke: '#F0F0F0', size: 2 },
									tickLabels: {
										padding: 5,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryBar
								labelComponent={
									<VictoryTooltip
										flyoutStyle={{
											fill: theme.colors.gray.gray700,
										}}
										style={{ fill: theme.colors.white.white, fontSize: 12 }}
										text={(d) => {
											const tenantName =
												d?.datum?.xName && d?.datum?.xName.length > 20
													? d?.datum?.xName.substring(0, 20) + '...'
													: d?.datum?.xName;
											return `(${formatInteger(d?.datum?.y || 0)}) \n Click the bar to view comps \n for ${tenantName}`;
										}}
										flyoutWidth={160}
										flyoutHeight={70}
										centerOffset={{ x: -120, y: 50 }}
									/>
								}
								labels={({ datum }) => formatInteger(datum.y)}
								alignment="middle"
								barRatio={1}
								barWidth={15}
								horizontal
								width={120}
								height={500}
								data={data}
								style={{
									data: { cursor: 'pointer', fill: PORTFOLIO_CHART_COLORS[0] },
									labels: {
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: (_, clickedProps) => {
												const tenantName = clickedProps.datum.x as string;
												navigate(
													routes.portfolioByIdView.toHref(
														{ portfolioId: portfolio.id, viewType: 'list' },
														{
															tenantNames: [tenantName],
														}
													)
												);
											},
										},
									},
								]}
							/>
						</VictoryChart>
					</Centered>
				);
			}}
		</ChartBox>
	);
};

const Centered = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`;

export const getTotalValues = (groups: PortfolioTenant[]) => {
	return groups.reduce(
		(
			total,
			{ activeLeaseCount, activeLeaseAmount, totalSqFt, tenantName }
		) => ({
			activeLeaseCount: total.activeLeaseCount + activeLeaseCount,
			activeLeaseAmount: total.activeLeaseAmount + activeLeaseAmount,
			totalSqFt: total.totalSqFt + totalSqFt,
			tenantName,
		}),
		{
			activeLeaseCount: 0,
			activeLeaseAmount: 0,
			totalSqFt: 0,
			tenantName: '',
		}
	);
};

export const getChartOptionData = (
	tenantData: Pick<PortfolioTenant, keyof TotalValue | 'tenantName'>[],
	chartOptionKey: keyof TotalValue
) => {
	return sortBy(tenantData, (tenant) => tenant[chartOptionKey])
		.slice(-TOP_COUNT)
		.map((tenant) => ({
			x: tenant.tenantName,
			y: tenant[chartOptionKey],
		}));
};
