import { Portfolio } from 'api';
import { getIsMarketSupportedForMapAnalytics } from 'maps';
import { useMemo } from 'react';
import { isPropertyTypeAllowed } from './isPropertyTypeAllowed';

type Props = {
	portfolio: Portfolio;
};

/** Portfolio markets which can be used for market rent analytics */
export const useValidMarkets = ({ portfolio }: Props) => {
	return useMemo(() => {
		return portfolio.markets.filter((m) => {
			const isSupported = getIsMarketSupportedForMapAnalytics(m.marketId);
			const marketPropertyTypes = portfolio.propertyTypesByMarkets.filter(
				(pm) => pm.marketId === m.marketId
			);
			const hasAtLeastOneAllowedPropertyType = marketPropertyTypes.some((pm) =>
				isPropertyTypeAllowed(pm.buildingPropertyTypeId)
			);

			return isSupported && hasAtLeastOneAllowedPropertyType;
		});
	}, [portfolio]);
};
