import styled from 'styled-components';
import CloseIcon from 'ui/svg_icons/cross.svg';

type Props = {
	onClick: () => void;
};

export const MapAnalyticsSettingsCloseButton = ({ onClick }: Props) => {
	return (
		<CloseButton
			data-qa-id="analytics-settings-menu-close-btn"
			onClick={onClick}
		>
			<CloseIcon /> Close
		</CloseButton>
	);
};

const CloseButton = styled.button`
	align-items: center;
	background-color: #f4f6f9;
	box-sizing: border-box;
	border: 1px solid #e6e6e6;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	font-size: 12px;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	height: 54px;
	padding: 0 20px;
	width: 250px;
	width: 100%;
	gap: 8px;
`;
