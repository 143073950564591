import { Container, GrayLabel, Body, Title, Text } from './EmptyPortfolio';

export const NoPortfolios = () => {
	return (
		<Container>
			<GrayLabel>CREATE A PORTFOLIO</GrayLabel>
			<Body>
				<Title>Create a Portfolio</Title>
				<Text>
					Organizing properties into portfolios can be a useful way to view and
					analyze property data. To create a portfolio,{' '}
					<strong>click the New Portfolio button on the upper left.</strong>
				</Text>
			</Body>
		</Container>
	);
};
