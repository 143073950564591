export const SUPPORTED_MARKET_IDS_FOR_MAP_ANALYTICS = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24,
	26, 27, 28, 29, 32, 37, 38,
];

/**
 * Multiply the size of the circle with this value for a specific market
 * to make the circle sizes somewhat proportional to the size of market &
 * amount of leases/sqft in the market
 * */
export const CIRCLE_MARKET_MULTIPLIER_MAP = {
	Atlanta: 200,
	Austin: 10,
	Baltimore: 50,
	'Bay Area': 300,
	Boston: 30,
	Chicago: 80,
	'Dallas - Fort Worth': 200,
	Denver: 200,
	Detroit: 200,
	Houston: 200,
	'Las Vegas': 100,
	'Los Angeles': 250,
	Manhattan: 1,
	Miami: 10,
	'Minneapolis - St. Paul': 10,
	'New Jersey': 100,
	Philadelphia: 200,
	Phoenix: 30,
	Portland: 10,
	'Raleigh-Durham': 30,
	'Sacramento - Central Valley': 10,
	'Salt Lake City': 10,
	'San Antonio': 10,
	'San Diego': 200,
	'San Francisco': 1,
	Seattle: 4,
	'Tampa Bay': 30,
	'Washington DC': 50,
	'Westchester County': 100,
};
