import React from 'react';

/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
export const usePrevious = <T extends any>(value: T) => {
	const ref = React.useRef<T>();
	React.useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
