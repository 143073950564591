import { DragSource } from 'react-dnd';

import { PLACED, UNPLACED } from '../DragTypes';

const pendingSpaceSource = {
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	beginDrag(props) {
		props.startDraggingSpace(props.space);
		return {
			originalFloor: props.space.floor,
			space: props.space,
			index: props.index,
		};
	},
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	canDrag(props) {
		if (typeof props.space.size === 'undefined') {
			return false;
		}
		return true;
	},
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	isDragging(props, monitor) {
		return props.space.id === monitor.getItem().space.id;
	},
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	endDrag(props, monitor) {
		props.endDraggingSpace(monitor.didDrop());
	},
};

// @ts-expect-error TS7006: Parameter 'connect' implicitly...
function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	};
}

export const UnplacedDragSource = DragSource(
	UNPLACED,
	pendingSpaceSource,
	collect
);
export const PlacedDragSource = DragSource(PLACED, pendingSpaceSource, collect);
