import {
	PropertyStatsMetric,
	PropertyStatsResponse,
} from 'api/propertyStats/types';
import { usePropertyStats } from 'api/propertyStats/usePropertyStats';
import { useIsPropertyMarketMonthly } from 'api/utils/useIsPropertyMarketMonthly';
import { useMemo } from 'react';
import { routes } from 'router';
import { MarketStatsChartItem } from './MarketStatsChart';
import { useMarketStatsFilters } from './MarketStatsFiltersProvider';
import { useDominantPropertySpaceType } from './useDominantPropertySpaceType';

type Props = {
	metric: PropertyStatsMetric;
	shouldShowMonthly?: boolean;
};

export const useMarketStatsChartData = ({
	metric,
	shouldShowMonthly = false,
}: Props) => {
	const params = routes.propertyById.useParams();

	const [filters] = useMarketStatsFilters();

	const { data: dominantSpaceType, isFetching: isFetchingSpaceType } =
		useDominantPropertySpaceType({
			propertyId: params.propertyId,
		});

	const { data, isFetching } = usePropertyStats({
		propertyId: params.propertyId,
		metric,
		months: filters.duration,
		spaceTypes: dominantSpaceType ? [dominantSpaceType] : [],
		enabled: !isFetchingSpaceType,
	});

	const { data: isMonthlyMarket, isFetching: isFetchingMonthlyMarket } =
		useIsPropertyMarketMonthly({ propertyId: params.propertyId });

	return {
		data: useMemo(() => {
			if (!data || isMonthlyMarket == null) return undefined;

			const isMonthly = shouldShowMonthly && isMonthlyMarket;

			return {
				market: getChartItem(data.market, isMonthly),
				submarket: getChartItem(data.submarket, isMonthly),
				compset: getChartItem(data.compset, isMonthly),
				leases: getChartItem(data.leases, isMonthly),
			};
		}, [data, isMonthlyMarket, shouldShowMonthly]),
		isFetching: isFetching || isFetchingMonthlyMarket || isFetchingSpaceType,
	};
};

const getChartItem = (
	data: PropertyStatsResponse,
	isMonthly: boolean
): Omit<MarketStatsChartItem, 'label'> => {
	const item = data.stats[0];
	return {
		start: getValue(item.weightedP25, isMonthly),
		end: getValue(item.weightedP75, isMonthly),
		middle: getValue(item.weightedAvg, isMonthly),
	};
};

const getValue = (value: number | undefined, isMonthly: boolean) => {
	if (value == null) return undefined;
	return isMonthly ? value / 12 : value;
};
