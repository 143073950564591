import { TenantIndustry, TopTenantIndustry } from 'api';
import { ComponentType } from 'react';
import { LeaseExpirationByPropertyTypeEdit } from './LeaseExpirationSection/LeaseExpirationByPropertyTypeEdit';
import { LeaseExpirationByPropertyTypePdf } from './LeaseExpirationSection/LeaseExpirationByPropertyTypePdf';
import { MarketRentMapPdfSection } from './MarketRentSection/MarketRentMapPdfSection';
import { MarketRentMapSection } from './MarketRentSection/MarketRentMapSection';
import {
	StartingRentPdfSection,
	StartingRentSection,
} from './StartingRentSection';
import { TenantIndustryDistributionPdfSection } from './TenantIndustryDistribution/TenantIndustryDistributionPdfSection';
import { TenantIndustryDistributionSection } from './TenantIndustryDistribution/TenantIndustryDistributionSection';
import { TenantIndustryMapPdfSection } from './TenantIndustryMap/TenantIndustryMapPdfSection';
import { TenantIndustryMapSection } from './TenantIndustryMap/TenantIndustryMapSection';
import { LeaseExpirationBySubmarketPdf } from './LeaseExpirationSection/LeaseExpirationBySubmarketPdf';
import { LeaseExpirationBySubmarketEdit } from './LeaseExpirationSection/LeaseExpirationBySubmarketEdit';

export const HALF_SECTIONS = [
	'marketRentHeatMap',
	'startingRent',
	'leaseExpirationPropertyType',
	'leaseExpirationSubmarket',
	'tenantIndustryMap',
	'tenantIndustryDistribution',
] as const;

export const TABLE_SECTIONS = [
	'recentlySignedLeases',
	'largestRecentLeases',
	'recentTransactions',
	'largeSales',
] as const;

export type HalfSectionKey = (typeof HALF_SECTIONS)[number];

export const DYNAMIC_SECTIONS = [...TABLE_SECTIONS, ...HALF_SECTIONS] as const;

export type SectionKey = (typeof DYNAMIC_SECTIONS)[number];

export const EDIT_COMPONENT_MAP = {
	marketRentHeatMap: MarketRentMapSection,
	startingRent: StartingRentSection,
	leaseExpirationPropertyType: LeaseExpirationByPropertyTypeEdit,
	leaseExpirationSubmarket: LeaseExpirationBySubmarketEdit,
	tenantIndustryMap: TenantIndustryMapSection,
	tenantIndustryDistribution: TenantIndustryDistributionSection,
} satisfies Record<HalfSectionKey, ComponentType>;

export const PDF_COMPONENT_MAP = {
	marketRentHeatMap: MarketRentMapPdfSection,
	leaseExpirationPropertyType: LeaseExpirationByPropertyTypePdf,
	leaseExpirationSubmarket: LeaseExpirationBySubmarketPdf,
	startingRent: StartingRentPdfSection,
	tenantIndustryDistribution: TenantIndustryDistributionPdfSection,
	tenantIndustryMap: TenantIndustryMapPdfSection,
} satisfies Record<HalfSectionKey, ComponentType<PdfSectionProps>>;

export const IMAGE_SECTIONS = [
	'marketRentHeatMap',
	'startingRent',
	'leaseExpirationPropertyType',
	'leaseExpirationSubmarket',
	'tenantIndustryDistribution',
	'tenantIndustryMap',
	'largestLandlords',
	'largestTenants',
] as const;

export type ImageSectionKey = (typeof IMAGE_SECTIONS)[number];

export type PdfSectionProps = {
	marketRentHeatMap: {
		title: string;
		description: string;
		url: string | null;
	};
	startingRent: {
		url: string | null;
		description: string;
	};
	leaseExpirationPropertyType: {
		url: string | null;
	};
	leaseExpirationSubmarket: {
		url: string | null;
	};
	tenantIndustryDistribution: {
		url: string | null;
		data: TopTenantIndustry[] | undefined;
	};
	tenantIndustryMap: {
		url: string | null;
		data: TenantIndustry[];
		description: string;
	};
};
