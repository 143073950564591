import React from 'react';
import { FilterCategory } from './FilterCategory';
import { useCurrentFiltersMarketsHaveFeature, useUserQuery } from 'api';
import { DateCreatedFilter } from 'Components/Filters/Fields/DateCreatedFilter/DateCreatedFilter';
import { HiddenFilter } from 'Components/Filters/Fields/HiddenFilter/HiddenFilter';
import { PartialFilter } from 'Components/Filters/Fields/PartialFilter/PartialFilter';
import { MyCompsFilter } from 'Components/Filters/Fields/MyCompsFilter/MyCompsFilter';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';

export const CompFilterCategory = () => {
	const { data: user } = useUserQuery();
	const { context, compType } = useFilterFieldContext();

	const { data: hasAllCompsAccess } = useCurrentFiltersMarketsHaveFeature(
		compType === 'sale' ? 'ownsAllSalesComps' : 'ownsAllLeaseComps'
	);

	const myCompsFilterIsVisible = !hasAllCompsAccess && context !== 'analytics';

	return (
		<FilterCategory category="Comp">
			<DateCreatedFilter>
				{context === 'analytics' && <UndesirableResults />}
			</DateCreatedFilter>

			{myCompsFilterIsVisible && <MyCompsFilter />}

			{user.userType === 'admin' && (
				<>
					<HiddenFilter />
					<PartialFilter />
				</>
			)}
		</FilterCategory>
	);
};

const UndesirableResults = () => {
	return (
		<FilterDescription>
			Note: using this filter may create undesirable results.
		</FilterDescription>
	);
};
