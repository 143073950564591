import { useUser } from 'Pages/Login/reducers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { LayoutWithoutHeader } from './LayoutWithoutHeader';
import { PageSpinner } from 'Layouts/styles/PageSpinner';

export const UnauthenticatedLayout = () => {
	const user = useUser();
	const navigate = useNavigate();

	// ensure that authorized user gets redirected to home page
	useEffect(() => {
		if (user) {
			navigate(routes.home.path);
		}
	}, [user, navigate]);

	// show spinner while authorized user gets redirected to home page
	if (user) {
		return <PageSpinner />;
	}

	return <LayoutWithoutHeader />;
};
