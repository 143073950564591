import styled, { css, createGlobalStyle } from 'styled-components';
import {
	Chip,
	MQB,
	SimpleTableBodyCellRoot,
	SimpleTableHeaderCellRoot,
} from '@compstak/ui-kit';

export const GlobalStyle = createGlobalStyle`
	.application_wrapper {
		overflow-y: scroll;
		overflow-x: hidden;
	}
	a:hover {
		color: unset;
	}
`;

export const ContentWrap = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 40px 120px;

	@media (max-width: ${MQB.T_834}) {
		padding: 24px;
	}

	${({ theme }) => theme.breakpoints.down('M_414')} {
		padding: 16px;
	}
`;

const MIN_TABLE_WIDTH = 320;
const MAX_TABLE_WIDTH = 1000;
const TABLE_GAP = 40;

export const commonStickyStyles = css`
	&:nth-child(1) {
		position: sticky;
		left: 0;
	}
	&:nth-child(2) {
		position: sticky;
		left: 40px;
		${({ theme }) => theme.breakpoints.down('M_414')} {
			border-right: ${({ theme }) => `1px solid ${theme.colors.gray.gray300}`};
		}
	}
`;

export const GridWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(
		2,
		minmax(${MIN_TABLE_WIDTH}px, ${MAX_TABLE_WIDTH}px)
	);
	grid-auto-rows: auto;
	gap: ${TABLE_GAP}px;
	overflow: auto;
	margin-bottom: 48px;

	${({ theme }) => theme.breakpoints.down('D_1280')} {
		grid-template-columns: repeat(
			1,
			minmax(${MIN_TABLE_WIDTH}px, ${MAX_TABLE_WIDTH}px)
		);
	}

	${SimpleTableHeaderCellRoot}, ${SimpleTableBodyCellRoot} {
		${commonStickyStyles}
	}
`;

export const ButtonWrap = styled.span`
	button {
		line-height: 24px;
		text-transform: none;
		padding: 12px 8px;

		svg {
			margin-right: 4px;
		}
	}

	${({ theme }) => theme.breakpoints.down('M_414')} {
		button {
			min-width: auto;

			span {
				display: none;
			}

			svg {
				margin-right: 0px;
			}
		}
	}
`;

export const FilterWrap = styled.span`
	display: grid;
	grid-template: 50px / 300px 160px;
	gap: 8px;

	${({ theme }) => theme.breakpoints.down('M_414')} {
		grid-template: 50px / 192px 120px;
	}
`;

const commonTextStyles = css`
	color: ${({ theme }) => theme.colors.neutral.n0};
	font-family: ${({ theme }) => theme.typography.fontFamily.openSans};
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	letter-spacing: 0.3px;

	strong {
		font-weight: 600;
	}
`;

export const HeaderMaxWidth = styled.div`
	max-width: ${MAX_TABLE_WIDTH * 2 + TABLE_GAP}px;
	width: 100%;
`;

export const Header = styled.h1`
	display: flex;
	justify-content: space-between;
	gap: 16px;

	font-family: ${({ theme }) => theme.typography.fontFamily.openSans};
	color: ${({ theme }) => theme.colors.neutral.n0};
	font-weight: 700;
	line-height: 52px;

	@media (max-width: ${MQB.T_834}) {
		font-size: 32px;
		line-height: 40px;
	}
`;

export const SubHeader = styled.h1`
	${commonTextStyles};
	font-weight: 300;
	font-size: 0.4em;
`;

export const Description = styled.p`
	margin: 16px 0 24px 0;
	${commonTextStyles}
`;

export const MissingSomethingText = styled.p`
	${commonTextStyles}
	margin-top: 24px;

	strong {
		font-weight: 400;
		cursor: pointer;
		color: ${({ theme }) => theme.colors.blue.blue400};
		text-decoration: underline;

		&:hover {
			color: ${({ theme }) => theme.colors.blue.blue600};
		}
	}

	@media (max-width: ${MQB.T_834}) {
		margin-bottom: 0;
	}
`;

export const HeaderSection = styled.section`
	display: flex;
	justify-content: center;
	border-radius: 0 0 120px 0;
	padding: 64px 120px 40px 120px;
	background-color: ${({ theme }) => theme.colors.neutral.n500};

	@media (max-width: ${MQB.T_834}) {
		padding: 24px;
		border-radius: 0 0 60px 0;
	}

	${({ theme }) => theme.breakpoints.down('M_414')} {
		padding: 16px;
	}
`;

export const MethodologySection = styled.section`
	title {
		display: block;
		color: ${({ theme }) => theme.colors.gray.gray700};
		font-family: Gotham, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		padding-bottom: 8px;
	}
	p {
		color: ${({ theme }) => theme.colors.neutral.n100};
		font-family: 'OpenSans', sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.3px;
	}
	strong {
		font-weight: 600;
	}
`;

export const LinkStyled = styled.a`
	color: ${({ theme }) => theme.colors.gray.gray700};
	text-decoration: underline;
`;

export const BetaChip = styled(Chip)`
	display: inline-flex;
	align-self: flex-start;

	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	padding: 0.3rem 1.5rem;
	background-color: ${({ theme }) => theme.colors.blue.blue400};
	color: ${({ theme }) => theme.colors.neutral.n0};
	font-size: 0.85rem;
	font-weight: 350;
	line-height: 16px;
	letter-spacing: 0.3px;
`;

export const PrintHide = styled.div`
	position: relative;
	overflow: hidden;
`;

export const PrintWrap = styled.div`
	position: absolute;
	top: 0;
	pointer-events: none;
	width: 2040px;

	${GridWrap} {
		grid-template-columns: repeat(
			2,
			minmax(${MIN_TABLE_WIDTH}px, ${MAX_TABLE_WIDTH}px)
		);
	}
	${ContentWrap} {
		padding: 40px 120px;
	}
	a {
		text-decoration: none;
	}
`;

export const NoAccessibleMarketFound = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 120px;

	@media (max-width: ${MQB.T_834}) {
		padding: 24px;
		h3 {
			font-size: 18px;
		}
	}
`;

export const PendingWrap = styled.div`
	border-radius: 4px;
	overflow: hidden;
	height: 24px;
`;
