import React, { useMemo } from 'react';

import styled from 'styled-components';
import Typeahead from 'Components/Typeahead';
import SubtractIcon from '../../../ui/svg_icons/subtract.svg';
import { SuggestionField } from 'api/suggestions/suggestions';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { FiltersObject, StringsFiltersKeys } from 'models/filters/types';
import { FilterErrors } from './Filter/Container';
import { CompType } from 'types';
import {
	GetVirtualListWidth,
	VirtualList,
	VirtualListProps,
} from '@compstak/ui-kit';
import { FilterInputPlaceholder } from './Filter/FilterInput';

type StringsTypeaheadProps = {
	attribute: StringsFiltersKeys;
	compType: CompType;
	filter?: string[] | null;
	filters: FiltersObject;
	typeaheadAttribute?: SuggestionField;
	placeholder?: string;
	touch: NoArgCallback;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setError: (errors: FilterErrors) => void;
};

const ITEM_SIZE = 48;
const MAX_HEIGHT = ITEM_SIZE * 4;

const StringsTypeahead = (props: StringsTypeaheadProps) => {
	const typeaheadAttr = (props.typeaheadAttribute ??
		props.attribute) as SuggestionField;
	const selectedFilterValues = props.filter ?? [];

	const setFilter = (value: string) => {
		props.touch();
		const newSelectedFilterValues = [...selectedFilterValues];
		if (!newSelectedFilterValues.includes(value)) {
			newSelectedFilterValues.push(value);
		}

		try {
			props.onFilterChange({
				[props.attribute]: newSelectedFilterValues,
			});
		} catch (e) {
			// @ts-expect-error TS18046: 'e' is of type 'unknown'....
			props.setError(e.message);
		}
	};

	const removeFilter = (val: string) => {
		try {
			props.onFilterChange({
				[props.attribute]: selectedFilterValues.filter((v) => v !== val),
			});
		} catch (e) {
			// @ts-expect-error TS18046: 'e' is of type 'unknown'....
			props.setError(e.message);
		}
	};

	const renderItem: VirtualListProps<string>['renderItem'] = ({
		data,
		style,
	}) => {
		return (
			<div style={style} key={data}>
				<InputPlaceholder>
					{data}
					<a onClick={() => removeFilter(data)} className="remove-control">
						<SubtractIcon />
					</a>
				</InputPlaceholder>
			</div>
		);
	};

	const height = useMemo(() => {
		const contentHeight = selectedFilterValues.length * ITEM_SIZE;
		return Math.min(MAX_HEIGHT, contentHeight);
	}, [selectedFilterValues.length]);

	const width: GetVirtualListWidth = (width, scrollbarWidth) => {
		return width + scrollbarWidth;
	};

	return (
		<Wrap>
			<VirtualList
				items={selectedFilterValues}
				renderItem={renderItem}
				constantItemSize={ITEM_SIZE}
				height={height}
				width={width}
			/>
			<Typeahead
				compType={props.compType}
				omittedSuggestions={selectedFilterValues}
				onSelection={setFilter}
				typeaheadAttribute={typeaheadAttr}
				markets={getFiltersMarkets(props.filters)}
				placeholder={props.placeholder}
				filters={props.filters}
			/>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 3px;
`;

const InputPlaceholder = styled(FilterInputPlaceholder)`
	margin-top: 0;
`;

export default StringsTypeahead;
