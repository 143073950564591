import { Spinner } from '@compstak/ui-kit';
import { formatDate } from 'format';
import { InfoRow, InfoValue } from 'pdf/UI';
import styled from 'styled-components';
import { PropertyComp } from 'types';
import { ExportPdfInput } from '../../../../pdf/ExportPdfInput';
import { LogoPicker } from '../../../../pdf/LogoPicker';
import { EditPropertyAddress } from '../EditPropertyAddress';
import { HideableLabel } from '../HideableLabel';
import { PropertyEditPage } from '../PropertyEditPage';
import { ExportPdfLabelsVisibleState, ExportPdfPageState } from '../types';
import {
	useMapImageUrl,
	useStreetViewImageUrl,
} from 'Pages/PropertyPageV3/hooks/mapImages';

type Props = {
	page: ExportPdfPageState;
	setPage: React.Dispatch<React.SetStateAction<ExportPdfPageState>>;
	property: PropertyComp;
	isStreetViewEnabled: boolean;
	isLoadingStreetView: boolean;
};

export const EditCoverPage = ({
	page,
	setPage,
	property,
	isStreetViewEnabled,
	isLoadingStreetView,
}: Props) => {
	const now = new Date();

	const { geoPoint } = property;

	const streetViewImageUrl = useStreetViewImageUrl({
		width: GOOGLE_STREETVIEW_SIZE.width,
		height: GOOGLE_STREETVIEW_SIZE.height,
		geoPoint,
	});
	const mapImageUrl = useMapImageUrl({
		width: GOOGLE_STREETVIEW_SIZE.width,
		height: GOOGLE_STREETVIEW_SIZE.height,
		geoPoint,
	});

	const onToggleLabel = (key: keyof ExportPdfLabelsVisibleState) => () => {
		setPage((s) => ({
			...s,
			labelsVisible: {
				...s.labelsVisible,
				[key]: !s.labelsVisible[key],
			},
		}));
	};

	return (
		<PropertyEditPage pageKey="cover">
			<DocumentInfo>
				<DocumentInfoLeft>
					{isLoadingStreetView ? (
						<Spinner />
					) : isStreetViewEnabled ? (
						<StreetViewImage
							width={GOOGLE_STREETVIEW_SIZE.width}
							height={GOOGLE_STREETVIEW_SIZE.height}
							src={streetViewImageUrl.href}
							alt="Google street view"
						/>
					) : (
						<img
							width={GOOGLE_STREETVIEW_SIZE.width}
							height={GOOGLE_STREETVIEW_SIZE.height}
							src={mapImageUrl.href}
							alt="Mapbox map"
						/>
					)}

					<EditPropertyAddress />
				</DocumentInfoLeft>
				<DocumentInfoRight>
					<InfoRow>
						<HideableLabel
							isVisible={page.labelsVisible.createdOn}
							onToggle={onToggleLabel('createdOn')}
						>
							Created on
						</HideableLabel>
						<HideableValue isVisible={page.labelsVisible.createdOn}>
							<InfoValue>
								{formatDate(now, 'MMMM D, YYYY [at] h:mm A')}
							</InfoValue>
						</HideableValue>
					</InfoRow>
					<InfoRow>
						<HideableLabel
							isVisible={page.labelsVisible.preparedFor}
							onToggle={onToggleLabel('preparedFor')}
						>
							Prepared for
						</HideableLabel>
						<HideableValue isVisible={page.labelsVisible.preparedFor}>
							<PreparedForContainer>
								<ExportPdfInput
									value={page.preparedFor}
									onChange={(e) =>
										setPage((s) => ({ ...s, preparedFor: e.target.value }))
									}
									placeholder="e.g. client's name"
								/>
								<LogoPicker
									value={page.preparedForLogo}
									onChange={(logo) => {
										setPage((s) => ({ ...s, preparedForLogo: logo }));
									}}
									text="+ Add your client's logo"
								/>
							</PreparedForContainer>
						</HideableValue>
					</InfoRow>
					<InfoRow>
						<HideableLabel
							isVisible={page.labelsVisible.preparedBy}
							onToggle={onToggleLabel('preparedBy')}
						>
							Prepared by
						</HideableLabel>
						<HideableValue isVisible={page.labelsVisible.preparedBy}>
							<ExportPdfInput
								value={page.preparedBy}
								onChange={(e) =>
									setPage((s) => ({ ...s, preparedBy: e.target.value }))
								}
							/>
						</HideableValue>
					</InfoRow>
					<InfoRow>
						<HideableLabel
							isVisible={page.labelsVisible.notes}
							onToggle={onToggleLabel('notes')}
						>
							Notes
						</HideableLabel>
						<HideableValue isVisible={page.labelsVisible.notes}>
							<ExportPdfInput
								value={page.notes}
								onChange={(e) =>
									setPage((s) => ({ ...s, notes: e.target.value }))
								}
								placeholder="Enter your comments..."
							/>
						</HideableValue>
					</InfoRow>
				</DocumentInfoRight>
			</DocumentInfo>
		</PropertyEditPage>
	);
};

const GOOGLE_STREETVIEW_SIZE = {
	width: 450,
	height: 300,
};

const DocumentInfo = styled.div`
	display: flex;
	gap: 2rem;
`;

const DocumentInfoLeft = styled.div`
	width: 45%;
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
`;

const StreetViewImage = styled.img`
	width: 100%;
	height: auto;
`;

const DocumentInfoRight = styled.div`
	width: 55%;
`;

const HideableValue = styled.div<{ isVisible: boolean }>`
	opacity: ${(p) => (p.isVisible ? 1 : 0.4)};
	pointer-events: ${(p) => (p.isVisible ? 'all' : 'none')};
`;

const PreparedForContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
`;
