import { ErrorMessage, MQB, Spinner, typography } from '@compstak/ui-kit';
import {
	SurveyQuestion,
	useAnswerQuestionMutation,
	useSurveyQuestionsQuery,
} from 'api/surveyForCredits/surveyQuestionQueries';
import { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'router';
import styled, { css } from 'styled-components';
import { SurveyDoneUi } from './components/SurveyDoneUi';
import {
	SingleQuestionFailed,
	surveyLetterSpacingCss,
} from './components/questionComponents';
import { QuestionSidebarBtns } from './components/questionSidebarComponents';
import {
	BuildingClassQuestionContent,
	BuildingClassQuestionSidebar,
} from './components/questions/buildingClassQuestion';
import {
	IndustrialQuestionContent,
	IndustrialQuestionSidebar,
} from './components/questions/industrialQuestionV1';
import {
	PropertySubtypeQuestionContent,
	PropertySubtypeQuestionSidebar,
} from './components/questions/propertySubtypeQuestion';
import { KnownQuestionType } from './constants/questionTypes';

export function SurveyForCreditsPage() {
	const {
		data: questions,
		isLoading: isLoadingQuestions,
		isError: isQuestionFetchFailed,
	} = useSurveyQuestionsQuery();

	const currentQuestion = questions
		? firstUnansweredQuestion(questions)
		: undefined;
	if (!isLoadingQuestions && questions && !currentQuestion) {
		return <SurveyDoneUi />;
	}

	const sidebarContent = isLoadingQuestions ? (
		<WithDisabledBtns>
			<Spinner isCentered />
		</WithDisabledBtns>
	) : isQuestionFetchFailed || !currentQuestion ? (
		<WithDisabledBtns>
			<AllQuestionsFailed />
		</WithDisabledBtns>
	) : (
		<SafeSidebarContentForQuestion question={currentQuestion} />
	);

	const content = isLoadingQuestions ? (
		<Spinner isCentered />
	) : isQuestionFetchFailed || !currentQuestion ? (
		<>
			<AllQuestionsFailed />
			<div style={{ fontWeight: 400, marginTop: 32 }}>
				<Link to="/home">Go back to home.</Link>
			</div>
		</>
	) : (
		<SafeMainContentForQuestion question={currentQuestion} />
	);

	return (
		<PageContainer>
			<QuestionSidebar>{sidebarContent}</QuestionSidebar>
			<QuestionContentContainer>{content}</QuestionContentContainer>
		</PageContainer>
	);
}

function firstUnansweredQuestion(questions: SurveyQuestion[]) {
	return questions.find((q) => !q.done);
}

function SafeSidebarContentForQuestion({
	question,
}: Parameters<typeof SidebarContentForQuestion>[0]) {
	return (
		<ErrorBoundary
			key={question?.id}
			FallbackComponent={() => (
				<WithAbandonQuestionButtons {...{ question }}>
					<SingleQuestionFailed />
				</WithAbandonQuestionButtons>
			)}
		>
			<SidebarContentForQuestion {...{ question }} />
		</ErrorBoundary>
	);
}

function SidebarContentForQuestion({
	question,
}: {
	question: SurveyQuestion;
}): JSX.Element {
	const qtype = question.type as KnownQuestionType;
	switch (qtype) {
		case 'buildingClass-v1':
			return <BuildingClassQuestionSidebar {...{ question }} />;
		case 'propertySubtype-v1':
			return <PropertySubtypeQuestionSidebar {...{ question }} />;
		case 'industrialAttributes-v1':
			return <IndustrialQuestionSidebar {...{ question }} />;
		default:
			return (
				<WithAbandonQuestionButtons {...{ question }}>
					<UknownQuestionType />
				</WithAbandonQuestionButtons>
			);
	}
}

function SafeMainContentForQuestion({
	question,
}: Parameters<typeof MainContentForQuestion>[0]) {
	return (
		<ErrorBoundary
			key={question?.id}
			FallbackComponent={() => <SingleQuestionFailed />}
		>
			<MainContentForQuestion {...{ question }} />
		</ErrorBoundary>
	);
}

function MainContentForQuestion({
	question,
}: {
	question: SurveyQuestion;
}): JSX.Element {
	const qtype = question.type as KnownQuestionType;
	switch (qtype) {
		case 'buildingClass-v1':
			return <BuildingClassQuestionContent {...{ question }} />;
		case 'propertySubtype-v1':
			return <PropertySubtypeQuestionContent {...{ question }} />;
		case 'industrialAttributes-v1':
			return <IndustrialQuestionContent {...{ question }} />;
		default:
			return <UknownQuestionType />;
	}
}

function WithDisabledBtns({ children }: { children: React.ReactNode }) {
	return (
		<>
			{children}
			<QuestionSidebarBtns
				onSubmit={() => null}
				submitEnabled={false}
				onSkip={() => null}
				skipDisabled
			/>
		</>
	);
}

function WithAbandonQuestionButtons({
	children,
	question,
}: {
	children: React.ReactNode;
	question: SurveyQuestion | undefined;
}) {
	const { mutateAsync: answer } = useAnswerQuestionMutation();
	const questionedAtRef = useRef(new Date());
	return (
		<>
			{children}
			<QuestionSidebarBtns
				onSubmit={() => null}
				onSkip={async () => {
					if (question) {
						await answer({
							questionId: question.id,
							questionType: question.type,
							abandon: true,
							questionedAt: questionedAtRef.current,
						});
					}
				}}
				submitEnabled={false}
			/>
		</>
	);
}

function AllQuestionsFailed() {
	return <ErrorMessage>Something went wrong</ErrorMessage>;
}

const UknownQuestionType = SingleQuestionFailed;

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-family: ${typography.fontFamily.gotham};
	position: absolute;
	inset: 0;
	height: 100%;
	overflow: auto;
	${surveyLetterSpacingCss}

	@media (min-width: ${MQB.T_834}) {
		flex-direction: row;
	}
`;

const screenHeightCss = css`
	height: fit-content;
	@media (min-width: ${MQB.T_834}) {
		min-height: 480px;
		height: 100%;
		overflow: auto;
	}
`;

const QuestionSidebar = styled.div`
	${screenHeightCss}
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: white;
	flex-grow: 1;
	flex-basis: 1px;
	padding: 48px 24px 24px 24px;

	@media (min-width: ${MQB.T_834}) {
		padding: 48px 24px 24px 48px;
		min-width: 360px;
		max-width: 480px;
	}
`;
QuestionSidebar.displayName = 'QuestionSidebar';

const QuestionContentContainer = styled.div`
	${screenHeightCss}
	background-color: ${(props) => props.theme.colors.gray.gray200};
	flex-grow: 4;
	flex-basis: 1px;
	width: 100%;
	min-width: 400px;
	padding: 48px 0px 48px 24px;

	@media (min-width: ${MQB.T_834}) {
		padding: 48px;
	}
`;
