import { Chip } from '@compstak/ui-kit';
import styled, { css } from 'styled-components';
import MapMarkerNoShadowOriginal from 'ui/svg_icons/map_marker_no_shadow.svg';
import SearchIconOriginal from 'ui/svg_icons/search.svg';
import ErrorIconOriginal from 'ui/svg_icons/error.svg';
import AssetTypeIconOriginal from 'ui/svg_icons/asset_type.svg';

export const SuggestionSearchIcon = () => {
	return <SearchIcon viewBox="-3 -3 24 24" />;
};

export const SuggestionMapMarkerIcon = () => {
	return <MapMarketNoShadow />;
};

export const SuggestionAssetTypeIcon = () => {
	return <AssetTypeIcon />;
};

export const SuggestionErrorIcon = () => {
	return <ErrorIcon viewBox="-2 -2 24 24" />;
};

export const ChipStyled = styled(Chip)`
	margin: 0 0.5rem;
	color: ${({ theme }) => theme.colors.gray.gray700};
	background-color: ${({ theme }) => theme.colors.gray.gray700Transparent15};
	font-size: ${({ theme }) => theme.typography.ordinaryFontSize};
	font-weight: 300;
`;

const iconCommonCss = css`
	flex-shrink: 0;
	width: 24px;
	height: 24px;
`;

const ErrorIcon = styled(ErrorIconOriginal)`
	${iconCommonCss}
	fill: ${({ theme }) => theme.colors.white.white};
`;

const MapMarketNoShadow = styled(MapMarkerNoShadowOriginal)`
	${iconCommonCss}
	fill: ${({ theme }) => theme.colors.blue.blue400};
`;

const SearchIcon = styled(SearchIconOriginal)`
	${iconCommonCss}
	fill: ${({ theme }) => theme.colors.gray.gray800};
`;

const AssetTypeIcon = styled(AssetTypeIconOriginal)`
	${iconCommonCss}
	fill: ${({ theme }) => theme.colors.gray.gray800};
`;
