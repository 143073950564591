import { SearchTable, SearchTableProps } from 'Components';
import {
	LeaseAndSaleAveragesResponse,
	SearchLeasesResponse,
	useSearchFilterParams,
	useSearchLeasesInfiniteQuery,
	useSearchTableAverages,
} from 'api';
import { useLeasesSalesAveragesFormatting } from 'hooks/useLeasesSalesAveragesFormatting';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { filtersToQueryString } from 'models/filters/util';
import { useCallback } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import { LeaseComp } from 'types';
import { ExportOrUnlockButton } from '../ExportOrUnlockButton';
import { useSearchTableRows } from '../useSearchTableRows';
import { useSearchTableSort } from '../useSearchTableSort';

type Props = Partial<SearchTableProps<LeaseComp>>;

export const LeasesSearchTable = (props: Props) => {
	const [filters] = useFilters();

	const params = useSearchFilterParams({ filters });

	const {
		data: leasesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchLeasesInfiniteQuery(params);

	const totalCount = leasesData?.pages[0].totalCount ?? 0;
	const searchAverages = leasesData?.pages[0].averages;

	const rows = useSearchTableRows<LeaseComp>({
		data: leasesData,
		getRows: useCallback((page: SearchLeasesResponse) => page.comps, []),
		isFetching,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'lease',
		searchAverages,
		filters,
	});

	const averagesFormatting = useLeasesSalesAveragesFormatting({
		averages: averages as LeaseAndSaleAveragesResponse,
		filters,
	});

	const selectionProps = useSearchTableSelection({
		compType: 'lease',
		totalCount,
		filters,
	});

	const sortProps = useSearchTableSort();

	return (
		<SearchTable
			{...props}
			{...selectionProps}
			{...sortProps}
			rows={rows}
			averages={averagesFormatting}
			getRowId={(row) => row.id}
			noData={!isFetching && totalCount === 0}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="lease"
			renderActionButton={(row) => (
				<ExportOrUnlockButton type="lease" row={row} />
			)}
			getBodyCellProps={(row, rowIndex) => ({
				to: routes.leaseById.toHref({ id: row.id }),
				state: {
					index: rowIndex,
					queryString: filtersToQueryString(filters),
				},
			})}
		/>
	);
};
