import {
	SelectListItem,
	OpenButton,
	Select,
	SelectProps,
	SelectedOption,
	Placeholder,
	OptionsList,
	OptionButton,
	DropdownListItemStyled,
	HeaderStyled,
	NoResultContainer,
} from '@compstak/ui-kit';
import styled from 'styled-components';
import { filterInputCSS } from './FilterInput';

export const FilterSelect = <T extends SelectListItem = SelectListItem>(
	props: SelectProps<T>
) => {
	return (
		<SelectWrapper>
			<Select {...props} />
		</SelectWrapper>
	);
};

const SelectWrapper = styled.div`
	margin-top: 3px;
	font-size: 13px;
	${OpenButton} {
		height: 42px;
		border: 1px solid ${({ theme }) => theme.colors.gray.gray500};
		border-radius: 2px;
		background-color: inherit;
		font-size: 13px;
		padding: 0 0.5rem 0 0.5rem;

		&:focus {
			outline: none;
		}
	}

	${SelectedOption} {
		font-weight: unset;

		&:has(${Placeholder}) {
			font-size: 13px;
			padding: 0;
		}

		&:not(:has(${Placeholder})) {
			font-size: 0.75rem;
			line-height: 1rem;
			background-color: ${({ theme }) => theme.colors.neutral.n30};
			border-radius: 20px;
		}

		${Placeholder} {
			color: ${({ theme }) => theme.colors.gray.n70};
			font-weight: unset;
		}
	}

	${OptionsList} {
		background-color: ${({ theme }) => theme.colors.gray.gray700};
		box-shadow:
			${({ theme }) => theme.colors.gray.gray800} 0px 3px 5px,
			${({ theme }) => theme.colors.gray.gray800} 0px 0px 1px;

		${HeaderStyled} {
			padding-left: 10px;
		}

		${DropdownListItemStyled} {
			&[aria-selected='true'] {
				background-color: ${({ theme }) =>
					theme.colors.gray.gray600} !important;
			}

			${OptionButton} {
				color: ${({ theme }) => theme.colors.white.white};
				padding-left: 10px;

				&:hover {
					background-color: ${({ theme }) => theme.colors.gray.gray600};
				}
			}
		}

		${NoResultContainer} {
			color: ${({ theme }) => theme.colors.white.white};
			margin: 10px;
		}
	}

	input {
		${filterInputCSS};
		margin-top: unset;
		min-height: unset;
		font-weight: unset;
		letter-spacing: unset;
	}
`;
