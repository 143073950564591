import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';

import { useTopTenantIndustriesQuery } from 'api';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import React, { useCallback } from 'react';
import { SpaceTypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceTypeFilter';
import { SpaceSubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceSubtypeFilter';
import { FilterFieldButton } from 'Components/Filters/Base/Filter/FilterFieldButton';
import { FilterCategory } from './FilterCategories/FilterCategory';
import { LocationFilterCategory } from './FilterCategories/LocationFilterCategory';
import { CompFilterCategory } from './FilterCategories';
import { BuildingFilterCategoryCommonFilters } from './FilterCategories/BuildingFilterCategoryCommonFilters';
import { FloorsFilter } from 'Components/Filters/Fields/FloorsFilter/FloorsFilter';
import { CommonlyUsedLeaseFilters } from './FilterCategories/CommonlyUsedLeaseFilters';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { TransactionTypeFilter } from 'Components/Filters/Fields/TransactionTypeFilter/TransactionTypeFilter';
import { StartingRentFilter } from 'Components/Filters/Fields/StartingRentFilter/StartingRentFilter';
import { EffectiveRentFilter } from 'Components/Filters/Fields/EffectiveRentFilter/EffectiveRentFilter';
import { LeaseTypeFilter } from 'Components/Filters/Fields/LeaseTypeFilter/LeaseTypeFilter';
import { SubleaseFilter } from 'Components/Filters/Fields/SubleaseFilter/SubleaseFilter';
import { YearRenovatedFilter } from 'Components/Filters/Fields/YearRenovatedFilter/YearRenovatedFilter';
import { LeaseTermFilter } from 'Components/Filters/Fields/LeaseTermFilter/LeaseTermFilter';
import { ExecutionDateFilter } from 'Components/Filters/Fields/ExecutionDateFilter/ExecutionDateFilter';
import { ExpirationDateFilter } from 'Components/Filters/Fields/ExpirationDateFilter/ExpirationDateFilter';
import { BrokerageFilter } from 'Components/Filters/Fields/BrokerageFilter/BrokerageFilter';
import { TenantFilter } from 'Components/Filters/Fields/TenantFilter/TenantFilter';
import { TenantIndustryFilter } from 'Components/Filters/Fields/TenantIndustryFilter/TenantIndustryFilter';
import { TenantRevenueFilter } from 'Components/Filters/Fields/TenantRevenueFilter/TenantRevenueFilter';
import { TenantEmployeesFilter } from 'Components/Filters/Fields/TenantEmployeesFilter/TenantEmployeesFilter';
import { TenantOwnershipFilter } from 'Components/Filters/Fields/TenantOwnershipFilter/TenantOwnershipFilter';
import { TenantTickerFilter } from 'Components/Filters/Fields/TenantTickerFilter/TenantTickerFilter';
import { TenantStatusFilter } from 'Components/Filters/Fields/TenantStatusFilter/TenantStatusFilter';
import { LandlordFilter } from 'Components/Filters/Fields/LandlordFilter/LandlordFilter';

export const LeaseFilterFields = () => {
	const { context, filters, onFilterChange } = useFilterFieldContext();

	const {
		data: topTenantIndustriesData,
		isLoading: isLoadingTopTenantIndustriesData,
	} = useTopTenantIndustriesQuery({
		filters: { markets: getFiltersMarkets(filters) },
	});

	const handleTopInMarket = useCallback(() => {
		if (!topTenantIndustriesData) {
			return;
		}

		onFilterChange({
			tenantIndustry: topTenantIndustriesData.map(
				(tenantIndustry) => tenantIndustry.industry
			),
		});
	}, [topTenantIndustriesData, onFilterChange]);

	return (
		<>
			{context !== 'analytics' && <CommonlyUsedLeaseFilters />}

			<LocationFilterCategory />

			<FilterCategory category="Space & Lease">
				{context !== 'analytics' ? (
					<SpaceTypeFilter>
						<SpaceSubtypeFilter />
					</SpaceTypeFilter>
				) : (
					<SpaceSubtypeFilter />
				)}
				<FloorsFilter />
				<TransactionSizeFilter />
				<TransactionTypeFilter />
				<StartingRentFilter />
				<LeaseTypeFilter />
				<EffectiveRentFilter />
				<SubleaseFilter />
				<LeaseTermFilter />
				{context !== 'analytics' && <ExecutionDateFilter />}
				{context !== 'analytics' && <ExpirationDateFilter />}
			</FilterCategory>

			<FilterCategory category="Building">
				<BuildingFilterCategoryCommonFilters />
				<YearRenovatedFilter />
			</FilterCategory>

			<FilterCategory category="Tenant & Landlord">
				<TenantFilter />
				<TenantIndustryFilter>
					<FilterFieldButton
						disabled={isLoadingTopTenantIndustriesData}
						onClick={handleTopInMarket}
					>
						Select Top 10 in Market
					</FilterFieldButton>
				</TenantIndustryFilter>
				<TenantRevenueFilter />
				<TenantEmployeesFilter />
				<TenantOwnershipFilter>
					{filters.insideviewOwnershipId === 5 && <TenantTickerFilter />}
				</TenantOwnershipFilter>
				<TenantStatusFilter />
				<LandlordFilter />
				<BrokerageFilter />
			</FilterCategory>

			<CompFilterCategory />
		</>
	);
};
