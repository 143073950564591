import geoPointsService from 'services/geopoints';
import leaseSearchService from 'services/leaseSearch';
import propertySearchService from 'services/propertySearch';
import saleSearchService from 'services/saleSearch';

import { Market } from '@compstak/common';
import { CompType } from 'types/comp';
import { change, reset } from './filters';

import { PermissionsState } from 'Pages/Login/reducers';
import { Dispatch } from 'redux';
import { AppDispatch, AppGetState } from 'store';
import { FiltersObject } from 'models/filters/types';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { getNearbyCompsFilters } from 'api/utils';
import { isMultifamily } from 'util/isMultifamily';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { ActiveMapControl } from 'types';

export const CREATE_LEASE_SEARCH = 'CREATE_LEASE_SEARCH' as const;
export const CREATE_SALE_SEARCH = 'CREATE_SALE_SEARCH' as const;
export const CREATE_PROPERTY_SEARCH = 'CREATE_PROPERTY_SEARCH' as const;
export const SELECT_MAP_CONTROL = 'SELECT_MAP_CONTROL' as const;
export const DESELECT_MAP_CONTROL = 'DESELECT_MAP_CONTROL' as const;
export const LOAD_SUBMARKETS = 'LOAD_SUBMARKETS' as const;
export const REMOVE_SUBMARKETS = 'REMOVE_SUBMARKETS' as const;
export const COLLAPSE_SUBMARKETS_LIST = 'COLLAPSE_SUBMARKETS_LIST' as const;
export const SET_MY_COMPS = 'SET_MY_COMPS' as const;
export const FOCUS_TYPEAHEAD = 'FOCUS_TYPEAHEAD' as const;

export enum MAP_CONTROLS {
	SEARCH_WITHIN_VIEW = 1,
	RADIUS = 2,
	POLYGON = 3,
	SUBMARKETS = 4,
	OPPORTUNITY_ZONES = 5,
}

const MAP_FILTERS_MAP = {
	[MAP_CONTROLS.SEARCH_WITHIN_VIEW]: 'polygon',
	[MAP_CONTROLS.RADIUS]: 'radius',
	[MAP_CONTROLS.POLYGON]: 'polygon',
	[MAP_CONTROLS.SUBMARKETS]: 'submarkets',
	[MAP_CONTROLS.OPPORTUNITY_ZONES]: 'opportunityZoneId',
};

const resetMapFilter = (
	activeMapControl: MAP_CONTROLS,
	dispatch: AppDispatch
) => {
	dispatch(change('main', { [MAP_FILTERS_MAP[activeMapControl]]: null }));
};

function selectMapControlInternal(id: ActiveMapControl, silent = false) {
	return {
		type: SELECT_MAP_CONTROL,
		payload: { id, silent },
	};
}

export function selectMapControl(id: ActiveMapControl, silent = false) {
	return (dispatch: AppDispatch, getState: AppGetState) => {
		const activeMapControl = getState().searchReducer.activeMapControl;
		if (id === activeMapControl) {
			return;
		}

		if (activeMapControl) {
			resetMapFilter(activeMapControl, dispatch);
		}

		dispatch(selectMapControlInternal(id, silent));
	};
}

function deselectMapControlInternal(silent = false) {
	return {
		type: DESELECT_MAP_CONTROL,
		payload: {
			silent,
		},
	};
}

export function deselectMapControl(silent = false) {
	return (dispatch: AppDispatch, getState: AppGetState) => {
		const activeMapControl = getState().searchReducer.activeMapControl;

		if (activeMapControl) {
			resetMapFilter(activeMapControl, dispatch);
		}

		dispatch(deselectMapControlInternal(silent));
	};
}

let searchId = 1;
export function createLeaseSearch(filters: FiltersObject) {
	const searchableFilters = filtersToServerJSON(filters);

	async function performSearch() {
		const searchPromise = leaseSearchService.create(
			searchableFilters,
			100,
			filters.sortDirection,
			filters.sortField
		);

		// @ts-expect-error TS2339: Property 'then' does not exist...
		const averagesPromise = searchPromise.then((search) => search.averages);

		return Promise.all([searchPromise, averagesPromise]).then(
			([search, averages]) => {
				return {
					search,
					leases: search.leases,
					averages,
				};
			}
		);
	}

	return {
		type: CREATE_LEASE_SEARCH,
		meta: {
			searchId: searchId++,
			filters,
			sortDirection: filters.sortDirection,
			sortField: filters.sortField,
			feedback: {
				duration: 0,
				id: 'map-no-results',
				pending: function () {
					return false;
				},
				// @ts-expect-error TS7006: Parameter 'response' implicitl...
				fulfilled: function (response) {
					if (response.leases.length === 0) {
						return 'No results found. Try removing some filters or expanding the area of your search.';
					} else {
						return false;
					}
				},
			},
			freshStore: false,
		},
		payload: {
			promise: performSearch(),
		},
	};
}

export function createSaleSearch(filters: FiltersObject) {
	const searchableFilters = filtersToServerJSON(filters);

	function performSearch() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'create' does not exist on type '{}'.
		const searchPromise = saleSearchService.create(
			searchableFilters,
			100,
			filters.sortDirection,
			filters.sortField
		);

		// @ts-expect-error TS7006: Parameter 'search' implicitly ...
		const averagesPromise = searchPromise.then((search) => search.averages);

		return Promise.all([searchPromise, averagesPromise]).then(
			([search, averages]) => {
				return {
					search,
					sales: search.sales,
					averages,
				};
			}
		);
	}

	return {
		type: CREATE_SALE_SEARCH,
		meta: {
			searchId: searchId++,
			filters,
			sortDirection: filters.sortDirection,
			sortField: filters.sortField,
			feedback: {
				duration: 0,
				id: 'map-no-results',
				pending: function () {
					return false;
				},
				// @ts-expect-error TS7006: Parameter 'response' implicitl...
				fulfilled: function (response) {
					if (response.sales.length === 0) {
						return 'No results found. Try removing some filters or expanding the area of your search.';
					} else {
						return false;
					}
				},
			},
			freshStore: false,
		},
		payload: {
			promise: performSearch(),
		},
	};
}

export function createPropertySearch(
	filters: FiltersObject,
	permissions: PermissionsState
) {
	const useMultifamily = isMultifamily({
		markets: getFiltersMarkets(filters),
		permissions,
	});

	const searchableFilters = filtersToServerJSON(filters, true);

	function performSearch() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'create' does not exist on type '{}'.
		const searchPromise = propertySearchService.create(
			searchableFilters,
			100,
			filters.sortDirection,
			filters.sortField,
			useMultifamily
		);

		// @ts-expect-error TS7006: Parameter 'search' implicitly ...
		const averagesPromise = searchPromise.then((search) => search.averages);

		return Promise.all([searchPromise, averagesPromise]).then(
			([search, averages]) => {
				return {
					search,
					properties: search.properties,
					averages,
				};
			}
		);
	}

	return {
		type: CREATE_PROPERTY_SEARCH,
		meta: {
			searchId: searchId++,
			filters,
			sortDirection: filters.sortDirection,
			sortField: filters.sortField,
			feedback: {
				duration: 0,
				id: 'map-no-results',
				pending: function () {
					return false;
				},
				// @ts-expect-error TS7006: Parameter 'response' implicitl...
				fulfilled: function (response) {
					if (response.properties.length === 0) {
						return 'No results found. Try removing some filters or expanding the area of your search.';
					} else {
						return false;
					}
				},
			},
			freshStore: false,
		},
		payload: {
			promise: performSearch(),
		},
	};
}

type CreateSearchArgs = {
	filters: FiltersObject;
	compType: CompType;
};

export const createSearch =
	({ compType, filters }: CreateSearchArgs) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		switch (compType) {
			case 'sale':
				return dispatch(createSaleSearch(filters));
			case 'lease':
				return dispatch(createLeaseSearch(filters));
			case 'property': {
				const store = getState();
				const permissions = store.permissions;

				return dispatch(createPropertySearch(filters, permissions));
			}
		}
	};

const loadSubmarketsInternal = (marketIds: number[]) => {
	return {
		type: LOAD_SUBMARKETS,
		meta: {
			marketIds,
		},
		payload: {
			promise: geoPointsService.loadMany(
				marketIds.map((marketId) => ({ marketId }))
			),
		},
	};
};

export const loadSubmarkets =
	(marketOrMarkets: Market | Market[]) => (dispatch: AppDispatch) => {
		const marketIds = Array.isArray(marketOrMarkets)
			? marketOrMarkets.map((market) => market.id)
			: [marketOrMarkets.id];

		dispatch(loadSubmarketsInternal(marketIds));
	};

export function removeSubmarkets() {
	return {
		type: REMOVE_SUBMARKETS,
	};
}

export function collapseSubmarketsList(collaps: boolean) {
	return {
		type: COLLAPSE_SUBMARKETS_LIST,
		payload: collaps,
	};
}

export function setMyComps(isMyComps: boolean) {
	return {
		type: SET_MY_COMPS,
		payload: isMyComps,
	};
}

export function focusTypeahead() {
	return {
		type: FOCUS_TYPEAHEAD,
	};
}

type ExpanderParams = {
	compType: CompType;
	lat: number;
	lon: number;
	filters: FiltersObject;
	minNumberOfLeases?: number;
	minNumberOfProperties?: number;
};
export const radiusExpanderSearch =
	({
		compType,
		lat,
		lon,
		filters,
		minNumberOfLeases,
		minNumberOfProperties,
	}: ExpanderParams) =>
	async (dispatch: Dispatch) => {
		const filtersWithRadius = await getNearbyCompsFilters({
			compType,
			latitude: lat,
			longitude: lon,
			filters,
			minNumberOfLeases,
			minNumberOfProperties,
		});
		dispatch(reset('main', filtersWithRadius));
	};

export type SearchAction =
	| ReturnType<typeof selectMapControlInternal>
	| ReturnType<typeof deselectMapControlInternal>
	| ReduxPromiseAction<ReturnType<typeof createLeaseSearch>>
	| ReduxPromiseAction<ReturnType<typeof createSaleSearch>>
	| ReduxPromiseAction<ReturnType<typeof createPropertySearch>>
	| ReduxPromiseAction<ReturnType<typeof loadSubmarketsInternal>>
	| ReturnType<typeof removeSubmarkets>
	| ReturnType<typeof collapseSubmarketsList>
	| ReturnType<typeof setMyComps>
	| ReturnType<typeof focusTypeahead>;

export const searchActions = {
	selectMapControl,
	deselectMapControl,
	createLeaseSearch,
	createSaleSearch,
	createPropertySearch,
	createSearch,
	loadSubmarkets,
	removeSubmarkets,
	collapseSubmarketsList,
	setMyComps,
	focusTypeahead,
	radiusExpanderSearch,
};

export type SearchActions = typeof searchActions;
