import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

import { NewProjectSuccessModal } from './NewProjectSuccessModal';

import { useAppSelector } from 'util/useAppSelector';
import { usePrevious } from 'util/hooks';
import { useDispatch } from 'react-redux';
import { ProjectDTO, saveProject } from '../../actions';
import { useMarkets } from 'hooks/useMarkets';
import { useModal } from 'providers/ModalProvider';
import { trimString } from 'util/trimString';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

export const NewProjectModal = () => {
	const { projects, lastUpdated } = useAppSelector(
		(store) => store.analyticsProjects
	);
	const markets = useMarkets();

	const [name, setName] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const [newProject, setNewProject] = useState<ProjectDTO | null>(null);
	const [errorMessage, setErrorMessage] = useState('');
	const prevLastUpdated = usePrevious(lastUpdated);

	const dispatch = useDispatch();
	const { closeModal } = useModal();

	const updateName = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length <= 75) {
			setName(event.target.value);
			setErrorMessage('');
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			onSaveProject();
		}
	};

	const trimmedName = trimString(name);

	const onSaveProject = () => {
		if (trimmedName) {
			if (projects?.find((p) => p.name === trimmedName)) {
				setErrorMessage(
					`The project ${trimmedName} already exists. Enter a new name to continue.`
				);
			} else {
				const newProject: ProjectDTO = {
					name: trimmedName,
					canDelete: true,
					charts: [],
				};
				dispatch(saveProject(newProject, markets));
				setIsSaving(true);
				setNewProject(newProject);
			}
		}
	};

	if (prevLastUpdated !== lastUpdated && newProject) {
		return <NewProjectSuccessModal project={newProject} onClose={closeModal} />;
	}

	return (
		<>
			<ModalTitle>Create New Project</ModalTitle>
			<ModalParagraph>
				Enter a project name for the new (empty) project that you want to add to
				your repository
			</ModalParagraph>
			<form
				onSubmit={(ev) => {
					ev.preventDefault();
					onSaveProject();
				}}
			>
				<ModalInputLabel isError={!!errorMessage}>
					Project Name
					<input
						type="text"
						value={name}
						onChange={updateName}
						onKeyDown={handleKeyDown}
					/>
					{!!errorMessage && <div>{errorMessage}</div>}
				</ModalInputLabel>
				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeModal}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={!trimmedName}
						isLoading={isSaving}
					>
						Create
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};
