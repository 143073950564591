import { useMapBoundingBox } from '@compstak/maps';
import bboxPolygon from '@turf/bbox-polygon';
import { useEffect } from 'react';
import { useFilters } from 'reducers/filtersReducer';

export const SearchWithinView = () => {
	const [, setFilters] = useFilters();
	const bbox = useMapBoundingBox();

	useEffect(() => {
		if (!bbox) return;

		const polygon = bboxPolygon(bbox);

		setFilters({
			polygon: polygon.geometry.coordinates[0].map(([lng, lat]) => {
				return { lat, lng };
			}),
		});
	}, [setFilters, bbox]);

	return null;
};
