import { Text } from '@react-pdf/renderer';
import { CherreLoan, TreppLoan } from 'api';
import { useMemo } from 'react';
import { pdfStyle } from '../style';
import { PdfColumn } from '../types';
import { PrivateLoansTablePdf } from './PrivateLoansTablePdf';
import { PublicLoansTablePdf } from './PublicLoansTablePdf';

type Props = {
	loan: TreppLoan | undefined;
	editablePublicLoansRow: Record<string, string | number>[] | undefined;
	columns: PdfColumn<Record<keyof CherreLoan, string | number>, CherreLoan>[];
};

export const LoansPage = (props: Props) => {
	const { loan, editablePublicLoansRow, columns } = props;

	const visibleColumns = useMemo(
		() => columns.filter((c) => c.isVisible),
		[columns]
	);

	return (
		<>
			<Text style={pdfStyle.pageTitle}>Property Loan Data</Text>
			{loan && <PrivateLoansTablePdf loan={loan} />}

			{editablePublicLoansRow && editablePublicLoansRow?.length > 0 && (
				<PublicLoansTablePdf
					loan={loan}
					visibleColumns={visibleColumns}
					editablePublicLoansRow={editablePublicLoansRow}
				/>
			)}
		</>
	);
};
