import styled from 'styled-components';

export const Card = styled.div`
	border: 1px solid ${(p) => p.theme.colors.neutral.n20};
	border-radius: 0.125rem;
	min-height: 80px;
	min-width: 140px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
