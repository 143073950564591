import { useLayoutContext } from 'Components';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { useUnlockConditions } from 'api';
import { useDispatch } from 'react-redux';
import { LeaseComp, SalesComp } from 'types';
import { UnlockActionButton } from '../UI';
import { showGetCreditsFeedback } from './showGetCreditsFeedback';
import { useIsRowUnlocking } from './useIsRowUnlocking';
import { useUnlockComp } from './useUnlockComp';

type Props = {
	type: 'lease' | 'sale';
	row: LeaseComp | SalesComp;
};

export const SearchTableUnlockButton = ({ type, row }: Props) => {
	const { isCompact } = useLayoutContext();
	const { mutate, isLoading } = useUnlockComp(type);
	const { totalCost, hasEnoughPoints, canUnlockForFree } = useUnlockConditions({
		comps: [row],
	});
	const isRowUnlocking = useIsRowUnlocking({ id: row.id, type });
	const dispatch = useDispatch();

	return (
		<UnlockActionButton
			size="m"
			variant="success"
			icon={<LockIconStyled />}
			data-qa-id="unlock-btn"
			isDisabled={isLoading || !hasEnoughPoints || isRowUnlocking}
			isLoading={isLoading || isRowUnlocking}
			onClick={() => {
				if (!hasEnoughPoints) {
					return dispatch(showGetCreditsFeedback(row.id));
				}
				if (isLoading || isRowUnlocking) return;
				// @ts-expect-error react query tripping
				mutate({ ids: [row.id] });
			}}
		>
			<span>|</span>
			<span>{canUnlockForFree ? 'Free' : totalCost}</span>
			{!isCompact && (
				<>
					<span>|</span>
					<span>Unlock</span>
				</>
			)}
		</UnlockActionButton>
	);
};
