import { DataSetType } from '../analytics';
import {
	ATTR_TO_PLOT_DEFAULT_COMMERCIAL,
	ATTR_TO_PLOT_DEFAULT_MUFA,
	ATTR_TO_PLOT_DEFAULT_SALES,
} from './Components/Modals/DataSets/dataSetConstants';
import { DataSetGroupTitles } from '../analytics';
import { AttributeType as AttributeTypeFormatting } from 'util/comp-format/attributes/index';

export const DEFAULT_NEW_CHART_TITLE = 'New Chart';

export const DATASET_TYPES_URL_SEARCH_PARAM_KEY = 'dataset-types';

// TODO: See why this is the max value? Is it because of colors?!
export const MAX_DATASETS_ALLOWED = 10;

export const DEFAULT_CHART = {
	title: DEFAULT_NEW_CHART_TITLE,
	dataSets: [],
	trendMonths: 3,
	chartType: 'line' as const,
	timespan: 60,
};

export const dataSetTypeToSeriesMap = {
	[DataSetType.COMMERCIAL]: ATTR_TO_PLOT_DEFAULT_COMMERCIAL,
	[DataSetType.LEASES]: ATTR_TO_PLOT_DEFAULT_COMMERCIAL,
	[DataSetType.MUFA]: ATTR_TO_PLOT_DEFAULT_MUFA,
	[DataSetType.SALES]: ATTR_TO_PLOT_DEFAULT_SALES,
};

export const dataSetGroupHeadingMap = {
	[DataSetType.COMMERCIAL]: DataSetGroupTitles.LEASES, // Until BE's switch to "commercialLeases"
	[DataSetType.LEASES]: DataSetGroupTitles.LEASES,
	[DataSetType.SALES]: DataSetGroupTitles.SALES,
	[DataSetType.MUFA]: DataSetGroupTitles.MUFA,
};

export enum AttributeToPlotMufaValues {
	ASKING_RPSF = 'askingRpsf',
	EFFECTIVE_RPSF = 'effectiveRpsf',
	CONCESSION = 'concessionValue',
	OCCUPANCY = 'occupancy',
}

export enum AttributeToPlotCommercialValues {
	STARTING_RENT = 'startingRent',
	EFFECTIVE_RENT = 'effectiveRent',
	FREE_RENT = 'freeMonths',
	TENANT_IMPROVEMENT = 'ti',
	LEASE_TERM = 'leaseTerm',
}

export enum SalesAttributeToPlotValues {
	SALES_PRICE = 'salePricePsf',
	SALES_VOLUME = 'totalSalePrice',
	CAP_RATE = 'capRate',
}

export const attributeToDataSetType = {
	[AttributeToPlotCommercialValues.STARTING_RENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.EFFECTIVE_RENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.FREE_RENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.TENANT_IMPROVEMENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.LEASE_TERM]: DataSetType.COMMERCIAL,

	[SalesAttributeToPlotValues.SALES_PRICE]: DataSetType.SALES,
	[SalesAttributeToPlotValues.SALES_VOLUME]: DataSetType.SALES,
	[SalesAttributeToPlotValues.CAP_RATE]: DataSetType.SALES,

	[AttributeToPlotMufaValues.ASKING_RPSF]: DataSetType.MUFA,
	[AttributeToPlotMufaValues.EFFECTIVE_RPSF]: DataSetType.MUFA,
	[AttributeToPlotMufaValues.CONCESSION]: DataSetType.MUFA,
	[AttributeToPlotMufaValues.OCCUPANCY]: DataSetType.MUFA,
};

export const dataSetTypeToFormattingAttr: Record<
	DataSetType,
	AttributeTypeFormatting
> = {
	[DataSetType.COMMERCIAL]: 'lease',
	[DataSetType.LEASES]: 'lease',
	[DataSetType.SALES]: 'sale',
	[DataSetType.MUFA]: 'property',
};

export const ATTRIBUTE_TO_PLOT_VALUES_COMMERCIAL_SPECIFIC = {
	[AttributeToPlotCommercialValues.FREE_RENT]: true,
	[AttributeToPlotCommercialValues.TENANT_IMPROVEMENT]: true,
	[AttributeToPlotCommercialValues.LEASE_TERM]: true,
};

export const ATTRIBUTE_TO_PLOT_VALUES_MUFA_SPECIFIC = {
	[AttributeToPlotMufaValues.OCCUPANCY]: true,
	[AttributeToPlotMufaValues.CONCESSION]: true,
};

export const ATTRIBUTE_TO_PLOT_VALUES_SALES_SPECIFIC = {
	[SalesAttributeToPlotValues.CAP_RATE]: true,
	[SalesAttributeToPlotValues.SALES_PRICE]: true,
	[SalesAttributeToPlotValues.SALES_VOLUME]: true,
};

export type AttributeToPlotType =
	| `${AttributeToPlotCommercialValues}`
	| `${SalesAttributeToPlotValues}`
	| `${AttributeToPlotMufaValues}`;

export interface YPropValue {
	value: { y: string; type: string };
	label: string;
}

export const ATTRIBUTE_TO_PLOT_LABEL: Record<AttributeToPlotType, string> = {
	[AttributeToPlotCommercialValues.STARTING_RENT]: 'Starting Rent',
	[AttributeToPlotCommercialValues.EFFECTIVE_RENT]: 'Effective Rent',
	[AttributeToPlotCommercialValues.FREE_RENT]: 'Free Rent',
	[AttributeToPlotCommercialValues.TENANT_IMPROVEMENT]: 'Tenant Improvement',
	[AttributeToPlotCommercialValues.LEASE_TERM]: 'Lease Term',
	[AttributeToPlotMufaValues.OCCUPANCY]: 'Occupancy Rate',
	[AttributeToPlotMufaValues.CONCESSION]: 'Concessions',
	[AttributeToPlotMufaValues.ASKING_RPSF]: 'Starting Rent',
	[AttributeToPlotMufaValues.EFFECTIVE_RPSF]: 'Effective Rent',
	[SalesAttributeToPlotValues.CAP_RATE]: 'Cap Rate',
	[SalesAttributeToPlotValues.SALES_PRICE]: 'Sales Price',
	[SalesAttributeToPlotValues.SALES_VOLUME]: 'Sales Volume',
};

export const yPropValuesCommercial: YPropValue[] = [
	{
		value: {
			y: AttributeToPlotCommercialValues.STARTING_RENT,
			type: 'line',
		},
		label: ATTRIBUTE_TO_PLOT_LABEL.startingRent,
	},
	{
		value: {
			y: AttributeToPlotCommercialValues.EFFECTIVE_RENT,
			type: 'line',
		},
		label: ATTRIBUTE_TO_PLOT_LABEL.effectiveRent,
	},
	{
		value: {
			y: AttributeToPlotCommercialValues.FREE_RENT,
			type: 'histogram',
		},
		label: ATTRIBUTE_TO_PLOT_LABEL.freeMonths,
	},
	{
		value: {
			y: AttributeToPlotCommercialValues.TENANT_IMPROVEMENT,
			type: 'histogram',
		},
		label: ATTRIBUTE_TO_PLOT_LABEL.ti,
	},
	{
		value: {
			y: AttributeToPlotCommercialValues.LEASE_TERM,
			type: 'line',
		},
		label: ATTRIBUTE_TO_PLOT_LABEL.leaseTerm,
	},
];

export const yPropValuesMufa: YPropValue[] = [
	{
		value: { y: AttributeToPlotMufaValues.ASKING_RPSF, type: 'line' },
		label: ATTRIBUTE_TO_PLOT_LABEL.startingRent,
	},
	{
		value: { y: AttributeToPlotMufaValues.EFFECTIVE_RPSF, type: 'line' },
		label: ATTRIBUTE_TO_PLOT_LABEL.effectiveRent,
	},
	{
		value: { y: AttributeToPlotMufaValues.CONCESSION, type: 'line' },
		label: ATTRIBUTE_TO_PLOT_LABEL.concessionValue,
	},
	{
		value: { y: AttributeToPlotMufaValues.OCCUPANCY, type: 'line' },
		label: ATTRIBUTE_TO_PLOT_LABEL.occupancy,
	},
];

export const yPropValuesSales: YPropValue[] = [
	// // Temporarily excluded, see https://compstak.atlassian.net/browse/AP-10001
	// 		{
	// 			value: { y: SalesAttributeToPlotValues.SALES_PRICE, type: 'line' },
	// 			label: ATTRIBUTE_TO_PLOT_LABEL.salePricePsf,
	// 		},
	{
		value: { y: SalesAttributeToPlotValues.SALES_VOLUME, type: 'line' },
		label: ATTRIBUTE_TO_PLOT_LABEL.totalSalePrice,
	},
	{
		value: { y: SalesAttributeToPlotValues.CAP_RATE, type: 'line' },
		label: ATTRIBUTE_TO_PLOT_LABEL.capRate,
	},
];
