import { useMemo } from 'react';
import { RowGridContainer } from '@compstak/ui-kit';
import { SubmarketRentTrend } from './SubmarketRentTrend';
import { SubmarketRentDistribution } from './SubmarketRentDistribution';
import { SubmarketTrendsHeader } from '../SubmarketTrendsHeader';
import { routes } from 'router';
import { usePropertyByIdQuery } from 'api';
import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import { useCommercialTrendsData } from '../../hooks/useCommercialTrendsData';
import { useCommercialTrendsState } from './useCommercialTrendsState';

type Props = ReturnType<typeof useCommercialTrendsState> & {
	data: ReturnType<typeof useCommercialTrendsData>['data'];
};

export const CommercialSubmarketTrends = ({
	filters,
	handleFilterChange,
	data,
}: Props) => {
	const propertyId = routes.propertyById.useParams().propertyId;
	const { data: propertyData } = usePropertyByIdQuery({
		id: propertyId,
	});
	const { data: submarketPolygons } = useSubmarketPolygons({
		id: propertyData?.marketId,
	});
	const submarketPolygon = useMemo(() => {
		return submarketPolygons?.features.find(
			(submarket) => submarket.properties.id === propertyData?.submarketId
		);
	}, [submarketPolygons, propertyData?.submarketId]);

	if (!propertyData) return null;

	const address = propertyData.buildingAddress ?? '';

	return (
		<div>
			<SubmarketTrendsHeader
				submarketName={propertyData.submarket}
				submarketPolygon={submarketPolygon}
				polygonDataSource="CompStak"
			>
				Commercial Submarket Trends
			</SubmarketTrendsHeader>
			<RowGridContainer>
				<SubmarketRentTrend
					address={address}
					filters={filters}
					handleFilterChange={handleFilterChange}
					rentTrend={data.rentTrend}
				/>
				<SubmarketRentDistribution
					address={address}
					marketId={propertyData.marketId}
					filters={filters}
					handleFilterChange={handleFilterChange}
					rentDistribution={data.rentDistribution}
				/>
			</RowGridContainer>
		</div>
	);
};
