import constate from 'constate';
import { useSelectionState } from 'utils';
import { useLeaseContext } from './LeaseProvider';

export const [LeasesSelectionProvider, useLeasesSelectionContext] = constate(
	() => {
		const { filteredLeases } = useLeaseContext();
		const selection = useSelectionState(filteredLeases);

		return selection;
	}
);
