import { getHasLoanData } from 'api';
import constate from 'constate';
import { useIsExchange } from 'hooks';
import { useCallback, useState } from 'react';
import { useIsShowTreppUI } from 'utils';
import { ExportPdfStep } from './types';
import { ExportPdfData } from './useExportPdfData';
import { useLeaseOptionsState } from './useLeaseOptionsState';
import { usePropertyPageDataContext } from '../PropertyPageDataProvider';

type Props = {
	data: ExportPdfData;
};

export const [ExportPdfProvider, useExportPdfContext] = constate(
	({ data }: Props) => {
		const [step, setStep] = useState(ExportPdfStep.ONE);
		const isExchange = useIsExchange();
		const showLoans = useIsShowTreppUI({ markets: [data.property.marketId] });

		const { compstakEstimates, isMarketStatsShown } =
			usePropertyPageDataContext();

		const [sections, setSections] = useState(() => {
			const _sections: SectionsState = {
				cover: true,
				overview: true,
				compset: true,
				leasesInCompset: true,
				submarketTrends: true,
			};
			if (compstakEstimates.showCompstakEstimates) {
				_sections.marketEstimates = true;
			}
			if (data.leases.length > 0) {
				_sections.leases = true;
			}
			if (data.hasSalesAccess && data.sales.length > 0) {
				_sections.sales = true;
			}
			const { hasLoans } = getHasLoanData(data.loans);

			if (showLoans && hasLoans) {
				_sections.loans = true;
			}

			if (data.isMufa && !isExchange) {
				_sections.multifamily = true;
			}
			if (data.isLeaseSnapshotPdfDisabled === false) {
				_sections.leaseSnapshot = true;
			}
			if (isMarketStatsShown) {
				_sections.marketStats = true;
			}

			return _sections;
		});

		const leasesInCompsetOptionsState = useLeaseOptionsState({
			leases: data.leasesInCompset,
		});
		const leaseOptionsState = useLeaseOptionsState({ leases: data.leases });

		const toggleSection = useCallback((name: SectionKey, value: boolean) => {
			setSections((s) => {
				const newState = {
					...s,
					[name]: value,
				};
				if (name === 'overview') {
					newState.leaseSnapshot = value;
					newState.marketEstimates = value;
					newState.marketStats = value;
				}
				if (name === 'compset') {
					newState.leasesInCompset = value;
				}

				return newState;
			});
		}, []);

		return {
			step,
			setStep,
			sections,
			setSections,
			leasesInCompsetOptionsState,
			leaseOptionsState,
			toggleSection,
		};
	}
);

export const SECTIONS_MAP = {
	cover: 'Cover Page',
	overview: 'Property Overview',
	compset: 'Competitive Set',
	leasesInCompset: 'Include Leases within the Competitive Set',
	submarketTrends: 'Submarket Trends',
	multifamily: 'Multifamily',
	leases: 'Property Lease Transactions',
	sales: 'Property Sales History',
	loans: 'Property Loan Data',
	leaseSnapshot: 'Lease Snapshot',
	marketEstimates: 'Market Estimates',
	marketStats: 'Market Stats',
};

export type SectionKey = keyof typeof SECTIONS_MAP;

export type SectionsState = Partial<Record<SectionKey, boolean>>;
