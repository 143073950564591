import { User } from '@compstak/common';
import UnlockOrExportButton from 'Components/UnlockOrExportButton';
import { Cell, CellProps } from 'fixed-data-table-2';
import React, { CSSProperties, memo } from 'react';
import { Comp, CompType } from '../../../../types/comp';

type CheckboxAndButtonCellProps = CellProps & {
	comp: Comp;
	selection: Map<number, boolean>;
	user: User;
	selectAllPending: boolean;
	style?: CSSProperties;
	actionsColumnWidth: number;
	handleCheckbox: (
		comp: Comp,
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	compType: CompType;
};

export default class CheckboxAndButtonCell extends React.Component<CheckboxAndButtonCellProps> {
	shouldComponentUpdate(nextProps: CheckboxAndButtonCellProps) {
		switch (true) {
			case this.props.comp === undefined:
			case nextProps.comp === undefined:
			case this.props.rowIndex !== nextProps.rowIndex:
			case this.props.comp !== nextProps.comp:
			// @ts-expect-error 'own' does not exist
			case this.props.comp.own !== nextProps.comp.own:
			case this.props.width !== nextProps.width:
			case this.props.selection.get(this.props.comp.id) !==
				nextProps.selection.get(nextProps.comp.id):
			// @ts-expect-error 'own' does not exist
			case !this.props.comp.own &&
				this.props.user.freeComps &&
				nextProps.user.freeComps === 0:
			case this.props.selectAllPending !== nextProps.selectAllPending:
				return true;
		}
		return false;
	}

	render() {
		const { comp, selection } = this.props;
		let className = 'fixed-to-left-comp-cell';

		const isChecked = comp && selection.get(comp.id);

		if (isChecked) {
			className += ' comp-selected';
		}

		return (
			<Cell
				rowIndex={this.props.rowIndex}
				height={this.props.height}
				width={this.props.width}
				style={this.props.style}
				className={className}
				columnKey={this.props.columnKey}
			>
				<CellContent {...this.props} />
			</Cell>
		);
	}
}

const CellContent = memo<CheckboxAndButtonCellProps>((props) => {
	const {
		comp,
		selection,
		selectAllPending,
		handleCheckbox,
		actionsColumnWidth,
		rowIndex,
	} = props;

	if (comp) {
		const { id } = comp;
		const isChecked = selection.get(id) || selectAllPending || false;

		return (
			<div>
				<input
					type="checkbox"
					className="checkbox"
					id={'comp-' + rowIndex}
					data-comp-id={id}
					onChange={(event) => handleCheckbox(comp, event)}
					checked={isChecked}
				/>
				<label htmlFor={'comp-' + rowIndex} className="checkbox" />
				<UnlockOrExportButton
					{...props}
					comp={comp}
					width={actionsColumnWidth - 46}
					height={30}
					configuration={'table'}
				/>
			</div>
		);
	} else {
		return (
			<div>
				<input type="checkbox" className="checkbox" checked={false} />
				<label className="checkbox" />
				<div className="button_button button_disabled loading" />
			</div>
		);
	}
});
CellContent.displayName = 'CellContent';
