import { WidgetContainer } from '@compstak/ui-kit';
import { usePropertyById } from 'api';
import {
	SubmarketRecentLeasesTable,
	CommercialSubmarketTrends,
	MufaSubmarketSection,
} from 'Components';
import { routes } from 'router';
import styled from 'styled-components';
import { TabContainer, TabContentTitle } from '../UI';
import { useSubmarketTrendsContext } from './SubmarketTrendsProvider';

export const SubmarketTrendsTab = () => {
	const propertyId = routes.propertyById.useParams().propertyId;
	const { data: propertyData } = usePropertyById({ propertyId: propertyId });

	const {
		commercialTrendsFilters,
		setCommercialTrendsFilters,
		commercialTrendsData,
		mufaTrendsFilters,
		setMufaTrendsFilters,
		mufaTrendsData,
		trendsToShow,
	} = useSubmarketTrendsContext();

	if (!propertyData) return null;

	return (
		<TabContainer>
			{trendsToShow.mufaTrends && (
				<MufaSubmarketSection
					filters={mufaTrendsFilters}
					handleFilterChange={setMufaTrendsFilters}
					data={mufaTrendsData}
				/>
			)}
			{trendsToShow.commercialTrends && (
				<CommercialSubmarketTrends
					filters={commercialTrendsFilters}
					handleFilterChange={setCommercialTrendsFilters}
					data={commercialTrendsData}
				/>
			)}
			<StyledTabContentTitle>Submarket Recent Leases</StyledTabContentTitle>
			<WidgetContainer>
				<SubmarketRecentLeasesTable
					propertyId={propertyId}
					marketId={propertyData.marketId}
					submarket={propertyData.submarket}
				/>
			</WidgetContainer>
		</TabContainer>
	);
};

const StyledTabContentTitle = styled(TabContentTitle)`
	margin-bottom: 16px;
`;
