import { composeEnabled } from 'api';
import {
	useAPNPolygonQuery,
	UseAPNPolygonQueryOptions,
} from 'api/ppm-property/apnPolygons/useAPNPolygonQuery';
import { useFilters } from 'reducers/filtersReducer';

export const useAPNFeatures = (options?: UseAPNPolygonQueryOptions) => {
	const [filters] = useFilters();
	const fips = filters.apn?.fips ?? '';
	const apn = filters.apn?.apn ?? '';

	const { data: apnFeature } = useAPNPolygonQuery(
		{ fips, apn },
		{
			...options,
			enabled: composeEnabled(!!fips && !!apn, options?.enabled),
		}
	);

	return {
		apnFeatures: apnFeature ? [apnFeature] : [],
	};
};
