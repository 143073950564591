import { CheckedState } from '@radix-ui/react-checkbox';
import {
	deselectAll,
	deselectComps,
	selectAllComps,
	selectComps,
} from 'actions/selection';
import { FiltersObject } from 'models/filters/types';
import { useDispatch } from 'react-redux';
import { CompType } from 'types';
import { useAppSelector } from 'util/useAppSelector';

type Props = {
	compType: CompType;
	totalCount: number;
	filters: FiltersObject;
};

/** Returns props to connect SearchTable to redux selection state */
export const useSearchTableSelection = <Row extends { id: number }>({
	compType,
	totalCount,
	filters,
}: Props) => {
	const dispatch = useDispatch();
	const selectionMap = useAppSelector((s) => s.selection.map);
	const selectAllPending = useAppSelector(
		(s) => s.searchReducer.selectAllPending
	);

	const areAllSelected = totalCount > 0 && selectionMap.size === totalCount;
	const areSomeSelected =
		selectionMap.size > 0 && selectionMap.size < totalCount;

	const getIsRowSelected = (row: Row) =>
		areAllSelected || !!selectionMap.get(row.id);

	return {
		getIsRowSelected,
		toggleRowSelection: (row: Row) => {
			if (selectAllPending) return;

			const isSelected = getIsRowSelected(row);

			if (isSelected) {
				dispatch(deselectComps([row.id]));
			} else {
				// @ts-expect-error legacy type
				dispatch(selectComps([row]));
			}
		},
		areAllSelected,
		toggleAllRowsSelection: (checked: CheckedState) => {
			if (!areSomeSelected && checked) {
				dispatch(selectAllComps(filters, compType));
			} else {
				dispatch(deselectAll());
			}
		},
		areSomeSelected,
	} as const;
};
