import {
	PropertyLease,
	SpaceTypeName,
	usePropertyLeasesSummaryQuery,
} from 'api';
import { useLeaseContext } from '../LeasesTab/LeaseProvider';
import { routes } from 'router';
import { useIsExchange } from 'hooks';

export const useLockedSnapshotData = () => {
	const params = routes.propertyById.useParams();

	const isExchange = useIsExchange();

	const { data: summary } = usePropertyLeasesSummaryQuery(params.propertyId);
	const { leasesActive, leasesActiveGrouppedBySpaceType } = useLeaseContext();

	if (!isExchange || !summary)
		return {
			isSnapshotLocked: false,
			isSnapshotBaseLocked: false,
			lockedLeases: [],
			spaceTypeRents: summary?.spaceTypeRents ?? [],
		};

	const lockedActiveLeases =
		summary.activeLeaseCount && summary.activeLeaseCount < 3
			? leasesActive.filter((lease) => !lease.own)
			: [];

	const lockedLeasesForRent: PropertyLease[] = [];
	const spaceTypeRents = summary.spaceTypeRents?.map(
		({ spaceType, weightedAvgCurrentRent }) => {
			const leasesActiveForType =
				leasesActiveGrouppedBySpaceType[spaceType as SpaceTypeName];

			const isValueLocked =
				weightedAvgCurrentRent !== undefined &&
				leasesActiveForType?.length === 1 &&
				leasesActiveForType[0].own === false;

			if (isValueLocked) lockedLeasesForRent.push(leasesActiveForType[0]);

			return {
				spaceType,
				weightedAvgCurrentRent: isValueLocked ? null : weightedAvgCurrentRent,
			};
		}
	);

	const uniqueLockedLeases = Array.from(
		new Map(
			[...lockedActiveLeases, ...lockedLeasesForRent].map((lease) => [
				lease.id,
				lease,
			])
		).values()
	);

	return {
		isSnapshotLocked: uniqueLockedLeases.length > 0,
		isSnapshotBaseLocked: lockedActiveLeases.length > 0,
		lockedLeases: uniqueLockedLeases,
		spaceTypeRents: spaceTypeRents ?? [],
	};
};
