// @ts-expect-error TS7016: Could not find a declaration f...
import fixAFloat from 'fix-a-float';

export const bucket = function (min: number, max: number, maxBuckets: number) {
	if (min > max) {
		throw new Error('Min must be smaller than max when bucketing');
	}

	let range = max - min || 1;
	let multiplier = 1;

	while (range < maxBuckets) {
		multiplier = multiplier * 10;
		range = range * 10;
	}

	const low = niceFloor(min * multiplier);
	const high = niceCeil(max * multiplier);

	range = high - low || 1;
	let interval = niceCeil(range / maxBuckets);

	let niceMin = niceFloor(min);

	// this prevents a min of like 10 when the first one is 10 and the next is 700
	if (interval / multiplier > min) {
		niceMin = 0;
		range = high - niceMin;
		interval = niceCeil(range / maxBuckets);
	}

	const bucketMins = [];
	let newNumber = niceMin;
	for (let i = 0; i < range; i += interval) {
		newNumber = niceMin + i / multiplier;
		if (newNumber > max) {
			break;
		}

		bucketMins.push(fixAFloat(newNumber));
	}

	return {
		interval: interval / multiplier,
		buckets: bucketMins,
	};
};

// @ts-expect-error TS7023: 'niceFloor' implicitly has ret...
export const niceFloor = function (n: number) {
	if (n === 0) {
		return 0;
	}

	if (n < 0) {
		return -1 * niceCeil(-n);
	}

	const exp = Math.floor(Math.log(n) / Math.LN10);
	const f = n / Math.pow(10, exp);
	return Math.floor(f) * Math.pow(10, exp);
};

// @ts-expect-error TS7023: 'niceCeil' implicitly has retu...
export const niceCeil = function (n: number) {
	if (n === 0) {
		return 0;
	}

	if (n < 0) {
		return -1 * niceFloor(-n);
	}

	const exp = Math.floor(Math.log(n) / Math.LN10);
	const f = n / Math.pow(10, exp);
	return Math.ceil(f) * Math.pow(10, exp);
};
