import { usePropertyByIdQuery } from 'api/propertyById';
import { getIsMonthlyMarket } from 'utils';

type Props = {
	propertyId: number;
};

export const useIsPropertyMarketMonthly = ({ propertyId }: Props) => {
	const { data: property, isFetching } = usePropertyByIdQuery({
		id: propertyId,
	});
	return {
		data: property ? getIsMonthlyMarket(property.marketId) : undefined,
		isFetching,
	};
};
