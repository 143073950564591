import { useUserQuery } from 'api';
import dayjs from 'dayjs';
import { useIsExchange } from 'hooks';
import { routes } from 'router';
import styled from 'styled-components';
import { CircleText, HeaderGreenButtonLink, MessageText } from './UI';

export const useUpgradeLinks = () => {
	const { data: user } = useUserQuery();
	const isEnterprise = !useIsExchange();

	if (!isEnterprise) return null;
	if (!user.enterpriseTrialData) return null;

	// @ts-expect-error enterpriseTrialData type is wrong
	const expiration = dayjs(user.enterpriseTrialData.expirationTime);
	const now = dayjs();
	const daysLeftInTrial = expiration.diff(now, 'days');

	let text;

	if (daysLeftInTrial > 0) {
		const daysText = daysLeftInTrial === 1 ? 'day' : 'days';
		text = (
			<TextContainer>
				<CircleText>{daysLeftInTrial}</CircleText>
				<MessageText>{daysText} left</MessageText>
			</TextContainer>
		);
	} else {
		text = <CircleText>last day</CircleText>;
	}

	return (
		<>
			{text}
			<HeaderGreenButtonLink to={routes.upgrade.path}>
				Upgrade
			</HeaderGreenButtonLink>
		</>
	);
};

const TextContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;
