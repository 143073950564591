/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import ArrowUpDown from '../../../ui/svg_icons/arrow_up_down.svg';
import SavedSearchEditMenu, {
	SavedSearchEditMenuProps,
} from './SavedSearchEditMenu';
import { SavedSearch } from 'reducers/savedSearches';
import { AppConfig } from 'types/config';
import { useMarkets } from 'hooks/useMarkets';
import { setFilters } from 'models/filters/util/setFilters';
import {
	filtersFromServerJSON,
	filtersToServerJSON,
} from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { MapImage } from '@compstak/maps';
import { featureCollection, point } from '@turf/helpers';
import { useDispatch } from 'react-redux';
import {
	deleteSavedSearch,
	duplicateSavedSearch,
	setCurrentSavedSearch,
} from '../actions';
import { addFeedback } from 'Singletons/Feedback/actions';
import { hideMenu, showMenu } from 'Singletons/Menu/actions';
import { trimString } from 'util/trimString';
import { SavedSearchItemAnalyticButton } from './SavedSearchItemAnalyticButton';
import { useSearchLeasesQuery, useSearchSalesQuery } from 'api';
import styled from 'styled-components';
import { Button } from '@compstak/ui-kit';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { useNewCompsQuery } from 'api/newComps/useNewCompsQuery';
import { PropertyFilters } from 'models/filters/property';
import { SEARCH_ROUTES } from 'router';
import { useModal } from 'providers/ModalProvider';
import { ShareWithColleagueModal } from 'Components/Modals/ShareWithColleagueModal/ShareWithColleagueModal';
import { sendSearch } from 'actions/user';

type SavedSearchItemProps = {
	appConfig: AppConfig;
	deletingId: string;
	editingId: string;
	savedSearch: SavedSearch;

	toggleEditingSavedSearch: (
		savedSearch: SavedSearch,
		isEditing: boolean
	) => void;
	toggleDeletingSavedSearch: (
		savedSearch: SavedSearch,
		isDeleting: boolean
	) => void;
};

export const SavedSearchItem = (props: SavedSearchItemProps) => {
	const [savedSearchName, setSavedSearchName] = useState(
		props.savedSearch.name
	);
	const [emailNotifications, setEmailNotifications] = useState(
		props.savedSearch.notifyByEmail
	);
	const markets = useMarkets();
	const dispatch = useDispatch();

	const { openModal } = useModal();

	const searchType = props.savedSearch.searchType;

	const savedSearchFilters = useMemo(() => {
		return filtersFromServerJSON(
			searchType,
			markets,
			props.savedSearch.filters
		);
	}, [searchType, markets, props.savedSearch.filters]);

	// for leases and sales, not properties
	const recentCompCountFilters = useMemo(() => {
		return setFilters(savedSearchFilters, 'dateCreated', {
			min: dayjs(props.savedSearch.lastRan).toDate(),
			max: null,
		});
	}, [props.savedSearch.lastRan, savedSearchFilters]);

	const { data: leaseData } = useSearchLeasesQuery(
		{
			filter: filtersToServerJSON(recentCompCountFilters),
			offset: 0,
			limit: 1,
			order: 'asc',
			sort: 'dateCreated',
		},
		{ enabled: searchType === 'leases' }
	);

	const { data: saleData } = useSearchSalesQuery({
		params: {
			filter: filtersToServerJSON(recentCompCountFilters),
			offset: 0,
			limit: 1,
			order: 'asc',
			sort: 'dateCreated',
		},
		enabled: searchType === 'sales',
	});

	const { data: propertiesNewCompsData } = useNewCompsQuery(
		{
			newFrom: props.savedSearch.lastRan,
			filters: savedSearchFilters as PropertyFilters,
		},
		{ enabled: searchType === 'properties' }
	);

	const newCompsCount =
		leaseData?.totalCount ??
		saleData?.totalCount ??
		(propertiesNewCompsData
			? propertiesNewCompsData?.leases.total +
				propertiesNewCompsData?.sales.total
			: undefined);

	const possiblyHasMore = propertiesNewCompsData?.possiblyHasMore ?? false;

	const handleEditModeCancelClick = () => {
		setSavedSearchName(props.savedSearch.name);
		setEmailNotifications(props.savedSearch.notifyByEmail);
		props.toggleEditingSavedSearch(props.savedSearch, false);
	};

	const handleDeleteSavedSearch = () => {
		props.toggleDeletingSavedSearch(props.savedSearch, false);
		dispatch(deleteSavedSearch(props.savedSearch));
	};

	const handleUpdateSavedSearch = () => {
		if (!trimString(savedSearchName).length) {
			dispatch(addFeedback('Search must have a name.'));
		} else {
			dispatch(
				duplicateSavedSearch(props.savedSearch, {
					name: savedSearchName,
					notifyByEmail: emailNotifications,
				})
			);
			props.toggleEditingSavedSearch(props.savedSearch, false);
		}
	};

	const enterDeleteMode = () => {
		dispatch(hideMenu('saved-search-edit-menu'));
		props.toggleDeletingSavedSearch(props.savedSearch, true);
	};

	const handleDuplicateSavedSearch = () => {
		setSavedSearchName(`${savedSearchName} (2)`);
		props.toggleEditingSavedSearch(props.savedSearch, true);
		dispatch(hideMenu('saved-search-edit-menu'));
	};

	const savedSearchUrl = SEARCH_ROUTES[props.savedSearch.searchType].toHref(
		undefined,
		{
			savedSearchId: props.savedSearch.id,
		}
	);

	const getClassName = () => {
		let className = 'saved-search-card';
		if (props.deletingId === props.savedSearch.id) {
			className += ' deleting';
		} else if (props.editingId === props.savedSearch.id) {
			className += ' editing';
		}
		return className;
	};

	const showSendSearchModal = (event: React.MouseEvent) => {
		event.preventDefault();
		const sendSearchWithId = (emails: string[]) =>
			dispatch(sendSearch(props.savedSearch.id, emails));
		openModal({
			modalContent: (
				<ShareWithColleagueModal
					itemName={props.savedSearch.name}
					modalTitle="Share Search"
					buttonText="Send Search"
					action={sendSearchWithId}
				/>
			),
		});
	};

	const showEditDropdown = (event: React.MouseEvent) => {
		dispatch(
			showMenu<SavedSearchEditMenuProps>(
				SavedSearchEditMenu,
				event.currentTarget,
				'below',
				{
					enterDeleteMode,
					url: savedSearchUrl,
					handleDuplicateSavedSearch,
					handleAdjustSavedSearchFilters: () =>
						dispatch(setCurrentSavedSearch(props.savedSearch)),
				}
			)
		);
	};

	const renderDeleteContent = () => {
		const shortSearchName =
			props.savedSearch.name.length > 65
				? props.savedSearch.name.slice(0, 65).concat(' ...')
				: props.savedSearch.name;
		return (
			<SavedSearchItemInfo>
				<SavedSearchItemTitle
					className="saved-search-title"
					data-tooltip={
						props.savedSearch.name.length > 65 ? props.savedSearch.name : null
					}
				>
					{shortSearchName}
				</SavedSearchItemTitle>
				<SavedSearchItemDeleteControls>
					<Button
						variant="secondary"
						onClick={() =>
							props.toggleDeletingSavedSearch(props.savedSearch, false)
						}
					>
						Cancel
					</Button>
					<Button variant="error" onClick={handleDeleteSavedSearch}>
						Delete
					</Button>
				</SavedSearchItemDeleteControls>
			</SavedSearchItemInfo>
		);
	};

	const renderEditContent = () => {
		return (
			<div>
				<div className="edit-title">
					<div className="edit-title-input">
						<input
							data-qa-id="saved-searh-name"
							onChange={(ev) => setSavedSearchName(ev.target.value)}
							type="text"
							value={savedSearchName}
							placeholder="My Saved Search"
						/>
					</div>
					<div className="edit-title-button">
						<div className="button_button" onClick={handleUpdateSavedSearch}>
							Save
						</div>
					</div>
				</div>
				<input
					data-qa-id="saved-search-email-notifications"
					onChange={(ev) => setEmailNotifications(ev.target.checked)}
					type="checkbox"
					checked={emailNotifications}
					className="checkbox"
					id="email-notifications"
				/>
				<label htmlFor="email-notifications">
					<span>Receive email updates</span>
				</label>
				<span className="link cancel" onClick={handleEditModeCancelClick}>
					Cancel
				</span>
			</div>
		);
	};

	const renderDefaultContent = () => {
		const shortSearchName =
			props.savedSearch.name.length > 65
				? props.savedSearch.name.slice(0, 65).concat(' ...')
				: props.savedSearch.name;

		return (
			<>
				<a href={savedSearchUrl} className="saved-search-map">
					<MapImage
						width={125}
						height={125}
						position={
							marketsPoints.features.length > 1
								? {
										type: 'bounds',
										points: marketsPoints.features,
									}
								: {
										type: 'center',
										latitude: firstMarketPoint.geometry.coordinates[1],
										longitude: firstMarketPoint.geometry.coordinates[0],
										zoom: getZoomLevel(firstMarketPoint.properties.zoom),
									}
						}
						showLogo={false}
						showAttribution={false}
					/>
				</a>
				<SavedSearchItemInfo>
					<SavedSearchItemTitle
						className="saved-search-title"
						data-tooltip={
							props.savedSearch.name.length > 65 ? props.savedSearch.name : null
						}
					>
						{shortSearchName}
					</SavedSearchItemTitle>
					<div className="edit-search-container">
						<div className="edit-saved-search">
							<span
								className="link view"
								onClick={() =>
									dispatch(setCurrentSavedSearch(props.savedSearch))
								}
							>
								<a href={savedSearchUrl}>View</a>
							</span>
						</div>
						<div className="edit-saved-search" onClick={showEditDropdown}>
							<span className="link edit">Edit</span>
							<ArrowUpDown />
						</div>
						<div className="edit-saved-search">
							{props.appConfig.isEnterprise && (
								<span className="link share" onClick={showSendSearchModal}>
									Share
								</span>
							)}
						</div>
						{searchType !== 'properties' && (
							<SavedSearchItemAnalyticButton
								markets={markets}
								savedSearch={props.savedSearch}
							/>
						)}
					</div>
				</SavedSearchItemInfo>
			</>
		);
	};

	const getContent = () => {
		if (props.deletingId === props.savedSearch.id) {
			return renderDeleteContent();
		} else if (props.editingId === props.savedSearch.id) {
			return renderEditContent();
		} else {
			return renderDefaultContent();
		}
	};

	const getZoomLevel = (zoom: number) => {
		if (zoom && zoom > 2.5) {
			return zoom - 2.5;
		}

		return 0;
	};

	const marketsPoints = useMemo(() => {
		return featureCollection(
			getFiltersMarkets(savedSearchFilters).map((market) =>
				point([market.lon, market.lat], market)
			)
		);
	}, [savedSearchFilters.market, savedSearchFilters.markets]);

	const firstMarketPoint = marketsPoints.features[0];

	return (
		<div className={getClassName()}>
			{!!newCompsCount && (
				<div className="card-count">
					{` ${abbreviateNumber(newCompsCount)}${
						possiblyHasMore ? '+' : ''
					} New `}
				</div>
			)}
			<div className="saved-search-content-container">
				<div className="saved-search-content">{getContent()}</div>
			</div>
		</div>
	);
};

const SavedSearchItemInfo = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	text-align: left;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px 0;

	.edit-search-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;
		.edit-saved-search {
			display: flex;
			align-items: flex-end;
			position: relative;
			&:hover {
				cursor: pointer;
				svg * {
					fill: ${({ theme }) => theme.colors.gray.gray0};
				}
			}
		}
	}
`;

const SavedSearchItemTitle = styled.p`
	font-weight: 400;
	line-height: 1.65em;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 20px;
`;

const SavedSearchItemDeleteControls = styled.div`
	display: flex;
	gap: 10px;
	align-self: flex-end;
`;
