import styles from './exportMenu.less';
import { ExportFormWrap } from './UI';
import { exportButtonClass } from './const';
import { CompType } from '../../../types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { exportComps } from '../../../actions/export';
import { hideMenu } from '../../../Singletons/Menu/actions';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import { useFilters } from 'reducers/filtersReducer';
import { useQueryClient } from '@tanstack/react-query';

interface FinalExportDialogProps {
	compIds: number[];
	compType: CompType;
	withFinancial: boolean;
}

export const ExportExcelFinalDialog = ({
	compType,
	withFinancial,
	compIds,
}: FinalExportDialogProps) => {
	const [filters] = useFilters();
	const dispatch = useDispatch();
	const [monthly, setMonthly] = useState(shouldUseMonthlyMetrics(filters));
	const queryClient = useQueryClient();

	return (
		<ExportFormWrap>
			{compType !== 'sale' && withFinancial ? (
				<div className={`${styles.actions} ${styles.timeIncrement}`}>
					<p>Show values monthly or annually</p>
					<div className={styles.toggleSwitch}>
						<input
							type="radio"
							id="monthly"
							name="time-increment"
							checked={monthly}
							onChange={() => setMonthly(true)}
						/>
						<label htmlFor="monthly">Monthly</label>
						<input
							type="radio"
							id="annually"
							name="time-increment"
							checked={!monthly}
							onChange={() => setMonthly(false)}
						/>
						<label htmlFor="annually">Annually</label>
					</div>
				</div>
			) : (
				false
			)}
			<div className={styles.actions}>
				<span
					className={exportButtonClass}
					onClick={() => {
						dispatch(
							exportComps({
								compIds,
								compType,
								exportType: 'xls',
								monthly,
								withFinancial,
								queryClient,
							})
						);
						dispatch(hideMenu('export-menu'));
					}}
				>
					Export
				</span>
			</div>
		</ExportFormWrap>
	);
};
