import { Chip as ChipOriginal } from '@compstak/ui-kit';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type FilterChipsProps = {
	className?: string;
	chips: ReactNode[];
};

export const FilterChips = ({ className, chips }: FilterChipsProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [visibleChips, setVisibleChips] = useState(chips);
	const chipRefs = useRef<HTMLDivElement[]>([]);

	useLayoutEffect(() => {
		const container = containerRef.current;
		if (!container) {
			return;
		}

		const containerWidth = containerRef.current.offsetWidth;
		let totalWidth = 0;
		let lines = 1;
		const maxLines = 3;
		const chipsToShow: ReactNode[] = [];

		chipRefs.current = chipRefs.current.slice(0, chips.length);

		for (let i = 0; i < chips.length; i++) {
			const chipWidth = chipRefs.current[i]?.getBoundingClientRect().width;

			if (totalWidth + chipWidth >= containerWidth) {
				lines++;
				if (lines > maxLines) {
					break;
				}
				totalWidth = 0;
			}
			totalWidth += chipWidth;
			chipsToShow.push(chips[i]);
		}

		setVisibleChips(chipsToShow);
	}, [chips]);

	const hiddenChips = chips.filter((chip) => !visibleChips.includes(chip));

	return (
		<ChipsContainer ref={containerRef} className={className}>
			{/* Rendering all chips in hidden container to derrive the real space they take */}
			<HiddenChipsContainer>
				{chips.map((chip, index) => (
					<div
						key={index}
						ref={(el) => {
							if (el) {
								chipRefs.current[index] = el;
							}
						}}
					>
						<Chip>{chip}</Chip>
					</div>
				))}
			</HiddenChipsContainer>
			{visibleChips.map((chip, index) => (
				<Chip key={index}>{chip}</Chip>
			))}
			{hiddenChips.length > 0 && <Chip>{`+${hiddenChips.length} More`}</Chip>}
		</ChipsContainer>
	);
};

const Chip = styled(ChipOriginal)`
	font-size: 0.75rem;
	background-color: ${({ theme }) => theme.colors.neutral.n30};
	color: ${({ theme }) => theme.colors.gray.gray700};
	line-height: 1rem;
	padding: 0.25rem 0.5rem;
	border-radius: 20px;
	text-align: center;
`;

export const ChipsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

const HiddenChipsContainer = styled.div`
	visibility: hidden;
	position: absolute;
	top: -9999px;
	left: -9999px;
`;
