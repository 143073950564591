import { View, Text } from '@react-pdf/renderer';
import { useTheme } from 'styled-components';
import { AssociatedApn } from 'api/ppm-property/associatedApns/useGetAssociatedApns';

export const PdfApn = ({
	apn,
}: {
	apn: AssociatedApn[] | string | undefined;
}) => {
	const theme = useTheme();

	if (!apn) return <></>;

	return (
		<View style={style.apnContainer}>
			<Text style={[style.apnLabel, { color: theme.colors.neutral.n100 }]}>
				APN:
			</Text>
			<Text style={[style.apnLabel, { color: theme.colors.neutral.n500 }]}>
				{Array.isArray(apn)
					? `${apn[0].apn} + ${apn.length - 1} Other APN Associated`
					: apn}
			</Text>
		</View>
	);
};

import { StyleSheet } from '@react-pdf/renderer';

export const style = StyleSheet.create({
	apnContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 2,
		marginTop: 10,
	},
	apnLabel: {
		fontSize: 10,
	},
});
