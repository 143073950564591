import { z } from 'zod';
import { API, APIClientNotOkResponseError } from '@compstak/common';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { qcRetryOnlyAuth } from 'api/utils/queryRetries';

const totpSecretSchema = z.string().length(32);

export const useEnableMfaForCurrentUserMutation = (
	options?: MutationOptions<
		{ completed: boolean },
		APIClientNotOkResponseError,
		{
			totpCode: string;
			totpSecret: string;
		}
	>
) => {
	return useMutation({
		mutationFn: async (params) => {
			totpSecretSchema.parse(params.totpSecret);
			const res = await API.post<{ completed: boolean }>('/api/users/mfa/set', {
				secret: params.totpSecret, // Get this from below the QR code
				code: params.totpCode, // Get this from the phone app
			});
			if (!res.data?.completed) {
				throw Error('Expected completed=true for mfa enabling response', {
					cause: res.data,
				});
			}
			return res.data;
		},
		retry: qcRetryOnlyAuth,
		...options,
	});
};

export const useLoginCurrentUserWithMfaMutation = (
	options?: MutationOptions<
		void,
		APIClientNotOkResponseError,
		{
			username: string;
			password: string;
			rememberMe: boolean;
			mfaSecret: string;
			totpCode: string;
		}
	>
) => {
	return useMutation({
		mutationFn: async (params) => {
			totpSecretSchema.parse(params.mfaSecret);
			const res = await API.post<void>('/api/users/mfa/login', params);
			return res.data;
		},
		retry: qcRetryOnlyAuth,
		...options,
	});
};

export const useDisableMFAForCurrentUserMutation = (
	options?: MutationOptions<void, APIClientNotOkResponseError>
) => {
	return useMutation({
		mutationFn: async () => {
			const res = await API.post<void>('/api/users/mfa/delete');
			return res.data;
		},
		retry: qcRetryOnlyAuth,
		...options,
	});
};
