import React, { memo } from 'react';
import { PDFPanel, PanelDescription } from './UI';
import { StartingRentChart } from '../../StartingRentChart';
import { useExchangeDashboardStartingRent } from 'ExchangeDashboard/hooks/startingRent/useExchangeDashboardStartingRent';
import { LoadingChart } from 'ExchangeDashboard/UI';

export const StartingRent = memo(() => {
	const { startingRentData, isFetching, monthly, movingAverage } =
		useExchangeDashboardStartingRent();

	return (
		<PDFPanel id="starting-rent" title="Starting Rent">
			{isFetching && <LoadingChart />}
			{!isFetching &&
				(startingRentData && startingRentData.length > 0 ? (
					<>
						<StartingRentChart
							startingRentData={startingRentData}
							monthly={monthly}
						/>
						<PanelDescription>
							Charts reflect starting rent trend for lease transactions in
							selected markets and submarket(s). The data reflects a{' '}
							{movingAverage} months moving average.
						</PanelDescription>
					</>
				) : (
					<div>No Data To Display. Update Filters and Try Again.</div>
				))}
		</PDFPanel>
	);
});

StartingRent.displayName = 'StartingRent';
