/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Menu from 'Singletons/Menu';

export type SavedSearchEditMenuProps = {
	url: string;

	enterDeleteMode: () => void;
	handleAdjustSavedSearchFilters: () => void;
	handleDuplicateSavedSearch: () => void;
};

const SavedSearchEditMenu = (props: SavedSearchEditMenuProps) => {
	return (
		<ul id="saved-search-edit-menu" className="edit-dropdown">
			<li className="edit-dropdown-item">
				<a
					data-qa-id="saved-search-edit-menu-adjust-filters"
					href={props.url}
					onClick={props.handleAdjustSavedSearchFilters}
				>
					Adjust Filters
				</a>
			</li>
			<li
				data-qa-id="saved-search-edit-menu-duplicate"
				className="edit-dropdown-item"
				onClick={(ev) => {
					ev.stopPropagation();
					props.handleDuplicateSavedSearch();
				}}
			>
				Duplicate
			</li>
			<li
				data-qa-id="saved-search-edit-menu-delete"
				className="edit-dropdown-item"
				onClick={(ev) => {
					ev.stopPropagation();
					props.enterDeleteMode();
				}}
			>
				Delete
			</li>
		</ul>
	);
};

Menu.addComponent('saved-search-edit-menu', SavedSearchEditMenu);

export default 'saved-search-edit-menu';
