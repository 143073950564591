import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import { TopTenantIndustry } from 'api';
import { useState } from 'react';
import styled from 'styled-components';
import { ExchangePanel } from '../UI';
import { Treemap } from './Treemap';
import { TreeNode } from './treemapTypes';
import useDimensions from 'react-use-dimensions';
import { Waypoint } from 'react-waypoint';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';

export const LOADING_TREEMAP = Array.from(Array(8).keys()).map(() => ({
	data: {
		type: 'loading-block',
		size: Math.random() * (10000 - 9000) + 9000,
	},
}));

const TenantIndustryDistribution = () => {
	const [view, setView] = useState<'visible' | 'hidden'>('hidden');
	const { fullPageState, setFullPageState } = useExchangeDashboardState();
	const { data, isFetching } = useExchangeDashboardTenantIndustryDistribution({
		enabled: view === 'visible' && fullPageState === null,
	});

	const [panelRef, dimensions] = useDimensions();

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				ref={panelRef}
				id="tenant-industry-distribution"
				title="Tenant Industry Distribution"
				tooltip="Top ten industries based on total square footage in selected market and submarket(s)."
			>
				{isFetching ? (
					<div>
						<TreemapContainer>
							<div className="container">
								<Treemap
									width={(dimensions?.width ?? 42) - 42}
									height={(dimensions?.height ?? 90) - 90}
									margin={2}
									data={{
										children: LOADING_TREEMAP,
									}}
									getSize={(node) => node.data?.size ?? 0}
									sortFunction={(n1, n2) =>
										(n2.data?.size ?? 0) - (n1.data?.size ?? 0)
									}
								/>
							</div>
						</TreemapContainer>
					</div>
				) : data && data.length > 0 ? (
					<div>
						<TreemapContainer>
							<div className="container">
								<Treemap
									width={(dimensions?.width ?? 42) - 42}
									height={(dimensions?.height ?? 90) - 90}
									margin={2}
									data={{
										children: data.map(createTopTenantIndustryNode),
									}}
									getSize={(node) => node.data?.size ?? 0}
									sortFunction={(n1, n2) =>
										(n2.data?.size ?? 0) - (n1.data?.size ?? 0)
									}
									onLeafClick={(tenantIndustry: string) => {
										setFullPageState &&
											setFullPageState({
												type: 'list',
												field: 'tenantIndustry',
												industry: tenantIndustry,
											});
									}}
								/>
							</div>
						</TreemapContainer>
					</div>
				) : (
					<div>No Data To Display. Update Filters and Try Again.</div>
				)}
			</ExchangePanel>
		</Waypoint>
	);
};

export default TenantIndustryDistribution;

export const createTopTenantIndustryNode = ({
	industry,
	propertyCount,
	leaseCount,
	sqft,
}: TopTenantIndustry): TreeNode => ({
	data: {
		size: sqft,
		title: industry,
		propertyCount,
		leaseCount,
		sqft,
	},
});

export const TreemapContainer = styled.div`
	height: 100%;

	.container {
		width: 100%;
		height: 100%;

		background-color: ${({ theme }) => theme.colors.white.white};
		.treemap {
			font-size: 12px;
			position: relative;
		}

		.treemap__leaf {
			overflow: hidden;
			position: absolute;
			color: ${({ theme }) => theme.colors.white.white};
			font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
			font-size: 10px;
			font-weight: 500;
			padding: 8px 4px;
			cursor: pointer;
			border: none;
			border-radius: 3px;
			transition: opacity 0.3s ease-in-out;

			&[data-hover-state='inactive'] {
				opacity: 0.6;
			}

			&:not([data-variant='loading-block']):hover {
				box-shadow: 0px 4px 14px rgba(51, 51, 51, 0.55);
			}

			.treemap__leaf__content {
				width: 100%;
				height: 100%;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				&[data-variant='dark'] {
					color: ${({ theme }) => theme.colors.gray.gray0};
				}

				.leaf-title {
					pointer-events: none;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 18px;
					min-height: 1.2em;
					text-align: center;
					letter-spacing: 0.5px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
				}
				.leaf-size {
					pointer-events: none;
					font-style: normal;
					font-weight: normal;
					font-size: 10px;
					line-height: 16px;
					min-height: 1.2em;
					letter-spacing: 0.5px;
				}
			}
		}
	}
`;
