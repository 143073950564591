import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { FilterOption, MultiselectProps } from './Multiselect';
import {
	ListSearchInputStyled,
	StyledCrossIcon,
	useListSearchInput,
} from '../../ListSearchInput/ListSearchInput';
import { multiMarketSelectorSearchInputTestId } from '../../../Pages/Home/Sidebar/Components/MultiMarketSelector';
import { Checkbox as CheckboxOrigin, CheckboxProps } from '../../Checkbox';
import { TooltipV2 } from '@compstak/ui-kit';
import { CheckedState } from '@radix-ui/react-checkbox';
import { useDebounce } from 'use-debounce';

type Props = Pick<
	MultiselectProps,
	'options' | 'usesStringNames' | 'renderOption'
> & {
	addOrRemoveItem: (value: string, checked: boolean) => void;
	isChecked: (option: FilterOption) => CheckedState;
	hasSearch?: boolean;
	searchInputPlaceholder?: string;
};

export const MultiSelectOptions = ({
	options,
	usesStringNames,
	renderOption,
	addOrRemoveItem,
	isChecked,
	hasSearch,
	searchInputPlaceholder,
}: Props) => {
	const { searchTerm, element } = useListSearchInput({
		inputPlaceholder: searchInputPlaceholder ?? '',
		inputDataQaId: multiMarketSelectorSearchInputTestId,
		inputHeight: '2rem',
		iconSize: 12,
		autoFocus: false,
	});

	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	const filteredOptions = useMemo(() => {
		if (!debouncedSearchTerm) {
			return options;
		}

		return options
			.filter((option) => {
				return option.type === 'header'
					? true
					: option.name.toLowerCase().includes(debouncedSearchTerm);
			})
			.filter((option, index, options) =>
				option.type === 'header'
					? options[index + 1]
						? options[index + 1].type !== 'header'
						: false
					: true
			);
	}, [debouncedSearchTerm, options]);

	return (
		<>
			{hasSearch && <SearchStyled>{element}</SearchStyled>}
			{filteredOptions.length > 0 ? (
				<ul>
					{filteredOptions.map((option) => {
						const idValue =
							String(usesStringNames ? option.name : option.id) ?? '';
						const idsValue = usesStringNames ? option.names : option.ids;

						const abridgedName =
							option.name.length > 20
								? `${option.name.substring(0, 20)}...`
								: option.name;
						const tooltip = option.disabledTooltip
							? option.disabledTooltip
							: option.name.length > 20
								? option.name
								: '';

						const resultValue =
							idsValue && idsValue.length ? idsValue.join('|') : idValue;

						const id = idValue + (idsValue || idValue);
						const key = option.key ?? id;
						const isDisabled = Boolean(option.disabledTooltip);

						return (
							<li key={key}>
								{option.type === 'header' ? (
									<HeaderOption>{option.name}</HeaderOption>
								) : (
									<TooltipV2 content={tooltip}>
										<OptionContent
											isDisabled={isDisabled}
											data-state={isDisabled ? 'disabled' : 'active'}
											htmlFor={id}
										>
											<MultiSelectCheckbox
												id={id}
												checked={isChecked(option)}
												name={option.name}
												disabled={isDisabled}
												onCheckedChange={(checked) =>
													addOrRemoveItem(resultValue, !!checked)
												}
											/>
											<OptionRenderWrap>
												{renderOption
													? renderOption(option, searchTerm)
													: abridgedName}
											</OptionRenderWrap>
										</OptionContent>
									</TooltipV2>
								)}
							</li>
						);
					})}
				</ul>
			) : (
				<EmptyWrap>
					<EmptyHeader>No results found</EmptyHeader>
					<EmptyDescription>
						There are no results that match your search criteria.
					</EmptyDescription>
				</EmptyWrap>
			)}
		</>
	);
};

export const MultiSelectCheckbox = memo((props: CheckboxProps) => {
	return <CheckboxOrigin {...props} iconSize={8} rootSize={10} />;
});

MultiSelectCheckbox.displayName = 'MultiSelectCheckbox';

export const OptionContent = styled.label<{ isDisabled?: boolean }>`
	&&&&&& {
		display: flex;
		align-items: center;
		padding: 0.5rem;
		${({ isDisabled }) => isDisabled && `cursor: not-allowed;`}
		&:hover {
			background-color: ${({ theme }) => theme.colors.neutral.n300};
		}
	}
`;

export const OptionRenderWrap = styled.span`
	margin-left: 0.5rem;
	flex-grow: 1;
	font-size: 0.75rem;
	color: ${({ theme }) => theme.colors.neutral.n0};
`;

const EmptyWrap = styled.div`
	width: 100%;
	height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0.5rem 30px;
`;

const EmptyHeader = styled.div`
	color: ${({ theme }) => theme.colors.neutral.n0};
	text-align: center;
	font-family: 'OpenSans', sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 8px;
`;

const EmptyDescription = styled.div`
	color: ${({ theme }) => theme.colors.neutral.n0};
	text-align: center;
	font-family: 'OpenSans', sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 300;
	line-height: 16px;
`;

const SearchStyled = styled.div`
	&&&&&&& {
		${ListSearchInputStyled} {
			padding: 4px 8px;
			border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.n100};
			background-color: ${({ theme }) => theme.colors.gray.gray700};
		}
		${StyledCrossIcon} {
			margin-right: 0;
		}
		input {
			all: unset;
			width: 100%;
			font-size: 12px;
			margin-left: 8px;
			cursor: text;
		}
	}
`;

const HeaderOption = styled.span`
	padding: 0.5rem 0.5rem 0.25rem 0.5rem;
	flex-grow: 1;
	font-size: 0.6875rem;
	display: flex;
	line-height: 18px;
	font-weight: 350;
	color: ${({ theme }) => theme.colors.blue.blue500};
	text-transform: uppercase;
	cursor: auto;
`;
