import React, { useMemo } from 'react';
import { Market } from '@compstak/common';
import { PermissionsState } from 'Pages/Login/reducers';
import { MarketListItems } from './MarketListItems';

type MarketListSearchViewProps = {
	changeMarket: (market: Market) => void;
	isEnterprise: boolean;
	permissions: PermissionsState;
	searchTerm: string;
	searchTermsToMarkets: Record<string, Market>;
	type: 'sales' | 'lease';
};

export const MarketListSearchView = ({
	changeMarket,
	isEnterprise,
	permissions,
	searchTerm,
	searchTermsToMarkets,
	type,
}: MarketListSearchViewProps) => {
	const matchingSearchTerms = useMemo(
		() =>
			Object.keys(searchTermsToMarkets).filter((stateName) =>
				stateName.includes(searchTerm.toLowerCase())
			),
		[searchTerm, searchTermsToMarkets]
	);

	return (
		<MarketListItems
			isEnterprise={isEnterprise}
			markets={matchingSearchTerms.map(
				(matchingSearchTerm) => searchTermsToMarkets[matchingSearchTerm]
			)}
			changeMarket={changeMarket}
			permissions={permissions}
			type={type}
		/>
	);
};
