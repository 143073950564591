import { AnalyticsPopup, TenantIndustryLayer } from 'maps';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useFilters } from 'reducers/filtersReducer';
import { MAP_LAYERS_ORDER } from 'Pages/Search/Map';
import { useTenantIndustryAnalyticsData } from './useTenantIndustryAnalyticsData';

const ID = 'TenantIndustryAnalyticsLayer';

export const TenantIndustryAnalytics = () => {
	const [filters] = useFilters();

	const market = getFiltersMarkets(filters)[0];

	const { data } = useTenantIndustryAnalyticsData();

	return (
		<TenantIndustryLayer
			data={data}
			marketName={market.name}
			circlesLayerProps={{
				getPopupContent: (f, closePopup) => {
					return (
						<AnalyticsPopup
							propertyId={f.properties.propertyId}
							closePopup={closePopup}
						/>
					);
				},
			}}
			id={ID}
			order={MAP_LAYERS_ORDER[ID]}
		/>
	);
};
