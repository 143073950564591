import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalTitle,
	ModalParagraph,
} from 'Components/Modals/common/UI';
import { CompType } from 'types/comp';
import pluralizeCompType from 'util/pluralizeCompType';
import { useState } from 'react';
import { AddToPortfolioDialog } from './AddToPortfolioDialog';

type Props = {
	propertyIds: number[];
	closePropertyCountModal: NoArgCallback;
	compType: CompType;
};

export const PropertyCountBeforeAddToPortfolioModal = ({
	propertyIds,
	closePropertyCountModal,
	compType,
}: Props) => {
	const [showAddToPortfolioDialog, setShowAddToPortfolioDialog] =
		useState(false);
	const propertyCount = propertyIds.length;
	const propertyOrProperties = propertyCount > 1 ? 'properties' : 'property';

	return (
		<Modal onClose={closePropertyCountModal}>
			<ModalTitle>
				{`Add ${propertyCount} ${propertyOrProperties} to portfolio?`}
			</ModalTitle>
			<ModalParagraph>
				{`Selected ${pluralizeCompType(compType, false)} are associated with ${propertyCount} ${propertyOrProperties}. Therefore, ${propertyCount} ${propertyOrProperties} will be added to the portfolio.`}
			</ModalParagraph>
			<ModalButtons>
				<ModalButton
					variant="secondary"
					type="button"
					onClick={closePropertyCountModal}
				>
					Cancel
				</ModalButton>
				<ModalButton
					type="submit"
					variant="primary"
					onClick={() => {
						setShowAddToPortfolioDialog(true);
					}}
				>
					Continue to Add
				</ModalButton>
			</ModalButtons>
			{showAddToPortfolioDialog && (
				<AddToPortfolioDialog
					propertyIds={propertyIds}
					closeDialog={() => {
						closePropertyCountModal();
						setShowAddToPortfolioDialog(false);
					}}
				/>
			)}
		</Modal>
	);
};
