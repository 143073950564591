import { SpaceTypeName } from 'api';
import { useState } from 'react';

export const usePropertyLeasesOptionsState = () => {
	const [checkedSpaceType, setCheckedSpaceType] =
		useState<CheckedLeaseSpaceType>('All Leases');

	const [checkedStatus, setCheckedStatus] =
		useState<CheckedLeaseStatus>('All Leases');

	return {
		checkedSpaceType,
		setCheckedSpaceType,
		checkedStatus,
		setCheckedStatus,
	};
};

export type CheckedLeaseStatus = 'All Leases' | 'Only Active Leases';
export type CheckedLeaseSpaceType = 'All Leases' | SpaceTypeName;
