import React from 'react';
import analyticsImage from './Analytics-Screenshot.jpg';
import analyticsService from 'services/analyticsRepo';
import { useModal } from 'providers/ModalProvider';
import { useUser } from 'Pages/Login/reducers';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import styled from 'styled-components';
import { ThanksForDemoRequestModal } from '../ThanksForDemoRequestModal/ThanksForDemoRequestModal';

type NoAnalyticsAccessModalProps = {
	onClose?: () => void;
};

export const NoAnalyticsAccessModal = ({
	onClose: _onClose,
}: NoAnalyticsAccessModalProps) => {
	const { openModal, closeModal } = useModal();
	const user = useUser();

	const onClose = () => {
		closeModal();
		_onClose?.();
	};

	const handleDemoRequest = () => {
		analyticsService.requestDemo(user.id);
		closeModal();
		openModal({
			modalContent: <ThanksForDemoRequestModal onClose={_onClose} />,
		});
	};

	return (
		<div>
			<ModalTitle>CompStak Analytics</ModalTitle>
			<ModalParagraph>
				CompStak Analytics provides National Access so that you can analyze
				leasing trends among multiple datasets within Markets, Submarkets, Saved
				Searches and More!
			</ModalParagraph>
			<AnalyticsScreenshot src={analyticsImage} />
			<ModalButtons>
				<ModalButton variant="secondary" onClick={onClose}>
					NO, THANKS
				</ModalButton>
				<ModalButton variant="primary" onClick={handleDemoRequest}>
					ACCESS ANALYTICS TODAY
				</ModalButton>
			</ModalButtons>
		</div>
	);
};

const AnalyticsScreenshot = styled.img`
	width: 428px;
	box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.gray.gray700};
	border: solid 1px ${({ theme }) => theme.colors.gray.gray700};
	display: block;
	margin: auto;
`;
