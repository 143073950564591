import { Button, Download } from '@compstak/ui-kit';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { routes } from 'router/routes/routes';
import { ExportPdfProvider } from '../pdf/ExportPdfProvider';
import { ExportPdfData, useExportPdfData } from '../pdf/useExportPdfData';
import { ExportPdfDialog } from './ExportPdfDialog';

export const ExportButton = () => {
	const [isOpen, setIsOpen] = useState(false);

	const params = routes.propertyById.useParams();
	const { data, isLoading } = useExportPdfData({
		propertyId: params.propertyId,
	});

	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
			<Dialog.Trigger asChild>
				<Button
					variant="primary"
					icon={<Download isInheritedColor />}
					isLoading={isLoading}
					disabled={isLoading}
				>
					EXPORT TO PDF
				</Button>
			</Dialog.Trigger>
			{isOpen && (
				<ExportPdfProvider data={data as ExportPdfData}>
					<ExportPdfDialog data={data as ExportPdfData} />
				</ExportPdfProvider>
			)}
		</Dialog.Root>
	);
};
