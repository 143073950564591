import React, { useEffect, useState } from 'react';
// @ts-expect-error expected
import geoViewport from 'geo-viewport';
import {
	point as turfPoint,
	featureCollection as turfFeatureCollection,
	Point,
	Feature,
} from '@turf/helpers';
import bbox from '@turf/bbox';

import { ExportPDFToolbar } from './ExportPDFToolbar';
import LogoPicker from './LogoPicker';

import SingleCompLandscape, {
	PortfolioSalesComp,
	SinglePageComp,
} from 'ui/Components/Export/SingleCompLandscape';
import LineItemLandscape from 'ui/Components/Export/LineItemLandscape';
import { LeaseComp, SalesComp } from 'types';
import { formatComp } from 'util/comp-format/src/format';
import { isPortfolioSaleComp } from 'utils/compHelpers';
import { ExportPDFLayoutRouteParams, ExportPDFLayoutRouteProps } from 'router';
import { useHistoryState } from 'router/HistoryProvider';
import { useDispatch } from 'react-redux';
import {
	clearCustomData,
	exportActions as exportActionsOriginal,
	prepareCustomExport,
	updateCustomExportMetaData,
} from 'actions/export';
import { useUser } from 'Pages/Login/reducers';
import { useAppSelector } from 'util/useAppSelector';
import { RouteComponentProps } from 'router/migration/types';
import { bindActionCreators } from 'redux';
import { ExportPDFModal } from './UI';
import { Spinner } from '@compstak/ui-kit';
import { isEqual } from 'lodash';

const pdfToolbarTitle = 'Edit any field by clicking it below.';

type ExportPDFProps = RouteComponentProps<ExportPDFLayoutRouteParams> & {
	route: ExportPDFLayoutRouteProps;
};

type CompAddress = {
	propertyAddress: string;
	pinLabel: number;
	lat: number;
	lon: number;
};

export type SaleCompAddress = CompAddress;

export type LeaseCompAddress = CompAddress & {
	leaseComps: LeaseComp[];
};

const MAIN_MAP_WIDTH = 516;
const MAIN_MAP_HEIGHT = 240;
const MAIN_MAP_PADDING = 70;

export const ExportPDFLayout = ({
	route: { compType },
	params: { compIds },
}: ExportPDFProps) => {
	const [showLineItemComps, setShowLineItemComps] = useState(true);
	const [showIndividualComps, setShowIndividualComps] = useState(true);
	const [compsAddresses, setCompsAddresses] = useState<
		Map<string, LeaseCompAddress | SaleCompAddress>
	>(new Map());

	const user = useUser();
	const exportState = useAppSelector((store) => store.export);

	const { goBack } = useHistoryState();
	const dispatch = useDispatch();

	const exportActions = bindActionCreators(exportActionsOriginal, dispatch);

	useEffect(() => {
		const parsedCompIds = String(compIds)
			.split(',')
			.map((id) => parseInt(id));
		if (
			!isEqual(
				parsedCompIds,
				exportState.exportData.leases.map(({ id }) => id)
			)
		) {
			dispatch(
				prepareCustomExport({
					compIds: parsedCompIds,
					user,
					compType,
				})
			);
		}
	}, [compIds]);

	useEffect(() => {
		if (exportState.exportData.formattedLeases) {
			updateCompsAddresses(exportState.exportData.formattedLeases);
		}
	}, [exportState.exportData.formattedLeases]);

	useEffect(() => {
		return () => {
			dispatch(clearCustomData());
		};
	}, []);

	const updateCompsAddresses = (formattedComps: LeaseComp[] | SalesComp[]) => {
		const compsAddresses =
			compType === 'sale'
				? getCompsAddresses(formattedComps)
				: getLeaseCompsAddresses(formattedComps as LeaseComp[]);
		setCompsAddresses(compsAddresses);
		setMapViewport(
			compsAddresses,
			MAIN_MAP_WIDTH - MAIN_MAP_PADDING,
			MAIN_MAP_HEIGHT - MAIN_MAP_PADDING
		);
	};

	const setMapViewport = (
		compsByAddress: Map<string, CompAddress>,
		mapWidth: number,
		mapHeight: number
	) => {
		const coords: Feature<Point>[] = [];
		compsByAddress.forEach((addressGroup) => {
			coords.push(turfPoint([addressGroup.lon, addressGroup.lat]));
		});
		const bounds = bbox(turfFeatureCollection(coords));
		const viewport = geoViewport.viewport(bounds, [
			mapWidth,
			Math.max(240, mapHeight),
		]);
		dispatch(updateCustomExportMetaData('viewport', viewport));
	};

	const toggleShowLineItemComps = () => {
		if (!showLineItemComps || showIndividualComps) {
			setShowLineItemComps(!showLineItemComps);
		}
	};

	const toggleShowIndividualComps = () => {
		if (!showIndividualComps || showLineItemComps) {
			setShowIndividualComps(!showIndividualComps);
		}
	};

	const formattedComps = exportState.exportData.formattedLeases;
	const sectionedComps = exportState.exportData.sectionedLeases;

	// portfolio sale with n properties => n comps (portfolio = [property[i]]) , i=(0,n)
	// this is "hack" to get SingleCompLandscape to work for now
	// TODO: remove this logic when the SingleCompLandscape is updated
	const compsByOneProperty = (
		compType === 'sale'
			? (exportState.exportData.leases as SalesComp[]).flatMap<
					SalesComp | PortfolioSalesComp
				>((comp) => {
					return isPortfolioSaleComp(comp)
						? comp.portfolio.map<PortfolioSalesComp>(
								(portfolioItem, index) => ({
									...comp,
									...portfolioItem,
									portfolio: [portfolioItem],
									indexInPortfolio: index + 1,
									oneOfProperties: comp.portfolio.length,
								})
							)
						: comp;
				})
			: exportState.exportData.leases
	) as SinglePageComp[];

	const disclaimerText =
		'All information is not guaranteed and should be verified by personal inspection by and/or with the appropriate professional(s).';

	const isLoading =
		exportState.exportData.leases.length === 0 || compsAddresses.size === 0;

	return (
		<ExportPDFModal
			onClose={goBack}
			overlayChildren={(ref) => {
				return (
					<ExportPDFToolbar
						ref={ref}
						isLoading={isLoading}
						title={pdfToolbarTitle}
						exportData={exportState.exportData}
						showLineItemComps={showLineItemComps}
						showIndividualComps={showIndividualComps}
						toggleShowLineItemComps={toggleShowLineItemComps}
						toggleShowIndivudualComps={toggleShowIndividualComps}
						onComplete={goBack}
						compType={compType}
					/>
				);
			}}
		>
			{isLoading && <Spinner isCentered />}
			{!isLoading && showLineItemComps && (
				<LineItemLandscape
					logoPickers={{
						companyLogo: (
							<LogoPicker
								// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the full error message
								attribute="companyLogo"
								placeholder="+ Add your logo"
								logo={exportState.exportData.companyLogo}
								setLogo={exportActions.updateCustomExportMetaData}
							/>
						),
						clientLogo: (
							<LogoPicker
								// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the full error message
								attribute="clientLogo"
								placeholder="+ Add your client's logo"
								logo={exportState.exportData.clientLogo}
								setLogo={exportActions.updateCustomExportMetaData}
							/>
						),
					}}
					exportData={exportState.exportData}
					exportActions={exportActions}
					compsAddresses={compsAddresses}
					editable={true}
					disclaimerText={disclaimerText}
					compType={compType}
					formattedComps={formattedComps}
					mapWidth={MAIN_MAP_WIDTH}
					mapHeight={MAIN_MAP_HEIGHT}
				/>
			)}
			{!isLoading &&
				showIndividualComps &&
				compsByOneProperty.map((comp, index) => (
					<SingleCompLandscape
						key={index}
						logoPicker={
							<LogoPicker
								//@ts-expect-error expected
								setLogo={exportActions.updateCustomExportMetaData}
								attribute="companyLogo"
								logo={exportState.exportData.companyLogo}
							/>
						}
						exportData={exportState.exportData}
						exportActions={exportActions}
						editable={true}
						lease={formatComp(comp, compType)}
						sectioned={sectionedComps?.[index]}
						raw={comp}
						pinLabel={compsAddresses.get(comp.buildingAddress)!.pinLabel}
						disclaimerText={disclaimerText}
						compType={compType}
					/>
				))}
		</ExportPDFModal>
	);
};

// pins for addresses are unique,
const getCompsAddresses: (
	comps: LeaseComp[] | SalesComp[]
) => Map<string, SaleCompAddress> = (comps) => {
	const result = new Map();

	comps.forEach((comp) => {
		if (isPortfolioSaleComp(comp)) {
			comp.portfolio.forEach((property) => {
				const { buildingAddress, geoPoint } = property;
				if (!result.has(buildingAddress)) {
					result.set(buildingAddress, {
						propertyAddress: buildingAddress,
						pinLabel: result.size + 1,
						lat: geoPoint.lat,
						lon: geoPoint.lon,
					});
				}
			});
		} else {
			// @ts-expect-error TS2339: Property 'buildingAddress' doe...
			const { buildingAddress, geoPoint } = comp;
			if (!result.has(buildingAddress)) {
				result.set(buildingAddress, {
					propertyAddress: buildingAddress,
					pinLabel: result.size + 1,
					lat: geoPoint.lat,
					lon: geoPoint.lon,
				});
			}
		}
	});

	return result;
};

// lease comps are grouped by address
const getLeaseCompsAddresses: (
	comps: LeaseComp[]
) => Map<string, LeaseCompAddress> = (comps) => {
	const result = new Map();

	comps.forEach((comp) => {
		const { buildingAddress, geoPoint } = comp;
		if (!result.has(buildingAddress)) {
			result.set(buildingAddress, {
				propertyAddress: buildingAddress,
				pinLabel: result.size + 1,
				lat: geoPoint.lat,
				lon: geoPoint.lon,
				leaseComps: [],
			});
		}
		result.get(buildingAddress).leaseComps.push(comp);
	});

	return result;
};
