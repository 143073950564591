import { useState } from 'react';
import { CompstakEstimate } from 'Pages/PropertyPageV3/hooks/useCompstakEstimatesData';
import { usePropertyPageDataContext } from 'Pages/PropertyPageV3/PropertyPageDataProvider';

export const useEditableCompstakEstimates = () => {
	const { compstakEstimates } = usePropertyPageDataContext();

	const [editableCompstakEstimates, setEditableCompstakEstimates] =
		useState<EditableCompstakEstimates>({
			visibleFields: {
				startingRent: true,
				effectiveRent: true,
			},
			...compstakEstimates,
		});

	return {
		editableCompstakEstimates,
		setEditableCompstakEstimates,
	};
};

type EditableCompstakEstimates = {
	visibleFields: Record<CompstakEstimate, boolean>;
} & ReturnType<typeof usePropertyPageDataContext>['compstakEstimates'];
