import { Image, Text, View } from '@react-pdf/renderer';
import { useTheme } from 'styled-components';

type Props = {
	title: string;
	logo: string | null;
};

export const PageHeader = ({ title, logo }: Props) => {
	const theme = useTheme();

	const rootStyle = {
		borderBottom: `1px solid ${theme.colors.gray.gray700}`,
		paddingBottom: 4,
		marginBottom: 5,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		gap: 6,
	} as const;

	const titleStyle = {
		color: theme.colors.gray.gray700,
		fontSize: 8,
		fontWeight: 400,
		lineHeight: 1,
	};

	return (
		<View style={rootStyle} fixed>
			{title && <Text style={titleStyle}>{title}</Text>}
			{logo && (
				<Image
					src={() => fetch(logo!).then((res) => res.url)}
					style={{ width: 35, height: 35, marginLeft: 'auto' }}
				/>
			)}
		</View>
	);
};
