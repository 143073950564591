import { isNumber } from '@compstak/ui-kit';
import {
	DateIntervalFiltersKeys,
	FilterDateInterval,
	FilterNumberInterval,
	FilterStringInterval,
	IntervalFiltersKeys,
	NumberIntervalFiltersKeys,
	StringIntervalFiltersKeys,
} from '../types';
import { stringToMonths } from './timespan';
import {
	isDateIntervalFilterKey,
	isNumberIntervalFilterKey,
	isStringIntervalFilterKey,
} from './isIntervalFilterKey';
import { isNil } from 'lodash';

export const validateIntervalFilter = (
	key: IntervalFiltersKeys,
	mergedObj:
		| Partial<FilterNumberInterval>
		| Partial<FilterStringInterval>
		| Partial<FilterDateInterval>
) => {
	if (isNumberIntervalFilterKey(key)) {
		validateNumberIntervalFilter(
			key,
			mergedObj as Partial<FilterNumberInterval>
		);
		return;
	}

	if (isDateIntervalFilterKey(key)) {
		validateDateIntervalFilter(key, mergedObj as Partial<FilterDateInterval>);
		return;
	}

	if (isStringIntervalFilterKey(key)) {
		validateStringIntervalFilter(
			key,
			mergedObj as Partial<FilterStringInterval>
		);
		return;
	}
};

const validateNumberIntervalFilter = (
	key: NumberIntervalFiltersKeys,
	mergedObj: Partial<FilterNumberInterval>
) => {
	if (!isNil(mergedObj.min) && !isNumber(mergedObj.min)) {
		throw new SyntaxError('Min must be a number.');
	}
	if (!isNil(mergedObj.max) && !isNumber(mergedObj.max)) {
		throw new SyntaxError('Max must be a number.');
	}

	const mustBePositive = key !== 'floors';

	if (mustBePositive && !isNil(mergedObj.min) && mergedObj.min < 0) {
		throw new RangeError('Min must be positive.');
	}
	if (mustBePositive && !isNil(mergedObj.max) && mergedObj.max < 0) {
		throw new RangeError('Max must be positive.');
	}
	if (
		!isNil(mergedObj.min) &&
		!isNil(mergedObj.max) &&
		mergedObj.min > mergedObj.max
	) {
		throw new RangeError('Min must be equal to or smaller than max.');
	}

	if (key === 'occupancy') {
		if (!isNil(mergedObj.min) && mergedObj.min > 1) {
			throw new SyntaxError('Min cannot exceed 100');
		}
		if (!isNil(mergedObj.max) && mergedObj.max > 1) {
			throw new SyntaxError('Max cannot exceed 100');
		}
	}
};

const validateStringIntervalFilter = (
	key: StringIntervalFiltersKeys,
	mergedObj: Partial<FilterStringInterval>
) => {
	if (key === 'leaseTerm') {
		Object.typedKeys(mergedObj).forEach((key) => {
			if (key === 'allowFallback') {
				return;
			}

			const val = mergedObj[key];
			if (val != null) {
				const monthsVal = stringToMonths(val);

				if (
					key === 'min' &&
					!!mergedObj.max &&
					monthsVal >= stringToMonths(mergedObj.max)
				) {
					throw new RangeError('Min must be smaller than max.');
				}
				if (
					key === 'max' &&
					!!mergedObj.min &&
					monthsVal <= stringToMonths(mergedObj.min)
				) {
					throw new RangeError('Max must be larger than min.');
				}
			}
		});
	}
};

const validateDateIntervalFilter = (
	_key: DateIntervalFiltersKeys,
	mergedObj: Partial<FilterDateInterval>
) => {
	if (mergedObj.min && mergedObj.max && mergedObj.min > mergedObj.max) {
		throw new RangeError('Min must be equal to or smaller than max.');
	}
};
