import { User } from '@compstak/common';
import {
	useSearchPropertiesNavHeaderContext,
	useSearchSalesNavHeaderContext,
} from 'Components';
import { ReorderGlobalStyle } from '../../../../Components/ReorderGlobalStyle';
import { Cell, Column, Plugins } from 'fixed-data-table-2';
import { FiltersObject } from 'models/filters/types';
import { getSetFilterKeys } from 'models/filters/util/getSetFilters';
import { filtersToQueryString } from 'models/filters/util/urls';
import { PINS_LIMIT } from 'Pages/Search/Map/actions';
import { SearchState, SearchStateAverages } from 'Pages/Search/searchReducer';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Comp, CompType } from 'types/comp';
import { FeatureFlags } from 'api/featureFlags/types';
import { TableField } from 'types/table';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { getAttributesById } from 'util/comp-format/src/format';
import pluralizeCompType from 'util/pluralizeCompType';
import { SelectionActions } from '../../../../actions/selection';
import ErrorIcon from '../../../../ui/svg_icons/error.svg';
import { PageActions } from '../../actions';
import CheckboxAndButtonCell from './CheckboxAndButtonCell';
import DataCell, { DataCellProps } from './DataCell';
import { HeaderCell } from './HeaderCell';
import ResponsiveTable from './ResponsiveTable';
import TableSettingsButton from './TableSettingsButton';
import { useTableHeaderCell } from 'hooks';

type Props = {
	averagesNeeded: false | Comp[];
	className?: string;
	compType: CompType;
	actionsColumnBreakPoint?: number;
	actionsColumnWidthExpanded?: number;
	actionsColumnWidthCollapsed?: number;
	columnFreeCompSpacer?: number;
	lowestLoaded: number;
	highestLoaded: number;
	fieldsLoading: boolean;
	filters: FiltersObject;
	pageActions: PageActions;
	isLoadingComps: boolean;
	selectAllPending: boolean;
	freeComps?: number;
	rowIndex: number;
	totalResults: number;
	lastExpectedChange: Date | null;
	leases: SearchState['leases'];
	sales: SearchState['sales'];
	properties: SearchState['properties'];
	selectionActions: SelectionActions;
	averages: SearchStateAverages;
	fields?: TableField[] | null;
	featureFlags: FeatureFlags;
	search: SearchState['search'];
	user: User;
	selection: Map<number, boolean>;
	onFilterChange: (filters: Partial<FiltersObject>) => void;
};

export const TableView = memo<Props>((props) => {
	const {
		compType,
		pageActions,
		averagesNeeded,
		selectionActions,
		filters,
		className,
		actionsColumnBreakPoint = 1500,
		actionsColumnWidthExpanded = 216,
		actionsColumnWidthCollapsed = 136,
		columnFreeCompSpacer = 0,
		freeComps = 0,
		lowestLoaded,
		highestLoaded,
		search,
		fields = [],
		selectAllPending,
		totalResults,
		fieldsLoading,
		averages,
		selection,
		user,
	} = props;

	const queryString = filtersToQueryString(filters);
	const { loadFields, persistColumns, reorderColumns } = pageActions;
	const {
		getAverages,
		deselectAll,
		selectComps,
		deselectComps,
		selectAllComps,
	} = selectionActions;

	const ref = useRef<HTMLDivElement>(null);

	const { onColumnReorderStart, onColumReorderEnd, activeReorderColumn } =
		useTableHeaderCell();

	const [actionsColumnWidth, setActionsColumnWidth] = useState(
		actionsColumnWidthExpanded
	);

	const [range, setRange] = useState<number | void>();
	const timeout = useRef<null | number>(null);
	const compTypePlural = pluralizeCompType(compType, false);

	useEffect(() => {
		loadFields(compType);
	}, [compType, loadFields]);

	const allSelected = selection.size === totalResults;
	const isAllCheckboxIntermediated =
		selection.size > 0 && selection.size < totalResults;
	const isSomeSelected = allSelected || isAllCheckboxIntermediated;

	useEffect(() => {
		if (averagesNeeded) {
			getAverages(filters, averagesNeeded, compType);
		}
	}, [averagesNeeded, getAverages, compType, filters]);

	const loadRow = useCallback(
		(rowIndex: number) => {
			return props[compTypePlural][rowIndex] || null;
		},
		[compType, props]
	);

	const loadRows = useCallback(
		// @ts-expect-error TS7006: Parameter 'lowest' implicitly ...
		(lowest, highest, sizeOfPage) => {
			if (lowest < lowestLoaded || highest > highestLoaded) {
				let request:
					| PageActions['requestLeases']
					| PageActions['requestSales']
					| PageActions['requestProperties'];

				switch (compType) {
					case 'lease': {
						request = pageActions.requestLeases;
						break;
					}
					case 'sale': {
						request = pageActions.requestSales;
						break;
					}
					case 'property': {
						request = pageActions.requestProperties;
						break;
					}
					default:
						throw Error(`Unknown compType: ${compType}`);
				}

				request(
					lowest,
					highest,
					sizeOfPage,
					lowestLoaded,
					highestLoaded,
					filters,
					// @ts-expect-error TS2345: Argument of type '{ averages: ...
					search
				);
			}
		},
		[compType, highestLoaded, lowestLoaded, pageActions, search]
	);

	const handleCheckbox = useCallback(
		(comp: Comp, event: React.ChangeEvent<HTMLInputElement>) => {
			if (selectAllPending) {
				return;
			}
			if (event.target.checked) {
				selectComps([comp]);
			} else {
				deselectComps([comp.id]);
			}
		},
		[deselectComps, selectAllPending, selectComps]
	);

	const selectAll: React.ChangeEventHandler<HTMLInputElement> = useCallback(
		(event) => {
			if (!isSomeSelected && event.target.checked) {
				selectAllComps(filters, compType);
			} else {
				deselectAll();
			}
		},
		[compType, deselectAll, filters, selectAllComps, isSomeSelected]
	);

	const saveColumns = useCallback(
		// @ts-expect-error TS7006: Parameter 'newSize' implicitly...
		(newSize, columnKey) => {
			const newFields = fields?.map((field) => ({
				compAttributeId: field.id,
				width: field.name === columnKey ? newSize : field.width,
			}));

			setTimeout(() => {
				// @ts-expect-error TS2345: Argument of type '{ compAttrib...
				persistColumns(newFields, compType);
			}, 4);
		},
		[compType, fields, persistColumns]
	);

	const setActionsColumnWidthExtended = useCallback(() => {
		const columnWidthSpacer = freeComps > 0 ? columnFreeCompSpacer : 0;
		const isSmall = window.innerWidth < actionsColumnBreakPoint;
		const width = isSmall
			? actionsColumnWidthCollapsed
			: actionsColumnWidthExpanded;

		setActionsColumnWidth(columnWidthSpacer + width);
	}, [
		actionsColumnBreakPoint,
		actionsColumnWidthCollapsed,
		actionsColumnWidthExpanded,
		columnFreeCompSpacer,
		freeComps,
	]);

	const onColumnReorder = useCallback(
		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		(event) => {
			reorderColumns(event, fields, compType);
		},
		[compType, fields, reorderColumns]
	);

	const mergeDynamicColumnsWithFields = () => {
		const formatFields = getAttributesById(compType);

		const specialPropertyColumns = {
			propertyMarketStartingRent: 260,
			propertyAverageTransactionSize: 262,
			lastSalePrice: 272,
			propertySqFtExpiringInTwelveMonths: 274,
		};

		const usedFilters = getSetFilterKeys(filters);

		// @ts-expect-error TS18047: 'fields' is possibly 'null'....
		const fieldList = fields.map((field) => field.name);

		// @ts-expect-error TS7034: Variable 'newFields' implicitl...
		const newFields = [];

		Object.typedKeys(specialPropertyColumns).forEach((name) => {
			if (usedFilters.includes(name)) {
				if (!fieldList.includes(name)) {
					newFields.unshift(formatFields[specialPropertyColumns[name]]);
				}
			}
		});

		// @ts-expect-error TS2488: Type 'TableField[] | null' mus...
		return [...newFields, ...fields];
	};

	const selectAllTooltip = `Select up to ${abbreviateNumber(
		PINS_LIMIT
	)} ${pluralizeCompType(compType, true)} comps`;

	const { initNavHeader: initSalesNavHeader } =
		useSearchSalesNavHeaderContext();

	const { initNavHeader: initPropertiesNavHeader } =
		useSearchPropertiesNavHeaderContext();

	const createColumns = () => {
		// @ts-expect-error TS7034: Variable 'lowest' implicitly h...
		let lowest;
		// @ts-expect-error TS7034: Variable 'highest' implicitly ...
		let highest;
		// @ts-expect-error TS7034: Variable 'lastIndex' implicitl...
		let lastIndex;
		// @ts-expect-error TS7034: Variable 'lowestThisRun' impli...
		let lowestThisRun;
		// @ts-expect-error TS7034: Variable 'highestThisRun' impl...
		let highestThisRun;
		const loadRowsWrapper = () => {
			if (!range) {
				// @ts-expect-error TS7005: Variable 'highest' implicitly ...
				setRange(highest - lowest);
			}

			timeout.current = null;
			lowestThisRun = highestThisRun = undefined;
			// @ts-expect-error TS7005: Variable 'lowest' implicitly h...
			loadRows(lowest, highest, range);
		};

		const header = (
			<Cell className="header-cell actions-header-cell">
				<input
					id="select-all-comps"
					data-qa-id="select-all-comps-input"
					onChange={selectAll}
					type="checkbox"
					className={`checkbox ${
						isAllCheckboxIntermediated ? 'tableview-indeterminate-checkbox' : ''
					}`}
					checked={allSelected}
				/>
				<label
					data-qa-id="select-all-comps-label"
					htmlFor="select-all-comps"
					className="checkbox"
					data-tooltip={selectAllTooltip}
					data-tooltipdelay="0"
				/>
				<TableSettingsButton
					actionsColumnWidth={actionsColumnWidth}
					{...props}
					columnFreeCompSpacer={columnFreeCompSpacer}
					actionsColumnWidthExpanded={actionsColumnWidthExpanded}
				/>
				<div className="averages">Averages</div>
			</Cell>
		);

		const actionsColumn = (
			<Column
				header={header}
				width={actionsColumnWidth}
				key="actions"
				fixed={true}
				cell={(cellProps) => {
					// this block determines which rows need to be loaded.
					if (cellProps.rowIndex != null) {
						if (
							// @ts-expect-error TS7005: Variable 'lastIndex' implicitl...
							lastIndex !== cellProps.rowIndex + 1 &&
							// @ts-expect-error TS7005: Variable 'lastIndex' implicitl...
							lastIndex !== cellProps.rowIndex - 1
						) {
							lowestThisRun = undefined;
							highestThisRun = undefined;
						}
						lastIndex = cellProps.rowIndex;
						// @ts-expect-error TS7005: Variable 'lowestThisRun' impli...
						if (lowestThisRun === undefined) {
							lowestThisRun = cellProps.rowIndex;
						} else {
							// @ts-expect-error TS7005: Variable 'lowestThisRun' impli...
							lowestThisRun = Math.min(lowestThisRun, cellProps.rowIndex);
						}
						// @ts-expect-error TS7005: Variable 'highestThisRun' impl...
						if (highestThisRun === undefined) {
							highestThisRun = cellProps.rowIndex;
						} else {
							// @ts-expect-error TS7005: Variable 'highestThisRun' impl...
							highestThisRun = Math.max(highestThisRun, cellProps.rowIndex);
						}
						if (!range) {
							lowest = lowestThisRun;
							highest = highestThisRun;
						} else {
							lowest = Math.max(0, lowestThisRun, cellProps.rowIndex);
							highest = Math.min(highestThisRun, cellProps.rowIndex);
						}
					}

					if (!timeout.current) {
						// @ts-expect-error TS2322: Type 'Timeout' is not assignab...
						timeout.current = setTimeout(loadRowsWrapper, 100);
					}
					const comp = props[compTypePlural][cellProps.rowIndex];

					return (
						<CheckboxAndButtonCell
							rowIndex={Number(cellProps.rowIndex)}
							width={actionsColumnWidth}
							height={cellProps.height}
							comp={comp}
							compType={compType}
							selection={selection}
							user={user}
							selectAllPending={selectAllPending}
							actionsColumnWidth={actionsColumnWidth}
							handleCheckbox={handleCheckbox}
						/>
					);
				}}
			/>
		);

		// @ts-expect-error TS7006: Parameter 'field' implicitly h...
		const getAverageText = (field) => {
			if (!averagesNeeded) {
				const fieldName = field.name === 'workValue' ? 'ti' : field.name;
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				return averages[fieldName] ? averages[fieldName].value : '';
			} else {
				return '';
			}
		};

		const otherColumns = mergeDynamicColumnsWithFields().map((field) => {
			const headerCell = (
				<ReorderCellStyled
					onColumnReorderStart={onColumnReorderStart}
					onColumnReorderEnd={(event) => {
						onColumReorderEnd({
							event,
							field,
							filters,
							onColumnReorder,
							onFilterChange: props.onFilterChange,
						});
					}}
					columnKey={field.name}
					className={activeReorderColumn ? 'active' : ''}
				>
					<Plugins.ResizeCell onColumnResizeEnd={saveColumns}>
						<HeaderCell field={field} data-header-for={field} {...props}>
							<div className="display-name">{field.displayName}</div>
							<div className="average">{getAverageText(field)}</div>
						</HeaderCell>
					</Plugins.ResizeCell>
				</ReorderCellStyled>
			);

			return (
				<Column
					isResizable={true}
					minWidth={103}
					width={Math.max(103, field.width)}
					key={field.id}
					columnKey={field.name}
					allowCellsRecycling={true}
					header={headerCell}
					cell={(cellProps) => {
						let onClickCell: DataCellProps['onClick'];

						const rowIndex = cellProps.rowIndex;

						if (props.compType === 'sale') {
							onClickCell = () => initSalesNavHeader(rowIndex, { filters });
						} else if (props.compType === 'property') {
							onClickCell = () =>
								initPropertiesNavHeader(rowIndex, { filters });
						}

						return (
							<DataCell
								compType={props.compType}
								selection={props.selection}
								user={props.user}
								isLoadingComps={props.isLoadingComps}
								height={cellProps.height}
								width={cellProps.width}
								rowIndex={cellProps.rowIndex}
								loadRow={loadRow}
								field={field}
								queryString={queryString}
								onClick={onClickCell}
							/>
						);
					}}
				/>
			);
		});

		return [actionsColumn].concat(otherColumns);
	};

	// Chrome will not remove the :hover state when elements are scrolled
	// from underneath the mouse. This is particularly horrible for the
	// table because the elements are reused, causing a new row to be created
	// and still have the hover state. This makes sure that hover states are
	// only present while the user is not scrolling. They can't be added back
	// until the user moves the mouse because that is when Chrome recalculates
	// hover states.

	const [isHacked, setIsHacked] = useState(false);

	const hoverHackStart = () => {
		setIsHacked(false);
		ref.current?.classList.remove('table-not-scrolling');
	};

	const hoverHackEnd = () => {
		if (isHacked) {
			return;
		}
		ref.current?.classList.add('table-not-scrolling');
		setIsHacked(true);
	};

	const isLoading = totalResults === null || !fields || fieldsLoading;

	if (isLoading) {
		return (
			<div className="table-view-wrapper table-no-results spinner large" />
		);
	}

	const isNothingFound = totalResults === 0;

	if (isNothingFound) {
		return (
			<div className="table-view-wrapper table-no-results">
				<div>
					<ErrorIconStyled width={20} height={20} />
					<h3>No Results Found</h3>
					<span className="search-table-no-results-message">
						Try removing some filters or expanding the area of your search
					</span>
				</div>
			</div>
		);
	}

	return (
		<div onMouseMove={hoverHackEnd} className={className} ref={ref}>
			<ReorderGlobalStyle activeReorderColumn={activeReorderColumn} />
			<ResponsiveTable
				{...props}
				rowsCount={totalResults}
				rowHeight={45}
				headerHeight={65}
				width={1000}
				height={1000}
				isColumnResizing={false}
				setActionsColumnWidth={setActionsColumnWidthExtended}
				onScrollStart={hoverHackStart}
			>
				{createColumns()}
			</ResponsiveTable>
		</div>
	);
});

export const ErrorIconStyled = styled(ErrorIcon)`
	fill: hsl(226, 15%, 62%);
`;

const ReorderCellStyled = styled(Plugins.ReorderCell)`
	&.public_fixedDataTableCell_resizeReorderCellContainer {
		border-right-width: 0;
	}

	& .fixedDataTableCellLayout_columnReorderContainer {
		position: absolute;
		width: 100%;
		background: transparent;
		height: 65px !important;
		cursor: pointer;
	}

	& .fixedDataTableCellLayout_columnReorderContainer:hover {
		position: absolute;
		width: 100%;
	}

	& .fixedDataTableCellLayout_columnReorderContainer::after {
		content: '';
		background: unset;
	}

	& .fixedDataTableCellLayout_columnResizerContainer {
		z-index: ${({ theme }) => theme.zIndex.overlay};
	}
`;

TableView.displayName = 'TableView';
