import React from 'react';

import styles from '../stacking.less';
import Add from '../../../ui/svg_icons/add-lease.svg';
import AutoFill from '../../../ui/svg_icons/auto-fill.svg';
import ExportPlan from '../../../ui/svg_icons/export-plan.svg';

import { isStackingPlanPopulated } from 'Pages/StackingPlans/util';

export default class SideButtons extends React.PureComponent<any> {
	autofill = () => {
		this.props.stackingPlanActions.automaticallyPlaceSpaces(
			this.props.stackingPlan
		);
	};

	newSpace = () => {
		this.props.stackingPlanActions.openSpaceEditor({});
	};

	popUp = () => {
		this.props.feedbackActions.addFeedback(
			'Generating your PDF. This can take up to 20 seconds.'
		);
	};

	render() {
		let filename = 'Compstak Stacking Plan Export';

		if (typeof this.props.property !== 'undefined') {
			if (typeof this.props.property.buildingAddress !== 'undefined') {
				filename = this.props.property.buildingAddress;
				filename = filename.replace(/,/g, '');
			}
		}

		let href;
		let tooltip =
			'Populate the stacking plan with at least 1 space before exporting.';
		let onClickMethod;

		if (isStackingPlanPopulated(this.props.stackingPlan)) {
			href = `${location.origin}/api/pdf-gen/stacking-plan/${this.props.stackingPlan.propertyId}`;
			tooltip = 'Export to PDF';
			onClickMethod = this.popUp;
		}

		return (
			<div className={styles.sideButtons}>
				<span
					className={styles.button}
					onClick={this.newSpace}
					data-tooltip="Add Space/Tenant"
					data-tooltip-position="onright"
				>
					<Add />
				</span>
				<span
					className={styles.button}
					onClick={this.autofill}
					data-tooltip="Autofill"
					data-tooltip-position="onright"
				>
					<AutoFill />
				</span>
				<a
					onClick={onClickMethod}
					href={href}
					download={filename}
					className={styles.button}
					data-tooltip={tooltip}
					data-tooltip-position="onright"
				>
					<ExportPlan />
				</a>
			</div>
		);
	}
}
