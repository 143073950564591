import { getMapAnalyticsMetricText } from '../getMapAnalyticsMetricText';
import { MapAnalyticsInfoDescription } from '../UI';
import { useLeaseExpirationState } from './LeaseExpirationProvider';

export const LeaseExpirationDescription = () => {
	const [state] = useLeaseExpirationState();

	return (
		<MapAnalyticsInfoDescription>
			Radius indicates total {getMapAnalyticsMetricText(state.metric)} expiring
			for a property within the time period.
		</MapAnalyticsInfoDescription>
	);
};
