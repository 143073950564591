import { Button, Flex, Spinner } from '@compstak/ui-kit';
import {
	useExchangeTeamMemberCanSeePromotions,
	useGetPromotion,
	useGetPublicPromotions,
	useLaunchPromoMutation,
} from 'api/promotions/promotionQueries';
import { ADMIN_HOST } from 'constants/externalPageUrls';
import { useShouldRedirect } from 'hooks/useShouldRedirect';
import { useMemo } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { PromotionState } from './promotionTypes';
import {
	PROMO_TABS_MAX_WIDTH,
	PromoPageOutline,
	PromoSimpleWarning,
} from './promotionUtilComponents';
import { PromotionsListAndFocusedPromo } from './promotionsListAndFocusedPromo';

export function PromotionReviewPage() {
	const canSeePromos = useExchangeTeamMemberCanSeePromotions();
	useShouldRedirect(!canSeePromos, '/');

	const selectedPromoId = parseInt(
		new URLSearchParams(window.location.search).get('focusedPromoId') ?? ''
	);

	const {
		data: publicPromotions,
		isLoading: isLoadingPublicPromos,
		error: publicPromosError,
	} = useGetPublicPromotions();

	const {
		data: promo,
		error: promoError,
		isLoading: isLoadingSelectedPromo,
	} = useGetPromotion(selectedPromoId);

	const isLoading = isLoadingPublicPromos || isLoadingSelectedPromo;

	const { mutateAsync: launchPromo, isLoading: isLaunching } =
		useLaunchPromoMutation();

	const promotions = useMemo(() => {
		if (!publicPromotions || !promo) {
			return [];
		}
		if (publicPromotions.some((p) => p.id === promo.id)) {
			return publicPromotions;
		}
		return [promo, ...publicPromotions];
	}, [promo, publicPromotions]);

	if (!canSeePromos) {
		return <PromoSimpleWarning>Redirecting...</PromoSimpleWarning>;
	}

	if (Number.isNaN(selectedPromoId)) {
		return (
			<PromoSimpleWarning>
				Error: Invalid promotion id in the url parameters
			</PromoSimpleWarning>
		);
	}

	if (publicPromosError) {
		return (
			<PromoSimpleWarning>
				<p>Error loading promotions.</p>
				<p>Please refresh this page or check back in a few minutes.</p>
			</PromoSimpleWarning>
		);
	}

	if (promoError) {
		return (
			<PromoSimpleWarning>
				<p>Error: Loading the promotion up for review failed.</p>
			</PromoSimpleWarning>
		);
	}

	if (!promo || isLoading) {
		return <Spinner isCentered />;
	}

	const canLaunch = promo.state === PromotionState.DRAFT;

	return (
		<PromoPageOutline>
			<LaunchBanner>
				<div>This is a preview of your promotion</div>
				<Flex gap="8px">
					<LinkBtn
						href={`${ADMIN_HOST}/promotions/edit/${selectedPromoId}`}
						data-qa-id="back-to-edit-promo-admin"
						target="_self"
					>
						BACK TO EDIT
					</LinkBtn>
					<Button
						size="l"
						disabled={!canLaunch}
						isLoading={isLaunching}
						onClick={async () => {
							try {
								await launchPromo(selectedPromoId);
								alert('Promotion launched!');
							} catch (err) {
								const LAUNCH_ERR_MSG = 'Promotion failed to launch!';
								const parsedErr = z
									.object({
										data: z.array(z.object({ message: z.string() })),
									})
									.safeParse(err);
								if (!parsedErr.success) {
									alert(LAUNCH_ERR_MSG);
									return;
								}
								const errors = parsedErr.data.data;
								const errMessages = errors.map(
									(d, idx) =>
										`Error ${idx + 1} (of ${errors.length} total): ` +
										d?.message
								);
								alert(LAUNCH_ERR_MSG + '\n\n' + errMessages.join('\n\n'));
							}
						}}
					>
						{(() => {
							if (!promo) {
								return 'NO PROMO SELECTED FOR LAUNCH';
							}
							if (canLaunch) {
								return 'LAUNCH PROMO';
							}
							if (
								[PromotionState.ACTIVE, PromotionState.PENDING].includes(
									promo.state
								)
							) {
								return 'PROMO ALREADY LAUNCHED';
							}
							return "CAN'T LAUNCH AN ENDED PROMO";
						})()}
					</Button>
				</Flex>
			</LaunchBanner>
			<PromoPageContent>
				<PromotionsListAndFocusedPromo
					{...{ promotions, selectedPromoId }}
					onSelectPromo={(id) => {
						if (id !== selectedPromoId) {
							alert("You can't switch promotions in review mode.");
						}
					}}
				/>
			</PromoPageContent>
		</PromoPageOutline>
	);
}

const PromoPageContent = styled.div`
	padding: 8px 80px 0px;
	width: 100%;
	max-width: ${PROMO_TABS_MAX_WIDTH}px;
	display: flex;
	flex-grow: 1;
`;

const LaunchBanner = styled(Flex)`
	background-color: ${(p) => p.theme.colors.neutral.n50};
	width: 100%;
	height: 64px;
	padding: 4px 80px;

	display: flex;
	justify-content: space-between;
`;

const LinkBtn = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	height: 40px;
	padding: 4px 12px;
	border-radius: 3px;
`;
