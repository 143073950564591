import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { analyticsProjectActions } from './actions';

import spinner from 'ui/styles/spinner.less';
import { AppState } from 'reducers/root';
import { useMarkets } from 'hooks/useMarkets';

const mapStoreToProps = (store: AppState) => ({
	analyticsProjects: store.analyticsProjects,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	analyticsProjectActions: bindActionCreators(
		analyticsProjectActions,
		dispatch
	),
});

const Spinner = () => <div className={spinner.spinner} />;
// @ts-expect-error TS7006: Parameter 'props' implicitly h...
const ErrorC = (props) => <div>{props.error.toString()}</div>;

type AnalyticsProjectsLoaderProps = ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

const loader = (
	Component: React.ComponentType,
	LoadingComponent = Spinner,
	ErrorComponent = ErrorC
) =>
	function AnalyticsProjectsLoader(props: AnalyticsProjectsLoaderProps) {
		const markets = useMarkets();

		const { analyticsProjects, analyticsProjectActions } = props;
		const { projects, loading, error, lastUpdated } = analyticsProjects;

		useEffect(() => {
			if (projects === null && !loading && !error) {
				analyticsProjectActions.loadProjects(markets);
			}
		}, [projects, loading, error, analyticsProjectActions, markets]);

		if (loading || projects === null) {
			// @ts-expect-error props
			return <LoadingComponent {...props} markets={markets} />;
		}

		if (error) {
			return <ErrorComponent {...props} error={error} markets={markets} />;
		}

		return (
			<Component
				{...props}
				// @ts-expect-error TS2322: Type '{ analyticsProjects: any...
				analyticsProjects={projects}
				analyticsLastUpdated={lastUpdated}
				markets={markets}
			/>
		);
	};

export const connector = connect(mapStoreToProps, mapDispatchToProps);

// @ts-expect-error TS7006: Parameter 'Component' implicit...
export default (Component) => connector(loader(Component));
