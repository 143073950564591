import React from 'react';

import { UnplacedDragSource } from './DragSource';
import { VerticalSortDropTarget } from './DropTargets';

import SpaceMenu from '../Menus/Space';

import styles from '../stacking.less';
import ThreeDot from '../../../ui/svg_icons/three-dot.svg';
import {
	getExpirationQuarter,
	getSpaceClassName,
	getTenant,
	getSize,
} from '../util';
import { nullValueText } from '@compstak/common';

class PendingSpaceInside extends React.PureComponent {
	subleaseOrNothing() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.space.sublessor) {
			return (
				<span
					className={styles.subleaseDetails}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
					data-tooltip={`Subleased from ${this.props.space.sublessor}`}
				>
					S
				</span>
			);
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.space.sublease) {
			return (
				<span className={styles.subleaseDetails} data-tooltip={`Sublease`}>
					S
				</span>
			);
		}
		return null;
	}

	getFloor() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		return this.props.space.floor ? this.props.space.floor : nullValueText;
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	showMenu = (event) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.showMenu(
			SpaceMenu,
			event.target,
			'onright',
			Object.assign({}, this.props, { unplaced: true })
		);
	};

	render() {
		return (
			<table>
				<thead>
					<tr>
						{/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
						<td colSpan="2">
							<div className={styles.tenantName}>
								{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
								{getTenant(this.props.space)} {this.subleaseOrNothing()}
							</div>
							<div
								onClick={this.showMenu}
								data-tooltip="Tenant Menu"
								data-tooltipdelay="250"
								className={styles.threeDotMenu}
							>
								<ThreeDot width={4} height={16} />
							</div>
						</td>
					</tr>
				</thead>

				<tbody>
					<tr>
						<td className={styles.key}>Size </td>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
						<td className={styles.value}>{getSize(this.props.space)} SF</td>
					</tr>

					<tr>
						<td className={styles.key}>Floor </td>
						<td className={styles.value}>{this.getFloor()}</td>
					</tr>

					<tr>
						<td className={styles.key}>Expires </td>
						<td className={styles.value}>
							{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
							{getExpirationQuarter(this.props.space)}
							<div
								className={
									// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
									styles[getSpaceClassName(this.props.space)] +
									' ' +
									styles.value +
									' ' +
									styles.yearsLeftIcon
								}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function PendingSpace(props) {
	return (
		<div className={styles.leaseContainer}>
			<PendingSpaceInside {...props} />
		</div>
	);
}

export default UnplacedDragSource(
	VerticalSortDropTarget(
		class DraggablePendingSpace extends React.Component {
			render() {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'connectDragSource' does not exist on typ... Remove this comment to see the full error message
				const { connectDragSource, connectDropTarget } = this.props;
				let className = styles.leaseContainer;
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'isDragging' does not exist on type 'Read... Remove this comment to see the full error message
				if (this.props.isDragging) {
					className += ' ' + styles.dragging;
				}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
				if (typeof this.props.space.size === 'undefined') {
					className += ' ' + styles.notDraggable;
				}
				return connectDragSource(
					connectDropTarget(
						<div className={className}>
							<PendingSpaceInside {...this.props} />
						</div>
					)
				);
			}
		}
	)
);
