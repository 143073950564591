import { numberUtils } from '@compstak/ui-kit';
import {
	CompsetDTO,
	CompsetType,
	MufaCompsetDTO,
	useCompsetType,
	usePropertyByIdQuery,
} from 'api';
import { PropertyComp } from 'types';
import { ExcelColumn, getDistanceBetweenProperties } from '../../utils';

type Props = {
	propertyId: number;
};

export const useCompsetExcelColumns = ({ propertyId }: Props) => {
	const { data: compsetType } = useCompsetType({ propertyId });

	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	if (!property) return [];

	const commercialColumns: ExcelColumn<CompsetDTO>[] = [
		{
			header: 'Building Name, Address',
			body: ({ row }) => getBuildingName(row.property),
		},
		{
			header: 'Submarket',
			body: ({ row }) => row.property.submarket,
		},
		{
			header: 'Distance From Subject Property',
			body: ({ row }) => {
				const distance = getDistanceBetweenProperties(property, row.property);

				return numberUtils.formatNumber(distance, 'twoDecimals');
			},
		},
		{
			header: 'Market Starting Rent $/SF',
			body: ({ row }) =>
				row.property.propertyMarketStartingRent
					? numberUtils.formatNumber(
							row.property.propertyMarketStartingRent,
							'twoDecimals'
						)
					: '',
		},
		{
			header: 'Year Built',
			body: ({ row }) => row.property.buildingYear ?? '',
		},
		{
			header: 'Year Renovated',
			body: ({ row }) => row.property.buildingYearRenovated ?? '',
		},
		{
			header: 'Floors',
			body: ({ row }) => row.property.buildingFloorsCount ?? '',
		},
		{
			header: 'Size (SF)',
			body: ({ row }) => row.property.buildingSize ?? '',
		},
	];

	const mufaColumns: ExcelColumn<MufaCompsetDTO>[] = [
		...commercialColumns.slice(0, 3),
		{
			header: 'Asking Rent $/SF',
			body: ({ row }) => {
				if (row.property.askingRentPerSqFt == null) return '';
				return numberUtils.formatCurrency(
					row.property.askingRentPerSqFt,
					'currencyTwoDecimals'
				);
			},
		},
		{
			header: 'Effective Rent $/SF',
			body: ({ row }) => {
				if (row.property.effectiveRentPerSqFt == null) return '';
				return numberUtils.formatCurrency(
					row.property.effectiveRentPerSqFt,
					'currencyTwoDecimals'
				);
			},
		},
		{
			header: '# of Units',
			body: ({ row }) => {
				if (row.property.numberOfUnits == null) return '';
				return numberUtils.formatNumber(row.property.numberOfUnits, 'integer');
			},
		},
		...commercialColumns.slice(4),
	];

	return compsetType === CompsetType.COMMERCIAL
		? commercialColumns
		: mufaColumns;
};

const getBuildingName = (data: PropertyComp) => {
	const { buildingAddress, buildingName } = data;

	if (!buildingName && !buildingAddress) {
		return '';
	}

	if (!buildingName) {
		return buildingAddress;
	}

	if (!buildingAddress) {
		return buildingName;
	}

	return `${buildingName}, ${buildingAddress}`;
};
