import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import styled from 'styled-components';

export const FilterSpacer = () => {
	const { searchTerm } = useFilterFieldContext();

	return <FilterSpacerUI isHidden={!!searchTerm} />;
};

export const FilterSpacerUI = styled.li<{ isHidden?: boolean }>`
	background-color: #424242;
	height: 1px;
	margin: 7px 0;
	display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
`;
