import React from 'react';
import styles from '../stacking.less';
import legendStyles from './stacking-legend.less';

export default class Legend extends React.PureComponent {
	renderLegend() {
		const currentYear = new Date().getFullYear();
		const years = [0, 1, 2, 3, 4, 'vacant', 'unrentable'];

		const legend = years.map(function (space, i) {
			// @ts-expect-error ts-migrate(2365) FIXME: Operator '+' cannot be applied to types 'number' a... Remove this comment to see the full error message
			let text = currentYear + space;
			if (space === 4) {
				text += '+';
			} else if (space === 'vacant' || space === 'unrentable') {
				text = space;
			}

			let yearsLeftStyle = 'yearsLeft' + space;
			if (space === 'vacant') {
				yearsLeftStyle = 'spaceVacant';
			} else if (space === 'unrentable') {
				yearsLeftStyle = 'spaceUnrentable';
			}

			return (
				<div key={i} className={legendStyles.legendBoxAndKey}>
					<div
						className={styles[yearsLeftStyle] + ' ' + legendStyles.legendKey}
					/>
					<div className={legendStyles.legendValue}>{text}</div>
				</div>
			);
		});

		return legend;
	}

	render() {
		return (
			<div className={legendStyles.legend}>
				<div className={legendStyles.expires}>Expires</div>
				{this.renderLegend()}
			</div>
		);
	}
}
