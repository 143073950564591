import { CheckExportStatusPerMarket } from 'api';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { Flex } from '@compstak/ui-kit';
import IconQuestionMarkOriginal from 'ui/svg_icons/question_mark_outline.svg';

type Props = {
	className?: string;
	marketToCompIds: Record<number, number[]>;
	perMarketPolicy: CheckExportStatusPerMarket[];
	setSelectedCompIds: React.Dispatch<React.SetStateAction<number[]>>;
};

export const MarketToCompsExportTable = ({
	className,
	marketToCompIds,
	perMarketPolicy,
	setSelectedCompIds,
}: Props) => {
	const selectableMarketIds = useMemo(() => {
		return perMarketPolicy
			.filter(({ canExport }) => !!canExport)
			.map(({ marketId }) => marketId);
	}, [perMarketPolicy]);
	const [selectedMarketIds, setSelectedMarketIds] =
		useState(selectableMarketIds);

	useEffect(() => {
		const selectedCompIds = selectedMarketIds
			.map((marketId) => marketToCompIds[marketId])
			.flat();
		setSelectedCompIds(selectedCompIds);
	}, [marketToCompIds, selectedMarketIds, setSelectedCompIds]);

	const totalExport = useMemo(() => {
		return selectedMarketIds.map((marketId) => marketToCompIds[marketId]).flat()
			.length;
	}, [marketToCompIds, selectedMarketIds]);

	const freeExportsMessage = useMemo(() => {
		const totalCost = selectedMarketIds
			.map(
				(selectedMarketId) =>
					perMarketPolicy.find(({ marketId }) => marketId === selectedMarketId)
						?.cost ?? 0
			)
			.reduce((acc, i) => {
				acc += i;
				return acc;
			}, 0);

		const freeExports = totalExport - totalCost;

		if (freeExports > 0) {
			return `You have selected ${freeExports} comp${freeExports === 1 ? '' : 's'} that ${
				freeExports === 1 ? 'was' : 'were'
			} previously exported. These comps will not count against your available exports.`;
		}
	}, [perMarketPolicy, selectedMarketIds, totalExport]);

	return (
		<TableContainer className={className}>
			<Table>
				<TableHeader
					selectableMarketIds={selectableMarketIds}
					selectedMarketIds={selectedMarketIds}
					setSelectedMarketIds={setSelectedMarketIds}
				/>
				<Tbody>
					{perMarketPolicy.map((marketPolicy) => (
						<TableRow
							key={marketPolicy.marketId}
							marketToCompIds={marketToCompIds}
							marketPolicy={marketPolicy}
							selectedMarketIds={selectedMarketIds}
							setSelectedMarketIds={setSelectedMarketIds}
						/>
					))}
				</Tbody>
				<Tfoot>
					<Tr>
						<Td></Td>
						<Td>
							<Flex justifyContent="start" gap="0.25rem">
								Total Export
								{freeExportsMessage && (
									<IconQuestionMark data-tooltip={freeExportsMessage} />
								)}
							</Flex>
						</Td>
						<Td>{totalExport}</Td>
						<Td></Td>
					</Tr>
				</Tfoot>
			</Table>
		</TableContainer>
	);
};

const TableContainer = styled.div`
	max-height: 20rem;
	overflow-y: auto;
`;

const Table = styled.table`
	border-collapse: separate;
	border-spacing: 0;
	padding: 0 1rem;
`;

export const Tr = styled.tr<{ disabled?: boolean }>`
	${({ disabled, theme }) => disabled && `color: ${theme.colors.neutral.n50};`}
`;

export const Td = styled.td`
	padding: 0.5rem;
	text-align: left;

	&:has(label) {
		padding: 0;
		label {
			padding: 0.5rem;
		}
	}
`;

const Tbody = styled.tbody`
	td {
		border: 1px solid ${({ theme }) => theme.colors.neutral.n70};
	}
	td:not(:last-child) {
		border-right: none;
	}

	tr:not(:last-child) td {
		border-bottom: none;
	}
`;

const Tfoot = styled.tfoot`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.gray.gray700};
`;

const IconQuestionMark = styled(IconQuestionMarkOriginal)`
	width: 1rem;
	height: 1rem;
	path {
		fill: ${({ theme }) => theme.colors.white.white};
	}
`;
