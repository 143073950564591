import { useFiltersParamWithMarketId, useSpaceTypeAnalyticsQuery } from 'api';
import { useSpaceTypeAnalyticsState } from './SpaceTypeAnalyticsProvider';

export const useSpaceTypeAnalyticsData = () => {
	const [state] = useSpaceTypeAnalyticsState();
	const filtersParam = useFiltersParamWithMarketId();

	return useSpaceTypeAnalyticsQuery({
		filters: filtersParam,
		maxBySqft: state.metric === 'sqft',
	});
};
