import React, { memo } from 'react';
import { ModalWithCloseTimestamp } from '../ModalWithCloseTimestamp';
import { useSetModalCloseTimestamp } from 'Pages/Analytics/api/user/setDontShowAgainWindows';

type DataProviderInfoModalProps = {
	onClose: NoArgCallback;
	updateUser: NoArgCallback;
};

export const DataProviderInfoModal = memo<DataProviderInfoModalProps>(
	({ onClose, updateUser }) => {
		const onTimestampChange = useSetModalCloseTimestamp(updateUser).mutate;

		return (
			<ModalWithCloseTimestamp
				title={title}
				description={description}
				onClose={onClose}
				onTimestampChange={onTimestampChange}
			/>
		);
	}
);

DataProviderInfoModal.displayName = 'DataProviderInfoModal';

const title = 'RealPage Multifamily Data';
const description = `This data set contains RealPage multifamily property data. Compstak
	commercial lease data and RealPage multifamily property data will be
	shown on separate trendlines, where relevant fields and filters are
	available`;
