import * as React from 'react';
import styled from 'styled-components';
import { CompType } from 'types';
import { SaleLeagueTables } from './SaleLeagueTables/SaleLeagueTables';

type LeagueTablesViewProps = {
	compType: CompType;
};

export const LeagueTablesView = ({ compType }: LeagueTablesViewProps) => {
	return <Wrap>{compType === 'sale' && <SaleLeagueTables />}</Wrap>;
};

const Wrap = styled.div`
	position: absolute;
	top: 90px;
	width: 100%;
	padding: 1em 3.5em;
	display: flex;
	flex-direction: column;
	gap: 1em;
`;
