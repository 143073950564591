import {
	calendarUtils,
	numberUtils,
	renderTableValue,
	TableColumn,
} from '@compstak/ui-kit';
import { SimilarSalesSaleComp } from 'api/similarSales';
import { formatValue } from 'util/comp-format/src/format';
import { IconLockGrey } from 'ui/UI';
import { getShouldShowLockIcon } from 'utils';
import { ReactNode } from 'react';

export const columns: TableColumn<
	SimilarSalesSaleComp,
	{ isExchange: boolean }
>[] = [
	{
		header: () => {
			return 'Sale Date';
		},
		body: ({ row }) => {
			return renderTableValue(row.saleDate, (v) =>
				calendarUtils.getDate(v, 'fullDate1')
			);
		},
		width: 80,
	},
	{
		header: () => {
			return 'Address';
		},
		body: ({ row }) => {
			return renderTableValue(row.portfolio[0].buildingAddress);
		},
		width: 100,
	},
	{
		header: () => {
			return 'Submarket';
		},
		body: ({ row }) => {
			return renderTableValue(row.portfolio[0].submarket);
		},
		width: 80,
	},
	{
		header: () => {
			return 'Buyer';
		},
		body: ({ row, extraData }) => {
			if (getShouldShowLockIcon(row.buyer, row, extraData.isExchange)) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.buyer);
		},
		width: 100,
	},
	{
		header: () => {
			return 'Seller';
		},
		body: ({ row, extraData }) => {
			if (getShouldShowLockIcon(row.seller, row, extraData.isExchange)) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.seller);
		},
		width: 100,
	},
	{
		header: () => {
			return 'Total Sale Price';
		},
		body: ({ row, extraData }) => {
			if (
				getShouldShowLockIcon(row.totalSalePrice, row, extraData.isExchange)
			) {
				return <IconLockGrey />;
			}

			return renderTableValue(
				row.totalSalePrice,
				(v) => formatValue('totalSalePrice', v, false, 'sale') as ReactNode
			);
		},
		width: 100,
	},
	{
		header: () => {
			return 'Sale Price ($/SF)';
		},
		body: ({ row, extraData }) => {
			if (getShouldShowLockIcon(row.salePricePsf, row, extraData.isExchange)) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.salePricePsf, (v) =>
				numberUtils.formatCurrency(v, 'currencyTwoDecimals')
			);
		},
		width: 100,
	},
	{
		header: () => {
			return 'Cap Rate';
		},
		body: ({ row, extraData }) => {
			if (getShouldShowLockIcon(row.capRate, row, extraData.isExchange)) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.capRate, (v) =>
				numberUtils.formatPercent(v / 100, 'percentTwoDecimals')
			);
		},
		width: 100,
	},
];
