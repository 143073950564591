import {
	UseTenantIndustryDashboardQueryOptions,
	useTenantIndustryDashboardQuery,
} from 'api';
import { useTenantIndustryState } from 'maps';
import { useFilters } from 'reducers/filtersReducer';

export const useDashboardTenantIndustryAnalyticsData = (
	options?: UseTenantIndustryDashboardQueryOptions
) => {
	const [filters] = useFilters('exchange-dashboard');
	const [state] = useTenantIndustryState();

	return useTenantIndustryDashboardQuery(
		{
			filters,
			maxBySqft: state.metric === 'sqft',
		},
		options
	);
};
