import { PropertyLease, SPACE_TYPE_ID_TO_NAME } from 'api';
import { useState } from 'react';
import { LeaseComp } from 'types';
import { getLeasesByStatus } from './getLeasesByStatus';
import { LeaseStatus } from './types';

type Props = {
	leases: LeaseComp[] | PropertyLease[];
};

export const useLeaseOptionsState = ({ leases }: Props) => {
	const [leaseStatus, _setLeaseStatus] = useState(LeaseStatus.ALL);

	const getLeaseSpaceTypes = (status: LeaseStatus) => {
		const _leaseSpaceTypes: Record<string, boolean> = {};
		getLeasesByStatus(status, leases).forEach((l) => {
			_leaseSpaceTypes[l.spaceType ?? SPACE_TYPE_ID_TO_NAME['13']] = true;
		});
		return _leaseSpaceTypes;
	};

	const [leaseSpaceTypes, setLeaseSpaceTypes] = useState<
		Record<string, boolean>
	>(() => getLeaseSpaceTypes(leaseStatus));

	const setLeaseStatus = (status: LeaseStatus) => {
		_setLeaseStatus(status);
		setLeaseSpaceTypes(getLeaseSpaceTypes(status));
	};

	return {
		leaseStatus,
		setLeaseStatus,
		leaseSpaceTypes,
		setLeaseSpaceTypes,
	};
};

export type UseLeaseOptionsStateReturn = ReturnType<
	typeof useLeaseOptionsState
>;
