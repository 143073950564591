import React from 'react';

import ThreeDot from '../../../ui/svg_icons/three-dot.svg';
import FloorMenu from '../Menus/Floor';

import {
	getExpirationQuarter,
	getActualLabel,
	getSpaceClassName,
} from '../util';
import number from 'ui/util/number';

import stackingStyles from '../stacking.less';
import directoryStyles from './stacking-directory.less';

export default class Directory extends React.Component {
	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	editSpace = (space) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.openSpaceEditor(space);
	};

	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	remove = (space) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.unplaceSingleSpace(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			space
		);
	};

	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	duplicate = (space) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.duplicateSpace(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			space
		);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	showFloorMenu = (event, floor) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		const vacantSpace = this.props.stackingPlan.spaces.reduce(
			// @ts-expect-error TS7006: Parameter 'sfLeft' implicitly ...
			(sfLeft, space) =>
				getActualLabel(space.floor) === getActualLabel(floor.label)
					? sfLeft - space.size
					: sfLeft,
			floor.size
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.showMenu(FloorMenu, event.currentTarget, 'onleft', {
			...this.props,
			floor,
			vacantSpace,
		});
	};

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		const spacesByFloor = this.props.stackingPlan.floors.reduce((acc, s) => {
			acc[getActualLabel(s.label)] = [];
			return acc;
		}, {});

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		this.props.stackingPlan.spaces.forEach((s) => {
			if (s.placed) {
				spacesByFloor[getActualLabel(s.floor)].push(s);
			}
		});

		return (
			<div
				className={stackingStyles.directory + ' ' + directoryStyles.container}
			>
				<table>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message */}
					{this.props.stackingPlan.floors.map((floor) => (
						<tbody key={floor.label} className={directoryStyles.floorContainer}>
							<tr className={directoryStyles.floorHeader}>
								<td>
									<span className={directoryStyles.floorText}>Floor</span>{' '}
									<span className={directoryStyles.floorLabel}>
										{/^[1-9]$/.test(floor.label)
											? '0' + floor.label
											: floor.label}
									</span>
								</td>
								<td>Size</td>
								<td>Expiration Date</td>
								<td className={directoryStyles.floorMenuCell}>
									<div onClick={(event) => this.showFloorMenu(event, floor)}>
										<ThreeDot />
									</div>
								</td>
							</tr>
							{/* @ts-expect-error TS7006: Parameter 'space' implicitly h... */}
							{spacesByFloor[getActualLabel(floor.label)].map((space) => (
								<tr key={space.id} className={directoryStyles.space}>
									<td>
										<span
											className={
												stackingStyles[getSpaceClassName(space)] +
												' ' +
												directoryStyles.yearsIndicator
											}
										/>
										{space.tenant}
									</td>
									<td>{number(space.size)}</td>
									<td>{getExpirationQuarter(space)}</td>
									<td>
										<div className={directoryStyles.options}>
											<a onClick={() => this.editSpace(space)}>Edit</a>
											<a onClick={() => this.duplicate(space)}>Duplicate</a>
											<a onClick={() => this.remove(space)}>Remove</a>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					))}
				</table>
			</div>
		);
	}
}
