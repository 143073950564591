import { Period } from '@compstak/ui-kit';
import { SpaceTypeName } from 'api';

export enum FiltersNames {
	METRIC = 'metric',
	PERIOD = 'period',
	MONTHS = 'months',
	SPACE_TYPE = 'spaceType',
	TIME_GRANULARITY = 'timeGranularity',
}

export enum CommercialChartsNames {
	SubmarketRentTrend = 'SubmarketRentTrend',
	SubmarketRentDistribution = 'SubmarketRentDistribution',
}

export enum MufaChartsNames {
	SubmarketMarketTrend = 'SubmarketMarketTrend',
	SubmarketLeaseTradeOut = 'SubmarketLeaseTradeOut',
	SubmarketSupply = 'SubmarketSupply',
	SubmarketExpensesPerUnit = 'ExpensesPerUnit',
	SubmarketOccupancy = 'SubmarketOccupancy',
	SubmarketNOI = 'SubmarketNOI',
}

export type DistributionMonths = 12 | 36 | 60 | 120 | 180;
export type FiltersValues = Period | SpaceTypeName | DistributionMonths;

export type CommonChartProps = {
	showAnimation?: boolean;
	showSaveDataMenu?: boolean;
	shouldShowTooltip?: boolean;
};

const metricListItems = [
	{ title: 'Starting Rent', value: 'startingRent' },
	{ title: 'Asking Rent $/SF', value: 'askingRentPerSqFt' },
	{ title: 'Effective Rent', value: 'effectiveRent' },
	{ title: 'Effective Rent $/SF', value: 'effectiveRentPerSqFt' },
	{ title: 'Free Months', value: 'freeMonths' },
	{ title: 'TI', value: 'ti' },
	{ title: 'Lease Term', value: 'leaseTerm' },
	{ title: 'Transaction Size', value: 'transactionSize' },
	{ title: 'Total Units', value: 'totalUnits' },
	{ title: 'New Supply', value: 'newSupply' },
	{ title: 'Units Under Construction', value: 'unitsUnderConstruction' },
] as const;

export type MetricListItem = (typeof metricListItems)[number];

export type Metric =
	| 'startingRent'
	| 'askingRentPerSqFt'
	| 'effectiveRent'
	| 'effectiveRentPerSqFt'
	| 'freeMonths'
	| 'ti'
	| 'leaseTerm'
	| 'transactionSize'
	| 'totalUnits'
	| 'newSupply'
	| 'unitsUnderConstruction';

export enum ChartTypes {
	LINE_CHART = 'lineChart',
	BAR_CHART = 'barChart',
}
