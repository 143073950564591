import { Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';
import { Compset } from './Compset';
import { useCompsetData } from 'api';

type Props = { propertyId: number };

export const CompsetContainer = ({ propertyId }: Props) => {
	const { data, fetchStatus } = useCompsetData({ propertyId });

	if (!data)
		return (
			<SpinnerContainer>
				<Spinner size="xl" />
			</SpinnerContainer>
		);

	return (
		<Compset compsetResponse={data} isFetching={fetchStatus === 'fetching'} />
	);
};

const SpinnerContainer = styled.div`
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
