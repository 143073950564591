import { Styles, Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

type PdfSectionBoxProps = {
	title: string;
	topRightInfo?: string;
	children: ReactNode;
};

export const PdfSectionBox = ({
	title,
	topRightInfo,
	children,
}: PdfSectionBoxProps) => {
	const style = useSharedPdfStyle();
	const theme = useTheme();

	return (
		<View style={style.pdfSectionBox}>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Text style={style.pdfSectionTitle}>{title}</Text>
				{topRightInfo && (
					<View
						style={{
							backgroundColor: theme.colors.neutral.n20,
							padding: 6,
							border: `1px solid ${theme.colors.neutral.n40}`,
						}}
					>
						<Text
							style={{
								fontSize: 9,
							}}
						>
							{topRightInfo}
						</Text>
					</View>
				)}
			</View>
			{children}
		</View>
	);
};

type PdfSectionDescriptionTextProps = {
	children: ReactNode;
};

export const PdfSectionDescriptionText = ({
	children,
}: PdfSectionDescriptionTextProps) => {
	const theme = useTheme();

	return (
		<View
			style={{
				backgroundColor: theme.colors.neutral.n20,
				padding: 9,
				borderRadius: 3,
			}}
		>
			<Text
				style={{
					fontSize: 12,
				}}
			>
				{children}
			</Text>
		</View>
	);
};

export const useSharedPdfStyle = () => {
	const theme = useTheme();

	return {
		pdfSectionBox: {
			border: `1px solid ${theme.colors.neutral.n40}`,
			padding: 12,
		},
		pdfSectionTitle: {
			fontSize: 12,
			textTransform: 'uppercase',
			marginBottom: 4,
		},
	} satisfies Styles;
};
