import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import IconChevronDown from '../../ui/svg_icons/arrow_bottom.svg';
import { CopyPortfolioDialog } from './CopyPortfolioDialog';
import { DeletePortfolioDialog } from './DeletePortfolioDialog';
import { PortfolioOptionsMenu } from './PortfolioOptionsMenu';
import { UpdatePortfolioDialog } from './UpdatePortfolioDialog';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioModal } from 'PortfolioAnalytics/PortfolioSidebar/PortfolioModal';
import { useModal } from 'providers/ModalProvider';
import { Portfolio } from 'api';

type Props = {
	portfolio: Portfolio;
	dashboardElement?: HTMLElement | null;
};

export const PortfolioOptionsButton = ({
	dashboardElement,
	portfolio,
}: Props) => {
	const { portfolioRevampOverviewTabFF } = useFeatureFlags();
	const { openModal } = useModal();

	return (
		<>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<Button data-html2canvas-ignore>
						<IconChevronDown fill="#fff" />
						<div>Options</div>
					</Button>
				</DropdownMenu.Trigger>
				<PortfolioOptionsMenu
					portfolio={portfolio}
					dashboardElement={dashboardElement}
					openUpdateDialog={() =>
						openModal({
							modalContent: portfolioRevampOverviewTabFF ? (
								<PortfolioModal
									action="edit"
									title="Edit Portfolio"
									editedPortfolio={portfolio}
									initialFormState={{
										name: portfolio.name,
										description: portfolio.description ?? '',
									}}
								/>
							) : (
								<UpdatePortfolioDialog portfolio={portfolio} />
							),
						})
					}
					openCopyDialog={() =>
						openModal({
							modalContent: portfolioRevampOverviewTabFF ? (
								<PortfolioModal
									action="copy"
									title="Duplicate Portfolio"
									editedPortfolio={portfolio}
									initialFormState={{
										name: `${portfolio.name} copy`,
										description: portfolio.description ?? '',
									}}
								/>
							) : (
								<CopyPortfolioDialog portfolio={portfolio} />
							),
						})
					}
					openDeleteDialog={() =>
						openModal({
							modalContent: (
								<DeletePortfolioDialog portfolioId={portfolio.id} />
							),
						})
					}
				/>
			</DropdownMenu.Root>
		</>
	);
};

const Button = styled.button`
	all: unset;
	box-sizing: border-box;
	height: 42px;
	background-color: ${(p) => p.theme.colors.neutral.n300};
	color: ${(p) => p.theme.colors.white.white};
	padding: 0.5rem 1rem;
	font-size: 14px;
	font-weight: 300;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	text-transform: uppercase;
	border-radius: 0.125rem;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	&:hover,
	&:active {
		background-color: ${(p) => p.theme.colors.neutral.n100};
	}
`;
