import { getIsMonthlyMarket, ExcelColumn } from 'utils';
import { PropertyLease, useUserQuery } from 'api';
import {
	formatCompQuarter,
	formatObfuscatedSizeRange,
	formatRentWithPostfixAsString,
	formatSqft,
} from 'format';

export const useSubmarketRecentLeasesExcelColumns = (marketId?: number) => {
	const { data: user } = useUserQuery();

	if (!marketId) return [];

	const isMonthly = getIsMonthlyMarket(marketId);

	const columns: ExcelColumn<PropertyLease>[] = [
		{
			header: 'Trans. Qtr.',
			body: ({ row }) => {
				return row.executionQuarter
					? formatCompQuarter(row.executionQuarter)
					: '';
			},
		},
		{
			header: 'Address',
			body: ({ row }) => {
				return row.buildingAddress;
			},
		},
		{
			header: 'City',
			body: ({ row }) => {
				return row.city ?? '';
			},
		},
		{
			header: 'Zip Code',
			body: ({ row }) => {
				return row.zip ?? '';
			},
		},
		{
			header: 'Tenant',
			body: ({ row }) => {
				return row.tenantName ?? '';
			},
		},
		{
			header: 'Space Type',
			body: ({ row }) => {
				return row.spaceType ?? '';
			},
		},
		{
			header: 'Size',
			body: ({ row }) => {
				const size = row.transactionSize;
				return size
					? row.own
						? formatSqft(size)
						: formatObfuscatedSizeRange(size)
					: '';
			},
		},
		{
			header: 'Starting Rent ($/SF)',
			body: ({ row }) => {
				const rent = row.startingRent;
				return rent ? formatRentWithPostfixAsString(rent, isMonthly) : '';
			},
		},
	];

	if (user.userType === 'admin') {
		columns.unshift({
			header: 'Id',
			body: ({ row }) => {
				return row.id;
			},
		});
	}

	return columns;
};
