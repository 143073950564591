import { Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';

export const PageSpinner = () => {
	return (
		<SpinnerContainer>
			<Spinner isCentered size="xl" thickness="3px" />
		</SpinnerContainer>
	);
};

const SpinnerContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`;
