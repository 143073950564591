import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import TimespanButton from 'Components/Filters/Base/TimespanButton';
import React from 'react';

const attribute = 'saleDate' as const;

export const SaleDateFilter = ({ inputId }: { inputId?: string }) => {
	return (
		<FilterBase attribute={attribute} inputId={inputId}>
			{(props) => (
				<>
					<Between {...props} />
					<div className="presets">
						In the past... <br />
						<TimespanButton
							min={-6}
							max={0}
							unit="months"
							roundTo="day"
							{...props}
						>
							6 months
						</TimespanButton>
						<TimespanButton
							min={-1}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							Year
						</TimespanButton>
						<TimespanButton
							min={-2}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							2 Years
						</TimespanButton>
					</div>
				</>
			)}
		</FilterBase>
	);
};
