import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import TimespanButton from 'Components/Filters/Base/TimespanButton';

const attribute = 'loanOriginationDate' as const;

export const LoanOriginationDateFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<div>
					<Between {...props} />
					<div className="presets">
						In the past... <br />
						<TimespanButton
							min={-1}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							Year
						</TimespanButton>
						<TimespanButton
							min={-2}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							2 Years
						</TimespanButton>
						<TimespanButton
							min={-5}
							max={0}
							unit="year"
							roundTo="day"
							{...props}
						>
							5 Years
						</TimespanButton>
					</div>
				</div>
			)}
		</FilterBase>
	);
};
