import { MapAnalyticsMetric } from '../types';
import {
	FormControl,
	FormControlOptionLabel,
	MapAnalyticsFormSection,
	MapAnalyticsSectionTitle,
	RadioButton,
	RadioButtonLabel,
} from './UI';

type Props = {
	id: string;
	metric: MapAnalyticsMetric;
	onChangeMetric: (metric: MapAnalyticsMetric) => void;
};

export const MapAnalyticsMetricRadioGroup = ({
	id,
	metric,
	onChangeMetric,
}: Props) => {
	const sqftId = `${id}-analytics-metric-sqft-radio-btn`;
	const leaseCountId = `${id}-analytics-metric-lease-count-radio-btn`;

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChangeMetric(e.target.value as MapAnalyticsMetric);
	};

	return (
		<MapAnalyticsFormSection>
			<MapAnalyticsSectionTitle>Metric</MapAnalyticsSectionTitle>
			<FormControl>
				<RadioButton
					id={sqftId}
					name={sqftId}
					value="sqft"
					checked={metric === 'sqft'}
					onChange={onChange}
					data-qa-id={sqftId}
				/>
				<RadioButtonLabel htmlFor={sqftId}>
					<FormControlOptionLabel>Square Feet</FormControlOptionLabel>
				</RadioButtonLabel>
			</FormControl>
			<FormControl>
				<RadioButton
					id={leaseCountId}
					name={leaseCountId}
					value="leaseCount"
					checked={metric === 'leaseCount'}
					onChange={onChange}
					data-qa-id={leaseCountId}
				/>
				<RadioButtonLabel htmlFor={leaseCountId}>
					<FormControlOptionLabel>Lease Count</FormControlOptionLabel>
				</RadioButtonLabel>
			</FormControl>
		</MapAnalyticsFormSection>
	);
};
