import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';
import {
	AsteriskCount,
	LabeledDateInput,
	LabeledInput,
	LabeledRadio,
	LabeledSelect,
	StrFormOption,
} from './UploadFormCommon';

export type FieldProps = {
	label: string;
	unit: string;
	postUnit: string;
	asteriskCount: AsteriskCount;
	value: string;
	update: (newValue: string) => void;
};

export function mappedUploadFormComponentsFactory<
	SubmissionKey extends string,
>({
	useFormField,
	displayNamePrefix,
}: {
	useFormField: (name: SubmissionKey) => FieldProps;
	displayNamePrefix: string;
}) {
	const FormInput = withErrorBoundaryDefault(function FormInputUnsafe({
		name,
		width,
		labelOverride,
		unitOverride,
		postUnitOverride,
		avoidAutofillByName,
	}: {
		width: number;
		name: SubmissionKey;
		labelOverride?: string;
		unitOverride?: string;
		postUnitOverride?: string;
		avoidAutofillByName?: boolean;
	}) {
		const { update, ...field } = useFormField(name);
		return (
			<LabeledInput
				{...field}
				{...{
					name,
					width,
					label: labelOverride || field.label,
					unit: unitOverride || field.unit,
					postUnit: postUnitOverride || field.postUnit,
					avoidAutofillByName,
				}}
				onChange={(ev) => update(ev.target.value)}
			/>
		);
	});
	FormInput.displayName = displayNamePrefix + 'FormInput';

	const FormSelect = withErrorBoundaryDefault(function FormSelectUnsafe({
		name,
		width,
		options,
		labelOverride,
		avoidAutofillByName,
	}: {
		width: number;
		name: SubmissionKey;
		options: Array<StrFormOption>;
		labelOverride?: string;
		avoidAutofillByName?: boolean;
	}) {
		const { update, ...field } = useFormField(name);
		return (
			<LabeledSelect
				{...field}
				{...{
					name,
					width,
					options,
					label: labelOverride || field.label,
					avoidAutofillByName,
				}}
				onChange={update}
			/>
		);
	});
	FormSelect.displayName = displayNamePrefix + 'FormSelect';

	const FormRadio = withErrorBoundaryDefault(function FormRadioUnsafe(props: {
		width: number;
		name: SubmissionKey;
		hideLabel?: boolean;
	}) {
		const { update, ...field } = useFormField(props.name);
		return (
			<LabeledRadio
				{...field}
				{...props}
				onChange={(ev) => update(ev.target.value)}
			/>
		);
	});
	FormRadio.displayName = displayNamePrefix + 'FormRadio';

	const FormDate = withErrorBoundaryDefault(function FormDateUnsafe({
		name,
		width,
		yearOptions,
	}: {
		width: number;
		name: SubmissionKey;
		yearOptions: Array<StrFormOption>;
	}) {
		const { update, ...field } = useFormField(name);

		return (
			<LabeledDateInput
				{...field}
				{...{
					width,
					name,
					yearOptions,
				}}
				onChange={update}
			/>
		);
	});
	FormDate.displayName = displayNamePrefix + 'FormDate';

	return {
		FormInput,
		FormSelect,
		FormRadio,
		FormDate,
	};
}

export const DEFAULT_UPLOAD_FORM_FIELD = Object.freeze({
	label: '',
	unit: '',
	postUnit: '',
});
