import { useShouldRedirect } from 'hooks/useShouldRedirect';
import React from 'react';
import { useIsExchangePlusUser } from './exchangePlusStatus';

export function ExchangePlusRedirectRouteHOC<PropsPassedToRoute>(
	WrappedComponent: (props: PropsPassedToRoute) => React.ReactElement | null
) {
	return function ExchangePlusRouteComponent(props: PropsPassedToRoute) {
		const isExchangePlusUser = useIsExchangePlusUser();

		useShouldRedirect(!isExchangePlusUser, '/home');

		if (!isExchangePlusUser) {
			return <></>;
		}
		// @ts-expect-error TS2322: Type 'PropsPassedToRoute' is n...
		return <WrappedComponent {...props} />;
	};
}
