import { useFiltersParamWithMarketId, useLeaseExpirationQuery } from 'api';
import { useDebounce } from 'use-debounce';
import { useLeaseExpirationState } from './LeaseExpirationProvider';

export const useLeaseExpirationData = () => {
	const [state] = useLeaseExpirationState();

	const [debouncedExpireInMonths] = useDebounce(state.expireInMonths, 300);

	const filtersParam = useFiltersParamWithMarketId();

	return useLeaseExpirationQuery({
		filters: filtersParam,
		maxBySqft: state.metric === 'sqft',
		expireInMonths: debouncedExpireInMonths,
	});
};
