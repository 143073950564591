import { Text, View } from '@react-pdf/renderer';
import { AssociatedApn } from 'api/ppm-property/associatedApns/useGetAssociatedApns';
import { useTheme } from 'styled-components';
import { PropertyComp } from 'types';
import { PdfApn } from './PdfApn';
import { pdfStyle } from './style';

type Props = {
	property: PropertyComp;
	apn: AssociatedApn[] | string | undefined;
};

export const PropertyAddress = ({ property, apn }: Props) => {
	const theme = useTheme();

	const cityStyle = [
		pdfStyle.addressTitleCity,
		{ color: theme.colors.neutral.n500 },
	];

	return (
		<View style={pdfStyle.addressTitleContainer}>
			<Text style={pdfStyle.addressTitleSubmarket}>
				{property.submarket} ({property.marketDisplayName})
			</Text>
			<Text style={pdfStyle.addressTitleAddress}>
				{property.buildingAddress}
			</Text>
			<View>
				<Text style={cityStyle}>
					{property.city}, {property.state} {property.zip}
				</Text>
				{property.buildingName && (
					<Text style={cityStyle}>{property.buildingName}</Text>
				)}
			</View>
			<PdfApn apn={apn} />
		</View>
	);
};
