import { Pinpoint } from '@compstak/maps';
import { Flex, renderTableValue, SimpleTableColumn } from '@compstak/ui-kit';
import { MarketRentAnalyticsPeriod } from 'maps';
import styled from 'styled-components';
import { formatMoney } from 'utils/ui';
import { PropertyWithSummary } from '../useMarketRentPropertiesWithSummaries';

export const createMarketRentColumns = (period: MarketRentAnalyticsPeriod) => {
	const marketRentColumns: SimpleTableColumn<PropertyWithSummary>[] = [
		{
			id: 'pin',
			header: () => '',
			body: () => <StyledPinpoint showCircle />,
			width: 34,
			hAlign: 'center',
		},
		{
			id: 'address',
			header: () => 'Portfolio Property',
			body: ({ row }) => row.buildingAddress,
			width: 120,
			flex: 1,
		},
		{
			id: 'submarket',
			header: () => 'Submarket',
			body: ({ row }) => row.submarket,
			width: 120,
			flex: 1,
		},
		{
			id: 'propertyType',
			header: () => 'Property Type',
			body: ({ row }) => row.buildingPropertyType,
			width: 100,
			flex: 1,
		},
		{
			id: 'currentRent',
			header: () => (
				<>
					<Flex justifyContent="center" style={{ fontWeight: 'bold' }}>
						Current Rent
					</Flex>
					<Flex
						justifyContent="center"
						style={{ fontSize: 10, fontWeight: 400 }}
					>
						per SQFT/YR
					</Flex>
				</>
			),
			body: ({ row }) => {
				const divideBy = period === MarketRentAnalyticsPeriod.YEAR ? 1 : 12;

				return renderTableValue(row.avgCurrentRent, (v) =>
					formatMoney(v / divideBy)
				);
			},
			width: 100,
			flex: 1,
			hAlign: 'center',
		},
	];

	return marketRentColumns;
};

const StyledPinpoint = styled(Pinpoint)`
	width: 15px;
	height: 23px;
`;
