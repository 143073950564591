import React, { memo } from 'react';
import styled from 'styled-components';
import { colors, typography } from '@compstak/ui-kit';
import { PortfolioItem } from 'types/comp';
import { MarketsState } from 'Pages/Login/reducers';
import { SalesComp } from 'types/comp';
import { Apn } from 'Components/CompPageApn/Apn';
import { useMarkets } from 'hooks/useMarkets';

type Props = {
	data: SalesComp;
};

export const PropertyAddress = memo<Props>(({ data }) => {
	const marketId = data.marketId;
	const portfolio = data.portfolio[0];

	const markets = useMarkets();

	const { marketSubmarket, addressLink, addressLine2, buildingName } =
		getAddressParts(portfolio, marketId, markets);

	return (
		<SectionWrapper>
			<MarketSubmarket>{marketSubmarket}</MarketSubmarket>
			<AddressLink href={`/property/${portfolio.propertyId}`}>
				{addressLink}
			</AddressLink>
			<AddressLine2>{addressLine2}</AddressLine2>
			{buildingName ? <AddressLine2>{buildingName}</AddressLine2> : null}
			<AddressLine2>
				<Apn comp={data} />
			</AddressLine2>
		</SectionWrapper>
	);
});

PropertyAddress.displayName = 'PropertyAddress';

const getAddressParts = (
	portfolioItem: PortfolioItem,
	marketId: number,
	markets: MarketsState
) => ({
	marketSubmarket: getMarketSubmarket(
		marketId,
		portfolioItem.submarket,
		markets
	),
	addressLink: portfolioItem.buildingAddress,
	addressLine2: `${portfolioItem.city}, ${portfolioItem.state} ${portfolioItem.zip}`,
	buildingName: portfolioItem.buildingName,
});

const getMarketSubmarket = (
	marketId: number,
	submarket: string,
	markets: MarketsState
) => {
	let displayName = markets[marketId].displayName;
	const marketName = markets[marketId].name;

	if (submarket) {
		const s = submarket.toLowerCase().replace(' ', '_');
		if (s !== marketName) displayName += ` (${submarket})`;
	}
	return displayName;
};

const SectionWrapper = styled.div`
	font-family: ${typography.fontFamily.gotham};
	margin-bottom: 30px;
	padding-bottom: 30px;
	box-shadow: 0 1px ${colors.neutral.n50};
`;

const MarketSubmarket = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 17.5px;
	color: ${colors.neutral.n100};
	text-transform: uppercase;
	overflow-wrap: break-word;
	margin-bottom: 18px;
`;

const AddressLink = styled.a`
	display: inline-block;
	font-size: 32px;
	font-weight: 400;
	line-height: 40px;
	text-decoration: underline;
	color: ${colors.blue.blue500};
	margin-bottom: 14px;
`;

const AddressLine2 = styled.p`
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	color: ${colors.neutral.n500};
	overflow-wrap: break-word;
	margin-bottom: 4px;
`;
