import { MapAnalyticsNoDataMessage } from 'maps';
import {
	MapAnalyticsMenuContainer,
	MapAnalyticsMenuPositionContainer,
} from '../UI';
import { useSpaceTypeAnalyticsState } from './SpaceTypeAnalyticsProvider';
import { SpaceTypeAnalyticsReadMenu } from './SpaceTypeAnalyticsReadMenu';
import { SpaceTypeAnalyticsSettingsMenu } from './SpaceTypeAnalyticsSettingsMenu';
import { useSpaceTypeAnalyticsData } from './useSpaceTypeAnalyticsData';

type Props = {
	onClose: () => void;
};

export const SpaceTypeAnalyticsMenu = ({ onClose }: Props) => {
	const [state] = useSpaceTypeAnalyticsState();
	const { data } = useSpaceTypeAnalyticsData();

	const hasNoData = data && data.length === 0;

	return (
		<MapAnalyticsMenuPositionContainer>
			{hasNoData && <MapAnalyticsNoDataMessage />}
			<MapAnalyticsMenuContainer>
				{state.view === 'read' && (
					<SpaceTypeAnalyticsReadMenu onClickClose={onClose} />
				)}
				{state.view === 'settings' && <SpaceTypeAnalyticsSettingsMenu />}
			</MapAnalyticsMenuContainer>
		</MapAnalyticsMenuPositionContainer>
	);
};
