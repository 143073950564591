import { API, APIClientNotOkResponseError } from '@compstak/common';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { composeEnabled } from '../utils';

export const usePropertyInfoQuery = (
	params: PropertyInfoParams,
	options?: UseQueryOptions<PropertyInfoResponse, APIClientNotOkResponseError>
) => {
	const propertyId = params.propertyId;

	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_INFO, params],
		queryFn: async () => {
			const response = await API.get<PropertyInfoResponse>(
				`/mufa/properties/${propertyId}/info`
			);

			return response.data;
		},
		...options,
		enabled: composeEnabled(propertyId != null, options?.enabled),
	});
};

type PropertyInfoParams = {
	propertyId?: number | string;
};

export type PropertyInfoResponse = {
	propertyId: number;
	hasRpCompSetData: boolean;
	propertyType: PropertyInfoPropertyType;
};

export enum PropertyInfoPropertyType {
	COMMERCIAL = 'commercial',
	MULTIFAMILY = 'multifamily',
	MIXED_USE = 'mixedUse',
}
