import { API } from '@compstak/common';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';

export const useSharedPortfolioByIdQuery = (
	params: SharedPortfolioByIdParams,
	options?: UseQueryOptions<SharedPortfolio>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.SHARED_PORTFOLIO_BY_ID, params],
		queryFn: async () => {
			const response = await API.get<SharedPortfolio>(
				`/pa/v2/api/domain/portfolios/${params.id}`
			);

			return response.data;
		},
		...options,
	});
};

type SharedPortfolioByIdParams = {
	id: number;
};

export type SharedPortfolio = {
	portfolio: {
		id: number;
		userId: number;
		propertyIds: number[];
		name: string;
		description?: string;
	};
	user: {
		id: number;
		firstName: string;
		lastName: string;
	};
};
