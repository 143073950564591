import { DOMAttributes, ReactNode } from 'react';

type EventHandlerKeys = keyof Omit<
	DOMAttributes<HTMLDivElement>,
	'children' | 'dangerouslySetInnerHTML'
>;

type Props = {
	children: ReactNode;
	handler: EventHandlerKeys;
};

export const StopPropagation = ({ children, handler }: Props) => {
	const props = {
		// @ts-expect-error TS7006: Parameter 'e' implicitly has a...
		[handler]: (e) => e.stopPropagation(),
	};
	return <div {...props}>{children}</div>;
};
