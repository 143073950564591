import {
	formatCompQuarter,
	formatMoney,
	formatMonths,
	formatRentBumpsCombined,
	formatRentWithPostfixAsString,
	formatSqft,
} from 'format';
import { LeaseComp, PropertyComp } from 'types';
import { getIsMonthlyMarket, renderSafeValue } from 'utils';
import { EditableLeaseComp, PdfColumn } from './types';

type Props = {
	property: PropertyComp;
};

export const useLeasesPdfColumns = ({
	property,
}: Props): PdfColumn<EditableLeaseComp, LeaseComp>[] => {
	const isMonthly = getIsMonthlyMarket(property.marketId);

	return [
		{
			id: 'executionQuarter',
			header: 'Trans. Qtr.',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.executionQuarter,
					format: formatCompQuarter,
				}),
			isVisible: true,
		},
		{
			id: 'tenantName',
			header: 'Tenant',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.tenantName,
				}),
			hAlign: 'left',
			isVisible: true,
			flex: 2,
		},
		{
			id: 'spaceType',
			header: 'Space Type',
			getValue: ({ row }) => renderSafeValue({ value: row.spaceType }),
			isVisible: true,
		},
		{
			id: 'transactionSize',
			header: 'Size',
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.transactionSize,
					format: formatSqft,
				});
			},
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'floors',
			header: 'Floor(s)',
			getValue: ({ row }) =>
				renderSafeValue({ value: row.floorOccupancies?.formatted }),
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'startingRent',
			header: 'Starting Rent',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.startingRent,
					format: (v) => formatRentWithPostfixAsString(v, isMonthly),
				}),
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'rentSchedule',
			header: 'Rent Schedule',
			getValue: ({ row }) =>
				renderSafeValue({ value: formatRentBumpsCombined(row) }),
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'freeRent',
			header: 'Free Rent',
			getValue: ({ row }) =>
				renderSafeValue({ value: row.freeMonths, format: formatMonths }),
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'workValue',
			header: 'TI Value',
			getValue: ({ row }) =>
				renderSafeValue({ value: row.workValue, format: formatMoney }),
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'leaseTerm',
			header: 'Term',
			getValue: ({ row }) =>
				renderSafeValue({ value: row.leaseTerm, format: formatMonths }),
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'leaseType',
			header: 'Lease Type',
			getValue: ({ row }) => renderSafeValue({ value: row.leaseType }),
			hAlign: 'left',
			isVisible: true,
		},
	];
};
