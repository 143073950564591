import { TooltipV2 } from '@compstak/ui-kit';
import { ReactNode } from 'react';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import Help from '../../ui/svg_icons/help.svg';

type PortfolioHelpIconProps = {
	tooltip: ReactNode;
	height?: number;
	width?: number;
};

export const PortfolioHelpIcon = ({
	tooltip,
	height = 14,
	width = 14,
}: PortfolioHelpIconProps) => (
	<TooltipV2 content={tooltip}>
		<div data-html2canvas-ignore>
			<Help height={height} width={width} />
		</div>
	</TooltipV2>
);
