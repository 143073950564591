import { RowGridContainer } from '@compstak/ui-kit';
import { CSSProperties, useMemo } from 'react';
import { SubmarketTrendsHeader, RealPageLogo } from 'Components';
import {
	NOIContainer,
	SubmarketExpensesPerUnit,
	SubmarketLeaseTradeOut,
	SubmarketMarketTrend,
	SubmarketOccupancy,
	SubmarketSupply,
} from './blocks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMufaSubmarketPolygonsQuery, usePropertyById } from 'api';
import { routes } from 'router';
import { MufaChartFilterProps, useMufaTrendsState } from './useMufaTrendsState';
import { useMufaTrendsData } from 'hooks/useMufaTrendsData';
import { CommonChartProps } from 'types/propertyTrendCharts';

export type MufaSubmarketChartProps = {
	address: string;
} & MufaChartFilterProps &
	CommonChartProps;

type Props = ReturnType<typeof useMufaTrendsState> & {
	data: ReturnType<typeof useMufaTrendsData>['data'];
};

export const MufaSubmarketSection = ({
	filters,
	handleFilterChange,
	data,
}: Props) => {
	const propertyId = routes.propertyById.useParams().propertyId;

	const { data: propertyData } = usePropertyById({ propertyId });
	const { data: submarketPolygons } = useMufaSubmarketPolygonsQuery({
		rpMarketId: propertyData?.rpMarketId,
	});

	const submarketPolygon = useMemo(() => {
		return submarketPolygons?.features.find((feature) => {
			return feature.properties.regioncode === propertyData?.rpSubmarketCode;
		});
	}, [submarketPolygons, propertyData?.rpSubmarketCode]);

	const flags = useFeatureFlags();

	const address = propertyData?.buildingAddressAndCity ?? '';

	return (
		<div style={widgetContainerStyle}>
			<SubmarketTrendsHeader
				submarketName={propertyData?.rpSubmarketName}
				submarketPolygon={submarketPolygon}
				polygonDataSource="RealPage"
			>
				Multifamily Submarket Trends
			</SubmarketTrendsHeader>
			<RowGridContainer>
				<SubmarketMarketTrend
					address={address}
					filters={filters}
					handleFilterChange={handleFilterChange}
					submarketMarketTrend={data.submarketMarketTrend}
				/>
				<SubmarketLeaseTradeOut
					address={address}
					filters={filters}
					handleFilterChange={handleFilterChange}
					submarketLeaseTradeOut={data.submarketLeaseTradeOut}
				/>
			</RowGridContainer>
			<RowGridContainer>
				<SubmarketOccupancy
					address={address}
					filters={filters}
					handleFilterChange={handleFilterChange}
					submarketOccupancy={data.submarketOccupancy}
				/>
				<SubmarketSupply
					address={address}
					filters={filters}
					handleFilterChange={handleFilterChange}
					submarketSupply={data.submarketSupply}
				/>
			</RowGridContainer>
			{flags['expensesNOI'] && (
				<RowGridContainer style={lastRowStyle}>
					<NOIContainer
						address={address}
						filters={filters}
						handleFilterChange={handleFilterChange}
						submarketNOI={data.submarketNOI}
					/>
					<SubmarketExpensesPerUnit
						address={address}
						filters={filters}
						handleFilterChange={handleFilterChange}
						expensesPerUnit={data.expensesPerUnit}
					/>
				</RowGridContainer>
			)}
			<RealPageLogo baseStyles={dataProviderLogoWrapperStyle} />
		</div>
	);
};

MufaSubmarketSection.displayName = 'MufaSubmarketSection';

const widgetContainerStyle: CSSProperties = { marginBottom: 32 };
const lastRowStyle: CSSProperties = { marginBottom: 0 };
const dataProviderLogoWrapperStyle: CSSProperties = {
	display: 'flex',
	justifyContent: 'end',
	paddingTop: '16px',
};
