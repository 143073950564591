import { CompType } from 'types';
import { FiltersObject } from '../types';
import { createFilters } from '..';

/** @returns { filters, deletedFilters } */
export const deleteCompTypeSpecificFilters = <T extends Partial<FiltersObject>>(
	filters: T,
	compType: CompType
) => {
	let deletedFilters: Partial<FiltersObject> = {};
	const compTypeFilters = createFilters(compType);

	Object.typedKeys(filters)
		.filter((key) => !!filters[key])
		.forEach((key) => {
			if (!(key in compTypeFilters)) {
				deletedFilters = { ...deletedFilters, [key]: filters[key] };
				filters = deleteFilter(filters, key);
			}
		});

	return { filters, deletedFilters };
};

const deleteFilter = <T extends object, K extends keyof T>(
	filters: T,
	key: K
): T => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { [key]: _, ...newFilters } = filters;
	return newFilters as T;
};
