import constate from 'constate';
import { getParamsFromQueryString } from 'models/filters/util';
import { ComponentType } from 'react';
import {
	NavigateFunction,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router';
import { useDeepCompareMemo } from 'use-deep-compare';
import { LegacyRouter, LocationDescriptor, RouteComponentProps } from './types';
import { useHistoryState } from 'router/HistoryProvider';

/**
 * Wraps the component passed as prop to Route ( react-router v3 )
 * and renders a RouteContext so that we can mimic react-router v6 hook api
 */
export const routeWrapper = (Component: ComponentType<any>) => {
	const RouteWrapper = (props: any) => {
		const location = useLocation();
		const params = useParams();
		const navigate = useNavigate();
		const { goBack } = useHistoryState();

		const memoizedParams = useDeepCompareMemo(() => params, [params]);

		const query = useDeepCompareMemo(() => {
			return getParamsFromQueryString(location.search);
		}, [location]);

		const legacyRouter = useDeepCompareMemo<LegacyRouter>(() => {
			return {
				push: createLegacyNavigate(navigate),
				replace: createLegacyNavigate(navigate, true),
				go: (delta) => navigate(delta),
				goBack,
				params: memoizedParams,
			};
		}, [memoizedParams]);

		const legacyLocation = useDeepCompareMemo(() => {
			return {
				pathname: location.pathname,
				search: location.search,
				state: location.state,
				query,
			};
		}, [location.pathname, location.search, location.state, query]);

		const memoizedProps = useDeepCompareMemo(() => {
			return props;
		}, [props]);

		return (
			<RouteProvider
				{...memoizedProps}
				params={memoizedParams}
				routeParams={memoizedParams}
				location={legacyLocation}
				router={legacyRouter}
			>
				<Component
					{...memoizedProps}
					params={memoizedParams}
					routeParams={memoizedParams}
					location={legacyLocation}
					router={legacyRouter}
				/>
			</RouteProvider>
		);
	};

	return RouteWrapper;
};

export const [RouteProvider, useRouteContext] = constate(
	(routeProps: RouteComponentProps) => routeProps
);

export const createLegacyNavigate =
	(navigate: NavigateFunction, replace = false) =>
	(newLocation: LocationDescriptor) => {
		navigate(newLocation, {
			replace,
			state: typeof newLocation === 'object' ? newLocation.state : {},
		});
	};
