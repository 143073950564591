import styled, { css } from 'styled-components';

export const filterInputCSS = css`
	width: 100%;
	height: 42px;
	transition: all 0.4s ease-in-out;
	border-radius: 2px;
	color: ${({ theme }) => theme.colors.white.white};
	padding-left: 0.5rem;
	padding-right: 2rem;
	background: transparent;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray500};
	font-size: 13px;
	box-sizing: border-box;
	margin-top: 3px;
	display: inline-block;
	line-height: 42px;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
`;

export const FilterInput = styled.input`
	${filterInputCSS}
`;

export const FilterInputPlaceholder = styled.div`
	${filterInputCSS}
`;
