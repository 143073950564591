import {
	AlertCircle,
	Button,
	Flex,
	Modal,
	Spinner,
	useBoolean,
	useNotifications,
} from '@compstak/ui-kit';
import { useAppConfig } from 'Pages/Login/reducers';
import dayjs from 'dayjs';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { Link } from 'router';
import styled, { css } from 'styled-components';
import IconAlertFill from 'ui/svg_icons/alert-fill.svg';
import { isValidEmail } from 'util/validate';
import { RewardRedemptionsT, useRedeemRewardMutation } from './RewardQueries';
import { EMPTY_VENDOR_ID } from './constants';
import {
	BalanceHeading,
	ButtonWrapper,
	CancelButton,
	ErrorWrapper,
	HistoryHeading,
	HistoryWrapper,
	InputWrapper,
	ModalBobyWrapper,
	RewardsInfoWrapper,
	Vendor,
	VendorsWrapper,
} from './styles';
import { Vendors } from './types';
import { UserW9Response } from 'api';

type RewardsInfoProps = {
	balanceInDollars: number;
	redemptionsInfo: RewardRedemptionsT;
	vendors: Vendors;
	userW9: UserW9Response;
};

export const RewardsInfo: FC<RewardsInfoProps> = ({
	balanceInDollars,
	redemptionsInfo,
	vendors,
	userW9,
}) => {
	const { giftbitVendorIds } = useAppConfig();
	const { addNotification } = useNotifications();
	const [isModalOpen, openModal, closeModal] = useBoolean(false);
	const { redemptions } = redemptionsInfo;
	const [email, setEmail] = useState('');
	const [vendorId, setVendorId] = useState(EMPTY_VENDOR_ID);
	const [validationErrors, setValidationErrors] = useState({
		email: false,
	});

	const onSuccess = async () => {
		await addNotification({
			icon: 'success',
			title: 'Redeemed successfully!',
		});
		closeModalAndResetFields();
	};

	const onError = async () => {
		addNotification({
			icon: 'error',
			title: 'Something went wrong. Please try again later.',
		});
		closeModalAndResetFields();
	};

	const { mutateAsync: redeemReward, isLoading: isRedeeming } =
		useRedeemRewardMutation({ onSuccess, onError });

	const totalDollarsRedeemed: number = useMemo(() => {
		const totalCents = redemptions.reduce((sum, curr) => {
			return sum + curr.cents;
		}, 0);
		return totalCents / 100;
	}, [redemptions]);

	const closeModalAndResetFields = () => {
		closeModal();
		setEmail('');
		setVendorId(EMPTY_VENDOR_ID);
		setValidationErrors({ email: false });
	};

	const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValidationErrors({
			...validationErrors,
			email: !isValidEmail(event.target.value),
		});
		setEmail(event.target.value);
	};

	const ErrorMsg = ({ errorText }: { errorText: string }) => (
		<ErrorWrapper alignItems="center" justifyContent="flex-start">
			<AlertCircle />
			<p>{errorText}</p>
		</ErrorWrapper>
	);

	const hasCredits = balanceInDollars > 0;

	const totalDollarsRedeemedThisCalendarYear = useMemo(() => {
		return (
			redemptions
				.filter(
					(r) =>
						new Date(r.redeemedAt).getFullYear() === new Date().getFullYear()
				)
				.reduce((sum, curr) => {
					return sum + curr.cents;
				}, 0) / 100
		);
	}, [redemptions]);

	const isW9Submitted = typeof userW9 === 'object';

	const limit = isW9Submitted ? Infinity : MAX_REDEMPTION_WITHOUT_W9;

	const isOverLimit =
		totalDollarsRedeemedThisCalendarYear + balanceInDollars >= limit;

	const canRedeem = hasCredits && !isOverLimit;

	const showW9Message = hasCredits && isOverLimit && !isW9Submitted;

	return (
		<>
			<RewardsInfoWrapper alignItems="flex-start" gap="2rem">
				<div>
					<BalanceHeading>
						Your Current Balance: <span>{dollarFormat(balanceInDollars)}</span>
					</BalanceHeading>
					<InfoList>
						<InfoListItem>
							Your current balance does not include{' '}
							<Link to="/credits">your pending submissions</Link>.
						</InfoListItem>
						<InfoListItem>
							You have the option to redeem the full amount of your current
							balance at any time.
						</InfoListItem>
						<InfoListItem>
							To request a partial redemption of your balance, please contact
							your account manager. Our team is here to assist you with
							customized redemption options that suit your needs.
						</InfoListItem>
					</InfoList>
					<Button variant="success" disabled={!canRedeem} onClick={openModal}>
						REDEEM NOW
					</Button>
					{showW9Message && (
						<MessageContainer>
							<IconAlertFill width={24} />
							<div>
								<MessageText>
									Please send a completed W-9 form to{' '}
									<a href="mailto:exchange@compstak.com">
										exchange@compstak.com
									</a>
								</MessageText>
								<MessageText>
									We will contact you as soon as we process your form so you can
									redeem your rewards.
								</MessageText>
							</div>
						</MessageContainer>
					)}
				</div>
				<div>
					<HistoryHeading>Reward History</HistoryHeading>
					<HistoryWrapper>
						<Flex>
							<p>Date</p>
							<p>Redeemed</p>
						</Flex>
						<div>
							{redemptions.map(({ redeemedAt, cents }) => (
								<Flex key={redeemedAt}>
									<p>{dayjs(redeemedAt).format('MM/DD/YYYY')}</p>
									<p>{dollarFormat(cents / 100)}</p>
								</Flex>
							))}
						</div>
						<Flex>
							<p>Total</p>
							<p>{dollarFormat(totalDollarsRedeemed)}</p>
						</Flex>
					</HistoryWrapper>
				</div>
			</RewardsInfoWrapper>
			{isModalOpen && (
				<Modal onClose={closeModalAndResetFields}>
					<ModalBobyWrapper>
						<h3>Redeem your Gift Card</h3>

						<p>Select a Provider</p>
						<VendorsWrapper>
							{giftbitVendorIds?.map((id: number) => (
								<Vendor
									selected={id === vendorId}
									key={id}
									onClick={() => setVendorId(id)}
								>
									<img
										src={vendors.find((vendor) => vendor.id === id)?.image_url}
										alt="vendor"
									/>
								</Vendor>
							))}
						</VendorsWrapper>
						<p>Send your Virtual Gift Card to:</p>
						<InputWrapper error={validationErrors.email}>
							<input
								type="email"
								placeholder="Type in your email address"
								onChange={onEmailChange}
								value={email}
								data-qa-id="giftcard-email"
							/>
							{validationErrors.email && (
								<ErrorMsg errorText="Type in a valid email address" />
							)}
						</InputWrapper>
						<p>
							If you have any questions, contact us at{' '}
							<a href="mailto:help@compstak.com">help@compstak.com</a> or{' '}
							<a href="tel:+16469266707">(646) 926-607</a>.
						</p>
						<ButtonWrapper justifyContent="flex-end">
							<CancelButton onClick={closeModalAndResetFields}>
								Go back
							</CancelButton>
							<Button
								variant="success"
								disabled={
									Object.values(validationErrors).some((i) => Boolean(i)) ||
									!canRedeem ||
									!email ||
									!vendorId
								}
								onClick={() =>
									redeemReward({
										email,
										vendorId,
									})
								}
							>
								{isRedeeming ? <Spinner size="s" /> : 'Redeem gift card'}
							</Button>
						</ButtonWrapper>
					</ModalBobyWrapper>
				</Modal>
			)}
		</>
	);
};

const MAX_REDEMPTION_WITHOUT_W9 = 600;

function dollarFormat(dollars: number) {
	// Example: $120.50
	return `$${dollars.toFixed(2)}`;
}

const InfoList = styled.ul`
	list-style-type: disc;
	padding-left: 1rem;
	margin-bottom: 2rem;
`;

const infoTextCss = css`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.5;
`;

const InfoListItem = styled.li`
	${infoTextCss}
`;

const MessageContainer = styled.div`
	display: flex;
	gap: 0.625rem;
	margin-top: 1rem;
`;

const MessageText = styled.div`
	${infoTextCss}
`;
