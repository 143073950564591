import { getActualLabel } from './util';
import sortBy from 'lodash/sortBy';

export default function (
	// @ts-expect-error TS7006: Parameter 'placedSpaces' impli...
	placedSpaces,
	// @ts-expect-error TS7006: Parameter 'floors' implicitly ...
	floors,
	// @ts-expect-error TS7006: Parameter 'floorSpaceOccupied'...
	floorSpaceOccupied,
	// @ts-expect-error TS7006: Parameter 'position' implicitl...
	position,
	// @ts-expect-error TS7006: Parameter 'minRatio' implicitl...
	minRatio
) {
	// WTF? Yes, WTF.

	// This allows animations when moving spaces between and within floors.
	// The problem is that if you reposition an item in the DOM it won't animate
	// between the two places. This has obvious concequences when moving between
	// floors, but also breaks animations within floors because swapping two elements
	// will break the animation.

	// To solve this issue, the place each element will appear must be calculated
	// manually. Additionally, we must never move elements in or out of the DOM
	// so they always have to be drawn in the same order.

	// This is needed to figure out how far down to draw the space, floor index * 100% gets you there.
	// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
	const floorIndices = floors.reduce((acc, f, i) => {
		acc[getActualLabel(f.label)] = floors.length - i - 1;
		return acc;
	}, {});

	// This is a convenience for making the grey space of the floor and to calculate overflow
	// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
	const floorSizes = placedSpaces.reduce((acc, s) => {
		const label = getActualLabel(s.floor);
		acc[label] = (acc[label] || 0) + s.size;
		return acc;
	}, {});

	const largestFloorSize = floors[0] ? floors[0].size : 0;

	// This gets mutated. It's how much blank space (to the left) on each floor.
	// For left aligned buildings it starts at zero. For right and center aligned
	// buildings it's the amount of whitespace on the left.
	// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
	const currentFloorPosition = floors.reduce((acc, f) => {
		const actualLabel = getActualLabel(f.label);
		if (position === 'left' || f.size === largestFloorSize) {
			acc[actualLabel] = 0;
		} else if (position === 'right') {
			acc[actualLabel] = (largestFloorSize - f.size) / largestFloorSize;
		} else if (position === 'center') {
			acc[actualLabel] = (largestFloorSize - f.size) / largestFloorSize / 2;
		}
		acc[actualLabel] = acc[actualLabel] * 100;
		return acc;
	}, {});

	// If a floor is more than 85 percent full we display it as full to compensate
	// for loss factor. This determines the size we should call 100%.
	// @ts-expect-error TS7006: Parameter 'label' implicitly h...
	function getSizeWithLossFactorOrOverfill(label) {
		const actualLabel = getActualLabel(label);
		const floorIndex = floors.findIndex(
			// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
			(f) => getActualLabel(f.label) === actualLabel
		);
		const filledRatio = floorSizes[actualLabel] / floors[floorIndex].size;

		if (filledRatio < 0.85) {
			return floors[floorIndex].size;
		} else {
			return floorSizes[actualLabel];
		}
	}

	// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
	const smallSpaces = floors.reduce((acc, f) => {
		acc[getActualLabel(f.label)] = [];
		return acc;
	}, {});

	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	placedSpaces.forEach((space) => {
		const actualLabel = getActualLabel(space.floor);
		const floorSizeWithLossFactorOrOverfill = getSizeWithLossFactorOrOverfill(
			actualLabel
		);
		if (
			space.size / floorSizeWithLossFactorOrOverfill < minRatio &&
			space.occupancy === 'Tenant'
		) {
			if (!smallSpaces[actualLabel]) {
				smallSpaces[actualLabel] = [];
			}
			smallSpaces[actualLabel].push(space);
		}
	});

	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	const createSingleSpaceObj = (space) => {
		let width;
		const actualLabel = getActualLabel(space.floor);
		const floorIndex = floorIndices[actualLabel] || 0;

		const floorSizeWithLossFactorOrOverfill = getSizeWithLossFactorOrOverfill(
			actualLabel
		);
		// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
		const floor = floors.find((f) => getActualLabel(f.label) === actualLabel);

		let weddingCakeRatio = 1;
		if (floor.size < largestFloorSize) {
			weddingCakeRatio = floor.size / largestFloorSize;
		}

		// width is always in relation to the widest floor, since that is the width of the container.
		// left is a % that is in relation to the size of the element.
		width =
			(space.size / floorSizeWithLossFactorOrOverfill) * 100 * weddingCakeRatio;
		const positionLeft = currentFloorPosition[actualLabel];

		// this puts a teeny little thing there when it's too small so we can still animate it in
		const tenantIsTooSmall =
			space.size / floorSizeWithLossFactorOrOverfill < minRatio &&
			space.occupancy === 'Tenant';
		if (tenantIsTooSmall && smallSpaces[actualLabel].length) {
			width = 0;
		}

		currentFloorPosition[actualLabel] += width;

		return {
			id: space.id,
			space,
			positionLeft,
			width,
			floorIndex,
		};
	};

	const largeSpaceDatas = placedSpaces.map(createSingleSpaceObj);

	const smallSpaceDatas = Object.keys(smallSpaces).map((actualLabel) => {
		const spaces = smallSpaces[actualLabel];
		let width;
		const floorIndex = floorIndices[actualLabel] || 0;

		const floorSizeWithLossFactorOrOverfill = getSizeWithLossFactorOrOverfill(
			actualLabel
		);
		// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
		const totalFloorSize = spaces.reduce((acc, s) => acc + s.size, 0);

		// width is always in relation to the widest floor, since that is the width of the container.
		// left is a % that is in relation to the size of the element.
		width = (totalFloorSize / floorSizeWithLossFactorOrOverfill) * 100;
		const positionLeft = currentFloorPosition[actualLabel];

		if (spaces.length < 1) {
			width = 0;
		}

		currentFloorPosition[actualLabel] += width;

		return {
			id: 'small-' + actualLabel,
			spaces,
			positionLeft,
			width,
			floorIndex,
		};
	});

	// this ensures that their order in the DOM never changes.
	return sortBy([...largeSpaceDatas, ...smallSpaceDatas], ['id']);
}
