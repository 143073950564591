import { CherreLoan } from 'api';
import { PdfColumn } from '../types';
import { numberUtils } from '@compstak/ui-kit';
import { isObject } from 'lodash';
import { formatDate, formatPercent } from 'format';
import { renderSafeValue } from 'utils';
import { formatYesNo } from 'format/formatYesNo';

export type PublicLoansColumn = PdfColumn<
	Record<string, string | number>,
	CherreLoan
>;

export const usePublicLoansPdfColumns = () => {
	const columns: PublicLoansColumn[] = [
		{
			id: 'loanOriginationDate',
			header: 'Origination Date',
			getValue: ({ row }) =>
				renderSafeValue({ value: row.loanOriginationDate, format: formatDate }),
			isVisible: true,
		},
		{
			id: 'loanPurpose',
			header: 'Loan Purpose',
			getValue: ({ row }) =>
				renderSafeValue({
					value: isObject(row.loanPurpose) ? null : row.loanPurpose,
				}),
			isVisible: true,
		},
		{
			id: 'loanOriginator',
			header: 'Lender',
			getValue: ({ row }) => renderSafeValue({ value: row.loanOriginator }),
			isVisible: true,
		},
		{
			id: 'loanAmount',
			header: 'Loan Amount',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanAmount,
					format: (v) => numberUtils.formatCurrency(v, 'currencyInteger'),
				}),
			isVisible: true,
		},
		{
			id: 'loanTerm',
			header: 'Original Loan Term',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanTerm,
					format: (v) => `${v} Months`,
				}),
			isVisible: true,
		},
		{
			id: 'loanType',
			header: 'Loan Type',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanType,
				}),
			isVisible: true,
		},
		{
			id: 'originationLoanRate',
			header: 'Origination Loan Rate',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.originationLoanRate,
					format: (v) => formatPercent(v / 100),
				}),
			isVisible: true,
		},
		{
			id: 'loanMaturityDate',
			header: 'Loan Maturity Date',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanMaturityDate,
					format: formatDate,
				}),
			isVisible: true,
		},
		{
			id: 'loanPrePaymentPenalty',
			header: 'Pre Payment Penalty',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanPrePaymentPenalty,
					format: formatYesNo,
				}),
			isVisible: true,
		},
		{
			id: 'loanInterestOnlyLoan',
			header: 'Interest-Only Loan',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanInterestOnlyLoan,
					format: formatYesNo,
				}),
			isVisible: true,
		},
		{
			id: 'loanInterestOnlyPeriod',
			header: 'IO Loan Term',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.loanInterestOnlyPeriod,
					format: (v) => formatDate(v, 'MMM YYYY'),
				}),
			isVisible: true,
		},
	];

	return columns;
};
