import React, {
	ChangeEventHandler,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import SearchIcon from 'ui/svg_icons/search.svg';
import '../../styles/marketSelector.nomodule.less';
import formConstants from 'util/formConstants';
import { CompType } from 'types/comp';
import { Market } from '@compstak/common';
import { AppConfig } from 'types/config';
import { PermissionsState } from 'Pages/Login/reducers';
import { MarketListSearchView } from './MarketListSearchView';
import { MarketListFullView } from './MarketListFullView';
import { ListSearchInputStyled } from '../../../../../Components/ListSearchInput/ListSearchInput';
import { getSearchTermsForMarkets } from 'util/getSearchTerms';
import { useMarkets } from 'hooks/useMarkets';

export type MarketListProps = {
	appConfig: AppConfig;
	changeMarket: (market: Market) => void;
	compType: CompType;
	permissions: PermissionsState;
	search: ChangeEventHandler<HTMLInputElement>;
	searchTerm: string;
	market: Market;
	marketSelectorExpanded: boolean;
};

export const MarketList = ({
	appConfig,
	changeMarket,
	compType,
	permissions,
	search,
	searchTerm,
	market,
	marketSelectorExpanded,
}: MarketListProps) => {
	const [expandedState, setExpandedState] = useState('');
	const markets = useMarkets();

	const toggleStateMarkets = useCallback((state: string) => {
		setExpandedState((prevExpandedState) =>
			prevExpandedState === state ? '' : state
		);
	}, []);

	useEffect(() => {
		const marketStateHasChanged = !market.states.some(
			({ name }) => name.toLowerCase() === expandedState
		);
		setExpandedState(
			marketStateHasChanged
				? market.states[0]?.name.toLowerCase()
				: expandedState
		);
	}, [market.states]);

	const [searchTermsToMarkets, marketsGroupedByState] = useMemo(() => {
		const searchTermsToMarkets = getSearchTermsForMarkets(markets.list);

		const marketsGroupedByState = formConstants.usStates.reduce<
			Record<string, Market[]>
		>((acc, state) => {
			const stateName = state.name.toLowerCase();
			const stateMarkets = markets.list.filter((market) =>
				market.states.some(
					(marketState) => marketState.name.toLowerCase() === stateName
				)
			);
			if (stateMarkets.length) {
				return { ...acc, [stateName]: stateMarkets };
			}

			return acc;
		}, {});

		return [searchTermsToMarkets, marketsGroupedByState];
	}, [markets.list]);

	const type = compType === 'sale' ? 'sales' : 'lease';

	return (
		<TransitionGroup>
			{marketSelectorExpanded && (
				<Transition
					key={`market-selector-${
						marketSelectorExpanded ? 'expanded' : 'hidden'
					}`}
				>
					<div className="market-list-wrap">
						<ListSearchInputStyled>
							<SearchIcon width="14px" height="14px" />
							<input
								data-qa-id="market-list-search-input"
								type="text"
								autoFocus
								onChange={search}
								placeholder="Search by state or market"
								defaultValue={searchTerm}
							/>
						</ListSearchInputStyled>
						<ul className="market-list">
							{searchTerm ? (
								<MarketListSearchView
									changeMarket={changeMarket}
									isEnterprise={appConfig.isEnterprise}
									permissions={permissions}
									searchTerm={searchTerm}
									searchTermsToMarkets={searchTermsToMarkets}
									type={type}
								/>
							) : (
								<MarketListFullView
									changeMarket={changeMarket}
									expandedState={expandedState}
									isEnterprise={appConfig.isEnterprise}
									marketsGroupedByState={marketsGroupedByState}
									permissions={permissions}
									toggleStateMarkets={toggleStateMarkets}
									type={type}
								/>
							)}
						</ul>
					</div>
				</Transition>
			)}
		</TransitionGroup>
	);
};

export const Transition = ({ children, ...props }: PropsWithChildren) => (
	<CSSTransition {...props} classNames="market-list-content" timeout={500}>
		{children}
	</CSSTransition>
);

Transition.displayName = 'MarketList_Transition';
