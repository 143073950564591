import constate from 'constate';
import { useState } from 'react';
import { useEditableLeaseSnapshot } from './OverviewPage/useEditableLeaseSnapshot';
import { useEditableOverview } from './OverviewPage/useEditableOverview';
import { getDocumentTitle } from './getDocumentTitle';
import { ExportPdfPageState } from './types';
import { ExportPdfData } from './useExportPdfData';

type Props = {
	data: ExportPdfData;
};

export const [ExportPdfStateProvider, useExportPdfStateContext] = constate(
	({ data }: Props) => {
		const { user, property } = data;

		const initialTitle = getDocumentTitle(property);

		const initialPreparedBy = `${user.firstName} ${user.lastName}
${user.email}
${user.phone ? user.phone : ''}`;

		const [page, setPage] = useState<ExportPdfPageState>({
			titles: {
				cover: initialTitle,
				compset: initialTitle,
				leasesInCompset: initialTitle,
				leases: initialTitle,
				loans: initialTitle,
				overview: initialTitle,
				sales: initialTitle,
				multifamily: initialTitle,
				submarketTrends: initialTitle,
				leaseSnapshot: initialTitle,
				marketEstimates: initialTitle,
				marketStats: initialTitle,
			},
			preparedFor: '',
			preparedForLogo: null,
			preparedBy: initialPreparedBy,
			notes: '',
			logo: null,
			labelsVisible: {
				createdOn: true,
				preparedFor: true,
				preparedBy: true,
				notes: true,
			},
		});

		const { editableOverviewFields, setEditableOverviewFields } =
			useEditableOverview({
				property: data.property,
			});

		const { editableLeaseSnapshotFields, setEditableLeaseSnapshotFields } =
			useEditableLeaseSnapshot({ data });

		return {
			page,
			setPage,
			editableOverviewFields,
			setEditableOverviewFields,
			editableLeaseSnapshotFields,
			setEditableLeaseSnapshotFields,
		};
	}
);
