import { TENANT_INDUSTRY_COLORS } from 'constants/colors';
import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import {
	createTopTenantIndustryNode,
	LOADING_TREEMAP,
	TreemapContainer,
} from 'ExchangeDashboard/TenantIndustryDistribution';
import { Treemap } from 'ExchangeDashboard/TenantIndustryDistribution/Treemap';
import { TreeNode } from 'ExchangeDashboard/TenantIndustryDistribution/treemapTypes';
import { MapAnalyticsLegendCircle } from 'maps';
import { useCallback } from 'react';
import useDimensions from 'react-use-dimensions';
import styled from 'styled-components';
import { useExportPdfState } from '../ExportPdfProvider';
import { PanelDescription, PDFPanel } from '../PreviewComponents/UI';
import { useLegendData } from './useLegendData';

export const TenantIndustryDistributionSection = () => {
	const { data, isFetching } = useExchangeDashboardTenantIndustryDistribution();
	const [panelRef, dimensions] = useDimensions();
	const { registerImage } = useExportPdfState();

	const refCallback = useCallback(
		(el: HTMLElement | null) => {
			// TODO: Hacky, might be avoided with a "Prepare export" button
			setTimeout(() => {
				registerImage('tenantIndustryDistribution', el);
			});
		},
		[registerImage]
	);

	const getSize = useCallback((node: TreeNode) => node.data?.size ?? 0, []);

	const sortFunction = useCallback(
		(n1: TreeNode, n2: TreeNode) => (n2.data?.size ?? 0) - (n1.data?.size ?? 0),
		[]
	);

	const legendData = useLegendData({ data });

	return (
		<PDFPanel ref={panelRef} title="Tenant Industry Distribution">
			{isFetching ? (
				<div>
					<StyledTreemapContainer>
						<div className="container">
							<Treemap
								width={(dimensions?.width ?? 42) - 42}
								height={400}
								margin={2}
								data={{
									children: LOADING_TREEMAP,
								}}
								getSize={getSize}
								sortFunction={sortFunction}
							/>
						</div>
					</StyledTreemapContainer>
				</div>
			) : data && data.length > 0 ? (
				<div>
					<StyledTreemapContainer>
						<div className="container" ref={refCallback}>
							<Treemap
								width={(dimensions?.width ?? 42) - 42}
								height={400}
								margin={2}
								data={{
									children: data.map(createTopTenantIndustryNode),
								}}
								getSize={getSize}
								sortFunction={sortFunction}
							/>
						</div>
					</StyledTreemapContainer>
				</div>
			) : (
				<div>No Data To Display. Update Filters and Try Again.</div>
			)}
			{data && data.length > 0 && (
				<>
					<LegendContainer>
						{legendData.map((ti, idx) => (
							<LegendItem key={idx} data-tooltip={ti.industry}>
								<MapAnalyticsLegendCircle
									style={{
										backgroundColor: `rgb(${
											// @ts-expect-error TS7053: Element implicitly has an 'any...
											TENANT_INDUSTRY_COLORS[ti.industry]
										})`,
									}}
								/>
								<LegendItemLabel>{ti.industry}</LegendItemLabel>
							</LegendItem>
						))}
					</LegendContainer>
					<PanelDescription>
						Top ten industries based on total square footage in selected market
						and submarket(s).
					</PanelDescription>
				</>
			)}
		</PDFPanel>
	);
};

const StyledTreemapContainer = styled(TreemapContainer)`
	.container {
		min-height: 400px;
		position: relative;
	}
	margin-bottom: 0.5rem;
`;

const LegendContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 0 0.5rem;
`;

const LegendItem = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const LegendItemLabel = styled.div`
	font-size: 0.75rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
`;
