import { MapAnalyticsLegendCircle } from 'maps';
import styled from 'styled-components';

export const SpaceTypeAnalyticsLegend = () => {
	return (
		<>
			<Title>Color Legend</Title>
			<ul>
				{spaceTypes.map((spaceType, index) => {
					return (
						<ListItem key={index}>
							<MapAnalyticsLegendCircle
								style={{ backgroundColor: spaceType.color }}
							/>
							{spaceType.name}
						</ListItem>
					);
				})}
			</ul>
		</>
	);
};

const spaceTypes = [
	{ name: 'Office', color: '#438FF7' },
	{ name: 'Industrial', color: '#455A63' },
	{ name: 'Retail', color: '#10D86A' },
] as const;

const Title = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 12px;
	margin-bottom: 1rem;
`;

const ListItem = styled.li`
	display: flex;
	align-items: center;
	line-height: 17px;
	gap: 10px;
	&:not(:last-of-type) {
		margin-bottom: 1rem;
	}
`;
