import { defaultTheme } from '@compstak/ui-kit';
import { formatDate } from 'format';
import {
	ChartLegendBubble,
	ChartLegendLine,
} from 'Pages/Analytics/Builder/Components/Components';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TOOLTIP_WIDTH } from './config';

type Props = {
	context: Highcharts.TooltipFormatterContextObject;
	tooltip: Highcharts.Tooltip;
};

export const Tooltip = ({ context, tooltip }: Props) => {
	const points = useTooltipPoints({
		context,
		tooltip,
	});

	const time = Number(context.x);

	return (
		<Root>
			<MonthLabel>{formatDate(time, 'MMM‘YYYY')}</MonthLabel>
			<ul>
				{points.map((point) => {
					const formatter = point.series.yAxis.userOptions.labels?.formatter;
					const isBubble = point.series.type === 'bubble';
					return (
						// @ts-expect-error TS2339: Property 'key' does not exist ...
						<SeriesItem key={point.key}>
							<SeriesIndicatorContainer>
								{isBubble ? (
									// @ts-expect-error TS2769: No overload matches this call....
									<ChartLegendBubble color={point.series.color} />
								) : (
									// @ts-expect-error TS2769: No overload matches this call....
									<ChartLegendLine color={point.series.color} />
								)}
							</SeriesIndicatorContainer>
							<SeriesName>{point.series.name}</SeriesName>
							{/* @ts-expect-error TS18048: 'formatter' is possibly 'undef... */}
							<PointValue>{formatter.call({ value: point.y })}</PointValue>
						</SeriesItem>
					);
				})}
			</ul>
		</Root>
	);
};

type UseTooltipPointsArgs = {
	context: Highcharts.TooltipFormatterContextObject;
	tooltip: Highcharts.Tooltip;
};

// needed to show line and bubble points on the same tooltip
// also handles case for multiple bubble points with same x value
const useTooltipPoints = ({ context, tooltip }: UseTooltipPointsArgs) => {
	return useMemo(() => {
		const points: Highcharts.Point[] = [];

		const currentBubblePoint = context.point;

		const isBubbleHovered = !!currentBubblePoint;

		const time = currentBubblePoint
			? currentBubblePoint.x
			: context.points?.[0].x;

		for (const series of tooltip.chart.series) {
			if (series.type === 'line') {
				let point;
				if (isBubbleHovered) {
					point = series.data.find((d) => d.x === time);
				} else {
					point = context.points?.find((p) => p.series === series);
				}
				if (point) {
					// @ts-expect-error TS2345: Argument of type 'TooltipForma...
					points.push(point);
				}
			}
			if (series.type === 'bubble') {
				// @ts-expect-error TS2345: Argument of type 'string | num...
				const point = getBubblePoint(series, time);
				if (point) {
					points.push(point);
				}
			}
		}

		return points;
	}, [context, tooltip.chart.series]);
};

// get a point for bubble series for a given x value
// if multiple points - aggregate them into a single point
const getBubblePoint = (
	series: Highcharts.Series,
	x: Highcharts.Point['x']
) => {
	const points = series.data.filter((d) => d.x === x);
	if (points?.length === 1) {
		return points[0];
	} else if (points.length > 1) {
		const sumY = points.reduce((sum, d) => sum + (d.y ?? 0), 0);
		const avgY = sumY / points.length;
		const point = {
			...points[0],
			y: avgY,
		};
		return point as Highcharts.Point;
	}
};

const Root = styled.div`
	padding: 1rem;
	background-color: ${defaultTheme.colors.gray.gray700};
	border-radius: 3px;
	width: ${TOOLTIP_WIDTH}px;
`;

const MonthLabel = styled.div`
	font-family: ${defaultTheme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: 325;
	font-size: 10px;
	line-height: 10px;
	letter-spacing: 0.02rem;
	color: #ffffff;
	margin-bottom: 1rem;
`;

const SeriesItem = styled.li`
	display: flex;
	align-items: center;
	gap: 6px;
	&:not(:last-child) {
		margin-bottom: 1rem;
	}
`;

const SeriesIndicatorContainer = styled.div`
	width: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SeriesName = styled.div`
	font-family: ${defaultTheme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 10px;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 6px;
`;

const PointValue = styled.div`
	font-family: ${defaultTheme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: 325;
	font-size: 11px;
	line-height: 11px;
`;
