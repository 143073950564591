import {
	FilterAction,
	FILTERS_CHANGE,
	FILTERS_INIT,
	FILTERS_RESET,
} from 'actions/filters';
import {
	COLLAPSE_SUBMARKETS_LIST,
	CREATE_LEASE_SEARCH,
	CREATE_PROPERTY_SEARCH,
	CREATE_SALE_SEARCH,
	DESELECT_MAP_CONTROL,
	FOCUS_TYPEAHEAD,
	LOAD_SUBMARKETS,
	MAP_CONTROLS,
	REMOVE_SUBMARKETS,
	SearchAction,
	SELECT_MAP_CONTROL,
	SET_MY_COMPS,
} from 'actions/search';
import { SelectionAction, SELECT_ALL_COMPS } from 'actions/selection';
import {
	SearchLeasesResponse,
	SearchPropertiesResponse,
	SearchSalesResponse,
} from 'api';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { SearchCompAggregations } from 'api/types/shared';
import produce, { Draft } from 'immer';
import sortBy from 'lodash/sortBy';
import { FiltersObject } from 'models/filters/types';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import { LOGOUT, LogoutAction } from 'Pages/Login/actions';
import {
	AveragesDisplayMapping,
	formatAverages,
} from 'services/util/formatAverages';
import { ActiveMapControl } from 'types';
import { LeaseComp, PropertyComp, SalesComp } from 'types/comp';
import {
	GetPromiseActionType,
	getPromiseActionType,
} from 'types/redux-promise-middleware';
import { TableField } from 'types/table';
import { formatComp } from 'util/comp-format/src/format';
import { useAppSelector } from 'util/useAppSelector';
import { UNLOCK_LEASES } from '../../actions/lease';
import { UNLOCK_SALES } from '../../actions/sale';
import getFieldValue from '../../util/getFieldValue';
import {
	APPLY_PRESET,
	LOAD_FIELDS,
	PageAction,
	PERSIST_COLUMNS,
	REQUEST_LEASES,
	REQUEST_PROPERTIES,
	REQUEST_SALES,
} from './actions';

export type SearchStateAverages = SearchState['averages'];
export type SearchStateAggregations = SearchState['aggregations'];
export type SearchStateFields = SearchState['fields'];
export type SubmarketsState = { [marketId: number]: SubmarketPolygon[] } | null;

export type SearchState = {
	activeMapControl: ActiveMapControl;
	activeMapControlSilent: boolean;
	aggregations: Partial<SearchCompAggregations>;
	averages: Partial<AveragesDisplayMapping>;
	countBySpaceType: null;
	fields: TableField[] | null;
	fieldsLoading: boolean;
	focusTypeahead: boolean;
	highestLoaded: number;
	isMyComps: boolean;
	isLoadingComps: boolean;
	loadingSubmarkets: boolean;
	lowestLoaded: number;
	modalIndex: number | null;
	requestCompId: number;
	// TODO: rework the legacy code
	// techincally, it's this + packages/apps/src/services/%compType%Search.ts,
	// the service instance is used in the comps table loading :D
	search: {
		averages: Partial<
			| SearchLeasesResponse['averages']
			| SearchSalesResponse['averages']
			| SearchPropertiesResponse['averages']
		>;
		aggregations: Partial<SearchCompAggregations>;
		total: number;
	} | null;
	searchId: number;
	selectAllPending: boolean;
	marketIdToSubmarkets: SubmarketsState;
	submarketsListCollapsed: boolean;
	totalResults: number | null;
	leases: { [leaseId: number]: LeaseComp };
	sales: { [salesId: number]: SalesComp };
	properties: { [propertyId: number]: PropertyComp };
	resetFiltersTrigger: boolean | null;
};

const initialState: SearchState = {
	leases: {},
	sales: {},
	properties: {},
	activeMapControl: null,
	activeMapControlSilent: false, // if true - don't trigger polygon/radius/submarket search
	aggregations: {},
	averages: {},
	countBySpaceType: null,
	fields: null,
	fieldsLoading: false,
	focusTypeahead: false,
	highestLoaded: -1,
	isMyComps: false,
	isLoadingComps: false,
	loadingSubmarkets: false,
	lowestLoaded: -1,
	modalIndex: null,
	requestCompId: 0,
	search: null, //searchService.create
	searchId: 0,
	selectAllPending: false,
	marketIdToSubmarkets: null,
	submarketsListCollapsed: false,
	totalResults: null,
	resetFiltersTrigger: null,
};

type ProcessedSelectionAction = Extract<
	SelectionAction,
	{ type: GetPromiseActionType<typeof SELECT_ALL_COMPS> }
>;

type ProcessedFilterAction = Extract<
	FilterAction,
	| { type: typeof FILTERS_CHANGE }
	| { type: typeof FILTERS_RESET }
	| { type: typeof FILTERS_INIT }
>;

const searchReducer = (
	state: SearchState = initialState,
	action:
		| PageAction
		| SearchAction
		| ProcessedSelectionAction
		| ProcessedFilterAction
		| LogoutAction
): SearchState => {
	return produce(state, (draftState: Draft<SearchState>) => {
		switch (action.type) {
			case getPromiseActionType(PERSIST_COLUMNS, 'PENDING'):
				// @ts-expect-error TS2322: Type '{ width: number; id: str...
				draftState.fields = action.meta.fields;
				return;

			case getPromiseActionType(APPLY_PRESET, 'PENDING'):
			case getPromiseActionType(LOAD_FIELDS, 'PENDING'):
				draftState.fieldsLoading = true;
				return;

			case getPromiseActionType(APPLY_PRESET, 'FULFILLED'):
			case getPromiseActionType(LOAD_FIELDS, 'FULFILLED'):
				// @ts-expect-error TS2322: Type '{ width: number; id: str...
				draftState.fields = action.payload;
				draftState.fieldsLoading = false;
				return;

			case getPromiseActionType(CREATE_LEASE_SEARCH, 'PENDING'):
			case getPromiseActionType(CREATE_PROPERTY_SEARCH, 'PENDING'):
			case getPromiseActionType(CREATE_SALE_SEARCH, 'PENDING'): {
				if (action.meta.freshStore) {
					Object.keys(initialState).forEach((key) => {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						draftState[key] = initialState[key];
					});
				}
				if (action.meta.searchId) {
					draftState.searchId = action.meta.searchId;
				}
				draftState.isLoadingComps = true;
				return;
			}

			case getPromiseActionType(CREATE_LEASE_SEARCH, 'FULFILLED'): {
				if (action.meta.searchId !== state.searchId) {
					return;
				}

				draftState.search = action.payload.search;
				draftState.totalResults = action.payload.search.total;
				draftState.lowestLoaded = 0;
				draftState.highestLoaded = action.payload.leases.length;
				draftState.aggregations = action.payload.search.aggregations;

				const isMonthly = shouldUseMonthlyMetrics(action.meta.filters);

				draftState.averages = formatAverages(
					isMonthly,
					action.payload.averages
				);

				draftState.leases = {};
				// @ts-expect-error TS7006: Parameter 'lease' implicitly h...
				action.payload.leases.forEach((lease, i) => {
					draftState.leases[i] = formatComp(lease, 'lease', isMonthly);
				});
				draftState.isLoadingComps = false;
				return;
			}

			case getPromiseActionType(CREATE_SALE_SEARCH, 'FULFILLED'): {
				if (action.meta.searchId !== state.searchId) {
					return;
				}

				draftState.search = action.payload.search;
				draftState.totalResults = action.payload.search.total;
				draftState.lowestLoaded = 0;
				draftState.highestLoaded = action.payload.sales.length;
				draftState.aggregations = action.payload.search.aggregations;

				const isMonthly = shouldUseMonthlyMetrics(action.meta.filters);

				draftState.averages = formatAverages(
					isMonthly,
					action.payload.averages
				);

				draftState.sales = {};
				// @ts-expect-error TS7006: Parameter 'sale' implicitly ha...
				action.payload.sales.forEach((sale, i) => {
					//spreading sale and its first portfolio item since
					//that's where propertyMarketEffectiveRent and propertyMarketStartingRent
					//for table view are and they weren't being correctly sent to formatter
					sale = formatComp(
						{ ...sale.portfolio[0], ...sale },
						'sale',
						isMonthly
					);
					draftState.sales[i] = sale;
				});
				draftState.isLoadingComps = false;
				return;
			}

			case getPromiseActionType(CREATE_PROPERTY_SEARCH, 'FULFILLED'): {
				if (action.meta.searchId !== state.searchId) {
					return;
				}

				draftState.search = action.payload.search;
				draftState.totalResults = action.payload.search.total;
				draftState.lowestLoaded = 0;
				draftState.highestLoaded = action.payload.properties.length;
				draftState.aggregations = action.payload.search.aggregations;

				const isMonthly = shouldUseMonthlyMetrics(action.meta.filters);

				draftState.averages = formatAverages(
					isMonthly,
					action.payload.averages
				);

				draftState.properties = {};
				// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
				action.payload.properties.forEach((data, i) => {
					const property = formatComp(data, 'property', isMonthly);
					draftState.properties[i] = property;
				});
				draftState.isLoadingComps = false;
				return;
			}

			case getPromiseActionType(CREATE_LEASE_SEARCH, 'REJECTED'):
			case getPromiseActionType(CREATE_PROPERTY_SEARCH, 'REJECTED'):
			case getPromiseActionType(CREATE_SALE_SEARCH, 'REJECTED'): {
				draftState.isLoadingComps = false;
				return;
			}

			case getPromiseActionType(REQUEST_LEASES, 'PENDING'):
			case getPromiseActionType(REQUEST_SALES, 'PENDING'):
			case getPromiseActionType(REQUEST_PROPERTIES, 'PENDING'):
				draftState.requestCompId = action.meta.id;
				return;

			case getPromiseActionType(REQUEST_LEASES, 'FULFILLED'): {
				if (action.meta.id !== state.requestCompId) {
					break;
				}

				const low = Math.min(action.meta.first, state.lowestLoaded);
				const high = Math.max(action.meta.last, state.highestLoaded);

				for (let i = low; i < high; i++) {
					if (i < action.meta.first || i >= action.meta.last) {
						delete draftState.sales[i];
					} else if (!draftState.sales[i]) {
						const j = i - action.meta.first;
						const lease = formatComp(
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							action.payload[j],
							'lease',
							shouldUseMonthlyMetrics(action.meta.filters)
						);
						draftState.leases[i] = lease;
					}
				}

				draftState.lowestLoaded = action.meta.first;
				draftState.highestLoaded = action.meta.last;
				return;
			}

			case getPromiseActionType(REQUEST_SALES, 'FULFILLED'): {
				if (action.meta.id !== state.requestCompId) {
					break;
				}

				const low = Math.min(action.meta.first, state.lowestLoaded);
				const high = Math.max(action.meta.last, state.highestLoaded);

				for (let i = low; i < high; i++) {
					if (i < action.meta.first || i >= action.meta.last) {
						delete draftState.sales[i];
					} else if (!draftState.sales[i]) {
						const j = i - action.meta.first;
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						const sale = action.payload[j];
						const formattedSale = formatComp(
							{ ...sale.portfolio[0], ...sale },
							'sale',
							shouldUseMonthlyMetrics(action.meta.filters)
						);
						draftState.sales[i] = formattedSale;
					}
				}

				draftState.lowestLoaded = action.meta.first;
				draftState.highestLoaded = action.meta.last;
				return;
			}

			case getPromiseActionType(REQUEST_PROPERTIES, 'FULFILLED'): {
				if (action.meta.id !== state.requestCompId) {
					break;
				}

				const low = Math.min(action.meta.first, state.lowestLoaded);
				const high = Math.max(action.meta.last, state.highestLoaded);

				for (let i = low; i < high; i++) {
					if (i < action.meta.first || i >= action.meta.last) {
						delete draftState.properties[i];
					} else if (!draftState.properties[i]) {
						const j = i - action.meta.first;
						const property = formatComp(
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							action.payload[j],
							'property',
							shouldUseMonthlyMetrics(action.meta.filters)
						);
						draftState.properties[i] = property;
					}
				}

				draftState.lowestLoaded = action.meta.first;
				draftState.highestLoaded = action.meta.last;
				return;
			}

			case getPromiseActionType(SELECT_ALL_COMPS, 'PENDING'):
				draftState.selectAllPending = true;
				return;

			case getPromiseActionType(SELECT_ALL_COMPS, 'FULFILLED'):
			case getPromiseActionType(SELECT_ALL_COMPS, 'REJECTED'):
				draftState.selectAllPending = false;
				return;

			case SELECT_MAP_CONTROL:
				draftState.activeMapControl = action.payload.id;
				draftState.activeMapControlSilent = action.payload.silent;
				return;

			case DESELECT_MAP_CONTROL:
				draftState.activeMapControl = null;
				draftState.activeMapControlSilent = action.payload.silent;
				return;

			case FILTERS_RESET:
			case FILTERS_CHANGE:
			case FILTERS_INIT: {
				if (action.type === FILTERS_INIT || action.payload.context === 'main') {
					const newFilters: Partial<FiltersObject> =
						action.type === FILTERS_RESET
							? {
									radius: null,
									polygon: null,
									submarkets: null,
									opportunityZoneId: null,
									...action.payload.defaults,
								}
							: action.type === FILTERS_INIT
								? action.payload.initialFilters.main
								: action.payload.changes;
					if (
						newFilters.radius === null &&
						newFilters.polygon === null &&
						newFilters.submarkets === null &&
						newFilters.opportunityZoneId === null
					) {
						draftState.activeMapControl = null;
					}

					if (newFilters.submarkets) {
						draftState.activeMapControl = MAP_CONTROLS.SUBMARKETS;
						draftState.activeMapControlSilent = true;
					}

					if (newFilters.opportunityZoneId) {
						draftState.activeMapControl = MAP_CONTROLS.OPPORTUNITY_ZONES;
						draftState.activeMapControlSilent = true;
					}

					if (newFilters.radius) {
						draftState.activeMapControl = MAP_CONTROLS.RADIUS;
						draftState.activeMapControlSilent = true;
					}

					if (newFilters.polygon) {
						if (state.activeMapControl !== MAP_CONTROLS.SEARCH_WITHIN_VIEW) {
							draftState.activeMapControl = MAP_CONTROLS.POLYGON;
							draftState.activeMapControlSilent = true;
						}
					}
				}

				if (action.type === 'FILTERS_RESET') {
					draftState.resetFiltersTrigger = !draftState.resetFiltersTrigger;
				}

				return;
			}

			case getPromiseActionType(LOAD_SUBMARKETS, 'PENDING'):
				draftState.loadingSubmarkets = true;
				return;

			case getPromiseActionType(LOAD_SUBMARKETS, 'FULFILLED'): {
				const marketIds = action.meta.marketIds;
				const submarkets = action.payload;
				draftState.marketIdToSubmarkets = marketIds.reduce<{
					[marketId: number]: SubmarketPolygon[];
				}>((acc, marketId, index) => {
					const marketSubmarkets = submarkets[index];
					const sortedMarketSubmarkets = sortBy(
						marketSubmarkets,
						(submarket) => submarket.properties.name
					);
					acc[marketId] = sortedMarketSubmarkets;
					return acc;
				}, {});
				draftState.loadingSubmarkets = false;
				return;
			}

			case REMOVE_SUBMARKETS:
				draftState.marketIdToSubmarkets = null;
				return;

			case COLLAPSE_SUBMARKETS_LIST:
				draftState.submarketsListCollapsed = action.payload;
				return;

			case SET_MY_COMPS:
				draftState.isMyComps = action.payload;
				return;

			case FOCUS_TYPEAHEAD:
				draftState.focusTypeahead = !draftState.focusTypeahead;
				return;

			case LOGOUT:
				return initialState;

			case UNLOCK_LEASES + '_FULFILLED': {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'RemoveS... Remove this comment to see the full error message
				const unlockedIds = action.payload.map((lease) => lease.id);

				for (const leaseKey in state.leases) {
					const lease = state.leases[leaseKey];
					const indexOfLeaseInPayload = unlockedIds.indexOf(lease.id);
					if (indexOfLeaseInPayload !== -1) {
						let formattedLease = formatComp(
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'RemoveS... Remove this comment to see the full error message
							action.payload[indexOfLeaseInPayload],
							'lease'
						);

						// this dirty hack changes all the values of just purchased leases in benign ways.
						// it's needed because the table checks to see if values have changed before
						// rerendering but we want it to rerender so it can flash.
						formattedLease = Object.keys(formattedLease).reduce((acc, key) => {
							switch (key) {
								case 'id':
								case 'own':
								case 'cost':
								case 'dollarCost':
								case 'market':
								case 'marketId':
									// @ts-expect-error TS7053: Element implicitly has an 'any...
									acc[key] = formattedLease[key];
									break;

								default:
									if (formattedLease[key] === undefined) {
										// @ts-expect-error TS7053: Element implicitly has an 'any...
										acc[key] = Infinity;
									} else {
										// @ts-expect-error TS7053: Element implicitly has an 'any...
										acc[key] = formattedLease[key] + ' ';
									}
							}
							return acc;
						}, {});

						draftState.leases[leaseKey] = formattedLease;
					}
				}
				return;
			}

			case UNLOCK_SALES + '_FULFILLED': {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'RemoveS... Remove this comment to see the full error message
				const unlockedIds = action.payload.map((sale) => sale.id);

				for (const saleKey in state.sales) {
					const sale = state.sales[saleKey];
					const indexOfSaleInPayload = unlockedIds.indexOf(sale.id);
					if (indexOfSaleInPayload !== -1) {
						let formattedSale = formatComp(
							{
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'RemoveS... Remove this comment to see the full error message
								...action.payload[indexOfSaleInPayload].portfolio[0],
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'RemoveS... Remove this comment to see the full error message
								...action.payload[indexOfSaleInPayload],
							},
							'sale'
						);

						// this dirty hack changes all the values of just purchased sales in benign ways.
						// it's needed because the table checks to see if values have changed before
						// rerendering but we want it to rerender so it can flash.
						formattedSale = Object.keys(formattedSale).reduce((acc, key) => {
							switch (key) {
								case 'id':
								case 'own':
								case 'cost':
								case 'dollarCost':
								case 'market':
								case 'marketId':
								case 'portfolio':
								case 'isPortfolio':
									// @ts-expect-error TS7053: Element implicitly has an 'any...
									acc[key] = formattedSale[key];
									break;

								default:
									const value = getFieldValue(formattedSale, 'sale', key);
									if (value === undefined) {
										// @ts-expect-error TS7053: Element implicitly has an 'any...
										acc[key] = Infinity;
									} else {
										// @ts-expect-error TS7053: Element implicitly has an 'any...
										acc[key] = value + ' ';
									}
							}
							return acc;
						}, {});

						draftState.sales[saleKey] = formattedSale;
					}
				}
				return;
			}
		}
	});
};

export default searchReducer;

export const useSearch = () => {
	const search = useAppSelector((state) => ({
		...state.searchReducer,
		...state.mapSearchResultsV2,
	}));

	return search;
};

export type SearchAndMapSearchState = ReturnType<typeof useSearch>;
