import { css } from 'styled-components';

export const checkboxStyles = css`
	svg {
		width: 12px;
		height: 12px;
		fill: ${({ theme }) => theme.colors.blue.blue500};

		rect.CompstakSvgPath {
			stroke: none;
			fill: ${({ theme }) => theme.colors.neutral.n0};
		}
	}
	svg:not(.checkbox-disabled) path.CompstakSvgPath {
		stroke: none;
	}
	svg.checkbox-disabled path.CompstakSvgPath {
		stroke: none;
		fill: ${({ theme }) => theme.colors.neutral.n0};
	}
	svg.checkbox-blocked path {
		fill: ${({ theme }) => theme.colors.neutral.n100};
	}
`;
