import { View, Image, Text } from '@react-pdf/renderer';
import { PdfPageProps } from '../types';
import { pdfStyle } from '../style';

export const CommercialSubmarketTrends = (
	props: PdfPageProps & {
		src: string;
	}
) => {
	return (
		<View wrap={false}>
			<Text style={pdfStyle.pageTitle}>
				Submarket Trends - {props.property.submarket}
			</Text>
			<View>
				{props.src && <Image src={props.src} style={pdfStyle.charts} />}
			</View>
		</View>
	);
};
