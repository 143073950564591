import { Flex } from '@compstak/ui-kit';
import { KvTableHeader, KvTableStylingWrapper } from 'Components/KvTable';
import styled from 'styled-components';

export const SECTION_WIDTH = 400;
export const SECTION_GAP = 40;

export const AttributesTableField = styled.div<{ vertical?: boolean }>`
	display: flex;
	flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
	justify-content: space-between;
	align-items: ${({ vertical }) => (vertical ? 'flex-start' : 'center')};
	gap: 0.5rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1rem;
	padding: 0.5rem 0;
	margin: 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray400};
	transition: border-bottom 0.5s ease 0s;
	&:hover {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray700};
	}
`;

export const AttributesTableSectionsContainer = styled(Flex)`
	gap: ${SECTION_GAP}px;
	align-items: flex-start;
`;

export const AttributesTableSection = styled.div`
	max-width: ${SECTION_WIDTH}px;
	flex: 1;
`;

export const AttributesTableHeader = styled(KvTableHeader)`
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	color: ${({ theme }) => theme.colors.gray.gray700};
	padding-top: 16px;
`;

export const AttributesTableWrapper = styled(KvTableStylingWrapper)`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 40px;
`;
