import styled from 'styled-components';

export const NoDataMessage = () => {
	return (
		<NoDataMessageContainer>
			<div>You currently don’t have any saved searches on your watchlist.</div>
			<div>
				To get started, apply filters like Space Type, Building Class, or
				Location, and click the <b>Save Search</b> button to store your criteria
				and start receiving alerts!
			</div>
		</NoDataMessageContainer>
	);
};

const NoDataMessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 100px 40px 0;
	text-align: center;
	font-size: 1rem;
	line-height: 2.5rem;
	margin: 0;
`;
