import { EditPage, EditPageProps } from 'pdf/EditPage';
import { ReactNode } from 'react';
import { useExportPdfState } from './ExportPdfProvider';

type Props = Partial<EditPageProps> & {
	children: ReactNode;
};

export const DashboardEditPage = ({ ...props }: Props) => {
	const { exportPdfState, setExportPdfState } = useExportPdfState();

	return (
		<EditPage
			title={exportPdfState.title}
			logo={exportPdfState.logo}
			onLogoChange={(logo) => {
				setExportPdfState((s) => ({ ...s, logo }));
			}}
			preparedFor={exportPdfState.preparedFor}
			{...props}
		/>
	);
};
