import { useReferenceDataQuery } from 'api';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useTenantIndustryAnalyticsData } from './useTenantIndustryAnalyticsData';

export const useFilteredTenantIndustries = () => {
	const [filters] = useFilters();
	const { data: referenceData } = useReferenceDataQuery();
	const { data: tenantIndustries } = useTenantIndustryAnalyticsData();

	const tenantIndustryReference = referenceData?.tenantIndustries;

	const selectedTenantIndustries = useMemo(() => {
		return filters.tenantIndustry ?? [];
	}, [filters.tenantIndustry]);

	return useMemo(() => {
		if (!tenantIndustryReference || !tenantIndustries) return [];

		if (tenantIndustries.length === 0) return [];

		return tenantIndustryReference.filter((industry) => {
			if (selectedTenantIndustries.length > 0) {
				return selectedTenantIndustries?.includes(industry.name);
			}
			return true;
		});
	}, [selectedTenantIndustries, tenantIndustryReference, tenantIndustries]);
};
