import {
	DashboardSettingsForm,
	DashboardSettingsMenuContainer,
	FormControl,
	MarketRentAnalyticsSharedFormControls,
} from 'maps';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';

export const MarketRentMapSettingsMenu = () => {
	return (
		<DashboardSettingsMenuContainer data-html2canvas-ignore>
			<DashboardSettingsForm>
				<MarketRentAnalyticsSharedFormControls />
				<FormControl>
					<CalculationOverviewLink context="marketRent" />
				</FormControl>
			</DashboardSettingsForm>
		</DashboardSettingsMenuContainer>
	);
};
