import { typography } from '@compstak/ui-kit';
import styled from 'styled-components';

export const KvTableHeader = styled.h3`
	font-family: ${typography.fontFamily.gotham};
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	padding-bottom: 8px;
	box-shadow: 0 1px 0 0 #000000;
`;
KvTableHeader.displayName = 'KvTableHeader';
