import { colors, VirtualTable } from '@compstak/ui-kit';
import { useMarketRentAnalyticsState } from 'maps';
import { useNavigate } from 'router';
import { useMarketRentState } from '../MarketRentProvider';
import { useMarketRentPropertiesWithSummaries } from '../useMarketRentPropertiesWithSummaries';
import { createMarketRentColumns } from './columns';

type Props = {
	portfolioId: number;
};

export const MarketRentTable = ({ portfolioId }: Props) => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();
	const propertiesWithSummaries = useMarketRentPropertiesWithSummaries({
		portfolioId,
	});
	const [, setMarketRentState] = useMarketRentState();
	const navigate = useNavigate();

	return (
		<VirtualTable
			id="market-rent-table"
			columns={createMarketRentColumns(marketRentAnalyticsState.period)}
			rows={propertiesWithSummaries}
			getBodyCellProps={({ column }) => ({
				style: {
					borderRight:
						column.id === 'pin' ? '' : `1px solid ${colors.neutral.n30}`,
					cursor: 'pointer',
				},
			})}
			getRowKey={(i) => propertiesWithSummaries[i].id}
			getBodyRowProps={({ row }) => ({
				onMouseEnter: () => {
					setMarketRentState((prev) => ({
						...prev,
						propertyPopup: row,
					}));
				},
				onMouseLeave: () => {
					setMarketRentState((prev) => ({
						...prev,
						propertyPopup: null,
					}));
				},
				onMouseDown: () => {
					navigate(`property/${row.id}`, { replace: true });
				},
			})}
			isBodyHoverable
		/>
	);
};
