import { API } from '@compstak/common';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { composeEnabled } from 'api/utils';
import { mapUrlToGateway } from 'utils/gatewayUtil';
import { QUERY_KEYS } from '../constants';
import {
	VersionsWithPromotionsParams,
	VersionsWithPromotionsResponse,
} from './types';

export const useLeaseVersionsWithPromotionsQuery = (
	params: VersionsWithPromotionsParams,
	options?: UseQueryOptions<VersionsWithPromotionsResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.LEASE_VERSIONS_WITH_PROMOTIONS, params],
		queryFn: async () => {
			const res = await API.post<VersionsWithPromotionsResponse>(
				mapUrlToGateway(
					`/user-service/user/leases/versions/promotions/findByVersionIds`
				),
				params
			);
			return res.data;
		},
		...options,
		enabled: composeEnabled(
			params.versionIds != null && params.versionIds.length > 0,
			options?.enabled
		),
	});
};
