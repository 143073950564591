import styled from 'styled-components';
import IconCross from 'ui/svg_icons/cross.svg';

type Props = {
	onClick?: () => void;
};

export const PopupCloseButton = ({ onClick }: Props) => {
	return (
		<CloseButton data-qa-id="comp-popup-close-btn" onClick={onClick}>
			<StyledCrossIcon />
		</CloseButton>
	);
};

export const CloseButton = styled.button`
	all: unset;
	margin-left: auto;
	opacity: 0.5;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
`;

export const StyledCrossIcon = styled(IconCross)`
	width: 13px;
	height: 13px;
	fill: ${({ theme }) => theme.colors.white.white};
	margin-top: 3px;
`;
