import React from 'react';

import { emailConstants } from '@compstak/common';
import { FilterFieldLink } from 'Components/Filters/Base/Filter/FilterFieldLink';
import { FilterInformationField } from 'Components/Filters/Base/Filter/FilterInformationField';

export const RealPageFilterInfo = () => {
	return (
		<FilterInformationField>
			RealPage filters are only available for markets in which you are
			subscribed to RealPage. Refine your market selection above to view
			Multifamily filters.
			<div>
				<FilterFieldLink
					data-qa-id="no-access-to-real-page-filters"
					href={`mailto:${emailConstants.clientSuccess}`}
					target="_blank"
				>
					Get more RealPage markets
				</FilterFieldLink>
			</div>
		</FilterInformationField>
	);
};

RealPageFilterInfo.displayName = 'RealPageFilterInfo';
