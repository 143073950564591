import { Spinner } from '@compstak/ui-kit';
import { PublicLoansTable } from 'Components/PublicLoansTable';
import { BetaChip, TableCellGapper, TableHeaderTitle } from 'Components/UI';
import { useAppConfig } from 'Pages/Login/reducers';
import {
	getHasLoanData,
	useMarketsHaveFeature,
	usePropertyById,
	usePropertyLoan,
} from 'api';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { routes } from 'router/routes';
import { NoDataTab } from '../../../Components/NoDataMessaging/NoDataMessaging';
import { RequestDemo } from '../RequestDemo';
import {
	TabContainer,
	TabContentHeader,
	TabContentTitle,
	TableContainer,
	TableHeader,
} from '../UI';
import { PropertyPageTab } from '../types';
import { PrivateLoansTable } from './PrivateLoansTable';
import { MIN_TAB_HEIGHT } from '../constants';

export const LoansTab = () => {
	const params = routes.propertyById.useParams();
	const { isEnterprise } = useAppConfig();
	const flags = useFeatureFlags();

	const { data: property, isFetching: isFetchingProperty } = usePropertyById({
		propertyId: params.propertyId,
	});

	const { data: loans, isFetching: isFetchingLoans } = usePropertyLoan({
		propertyId: params.propertyId,
	});

	const { data: hasSalesAccess, isFetching: isFetchingSalesAccess } =
		useMarketsHaveFeature({
			feature: 'salesAccess',
			marketIds: property ? [property.marketId] : [],
			isEnabled: property != null,
		});

	const isTabFetching =
		!loans || isFetchingProperty || isFetchingSalesAccess || isFetchingLoans;

	if (isTabFetching) {
		return (
			<TabContainer>
				<Spinner isCentered />
			</TabContainer>
		);
	}

	const canSeeLoans = (isEnterprise || flags.ExchangeLoans) && hasSalesAccess;

	if (!canSeeLoans) return <RequestDemo tab={PropertyPageTab.LOANS} />;

	const { hasLoans, hasPublicLoans } = getHasLoanData(loans);

	if (!hasLoans)
		return (
			<NoDataTab
				minHeight={`${MIN_TAB_HEIGHT}px`}
				message="CompStak doesn't have any Loan data for this property."
			/>
		);

	const mostRecentPrivateLoan = loans?.treppLoans?.[0];

	return (
		<TabContainer>
			<TabContentHeader>
				<TabContentTitle>
					<TableCellGapper>
						<div>Property Loans</div>
						{!isEnterprise && (
							<BetaChip style={{ marginTop: -7 }}>Beta</BetaChip>
						)}
					</TableCellGapper>
				</TabContentTitle>
			</TabContentHeader>
			{mostRecentPrivateLoan && <PrivateLoansTable />}
			{hasPublicLoans && (
				<TableContainer>
					<TableHeader>
						<TableHeaderTitle>Public Loan Data</TableHeaderTitle>
					</TableHeader>
					<PublicLoansTable propertyId={params.propertyId} />
				</TableContainer>
			)}
		</TabContainer>
	);
};
