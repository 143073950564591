import React, { useEffect } from 'react';
import { ExchangePanel, ChartTooltip, TooltipRow, LoadingChart } from './UI';
import {
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryStack,
	VictoryBar,
	VictoryAxis,
	VictoryLabel,
	VictoryTooltip,
} from 'victory';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { useReferenceData } from 'Pages/Login/reducers';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { Waypoint } from 'react-waypoint';
import { useExchangeDashboardLeaseExpirationByPropertyType } from './hooks/leaseExpirationByPropertyType/useExchangeDashboardLeaseExpirationByPropertyType';

export const getYearKeys = () => {
	const currentYear = new Date().getFullYear();
	return Array.from(Array(11).keys()).map(
		(n, idx) => `${currentYear + n}${idx === 10 ? '+' : ''}`
	);
};

export const COLOR_SCALE = [
	'#065bb2',
	'#228fff',
	'#7abcff',
	'#246b44',
	'#50b87f',
	'#b9e3cc',
	'#000000',
	'#7B61FF',
	'#cccccc',
];

export const LeaseExpirationPropertyType = ({
	onChartRender,
}: {
	onChartRender?: () => void;
}) => {
	const [view, setView] = React.useState<'visible' | 'hidden'>('hidden');

	const [dashboardFilters] = useFilters('exchange-dashboard');
	const referenceData = useReferenceData();
	const {
		fullPageState,
		setFullPageState,
		setIsLeaseExpirationPropertyTypeVisible,
	} = useExchangeDashboardState();
	const { data, isFetching } =
		useExchangeDashboardLeaseExpirationByPropertyType({
			enabled: view === 'visible' && fullPageState === null,
		});

	const domain: { x: [number, number] } = { x: [0, 5] };
	const isEnoughData =
		data &&
		Object.values(data ?? []).reduce((acc, compsByType) => {
			return (
				acc +
				Object.values(compsByType).reduce(
					(acc, compCount) => acc + compCount,
					0
				)
			);
		}, 0) >= 10;
	const yearKeys = getYearKeys();

	useEffect(() => {
		setIsLeaseExpirationPropertyTypeVisible(view === 'visible');
		return () => setIsLeaseExpirationPropertyTypeVisible(false);
	}, [view]);

	const eventsAllowed = !onChartRender;

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				id="leaseExpirationPropertyType"
				title="Lease Expiration by Property Type"
				noBoxShadow
			>
				{isFetching ? (
					<LoadingChart />
				) : data && referenceData && isEnoughData ? (
					<ChartContainer>
						<VictoryChart
							horizontal
							maxDomain={{ x: 5 }}
							domain={domain}
							domainPadding={0}
							style={{ parent: { maxWidth: '100%' } }}
							height={320}
							width={520}
							padding={{ left: 75, top: 50, bottom: 0, right: 50 }}
							animate={
								onChartRender
									? {
											duration: 500,
											onEnd() {
												onChartRender();
											},
									  }
									: undefined
							}
							containerComponent={
								<VictoryVoronoiContainer
									voronoiDimension="x"
									height={350}
									style={{ margin: '0 auto' }}
								/>
							}
						>
							<VictoryAxis
								axisLabelComponent={<VictoryLabel dy={-22} />}
								fixLabelOverlap
								label="Expiring in the market (years)"
								tickLabelComponent={
									<VictoryLabel
										style={{ fontSize: 12, fontFamily: 'Gotham' }}
									/>
								}
								style={{
									axis: { stroke: 'none' },
									axisLabel: {
										fontSize: 14,
										color: '#000',
										textTransform: 'capitalize',
										fontFamily: 'Gotham',
									},
									grid: { stroke: 'none' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										fontSize: 12,
										fontFamily: 'Gotham',
										color: '#000',
									},
								}}
							/>
							<VictoryAxis
								axisLabelComponent={
									<VictoryLabel dy={-22} style={{ fontFamily: 'Gotham' }} />
								}
								dependentAxis
								fixLabelOverlap
								label="Number of Leases Expiring"
								tickLabelComponent={
									<VictoryLabel
										dy={-30}
										style={{ fontSize: 12, fontFamily: 'Gotham' }}
									/>
								}
								style={{
									axis: { stroke: 'none', padding: 0, margin: 0 },
									axisLabel: {
										fontSize: 14,
										fontFamily: 'Gotham',
										color: '#000',
										textTransform: 'capitalize',
									},
									grid: { stroke: 'none', margin: 0, padding: 0 },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										fontSize: 12,
										fontFamily: 'Gotham',
										color: '#000',
									},
								}}
							/>
							<VictoryStack colorScale={COLOR_SCALE} labels={() => ' '}>
								{referenceData.propertyTypes
									.filter((pt) => pt.id > 0)
									.map((propertyType) => {
										const barData = yearKeys
											.slice(...domain.x)
											.map((yearKey) => {
												return {
													x: yearKey,
													y: data[propertyType.name]
														? data[propertyType.name]?.[yearKey] ?? 0
														: 0,
													propertyTypeId: propertyType.id,
												};
											});
										return (
											<VictoryBar
												key={propertyType.name}
												data={barData}
												barWidth={36}
												style={
													eventsAllowed
														? {
																data: {
																	cursor: 'pointer',
																},
														  }
														: {}
												}
												labels={() => ' '}
												labelComponent={
													eventsAllowed ? (
														<VictoryTooltip
															dx={-80}
															dy={-210}
															flyoutHeight={200}
															orientation="left"
															flyoutComponent={<GraphFlyout />}
														/>
													) : undefined
												}
												events={
													eventsAllowed
														? [
																{
																	target: 'data',
																	eventHandlers: {
																		onClick: (_e, clickProps) => {
																			const {
																				datum: { xName: year, propertyTypeId },
																			} = clickProps;
																			setFullPageState &&
																				setFullPageState({
																					type: 'list',
																					field: 'propertyType',
																					propertyTypeId,
																					year,
																				});
																		},
																	},
																},
														  ]
														: undefined
												}
											/>
										);
									})}
							</VictoryStack>
						</VictoryChart>
						<Legend>
							{referenceData.propertyTypes
								.filter((pt) => pt.id > 0)
								.map((propertyType, i) => {
									if (
										!dashboardFilters.buildingPropertyTypeId?.length ||
										dashboardFilters?.buildingPropertyTypeId.includes(
											propertyType.id
										)
									) {
										return (
											<div key={i} className="property-type">
												<div
													className="color"
													style={{ backgroundColor: COLOR_SCALE[i] }}
												/>
												<div>{propertyType.name}</div>
											</div>
										);
									} else {
										return null;
									}
								})}
						</Legend>
					</ChartContainer>
				) : (
					<div>No Data To Display. Update Filters and Try Again.</div>
				)}
			</ExchangePanel>
		</Waypoint>
	);
};

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
const GraphFlyout = (props) => {
	const referenceData = useReferenceData();
	const { data } = useExchangeDashboardLeaseExpirationByPropertyType();
	const { datum, x, y } = props;
	const yearKey = datum.xName;
	const [dashboardFilters] = useFilters('exchange-dashboard');

	return (
		<g
			width={200}
			height={200}
			transform={`translate(${x + props.dx}, ${y + props.dy})`}
			style={{ pointerEvents: 'none' }}
		>
			<foreignObject width={200} height={208}>
				<ChartTooltip>
					<TooltipRow>
						<span>{yearKey}</span>
						<span>Leases Expiring</span>
					</TooltipRow>
					{referenceData.propertyTypes
						.filter((pt) => pt.id > 0)
						.map((propertyType, i) => {
							if (
								!dashboardFilters.buildingPropertyTypeId?.length ||
								dashboardFilters?.buildingPropertyTypeId.includes(
									propertyType.id
								)
							) {
								return (
									<TooltipRow key={i}>
										<TooltipRow>
											<div
												className="color"
												style={{ backgroundColor: COLOR_SCALE[i] }}
											/>
											<div>{propertyType.name}</div>
										</TooltipRow>
										<div>{data?.[propertyType.name]?.[yearKey] ?? 0}</div>
									</TooltipRow>
								);
							} else {
								return null;
							}
						})}
				</ChartTooltip>
			</foreignObject>
		</g>
	);
};

const ChartContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		overflow: visible;
	}
`;

const Legend = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1rem;
	max-width: 500px;

	.property-type {
		display: flex;
		width: 125px;
		align-items: center;
		margin-bottom: 1rem;

		.color {
			width: 18px;
			height: 18px;
			border-radius: 3px;
			margin-right: 8px;
		}
	}
`;
