import { API, APIClientNotOkResponseError } from '@compstak/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadUser } from 'Pages/Login/actions';
import { useAppConfig } from 'Pages/Login/reducers';
import { QUERY_KEYS } from 'api';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import userflow from 'userflow.js';
import { useAppSelector } from 'util/useAppSelector';

enum USERFLOW_QUERY_KEYS {
	CONFIG = 'userflowConfig',
	COMPLETED_FLOWS = 'userflowCompletedFlows',
	PREFERRED_COMP = 'preferredComp',
}

interface UfConfig {
	show: boolean;
	flows: UfConfigFlow[];
}

interface UfConfigFlow {
	flowName: string;
	flowId: string;
	onDoneFlowId: string;
	iconName: string;
	minutes: number;
	rewardCredits: number;
	autoUpdateCredits: boolean;
}

interface CompletedFlowsDTO {
	flows: CompletedFlow[];
}

interface CompletedFlow {
	flowId: string;
	rewardCredits?: number;
	autoUpdateCredits: boolean;
}

const EMPTY_CONFIG: UfConfig = { show: false, flows: [] };
const EMPTY_COMPLETED_FLOWS: CompletedFlowsDTO = { flows: [] };

function useUserflowEndpointsEnabled() {
	const user = useAppSelector((store) => store.user);
	const isExchange = useAppConfig()?.isExchange;

	if (!user) return false;

	const isTermsOrPrivacyNeeded = user.termsNeeded || user.privacyPolicyNeeded;
	const enabled = Boolean(user.id && !isTermsOrPrivacyNeeded && isExchange);
	return enabled;
}

const shouldRetry = (failureCount: number, error: unknown): boolean =>
	failureCount < 3 &&
	(error as APIClientNotOkResponseError)?.response?.status !== 403;

export function useUserflowOnboardingConfig() {
	const userflowEnabled = useUserflowEndpointsEnabled();
	const userId = useAppSelector((store) => store.user?.id);

	return useQuery<UfConfig>(
		[USERFLOW_QUERY_KEYS.CONFIG, userId, userflowEnabled],
		async () => {
			if (!userflowEnabled) {
				// Don't put userflowEnabled in the query options enabled field.
				// We don't want to pause the query if onboarding is not enabled for the user,
				// instead we want explicitly return a sentinel value that signals
				// that onboarding flows should not be shown to the user.
				return EMPTY_CONFIG;
			}
			try {
				const flows = (
					await API.get<UfConfigFlow[]>(`/api/user/${userId}/userflow/config`)
				).data;
				if (!Array.isArray(flows)) {
					throw Error('Expected array', { cause: { flows } });
				}
				return { show: true, flows };
			} catch (error) {
				if ((error as APIClientNotOkResponseError)?.response?.status === 403) {
					// A 403 status means the user should not see the userflow onboarding section.
					return EMPTY_CONFIG;
				}
				throw error;
			}
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retryOnMount: false,
			retry: shouldRetry,
		}
	);
}

export function useUserflowCompletedFlows() {
	const userflowEnabled = useUserflowEndpointsEnabled();
	const userId = useAppSelector((store) => store.user?.id);

	return useQuery<CompletedFlowsDTO>(
		[USERFLOW_QUERY_KEYS.COMPLETED_FLOWS, userId, userflowEnabled],
		async (): Promise<CompletedFlowsDTO> => {
			if (!userflowEnabled) {
				// Don't put userflowEnabled in the query options enabled field.
				// If onboarding is not enabled for the user, we assume that they have
				// not completed any flows.
				return EMPTY_COMPLETED_FLOWS;
			}
			const response = await API.get<CompletedFlowsDTO>(
				`/api/user/${userId}/flow/completed`
			);
			const { data } = response;
			if (!Array.isArray(data.flows)) {
				throw Error('Bad completed flows DTO', { cause: { data } });
			}
			return data;
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retryOnMount: false,
			retry: shouldRetry,
		}
	);
}

export function useUserflowCompleteFlowMutation() {
	const userId = useAppSelector((store) => store.user?.id);
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const mutationFn = useCallback(
		async function completeFlowMutationFn({
			flowId,
			onDoneFlowId,
		}: {
			flowId: string;
			onDoneFlowId: string;
		}) {
			try {
				if (!userId) {
					console.error('userId is falsy?!', userId);
					return;
				}
				await API.post(`/api/user/${userId}/flow/${flowId}/complete`);
			} catch (err) {
				console.error('BE error when completing a flow?!', err);
				return;
			}
			queryClient.invalidateQueries({
				queryKey: [USERFLOW_QUERY_KEYS.COMPLETED_FLOWS],
			});
			// Refresh user credits count in redux.
			const refreshUserDataAction = loadUser();
			refreshUserDataAction.payload.promise.then((user) => {
				// Refresh user credits count in react-query.
				queryClient.setQueryData([QUERY_KEYS.USER], user);
				if (typeof onDoneFlowId === 'string') {
					userflow.start(onDoneFlowId);
				}
			});
			dispatch(refreshUserDataAction);
		},
		[userId, dispatch, queryClient]
	);

	return useMutation({
		mutationFn,
	});
}

export function useGetPreferredPreviewComp() {
	// https://compstak.atlassian.net/browse/AP-9317
	const userId = useAppSelector((store) => store.user?.id);
	return useQuery<number>(
		[USERFLOW_QUERY_KEYS.PREFERRED_COMP],
		async () => {
			const preferredCompData = await API.get(
				`/api/user/${userId}/preferred-comp`
			).then((res) => res.data);

			//@ts-expect-error  error TS2339: Property 'value' does not exist on type '{}'.
			const preferredCompId = preferredCompData?.value;
			if (typeof preferredCompId !== 'number') {
				throw Error('Expected a number', {
					cause: { preferredCompData },
				});
			}
			return preferredCompId;
		},
		{ enabled: !!userId, refetchOnWindowFocus: false }
	);
}
