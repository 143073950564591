import React from 'react';

import AnimatingNumber from './AnimatingNumber';

export default function FreeCompCounter({ freeComps }: { freeComps: number }) {
	return (
		<AnimatingNumber
			value={freeComps}
			label={freeComps === 1 ? 'Free Comp' : 'Free Comps'}
		/>
	);
}
