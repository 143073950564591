import { Market } from '@compstak/common';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryHook } from 'api';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useCallback, useEffect, useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useMarketSelectorExpanded } from './useMarketSelectorExpanded';

const MARKETS_BUFFER = 'MARKETS_BUFFER';

export const useMarketsBuffer = () => {
	const [filters] = useFilters();
	const actualMarkets = useMemo(
		() => getFiltersMarkets(filters),
		[filters.markets]
	);
	const marketSelectorExpanded = useMarketSelectorExpanded();
	const queryClient = useQueryClient();

	const { data: marketsBuffer } = useQueryHook({
		queryKey: [MARKETS_BUFFER],
		initialData: actualMarkets,
		queryFn: () => queryClient.getQueryData<Market[]>([MARKETS_BUFFER]),
	});

	const setMarketsBuffer = useCallback(
		(newMarketsBuffer: Market[]) => {
			queryClient.setQueryData([MARKETS_BUFFER], newMarketsBuffer);
		},
		[queryClient]
	);

	const resetMarketsBuffer = useCallback(() => {
		queryClient.setQueryData([MARKETS_BUFFER], []);
	}, [queryClient]);

	useEffect(() => {
		setMarketsBuffer(actualMarkets);
	}, [actualMarkets, setMarketsBuffer]);

	useEffect(() => {
		if (!marketSelectorExpanded) {
			setMarketsBuffer(actualMarkets);
		}
	}, [actualMarkets, marketSelectorExpanded, setMarketsBuffer]);

	return {
		marketsBuffer: marketsBuffer ?? actualMarkets,
		setMarketsBuffer,
		resetMarketsBuffer,
	};
};
