import styled from 'styled-components';

export const EditContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding-bottom: 1rem;
`;

export const InfoRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: start;
	padding: 0.5rem 0;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n40};
`;

export const InfoLabel = styled.div`
	font-weight: 500;
	font-size: 0.625rem;
	text-transform: uppercase;
	line-height: 1;
`;

export const InfoValue = styled.div`
	font-size: 0.75rem;
	line-height: 1.5;
	color: ${(p) => p.theme.colors.gray.gray500};
`;
