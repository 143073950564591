import { FiltersTypesKeys } from 'models/filters/types';

// NOTICE: use `util/filterHelpers` to get any filter label
export const FILTER_LABELS: Partial<Record<FiltersTypesKeys, string>> = {
	propertyMarketStartingRent: 'Market Starting Rent',
	propertyAverageTransactionSize: 'Average Lease Size',
	saleDate: 'Transaction Date',
	totalSalePrice: 'Price',
	lastSalePrice: 'Last Sale Price',
	salePricePsf: 'Price (PSF)',
	isPortfolio: 'Multi-Property Sale',
	zip: 'Zip Code',
	tenantNames: 'Tenant Name',
	landlordNames: 'Current Landlord',
	buyers: 'Buyer',
	sellers: 'Seller',
	floors: 'Floor(s) Occupied',
	buildingFloorsCount: 'Number of Floors',
	propertySqFtExpiringInTwelveMonths: 'Sq Ft Expiring in 12 Months',
	// InsideView has been acquired by Demandbase.
	// Mentions of InsideView in the codebase will stay unchanged as decided by the team.
	// https://compstak.atlassian.net/browse/AP-17517
	insideviewRevenue: 'Tenant Revenue (USD)',
	insideviewEmployees: 'Tenant Employees',
	insideviewStatusId: 'Tenant Status',
	landlordRealtyCompanies: 'Brokerage',
	tenantRealtyCompanies: 'Brokerage',
	owns: 'My Comps',
	hidden: 'Hidden',
	partial: 'Partial',
	fips: 'County / APN',
	spaceTypeId: 'Space Type',
	spaceSubtypeId: 'Space Subtype',
	buildingPropertyTypeId: 'Property Type',
	buildingPropertySubtype: 'Property Subtype',

	// Trepp loan
	loanOriginators: 'Lender',
	loanAmount: 'Loan Amount',
	loanInterestType: 'Interest Type',
	currentLoanRate: 'Current Loan Rate',
	loanOriginationDate: 'Origination Date',
	loanMaturityDate: 'Loan Maturity Date',
	loanToValue: 'Loan-to-Value (LTV)',

	/** MuFa */
	propertyStatus: 'Property Status',
	totalUnits: 'Total Units',
	occupancy: 'Current Occupancy Rate',
	askingRpsf: 'Starting Rent, Monthly ($/SF)',
	effectiveRpsf: 'Effective Rent, Monthly ($/SF)',
	assetClassMarket: 'Asset Class',
	managementCompany: 'Management Company',
};
