import { ChangeEvent } from 'react';

import Add from '../../../ui/svg_icons/add.svg';
import Subtract from '../../../ui/svg_icons/subtract.svg';
import styled from 'styled-components';
import { ModalInputLabel } from '../common/UI';

type Props = {
	idx: number;
	email: {
		text: string;
		error: string;
	};
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	addEmail: () => void;
	removeEmail: () => void;
};

const ICON_DIMENSION = 26;

export const SendToColleagueInput = ({
	addEmail,
	email,
	handleChange,
	idx,
	removeEmail,
}: Props) => {
	const iconEl =
		idx === 0 ? (
			<IconContainer onClick={addEmail}>
				<Add width={`${ICON_DIMENSION}px`} height={`${ICON_DIMENSION}px`} />
			</IconContainer>
		) : (
			<IconContainer onClick={() => removeEmail()}>
				<Subtract
					width={`${ICON_DIMENSION}px`}
					height={`${ICON_DIMENSION}px`}
				/>
			</IconContainer>
		);

	const isError = !!email.error;

	return (
		<ModalInputLabel isError={isError}>
			<InputContainer>
				<input
					type="text"
					name="email"
					onChange={(ev) => handleChange(ev)}
					value={email.text}
				/>
				{iconEl}
			</InputContainer>
			{isError && <div>{email.error}</div>}
		</ModalInputLabel>
	);
};

const InputContainer = styled.div`
	position: relative;

	input {
		padding-right: calc(${ICON_DIMENSION}px + 1rem);
	}
`;

const IconContainer = styled.div`
	position: absolute;
	right: 1em;
	top: calc(50% - ${ICON_DIMENSION / 2}px);
	cursor: pointer;
`;
