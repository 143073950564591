import {
	Chip,
	ChipStyled,
	defaultTheme,
	FormSelect,
	OpenButton,
	SalesAnalyticsSidebarStyled,
	SelectedOption,
	SelectOnChangeParams,
} from '@compstak/ui-kit';
import { PROPERTY_TYPE_ID_TO_NAME } from 'api';
import { useCallback } from 'react';
import styled from 'styled-components';
import { DataSet, DataSetType } from '../../../analytics';
import { useDataSetForm, UseDataSetFormProps } from '../DataSet/useDataSetForm';
import { MarketItem } from '../Modals/DataSets/CreateNewDataSetV2/utils';
import { SubmarketFilter } from 'Components/Filters/Fields/SubmarketFilter/SubmarketFilter';
import { SpaceTypeMultiselect } from 'Components/Filters/Fields/AggregationFilter/SpaceTypeMultiselect';
import { PropertyTypeMultiselect } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeMultiselect';
import { useMarkets } from 'hooks/useMarkets';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

interface SidebarDataSetFormProps extends UseDataSetFormProps {
	onAttributeToPlotChange: (val?: string | null) => void;
	onApplyAll: (val: AttributeToPlotType) => void;
	dataSet: DataSet;
	expanded: boolean;
}

export const DataSetFormV2 = (props: SidebarDataSetFormProps) => {
	const markets = useMarkets();
	const { onAttributeToPlotChange, onApplyAll, dataSet, expanded } = props;
	const dataSetType = dataSet.type;
	const { compType, onFilterChange } = useFilterFieldContext();

	const { options, handlers, state } = useDataSetForm({
		...props,
		dataSetType,
	});

	const handleMarketChange = useCallback(
		(event: SelectOnChangeParams<MarketItem>) => {
			if (!event.selectedItem) return;

			handlers.onChangeMarket(event);
			onFilterChange({
				market: markets[event.selectedItem.id],
				submarkets: [],
			});
		},
		[handlers, onFilterChange, markets]
	);

	const handleAttributeToPlotChange = useCallback(
		(arg: SelectOnChangeParams) => {
			if (!arg.selectedItem) return;

			handlers.onChangeAttributeToPlot(arg);
			onAttributeToPlotChange(String(arg.selectedItem.value));
		},
		[handlers, onAttributeToPlotChange]
	);

	const handleApplyAll = useCallback(
		// @ts-expect-error TS7006: Parameter 'value' implicitly h...
		(value) => (e) => {
			e.stopPropagation();
			onApplyAll?.(value);
		},
		[onApplyAll]
	);

	const optionContentRenderer = useCallback(
		// @ts-expect-error TS7031: Binding element 'title' implic...
		({ title, value }) => {
			return (
				<OptionStyled>
					<span>{title}</span>
					{/* @ts-expect-error TS2322: Type '{ children: string; "dat... */}
					<Chip data-qa-id="apply-all" onClick={handleApplyAll(value)}>
						Apply All
					</Chip>
				</OptionStyled>
			);
		},
		[handleApplyAll]
	);

	return (
		<>
			<SalesAnalyticsSidebarStyled style={{ marginBottom: 0 }}>
				{getAttrToPlotSelect({
					dataSetType,
					options,
					state,
					onChange: handleAttributeToPlotChange,
					optionContentRenderer,
				})}
			</SalesAnalyticsSidebarStyled>
			<SalesAnalyticsSidebarStyled>
				<OpenButtonWrapper>
					<FormSelect<MarketItem>
						placeholder="Search or select"
						items={options.market}
						onChange={handleMarketChange}
						value={state.market?.name}
						noResultMessage="No data"
						isSearchable
					/>
				</OpenButtonWrapper>
			</SalesAnalyticsSidebarStyled>
			<FilterContainer>
				<SubmarketFilter
					filters={dataSet.filters}
					isActive={expanded}
					onFilterChange={onFilterChange}
				/>
			</FilterContainer>
			<FilterContainer>
				{compType === 'lease' && <SpaceTypeMultiselect />}
				{compType === 'sale' && <PropertyTypeMultiselect />}
				{compType === 'property' && (
					<PropertyTypeMultiselect
						multiSelectProps={{
							renderAllPlaceholder: () => PROPERTY_TYPE_ID_TO_NAME[2],
							disabled: true,
						}}
					/>
				)}
			</FilterContainer>
		</>
	);
};

const getAttrToPlotSelect = ({
	// @ts-expect-error TS7031: Binding element 'dataSetType' ...
	dataSetType,
	// @ts-expect-error TS7031: Binding element 'options' impl...
	options,
	// @ts-expect-error TS7031: Binding element 'state' implic...
	state,
	// @ts-expect-error TS7031: Binding element 'onChange' imp...
	onChange,
	// @ts-expect-error TS7031: Binding element 'optionContent...
	optionContentRenderer,
}) => {
	const dataSetTypeToSelectProps = {
		[DataSetType.SALES]: {
			label: '',
			items: options.salesAttributeToPlot,
			placeholder: '',
		},
		[DataSetType.COMMERCIAL]: {
			// with switching on the BE to "commercialLeases", this key will be deleted
			label: '',
			items: options.attributeToPlot,
			placeholder: 'Starting Rent (by default)',
		},
		[DataSetType.LEASES]: {
			label: '',
			items: options.attributeToPlot,
			placeholder: 'Starting Rent (by default)',
		},
		[DataSetType.MUFA]: {
			label: '',
			items: options.attributeToPlot,
			placeholder: 'Starting Rent (by default)',
		},
	};

	return (
		<OpenButtonWrapper>
			<FormSelect
				data-qa-id="select-attribute-to-plot"
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				label={dataSetTypeToSelectProps[dataSetType]?.label}
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				items={dataSetTypeToSelectProps[dataSetType]?.items}
				onChange={onChange}
				value={state.attributeToPlot}
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				placeholder={dataSetTypeToSelectProps[dataSetType]?.placeholder}
				optionContentRenderer={optionContentRenderer}
			/>
		</OpenButtonWrapper>
	);
};

const OpenButtonWrapper = styled.div`
	${OpenButton} {
		height: 50px;
	}

	${SelectedOption} {
		background-color: transparent;
		padding: 0;
		letter-spacing: 0.2px;

		padding-right: 16px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	& input {
		color: ${({ theme }) => theme.colors.white.white};
		background-color: transparent;
		border: unset;
		padding: 0 35px 0 13px;
	}

	&& svg {
		position: relative;
		right: 4px;

		width: 18px;
		height: 18px;
	}
`;

const OptionStyled = styled.div`
	position: relative;

	${ChipStyled} {
		position: absolute;
		right: 0px;
		top: -5px;
		padding: 6px 10px;
		font-size: 11px;
		background-color: ${defaultTheme.colors.neutral.n90};

		&:hover {
			background-color: ${defaultTheme.colors.neutral.n85};
		}
		&:active {
			background-color: ${defaultTheme.colors.neutral.n85};
		}
	}
`;

const FilterContainer = styled.div`
	margin: 0.5rem 0;

	.ms-parent:not(.expanded) {
		padding: 0.75rem 0.5rem;
	}
`;
