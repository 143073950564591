import { AngleArrow, Spinner } from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { useAllPresetsQuery } from 'api';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { capitalize } from '../../../utils';
import { useColumnsContext } from '../Columns';
import { useOptionsContext } from '../OptionsProvider';

type Option = {
	label: string;
	value: string;
	ids: string;
};

// This "Select" is actually just a button with a popup menu
// because the "options" don't select a value but rather do a mutation
export const PresetSelect = () => {
	const { columnPreferencesType } = useOptionsContext();

	const [isOpen, setIsOpen] = useState(false);

	const { data: presets, isFetching: isFetchingPresets } = useAllPresetsQuery(
		columnPreferencesType
	);

	const { selectedColumnPreferences, applyPreset, isLoadingApplyPreset } =
		useColumnsContext();

	const stringifiedPreferencesIds = useMemo(
		() =>
			getStringifiedSortedIds(
				selectedColumnPreferences.map((p) => p.compAttributeId)
			),
		[selectedColumnPreferences]
	);

	const presetOptions = useMemo(() => {
		if (!presets) return [];
		const entries = Object.entries(presets);

		const options: Option[] = [];

		for (const [name, columnPreferences] of entries) {
			const value = name;
			const ids = getStringifiedSortedIds(
				columnPreferences.map((c) => c.compAttributeId)
			);

			if (name.startsWith('summary')) {
				options.unshift({
					label: 'Default',
					value,
					ids,
				});
			} else {
				options.push({
					label: capitalize(name),
					value,
					ids,
				});
			}
		}
		return options;
	}, [presets]);

	const selectedOption = presetOptions.find(
		(o) => o.ids === stringifiedPreferencesIds
	);

	const isLoading = isFetchingPresets || isLoadingApplyPreset;

	return (
		<Popover.Root open={isOpen} onOpenChange={setIsOpen}>
			<OpenButton disabled={isLoading}>
				{isLoading
					? 'Loading...'
					: selectedOption
					? selectedOption.label
					: 'Custom'}
				{isLoading ? (
					<Spinner size="s" />
				) : (
					<StyledAngleArrow rotate={isOpen ? 180 : 0} />
				)}
			</OpenButton>
			<Popover.Portal>
				<Content>
					<ul>
						{presetOptions.map((option) => {
							const isSelected = option.value === selectedOption?.value;
							return (
								<Item
									key={option.value}
									value={option.value}
									onClick={() => {
										setIsOpen(false);
										applyPreset(option.value);
									}}
									style={{
										fontWeight: isSelected ? 600 : undefined,
									}}
								>
									{option.label}
								</Item>
							);
						})}
					</ul>
				</Content>
			</Popover.Portal>
		</Popover.Root>
	);
};

const getStringifiedSortedIds = (ids: number[]) => {
	return ids.sort((a, b) => a - b).join(',');
};

const OpenButton = styled(Popover.Trigger)`
	width: 140px;
	height: 32px;
	border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
	background-color: ${(p) => p.theme.colors.gray.gray700};
	color: ${(p) => p.theme.colors.neutral.n0};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: 14px;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
`;

const StyledAngleArrow = styled(AngleArrow)`
	path {
		stroke: ${(p) => p.theme.colors.neutral.n0};
	}
`;

const Content = styled(Popover.Content)`
	width: 140px;
	background-color: ${(p) => p.theme.colors.gray.gray700};
	z-index: ${(p) => p.theme.zIndex.dropdown};
	border: 1px solid ${(p) => p.theme.colors.neutral.n100};
	border-top: 0;
`;

const Item = styled.li`
	height: 32px;
	color: ${(p) => p.theme.colors.neutral.n0};
	padding-left: 0.5rem;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n100};
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 13px;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n500};
	}
	&:last-of-type {
		border-bottom: 0;
	}
`;
