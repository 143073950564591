import { MarketRentAnalyticsRentType } from 'api';
import { useDashboardMarketRentData } from 'ExchangeDashboard/useDashboardMarketRentData';
// @ts-expect-error TS2307: Cannot find module 'MapAnalyti...
import { MarketRentByPropertyTypeState } from 'MapAnalytics/mapAnalyticsTypes';
import {
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsViewMode,
	useMarketRentAnalyticsState,
} from 'maps';

/**
 * Dashboard export uses redux data for generating a PDF,
 * so this is a temprorary adjustment until we eventually refactor
 * PDF generation code
 */
export const useAdaptedMarketRentExportData = () => {
	const [state] = useMarketRentAnalyticsState();

	const { data } = useDashboardMarketRentData();

	const marketRentState: Partial<MarketRentByPropertyTypeState> = {
		params: {
			metric:
				state.rentType === MarketRentAnalyticsRentType.STARTING
					? 'startingRent'
					: 'effectiveRent',
			monthly: state.period === MarketRentAnalyticsPeriod.MONTH,
			propertyType: state.propertyType,
			submarket: state.viewMode === MarketRentAnalyticsViewMode.SUBMARKET,
		},
		results: data,
	};

	return marketRentState;
};
