import { SimpleTableColumn } from '@compstak/ui-kit';
import {
	Buyer,
	TopBuyersResponse,
	useTopBuyersQuery,
} from 'api/topBuyers/useTopBuyers';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { PremierListTableProps } from '../PremierList';
import { getPremierListTableColumns } from './columns';
import { useViewCompLink } from './util';
import { PROPERTY_TYPE_NAME_TO_ID } from '../../../api';
import { PremierListTableView } from './PremierListTableView';

const DEFAULT_ROWS: TopBuyersResponse['buyers'] = [];
const BASE_LINK_URL = '/search/sales/list';
const FILTER_NAME: keyof FiltersObject = 'buyers';

export const ActiveBuyersTable = ({
	commonFilters,
	selectedSpaceTypeName,
}: PremierListTableProps) => {
	const filters: Partial<FiltersObject> = useMemo(
		() => ({
			buildingPropertyTypeId: [PROPERTY_TYPE_NAME_TO_ID[selectedSpaceTypeName]],
			saleDate: {
				max: dayjs().toDate(),
				min: dayjs().subtract(1, 'year').toDate(),
			},
		}),
		[selectedSpaceTypeName]
	);

	const localFilters = useMemo(
		() => ({ ...filters, ...commonFilters }),
		[commonFilters, filters]
	);

	const { data, isFetching } = useTopBuyersQuery({ filters: localFilters });
	const rows = data?.buyers ?? DEFAULT_ROWS;

	const columns: SimpleTableColumn<Buyer>[] = useMemo(
		() =>
			getPremierListTableColumns<Buyer>({
				filterName: FILTER_NAME,
				baseLinkUrl: BASE_LINK_URL,
				filters: localFilters,
				nameColumn: {
					header: 'Buyer Name',
				},
				volumeColumn: {
					header: 'Volume ($M)',
					prefix: '$',
					key: 'sumOfSalesPrice',
					numberProcess: (value) => value / 1e6,
				},
				countColumn: {
					header: 'Sale Transactions',
					key: 'totalSalesCount',
				},
			}),
		[localFilters]
	);

	const viewSaleCompsHref = useViewCompLink({
		filterName: FILTER_NAME,
		baseLinkUrl: BASE_LINK_URL,
		filters: localFilters,
		rows,
	});

	return (
		<PremierListTableView
			data={rows}
			buttonHref={viewSaleCompsHref}
			buttonText="View Sale Comps"
			title="Buyer Activity"
			description={`Companies that bought the most ${selectedSpaceTypeName.toLowerCase()} space in the last 365 days in ${
				commonFilters.markets[0].displayName
			} according to CompStak’s available data.`}
			isFetching={isFetching}
			columns={columns}
			id="ActiveBuyersTable"
		/>
	);
};
