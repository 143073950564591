import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { MapListLoadingContainer } from '../UI';
import { PortfolioTable } from './PortfolioTable';
import { PortfolioTableLeases } from './PortfolioTableLeases';
import { ListViewAndMapViewToolbarV2 } from './ListViewAndMapViewToolbarV2';
import { ExportCompsButton } from 'Components/Export';
import { usePropertiesListAndMapViewContext } from './PropertiesListAndMapViewsProvider';
import { useLeasesListAndMapViewContext } from './LeasesListAndMapViewProvider';
import { RemovePropertiesButton } from 'PortfolioAnalytics/Components/RemovePropertiesButton';

type Props = {
	portfolioId: number;
};

export const ListViewV2 = ({ portfolioId }: Props) => {
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	const isLeasesList =
		!!searchParams.expirationYears ||
		!!searchParams.tenantNames ||
		!!searchParams.tenantIndustry;

	const { filters } = usePortfolioFilters();

	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	const propertiesSelection = usePropertiesListAndMapViewContext();
	const leasesSelection = useLeasesListAndMapViewContext();

	if (!portfolio) {
		return (
			<MapListLoadingContainer>
				<Spinner />
			</MapListLoadingContainer>
		);
	}

	return (
		<Root>
			<ListViewAndMapViewToolbarV2
				view="list"
				portfolioId={portfolioId}
				searchParams={searchParams}
			/>
			<>{isLeasesList ? <PortfolioTableLeases /> : <PortfolioTable />}</>
			<ListViewButtonsContainer>
				<ExportCompsButton
					buttonType="table"
					compIds={
						isLeasesList
							? leasesSelection.selection
							: propertiesSelection.selection
					}
					compType={isLeasesList ? 'lease' : 'property'}
					getComps={
						isLeasesList
							? leasesSelection.getSelectedRows
							: propertiesSelection.getSelectedRows
					}
				/>
				{!isLeasesList && (
					<RemovePropertiesButton selectionState={propertiesSelection} />
				)}
			</ListViewButtonsContainer>
		</Root>
	);
};

const Root = styled.div`
	display: grid;
	grid-template-rows: auto 1fr auto;
	box-sizing: border-box;
	height: 100%;
	* {
		box-sizing: border-box;
	}
`;

const ListViewButtonsContainer = styled.div`
	display: flex;
	margin: 1rem;
	gap: 1rem;
`;
