import React, { useState } from 'react';
import Menu from 'Singletons/Menu';
import Tick from '../../../../../ui/svg_icons/tick_circle_filled.svg';
import button from 'ui/styles/button.less';
import styles from '../styles/menu.less';
import { useDispatch } from 'react-redux';
import { hideMenu } from 'Singletons/Menu/actions';
import { useModal } from 'providers/ModalProvider';
import { GraphExportModal } from 'Components/Modals/GrapghExportModal/GraphExportModal';
import { Options } from 'highcharts';

export type AnalyticsExportMenuProps = {
	copyToExcel: () => void;
	exportFn: (chartOptions: Options) => void;
	title?: string;
};

const AnalyticsExportMenu = ({
	copyToExcel,
	exportFn,
	title,
}: AnalyticsExportMenuProps) => {
	const [copied, setCopied] = useState(false);
	const dispatch = useDispatch();
	const { openModal } = useModal();

	const handleCopyToExcel = () => {
		copyToExcel();
		setCopied(true);
		setTimeout(() => dispatch(hideMenu('analytics-export-menu')), 1000);
	};

	const handleExportModal = () => {
		dispatch(hideMenu('analytics-export-menu'));
		openModal({
			modalContent: (
				<GraphExportModal
					chartOptions={{
						title: { text: title },
						legend: {
							padding: 10,
							margin: 20,
						},
					}}
					exportFn={exportFn}
				/>
			),
		});
	};

	if (copied) {
		return (
			<div className={styles.exportMenuSuccess}>
				<span className={styles.successCircle}>
					<Tick width={30} height={30} />
				</span>{' '}
				Copied
			</div>
		);
	}

	return (
		<div className={styles.exportMenu}>
			<div className={styles.label}>Select Export Option</div>
			<button
				className={`${button.button} ${styles.button}`}
				onClick={handleCopyToExcel}
			>
				Copy to Excel
			</button>
			<button
				className={`${button.button} ${styles.button}`}
				onClick={handleExportModal}
			>
				Save Image
			</button>
		</div>
	);
};

Menu.addComponent('analytics-export-menu', AnalyticsExportMenu);
