import { useState } from 'react';
import { defaultTheme, Button } from '@compstak/ui-kit';
import { SuggestUpdateForm, SuggestUpdateFormProps } from './SuggestUpdateForm';
import styled from 'styled-components';

export const SuggestUpdateBar = ({
	selectedComps,
	market,
	compType,
}: Pick<SuggestUpdateFormProps, 'selectedComps' | 'market' | 'compType'>) => {
	const [formIsOpen, setFormIsOpen] = useState(false);
	return formIsOpen ? (
		<SuggestUpdateForm
			setFormIsOpen={setFormIsOpen}
			formIsOpen={formIsOpen}
			selectedComps={selectedComps}
			compType={compType}
			market={market}
		/>
	) : (
		// @ts-expect-error TS2769: No overload matches this call....
		<SuggestUpdateContainer enabled={selectedComps.length}>
			<span>Update missing information for this transaction and </span>
			<TextUnderlined>earn 30 credits</TextUnderlined>
			<OpenFormButton
				disabled={!selectedComps.length}
				onClick={() => setFormIsOpen(true)}
			>
				Suggest an Update
			</OpenFormButton>
		</SuggestUpdateContainer>
	);
};

/* TODO  add to colors in ui-kit*/
const LIGHT_GREEN = '#b5dac1';

const SuggestUpdateContainer = styled.div<{ enabled: boolean }>`
	text-align: center;
	color: ${defaultTheme.colors.white.white};
	background: ${(props) =>
		props.enabled ? `${defaultTheme.colors.green.green500}` : LIGHT_GREEN};
	padding: 12px;
`;

const TextUnderlined = styled.span`
	text-decoration: underline;
	padding-right: 6px;
`;

const OpenFormButton = styled(Button)`
	color: ${defaultTheme.colors.green.green500};
	background-color: ${defaultTheme.colors.white.white};
	opacity: 1;
	&:not(:disabled) {
		&:hover {
			background-color: ${defaultTheme.colors.gray.gray900};
		}
	}
	&:disabled {
		color: ${LIGHT_GREEN};
		background-color: ${defaultTheme.colors.white.white};
	}
`;
