import { Button, IconCross } from '@compstak/ui-kit';
import {
	useHideSurveyBannerMutation,
	useSurveyBannerStateQuery,
	useSurveyQuestionsQuery,
} from 'api/surveyForCredits/surveyQuestionQueries';
import { BELOW_APP_HEADER_CSS } from 'constants/appHeader';
import { SURVEY_FOR_CREDITS_COLORS } from 'constants/colors';
import { useIsExchange } from 'hooks';
import { Link } from 'router';
import styled from 'styled-components';

export function GoToSurveyForCreditsBanner(): JSX.Element {
	const isExchange = useIsExchange();
	const { data: questions } = useSurveyQuestionsQuery();
	const { data: bannerState } = useSurveyBannerStateQuery();
	const { mutate: hideBanner } = useHideSurveyBannerMutation();

	if (
		!isExchange ||
		!bannerState?.shown ||
		!questions ||
		questions.every((q) => q.done)
	) {
		return <></>;
	}

	return (
		<SurveyForCreditsBannerOverlay>
			<span>
				Use your market expertise to answer survey questions and earn credits.
			</span>
			<Link to="/survey">START SURVEY</Link>
			<Spacer />
			<Button variant="ghost" onClick={() => hideBanner()}>
				<IconCross fill="white" />
			</Button>
		</SurveyForCreditsBannerOverlay>
	);
}

const SurveyForCreditsBannerOverlay = styled.div`
	position: fixed;
	${BELOW_APP_HEADER_CSS}
	min-height: 48px;
	max-height: 200px;
	height: fit-content;
	display: flex;
	background-color: ${SURVEY_FOR_CREDITS_COLORS.goToSurveyBanner};
	opacity: 0.9;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	letter-spacing: 0.3px;
	color: white;
	z-index: ${(props) => props.theme.zIndex.overlay};
	padding: 8px 16px;
	align-items: center;
	gap: 16px;

	& a {
		text-decoration: underline;
		font-weight: 400;
	}
`;

const Spacer = styled.div`
	flex: 1;
`;
