import * as leaseActions from 'actions/lease';
import * as saleActions from 'actions/sale';
import { selectionActions } from 'actions/selection';
import * as userActions from 'actions/user';
import 'Components/Menus/Export';
import { connect } from 'react-redux';
import { AppState } from 'reducers/root';
import * as feedbackActions from 'Singletons/Feedback/actions';
import * as menuActions from 'Singletons/Menu/actions';
import { AppDispatch } from 'store';
import actionWrapper from 'util/actionWrapper';
import * as pageActions from '../actions';
import './Components/TableSettingsMenu/TableSettingsMenu';
import { TableComponent } from './TableComponent';
import { getAveragesState } from '../../../hooks/useAverages';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './styles/table.nomodule.less';

export function mapStoreToProps(store: AppState) {
	const { averages, averagesNeeded } = getAveragesState(
		store.searchReducer,
		store.selection
	);

	return {
		appConfig: store.appConfig,
		leases: store.searchReducer.leases,
		sales: store.searchReducer.sales,
		properties: store.searchReducer.properties,
		totalResults: store.searchReducer.totalResults,
		search: store.searchReducer.search,
		lowestLoaded: store.searchReducer.lowestLoaded,
		highestLoaded: store.searchReducer.highestLoaded,
		fields: store.searchReducer.fields,
		selection: store.selection.map,
		user: store.user!,
		allSelected: store.selection.allSelected,
		averages,
		averagesNeeded,
		isLoadingComps: store.searchReducer.isLoadingComps,
		selectAllPending: store.searchReducer.selectAllPending,
		activeMapControl: store.searchReducer.activeMapControl,
		permissions: store.permissions,
		referenceData: store.referenceData,
		featureFlags: store.featureFlags,
	};
}

export const mapDispatchToProps = (dispatch: AppDispatch) =>
	actionWrapper(
		{
			userActions,
			pageActions,
			leaseActions,
			saleActions,
			selectionActions,
			feedbackActions,
			menuActions,
		},
		dispatch
	);

export const Table = connect(
	mapStoreToProps,
	mapDispatchToProps
)(TableComponent);
