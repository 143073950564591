import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { CompType } from 'types';
import { routesWithCompType } from './routes';

export const useCompType = () => {
	const location = useLocation();

	return useMemo(() => {
		for (const r of routesWithCompType) {
			if (matchPath(r.path, location.pathname)) {
				return (r.data as { compType: CompType }).compType;
			}
		}
		return undefined;
	}, [location]);
};
