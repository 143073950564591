import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from '../common/UI';

type Props = {
	totalProperties: number;
	setIsAddToPortfolioDialogOpen: (isAddToPortfolioDialogOpen: boolean) => void;
};

export const MultiPropertyInfoModal = ({
	totalProperties,
	setIsAddToPortfolioDialogOpen,
}: Props) => {
	const { closeModal } = useModal();

	const handleClick = () => {
		setIsAddToPortfolioDialogOpen(true);

		closeModal();
	};

	return (
		<>
			<ModalTitle>Info</ModalTitle>
			<ModalParagraph>
				This is a portfolio sale. All ({totalProperties}) properties within the
				sale will be added to your portfolio. If you wish to add a single
				property, click on the address and add to portfolio from the Property
				Page.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton onClick={handleClick}>Add All Properties</ModalButton>
			</ModalButtons>
		</>
	);
};
