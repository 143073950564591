import { User } from '@compstak/common';
import { Text, View } from '@react-pdf/renderer';
import { useExchangeDashboardAverages } from 'ExchangeDashboard/hooks/averages/useExchangeDashboardAverages';
import { PdfPage } from 'pdf/PdfPage';
import { useSharedPdfStyle } from 'pdf/useSharedPdfStyle';
import { AveragesPdfSection } from '../Averages/AveragesPdfSection';
import { ExportPdfState } from '../ExportPdfProvider';

type Props = {
	exportPdfState: ExportPdfState;
	user: User;
	marketText: string;
	submarketText: string;
	propertyTypesText: string;
	createdOnText: string;
	showAverages: boolean;
	averagesData: ReturnType<typeof useExchangeDashboardAverages>;
};

export const HeaderPage = ({
	exportPdfState,
	user,
	marketText,
	submarketText,
	propertyTypesText,
	createdOnText,
	showAverages,
	averagesData,
}: Props) => {
	const pdfStyle = useSharedPdfStyle();

	return (
		<PdfPage
			logo={exportPdfState.logo}
			preparedFor={exportPdfState.preparedFor}
			user={user}
			title={exportPdfState.title}
		>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: 24,
					marginBottom: 12,
				}}
			>
				<View style={{ flex: 1 }}>
					<View style={pdfStyle.infoRow}>
						<Text style={pdfStyle.infoLabel}>Market</Text>
						<Text style={pdfStyle.infoValue}>{marketText}</Text>
					</View>
					<View style={pdfStyle.infoRow}>
						<Text style={pdfStyle.infoLabel}>Submarket</Text>
						<Text style={pdfStyle.infoValue}>{submarketText}</Text>
					</View>
					<View style={pdfStyle.infoRow}>
						<Text style={pdfStyle.infoLabel}>Property Types*</Text>
						<Text style={pdfStyle.infoValue}>{propertyTypesText}</Text>
					</View>
					<Text
						style={{
							marginTop: 12,
							fontSize: 10,
						}}
					>
						*Except where noted
					</Text>
				</View>
				<View style={{ flex: 1 }}>
					<View style={pdfStyle.infoRow}>
						<Text style={pdfStyle.infoLabel}>Created On</Text>
						<Text style={pdfStyle.infoValue}>{createdOnText}</Text>
					</View>
					{exportPdfState.preparedFor && (
						<View style={pdfStyle.infoRow}>
							<Text style={pdfStyle.infoLabel}>Prepared For</Text>
							<Text style={pdfStyle.infoValue}>
								{exportPdfState.preparedFor}
							</Text>
						</View>
					)}
					<View style={pdfStyle.infoRow}>
						<Text style={pdfStyle.infoLabel}>Prepared By</Text>
						<View style={pdfStyle.infoValue}>
							<Text>
								{user.firstName} {user.lastName}
							</Text>
							<Text>{user.email}</Text>
						</View>
					</View>
					{exportPdfState.notes && (
						<View style={pdfStyle.infoRow}>
							<Text style={pdfStyle.infoLabel}>Notes</Text>
							<Text style={pdfStyle.infoValue}>{exportPdfState.notes}</Text>
						</View>
					)}
				</View>
			</View>
			{showAverages && <AveragesPdfSection averagesData={averagesData} />}
		</PdfPage>
	);
};
