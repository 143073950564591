import { BuildingClassId, Portfolio, PropertyTypeId } from 'api';
import constate from 'constate';
import { FilterNumberInterval } from 'models/filters/types';
import { useCallback, useMemo, useState } from 'react';

type Props = {
	portfolio: Portfolio;
};

type UsePortfolioFilters = {
	filters: FiltersState & { marketIds: number[] };
	setFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
	resetFilters: () => void;
	portfolio: Portfolio;
	isPortfolioEmpty: boolean | undefined;
};

export const [PortfolioFiltersProvider, usePortfolioFilters] = constate(
	({ portfolio }: Props): UsePortfolioFilters => {
		const [filters, setFilters] = useState<FiltersState>(initialState);

		const resetFilters = useCallback(() => {
			setFilters(initialState);
		}, []);

		const marketIds = useMemo(() => {
			return portfolio.markets
				.filter((m) => {
					return m.submarkets.some((s) =>
						filters.submarketIds.includes(s.submarketId)
					);
				})
				.map((m) => m.marketId);
		}, [portfolio, filters.submarketIds]);

		const isPortfolioEmpty = useMemo(() => {
			if (!portfolio) return;
			const totalPropertyCount = portfolio.markets.reduce(
				(sum, m) => sum + m.propertyCount,
				0
			);
			return totalPropertyCount === 0;
		}, [portfolio]);

		return useMemo(
			() => ({
				filters: {
					...filters,
					marketIds,
				},
				setFilters,
				resetFilters,
				portfolio,
				isPortfolioEmpty,
			}),
			[
				filters,
				setFilters,
				resetFilters,
				marketIds,
				portfolio,
				isPortfolioEmpty,
			]
		);
	}
);

const initialState: FiltersState = {
	submarketIds: [],
	propertyTypeIds: [],
	buildingClassIds: [],
	leaseExpirationsMonths: null,
	buildingSize: {
		min: null,
		max: null,
	},
};

export type FiltersState = {
	submarketIds: number[];
	propertyTypeIds: PropertyTypeId[];
	buildingClassIds: BuildingClassId[];
	leaseExpirationsMonths: LeaseExpirationsMonths;
	buildingSize: FilterNumberInterval | null | undefined;
};

export type LeaseExpirationsMonths = 12 | 24 | 36 | 48 | 60 | null;
