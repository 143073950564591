import React from 'react';
import Select from 'react-select';

import { LeaseAggregationsLoader } from 'Components/Filters/Loaders/SpaceTypeAggregations';
import { ShapeDataLoader } from 'Components/Graphs/ShapeDataLoader';

import CircleTick from '../../../../ui/svg_icons/circle_tick.svg';

import { getSetFilterKeys } from 'models/filters/util/getSetFilters';

import { Graph } from 'Components/Graphs';
import GraphOptions from './GraphOptions';

import styles from '../styles/table-graph.less';
import spinner from 'ui/styles/spinner.less';
import button from 'ui/styles/button.less';
import { selectorStyles } from 'util/reactSelectStyles';
import { FiltersObject } from 'models/filters/types';
import { Button } from '@compstak/ui-kit';
import styled from 'styled-components';

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function Loading(props) {
	return (
		<div className={styles.container}>
			<div className={styles.graphContainer}>
				<div className={spinner.large} />
			</div>
			{/* @ts-expect-error Type '{}' is missing the following properties from type 'GraphOptionsProps' */}
			<GraphOptions {...props} />
		</div>
	);
}

export class Warning extends React.Component {
	static defaultProps = {
		buttonText: 'Show me anyway',
	};

	acceptWarning = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'acceptWarning' does not exist on type 'R... Remove this comment to see the full error message
		this.props.acceptWarning(this.props.type);
	};

	render() {
		return (
			<div className={styles.container}>
				<div className={styles.warning}>
					<p>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message */}
						{typeof this.props.message === 'function'
							? // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
								this.props.message()
							: // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
								this.props.message}
					</p>
					<StyledButton onClick={this.acceptWarning}>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'buttonText' does not exist on type 'Read... Remove this comment to see the full error message */}
						{this.props.buttonText}
					</StyledButton>
				</div>
			</div>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function TableGraph(props) {
	if (!props.warningsAccepted.points && props.data[0].data.points) {
		return (
			<Warning
				message={() => (
					<span>
						{`If there are fewer than ${props.thresholdForPoints} comps in your filtered data set, we plot dots instead of an average line.`}
						<br />
						<br />
						Bar graphs, however, will always display as bars, regardless of the
						number of comps in your filtered data set.
					</span>
				)}
				buttonText="Okay"
				type="points"
				{...props}
			/>
		);
	}
	return (
		<div className={styles.container}>
			<div className={styles.graphContainer}>
				<Graph
					// @ts-expect-error ts-migrate(2769) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
					className={styles.graph}
					chartType={props.userPreferences.type}
					timespan={props.userPreferences.timespan}
					shapes={props.shapes}
					data={props.data}
					legend={false}
					market={props.market}
					copyRequest={props.copyRequest}
					exportRequest={props.exportRequest}
				/>
			</div>
			{/* @ts-expect-error Type '{}' is missing the following properties from type 'GraphOptionsProps' */}
			<GraphOptions {...props} />
		</div>
	);
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(props: any) => Element' is not ... Remove this comment to see the full error message
export const GraphLoaderAndDisplayer = ShapeDataLoader(TableGraph, Loading);

const SpaceTypeChooser = LeaseAggregationsLoader(
	class SpaceTypeChooser extends React.Component {
		state = {
			spaceTypeIdsOption: false,
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		setSelection = (event) => {
			this.setState({
				spaceTypeIdsOption: event,
			});
		};

		setFilters = () => {
			if (this.state.spaceTypeIdsOption) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'onFilterChange' does not exist on type '... Remove this comment to see the full error message
				this.props.onFilterChange(
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'true'.
					{ spaceTypeId: this.state.spaceTypeIdsOption.value }
				);
			}
		};

		render() {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'searchResultsAggregations' does not exis... Remove this comment to see the full error message
			if (!this.props.searchResultsAggregations) {
				return <div />;
			}

			// show only space types with > 0 results

			const spaceTypesWithResults =
				// @ts-expect-error TS2339: Property 'referenceData' does ...
				this.props.referenceData.filterableSpaceTypes.filter((spaceType) => {
					let resultsNumber = 0;
					if (spaceType.ids) {
						// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
						spaceType.ids.forEach((id) => {
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'searchResultsAggregations' does not exis... Remove this comment to see the full error message
							if (this.props.searchResultsAggregations.spaceTypeId[id]) {
								resultsNumber +=
									// @ts-expect-error TS2339: Property 'searchResultsAggrega...
									this.props.searchResultsAggregations.spaceTypeId[id];
							}
						});
					} else {
						resultsNumber =
							// @ts-expect-error TS2339: Property 'searchResultsAggrega...
							this.props.searchResultsAggregations.spaceTypeId[spaceType.id];
					}
					return resultsNumber !== undefined && resultsNumber > 0;
				});

			// @ts-expect-error TS7006: Parameter 'spaceType' implicit...
			const spaceTypeOptions = spaceTypesWithResults.map((spaceType) => {
				const ids = spaceType.ids ? spaceType.ids : [spaceType.id];
				return {
					value: ids,
					label: spaceType.name,
				};
			});

			return (
				<div className={styles.noSpaceTypeContainer}>
					<div className={styles.container}>
						<div className={styles.graphContainer} />
						{/* @ts-expect-error Type '{}' is missing the following properties from type 'GraphOptionsProps' */}
						<GraphOptions {...this.props} />
					</div>
					<div className={styles.spaceTypeSelector}>
						<h2>Select space type to view chart</h2>
						<label>
							Space Type
							<Select
								onChange={this.setSelection}
								// @ts-expect-error TS2769: No overload matches this call....
								value={this.state.spaceTypeIdsOption}
								options={spaceTypeOptions}
								isSearchable={false}
								styles={selectorStyles}
								classNamePrefix="space-type-dropdown"
							/>
						</label>
						<button
							className={button.blue}
							disabled={!this.state.spaceTypeIdsOption}
							onClick={this.setFilters}
						>
							Confirm
						</button>
					</div>
				</div>
			);
		}
	}
);

export const hasMultipleSpaceTypes = (
	spaceTypeId: FiltersObject['spaceTypeId']
) => {
	if (spaceTypeId == null) {
		return true;
	}
	if (spaceTypeId.length === 1) {
		return false;
	}
	let hasOffice = 0;
	let hasRetail = 0;
	let hasIndustrial = 0;
	let hasOther = 0;
	spaceTypeId.forEach((v) => {
		switch (v) {
			case 1:
				hasOffice = 1;
				break;
			case 2:
				hasRetail = 1;
				break;
			case 6:
			case 7:
				hasIndustrial = 1;
				break;
			default:
				hasOther = 1;
		}
	});

	if (hasOffice + hasIndustrial + hasRetail + hasOther > 1) {
		return true;
	}

	return false;
};

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export const GraphOrWarningOrSpaceTypeChooser = (props) => {
	if (!props.filters.spaceTypeId) {
		return <SpaceTypeChooser {...props} />;
	} else if (
		!props.warningsAccepted.multipleSpaceTypes &&
		hasMultipleSpaceTypes(props.filters.spaceTypeId)
	) {
		return (
			<Warning
				message="The Insights chart is best viewed with one space type. You’ve selected more than one."
				type="multipleSpaceTypes"
				{...props}
			/>
		);
	} else if (
		!props.warningsAccepted.dateFilter &&
		getSetFilterKeys(props.filters).find((s) => s.includes('Date'))
	) {
		return (
			<Warning
				message={() => (
					<span>
						Please note that the time period of this chart is defined by the
						Insights <b>timespan dropdown</b>, rather than any date filters in
						the sidebar.
					</span>
				)}
				buttonText="Okay"
				type="dateFilter"
				{...props}
			/>
		);
	} else if (
		props.copyRequest &&
		!props.warningsAccepted['copy-' + props.copyRequest.toISOString()]
	) {
		return [
			<Warning
				key="warning"
				message={() => (
					<span>
						<CircleTick
							width={50}
							height={50}
							className={styles.feedbackTick}
						/>
						<br />
						Copied to your clipboard. You can now paste in an Excel file.
					</span>
				)}
				buttonText="Okay"
				type={'copy-' + props.copyRequest.toISOString()}
				{...props}
			/>,
			<GraphLoaderAndDisplayer
				key="graph"
				chartType={props.user.preferences.insights.type}
				{...props}
			/>,
		];
	} else {
		return (
			<GraphLoaderAndDisplayer
				key="graph"
				{...props}
				chartType={props.user.preferences.insights.type}
			/>
		);
	}
};

const StyledButton = styled(Button)`
	margin-bottom: 56px;
`;
