import { API } from '@compstak/common';
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { PropertyTypeId, SpaceTypeId } from 'api/referenceData';

export const useInviteQuery = (
	uuid: string,
	options?: UseInviteQueryOptions
) => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.INVITES, uuid],
		queryFn: async () => {
			const { data } = await API.get<InviteResponse>(`/api/invites/${uuid}`);
			return data;
		},
		...options,
	});

	return queryResult;
};

export const useTeamInviteMutation = (options?: UseInviteMutationOptions) => {
	return useMutation({
		mutationFn: (invites) =>
			API.post<void, InviteParams>('/api/dashboard/teamInvite', {
				invites,
			}),
		...options,
	});
};

export type UseInviteQueryOptions = UseQueryOptions<InviteResponse>;
export type UseInviteMutationOptions = UseMutationOptions<
	unknown,
	unknown,
	InviteDTO[]
>;

export type InviteParams = {
	invites: InviteDTO[];
};
export type InviteResponse = {
	id: number;
	comment: string;
	dateCreated: string;
	email: string;
	firstName: string;
	lastName: string;
	registrationToken: string;
	statusId: number;
	userInfo: string;
	phoneNumber: string | number | undefined;
	marketId: number;
	statusChange: string;
	madeNoMarket: boolean;
	market?: string;
	lastUpdated: string;
	application: string;
	status: string;
	tags: string[];
	inviteType: string;
	activationLink: string;
	personId: number;
	spaceTypes: SpaceTypeId[];
	propertyTypes: PropertyTypeId[];
	sentComps: string;
	skipFue: boolean;
	utmSource: string[] | null;
	utmMedium: string[] | null;
	utmCampaign: string[] | null;
	utmTerm: string[] | null;
	csApplication: string;
	krakenOptIn: boolean;
	enterpriseTrial: boolean;
};

export type InviteDTO = Omit<
	InviteResponse,
	| 'id'
	| 'comment'
	| 'dateCreated'
	| 'registrationToken'
	| 'statusId'
	| 'statusChange'
	| 'lastUpdated'
	| 'status'
	| 'inviteType'
	| 'activationLink'
	| 'personId'
	| 'spaceTypes'
	| 'propertyTypes'
	| 'sentComps'
	| 'skipFue'
	| 'csApplication'
	| 'krakenOptIn'
	| 'enterpriseTrial'
>;
