import { FiltersObject } from 'models/filters/types';
import { ServerFilterItem } from 'types/serverFilters';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';

export type ChartType = 'histogram' | 'line';

export type Timespan = number;

export type Chart = {
	id?: number | null;
	title: string;
	dataSets: DataSet[];
	trendMonths?: number;
	chartType?: ChartType;
	timespan: number;
	originalChartId?: number | null;
	projectId?: number | null;
	userId?: number;
};

export type ChartFromServer = Omit<Chart, 'dataSets'> & {
	dataSets: DataSetFromServer[];
};

export enum DataSetType {
	COMMERCIAL = 'commercial',
	LEASES = 'commercialLeases',
	SALES = 'commercialSales',
	MUFA = 'multifamily',
}

export enum DataSetGroupTitles {
	LEASES = 'leases',
	SALES = 'sales',
	MUFA = 'multifamily',
}

export type DataSet = {
	id?: number;
	name: string;
	series: AttributeToPlotType;
	filters: FiltersObject;
	isVisible: boolean;
	type: DataSetType;
	excludedCompIds?: number[];
};

export type DataSetFromServer = Omit<DataSet, 'filters'> & {
	filters: ServerFilterItem[];
};

export type Project = {
	id?: number;
	name: string;
	charts: Chart[];
	dateCreated: string;
	lastUpdated: string;
	canDelete: boolean;
};

export type Shape = {
	x: string;
	y: string;
	z: string;
	// Seems that in some cases the points field has the boolean value true!?
	points: number;
	color: string;
	trendMonths: number | undefined;

	// These fields are derived from a dataset.
	filters: DataSet['filters'];
	name: string;
	dataSetType: DataSetType;
	excludedCompIds?: number[];

	// TODO: These fields might be unused!?
	quartiles?: boolean | number;
	summaries?: any;
};

export type Summary = {
	annualizedGrowth: number;
	histogram: [number, number][];
	transactionSize: number;
	months?: number;
	leases?: number;
	properties?: number;
};

export type InsightDataPoint = {
	freeMonths?: number;
	startingRent?: number;
	effectiveRent?: number;
	workValue?: number;
	leaseTerm?: number;
	executionDate: string;
	transactionSize: number;
	id: number;
	marketId: number;
	dataSource?: string;
	dateCreated?: string;
	saleDate?: string;
};

export type InsightData = {
	averages: {
		[key: string]: number;
	};
	count: number;
	summaries: Summary[];
	trendLine: { date: string; value: number }[];
	points?: InsightDataPoint[];

	// FE added fields
	isIgnored?: boolean;
	isError?: boolean;
};

export type InsightDataResponse = {
	data: InsightData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
};

export type DataSetStats = {
	annualizedGrowth: number;
	properties?: number;
	months?: number;
	transactionSize?: number;
	histogram?: [number, number][];
	leases?: number;
	sales?: number;
	buildingSize?: number;
};

export type GraphUserPreferences = {
	type: ChartType;
	yAxis: string;
	timespan: number;
	trendMonths: number;
};
