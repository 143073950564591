import React from 'react';
import { defaultTheme, TooltipV2 } from '@compstak/ui-kit';
import styled from 'styled-components';
import { AssociatedApn } from 'api/ppm-property/associatedApns/useGetAssociatedApns';

export const AssociatedApns = ({
	associatedApns,
}: {
	associatedApns: AssociatedApn[];
}) => {
	return (
		<TooltipV2
			side="bottom"
			content={<Content associatedApns={associatedApns} />}
			contentCSS={() => ({ zIndex: defaultTheme.zIndex.overlay })}
		>
			<Trigger>{`${associatedApns.length} associated`}</Trigger>
		</TooltipV2>
	);
};

const Content = ({ associatedApns }: { associatedApns: AssociatedApn[] }) => {
	return (
		<ContentList>
			{associatedApns.map((associatedApn) => (
				<li key={associatedApn.apn}>{associatedApn.apn}</li>
			))}
		</ContentList>
	);
};

const Trigger = styled.span`
	color: ${({ theme }) => theme.colors.blue.blue500};
	cursor: context-menu;
`;

const ContentList = styled.ul`
	max-height: 25vh;
	overflow: auto;
	list-style-position: inside;
	list-style-type: disc;
`;
