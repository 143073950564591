export const MAX_COMP_UPLOAD_FILE_SIZE_MB = 100;
export const COMP_FILE_TOO_LARGE_ERROR_REASON =
	'Comp file is too large to upload.';

export function uploadCompFile({
	file,
	addEventListeners,
}: {
	file: File;
	addEventListeners: (req: XMLHttpRequest) => void;
}) {
	const sizeInMb = file.size / (1024 * 1024);
	if (sizeInMb > MAX_COMP_UPLOAD_FILE_SIZE_MB) {
		throw Error(COMP_FILE_TOO_LARGE_ERROR_REASON, {
			cause: { file, reason: COMP_FILE_TOO_LARGE_ERROR_REASON },
		});
	}

	// Adding id to the file object was already here before I refactored this (Matija).
	(file as File & { id?: string }).id = Math.random().toString();

	const formData = new FormData();
	formData.append('file', file);

	const req = new XMLHttpRequest();

	req.open('POST', '/api/comps/actions/upload', true);
	try {
		req.responseType = 'json';
	} catch (error) {
		console.error(
			"browser doesn't support json responseType for XMLHttpRequest"
		);
	}

	addEventListeners(req);

	req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
	req.setRequestHeader('Cache-Control', 'no-cache');
	req.send(formData);
}
