import { Spinner } from '@compstak/ui-kit';
import {
	useMufaFloorPlansQuery,
	useMufaKeyMetricsQuery,
	useMufaUnitMixSummaryQuery,
} from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { TabContainer } from '../UI';
import { FloorPlans } from './FloorPlans';
import { KeyMetrics } from './KeyMetrics';
import { Summary } from './Summary';

export const MultifamilyTab = () => {
	const params = routes.propertyById.useParams();
	const { isLoading: isLoadingSummary } = useMufaUnitMixSummaryQuery({
		propertyId: params.propertyId,
	});
	const { isLoading: isLoadingMetrics } = useMufaKeyMetricsQuery({
		propertyId: params.propertyId,
	});
	const { isLoading: isLoadingFloorPlans } = useMufaFloorPlansQuery({
		propertyId: params.propertyId,
	});

	const isLoading = isLoadingSummary || isLoadingMetrics || isLoadingFloorPlans;

	return (
		<StyledTabContainer>
			{isLoading ? (
				<Spinner isCentered />
			) : (
				<>
					<Summary />
					<KeyMetrics />
					<FloorPlans />
				</>
			)}
		</StyledTabContainer>
	);
};

const StyledTabContainer = styled(TabContainer)`
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
`;
