import React from 'react';

import { FilterCategory } from './FilterCategory';
import { SpaceTypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceTypeFilter';
import { SpaceSubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceSubtypeFilter';
import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { ExecutionDateFilter } from 'Components/Filters/Fields/ExecutionDateFilter/ExecutionDateFilter';

const inputId = 'lease-commonlyUsedFilters' as const;

export const CommonlyUsedLeaseFilters = () => {
	return (
		<FilterCategory category="Commonly Used Filters">
			<SpaceTypeFilter inputId={inputId}>
				<SpaceSubtypeFilter inputId={inputId} />
			</SpaceTypeFilter>

			<ExecutionDateFilter inputId={inputId} />
			<TransactionSizeFilter inputId={inputId} />

			<BuildingClassFilter inputId={inputId} />
			<BuildingSizeFilter inputId={inputId} />
		</FilterCategory>
	);
};
