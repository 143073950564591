import React from 'react';

import styles from '../../repository.less';

import { DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { getAttributesHashForDatasetType } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { MUFA_OPTION } from 'Pages/Analytics/Builder/Components/Modals/DataSets/CreateNewDataSetV2/spaceTypeItems';

type Props = {
	dataSet: DataSet;
	idx: number;
	spaceTypeMap: {
		id: number;
		name: string;
	}[];
};

const DataSetRow: React.FC<Props> = ({ dataSet, idx, spaceTypeMap }) => {
	const attributeHash = getAttributesHashForDatasetType(dataSet.type);

	const submarkets = dataSet.filters.submarkets?.map(({ name }) => name).join(', ');
	const spaceTypeContent = (() => {
		if (dataSet.type === DataSetType.MUFA) {
			return MUFA_OPTION.title;
		}
		const spaceTypes = dataSet.filters.spaceTypeId;
		if (!spaceTypes) {
			return '';
		}
		// When a user chooses "Other" as a space type, it technically includes
		// "Other" and "Unknown"; we only want them to see this as one space type
		// (the id for "Unknown" is -1)
		const spaceTypesWithoutUnknown = spaceTypes.filter((id) => id >= 0);
		if (spaceTypesWithoutUnknown.length > 1) {
			return `${spaceTypesWithoutUnknown.length} Space Types`;
		} else {
			const spaceTypeId = spaceTypesWithoutUnknown[0];
			return spaceTypeMap.find((s) => s.id === spaceTypeId)?.name;
		}
	})();
	return (
		<tr className={styles.dataSetRow}>
			<td className={styles.dataSetNumber}>
				<div>{idx + 1}</div>
			</td>
			<td className={styles.dataSetTitle}>
				<div>{dataSet.name}</div>
			</td>
			<td className={styles.attribute}>
				{/* @ts-expect-error TS7015: Element implicitly has an 'any... */}
				<div>{attributeHash?.[dataSet.series]?.displayName}</div>
			</td>
			{dataSet.filters.market && (
				<>
					<td className={styles.market}>
						<div>{dataSet.filters.market.name}</div>
					</td>
					<td className={styles.submarkets}>
						<div>{submarkets}</div>
					</td>
				</>
			)}
			<td className={styles.spaceType}>
				<div>{spaceTypeContent}</div>
			</td>
		</tr>
	);
};

type AnimatedDataSetRows = {
	dataSets: DataSet[];
	toggled: boolean;
	spaceTypeMap: {
		id: number;
		name: string;
	}[];
};

export const AnimatedDataSetRows: React.FC<AnimatedDataSetRows> = (props) => {
	const [offsetHeight, setOffsetHeight] = React.useState<number>(0);
	// @ts-expect-error TS7006: Parameter 'el' implicitly has ...
	const expand = (el) => {
		const offset = el ? el.offsetHeight : 0;
		setOffsetHeight(offset);
	};

	const dataSetRows = props.dataSets.map((dataSet, idx) => {
		return (
			<DataSetRow
				dataSet={dataSet}
				spaceTypeMap={props.spaceTypeMap}
				idx={idx}
				key={idx}
			/>
		);
	});

	const elHeight = props.toggled ? offsetHeight : 0;

	return (
		<div
			className={styles.animationContainer}
			style={{
				height: elHeight + 'px',
				overflow: 'hidden',
			}}
		>
			<table ref={expand} className={styles.dataSets}>
				<thead>
					<tr className={styles.dataSetHeader}>
						<th className={styles.dataSetNumber}>
							<div>No.</div>
						</th>
						<th className={styles.dataSetTitle}>
							<div>Data Set Title</div>
						</th>
						<th className={styles.attribute}>
							<div>Attribute</div>
						</th>
						<th className={styles.market}>
							<div>Market</div>
						</th>
						<th className={styles.submarkets}>
							<div>Submarket</div>
						</th>
						<th className={styles.spaceType}>
							<div>Space Type</div>
						</th>
					</tr>
				</thead>
				<tbody>{dataSetRows}</tbody>
			</table>
		</div>
	);
};
