import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
	useTrendLineDashboard,
	UseTrendLineDashboardOptions,
} from 'api/trendLineDashboard/useTrendLineDashboard';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

const EXCHANGE_DASHBOARD_STARTING_RENT_MOVING_AVERAGE =
	'EXCHANGE_DASHBOARD_STARTING_RENT_MOVING_AVERAGE';

export type MovingAverage = 3 | 6 | 12;

export type StartingRentData = {
	date: dayjs.Dayjs;
	value: number;
}[];

export const useExchangeDashboardStartingRent = (
	options?: UseTrendLineDashboardOptions
) => {
	const queryClient = useQueryClient();

	const { data: movingAverage } = useQuery({
		queryKey: [EXCHANGE_DASHBOARD_STARTING_RENT_MOVING_AVERAGE],
		initialData: 3 as MovingAverage,
		queryFn: () =>
			queryClient.getQueryData<MovingAverage>([
				EXCHANGE_DASHBOARD_STARTING_RENT_MOVING_AVERAGE,
			]) ?? 3,
	});

	const setMovingAverage = useCallback(
		(newTimeline: MovingAverage) => {
			queryClient.setQueryData(
				[EXCHANGE_DASHBOARD_STARTING_RENT_MOVING_AVERAGE],
				newTimeline
			);
		},
		[queryClient]
	);

	const [filters] = useFilters('exchange-dashboard');
	const monthly = filters.market.monthly;

	const { data, isFetching } = useTrendLineDashboard(
		{
			filters,
			trendMonths: movingAverage,
		},
		options
	);

	const startingRentData: StartingRentData | undefined = useMemo(() => {
		const currentYear = new Date().getFullYear();

		return data
			?.map(({ date, value }) => ({
				date: dayjs(date),
				value: monthly ? value / 12 : value,
			}))
			.filter(({ date }) => date.isSameOrAfter(`${currentYear - 9}-01-01`));
	}, [data, monthly]);

	return {
		startingRentData,
		isFetching,
		movingAverage,
		setMovingAverage,
		monthly,
		type: 'startingRent' as const,
	};
};
