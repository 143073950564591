import {
	PropertyInfoPropertyType,
	usePropertyByIdQuery,
	usePropertyInfoQuery,
} from 'api';
import { useIsMultifamily } from 'hooks';

type Props = {
	propertyId: number | undefined;
};

export const useCompsetType = ({ propertyId }: Props) => {
	const {
		data: info,
		isFetching: isFetchingInfo,
		error,
	} = usePropertyInfoQuery({
		propertyId,
	});

	const isInfo404 = error?.response?.status === 404;

	const { data: property, isFetching: isFetchingProperty } =
		usePropertyByIdQuery({ id: propertyId });

	const isMultifamily = useIsMultifamily({
		markets: property && [property.marketId],
	});

	return {
		data: (() => {
			if (isInfo404) return CompsetType.COMMERCIAL;

			if (!info) return undefined;

			if (
				isMultifamily &&
				(info.propertyType === PropertyInfoPropertyType.MULTIFAMILY ||
					info.propertyType === PropertyInfoPropertyType.MIXED_USE) &&
				info.hasRpCompSetData
			) {
				return CompsetType.REALPAGE;
			}
			return CompsetType.COMMERCIAL;
		})(),
		isFetching: isFetchingInfo || isFetchingProperty,
	};
};

export enum CompsetType {
	COMMERCIAL = 'COMMERCIAL',
	REALPAGE = 'REALPAGE',
}
