import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { ExportCompParams, ExportCompResponse } from '../types';

const exportProperties = async (params: ExportCompParams) => {
	const res = await API.post<ExportCompResponse>(
		`/api/properties/export`,
		params
	);
	return res.data;
};

export const useExportPropertiesMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: exportProperties,
		onSuccess: () => {
			queryClient.invalidateQueries([
				QUERY_KEYS.CHECK_PROPERTIES_EXPORT_STATUS,
			]);
		},
	});
};
