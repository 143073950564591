import factory from './factory';

const service = factory.create({
	// @ts-expect-error TS2322: Type '(data: unknown) => strin...
	createUrl: (data) => {
		// @ts-expect-error TS18046: 'data' is of type 'unknown'....
		switch (data.compType) {
			case 'lease':
				return '/api/comps/search/spaceTypeFilterAggregations';
			case 'sale':
				return '/api/salesComps/search/spaceTypeFilterAggregations';
			case 'property':
				const url =
					'/api' +
					// @ts-expect-error TS18046: 'data' is of type 'unknown'....
					(data.useMultifamily ? '/mufa' : '') +
					'/properties/search/spaceTypeFilterAggregations';
				return url;
		}
	},
	// @ts-expect-error TS18046: 'data' is of type 'unknown'....
	createData: (data) => ({ filter: data.filters }),
	createKey: (identifier) => JSON.stringify(identifier),
});

const exportable = service.exportable({});

export default exportable;
