import { memo } from 'react';
import styled, { css } from 'styled-components';

type SidebarNavigationButtonsProps = {
	onClickChartbuilder?: () => void;
	onClickProjects?: () => void;
};

const SidebarNavigationButtons = memo<SidebarNavigationButtonsProps>(
	({ onClickChartbuilder, onClickProjects }) => {
		return (
			<SidebarNavigationContainer>
				<SidebarNavigationButton
					selected={!onClickChartbuilder}
					onClick={onClickChartbuilder}
				>
					Chartbuilder
				</SidebarNavigationButton>
				<SidebarNavigationButton
					selected={!onClickProjects}
					onClick={onClickProjects}
				>
					Projects
				</SidebarNavigationButton>
			</SidebarNavigationContainer>
		);
	}
);

SidebarNavigationButtons.displayName = 'SidebarNavigationButtons';

export default SidebarNavigationButtons;

export const SidebarNavigationContainer = styled.div`
	width: 100%;
	color: ${({ theme }) => theme.colors.white.white};
	text-align: center;
	font-style: normal;
	font-size: 12px;
	line-height: 16px;
	padding-right: 2px;
	font-weight: 500;
`;
export const SidebarNavigationButton = styled.div<{ selected: boolean }>`
	display: inline-block;
	width: 50%;
	padding: 16px 0;
	${(prop) =>
		prop.selected
			? css`
					border-bottom: 2px solid ${({ theme }) => theme.colors.white.white};
					font-weight: 400;
					text-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';
					cursor: default;
				`
			: css`
					font-weight: 325;
					cursor: pointer;
				`}

	&:hover {
		background: ${({ theme }) => theme.colors.neutral.n400};
		border-radius: 3px 3px 0px 0px;
	}
`;
