import { Portfolio, useAllPortfoliosQuery } from 'api';
import { useEffect, useMemo, useState } from 'react';
import { PortfolioFormErrorsState, PortfolioFormState } from './types';

export type UsePortfolioFormProps = {
	initialFormState?: PortfolioFormState;
	editedPortfolio?: Portfolio;
};

/** Portfolio form state and validation */
export const usePortfolioForm = ({
	initialFormState = {
		name: '',
		description: '',
		checkboxes: undefined,
	},
	editedPortfolio,
}: UsePortfolioFormProps) => {
	const [state, setState] = useState<PortfolioFormState>(initialFormState);
	const [errors, setErrors] = useState<PortfolioFormErrorsState>({});
	const [isTouched, setIsTouched] = useState(false);

	const isEdit = !!editedPortfolio;

	const { data: allPortfolios, isFetching: isFetchingAllPortfolios } =
		useAllPortfoliosQuery();

	const trimmedState = useMemo(() => {
		return {
			name: state.name.trim(),
			description: state.description.trim(),
		};
	}, [state]);

	useEffect(() => {
		if (!allPortfolios) return;

		const newErrors: PortfolioFormErrorsState = {};

		// name validation
		if (trimmedState.name === '') {
			newErrors.name = 'Name is empty.';
		} else if (trimmedState.name.length > MAX_NAME_DESC_LENGTH) {
			newErrors.name = 'Name is too long.';
		} else {
			const isNameUnique = !allPortfolios.find((p) => {
				if (isEdit && editedPortfolio.name === p.name) return false;
				return p.name === trimmedState.name;
			});
			if (!isNameUnique) {
				newErrors.name = 'There is already a portfolio with this name.';
			}
		}
		// description validation
		if (trimmedState.description.length > MAX_NAME_DESC_LENGTH) {
			newErrors.description = 'Description is too long.';
		}

		setErrors(newErrors);
	}, [isTouched, trimmedState, allPortfolios, isEdit, editedPortfolio]);

	const isValid = useMemo(() => {
		return Object.keys(errors).length === 0;
	}, [errors]);

	return {
		state,
		setState,
		errors,
		setErrors,
		isTouched,
		setIsTouched,
		isValid,
		trimmedState,
		isFormLoading: isFetchingAllPortfolios,
	};
};

const MAX_NAME_DESC_LENGTH = 255;
