import { useAnalyticsMarketRent } from '@compstak/fe-react-api-layer';
import { defaultContext } from '@tanstack/react-query';
import { createFiltersParamWithMarketId } from 'api';
import {
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsViewMode,
	useMarketRentAnalyticsState,
} from 'maps';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

export const useMarketRentAnalyticsData = () => {
	const [filters] = useFilters();

	const [state] = useMarketRentAnalyticsState();

	const filterSpaceType = filters?.spaceTypeId;

	const spaceType =
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		SPACE_TYPE_MAP[filterSpaceType?.[0] ?? DEFAULT_SPACE_TYPE_ID];

	const filtersParam = useMemo(() => {
		const serverFilters = createFiltersParamWithMarketId(filters);
		return serverFilters.filter((f) => f.property !== 'spaceTypeId');
	}, [filters]);

	return useAnalyticsMarketRent(
		{
			metric: state.rentType,
			spaceType,
			monthly: state.period === MarketRentAnalyticsPeriod.MONTH,
			submarket: state.viewMode === MarketRentAnalyticsViewMode.SUBMARKET,
			body: {
				filters: filtersParam,
			},
		},
		{
			context: defaultContext,
		}
	);
};

const DEFAULT_SPACE_TYPE_ID = 1;

const SPACE_TYPE_MAP = {
	1: 'Office',
	6: 'Industrial',
} as const;
