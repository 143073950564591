import { FiltersObject } from 'models/filters/types';
import { CompType } from 'types';
import { useLeaseRecommendedSearches } from './useLeaseRecommendedSearches';
import { ReactElement, ReactNode, SVGProps, useMemo } from 'react';

export type RecommendedSearch = {
	title: ReactNode;
	tooltip?: string;
	filters: Partial<FiltersObject>;
	filtersText: string;
	icon: (props: SVGProps<SVGElement>) => ReactElement | null;
	compType?: CompType;
};

export const useRecommendedSearches = (): Record<
	CompType,
	RecommendedSearch[]
> => {
	const leaseRecommendedSearches = useLeaseRecommendedSearches();

	return useMemo(
		() => ({
			lease: leaseRecommendedSearches,
			sale: [] as RecommendedSearch[],
			property: [] as RecommendedSearch[],
		}),
		[leaseRecommendedSearches]
	);
};
