import { SortDirection } from 'api';
import { useMemo } from 'react';
import { SortField } from './types';
import {
	PortfolioShortWithAggregations,
	usePortfoliosWithAggregations,
} from './usePortfoliosWithAggregations';

type Props = {
	portfoliosWithAggregates: PortfolioShortWithAggregations[];
	sortField: SortField;
	sortDirection: SortDirection;
};

export const useSortedPortfolios = ({
	portfoliosWithAggregates,
	sortField,
	sortDirection,
}: Props): ReturnType<typeof usePortfoliosWithAggregations> => {
	const sortedPortfolios = useMemo(() => {
		const sortedAsc = portfoliosWithAggregates.toSorted(SORT_MAP[sortField]);

		return sortDirection === 'desc' ? sortedAsc.reverse() : sortedAsc;
	}, [portfoliosWithAggregates, sortField, sortDirection]);

	return sortedPortfolios;
};

const sortByName: SortFunction = (p1, p2) => {
	return p1.name.localeCompare(p2.name);
};

const sortByDateCreated: SortFunction = (p1, p2) =>
	new Date(p1.dateCreated).valueOf() - new Date(p2.dateCreated).valueOf();

const sortByLastUpdated: SortFunction = (p1, p2) =>
	new Date(p1.lastUpdated ?? p1.dateCreated).valueOf() -
	new Date(p2.lastUpdated ?? p2.dateCreated).valueOf();

const sortByPropertyCount: SortFunction = (p1, p2) =>
	p1.propertyCount - p2.propertyCount;

const SORT_MAP: Record<SortField, SortFunction> = {
	name: sortByName,
	dateCreated: sortByDateCreated,
	lastUpdated: sortByLastUpdated,
	propertyCount: sortByPropertyCount,
};

type SortFunction = (
	portfolio1: PortfolioShortWithAggregations,
	portfolio2: PortfolioShortWithAggregations
) => number;
