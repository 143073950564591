import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';

const TOP_SELLERS_DEFAULT_LIMIT = 10;

export const useTopSellersQuery = (
	params: UseTopSellersParams,
	options?: UseTopSellersOptions
) => {
	const { filters, limit = TOP_SELLERS_DEFAULT_LIMIT } = params;
	const filter = filtersToServerJSON(filters);

	return useQuery({
		queryKey: [QUERY_KEYS.TOP_SELLERS, filter, limit],
		queryFn: async () => {
			const response = await API.post<TopSellersResponse>(
				'/api/salesComps/topSellers',
				{
					filter,
					limit,
				}
			);

			return response.data;
		},
		...options,
	});
};

type UseTopSellersOptions = UseQueryOptions<TopSellersResponse>;

type UseTopSellersParams = { filters: Partial<FiltersObject>; limit?: number };

export type Seller = {
	name: string;
	sumOfSalesPrice: number;
	totalSalesCount: number;
	totalPropertiesCount: number;
	averageDealSize: number;
	globalSumOfSalesPrice: number;
	globalTotalSalesCount: number;
	officePropertiesCount?: number;
	industrialPropertiesCount?: number;
	retailPropertiesCount?: number;
	otherPropertiesCount?: number;
};

export type TopSellersResponse = {
	sellers: Seller[];
};
