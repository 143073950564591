import { Button, TooltipV2 } from '@compstak/ui-kit';
import { BlueLink, CompsetMap, CompsetTable } from 'Components';
import { CompsetType, useCompsetType } from 'api';
import { useState } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import IconHelp from 'ui/svg_icons/help.svg';
import { RealPage } from '../RealPage';
import {
	TabContentHeader,
	TabContentHeaderRightContainer,
	TabContentTitle,
	TabContentTitleContainer,
} from '../UI';
import { AddCompsetPropertyDialog } from './AddCompsetPropertyDialog';
import { CompsetActionsButton } from './CompsetActionsButton';
import { CONTAINER_ID, CompsetMenu } from './CompsetMenu';
import { CompsetModified } from './CompsetModified';
import { useCompsetLeasesUrl } from './useCompsetLeasesUrl';

export const Compset = () => {
	const [isAddCompPropertyDialogOpen, setIsAddCompPropertyDialogOpen] =
		useState(false);
	const params = routes.propertyById.useParams();
	const { data: compsetType } = useCompsetType({
		propertyId: params.propertyId,
	});

	const { data: compsetLeasesUrl } = useCompsetLeasesUrl({
		propertyId: params.propertyId,
	});

	return (
		<>
			<TabContentHeader>
				<TabContentTitleContainer>
					<TabContentTitle>Competitive Set</TabContentTitle>
					<TooltipV2 content="CompStak’s automatically generated competitive set based on nearby, similar properties.">
						<span>
							<IconHelp width={16} />
						</span>
					</TooltipV2>
				</TabContentTitleContainer>
				<TabContentHeaderRightContainer>
					<BlueLink to={compsetLeasesUrl} data-qa-id="see-all-leases-link">
						See all leases in this set
					</BlueLink>
					<CompsetMenu />
				</TabContentHeaderRightContainer>
			</TabContentHeader>
			<div id={CONTAINER_ID}>
				<div style={{ height: 350 }}>
					<CompsetMap propertyId={params.propertyId} />
				</div>
				<CompsetTable propertyId={params.propertyId} deleteColumn />
				<BottomContainer>
					<Button
						variant="primary"
						onClick={() => {
							setIsAddCompPropertyDialogOpen(true);
						}}
					>
						+ Add Comp Property
					</Button>
					<CompsetActionsButton />
					<CompsetModified />
					{compsetType === CompsetType.REALPAGE && (
						<RealPage style={{ marginLeft: 'auto' }} />
					)}
				</BottomContainer>
			</div>
			{isAddCompPropertyDialogOpen && (
				<AddCompsetPropertyDialog
					closeDialog={() => setIsAddCompPropertyDialogOpen(false)}
				/>
			)}
		</>
	);
};

const BottomContainer = styled.div`
	padding-top: 1rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;
