import React from 'react';

import { PlacedDragSource } from './DragSource';
import { HorizontalSortDropTarget } from './DropTargets';

import SpaceMenu from '../Menus/Space';
import ThreeDot from '../../../ui/svg_icons/three-dot.svg';
import styled from 'styled-components';
import styles from '../stacking.less';
import {
	getSpaceClassName,
	getTenant,
	getSize,
	getExpirationQuarter,
} from '../util';

export default PlacedDragSource(
	HorizontalSortDropTarget(
		class SpaceOnFloor extends React.PureComponent {
			// @ts-expect-error TS7006: Parameter 'event' implicitly h...
			showMenu = (event) => {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
				this.props.menuActions.showMenu(
					SpaceMenu,
					event.target,
					'onleft',
					this.props
				);
			};

			subleaseOrNothing() {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
				if (this.props.space.sublessor) {
					return (
						<span
							className={styles.subleaseDetails}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
							data-tooltip={`Subleased from ${this.props.space.sublessor}`}
						>
							S
						</span>
					);
				}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
				if (this.props.space.sublease) {
					return (
						<span className={styles.subleaseDetails} data-tooltip={`Sublease`}>
							S
						</span>
					);
				}
				return null;
			}

			render() {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
				const { space } = this.props;

				let sizeStyle;

				sizeStyle = {
					// width: this.props.width + '%',
					position: 'absolute',
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'floorIndex' does not exist on type 'Read... Remove this comment to see the full error message
					top: `${(this.props.floorIndex * 100).toFixed(4)}%`,
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'positionLeft' does not exist on type 'Re... Remove this comment to see the full error message
					left: `${this.props.positionLeft.toFixed(4)}%`,
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'positionLeft' does not exist on type 'Re... Remove this comment to see the full error message
					right: `${(100 - this.props.positionLeft - this.props.width).toFixed(
						4
					)}%`,
				};

				const expirationQuarter = getExpirationQuarter(space);

				let className = styles.varWidthWrapper;

				// @ts-expect-error ts-migrate(2339) FIXME: Property 'isDragging' does not exist on type 'Read... Remove this comment to see the full error message
				if (this.props.isDragging) {
					className += ' ' + styles.dragging;
				}

				let hideStyle;
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
				if (this.props.space.occupancy.toLowerCase() === 'unrentable') {
					hideStyle = {
						display: 'none',
					};
				}

				let unrentableTextStyle;
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
				if (getTenant(this.props.space).toLowerCase() === 'unrentable') {
					unrentableTextStyle = {
						textTransform: 'uppercase',
						opacity: '0.5',
					};
				}

				// @ts-expect-error ts-migrate(2339) FIXME: Property 'connectDragSource' does not exist on typ... Remove this comment to see the full error message
				const { connectDragSource, connectDropTarget } = this.props;

				return connectDropTarget(
					connectDragSource(
						// @ts-expect-error TS2322: Type '{ position: string; top:...
						<div style={sizeStyle} className={className}>
							<div
								className={
									styles[getSpaceClassName(space)] +
									' ' +
									styles.floorCellVarWidth
								}
							>
								<div
									// @ts-expect-error TS2322: Type '{ textTransform: string;...
									style={unrentableTextStyle}
									className={styles.floorCellTenantName}
								>
									{getTenant(space)} {this.subleaseOrNothing()}
								</div>
								<div className={styles.floorCellTenantProps}>
									<div className={styles.floorCellKey}>Size</div>
									<div className={styles.floorCellValue}>
										{getSize(space)} SF
									</div>
									<div style={hideStyle} className={styles.floorCellKey}>
										Exp
									</div>
									<div style={hideStyle} className={styles.floorCellValue}>
										{expirationQuarter}
									</div>
								</div>
								<div
									className={styles.threeDotMenu}
									data-tooltip="Tenant Menu"
									data-tooltipdelay="250"
									onClick={this.showMenu}
								>
									<StyledThreeDot />
								</div>
							</div>
						</div>
					)
				);
			}
		}
	)
);

const StyledThreeDot = styled(ThreeDot)`
	width: 4px;
	height: 16px;
	margin: 0 auto;
`;
