import styled from 'styled-components';

const TRIANGLE_SIZE = 5;

export const FilterDescription = styled.div`
	position: relative;
	display: flex;
	padding: 10px 15px;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 2px;
	background: ${({ theme }) => theme.colors.gray.gray700};
	font-family: 'OpenSans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.1px;
	margin-top: calc(8px + ${TRIANGLE_SIZE}px);
	margin-bottom: 8px;

	&::before {
		content: '';
		position: absolute;
		top: -${TRIANGLE_SIZE}px;
		left: 20px;
		border-left: ${TRIANGLE_SIZE}px solid transparent;
		border-right: ${TRIANGLE_SIZE}px solid transparent;
		border-bottom: ${TRIANGLE_SIZE}px solid
			${({ theme }) => theme.colors.gray.gray700};
	}
`;
