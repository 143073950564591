import React from 'react';

import Menu from 'Singletons/Menu';

import styles from '../stacking.less';
import popupStyles from 'Components/Popup/popup.less';

export class FloorMenu extends React.PureComponent {
	add = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.openSpaceEditor({
			id: null,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			floor: this.props.floor.label,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'vacantSpace' does not exist on type 'Rea... Remove this comment to see the full error message
			size: this.props.vacantSpace,
		});
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	edit = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.openFloorEditor(this.props.floor);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	addAbove = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.insertFloorAbove(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			this.props.floor
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	addBelow = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.insertFloorBelow(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			this.props.floor
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	remove = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.removeFloor(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.stackingPlan,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			this.props.floor
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	};

	render() {
		return (
			<ul className={styles.menuContent}>
				<li onClick={this.add}>Add (Space)</li>
				<li onClick={this.edit}>Edit</li>
				<li onClick={this.addAbove}>Insert floor above</li>
				<li onClick={this.addBelow}>Insert floor below</li>
				<li onClick={this.remove}>Remove floor</li>
			</ul>
		);
	}
}

Menu.addComponent('stacking-plan-floor-menu', FloorMenu, {
	className: popupStyles.compactWhite,
});

export default 'stacking-plan-floor-menu';
