import Between from 'Components/Filters/Base/Between';
import FallbackSwitcher from 'Components/Filters/Base/FallbackSwitcher';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import React from 'react';
import styled from 'styled-components';

const attribute = 'startingRent' as const;

export const StartingRentFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => {
				return (
					<>
						<Between
							attribute={attribute}
							filter={props.filter}
							onFilterChange={props.onFilterChange}
							monthly={shouldUseMonthlyMetrics(props.filters)}
							touch={props.touch}
							setError={props.setError}
						/>
						<Container>
							<FallbackSwitcher
								// @ts-expect-error ts-migrate(2769) FIXME: Property 'options' does not exist on type 'Intrins... Remove this comment to see the full error message
								attribute={attribute}
								filter={props.filter}
								onFilterChange={props.onFilterChange}
								touch={props.touch}
								setError={props.setError}
								options={[
									{
										name: 'Use any rent if starting rent is missing',
										value: true,
									},
									{
										name: 'Use only starting rent',
										value: false,
									},
								]}
							/>
						</Container>
					</>
				);
			}}
		</FilterBase>
	);
};

const Container = styled.div`
	margin-top: 7px;
`;
