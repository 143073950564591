import { MultiselectListItem } from '@compstak/ui-kit';
import {
	PROPERTY_TYPE_ID_TO_NAME,
	PROPERTY_TYPE_NAME_TO_ID,
	PropertyTypeId,
} from 'api';

export const SALE_PROPERTY_TYPE_ITEMS: MultiselectListItem[] = [
	{
		title: 'All',
		value: 'all',
	},
	...Object.typedKeys(PROPERTY_TYPE_ID_TO_NAME).map((id) => ({
		title: PROPERTY_TYPE_ID_TO_NAME[id],
		value: `${id}`,
	})),
];

export const MUFA_PROPERTY_TYPE_ITEMS: MultiselectListItem[] = [
	{
		value: `${PROPERTY_TYPE_NAME_TO_ID['Multi-Family']}`,
		title: PROPERTY_TYPE_ID_TO_NAME[2],
	},
];

export const getSalePropertyTypeItemById = (id: PropertyTypeId) => {
	return SALE_PROPERTY_TYPE_ITEMS.find((item) => item.value === String(id));
};

export const getPropertyTypeIdsFromItems = (items: MultiselectListItem[]) => {
	return items.map((item) => {
		return Number(item.value) as PropertyTypeId;
	});
};
