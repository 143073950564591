import { CompstakContactInfo } from '@compstak/common';
import { ExchangePanel } from '../../UI';
import button from 'ui/styles/button.less';
import Gift from '../../../ui/svg_icons/gift.svg';
import React from 'react';
import { ReferColleguesModal } from './ReferColleguesModal';
import Rocket from '../../../ui/svg_icons/rocket.svg';
import styled from 'styled-components';
import { TeamInfoRequestModal } from './TeamInfoRequestModal';
import { useModal } from 'providers/ModalProvider';

type Props = { contactInfo: CompstakContactInfo | null };

const Promotions = (props: Props) => {
	return (
		<ExchangePanel id="promotions" title="Promotions">
			<PromotionItems {...props} />
		</ExchangePanel>
	);
};

const PromotionItems = (props: Props) => {
	const { openModal } = useModal();

	return (
		<PromotionsContainer>
			<div className="promotion-item">
				<PromotionsIconContainer>
					<Gift />
				</PromotionsIconContainer>
				<div>
					<h3>Refer Your Colleague</h3>
					<div className="promotion-description">
						Introduce a colleague to CompStak and earn bonus credits
					</div>
				</div>
				<button
					className={button.blue}
					data-qa-id="promotion-refer-button"
					onClick={() => openModal({ modalContent: <ReferColleguesModal /> })}
				>
					Refer your colleague
				</button>
			</div>
			<div className="promotion-item">
				<PromotionsIconContainer>
					<Rocket />
				</PromotionsIconContainer>
				<div>
					<h3>Launch Your Team</h3>
					<div className="promotion-description">
						Learn how you can share comps and credits with your colleagues
					</div>
				</div>
				<button
					className={button.blue}
					data-qa-id="promotion-launch-team-button"
					onClick={() =>
						openModal({
							modalContent: (
								<TeamInfoRequestModal contactInfo={props.contactInfo} />
							),
						})
					}
				>
					Launch Your Team
				</button>
			</div>
		</PromotionsContainer>
	);
};

export default Promotions;

const PromotionsIconContainer = styled.div`
	svg {
		width: 88px;
		height: 88px;
	}
`;

const PromotionsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	min-height: 280px;
	padding: 0 0 1rem;

	.promotion-item {
		align-items: center;
		background: #f9f9f9;
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 70px;
		padding: 1rem;
		text-align: center;
		width: 296px;

		h3 {
			font-size: 14px;
			font-style: normal;
			font-weight: normal;
			line-height: 19px;
			margin-bottom: 10px;
		}

		.promotion-description {
			color: rgba(0, 0, 0, 0.87);
			font-size: 12px;
			font-style: normal;
			font-weight: normal;
			line-height: 19px;
			max-width: 194px;
			text-align: left;
		}
		button,
		a {
			font-size: 11px;
			width: min-content;
		}
	}
`;
