import React, { useEffect } from 'react';
// @ts-expect-error TS7016: Could not find a declaration f...
import isMobile from 'is-mobile';
import { useModal } from 'providers/ModalProvider';
import { MobileIncompatibilityModal } from 'Components/Modals/MobileIncompatibilityModal/MobileIncompatibilityModal';

import LoginForm from './Components/LoginForm';
import { LoginLayoutWrapper } from './Components/LoginLayoutComponents';
import { ForgotPasswordForm } from './Components/ForgotPassword';

import { useAppSelector } from 'util/useAppSelector';
import { TrialExpired } from 'Pages/TrialExpired/TrialExpired';
import { useLocation } from 'react-router';

export const LoginLayout = () => {
	const location = useLocation();
	const authState = useAppSelector((state) => state.authState);
	const { openModal } = useModal();

	useEffect(() => {
		if (isMobile()) {
			openModal({
				modalContent: <MobileIncompatibilityModal />,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (location.pathname === '/forgotPassword') {
		return (
			<LoginLayoutWrapper transitionKey={'forget-password'}>
				<ForgotPasswordForm authState={authState} />
			</LoginLayoutWrapper>
		);
	}

	if (authState.trialExpired) {
		return <TrialExpired authState={authState} />;
	}

	return <LoginForm />;
};
