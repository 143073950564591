import React, { useState, useRef } from 'react';
import { useClearbitCompanySuggestions } from 'api/suggestions/clearbit/useClearbitCompanySuggestions';
import { trimString } from 'util/trimString';
import { DefaultLogo } from './DefaultLogo';
import styled from 'styled-components';
import { STATIC_COMPANY_LOGOS } from './staticCompanyLogos';

type CompanyLogoProps = {
	companyName: string;
};

export const CompanyLogo = ({ companyName }: CompanyLogoProps) => {
	const [hasError, setHasError] = useState(false);
	const printImgRef = useRef<HTMLImageElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);

	companyName = trimString(companyName);

	let companyLogoUrl = STATIC_COMPANY_LOGOS[companyName];

	const { data: companySuggestions } = useClearbitCompanySuggestions(
		{ query: companyName },
		{ enabled: !companyLogoUrl && !!companyName }
	);

	if (!companyName) {
		return null;
	}

	companyLogoUrl =
		companyLogoUrl ??
		companySuggestions?.find(
			(companySuggestion) =>
				companySuggestion.name
					.toLowerCase()
					.includes(companyName.toLowerCase()) ||
				companyName.toLowerCase().includes(companySuggestion.name.toLowerCase())
		)?.logo;

	if (
		!companyLogoUrl &&
		companySuggestions?.length === 0 &&
		window._env_.REACT_APP_DEPLOYMENT_ENV !== 'prod'
	) {
		console.warn(`No company logo found for "${companyName}" company name`);
	}

	return (
		<LogoWrapper>
			{companyLogoUrl && !hasError ? (
				<>
					<HiddenLogoImage
						ref={imgRef}
						alt={companyName}
						src={companyLogoUrl}
						crossOrigin={'anonymous'}
						onError={() => setHasError(true)}
						onLoad={(e) => {
							const img = e.target as HTMLImageElement;
							const canvas = document.createElement('canvas');
							const ctx = canvas.getContext('2d');

							if (!ctx) {
								console.error('Canvas context is not available');
								return;
							}

							canvas.width = img.naturalWidth;
							canvas.height = img.naturalHeight;
							ctx.drawImage(img, 0, 0);

							if (!printImgRef.current) {
								console.error('Print Image ref is not available');
								return;
							}

							printImgRef.current.src = canvas.toDataURL('image/png');
						}}
					/>
					<LogoImage ref={printImgRef} alt={companyName} />
				</>
			) : (
				<DefaultLogo text={companyName} />
			)}
		</LogoWrapper>
	);
};

const LogoWrapper = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 3px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

const LogoImage = styled.img`
	width: inherit;
	height: inherit;
	border-radius: inherit;
`;

const HiddenLogoImage = styled(LogoImage)`
	display: none;
`;
