import styled from 'styled-components';
import { SubmissionCategory } from './submissionTypes';

export function CategoryPills({
	category,
}: {
	category: SubmissionCategory;
}): JSX.Element {
	const { labels, color, tooltip } =
		categoryMapping[category] ?? DEFAULT_CATEGORY_MAPPING;
	return (
		<>
			{labels.map((label) => (
				<CategoryPill key={label} {...{ color, label, tooltip }} />
			))}
		</>
	);
}

function CategoryPill({
	color,
	label,
	tooltip,
}: {
	color: string;
	label: string;
	tooltip: string;
}) {
	return (
		<PillSpan
			data-placement="bottom"
			data-tooltipdelay="0"
			data-tooltip={tooltip}
			className={'submission-pill ' + color}
		>
			{label}
		</PillSpan>
	);
}

type CategoryMapping = {
	color: 'grey' | 'light-grey' | 'green';
	labels: string[];
	tooltip: string;
};

const categoryMapping: Record<SubmissionCategory, CategoryMapping> = {
	STASHED: {
		color: 'light-grey',
		labels: ['INCOMPATABLE'],
		tooltip: 'This failed to qualify as a lease Comp.',
	},
	PARTIAL: {
		color: 'light-grey',
		labels: ['INCOMPLETE'],
		tooltip:
			'This Comp is missing some key details. Incomplete Comps are worth 1 credit.',
	},
	'PARTIAL-ENRICHMENT': {
		color: 'light-grey',
		labels: ['PARTIAL', 'ENRICHMENT'],
		tooltip:
			'Adding additional attributes that we do not have yet is worth 5 credits even if the comp submitted was partial',
	},
	DUPLICATE: {
		color: 'light-grey',
		labels: ['DUPLICATE'],
		tooltip:
			'This Comp already exists on Compstak. Duplicate Comps are worth 1 credit.',
	},
	OUTDATED: {
		color: 'grey',
		labels: ['OLD'],
		tooltip: 'This Comp is over 12 months old. Old Comps are worth 5 credits.',
	},
	'OUTDATED-ENRICHMENT': {
		color: 'grey',
		labels: ['OUTDATED', 'ENRICHMENT'],
		tooltip:
			'Adding additional premium fields to a lease that is over a year old is worth 5 credits.',
	},
	'OUTDATED-PREMIUM': {
		color: 'grey',
		labels: ['OUTDATED', 'PREMIUM'],
		tooltip:
			'This comp is over 12 months old, but has extra information and is worth 30 credits.',
	},
	BASIC: {
		color: 'green',
		labels: ['RECENT-12-MON'],
		tooltip:
			'This Comp is great. Comps from within the last year are worth 25 credits.',
	},
	'BASIC-ENRICHMENT': {
		color: 'green',
		labels: ['RECENT-12-MON', 'ENRICHMENT'],
		tooltip:
			'Adding additional premium fields to a lease from within the last year is worth 10 credits.',
	},
	PREMIUM: {
		color: 'green',
		labels: ['RECENT-12-MON', 'PREMIUM'],
		tooltip:
			'This comp is not only from within the last year but has extra information. Premium Comps are worth 30 credits.',
	},
	'RECENT-30-DAY': {
		color: 'green',
		labels: ['RECENT-30-DAY'],
		tooltip: 'Comps from within the last 30 days are worth 50 credits.',
	},
	'RECENT-30-DAY-ENRICHMENT': {
		color: 'green',
		labels: ['RECENT-30-DAY', 'ENRICHMENT'],
		tooltip:
			'Adding additional premium fields to a lease from the last 30 days is worth 25 credits.',
	},
	'RECENT-30-DAY-PREMIUM': {
		color: 'green',
		labels: ['RECENT-30-DAY', 'PREMIUM'],
		tooltip:
			'This comp is not only from within the last 30 days but has extra information and is worth 60 credits.',
	},
};

const DEFAULT_CATEGORY_MAPPING = {
	color: 'light-grey',
	labels: ['N/A'],
	tooltip: '',
};

const PillSpan = styled.span`
	padding: 4px 7px 4px 8px;
	margin: -0.2em 4px -0.3125em 0px;
	border-radius: 20px;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	font-size: 11px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-weight: 500;
	line-height: 1.1em;
	display: inline-block;
`;
