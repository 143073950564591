import React from 'react';
import { CompstakContact } from './CompstakContact';
import { SupportGuides } from './SupportGuides';
import { TeamProfile } from './Team/TeamProfile';
import { MemberProfile } from './Member/MemberProfile';
import { TeamStatsPanel } from '../TeamStatsPanel';
import { ExchangeSection } from '../UI';
import styled from 'styled-components';
import { CompstakContactInfo } from '@compstak/common';
import Promotions from './Promotions';
import { Section } from 'ExchangeDashboard/types';

type Props = {
	contactInfo: CompstakContactInfo | null;
	setSectionsInView: React.Dispatch<React.SetStateAction<Section['type'][]>>;
};

const Membership: React.FC<Props> = (props) => {
	return (
		<ExchangeSection
			id="membership"
			title="Membership"
			setSectionsInView={props.setSectionsInView}
		>
			<TeamStatsPanel />
			<Promotions contactInfo={props.contactInfo} />
			<ProfileAndContact>
				<MemberProfile />
				<TeamProfile />
				<CompstakContact {...props} />
			</ProfileAndContact>
			<SupportGuides />
		</ExchangeSection>
	);
};

export default Membership;

const ProfileAndContact = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;

	@media (min-width: 1400px) {
		flex-direction: row;
		flex-wrap: nowrap;

		> *:first-child {
			margin-right: 1rem;
		}
		> *:last-child {
			margin-left: 1rem;
		}
	}
`;
