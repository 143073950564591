import { EditableCircleLayer } from '@compstak/maps';
import {
	FiltersObject,
	RadiusFilter as RadiusFilterType,
} from 'models/filters/types';
import { useMemo } from 'react';
import { createRadiusFeatureFromRadiusFilter } from './createRadiusFeatureFromRadiusFilter';
import { createRadiusFilterFromRadiusFeature } from './createRadiusFilterFromRadiusFeature';
import { RadiusFeature } from './type';

type RadiusFilterProps = {
	radiusFilter?: RadiusFilterType | null;
	setFilters: (changes: Partial<FiltersObject>) => void;
};

export const RadiusFilter = ({
	radiusFilter,
	setFilters,
}: RadiusFilterProps) => {
	const radiusFeature = useMemo(() => {
		if (radiusFilter) {
			return createRadiusFeatureFromRadiusFilter(radiusFilter);
		}
	}, [radiusFilter]);

	return (
		<EditableCircleLayer
			initialCircle={radiusFeature}
			// @ts-expect-error expected wrong feature type
			onUpdate={(f: RadiusFeature) => {
				setFilters({
					radius: {
						...createRadiusFilterFromRadiusFeature(f),
						buildingAddressAndCity: undefined,
					},
				});
			}}
		/>
	);
};
