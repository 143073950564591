import {
	Combobox,
	ComboboxItem,
	ComboboxPopover,
	ComboboxProvider,
} from '@ariakit/react';
import { Button, Spinner } from '@compstak/ui-kit';
import { defaultDropdownContentCss } from 'Components';
import { useClearbitCompanySuggestions } from 'api/suggestions/clearbit/useClearbitCompanySuggestions';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import IconSubtract from 'ui/svg_icons/subtract.svg';
import { useDebounce } from 'use-debounce';
import { IconButton } from '../Pages/PropertyPageV3/pdf/UI';

type Props = {
	value: string | null;
	onChange: (value: string | null) => void;
	text: ReactNode;
	displayValueOnly?: boolean;
};

export const LogoPicker = ({
	value,
	onChange,
	text,
	displayValueOnly,
}: Props) => {
	const [isEdit, setIsEdit] = useState(false);
	const [search, setSearch] = useState('');

	const [debouncedSearch] = useDebounce(search, 300);

	const trimmedSearch = debouncedSearch.trim();

	const { data: suggestions, isFetching } = useClearbitCompanySuggestions(
		{ query: trimmedSearch },
		{ enabled: isEdit }
	);

	if (value != null) {
		return (
			<SelectedLogoContainer>
				<IconButton onClick={() => onChange(null)}>
					<IconSubtract />
				</IconButton>
				<SelectedLogo src={value} alt="Selected company logo" />
			</SelectedLogoContainer>
		);
	}

	if (displayValueOnly) return null;

	return (
		<>
			{isEdit ? (
				<ComboboxProvider
					setSelectedValue={(logo) => {
						onChange(logo as string);
						setIsEdit(false);
					}}
				>
					<SuggestionContainer>
						<SuggestionInput
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							placeholder="Search for a logo..."
							onBlur={() => {
								setIsEdit(false);
								setSearch('');
							}}
							focusable
							autoFocus
						/>
						{isFetching && <Spinner size="s" />}
					</SuggestionContainer>
					<SuggestionsContainer
						sameWidth
						gutter={1}
						getAnchorRect={(anchor) =>
							anchor!.parentElement!.getBoundingClientRect()
						}
					>
						{suggestions && (
							<>
								{suggestions.map((s) => {
									return (
										<Suggestion key={s.name} value={s.logo}>
											<SuggestionImage
												src={s.logo}
												alt={`${s.name} company logo`}
											/>
											<SuggestionName>{s.name}</SuggestionName>
										</Suggestion>
									);
								})}
							</>
						)}
					</SuggestionsContainer>
				</ComboboxProvider>
			) : (
				<AddLogoButton variant="ghost" size="m" onClick={() => setIsEdit(true)}>
					{text}
				</AddLogoButton>
			)}
		</>
	);
};

const AddLogoButton = styled(Button)`
	font-size: 0.75rem;
	text-transform: none;
	width: fit-content;
	&:not(:disabled):hover {
		background-color: transparent;
	}
`;

const SelectedLogoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const SelectedLogo = styled.img`
	width: 48px;
	height: 48px;
`;

const SuggestionContainer = styled.div`
	height: 32px;
	width: 100%;
	display: flex;
	align-items: center;
	border: 1px solid ${(p) => p.theme.colors.neutral.n40};
	border-radius: 0.25rem;
	padding-right: 0.625rem;
	&:focus-within {
		border-color: ${(p) => p.theme.colors.blue.blue400};
	}
`;

const SuggestionInput = styled(Combobox)`
	font-size: 0.75rem;
	padding: 0 0.625rem;
	flex: 1;
	border: 0;
	height: 100%;
	border-radius: 0.25rem;
`;

const SuggestionsContainer = styled(ComboboxPopover)`
	${defaultDropdownContentCss}
	max-height: 300px;
`;

const Suggestion = styled(ComboboxItem)`
	height: 40px;
	padding: 0 0.625rem;
	display: flex;
	align-items: center;
	gap: 0.625rem;
	background-color: ${(p) => p.theme.colors.white.white};
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
	&[data-active-item] {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
`;

const SuggestionImage = styled.img`
	width: 30px;
	height: 30px;
`;

const SuggestionName = styled.div`
	font-size: 0.75rem;
	line-height: 1.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
