import { calendarUtils } from '@compstak/ui-kit';
import { formatCsv, formatInteger, formatNumber } from 'format';
import { formatBool } from 'format';
import { MufaPropertyComp, PropertyComp } from 'types';
import { PropertySubtypeName } from 'api';

const formatString = (val: string) => val.replace(/\//g, '/ ');

export const separateBuildingSubtype = (subtype: PropertySubtypeName) => {
	return ['Flex/R&D', 'Live/Work'].includes(subtype)
		? formatString(subtype)
		: subtype;
};

export type LabelItem = {
	name: string;
	tooltip?: string;
	formatter?: (value: any) => string;
};

export type Labels = Record<KeysToDisplay, LabelItem>;
export type MufaLabels = Record<KeysToDisplay | MufaKeysToDisplay, LabelItem>;

export const commercialLabels: Labels = {
	buildingPropertyType: { name: 'Property Type' },
	buildingClass: {
		name: 'Building Class',
		tooltip:
			"CompStak's proprietary building class model assesses properties based on factors like year built, square footage, floors, and other features, scoring them by market for standardized comparison of building quality.",
	},
	propertyAverageTransactionSize: {
		name: 'Avg. Lease Size',
		formatter: formatInteger,
		tooltip: `The average transaction size of all captured leases associated with a
			property measured in square feet.`,
	},
	buildingSize: { name: 'Building Size', formatter: formatInteger },
	buildingFloorsCount: { name: 'Floors' },
	landlordName: {
		name: 'Landlord',
		formatter: formatCsv,
	},
	buildingYear: { name: 'Year Built' },
	buildingName: { name: 'Building Name' },
	buildingAddress: { name: 'Address' },
	buildingYearRenovated: { name: 'Year Renovated' },
	propertyMarketStartingRent: {
		name: 'Property Market Starting Rent',
		formatter: formatNumber,
	},
	lotSize: {
		name: 'Lot Size',
		tooltip: 'The size of the building lot or the land measured in acres.',
	},
	parkingRatio: {
		name: 'Parking Ratio',
		tooltip: `The number of parking spaces per 1,000 square feet of 
			leased space that the tenant is entitled to use.`,
	},
	buildingPropertySubtype: {
		name: 'Property Subtype',
		formatter: separateBuildingSubtype,
	},
	opportunityZoneId: { name: 'Opportunity Zone' },
	zoning: {
		name: 'Zoning',
		formatter: formatString,
	},
	parkingNotes: { name: 'Parking Notes' },
	constructionType: {
		name: 'Construction Type',
		formatter: formatString,
	},
	loadingDocksCount: { name: 'Loading Docks' },
	ceilingHeight: { name: 'Clear Height' },
	sprinkler: { name: 'Sprinklers', formatter: formatBool },
	amps: { name: 'Amps' },
	volts: { name: 'Volts' },
	numberOfUnits: { name: 'Number of Units' },
	groundLvlDoorsCount: { name: 'Ground Level Doors' },
};

export const mufaLabels: Record<KeysToDisplay | MufaKeysToDisplay, LabelItem> =
	{
		...commercialLabels,
		totalUnits: { name: 'Total Units' },
		propertyStatus: { name: 'Property Status' },
		marketRateUnits: {
			name: 'Market Rate Units',
		},
		assetClassMarket: {
			name: 'Asset Class',
			tooltip:
				'Assigned asset grade of the property relative to the other properties in the market based on the RealPage proprietary classification model.',
		},
		areaPerUnit: {
			name: 'Average Unit Size',
			formatter: (val: number) =>
				`${formatInteger(val, { shorten: false })} SF`,
		},
		assetClassSubmarket: {
			name: 'Submarket Asset Class',
			tooltip: `Assigned asset grade of the property relative to the other properties in the submarket based on the RealPage proprietary classification model.`,
		},
		unitMixType: {
			name: 'Unit Mix Type',
			tooltip: `Identifier to determine if the specific floorplans within a property are actual numbers received from the property, or estimated based on other property information.`,
		},
		buildingYear: { name: 'Year Built' },
		buildingYearRenovated: { name: 'Year Renovated' },
		buildingFloorsCount: { name: 'Floors' },
		dailyPricing: {
			name: 'Revenue Management',
			tooltip:
				'Identifier to determine if the property utilizes revenue management system for pricing.',
			formatter: (val: boolean) => formatBool(Boolean(val)),
		},
		propertySubtype: {
			name: 'Building Style',
			tooltip:
				'Assigned class based on stories, Low-Rise is defined as 1-3 stories, Mid-Rise is defined as 4-6 stories, and High-Rise is defined as 7+ stories.',
			formatter: formatString,
		},
		survey: {
			name: 'Survey',
			tooltip:
				'The date the property was surveyed and survey level data was collected.',
			formatter: (date: any) =>
				typeof date === 'string'
					? calendarUtils.getDate(date, 'fullDate2')
					: '',
		},
		buildingSize: {
			name: 'Gross Living Area',
			formatter: (val: number) =>
				`${formatInteger(val, { shorten: false })} SF`,
		},
	};

export type KeysToDisplay = keyof Pick<
	PropertyComp,
	| 'amps'
	| 'buildingAddress'
	| 'buildingClass'
	| 'buildingFloorsCount'
	| 'buildingName'
	| 'buildingPropertySubtype'
	| 'buildingPropertyType'
	| 'buildingSize'
	| 'buildingYear'
	| 'buildingYearRenovated'
	| 'ceilingHeight'
	| 'constructionType'
	| 'groundLvlDoorsCount'
	| 'landlordName'
	| 'loadingDocksCount'
	| 'lotSize'
	| 'numberOfUnits'
	| 'opportunityZoneId'
	| 'parkingNotes'
	| 'parkingRatio'
	| 'propertyAverageTransactionSize'
	| 'propertyMarketStartingRent'
	| 'sprinkler'
	| 'volts'
	| 'zoning'
>;

export type MufaKeysToDisplay = keyof Pick<
	MufaPropertyComp,
	| 'areaPerUnit'
	| 'assetClassMarket'
	| 'assetClassSubmarket'
	| 'buildingFloorsCount'
	| 'propertySubtype'
	| 'buildingSize'
	| 'buildingYear'
	| 'buildingYearRenovated'
	| 'dailyPricing'
	| 'marketRateUnits'
	| 'propertyStatus'
	| 'survey'
	| 'totalUnits'
	| 'unitMixType'
>;

export const getLabel = (
	isMufa: boolean,
	key: MufaKeysToDisplay | KeysToDisplay
) => {
	return isMufa
		? mufaLabels[key as MufaKeysToDisplay].name
		: commercialLabels[key as KeysToDisplay].name;
};
