import { Flex, TooltipV2, renderTableValue } from '@compstak/ui-kit';
import { TableHeaderTitle } from 'Components/UI';
import { usePropertyLoan } from 'api';
import { formatDate, formatMoney, formatPercent } from 'format';
import { routes } from 'router';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import IconHelp from 'ui/svg_icons/help.svg';
import IconTrepp from 'ui/svg_icons/trepp_logo.svg';
import {
	KeyValueGrid,
	KeyValueItem,
	KeyValueLabel,
	KeyValueLabelHelpText,
	KeyValueValue,
	PoweredByContainer,
	PoweredByText,
	TableContainer,
	TableHeader,
} from '../UI';
import { getLoanNOI } from 'util/getLoanNOI';

export const PrivateLoansTable = () => {
	const params = routes.propertyById.useParams();
	const { data: loans } = usePropertyLoan({ propertyId: params.propertyId });

	const loan = loans?.treppLoans?.[0];

	if (!loan) return null;

	const loanNoi = getLoanNOI(loan);

	return (
		<TableContainer>
			<TableHeader>
				<TableHeaderTitle>Most Recent CMBS Loan</TableHeaderTitle>
				<PoweredByContainer>
					<PoweredByText>Powered by</PoweredByText>
					<IconTrepp width={42} height={14} />
				</PoweredByContainer>
			</TableHeader>
			<KeyValueGrid>
				<KeyValueItem>
					<KeyValueLabel>Lender</KeyValueLabel>
					<KeyValueValue>{renderTableValue(loan.loanOriginator)}</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<Flex gap="0.25rem">
						<KeyValueLabel>Loan Amount</KeyValueLabel>
						<TooltipV2 content="Property-level Loan Amount at Origination">
							<div>
								<IconHelp width={12} />
							</div>
						</TooltipV2>
					</Flex>
					<KeyValueValue>
						{renderTableValue(loan.loanAmount, (v) =>
							formatMoney(v, { shorten: false, decimalPlaces: 0 })
						)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>Original Loan Term</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanTerm)} Months
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>Interest Type</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanInterestType)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>Appraised Value</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanAppraisedValue, (v) =>
							formatMoney(v, { shorten: false, decimalPlaces: 0 })
						)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>
						Loan Rate
						<UpdatedAtText date={loan.loanUpdatedAt.currentLoanRate} />
					</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.currentLoanRate, (v) =>
							formatPercent(v / 100)
						)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>Loan Maturity Date</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanMaturityDate, formatDate)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>Origination Date</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanOriginationDate, formatDate)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>
						Net Operating Income
						{typeof loan.loanOriginationDate === 'string' && (
							<UpdatedAtText date={loan.loanOriginationDate} />
						)}
					</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loanNoi, (v) =>
							formatMoney(v, { shorten: false, decimalPlaces: 0 })
						)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>
						Occupancy
						<UpdatedAtText date={loan.loanUpdatedAt.loanOccupancy} />
					</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanOccupancy, (v) =>
							formatPercent(v / 100, 1)
						)}
					</KeyValueValue>
				</KeyValueItem>
				<KeyValueItem>
					<KeyValueLabel>
						Loan-to-Value (LTV)
						<UpdatedAtText date={loan.loanUpdatedAt.loanToValue} />
					</KeyValueLabel>
					<KeyValueValue>
						{renderTableValue(loan.loanToValue, (v) => formatPercent(v / 100))}
					</KeyValueValue>
				</KeyValueItem>
			</KeyValueGrid>
		</TableContainer>
	);
};

const UpdatedAtText = ({ date }: { date: string }) => {
	return (
		<KeyValueLabelHelpText>
			as of {formatDate(date, 'MM/DD/YYYY')}
		</KeyValueLabelHelpText>
	);
};
