// Some interesting documentation on logging:
// https://opentelemetry.io/docs/
// https://opentelemetry.io/docs/concepts/signals/logs/

import { logEventToSegment } from 'middleware/tracking/segment';

enum LogLevel {
	// Some logging level specs:
	// https://www.rfc-editor.org/rfc/rfc5424
	// https://github.com/winstonjs/winston#logging-levels
	// https://www.tutorialspoint.com/log4j/log4j_logging_levels
	OFF = 0,
	FATAL = 10,
	ERROR = 20,
	WARN = 30,
	TELEMETRY = 40,
	INFO = 50,
	DEBUG = 60,
	TRACE = 70,
	ALL = 90,
	// Most of these levels are not yet used, but they are here as a reference for the future.
}

export type CsLogApp = 'NO_APP' | 'EXCHANGE' | 'ENTERPRISE';

interface ServerLogData {
	event: string; // Event should be a constant string for querying.
	stack?: string;
	level?: LogLevel;
	// Data below is not for querying.
	extraInfo?: unknown; // This data will be stringified.
}

export type CsLogData = Omit<ServerLogData, 'level'>;

const deploymentEnv = window._env_.REACT_APP_DEPLOYMENT_ENV ?? 'prod';
const LOG_TO_CONSOLE = ['local', 'dev', 'stage'].includes(deploymentEnv);

async function logToServer(data: ServerLogData): Promise<void> {
	// Segment (window.analytics) needs to be init-ed to be able to log to the server.
	const LOG_TO_CONSOLE_ONLY = !window.analytics;
	if (LOG_TO_CONSOLE_ONLY) {
		return;
	}
	let stringifiedExtraInfo = '';
	if (data?.extraInfo) {
		try {
			stringifiedExtraInfo = JSON.stringify(data.extraInfo);
		} catch (_err) {
			stringifiedExtraInfo = 'Failed to stringify extra info';
		}
	}
	try {
		const dataToLog = {
			stack: JSON.stringify(Error().stack),
			// Level should be specified in the data. That is why warn is the default.
			level: LogLevel.WARN,
			timestamp: new Date().getTime(),
			// The defaults will be overwritten by data.
			...data,
			event: undefined,
		};
		delete dataToLog.event;
		if (stringifiedExtraInfo) {
			dataToLog.extraInfo = stringifiedExtraInfo;
		}
		logEventToSegment(data.event, dataToLog);
	} catch (err) {
		console.error('An error occured when trying to log to the server?!', {
			err,
		});
	}
}

async function info(data: CsLogData) {
	const expandedData = { ...data, level: LogLevel.INFO };
	if (LOG_TO_CONSOLE) {
		console.info('CS info: ' + data.event, expandedData);
	}
	return logToServer(expandedData);
}

async function log(data: ServerLogData) {
	const expandedData = {
		// The log level on the log method can be overwritten by the data.
		// The log level on info, warn, and error methods is fixed.
		level: LogLevel.INFO,
		...data,
	};
	if (LOG_TO_CONSOLE) {
		// eslint-disable-next-line no-console
		console.log('CS log: ' + data.event, expandedData);
	}
	return logToServer(expandedData);
}

async function warn(data: CsLogData) {
	const expandedData = { ...data, level: LogLevel.WARN };
	if (LOG_TO_CONSOLE) {
		console.warn('CS warn: ' + data.event, expandedData);
	}
	return logToServer(expandedData);
}

async function error(data: CsLogData) {
	const expandedData = { ...data, level: LogLevel.ERROR };
	if (LOG_TO_CONSOLE) {
		console.error('CS error: ' + data.event, expandedData);
	}
	return logToServer(expandedData);
}

// "csLogger" stands for "CompStak logger", and the name is used to avoid ambiguity
// with loggers from deckgl or other libraries.
export const csLogger = {
	info,
	log,
	warn,
	error,
};
