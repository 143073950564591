import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import IconSpeakerphone from '../../ui/svg_icons/speakerphone.svg';

export const ProfileMenu = styled.div`
	box-sizing: border-box;
	background-color: ${(p) => p.theme.colors.gray.gray700};
	min-width: 250px;
	border: 1px solid;
	border-radius: 0.25rem;
	box-shadow: 0px 3px 6px rgba(245, 205, 205, 0.3);
`;

export const profileMenuItemCss = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.25rem;
	color: ${(p) => p.theme.colors.neutral.n30};
	font-size: 0.875rem;
	font-weight: 300;
	letter-spacing: 0.02em;
	border-top: 1px solid ${(p) => p.theme.colors.neutral.n100};
	height: 50px;
	text-align: left;
	cursor: pointer;
	box-sizing: border-box;
	width: 100%;
	transition: none;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n600};
		color: ${(p) => p.theme.colors.white.white};
	}

	svg {
		fill: ${(p) => p.theme.colors.blue.blue500};
		path {
			fill: ${(p) => p.theme.colors.blue.blue500};
		}
	}
`;

export const ProfileMenuLink = styled(Link)`
	${profileMenuItemCss};
`;

export const ProfileMenuAnchor = styled.a`
	${profileMenuItemCss};
`;

export const ProfileMenuButton = styled.button`
	all: unset;
	${profileMenuItemCss};
`;

export const IconPromotions = styled(IconSpeakerphone)`
	&& {
		fill: none;
		path {
			fill: none;
		}
	}
	stroke: ${(p) => p.theme.colors.blue.blue500};
`;
