import { MapStyle } from '@compstak/maps';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import GlobeIcon from 'ui/svg_icons/globe.svg';
import MapIcon from 'ui/svg_icons/map_folded.svg';

type Props = Omit<ComponentProps<'button'>, 'ref'> & {
	mapStyle: MapStyle;
};

export const MapStyleButton = ({ mapStyle, ...props }: Props) => {
	const Icon = iconMap[mapStyle];

	return (
		<Button data-qa-id="map-style-btn" {...props}>
			<Icon fill="#438ff7" />
			{textMap[mapStyle]}
		</Button>
	);
};

export const Button = styled.button`
	width: 110px;
	height: 40px;
	border: none;
	border-radius: 3px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.75rem;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	text-transform: uppercase;
	font-size: 10px;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
	background-color: ${(p) => p.theme.colors.white.white};
	color: #303440;
	padding: 0 10px;
`;

const StyledMapIcon = styled(MapIcon)`
	width: 18px;
	height: 19px;
`;

const iconMap = {
	[MapStyle.DEFAULT]: GlobeIcon,
	[MapStyle.MONOCHROME]: GlobeIcon,
	[MapStyle.SATELLITE]: StyledMapIcon,
};

const textMap = {
	[MapStyle.DEFAULT]: 'satellite',
	[MapStyle.MONOCHROME]: 'satellite',
	[MapStyle.SATELLITE]: 'map view',
};
