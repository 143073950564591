import { KeyInfoItemContainer, Title } from 'Components/KeyInfoItem';
import { EditableOverviewFields } from './useEditableOverview';
import {
	KeyInfoContainer,
	KeysToDisplay,
	MufaKeysToDisplay,
	getLabel,
	keyInfoAttributes,
} from 'Pages/PropertyPageV3/MainInfo';
import { HideableLabel } from '../HideableLabel';
import styled from 'styled-components';
import { ExportPdfInput } from 'pdf/ExportPdfInput';

type EditableKeyInfoItemsProps = {
	editableOverviewFields: EditableOverviewFields;
	setEditableOverviewFields: React.Dispatch<
		React.SetStateAction<EditableOverviewFields>
	>;
	setIsVisible: (key: KeysToDisplay | MufaKeysToDisplay) => () => void;
	getIsVisible: (key: KeysToDisplay | MufaKeysToDisplay) => boolean;
	isMufa: boolean;
};

export const EditableKeyInfoItems = ({
	editableOverviewFields,
	setEditableOverviewFields,
	setIsVisible,
	getIsVisible,
	isMufa,
}: EditableKeyInfoItemsProps) => {
	return (
		<KeyInfoContainer>
			{keyInfoAttributes.map((key) => (
				<KeyInfoItemContainer key={key}>
					<HideableLabel
						isVisible={getIsVisible(key)}
						onToggle={setIsVisible(key)}
					>
						<Title>{getLabel(isMufa, key)}</Title>
					</HideableLabel>
					<KeyValueExportPdfInput
						isVisible={getIsVisible(key)}
						value={editableOverviewFields.keyInfoAttributes[key]}
						onChange={(e) => {
							setEditableOverviewFields((s) => ({
								...s,
								keyInfoAttributes: {
									...s.keyInfoAttributes,
									[key]: e.target.value,
								},
							}));
						}}
					/>
				</KeyInfoItemContainer>
			))}
		</KeyInfoContainer>
	);
};

const KeyValueExportPdfInput = styled(ExportPdfInput)<{ isVisible: boolean }>`
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.375rem;
	color: ${({ theme }) => theme.colors.gray.gray700};
	text-transform: none;
	opacity: ${(props) => (props.isVisible ? 1 : 0.4)};
	word-wrap: break-word;
	padding: 0.375rem 0.375rem 0.375rem 0;
`;
