import { nullValueText } from '@compstak/common';
import { TooltipV2 } from '@compstak/ui-kit';
import { Portfolio, PortfolioFilterQuery, PortfolioMarket } from 'api';
import { formatInteger } from 'format';
import { ReactNode, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { Card } from './UI';

type Props = {
	portfolio: Portfolio;
};

export const MainOverview = ({ portfolio }: Props) => {
	const { filters } = usePortfolioFilters();

	const totals = useTotals(portfolio, filters);
	const tooltips = useTooltips(portfolio, totals, filters);

	const is2xl = useMediaQuery({ minWidth: 1536 });

	const tooltipSide = is2xl ? 'right' : 'bottom';
	const tooltipDelay = 500;

	return (
		<Root>
			<TooltipV2
				content={tooltips.markets}
				side={tooltipSide}
				delayDuration={tooltipDelay}
			>
				<GrayCard>
					<GrayCardValue>{portfolio.markets.length}</GrayCardValue>
					<GrayCardTitle>Markets</GrayCardTitle>
				</GrayCard>
			</TooltipV2>
			<TooltipV2
				content={tooltips.propertyCount}
				side={tooltipSide}
				delayDuration={tooltipDelay}
			>
				<GrayCard>
					<GrayCardValue>{totals.propertyCount}</GrayCardValue>
					<GrayCardTitle>Properties</GrayCardTitle>
				</GrayCard>
			</TooltipV2>
			<TooltipV2
				content={tooltips.totalSqFt}
				side={tooltipSide}
				delayDuration={tooltipDelay}
			>
				<GrayCard>
					<GrayCardValue>{formatInteger(totals.totalSqFt)}</GrayCardValue>
					<GrayCardTitle>Active Leased SQFT</GrayCardTitle>
				</GrayCard>
			</TooltipV2>
			<TooltipV2
				content={tooltips.activeLeaseCount}
				side={tooltipSide}
				delayDuration={tooltipDelay}
			>
				<GrayCard>
					<GrayCardValue>{totals.activeLeaseCount}</GrayCardValue>
					<GrayCardTitle>Total Active Leases</GrayCardTitle>
				</GrayCard>
			</TooltipV2>
		</Root>
	);
};

const useTotals = (portfolio: Portfolio, filters: PortfolioFilterQuery) => {
	return useMemo(() => {
		let propertyCount = 0;
		let totalSqFt = 0;
		let activeLeaseCount = 0;

		for (const market of portfolio.markets) {
			propertyCount +=
				filters.leaseExpirationsMonths !== null
					? market.propertyThatHasActiveLeasesCount
					: market.propertyCount;
			totalSqFt += market.totalSqFt;
			activeLeaseCount += market.activeLeaseCount;
		}

		return {
			propertyCount,
			totalSqFt,
			activeLeaseCount,
		};
	}, [portfolio, filters.leaseExpirationsMonths]);
};

const useTooltips = (
	portfolio: Portfolio,
	totals: ReturnType<typeof useTotals>,
	filters: PortfolioFilterQuery
) => {
	return useMemo(() => {
		const markets: ReactNode[] = [];
		const propertyCount: ReactNode[] = [];
		const totalSqFt: ReactNode[] = [];
		const activeLeaseCount: ReactNode[] = [];

		for (const market of portfolio.markets) {
			markets.push(
				<TooltipRow key={market.marketId}>
					<div>{market.market}</div>
				</TooltipRow>
			);
			propertyCount.push(
				getTooltipPercentageRow(
					market,
					filters.leaseExpirationsMonths != null
						? market.propertyThatHasActiveLeasesCount
						: market.propertyCount,
					totals.propertyCount
				)
			);
			totalSqFt.push(
				getTooltipPercentageRow(market, market.totalSqFt, totals.totalSqFt)
			);
			activeLeaseCount.push(
				getTooltipPercentageRow(
					market,
					market.activeLeaseCount,
					totals.activeLeaseCount
				)
			);
		}

		return {
			markets: <>{markets}</>,
			propertyCount: <>{propertyCount}</>,
			totalSqFt: <>{totalSqFt}</>,
			activeLeaseCount: <>{activeLeaseCount}</>,
		};
	}, [portfolio, totals, filters.leaseExpirationsMonths]);
};

const getTooltipPercentageRow = (
	market: PortfolioMarket,
	value: number,
	totalValue: number
) => {
	return (
		<TooltipRow key={market.marketId}>
			<div>{market.market}</div>
			<div>
				{value === 0 || totalValue === 0
					? nullValueText
					: `${Math.round((value / totalValue) * 100)}%`}
			</div>
		</TooltipRow>
	);
};

const Root = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;
	@media (min-width: 1280px) {
		grid-template-columns: repeat(4, 1fr);
	}
	@media (min-width: 1536px) {
		grid-template-columns: 1fr;
	}
`;

const GrayCard = styled(Card)`
	background-color: ${(p) => p.theme.colors.neutral.n10};
`;

const GrayCardValue = styled.div`
	font-size: 2rem;
	margin-bottom: 8px;
	font-weight: 400;
`;

const GrayCardTitle = styled.div`
	text-transform: capitalize;
	font-size: 0.75rem;
`;

const TooltipRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1rem;
`;
