import { Market } from '@compstak/common';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';

// { "$market $state1 $state2 $alias1 $alias2": { $market }, ... }
export const getSearchTermsForMarkets = (markets: Market[]) => {
	return markets.reduce<Record<string, Market>>((acc, market) => {
		const searchTerms = getSearchTermsForMarket(market);
		acc[searchTerms] = market;

		return acc;
	}, {});
};

export const getSearchTermsForMarket = (market: Market, withAliases = true) => {
	let searchTerms = (market.displayName ?? '').toLowerCase();

	(market.states ?? []).forEach((state) => {
		searchTerms += ` ${state.name.toLowerCase()}`;
	});

	if (withAliases) {
		(market.aliases ?? []).forEach((aliasObj) => {
			searchTerms += ` ${aliasObj.name.toLowerCase()}`;
		});
	}

	return searchTerms;
};

// { "$submarket": { $submarket }, ... }
export const getSearchTermsForSubmarkets = (submarkets: SubmarketPolygon[]) => {
	return submarkets.reduce<Record<string, SubmarketPolygon>>((acc, obj) => {
		const name = obj.properties.name.toLowerCase();
		acc[name] = obj;

		return acc;
	}, {});
};
