import { useUserQuery } from 'api';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import LogoCompstak from 'ui/svg_icons/cs_full_logo.svg';
import { ExportPdfInput } from './ExportPdfInput';
import { LogoPicker } from './LogoPicker';

export type EditPageProps = {
	title: string;
	onTitleChange?: (value: string) => void;
	preparedFor: string;
	logo: string | null;
	logoDisplayValueOnly?: boolean;
	/** @default true */
	showLogo?: boolean;
	onLogoChange: (value: string | null) => void;
	children: ReactNode;
};

export const EditPage = ({
	title,
	onTitleChange,
	preparedFor,
	logo,
	logoDisplayValueOnly,
	onLogoChange,
	showLogo = true,
	children,
}: EditPageProps) => {
	const { data: user } = useUserQuery();

	const isTitleEditable = !!onTitleChange;

	return (
		<Page>
			<PageHeader>
				<TitleContainer>
					{isTitleEditable ? (
						<TitleInput
							value={title}
							onChange={(e) => onTitleChange?.(e.target.value)}
						/>
					) : (
						<Title>{title}</Title>
					)}
				</TitleContainer>
				{showLogo && (
					<LogoPickerContainer>
						<LogoPicker
							value={logo}
							onChange={onLogoChange}
							text="+ Add your logo"
							displayValueOnly={logoDisplayValueOnly}
						/>
					</LogoPickerContainer>
				)}
			</PageHeader>
			{children}
			<Footer>
				<ProvidedBy>Data provided by</ProvidedBy>
				<LogoCompstak />
				<Copyright>
					This report was prepared
					{preparedFor ? ` for ${preparedFor}` : ''} by {user.firstName}{' '}
					{user.lastName} at {user.companyName} and is governed by the{' '}
					<TermsOfUse
						href="http://www.compstak.com/legal"
						target="_blank"
						data-qa-id="terms-of-use-link"
					>
						CompStak Terms of Use
					</TermsOfUse>
					. It may not be copied or disseminated to others without written
					permission from CompStak. All information is not guaranteed and should
					be verified by personal inspection by and/or with the appropriate
					professional(s).
				</Copyright>
			</Footer>
		</Page>
	);
};

const Page = styled.div`
	padding: 1rem 2rem;
	background-color: ${(p) => p.theme.colors.white.white};
	width: 100%;
	width: 1200px;
	min-height: 1000px;
	position: relative;
	padding-bottom: 10%;
`;

const PageHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n40};
	margin-bottom: 1.25rem;
`;

const titleCss = css`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.875rem;
	font-weight: 400;
`;

const TitleInput = styled(ExportPdfInput)`
	${titleCss}
`;

const Title = styled.div`
	${titleCss}
`;

const TitleContainer = styled.div`
	width: 60%;
`;

const LogoPickerContainer = styled.div`
	width: 40%;
	max-width: 320px;
	display: flex;
	justify-content: flex-end;
`;

const Footer = styled.div`
	position: absolute;
	bottom: 20px;
	padding-top: 0.5rem;
	border-top: 1px solid ${(p) => p.theme.colors.gray.gray700};
	width: calc(100% - 4rem);
`;

const ProvidedBy = styled.div`
	font-size: 0.875rem;
`;

const Copyright = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.75rem;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: 0.01em;
	margin-top: 0.25rem;
`;

const TermsOfUse = styled.a`
	color: ${(p) => p.theme.colors.gray.gray700};
	text-decoration: underline;
	&:hover {
		cursor: pointer;
		color: ${(p) => p.theme.colors.gray.gray700};
		text-decoration: underline;
	}
`;
