const toNum = (val?: string) => (val ? parseInt(val, 10) : 0);

export const regex = /^(?:(\d+)y)? ?(?:(\d+)m)?$/;
export function stringToMonths(str: string | number) {
	if (typeof str !== 'string') {
		throw new TypeError(
			'Only strings can be converted from a timespan to months.'
		);
	}

	if (str === '') return 0;

	const [, years, months] = regex.exec(str) ?? NO_VALUES;
	const isValid = months || years;

	if (!isValid) {
		throw new SyntaxError(
			'Timespans must be of the format "1y 1m", "1y", or "1m".'
		);
	}

	return toNum(months) + toNum(years) * 12;
}

const NO_VALUES = [undefined, undefined, undefined];
