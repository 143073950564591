import { nullValueText } from '@compstak/common';
import * as exportActions from 'actions/export';
import * as leaseActions from 'actions/lease';
import * as saleActions from 'actions/sale';
import EffectiveRent from 'Components/AttributeValues/EffectiveRent';
import MissingValue from 'Components/AttributeValues/MissingValue';
import StartingRent from 'Components/AttributeValues/StartingRent';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import { useUser } from 'Pages/Login/reducers';
import { connect } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import * as feedbackActions from 'Singletons/Feedback/actions';
import * as menuActions from 'Singletons/Menu/actions';
import { AppDispatch } from 'store';
import styled from 'styled-components';
import { LeaseComp, SalesComp } from 'types/comp';
import { TableField } from 'types/table';
import button from 'ui/styles/button.less';
import actionWrapper from 'util/actionWrapper';
import getFieldValue from 'util/getFieldValue';
import {
	getIsPortfolioField,
	getShouldShowDefinedValuesForPortfolioField,
	getShouldShowUniqueValuesForPortfolioField,
	MultiPropertyItemsTooltip,
} from '../../../Components';
import LockIcon from '../../../ui/svg_icons/lock.svg';
import {
	EXPLICIT_SPACE,
	getNumberOfDefinedValuesInPortfolio,
	getUniqueDefinedValuesInPortfolio,
} from '../../../utils';
import AttributeValue from '../AttributeValue';
import styles from './valueorlock.less';
import { isPortfolioSaleComp } from 'utils/compHelpers';

type SharedProps = {
	field: TableField;
	compIdRef: { value: number | null };
};

type Props =
	| ({ compType: 'lease'; comp: LeaseComp } & SharedProps)
	| ({ compType: 'sale'; comp: SalesComp } & SharedProps);

const ValueOrLockButton = (props: Props) => {
	const user = useUser();

	const [filters] = useFilters();
	const isMonthly = shouldUseMonthlyMetrics(filters);

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const showCreditsAlert = (event) => {
		event.preventDefault();
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'feedbackActions' does not exist on type ... Remove this comment to see the full error message
		props.feedbackActions.addFeedback('Get credits to unlock more comps');
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const unlock = (event) => {
		event.preventDefault();
		if (props.compType === 'lease') {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'leaseActions' does not exist on type 'Re... Remove this comment to see the full error message
			props.leaseActions.unlockLeases([props.compIdRef.value]);
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'saleActions' does not exist on type 'Rea... Remove this comment to see the full error message
			props.saleActions.unlockSales([props.compIdRef.value]);
		}
	};

	const { comp } = props;
	let value = getFieldValue(comp, props.compType, props.field.name);

	// @ts-expect-error TS2367: This comparison appears to be ...
	if (props.compType === 'property' && value === null) {
		value = undefined;
	}

	// @ts-expect-error TS7034: Variable 'onClick' implicitly ...
	let onClick, tooltip;

	switch (true) {
		// Infinity is here for the hack that makes it flash. See the search reducer.
		case props.field.showAlertIfNotPresent &&
			(value === undefined || value === Infinity):
			return <MissingValue displayName={props.field.displayName} />;

		case value !== null && props.field.name === 'startingRent':
			return (
				<StartingRent isMonthly={isMonthly} comp={comp} abbreviateText={true} />
			);

		case typeof value === 'string' && props.field.name === 'effectiveRent':
			return (
				<EffectiveRent
					isMonthly={isMonthly}
					abbreviateText={true}
					value={value as string}
				/>
			);

		case isPortfolioSaleComp(comp) && getIsPortfolioField(props.field.name): {
			if (props.field.name === 'buildingAddress') {
				const numOfProperties = comp?.portfolio?.length;
				const text = `${numOfProperties} Propert${
					numOfProperties === 1 ? 'y' : 'ies'
				}`;

				return (
					<MultiPropertyItemsTooltip saleComp={comp as SalesComp}>
						<span className={styles.portfolio}>{text}</span>
					</MultiPropertyItemsTooltip>
				);
			} else if (getShouldShowUniqueValuesForPortfolioField(props.field.name)) {
				const uniqueValues = getUniqueDefinedValuesInPortfolio(
					comp.portfolio,
					props.field.name
				);
				const numOfUniqueValues = uniqueValues.length;

				if (numOfUniqueValues === 0) {
					return <GrayText>{nullValueText}</GrayText>;
				} else if (numOfUniqueValues === 1) {
					return uniqueValues[0];
				} else {
					let secondWord = 'Values';

					if (props.field.name === 'submarket') {
						secondWord = 'Submarkets';
					} else if (props.field.name === 'marketDisplayName') {
						secondWord = 'Markets';
					}

					const text = `${numOfUniqueValues} ${secondWord}`;

					return (
						<MultiPropertyItemsTooltip
							saleComp={comp as SalesComp}
							field={props.field}
						>
							<span className={styles.portfolio}>{text}</span>
						</MultiPropertyItemsTooltip>
					);
				}
			} else if (
				getShouldShowDefinedValuesForPortfolioField(props.field.name)
			) {
				const numOfValues = getNumberOfDefinedValuesInPortfolio(
					comp.portfolio,
					props.field.name
				);

				if (numOfValues === 0) {
					return <GrayText>{nullValueText}</GrayText>;
				} else {
					const text = `${numOfValues} Value${numOfValues > 1 ? 's' : ''}`;

					return (
						<MultiPropertyItemsTooltip
							saleComp={comp as SalesComp}
							field={props.field}
						>
							<span className={styles.portfolio}>{text}</span>
						</MultiPropertyItemsTooltip>
					);
				}
			}
			break;
		}

		case value === undefined:
		case value === Infinity: // this is here for the hack that makes it flash. See the search reducer.
			return <span>{nullValueText}</span>;

		// @ts-expect-error TS2339: Property 'credits' does not ex...
		case value === null && user?.freeComps === 0 && user?.credits < comp.cost:
			return (
				<span
					className={button.small + ' ' + button.disabled}
					onClick={showCreditsAlert}
				>
					<LockIcon /> Unlock | {comp.cost}
				</span>
			);

		case value === null: {
			onClick = unlock;
			let className = button.small + ' unlock ';
			let costText = '';
			let costClassName = 'unlock-export-button-credits';
			if (user?.freeComps) {
				costText = 'Free';
				costClassName += '-free';
				className += ' ' + button.green;
				tooltip = 'Unlock for free';
			} else if (user?.userType === 'exchange' || user?.salesTrader) {
				// @ts-expect-error TS2322: Type 'number' is not assignabl...
				costText = comp.cost;
				if (user?.pointsAvailable < comp.cost) {
					className += ' ' + button.disabled;
					onClick = showCreditsAlert;
					tooltip = 'Get more credits to unlock';
				} else {
					className += ' ' + button.green;
					tooltip = 'Unlock for ' + comp.cost + ' Credits';
				}
			}

			return (
				<span
					className={className}
					data-tooltip={tooltip}
					onClick={(e) => {
						e.stopPropagation();
						// @ts-expect-error TS7005: Variable 'onClick' implicitly ...
						onClick(e);
					}}
				>
					<LockIconStyled width={14} height={16} />
					<span className={button.divider}>|</span>
					{EXPLICIT_SPACE}
					<span className={costClassName}>{costText}</span>
				</span>
			);
		}
	}

	return (
		<span>
			{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
			<AttributeValue value={value} />
		</span>
	);
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
	return actionWrapper(
		{
			menuActions,
			feedbackActions,
			exportActions,
			leaseActions,
			saleActions,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(ValueOrLockButton);

const GrayText = styled.span`
	// TODO: add color to ui-kit
	color: #cacdd8;
`;
