import { matchPath } from 'react-router-dom';
import { routes } from './routes';

export const isModalPath = (pathname: string) => {
	return Object.values(routes)
		.filter((r) => {
			return r.isModal;
		})
		.some((r) => matchPath(r.path, pathname));
};
