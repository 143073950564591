import { Market } from '@compstak/common';
import styled from 'styled-components';
import { PropertyComp } from 'types';
import abbreviateNumber from 'ui/util/abbreviateNumber';

type Props = {
	property: PropertyComp;
	market?: Market;
};

export const BuildingStats = ({ property, market }: Props) => {
	return (
		<BuildingStatsList>
			{property.buildingPropertyType && (
				<BuildingStatsListItem>
					{property.buildingPropertyType}
				</BuildingStatsListItem>
			)}
			{property.buildingClass && (
				<BuildingStatsListItem>
					{formatPropertyBuildingClass(property.buildingClass, market)}
				</BuildingStatsListItem>
			)}
			{property.buildingSize && (
				<BuildingStatsListItem>
					{abbreviateNumber(property.buildingSize)}
				</BuildingStatsListItem>
			)}
		</BuildingStatsList>
	);
};

const BUILDING_CLASS_LABEL_BY_COUNTRY_CODE = {
	DEFAULT: 'Class',
	US: 'Class',
	GB: 'Grade',
};

const getBuildingClassLabel = (market?: Market) => {
	if (!market?.countryCode) {
		return BUILDING_CLASS_LABEL_BY_COUNTRY_CODE.DEFAULT;
	}
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	return BUILDING_CLASS_LABEL_BY_COUNTRY_CODE[market.countryCode];
};

const formatPropertyBuildingClass = (
	buildingClass: PropertyComp['buildingClass'],
	market?: Market
) => {
	return `${getBuildingClassLabel(market)} ${buildingClass}`;
};

const BuildingStatsList = styled.ul`
	list-style: none;
	display: flex;
	align-items: center;
	margin-top: 5px;
	& li:not(:last-of-type)::after {
		content: '•';
		padding: 0 5px;
	}
`;

const BuildingStatsListItem = styled.li`
	color: #228fff;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 10px;
	letter-spacing: 0.6px;
	text-transform: uppercase;
`;
