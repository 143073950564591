import { numberUtils, renderTableValue, TableColumn } from '@compstak/ui-kit';
import { PortfolioItem } from 'types/comp';
import { formatValue } from 'util/comp-format/src/format';
import { IconLockGrey } from 'ui/UI';
import { getShouldShowLockIcon } from 'utils';
import { ReactNode } from 'react';

export const columns: TableColumn<PortfolioItem, { isExchange: boolean }>[] = [
	{
		header: () => {
			return 'Address';
		},
		body: ({ row }) => {
			return renderTableValue(row.buildingAddress);
		},
		width: 172,
	},
	{
		header: () => 'City, State',
		width: 142,
		body: ({ row }) => {
			return `${row.city}, ${row.state}`;
		},
	},
	{
		header: () => {
			return 'Submarket';
		},
		body: ({ row }) => {
			return renderTableValue(row.submarket);
		},
		width: 185,
	},
	{
		header: () => {
			return 'Total Sale Price';
		},
		body: ({ row, extraData }) => {
			if (
				getShouldShowLockIcon(row.totalSalePrice, row, extraData.isExchange)
			) {
				return <IconLockGrey />;
			}

			return renderTableValue(
				row.totalSalePrice,
				(v) => formatValue('totalSalePrice', v, false, 'sale') as ReactNode
			);
		},
		width: 110,
	},
	{
		header: () => {
			return 'Sale Price ($/SF)';
		},
		body: ({ row, extraData }) => {
			if (getShouldShowLockIcon(row.salePricePsf, row, extraData.isExchange)) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.salePricePsf, (v) =>
				numberUtils.formatCurrency(v, 'currencyTwoDecimals')
			);
		},
		width: 100,
	},
	{
		header: () => {
			return 'Cap Rate';
		},
		body: ({ row, extraData }) => {
			if (getShouldShowLockIcon(row.capRate, row, extraData.isExchange)) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.capRate, (v) =>
				numberUtils.formatPercent(v / 100, 'percentTwoDecimals')
			);
		},
		width: 85,
	},
	{
		header: () => {
			return 'Total NOI';
		},
		body: ({ row, extraData }) => {
			if (
				getShouldShowLockIcon(
					row.totalNetOperatingIncome,
					row,
					extraData.isExchange
				)
			) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.totalNetOperatingIncome, (v) =>
				numberUtils.formatPercent(v / 100, 'percentTwoDecimals')
			);
		},
		width: 96,
	},
	{
		header: () => {
			return 'Occupancy Rate';
		},
		body: ({ row, extraData }) => {
			if (
				getShouldShowLockIcon(row.percentOccupied, row, extraData.isExchange)
			) {
				return <IconLockGrey />;
			}

			return renderTableValue(row.percentOccupied, (v) =>
				numberUtils.formatPercent(v / 100, 'percentTwoDecimals')
			);
		},
		width: 94,
	},
];
