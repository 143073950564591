import {
	TopTenantIndustry,
	UseTopTenantIndustriesOptions,
	useTopTenantIndustriesQuery,
} from 'api';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

export const useExchangeDashboardTenantIndustryDistribution = (
	options?: UseTopTenantIndustriesOptions
) => {
	const [filters] = useFilters('exchange-dashboard');
	const { data: responseData, isFetching } = useTopTenantIndustriesQuery(
		{ filters },
		options
	);

	const data = useMemo(() => {
		return responseData
			?.sort((item1, item2) => item2.sqftOrLeaseCount - item1.sqftOrLeaseCount)
			.reduce<TopTenantIndustry[]>(
				(acc, curItem) =>
					acc.length < 10
						? [...acc, curItem]
						: [
								...acc.slice(0, 9),
								{
									industry: 'Other',
									sqftOrLeaseCount:
										acc[9].sqftOrLeaseCount + curItem.sqftOrLeaseCount,
									leaseCount: acc[9].leaseCount + curItem.leaseCount,
									propertyCount: acc[9].propertyCount + curItem.propertyCount,
									sqft: acc[9].sqft + curItem.sqft,
								},
						  ],
				[]
			);
	}, [responseData]);

	return {
		data,
		isFetching,
		type: 'tenantIndustryDistribution' as const,
	};
};
