import { MapAnalyticsMetric } from 'maps';
import { useState } from 'react';
import { createSimpleProvider } from '../../../../../utils';

export const {
	Provider: LeaseExpirationAnalyticsProvider,
	useProviderContext: useLeaseExpirationState,
} = createSimpleProvider({
	useValue: () => useState(initialState),
});

export type LeaseExpirationState = {
	view: LeaseExpirationView;
	metric: MapAnalyticsMetric;
	expireInMonths: number;
	isPlaying: boolean;
	title: string;
};

type LeaseExpirationView = 'read' | 'settings';

const initialState: LeaseExpirationState = {
	view: 'read',
	metric: 'sqft',
	expireInMonths: 0,
	isPlaying: false,
	title: 'Lease Expiration',
};
