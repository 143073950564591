import { MapAnalyticsTooltipDescription, MapAnalyticsTooltipTitle } from '../UI';
import { moneyFormat, MONTHLY_POSTFIX, YEARLY_POSTFIX } from './constants';
import { MarketRentAnalyticsPeriod } from './types';

type Props = {
	title?: string;
	marketRent: number;
	period: MarketRentAnalyticsPeriod;
};

export const MarketRentAnalyticsTooltip = ({
	title,
	marketRent,
	period,
}: Props) => {
	const value = moneyFormat(marketRent);
	const postfix =
		period === MarketRentAnalyticsPeriod.MONTH
			? MONTHLY_POSTFIX
			: YEARLY_POSTFIX;
	return (
		<>
			{title && <MapAnalyticsTooltipTitle>{title}</MapAnalyticsTooltipTitle>}
			<MapAnalyticsTooltipDescription>{value + postfix}</MapAnalyticsTooltipDescription>
		</>
	);
};
