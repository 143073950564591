import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'transactionSize' as const;

type Props = {
	inputId?: string;
};
export const TransactionSizeFilter = ({ inputId }: Props) => {
	return (
		<FilterBase attribute={attribute} inputId={inputId}>
			{(props) => {
				return (
					<Between
						attribute={attribute}
						filter={props.filter}
						onFilterChange={props.onFilterChange}
						touch={props.touch}
						setError={props.setError}
					/>
				);
			}}
		</FilterBase>
	);
};
