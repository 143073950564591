import { Checkbox, defaultTheme } from '@compstak/ui-kit';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRect } from '../../../utils';
import { ExportPdfInput } from '../../../pdf/ExportPdfInput';
import { PdfColumn } from './types';

type Props<EditableRow, Row> = {
	rows: EditableRow[];
	columns: PdfColumn<EditableRow, Row>[];
	onChange: (args: {
		row: EditableRow;
		column: PdfColumn<EditableRow, Row>;
		value: string;
	}) => void;
	onToggleColumn: (args: {
		column: PdfColumn<EditableRow, Row>;
		columnIndex: number;
	}) => void;
	selection?: EditPdfSelection<EditableRow>;
	getShouldHighlightRow?: (id: number | string) => boolean;
};

type EditPdfSelection<Row> = {
	getIsRowSelected: (row: Row, rowIndex: number) => boolean;
	areAllSelected: boolean;
	areSomeSelected: boolean;
	toggleRow: (row: Row, rowIndex: number) => void;
	toggleAllSelected: () => void;
};

export const EditPdfTable = <
	EditableRow extends Record<string, string | number>,
	Row,
>({
	rows,
	columns,
	onChange,
	onToggleColumn,
	selection,
	getShouldHighlightRow,
}: Props<EditableRow, Row>) => {
	const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null);
	const rect = useRect(rootEl);

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => 50,
		getScrollElement: () => rootEl,
		overscan: 5,
	});

	const hasVScroll = rootEl && rootEl.scrollHeight > rootEl.clientHeight;

	const columnSizes = useMemo(() => {
		if (rect?.width == null) return [];
		const totalAvailableWidth =
			rect.width -
			(selection ? SELECTION_COL_WIDTH : 0) -
			(hasVScroll ? SCROLLBAR_WIDTH : 0);

		const totalFlex = columns.reduce((sum, col) => sum + (col.flex ?? 1), 0);

		return columns.map((col) => {
			const flex = col.flex ?? 1;
			const flexPart = flex / totalFlex;
			return flexPart * totalAvailableWidth;
		});
	}, [columns, rect, selection, hasVScroll]);

	const [trigger, setTrigger] = useState(false);

	useEffect(() => {
		setTrigger((t) => !t);
	}, []);

	return (
		<Root ref={setRootEl}>
			<Row
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 1,
				}}
			>
				{selection && (
					<HeaderCell
						style={{ width: SELECTION_COL_WIDTH, justifyContent: 'center' }}
						isVisible
					>
						<Checkbox
							checked={
								selection.areAllSelected
									? true
									: selection.areSomeSelected
										? 'indeterminate'
										: false
							}
							onCheckedChange={selection.toggleAllSelected}
							inheritBg={false}
						/>
					</HeaderCell>
				)}
				{columns.map((column, columnIndex) => {
					return (
						<HeaderCell
							key={column.id}
							style={{
								width: columnSizes[columnIndex],
							}}
							isVisible={column.isVisible}
						>
							{column.header}
							<HeaderCellOverlayButton
								onClick={() => {
									onToggleColumn({ column, columnIndex });
								}}
							>
								<ToggleBadge isVisible={column.isVisible}>
									{column.isVisible ? 'Hide' : 'Show'}
								</ToggleBadge>
							</HeaderCellOverlayButton>
						</HeaderCell>
					);
				})}
			</Row>
			<div
				style={{
					position: 'relative',
					height: rowVirtualizer.getTotalSize(),
				}}
			>
				{rowVirtualizer.getVirtualItems().map((virtualRow) => {
					const rowIndex = virtualRow.index;
					const row = rows[virtualRow.index];
					const highlightRow = getShouldHighlightRow?.(row.propertyId);

					return (
						<Row
							key={virtualRow.key}
							data-index={virtualRow.index}
							ref={(el) => {
								// solution taken from: https://github.com/TanStack/virtual/issues/659
								setTimeout(() => {
									rowVirtualizer.measureElement(el);
								});
							}}
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								transform: `translateY(${virtualRow.start - rowVirtualizer.options.scrollMargin}px)`,
							}}
							highlightRow={highlightRow}
						>
							{selection && (
								<Cell
									style={{
										width: SELECTION_COL_WIDTH,
										justifyContent: 'center',
									}}
									isVisible
								>
									<Checkbox
										checked={selection.getIsRowSelected(row, rowIndex)}
										onCheckedChange={() => selection.toggleRow(row, rowIndex)}
										onClick={(e) => e.stopPropagation()}
									/>
								</Cell>
							)}
							{columns.map((column, columnIndex) => {
								let content: ReactNode = (
									<ExportPdfInput
										value={row[column.id]}
										onChange={(e) => {
											onChange({ row, column, value: e.target.value });
										}}
										triggerResize={trigger}
									/>
								);

								if (column.body) {
									content = column.body({
										row,
										rowIndex,
										defaultContent: content,
									});
								}

								return (
									<Cell
										key={column.id}
										isVisible={column.isVisible}
										style={{
											width: columnSizes[columnIndex],
										}}
									>
										{content}
									</Cell>
								);
							})}
						</Row>
					);
				})}
			</div>
		</Root>
	);
};

const SELECTION_COL_WIDTH = 45;

const SCROLLBAR_WIDTH = 15;

const Root = styled.div`
	width: 100%;
	position: relative;
	overflow: auto;
	max-height: 600px;
	&::-webkit-scrollbar {
		background-color: ${(p) => p.theme.colors.gray.gray30};
		width: ${SCROLLBAR_WIDTH}px;
		height: ${SCROLLBAR_WIDTH}px;
	}

	&::-webkit-scrollbar-track {
		background-color: ${(p) => p.theme.colors.gray.gray30};
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c1c1c1;
		border-radius: ${SCROLLBAR_WIDTH}px;
		border: ${(p) =>
			`${SCROLLBAR_WIDTH / 4}px solid ${p.theme.colors.gray.gray30}`};

		&:hover {
			background-color: #7d7d7d;
		}
	}

	&::-webkit-scrollbar-button {
		display: none;
	}
`;

const Row = styled.div<{ highlightRow?: boolean }>`
	width: 100%;
	display: flex;
	min-height: 40px;
	background-color: ${(props) =>
		props.highlightRow ? defaultTheme.colors.gray.gray200 : ''};
`;

const HeaderCellOverlayButton = styled.button`
	all: unset;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	display: none;
	align-items: center;
	justify-content: center;
`;

const ToggleBadge = styled.div<{ isVisible: boolean }>`
	font-size: 0.625rem;
	font-weight: 500;
	padding: 0.125rem;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.white.white};
	background-color: ${(p) =>
		p.isVisible ? '#f16852' : p.theme.colors.blue.blue500};
`;

const baseCellCss = css`
	display: flex;
	align-items: center;
	flex-shrink: 0;
`;

const Cell = styled.div<{ isVisible: boolean }>`
	${baseCellCss};
	opacity: ${(p) => (p.isVisible ? 1 : 0.4)};
	&:last-of-type {
		padding-right: 10px;
	}
`;

const HeaderCell = styled.div<{ isVisible: boolean }>`
	${baseCellCss};
	background-color: ${(p) => `rgba(223,225,230,${p.isVisible ? 1 : 0.4})`};
	color: ${(p) => `rgba(0,0,0,${p.isVisible ? 1 : 0.4})`};
	font-weight: 500;
	padding: 0 0.375rem;
	font-size: 0.75rem;
	position: relative;
	&:hover {
		${HeaderCellOverlayButton} {
			display: flex;
		}
	}
`;
