import { ComponentProps, useEffect, useRef } from 'react';
import styled from 'styled-components';
import IconEdit from 'ui/svg_icons/edit.svg';

type Props = ComponentProps<'textarea'> & {
	rootProps?: ComponentProps<'div'>;
	triggerResize?: boolean;
};

export const ExportPdfInput = ({
	rootProps,
	triggerResize = false,
	...props
}: Props) => {
	const ref = useRef<HTMLTextAreaElement>(null);

	// auto resize based on content
	useEffect(() => {
		if (!ref.current) return;
		ref.current.style.height = 'auto';
		ref.current.style.height = ref.current.scrollHeight + 'px';
	}, [props.value, triggerResize]);

	return (
		<Root
			hasValue={props.value != null && props.value !== ''}
			{...rootProps}
			onMouseDown={() => {
				ref.current?.focus();
			}}
		>
			<TextArea rows={1} {...props} ref={ref} />
			<StyledIconEdit width={16} />
		</Root>
	);
};

const StyledIconEdit = styled(IconEdit)`
	display: none;
	position: absolute;
	top: -10px;
	right: -10px;
	fill: ${({ theme }) => theme.colors.blue.blue500};
`;

const Root = styled.div<{ hasValue: boolean }>`
	position: relative;
	width: 100%;
	display: flex;
	outline: 1px solid
		${(p) => (!p.hasValue ? p.theme.colors.blue.blue400 : 'transparent')};
	border-radius: 0.25rem;
	cursor: text;
	&:hover,
	&:focus-within {
		outline-color: ${(p) => p.theme.colors.blue.blue400};
	}
	&:hover:not(:focus-within) {
		${StyledIconEdit} {
			display: block;
		}
	}
`;

const TextArea = styled.textarea`
	& {
		all: unset;
		width: 100%;
		height: 100%;
		font-size: 0.75rem;
		padding: 0.375rem;
		border: 0;
		margin: 0;
		resize: none;
		overflow: hidden;
		transition: none;
		flex: 1;
		border-radius: 0.25rem;
	}
`;
