import styled from 'styled-components';
import { MapAnalyticsActions } from '../MapAnalyticsActions';
import { MapAnalyticsInfo, MapAnalyticsInfoTitle } from '../UI';
import { LeaseExpirationDescription } from './LeaseExpirationDescription';
import { useLeaseExpirationState } from './LeaseExpirationProvider';

export const LeaseExpirationInfo = () => {
	const [state, setState] = useLeaseExpirationState();

	return (
		<StyledAnalyticsInfo>
			<MapAnalyticsInfoTitle>{state.title}</MapAnalyticsInfoTitle>
			<LeaseExpirationDescription />
			<MapAnalyticsActions
				onClickSettings={() => {
					setState((s) => ({ ...s, view: 'settings' }));
				}}
			/>
		</StyledAnalyticsInfo>
	);
};

const StyledAnalyticsInfo = styled(MapAnalyticsInfo)`
	padding-bottom: 2rem;
`;
