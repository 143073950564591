import { Chart, DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useDispatch } from 'react-redux';
import { updateChartDraft } from '../../actions';
import { useMarkets } from 'hooks/useMarkets';
import {
	ATTRIBUTE_TO_PLOT_VALUES_COMMERCIAL_SPECIFIC,
	ATTRIBUTE_TO_PLOT_VALUES_MUFA_SPECIFIC,
	ATTRIBUTE_TO_PLOT_VALUES_SALES_SPECIFIC,
	AttributeToPlotCommercialValues,
	AttributeToPlotMufaValues,
	AttributeToPlotType,
} from '../../chartBuilderConstants';

type Props = {
	chartDraft: Chart;
	attributeToPlotType: AttributeToPlotType;
};

const changeSeriesOnlyForDataSetType =
	(dataSetType: DataSetType, newSeries: AttributeToPlotType) =>
	(dataSet: DataSet) => {
		if (dataSet.type === dataSetType) {
			return {
				...dataSet,
				series: newSeries,
			};
		}
		return dataSet;
	};

const updateIfNewSeriesOneOf = (
	dataSet: DataSet,
	newSeries: AttributeToPlotType,
	optionAlternatives: AttributeToPlotType[]
) => {
	if (optionAlternatives.includes(newSeries)) {
		const updatedDataSetIfCommercial = changeSeriesOnlyForDataSetType(
			DataSetType.COMMERCIAL,
			optionAlternatives[0]
		)(dataSet);

		return changeSeriesOnlyForDataSetType(
			DataSetType.MUFA,
			optionAlternatives[1]
		)(updatedDataSetIfCommercial);
	}
	return dataSet;
};

export const ApplyAllModal = (props: Props) => {
	const { closeModal } = useModal();
	const dispatch = useDispatch();
	const markets = useMarkets();

	const applyAll = () => {
		const {
			chartDraft: { dataSets, ...otherChartDraftProps },
		} = props;
		const newSeries = props.attributeToPlotType;

		let resultDataSets: DataSet[];
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (ATTRIBUTE_TO_PLOT_VALUES_MUFA_SPECIFIC[newSeries]) {
			resultDataSets = dataSets.map(
				changeSeriesOnlyForDataSetType(DataSetType.MUFA, newSeries)
			);
			// @ts-expect-error TS7053: Element implicitly has an 'any...
		} else if (ATTRIBUTE_TO_PLOT_VALUES_COMMERCIAL_SPECIFIC[newSeries]) {
			resultDataSets = dataSets.map(
				changeSeriesOnlyForDataSetType(DataSetType.COMMERCIAL, newSeries)
			);
			// @ts-expect-error TS7053: Element implicitly has an 'any...
		} else if (ATTRIBUTE_TO_PLOT_VALUES_SALES_SPECIFIC[newSeries]) {
			resultDataSets = dataSets.map(
				changeSeriesOnlyForDataSetType(DataSetType.SALES, newSeries)
			);
		} else {
			resultDataSets = dataSets.map((dataSet) => {
				const maybeUpdatedDataSet = updateIfNewSeriesOneOf(dataSet, newSeries, [
					AttributeToPlotCommercialValues.STARTING_RENT,
					AttributeToPlotMufaValues.ASKING_RPSF,
				]);

				return updateIfNewSeriesOneOf(maybeUpdatedDataSet, newSeries, [
					AttributeToPlotCommercialValues.EFFECTIVE_RENT,
					AttributeToPlotMufaValues.EFFECTIVE_RPSF,
				]);
			});
		}

		const newChartDraft = {
			...otherChartDraftProps,
			dataSets: resultDataSets,
		};

		dispatch(updateChartDraft(newChartDraft, markets));
		closeModal();
	};

	return (
		<>
			<ModalTitle>
				Are you sure you want to Apply this attribute to all data sets?
			</ModalTitle>
			<ModalButtons>
				<ModalButton onClick={closeModal} variant="secondary">
					Cancel
				</ModalButton>

				<ModalButton onClick={applyAll}>Apply All</ModalButton>
			</ModalButtons>
		</>
	);
};
