import { useDeleteSavedSearchMutation } from 'api/savedSearches/useDeleteSavedSearchMutation';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import { useModal } from 'providers/ModalProvider';
import {
	ModalTitle,
	ModalParagraph,
	ModalButtons,
	ModalButton,
} from 'Components/Modals/common/UI';

type Props = {
	savedSearch: SavedSearch;
};
export const DeleteSavedSearchModal = ({ savedSearch }: Props) => {
	const deleteSavedSearch = useDeleteSavedSearchMutation();

	const { closeModal } = useModal();

	return (
		<div>
			<ModalTitle>Delete Your Saved Search</ModalTitle>
			<ModalParagraph>
				Clicking Delete Search will permanently remove your saved search,{' '}
				<b>{savedSearch.name}</b>.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					onClick={() => {
						deleteSavedSearch.mutate({ id: savedSearch.id });
						closeModal();
					}}
					variant="error"
				>
					Delete Search
				</ModalButton>
			</ModalButtons>
		</div>
	);
};
