import { useAppConfig, useUser } from 'Pages/Login/reducers';
import styled from 'styled-components';
import { useAppSelector } from 'util/useAppSelector';
import { SavedSearchesList } from './Components/SavedSearchesList';
import './styles/savedSearches.nomodule.less';
import { Modal, ModalContent } from 'Components/Modals/common/UI';
import { useHistoryState } from 'router/HistoryProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { SavedSearchesV2 } from './SavedSearchesV2';

export const SavedSearchesLayout = () => {
	const appConfig = useAppConfig();
	const savedSearches = useAppSelector((s) => s.savedSearches);
	const user = useUser();
	const { goBack } = useHistoryState();

	const flags = useFeatureFlags();

	return flags.SavedSearchImprovements ? (
		<SavedSearchesModal
			onClose={goBack}
			contentProps={{
				onInteractOutside: (ev) => ev.preventDefault(),
				view: 'large',
			}}
		>
			<SavedSearchesV2 />
		</SavedSearchesModal>
	) : (
		<Modal
			onClose={goBack}
			contentProps={{
				view: 'wide',
				onInteractOutside: (ev) => ev.preventDefault(),
			}}
		>
			<SavedSearchPage>
				<SavedSearchesList
					appConfig={appConfig}
					savedSearches={savedSearches}
					user={user}
				/>
			</SavedSearchPage>
		</Modal>
	);
};

const SavedSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.white.white};
`;

const SavedSearchesModal = styled(Modal)`
	${ModalContent} {
		padding: 1rem;
	}
`;
