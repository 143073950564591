import {
	OnUnlockLeasesSuccessFn,
	useIsMutatingUnlockLeases,
	useIsMutatingUnlockSales,
	useUnlockConditions,
	useUnlockLeasesMutation,
	useUnlockSalesMutation,
} from 'api';

export type UseUnlockCompsProps = {
	comps: { id: number; cost: number }[];
	type: 'lease' | 'sale';
	disabled?: boolean;
	isLoading?: boolean;
	onUnlockLeasesSuccess?: OnUnlockLeasesSuccessFn;
};

export const useUnlockComps = ({
	comps,
	type,
	onUnlockLeasesSuccess,
	disabled = false,
	isLoading = false,
}: UseUnlockCompsProps) => {
	const unlockLeasesResult = useUnlockLeasesMutation({
		onSuccess: onUnlockLeasesSuccess,
	});
	const unlockSalesResult = useUnlockSalesMutation();

	const numberOfActiveLeaseMutations = useIsMutatingUnlockLeases();
	const numberOfActiveSaleMutations = useIsMutatingUnlockSales();

	const numberOfActiveMutations =
		type === 'lease'
			? numberOfActiveLeaseMutations
			: numberOfActiveSaleMutations;

	const { mutate: unlock, isLoading: isUnlocking } =
		type === 'lease'
			? unlockLeasesResult
			: (unlockSalesResult as unknown as typeof unlockLeasesResult);

	const { isUserFetching, hasEnoughPoints, totalCost, canUnlockForFree } =
		useUnlockConditions({
			comps,
		});

	return {
		disabled:
			comps.length === 0 ||
			isUserFetching ||
			isUnlocking ||
			!hasEnoughPoints ||
			disabled ||
			numberOfActiveMutations > 0,
		isLoading: isUserFetching || isUnlocking || isLoading,
		unlock,
		totalCost,
		canUnlockForFree,
	};
};
