import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import TimespanButton from 'Components/Filters/Base/TimespanButton';

const attribute = 'loanMaturityDate' as const;

export const LoanMaturityDateFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<div>
					<Between {...props} />
					<div className="presets">
						In the coming... <br />
						<TimespanButton
							min={0}
							max={6}
							unit="months"
							roundTo="day"
							{...props}
						>
							6 Months
						</TimespanButton>
						<TimespanButton
							min={0}
							max={1}
							unit="year"
							roundTo="day"
							{...props}
						>
							Year
						</TimespanButton>
						<TimespanButton
							min={0}
							max={2}
							unit="year"
							roundTo="day"
							{...props}
						>
							2 Years
						</TimespanButton>
					</div>
				</div>
			)}
		</FilterBase>
	);
};
