import { PropertySale } from 'api';
import { formatDate, formatMoney, formatPercent } from 'format';
import { renderSafeValue } from 'utils';
import { PdfColumn } from '../types';

export const useSalesPdfColumns = () => {
	const columns: PdfColumn<EditablePropertySale, PropertySale>[] = [
		{
			id: 'saleDate',
			header: 'Sale Date',
			getValue: ({ row }) => {
				return renderSafeValue({ value: row.saleDate, format: formatDate });
			},
			isVisible: true,
		},
		{
			id: 'portfolio',
			header: 'Multi Property Sale',
			getValue: ({ row }) => {
				return row.isPortfolio
					? `${row.portfolio.length} Propert${row.portfolio.length === 1 ? 'y' : 'ies'}`
					: 'No';
			},
			isVisible: true,
		},
		{
			id: 'totalSalePrice',
			header: 'Total Sale Price',
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.totalSalePrice,
					format: (v) => formatMoney(v, { decimalPlaces: 0, shorten: false }),
				});
			},
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'dataSource',
			header: 'Data Source',
			getValue: ({ row }) => renderSafeValue({ value: row.dataSource }),
			isVisible: true,
		},
		{
			id: 'buyer/buyerRepCompanies',
			header: 'Buyer/Buyer Rep Companies',
			getValue: ({ row }) => {
				return `${renderSafeValue({ value: row.buyer })} / ${renderSafeValue({ value: row.buyerRepCompanies })}`;
			},
			isVisible: true,
		},
		{
			id: 'seller/sellerRepCompanies',
			header: 'Seller/Seller Rep Companies',
			getValue: ({ row }) => {
				return `${renderSafeValue({ value: row.seller })} / ${renderSafeValue({ value: row.sellerRepCompanies })}`;
			},
			isVisible: true,
		},
		{
			id: 'capRate',
			header: 'Cap Rate',
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.capRate,
					format: () => formatPercent(Number(row.capRate) / 100, 2),
				});
			},
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'percentOccupied',
			header: 'Occupancy Rate',
			getValue: ({ row }) => {
				return renderSafeValue({
					value: row.percentOccupied,
					format: () => formatPercent(Number(row.percentOccupied) / 100, 0),
				});
			},
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'interestPercentage',
			header: 'Interest',
			getValue: ({ row }) => {
				return renderSafeValue({ value: row.interestPercentage });
			},
			hAlign: 'right',
			isVisible: true,
		},
		{
			id: 'documentNumber',
			header: 'Deed Doc#',
			getValue: ({ row }) => {
				return renderSafeValue({ value: row.documentNumber });
			},
			hAlign: 'right',
			isVisible: true,
		},
	];

	return columns;
};

export type EditablePropertySale = {
	id: number;
};
