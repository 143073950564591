import { showMenu } from 'Singletons/Menu/actions';
import { useMarketsHaveFeature, useUserQuery } from 'api';
import AnalyticsMenu, {
	AnalyticsMenuItems,
} from 'enterprise/Components/Header/AnalyticsMenu';
import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { useIsExchange } from 'hooks';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { routes, useMatch } from 'router';
import {
	AnalyticsContainer,
	HeaderButton,
	HeaderDropdownButton,
	IconHeaderDropdown,
} from './UI';
import { useModal } from 'providers/ModalProvider';
import { NoAnalyticsAccessModal } from 'Components/Modals/NoAnalyticsAccessModal/NoAnalyticsAccessModal';

export const useAnalyticsLinks = () => {
	const dispatch = useDispatch();
	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;
	const isExchangePlusUser = useIsExchangePlusUser();

	const { data: user } = useUserQuery();

	const currentMarketId = user.preferences.currentMarketId;

	// currentMarketId - selected market in exchange, so it's a false permission check
	// as well as checking filters.markets permissions
	// TODO: permission check to be entirely different
	const { data: hasAnalyticsAccess } = useMarketsHaveFeature({
		feature: 'analytics',
		marketIds: [currentMarketId],
	});

	const { openModal } = useModal();

	const isAnalytics = !!useMatch(routes.analytics.path);
	const isDashboard = !!useMatch(routes.analyticsDashboard.path);

	const showAnalyticsMenu = (e: React.MouseEvent) => {
		dispatch(
			showMenu(AnalyticsMenu, e.currentTarget, 'below', {
				isAnalyticsActive: isAnalytics,
				isDashboardActive: isDashboard,
			})
		);
	};

	const dropdownButton = (
		<AnalyticsContainer>
			<HeaderDropdownButton
				onClick={showAnalyticsMenu}
				active={isAnalytics || isDashboard}
			>
				Analytics <IconHeaderDropdown width={11} height={8} />
			</HeaderDropdownButton>
			<AnalyticsMenuItems
				isAnalyticsActive={isAnalytics}
				isDashboardActive={isDashboard}
				showItemsInHeader
			/>
		</AnalyticsContainer>
	);

	let analyticsEl: ReactNode = null;

	if (isEnterprise) {
		if (hasAnalyticsAccess) {
			analyticsEl = dropdownButton;
		} else {
			analyticsEl = (
				<HeaderButton
					onClick={() => {
						openModal({ modalContent: <NoAnalyticsAccessModal /> });
					}}
				>
					Analytics*
				</HeaderButton>
			);
		}
	} else if (isExchangePlusUser) {
		analyticsEl = dropdownButton;
	}

	return analyticsEl;
};
