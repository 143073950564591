import { ExportPdfInput } from 'pdf/ExportPdfInput';
import styled from 'styled-components';

export const OverviewKeyValueTableInput = styled(ExportPdfInput)<{
	isVisible: boolean;
}>`
	font-size: 0.875rem;
	font-weight: 400;
	text-align: right;
	opacity: ${(props) => (props.isVisible ? 1 : 0.4)};
`;

export const OverviewKeyValueTableLabel = styled.div`
	text-transform: none;
	text-align: left !important;
	font-size: 0.875rem;
	font-weight: 400;
`;
