import React from 'react';

import { Styled } from './styled';

import ArrowBottom from '../../../../ui/svg_icons/arrow_bottom.svg';
import useOnClickOutside from 'util/useOnClickOutside';

export type DropdownItem = { id: number | string; value: string };

type DropdownProps = {
	activeItemId: null | DropdownItem['id'];
	items: DropdownItem[];
	onSelect: (itemId: DropdownItem['id']) => void;
	title?: string;
	width?: number;
	defaultLabel?: string;
};

export const Dropdown = ({
	activeItemId,
	items,
	onSelect,
	title,
	width,
	defaultLabel = 'Select Item',
}: DropdownProps) => {
	const dropdownMenuRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = React.useState(false);

	const close = () => setIsOpen(false);

	const handleClick = () => setIsOpen((stateIsOpen) => !stateIsOpen);

	useOnClickOutside(dropdownMenuRef, close);

	React.useEffect(() => {
		return close;
	}, []);

	const onSelectItem = (item: DropdownItem) => {
		const itemId = item.id;
		if (activeItemId !== itemId) {
			onSelect(itemId);
		}
		close();
	};

	const activeItem = items.find((item) => item.id === activeItemId);
	const activeItemLabel = activeItem ? activeItem.value : defaultLabel;

	return (
		<Styled.Wrapper width={width}>
			<Styled.Selector onClick={handleClick}>
				<Styled.SelectorContentWrapper>
					{title && <Styled.SelectorTitle>{title}</Styled.SelectorTitle>}
					<Styled.SelectorLabel>{activeItemLabel}</Styled.SelectorLabel>
				</Styled.SelectorContentWrapper>
				<Styled.SelectorIconWrapper isOpen={isOpen}>
					<ArrowBottom width={10} />
				</Styled.SelectorIconWrapper>
			</Styled.Selector>
			{isOpen && (
				<Styled.ListWrapper ref={dropdownMenuRef}>
					{items.map((item) => (
						<Styled.ListItem key={item.id} onClick={() => onSelectItem(item)}>
							<Styled.ListItemText active={activeItemId === item.id}>
								{item.value}
							</Styled.ListItemText>
						</Styled.ListItem>
					))}
				</Styled.ListWrapper>
			)}
		</Styled.Wrapper>
	);
};
