import { Market } from '@compstak/common';
import { CenterOnBounds, CenterOnPoint } from '@compstak/maps';
import { featureCollection, point } from '@turf/helpers';
import { useMarkets } from 'hooks/useMarkets';
import { CENTER_OF_CONTINENTAL_US } from 'Pages/Search/constants';
import { useMemo } from 'react';

type CenterOnMarketsEffectProps = {
	markets: Market[];
};

export const CenterOnMarketsEffect = ({
	markets,
}: CenterOnMarketsEffectProps) => {
	const totalMarketCount = useMarkets().list.length;
	const allMarketPoints = useMemo(() => {
		return featureCollection(markets.map((m) => point([m.lon, m.lat])));
	}, [markets]);

	return (
		<>
			{markets.length === 1 ? (
				<CenterOnPoint
					latitude={markets[0].lat}
					longitude={markets[0].lon}
					zoom={markets[0].zoom}
				/>
			) : markets.length === totalMarketCount ? (
				<CenterOnPoint {...CENTER_OF_CONTINENTAL_US} />
			) : (
				<CenterOnBounds geoJSON={allMarketPoints} />
			)}
		</>
	);
};
