import { useCallback, useState } from 'react';
import { createSimpleProvider } from 'utils';

export const {
	Provider: SearchPageProvider,
	useProviderContext: useSearchPageContext,
} = createSimpleProvider({
	useValue: () => {
		const [searchState, setSearchState] = useState<SearchState>(initialState);

		const resetSearchState = useCallback(() => {
			setSearchState(initialState);
		}, []);

		return {
			searchState,
			setSearchState,
			resetSearchState,
		};
	},
});

const initialState: SearchState = {
	mapAnalyticsType: null,
};

export type SearchState = {
	mapAnalyticsType: MapAnalyticsType | null;
};

export type MapAnalyticsType =
	| 'marketRent'
	| 'leaseExpiration'
	| 'spaceType'
	| 'tenantIndusty';
