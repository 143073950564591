import styled from 'styled-components';
import { SalesComp } from 'types';
import { UnlockButton, UNLOCK_BUTTON_STATE } from '../UnlockButton';

type Props = {
	data: SalesComp;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
};

export const Overlay = ({ data, hasEnoughCredits }: Props) => {
	return (
		<OverlayContainer>
			<UnlockButton data={data} hasEnoughCredits={hasEnoughCredits} />
		</OverlayContainer>
	);
};

const OverlayContainer = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1;
	overflow: hidden;
`;
