import { Button } from '@compstak/ui-kit';
import { useUnlockComps } from 'Components/Unlock/useUnlockComps';
import { useUser } from 'Pages/Login/reducers';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'util/useAppSelector';
import LockIcon from '../../ui/svg_icons/lock.svg';

export type UnlockButtonComponentProps = {
	compType: 'lease' | 'sale';
};

export const UnlockButtonComponent = ({
	compType,
}: UnlockButtonComponentProps) => {
	const selection = useAppSelector((state) => state.selection.list);
	const lockedSelection = useMemo(() => {
		return selection.filter((c) => 'own' in c && !c.own);
	}, [selection]);

	const { unlock, disabled, isLoading, totalCost } = useUnlockComps({
		type: compType,
		// @ts-expect-error the comps cannot be properties here
		comps: lockedSelection,
	});
	const user = useUser();

	const compIds = useMemo(() => {
		return lockedSelection.map((lease) => lease.id);
	}, [lockedSelection]);

	const buttonText = useMemo(() => {
		switch (true) {
			case totalCost === 0 && user.freeComps > 0:
				return 'FREE';

			case !!user.freeComps || false:
				return `${user.freeComps} FREE + ${totalCost} CREDITS`;

			default:
				return totalCost + ' CREDITS';
		}
	}, [totalCost, user.freeComps]);

	const tooltip = useMemo(() => {
		switch (true) {
			case selection.length === 0:
				return '';

			case (user.pointsAvailable || 0) < totalCost:
				return 'Get more credits to unlock these comps.';

			case totalCost === 0 && user.freeComps > 0:
				return 'Unlock for Free';

			case user.freeComps === 1:
				return `Unlock for ${totalCost} credits. Free comp will be used for the most expensive one.`;

			case (user.freeComps || 0) > 0:
				return `Unlock for ${totalCost} credits. Free comps will be used for the most expensive ${user.freeComps}.`;

			case totalCost > 0:
				return 'Unlock for ' + totalCost + ' credits.';

			default:
				return '';
		}
	}, [totalCost, selection.length, user.freeComps, user.pointsAvailable]);

	return (
		<StyledButton
			variant="success"
			size="l"
			icon={<LockIconStyled width={14} height={17} />}
			onClick={() => unlock({ ids: compIds })}
			isLoading={isLoading}
			disabled={disabled}
			data-tooltipdelay="0"
			data-placement="bottom"
			data-tooltip={tooltip}
		>
			<div>
				<ButtonLabel>UNLOCK</ButtonLabel> ({buttonText})
			</div>
		</StyledButton>
	);
};

export const LockIconStyled = styled(LockIcon)<{ hasDarkColor?: boolean }>`
	fill: ${({ theme }) => theme.colors.white.white};

	path {
		fill: ${({ hasDarkColor, theme }) =>
			hasDarkColor ? '#acb2c0' : theme.colors.white.white};
	}

	&.center-y {
		position: relative;
		top: 4px;
	}
`;

const StyledButton = styled(Button)`
	height: 48px;
`;

const ButtonLabel = styled.span`
	display: none;
	${(p) => p.theme.breakpoints.up('D_1536')} {
		display: inline;
	}
`;
