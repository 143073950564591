import React from 'react';
import { useAppDispatch } from 'util/useAppDispatch';
import { trialExpired } from '../actions';
import { AuthStateType } from '../reducers';
import styles from '../stylesheets/login.less';
import errorMessages from './errorMessages';
import {
	CheckboxChangeEv,
	ForgotPasswdSection,
	LoginBtn,
	LoginFormWrapper,
} from './LoginFormComponents';
import { MfaLoginForm } from './MfaLoginForm';
import { SetupMultiFactorAuth } from 'Components/MultiFactorAuth/SetupMfAuth';
import { LoginLayoutWrapper } from './LoginLayoutComponents';
import { login, getRememberMeUserName } from 'auth/auth';
import { APIClientNotOkResponseError } from '@compstak/common';
import { useAppSelector } from 'util/useAppSelector';

enum LoginStage {
	USERNAME_PASSWD = 'stage-username-passwd',
	MFA_INPUT = 'stage-mfa-input',
	MFA_SETUP = 'stage-mfa-setup',
}

interface State {
	username: string;
	password: string;
	totpCode: string;
	rememberMe: boolean;
	errorMessage: null | string;
	checking: boolean;
	activeStage: LoginStage;
}

interface Props {
	authState: AuthStateType;
	trialExpired: typeof trialExpired;
}

type LoginErrorResponse = APIClientNotOkResponseError<{
	error?: string;
	meta?: string;
	reasons?: string[];
}>;

const SPECIAL_ERROR_CODES = {
	MISSING_2FA: '2FA Code Incorrect',
	REQUIRED_2FA_SETUP: '2FA Setup Required',
	TRIAL_EXPIRED: 'trial_expired',
} as const;

class LoginForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			username: getRememberMeUserName() ?? '',
			password: '',
			totpCode: '',
			rememberMe: true,
			errorMessage: null,
			checking: false,
			activeStage: LoginStage.USERNAME_PASSWD,
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	submitIfEnter = (event) => {
		if (event.keyCode === 13) {
			this.signin();
		}
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	updateState = (event) => {
		const obj = {};
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	updateRememberMe = (event: CheckboxChangeEv) => {
		this.setState({ rememberMe: event.target.checked });
	};

	signin = (event?: React.MouseEvent<HTMLDivElement>) => {
		if (event) {
			event.preventDefault();
		}
		if (!this.state.username && !this.state.password) {
			this.setErrorMessage(errorMessages.noUsernameOrPassword);
			return;
		}
		if (!this.state.username) {
			this.setErrorMessage(errorMessages.noUsername);
			return;
		}
		if (!this.state.password) {
			this.setErrorMessage(errorMessages.noPassword);
			return;
		}

		this.setState({
			errorMessage: null,
			checking: true,
		});

		const { username, password, rememberMe, totpCode } = this.state;

		login({
			username,
			password,
			rememberMe,
			totpCode,
		})
			.catch((err: LoginErrorResponse) => {
				const errorCode = err?.response.data.error;
				const errorReason = err?.response.data.reasons?.[0];

				if (
					errorCode === SPECIAL_ERROR_CODES.MISSING_2FA &&
					this.state.activeStage !== LoginStage.MFA_INPUT
				) {
					this.setState({
						activeStage: LoginStage.MFA_INPUT,
						errorMessage: null,
					});
					return;
				}
				if (errorCode === SPECIAL_ERROR_CODES.REQUIRED_2FA_SETUP) {
					// TODO: Implement this: https://compstak.atlassian.net/browse/AP-9835
					this.setState({
						activeStage: LoginStage.MFA_SETUP,
						errorMessage: null,
					});
					return;
				}
				if (errorReason === SPECIAL_ERROR_CODES.TRIAL_EXPIRED) {
					this.props.trialExpired({ username });
					return;
				}
				this.setErrorMessage(
					errorCode ??
						'Server Error. Request cannot be processed at the moment.'
				);
			})
			.finally(() => {
				this.setState({ checking: false });
			});
	};

	setErrorMessage = (errorMessage: string | null) => {
		return this.setState({ errorMessage });
	};

	render() {
		const { rememberMe, activeStage } = this.state;
		const checking = this.props.authState.checking || this.state.checking;
		if (activeStage === LoginStage.MFA_INPUT) {
			return (
				<LoginLayoutWrapper transitionKey={'login-form-mfa-input'}>
					<MfaLoginForm
						checking={checking}
						signin={this.signin}
						errorMessage={this.state.errorMessage}
						totpCode={this.state.totpCode}
						setTotpCode={(totpCode: string) => this.setState({ totpCode })}
						setErrorMessage={this.setErrorMessage}
					/>
				</LoginLayoutWrapper>
			);
		}
		if (activeStage === LoginStage.MFA_SETUP) {
			return (
				<SetupMultiFactorAuth
					isSignIn
					cancel={() =>
						this.setState((prevState) => ({
							...prevState,
							activeStage: LoginStage.USERNAME_PASSWD,
						}))
					}
					credentials={this.state}
					setTotpCode={(totpCode: string) => this.setState({ totpCode })}
				/>
			);
		}
		return (
			<LoginLayoutWrapper transitionKey={'login-form-username-passwd'}>
				<LoginFormWrapper key="login-form-passwd">
					{this.state.errorMessage && (
						<div className={styles.error_message}>
							{this.state.errorMessage}
						</div>
					)}
					<input
						data-qa-id="username-or-email"
						type="text"
						placeholder="Username or Email"
						name="username"
						onChange={this.updateState}
						value={this.state.username}
						onKeyDown={this.submitIfEnter}
					/>
					<input
						data-qa-id="password"
						type="password"
						name="password"
						placeholder="Password"
						onChange={this.updateState}
						onKeyDown={this.submitIfEnter}
					/>
					<ForgotPasswdSection
						rememberMe={rememberMe}
						updateRememberMe={this.updateRememberMe}
					/>
					<LoginBtn
						data-qa-id="login-btn"
						checking={checking}
						onClick={this.signin}
					/>
				</LoginFormWrapper>
			</LoginLayoutWrapper>
		);
	}
}

const WrappedLogin = () => {
	const dispatch = useAppDispatch();
	const authState = useAppSelector((store) => store.authState);
	return (
		<LoginForm
			authState={authState}
			trialExpired={({ username }) => dispatch(trialExpired({ username }))}
		/>
	);
};

export default WrappedLogin;
