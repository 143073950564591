import { MapAnalyticsNoDataMessage } from 'maps';
import {
	MapAnalyticsMenuContainer,
	MapAnalyticsMenuPositionContainer,
} from '../UI';
import { useLeaseExpirationState } from './LeaseExpirationProvider';
import { LeaseExpirationReadMenu } from './LeaseExpirationReadMenu';
import { LeaseExpirationSettingsMenu } from './LeaseExpirationSettingsMenu';
import { useLeaseExpirationData } from './useLeaseExpirationData';

type Props = {
	onClose: () => void;
};

export const LeaseExpirationMenu = ({ onClose }: Props) => {
	const [state] = useLeaseExpirationState();
	const { data } = useLeaseExpirationData();

	const hasNoData = data && data.length === 0;

	return (
		<MapAnalyticsMenuPositionContainer>
			{hasNoData && <MapAnalyticsNoDataMessage />}
			<MapAnalyticsMenuContainer>
				{state.view === 'read' && <LeaseExpirationReadMenu onClose={onClose} />}
				{state.view === 'settings' && <LeaseExpirationSettingsMenu />}
			</MapAnalyticsMenuContainer>
		</MapAnalyticsMenuPositionContainer>
	);
};
