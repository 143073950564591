import { Feature, Point } from '@turf/helpers';
import { SpaceTypeDataPoint } from 'api';
import { MapAnalyticsTooltipDescription, MapAnalyticsTooltipTitle } from 'maps';
import { LeaseComp } from 'types';

type Props = {
	feature: Feature<Point, SpaceTypeDataPoint>;
	lease: LeaseComp;
};

export const SpaceTypeAnalyticsTooltip = ({ feature, lease }: Props) => {
	return (
		<>
			<MapAnalyticsTooltipTitle>
				{lease.buildingAddressAndCity}
			</MapAnalyticsTooltipTitle>
			<MapAnalyticsTooltipDescription>
				{feature.properties.spaceType}
			</MapAnalyticsTooltipDescription>
		</>
	);
};
