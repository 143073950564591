import { SearchLeasesPayload, useSaleCompQuery } from 'api';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useLeaseSnapshotData } from './useLeaseSnapshotData';

type Props = {
	saleId: number;
	sort?: SearchLeasesPayload['sort'];
};

export const useLeasesActiveAtTimeOfSale = ({
	saleId,
	sort = 'executionQuarter',
}: Props) => {
	const { data: sale, isFetching: isFetchingSale } = useSaleCompQuery({
		id: saleId,
	});
	const { data: leaseSnapshotData, isFetching: isFetchingLeaseSnapshot } =
		useLeaseSnapshotData({ saleId, sort });

	return {
		data: useMemo(() => {
			if (!sale || !leaseSnapshotData) return [];

			const saleDate = sale.saleDate;

			if (!saleDate) return leaseSnapshotData.comps;

			return leaseSnapshotData.comps.filter((lease) => {
				if (
					lease.own &&
					(!lease.commencementDate ||
						dayjs(lease.commencementDate).isBefore(dayjs(saleDate))) &&
					(!lease.expirationDate ||
						dayjs(lease.expirationDate).isAfter(dayjs(saleDate)))
				) {
					return lease;
				}
			});
		}, [sale, leaseSnapshotData]),
		isFetching: isFetchingSale || isFetchingLeaseSnapshot,
	};
};
