import React from 'react';

import styles from 'Pages/StackingPlans/stacking.less';
import button from 'ui/styles/button.less';

type State = any;

export default class Switcher extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);
		this.state = {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'routeParams' does not exist on type 'Rea... Remove this comment to see the full error message
			hideNavigation: this.props.routeParams.displayMode === 'directory',
		};
	}

	getVisibilityStyle() {
		let hiddenStyle;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'routeParams' does not exist on type 'Rea... Remove this comment to see the full error message
		if (this.props.routeParams.displayMode === 'directory') {
			hiddenStyle = {
				visibility: 'hidden',
			};
		}
		return hiddenStyle;
	}

	// @ts-expect-error TS7006: Parameter 'label' implicitly h...
	abbreviateFloorName(label) {
		switch (label.toLowerCase()) {
			case 'mezzanine':
				return 'mezz';
			case 'subbasement':
				return 'sb';
			case 'basement':
				return 'bsmt';
			case 'lower level':
				return 'll';
			case 'lower ground':
				return 'lg';
			case 'concourse':
				return 'conc';
		}
	}

	// @ts-expect-error TS7006: Parameter 'floors' implicitly ...
	renderMiniMap(floors) {
		if (floors <= 15 || !floors) {
			return <div className={styles.mapNavigation} />;
		}

		return (
			// @ts-expect-error TS2322: Type '{ visibility: string; } ...
			<div style={this.getVisibilityStyle()} className={styles.mapNavigation}>
				<ul className={styles.text}>
					<li className={styles.label}>Minimap</li>
					{this.splitFloors(floors)}
				</ul>
			</div>
		);
	}

	// @ts-expect-error TS7006: Parameter 'floorCount' implici...
	splitFloors(floorCount) {
		let beginOfRange = 0;
		const list = [];
		let increment = 10;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
		const stackingPlanFloors = this.props.stackingPlan.floors;
		const floorTypes = [
			'mezzanine',
			'subbasement',
			'basement',
			'lower level',
			'lower ground',
			'concourse',
		];

		// keep number of floor ranges down to control width
		while (Math.floor(floorCount / increment) > 9) {
			increment += 5;
		}

		// prevent "N - N" floor range
		while (floorCount % increment === 1) {
			increment += 1;
		}

		while (beginOfRange < floorCount) {
			let endOfRange = beginOfRange + increment - 1;
			if (endOfRange >= floorCount) {
				endOfRange = floorCount - 1;
			}
			let startText = stackingPlanFloors[beginOfRange].label;
			let endText = stackingPlanFloors[endOfRange].label;

			if (floorTypes.includes(startText.toLowerCase())) {
				startText = this.abbreviateFloorName(startText);
			} else if (floorTypes.includes(endText.toLowerCase())) {
				endText = this.abbreviateFloorName(endText);
			}


			list.push([beginOfRange, endOfRange, startText, endText]);
			beginOfRange += increment;
		}

		return list.map(function (textArray, index) {
			const startLink = '#sp' + textArray[0];
			const endLink = '#sp' + textArray[1];
			const startText = textArray[2];
			const endText = textArray[3];

			return (
				<li key={index}>
					<a className={styles.text} href={startLink}>
						{startText}
					</a>{' '}
					-{' '}
					<a className={styles.text} href={endLink}>
						{endText}
					</a>
				</li>
			);
		});
	}

	render() {
		const selectedView =
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'routeParams' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.routeParams.displayMode === 'directory' ? 'directory' : 'plan';

		return (
			<div className={styles.floorMapRow}>
				{/* @ts-expect-error TS2322: Type '{ visibility: string; } ... */}
				<div style={this.getVisibilityStyle()} className={styles.floorText}>
					Floor
				</div>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message */}
				{this.renderMiniMap(this.props.stackingPlan.floors.length)}

				<div className={button.switch + ' ' + styles.switcher}>
					<ul>
						<li className={selectedView === 'plan' ? button.active : ''}>
							<a
								className={button.button + ' ' + styles.button}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'property' does not exist on type 'Readon... Remove this comment to see the full error message
								href={`/stacking-plan/${this.props.property.id}`}
							>
								Plan
							</a>
						</li>
						<li className={selectedView === 'directory' ? button.active : ''}>
							<a
								className={button.button + ' ' + styles.button}
								// @ts-expect-error ts-migrate(2339) FIXME: Property 'property' does not exist on type 'Readon... Remove this comment to see the full error message
								href={`/stacking-plan/${this.props.property.id}/directory`}
							>
								List
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}
