import { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
	Select,
	capitalizeFirstLetter,
	SelectOnChangeParams,
	SelectContainer,
	OpenButton,
	SelectedOption,
	OptionsList,
	DropdownListItemStyled,
	Placeholder,
} from '@compstak/ui-kit';
import { useAllPresetsQuery } from '../../../../../../api/presets/useAllPresetsQuery';
import { useDispatch } from 'react-redux';
import { CompType } from '../../../../../../types';
import { applyColumnsPreset } from '../../../../actions';
import { useSearch } from 'Pages/Search/searchReducer';
import { useUserQuery } from 'api';
import { COLUMN_PREF_MAP } from 'Components/SearchTable/constants';
import { filterColumnPreferences } from 'Components/SearchTable/filterColumnPreferences';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

interface PresetSelectProps {
	compType: CompType;
}

interface PresetSelectItem {
	value: string;
	title: string;
}

const customOption = {
	value: 'custom',
	title: 'Custom',
};

export const PresetSelect = ({ compType }: PresetSelectProps) => {
	const dispatch = useDispatch();

	const { data, isFetching: isFetchingPresets } = useAllPresetsQuery(compType);
	const { fieldsLoading } = useSearch();
	const { data: user } = useUserQuery();
	const featureFlags = useFeatureFlags();
	const userPrefs = filterColumnPreferences(
		user.preferences[COLUMN_PREF_MAP[compType]],
		featureFlags
	);

	const isFetching = isFetchingPresets || fieldsLoading;

	const dataEntries = useMemo(() => {
		const entries = Object.entries(data || {});

		if (entries.length === 0) return entries;

		// Move summary to the top
		const summaryIdx = entries.findIndex((entry) =>
			entry[0].startsWith('summary')
		);

		const summaryEntry = entries[summaryIdx];

		entries.splice(summaryIdx, 1);
		entries.unshift(summaryEntry);

		return entries;
	}, [data]);

	const [isOpen, setIsOpen] = useState(false);

	const stringifiedActiveAttrs = useMemo(
		() =>
			getSortedIdsString(
				userPrefs.map(({ compAttributeId }) => compAttributeId)
			),
		[userPrefs]
	);

	const stringifiedValues = useMemo(
		() =>
			dataEntries.reduce<Record<string, string>>(
				(acc, [presetName, preferences]) => {
					const stringifiedValue = getSortedIdsString(
						preferences.map((preference) => preference.compAttributeId)
					);

					acc[stringifiedValue] = presetName;
					return acc;
				},
				{}
			),
		[dataEntries]
	);

	const currentPreset = stringifiedValues[stringifiedActiveAttrs] || 'custom';

	const items = dataEntries.map(([presetName]) => {
		return {
			value: presetName,
			title: ['summary', 'summaryWithLoan'].includes(presetName)
				? 'Default'
				: capitalizeFirstLetter(presetName)!,
		};
	});

	const handleChange = ({
		selectedItem,
	}: SelectOnChangeParams<PresetSelectItem>) => {
		if (!selectedItem) return;

		dispatch(applyColumnsPreset(selectedItem.value, compType));
	};

	return (
		<Wrap isOpen={isOpen}>
			<Select<PresetSelectItem>
				items={items}
				onChange={handleChange}
				onOpen={setIsOpen}
				value={isFetching ? 'Loading...' : currentPreset}
				placeholder={
					isFetching
						? 'Loading...'
						: currentPreset === customOption.value
							? customOption.title
							: 'Select preset'
				}
				optionContentRenderer={({ title, value }) =>
					currentPreset === value ? <strong>{title}</strong> : title
				}
				isLoading={isFetching}
				disabled={isFetching}
			/>
		</Wrap>
	);
};

function getSortedIdsString(ids: number[]) {
	return ids.sort((a, b) => a - b).join();
}

const Wrap = styled.div<{ isOpen: boolean }>`
	${SelectContainer} {
		margin-right: 8px;
	}
	${OpenButton} {
		background-color: transparent;
		width: 140px;
		height: 32px;
		border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
		box-shadow: none;

		path {
			stroke: ${({ theme }) => theme.colors.neutral.n0};
		}

		&:focus {
			outline: none;
		}

		${({ isOpen }) => isOpen && 'border-radius: 3px 3px 0px 0px;'}
	}
	${SelectedOption} {
		color: ${({ theme }) => theme.colors.neutral.n0};
		background-color: transparent;
		padding: 0;
		font-size: 14px;
	}
	${Placeholder} {
		color: ${({ theme }) => theme.colors.neutral.n0};
	}
	${OptionsList} {
		width: 140px;
		padding: 0px;
		top: -1px;
		background-color: ${({ theme }) => theme.colors.gray.gray700};
		box-shadow: none;
		border-radius: 0px 0px 2px 2px;
		border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
	}
	${DropdownListItemStyled} {
		height: 32px;

		&:not(:last-child) {
			border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.n100};
		}
		strong {
			font-weight: 600;
		}
		button {
			padding: 0;
			padding-left: 8px;
			color: ${({ theme }) => theme.colors.neutral.n0};
			height: 100%;

			&:hover {
				background-color: ${({ theme }) => theme.colors.neutral.n500};
			}
		}
	}
`;
