import { ErrorMessage } from '@compstak/ui-kit';
import {
	SavedSearchEmailFrequency,
	SavedSearchType,
} from 'api/savedSearches/types';
import { useCreateSavedSearchMutation } from 'api/savedSearches/useCreateSavedSearchMutation';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useModal } from 'providers/ModalProvider';
import { ToastLink, useToast } from 'providers/ToastProvider';
import { ChangeEvent, useEffect, useId, useState } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import { useCompType } from 'router/useCompType';
import styled from 'styled-components';
import IconFloppyDisk from 'ui/svg_icons/floppy-disk.svg';
import pluralizeCompType from 'util/pluralizeCompType';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from './Modals/common/UI';
import { RadioButton, RadioButtonProps } from './RadioButton/RadioButton';

export const CreateSavedSearchModal = () => {
	const nameId = useId();
	const { closeModal } = useModal();
	const [name, setName] = useState('');
	const [emailFrequency, setEmailFrequency] = useState(
		SavedSearchEmailFrequency.Never
	);
	const [isTouched, setIsTouched] = useState(false);
	const [nameError, setNameError] = useState<string | null>(null);

	const [filters] = useFilters();

	const trimmedName = name.trim();

	useEffect(() => {
		if (!isTouched) return;

		let nameError = null;

		if (trimmedName.length === 0) {
			nameError = 'Name is required.';
		}

		setNameError(nameError);
	}, [isTouched, trimmedName, setNameError]);

	const {
		mutateAsync: createSavedSearch,
		isLoading: isLoadingCreateSavedSearch,
	} = useCreateSavedSearchMutation();

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmailFrequency(e.target.value as SavedSearchEmailFrequency);
	};

	const renderEmailFrequencyOption = ({
		label,
		...props
	}: { label: string } & RadioButtonProps) => {
		const id = `emailFreq${props.value}`;

		return (
			<>
				<RadioLabel htmlFor={id}>{label}</RadioLabel>
				<RadioButton
					id={id}
					name={EMAIL_FREQ_NAME}
					onChange={onChange}
					checked={emailFrequency === props.value}
					onBlur={() => setIsTouched(true)}
					{...props}
				/>
			</>
		);
	};

	const isDisabled = name === '' || isLoadingCreateSavedSearch;

	const compType = useCompType();

	const { addToast, removeToast } = useToast();

	return (
		<>
			<ModalTitle>Create a Saved Search</ModalTitle>
			<div style={{ marginBottom: '1.5rem' }}>
				<Label htmlFor={nameId}>
					Name<Required>*</Required>
				</Label>
				<Input
					id={nameId}
					value={name}
					onChange={(e) => {
						setName(e.target.value);
						setIsTouched(true);
					}}
					placeholder="Give your saved search a memorable name"
					onBlur={() => setIsTouched(true)}
				/>
				{nameError && <ErrorMessage>{nameError}</ErrorMessage>}
			</div>
			<div>
				<Subtitle>Notifications</Subtitle>
				<ModalParagraph>
					Select how often you want to be emailed when this saved search is
					updated.
				</ModalParagraph>
				<Grid>
					<Label>Activity</Label>
					<Label>Email</Label>
					{renderEmailFrequencyOption({
						label: 'I don’t want emails sent to me',
						value: SavedSearchEmailFrequency.Never,
					})}
					{renderEmailFrequencyOption({
						label: 'Every time a new comp is added',
						value: SavedSearchEmailFrequency.RealTime,
					})}
					{renderEmailFrequencyOption({
						label: 'Daily updates',
						value: SavedSearchEmailFrequency.Daily,
					})}
					{renderEmailFrequencyOption({
						label: 'Weekly updates',
						value: SavedSearchEmailFrequency.Weekly,
					})}
				</Grid>
			</div>
			<ModalButtons>
				<ModalButton variant="secondary" type="button" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					type="submit"
					variant="primary"
					disabled={isDisabled}
					isLoading={isLoadingCreateSavedSearch}
					icon={<IconFloppyDisk style={{ marginRight: '0.25rem' }} />}
					onClick={async () => {
						try {
							await createSavedSearch({
								name: trimmedName,
								emailFrequency,
								filters: filtersToServerJSON(filters),
								marketId: getFiltersMarkets(filters)[0].id,
								notifyByEmail:
									emailFrequency !== SavedSearchEmailFrequency.Never,
								searchType: pluralizeCompType(
									compType!,
									true
								) as SavedSearchType,
								sortAsc: filters.sortDirection === 'asc',
								sortProperty: filters.sortField,
							});
							closeModal();
							const toast = addToast({
								title: `Successfully created ${trimmedName}!`,
								description: (
									<>
										You can see comps that match your filter criteria any time
										by clicking{' '}
										<ToastLink
											to={routes.savedSearches.toHref()}
											onClick={() => {
												removeToast(toast.id);
											}}
										>
											Saved Searches
										</ToastLink>{' '}
										link in the header bar.
									</>
								),
							});
						} catch (err: any) {
							addToast({
								title: `Error, saved search not created.`,
								description: err?.response?.data?.error,
								type: 'error',
							});
						}
					}}
				>
					Save Search
				</ModalButton>
			</ModalButtons>
		</>
	);
};

const EMAIL_FREQ_NAME = 'emailFrequency';

const Label = styled.label`
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
	display: block;
	margin-bottom: 0.5rem;
`;

const Required = styled.span`
	color: ${(p) => p.theme.colors.red.red500};
`;

const Input = styled.input`
	padding: 0.75rem 1rem;
	width: 100%;
	border-radius: 0.25rem;
	border: 1px solid ${(p) => p.theme.colors.neutral.n50};
	background-color: ${(p) => p.theme.colors.white.white};
	height: 48px;
`;

const Subtitle = styled.div`
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5;
	margin-bottom: 0.5rem;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 0.5rem;
`;

const RadioLabel = styled.label`
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.5;
	display: block;
`;
