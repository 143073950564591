import { SimpleTableColumn, renderTableValue } from '@compstak/ui-kit';
import { PropertyLease } from 'api';
import {
	formatCompQuarter,
	formatObfuscatedSizeRange,
	formatRentWithPostfix,
} from 'format';
import { formatSqft } from 'format/formatSqft';
import { useIsExchange } from 'hooks';
import { IconLockGrey } from 'ui/UI';
import { getIsMonthlyMarket, getShouldShowLockIcon } from 'utils';

export const useSubmarketRecentLeasesTableColumns = (
	marketId?: number
): SimpleTableColumn<PropertyLease>[] => {
	const isExchange = useIsExchange();

	if (!marketId) return [];

	const isMonthly = getIsMonthlyMarket(marketId);

	return [
		{
			id: 'executionQuarter',
			header: () => 'Trans. Qtr.',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.executionQuarter, formatCompQuarter);
			},
			hAlign: 'center',
		},
		{
			id: 'buildingAddress',
			header: () => 'Address',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.buildingAddress);
			},
		},
		{
			id: 'city',
			header: () => 'City',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.city);
			},
		},
		{
			id: 'zip',
			header: () => 'Zip Code',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.zip);
			},
			hAlign: 'center',
		},
		{
			id: 'tenantName',
			header: 'Tenant',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.tenantName);
			},
		},
		{
			id: 'spaceType',
			header: 'Space Type',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.spaceType);
			},
			hAlign: 'center',
		},
		{
			id: 'transactionSize',
			header: 'Size',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				return renderTableValue(row.transactionSize, (v) =>
					row.own ? formatSqft(v) : formatObfuscatedSizeRange(v)
				);
			},
			hAlign: 'center',
		},
		{
			id: 'startingRent',
			header: 'Starting Rent ($/SF)',
			width: 128,
			flex: 1,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.startingRent, row, isExchange)) {
					return <IconLockGrey />;
				}
				return renderTableValue(row.startingRent, (v) =>
					formatRentWithPostfix(v, isMonthly)
				);
			},
			hAlign: 'center',
		},
	];
};
