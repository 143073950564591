import { useState } from 'react';
import {
	MarketRentAnalyticsDashboardPropertyType,
	MarketRentAnalyticsRentType,
	MarketRentAnalyticsSpaceType,
} from '../../../api';
import { createSimpleProvider } from '../../../utils';
import {
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsState,
	MarketRentAnalyticsViewMode,
} from './types';

export const {
	Provider: MarketRentAnalyticsProvider,
	useProviderContext: useMarketRentAnalyticsState,
} = createSimpleProvider({
	useValue: () => useState(initialState),
});

const initialState: MarketRentAnalyticsState = {
	title: 'Market Rent',
	viewMode: MarketRentAnalyticsViewMode.SUBMARKET,
	rentType: MarketRentAnalyticsRentType.STARTING,
	period: MarketRentAnalyticsPeriod.YEAR,
	spaceType: MarketRentAnalyticsSpaceType.OFFICE,
	propertyType: MarketRentAnalyticsDashboardPropertyType.OFFICE,
	menuView: 'read',
};
