import { ModalParagraph, ModalTitle } from 'Components/Modals/common/UI';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Promotion } from './promotionTypes';

type Props = {
	promotion: Promotion;
};

export const PromotionMarketsDialog = ({ promotion }: Props) => {
	const sortedMarkets = useMemo(() => {
		return promotion.markets.sort((a, b) => a.localeCompare(b));
	}, [promotion.markets]);

	return (
		<div>
			<ModalTitle>Markets in this Promotion</ModalTitle>
			<ModalParagraph>
				The following markets are accepted as part of the promotion,{' '}
				<strong>{promotion.title}</strong>.
			</ModalParagraph>
			<MarketsList>
				{sortedMarkets.map((m) => (
					<li key={m}>{m}</li>
				))}
			</MarketsList>
		</div>
	);
};

const MarketsList = styled.ol`
	max-height: 400px;
	overflow-y: auto;
	list-style-type: decimal;
	padding-left: 40px;
`;
