import { Path, Svg } from '@react-pdf/renderer';

export const PdfRealPageLogo = () => {
	return (
		<Svg viewBox="0 0 794.49 97.58" width={64}>
			<Path
				d="M71.03 16.03c0 8.85-7.15 16.03-15.97 16.03S39.1 24.88 39.1 16.03 46.25 0 55.07 0c8.81 0 15.96 7.18 15.96 16.03M31.93 81.55c0 8.85-7.15 16.03-15.97 16.03S0 90.4 0 81.55s7.15-16.03 15.97-16.03 15.96 7.17 15.96 16.03M110.13 81.55c0 8.85-7.15 16.03-15.97 16.03-8.81 0-15.96-7.18-15.96-16.03s7.15-16.03 15.96-16.03c8.83 0 15.97 7.17 15.97 16.03"
				fill="#EE7755"
			/>
			<Path
				d="M628.01 59.4c0-22.22 17.18-37.81 38.51-37.81 12.24 0 23.3 5.22 29.62 12.24l-8.98 9.48c-5.23-5.33-12.54-8.69-20.64-8.69-14.12 0-24.69 10.47-24.69 24.68 0 13.93 10.27 24.69 24.48 24.69 8 0 15.7-3.56 20.44-8.39V64.54h-21.13V52.69h33.57v29.52c-8.98 9.48-20.54 14.71-33.27 14.71-22.01.01-37.91-15.89-37.91-37.52M167.2 68.39h-8.98v27.35h-13.43V22.78h27.16c16.68 0 24.48 10.96 24.48 22.8 0 8.89-4.64 17.77-15.3 21.24l18.37 28.93h-16zm-8.99-12.15h12.25c8.78 0 12.14-5.22 12.14-10.56 0-5.53-3.65-10.36-10.66-10.36h-13.73zM230.5 22.78h48.48v12.64h-34.95V52.6h32.29v12.54h-32.29v17.97h34.95v12.64H230.5zM350.86 79.15h-25.87l-6.02 16.59h-14.32l28.24-72.97h10.37l27.94 72.97h-14.32zm-21.91-12.14h18.07l-8.98-24.98zM399.43 22.78h13.53v60.03h37.52v12.93h-51.05zM530.69 46.57c0 12.15-7.7 23.41-26.27 23.41h-11.85v25.77h-13.43V22.78h26.36c17.88 0 25.19 11.85 25.19 23.79m-13.73-.2c0-5.82-3.85-11.05-11.16-11.05h-13.24v22.31h12.25c8.5 0 12.15-5.73 12.15-11.26M591.32 79.15h-25.87l-6.02 16.59h-14.32l28.24-72.97h10.37l27.94 72.97h-14.32zM569.4 67.01h18.07l-8.98-24.98zM727.99 22.78h48.48v12.64h-34.95V52.6h32.29v12.54h-32.29v17.97h34.95v12.64h-48.48zM788.77 22.78c3.19 0 5.73 2.47 5.73 5.62s-2.54 5.66-5.76 5.66-5.8-2.5-5.8-5.66 2.57-5.62 5.8-5.62zm-.07 1.13c-2.44 0-4.32 2.02-4.32 4.49 0 2.5 1.89 4.49 4.39 4.49 2.44.03 4.29-1.99 4.29-4.46 0-2.5-1.85-4.53-4.32-4.53h-.04zm-.86 7.41h-1.3V25.7c.52-.07 1.23-.17 2.16-.17 1.06 0 1.54.17 1.92.45.34.24.58.65.58 1.2 0 .69-.52 1.13-1.17 1.34v.07c.55.17.82.62.99 1.37.17.86.31 1.17.41 1.37h-1.37c-.17-.21-.27-.69-.45-1.37-.1-.58-.45-.86-1.17-.86h-.62v2.22zm.03-3.16h.62c.72 0 1.3-.24 1.3-.82 0-.52-.38-.86-1.2-.86-.34 0-.58.03-.72.07z"
				fill="#232526"
			/>
		</Svg>
	);
};
