import { useActiveMfaSetupScreen } from 'Components/MultiFactorAuth/MfAuthHooks';
import {
	DisableMultiFactorAuth,
	SetupMultiFactorAuth,
} from 'Components/MultiFactorAuth/SetupMfAuth';
import {
	usePermissions,
	useReferenceData,
	useUser,
} from 'Pages/Login/reducers';
import { useMemo, useState } from 'react';
import layoutStyles from 'ui/styles/layout.less';
import Account from '../../ui/svg_icons/account.svg';
import EmailText from '../../ui/svg_icons/email_text.svg';
import LockLarge from '../../ui/svg_icons/lock_large.svg';
import Rewards from '../../ui/svg_icons/rewards.svg';
import TeamIcon from '../../ui/svg_icons/team.svg';
import Upgrade from '../../ui/svg_icons/upgrade.svg';
import AccountSettings from './Components/AccountSettings';
import EmailSettings from './Components/EmailSettings';
import NotFound from './Components/NotFound';
import { PasswordSettings } from './Components/PasswordSettings';
import { shouldRewardsLinkBeShown } from './Components/Rewards/index';
import TeamSettings from './Components/TeamSettings';
import './styles/content.nomodule.less';
import styles from './styles/settings.less';
import { useMarkets } from 'hooks/useMarkets';
import {
	useTeamQuery,
	Team,
	useTeamAssignCreditsMutation,
} from '../../api/team/teamQueries';
const classMap = {
	account: AccountSettings,
	email: EmailSettings,
	team: TeamSettings,
	password: PasswordSettings,
};

type Props = {
	params: { page: string };
};

function ExchangeSettings(props: Props) {
	const user = useUser();
	const markets = useMarkets();
	const permissions = usePermissions();
	const { data: team } = useTeamQuery(user?.team?.id);
	const { mutate: assignCredits } = useTeamAssignCreditsMutation(
		user?.team?.id
	);

	const referenceData = useReferenceData();

	const isRewardsPageShown = useMemo(
		() => shouldRewardsLinkBeShown(user),
		[user]
	);
	const {
		activeMfaScreen,
		hideActiveMfaScreen,
		showDisableMfaScreen,
		showSetupMfaScreen,
	} = useActiveMfaSetupScreen();
	const [totpCode, setTotpCode] = useState('');

	// @ts-expect-error TS7053: Element implicitly has an 'any...
	let SubComponent = classMap[props.params.page];
	if (!SubComponent || (props.params.page === 'team' && !user.isTeamAdmin)) {
		SubComponent = NotFound;
	}

	const shortcutLabelClasses = {
		[props.params.page]: styles.selected,
	};

	const teamEl =
		user.team && user.isTeamAdmin ? (
			<li className={shortcutLabelClasses.team || ''}>
				<a href="/settings/team">
					<span>
						<TeamIcon width={29} height={21} />
					</span>
					<span className={styles.shortcutLabel}>TEAM</span>
				</a>
			</li>
		) : null;

	if (activeMfaScreen === 'mfa-setup-screen') {
		return (
			<SetupMultiFactorAuth
				cancel={hideActiveMfaScreen}
				// @ts-expect-error TS2739: Type '{ totpCode: string; }' i...
				credentials={{ totpCode }}
				setTotpCode={(code: string) => setTotpCode(code)}
			/>
		);
	}
	if (activeMfaScreen === 'mfa-disable-screen') {
		return <DisableMultiFactorAuth cancel={hideActiveMfaScreen} />;
	}

	return (
		<section className={layoutStyles.contentWithSidebar}>
			<div className={layoutStyles.sidebar}>
				<ul className={styles.tabItemView}>
					<li className={shortcutLabelClasses.account || ''}>
						<a href="/settings/account">
							<span>
								<Account width={27} height={22} />
							</span>
							<span className={styles.shortcutLabel}>USER ACCOUNT</span>
						</a>
					</li>
					<li className={shortcutLabelClasses.email || ''}>
						<a href="/settings/email">
							<span>
								<EmailText width={28} height={28} />
							</span>
							<span className={styles.shortcutLabel}>EMAIL SUBSCRIPTIONS</span>
						</a>
					</li>
					<li className={shortcutLabelClasses.credits || ''}>
						<a href="/credits">
							<span>
								<Upgrade width={25} height={25} />
							</span>
							<span className={styles.shortcutLabel}>CREDITS</span>
						</a>
					</li>
					{isRewardsPageShown && (
						<li className={shortcutLabelClasses.rewards || ''}>
							<a href="/rewards">
								<span>
									<Rewards width={26} height={26} />
								</span>
								<span className={styles.shortcutLabel}>REWARDS</span>
							</a>
						</li>
					)}

					<li className={shortcutLabelClasses.password || ''}>
						<a href="/settings/password">
							<span>
								<LockLarge width={27} height={30} />
							</span>
							<span className={styles.shortcutLabel}>PASSWORD</span>
						</a>
					</li>
					{teamEl}
				</ul>
			</div>
			<div className={`${layoutStyles.content}`}>
				<SubComponent
					permissions={permissions}
					markets={markets}
					user={user}
					referenceData={referenceData}
					team={team}
					assignCredits={(teamInfo: Team) => {
						assignCredits(teamInfo);
					}}
					showSetupMfaScreen={showSetupMfaScreen}
					showDisableMfaScreen={showDisableMfaScreen}
				/>
			</div>
		</section>
	);
}

export default ExchangeSettings;
