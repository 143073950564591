import dayjs from 'dayjs';
import {
	calcMonthsQuantityInPeriod,
	LinesChartProps,
	VerticalBarChartProps,
	VerticalStackBarWithLineChartProps,
	Period,
} from '@compstak/ui-kit';

type Data =
	| LinesChartProps['data']
	| VerticalBarChartProps['data']
	| VerticalStackBarWithLineChartProps['data'];

export const calcXTicks = (
	data: Data,
	period: Period
): Array<string | number> => {
	const diffInMonths = calcMonthsQuantityInPeriod(period);
	const getFilteredByCondition = getFiltered.bind(null, diffInMonths);
	const ticks = data?.filter(getFilteredByCondition).map((item) => item.name);

	return ticks;
};

// @ts-expect-error TS7006: Parameter 'condition' implicit...
const getFiltered = (condition, item, index, arr) => {
	switch (true) {
		case condition > 24:
			return index === 0 || !isSameYear(item.name, arr[index - 1].name);

		case condition > 8:
		case arr.length <= 3:
			return index === 0 || dayjs(item.name).month() % 3 === 0;

		default:
			return true;
	}
};

export const isSameYear = (date1: string, date2: string): boolean =>
	dayjs(date1).year() === dayjs(date2).year();
