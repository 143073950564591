import { Map, MapInteractivity, MapStyle } from '@compstak/maps';
import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import {
	MarketRentAnalyticsLayers,
	MarketRentAnalyticsLegend,
	MarketRentAnalyticsLegendContainer,
	MarketRentAnalyticsLegendVariant,
	useMarketRentAdjustedPercentiles,
	useMarketRentAnalyticsState,
} from 'maps';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { useMarketRentDescriptionText } from '../../MarketRentMap';
import { useDashboardMarketRentData } from '../../useDashboardMarketRentData';
import { PanelDescription, PDFPanel } from './UI';

export const MarketRentMap = () => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();
	const [filters] = useFilters('exchange-dashboard');
	const { data: marketRentData } = useDashboardMarketRentData();

	const adjustedPercentiles = useMarketRentAdjustedPercentiles({
		percentiles: marketRentData?.percentiles ?? [],
	});

	const { data: submarketsData } = useSubmarketPolygons(filters.market);

	const descriptionText = useMarketRentDescriptionText(filters.market);

	return (
		<PDFPanel
			id="market-rent-heat-map"
			title={`${marketRentAnalyticsState.propertyType} Market Rent Heat Map`}
		>
			<MapContainer>
				<Map
					initialViewState={{
						latitude: filters.market.lat,
						longitude: filters.market.lon,
						zoom: filters.market.zoom,
					}}
					interactivity={MapInteractivity.STATIC}
					mapStyle={MapStyle.MONOCHROME}
				>
					<MarketRentAnalyticsLayers
						data={marketRentData}
						adjustedPercentiles={adjustedPercentiles}
						submarketData={submarketsData}
					/>
					<MarketRentAnalyticsLegendContainer
						variant={MarketRentAnalyticsLegendVariant.SMALL}
					>
						<MarketRentAnalyticsLegend
							percentiles={adjustedPercentiles}
							variant={MarketRentAnalyticsLegendVariant.SMALL}
						/>
					</MarketRentAnalyticsLegendContainer>
				</Map>
			</MapContainer>
			<PanelDescription>
				{descriptionText} Data available for office and industrial property
				types in select markets only.
			</PanelDescription>
		</PDFPanel>
	);
};

const MapContainer = styled.div`
	height: 332px;
	position: relative;
	width: 100%;
`;
