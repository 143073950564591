import { UsePortfolioFormProps, usePortfolioForm } from 'Components';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { PortfolioFormFieldsV2 } from 'Components/PortfolioForm';
import { usePortfolioActions } from 'PortfolioAnalytics/PortfolioOptions/usePortfolioActions';
import { useModal } from 'providers/ModalProvider';
import { routes, useNavigate } from 'router';

export type Actions = 'create' | 'edit' | 'copy';

type Props = UsePortfolioFormProps & {
	title: string;
	action: Actions;
};
export const PortfolioModal = ({
	editedPortfolio,
	initialFormState,
	title,
	action,
}: Props) => {
	const portfolioForm = usePortfolioForm({
		editedPortfolio,
		initialFormState,
	});

	const { closeModal } = useModal();
	const { copyPortfolio, createPortfolio, updatePortfolio, isLoading } =
		usePortfolioActions();

	const navigate = useNavigate();

	return (
		<>
			<ModalTitle>{title}</ModalTitle>
			<form
				onSubmit={async (e) => {
					e.preventDefault();

					if (action === 'create') {
						const newPortfolio = await createPortfolio({
							name: portfolioForm.state.name,
							description: portfolioForm.state.description || undefined,
						});

						navigate(
							routes.portfolioByIdView.toHref({
								portfolioId: newPortfolio.id,
								viewType: 'dashboard',
							})
						);
					}

					if (action === 'edit') {
						if (!editedPortfolio) return;

						const body = {
							id: editedPortfolio.id,
							name: portfolioForm.state.name,
							description: portfolioForm.state.description || undefined,
						};

						await updatePortfolio({
							id: editedPortfolio.id,
							body,
						});
					}

					if (action === 'copy') {
						if (!editedPortfolio) return;

						await copyPortfolio({
							portfolioId: editedPortfolio.id,
							name: portfolioForm.state.name,
							description: portfolioForm.state.description || undefined,
						});
					}

					closeModal();
				}}
			>
				<PortfolioFormFieldsV2 {...portfolioForm} action={action} />

				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeModal}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={
							!portfolioForm.isValid || portfolioForm.isFormLoading || isLoading
						}
						isLoading={isLoading || portfolioForm.isFormLoading}
					>
						{title}
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};
