import React from 'react';
import { AveragesPanel } from './AveragesPanel';
import { LargestLandlordsChart } from './LargestLandlordsChart';
import { LargestTenantsChart } from './LargestTenantsChart';
import { LeaseExpirationPropertyType } from './LeaseExpirationPropertyType';
import { LeaseExpirationSubmarket } from './LeaseExpirationSubmarket';
import { MarketRentMap } from './MarketRentMap';
import { StartingRent } from './StartingRent';
import TenantIndustryDistribution from './TenantIndustryDistribution';
import { TenantIndustryMap } from './TenantIndustryMap';
import { ExchangeSection, Row } from './UI';
import { Section } from './types';

type Props = {
	setSectionsInView: React.Dispatch<React.SetStateAction<Section['type'][]>>;
};

export const LeaseInsights: React.FC<Props> = React.memo((props) => {
	return (
		<ExchangeSection
			id="lease-insights"
			title="Lease Insights"
			setSectionsInView={props.setSectionsInView}
		>
			<AveragesPanel />
			<Row>
				<MarketRentMap />
				<StartingRent />
			</Row>
			<Row>
				<LeaseExpirationPropertyType />
				<LeaseExpirationSubmarket />
			</Row>
			<Row>
				<TenantIndustryMap />
				<TenantIndustryDistribution />
			</Row>
			<LargestLandlordsChart />
			<LargestTenantsChart />
		</ExchangeSection>
	);
});

LeaseInsights.displayName = 'LeaseInsights';
