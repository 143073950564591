import React from 'react';

import styles from '../styles/details.less';
import button from 'ui/styles/button.less';

export default class LeaseCredits extends React.Component {
	creditsConfig() {
		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			!this.props.comp.own &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.freeComps === 0 &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.pointsAvailable < this.props.comp.cost
		) {
			return {
				text: 'Not enough credits to unlock this comp',
				class: styles.leaseCreditsGreen,
			};
		} else if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			!this.props.comp.own &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.freeComps === 0 &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.pointsAvailable < this.props.comp.cost * 2
		) {
			return {
				text: 'Looks like you’re running low on credits',
				class: styles.leaseCreditsBlue,
			};
		} else {
			return {
				text: '',
				class: 'hidden',
			};
		}
	}

	render() {
		const creditsText = this.creditsConfig().text;
		const creditsClass = this.creditsConfig().class;

		return (
			<div className={`${styles.leaseCredits} ${creditsClass}`}>
				<div className={styles.leftSide}>
					<p>{creditsText}</p>
				</div>
				<div className={styles.rightSide}>
					<a
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'getCredits' does not exist on type 'Read... Remove this comment to see the full error message
						href={this.props.getCredits}
						className={`${button.button} ${styles.getCreditsButton}`}
					>
						Get Credits
					</a>
				</div>
			</div>
		);
	}
}
