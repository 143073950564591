import { useUserQuery } from 'api';
import styled from 'styled-components';
import IconCross from '../../ui/svg_icons/cross.svg';

type Props = {
	close: () => void;
};

export const ProfileMenuHeader = ({ close }: Props) => {
	const { data: user } = useUserQuery();

	return (
		<Root>
			<ProfileMenuHeaderText>
				{user.firstName} {user.lastName}
			</ProfileMenuHeaderText>
			<ProfileMenuCloseButton onClick={close}>
				<StyledIconCross />
			</ProfileMenuCloseButton>
		</Root>
	);
};

const Root = styled.div`
	padding: 0 1.25rem;
	height: 50px;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ProfileMenuHeaderText = styled.div`
	color: ${(p) => p.theme.colors.white.white};
	font-size: 0.75rem;
	line-height: 1.5;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledIconCross = styled(IconCross)`
	fill: ${(p) => p.theme.colors.neutral.n85};
`;

const ProfileMenuCloseButton = styled.button`
	all: unset;
	padding: 0.25rem;
	cursor: pointer;
	&:hover ${StyledIconCross} {
		fill: ${(p) => p.theme.colors.white.white};
	}
`;
