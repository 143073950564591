import { CommonMenu } from '@compstak/ui-kit';
import { PropertySections } from '../type';

export const allMenuItems: Array<CommonMenu<PropertySections>> = [
	{ url: PropertySections.OVERVIEW, title: 'Overview' },
	{ url: PropertySections.COMPS, title: 'Comps' },
	{ url: PropertySections.UNIT_MIX, title: 'Unit mix' },
	{ url: PropertySections.COMP_SET, title: 'Comp. Set' },
	{ url: PropertySections.STACKING_PLAN, title: 'Stkg. plan' },
	{ url: PropertySections.SUBMARKET, title: 'Submarket' },
	{ url: PropertySections.TRANSIT_SCREEN, title: 'TransitScreen' },
];
