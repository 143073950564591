import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api';
import { UnderlyingLeaseResponse } from './types';
import { API } from '@compstak/common';
import { SearchPayload, SEARCH_INFINITE_PAGE_SIZE } from 'api';

export type UnderlyingLeasesInfiniteParams = Omit<
	SearchPayload,
	'offset' | 'limit'
> & {
	trendMonths: number;
	fullDataThreshold: number;
};

const fetchInfiniteUnderlyingLeases = async ({
	pageParam,
	filter,
	sort,
	order,
	properties,
	signal,
	trendMonths,
	fullDataThreshold,
}: UnderlyingLeasesInfiniteParams & {
	pageParam: number;
	signal?: AbortSignal;
}) => {
	const res = await API.post<UnderlyingLeaseResponse>(
		'/api/comps/insights/trendlineUnderlying',
		{
			offset: pageParam * SEARCH_INFINITE_PAGE_SIZE,
			limit: SEARCH_INFINITE_PAGE_SIZE,
			filter,
			sort,
			order,
			properties,
			trendMonths,
			fullDataThreshold,
		},
		{ signal }
	);

	const lastReqCount = res.data.comps.length;
	const totalCount = res.data.totalCount;
	const totalCountSoFar = pageParam * SEARCH_INFINITE_PAGE_SIZE + lastReqCount;
	const hasMore = totalCount > totalCountSoFar;

	const nextPageSize = Math.min(
		totalCount - totalCountSoFar,
		SEARCH_INFINITE_PAGE_SIZE
	);

	return { ...res.data, pageParam, hasMore, nextPageSize };
};

export const useSearchUnderlyingLeasesInfiniteQuery = (
	params: UnderlyingLeasesInfiniteParams
) => {
	return useInfiniteQuery({
		queryKey: createUnderlyingLeasesInfiniteQueryKey(params),
		queryFn: ({ pageParam = 0, signal }) =>
			fetchInfiniteUnderlyingLeases({ ...params, pageParam, signal }),
		getNextPageParam: (last) => {
			if (!last.hasMore) return;
			return last.pageParam + 1;
		},
	});
};

export const createUnderlyingLeasesInfiniteQueryKey = (
	params: UnderlyingLeasesInfiniteParams
) => {
	return [QUERY_KEYS.UNDERLYING_LEASES, params];
};
