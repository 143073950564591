import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import {
	MarketRentAnalyticsLayers,
	MarketRentAnalyticsLegend,
	MarketRentAnalyticsLegendContainer,
	MarketRentAnalyticsLegendVariant,
	useMarketRentAdjustedPercentiles,
} from '../../../../maps';
import { useMarketRentState } from '../../MarketRentProvider';
import { usePortfolioMarketRentData } from './usePortfolioMarketRentData';

export const MarketRentAnalytics = () => {
	const [state] = useMarketRentState();

	const marketId = state.selectedMarketId;

	const { data: marketRentData } = usePortfolioMarketRentData();

	const adjustedPercentiles = useMarketRentAdjustedPercentiles({
		percentiles: marketRentData?.percentiles ?? [],
	});

	const { data: submarketsData } = useSubmarketPolygons(
		{ id: marketId! },
		{
			enabled: marketId != null,
		}
	);

	return (
		<>
			<MarketRentAnalyticsLayers
				data={marketRentData}
				adjustedPercentiles={adjustedPercentiles}
				submarketData={submarketsData}
				polygonsLayerProps={{
					highlightColor: undefined,
				}}
			/>
			<MarketRentAnalyticsLegendContainer
				variant={MarketRentAnalyticsLegendVariant.SMALL}
			>
				<MarketRentAnalyticsLegend
					percentiles={adjustedPercentiles}
					variant={MarketRentAnalyticsLegendVariant.SMALL}
				/>
			</MarketRentAnalyticsLegendContainer>
		</>
	);
};
