import React from 'react';
import LockIcon from '../../../../ui/svg_icons/lock.svg';
import { isEstimated } from 'util/estimated';
import { createFilters } from 'models/filters';
import { isListFilterKey } from 'models/filters/util/isListFilterKey';

import StartingRent from 'Components/AttributeValues/StartingRent';
import MissingValue from 'Components/AttributeValues/MissingValue';
import EffectiveRent from 'Components/AttributeValues/EffectiveRent';
import { ReportedRentWithDate } from 'Components/AttributeValues/ReportedRentWithDate';
import AttributeValue from 'Components/AttributeValues/AttributeValue';

import styles from '../styles/details.less';

import IconHelp from '../../../../ui/svg_icons/help_v2.svg';

import pluralizeCompType from 'util/pluralizeCompType';
import { nullValueText } from '@compstak/common';
import { filtersToQueryString } from 'models/filters/util/urls';
import { LeaseComp } from '../../../../types';
import { COMP_FIELD_TOOLTIPS } from '../../../../constants/tooltips';
import { MarketsState } from 'Pages/Login/reducers';
import { FormattedComp } from 'util/comp-format/src/format';

type CompItemProps = {
	item: FormattedComp;
	comp: LeaseComp;
	displayName: string;
	name: keyof LeaseComp;
	markets: MarketsState;
	isMonthly: boolean;
};

export default class CompItem extends React.Component<
	CompItemProps,
	{ showingUnlockSuccess: boolean }
> {
	componentWillReceiveProps(props: CompItemProps) {
		if (this.props.comp.id !== props.comp.id) {
			this.setState({
				showingUnlockSuccess: false,
			});
		}

		if (!this.props.comp.own && props.comp.own) {
			this.setState({
				showingUnlockSuccess: true,
			});
		}
	}

	compItemClass() {
		return this.state && this.state.showingUnlockSuccess
			? styles.itemUnlocked
			: '';
	}

	compInfoClass() {
		return this.props.item.value !== null && this.props.item.value !== undefined
			? styles.full
			: '';
	}

	getValue() {
		if (
			this.props.item.showAlertIfNotPresent &&
			this.props.item.value === undefined
		) {
			return <MissingValue displayName={this.props.displayName} />;
		} else if (this.props.name === 'startingRent') {
			return (
				<StartingRent
					isMonthly={this.props.isMonthly}
					comp={this.props.comp}
					abbreviateText={false}
				/>
			);
		} else if (this.props.item.value === null) {
			return (
				<div className={styles.lockedData}>
					<LockIcon width={14} height={16} />
				</div>
			);
		} else if (this.props.item.value === undefined) {
			return <div className={styles.data}>{nullValueText}</div>;
		} else if (isEstimated(this.props.comp, this.props.name)) {
			return (
				<div
					className={styles.data}
					data-placement="bottom"
					data-tooltipdelay="0"
					data-tooltip="This date is estimated based on other information we have on the comp"
				>
					{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
					<AttributeValue value={this.props.item.value} />{' '}
					<span style={{ color: '#cbced8' }}> (Estimated)</span>
				</div>
			);
		} else if (this.props.name === 'insideviewWebsites') {
			const value = this.props.comp[this.props.name];
			const values = Array.isArray(value) ? value : [value ?? ''];
			return (
				<div className={styles.data}>
					{values.map((website) => (
						<a
							className={styles.truncate}
							key={website}
							href={
								website.substring(0, 4) === 'http' ? website : `//${website}`
							}
							target="_blank"
							rel="noreferrer"
						>
							{website}
						</a>
					))}
				</div>
			);
		} else if (
			this.props.name === 'tenantName' ||
			this.props.name === 'landlordName' ||
			this.props.name === 'tenantIndustry' ||
			this.props.name === 'landlordRealtyCompanies' ||
			this.props.name === 'tenantRealtyCompanies'
		) {
			const value = this.props.comp[this.props.name];
			const values = Array.isArray(value) ? value : [value ?? ''];

			return (
				<div className={styles.data}>
					{values.map((value, i) => {
						const filters = createFilters('lease', {
							market: this.props.markets[this.props.comp.market],
							[this.props.name]: isListFilterKey(this.props.name)
								? [value]
								: value,
						});
						const url = `/search/${pluralizeCompType(
							'lease',
							false
						)}/list?${filtersToQueryString(filters)}`;
						if (i === values.length - 1) {
							return (
								<a href={url} key={url}>
									{value}
								</a>
							);
						} else {
							return [
								<a href={url} key={url}>
									{value}
								</a>,
								', ',
							];
						}
					})}
				</div>
			);
		} else if (this.props.name === 'effectiveRent') {
			return (
				<EffectiveRent
					isMonthly={this.props.isMonthly}
					abbreviateText={false}
					value={this.props.item.value}
				/>
			);
		} else if (this.props.name === 'reportedRentWithDate') {
			return (
				<ReportedRentWithDate
					comp={this.props.comp}
					isMonthly={this.props.isMonthly}
				/>
			);
		} else {
			return (
				<div className={styles.data}>
					{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
					<AttributeValue
						value={this.props.item.value}
						showNullValueText={true}
					/>
				</div>
			);
		}
	}

	render() {
		const getValue = this.getValue();
		const compItemClass = this.compItemClass();
		const compInfoClass = this.compInfoClass();

		if (
			['amps', 'elevators', 'parkingNotes', 'volts'].includes(this.props.name)
		) {
			return null;
		} else if (this.props.name === 'comments' && this.props.comp.own) {
			return (
				<li className={`${compItemClass}`}>
					<div>{getValue}</div>
				</li>
			);
		} else {
			return (
				<li className={`${compItemClass}`}>
					<span className={styles.label}>
						{this.props.displayName}
						{COMP_FIELD_TOOLTIPS[this.props.name] && (
							<span
								className={styles.capRateIcon}
								data-tooltip={COMP_FIELD_TOOLTIPS[this.props.name]}
								data-tooltip-position="above"
							>
								<IconHelp />
							</span>
						)}
					</span>
					<div className={`${styles.info} ${compInfoClass}`}>{getValue}</div>
				</li>
			);
		}
	}
}
