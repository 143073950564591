import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useIsMultifamily } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { filterToServerJSON } from 'models/filters/util/serverJson';
import {
	DEFAULT_SUGGESTIONS_LIMIT,
	Suggestion,
	SuggestionField,
} from 'api/suggestions/suggestions';
import { CompType } from 'types';
import { ServerFilterItem } from 'types/serverFilters';
import { encodeQuery } from 'util/encodeQuery';
import { trimString } from 'util/trimString';
import { QUERY_KEYS } from '../../constants';

export const useSuggestionsV2Query = <T extends SuggestionField>(
	params: UseSuggestionsV2QueryParams<T>,
	options?: UseSuggestionsV2QueryOptions<T>
) => {
	const {
		compType,
		field,
		marketId,
		query: _query,
		limit = DEFAULT_SUGGESTIONS_LIMIT,
		filters,
	} = params;
	const query = trimString(_query);
	const hidden = (
		filterToServerJSON(filters?.hidden, 'hidden') as ServerFilterItem<'hidden'>
	).value;
	const partial = (
		filterToServerJSON(
			filters?.partial ?? null,
			'partial'
		) as ServerFilterItem<'partial'>
	).value;
	const queryParams = encodeQuery({
		field,
		marketId,
		query,
		limit,
		hidden,
		partial,
		// TODO: AP-15284 - to remove once it is `true` on the BE side by default
		includeTypeSuggestion: true,
	});
	const isMufa = useIsMultifamily({ markets: params.marketId });
	const compTypePrefix =
		compType === 'property'
			? `${isMufa ? 'mufa/' : ''}properties/actions`
			: compType === 'sale'
				? 'salesComps'
				: 'comps/actions';

	return useQuery({
		queryKey: [QUERY_KEYS.SUGGESTIONS_V2, params],
		queryFn: async () => {
			const response = await API.get<SuggestionsV2Response<T>>(
				`/api/${compTypePrefix}/suggestions/v2${queryParams}`
			);

			return response.data;
		},
		enabled: query.length > 2,
		...options,
	});
};

type UseSuggestionsV2QueryOptions<T extends SuggestionField> = UseQueryOptions<
	SuggestionsV2Response<T>
>;

type UseSuggestionsV2QueryParams<T extends SuggestionField> = {
	compType: CompType;
	query: string;
	marketId: number[];
	limit?: number;
	field?: T;
	filters?: FiltersObject;
};

type SuggestionsV2Response<T extends SuggestionField> = Suggestion<'v2', T>[];
