import { CheckedState } from '@radix-ui/react-checkbox';
import { MapFilterMultiSelect } from 'Components/Filters/Base/Filter/MapFilterMultiSelect';
import { FilterOption } from 'Components/Filters/Base/Multiselect';
import { useMarketToOpportunityZones } from 'api/opportunityZones/useMarketToOpportunityZones';
import { useMarkets } from 'hooks/useMarkets';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useLayoutEffect, useMemo } from 'react';
import abbreviateNumber from 'ui/util/abbreviateNumber';

type OpportunityZonesFilterProps = {
	filters: FiltersObject;
	isActive: boolean;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setIsLoading?: (isLoading: boolean) => void;
};

const MAX_CHECKED_MARKETS_NUMBER = 15;

const attribute = 'opportunityZoneId' as const;

export const OpportunityZonesFilter = ({
	filters,
	isActive,
	onFilterChange,
	setIsLoading,
}: OpportunityZonesFilterProps) => {
	const { isFetching, marketIdToOpportunityZones } =
		useMarketToOpportunityZones(getFiltersMarkets(filters), {
			enabled: isActive,
		});
	const markets = useMarkets();

	useLayoutEffect(() => {
		setIsLoading?.(isFetching);
	}, [isFetching, setIsLoading]);

	const filtersOpportunityZoneIds = useMemo(
		() => filters.opportunityZoneId ?? [],
		[filters.opportunityZoneId]
	);

	const marketNameToChecked = useMemo(() => {
		if (!marketIdToOpportunityZones) {
			return undefined;
		}

		const getIsChecked = (_marketId: number | string) => {
			const marketId = Number(_marketId);
			const marketOpportunityZoneIds = Object.values(
				marketIdToOpportunityZones[marketId]
			).map(
				(opportunityZoneFeature) => opportunityZoneFeature.properties.GEOID10
			);

			if (!filtersOpportunityZoneIds.length) {
				return false;
			}

			let isChecked: CheckedState = false;
			for (let i = 0; i < marketOpportunityZoneIds.length; i++) {
				const marketOpportunityZoneId = marketOpportunityZoneIds[i];
				if (filtersOpportunityZoneIds.includes(marketOpportunityZoneId)) {
					isChecked =
						i === marketOpportunityZoneIds.length - 1 ? true : 'indeterminate';
					continue;
				}

				if (isChecked === 'indeterminate') {
					break;
				}
			}
			return isChecked;
		};

		const marketToOpportunityZones = Object.keys(
			marketIdToOpportunityZones
		).reduce<Record<string, CheckedState>>((acc, marketId) => {
			const marketDisplayName = markets[marketId].displayName;
			acc[marketDisplayName] = getIsChecked(marketId);
			return acc;
		}, {});

		return Object.keys(marketToOpportunityZones)
			.sort()
			.reduce<Record<string, CheckedState>>((obj, sortedDisplayName) => {
				obj[sortedDisplayName] = marketToOpportunityZones[sortedDisplayName];
				return obj;
			}, {});
	}, [marketIdToOpportunityZones, markets, filtersOpportunityZoneIds]);

	const toggleOpportunityZones = (marketId: number, checked: boolean) => {
		const marketOpportunityZoneIds = Object.values(
			marketIdToOpportunityZones?.[marketId] ?? {}
		).map(
			(opportunityZoneFeature) => opportunityZoneFeature.properties.GEOID10
		);
		const opportunityZoneIds = checked
			? [...filtersOpportunityZoneIds, ...marketOpportunityZoneIds]
			: filtersOpportunityZoneIds.filter(
					(oppZoneId) => !marketOpportunityZoneIds.includes(oppZoneId)
				);

		onFilterChange({
			opportunityZoneId: opportunityZoneIds,
		});
	};

	const hasMultipleMarkets =
		Object.keys(marketIdToOpportunityZones ?? {}).length > 1;

	const exceedsMaxCheckedMarkets =
		Object.values(marketNameToChecked ?? {}).filter((isChecked) => !!isChecked)
			.length >= MAX_CHECKED_MARKETS_NUMBER;

	const options: FilterOption[] = useMemo(() => {
		if (!marketNameToChecked) {
			return [];
		}

		return Object.keys(marketNameToChecked).map((marketName) => {
			const marketId = markets[marketName].id;
			const isChecked = marketNameToChecked[marketName];
			const isDisabled = !isChecked && exceedsMaxCheckedMarkets;

			return {
				name: marketName,
				id: marketId,
				disabledTooltip: isDisabled
					? `You cannot select more than ${MAX_CHECKED_MARKETS_NUMBER} markets for Opportunity Zones at this time`
					: undefined,
			};
		});
	}, [exceedsMaxCheckedMarkets, marketNameToChecked, markets]);

	return (
		<MapFilterMultiSelect
			attribute={attribute}
			filter={filters[attribute]}
			onFilterChange={onFilterChange}
			options={options}
			hasSearch={hasMultipleMarkets}
			searchInputPlaceholder="Search for market"
			renderAllOption={() => undefined}
			renderAllPlaceholder={() => 'All opportunity zones'}
			isOptionChecked={(option) => {
				if (!marketNameToChecked) {
					return false;
				}
				return marketNameToChecked[option.name];
			}}
			onChange={(values, checked) => {
				values.forEach((value) =>
					toggleOpportunityZones(Number(value), checked)
				);
			}}
			renderCheckedChips={() => {
				return [
					`${abbreviateNumber(filtersOpportunityZoneIds.length)} Zone${filtersOpportunityZoneIds.length === 1 ? '' : 's'} Selected`,
				];
			}}
			isLoading={isFetching}
		/>
	);
};
