import React from 'react';
import { FilterCategory } from './FilterCategory';
import { PropertyTypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeFilter';
import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';
import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { SaleDateFilter } from 'Components/Filters/Fields/SaleDateFilter/SaleDateFilter';

const inputId = 'sale-commonlyUsedFilters' as const;

export const CommonlyUsedSaleFilters = () => {
	return (
		<FilterCategory category="Commonly Used Filters">
			<PropertyTypeFilter inputId={inputId}>
				<PropertySubtypeFilter inputId={inputId} />
			</PropertyTypeFilter>
			<SaleDateFilter inputId={inputId} />

			<TransactionSizeFilter inputId={inputId} />

			<BuildingClassFilter inputId={inputId} />
			<BuildingSizeFilter inputId={inputId} />
		</FilterCategory>
	);
};
