import { TableStyled } from '../../../../Components/ReorderGlobalStyle';
import { Table, TableProps } from 'fixed-data-table-2';
import React from 'react';

type State = { gridWidth: number; gridHeight: number };

type ResponsiveFixedDataTableProps = TableProps & {
	lastExpectedChange: Date | null;
	setActionsColumnWidth: () => void;
};

class ResponsiveFixedDataTable extends React.Component<
	ResponsiveFixedDataTableProps,
	State
> {
	static defaultProps = {
		lastExpectedChange: null,
	};

	state = {
		gridWidth: 0,
		gridHeight: 0,
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this._setDimensionsOnState);
	}

	// @ts-expect-error TS7006: Parameter 'prevProps' implicit...
	componentDidUpdate(prevProps) {
		if (prevProps.lastExpectedChange !== this.props.lastExpectedChange) {
			this._setDimensionsOnState();
		}
	}

	_attachResizeEvent() {
		const win = window;

		if (win.addEventListener) {
			win.addEventListener('resize', this._setDimensionsOnState, false);
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'attachEvent' does not exist on type 'Win... Remove this comment to see the full error message
		} else if (win.attachEvent) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'attachEvent' does not exist on type 'Win... Remove this comment to see the full error message
			win.attachEvent('resize', this._setDimensionsOnState);
		} else {
			win.onresize = this._setDimensionsOnState;
		}
	}

	_setDimensionsOnState = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'tableWrapperNode' does not exist on type... Remove this comment to see the full error message
		if (this.tableWrapperNode) {
			this.setState({
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'tableWrapperNode' does not exist on type... Remove this comment to see the full error message
				gridWidth: this.tableWrapperNode.clientWidth,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'tableWrapperNode' does not exist on type... Remove this comment to see the full error message
				gridHeight: this.tableWrapperNode.clientHeight,
			});
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'setActionsColumnWidth' does not exist on... Remove this comment to see the full error message
			this.props.setActionsColumnWidth(this.tableWrapperNode.clientWidth);
		}
	};

	// @ts-expect-error TS7006: Parameter 'el' implicitly has ...
	_setContainer = (el) => {
		if (el) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'tableWrapperNode' does not exist on type... Remove this comment to see the full error message
			this.tableWrapperNode = el.parentNode;
			this._setDimensionsOnState();
			this._attachResizeEvent();
		}
	};

	/**
	 * @return {ReactDOMNode}
	 */
	render() {
		return (
			<div ref={this._setContainer}>
				{this.state.gridWidth ? (
					<TableStyled
						{...this.props}
						ref={this.props.ref as React.Ref<Table>}
						width={this.state.gridWidth}
						height={this.state.gridHeight}
					/>
				) : null}
			</div>
		);
	}
}

export default ResponsiveFixedDataTable;
