import {
	numberUtils,
	renderTableValue,
	SimpleTableColumn,
} from '@compstak/ui-kit';
import { CherreLoan } from 'api';
import { formatDate, formatPercent } from 'format';
import { formatYesNo } from 'format/formatYesNo';
import { isObject } from 'lodash';

export const publicLoanColumns: SimpleTableColumn<CherreLoan>[] = [
	{
		id: 'originationDate',
		header: () => 'Origination Date',
		body: ({ row }) => renderTableValue(row.loanOriginationDate, formatDate),
		width: 94,
	},
	{
		id: 'loanPurpose',
		header: () => 'Loan Purpose',
		// TODO: delete isObject check when the bug is fixed(returns en empty object instead of null/undefined)
		body: ({ row }) =>
			renderTableValue(isObject(row.loanPurpose) ? null : row.loanPurpose),
		width: 80,
		flex: 1,
	},
	{
		id: 'lender',
		header: () => 'Lender',
		body: ({ row }) => renderTableValue(row.loanOriginator),
		width: 150,
		flex: 2,
	},
	{
		id: 'loanAmount',
		header: () => 'Loan Amount',
		body: ({ row }) =>
			renderTableValue(row.loanAmount, (v) =>
				numberUtils.formatCurrency(v, 'currencyInteger')
			),
		width: 100,
		flex: 1,
	},
	{
		id: 'originalLoanTerm',
		header: () => 'Original Loan Term',
		body: ({ row }) => renderTableValue(row.loanTerm, (v) => `${v} Months`),
		width: 100,
		flex: 1,
	},
	{
		id: 'loanType',
		header: () => 'Loan Type',
		body: ({ row }) => renderTableValue(row.loanType),
		width: 90,
	},
	{
		id: 'originationLoanRate',
		header: () => 'Origination Loan Rate',
		body: ({ row }) =>
			renderTableValue(row.originationLoanRate, (v) => formatPercent(v / 100)),
		width: 80,
	},
	{
		id: 'loanMaturityDate',
		header: () => 'Loan Maturity Date',
		body: ({ row }) => renderTableValue(row.loanMaturityDate, formatDate),
		width: 100,
		flex: 1,
	},
	{
		id: 'prePaymentPenalty',
		header: () => 'Pre Payment Penalty',
		body: ({ row }) => renderTableValue(row.loanPrePaymentPenalty, formatYesNo),
		width: 100,
	},
	{
		id: 'interestOnlyLoan',
		header: () => 'Interest-Only Loan',
		body: ({ row }) => renderTableValue(row.loanInterestOnlyLoan, formatYesNo),
		width: 100,
	},
	{
		id: 'ioLoanTerm',
		header: () => 'IO Loan Term',
		body: ({ row }) =>
			renderTableValue(row.loanInterestOnlyPeriod, (v) =>
				formatDate(v, 'MMM YYYY')
			),
		width: 100,
	},
];
