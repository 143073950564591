import { AppState } from 'reducers/root';
import { getAttributeHash } from 'util/comp-format/src/format';

type SubmissionMap = Record<
	string,
	{
		label: string;
		unit: string;
		postUnit: string;
	}
>;

export function getLeaseSubmissionMapFromAppStore(
	store: AppState
): SubmissionMap {
	const attributeHash = getAttributeHash('lease');
	const submissionKeys = Object.keys(store.uploads.leaseSubmission ?? {});
	const submissionMap = getSubMap({
		submissionKeys,
		attributeHash,
		compType: 'lease',
	});
	return submissionMap;
}

export function getSaleSubmissionMapFromAppStore(
	store: AppState
): SubmissionMap {
	const attributeHash = getAttributeHash('sale');
	const submissionKeys = Object.keys(store.uploads.saleSubmission ?? {}).filter(
		(key) => key !== 'additionalAddresses'
	);
	const submissionMap = getSubMap({
		submissionKeys,
		attributeHash,
		compType: 'sale',
	});
	return submissionMap;
}

function getSubMap({
	submissionKeys,
	attributeHash,
	compType,
}: {
	submissionKeys: string[];
	attributeHash: ReturnType<typeof getAttributeHash>;
	compType: 'lease' | 'sale';
}): SubmissionMap {
	const submissionMap = {} as SubmissionMap;
	submissionKeys.forEach((key) => {
		// @ts-expect-error TS7015: Element implicitly has an 'any...
		const attrVal = attributeHash[key];
		if (attrVal == null) {
			const errorMsg =
				'Failed to get attributeHash[key] value for ' +
				`compType="${compType}" key="${key}"`;
			throw Error(errorMsg);
		}
		submissionMap[key] = {
			label: attrVal.displayName,
			unit: attrVal.preUnit || attrVal.postUnit,
			postUnit: attrVal.postUnit || '',
		};
	});
	return submissionMap;
}
