import React, { useState, useEffect, useRef } from 'react';

import EmailIcon from '../../../../../ui/svg_icons/email_light.svg';
import TrashIcon from '../../../../../ui/svg_icons/delete.svg';
import DuplicateIcon from '../../../../../ui/svg_icons/duplicate.svg';
import TickIcon from '../../../../../ui/svg_icons/tick.svg';
import EditIcon from '../../../../../ui/svg_icons/edit.svg';

import { ShareChartModal } from '../Modals/ShareChartModal';
import { DeleteChartModal } from '../Modals/DeleteChartModal';
import { DuplicateChartModal } from '../Modals/DuplicateChartModal';

import { AnimatedDataSetRows } from './DataSetRows';

import styles from '../../repository.less';
import { Chart, Project } from 'Pages/Analytics/analytics';
import { formatDate } from 'format';
import { ModalActions } from 'Singletons/Modal/actions';
import { useModal } from 'providers/ModalProvider';
import { AnalyticsProjectActions } from '../../actions';
import styled from 'styled-components';

type Props = {
	handleToggle: (chartId: number) => void;
	openNewChart: (chart: Chart) => void;
	chart: Chart;
	modalActions: ModalActions;
	project: Project;
	colorClass: string;
	toggled: boolean;
	analyticsProjectActions: AnalyticsProjectActions;
};

const ChartRow: React.FC<Props> = (props) => {
	const [chart, setChart] = useState(props.chart);
	const [title, setTitle] = useState(props.chart.title ?? '');
	const [focused, setFocused] = useState(false);

	const myRef = useRef<HTMLInputElement>(null);

	const { openModal } = useModal();

	useEffect(() => {
		if (props.chart.title !== chart.title) {
			setChart(props.chart);
			setTitle(props.chart.title);
		}
	}, [props.chart]);

	const handleToggle = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		if (props.chart.id) {
			props.handleToggle(props.chart?.id);
		}
	};

	const openNewChart = (event: React.MouseEvent<HTMLDivElement>) => {
		if (!event.isDefaultPrevented()) {
			props.openNewChart(props.chart);
		}
	};

	const openShareModal = (event: React.MouseEvent<HTMLOrSVGElement>) => {
		event.preventDefault();

		// props.modalActions.pushModal(shareChartModal, props);
		openModal({ modalContent: <ShareChartModal chart={props.chart} /> });
	};

	const openDeleteModal = (event: React.MouseEvent<HTMLOrSVGElement>) => {
		event.preventDefault();

		openModal({
			modalContent: <DeleteChartModal chart={props.chart} />,
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const openDuplicateModal = (event) => {
		event.preventDefault();

		openModal({
			modalContent: (
				<DuplicateChartModal chart={props.chart} project={props.project} />
			),
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const handleTitleChange = (event) => {
		if (event.target.value.length <= 100) {
			setTitle(event.target.value);
		}
	};

	const handleTitleFocus = () => {
		setFocused(true);
	};

	const handleTitleBlur = () => {
		handleTitleSubmit();
		setFocused(false);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const handleKeyUp = (event) => {
		if (event.keyCode === 13) {
			event.currentTarget.blur();
		}
	};

	const handleIconClick = (event: { preventDefault: () => void }) => {
		event.preventDefault();
		if (focused) {
			handleTitleSubmit();
			setFocused(false);
			myRef.current?.blur();
		}
	};

	const handleTitleSubmit = () => {
		if (title !== chart.title) {
			const trimmedTitle = title.trim();
			if (trimmedTitle.length === 0) {
				props.analyticsProjectActions.nonameChartError();
				setTitle(chart.title);
			} else if (props.project.charts.some((c) => c.title === trimmedTitle)) {
				props.analyticsProjectActions.renameChartError();
				setTitle(chart.title);
			} else {
				props.analyticsProjectActions.saveChart(
					{
						...chart,
						title: trimmedTitle,
					},
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'markets' does not exist on type 'Readonly<Props>'.
					props.markets
				);
			}
		}
	};

	// @ts-expect-error TS7006: Parameter 'projectName' implicit...
	const isNew = (projectName, chartDate) => {
		// is it a received chart more than a week old?
		return (
			projectName === 'Received Charts' &&
			// @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation must be of type 'any'.
			Date.now() - new Date(chartDate) <= 604800
		);
	};

	const arrowBoxClass = props.toggled ? styles.toggled : '';

	let icon;
	let iconClass;
	let focusClass;
	if (focused) {
		icon = <TickIcon />;
		iconClass = styles.iconFocus;
		focusClass = styles.focused;
	} else {
		icon = <EditIcon />;
		iconClass = styles.iconHidden;
	}

	let newTag;
	let newTagInputClass;
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'project' does not exist on type 'Readonly<Props>'.
	if (isNew(props.project.name, props.chart.dateCreated)) {
		newTag = <span className={styles.newTag}>New</span>;
		newTagInputClass = styles.newTagInput;
	}

	const chartRow = (
		<div className={`${styles.row} ${props.colorClass}`} onClick={openNewChart}>
			<span
				className={`${styles.arrowBox} ${arrowBoxClass}`}
				onClick={handleToggle}
			>
				<div className={styles.arrow} />
			</span>
			<div className={styles.name}>
				{newTag}
				<span
					className={`${styles.customInput} ${focusClass} ${newTagInputClass}`}
					onClick={(event) => event.preventDefault()}
				>
					<input
						className={styles.nameInput}
						value={title}
						onChange={handleTitleChange}
						onFocus={handleTitleFocus}
						onBlur={handleTitleBlur}
						onKeyUp={handleKeyUp}
						ref={myRef}
					/>
					<div
						className={`${styles.iconContainer} ${iconClass}`}
						onClick={handleIconClick}
					>
						{icon}
					</div>
				</span>
			</div>
			<span className={styles.dateCreated}>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Readonly<Props>'. */}
				{formatDate(props.chart.dateCreated)}
			</span>
			<span className={styles.chartDateModified}>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Readonly<Props>'. */}
				{formatDate(props.chart.lastUpdated)}
			</span>
			<StyledSpan onClick={openDuplicateModal}>
				<DuplicateIconStyled />
			</StyledSpan>

			<StyledSpan onClick={openShareModal}>
				<EmailIconStyled />
			</StyledSpan>

			<StyledSpan onClick={openDeleteModal}>
				<TrashIconStyled />
			</StyledSpan>
		</div>
	);

	return (
		<>
			{chartRow}
			<AnimatedDataSetRows
				dataSets={props.chart.dataSets}
				toggled={props.toggled}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'referenceData' does not exist on type 'Readonly<Props>'.
				spaceTypeMap={props.referenceData.spaceTypes}
			/>
		</>
	);
};

const DuplicateIconStyled = styled(DuplicateIcon)`
	fill: ${({ theme }) => theme.colors.blue.blue400};
`;

const TrashIconStyled = styled(TrashIcon)`
	fill: #f16852;
`;

const EmailIconStyled = styled(EmailIcon)`
	fill: ${({ theme }) => theme.colors.green.green500};
`;

const StyledSpan = styled.span`
	padding: 0.75rem;
	margin-right: 0.5rem;

	cursor: pointer;
`;

export default ChartRow;
