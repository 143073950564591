import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { pdfStyle } from '../style';
import { PdfPageProps } from '../types';
import { defaultTheme } from '@compstak/ui-kit';
import { PdfTable } from '../PdfTable';
import { SubmarketRecentColumn } from './useSubmarketRecentLeasesPdfColumns';

type Props = PdfPageProps & {
	columns: SubmarketRecentColumn[];
	editableSubmarketRecentLeasesRow: Record<string, string | number>[];
};
export const SubmarketRecentLeasesPdfTable = (props: Props) => {
	const visibleColumns = useMemo(
		() => props.columns.filter((c) => c.isVisible),
		[props.columns]
	);

	const topNumber = props.editableSubmarketRecentLeasesRow.length;

	return (
		<View break>
			<View>
				<Text style={pdfStyle.pageTitle}>Submarket Recent Leases</Text>
				<Text style={style.tableTitle}>
					{topNumber === 1
						? 'Most recent lease '
						: `Top ${topNumber} most recent leases `}
					in {props.property.submarket}
				</Text>
			</View>
			<PdfTable
				columns={visibleColumns}
				rows={props.editableSubmarketRecentLeasesRow ?? []}
			/>
		</View>
	);
};

const style = StyleSheet.create({
	tableTitle: {
		width: 330,
		backgroundColor: defaultTheme.colors.gray.gray200,
		fontSize: 10.5,
		marginTop: 3,
		marginBottom: 12,
		padding: '4px 40px 4px 4px',
		borderRadius: 2.25,
	},
});
