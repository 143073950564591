import {
	formatDate,
	formatMoney,
	formatNumber,
	formatObfuscatedSizeRange,
} from 'format';
import { PdfColumn } from 'pdf/PdfTable';
import { SalesComp } from 'types';
import { renderSafeValue } from 'utils';

export const useSalesPdfColumns = (): PdfColumn<SalesComp>[] => {
	return [
		{
			id: 'buildingAddress',
			header: 'Address',
			body: ({ row }) => row.portfolio[0].buildingAddress,
		},
		{
			id: 'city',
			header: 'City',
			body: ({ row }) => row.portfolio[0].city,
		},
		{
			id: 'submarket',
			header: 'Submarket',
			body: ({ row }) => row.portfolio[0].submarket,
		},
		{
			id: 'saleDate',
			header: 'Sale Date',
			body: ({ row }) =>
				renderSafeValue({
					value: row.saleDate,
					format: (v) => formatDate(v, 'MMM D, YYYY'),
				}),
		},
		{
			id: 'totalSalePrice',
			header: 'Total Sale Price',
			body: ({ row }) =>
				renderSafeValue({
					value: row.totalSalePrice,
					format: (v) => formatMoney(v),
				}),
		},
		{
			id: 'transactionSize',
			header: 'Transaction SQFT',
			body: ({ row }) =>
				renderSafeValue({
					value: row.transactionSize,
					format: (v) =>
						row.own
							? formatNumber(v, { shorten: false })
							: formatObfuscatedSizeRange(v),
				}),
		},
		{
			id: 'salePrice',
			header: 'Sale Price (PSF)',
			body: ({ row }) =>
				renderSafeValue({
					value: row.salePricePsf,
					format: (v) => `${formatMoney(v)} PSF`,
				}),
		},
	];
};
