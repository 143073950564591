import React, { memo, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
	WidgetContainer,
	WidgetTitle,
	RowFlexContainer,
	PeriodSelector,
	SaveDataMenu,
	Pending,
	LinesChart,
	HelpCircle,
	Tooltip,
	numberUtils,
	TickFormatter,
	ErrorUI,
	HeightContainer,
	LinesChartProps,
	getDownloadFileName,
	PeriodSelectorProps,
	defaultTheme,
	createTypedLineChartLegend,
	Period,
} from '@compstak/ui-kit';
import { RequestStates } from '../../../../Pages/PropertyPageV2_1/api';
import {
	years,
	calcXTicks,
	getXTickDateFormatter,
} from '../../../../Pages/PropertyPageV2_1/utils';
import { addEmptyLines } from '@compstak/common';
import dayjs from 'dayjs';
import { SuggestedSpan } from '../../../../Pages/PropertyPageV2_1/type';
import { NOIMetric, NOIDTO } from 'api';

export type NOIProps = RequestStates & {
	data: LinesChartProps['data'];
	onPeriodChange: PeriodSelectorProps['onChange'];
	suggestedSpan: SuggestedSpan;
	period: Period;
	metric: NOIMetric;
	address: string;
};

export const NOI = memo<NOIProps>(
	({
		data,
		suggestedSpan,
		isSuccess,
		isLoading,
		isError,
		error,
		period,
		onPeriodChange,
		metric,
		address,
	}) => {
		const [ref, setRef] = useState<HTMLElement | null>(null);
		const LineChartLegend = createTypedLineChartLegend<NOIDTO>();

		const chartId = `${widgetTitle}${address}`;

		const line = useMemo(
			() => ({
				value: widgetTitle,
				title: 'Submarket',
				type: 'line',
				isConnectNulls: false,
				color: defaultTheme.colors.blue.blue700,
			}),
			[]
		);

		const dataWithEmptyNOI = useMemo(
			() => data.map((el) => ({ ...el, empty_NOI: el.NOI })),
			[data]
		);

		const emptyLines = useMemo(() => addEmptyLines([line]), [line]);

		const yTickFormatter = useCallback<NonNullable<TickFormatter>>(
			(value) => {
				const metricToFormatter = {
					perUnit: numberUtils.formatCurrency(
						value,
						'currencyOneDecimal',
						'financial'
					),
					percentageOfRevenue: numberUtils.formatPercent(
						value,
						'percentInteger'
					),
				};

				return metricToFormatter[metric];
			},
			[metric]
		);

		const xTicks = useMemo(() => calcXTicks(data, period), [data, period]);

		const dataPeriod = useMemo(() => {
			return `${dayjs(period.start).format('MM/YYYY')} - ${dayjs(
				period.end
			).format('MM/YYYY')}`;
		}, [period]);

		const dataCount = useMemo(() => {
			let count = 0;

			data?.forEach((dataRecord) => {
				if (dataRecord.NOI !== 'NaN') {
					count++;
				}
			});

			return count;
		}, [data]);

		const tooltipFormatter: TickFormatter = (value) =>
			value === 'NaN'
				? '--'
				: numberUtils.formatCurrency(value, 'currencyInteger');

		return (
			<Root>
				<HeaderContainer>
					<RowFlexContainer>
						<Title ref={setRef} data-qa-id={titleTestId}>
							{widgetTitle} Per Unit
						</Title>
						<Tooltip
							tooltipComponent={tooltipComponent}
							placement="bottom-end"
							tooltipRootStyle={tooltipRootStyle}
							refToObservePositionChange={ref}
						>
							<HelpCircle />
						</Tooltip>
						<PeriodSelector
							period={period}
							onChange={onPeriodChange}
							years={years}
							data-qa-id="period-selector"
						/>
					</RowFlexContainer>
					<SaveDataMenu
						downloadFileName={getDownloadFileName(address, widgetTitle)}
						elementId={chartId}
						dataToCopy={data}
					/>
				</HeaderContainer>
				<HeightContainer>
					{isLoading && <Pending times={4} />}
					{isSuccess && (
						<LinesChart
							lines={[line, ...emptyLines]}
							data={dataWithEmptyNOI}
							yTickFormatter={yTickFormatter}
							xTickFormatter={getXTickDateFormatter(period)}
							xTicks={xTicks}
							xInterval={0}
							id={chartId}
							yTooltipPosition={-15}
							isWithForecastArea={true}
							yAxisLabel={'NOI $/Unit'}
							tooltipFormatter={tooltipFormatter}
							dataPeriod={dataPeriod}
							dataCount={dataCount}
							// @ts-expect-error TS2322: Type 'SuggestedSpan' is not as...
							hasDataForSomePeriod={suggestedSpan}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<LegendContainer>
					{/* @ts-expect-error TS2741: Property 'trendLine' is missin... */}
					<LineChartLegend lines={[line]} data={dataWithEmptyNOI} />
				</LegendContainer>
			</Root>
		);
	}
);

NOI.displayName = 'NOI';
export const titleTestId = 'titleTestId';

const widgetTitle = 'NOI';

const tooltipComponent = (
	<>
		NOI metrics are sourced from individual leases executed via RealPage, Inc.
		software products in&nbsp;a&nbsp;specified time period. Lease-transaction
		data is&nbsp;aggregated by&nbsp;market and submarket.
	</>
);

const Root = styled(WidgetContainer)`
	margin: 0;
	width: auto;
`;

const LegendContainer = styled.div`
	margin-top: 20px;
`;
export const HeaderContainer = styled(RowFlexContainer)`
	margin-bottom: 16px;
`;

export const Title = styled(WidgetTitle)`
	margin-right: 4px;
`;

const tooltipRootStyle = {
	margin: '0 16px 0 0',
};
