import { API } from '@compstak/common';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { Feature, MultiPolygon, Polygon } from '@turf/helpers';
import { QUERY_KEYS } from 'api/constants';

export const useOpportunityZonesByMarketQuery = (
	params: UseOpportunityZonesByMarketQueryParams,
	options?: UseOpportunityZonesByMarketQueryOptions
): UseOpportunityZonesByMarketQueryResponse => {
	return useQuery({
		queryKey: [QUERY_KEYS.OPPORTUNITY_ZONES, params],
		queryFn: () => fetchOpportunityZonesByMarket(params.id),
		...options,
	});
};

// For some reason BE returns non-conventional Polygon | MultiPolygon feature format
// and here we map it to the conventional geojson feature
type BackendOpportunityZoneFeature = Omit<
	OpportunityZoneFeature,
	'geometry'
> & {
	geoPolygon: OpportunityZoneFeature['geometry'];
};

type BackendOpportunityZonesResponse = {
	type: 'FeatureCollection';
	features: BackendOpportunityZoneFeature[];
};

export const fetchOpportunityZonesByMarket = async (
	marketId: number
): Promise<OpportunityZonesResponse> => {
	const response = await API.get<BackendOpportunityZonesResponse>(
		`/api/markets/${marketId}/opportunityZones`
	);

	return {
		...response.data,
		features: response.data.features.map((feature) => ({
			properties: feature.properties,
			geometry: feature.geoPolygon,
			type: feature.type,
		})),
	};
};

type OpportunityZoneProperties = {
	GEOID10: string;
	marketIds: number[];
};

export type OpportunityZoneFeature = Feature<
	Polygon | MultiPolygon,
	OpportunityZoneProperties
>;

export type OpportunityZonesResponse = {
	type: 'FeatureCollection';
	features: OpportunityZoneFeature[];
};

export type UseOpportunityZonesByMarketQueryParams = { id: number };

export type UseOpportunityZonesByMarketQueryOptions =
	UseQueryOptions<OpportunityZonesResponse>;

export type UseOpportunityZonesByMarketQueryResponse =
	UseQueryResult<OpportunityZonesResponse>;
