import { useSubmarketsByMarket } from 'api/submarkets/useSubmarketsByMarket';
import dayjs from 'dayjs';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import { useExchangeDashboardState } from '../../reducers/exchangeDashboard';

export const useCreateAdditionalFilters = (filters: FiltersObject) => {
	const { fullPageState } = useExchangeDashboardState();
	const { data: marketSubmarkets } = useSubmarketsByMarket(filters.market);

	const result: Partial<FiltersObject> | null = useMemo(() => {
		if (fullPageState?.type !== 'list') return null;

		// @ts-expect-error TS2339: Property 'year' does not exist...
		const fullPageStateYear = dayjs().year(fullPageState.year);

		switch (fullPageState?.field) {
			case 'tenantIndustry':
				return { tenantIndustry: [fullPageState.industry] };

			case 'propertyType':
				return {
					buildingPropertyTypeId: [fullPageState.propertyTypeId],
					expirationDate: {
						allowFallback: false,
						min:
							fullPageState.year === currentYear
								? fullPageStateYear.toDate()
								: fullPageStateYear.startOf('year').toDate(),
						max: fullPageStateYear.endOf('year').toDate(),
					},
				};

			case 'submarket': {
				const submarket = marketSubmarkets?.find(
					({ name }) => fullPageState.submarket === name
				);

				return {
					submarkets: submarket ? [submarket] : [],
					expirationDate: {
						allowFallback: false,
						min:
							fullPageState.year === currentYear
								? fullPageStateYear.toDate()
								: fullPageStateYear.startOf('year').toDate(),
						max: fullPageStateYear.endOf('year').toDate(),
					},
				};
			}

			case 'tenantName':
				return {
					tenantNames: [fullPageState.tenantName],
				};

			case 'landlordName': {
				return {
					landlordNames: [fullPageState.landlordName],
					expirationDate: {
						min: dayjs().toDate(),
						max: null,
					},
				};
			}

			default:
				return null;
		}
	}, [
		fullPageState?.field,
		// @ts-expect-error TS2339: Property 'industry' does not e...
		fullPageState?.industry,
		// @ts-expect-error TS2339: Property 'landlordName' does n...
		fullPageState?.landlordName,
		// @ts-expect-error TS2339: Property 'propertyTypeId' does...
		fullPageState?.propertyTypeId,
		// @ts-expect-error TS2339: Property 'submarket' does not ...
		fullPageState?.submarket,
		// @ts-expect-error TS2339: Property 'tenantName' does not...
		fullPageState?.tenantName,
		fullPageState?.type,
		// @ts-expect-error TS2339: Property 'year' does not exist...
		fullPageState?.year,
		marketSubmarkets,
	]);

	return result;
};

const currentYear = new Date().getFullYear();
