import { AnalyticsChip } from 'Components';
import { MapAnalyticsActions } from '../MapAnalyticsActions';
import { MapAnalyticsCloseButton } from '../MapAnalyticsCloseButton';
import {
	MapAnalyticsInfo,
	MapAnalyticsInfoTitle,
	MapAnalyticsMenuContent,
	MapAnalyticsMenuHeader,
} from '../UI';
import { SpaceTypeAnalyticsDescription } from './SpaceTypeAnalyticsDescription';
import { SpaceTypeAnalyticsLegend } from './SpaceTypeAnalyticsLegend';
import { useSpaceTypeAnalyticsState } from './SpaceTypeAnalyticsProvider';

type Props = {
	onClickClose: () => void;
};

export const SpaceTypeAnalyticsReadMenu = ({ onClickClose }: Props) => {
	const [state, setState] = useSpaceTypeAnalyticsState();

	return (
		<>
			<MapAnalyticsMenuHeader>
				<AnalyticsChip />
				<MapAnalyticsCloseButton
					data-qa-id="analytics-close-btn"
					onClick={onClickClose}
				/>
			</MapAnalyticsMenuHeader>
			<MapAnalyticsInfo>
				<MapAnalyticsInfoTitle>{state.title}</MapAnalyticsInfoTitle>
				<SpaceTypeAnalyticsDescription />
				<MapAnalyticsActions
					onClickSettings={() => {
						setState((s) => ({ ...s, view: 'settings' }));
					}}
				/>
			</MapAnalyticsInfo>
			<MapAnalyticsMenuContent>
				<SpaceTypeAnalyticsLegend />
			</MapAnalyticsMenuContent>
		</>
	);
};
