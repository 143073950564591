// @ts-expect-error TS7006: Parameter 'lease' implicitly h...
export function isEstimated(lease, name) {
	//TODO: see src/Pages/Search/reducer.js about dirty hack, should be fixed
	return !!(
		(name === 'executionDate' &&
			lease['estimatedExecutionDate'] &&
			lease['estimatedExecutionDate'] !== 'false ') ||
		(name === 'commencementDate' &&
			lease['estimatedCommencementDate'] &&
			lease['estimatedCommencementDate'] !== 'false ') ||
		(name === 'expirationDate' &&
			lease['estimatedExpirationDate'] &&
			lease['estimatedExpirationDate'] !== 'false ') ||
		(name === 'leaseTerm' &&
			lease['estimatedLeaseTerm'] &&
			lease['estimatedLeaseTerm'] !== 'false ')
	);
}
