import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { dataActions } from './actions';
import { modalActions } from '../../Singletons/Modal/actions';
import isEqual from 'lodash/isEqual';
import { AppState } from 'reducers/root';
import { Service } from 'services/impl/ServiceController';

const mapStoreToProps = (store: AppState) => ({
	data: store.loaderData,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
	dataActions: bindActionCreators(dataActions, dispatch),
	modalActions: bindActionCreators(modalActions, dispatch),
});

export type PropsFromRedux = ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

type State<Response> = {
	data: Response[];
	lastSuccessfulData: Response[];
	loading?: boolean;
};

export default function LoaderHocFactory<
	FutureWrappedComponentPartialProps,
	Payload,
	Response
>(
	service: Service<Payload, Response>,
	namespace: string,
	inputGetter: (props: FutureWrappedComponentPartialProps) => null | unknown,
	isMultiple = true
) {
	if (!isMultiple) {
		const oldGetter = inputGetter;
		inputGetter = (props) => {
			const value = oldGetter(props);
			return value === null ? value : [value];
		};
	}

	return function LoaderHoc<WrappedComponentProps>(
		Component: React.ComponentType<WrappedComponentProps>,
		LoadingComponent: React.ComponentType<WrappedComponentProps> | null = null
	) {
		type DataLoaderProps = PropsFromRedux & WrappedComponentProps;
		return connect(
			mapStoreToProps,
			mapDispatchToProps
		)(
			class DataLoader extends React.Component<
				DataLoaderProps,
				State<Response>
			> {
				// @ts-expect-error TS7006: Parameter 'props' implicitly h...
				constructor(props) {
					super(props);
					this.loadDataIfNeeded(props);
					this.state = {
						...this.getStateData(props),
						lastSuccessfulData: [],
					};
				}

				retainedKeys: string[] = [];
				state: State<Response> = {
					data: [],
					lastSuccessfulData: [],
				};

				createKey = (item: Payload) => {
					return service.keyOf(item);
				};

				getItems = (props: DataLoaderProps) =>
					inputGetter(
						props as any as FutureWrappedComponentPartialProps
					) as Payload[];

				loadItems = (items: Payload[]) => {
					return this.props.dataActions.loadItems(service, namespace, items);
				};

				componentWillReceiveProps(newProps: DataLoaderProps) {
					if (!isEqual(this.getItems(newProps), this.getItems(this.props))) {
						this.loadDataIfNeeded(newProps);
						this.setState(this.getStateData(newProps));
						const currentItems = this.getItems(newProps);
						if (currentItems !== null) {
							const currentItemKeys = currentItems.map(this.createKey);
							const releasedKeys = this.retainedKeys.filter(
								(id) => currentItemKeys.includes(id) === false
							);
							if (releasedKeys.length !== 0) {
								this.props.dataActions.releaseItems(namespace, releasedKeys);
							}
							this.retainedKeys = currentItemKeys;
						}
					}

					// @ts-expect-error TS7053: Element implicitly has an 'any...
					if (this.props.data[namespace] !== newProps.data[namespace]) {
						this.setState(this.getStateData(newProps));
					}
				}

				componentWillUnmount() {
					this.props.dataActions.releaseItems(namespace, this.retainedKeys);
				}

				loadDataIfNeeded(props: DataLoaderProps) {
					const items = this.getItems(props);
					if (items === null) {
						return;
					}
					const itemsToLoad = items.filter((item) => {
						const key = this.createKey(item);
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						return !props.data[namespace] || !props.data[namespace][key];
					});
					this.loadItems(itemsToLoad);

					const newKeys = itemsToLoad.map(this.createKey);
					this.retainedKeys = [...this.retainedKeys, ...newKeys];
					this.props.dataActions.retainItems(namespace, newKeys);
				}

				getStateData(
					props: DataLoaderProps
				): State<Response> | { data: State<Response>['data'] } {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					if (!props.data[namespace] || this.getItems(props) === null) {
						return {
							data: [],
						};
					}

					const keys = this.getItems(props).map(this.createKey);
					const data = keys
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						.map((key) => props.data[namespace][key])
						.filter((a) => a && a.data);

					if (isEqual(data, this.state.data)) {
						return {
							data: [],
						};
					}

					if (data.length === keys.length) {
						const stateData = data.map((item) => item.data);
						return {
							data: stateData,
							lastSuccessfulData: stateData,
						};
					}

					return {
						data: [],
					};
				}

				render() {
					let shouldRender;
					if (isMultiple) {
						shouldRender =
							this.state.data.length === this.getItems(this.props).length;
					} else {
						shouldRender = this.state.data.length === 1;
					}
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { data, ...props } = {
						...this.props,
						// @ts-expect-error TS2339: Property 'loading' does not ex...
						loading: this.props.data.loading,
					};

					if (shouldRender) {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						props[namespace] = isMultiple
							? this.state.data
							: this.state.data[0];
						// @ts-expect-error TS2322: Type 'WrappedComponentProps' i...
						return <Component {...(props as WrappedComponentProps)} />;
					}

					if (LoadingComponent === null) {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						props[namespace] = isMultiple
							? this.state.lastSuccessfulData
							: this.state.lastSuccessfulData[0];
						// @ts-expect-error TS2322: Type 'WrappedComponentProps' i...
						return <Component {...(props as WrappedComponentProps)} />;
					}

					// @ts-expect-error TS2322: Type 'WrappedComponentProps' i...
					return <LoadingComponent {...(props as WrappedComponentProps)} />;
				}
			} as any
		) as React.ComponentType<WrappedComponentProps>;
	};
}
