/* eslint-disable camelcase */
import { User } from '@compstak/common';
import { useEffect } from 'react';
import {
	useUserflowCompletedFlows,
	useUserflowCompleteFlowMutation,
	useUserflowOnboardingConfig,
} from 'services/userflowOnboardingServices';
import userflow from 'userflow.js';

type UserFlowProps = {
	id: number;
	name: string;
	email: string;
	signedUpDate: string;
	companyId: number;
	companyName: string;
	tags: string[] | undefined;
};

const setUserFlow = ({
	id,
	name,
	email,
	signedUpDate,
	companyId,
	companyName,
	tags,
}: UserFlowProps) => {
	const token = window._env_.REACT_APP_USERFLOW_KEY;

	userflow.init(token);
	userflow.identify(`${id}`, {
		name,
		email,
		signed_up_at: signedUpDate,
		company_name: companyName,
		...(tags && { tags: `,${tags.join(',')},` }),
	});
	userflow.group(`${companyId}`, {
		name: companyName,
	});
};

export interface FlowEndedEvent {
	flow: { id: string };
	endReason: string;
}

export function useInitUserflow({
	user,
	isAuthenticated,
}: {
	user: User | undefined;
	isAuthenticated: boolean;
}) {
	const { data: userflowConfig } = useUserflowOnboardingConfig();
	const { data: completedFlows } = useUserflowCompletedFlows();
	const { mutate: completeFlow } = useUserflowCompleteFlowMutation();

	useEffect(() => {
		if (!isAuthenticated || !user?.id) {
			return;
		}
		setUserFlow({
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
			email: user.email,
			signedUpDate: user.termsAcceptDate,
			companyId: user.company,
			companyName: user.companyName,
			tags: user.tags,
		});
		// Important: Run this effect only when the user id changes,
		// to not make unnecessary calls to setUserFlow:
		// https://compstak.atlassian.net/browse/AP-11566
		//
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.id, isAuthenticated]);

	useEffect(() => {
		if (!isAuthenticated || !user || !userflowConfig || !completedFlows) {
			return;
		}

		const completedFlowIds = completedFlows.flows.map((flow) => flow.flowId);

		function onFlowDoneCb(ev: FlowEndedEvent) {
			const flowId = ev?.flow?.id;
			if (
				!userflowConfig ||
				!completedFlows ||
				completedFlowIds.includes(flowId)
			) {
				return;
			}
			if (!userflow.isIdentified()) {
				console.warn("User isn't identified in userflow.js?");
			}
			const configFlow = userflowConfig.flows.find(
				(flowFromConfig) => flowFromConfig.flowId === flowId
			);
			if (!configFlow || ev?.endReason !== 'ACTION') {
				return;
			}
			completeFlow(configFlow);
		}

		userflow.on('flowEnded', onFlowDoneCb);
		return () => {
			userflow.off('flowEnded', onFlowDoneCb);
		};
	}, [user, isAuthenticated, userflowConfig, completeFlow, completedFlows]);
}
