import { SearchLeasesResponse, SortDirection } from 'api';
import { ServerFilterItem } from 'types/serverFilters';
import averagesService from './averages/leaseAverages';
import factory from './factory';
import leaseService from './lease';

const searchService = {
	create(
		filters: ServerFilterItem[],
		initialNumber: number,
		order?: SortDirection,
		sortField?: string
	) {
		order = order || 'desc';
		sortField = sortField || '-executionDate';

		const results = {};
		const leaseIdPromises = {};
		let isFirstLoad = true;

		// @ts-expect-error TS7006: Parameter 'loader' implicitly ...
		function createLeaseIdPromise(loader, loadStart, i) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			leaseIdPromises[i + loadStart] = new Promise(function (resolve, reject) {
				loader.then(
					// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
					function (data) {
						if (i >= data.comps.length) {
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							delete leaseIdPromises[i + loadStart]; // fewer than initialNumber of first fetch
							// @ts-expect-error TS2794: Expected 1 arguments, but got ...
							resolve();
						} else {
							const rawLease = data.comps[i];
							leaseService.add(rawLease.id, rawLease);
							resolve(rawLease.id);
						}
					},
					function () {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						delete leaseIdPromises[i + loadStart];
						reject();
					}
				);
			});
		}

		function loadRange(start: number, end: number) {
			let loader;

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
			if (!isFirstLoad && end > results.total) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
				end = results.total;
			}

			let loadStart = start;
			let loadEnd = end;

			// @ts-expect-error TS7053: Element implicitly has an 'any...
			while (leaseIdPromises[loadStart + 1] && loadStart < loadEnd) {
				loadStart += 1;
			}

			// @ts-expect-error TS7053: Element implicitly has an 'any...
			while (leaseIdPromises[loadEnd - 1] && loadStart < loadEnd) {
				loadEnd -= 1;
			}

			const loadTotal = loadEnd - loadStart;

			if (loadTotal > 0 || isFirstLoad) {
				loader = factory.post(
					`/api/comps/actions/search`,
					{
						filter: filters,
						offset: loadStart,
						limit: loadTotal,
						order,
						sort: sortField,
					},
					true
				);

				for (let i = 0; i < loadTotal; i += 1) {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					if (!leaseIdPromises[i + loadStart]) {
						createLeaseIdPromise(loader, loadStart, i);
					}
				}
			}

			const promises = [];
			for (let i = start; i < end; i += 1) {
				// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
				promises.push(leaseIdPromises[i]);
			}

			if (isFirstLoad) {
				isFirstLoad = false;
				// @ts-expect-error TS18048: 'loader' is possibly 'undefine...
				return loader.then(function (data: SearchLeasesResponse) {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'averages' does not exist on type '{}'.
					results.averages = averagesService.of(data.averages || {}); // comes back as null if zero results
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'aggregations' does not exist on type '{}... Remove this comment to see the full error message
					results.aggregations = data.aggregations;
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
					results.total = data.totalCount;
					// @ts-expect-error TS2339: Property 'leases' does not exi...
					results.leases = data.comps;
					return results;
				});
			} else {
				return promises;
			}
		}

		// @ts-expect-error TS7006: Parameter 'page' implicitly ha...
		function loadPage(page, numberPerPage) {
			numberPerPage = Math.max(numberPerPage || 0, 1);
			const start = page * numberPerPage;
			const end = start + numberPerPage;
			return loadRange(start, end);
		}

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'load' does not exist on type '{}'.
		// TODO #AP-5379 move this to the service level
		results.load = function (page, numberPerPage) {
			// @ts-expect-error TS2345: Argument of type 'any[] | Prom...
			return leaseService.loadMany(loadPage(page, numberPerPage));
		};

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'loadRange' does not exist on type '{}'.
		// TODO #AP-5379 move this to the service level
		results.loadRange = function (start: number, end: number) {
			// @ts-expect-error TS2345: Argument of type 'any[] | Prom...
			return leaseService.loadMany(loadRange(start, end));
		};

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'loadIndividualPromises' does not exist o... Remove this comment to see the full error message
		// TODO #AP-5379 move this to the service level
		results.loadIndividualPromises = function (page, numberPerPage) {
			// @ts-expect-error TS2339: Property 'map' does not exist ...
			return loadPage(page, numberPerPage).map(leaseService.load);
		};

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'loadRangeIndividualPromises' does not ex... Remove this comment to see the full error message
		// TODO #AP-5379 move this to the service level
		results.loadRangeIndividualPromises = function (start, end) {
			// @ts-expect-error TS2339: Property 'map' does not exist ...
			return loadRange(start, end).map(leaseService.load);
		};

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'getFilters' does not exist on type '{}'.
		// TODO #AP-5379 move this to the service level
		results.getFilters = function () {
			// yes, this actually is one of the fastest ways to copy an object, and definitely the safest.
			return JSON.parse(JSON.stringify(filters));
		};

		return loadPage(0, initialNumber);
	},
};

export default searchService;
