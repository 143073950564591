import { useState } from 'react';
import { PdfColumn } from './types';

type Props<EditableRow, Row> = {
	rows: Row[];
	columns: PdfColumn<EditableRow, Row>[];
	getId: (row: Row) => number;
	getPropertyId?: (row: Row) => number;
};

export const useEditableRows = <EditableRow, Row>({
	rows,
	columns,
	getId,
	getPropertyId,
}: Props<EditableRow, Row>) => {
	return useState(createEditableRows({ rows, columns, getId, getPropertyId }));
};

export const createEditableRows = <EditableRow, Row>({
	rows,
	columns,
	getId,
	getPropertyId,
}: Props<EditableRow, Row>) => {
	return rows.map((row, rowIndex) => {
		const editableRow: Record<string, string | number> = {
			id: getId(row),
			...(getPropertyId ? { propertyId: getPropertyId(row) } : {}),
		};
		columns.forEach((c) => {
			editableRow[c.id] = c.getValue({ row, rowIndex });
		});
		return editableRow as EditableRow;
	});
};
