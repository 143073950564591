import Multiselect, { FilterOption } from 'Components/Filters/Base/Multiselect';
import { SPACE_TYPE_NAME_TO_ID } from 'api';
import React, { useMemo } from 'react';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { useReferenceData } from 'Pages/Login/reducers';
import { useSpaceTypeAggregations } from 'api/spaceTypeAggregations/useSpaceTypeAggregations';
import {
	getCountForAggregation,
	renderAggregationOption,
} from './aggregationFilterHelper';
import { alphabeticalOrder } from 'utils';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import { useSearchPageContext } from 'Layouts/SearchProvider';
import { FilterErrors } from 'Components/Filters/Base/Filter/Container';

const attribute = 'spaceTypeId' as const;

type Props = {
	nestedFilterIsSet?: boolean;
	setError?: (errors: FilterErrors) => void;
	touch?: NoArgCallback;
};

export const SpaceTypeMultiselect = ({
	nestedFilterIsSet,
	setError,
	touch,
}: Props) => {
	const { compType, filters, context, onFilterChange } =
		useFilterFieldContext();
	const { searchState } = useSearchPageContext();
	const referenceData = useReferenceData();

	const { data: aggregationsData } = useSpaceTypeAggregations({
		compType,
		filters,
	});
	const aggregation = aggregationsData?.spaceTypeId;

	const unfilteredOptions: FilterOption[] = useMemo(() => {
		const filterableSpaceTypes = referenceData?.filterableSpaceTypes ?? [];

		if (searchState?.mapAnalyticsType === 'spaceType') {
			return filterableSpaceTypes.map((option) => ({
				...option,
				disabled: !['Office', 'Retail', 'Industrial'].includes(option.name),
			}));
		}

		if (searchState?.mapAnalyticsType === 'marketRent') {
			return filterableSpaceTypes.map((option) => ({
				...option,
				inputType: ['Office', 'Industrial'].includes(option.name)
					? 'radio'
					: 'checkbox',
				disabled: !['Office', 'Industrial'].includes(option.name),
			}));
		}

		return filterableSpaceTypes;
	}, [referenceData, searchState?.mapAnalyticsType]);

	const filteredOptions = useMemo(() => {
		const list =
			context !== 'analytics'
				? unfilteredOptions.filter(
						(option) =>
							getCountForAggregation({
								aggregation,
								option,
							}) > 0
					)
				: unfilteredOptions;

		return alphabeticalOrder({ list, sortByProperty: 'name' });
	}, [aggregation, context, unfilteredOptions]);

	const spaceTypeAmount = (filters['spaceTypeId'] ?? []).filter(
		(id) => SPACE_TYPE_NAME_TO_ID.Unknown !== id
	).length;

	const showMessage = context === 'exchange-dashboard' && spaceTypeAmount > 1;

	return (
		<>
			<Multiselect
				attribute={attribute}
				filter={filters[attribute]}
				onFilterChange={onFilterChange}
				options={filteredOptions}
				renderAllPlaceholder={() => 'All Space Types'}
				renderOption={(option) =>
					renderAggregationOption({
						aggregation,
						option,
						shouldRenderAggregationCount: context !== 'analytics',
					})
				}
				setError={setError}
				touch={touch}
				initialExpanded={!nestedFilterIsSet}
			/>
			{showMessage && (
				<FilterDescription>
					The Market Dashboard is best viewed using one Space Type at a time
				</FilterDescription>
			)}
		</>
	);
};
