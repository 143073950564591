import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Feature, MultiPolygon, Polygon, Position } from '@turf/helpers';
import RBush from 'rbush';
import { PinFeature } from 'types';
import { AggTileFeature, HitTileFeature, TileFeature } from './types';

export const isAggOrHitTileFeature = (
	tileFeature: TileFeature
): tileFeature is AggTileFeature | HitTileFeature => {
	return isAggTileFeature(tileFeature) || isHitTileFeature(tileFeature);
};

export const isAggTileFeature = (
	tileFeature: TileFeature
): tileFeature is AggTileFeature => {
	return tileFeature.properties.layerName === 'aggs';
};

export const isHitTileFeature = (
	tileFeature: TileFeature
): tileFeature is HitTileFeature => {
	return tileFeature.properties.layerName === 'hits';
};

type RTreeItem = {
	minX: number;
	minY: number;
	maxX: number;
	maxY: number;
	index: number;
};

export const buildRTree = (polygons: Feature<Polygon | MultiPolygon>[]) => {
	const rTree = new RBush<RTreeItem>();

	const rTreeItems = polygons.map((polygon, index) => {
		const polygonBbox = bbox(polygon);
		return {
			minX: polygonBbox[0],
			minY: polygonBbox[1],
			maxX: polygonBbox[2],
			maxY: polygonBbox[3],
			index,
		};
	});

	rTree.load(rTreeItems);

	return rTree;
};

export const isPointInsideRTree = ({
	point,
	rTree,
	polygons,
}: {
	point: Position;
	rTree: RBush<RTreeItem>;
	polygons: Feature<Polygon | MultiPolygon>[];
}) => {
	const polygonsContainingPoint = rTree.search({
		minX: point[0],
		minY: point[1],
		maxX: point[0],
		maxY: point[1],
	});

	return polygonsContainingPoint.some((result) => {
		const polygonContaingPoint = polygons[result.index];
		// we can have holes inside polygons, thus gotta actually check additionally via booleanPointInPolygon
		return booleanPointInPolygon(point, polygonContaingPoint);
	});
};

export const hitTileFeatureToPinFeature = (
	tileFeature: HitTileFeature
): PinFeature => {
	const tileFeatureProps = tileFeature.properties;

	const pinFeature = {
		...tileFeature,
		properties: {
			address: tileFeatureProps.buildingAddressAndCity,
			id: tileFeatureProps.propertyId,
			geoPoint: {
				lon: tileFeatureProps.coordinatesInWGS84[0],
				lat: tileFeatureProps.coordinatesInWGS84[1],
			},
		},
	};
	return pinFeature;
};
