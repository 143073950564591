import { KeyValueTable } from '@compstak/ui-kit';
import { KvTableHeader } from 'Components/KvTable/kvTableHeader';
import { KvTableStylingWrapper } from 'Components/KvTable/kvTableWrapper';
import styled from 'styled-components';

export function MajorTenantsOfPropertyInQuestion({
	tenantNames,
}: {
	tenantNames: string;
}) {
	return (
		<KvTableContainer>
			<KvTableHeader>Major Tenants</KvTableHeader>
			<KeyValueTable columns={1} data={[[tenantNames, '']]} />
		</KvTableContainer>
	);
}

const KvTableContainer = styled(KvTableStylingWrapper)`
	max-width: 320px;
	min-width: 240px;
	margin-top: 48px;
`;
KvTableContainer.displayName = 'KvTableContainer';
