import { Period } from '@compstak/ui-kit';
import { createDefaultPeriodRange } from 'Pages/PropertyPageV2_1/utils/createDefaultPeriodRange';
import { OccupancyMetrics } from 'api';
import { useCallback, useState } from 'react';
import {
	FiltersNames,
	FiltersValues,
	Metric,
	MufaChartsNames,
} from 'types/propertyTrendCharts';

export type MufaChartsProps = {
	filters: MufaTrendsFiltersState;
	handleFilterChange: MufaFilterChangeFn;
};

export const initFiltersState: MufaTrendsFiltersState = {
	[MufaChartsNames.SubmarketMarketTrend]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
		[FiltersNames.METRIC]: 'effectiveRentPerSqFt',
	},
	[MufaChartsNames.SubmarketLeaseTradeOut]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
	},
	[MufaChartsNames.SubmarketOccupancy]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
		[FiltersNames.METRIC]: OccupancyMetrics.OCCUPANCY,
	},
	[MufaChartsNames.SubmarketSupply]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
		[FiltersNames.METRIC]: 'totalUnits',
	},
	[MufaChartsNames.SubmarketNOI]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
	},
	[MufaChartsNames.SubmarketExpensesPerUnit]: {
		[FiltersNames.PERIOD]: createDefaultPeriodRange(),
	},
};

export type MufaTrendsFiltersState = {
	[MufaChartsNames.SubmarketMarketTrend]: {
		[FiltersNames.PERIOD]: Period;
		[FiltersNames.METRIC]: Extract<
			Metric,
			'effectiveRentPerSqFt' | 'askingRentPerSqFt'
		>;
	};
	[MufaChartsNames.SubmarketLeaseTradeOut]: {
		[FiltersNames.PERIOD]: Period;
	};
	[MufaChartsNames.SubmarketOccupancy]: {
		[FiltersNames.PERIOD]: Period;
		[FiltersNames.METRIC]: OccupancyMetrics;
	};
	[MufaChartsNames.SubmarketSupply]: {
		[FiltersNames.PERIOD]: Period;
		[FiltersNames.METRIC]: Extract<
			Metric,
			'totalUnits' | 'newSupply' | 'unitsUnderConstruction'
		>;
	};
	[MufaChartsNames.SubmarketNOI]: {
		[FiltersNames.PERIOD]: Period;
	};
	[MufaChartsNames.SubmarketExpensesPerUnit]: {
		[FiltersNames.PERIOD]: Period;
	};
};

export const useMufaTrendsState = () => {
	const [filters, setFilters] =
		useState<MufaTrendsFiltersState>(initFiltersState);

	const handleFilterChange: MufaFilterChangeFn = useCallback(
		(
			chartName: MufaChartsNames,
			filterName: FiltersNames,
			filterValue: FiltersValues
		) => {
			setFilters((s) => ({
				...s,
				[chartName]: {
					...s[chartName],
					[filterName]: filterValue,
				},
			}));
		},
		[]
	);

	return { filters, handleFilterChange };
};

export type MufaFilterChangeFn = (
	chartName: MufaChartsNames,
	filterName: FiltersNames,
	filterValue: FiltersValues
) => void;

export type MufaChartFilterProps = {
	filters: MufaTrendsFiltersState;
	handleFilterChange: MufaFilterChangeFn;
};
