import React from 'react';
import { Market } from '@compstak/common';
import { PermissionsState } from 'Pages/Login/reducers';
import { MarketListItem } from './MarketListItem';

type MarketListItemsProps = {
	isEnterprise: boolean;
	markets: Market[];
	changeMarket: (market: Market) => void;
	permissions: PermissionsState;
	type: 'sales' | 'lease';
};

export const MarketListItems = ({
	isEnterprise,
	markets,
	changeMarket,
	permissions,
	type,
}: MarketListItemsProps) => {
	return (
		<>
			{markets.map((market) => {
				const hasFullAccess =
					isEnterprise && permissions[market.id]?.[`${type}Access`];
				const key = `market-selector-${market.id}-${market.displayName
					.toLowerCase()
					.replace(/\s/g, '')}`;

				return (
					<MarketListItem
						key={key}
						hasFullAccess={hasFullAccess}
						market={market}
						onMarketClick={() => changeMarket(market)}
					/>
				);
			})}
		</>
	);
};
