import {
	colors,
	IntermittentStyledTD,
	IntermittentStyledTR,
	KeyValueTable,
	typography,
	Value,
} from '@compstak/ui-kit';
import { BlueLink } from 'Components';
import styled from 'styled-components';

export const TabContentContainer = styled.div`
	padding: 32px 24px;
`;

export const TabContentHeader = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;

export const TabContentTitle = styled.h2`
	font-family: ${typography.fontFamily.gotham};
	font-style: normal;
	font-weight: 325;
	font-size: 20px;
	line-height: 24px;
`;

export const StyledKeyValueTable = styled(KeyValueTable)`
	& ${IntermittentStyledTD} {
		padding: 8px 0;
		font-size: 12px;
		line-height: 18px;
		height: auto;
	}
	& ${IntermittentStyledTR} {
		padding-right: 0;
		${IntermittentStyledTD}:last-child {
			padding-right: 0;
		}
	}
	& ${Value} {
		font-weight: 400;
		color: ${({ theme }) => theme.colors.gray.gray700};
		margin-left: 8px;
		text-align: right;
	}
`;

export const SeeAllLink = styled(BlueLink)<{ isDisabled?: boolean }>`
	margin-left: auto;
	cursor: pointer;

	${({ isDisabled, theme }) =>
		isDisabled &&
		`
        pointer-events: none;
        color: ${theme.colors.gray.gray300};
    `}
`;

export const TableCellGapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;

export const TableCellGrayText = styled.span`
	color: ${colors.neutral.n70};
`;
