import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { ExportCompParams, ExportCompResponse } from '../types';

const exportLeases = async (params: ExportCompParams) => {
	const res = await API.post<ExportCompResponse>(
		`/api/comps/actions/export`,
		params
	);
	return res.data;
};

export const useExportLeasesMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: exportLeases,
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.CHECK_LEASES_EXPORT_STATUS]);
		},
	});
};
