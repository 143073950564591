import { PortfolioPropertyTypeByMarket } from 'api';
import { OFFICE_ID } from './constants';
import { getValidPropertyTypes } from './getValidPropertyTypes';

export const getPropertyTypeForSelectionForMarket = (
	marketId: number,
	propertyTypesByMarkets: PortfolioPropertyTypeByMarket[]
) => {
	const validPropertyTypes = getValidPropertyTypes(
		propertyTypesByMarkets,
		marketId
	);

	if (validPropertyTypes.length === 1) {
		return validPropertyTypes[0].buildingPropertyTypeId;
	} else {
		const officePropertyType = validPropertyTypes.find(
			(pm) => pm.buildingPropertyTypeId === OFFICE_ID
		);
		if (officePropertyType) {
			return officePropertyType.buildingPropertyTypeId;
		}
	}
};
