import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@compstak/ui-kit';
import { Portfolio } from 'api';
import styled from 'styled-components';
import IconVerticalDots from 'ui/svg_icons/vertical-dots.svg';
import { CopyPortfolioDialog } from './CopyPortfolioDialog';
import { DeletePortfolioDialog } from './DeletePortfolioDialog';
import { PortfolioOptionsMenuV2 } from './PortfolioOptionsMenuV2';
import { UpdatePortfolioDialog } from './UpdatePortfolioDialog';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';
import { PortfolioModal } from 'PortfolioAnalytics/PortfolioSidebar/PortfolioModal';

type Props = {
	portfolio: Portfolio;
};

export const PortfolioOptionsButtonV2 = ({ portfolio }: Props) => {
	const { portfolioRevampOverviewTabFF } = useFeatureFlags();
	const { openModal } = useModal();

	return (
		<>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<OptionsButton variant={'ghost'} fitContent={true}>
						<IconVerticalDots />
					</OptionsButton>
				</DropdownMenu.Trigger>
				<PortfolioOptionsMenuV2
					openUpdateDialog={() =>
						openModal({
							modalContent: portfolioRevampOverviewTabFF ? (
								<PortfolioModal
									title="Edit Portfolio"
									action="edit"
									editedPortfolio={portfolio}
									initialFormState={{
										name: portfolio.name,
										description: portfolio.description ?? '',
									}}
								/>
							) : (
								<UpdatePortfolioDialog portfolio={portfolio} />
							),
						})
					}
					openCopyDialog={() =>
						openModal({
							modalContent: portfolioRevampOverviewTabFF ? (
								<PortfolioModal
									action="copy"
									title="Duplicate Portfolio"
									editedPortfolio={portfolio}
									initialFormState={{
										name: `${portfolio.name} copy`,
										description: portfolio.description ?? '',
									}}
								/>
							) : (
								<CopyPortfolioDialog portfolio={portfolio} />
							),
						})
					}
					openDeleteDialog={() =>
						openModal({
							modalContent: (
								<DeletePortfolioDialog portfolioId={portfolio.id} />
							),
						})
					}
				/>
			</DropdownMenu.Root>
		</>
	);
};

const OptionsButton = styled(Button)`
	display: flex;
	align-items: center;
	&:hover {
		background-color: ${(p) => p.theme.colors.gray.gray200};
	}
`;
