import { useIsMutating } from '@tanstack/react-query';
import { MUTATION_KEYS } from 'api';

type Props = {
	id: number;
	type: 'lease' | 'sale';
};

export const useIsRowUnlocking = ({ id, type }: Props) => {
	const numberOfActiveMutations = useIsMutating({
		mutationKey: [
			type === 'lease'
				? MUTATION_KEYS.UNLOCK_LEASES
				: MUTATION_KEYS.UNLOCK_SALES,
		],
		predicate: ({ state }) => {
			return state.variables.ids.includes(id);
		},
	});

	return numberOfActiveMutations > 0;
};
