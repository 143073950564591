import { useEffect, useState } from 'react';

export function useExpectedLoadTimePassedForProperty(
	propertyId: number
): boolean {
	const [expectedLoadTimePassed, setExpectedLoadTimePassed] = useState(false);
	useEffect(() => {
		setExpectedLoadTimePassed(false);
		setTimeout(() => {
			setExpectedLoadTimePassed(true);
		}, 5000);
	}, [propertyId]);
	return expectedLoadTimePassed;
}
