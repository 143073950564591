import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MUTATION_KEYS, QUERY_KEYS } from 'api/constants';
import { Feedback, MarketSummaryFeedbackParams } from 'types/marketSummary';

import { mapUrlToGateway } from 'utils/gatewayUtil';

export const useDeleteMarketSummaryFeedbackMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: [MUTATION_KEYS.DELETE_MARKET_SUMMARY_FEEDBACK],
		mutationFn: ({
			marketId,
			spaceType,
			userId,
		}: MarketSummaryFeedbackParams) => {
			return API.delete(
				mapUrlToGateway(
					`/user-service/markets/${marketId}/users/${userId}/spaceTypes/${spaceType}/feedback`
				)
			);
		},
		onMutate: ({ marketId, userId, spaceType }) => {
			queryClient.setQueryData<
				{ feedbacks: Feedback[] | undefined } | undefined
			>([QUERY_KEYS.MARKET_SUMMARY_FEEDBACK, marketId, userId], (oldData) => {
				if (!oldData) return oldData;
				const updatedData = {
					feedbacks: oldData.feedbacks?.filter(
						(feedback) => feedback.spaceType !== spaceType
					),
				};
				return updatedData;
			});
		},
		onSuccess: (_, { marketId, userId }) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.MARKET_SUMMARY_FEEDBACK, marketId, userId],
			});
		},
	});
};
