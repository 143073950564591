import { renderRentPeriodPostfix } from 'format';
import React from 'react';
import AttributeValue from '../AttributeValue';

type EffectiveRentComponentProps = {
	isMonthly: boolean;
	value: string;
	abbreviateText?: boolean;
};

const EffectiveRentComponent = React.memo<EffectiveRentComponentProps>(
	({ isMonthly, value, abbreviateText }) => {
		const annualOrMonthlyAmount = isMonthly ? 'a MONTHLY' : 'an ANNUAL';
		const tooltip = `Net Effective Rent for this lease is set at ${annualOrMonthlyAmount} amount`;

		if (typeof value !== 'string') {
			console.error('EffectiveRentComponent value is not a string!?', value);
			value = 'ERROR';
		}

		const valueOnly = value.split(' ')[0];

		const annualOrMonthly = renderRentPeriodPostfix(isMonthly, abbreviateText);

		return (
			<span
				data-placement="bottom"
				data-tooltipdelay="0"
				data-tooltip={tooltip}
			>
				{/* @ts-expect-error TS2786: 'AttributeValue' cannot be use... */}
				<AttributeValue value={valueOnly} /> {annualOrMonthly}
			</span>
		);
	},
	(prevProps, nextProps) => {
		return prevProps.value === nextProps.value;
	}
);

EffectiveRentComponent.displayName = 'EffectiveRentComponent';

export default EffectiveRentComponent;
