import { PropertyTitle } from 'Pages/PropertyPageV3/MainInfo';
import { DurationFilter } from 'Pages/PropertyPageV3/MarketStats/DurationFilter';
import { EffectiveRentMarketStats } from 'Pages/PropertyPageV3/MarketStats/EffectiveRentMarketStats';
import { FreeMonthsMarketStats } from 'Pages/PropertyPageV3/MarketStats/FreeMonthsMarketStats';
import { StartingRentMarketStats } from 'Pages/PropertyPageV3/MarketStats/StartingRentMarketStats';
import { TenantImprovementMarketStats } from 'Pages/PropertyPageV3/MarketStats/TenantImprovementMarketStats';
import { TransactionSizeMarketStats } from 'Pages/PropertyPageV3/MarketStats/TransactionSizeMarketStats';
import styled from 'styled-components';
import { PropertyComp } from 'types';
import { PropertyEditPage } from '../PropertyEditPage';
import { EditPageSectionTitle } from '../UI';

type Props = {
	property: PropertyComp;
};

export const MARKET_STATS_CONTAINER_ID = 'markeStats';

export const EditMarketStatsPage = ({ property }: Props) => {
	return (
		<PropertyEditPage pageKey="marketStats">
			<PropertyTitle property={property} />
			<StyledEditPageSectionTitle>Market Stats</StyledEditPageSectionTitle>
			<FilterContainer>
				<DurationFilter />
			</FilterContainer>
			<Grid id={MARKET_STATS_CONTAINER_ID}>
				<StartingRentMarketStats />
				<EffectiveRentMarketStats />
				<TransactionSizeMarketStats />
				<TenantImprovementMarketStats />
				<FreeMonthsMarketStats />
			</Grid>
		</PropertyEditPage>
	);
};

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem;
	row-gap: 4rem;
`;

const StyledEditPageSectionTitle = styled(EditPageSectionTitle)`
	margin-top: 2.5rem;
	margin-bottom: 0.5rem;
`;

const FilterContainer = styled.div`
	margin-bottom: 1rem;
`;
