import { Styles } from '@react-pdf/renderer';
import { useTheme } from 'styled-components';

export const useSharedPdfStyle = () => {
	const theme = useTheme();

	return {
		page: {
			backgroundColor: '#fff',
			padding: 16,
			paddingBottom: '15%',
		},
		infoRow: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			borderBottom: `1px solid ${theme.colors.gray.gray700}`,
			padding: '6px 0',
		},
		infoLabel: {
			fontWeight: 600,
			fontSize: 9,
			textTransform: 'uppercase',
			lineHeight: 1,
			flex: 1,
		},
		infoValue: {
			fontSize: 10,
			lineHeight: 1.5,
			color: theme.colors.gray.gray700,
			flex: 1,
		},
	} satisfies Styles;
};
