import { useCallback } from 'react';
import { useNavigate } from 'router';

export const useBodyCellProps = () => {
	const navigate = useNavigate();
	return useCallback(
		// @ts-expect-error TS7031: Binding element 'row' implicit...
		({ row }) => ({
			onClick: () => {
				navigate(`/comps/leases/${row.id}`, {
					state: { fromNewSalePage: true },
				});
			},
		}),
		[navigate]
	);
};
