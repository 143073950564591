import React, { MouseEventHandler } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import ReactTimeout, { ReactTimeoutProps } from 'react-timeout';

// @ts-expect-error TS7016: Could not find a declaration f...
import isMobile from 'is-mobile';

import StackingPlanIcon from '../../ui/svg_icons/auto-fill.svg';
import ExportIcon from '../../ui/svg_icons/download_icon.svg';
import ExcelIcon from '../../ui/svg_icons/export-excel.svg';
import PdfIcon from '../../ui/svg_icons/export_pdf.svg';
import ReportIcon from '../../ui/svg_icons/report.svg';
import ShareIcon from '../../ui/svg_icons/share.svg';

import * as exportActions from 'actions/export';
import * as leaseActions from 'actions/lease';
import * as saleActions from 'actions/sale';
import * as feedbackActions from 'Singletons/Feedback/actions';
import * as modalActions from 'Singletons/Modal/actions';
import wrapActions from 'util/actionWrapper';

import buttonStyles from 'ui/styles/button.less';
import styles from './unlockOrExportButton.less';

import { Button } from '@compstak/ui-kit';
import {
	invalidateLeaseQueries,
	invalidateSalesQueries,
	withQueryClient,
	WithQueryClientProps,
} from 'api';
import { AddToPortfolioButton } from 'Components/AddToPortfolioButton';
import { AddToPortfolioDialog } from 'Components/AddToPortfolioDialog';
import { ShareACompProps } from 'Components/Lease/Details/Components/ShareAComp';
import 'Components/Menus/Export';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { invalidateUserQuery } from 'hooks/userHooks';
import { AppState } from 'reducers/root';
import { routes } from 'router';
import { menuActions } from 'Singletons/Menu/actions';
import { AppDispatch } from 'store';
import styled from 'styled-components';
import { LeaseComp, SalesComp } from 'types/comp';

type State = {
	exportDisabled: boolean;
	isUnlocking: boolean;
	showingUnlockSuccess: boolean;
	isAddToPortfolioDialogOpen: boolean;
};

type GlobalProps = {
	className?: string;
	breakWidth?: number;
	configuration?: 'table' | 'details';
	height?: number;
	inlineExport?: boolean;
	popupSide?: string;
	exportText?: string;
	width?: number | string;
} & ReactTimeoutProps;

type LeaseProps = {
	comp: LeaseComp;
	compType: 'lease';
	lease: LeaseComp;
};

type SalesProps = {
	compType: 'sale';
	comp: SalesComp;
};

type Props = ((LeaseProps & GlobalProps) | (SalesProps & GlobalProps)) &
	WithQueryClientProps &
	ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

export class UnlockOrExportButton extends React.Component<Props, State> {
	static defaultProps = {
		breakWidth: 90,
		width: 250,
		height: 40,
		inlineExport: false,
		configuration: 'table',
		popupSide: 'above',
	};

	state = {
		exportDisabled: false,
		isUnlocking: false,
		showingUnlockSuccess: false,
		isAddToPortfolioDialogOpen: false,
	};

	componentDidUpdate(prevProps: Props) {
		// comp changed
		if (this.props.comp.id !== prevProps.comp.id) {
			this.setState({
				isUnlocking: this.props.unlock.indexOf(prevProps.comp.id) !== -1,
				showingUnlockSuccess: false,
			});
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'UnlockO... Remove this comment to see the full error message
			if (this.timeout) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'UnlockO... Remove this comment to see the full error message
				clearTimeout(this.timeout);
			}

			// changed from not unlocking to unlocking
		} else if (
			this.state.isUnlocking &&
			this.props.unlock.indexOf(prevProps.comp.id) === -1
		) {
			this.setState({
				isUnlocking: false,
			});

			if (this.props.comp.own) {
				this.setState({
					showingUnlockSuccess: true,
				});
				// @ts-expect-error TS2339: Property 'timeout' does not ex...
				this.timeout = this.props.setTimeout?.(() => {
					this.setState({
						showingUnlockSuccess: false,
					});
				}, 1000);
			}
		}
	}

	throttleExport() {
		this.setState({
			exportDisabled: true,
		});
		this.props.setTimeout?.(() => {
			this.setState({
				exportDisabled: false,
			});
		}, 3000);
	}

	showExportMenu = (
		location = 'onright',
		domNode = ReactDOM.findDOMNode(this)
	) => {
		this.props.exportActions.showExportMenu(
			[this.props.comp],
			this.props.compType,
			// @ts-expect-error domNode type
			domNode,
			location,
			{ singleComp: true }
		);
	};

	onExportPdf: MouseEventHandler<HTMLAnchorElement> = (event) => {
		if (this.state.exportDisabled) {
			event.preventDefault();
		}
	};

	showExcelExportMenu: MouseEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		if (this.state.exportDisabled) {
			return;
		}

		this.props.exportActions.showExportMenu(
			[this.props.comp],
			this.props.compType,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'excelButton' does not exist on type 'Unl... Remove this comment to see the full error message
			this.excelButton,
			'below',
			{
				exportType: 'xls',
				singleComp: true,
			}
		);
	};

	showShareMenu: MouseEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		this.props.menuActions.showMenu<ShareACompProps>(
			'share-a-comp',
			event.target,
			'below',
			{
				comp: this.props.comp,
				compType: this.props.compType,
			}
		);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	unlock = (event) => {
		event.preventDefault();
		this.setState({
			isUnlocking: true,
		});
		if (this.props.compType === 'lease') {
			this.props.leaseActions.unlockLeases([this.props.comp.id], {
				center: this.props.configuration === 'details',
				onSuccess: () => {
					invalidateLeaseQueries(this.props.queryClient);
					invalidateUserQuery(this.props.queryClient);
				},
			});
			// this.props.queryClient.inval
		}
		if (this.props.compType === 'sale') {
			this.props.saleActions.unlockSales([this.props.comp.id], {
				center: this.props.configuration === 'details',
				onSuccess: (data) => {
					invalidateSalesQueries(this.props.queryClient, data);
					invalidateUserQuery(this.props.queryClient);
				},
			});
		}
	};

	showCreditsAlert = () => {
		const alertClass = this.props.configuration === 'details' ? 'center' : '';
		this.props.feedbackActions.addFeedback(
			'Get credits to unlock more comps.',
			alertClass,
			'CreditsAlert'
		);
	};

	unlockText() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'unlockText' does not exist on type 'Read... Remove this comment to see the full error message
		if (this.props.unlockText) {
			return this.props.exportText;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
		} else if (this.props.width > this.props.breakWidth) {
			return [
				<span className={`${buttonStyles.divider} hide-small`} key="1">
					|
				</span>,
				<span className="unlock-export-button-text hide-small" key="text">
					Unlock
				</span>,
			];
		} else {
			return '';
		}
	}

	costText() {
		if (this.props.user.freeComps) {
			return (
				<span className={styles['unlock-export-button-credits-free']}>
					Free
				</span>
			);
		} else if (
			this.props.user?.userType === 'exchange' ||
			this.props.user.salesTrader
		) {
			return (
				<span className={styles['unlock-export-button-credits']}>
					{this.props.comp.cost}
				</span>
			);
		} else {
			return <span />;
		}
	}

	exportText() {
		if (this.props.exportText) {
			return this.props.exportText;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
		} else if (this.props.width > this.props.breakWidth) {
			return 'Export';
		} else {
			return '';
		}
	}

	buttonStyle() {
		return {
			width: this.props.width,
			height: this.props.height,
			padding: '0',
		};
	}

	unlockButtonClass() {
		let className = `${styles.small} ${styles.unlock} `;
		if (this.props.user.freeComps) {
			className += `${buttonStyles.green} ${styles.unlockButtonFree}`;
		} else if (
			this.props.user?.userType !== 'exchange' &&
			!this.props.user.salesTrader
		) {
			className += `${buttonStyles.green}`;
		} else if (this.props.user.pointsAvailable < this.props.comp.cost) {
			className += ' ' + buttonStyles.disabled;
		} else {
			className += `${buttonStyles.green}`;
		}

		if (this.state.isUnlocking) {
			className += ' ' + styles.loading;
		}

		if (this.state.showingUnlockSuccess) {
			className += ' ' + styles.success;
		}

		return className;
	}

	unlockButtonAction() {
		if (
			(this.props.user.userType === 'exchange' ||
				this.props.user.salesTrader) &&
			this.props.user.freeComps === 0 &&
			this.props.user.pointsAvailable < this.props.comp.cost
		) {
			return this.showCreditsAlert;
		} else {
			return this.unlock;
		}
	}

	unlockButton() {
		const buttonStyle = this.buttonStyle();
		const onClick = this.unlockButtonAction();
		const unlockText = this.unlockText();
		const costText = this.costText();
		const buttonClass = this.unlockButtonClass();
		const divider = <span className={buttonStyles.divider}>|</span>;

		if (this.state.isUnlocking || this.state.showingUnlockSuccess) {
			return (
				<div
					key="the-button"
					style={buttonStyle}
					onClick={onClick}
					className={`${buttonClass} ${this.props.className || ''}`}
				/>
			);
		}

		if (this.props.configuration === 'table') {
			return (
				<ButtonContainerStyled
					key="the-button"
					style={buttonStyle}
					onClick={onClick}
					className={buttonClass}
				>
					<LockIconStyled width={14} height={16} /> {divider}
					{costText} {unlockText}
				</ButtonContainerStyled>
			);
		}

		const unlockButton = (
			<ButtonContainerStyled
				key="the-button"
				style={buttonStyle}
				onClick={onClick}
				className={`${buttonClass} ${this.props.className || ''}`}
			>
				<LockIconStyled width={14} height={16} /> {unlockText} {divider}
				{costText}
			</ButtonContainerStyled>
		);

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'configuration' does not exist on type 'R... Remove this comment to see the full error message
		if (this.props.configuration === 'details' && this.props.infoBar) {
			return (
				<div>
					{unlockButton}
					{this.propertyReportButton(this.exportButtonClass())}
				</div>
			);
		}

		return unlockButton;
	}

	exportButtonClass() {
		let className = `${buttonStyles.button} ${buttonStyles.blue} ${styles.small} ${styles.compExport}`;
		if (this.state.showingUnlockSuccess) {
			className = `${buttonStyles.button} ${buttonStyles.green} ${styles.small} ${styles.success}`;
		}

		if (this.state.exportDisabled) {
			className += ` ${buttonStyles.disabled}`;
		}

		return className;
	}

	// @ts-expect-error TS7006: Parameter 'buttonClass' implic...
	propertyReportButton(buttonClass) {
		let comp;

		if (this.props.compType === 'lease') {
			comp = this.props.lease;
		} else if (this.props.compType === 'sale') {
			comp = this.props.comp.portfolio[0];
		} else {
			return null;
		}

		let propertyReportStyling = styles.propertyReportEnterprise;
		if (this.props.appConfig.isExchange) {
			propertyReportStyling = styles.propertyReport;
		}

		return (
			<Button
				icon={<ReportIcon width="14px" />}
				href={routes.propertyById.toHref({ propertyId: comp.propertyId })}
				key="property-report"
				className={buttonClass}
				variant="secondary"
				data-tooltipdelay="0"
				data-tooltip="View Property Report"
				data-modal="true"
			>
				<SpanStyled className={propertyReportStyling}>
					Property Report
				</SpanStyled>
			</Button>
		);
	}

	// @ts-expect-error TS7006: Parameter 'buttonClass' implic...
	stackingPlanButton(buttonClass) {
		if (this.props.appConfig.isExchange) {
			return (
				<Button
					icon={<StackingPlanIconStyled width="14px" />}
					key="stacking-plan"
					data-tooltipdelay="0"
					data-tooltip="View/Edit Stacking Plan"
					data-modal="true"
					className={buttonClass}
					href={routes.stackingPlan.toHref({
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'comp' does not exist on type 'Readonly<{... Remove this comment to see the full error message
						propertyId: this.props.comp.propertyId,
					})}
				>
					<SpanStyled>Stacking Plan</SpanStyled>
				</Button>
			);
		}
		return null;
	}

	exportButton() {
		const buttonStyle = this.buttonStyle();
		const buttonClass = this.exportButtonClass();
		const compTypePlural = this.props.compType + 's';

		let buttonText;

		if (typeof this.props.exportText === 'string') {
			buttonText = this.props.exportText;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
		} else if (this.props.width > this.props.breakWidth) {
			buttonText = [
				<span className={`${buttonStyles.divider} hide-small`} key="1">
					|
				</span>,
				<span
					className={`${styles.unlockExportButtonText} hide-small`}
					key="text"
				>
					Export
				</span>,
			];
		} else {
			buttonText = '';
		}

		if (this.props.inlineExport) {
			if (isMobile()) {
				return (
					<div className={styles.exportButtons}>
						<div
							className={buttonClass}
							onClick={this.showShareMenu}
							data-tooltipdelay="0"
							data-tooltip="Share Comp"
						>
							<ShareIcon />
							<span className={styles.unlockExportButtonText}> Share</span>
						</div>
					</div>
				);
			}
			return (
				<div className={styles.exportButtons}>
					<div
						ref={(button) => {
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'excelButton' does not exist on type 'Unl... Remove this comment to see the full error message
							this.excelButton = button;
						}}
						className={buttonClass}
						onClick={this.showExcelExportMenu}
						data-tooltipdelay="0"
						data-tooltip="Export Excel"
					>
						<ExcelIcon width={14} height={16} fill={'#ffffff'} />
						SRBIJA
						<span>Excel</span>
					</div>
					<a
						className={buttonClass}
						onClick={this.onExportPdf}
						data-tooltipdelay="0"
						data-tooltip="Export PDF"
						data-modal="true"
						href={`/export/${compTypePlural}/${this.props.comp.id}`}
					>
						<PdfIcon width={14} height={16} fill={'#ffffff'} />
						<span>PDF</span>
					</a>

					{this.propertyReportButton(buttonClass)}

					{this.props.compType === 'lease' ? (
						<div
							className={buttonClass}
							onClick={this.showShareMenu}
							data-tooltipdelay="0"
							data-tooltip="Share Comp"
						>
							<ShareIcon />
							<span>Share</span>
						</div>
					) : null}
				</div>
			);
		} else if (isMobile()) {
			return (
				<div
					key="the-button"
					style={buttonStyle}
					className={`${buttonClass} ${this.props.className || ''}`}
					onClick={this.showShareMenu}
					data-tooltipdelay="0"
					data-tooltip={'Share Comp'}
				>
					<ShareIcon />
					{buttonText}
				</div>
			);
		} else if (
			this.props.compType === 'lease' &&
			this.props.configuration === 'details'
		) {
			return (
				<div className={styles.exportButtons}>
					{this.addToPortfolioButton(buttonClass)}
					{this.propertyReportButton(buttonClass)}

					<Button
						icon={<ExportIconStyled width="14px" />}
						ref={(button) => {
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportCompButton' does not exist on type... Remove this comment to see the full error message
							this.exportCompButton = button;
						}}
						className={buttonClass}
						onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
							event.preventDefault();
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportCompButton' does not exist on type... Remove this comment to see the full error message
							this.showExportMenu('below', this.exportCompButton);
						}}
						data-tooltipdelay="0"
						data-tooltip="Export Comp"
					>
						<SpanStyled>Export</SpanStyled>
					</Button>

					{this.stackingPlanButton(buttonClass)}
				</div>
			);
		} else {
			return (
				<div
					ref={(button) => {
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportCompButton' does not exist on type... Remove this comment to see the full error message
						this.exportCompButton = button;
					}}
					key="the-button"
					style={buttonStyle}
					className={`${buttonClass} ${this.props.className || ''}`}
					onClick={(event) => {
						event.preventDefault();
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'popupSide' does not exist on type 'Reado... Remove this comment to see the full error message
						this.showExportMenu(this.props.popupSide, this.exportCompButton);
					}}
					data-tooltipdelay="0"
					data-tooltip={'Export Comp'}
					data-qa-id="export-menu-button"
				>
					<ExportIconStyled width={10} height={14} />
					{buttonText}
				</div>
			);
		}
	}

	// @ts-expect-error TS7006: Parameter 'buttonClass' implic...
	addToPortfolioButton = (buttonClass) => {
		if (
			this.props.featureFlags['portfolio-analytics'] &&
			this.props.appConfig.isEnterprise
		) {
			if (this.props.featureFlags['portfolioRevampFF']) {
				return (
					<AddToPortfolioButton
						variant="secondary"
						// @ts-expect-error no prop types
						propertyIds={[this.props.lease.propertyId]}
						key="add-to-portfolio"
						data-tooltipdelay="0"
						data-tooltip="Add to Portfolio"
						data-modal="true"
					/>
				);
			}

			return (
				<>
					<span
						className={`${buttonClass} ${styles.buttonOverride}`}
						key="add-to-portfolio"
						data-tooltipdelay="0"
						data-tooltip="Add to Portfolio"
						data-modal="true"
						data-qa-id={`add-to-portfolio-button`}
						onClick={() =>
							this.setState({
								...this.state,
								isAddToPortfolioDialogOpen: true,
							})
						}
					>
						Add to Portfolio
					</span>
					{this.state.isAddToPortfolioDialogOpen && (
						<AddToPortfolioDialog
							//@ts-expect-error no prop types
							propertyIds={[this.props.lease.propertyId]}
							closeDialog={() => {
								this.setState({
									...this.state,
									isAddToPortfolioDialogOpen: false,
								});
							}}
						/>
					)}
				</>
			);
		}
		return null;
	};

	render() {
		let button;

		if (!this.props.comp) {
			return <span />;
		} else if (!this.state.showingUnlockSuccess && this.props.comp.own) {
			button = this.exportButton();
		} else {
			button = this.unlockButton();
		}

		return button;
	}
}

const mapStoreToProps = (store: AppState) => {
	return {
		appConfig: store.appConfig,
		featureFlags: store.featureFlags,
		unlock: store.unlock,
		user: store.user!,
		export: store.export,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
	return wrapActions(
		{
			leaseActions,
			saleActions,
			exportActions,
			feedbackActions,
			modalActions,
			menuActions,
		},
		dispatch
	);
};

const ButtonContainerStyled = styled.div`
	padding: 0;
`;

const StackingPlanIconStyled = styled(StackingPlanIcon)`
	g {
		fill: #ffffff;
	}
`;

export const ExportIconStyled = styled(ExportIcon)`
	fill: #ffffff;
`;

const SpanStyled = styled.span`
	display: none;

	${(props) => props.theme.breakpoints.up('D_1280')} {
		display: block;
	}
`;

export default connect(
	mapStoreToProps,
	mapDispatchToProps
	// @ts-expect-error TS2345: Argument of type 'ComponentCla...
)(ReactTimeout(withQueryClient(UnlockOrExportButton)));
