import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { ExportCompParams, ExportCompResponse } from '../types';

const exportSales = async (params: ExportCompParams) => {
	const res = await API.post<ExportCompResponse>(
		`/api/salesComps/export`,
		params
	);
	return res.data;
};

export const useExportSalesMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: exportSales,
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.CHECK_SALES_EXPORT_STATUS]);
		},
	});
};
