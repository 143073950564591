import React, { useState } from 'react';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { Options } from 'highcharts';

type ModalGraphExportProps = {
	chartOptions?: Options;
	exportFn: (chartOptions: Options) => void;
};

export const GraphExportModal = ({
	chartOptions: _chartOptions,
	exportFn,
}: ModalGraphExportProps) => {
	const [title, setTitle] = useState<string>(_chartOptions?.title?.text || '');
	const { closeModal } = useModal();

	const handleExport = () => {
		const chartOptions = {
			..._chartOptions,
			title: {
				text: title,
			},
			chart: {
				width: 900,
				height: 500,
			},
		};

		exportFn(chartOptions);
		closeModal();
	};

	return (
		<div>
			<ModalTitle>Title your chart</ModalTitle>
			<ModalInputLabel>
				Title
				<input
					data-qa-id="chart-title"
					type="text"
					value={title}
					onChange={(ev) => setTitle(ev.target.value)}
				/>
			</ModalInputLabel>

			<ModalButtons>
				<ModalButton onClick={handleExport}>Download</ModalButton>
			</ModalButtons>
		</div>
	);
};
