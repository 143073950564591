import ModalContainer from 'Singletons/Modal/Container';
import modalStyles from 'Singletons/Modal/modal.less';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
	children: ReactNode;
};

export const ModalPageContainer = ({ children }: Props) => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<ModalContainer
			className={modalStyles.pageModal}
			push={navigate}
			locationPathname={location.pathname}
			allowHeaderClicks={true}
			hideCloseIcon={false}
		>
			{children as any}
		</ModalContainer>
	);
};
