import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import React from 'react';

const attribute = 'askingRpsf' as const;

export const StartingRentPerSqfFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => <Between {...props} thousandSeparator={false} />}
		</FilterBase>
	);
};
