import { APIClientNotOkResponseError } from '@compstak/common';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { composeEnabled, createFetchFn } from 'api/utils';
import { PropertyComp } from 'types';

const createFetchProperty = createFetchFn<PropertyByIdParams, PropertyComp>({
	method: 'get',
	getURL: ({ id }) => `/properties/${id}`,
});

export const usePropertyByIdQuery = (
	params: PropertyByIdParams,
	options?: UseQueryOptions<PropertyComp, APIClientNotOkResponseError>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_BY_ID, params],
		queryFn: createFetchProperty(params),
		...options,
		enabled: composeEnabled(params.id != null, options?.enabled),
	});
};

type PropertyByIdParams = {
	id: number | undefined;
};
