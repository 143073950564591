import { ColumnPreference } from '@compstak/common';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { AnyComp } from 'types';
import { ColumnConfig, COLUMN_CONFIG } from '../columnConfigs';
import { DEFAULT_SORT_DIRECTION } from '../constants';
import { filterColumnPreferences } from '../filterColumnPreferences';
import { useOptionsContext } from '../OptionsProvider';
import { SearchTableColumn } from '../types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useRenderCompField } from 'hooks/useFormatCompField';

type Props = {
	columnPreferences: ColumnPreference[];
};

export const useSearchTableColumns = ({ columnPreferences }: Props) => {
	const { renderCellContent, averages, filterTableColumns } =
		useOptionsContext();

	const featureFlags = useFeatureFlags();

	const [filters] = useFilters();

	const isMonthly = useMemo(() => shouldUseMonthlyMetrics(filters), [filters]);

	const defaultRenderContent = useRenderCompField();

	return useMemo(() => {
		const filteredColumnPreferences = filterTableColumns
			? filterTableColumns(columnPreferences)
			: columnPreferences;

		return filterColumnPreferences(filteredColumnPreferences, featureFlags).map(
			(p) => {
				const config = COLUMN_CONFIG[p.compAttributeId as keyof ColumnConfig];

				const column: SearchTableColumn<AnyComp> = {
					...config,
					width: p.width,
					body: ({ row }) => {
						const name = config.name;

						if (renderCellContent) {
							return renderCellContent({
								row,
								config,
								defaultRenderContent: () =>
									defaultRenderContent({
										name,
										config,
										row,
										isMonthly,
									}),
							});
						} else {
							return defaultRenderContent({
								name,
								config,
								row,
								isMonthly,
							});
						}
					},
					sortName: config.sortName ?? config.name,
					isSortable: config.isSortable ?? true,
					initialSortDir: config.initialSortDir ?? DEFAULT_SORT_DIRECTION,
					renderAverage: averages?.[config.name],
				};

				return column;
			}
		);
	}, [columnPreferences, isMonthly, renderCellContent, averages]);
};
