import { MapboxImagePinSize, useMapboxImageURL } from '@compstak/maps';
import { point } from '@turf/helpers';
import { GeoPoint } from 'types';

type Props = {
	width: number;
	height: number;
	geoPoint: GeoPoint;
};

export const useMapImageUrl = ({ width, height, geoPoint }: Props) => {
	const { lon, lat } = geoPoint;
	const url = useMapboxImageURL({
		width,
		height,
		position: {
			type: 'center',
			latitude: lat,
			longitude: lon,
		},
		pinPoints: [point([lon, lat])],
		getPinSize: () => MapboxImagePinSize.SMALL,
	});

	return url;
};
