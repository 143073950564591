import { TenantIndustry } from 'api';
import { TENANT_INDUSTRY_COLORS } from 'constants/colors';
import styled, { CSSObject } from 'styled-components';
import { MapAnalyticsLegendCircle } from '../UI';

export type TenantIndustryLegendProps = {
	tenantIndustries: TenantIndustry[];
	isTopTen: boolean;
	listCss?: CSSObject;
};

export const TenantIndustryLegend = ({
	tenantIndustries,
	isTopTen,
	listCss,
}: TenantIndustryLegendProps) => {
	return (
		<>
			{isTopTen && (
				<TenantIndustryTopTenTitle>Top 10 Industries</TenantIndustryTopTenTitle>
			)}
			<StyledList $css={listCss}>
				{tenantIndustries.map((industry) => {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					const color = `rgb(${TENANT_INDUSTRY_COLORS[industry.name].join(
						', '
					)}`;
					return (
						<TenantIndustryListItem key={industry.id}>
							<MapAnalyticsLegendCircle
								style={{
									backgroundColor: color,
								}}
							/>
							<TextOverflow>{industry.name}</TextOverflow>
						</TenantIndustryListItem>
					);
				})}
			</StyledList>
		</>
	);
};

const StyledList = styled.ul<{ $css?: CSSObject }>((props) => ({
	...props.$css,
}));

const TenantIndustryTopTenTitle = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 6px;
`;

const TenantIndustryListItem = styled.li`
	display: flex;
	align-items: center;
	gap: 10px;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 12px;
	height: 17px;
`;

const TextOverflow = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
