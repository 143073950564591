import { Flex } from '@compstak/ui-kit';
import styled from 'styled-components';
import { Promotion } from './promotionTypes';
import {
	PromoDetailsSection,
	PromoTitleAndDate,
} from './promotionUtilComponents';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export function PromotionsList({
	promotions,
	selectedPromoId,
	onSelectPromo,
}: {
	promotions: Promotion[];
	selectedPromoId: number | null;
	onSelectPromo: (id: number) => void;
}) {
	return (
		<PromosUl>
			{promotions.length === 0 && (
				<li>There are no active promotions at the moment</li>
			)}
			{promotions.map((promotion) => (
				<li key={promotion.id} id={promotionListItemId(promotion.id)}>
					<PromotionCard
						onSelect={() => onSelectPromo(promotion.id)}
						promotion={promotion}
						isSelected={selectedPromoId === promotion.id}
					/>
				</li>
			))}
		</PromosUl>
	);
}

type PromotionProps = {
	promotion: Promotion;
	isSelected: boolean;
	onSelect: NoArgCallback;
};

const PromotionCard = ({ promotion, isSelected, onSelect }: PromotionProps) => {
	const { TargetedPromotionIterationsAppsPhase2 } = useFeatureFlags();

	if (!TargetedPromotionIterationsAppsPhase2 && promotion.compType === 'Sales')
		return null;

	return (
		<PromoCardStyled isSelected={isSelected} onClick={onSelect}>
			<PromoTitleAndDate promotion={promotion} />
			<hr />
			<Flex direction="column" alignItems="flex-start" gap="8px">
				<div>{promotion.shortDescription}</div>
				<PromoDetailsSection promotion={promotion} />
			</Flex>
		</PromoCardStyled>
	);
};

const PromosUl = styled.ul`
	display: flex;
	flex-direction: column;

	min-height: 200px;
	flex-basis: 200px;
	flex-grow: 1;

	gap: 6px;
	overflow-y: auto;
`;

const PromoCardStyled = styled.div<{ isSelected: boolean }>`
	padding: 16px;
	border: 3px solid
		${(props) =>
			props.isSelected
				? props.theme.colors.blue.blue500
				: props.theme.colors.neutral.n90};

	cursor: pointer;
`;

export function promotionListItemId(promotionId: number) {
	return `promotion-list-item-${promotionId}`;
}
