import { User } from '@compstak/common';
import { Page } from '@react-pdf/renderer';
import { useSharedPdfStyle } from 'pdf/useSharedPdfStyle';
import { ReactNode } from 'react';
import { PageFooter } from './PageFooter';
import { PageHeader } from './PageHeader';

type Props = {
	user: User;
	preparedFor: string;
	logo: string | null;
	title: string;
	children: ReactNode;
};

export const PdfPage = ({
	user,
	preparedFor,
	logo,
	title,
	children,
}: Props) => {
	const pdfStyle = useSharedPdfStyle();

	return (
		<Page style={pdfStyle.page} size="A4" orientation="landscape">
			<PageHeader logo={logo} title={title} />
			{children}
			<PageFooter preparedFor={preparedFor} user={user} />
		</Page>
	);
};
