import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import StringsTypeahead from 'Components/Filters/Base/StringsTypeahead';

const attribute = 'sellers' as const;

export const SellerFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => (
				<StringsTypeahead
					attribute={attribute}
					compType={props.compType}
					filter={props.filter}
					filters={props.filters}
					onFilterChange={props.onFilterChange}
					setError={props.setError}
					touch={props.touch}
					typeaheadAttribute="seller"
					placeholder="Add a seller"
				/>
			)}
		</FilterBase>
	);
};
