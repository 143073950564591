import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTheme } from 'styled-components';
import { pdfStyle } from '../style';
import { MufaKeyMetricsResponse } from 'api';
import { formatMoneyMufa, formatPercent } from 'format';
import { renderSafeValue } from 'utils';
import { nullValueText } from '@compstak/common';
import { PdfRealPageLogoView } from '../PdfRealPageLogoView';

type Props = {
	keyMetrics: MufaKeyMetricsResponse | undefined;
};
export const KeyMetricsPdf = ({ keyMetrics }: Props) => {
	const theme = useTheme();

	const stylesKeyValueItem = {
		flex: 1,
		justifyContent: 'space-between',
		borderBottom: `1px solid ${theme.colors.neutral.n50}`,
		paddingVertical: '6px',
		marginRight: 8,
		fontSize: '12px',
	} as const;

	return (
		<>
			<View
				style={[
					styles.keyMetricsCard,
					{ backgroundColor: theme.colors.neutral.n10 },
				]}
			>
				<Text style={pdfStyle.multifamilyTitle}>Key Property Metrics</Text>
				<View style={pdfStyle.dFlex}>
					<View style={pdfStyle.row}>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Asking Rent $/Unit
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.askingRentPerUnit,
									format: (v) => formatMoneyMufa(v),
								})}
							</Text>
						</View>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Effective Rent $/Unit
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.effectiveRentPerUnit,
									format: (v) => formatMoneyMufa(v),
								})}
							</Text>
						</View>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Concessions ($)
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.concessionValue,
									format: (v) => formatMoneyMufa(v),
								})}
							</Text>
						</View>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Units with Concessions
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.unitsWithConcessionsPercentage,
									format: (v) => {
										if (v === 0) return nullValueText;
										return formatPercent(v, 0);
									},
								})}
							</Text>
						</View>
					</View>

					<View style={pdfStyle.row}>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Asking Rent $/SF
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.askingRentPerSqFt,
									format: (v) => formatMoneyMufa(v),
								})}
							</Text>
						</View>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Effective Rent $/SF
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.effectiveRentPerSqFt,
									format: (v) => formatMoneyMufa(v),
								})}
							</Text>
						</View>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>
								Concessions (%)
							</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.concessionsPercentage,
									format: (v) => {
										if (v === 0) return nullValueText;
										return formatPercent(v, 0);
									},
								})}
							</Text>
						</View>
						<View style={[stylesKeyValueItem, pdfStyle.row]}>
							<Text style={{ color: theme.colors.neutral.n85 }}>Occupancy</Text>
							<Text>
								{renderSafeValue({
									value: keyMetrics?.occupancyPercentage,
									format: (v) => formatPercent(v, 1),
								})}
							</Text>
						</View>
					</View>
				</View>
			</View>
			<View style={pdfStyle.multifamilyRpContainer}>
				<PdfRealPageLogoView />
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	keyMetricsCard: {
		padding: '24px 16px',
		borderRadius: '4px',
		marginBottom: '12px',
	},
});
