import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioMap } from '../PortfolioMap';
import { MapListLoadingContainer } from '../UI';
import { ListViewAndMapViewToolbarV2 } from 'PortfolioAnalytics/ListView/ListViewAndMapViewToolbarV2';

type Props = {
	portfolioId: number;
};

export const MapViewV2 = ({ portfolioId }: Props) => {
	const { filters } = usePortfolioFilters();
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery({
			id: portfolioId,
			filters,
		});

	if (isFetchingPortfolio || !portfolio) {
		return (
			<MapListLoadingContainer>
				<Spinner />
			</MapListLoadingContainer>
		);
	}

	return (
		<Root>
			<ListViewAndMapViewToolbarV2
				view="map"
				portfolioId={portfolio.id}
				searchParams={searchParams}
			/>
			<div>
				<PortfolioMap
					portfolioId={portfolioId}
					isMapView={true}
					searchParams={searchParams}
				/>
			</div>
		</Root>
	);
};

const Root = styled.div`
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100%;
	box-sizing: border-box;
	flex: 1;
	* {
		box-sizing: border-box;
	}
`;
