import React, { PropsWithChildren } from 'react';
import dayjs, { QUnitType } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

import {
	DateIntervalFiltersKeys,
	FilterDateInterval,
	FilterNumberInterval,
	FiltersObject,
	NumberIntervalFiltersKeys,
} from 'models/filters/types';
import { FilterErrors } from './Filter/Container';
import { FilterFieldButton } from './Filter/FilterFieldButton';

type TimespanButtonProps = PropsWithChildren<{
	roundTo?: QUnitType;
	unit: QUnitType;
	filter: FilterDateInterval | FilterNumberInterval | null | undefined;
	attribute: DateIntervalFiltersKeys | NumberIntervalFiltersKeys;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setError: (errors: FilterErrors) => void;
	min?: number;
	max?: number;
	touch: NoArgCallback;
}>;

export default class TimespanButton extends React.Component<TimespanButtonProps> {
	onClick = () => {
		this.props.touch();

		const min =
			this.props.min !== undefined
				? dayjs()
						.add(this.props.min, this.props.unit)
						.startOf(this.props.roundTo ?? 'month')
						.toDate()
				: null;

		const max =
			this.props.max !== undefined
				? dayjs().add(this.props.max, this.props.unit).toDate()
				: null;

		const newFilterValue = {
			[this.props.attribute]: { ...this.props.filter, min, max },
		};

		try {
			this.props.onFilterChange(newFilterValue);
		} catch (e) {
			// @ts-expect-error TS18046: 'e' is of type 'unknown'....
			this.props.setError(e.message);
		}
	};

	render() {
		return (
			<FilterFieldButton onClick={this.onClick}>
				{this.props.children}
			</FilterFieldButton>
		);
	}
}
