import {
	useLeaseExpirationBySubmarket,
	UseLeaseExpirationBySubmarketOptions,
} from 'api/leaseExpirationBySubmarket/useLeaseExpirationBySubmarket';
import { useSubmarketsByMarket } from 'api/submarkets/useSubmarketsByMarket';
import { useFilters } from 'reducers/filtersReducer';

type LeaseExpirationBySubmarketData = {
	[yearKey: number]: {
		[submarket: string]: number;
	};
};

export const useExchangeDashboardLeaseExpirationBySubmarket = (
	options?: UseLeaseExpirationBySubmarketOptions
) => {
	const [filters] = useFilters('exchange-dashboard');
	const submarketsFromFilters = filters.submarkets?.length
		? filters.submarkets
		: undefined;
	const {
		data: leaseExpirationData,
		isFetching: leaseExpirationDataIsFetching,
	} = useLeaseExpirationBySubmarket({ filters }, options);
	const { data: submarketsData, isFetching: submarketsDataIsFetching } =
		useSubmarketsByMarket(filters.market, {
			enabled: options?.enabled ?? !submarketsFromFilters,
		});
	const isFetching = leaseExpirationDataIsFetching || submarketsDataIsFetching;

	const data = leaseExpirationData?.reduce<LeaseExpirationBySubmarketData>(
		(acc, { year, submarket, leaseCount }) => {
			return {
				...acc,
				[year]: {
					...acc[year],
					[submarket]: (acc[year] ? acc[year][submarket] ?? 0 : 0) + leaseCount,
				},
			};
		},
		{}
	);

	const currentYear = new Date().getFullYear();

	const countsBySubmarket = leaseExpirationData?.reduce<Record<string, number>>(
		(acc, dataItem) =>
			dataItem.year <= currentYear + 4
				? {
						...acc,
						[dataItem.submarket]:
							(acc[dataItem.submarket] ?? 0) +
							(dataItem.year <= currentYear + 4 ? dataItem.leaseCount : 0),
				  }
				: acc,
		{}
	);

	const submarkets = (submarketsFromFilters ?? submarketsData)
		?.map((s) => s.name)
		.filter((s) => countsBySubmarket?.[s])
		.sort(
			(s1, s2) =>
				(countsBySubmarket?.[s2] ?? 0) - (countsBySubmarket?.[s1] ?? 0)
		);

	return {
		data,
		submarkets,
		isFetching,
		type: 'leaseExpirationSubmarket' as const,
	};
};
