import {
	PortfolioPropertiesFilterQuery,
	PortfolioPropertySummary,
	usePortfolioPropertiesQuery,
	usePortfolioPropertiesSummaryQuery,
} from 'api';
import { useMemo } from 'react';
import { PropertyComp } from 'types';

type Props = {
	portfolioId: number;
	filters?: PortfolioPropertiesFilterQuery;
	enabled?: boolean;
};

// TODO: AP-14320 to remove when portfolio revamp is done
export const usePropertiesWithSummaries = ({
	portfolioId,
	filters,
	enabled,
}: Props) => {
	const { data: properties, isFetching: isFetchingProperties } =
		usePortfolioPropertiesQuery(
			{
				portfolioId,
				...filters,
			},
			{ enabled }
		);

	const { data: propertiesSummary, isFetching: isFetchingSummaries } =
		usePortfolioPropertiesSummaryQuery(
			{
				portfolioId,
				...filters,
			},
			{ enabled }
		);

	const data = useMemo(() => {
		if (!properties || !propertiesSummary) return [];

		return properties.map((p) => {
			const summary = propertiesSummary.find((ps) => ps.propertyId === p.id);
			return {
				...p,
				...summary,
			};
		}) as PropertyWithSummary[];
	}, [properties, propertiesSummary]);

	return {
		data,
		isFetching: isFetchingProperties || isFetchingSummaries,
	};
};

export type PropertyWithSummary = PropertyComp & PortfolioPropertySummary;
