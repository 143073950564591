import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import Multiselect, { FilterOption } from 'Components/Filters/Base/Multiselect';
import { useSpaceTypeAggregations } from 'api/spaceTypeAggregations/useSpaceTypeAggregations';
import { useMemo } from 'react';
import { useReferenceData } from 'Pages/Login/reducers';
import {
	getCountForAggregation,
	renderAggregationOption,
} from './aggregationFilterHelper';
import { alphabeticalOrder } from 'utils';
import { getLabelForFilterAttribute } from 'models/filters/util/filterHelpers';

const attribute = 'spaceSubtypeId' as const;

export const SpaceSubtypeFilter = ({ inputId }: { inputId?: string }) => {
	const { compType, context, filters } = useFilterFieldContext();
	const referenceData = useReferenceData();

	const { data: aggregationsData } = useSpaceTypeAggregations({
		compType,
		filters,
	});

	const aggregation = aggregationsData?.spaceSubtypeId;

	const options: FilterOption[] = useMemo(() => {
		if (!referenceData?.filterableSpaceSubtypes) {
			return [];
		}

		const filterableSpaceSubtypes =
			referenceData.filterableSpaceSubtypes.filter(
				(option) =>
					getCountForAggregation({
						aggregation,
						option,
					}) > 0
			);

		return alphabeticalOrder({
			list: filterableSpaceSubtypes,
			sortByProperty: 'name',
		});
	}, [aggregation, referenceData?.filterableSpaceSubtypes]);

	return (
		<FilterBase
			inputId={inputId}
			attribute={attribute}
			labelSynonyms={[
				getLabelForFilterAttribute({ attribute: 'spaceTypeId', compType }),
			]}
		>
			{(props) => (
				<Multiselect
					attribute={attribute}
					filter={props.filter}
					onFilterChange={props.onFilterChange}
					options={options}
					renderOption={(option, searchTerm) =>
						renderAggregationOption({
							aggregation,
							option,
							searchTerm,
							shouldRenderAggregationCount: context !== 'analytics',
						})
					}
					setError={props.setError}
					touch={props.touch}
					hasSearch
					searchInputPlaceholder="Search for a space subtype"
					initialExpanded={!props.nestedFilterIsSet}
				/>
			)}
		</FilterBase>
	);
};
