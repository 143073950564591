import { GridChildComponentProps, VariableSizeGrid } from 'react-window';
import { BodyCell } from './BodyCell';
import { useColumnsContext } from './Columns';
import { ROW_HEIGHT } from './constants';
import { useLayoutContext } from './LayoutProvider';
import { useOptionsContext } from './OptionsProvider';

export const Body = () => {
	const { rows, getRowId } = useOptionsContext();
	const { columns } = useColumnsContext();
	const { setBody, bodyGridRef, bodyWidthWithOffset, bodyHeightWithOffset } =
		useLayoutContext();

	return (
		<VariableSizeGrid
			width={bodyWidthWithOffset}
			height={bodyHeightWithOffset}
			rowCount={rows.length}
			rowHeight={() => ROW_HEIGHT}
			columnCount={columns.length}
			columnWidth={(columnIndex) => columns[columnIndex].width}
			itemKey={({ rowIndex, columnIndex }) =>
				`${getRowId(rows[rowIndex])}-${columns[columnIndex].id}`
			}
			style={{
				overflow: 'hidden',
				flexShrink: 0,
			}}
			ref={(grid) => {
				bodyGridRef.current = grid;
				const container = (grid as any)?._outerRef;
				setBody(container);
			}}
		>
			{renderBodyCell}
		</VariableSizeGrid>
	);
};

const renderBodyCell = <Row,>({
	style,
	rowIndex,
	columnIndex,
}: GridChildComponentProps<Row>) => (
	<BodyCell style={style} rowIndex={rowIndex} columnIndex={columnIndex} />
);
