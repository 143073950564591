import {
	MarketRentAnalyticsLegend,
	useMarketRentAdjustedPercentiles,
	useMarketRentAnalyticsState,
} from 'maps';
import {
	MarketRentAnalyticsDescription,
	useMarketRentAnalyticsData,
} from '../MarketRentAnalytics';
import { MapExportLegend } from './MapExportLegend';

export const MarketRentAnalyticsExportLegend = () => {
	const [state] = useMarketRentAnalyticsState();

	const { data } = useMarketRentAnalyticsData();

	const percentiles = useMarketRentAdjustedPercentiles({
		percentiles: data?.percentiles ?? [],
	});

	return (
		<MapExportLegend
			title={state.title}
			description={<MarketRentAnalyticsDescription />}
		>
			<MarketRentAnalyticsLegend percentiles={percentiles} />
		</MapExportLegend>
	);
};
