import React from 'react';

import styles from './unknownfloors.less';
import button from 'ui/styles/button.less';

type State = any;

export default class SFInput extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);

		this.state = {
			validInput: false,
			footprint: null,
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	update = (event) => {
		const num = event.target.value;
		if (Number(num)) {
			if (num > 0) {
				this.setState({ validInput: true, footprint: num });
			} else {
				this.setState({ validInput: false });
			}
		} else {
			this.setState({ validInput: false });
		}
	};

	sendParentFloorPlate = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'updateFloorPlate' does not exist on type... Remove this comment to see the full error message
		this.props.updateFloorPlate(this.state.footprint);
	};

	renderButton() {
		const buttonStyle = this.state.validInput ? 'button' : 'disabled';

		let onClickAction;
		if (buttonStyle !== 'disabled') {
			onClickAction = this.sendParentFloorPlate;
		}

		return (
			<div
				onClick={onClickAction}
				className={button[buttonStyle] + ' ' + styles.button}
			>
				Done
			</div>
		);
	}

	render() {
		return (
			<div className={styles.container}>
				<div className={styles.header}>What is this building's footprint?</div>
				<div className={styles.subheading}>
					A building footprint refers to the perimeter of the building or the
					area of a building where it meets the ground
				</div>
				<div className={styles.center}>
					<label className={styles.label + ' ' + styles.sfText}>
						Enter Building FootPrint
						<input
							type="text"
							name="footprint"
							className={styles.formInput}
							onChange={this.update}
						/>
					</label>
					<div />
					{this.renderButton()}
				</div>
			</div>
		);
	}
}
