import React from 'react';
import ReactTimeout, { ReactTimeoutProps } from 'react-timeout';

import styles from 'Components/Header/header.less';

type Props = {
	freeComps: number;
} & ReactTimeoutProps;

type State = {
	visible: boolean;
	animate: boolean;
};

class FreeCompsNotification extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			visible: false,
			animate: false,
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.freeComps > prevProps.freeComps) {
			this.setState({ visible: true, animate: true });
			this.props.setTimeout?.(() => {
				this.setState({ animate: false });
			}, 2000);
		}
		if (this.props.freeComps < prevProps.freeComps) {
			this.setState({ visible: false, animate: false });
		}
	}

	render() {
		const className: string = (() => {
			const selector = styles.freeCompsNotification;
			const visible = this.state.visible ? null : styles.hidden;
			const animate = this.state.animate ? styles.blink : null;
			const classNames = [selector, visible, animate];
			return classNames.filter((item) => item !== null).join(' ');
		})();

		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<div
				onClick={() => this.setState({ visible: false })}
				className={className}
			>
				<span className={styles.arrowUp} />
				<span className={styles.freeCompsNotificationContent}>
					{"Here's a free comp while you wait"}
				</span>
			</div>
		);
	}
}

export default ReactTimeout(FreeCompsNotification);
