import {
	UseTopTenantIndustriesOptions,
	useReferenceDataQuery,
	useTopTenantIndustriesQuery,
} from 'api';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

/** Tenant industries to show */
export const useFilteredTenantIndustries = (
	options?: UseTopTenantIndustriesOptions
) => {
	const [filters] = useFilters('exchange-dashboard');

	const { data: topTenantIndustries } = useTopTenantIndustriesQuery(
		{
			filters,
		},
		options
	);

	const selectedTenantIndustries = useMemo(() => {
		return filters.tenantIndustry ?? [];
	}, [filters.tenantIndustry]);

	const { data: referenceData } = useReferenceDataQuery();

	const tenantIndustryReference = referenceData?.tenantIndustries;

	return useMemo(() => {
		if (!tenantIndustryReference) return [];

		return tenantIndustryReference.filter((industry) => {
			if (selectedTenantIndustries.length > 0) {
				return selectedTenantIndustries?.includes(industry.name);
			} else {
				return !!topTenantIndustries?.find(
					(topIndustry) => topIndustry.industry === industry.name
				);
			}
		});
	}, [selectedTenantIndustries, topTenantIndustries, tenantIndustryReference]);
};
