import { FormSelect, SelectListItem, SelectProps } from '@compstak/ui-kit';
import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalInputMessage,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { Chart } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import { useMemo, useState } from 'react';
import { trimString } from 'util/trimString';
import { useAppSelector } from 'util/useAppSelector';

type Props = {
	chartDraft: Chart;
	onSave: (chart: Chart, projectName: string) => void;
	isSaving: boolean;
};

export const SaveChartAsModal = ({ chartDraft, onSave, isSaving }: Props) => {
	const { projects } = useAppSelector((store) => store.analyticsProjects);

	const { closeModal } = useModal();

	const [selectedProjectId, setSelectedProjectId] = useState(() => {
		const currentProject = projects?.find((project) =>
			project.charts.find((chart) => chart.id === chartDraft.originalChartId)
		);
		return currentProject?.id;
	});
	const [customProjectName, setCustomProjectName] = useState('');

	const [chartTitle, setChartTitle] = useState(chartDraft.title ?? 'New Chart');
	const [projectError, setProjectError] = useState<string>('');
	const [titleError, setTitleError] = useState<string>('');

	const projectOptions: SelectListItem[] = useMemo(() => {
		const options: SelectListItem[] = (projects ?? []).map((project) => ({
			value: Number(project.id),
			title: project.name,
		}));

		const customOption: SelectListItem = {
			value: -1,
			title: 'Create A New Project',
		};

		return [customOption, ...options];
	}, [projects]);

	const isNewProject = selectedProjectId === -1;

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitleError('');
		setChartTitle(event.target.value);
	};

	const handleCustomProjectNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setProjectError('');
		setCustomProjectName(event.target.value);
	};

	const dupeTitleExists = (projectName: string, title: string) => {
		const project = projects?.find((p) => p.name === projectName);
		if (project && project.charts.find((chart) => chart.title === title)) {
			return true;
		}
		return false;
	};

	const handleProjectChange: SelectProps['onChange'] = ({ selectedItem }) => {
		setProjectError('');
		setTitleError('');
		if (!selectedItem) {
			return;
		}

		const value = Number(selectedItem.value);
		const projectName = String(selectedItem.title);

		setSelectedProjectId(value);

		if (dupeTitleExists(projectName, chartTitle)) {
			setTitleError('There is already a chart with this name.');
		}
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		setTitleError('');
		setProjectError('');

		const projectName = !isNewProject
			? projects?.find((project) => project.id === selectedProjectId)?.name ??
				''
			: trimString(customProjectName);

		const trimmedChartTitle = trimString(chartTitle);

		if (!trimmedChartTitle) {
			setTitleError('Please name the chart');
			return;
		}

		if (!projectName) {
			if (!isNewProject) {
				setProjectError('Please select a project');
				return;
			}
			setProjectError('Please name the project');
			return;
		}

		if (dupeTitleExists(projectName, trimmedChartTitle)) {
			setTitleError('There is already a chart with this name.');
			return;
		}

		const chart = {
			...chartDraft,
			title: trimmedChartTitle,
		};

		onSave(chart, projectName);
	};

	return (
		<>
			<ModalTitle>Save In-Progress Chart</ModalTitle>
			<form onSubmit={handleSubmit}>
				<div>
					<ModalInputLabel isError={!!titleError}>
						Chart Name
						<input
							type="text"
							value={chartTitle}
							onChange={handleTitleChange}
						/>
						{!!titleError && (
							<ModalInputMessage>{titleError}</ModalInputMessage>
						)}
					</ModalInputLabel>

					<ModalInputLabel isError={!isNewProject && !!projectError}>
						Select Project
						<FormSelect
							onChange={handleProjectChange}
							items={projectOptions}
							value={selectedProjectId}
							isSearchable
						/>
						{!isNewProject && !!projectError && (
							<ModalInputMessage>{projectError}</ModalInputMessage>
						)}
					</ModalInputLabel>
					{isNewProject && (
						<ModalInputLabel isError={!!projectError}>
							Project Name
							<input
								type="text"
								value={customProjectName}
								onChange={handleCustomProjectNameChange}
							/>
							{!!projectError && (
								<ModalInputMessage>{projectError}</ModalInputMessage>
							)}
						</ModalInputLabel>
					)}
				</div>
				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeModal}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={
							!!titleError ||
							!!projectError ||
							!selectedProjectId ||
							(isNewProject && customProjectName === '')
						}
						isLoading={isSaving}
					>
						Save & create new
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};
