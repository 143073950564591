import React from 'react';
import * as Styled from './styled';

type Data = {
	color: string;
	label: string;
};
type LegendProps = {
	data: Data[];
};

const Legend = ({ data }: LegendProps) => (
	<Styled.Container>
		<Styled.Spacer />
		<Styled.ItemsContainer>
			{data.map(({ color, label }) => (
				<Styled.Item key={label} color={color}>
					<div />
					<span>{label}</span>
				</Styled.Item>
			))}
		</Styled.ItemsContainer>
	</Styled.Container>
);

export default Legend;
