import { Market } from '@compstak/common';
import { useIsExchange } from 'hooks';
import { useMarketsHaveFeature } from 'api';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type useIsShowTreppUIProps = {
	markets?: Market[] | number[];
};

export const useIsShowTreppUI = ({ markets }: useIsShowTreppUIProps) => {
	const isExchange = useIsExchange();
	const flags = useFeatureFlags();

	const marketIds = markets?.map((market: Market | number) =>
		typeof market === 'object' ? market.id : market
	);

	return !!useMarketsHaveFeature({
		feature: 'salesAccess',
		marketIds,
		isEnabled: !!marketIds && (!isExchange || flags.ExchangeLoans),
	}).data;
};
