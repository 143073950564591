import { nullValueText } from '@compstak/common';
import {
	colors,
	KeyValueTable,
	numberUtils,
	typography,
} from '@compstak/ui-kit';
import { KvTableHeader } from 'Components/KvTable/kvTableHeader';
import { KvTableStylingWrapper } from 'Components/KvTable/kvTableWrapper';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SalesComp } from 'types/comp';
import { formatValue } from 'util/comp-format/src/format';
import { Overlay, TabContentContainer } from '../../Components';
import { UNLOCK_BUTTON_STATE } from '../../Components/UnlockButton';
import { TabsProps } from '../../Tabs';
import { buyerSellerItems, Item, transactionItems } from './items';
import { formatObfuscatedSizeRange } from 'format';

type Props = TabsProps;

export const TransactionDetails = memo<Props>(({ data, hasEnoughCredits }) => {
	const getTableData = useMemo(() => getTablesDataFactory(data), [data]);

	return (
		<TabContentContainer>
			<TablesContainer>
				<TableContainer data={data} hasEnoughCredits={hasEnoughCredits}>
					<KvTableHeader>Transaction</KvTableHeader>
					<KeyValueTable data={getTableData(transactionItems)} columns={1} />
				</TableContainer>
				<TableContainer data={data} hasEnoughCredits={hasEnoughCredits}>
					<KvTableHeader>Buyer/Seller</KvTableHeader>
					<KeyValueTable data={getTableData(buyerSellerItems)} columns={1} />
				</TableContainer>

				<TableContainer data={data} hasEnoughCredits={hasEnoughCredits}>
					<KvTableHeader>Notes</KvTableHeader>
					<NotesParagraph>
						{data.own ? (
							data.comments ?? nullValueText
						) : (
							<>
								<span>Comments</span>
								{data.comments !== undefined ? (
									<LockIconStyled width={14} height={16} hasDarkColor />
								) : (
									nullValueText
								)}
							</>
						)}
					</NotesParagraph>
				</TableContainer>
			</TablesContainer>
			{/* This table is basically always going to be empty right now based on the data we get, so we need to hide it for now*/}
			{/* {isPortfolio && <PortfolioTransactionDetails data={data} />} */}
		</TabContentContainer>
	);
});

TransactionDetails.displayName = 'TransactionDetails';

const getTablesDataFactory =
	(data: SalesComp) =>
	(items: Item[]): [React.ReactNode, React.ReactNode][] => {
		const { own, isPortfolio } = data;

		return items
			.filter(
				({ key }) =>
					(isPortfolio && key !== 'documentNumber') ||
					(!isPortfolio && !['portfolio', 'percentOccupied'].includes(key))
			)
			.map(({ key, title }) => {
				if (!own && key === 'transactionSize') {
					return [title, formatObfuscatedSizeRange(data[key])];
				}

				if (
					!own &&
					(key === 'salePricePsf' ||
						key === 'capRate' ||
						key === 'documentNumber' ||
						key === 'buyer' ||
						key === 'seller') &&
					data[key] === null
				) {
					return [
						title,
						<LockIconStyled hasDarkColor key={key} width={14} height={16} />,
					];
				}

				let itemValue: React.ReactNode;
				if (key === 'percentOccupied') {
					itemValue =
						data.portfolio[0].percentOccupied !== undefined
							? numberUtils.formatPercent(
									data.portfolio[0].percentOccupied / 100,
									'percentInteger'
								)
							: nullValueText;
				} else {
					itemValue = nullValueText;

					const value = data[key];
					if (value) {
						const isArrayWithElements =
							Array.isArray(value) && value.length > 0;

						const isNotArray = !Array.isArray(value);

						if (isArrayWithElements || isNotArray) {
							itemValue = formatValue(
								key,
								value,
								data.portfolio[0].inMonthlyMarket,
								'sale'
							) as React.ReactNode;
						}
					}
				}

				return [title, itemValue];
			});
	};

const TableContainer = ({
	children,
	data,
	hasEnoughCredits,
}: {
	children: React.ReactNode;
	data: SalesComp;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
}) => {
	const [isMouseOver, setIsMouseOver] = useState(false);

	const handleMouseEnter = () => {
		setIsMouseOver(true);
	};

	const handleMouseLeave = () => {
		setIsMouseOver(false);
	};

	return (
		<KvTableStylingWrapper
			onMouseOver={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{!data.own && isMouseOver && (
				<Overlay data={data} hasEnoughCredits={hasEnoughCredits} />
			)}
			{children}
		</KvTableStylingWrapper>
	);
};

const NotesParagraph = styled.p`
	margin: 0;
	padding: 8px 0;
	font-family: ${typography.fontFamily.gotham};
	font-size: 12px;
	line-height: 16px;
	color: ${colors.gray.gray700};

	display: flex;
	justify-content: space-between;
`;

const TablesContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: auto;
	gap: 24px;

	@media (max-width: 1400px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 1050px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;
