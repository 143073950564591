import { InfiniteData } from '@tanstack/react-query';
import { SEARCH_INFINITE_PAGE_SIZE } from 'api';
import { createLoadingRows } from 'Components';
import { useMemo } from 'react';

type Props<Row> = {
	data: InfiniteData<any> | undefined;
	isFetching: boolean;
	getRows: (page: any) => Row[];
};

/** Merges rows from all pages (infinite query) & adds "loading rows" appropriately. */
export const useSearchTableRows = <Row>({
	data,
	isFetching,
	getRows,
}: Props<Row>): Row[] => {
	return useMemo(() => {
		if (!data) return createLoadingRows(SEARCH_INFINITE_PAGE_SIZE);
		const dataRows = data.pages.reduce<Row[]>((acc, page) => {
			acc.push(...getRows(page));
			return acc;
		}, []);
		if (isFetching) {
			const nextPageSize = data.pages.at(-1).nextPageSize ?? 0;
			return dataRows.concat(createLoadingRows(nextPageSize));
		}
		return dataRows;
	}, [data, isFetching, getRows]);
};
