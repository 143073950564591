import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { filtersToServerJSON } from 'models/filters/util';
import { QUERY_KEYS } from '../constants';
import { CommonFilters as CommonPremierListFilters } from '../../Pages/PremierList/PremierList';
import { useIsMultifamily } from '../../hooks';

const TOP_PROPERTIES_LANDLORDS_DEFAULT_LIMIT = 10;

export const useTopPropertiesLandlordsQuery = (
	params: UseTopPropertiesLandlordsParams,
	options?: UseTopPropertiesLandlordsOptions
) => {
	const { filters, limit = TOP_PROPERTIES_LANDLORDS_DEFAULT_LIMIT } = params;
	const filter = filtersToServerJSON(filters);

	const hasMufaAccess = useIsMultifamily({ markets: filters.markets });

	return useQuery({
		queryKey: [
			QUERY_KEYS.TOP_PROPERTIES_LANDLORDS,
			filter,
			limit,
			hasMufaAccess,
		],
		queryFn: async () => {
			const response = await API.post<TopPropertiesLandlordsResponse>(
				`/api/${hasMufaAccess ? 'mufa/' : ''}properties/actions/topLandlords`,
				{
					filter,
					limit,
				}
			);

			return response.data;
		},
		...options,
	});
};

type UseTopPropertiesLandlordsOptions =
	UseQueryOptions<TopPropertiesLandlordsResponse>;

type UseTopPropertiesLandlordsParams = {
	filters: Omit<CommonPremierListFilters, 'hidden' | 'partial'>;
	limit?: number;
};

export type PropertyLandlord = {
	name: string;
	totalRecordsCount: number;
	sumOfPropertySqFt: number;
	totalPropertiesCount: number;
	averagePropertySize: number;
	officePropertiesCount?: number;
	industrialPropertiesCount?: number;
	retailPropertiesCount?: number;
	otherPropertiesCount?: number;
};

export type TopPropertiesLandlordsResponse = {
	landlords: PropertyLandlord[];
};
