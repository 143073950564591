import { numberUtils } from '@compstak/ui-kit';
import { MufaCompsetDTO } from 'api';
import { MufaPropertyComp } from 'types';
import { ExcelColumn } from '../../utils';
import { excelColumns } from './excelColumns';

export const excelMufaColumns: ExcelColumn<
	MufaCompsetDTO,
	{ subjectProperty: MufaPropertyComp }
>[] = [
	...excelColumns.slice(0, 3),
	{
		header: 'Asking Rent $/SF',
		body: ({ row }) => {
			if (row.property.askingRentPerSqFt == null) return '';
			return numberUtils.formatCurrency(
				row.property.askingRentPerSqFt,
				'currencyTwoDecimals'
			);
		},
	},
	{
		header: 'Effective Rent $/SF',
		body: ({ row }) => {
			if (row.property.effectiveRentPerSqFt == null) return '';
			return numberUtils.formatCurrency(
				row.property.effectiveRentPerSqFt,
				'currencyTwoDecimals'
			);
		},
	},
	{
		header: '# of Units',
		body: ({ row }) => {
			if (row.property.numberOfUnits == null) return '';
			return numberUtils.formatNumber(row.property.numberOfUnits, 'integer');
		},
	},
	...excelColumns.slice(4),
];
